import {
  Select as UISelect,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { ClassName } from '@/types/globals'
import { Loading } from '@/components/ui/loading'
import { useState } from 'react'

interface SelectOption {
  value: string
  label: string
}

interface SelectProps<T extends string> {
  options: SelectOption[]
  value: T
  onValueChange: (_value: T) => void
  placeholder?: string
  className?: ClassName
  disabled?: boolean
  isLoading?: boolean
}

interface MultiSelectProps<T extends string> {
  options: SelectOption[]
  value: T[]
  onValueChange: (_value: T[]) => void
  placeholder?: string
  className?: ClassName
  disabled?: boolean
  isLoading?: boolean
}

export const Select = <T extends string>({
  options,
  value,
  onValueChange,
  placeholder = 'Select an option',
  className = '',
  disabled,
  isLoading,
}: SelectProps<T>) => {
  return (
    <UISelect
      value={value}
      onValueChange={onValueChange}
      disabled={disabled || isLoading}
    >
      <SelectTrigger className={cn('w-[200px]', className)}>
        <SelectValue placeholder={placeholder}>
          {isLoading ? (
            <Loading className="h-4 w-4" />
          ) : (
            options.find((opt) => opt.value === value)?.label
          )}
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {options.map((option) => (
          <SelectItem key={option.value} value={option.value}>
            {option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </UISelect>
  )
}
export const MultiSelect = <T extends string>({
  options,
  value: selectedValues,
  onValueChange,
  placeholder = 'Select options',
  className = '',
  disabled,
  isLoading,
}: MultiSelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleValueChange = (newValue: string, e?: React.MouseEvent) => {
    // Stop event propagation
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (newValue === 'all') {
      onValueChange(['all' as T])
      setIsOpen(false) // Only close for 'all' selection
    } else {
      const updatedValues = selectedValues.includes(newValue as T)
        ? selectedValues.filter((v) => v !== newValue)
        : [...selectedValues.filter((v) => v !== 'all'), newValue as T]

      onValueChange(updatedValues)
      // Keep open for individual selections
      setIsOpen(true)
    }
  }

  const displayValue = () => {
    if (isLoading) return <Loading className="h-4 w-4" />
    if (selectedValues.includes('all' as T)) {
      return options.find((opt) => opt.value === 'all')?.label
    }
    return selectedValues.length > 0
      ? `${selectedValues.length} selected`
      : placeholder
  }

  return (
    <UISelect
      value={selectedValues[selectedValues.length - 1] || ''}
      onValueChange={handleValueChange}
      disabled={disabled || isLoading}
      open={isOpen}
      onOpenChange={(open) => {
        // Only allow closing when clicking outside
        if (!open) {
          setIsOpen(false)
        } else {
          setIsOpen(true)
        }
      }}
    >
      <SelectTrigger className={cn('w-[200px]', className)}>
        <SelectValue placeholder={placeholder}>{displayValue()}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        {options.map((option) => (
          <SelectItem
            key={option.value}
            value={option.value}
            onSelect={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleValueChange(option.value)
            }}
          >
            <div
              className="flex items-center gap-2"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleValueChange(option.value, e)
              }}
              onMouseDown={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
            >
              <div
                className={cn(
                  'h-4 w-4 border rounded-sm flex items-center justify-center',
                  selectedValues.includes(option.value as T)
                    ? 'bg-primary border-primary'
                    : 'border-input',
                )}
              >
                {selectedValues.includes(option.value as T) && (
                  <svg
                    className="h-3 w-3 text-primary-foreground"
                    fill="none"
                    strokeWidth="2"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                )}
              </div>
              <span>{option.label}</span>
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </UISelect>
  )
}
