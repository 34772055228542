import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  UserButton,
  useAuth,
  useOrganization,
  useUser,
} from '@clerk/clerk-react'

import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { Icon, IconName } from '@/components/ui/icon'
import { LogoLink } from '@/components/common/logo'
import { CustomOrganizationSwitcher } from '@/components/custom-organization-switcher'
import { Badge } from '@/components/ui/badge'

import { useFetchTeamMembers } from '@/services/api/organization.api'
import { cn } from '@/lib/utils'
import { useUserRole } from '@/hooks/useUserRole'
import { useLocalStorage } from '@/hooks/useLocalStorage'

import type { UserProfile } from '@/types/UserProfile'
import type { CannyOptions } from '@/types/Canny'
import type { TawkAPI } from '@/types/Tawk'
import { MOCK_SERVER_ENABLED } from '@/services/config/env'

interface MenuOption {
  label: string
  href: string
  icon: IconName
  disabled?: boolean
  hidden?: boolean
  submenu?: MenuOption[]
  position?: 'main' | 'bottom'
  adminOnly?: boolean
  onClick?: () => void
  badge?: {
    text: string
  }
}

const useOnboardingStatus = (isAdmin: boolean) => {
  const { user } = useUser()
  return user?.id
    ? (() => {
        const savedChecklist = localStorage.getItem(`onboarding-${user.id}`)
        if (!savedChecklist) return false
        const checklist = JSON.parse(savedChecklist)
        const relevantItems = isAdmin
          ? checklist
          : checklist.filter((item: { id: string }) =>
              ['calendar', 'feedback', 'orgchart'].includes(item.id),
            )
        return (
          relevantItems.length > 0 &&
          relevantItems.every((item: { checked: boolean }) => item.checked)
        )
      })()
    : false
}

const getMenuOptions = (
  teamMembersUnderUser: UserProfile[],
  isAdmin: boolean,
  isOnboardingComplete: boolean,
): MenuOption[] => {
  return [
    {
      label: 'Get Started',
      icon: 'Flag',
      href: '/onboarding',
      position: 'main',
      hidden: isOnboardingComplete || MOCK_SERVER_ENABLED,
    },
    {
      label: 'My Dashboard',
      icon: 'User',
      href: '/dashboard',
      position: 'main',
    },
    {
      label: 'Team Dashboard',
      icon: 'Users',
      href: '/management-dashboard',
      position: 'main',
      hidden: !teamMembersUnderUser.length && !isAdmin,
      badge: {
        text: 'New',
      },
    },
    {
      label: 'Company',
      icon: 'Building',
      href: '/company',
      position: 'main',
      submenu: [
        {
          label: 'Organizational Chart',
          icon: 'Building2',
          href: '/company/organizational-chart',
        },
        {
          label: 'Values',
          icon: 'Heart',
          href: '/company/values',
          badge: {
            text: 'New',
          },
        },
      ],
    },
    {
      label: 'Feedback Points',
      icon: 'TrendingUp',
      href: '/feedback-points',
      position: 'main',
    },
    {
      label: 'Feedback Sessions',
      icon: 'Star',
      href: '/feedback-sessions',
      position: 'main',
    },
    {
      label: 'Objectives',
      icon: 'Crosshair',
      href: '/objectives',
      position: 'main',
    },
    {
      label: 'Performance Reviews',
      icon: 'ClipboardCheck',
      href: '/performance-review',
      position: 'main',
      submenu: [
        {
          label: 'My Reviews',
          icon: 'User',
          href: '/performance-review/me',
        },
        {
          label: 'My Team Reviews',
          icon: 'Users',
          href: '/performance-review/team',
          hidden: !teamMembersUnderUser.length && !isAdmin,
        },
      ],
    },
    {
      label: 'Time Off',
      icon: 'Calendar',
      href: '/time-off',
      disabled: true,
      position: 'main',
    },
    {
      label: 'Changelog',
      icon: 'Inbox',
      href: '#',
      position: 'bottom',
    },
    {
      label: 'Support',
      icon: 'CircleHelp',
      href: '#',
      position: 'bottom',
      onClick: () => {
        const tawkApi = window.Tawk_API as TawkAPI | undefined
        if (tawkApi && typeof tawkApi.toggle === 'function') {
          tawkApi.toggle()
        }
      },
    },
    {
      label: 'License Management',
      icon: 'Key',
      href: '/licensing',
      position: 'bottom',
      adminOnly: true,
    },
    {
      label: 'Settings',
      icon: 'Settings',
      href: '/settings',
      position: 'bottom',
    },
  ]
}

interface MenuOptionProps extends MenuOption {
  isCollapsed?: boolean
}

const MENU_STORAGE_KEY = {
  collapsed: 'menu-collapsed',
  performanceReview: 'menu-performance-review',
  company: 'menu-company',
} as const

const MenuOptionItem = ({
  label,
  href,
  icon,
  disabled,
  hidden,
  submenu,
  onClick,
  adminOnly,
  badge,
  isCollapsed,
}: MenuOptionProps) => {
  const { pathname } = useLocation()
  const [performanceReviewExpanded, setPerformanceReviewExpanded] =
    useLocalStorage(MENU_STORAGE_KEY.performanceReview, true)
  const [companyExpanded, setCompanyExpanded] = useLocalStorage(
    MENU_STORAGE_KEY.company,
    true,
  )

  const isOpen =
    label === 'Performance Reviews'
      ? performanceReviewExpanded
      : label === 'Company'
        ? companyExpanded
        : false

  const { membership } = useOrganization()

  const isAdmin = membership?.role === 'org:admin'

  if ((adminOnly && !isAdmin) || hidden) {
    return null
  }

  const isActive =
    pathname === href ||
    (submenu && submenu.some((item) => pathname === item.href))

  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      e.preventDefault()
      onClick()
    }
    if (submenu) {
      if (label === 'Performance Reviews') {
        setPerformanceReviewExpanded(!performanceReviewExpanded)
      } else if (label === 'Company') {
        setCompanyExpanded(!companyExpanded)
      }
    }
  }

  return (
    <div>
      <Link
        to={!disabled && !submenu ? href : '#'}
        className={cn(
          'flex items-center gap-2.5 rounded-md py-2 px-3 text-muted-foreground transition-all hover:bg-accent hover:text-accent-foreground text-sm',
          isActive &&
            !submenu &&
            'text-white bg-green-600 hover:bg-green-600/90 hover:text-white',
          disabled && 'opacity-50 hover:opacity-50 cursor-not-allowed',
          submenu && 'cursor-pointer',
          submenu && isActive && 'text-green-600',
          isCollapsed && 'px-2 w-10 h-10 justify-center',
        )}
        onClick={handleClick}
        title={isCollapsed ? label : undefined}
      >
        <Icon name={icon} className="h-4 w-4" />
        {!isCollapsed && (
          <>
            <span>{label}</span>
            {badge && (
              <Badge
                variant="outline"
                className="ml-auto text-[10px] px-1.5 py-0 h-4 rounded-full text-muted-foreground/70 border-muted-foreground/20"
              >
                {badge.text}
              </Badge>
            )}
            {submenu && (
              <Icon
                name={isOpen ? 'ChevronUp' : 'ChevronDown'}
                className={cn('h-4 w-4', badge ? 'ml-2' : 'ml-auto')}
              />
            )}
          </>
        )}
      </Link>
      {submenu && isOpen && (
        <div className={cn('mt-0.5 space-y-0.5', !isCollapsed && 'ml-4')}>
          {submenu.map((subItem) => (
            <MenuOptionItem
              key={subItem.label}
              {...subItem}
              isCollapsed={isCollapsed}
            />
          ))}
        </div>
      )}
    </div>
  )
}

const LogoutButton = ({ isCollapsed }: { isCollapsed?: boolean }) => {
  const { signOut } = useAuth()

  return (
    <div
      className={cn('flex items-center gap-2', isCollapsed && 'flex-col mt-2')}
    >
      <UserButton afterSignOutUrl="/sign-in" />
      {!isCollapsed && (
        <Button
          variant="link"
          className="flex gap-2"
          onClick={() => signOut({ redirectUrl: '/sign-in' })}
        >
          <Icon name="LogOut" />
          Log Out
        </Button>
      )}
    </div>
  )
}

const ChangelogButton = ({ isCollapsed }: { isCollapsed?: boolean }) => (
  <div
    data-canny-changelog
    className={cn(
      'flex items-center gap-2.5 rounded-md py-2 px-3 text-muted-foreground transition-all hover:bg-accent hover:text-accent-foreground cursor-pointer text-sm',
      isCollapsed && 'px-2 w-10 h-10 justify-center mx-auto',
    )}
    title={isCollapsed ? 'Changelog' : undefined}
  >
    <Icon name="Inbox" className="h-4 w-4" />
    {!isCollapsed && <span>Changelog</span>}
  </div>
)

const CannyInit = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.Canny) {
      window.Canny('initChangelog', {
        appID: '66ad67a82ec2f62f925c7e66',
        position: 'top',
        align: 'left',
        theme: 'light',
      } as CannyOptions)
    }
  }, [])

  return null
}

export const MenuDesktop = () => {
  const { isAdmin } = useUserRole()
  const isOnboardingComplete = useOnboardingStatus(isAdmin)
  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    MENU_STORAGE_KEY.collapsed,
    false,
  )
  const { data: teamMembersUnderUser = [], isError } = useFetchTeamMembers({
    teamScope: 'underUser',
  })

  const menuOptions = getMenuOptions(
    isError ? [] : teamMembersUnderUser,
    isAdmin,
    isOnboardingComplete,
  )
  const mainMenuItems = menuOptions.filter((item) => item.position === 'main')
  const bottomMenuItems = menuOptions.filter(
    (item) => item.position === 'bottom',
  )

  return (
    <div
      className={cn(
        'hidden border-r bg-background md:block relative transition-[width,padding] duration-300 h-full',
        isCollapsed ? 'w-[80px]' : 'w-[280px]',
      )}
    >
      <CannyInit />
      <div className="flex h-full max-h-screen flex-col">
        <div className="flex h-16 items-center border-b px-6 overflow-hidden">
          <LogoLink
            size="normal"
            hideText={isCollapsed}
            className={cn(
              'transition-all duration-300',
              isCollapsed && 'justify-center w-full px-0',
            )}
          />
          <Button
            variant="ghost"
            size="icon"
            className={cn(
              'h-8 w-8 rounded-full hover:bg-accent absolute',
              'right-[-16px] top-[22px]',
              'border bg-background shadow-sm',
            )}
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            <Icon
              name={isCollapsed ? 'ChevronRight' : 'ChevronLeft'}
              className="h-4 w-4"
            />
          </Button>
        </div>
        <div className="flex-1 flex flex-col justify-between overflow-auto">
          <nav
            className={cn(
              'flex flex-col px-4 py-2 text-sm space-y-0.5 transition-all duration-300',
              isCollapsed && 'items-center px-2',
            )}
          >
            {mainMenuItems.map((option) => (
              <MenuOptionItem
                key={option.label}
                {...option}
                isCollapsed={isCollapsed}
              />
            ))}
          </nav>
          <div
            className={cn(
              'px-4 py-2 pb-4 mt-auto space-y-0.5 transition-all duration-300',
              isCollapsed && 'px-2 items-center flex flex-col',
            )}
          >
            <ChangelogButton isCollapsed={isCollapsed} />
            {bottomMenuItems
              .filter((item) => item.label !== 'Changelog')
              .map((option) => (
                <MenuOptionItem
                  key={option.label}
                  {...option}
                  isCollapsed={isCollapsed}
                />
              ))}
            {!isCollapsed && <CustomOrganizationSwitcher />}
            <LogoutButton isCollapsed={isCollapsed} />
          </div>
        </div>
      </div>
    </div>
  )
}

export const MenuMobile = () => {
  const { isAdmin } = useUserRole()
  const isOnboardingComplete = useOnboardingStatus(isAdmin)
  const { data: teamMembersUnderUser = [], isError } = useFetchTeamMembers({
    teamScope: 'underUser',
  })

  const menuOptions = getMenuOptions(
    isError ? [] : teamMembersUnderUser,
    isAdmin,
    isOnboardingComplete,
  )
  const mainMenuItems = menuOptions.filter((item) => item.position === 'main')
  const bottomMenuItems = menuOptions.filter(
    (item) => item.position === 'bottom',
  )

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="shrink-0 md:hidden text-green-600"
        >
          <Icon name="Menu" className="h-5 w-5" />
        </Button>
      </SheetTrigger>
      <SheetContent
        side="left"
        className="flex flex-col p-0 w-[280px] sm:w-[300px]"
      >
        <div className="flex h-16 items-center border-b px-6">
          <LogoLink className="flex-1" />
        </div>
        <div className="flex-1 overflow-y-auto">
          <nav className="px-3 py-1 space-y-0.5">
            {mainMenuItems.map((option) => (
              <MenuOptionItem key={option.label} {...option} />
            ))}
          </nav>
        </div>
        <div className="border-t px-3 py-1 pb-2 space-y-0.5">
          <ChangelogButton />
          {bottomMenuItems
            .filter((item) => item.label !== 'Changelog')
            .map((option) => (
              <MenuOptionItem key={option.label} {...option} />
            ))}
          <CustomOrganizationSwitcher />
          <LogoutButton />
        </div>
      </SheetContent>
    </Sheet>
  )
}
