import { useOrganization, useUser } from '@clerk/clerk-react'
import PurchaseLicensesCard from '@/components/licensing/purchase-licenses-card'
import ManageLicensesCard from '@/components/licensing/manage-license-cards'
import { useFetchLicenseInfo } from '@/services/api/billing.api'
import { LayoutTile } from '@/components/layout'
import { H4, P, Muted } from '@/components/ui/typography'
import { Loading } from '@/components/ui/loading'
import { MembershipRow } from '@/components/licensing/user-row'
import { useFetchOrganizationMemberships } from '@/services/api/organization.api'
import { useUserRole } from '@/hooks/useUserRole'

export default function LicensingPage() {
  const { organization, membership } = useOrganization()
  const { user } = useUser()
  const { isAdmin } = useUserRole()
  const { data: memberships = [] } = useFetchOrganizationMemberships()
  const { data: licenseInfo, isLoading, error } = useFetchLicenseInfo()

  if (!organization || !user || !membership) {
    return (
      <LayoutTile>
        <div className="flex-1 w-full justify-center items-center flex">
          <Loading />
        </div>
      </LayoutTile>
    )
  }

  if (!isAdmin) {
    return (
      <LayoutTile>
        <div className="flex-1 w-full justify-center items-center flex">
          <Muted>You don&apos;t have permission to access this page.</Muted>
        </div>
      </LayoutTile>
    )
  }

  return (
    <LayoutTile>
      <div className="sm:max-w-full mx-auto">
        <H4 className="font-semibold">License Management</H4>
        <P className="text-muted-foreground mt-2">
          Follow these steps to set up licenses for your team:
        </P>
        <ol className="list-decimal ml-6 mt-2 space-y-1 text-sm text-muted-foreground">
          <li>
            <P className="text-muted-foreground">
              Purchase the required number of licenses for your organization
            </P>
          </li>
          <li>
            <P className="text-muted-foreground">
              Invite team members by clicking your organization name at the
              bottom of the left sidebar, selecting &quot;Manage&quot;, and
              using the &quot;Invite&quot; button under the Members tab
            </P>
          </li>
          <li>
            <P className="text-muted-foreground">
              Assign licenses to users using the toggles below
            </P>
          </li>
        </ol>
      </div>

      {isLoading ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Loading />
        </div>
      ) : error ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Muted>Error loading license info: {(error as Error).message}</Muted>
        </div>
      ) : !licenseInfo ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Muted>No license info available</Muted>
        </div>
      ) : (
        <>
          <div className="mb-4 flex justify-center">
            {licenseInfo.totalLicenses === 0 ? (
              <PurchaseLicensesCard />
            ) : (
              <ManageLicensesCard
                licensedUsersCount={licenseInfo.usedLicenses}
                purchasedLicensesCount={licenseInfo.totalLicenses}
              />
            )}
          </div>

          {licenseInfo.totalLicenses > 0 && (
            <div className="overflow-x-auto -mx-2 sm:mx-0">
              <H4 className="mb-2">Organization Users</H4>
              <div className="min-w-full inline-block align-middle">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead>
                      <tr>
                        <th className="px-3 py-3 text-left sm:px-4">
                          <P className="font-medium">Name</P>
                        </th>
                        <th className="px-3 py-3 text-left hidden sm:table-cell">
                          <P className="font-medium">Email</P>
                        </th>
                        <th className="px-3 py-3 text-right sm:px-4">
                          <P className="font-medium">Licensed</P>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                      {memberships.map((m) => (
                        <MembershipRow
                          key={m.id}
                          membership={m}
                          disabled={
                            !m.isLicensed &&
                            licenseInfo.usedLicenses >=
                              licenseInfo.totalLicenses
                          }
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </LayoutTile>
  )
}
