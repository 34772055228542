import { useEffect, useRef, useState } from 'react'
import { Printer, Star } from 'lucide-react'
import { useReactToPrint } from 'react-to-print'
import moment from 'moment'

import { AvatarWithFallback } from '@/components/ui/avatar'
import { P, Muted, H3, H5 } from '@/components/ui/typography'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog'
import { Progress } from '@/components/ui/progress'
import { Select } from '@/components/common/select'

import { getUserDisplayName } from '@/services/utils/formatters'
import { useFetchFeedbackSession } from '@/services/api/feedbackSessions.api'

import { cn } from '@/lib/utils'

import { FeedbackSessionOverview } from '@/types/FeedbackSessions'
import { UserProfile } from '@/types/UserProfile'
import { Label } from '@/components/ui/label'
import { Checkbox } from '@/components/ui/checkbox'

const MOMENT_DATE_FORMAT = 'MMM D, YYYY'

const hideUserReferences = (text: string) => {
  // Hide #U[Name] format
  text = text.replace(/#U\[([^\]]+)\]/g, '')

  // Hide #U{Name} format (if it occurs)
  text = text.replace(/#U{([^}]+)}/g, '')

  // Hide simple #U[Name format (if unclosed)
  text = text.replace(/#U\[[^\]]*$/g, '')

  // Hide #U{Name format (if unclosed)
  text = text.replace(/#U{[^}]*$/g, '')

  // Hide #U[Name] format without brackets
  text = text.replace(/#U[a-zA-Z\s]+/g, '')

  // Clean up any leftover #U markers
  text = text.replace(/#U/g, '')

  // Clean up double spaces that might result from removing names
  text = text.replace(/\s+/g, ' ').trim()

  return text
}

interface FeedbackSessionModalContentProps {
  user: UserProfile
  dateRange: FeedbackSessionOverview['dateRange']
  canIncludeAll: boolean
  isOpen: boolean
  includePrint?: boolean
}

export const FeedbackSessionModalContent = ({
  user,
  dateRange,
  canIncludeAll,
  isOpen,
  includePrint,
}: FeedbackSessionModalContentProps) => {
  const [hideNames, setHideNames] = useState(false)
  const [includeFilter, setIncludeFilter] = useState<'all' | 'mine'>(
    canIncludeAll ? 'all' : 'mine',
  )
  const [progressValue, setProgressValue] = useState(0)
  const {
    data: feedbackSessionContent,
    isLoading: isLoadingFeedbackSessionContent,
  } = useFetchFeedbackSession(
    user.id,
    {
      from: moment(dateRange.from).valueOf(),
      to: moment(dateRange.to).valueOf(),
    },
    includeFilter === 'all' ? true : false,
    { enabled: isOpen },
  )
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen) {
      setProgressValue(0)
    }

    const interval = setInterval(() => {
      setProgressValue((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval)
          return 100
        }
        return prevProgress + 1 / 3
      })
    }, 50)

    if (!isOpen) {
      clearInterval(interval)
    }
  }, [isOpen, includeFilter])

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    documentTitle: `Feedback Report for ${getUserDisplayName(user)}`,
    pageStyle: `
      @media print {
        @page {
          margin: 20mm;
        }
      }
    `,
  })

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <AvatarWithFallback
              className="w-12 h-12"
              image={user.image}
              fallback={`${user.firstName[0]}${user.lastName[0]}`}
            />
            <div>
              <P className="font-semibold">{`${user.firstName} ${user.lastName}`}</P>
              <Muted>
                {dateRange?.from && dateRange.to
                  ? `${moment(dateRange.from).format(MOMENT_DATE_FORMAT)} - ${moment(dateRange.to).format(MOMENT_DATE_FORMAT)}`
                  : 'Date range not selected'}
              </Muted>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end gap-4">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="hide-names"
              checked={hideNames}
              onCheckedChange={(val: boolean) => setHideNames(val)}
            />
            <Label
              htmlFor="hide-names"
              className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-normal"
            >
              Hide names
            </Label>
          </div>
          <div className="flex items-center gap-4">
            <Select
              options={[
                { label: 'Include all feedback', value: 'all' },
                { label: 'My feedback only', value: 'mine' },
              ]}
              value={includeFilter}
              onValueChange={setIncludeFilter}
              disabled={!canIncludeAll}
            />
            {!!includePrint &&
              !isLoadingFeedbackSessionContent &&
              feedbackSessionContent && (
                <Button onClick={handlePrint} variant="outline" size="sm">
                  <Printer className="w-4 h-4 mr-2" />
                  Print
                </Button>
              )}
          </div>
        </div>
      </div>
      {isLoadingFeedbackSessionContent ? (
        <div className="flex items-center justify-center w-full h-[300px]">
          <div className="flex flex-col gap-2 items-center justify-center w-full max-w-[200px]">
            <Progress
              value={progressValue}
              classNameIndicator={
                progressValue === 100 ? 'bg-yellow-500' : undefined
              }
            />
            <P className="text-muted">
              {progressValue < 100
                ? 'Generating...'
                : 'Taking longer than expected...'}
            </P>
          </div>
        </div>
      ) : !feedbackSessionContent ? (
        <div className="flex items-center justify-center w-full h-[300px]">
          <P className="text-muted">No Feedback Found</P>
        </div>
      ) : (
        <div ref={contentRef} className="flex flex-col gap-6 overflow-y-auto">
          <div>
            <H3>Highlights</H3>
            <ul className="mt-2 space-y-2 text-sm text-muted-foreground list-disc list-inside">
              {feedbackSessionContent.highlights.map((highlight, index) => (
                <li key={`highlight-${index}`}>
                  {hideNames
                    ? hideUserReferences(highlight)
                    : highlight.replace(/#U/g, '')}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <H3>Lowlights</H3>
            <ul className="mt-2 space-y-2 text-sm text-muted-foreground list-disc list-inside">
              {feedbackSessionContent.lowlights.map((lowlight, index) => (
                <li key={`lowlight-${index}`}>
                  {hideNames
                    ? hideUserReferences(lowlight)
                    : lowlight.replace(/#U/g, '')}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <H3>Value Alignment</H3>
            <div className="grid grid-cols-2 gap-6 mt-4">
              {feedbackSessionContent.valueAlignment.map((value) => (
                <div key={value.name}>
                  <H5>{value.name}</H5>
                  <div className="flex items-center gap-2 mt-2">
                    <div className="flex gap-1">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <Star
                          key={`${value.name}-start-${star}`}
                          className={cn(
                            'w-5 h-5',
                            star <= value.score
                              ? 'fill-primary text-primary'
                              : 'fill-muted text-muted-foreground opacity-30',
                          )}
                        />
                      ))}
                    </div>
                    <span className="text-sm text-muted-foreground">
                      {value.score.toFixed(1)}
                    </span>
                  </div>
                  <div className="mt-2 text-sm text-muted-foreground">
                    {hideNames
                      ? hideUserReferences(value.comment)
                      : value.comment}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div>
            <H3>Approach for Feedback Conversation</H3>
            <div className="mt-2 space-y-4">
              <ul className="mt-2 space-y-2 text-sm text-muted-foreground list-disc list-inside">
                {feedbackSessionContent.approachForFeedback.map(
                  (approach, index) => (
                    <li key={`approach-${index}`}>
                      {hideNames ? hideUserReferences(approach) : approach}
                    </li>
                  ),
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

interface FeedbackSessionModalProps {
  isOpen: boolean
  setIsOpen: (_isOpen: boolean) => void
  feedbackSessionOverview: FeedbackSessionOverview
  title: string
}

export const FeedbackSessionModal = ({
  isOpen,
  setIsOpen,
  feedbackSessionOverview,
  title,
}: FeedbackSessionModalProps) => {
  const contentRef = useRef<HTMLDivElement>(null)

  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
    documentTitle: `Feedback Report for ${getUserDisplayName(feedbackSessionOverview.user)}`,
    pageStyle: `
      @media print {
        @page {
          margin: 20mm;
        }
      }
    `,
  })

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent
        className="sm:max-w-[725px] max-h-[80vh]"
        noInnerScroll
        onPrint={handlePrint}
        showBottomCloseButton
        customCloseButton={
          <Button className="w-full" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        }
      >
        <DialogTitle className="sr-only">{title}</DialogTitle>
        <div ref={contentRef}>
          <FeedbackSessionModalContent
            user={feedbackSessionOverview.user}
            dateRange={feedbackSessionOverview.dateRange}
            canIncludeAll={feedbackSessionOverview.canIncludeAll}
            isOpen={isOpen}
          />
        </div>
      </DialogContent>
    </Dialog>
  )
}
