import moment from 'moment'

const MINUS_WEEKS = 10

let startDate = moment()
  .add({ weeks: -MINUS_WEEKS + 1 })
  .startOf('week')
const endDate = moment(new Date()).startOf('week')

const pastWeekIntervals: string[] = []
while (startDate <= endDate) {
  pastWeekIntervals.push(startDate.format('YYYY-MM-DD'))
  startDate = moment(startDate).add(7, 'days')
}

export default pastWeekIntervals
