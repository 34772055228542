import { ErrorTile } from '@/components/common/error-tile'
import { Loading } from '@/components/ui/loading'
import { OrgChart } from '@/components/org-chart'

import { useFetchOrganizationMemberships } from '@/services/api/organization.api'

export const OrganizationChartPage = () => {
  const {
    data: memberships,
    isLoading,
    error,
  } = useFetchOrganizationMemberships()

  if (error) return <ErrorTile message={error.message} />

  return (
    <div className="flex-1">
      {isLoading || !memberships ? (
        <Loading containerClassName="w-full h-full flex justify-center items-center" />
      ) : (
        <OrgChart orgChart={memberships} />
      )}
    </div>
  )
}
