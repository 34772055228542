import React, { useEffect, useState, useCallback } from 'react'
import { useAuth, useUser } from '@clerk/clerk-react'
import { TAWK_PROPERTY_ID, TAWK_WIDGET_ID } from '@/services/config/env'
import type { TawkMessengerProps, TawkAPI } from '@/types/Tawk'
import { useTawk } from '@/hooks/useTawk'

export function TawkIntegration() {
  const { isLoaded, isSignedIn } = useAuth()
  const { user } = useUser()
  const [TawkMessenger, setTawkMessenger] =
    useState<React.ComponentType<TawkMessengerProps> | null>(null)
  const { setAttributes } = useTawk()

  useEffect(() => {
    const loadTawkMessenger = async () => {
      try {
        const TawkMessengerReact = (
          await import('@tawk.to/tawk-messenger-react')
        ).default
        setTawkMessenger(() => TawkMessengerReact)
      } catch (error) {
        console.error('Failed to load Tawk Messenger:', error)
      }
    }

    loadTawkMessenger()
  }, [])

  const updateTawkAttributes = useCallback(() => {
    if (isLoaded && isSignedIn && user) {
      const waitForTawk = setInterval(() => {
        const tawkApi = window.Tawk_API as TawkAPI
        if (tawkApi?.$socket?.readyState === 1) {
          clearInterval(waitForTawk)
          setAttributes({
            name: user.fullName || '',
            email: user.primaryEmailAddress?.emailAddress || '',
            userId: user.id || '',
          }).catch((error) => {
            console.error('Failed to set Tawk attributes:', error)
          })
        }
      }, 100)

      setTimeout(() => clearInterval(waitForTawk), 10000)
    }
  }, [isLoaded, isSignedIn, user, setAttributes])

  useEffect(() => {
    updateTawkAttributes()
  }, [updateTawkAttributes])

  if (!TawkMessenger) {
    return null
  }

  return (
    <TawkMessenger
      propertyId={TAWK_PROPERTY_ID}
      widgetId={TAWK_WIDGET_ID}
      onLoad={updateTawkAttributes}
    />
  )
}
