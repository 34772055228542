import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { H4, P } from '@/components/ui/typography'

interface ValueCardProps {
  label: string
  description: string
  is?: string
  isNot?: string
}

export const ValueCard = ({
  label,
  description,
  is,
  isNot,
}: ValueCardProps) => {
  return (
    <Card className="flex flex-col">
      <CardHeader>
        <H4 className="-mb-4 font-bold">{label}</H4>
      </CardHeader>
      <CardContent className="flex-grow flex flex-col justify-between">
        <P className="mb-6">{description}</P>
        <div>
          {is && (
            <>
              <P className="font-semibold text-green-600 dark:text-green-400 mb-2">
                This value is:
              </P>
              <P className="text-sm mb-4 text-muted-foreground">{is}</P>
            </>
          )}
          {isNot && (
            <>
              <P className="font-semibold text-red-600 dark:text-red-400 mb-2">
                This value is not:
              </P>
              <P className="text-sm text-muted-foreground">{isNot}</P>
            </>
          )}
        </div>
      </CardContent>
    </Card>
  )
}
