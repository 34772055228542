import { useMemo, useState } from 'react'
import moment from 'moment'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { useUserRole } from '@/hooks/useUserRole'

import { Muted } from '@/components/ui/typography'
import { ExternalLink } from 'lucide-react'
import { Loading } from '@/components/ui/loading'
import { Select } from '@/components/common/select'
import NineBoxGrid from '@/components/ninebox-grid'
import { TeamReviewOverviewItem } from '@/components/team-review-overview-item'
import { TeamReviewEditModal } from '@/components/team-review-edit-modal'
import { ErrorTile } from '@/components/common/error-tile'
import { LayoutTile } from '@/components/layout'
import { Checkbox } from '@/components/ui/checkbox'
import { LayoutHeader } from '@/components/common/layout-header'

import { useFetchTeamMemberPerformanceReviews } from '@/services/api/performanceReview.api'

import {
  Quarter,
  getQuarterFromDate,
  parseQuarterString,
} from '@/services/utils/dates'
import {
  calculateObjectivesGrade,
  calculateValuesGrade,
} from '@/services/utils/performance-reviews'

import {
  TeamMemberPerformanceReview,
  StoredReviewVisibilityState,
} from '@/types/PerformanceReview'

interface StoredTeamReviewState {
  quarter: {
    label: string
    value: Quarter
  }
  employeeScope: 'directReports' | 'allUnderMe' | 'all'
  showNineBox: boolean
}

export const MyTeamReviewsPage = () => {
  const { isAdmin } = useUserRole()

  const defaultState: StoredTeamReviewState = {
    quarter: getQuarterFromDate(moment().add({ quarter: -1 }).format()),
    employeeScope: 'directReports',
    showNineBox: true,
  }

  const [storedState, setStoredState] = useLocalStorage<StoredTeamReviewState>(
    'team-reviews.page-state-v0.1',
    defaultState,
  )

  const [selectedTeamReview, setSelectedTeamReview] = useState<
    TeamMemberPerformanceReview | undefined
  >(undefined)

  const [hiddenStates, setHiddenStates] =
    useLocalStorage<StoredReviewVisibilityState>(
      'team-reviews.visibility-states',
      {},
    )

  const {
    data: teamReviews = [],
    isLoading,
    error,
  } = useFetchTeamMemberPerformanceReviews(
    storedState.quarter.value,
    storedState.employeeScope,
  )

  const scopeOptions = useMemo(() => {
    const options = [
      { value: 'directReports', label: 'Only direct reports' },
      { value: 'allUnderMe', label: 'All employees under me' },
    ]

    if (isAdmin) {
      options.push({ value: 'all', label: 'All employees' })
    }

    return options
  }, [isAdmin])

  const handleHideClick = (userId: string, hidden: boolean) => {
    setHiddenStates({
      ...hiddenStates,
      [userId]: hidden,
    })
  }

  const nineBoxUsers = useMemo(() => {
    return teamReviews
      .filter((tr: TeamMemberPerformanceReview) => {
        const isHidden =
          tr.user.id in hiddenStates
            ? hiddenStates[tr.user.id]
            : tr.review?.hidden
        return !isHidden
      })
      .map((tr: TeamMemberPerformanceReview) => ({
        ...tr.user,
        objectivesScore: calculateObjectivesGrade(tr.review?.objectives ?? []),
        valuesScore: calculateValuesGrade(tr.review?.values ?? []),
      }))
  }, [teamReviews, hiddenStates])

  if (error) {
    return <ErrorTile />
  }

  return (
    <LayoutTile>
      <LayoutHeader
        title="Your Team's Reviews"
        description={
          <>
            View and manage performance reviews for your team members.{' '}
            <a
              href="https://youtu.be/yyPvyqrSdpo"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline inline-flex items-center"
            >
              Watch our tutorial
              <ExternalLink size={16} className="ml-1" />
            </a>
          </>
        }
        rightSide={
          <div className="flex items-center space-x-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="show-nine-box"
                checked={storedState.showNineBox}
                onCheckedChange={(checked: boolean) =>
                  setStoredState({ ...storedState, showNineBox: checked })
                }
              />
              <label
                htmlFor="show-nine-box"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Show 9-box
              </label>
            </div>
            <Select
              options={scopeOptions}
              value={storedState.employeeScope}
              onValueChange={(
                value: 'directReports' | 'allUnderMe' | 'all',
              ) => {
                const newValue =
                  !isAdmin && value === 'all' ? 'directReports' : value
                setStoredState({ ...storedState, employeeScope: newValue })
              }}
            />
            <Select
              className="w-[130px]"
              options={[
                { value: '2024 Q1', label: '2024 Q1' },
                { value: '2024 Q2', label: '2024 Q2' },
                { value: '2024 Q3', label: '2024 Q3' },
                { value: '2024 Q4', label: '2024 Q4' },
              ]}
              value={storedState.quarter.label}
              onValueChange={(value) =>
                setStoredState({
                  ...storedState,
                  quarter: parseQuarterString(value),
                })
              }
            />
          </div>
        }
      />
      {isLoading ? (
        <div className="flex-1 w-full justify-center items-center flex">
          <Loading />
        </div>
      ) : (
        <div>
          {storedState.showNineBox && (
            <div className="w-full max-w-3xl mx-auto">
              <NineBoxGrid users={nineBoxUsers} hiddenStates={hiddenStates} />
            </div>
          )}
          <div className="mt-4 flex flex-col gap-4 team-reviews-list">
            {teamReviews.length === 0 ? (
              <Muted className="my-16">
                No Team Reviews Available for this Quarter
              </Muted>
            ) : (
              teamReviews.map((review: TeamMemberPerformanceReview) => (
                <TeamReviewOverviewItem
                  key={`team-review-${review.user.id}`}
                  data={review}
                  handleEditClick={() => setSelectedTeamReview(review)}
                  selectedQuarter={storedState.quarter.value}
                  isHidden={
                    !!(review.user.id in hiddenStates
                      ? hiddenStates[review.user.id]
                      : review.review?.hidden)
                  }
                  onHideClick={(hidden) =>
                    handleHideClick(review.user.id, hidden)
                  }
                />
              ))
            )}
            {!!selectedTeamReview && (
              <TeamReviewEditModal
                selectedReview={selectedTeamReview}
                setSelectedReview={setSelectedTeamReview}
                selectedQuarter={storedState.quarter.value}
              />
            )}
          </div>
        </div>
      )}
    </LayoutTile>
  )
}
