import React from 'react'
import {
  Tooltip as TooltipPrimitive,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { Icon, IconName } from '@/components/ui/icon'

type TooltipType = 'info' | 'warning' | 'error'

const tooltipIconMap: Record<TooltipType, IconName> = {
  info: 'Info',
  warning: 'TriangleAlert',
  error: 'CircleAlert',
} as const

interface CustomTooltipProps {
  content: string
  type?: TooltipType
  children?: React.ReactNode
}

export const TooltipWithIcon: React.FC<CustomTooltipProps> = ({
  content,
  type = 'info',
  children,
}) => {
  const iconName = tooltipIconMap[type]

  const iconColor = {
    info: 'text-muted-foreground',
    warning: 'text-yellow-500',
    error: 'text-red-500',
  }[type]

  const tooltipTrigger = children || (
    <Icon name={iconName} className={iconColor} />
  )

  return <Tooltip content={content}>{tooltipTrigger}</Tooltip>
}

export const Tooltip: React.FC<CustomTooltipProps> = ({
  content,
  children,
}) => (
  <TooltipProvider delayDuration={0}>
    <TooltipPrimitive>
      <TooltipTrigger asChild>
        <div tabIndex={-1} aria-hidden="true">
          {children}
        </div>
      </TooltipTrigger>
      <TooltipContent className="bg-white text-black border border-black rounded max-w-[300px] text-wrap">
        <p>{content}</p>
      </TooltipContent>
    </TooltipPrimitive>
  </TooltipProvider>
)
