import React from 'react'
import { Skeleton } from '@/components/ui/skeleton'
import { cn } from '@/lib/utils'

interface TileProps {
  children: React.ReactNode[] | React.ReactNode
  className?: React.HTMLProps<HTMLElement>['className']
  isLoading?: boolean
  onClick?: () => void
}

export const Tile: React.FC<TileProps> = ({
  children,
  className,
  isLoading,
  onClick,
}) => {
  const tileClass = cn(
    'flex flex-col gap-2 py-4 px-6 bg-white rounded-2xl',
    onClick && 'cursor-pointer hover:bg-gray-100 transition-colors',
    className,
  )

  return (
    <div className={tileClass} onClick={onClick}>
      {isLoading ? (
        <Skeleton className="h-full w-full rounded-2xl" />
      ) : (
        children
      )}
    </div>
  )
}
