import { Dialog, DialogContent } from '@/components/ui/dialog'

import { cn } from '@/lib/utils'

import { ClassName, SetState } from '@/types/globals'

interface ModalProps {
  isOpen: boolean
  setIsOpen: SetState<boolean>
  children: JSX.Element
  className?: ClassName
}

export const Modal = ({
  isOpen,
  setIsOpen,
  children,
  className,
}: ModalProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent
        className={cn(
          'sm:max-w-[725px] max-h-[80vh] overflow-hidden flex flex-col',
          className,
        )}
      >
        {children}
      </DialogContent>
    </Dialog>
  )
}
