import {
  Bar,
  BarChart as RechartsBarChart,
  CartesianGrid,
  XAxis,
  Rectangle,
  LabelList,
} from 'recharts'
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '@/components/ui/chart'
import { cn } from '@/lib/utils'
import { ClassNameValue } from 'tailwind-merge'

export type BarChartProps = {
  data: unknown[]
  bars: string[]
  config: ChartConfig
  legend?: boolean
  className?: ClassNameValue
  activeIndex?: number
  activeColor?: string
  stacked?: boolean
}

export default function BarChart({
  data,
  bars,
  config,
  legend,
  className,
  activeIndex,
  activeColor = 'hsl(var(--chart-1))',
  stacked = false,
}: BarChartProps) {
  // Calculate total for each data point
  const dataWithTotal = data.map((item: unknown) => {
    const typedItem = item as Record<string, number | string>
    return {
      ...typedItem,
      total: bars.reduce((sum, bar) => sum + (Number(typedItem[bar]) || 0), 0),
    }
  })

  // Custom formatter function to hide zero values
  const labelFormatter = (value: number) =>
    value === 0 ? '' : value.toString()

  return (
    <ChartContainer
      config={config}
      className={cn('min-h-[200px] max-h-[250px] w-full m-auto', className)}
    >
      <RechartsBarChart
        accessibilityLayer
        data={dataWithTotal}
        margin={{
          top: 20,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="label"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value.slice(0, 3)}
        />
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent hideLabel />}
        />
        {!!legend && <ChartLegend content={<ChartLegendContent />} />}
        {bars.map((bar, index) => (
          <Bar
            key={bar}
            dataKey={bar}
            fill={`var(--color-${bar})`}
            radius={
              stacked
                ? index === 0
                  ? [0, 0, 8, 8] // bottom bar
                  : index === bars.length - 1
                    ? [8, 8, 0, 0] // top bar
                    : [0, 0, 0, 0] // middle bars
                : 8 // not stacked, use original radius
            }
            stackId={stacked ? 'stack' : undefined}
            activeIndex={activeIndex}
            activeBar={({ ...props }) => (
              <Rectangle {...props} fill={activeColor} />
            )}
          >
            {stacked && index === bars.length - 1 && (
              <LabelList
                dataKey="total"
                position="top"
                offset={12}
                fontSize={12}
                fill="#6f7eae"
                formatter={labelFormatter}
              />
            )}
          </Bar>
        ))}
      </RechartsBarChart>
    </ChartContainer>
  )
}
