import { useUser } from '@clerk/clerk-react'
import { MOCK_SERVER_ENABLED } from '@/services/config/env'

interface UseGoogleOAuthOptions {
  redirectUrl: string
}

export function useGoogleOAuth({ redirectUrl }: UseGoogleOAuthOptions) {
  const { user } = useUser()

  const connectGoogle = async () => {
    if (MOCK_SERVER_ENABLED) return
    try {
      if (!user) return
      const account = await user.createExternalAccount({
        strategy: 'oauth_google',
        redirectUrl,
        additionalScopes: [
          'https://www.googleapis.com/auth/calendar.events.readonly',
        ],
      })
      if (account.verification?.externalVerificationRedirectURL) {
        window.location.href =
          account.verification.externalVerificationRedirectURL.toString()
      }
    } catch (error) {
      console.error('Failed to create external account:', error)
    }
  }

  return { connectGoogle }
}
