import { MOCK_SERVER_ENABLED } from '@/services/config/env'
import { useAuth } from '@clerk/clerk-react'

export type Role = 'ADMIN' | 'MEMBER'

export const useUserRole = (): { userRole: Role; isAdmin: boolean } => {
  const { has } = useAuth()

  const userRole = has?.({ role: 'org:admin' }) ? 'ADMIN' : 'MEMBER'

  return { userRole, isAdmin: userRole === 'ADMIN' || MOCK_SERVER_ENABLED }
}
