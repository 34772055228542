import { client } from '@/services/api/client'

/**
 * This function is called whenever a Get query is perfomed
 * If a 4xx or 5xx is received an error is raised. We can catch
 * this error using React Query and display the backend error message.
 */
export const fetchResolve = async (
  path: string,
  getToken: () => Promise<string | null>,
) => {
  const token = await getToken()
  const res = await client.get(path, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return res.data
}

/**
 * This function is called whenever a Post query is perfomed
 * If a 4xx or 5xx is received an error is raised. We can catch
 * this error using React Query and display the backend error message.
 */
export async function postResolve(
  path: string,
  body: unknown,
  getToken: () => Promise<string | null>,
) {
  const token = await getToken()
  const res = await client.post(path, body, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return res.data
}

/**
 * This function is called whenever a Patch query is perfomed
 * If a 4xx or 5xx is received an error is raised. We can catch
 * this error using React Query and display the backend error message.
 */
export async function patchResolve(
  path: string,
  body: unknown,
  getToken: () => Promise<string | null>,
) {
  const token = await getToken()
  const res = await client.patch(path, body, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return res.data
}

/**
 * This function is called whenever a Delete query is perfomed
 * If a 4xx or 5xx is received an error is raised. We can catch
 * this error using React Query and display the backend error message.
 */
export async function deleteResolve(
  path: string,
  getToken: () => Promise<string | null>,
) {
  const token = await getToken()
  const res = await client.delete(path, {
    headers: { Authorization: `Bearer ${token}` },
  })
  return res.data
}

/**
 * Adds query parameters to a URL or API endpoint.
 */
export function addQueryParams(
  baseUrl: string,
  params?: Record<string, string | number | boolean | undefined>,
): string {
  // Create a URLSearchParams object to handle the query string
  const searchParams = new URLSearchParams()

  // If params are provided, add them to the searchParams
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        searchParams.append(key, value.toString())
      }
    })
  }

  // Get the query string
  const queryString = searchParams.toString()

  // If there's no query string, return the baseUrl as is
  if (!queryString) {
    return baseUrl
  }

  // If the baseUrl already has a query string, use '&' instead of '?'
  const separator = baseUrl.includes('?') ? '&' : '?'

  // Combine the baseUrl with the query string
  return `${baseUrl}${separator}${queryString}`
}
