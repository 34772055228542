import { LayoutTile } from '@/components/layout'
import { Loading } from '@/components/ui/loading'
import { ErrorTile } from '@/components/common/error-tile'
import { ValueCard } from '@/components/common/value-card'
import { LayoutHeader } from '@/components/common/layout-header'

import { useFetchCompanyValues } from '@/services/api/values.api'

export const CompanyValuesPage = () => {
  const { data: values = [], isLoading, error } = useFetchCompanyValues()

  if (error) return <ErrorTile />

  if (isLoading) {
    return (
      <LayoutTile>
        <div className="flex-1 flex items-center justify-center">
          <Loading />
        </div>
      </LayoutTile>
    )
  }

  return (
    <LayoutTile>
      <LayoutHeader
        title="Our Company Values"
        description="These core principles guide our decisions, shape our culture, and define who we are as a company."
      />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {values.map((value) => (
          <ValueCard
            key={value.id}
            label={value.label}
            description={value.description || 'Description coming soon...'}
            is={value.is}
            isNot={value.isNot}
          />
        ))}
      </div>
    </LayoutTile>
  )
}
