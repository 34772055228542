import { Link } from 'react-router-dom'

import LogoSvg from '/logo.svg'
import { cn } from '@/lib/utils'

interface LogoProps {
  size?: 'small' | 'normal' | 'big'
  hideText?: boolean
  className?: React.HTMLProps<HTMLElement>['className']
}

export const LogoLink = ({ hideText, size, className }: LogoProps) => {
  return (
    <Link to="/" className={cn('flex gap-2 items-center', className)}>
      <Logo size={size} />
      {!hideText && (
        <div>
          <span
            className={cn(
              'font-bold text-green-600',
              size === 'small' ? 'text-sm' : undefined,
              size === 'big' ? 'text-lg' : undefined,
            )}
          >
            Fid
          </span>
          <span className="text-slate-600">Forward</span>
        </div>
      )}
    </Link>
  )
}

export const Logo = ({ size, className }: LogoProps) => (
  <img
    src={LogoSvg}
    alt="Logo"
    className={cn(
      'w-10 h-10',
      size === 'small' ? 'w-8 h-8' : undefined,
      size === 'big' ? 'w-16 h-16' : undefined,
      className,
    )}
  />
)
