import { Toast } from '@/hooks/use-toast'

export const createServerErrorToast = (description: string): Toast => {
  return {
    variant: 'destructive',
    title: 'Uh oh! Something went wrong.',
    description,
  }
}

export const createSuccessToast = (description: string): Toast => {
  return {
    variant: 'default',
    description,
  }
}
