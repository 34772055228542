import { MutableRefObject, useState } from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community'

import {
  CellProps,
  TypeColumn,
  TypeComputedProps,
  TypeEditInfo,
} from '@inovua/reactdatagrid-community/types'

import '@inovua/reactdatagrid-community/index.css'
import '@inovua/reactdatagrid-community/theme/green-light.css'

// eslint-disable-next-line
export type ColumnDef = TypeColumn & { editorProps?: any }

interface DataTableProps<TData> {
  columns: ColumnDef[]
  data: TData[]
  onEditComplete?: (_editInfo: TypeEditInfo) => void
}

const getHeader = (_cellProps: CellProps, header: string) => {
  return <div className="text-green-600 font-medium">{header}</div>
}

export function DataTable<TData>({
  columns,
  data,
  onEditComplete,
}: DataTableProps<TData>) {
  const [gridRef, setGridRef] =
    useState<MutableRefObject<TypeComputedProps | null> | null>(null)

  const cellDOMProps = (cellProps: CellProps) => {
    return {
      onClick: () => {
        if (gridRef?.current?.startEdit && cellProps.id && cellProps.rowIndex) {
          gridRef.current.startEdit({
            columnId: cellProps.id as string,
            rowIndex: cellProps.rowIndex,
          })
        }
      },
    }
  }

  return (
    <>
      <style>
        {`
          .InovuaReactDataGrid .InovuaReactDataGrid__column-header__resize-wrapper {
            border: none !important;
          }
          .InovuaReactDataGrid .InovuaReactDataGrid__row--odd {
            background-color: rgba(0, 128, 0, 0.02) !important;
          }
        `}
      </style>
      <ReactDataGrid
        idProperty="id"
        onReady={setGridRef}
        columns={columns.map((c) => ({
          ...c,
          header: (cellProps: CellProps) => getHeader(cellProps, c.header),
          cellDOMProps,
        }))}
        dataSource={data}
        className="w-full h-full"
        theme="green-light"
        showCellBorders={false}
        showColumnMenuTool={false}
        showActiveRowIndicator={false}
        editable={true}
        style={{
          border: 'none',
        }}
        onEditComplete={onEditComplete}
      />
    </>
  )
}
