import { SignIn, useAuth } from '@clerk/clerk-react'
import { useNavigate } from 'react-router-dom'

export function SignInPage() {
  const { isSignedIn } = useAuth()
  const navigate = useNavigate()

  if (isSignedIn) {
    navigate('/')
    return
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <SignIn path="/sign-in" signUpUrl="/sign-up" />
    </div>
  )
}
