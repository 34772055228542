import { useCallback, useMemo, useState } from 'react'
import moment from 'moment'
import { ExternalLink } from 'lucide-react'

import { DataTable } from '@/components/ui/data-table'
import { Loading } from '@/components/ui/loading'
import { Button } from '@/components/ui/button'
import { NewObjectiveModal } from '@/components/objective-modal'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { LayoutTile } from '@/components/layout'
import { ErrorTile } from '@/components/common/error-tile'
import { LayoutHeader } from '@/components/common/layout-header'

import {
  useDeleteObjective,
  useFetchTeamObjectives,
  usePatchObjective,
  usePatchObjectiveValue,
} from '@/services/api/objective.api'

import { generateColumns, generateRows } from './objectives.config'
import {
  Quarter,
  getIntervalsFromQuarter,
  getQuarterFromDate,
  parseQuarterString,
} from '@/services/utils/dates'
import { TypeEditInfo } from '@inovua/reactdatagrid-community/types'
import { Objective } from '@/types/Objective'
import { UserProfile } from '@/types/UserProfile'
import { useLocalStorage } from '@/hooks/useLocalStorage'

interface StoredQuarterState {
  label: string
  value: Quarter
}

export const ObjectivesPage = () => {
  const defaultQuarterState: StoredQuarterState = getQuarterFromDate(
    moment().format(),
  )
  const [addNewFormOpen, setAddNewFormOpen] = useState(false)

  const [selectedQuarter, setSelectedQuarter] =
    useLocalStorage<StoredQuarterState>(
      'objectives.quarter-state',
      defaultQuarterState,
    )

  const quarterIntervals = useMemo(
    () => getIntervalsFromQuarter(selectedQuarter.value),
    [selectedQuarter],
  )

  const {
    data: objectives = [],
    isLoading: isLoadingObjectives,
    error,
  } = useFetchTeamObjectives(selectedQuarter.value)
  const { mutate: deleteObjective } = useDeleteObjective(selectedQuarter.value)
  const { mutate: patchObjective } = usePatchObjective(selectedQuarter.value)
  const { mutate: patchObjectiveValue } = usePatchObjectiveValue(
    selectedQuarter.value,
  )

  const handleNewObjective = () => {
    setAddNewFormOpen(true)
  }

  const handleDeleteClick = useCallback(
    (objective: Objective) => {
      deleteObjective(objective.id)
    },
    [deleteObjective],
  )

  const handleEdit = (editInfo: TypeEditInfo) => {
    const { rowId, columnId, value, data } = editInfo as TypeEditInfo & {
      data: Objective
    }

    if (data[columnId as keyof Objective] === value) {
      return
    }

    // For the weekly values, we need to apply some extra logic
    // in order to update the record correctly
    if (columnId.includes('week')) {
      const weekNumber = Number(columnId.split('-')?.[1])
      const newObjectiveItem = {
        ...data.values.find((item) => weekNumber === item.weekNumber)!,
        value,
      }

      return patchObjectiveValue({
        teamObjectiveId: rowId,
        body: newObjectiveItem,
      })
    }

    patchObjective({
      id: rowId,
      body: {
        [columnId as keyof Objective]: value,
      },
    })
  }

  const handlePatchOwners = useCallback(
    (id: string, newOwners: UserProfile[]) => {
      patchObjective({
        id,
        body: {
          owners: newOwners,
        },
      })
    },
    [patchObjective],
  )

  const columns = useMemo(
    () =>
      generateColumns(quarterIntervals, handleDeleteClick, handlePatchOwners),
    [quarterIntervals, handleDeleteClick, handlePatchOwners],
  )
  const rows = useMemo(() => generateRows(objectives), [objectives])

  if (error) return <ErrorTile message={error.message} />

  return (
    <LayoutTile>
      <LayoutHeader
        title="Objectives"
        description={
          <>
            Track and update your (or your team&apos;s) objectives.{' '}
            <a
              href="https://youtu.be/FtZNA67-A1o"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline inline-flex items-center"
            >
              Watch our tutorial
              <ExternalLink size={16} className="ml-1" />
            </a>
            <br />
            In case you prefer, send us your list of objectives in CSV/Excel
            format to{' '}
            <a
              href="mailto:hey@fidforward.com"
              className="text-primary hover:underline"
            >
              hey@fidforward.com
            </a>
            .
          </>
        }
        rightSide={
          <Select
            value={selectedQuarter.label}
            onValueChange={(value) => {
              setSelectedQuarter(parseQuarterString(value))
            }}
          >
            <SelectTrigger className="w-[130px]">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="2024 Q1">2024 Q1</SelectItem>
              <SelectItem value="2024 Q2">2024 Q2</SelectItem>
              <SelectItem value="2024 Q3">2024 Q3</SelectItem>
              <SelectItem value="2024 Q4">2024 Q4</SelectItem>
            </SelectContent>
          </Select>
        }
      />
      <Button onClick={handleNewObjective} className="w-fit">
        Add New Objective
      </Button>
      {isLoadingObjectives ? (
        <Loading containerClassName="w-full h-full flex justify-center items-center" />
      ) : (
        <DataTable columns={columns} data={rows} onEditComplete={handleEdit} />
      )}
      <NewObjectiveModal
        isOpen={addNewFormOpen}
        setIsOpen={setAddNewFormOpen}
        intervals={quarterIntervals}
        selectedQuarter={selectedQuarter.value}
      />
    </LayoutTile>
  )
}
