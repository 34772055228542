import {
  PerformanceReviewObjective,
  PerformanceReviewValue,
} from '@/types/PerformanceReview'

export const calculateObjectivesGrade = (
  objectives: PerformanceReviewObjective[],
) => {
  const average = calculateAverageObjectives(objectives)

  if (average < 60) return 1
  if (average < 90) return 2
  return 3
}

export const calculateValuesGrade = (values: PerformanceReviewValue[]) => {
  const average = calculateAverageValues(values)

  if (average < 3) return 1
  if (average < 4) return 2
  return 3
}

export const calculateAverageObjectives = (
  objectives: PerformanceReviewObjective[],
): number => {
  if (!objectives || objectives.length === 0) return 0
  const total = objectives.reduce((sum, obj) => sum + obj.completion, 0)
  return total / objectives.length
}

export const calculateAverageValues = (
  values: PerformanceReviewValue[],
): number => {
  if (!values || values.length === 0) return 0
  const total = values.reduce((sum, val) => sum + val.rating, 0)
  return total / values.length
}
