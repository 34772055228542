import { OrganizationSwitcher } from '@clerk/clerk-react'

export const CustomOrganizationSwitcher = () => {
  return (
    <OrganizationSwitcher
      hidePersonal
      hideSlug
      afterCreateOrganizationUrl="/licensing"
      appearance={{
        elements: {
          rootBox: 'w-full bg-white hover:bg-gray-50 focus:outline-none',
          organizationSwitcherTrigger:
            'flex items-center justify-between w-full px-3 py-3 text-green-600',
          organizationPreview: 'flex items-center gap-3',
          organizationPreviewTextContainer: 'font-medium',
        },
      }}
    />
  )
}
