import { useState } from 'react'

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible'
import { Card } from '@/components/ui/card'
import { P, H5 } from '@/components/ui/typography'
import { Icon } from '@/components/ui/icon'

import {
  calculateObjectivesGrade,
  calculateValuesGrade,
} from '@/services/utils/performance-reviews'
import { cn } from '@/lib/utils'

import { PerformanceReview } from '@/types/PerformanceReview'
import { ReviewOverviewItemContent } from './team-review-overview-item'

interface MeReviewOverviewItemProps {
  review: PerformanceReview
}

export const MeReviewOverviewItem = ({ review }: MeReviewOverviewItemProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const objectivesGrade = calculateObjectivesGrade(review.objectives)
  const valuesGrade = calculateValuesGrade(review.values)

  return (
    <Card>
      <Collapsible open={isOpen} onOpenChange={setIsOpen}>
        <CollapsibleTrigger>
          <div className="p-4 flex items-center justify-between">
            <div className="flex-grow text-left">
              <H5 className="text-left">{`Q${review.quarter} ${review.year}`}</H5>
              <P className="text-muted-foreground text-left">
                {`Objectives ${objectivesGrade}/3, Values ${valuesGrade}/3`}
              </P>
            </div>
            <div className="flex items-center space-x-2 ml-4">
              <Icon
                name="ChevronDown"
                className={cn('h-4 w-4 transition-transform duration-200', {
                  'transform rotate-180': isOpen,
                })}
              />
            </div>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent className="px-4 pb-4">
          <ReviewOverviewItemContent review={review} />
        </CollapsibleContent>
      </Collapsible>
    </Card>
  )
}
