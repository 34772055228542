import { sum } from 'lodash'
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor'
import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor'

import { Button } from '@/components/ui/button'
import { Icon } from '@/components/ui/icon'
import { TeamMemberSelector } from '@/components/team-user-selector'
import { ColumnDef } from '@/components/ui/data-table'

import { average } from '@/services/utils/math'
import { Interval } from '@/services/utils/dates'
import { numberWithCommas, toProperCase } from '@/services/utils/formatters'

import { Objective } from '@/types/Objective'
import { UserProfile } from '@/types/UserProfile'

export const typeToLabel = (type: string) => {
  if (type === 'lastColumn') {
    return 'Last Column'
  }
  return toProperCase(type)
}

export const generateColumns = (
  intervals: Interval[],
  handleDeleteClick: (_: Objective) => void,
  patchOwners: (_id: string, _newOwners: UserProfile[]) => void,
): ColumnDef[] => [
  {
    defaultWidth: 54,
    editable: false,
    render: ({ data }) => (
      <Button
        variant="ghost"
        size="icon"
        onClick={() => handleDeleteClick(data)}
      >
        <Icon name="Trash" className="h-4 w-4 text-red-400" />
      </Button>
    ),
  },
  {
    name: 'title',
    header: 'Objective',
    minWidth: 300,
    defaultFlex: 1,
    style: { fontWeight: 600 },
  },
  {
    name: 'owners',
    header: 'Owner(s)',
    editable: false,
    defaultWidth: 200,
    render: ({ value, data }) => {
      return (
        <TeamMemberSelector
          classNameTeamUserListAvatar="h-7 w-7"
          value={value ?? []}
          onChange={(newOwners) => patchOwners(data.id, newOwners)}
        />
      )
    },
  },
  {
    name: 'type',
    header: 'Type',
    render: ({ value }) => typeToLabel(value),
    defaultWidth: 100,
    // eslint-disable-next-line
    editor: SelectEditor as any,
    editorProps: {
      idProperty: 'id',
      dataSource: [
        {
          id: 'average',
          label: 'Average',
        },
        {
          id: 'lastColumn',
          label: 'Last Column',
        },
        {
          id: 'sum',
          label: 'Sum',
        },
      ],
      collapseOnSelect: true,
      clearIcon: null,
    },
  },
  {
    name: 'target',
    header: 'Target',
    defaultWidth: 90,
    type: 'number',
    // eslint-disable-next-line
    editor: NumericEditor as any,
    render: ({ value }) => numberWithCommas(value),
  },
  {
    name: 'hit',
    header: 'Hit',
    defaultWidth: 80,
    editable: false,
    type: 'number',
    render: ({ value }) => numberWithCommas(value, 1),
  },
  ...(intervals.map(
    ({ label, weekNumber }) =>
      ({
        name: `week-${weekNumber}`,
        header: label,
        defaultWidth: 80,
        type: 'number',
        // eslint-disable-next-line
        editor: NumericEditor as any,
        render: ({ value }) => numberWithCommas(value, 2),
      }) as ColumnDef,
  ) ?? []),
]

export const generateRows = (data: Objective[]): Objective[] => {
  return data.map((row) => {
    const filteredValues = row.values
      .filter((value) => value.value !== null)
      .map((value) => value.value!)

    return {
      ...row,
      ...Object.fromEntries(
        row.values.map((value) => [`week-${value.weekNumber}`, value.value]),
      ),
      hit:
        row.type === 'sum'
          ? sum(filteredValues)
          : row.type === 'average'
            ? average(filteredValues)
            : (filteredValues.at(-1) ?? 0),
    }
  })
}
