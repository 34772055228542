import { Navigate, createBrowserRouter } from 'react-router-dom'
import {
  RedirectToOrganizationProfile,
  RedirectToUserProfile,
  useUser,
} from '@clerk/clerk-react'
import { useUserRole } from '@/hooks/useUserRole'
import { ReactFlowProvider } from '@xyflow/react'
import { useEffect, useState } from 'react'

import { ProtectedPage } from '@/components/common/protected-page'

/* Routes */
import { Layout } from '@/components/layout'
import { Root } from '@/components/root'
import { FeedbackPointsPage } from '@/routes/feedback-points'
import { FeedbackSessionsPage } from '@/routes/feedback-sessions'
import { SignInPage } from '@/routes/auth/sign-in'
import { SignUpPage } from '@/routes/auth/sign-up'
import { PersonalDashboard } from '@/routes/personal-dashboard'
import { ManagementDashboard } from '@/routes/management-dashboard'
import { SlackSuccessPage } from '@/routes/slack/success'
import { ObjectivesPage } from '@/routes/objectives'
import { OrganizationChartPage } from '@/routes/company/organization-chart'
import { MyReviewsPage } from '@/routes/performance-review/me'
import { MyTeamReviewsPage } from '@/routes/performance-review/team'
import LicensingPage from '@/routes/licensing'
import { OnboardingPage } from '@/routes/onboarding'
import { CompanyValuesPage } from '@/routes/company/values'
import { SettingsPage } from '@/routes/settings'

const HomeRedirect = () => {
  const { user } = useUser()
  const { isAdmin } = useUserRole()
  const [shouldRedirect, setShouldRedirect] = useState<boolean | null>(null)

  useEffect(() => {
    if (user?.id) {
      const savedChecklist = localStorage.getItem(`onboarding-${user.id}`)
      if (!savedChecklist) {
        // If no checklist exists, redirect to onboarding
        setShouldRedirect(true)
        return
      }

      const checklist = JSON.parse(savedChecklist)

      // Get the relevant checklist items based on role
      const relevantItems = isAdmin
        ? checklist // For admin, check all items
        : checklist.filter((item: { id: string }) =>
            ['calendar', 'feedback', 'orgchart'].includes(item.id),
          )

      // Check if all relevant items are completed
      const isComplete =
        relevantItems.length > 0 &&
        relevantItems.every((item: { checked: boolean }) => item.checked)

      setShouldRedirect(!isComplete)
    }
  }, [user?.id, isAdmin])

  // Wait for the redirect decision before rendering
  if (shouldRedirect === null) {
    return null // Or a loading spinner if you prefer
  }

  if (shouldRedirect) {
    return <Navigate to="/onboarding" replace />
  }

  return <Navigate to="/dashboard" replace />
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '/sign-in/*',
        element: <SignInPage />,
      },
      {
        path: '/sign-up/*',
        element: <SignUpPage />,
      },
      {
        path: '/slack/success',
        element: <SlackSuccessPage />,
      },
      {
        path: '/',
        element: <ProtectedPage />,
        children: [
          {
            path: '/',
            element: <Layout />,
            children: [
              {
                path: '/',
                element: <HomeRedirect />,
              },
              {
                path: '/dashboard',
                element: <PersonalDashboard />,
              },
              {
                path: '/onboarding',
                element: <OnboardingPage />,
              },
              {
                path: '/management-dashboard',
                element: <ManagementDashboard />,
              },
              {
                path: '/account',
                element: <RedirectToUserProfile />,
              },
              {
                path: '/organization',
                element: <RedirectToOrganizationProfile />,
              },
              {
                path: '/feedback-points',
                element: <FeedbackPointsPage />,
              },
              {
                path: '/feedback-sessions',
                element: <FeedbackSessionsPage />,
              },
              {
                path: '/objectives',
                element: <ObjectivesPage />,
              },
              {
                path: '/performance-review',
                children: [
                  {
                    index: true,
                    element: <Navigate to="me" replace />,
                  },
                  {
                    path: 'me',
                    element: <MyReviewsPage />,
                  },
                  {
                    path: 'team',
                    element: <MyTeamReviewsPage />,
                  },
                ],
              },
              {
                path: '/company',
                children: [
                  {
                    index: true,
                    element: <Navigate to="values" replace />,
                  },
                  {
                    path: 'organizational-chart',
                    element: (
                      <ReactFlowProvider>
                        <OrganizationChartPage />
                      </ReactFlowProvider>
                    ),
                  },
                  {
                    path: 'values',
                    element: <CompanyValuesPage />,
                  },
                ],
              },
              {
                path: '/licensing/*',
                element: <LicensingPage />,
              },
              {
                path: '/settings',
                element: <SettingsPage />,
              },
            ],
          },
          {
            path: '*',
            element: <Navigate to="/" />,
          },
        ],
      },
    ],
  },
])
