import { z } from 'zod'

const PerformanceReviewObjective = z.object({
  id: z.string(),
  completion: z
    .number({ message: 'Invalid' })
    .min(0, { message: 'Must be positive' })
    .int(),
  title: z.string(),
  comments: z.string(),
})

const PerformanceReviewValue = z.object({
  id: z.string(),
  rating: z.number().min(0).max(5).int(),
  label: z.string(),
  comments: z.string(),
})

export const PerformanceReviewCreateSchema = z.object({
  userId: z.string(),
  year: z.number().int().positive(),
  quarter: z.number().int().min(1).max(4),
  objectives: z.array(PerformanceReviewObjective),
  values: z.array(PerformanceReviewValue),
  managerReview: z.string(),
  actionPlan: z.string(),
  status: z.enum(['draft', 'submitted', 'published']),
  hidden: z.boolean().optional(),
})

export const PerformanceReviewPatchSchema =
  PerformanceReviewCreateSchema.partial()
