import { cn } from '@/lib/utils'

import { ClassName } from '@/types/globals'

export const Loading = ({
  className,
  containerClassName,
}: {
  className?: ClassName
  containerClassName?: ClassName
}) => (
  <div className={cn(containerClassName)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={cn('animate-spin text-primary', className)}
    >
      <path d="M21 12a9 9 0 1 1-6.219-8.56" />
    </svg>
  </div>
)
