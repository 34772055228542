import { useAuth, useOrganization } from '@clerk/clerk-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { DateRange } from 'react-day-picker'

import { deleteResolve, fetchResolve, addQueryParams } from './helpers'
import { useToast } from '@/hooks/use-toast'
import { createServerErrorToast } from '@/lib/toast'

import { FeedbackStats } from '@/types/FeedbackStats'
import { FeedbackPoint } from '@/types/FeedbackPoint'
import { MOCK_SERVER_ENABLED } from '../config/env'

export const useFetchFeedbackPoints = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<FeedbackPoint[]>({
    queryKey: ['useFetchFeedbackPoints', organization?.id],
    queryFn: () =>
      fetchResolve(
        `/organization/${organization?.id}/feedback/point`,
        getToken,
      ),
    enabled: MOCK_SERVER_ENABLED || !!organization?.id,
  })
}

export const useFetchFeedbackStats = (
  teamMemberIds: string[] | 'all' | undefined,
  dateRange?: DateRange,
) => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<FeedbackStats>({
    queryKey: ['feedbackStats', organization?.id, teamMemberIds, dateRange],
    queryFn: () => {
      const params: Record<string, string> = {}

      if (teamMemberIds) {
        params.teamMemberIds =
          teamMemberIds === 'all' ? 'all' : teamMemberIds.join(',')
      }

      if (dateRange?.from) {
        params.from = moment(dateRange.from).valueOf().toString()
      }

      if (dateRange?.to) {
        params.to = moment(dateRange.to).valueOf().toString()
      }

      const url = addQueryParams(
        `/organization/${organization?.id}/feedback/stats`,
        params,
      )

      return fetchResolve(url, getToken)
    },
    enabled: MOCK_SERVER_ENABLED || !!organization?.id,
  })
}

export const useDeleteFeedbackPoint = () => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async ({ id }: { id: string }) =>
      await deleteResolve(
        `/organization/${organization?.id}/feedback/point/${id}`,
        getToken,
      ),
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        ['useFetchFeedbackPoints', organization?.id],
        (oldData: FeedbackPoint[]) =>
          oldData && oldData.filter((fp) => fp.id !== variables.id),
      )
    },
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}
