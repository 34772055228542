export const average = (list: number[]) => {
  if (!list.length) {
    return 0
  }

  return list.reduce((prev, curr) => prev + curr) / list.length
}

export const sum = (list: number[]) => {
  return list.reduce((partialSum, a) => partialSum + a, 0)
}
