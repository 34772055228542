import { UserProfile } from '@/types/UserProfile'

type CompanyUser = UserProfile & { managerId?: null | string }

export interface CompanyProfile {
  name: string
  users: CompanyUser[]
  values: { label: string; id: string }[]
}

export const companyProfiles: Record<string, CompanyProfile> = {
  default: {
    name: 'Tech Giants',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Elon',
        lastName: 'Musk',
        emails: ['emusk@spacex.com'],
        image:
          'https://pbs.twimg.com/profile_images/1590968738358079488/IY9Gx6Ok_400x400.jpg',
        managerId: null,
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Zuckerberg',
        emails: ['mzuckerberg@meta.com'],
        image:
          'https://pbs.twimg.com/profile_images/77846223/profile_400x400.jpg',
        managerId: 'U077TRRJQ3F',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeff',
        lastName: 'Bezos',
        emails: ['jbezos@blueorigin.com'],
        image:
          'https://pbs.twimg.com/profile_images/1591558315254890500/ETIHb4Nl_400x400.jpg',
        managerId: 'U077TRRJQ3F',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steve',
        lastName: 'Jobs',
        emails: ['sjobs@apple.com'],
        image:
          'https://pbs.twimg.com/profile_images/1155917668697985025/w4N0eB1E_400x400.jpg',
        managerId: null,
      },
      {
        id: 'U077TRRJQ3K',
        firstName: 'Tim',
        lastName: 'Cook',
        emails: ['tcook@apple.com'],
        image:
          'https://pbs.twimg.com/profile_images/1535420431766671360/Pwq-1eJc_400x400.jpg',
        managerId: 'U077TRRJQ3J',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wcplusa: {
    name: 'WCA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sinéad',
        lastName: 'Harte',
        emails: ['sharte@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGNKVwSfKewmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643847214856?e=2147483647&v=beta&t=06TZh3Vi5IB3IMJp4UiNznyb6gaQqevn5VJaEz9W0II',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kevin',
        lastName: 'Williams',
        emails: ['kwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEAV6MOAsBaUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583203925045?e=2147483647&v=beta&t=qtLMR2XYmM8-TQ7XEKyZ5mdsbMcm4BRepZ7zY5i9l5k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jerome',
        lastName: 'Boroff',
        emails: ['jboroff@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alyssa',
        lastName: 'Heisten',
        emails: ['aheisten@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carlos',
        lastName: 'Zaldivar',
        emails: ['czaldivar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pulseinsights: {
    name: 'Pulse Insights',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeremy',
        lastName: 'Dalnes',
        emails: ['jdalnes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE864Q1iLiKlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727207527528?e=2147483647&v=beta&t=6XkDizAWUnlTJi-tX9etVCkPyAaqX4V7xIm5xbLCjsA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeremy',
        lastName: 'Bieger',
        emails: ['jbieger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHBGrhIYWhk0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516245735869?e=2147483647&v=beta&t=ZvluhJmRyD6pdQL3KfvLFhhsC03Uf9ZmzyE22mk4i64',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pablo',
        lastName: 'Rojas',
        emails: ['projas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFO2LhaDKQymQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516239778189?e=2147483647&v=beta&t=ZJeN163HNlygXwUsVlDdOzTbH8U5vmYc_7Tk40nFxd0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Peregrine Bosler,',
        lastName: 'MBA',
        emails: ['pmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEe3BoTWhPwwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726074253548?e=2147483647&v=beta&t=aYqSBnpBZHwQJJUfPGBXPRuoTQnHb0noH_9aXvjZ-yg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kristen',
        lastName: "D'Alelio",
        emails: ["kd'alelio@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFeW3sXj1ktVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516993756914?e=2147483647&v=beta&t=u17hCtsm65jIuEyzvncm4eogdG41hX3dzj8IPQyi5q0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ontocollege: {
    name: 'OnToCollege',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adam',
        lastName: 'Pieper',
        emails: ['apieper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGZeAV4t_xOcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679587399113?e=2147483647&v=beta&t=nHQXCN87RPJ467a1DfALDDF6QLkopVAbXK1G5fipGog',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bhagyalakshmi',
        lastName: 'Pushkaran',
        emails: ['bpushkaran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGaTI-u8oEPXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694025447982?e=2147483647&v=beta&t=aUW1btUWPGQawONEunw6eAyi9-Is_ky_IfC71iVlMII',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Baylor',
        emails: ['jbaylor@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Heidi',
        lastName: 'Macy',
        emails: ['hmacy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joni',
        lastName: 'Mason',
        emails: ['jmason@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQENq6WWed901Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517491202186?e=2147483647&v=beta&t=VM_b00byALH23til9LC4mQe2HfvPNsip1rk559xoONQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  abetterwayhc: {
    name: 'A Better Way Home Care',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Craig Glover,',
        lastName: 'M.B.A.',
        emails: ['cm.b.a.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marlynnda',
        lastName: 'Calixte',
        emails: ['mcalixte@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFrvtA3SwHRqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654280964046?e=2147483647&v=beta&t=3RS36K8RSFhDwRKDZcJHhLtscWoAs2o71onoiZk1xlY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tina',
        lastName: 'kuehl',
        emails: ['tkuehl@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nylah',
        lastName: 'Easterling',
        emails: ['neasterling@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE_e0AL-nn81Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727104270850?e=2147483647&v=beta&t=F-AUlw7yrh7pBV2FHDFzqWM1cEYrxQNni1PDI6wOVjI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bonnie',
        lastName: 'Glover',
        emails: ['bglover@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lanternsol: {
    name: 'Lantern Sol',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'DALGIS PAJARO',
        lastName: 'PACHECO',
        emails: ['dpacheco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF45kWwYkBwBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614519689700?e=2147483647&v=beta&t=jZugCk80uS0-G2OU30BDbnhH6ZlMr9NZhS1VrgT0lR8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sebastian Hurtado',
        lastName: 'Herrera',
        emails: ['sherrera@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erin',
        lastName: 'Geribon',
        emails: ['egeribon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGm7hcMrg7Nvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667848457110?e=2147483647&v=beta&t=uIin681QOCelgNQss6kEhC3shAw1ie6Dg6uOUjQgyC4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mariana',
        lastName: 'Roca',
        emails: ['mroca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF9lcIb1V0VCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650426615479?e=2147483647&v=beta&t=czIftJ8CjfJqACjR4VfA2gsVB3Xzq9m6yrsNKvnxm94',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Maricel',
        lastName: 'Rivera',
        emails: ['mrivera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFRAmhM7EKFrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619386840570?e=2147483647&v=beta&t=Rvld9VhQXjzVfxXKwwn-wdHOiUC8DFfOwb0b0TKC_SM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  acceleraisecorp: {
    name: 'Acceleraise',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cheyenne',
        lastName: 'Gonzales',
        emails: ['cgonzales@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFor3Fxka4kag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694108571129?e=2147483647&v=beta&t=nx8mNSBLavngrPsZiC76BNcyu2Lnmd_XAc1Zp3UrBJc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ingrid Alvarado,',
        lastName: 'Ph.D.',
        emails: ['iph.d.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Denice',
        lastName: 'Mitchell',
        emails: ['dmitchell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGJucq_m-xWYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675969682699?e=2147483647&v=beta&t=mi6pbq2jomjX0Dg6mYsWMPzbKV-cFq_c1RSSbtIY0Vc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paola',
        lastName: 'Andrade',
        emails: ['pandrade@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGHvNIdNavxrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706211881673?e=2147483647&v=beta&t=8v8F3GtUFALklYNnmRTmPMuMEV5NqHrBUBZDYDJtpYw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Madison',
        lastName: 'Perry',
        emails: ['mperry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHXBO_fBBcdfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718220476796?e=2147483647&v=beta&t=NbZzybPtRioFnvrT2f7CLPLdETSGS_PM149kXyOmKvc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  yourpassion1st: {
    name: 'YourPassion1st',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dakota',
        lastName: 'Gipson',
        emails: ['dgipson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHdvwmK3DbrfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698017552357?e=2147483647&v=beta&t=h_WobGVk4uY8EUDrPqjiV2UZWgqc1qd9Mkw_kAw_xTA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Premalatha',
        lastName: 'Ganesan',
        emails: ['pganesan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Thomas',
        emails: ['cthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEuORsVh1PDLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556039352524?e=2147483647&v=beta&t=XCVxg-wu-O0DDsnLq-Jvn-j1ML1c8bjNy-Rc4y5jPQc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shaily',
        lastName: 'Shrivastava',
        emails: ['sshrivastava@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGqv9PRHo7V9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676676111526?e=2147483647&v=beta&t=vZn0LxqJjHUEJCxc_E6YJlVXoBM3aYS_RBIRNZDjQrU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kendrick',
        lastName: 'Holden',
        emails: ['kholden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHfao8GDbysLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715705580292?e=2147483647&v=beta&t=uboYbxLtuBh1j3EMzK5NT4T_3crJZ4zA1l00qkLMwLo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zagaran: {
    name: 'Zagaran, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Benjamin (Zags)',
        lastName: 'Zagorsky',
        emails: ['bzagorsky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEelqoL8xezGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544794626160?e=2147483647&v=beta&t=rjSeVJAxbDEMhwPrwtykmbVCKOJWSv0cShgZN_70wlg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Percy',
        lastName: 'Street',
        emails: ['pstreet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHdUELCaajgiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669928566834?e=2147483647&v=beta&t=xEJITBH2CwAd7gC4bnsme5Ly0qxebRIJIxO_FJhlIF8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Akiva',
        lastName: 'Jackson',
        emails: ['ajackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHdi8DQbGgKjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578085032391?e=2147483647&v=beta&t=Kt40nMoB5_VPj4suthnAJ-675SMUb6FBKBeDvv1DKZs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Katie',
        lastName: 'Baldwin',
        emails: ['kbaldwin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF9u8RkS4lQ_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627008430349?e=2147483647&v=beta&t=pBtAhw65XWQR8BobqKl29H0wztxO3uZcxxLrV8wuz0c',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Louisa',
        lastName: 'Esteban',
        emails: ['lesteban@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  getfistbumps: {
    name: 'Fist Bump',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brandon',
        lastName: 'Lee',
        emails: ['blee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEyk1N9aXhBMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692375838853?e=2147483647&v=beta&t=Q-v92bJ6U_wpYIbIqSVYR0S7acAcjZPJVzm5fzgbK40',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Willems',
        emails: ['dwillems@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFMxFm9oiywtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728669849016?e=2147483647&v=beta&t=nVpa--lhTu5-FXFjgWoANh9hFXJB-CbHK4caFj8AYoI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Robert (BUTCH)',
        lastName: 'NICHOLSON',
        emails: ['rnicholson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEjb-3QGJAT_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699658521927?e=2147483647&v=beta&t=1zJ8wdKsb5F0ZUbROu87scp--KVNHw4KoJcBTZO-Vxk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dan',
        lastName: 'King',
        emails: ['dking@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGt00tGnqNqBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516319941905?e=2147483647&v=beta&t=d3dq3Kk8CLDSNOSNtMftiQDT4-4ua--DTdaWvi8MXmM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nathan',
        lastName: 'Chan',
        emails: ['nchan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFj_2PjBvRI1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693083339930?e=2147483647&v=beta&t=UlU93-PQnLuVmUCJPtrPxH7nj7NdmggmyFDuMryptjI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bestcode: {
    name: 'BestCode',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dennis',
        lastName: 'Sibley',
        emails: ['dsibley@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andy',
        lastName: 'Fox',
        emails: ['afox@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Richard',
        lastName: 'Fox',
        emails: ['rfox@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ray',
        lastName: 'Garcia',
        emails: ['rgarcia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG9nfvZTJH4DQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718337954719?e=2147483647&v=beta&t=Cn7M-fkZvTU-3I-5iSgg3pZ15xtZ2dwFdrHranJgLqU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Danny',
        lastName: 'Adams',
        emails: ['dadams@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  platinumcubed: {
    name: 'Platinum Cubed LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brittany',
        lastName: 'Hart',
        emails: ['bhart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFil0HOXG1VbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605543949910?e=2147483647&v=beta&t=VMryR27R_dY-i3LjXzHgSkY8VozNQuM3fxDnh2qSToM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cora',
        lastName: 'Tucker',
        emails: ['ctucker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGTXPb_--oXIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600990063971?e=2147483647&v=beta&t=wDdVFNp_BoC7Ra7OKB5mD4UM7oI5sgn5mB3bvmL_EW0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jon',
        lastName: 'Chen',
        emails: ['jchen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHgHaM_gKwarA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695056690689?e=2147483647&v=beta&t=--9WISR8jWAk-LYrelYr4i9DRGt0AUuG13e4FbiR1PU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Victoria',
        lastName: 'Nguyen',
        emails: ['vnguyen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFd33uUt8Dl8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689785233270?e=2147483647&v=beta&t=MbIEmnLsJ4senf_CCRBE_UbV1iV1znK2wP8pgSInktI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dinah',
        lastName: 'Reece',
        emails: ['dreece@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHYbYSDps0fag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670883303770?e=2147483647&v=beta&t=PlFCxfkYKyH8Jgm-exChGPD81e1RGsgr7rcT-6JRVCw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lypid: {
    name: 'Lypid',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jen',
        lastName: '',
        emails: ['j@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHtEend8QmPxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590891834329?e=2147483647&v=beta&t=7q2zgITKT-_vzatnH0Tgm5fhqzpVWbJpfYyJ-TwmuIs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ruthy',
        lastName: 'Ying',
        emails: ['rying@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGBc7rsJ7lbxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1537734901736?e=2147483647&v=beta&t=bwaH3_eI49IIh5giEkukOHG3ALGQsKkYa6yHvaVPCEs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Karen',
        lastName: 'Chiu',
        emails: ['kchiu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGzT7EITI4dlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682574961791?e=2147483647&v=beta&t=j1I-44XuQo3I68-eEyakYMFHlDYzKG7WE-pCvxvUiMk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yi',
        lastName: '',
        emails: ['y@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHL6UBvvSz_iw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609834593279?e=2147483647&v=beta&t=eGpSqnMkTO4T6mmrS0TnVv2_vS1BVnj_xdNn8ldUvdY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ian',
        lastName: 'Kuo',
        emails: ['ikuo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG6TqU78yIhUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706845009695?e=2147483647&v=beta&t=-vW8zLHk-ADu1B0LCUwq3hbVqWdUwvEUmLzKoNRN-MA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  doinggoodagency: {
    name: 'Doing Good Digital',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul St',
        lastName: 'Onge',
        emails: ['ponge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHJqaQLiheYuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675135343104?e=2147483647&v=beta&t=HOJ4fEBgQCg-zESqmjyFl3WY9UmffwzA-ws0qMWv10I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lisa',
        lastName: 'Ng',
        emails: ['lng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFu4fnQ5uCdbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724105206224?e=2147483647&v=beta&t=tt2O_nh5s4C0aTN5cOH4ARxGvTxcF9KDhwXqaycclac',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sarah',
        lastName: 'Lesh',
        emails: ['slesh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGMcdn0dlIi5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713966465858?e=2147483647&v=beta&t=IZk2yWn32khjUd_om9OwUfGj2yUmWcONFG5_r1UOy-k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zach',
        lastName: 'Bines',
        emails: ['zbines@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGMZpdhJNFdIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622306191758?e=2147483647&v=beta&t=CBPF0INxn6qj8TQaoK0Euo0kYFjKjXVfzQuzwa08E5M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Katy',
        lastName: 'Spencer',
        emails: ['kspencer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  odysseyretailadvisors: {
    name: 'Odyssey Retail Advisors',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Charlie',
        lastName: 'Koniver',
        emails: ['ckoniver@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQELa0_a-kDctA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548290264657?e=2147483647&v=beta&t=gzf0w21Ni91WPEiiUPF08Fb6zikFU3BOKSl2hzavxPA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jaclyn',
        lastName: 'Gottesman',
        emails: ['jgottesman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQERNbD3BNktbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631110799607?e=2147483647&v=beta&t=ThuvVGSUh6SNwfTlZhkxP7Pl3Ob1AkmNWVEWvGELIQU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rick',
        lastName: 'Strauss',
        emails: ['rstrauss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGkP4Ir3gOgeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1533161316040?e=2147483647&v=beta&t=Cp_dGkuDh369rCyT8VGfQT66WOM98wY8bnbTzR5ywDc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sam',
        lastName: 'Masters',
        emails: ['smasters@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHlfWExJGQkwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668878173436?e=2147483647&v=beta&t=rZhTRN6rHzbhnVSrNcI3amvGQWX3RlF3-qhKP3kVCYQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Colleen',
        lastName: 'Franke',
        emails: ['cfranke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEhkSumM8GyRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686251373892?e=2147483647&v=beta&t=bpGy8JVuvUBKrcrGQHOGV7EumxU93IxeXVbbeSxgtbE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  orical: {
    name: 'Orical LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gregory',
        lastName: 'Florio',
        emails: ['gflorio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH0-IpOHOOqjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516872156697?e=2147483647&v=beta&t=7ulZCIpqRjUBL0HnEefIWseDErnXkCnQZDDoNHH0mos',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jigesh',
        lastName: 'Tiyar',
        emails: ['jtiyar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGUHecj-Plc8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610373229363?e=2147483647&v=beta&t=Rw008RnZGU0f1uuA1n6FvOSoSaZC4QGYAyOHLUvWyg8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pierce Iman',
        lastName: 'Reza',
        emails: ['preza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGXW-clbfgB2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570827724558?e=2147483647&v=beta&t=jyr4Lv_UY-PhSThFxfb43pRDpsKBrYdIxioy43WrO5U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mekyas',
        lastName: 'Moges',
        emails: ['mmoges@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE1ctmBoNoZSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517604838148?e=2147483647&v=beta&t=gKu14dWl5_Yf1DMvYys229-3YdhskH9GMQpcQOVG2qQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kori',
        lastName: 'Dipak',
        emails: ['kdipak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHu9wD4qAGoNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718492381209?e=2147483647&v=beta&t=vgNZ0wRzAm3lmq3KA3VRHnIquXzthj1JEAn9cFMXqcQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  vets4childrescue: {
    name: 'Veterans For Child Rescue',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Craig',
        lastName: 'Sawyer',
        emails: ['csawyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFx7ns19Wy9Gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720012789907?e=2147483647&v=beta&t=sXQ8nw16gPOZxoOBAjTHJsnw9c2UWC5bKkfcFNLIjHM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Forrest',
        lastName: 'Sealey',
        emails: ['fsealey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brian',
        lastName: 'Pike',
        emails: ['bpike@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQELET8sawo-dQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671545219868?e=2147483647&v=beta&t=LlBVjLidnGqNG7hNz00nnWkCmWK7SwOws7fE8cjCpI8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Timothy',
        lastName: 'Brown',
        emails: ['tbrown@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Morgan',
        lastName: 'Boyer',
        emails: ['mboyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHQbcFb3qnBkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516661784078?e=2147483647&v=beta&t=JlDCVC79GjaqCReUqWTLqgOmgKFE01mq7VEiVJXa_JM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  realtalksandiego: {
    name: 'Real Talk Media',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marklyn',
        lastName: 'Johnson',
        emails: ['mjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH0RbKy2rXHOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650073257488?e=2147483647&v=beta&t=1mtdeJg6JNAxes9KsgMyL_fYgoIaP7KEPlaHZ7jDiQ8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Renell',
        lastName: 'McKibbon',
        emails: ['rmckibbon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brett',
        lastName: 'Ward',
        emails: ['bward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGQOmhHkUlb9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680099692787?e=2147483647&v=beta&t=dHvfJA04ipGd9UxfCE-VzkRMCl1cfQtLXuPZQk035Iw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Courtney Kay',
        lastName: 'F.',
        emails: ['cf.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG-VXYb08r55g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709148398155?e=2147483647&v=beta&t=L5LSnpX_whK1thPfgUNsaTzyu_oT9AqbWR4hqC2wDY4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bonnie',
        lastName: 'Reincke',
        emails: ['breincke@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  easypaydirect: {
    name: 'Easy Pay Direct',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cory',
        lastName: 'Jones',
        emails: ['cjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF5FWkFltOtew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517618350794?e=2147483647&v=beta&t=a8z355oxWFGv_DEx-54euRTDu0iAIQG58zY_3xDoTQ8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Usey',
        emails: ['musey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH5bf1srO-uBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649200722573?e=2147483647&v=beta&t=__sXVKEeYmvQ-npbfJv79pLRIUgJnnDUXjxWh5JetRc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brad',
        lastName: 'Weimert',
        emails: ['bweimert@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Henry',
        lastName: 'Fuentes',
        emails: ['hfuentes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGWhHberV9-CA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691632243127?e=2147483647&v=beta&t=ID2Tww9Kn-hWF0K-dw_1U3zXHAuxq1ScL7p3hGeORYc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Maria',
        lastName: 'Alvarez',
        emails: ['malvarez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGSqMyjZL9NlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588023484317?e=2147483647&v=beta&t=OQBlDGurYskN5XZUMqtv5_rHm1lLnDssxWgSNOT6MGU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  creativets: {
    name: 'CreatiVets',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tim',
        lastName: 'Brown',
        emails: ['tbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFGNGJwXuStNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727981805410?e=2147483647&v=beta&t=N2LXTF0RhfvP3u4AA3Az-crsYItJV8Jeho0wk5iVs3o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brett',
        lastName: 'Gillan',
        emails: ['bgillan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEGc2U9VHDhZg/profile-displayphoto-shrink_200_200-alternative/profile-displayphoto-shrink_200_200-alternative/0/1529433720496?e=2147483647&v=beta&t=skKdmYV4U5HZ3D6SeIHdtBwnri9oqUS7mI5afXmzI08',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Richard',
        lastName: 'Casper',
        emails: ['rcasper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEoJKhGD3Pkpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677766369438?e=2147483647&v=beta&t=HBIewszg2ESQmx648KGVqYFyEIKb0QZ2KEbgK-z73os',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maria',
        lastName: 'Hernandez',
        emails: ['mhernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHVg3l-DqARLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516928913032?e=2147483647&v=beta&t=uQr26eM2Wb-tl739rOFg-SHl-WXR5cvwqUiyhOW6yKo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Richard',
        lastName: 'Casper',
        emails: ['rcasper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFNQmHPvxZKAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666963917550?e=2147483647&v=beta&t=0U1AgNuHuLnU0J04eJ7Jkn8VhZwP5natynF0H6C-Gtk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  peakcyberinstitute: {
    name: 'Peak Cyber Institute',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Emin',
        lastName: 'CALISKAN',
        emails: ['ecaliskan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF7LUL2VB4Gqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697242006514?e=2147483647&v=beta&t=U2ekBVXjNS1fcaL72hwWvLX6gV_GCD-S0ELwiZwtVZw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mitchell',
        lastName: 'Huett',
        emails: ['mhuett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQElgSRPcgOuWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624640416341?e=2147483647&v=beta&t=lXUA2oNebw2IJ9lq2hPWYMecSGq2clqX_30twjK-E9c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Allison',
        lastName: 'Singh',
        emails: ['asingh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'MESUT',
        lastName: 'M.',
        emails: ['mm.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Horatio',
        lastName: 'Graham',
        emails: ['hgraham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE1h1nhmhvZtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727962589934?e=2147483647&v=beta&t=1GxA2MHPm8rTxrRUn6SoECrUccWOLjqXuFXYBYISZCE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  beyondbenign: {
    name: 'Beyond BenignGreen Chemistry for a Sustainable Future',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amy',
        lastName: 'Cannon',
        emails: ['acannon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHxs3LsZrTxdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516316405021?e=2147483647&v=beta&t=BElHBKPQCCh5vgOJpBmWAbEE0oR1Bi-l9wvGUc32WYs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ann',
        lastName: 'Lee',
        emails: ['alee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGoC6z4ag9JJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712833284173?e=2147483647&v=beta&t=t5Q47ed4oU7trPq3NcJx5jQsvBMBz0WHNftDCC_rLoM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Juliana',
        lastName: 'Vidal',
        emails: ['jvidal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: "Nicki D'Onofrio",
        lastName: 'Wiggins',
        emails: ['nwiggins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nimrat',
        lastName: 'Obhi',
        emails: ['nobhi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGayBL5zN4fKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611066724545?e=2147483647&v=beta&t=H4begwg_TPcQ4UsBE4__51ffq5zCpW9tThu7Ep80zQ8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  element2grp: {
    name: 'ELEMENT2 GROUP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Patrick',
        lastName: 'Madden',
        emails: ['pmadden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGJhtztfgODhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516572625508?e=2147483647&v=beta&t=WaC7CvJ2qNhXbb6DFrHZ2NKZZtCinc2M5CVn_rRNTtc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Deanna',
        lastName: 'DeLuca',
        emails: ['ddeluca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF9jbDJdFK1rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686938286286?e=2147483647&v=beta&t=Zgx2Vl_RKBjOI1vedGqIZtTghsV6o52KybH6efqxHXE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rocco',
        lastName: 'G.',
        emails: ['rg.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEUjKujpZ1CWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516455652530?e=2147483647&v=beta&t=ZM2LKIys58PtoIgSV8b0hvt5UNokYmUihP-ZjNuxKLY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Steven',
        lastName: 'Serpa',
        emails: ['sserpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFNKOwwsQZbfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718931600575?e=2147483647&v=beta&t=5thGaPEr6szRetAm3LU1-jyDaw8D7YPDCCLoc_vjEGs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Julia',
        lastName: 'Mirsky',
        emails: ['jmirsky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGmHH2P9xTDnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728522364174?e=2147483647&v=beta&t=U36oCr-wbzPNjA1tyT1WZzxcgxxHw1NdQxoQqxablwo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  meetharlow: {
    name: 'Harlow',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Samantha',
        lastName: 'Anderl',
        emails: ['sanderl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF3osmYvQBM9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701974894666?e=2147483647&v=beta&t=7dWX_cLYDnZKx4QGnjtNcDN7P4x7KGJyUaxc_2T0MlI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sara',
        lastName: 'Warrens',
        emails: ['swarrens@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rabbi Berel',
        lastName: 'Slavaticki',
        emails: ['rslavaticki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHoIn0osSLMNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603083491654?e=2147483647&v=beta&t=fHV0ch6ia5QCWRPeRynmTAsLJfvV-899wfYsIZdVE24',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hayden',
        lastName: 'Anderl',
        emails: ['handerl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHpkeccrG-GaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707940712696?e=2147483647&v=beta&t=YVith9JJ3-vUdG6Gr4rhg_H6T8wV9xdzgs5UYH1gtyo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pilar',
        lastName: 'Minue',
        emails: ['pminue@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  morphl2: {
    name: 'Morph',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Azeem',
        lastName: 'Khan',
        emails: ['akhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEvX7UiH3R-cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681817935033?e=2147483647&v=beta&t=dyKchaigR-SbmKPX0liwa3glc6oogXtVB8V-SjcUwfo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cecilia',
        lastName: 'H.',
        emails: ['ch.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yuliana',
        lastName: 'Yi',
        emails: ['yyi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH1N-3R6YxnTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688912545390?e=2147483647&v=beta&t=6S5I5MG1OUIGU4EFJhXdgIWwpKmPeGskn9MSRi0AOuI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Freya',
        lastName: 'C.',
        emails: ['fc.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gloria Kimbwala',
        lastName: '(She/Her)',
        emails: ['g(she/her)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHTEVChI5Q3Eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719853424604?e=2147483647&v=beta&t=hDSaTza5sIoTP9jKEzSXX3D98UciI6O-vA_qsNyqp9c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  raxia: {
    name: 'Raxia',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'Jones',
        emails: ['mjones@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nicole',
        lastName: 'Neutz',
        emails: ['nneutz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE5naE-ivDXnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682706813801?e=2147483647&v=beta&t=qn2KyXtvE-fYymTR6jUj2LPMOVMYlDBXEk5ynkjwlj4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ryan',
        lastName: 'Green',
        emails: ['rgreen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Raxia',
        lastName: 'Shalulange',
        emails: ['rshalulange@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Peyatt',
        emails: ['mpeyatt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHeCiUwti5ofA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616698749328?e=2147483647&v=beta&t=C6Vupa89WaGtQJnv6Fsj5szeFgXlHw-Jc9RgPiKSV-U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  omnilabconsulting: {
    name: 'Omni Lab',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jonathan',
        lastName: 'Bland',
        emails: ['jbland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEskibu0l8VQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661531067814?e=2147483647&v=beta&t=vrtKf3KMLvOxDfLBjfwYoYtZ5NdjpHftKZNpm1vk-C4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'S.',
        emails: ['js.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH-F1eiAZekiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712002383826?e=2147483647&v=beta&t=xgV7jisTPWD4Ice3_0ExiWffqoE2MdwT4Q71-kNIoFU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jenna',
        lastName: 'Rowlson',
        emails: ['jrowlson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH3v2YmdMEpdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703344910562?e=2147483647&v=beta&t=QPY7SXIMTNiR6vEExvF__BNkokjwyYYf0N2_aPs56_s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jackson',
        lastName: 'Swearingen',
        emails: ['jswearingen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG-AUKEBZ5GGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724256397141?e=2147483647&v=beta&t=2Eil3P4ZqRpm2mQE4Akorkb-ehYpx6WUxHeK2qdSJM4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mandie',
        lastName: 'Carlile',
        emails: ['mcarlile@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFFjhz3V_YRzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703007363194?e=2147483647&v=beta&t=1LLIa8A4zRW6rrFNwY7f-MPv4sbhscY-_v4p1c1VroI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ovis: {
    name: 'OVIS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Linton',
        lastName: 'Johnson',
        emails: ['ljohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGZWfV7U7uXBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581406474177?e=2147483647&v=beta&t=Vo1zxNI4Vt0oBBwP9sf4OruqUVveMfkzKp0Ag5xfIdk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Elizabeth',
        lastName: 'Sexton',
        emails: ['esexton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHTY7SVOjUj_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686254674168?e=2147483647&v=beta&t=oZ05Lz-9rflJjIp80gQkX__O5NcYsKFV9Ka_Dxfh32Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Odike',
        lastName: 'Michael',
        emails: ['omichael@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGT_zG1fGoGBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655324534253?e=2147483647&v=beta&t=Lq_fCGYJn1Trqxyq2P-eAwJ1KCHenAwz-osPG8tsguY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Adina',
        lastName: 'Farcut',
        emails: ['afarcut@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFO9kBEv6TsEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517044651736?e=2147483647&v=beta&t=-1n6mpAiqPO-C0Qhkagtmw-scdrs-IGC8UUayLUL7Aw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kamraan',
        lastName: 'Jaffar',
        emails: ['kjaffar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ninthWave: {
    name: 'Ninth Wave',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Douglas Allan',
        lastName: 'Bacon',
        emails: ['dbacon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Fred',
        lastName: 'Aiken',
        emails: ['faiken@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHA0AKeS_kkZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583848018031?e=2147483647&v=beta&t=RP3LwTzvg9M2HAkEmALgeJ131YCsHmHYkssDYnnbArE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'George',
        lastName: 'A.',
        emails: ['ga.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gotce',
        lastName: 'Peev',
        emails: ['gpeev@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shalini',
        lastName: 'Jerath',
        emails: ['sjerath@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  highergroundlabs: {
    name: 'Higher Ground Labs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jose',
        lastName: 'Cornejo',
        emails: ['jcornejo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE9gV0feKDrrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569950009505?e=2147483647&v=beta&t=1BlETq6Cz8-guJi02PNv1ySYSLxoHlQkEjUHSEL4qGE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Betsy',
        lastName: 'Hoover',
        emails: ['bhoover@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHsCMS5zOHjkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517549153574?e=2147483647&v=beta&t=JycShcegu-RKlXLexbbhFmp3zd083zCmF98WWLcZ4Nk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ali',
        lastName: 'T.',
        emails: ['at.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGpINf1s7evcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664766419406?e=2147483647&v=beta&t=3NlPIk5hk1Z5ZHbSNIfXHCsJsYFsOKVeCJVfW91TZh0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Olivia',
        lastName: 'Ellis',
        emails: ['oellis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEnpxUqD1tSeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672158883143?e=2147483647&v=beta&t=A-FmykFopGc__qPs6bNOB6u32FyUzDYfai-q-nMXyU8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Leah',
        lastName: 'Bae',
        emails: ['lbae@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  welwynn: {
    name: 'Welwynn Outpatient Center',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lewis',
        lastName: 'Finch',
        emails: ['lfinch@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'William',
        lastName: 'Guest',
        emails: ['wguest@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGHZQQMNjTzhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670864427723?e=2147483647&v=beta&t=y0pJIudWD7KS92hEMvCqOHLc14x0ZhnVlsLjWeNLZio',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Desiree King',
        lastName: '(Susini)',
        emails: ['d(susini)@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Autumn Mize (Stewart),',
        lastName: 'LCMHC',
        emails: ['alcmhc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF8IZXd19PHGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720987826948?e=2147483647&v=beta&t=k_gks90jnkv7-JoBcLL74JnTUiWpHK3M_1_Hbl2biM8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Le',
        lastName: '',
        emails: ['l@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hensleehr: {
    name: 'HENSLEE',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Leslie',
        lastName: 'Hensley',
        emails: ['lhensley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHYWZq3jAJcCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655268788314?e=2147483647&v=beta&t=rsdD5EnDtmTHGkBUvtK5QLu8LWViv-Ym-HA_vB7roMs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lee Turner,',
        lastName: 'SHRM',
        emails: ['lshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE8Dq8JfYOkig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543362515499?e=2147483647&v=beta&t=XdoOlRTN1md4NjHGhvrQ96oo1O30we0YNHeZ_CvIck4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kathy',
        lastName: 'Henslee',
        emails: ['khenslee@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gabrielle',
        lastName: 'M.',
        emails: ['gm.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Beth',
        lastName: 'Nelsen',
        emails: ['bnelsen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  itsgoldilocks: {
    name: 'Goldilocks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sara',
        lastName: 'Brooks',
        emails: ['sbrooks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE0dZxYcPZlJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728512312092?e=2147483647&v=beta&t=uqj7uHwM-WnWvilG6ROZIW87fwFgueO30ejkMiouljw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Katherine',
        lastName: 'Eyre',
        emails: ['keyre@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEhpn-_WlUxmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1532422935787?e=2147483647&v=beta&t=k6TMUSTaWx0yfctEMlFiY3fwtg5IrwUleZdkGOWZgug',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Emily',
        lastName: 'Jenkins',
        emails: ['ejenkins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFidQaVuWGWXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651754657233?e=2147483647&v=beta&t=uqvuGwMWJBPLG7VrzAAu862Ixs4I0zcwNGznP_39gdQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tracy',
        lastName: 'Fussell',
        emails: ['tfussell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHUXaCH4H-e7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516257927521?e=2147483647&v=beta&t=9xZp00V4wHclfDQD6XEVGdIezgKyAE7ab-g_hg9zels',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zane',
        lastName: 'Yosif',
        emails: ['zyosif@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  weareallhuman: {
    name: 'We Are All Human Foundation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Claudia Romo',
        lastName: 'Edelman',
        emails: ['cedelman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEy1MKj3k_hsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612910467614?e=2147483647&v=beta&t=MxufqidF2fMd95UfqFy7wsQSVbHvlqW09KbCXwlW5O4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carolina Guardiola',
        lastName: 'Romo',
        emails: ['cromo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFfEcRr6bvUcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628555664884?e=2147483647&v=beta&t=yOJxKHTARMTJkCpdKHrkS4VZM4YRN7mhJzTbyhvNAk0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Denisse G. Gomez',
        lastName: 'Zepeda',
        emails: ['dzepeda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHvwyhJZig9VA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667482293800?e=2147483647&v=beta&t=h1TaGuFj6EwgOW5Wb4HD3d1dN3xnmr8a9Js7a4sALxU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ana',
        lastName: 'Saldarriaga',
        emails: ['asaldarriaga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGtpjGdMnJkHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669936711489?e=2147483647&v=beta&t=tELlsL3ZU8ry8Clj-PLIVjQZAYGjAdiJu-yNibc3kfM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'we are all human. |',
        lastName: 'LinkedIn',
        emails: ['wlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGyqLAWyhe_pA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701229600223?e=2147483647&v=beta&t=4j8GpdZgzvAF4maZucEJmAzuaOKAzAA5aC5AZOxEskM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  boddlelearning: {
    name: 'Boddle Learning',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Erika',
        lastName: 'Johnson',
        emails: ['ejohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Clarence',
        lastName: 'Tan',
        emails: ['ctan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGysdihbOXPZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664832115710?e=2147483647&v=beta&t=npbKz1zhAUSLmM9HtXTizP5HQKXhLJvCs-Endi3gVCg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Maha Abdul',
        lastName: 'Alaam',
        emails: ['malaam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGZzR7scZtkAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678919604130?e=2147483647&v=beta&t=wucNMQnMt93Svo7Zd8_phfeQVf4sL7b5RjplDuZ4r54',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rob',
        lastName: 'Fink',
        emails: ['rfink@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQERCKb69X_N0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636400015835?e=2147483647&v=beta&t=94MZTVT0qutdZP5Z8z4x1wL3w_t3lO4yWc9UH3LEW2k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Edna',
        lastName: 'Martinson',
        emails: ['emartinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH5Bay3peMP7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667316712282?e=2147483647&v=beta&t=40Yms5nH1CkJEFJ_MfH8rb96jghCAwk0f5DhYmvhYMw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  linbis: {
    name: 'Linbis Logistics Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ignacio',
        lastName: 'B.',
        emails: ['ib.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daylin',
        lastName: 'Marrero',
        emails: ['dmarrero@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFegekGMtbbNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556599841696?e=2147483647&v=beta&t=jMnRHPzEwc9pAeuButKBMopWXHRtIx1s-ir7OhOTubU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mirna',
        lastName: 'Oliveros',
        emails: ['moliveros@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGjLUU1gUFN1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668834523539?e=2147483647&v=beta&t=DAH7iLtzPbGfFYgHuxjuqR7Y4AdV3lAMfBhvp6TiXHM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alejandro',
        lastName: 'Junco',
        emails: ['ajunco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHOKgZvCjl2RQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555530779261?e=2147483647&v=beta&t=R1mfj7jVp6C4C0FFC6Ep52qsLp1uggSyDpytuukQZcA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jason',
        lastName: 'Drew',
        emails: ['jdrew@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFP2aOr-k6WMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672763145763?e=2147483647&v=beta&t=f8eJtE23dBt5Fvenj1TbfONkB0VHxLRAYNmWXnipKrc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  truemedia: {
    name: 'TrueMedia.org',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Oren',
        lastName: 'Zamir',
        emails: ['ozamir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHdL2s1H13GKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516241141663?e=2147483647&v=beta&t=NEsrKFrAc-N9X5DQh1EGFHBlOHeocVSopZq4xnD563M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steve',
        lastName: 'Geluso',
        emails: ['sgeluso@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGplIUiNrWD9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548877813001?e=2147483647&v=beta&t=IDk0T1OFs7Leg4jkH63k26gNZxHMXCucbIBLB0rzzv4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Molly',
        lastName: 'N.',
        emails: ['mn.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Langan',
        emails: ['mlangan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'James',
        lastName: 'Allard',
        emails: ['jallard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHzyNAv-bvPIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517370642826?e=2147483647&v=beta&t=gENv_tQEl0P33gKdACYyE7jf7dnYlSYNGe1_oIGHGbs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  iconsavingsplan: {
    name: 'Icon',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Laurie',
        lastName: 'Rowley',
        emails: ['lrowley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHWXVGgPxV5vw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516274602203?e=2147483647&v=beta&t=Hz19tORycT7PcQjX-ixGUPMVtPsJnMKl8Gmkf32UUiU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sasha',
        lastName: 'Mace',
        emails: ['smace@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQErBzaHbe2oCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572896866239?e=2147483647&v=beta&t=WL50RfWMw510gPR30wIYFNNKjEacfGY4YI3ZpFowAtQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lisa',
        lastName: 'Ross',
        emails: ['lross@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHqw_Y8WXMKhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600206494821?e=2147483647&v=beta&t=JVMNqMOsXp0cZT-VPMUcaKV1N_g73FU7CaE_jgVzXsE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Whiteside',
        emails: ['jwhiteside@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHo1CKWI7VPGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564499744914?e=2147483647&v=beta&t=YlpOk5uCgJoeAYMxed3i87wMdTrjbZMzL8DgM5ajosM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Karly Christensen,',
        lastName: 'MPH',
        emails: ['kmph@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEcgUxy9tVbyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670251746640?e=2147483647&v=beta&t=5tBS47PSpSKBTocabP6pJUeO7d9Fq28Apmnm7SmHQKU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  whitelabelstorage: {
    name: 'White Label Storage',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Peter',
        lastName: 'Smyth',
        emails: ['psmyth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFu1Az8w2LnnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681244746537?e=2147483647&v=beta&t=asp-pW7CN8UzRdkrtyhjHG3CdnTpLFppFbFBj-WDZtE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexander',
        lastName: 'Hartman',
        emails: ['ahartman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHqLQTDhGOILw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581297608557?e=2147483647&v=beta&t=Q74X3gmz_3KjflumMSK_aca4IBytMtO_HmBN4h_re14',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Lasiter',
        emails: ['clasiter@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rachel',
        lastName: 'Au',
        emails: ['rau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGt7CVhFLtIzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585211351898?e=2147483647&v=beta&t=bHzxYbSxvc-JqdoyACPONiMpOqdzolGp0vdjMNuIUGs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Russell',
        emails: ['prussell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE1Di2Pnk8ieA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659469534506?e=2147483647&v=beta&t=zzxVaoGAWPaUJThjCapZ1QwPudk-LecQ9DjRlmRjcjY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kapstan: {
    name: 'Kapstan',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Akshay',
        lastName: 'Pilankar',
        emails: ['apilankar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHisk_L-hd4sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627529111558?e=2147483647&v=beta&t=ADdE-hdQVsLRmsZlTYEd2f9M5pKDydGXRtzmzWZLDgs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mohit',
        lastName: 'Pahuja',
        emails: ['mpahuja@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEyYAmPTq3v-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604995384392?e=2147483647&v=beta&t=OJOmbYHEn5UAGXSA5vnyJepEqHE4Qcp1e7J7FRkhvBk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yannis',
        lastName: 'Weishaupt',
        emails: ['yweishaupt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFeqC-i_leiLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679061708496?e=2147483647&v=beta&t=wmwxmJl_iwsibLtd19h6TporjsimRSU3IS1UEbZkhRA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yazid',
        lastName: 'Charkani',
        emails: ['ycharkani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFf--MjtMANpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635242839229?e=2147483647&v=beta&t=h1mjwl27qjLG6TmwD6Rb7-50PEpj5e7HpMV_RdOBhi8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Filippo',
        lastName: 'Ceron',
        emails: ['fceron@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH7iAucYcGwBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517065524082?e=2147483647&v=beta&t=9uKFN24apWv6NjT3lq8wyRs6sPg0R76_Kv30_vH19OI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hireteamup: {
    name: 'TeamUp',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Janelle Celine',
        lastName: 'Tupaz',
        emails: ['jtupaz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF4gRFCTcheyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726164111179?e=2147483647&v=beta&t=tOA2aXc1c5wJoiOZ8WiA2J1imtSRyCQgmcAeIHZHEMM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ethel',
        lastName: 'Verana',
        emails: ['everana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHWGhxcmbOiVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726389148062?e=2147483647&v=beta&t=w7fC4vGn54I9lI_sj3-9wZB-I34ikjK219X1hUI80xQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Moriel John',
        lastName: 'Dacuital',
        emails: ['mdacuital@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH2H4yhQSpH4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689744192750?e=2147483647&v=beta&t=0ioHawOwN37lYW9DDKfSPXBKLd8SrvJc3Py2BAs-9Fg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'nalyn',
        lastName: 'ortiz',
        emails: ['nortiz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGuLh3yr8UGuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699834727687?e=2147483647&v=beta&t=09fYhbJU6mOWM53UEAJE0b6Riaw6WzP5Ose0Qpfriwk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Harry Jim',
        lastName: 'Secusana',
        emails: ['hsecusana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGihOpX5uo2bA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1669076353359?e=2147483647&v=beta&t=qPb02ByVDMbnYs2F-Lw1nH5amBr2A-XT2v_VkcBa-sc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  longevitydocs: {
    name: 'Longevity Docs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David Luu,',
        lastName: 'MD',
        emails: ['dmd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG4pp8wTtr_0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709859968822?e=2147483647&v=beta&t=VHBBYbLDLE0bTCsoJpuy4lB1ceZbeo2c_yrN34dx6Is',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeffrey Rouse,',
        lastName: 'M.D.',
        emails: ['jm.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGzneWjyjC7Pg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683044939812?e=2147483647&v=beta&t=DWZ-2c4LY2xIn_-bLzXaPbQa6JFUj9PWKnNkX4gkVNk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Angus',
        lastName: 'Tallini',
        emails: ['atallini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF5F47sVwUITA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682602917434?e=2147483647&v=beta&t=8WJ6FVGZQHnpvqDCrjKs3vQJQQqUuVvpD8awKc62Xss',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dr Ankit',
        lastName: 'Chawla',
        emails: ['dchawla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEFaK8n5eHw-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711727822686?e=2147483647&v=beta&t=XlGh_h2rQRN_taSo_SpubHGt6Yv7hs-3nfrX4Ge91xc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Miss (Dr) Mayoni',
        lastName: 'Gooneratne',
        emails: ['mgooneratne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHx4E7Sdh27hw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598301947580?e=2147483647&v=beta&t=rWWbpZSus0Z1nLqEwYzVHSPMbTDIiYEp79k0wYvgFSs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lawnTek: {
    name: 'Deep Lawn',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ali',
        lastName: 'Sabti',
        emails: ['asabti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEoNKnM9fpiVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715873924281?e=2147483647&v=beta&t=CdPQAfrMBA1zTZ0XChcX4jMB7Ubr8TdmQwkMCuj4PdA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Madeleine',
        lastName: 'Lee',
        emails: ['mlee@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Maya',
        lastName: 'Hart',
        emails: ['mhart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFaDQSWBb7S0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647043181778?e=2147483647&v=beta&t=T-j31irLipEKYEeCx7E8hVm6Ljl6JlJwJWJFw8m_NFc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nicholas',
        lastName: 'Lee',
        emails: ['nlee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHDeDmLuKHfZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586625109352?e=2147483647&v=beta&t=Qag-dCGUbfmsiwQ2gCMRDPGDMJ3BOZ9Ciii1tq2a1U0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sarah Van',
        lastName: 'Norden',
        emails: ['snorden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEFcG7hHa3NAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630879138495?e=2147483647&v=beta&t=wnIvHSuhCP49N7App-JDqmJJH0FkNtJEqaS7xB6ELmA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  theramart: {
    name: 'TheraMart',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Victor',
        lastName: 'Leal',
        emails: ['vleal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGMMSmRL3irvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688173038141?e=2147483647&v=beta&t=Brg4tCYebuDRZ9OmvMtuBLefgD6g40Ca3gjThpyEy40',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pedro',
        lastName: 'Maia',
        emails: ['pmaia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG0p4iLkZcjIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638564233639?e=2147483647&v=beta&t=GIoqTZhVjEGg007qBdmadedsAUBEcMrLVfjP33Iw8d0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mariana Rios de Isasa,',
        lastName: 'CFA',
        emails: ['mcfa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Luiz Henrique',
        lastName: 'Zamboni',
        emails: ['lzamboni@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lara M.',
        lastName: 'Castillo',
        emails: ['lcastillo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQErmNR_6NDh6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703590374243?e=2147483647&v=beta&t=pFb4nFENMJ3UNOfSBU5JIAypF-eur7fKaSxzsUIOvko',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  deepersignals: {
    name: 'Deeper Signals',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Uri',
        lastName: 'Ort',
        emails: ['uort@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHwTZVy5Xb3SQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600906110133?e=2147483647&v=beta&t=jCorctH7HVVXHoJnD7Lt_alFxizxZTWxqWAppZI3N5k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Reece',
        lastName: 'Akhtar',
        emails: ['rakhtar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQETIYta27tBJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706896271273?e=2147483647&v=beta&t=CrNcek8m5PUfbE2KKKZZYLmqfsPSvgqdhLTCpor4naA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pavlo',
        lastName: 'Cherniavskyi',
        emails: ['pcherniavskyi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFEupb9HD_JYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561465748315?e=2147483647&v=beta&t=pHTiItMTRi7CNK0u-d1sZS2Hm02AIKb50zRHSObZ2zs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Ahrendt',
        emails: ['dahrendt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGKf27ENN8ZDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587996366306?e=2147483647&v=beta&t=iyVRjyWbW6nrcsRLPl4G3A7biaY5Cy4AEQDPn0gFq7Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matej',
        lastName: 'Bernatovic',
        emails: ['mbernatovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGnyFaRqKxXiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587300504456?e=2147483647&v=beta&t=qshqAGFqrv8X_pXlTrtPVb5s2C02q7m7qeACXX2J2uc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trynova: {
    name: 'Nova AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Evgenia',
        lastName: 'Trynova',
        emails: ['etrynova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGOIJFS6h6dIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517676993376?e=2147483647&v=beta&t=JvwKypL2K2iwM6JHXXvyoUNG3A9mqFcxUEvWjXvKVwM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Zach',
        lastName: 'Smith',
        emails: ['zsmith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amanda',
        lastName: 'Ávila',
        emails: ['aávila@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEcz65ESYQl7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635792895297?e=2147483647&v=beta&t=qPI_0B534m_DFWDHJb-DCY1orfpMdpFUTBXpkb82lcY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeffrey',
        lastName: 'Shih',
        emails: ['jshih@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEioYjAhAkB4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557092089845?e=2147483647&v=beta&t=7ygrQbqE8xDtB954sfUWasHdbiuIT2dZWTDJXX81CbE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Juliana',
        lastName: 'Yamagi',
        emails: ['jyamagi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  weareindustrial: {
    name: 'Industrial Strength Marketing INDUSTRIAL',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Soto',
        emails: ['jsoto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHUkUsv8PdY3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707487605847?e=2147483647&v=beta&t=nHthLMkuCWUVSf0AlnxYHOT4e_e8eBAPUlAy_TAUeSo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Allison',
        lastName: 'Davis',
        emails: ['adavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGwGP_E1IZ_Xg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694731741745?e=2147483647&v=beta&t=y3sM2CFfShvj45YcS1qj8aaEVVfz6NgLsIrrKMIQt1E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jim',
        lastName: 'Eisenbeck',
        emails: ['jeisenbeck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGS4v-QurODEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560205787921?e=2147483647&v=beta&t=P4Tl0FvszGn4SK50HtcYlI_JbRdH9CqlSM72RebPMbY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Patrick',
        lastName: 'Copeland',
        emails: ['pcopeland@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: 'McKelvey',
        emails: ['pmckelvey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEarUEqWID6vA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573241395081?e=2147483647&v=beta&t=zsVCFnOtjTt850ViohPpDVwmAlnDpFIFXRyKVczMjZA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gclegacyconstruction: {
    name: 'GC Legacy Construction, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jake',
        lastName: 'Grosswiler',
        emails: ['jgrosswiler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH9niX1XQSOJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611269318140?e=2147483647&v=beta&t=32hQPvDkt1OWcXl7F-n3Vq0zBx0mY_iGsbC1AEhKKDQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sean',
        lastName: 'Kerr',
        emails: ['skerr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sarah',
        lastName: 'Card',
        emails: ['scard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH1ImATasqUkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590161866311?e=2147483647&v=beta&t=Sw4w7ceAcH-C7JcT_BDCXwNcmXRF-yKgZqx5THIO0z0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Frank Hagaman',
        lastName: 'Jr',
        emails: ['fjr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGLPMJqI-Rgcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1538072312988?e=2147483647&v=beta&t=KYcjdsLO8cm6vZYl-Ec6xiR3Mjy7PLf6tCQ73CGRsoI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Delmis',
        lastName: 'Flores',
        emails: ['dflores@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFCpf5w4IY9vQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663107415201?e=2147483647&v=beta&t=s633pRxX9xGLJbHKZ42-AcK3ti8d5S-0cZvu3-QyeG4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pynecone: {
    name: 'Reflex',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alek',
        lastName: 'Petuskey',
        emails: ['apetuskey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEIQEFFXSexlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691205339818?e=2147483647&v=beta&t=BDUz0DEf2AAglPXl_NCphLCxsGubv0RWI94TSJ9y8W4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'elvis',
        lastName: 'kahoro',
        emails: ['ekahoro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF4NA3V-B-RiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705619759076?e=2147483647&v=beta&t=2Ma3yuFJ9nhABTwIsmwwfSGDqGnbGKsRsQbg9Dxhy4E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tom',
        lastName: 'Gotsman',
        emails: ['tgotsman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEnXFcWzkL6pQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709238631745?e=2147483647&v=beta&t=zhfV_ZY0aucKRiEYV2gIWjj5YJYj6ojvKlqDVQjWjzQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nikhil',
        lastName: 'Rao',
        emails: ['nrao@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHbnWf42qLcxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634795208811?e=2147483647&v=beta&t=aEYFN3lwETy0LLF2VzzVSjj0BwWwdvGUnkRZDT98W1I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mike',
        lastName: 'Meyers',
        emails: ['mmeyers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHgRx1ZR504aQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632707258851?e=2147483647&v=beta&t=g0WG2pObzmIipTIF12b9Bv_CdNObygoSm4POT5JQ6OI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rlz: {
    name: 'Realize',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joanna Gulløv',
        lastName: 'Widding',
        emails: ['jwidding@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEuHSIFK7mNfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594072677702?e=2147483647&v=beta&t=eE8GWBVYf9lFWZbyVE7xmwWLyKnAV66CVY26bplLztY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Charnette',
        lastName: 'Howard',
        emails: ['choward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH3XPI9Z3USNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644672699913?e=2147483647&v=beta&t=DibLJ80pnD52okVGnm2m6RSb-Tj570midSC9ZVKUPrk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Therese Marie',
        lastName: 'Dyrby',
        emails: ['tdyrby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEE3EB4SVhA7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656767792474?e=2147483647&v=beta&t=7t2fVZuLKmEcGpEjtuJVz6rBTp3FNNEAjcmve3Etrhc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hattie P',
        lastName: 'Crawley',
        emails: ['hcrawley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHh1aOyW2vahA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718210676431?e=2147483647&v=beta&t=jlKl4aL9TyNYEjZiG72M0R0pecbZjBvtVf7ffduglbA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nicolaj',
        lastName: 'Ejler',
        emails: ['nejler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFhrkEgQyTAEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517743633498?e=2147483647&v=beta&t=3Mbdlw34_WJo8riwQEpu18lBak-gTDLQh5Jc0fTCSFU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nexusedge: {
    name: 'NexusEdge Education',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eddie',
        lastName: 'Lin',
        emails: ['elin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHPa37VF9-tig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517668237534?e=2147483647&v=beta&t=tVO1fBcGztAkH0OKh2-95ujZRCIwkFdqCrEgc34yL5g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'NexusEdge',
        lastName: 'Education',
        emails: ['neducation@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG0O_qBxT446w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517482677861?e=2147483647&v=beta&t=xz2mdGekmnN5RIWHg7736Y8HwDsfvepmqfMoMwkO8ME',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Norah',
        lastName: 'Chi',
        emails: ['nchi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEkSJoCnXzRtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694390624520?e=2147483647&v=beta&t=S1SlFDmOSbRKDEW61tfukc9U7TZ32lCq0Jv4_UN8vRU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shanti',
        lastName: 'Akkineni',
        emails: ['sakkineni@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gregory',
        lastName: 'Herrera',
        emails: ['gherrera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEH6SaVgVittA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713423628125?e=2147483647&v=beta&t=fdpVDaQs0oYLq_r1wbuasjb7NF9UpWzCoVZwwJkDoFg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  knockmedia: {
    name: 'KnockMedia',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nate',
        lastName: 'Berger',
        emails: ['nberger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGhQvWqforI2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724264989478?e=2147483647&v=beta&t=udSykDtLaWeZS_ieir4K76b2hHhYIToJCu-aHVGEjtc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tara',
        lastName: 'Burguiere',
        emails: ['tburguiere@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHmdn4LxHAveg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658769113050?e=2147483647&v=beta&t=jeRgSOKHKmUQsOpNHKNdPqld3uGZU7cCUKARIPPllUI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Justin',
        lastName: 'Romine',
        emails: ['jromine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFFZCVEBbx9hg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718246150358?e=2147483647&v=beta&t=QlgFlNGuKtVZiR11IBCZgnnh6rFmoRAvXaIDHi5Qj6c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Caleb',
        lastName: 'Whewell',
        emails: ['cwhewell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQElLxLpGCil5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517453122747?e=2147483647&v=beta&t=OE5gyh-KSmoKmSyi7hsgO0Whr3KqCNePnfzZhthF5mU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Robin',
        lastName: 'Breen',
        emails: ['rbreen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG8oFlvZVluMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689270198534?e=2147483647&v=beta&t=rOKIGNG0De6zrG2h-emVwYTKSaMOPy49nYhPlw-2J9k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dcatalog: {
    name: 'DCatalog',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Raviv',
        emails: ['mraviv@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEoF7hSyWHNtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516608969876?e=2147483647&v=beta&t=webOQGWCOQ0ozh6X17VeXyYfRB9ztkx_mx9jgj3_jJo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Costandinos',
        lastName: 'Bakouros',
        emails: ['cbakouros@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG-Zd8rvtxoBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692632909212?e=2147483647&v=beta&t=jTPW0ZhN9kwQqRiwH5sHTJ4U9DQpI4707QuWoFdWgw8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joshua',
        lastName: 'Salamea',
        emails: ['jsalamea@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mirit',
        lastName: 'Elimelech',
        emails: ['melimelech@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE7Z7isLB8rQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516355719225?e=2147483647&v=beta&t=gq1Msc1o4i0SVnAm0nuNot6XFQtqle3H7IyRgB0zbFI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daniel',
        lastName: 'Weintraub',
        emails: ['dweintraub@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFJp0lOkq-nCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685152127777?e=2147483647&v=beta&t=Y1jJDknfuaK0iFT4-ReYPgxshXRNOyn2gebMXWps_yo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  energycreditconsulting: {
    name: 'Energy Credit Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Charles de',
        lastName: 'Jager',
        emails: ['cjager@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGatL0h1ILOxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704928223443?e=2147483647&v=beta&t=u-pWpdJANmY46_CiyE7AJRztM6q9PG26CNdPlyPDrYA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Evelyn',
        lastName: 'Thayer',
        emails: ['ethayer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHy8ZCyTu-LJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714422703350?e=2147483647&v=beta&t=tJQp0ptQlURP6odjdqkznk76uU4cSaRNHLEcrhnCXLE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jason',
        lastName: 'Riggs',
        emails: ['jriggs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG6JEtHHnJYow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531866499833?e=2147483647&v=beta&t=so4kPeHKAJpyuSWrSA4eSzbjfOp_f7tGBkWumocFAZM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tommie',
        lastName: 'Rooney',
        emails: ['trooney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEjtxgJ572ZKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694569545146?e=2147483647&v=beta&t=fSbkf5yC1EWDNN0bvuycXRtZ2LcBTLwZ34stU6_AXOg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kim Smith',
        lastName: 'Barnett',
        emails: ['kbarnett@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  neurocare: {
    name: 'NeuroCare.AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Junaid Kalia',
        lastName: 'MD',
        emails: ['jmd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGlduei-jNy7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687786293181?e=2147483647&v=beta&t=Ho3X-x5-srs1u1a2QXHsNxX5g-H7HA5pKcDpg4iceg0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Palwasha',
        lastName: 'Yunus',
        emails: ['pyunus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHTDQ3xq3gUag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713344356164?e=2147483647&v=beta&t=LVzOC3h_E_dcpw3H5wRp5SGslIal03hI3fAcs7pXicI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rabiya',
        lastName: 'Farooq',
        emails: ['rfarooq@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGWlM1dwS4waw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1626336053693?e=2147483647&v=beta&t=Y_4VLF-FKaaaCwVfqBxCPIj7u4qr7gAu8Yrdh1sUW-8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Arif',
        lastName: 'Hussain',
        emails: ['ahussain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGVN3xuL1uyVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722332487359?e=2147483647&v=beta&t=Oda0nV69X867Ck_LOShJONqvojKIUQy0EDqQoTB2lPQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Usman',
        lastName: 'Ahmad',
        emails: ['uahmad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEYg7tBTPXWDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682453559030?e=2147483647&v=beta&t=mJlGCk-KAlusobVSIJbDuW0LseyeX7k83M1M0XW3nvk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fundminer: {
    name: 'FundMiner',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chelsea',
        lastName: 'Lamego',
        emails: ['clamego@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHyzmt52KKbUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665867770104?e=2147483647&v=beta&t=XAB7BSZQiJcQU2YL97T3URyLTX92BbgzniXE2ka01vQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alejandro',
        lastName: 'Stevenson',
        emails: ['astevenson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFz04vUi0vvAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678036924208?e=2147483647&v=beta&t=5b4DModaOKIlrRyiEoytwiDIcYlaknbiidavHubKAr0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Haider',
        lastName: 'Ali',
        emails: ['hali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEo6T36OHvldw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668719350288?e=2147483647&v=beta&t=WUlsQnYvGLCDrld-l6qn4Jj2nzAxNUPaq2MO5GYBz9M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marleen Ghattas',
        lastName: 'Hoskins',
        emails: ['mhoskins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carlos',
        lastName: 'Mora',
        emails: ['cmora@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGm2pVq5KNaEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713133592298?e=2147483647&v=beta&t=OqmIZVA2m902QPiziFLpKKKK_v9pVTI5ZNnG2qNwVL8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eoddistillery: {
    name: 'End of Days Distillery',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shane',
        lastName: 'Faulkner',
        emails: ['sfaulkner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFGurekYBI6Gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651975538192?e=2147483647&v=beta&t=-w5jgoU6WxtT1kUYCYrSm0y-BhQhujYZU9PB3RFC6k4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tyler',
        lastName: 'Hollowell',
        emails: ['thollowell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHKUlSP2jD55Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701812069092?e=2147483647&v=beta&t=10Hq6t8qyKwrZ8P0L3CTBpg4RAFKYEV5EYyVnoYXPHY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Evan',
        lastName: 'Faulkner',
        emails: ['efaulkner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGbf-teIIkqHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697554541041?e=2147483647&v=beta&t=lDyMPx6rl64ISeoxPAKW--pUPb4Kc4TfK7JXtQsRACM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Oliver Earney,',
        lastName: 'CPA',
        emails: ['ocpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH9Htd2OJCWfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635122538595?e=2147483647&v=beta&t=JldlNzPFm6obSTUfSUqP6V_Hy8WEFSizAZCorfpP_1U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Beth',
        lastName: 'Faulkner',
        emails: ['bfaulkner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGudr69VM8r4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602124833951?e=2147483647&v=beta&t=B-itFeGCvxNLDgJFMOz5-zSKxTN0H_oGuptspoWlV6I',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  alysio: {
    name: 'Alysio',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Justin',
        lastName: 'Ashby',
        emails: ['jashby@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aaron',
        lastName: 'McReynolds',
        emails: ['amcreynolds@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEbFFamdKBWoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696867094706?e=2147483647&v=beta&t=lX3WFe49WsY_jIB0-WECI7ozh_v4X-M9Ajpk74YWXVE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ryan',
        lastName: 'Harris',
        emails: ['rharris@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Connor',
        lastName: 'Salmon',
        emails: ['csalmon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tom',
        lastName: 'Butterfield',
        emails: ['tbutterfield@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG_dRFNUlkJ2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696956268436?e=2147483647&v=beta&t=F45IKFzN0S4hUBBKushJAf7blWWPnMmXrL9QTO23jkw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  feelsunnie: {
    name: 'Sunnie',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lisette',
        lastName: 'Howard',
        emails: ['lhoward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHl8bTJMn522Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698328958176?e=2147483647&v=beta&t=jrbLaXj7ifm_F-_jDFzkEuxzBt9fZw3A4_797aaUI8I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Katy',
        lastName: 'Tucker',
        emails: ['ktucker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF2mPXsQcVWCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596956713886?e=2147483647&v=beta&t=HPlc0qZQZKDklETxrys9qtn7BNDVQ251pK72XZ4cyZ4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sunnie',
        lastName: 'Planthold',
        emails: ['splanthold@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF2zOfUbguPgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517672398340?e=2147483647&v=beta&t=DIpL8vE024zE7wsPQSnmXoXzb93yWoivf8kJH5wYi7Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Xiaoqing (Sunnie)',
        lastName: 'Wu',
        emails: ['xwu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH-BZeUA2tiHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590463046541?e=2147483647&v=beta&t=Py4mIz2MI_Nfyar1n5eH2fjqlJcnFVe7PCq6XkxxA5s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Monica',
        lastName: 'Sunny',
        emails: ['msunny@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE537oRR9OsVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626971395466?e=2147483647&v=beta&t=bzr4UWo7BwMoTXQv7KIbYXbG6ylBsgCdvViqu1ud46E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  handspringhealth: {
    name: 'Handspring Health',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sahil',
        lastName: 'Choudhry',
        emails: ['schoudhry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHbkrD81c4Wmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628466577127?e=2147483647&v=beta&t=TDdJbQ1qdr5sRlTJv2dHjc07j-3KschhsqruLMUTUm8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kwasi',
        lastName: 'Kyei',
        emails: ['kkyei@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEGxytju3PuBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671135931032?e=2147483647&v=beta&t=droUNva4VIjS09HqMB5ZdcaL7vfMlwiI_Zk7utuFNnA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amy Kranzler,',
        lastName: 'PhD',
        emails: ['aphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEbyrpWtTyoSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516764254645?e=2147483647&v=beta&t=PfH9EhuMcQcyJmvu4ZYafdZWx3fxlKZmHfVDASAK3c8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Wendy',
        lastName: 'Robinson',
        emails: ['wrobinson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elizabeth Hahn,',
        lastName: 'LCSW',
        emails: ['elcsw@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGuONualaIlUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587427682535?e=2147483647&v=beta&t=kSe8LwCpV0w0gwMFpTlAhacojeYGzHc4BDBuV2MNvCc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  flyingzebra: {
    name: 'FLYING ZEBRA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'BELVI',
        lastName: 'CHOW',
        emails: ['bchow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGTaIs3vDnAPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628915855654?e=2147483647&v=beta&t=qdLXV1-hlabzG4l-oSLIoRc3ktWSV7VGU5niO7UGNTE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Benjamin Van',
        lastName: 'Damme',
        emails: ['bdamme@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHgmggQDllTCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688305102320?e=2147483647&v=beta&t=MYxhU2gxq35AIf2WBzCjJeeEErPnAHp-sELN7VVBun8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lynette',
        lastName: 'Boshoff',
        emails: ['lboshoff@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lucille Fox,',
        lastName: 'Voice',
        emails: ['lvoice@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFcuZLj3YnmPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573710461955?e=2147483647&v=beta&t=zHTBQD1Pr1CTtUQO7De_jxpJ-QWRmERXxoS5V40Zeqo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Teresa',
        lastName: 'Hernandez',
        emails: ['thernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHw-37DCNOajA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1520286201417?e=2147483647&v=beta&t=BVRUfnFzouIwtP3rlnjhP0U76LAWe3FMkqBYDyObieQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  clotheslyne: {
    name: 'Clotheslyne Techstars NYC 23',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Olivia',
        lastName: 'T.',
        emails: ['ot.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Camden',
        lastName: 'Olivero',
        emails: ['colivero@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFYJTBEQZJdUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621012002067?e=2147483647&v=beta&t=wlg8L31OdY1RCr_VrW1VG3jixK99GSXGx3evwbryu9o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Kallick',
        emails: ['skallick@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dan',
        lastName: 'Feliciano',
        emails: ['dfeliciano@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matthew',
        lastName: 'Ehret',
        emails: ['mehret@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHZ5YuaiEev7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620663066576?e=2147483647&v=beta&t=bZ3eA23eYv9UwMBmsVHHN3E21iJmmOy8wjcfoAQ-7QU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eosgrowthpartners: {
    name: 'Grow IT Partners',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alessandro',
        lastName: 'Cutri',
        emails: ['acutri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGfp1FTQCawjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725917030834?e=2147483647&v=beta&t=7M8ZbbJBtOQ9FCrMshzr52UbWYYgnXHazi-RfqR6cc0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Terrence',
        lastName: 'Branley',
        emails: ['tbranley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEjtmP3hKmJgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711162191229?e=2147483647&v=beta&t=0wG_xbeeof4n60R1c6hhWSHFDezxOFZoYof9y3ik_Zc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Austin',
        lastName: 'Neudecker',
        emails: ['aneudecker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFjYWWXIFMXpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516218530515?e=2147483647&v=beta&t=9mN1qH6FsiAuC9sJj0jVxeX1tPOHhWV1z5uNdlLFbyU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Srikanth',
        lastName: 'Seshadri',
        emails: ['sseshadri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHqji5kNrsRwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553707870399?e=2147483647&v=beta&t=2ITQ0l2SnGZH3XMgTt4zySckKgFYKFTpHfKIkwb4Vew',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: 'Conroy',
        emails: ['pconroy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG9fmM998UFig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560363639753?e=2147483647&v=beta&t=gZcKQL6bg_TYczYdQy_suwFpMNJZwVQ-QoVaCimEKXE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  stickydigital: {
    name: 'Sticky Digital',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Emily',
        lastName: 'Chou',
        emails: ['echou@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFfyuXJeBsVcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623623973001?e=2147483647&v=beta&t=0PmRHo38wRx4cmesvmW-VTll8Hscfic9pZFtat7CbCE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Grace',
        lastName: 'Garven',
        emails: ['ggarven@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachel',
        lastName: 'Hanson',
        emails: ['rhanson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFM82YZfYceVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719264042933?e=2147483647&v=beta&t=FtbFFQQDEBBzC9wzGPVFuzRFGTou6yrTDyuvo_Q6Bp4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maria',
        lastName: 'Gourgeot',
        emails: ['mgourgeot@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHDsT8NwI2J9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630507554768?e=2147483647&v=beta&t=x_-eLfiBNRddkCPFKq4iGUnpldLuwXCvAl84c10nvko',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zoee',
        lastName: 'Simmons',
        emails: ['zsimmons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGTHI6zs8N_lA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701065489758?e=2147483647&v=beta&t=mTkpePqgGkM1sQJ1n9mFE_hOGfO1z1gkfrLtNQiyoGs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  projectinsulin: {
    name: 'Project Insulin',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric',
        lastName: 'Moyal',
        emails: ['emoyal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFtK2xbOdr4Mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675708619279?e=2147483647&v=beta&t=DtR8Wjm_UJE4cb2jdx-A_kzh891efo0M4Q_fc-gJd5c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jasmin',
        lastName: 'Singh',
        emails: ['jsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGI53WRu_hALQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687560583276?e=2147483647&v=beta&t=br71ZzNDjjYnbBzcOxaedrTpt5OCA53foSllGOfW_YU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Devanshi',
        lastName: 'Vasadiya',
        emails: ['dvasadiya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG6_gDCKteb4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678161563396?e=2147483647&v=beta&t=UFnlRYMk_G92JuMtuzSWCgMxWBFeGQtZJg2HYVAe7p0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nick',
        lastName: 'Bayhi',
        emails: ['nbayhi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQER8qLluz6SEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684524495394?e=2147483647&v=beta&t=oG6FcMngtmCHu3QhPushzJXEnfRvYB05fZLz6-1nwu0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rishab',
        lastName: 'Shirur',
        emails: ['rshirur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF7mIHoFPvlgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722807207281?e=2147483647&v=beta&t=_2LQ7vAlo2Heao3gkQ1VzUuU_mWNA_1ymEerNR97JMg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  canopyteam: {
    name: 'Canopy Team',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Janet Marie',
        lastName: 'Smith',
        emails: ['jsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHE83wh_GhPGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597871476472?e=2147483647&v=beta&t=PAqMyulASECC4HIEcuktAoxQgZW0oxjSXRQNyXfgUxY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Claire',
        lastName: 'Lew',
        emails: ['clew@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEt-HjCDEkX6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712784438490?e=2147483647&v=beta&t=xWBnxksc3VErUI488RaXnJ9Yup9EnHd3TXtIg_o-TrA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carly',
        lastName: 'Eisenbrandt',
        emails: ['ceisenbrandt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG3-kiSno9g3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698953617521?e=2147483647&v=beta&t=yWJjXAJFmVFni4oN8TVKCVxT-jRphHwGmPwk8zCHdbM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Whitty',
        emails: ['jwhitty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGN0GmUZ6_TjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627386161519?e=2147483647&v=beta&t=YhwSLjesy8tWJaAuddFe40ExUW6aNhFdr88sjlp0g58',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michelle',
        lastName: 'Arteaga',
        emails: ['marteaga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGJpglZ0TUyfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695615436771?e=2147483647&v=beta&t=Z5DT_Bem7kfNOyA9S7GXxsWIyiHoWOrn7WbzmLpqlp0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  launchsource: {
    name: 'LaunchSource',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'GABRIELA',
        lastName: 'BRANDT',
        emails: ['gbrandt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHTI_3NkrKjZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517028205434?e=2147483647&v=beta&t=zJpE4RUKh85pJ8URq8e0gv5561RhW1UACiJtonHTXxI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kristina',
        lastName: 'Bompiani',
        emails: ['kbompiani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGb11YL7t3yTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680974765061?e=2147483647&v=beta&t=-ETOJCwCphEDcg9D4nkgw36H1GaE-0poqDZN255RZ9A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shamil',
        lastName: 'Hettige',
        emails: ['shettige@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQETBCt3EzihGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516807855939?e=2147483647&v=beta&t=2dkjpfMi9TSrdV-IAYHUScM83DbKE5-FZm7c208T68s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sylvia',
        lastName: 'P.',
        emails: ['sp.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFUzPNc05D9qA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683575508213?e=2147483647&v=beta&t=Mwfu_2UdDR_wJSQ2Hlnxnk6rDtEqEMD-k0PIUI2IjPE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Slava',
        lastName: 'Heretz',
        emails: ['sheretz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEmiaun0LOjEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1545265097405?e=2147483647&v=beta&t=KGAeKkOstjUHtSg-aUyoVWp8xplj4Hr1B23IIh4wP2o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nushama: {
    name: 'Nushama',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lola',
        lastName: 'Martinez',
        emails: ['lmartinez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kalena',
        lastName: 'Gravina',
        emails: ['kgravina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEYdDI94GmUPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683664127907?e=2147483647&v=beta&t=ykRTRsR9z5RuOPVCTOMHM-O0kUyxiKk2PoWBrLfwJ9k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eric',
        lastName: 'Stoneberg',
        emails: ['estoneberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Peter Grayson,',
        lastName: 'LCDP',
        emails: ['plcdp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stephanie',
        lastName: 'Maiolo',
        emails: ['smaiolo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFqe-OuOvvb5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697221227071?e=2147483647&v=beta&t=LF9q0UZIqggH-vUAj6WcgtwfzNFNreep_IpcfLld690',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  oak: {
    name: 'Ava Protocol',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Li',
        emails: ['cli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE8em7yIRKMuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639180324274?e=2147483647&v=beta&t=5P0NKD-X0CQwpPTUPMg9ynRmJ5tgU-z68p3Kbtr6rak',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sam',
        lastName: 'Shev',
        emails: ['sshev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG5EJFDZHyNwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710441127389?e=2147483647&v=beta&t=RXfFL1t4WD0rUKZ9wCYu9UhiDRbxbk9RFNQHP2lQS0E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Staton',
        emails: ['sstaton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFUyRPTNxTayg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573510414889?e=2147483647&v=beta&t=YrHJzV2sgqO3M-N0E0ZJCeG-dw9VQUSZ0k8tAefJWAo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'Zimmermann',
        emails: ['mzimmermann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGzOU9dd2asXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693504632759?e=2147483647&v=beta&t=TqP9DQ5OUa_z1DRoQ1-wKeG97oxMmvpDXAgf0P_RSW8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yikai',
        lastName: 'Jin',
        emails: ['yjin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEwxJYCrjH-yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699941220750?e=2147483647&v=beta&t=7R-lVceMNOIkXYetUpz3T9k1n_WHkPeg4KHkQdN2-gM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thegirlandthefig: {
    name: 'the girl the fig, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anne',
        lastName: 'Partridge',
        emails: ['apartridge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGGLULgRTZYhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542048312335?e=2147483647&v=beta&t=n3APU-lAO3hMC9LDsCMxPr6s-428zorcj20UQiLgv7E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joel',
        lastName: 'Hoachuck',
        emails: ['jhoachuck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE3U3fgeCKgDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516431312369?e=2147483647&v=beta&t=HldSBSZIWPdkYMpg7JhSmDys7q_0FvI6A2gw8siI5M8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeremy',
        lastName: 'Zimmerman',
        emails: ['jzimmerman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGVFr8uMSf7cQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516874434415?e=2147483647&v=beta&t=ufVbx1f78VlN4gIFj8mvJSAn2IjBqaaCsnLX-ahaWD4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Colette',
        lastName: 'René',
        emails: ['crené@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF23RoFWs0yOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517045188523?e=2147483647&v=beta&t=WxTxvAxg7VTMDBgUz05WnNMCFhjHum9OoxY7r0VGwyE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Randy',
        lastName: 'Collins',
        emails: ['rcollins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aPlayers: {
    name: 'A-Players',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lesya',
        lastName: 'Arnold',
        emails: ['larnold@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHuev69jC-OIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649773540244?e=2147483647&v=beta&t=1BhWowenONC7ClRhgYSMfCTSxJ3KnjutT2-_dsIrJKE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sivasangari',
        lastName: 'Subramaniam',
        emails: ['ssubramaniam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHwoVwCsMcn7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707877972241?e=2147483647&v=beta&t=hRxuc3_U_mpDwT7swUyz-rzLSNT3-7XIR7E3WRCZv0k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Steven',
        lastName: 'Hallworth',
        emails: ['shallworth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHRFFc1hSgD-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710013437112?e=2147483647&v=beta&t=TXvPYtj5XLgL5Nl6TRPnbnXvdZ2qR7srH3xpt4xaLC4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Darnell',
        lastName: 'McDonald',
        emails: ['dmcdonald@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH5UJOA3GRm_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617345728313?e=2147483647&v=beta&t=ncYMKHK4l_TX0jVn6zpPxPgExY117D1T8QIMz3P4Djc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mike',
        lastName: 'Marro',
        emails: ['mmarro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGoq03ICDoyUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632849809377?e=2147483647&v=beta&t=cvbhdrM32uhj5xzM2r6pP_SOveeZvr_SKxk5ATPnH_Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  choiceConsulting: {
    name: 'Choice Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jim',
        lastName: 'Lambert',
        emails: ['jlambert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQETkFiv0c3UtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567020557439?e=2147483647&v=beta&t=yYpEY5dcw1w-p6hxak9m707ju7Vop66M8USf7zTOoIQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joaquin Gonzalo',
        lastName: 'Navarro',
        emails: ['jnavarro@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Johnson',
        emails: ['sjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG9AzCGx2lVmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629905197739?e=2147483647&v=beta&t=LinZK-K5IxRJZbQyIancNBxCb5rUw8TUhJl6PUTUBRI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joe',
        lastName: 'Ruder',
        emails: ['jruder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG8dGvWe35WrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516798498803?e=2147483647&v=beta&t=arQGpIYCXlZphBHJohsuq2JlO7Z8KL4xEwjTzubktEs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yvonne McKinney, SPHR,',
        lastName: 'CP',
        emails: ['ycp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEgKoNq4qL-9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719697883551?e=2147483647&v=beta&t=nLIwvTOtq_YmcE22hec_XEKuMKrAW51fiMxhwrEXoPk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  thesocialcontinent: {
    name: 'The Social Continent',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shubham',
        lastName: 'Jain',
        emails: ['sjain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFPuckbqpVTHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703765360280?e=2147483647&v=beta&t=frq67HCJ8-5AtgH7XsLJ6GiAc247mFVwEfM1Y8K-ibE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Meghali',
        lastName: 'Thakur',
        emails: ['mthakur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFniHmu-3wgcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721648654683?e=2147483647&v=beta&t=YKuP-3B5fBHsdE6E4cxpGeJY_gDYP01PtfFzHDSc0V4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'The Social',
        lastName: 'Continent',
        emails: ['tcontinent@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFcGbV7kv-W3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675319989358?e=2147483647&v=beta&t=ltmZtG54M_YSoXS34nZyWGj5Z95Uvq9SXmnysnyNBs4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aditi',
        lastName: 'Polkam',
        emails: ['apolkam@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ishiti',
        lastName: 'Pandey',
        emails: ['ipandey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEnfKJudRveoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711972061545?e=2147483647&v=beta&t=22yt8MUenPLPdBXJ9JcwlPv10SfugkY_ZmP1JFSVcsQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sstm2: {
    name: 'System2',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Nutter',
        emails: ['knutter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQESNHZUO4uh5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679927473608?e=2147483647&v=beta&t=Fc-t2Y9EuhNxSL8q-6Nc2nNAk7ba9DRsaUCzFyhnSWk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Yoko',
        lastName: 'Fukaya',
        emails: ['yfukaya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGrWJBZIykJTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649079531654?e=2147483647&v=beta&t=BEuXo8ehTDwoFxtIgH2ekrlHImgAy99dmuKwmMxa76s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Seth',
        lastName: 'Leonard',
        emails: ['sleonard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGdyaRd1ItM6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676059468271?e=2147483647&v=beta&t=JzZHDeKy2nLpYPDJ-pX3Rxg23PVhVCFQ97XWRo_eTjE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: '深谷',
        lastName: '羊子',
        emails: ['深羊子@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGrWJBZIykJTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649079531654?e=2147483647&v=beta&t=BEuXo8ehTDwoFxtIgH2ekrlHImgAy99dmuKwmMxa76s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mike',
        lastName: 'Kirch',
        emails: ['mkirch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEd1OY8kVAO3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628708959179?e=2147483647&v=beta&t=pA6Q2pHFYo5qd-JakVU9kniYfNhsSxeymN5TRd48W9g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fiercecreativesolutions: {
    name: 'Fierce Creative Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lindsey',
        lastName: 'Chupp',
        emails: ['lchupp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Julia',
        lastName: 'Kooser',
        emails: ['jkooser@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGM5A_CY39vUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725668305938?e=2147483647&v=beta&t=Bxq5jvT2QaUfyr2UVeG_k-LRm4v4MCQ8D1MnxQv1Fwc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vincent',
        lastName: 'Fiorita',
        emails: ['vfiorita@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH0alcODPFZVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724851797989?e=2147483647&v=beta&t=mPRf43yunREioLHPbaUPdADHpjDry0SHndeeU8G43PM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dylan',
        lastName: 'Prost',
        emails: ['dprost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGyyMwRy_mGXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632143910386?e=2147483647&v=beta&t=I022THWG7xKoiXdDtIZQweKuXiy28xC2q7boXCym9Sc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ash',
        lastName: 'Vogelhuber',
        emails: ['avogelhuber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHtZahJ9tlnrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725474103956?e=2147483647&v=beta&t=WzUGtJuDeZo-vtZS8xizWz2yF_l7knJVABj-h2Bpul8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  onstrider: {
    name: 'Strider',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nicole',
        lastName: 'B.',
        emails: ['nb.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFrflzrEWFuBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670790792589?e=2147483647&v=beta&t=zKosdq7aBB1XVTbk5JZBcqUNUis0zW7cSocS3f9RdeY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Strider',
        lastName: 'Wilson',
        emails: ['swilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHkmTVqlGU5jA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702043687531?e=2147483647&v=beta&t=fK2OyXPRcUqMbHIikATJYk07Li_Td63Xl9YZ8UmpuXE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dillon',
        lastName: 'Staub',
        emails: ['dstaub@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Sergey Z. – Kyiv, Kyiv City, Ukraine | Професійний профіль |',
        lastName: 'LinkedIn',
        emails: ['slinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFtDQPzBaP-Eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1562352092613?e=2147483647&v=beta&t=5M70fC2wcVQAyz7UmTG0Xv99BsHNWCSN1nIBF8-epPQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amruta',
        lastName: 'Kale',
        emails: ['akale@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  foliahealth: {
    name: 'Folia Health The Home-Reported Outcomes Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nell Meosky',
        lastName: 'Luo',
        emails: ['nluo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEQQ03gMEe_Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664894084107?e=2147483647&v=beta&t=L2Wf3mcchWQluXMbANwPnrOp3f51NrFwMef4cgcrSQg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Connie',
        lastName: 'Zhang',
        emails: ['czhang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daniel',
        lastName: 'Toffling',
        emails: ['dtoffling@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGspU4Ww5UfpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664975737014?e=2147483647&v=beta&t=fnkG1YzBzHDLeLG0-p0BsZ16g52HiMKg9d1N_R7c67s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jordan',
        lastName: 'Owashi',
        emails: ['jowashi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE-6sjI_vAy_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684259396841?e=2147483647&v=beta&t=qU56DRooPwum-CC2R0R6-8DXy36-hPPCrh4UIjz_28w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Samantha',
        lastName: 'McStocker',
        emails: ['smcstocker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHcj0Q7_yxbLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701803602470?e=2147483647&v=beta&t=nMWbWhe_0jX_--JsdH5EvBFRmM9uaelC7jOX5hsJR4w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  auxia: {
    name: 'Auxia',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sandeep',
        lastName: 'Menon',
        emails: ['smenon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH0-yNUTYMAtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656360884094?e=2147483647&v=beta&t=zm4dKsceTpmjYSFpUHRf9enXzKaWUN-a6Z-vswmhGnU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shinnosuke',
        lastName: 'Taniya',
        emails: ['staniya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG8o7TAmHdRKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697328888603?e=2147483647&v=beta&t=Y-WiwfykIXVyACaL6QTAomRHfxlmpwnwktmESeNxIng',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Asim Krishna',
        lastName: 'Prasad',
        emails: ['aprasad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGCH1UbZ-SC-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718486946810?e=2147483647&v=beta&t=QL7C03aRqlEEvq72ueLweWnOCQcH0ynlEG4gb6Lgn84',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sumeet',
        lastName: 'Kumar',
        emails: ['skumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGz5LzcqmBeAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674534553040?e=2147483647&v=beta&t=gP-WB9GrtGoQzA7r_lgZv2OBijCpmfRJhi8-MQNGxcs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ravi',
        lastName: 'Desu',
        emails: ['rdesu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGWy_vlaSKkaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516284237933?e=2147483647&v=beta&t=szE7kIIN9o8VtIOI_GCHfxxvZZPzkbhHozXMOuFg4-Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wartinlabs: {
    name: 'Wartin Labs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ankit',
        lastName: 'Verma',
        emails: ['averma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFdLmN7RFShTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720097202445?e=2147483647&v=beta&t=GTVho0lWBXKvsxCNflCvB32yYc-v3gkAQJJShO09q8o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'adnan',
        lastName: 'shoukat',
        emails: ['ashoukat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFqcCRtO0pDdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718272984898?e=2147483647&v=beta&t=SnR15PEWr1EXA7dujt1dnAwCMZx9AMiLAnJccA_5BmI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yash',
        lastName: 'Garg',
        emails: ['ygarg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF6JuXmVnHftg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636134183777?e=2147483647&v=beta&t=QQ4mjciUYSDJmXkHo5zYD1PAKZkUvuEuqwBl5s_QnYY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Varsha',
        lastName: 'Verma',
        emails: ['vverma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFE_sHLpcQikA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669824005157?e=2147483647&v=beta&t=TEsydOHyxTwu-ClGBIHvzeTEcJcuxxHYRXXql3fdhmQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Wharton',
        emails: ['jwharton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG9zu6Duv9a9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722616275673?e=2147483647&v=beta&t=DQW4Mt7PpoKqfyYFrhHAf547LdIoXJUMW_9zBIDTa-c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  d3: {
    name: 'D3',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chloe',
        lastName: 'Alexander',
        emails: ['calexander@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHKfhI6Q5j9kA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627318523194?e=2147483647&v=beta&t=TgOa_i52GeyLrjjCX6eH_pmQ2crbqiM1YxswC8oPSFI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mike',
        lastName: 'Gibson',
        emails: ['mgibson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Martin',
        lastName: 'S.',
        emails: ['ms.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGDSqdHqSE0pQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702554680302?e=2147483647&v=beta&t=aRziFJM1JQLLcqMGoRjOMVwvs-KoeK0SpnmZ_qdfBco',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ros',
        lastName: 'Haverson',
        emails: ['rhaverson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joe Liddane CEng',
        lastName: 'MIMechE',
        emails: ['jmimeche@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHYwlZjWHtmPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516388546168?e=2147483647&v=beta&t=kHIvqj6lJEvXbR3dDf6yvD3Wc7NU4yOItjeNUyz1Glc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kopperfield: {
    name: 'Kopperfield',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jesse C.',
        lastName: 'Vaughan',
        emails: ['jvaughan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEKkybwQBIw2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653611253026?e=2147483647&v=beta&t=biOL0gO4QWi8lMbF0ewFoHQ0aX5SbyyB06bj1LYGntE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Justin',
        lastName: 'Slutsky',
        emails: ['jslutsky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH9i3qcQnv53A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517000740264?e=2147483647&v=beta&t=2RHTiuDbYtdKEiDaGOF2Zxb-b0Y0AGPolWxQMhEVjoI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'Wong',
        emails: ['mwong@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Clare',
        lastName: 'Rubin',
        emails: ['crubin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFKLYSkQ1iqSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580772914506?e=2147483647&v=beta&t=UkDEoGMpLKVyPlFCbMIQ9VRmDvRYanNnqiq0WOawpAQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jason',
        lastName: 'Ginsberg',
        emails: ['jginsberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wearetheblueprint: {
    name: 'The Blueprint',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joe',
        lastName: 'Head',
        emails: ['jhead@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF2oX62vAecJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727956253844?e=2147483647&v=beta&t=boHAxziKLgdI1dTAouP7maZIcYczQ6bpOjji2m1cXZc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kaila',
        lastName: 'Wright',
        emails: ['kwright@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEOJMqEVtvlYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706735080342?e=2147483647&v=beta&t=2YHWxtpTswRqC3sLbfLm5yeRkTJrOm_im9RN6sRZdsc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gretchen Kelley',
        lastName: 'Bietz',
        emails: ['gbietz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHJMIajg5SWtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668914402839?e=2147483647&v=beta&t=IUUO7yLlHL6tzRjMc0rN_qiRWsF5s7vXI3t4sKxLf8M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ali',
        lastName: 'El',
        emails: ['ael@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ethan',
        lastName: 'Golding',
        emails: ['egolding@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGeJwA7hRHFbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675973121750?e=2147483647&v=beta&t=-iyL-nn5fxNYBhcgVG24p6znCPPsVL6CWxvwJZzgH24',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  size: {
    name: 'SIZE',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Schwartz',
        emails: ['bschwartz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH98UO88Gi7AA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622514546394?e=2147483647&v=beta&t=ZU5Fn7c3MvJQEuxwhxcvXc67TL3AKROwLdq3g3NlrCU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tim',
        lastName: 'Size',
        emails: ['tsize@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kristin',
        lastName: 'Size',
        emails: ['ksize@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kelley',
        lastName: 'Giffin',
        emails: ['kgiffin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEP9lcTzaKBvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648489568091?e=2147483647&v=beta&t=YJw-dSghhcuYemp1AMrdpkgX-3WWAqA_m5H6r0i9nlM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mike',
        lastName: 'Size',
        emails: ['msize@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fbabros: {
    name: 'FBA Bros',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aion',
        lastName: 'B.',
        emails: ['ab.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE99Tdm3WMrvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713540300083?e=2147483647&v=beta&t=Xw0X_Tb0rqrl9dcCgjmdsNTVbD1BPT8szy9vkd2zUq4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Melvin',
        lastName: 'Rios',
        emails: ['mrios@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGP5OOJjKPL3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712226688299?e=2147483647&v=beta&t=lxPZpyuQ0IIjwmbMsXT4EVK_CRNDEJncuhq8rlLTPK0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Wahab Ul',
        lastName: 'Hassan',
        emails: ['whassan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHk9QS0b8Ppbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712504833337?e=2147483647&v=beta&t=nJcuFjDO4uBJhI732iu1p-IHyVQJ-SAorsHtmmL73YQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daniella M Hernandez | Amazon |',
        lastName: 'WBDGCP',
        emails: ['dwbdgcp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Puff',
        lastName: 'Bros',
        emails: ['pbros@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHm7gosPUAEbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696867681249?e=2147483647&v=beta&t=iwJynNfEVXSInfj9AcnyGoImJZtmQc8LJ3ZnVHu4Oic',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  speedscale: {
    name: 'Speedscale',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ken',
        lastName: 'Ahrens',
        emails: ['kahrens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHJJx6b8eKQjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560952447169?e=2147483647&v=beta&t=fVQKVfCqKA5v9CLZSrYaHVhcFJCVPiJ-iMfU_Mfm59s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matthew',
        lastName: 'LeRay',
        emails: ['mleray@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHqYDFZbzgllQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516299590921?e=2147483647&v=beta&t=XCwku26xUY6nrawAJjuCn16t8nb9bwd3J95KPzxKQVs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nate',
        lastName: 'Lee',
        emails: ['nlee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFVDKhH74kOlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591654803007?e=2147483647&v=beta&t=UdpsOKtqOJ3l3UxqQI2oxlDaFODjcm-AB8wxEBl94MA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Caplan',
        emails: ['jcaplan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH-x2RU_mPbag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517756859298?e=2147483647&v=beta&t=R8BwiQ-4MtL8i9Cy7dIePucbAQ7lKeDkRuJSFDNqcws',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shaun',
        lastName: 'Duncan',
        emails: ['sduncan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH818yyuETDtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517667844475?e=2147483647&v=beta&t=mA8pq9E-z3mlADWsz7n_dHZbNoNdup9_XX615Dqt4gM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  myivy: {
    name: 'ivy Group Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Catherine',
        lastName: 'Graham',
        emails: ['cgraham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHWElBB7qxrmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1599773318248?e=2147483647&v=beta&t=ARspmC0Houl5V55pi5wB3330A5HFhX2Ur1A9DmrFgi4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emily Murgatroyd',
        lastName: 'McCann',
        emails: ['emccann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEglFBJHHNtGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729730780123?e=2147483647&v=beta&t=kkbq9vMoAeujHNmfwWzVEp2HdqCuPKFtV0t5Wc1j9k0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Laura',
        lastName: 'Caffey',
        emails: ['lcaffey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFQ9_fNyE7SMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715704285722?e=2147483647&v=beta&t=hT168kgn6NEAaGq6f4-9zDMj_g4kZGHCgIlKtLYfrA8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Adam',
        lastName: 'Halpern',
        emails: ['ahalpern@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tracy',
        lastName: 'Posner',
        emails: ['tposner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pragmaticleaders: {
    name: 'Pragmatic Leaders YC W21',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Talvinder',
        lastName: 'Singh',
        emails: ['tsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHTiKSF_PnaUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516496663419?e=2147483647&v=beta&t=fIHtbyUz2psAePwmx_m1BQj3bIlxNOAoqGtU5IFF2pQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rakhi',
        lastName: 'Krishnan',
        emails: ['rkrishnan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gaganjeet Kaur',
        lastName: 'Sawhney',
        emails: ['gsawhney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anand',
        lastName: 'Lamani',
        emails: ['alamani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF32OST1G7Pew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629056404550?e=2147483647&v=beta&t=cOp-ti7KQMMatkt0nNIytVI1r-ljY7khEU5zpeSwXVI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Meera',
        lastName: 'Khokhani',
        emails: ['mkhokhani@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hudsonBend: {
    name: 'Hudson Bend',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anthony',
        lastName: 'Chen',
        emails: ['achen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHZkPArSJQSwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625150413241?e=2147483647&v=beta&t=7JqVuauT2EFUAJgd2zN65DqK4CuLopBK0C5WVjEf6Jg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aastha',
        lastName: 'Patel',
        emails: ['apatel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHh_qczap_H_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700725274017?e=2147483647&v=beta&t=rIJOlk_UEF-6wA6j0ZBIOirr4Iy4AeehMPN_GjZhg70',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Israel',
        lastName: 'Ford',
        emails: ['iford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE3VXsWR4LX6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560819936342?e=2147483647&v=beta&t=h3_m_l12zTz1_jYNTElUQI8Q3aDRaxAvllodIqnMtsc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pranav',
        lastName: 'Tammineedi',
        emails: ['ptammineedi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGx8wLhnAgQ8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726806813989?e=2147483647&v=beta&t=XRZc7zJFal6f7aIpHsH-CK14PfsgZJ6w4b0WR0xMzfU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kendall',
        lastName: 'Sealy',
        emails: ['ksealy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE1pteDu92MBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702321508215?e=2147483647&v=beta&t=dNlRpyiy-xGCJiS_eh-KY2t6UnxpP9asZLMLEBaA7B0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  ksfamilylaw: {
    name: 'Kessler Solomiany, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marvin',
        lastName: 'Solomiany',
        emails: ['msolomiany@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGLr75GtbrwlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690408116992?e=2147483647&v=beta&t=gYxplv4Ec9IJOUovqr-AptC3pPJQ24TfLpCerLtaqZU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Randy',
        lastName: 'Kessler',
        emails: ['rkessler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFvjI0sHIO48A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652327613035?e=2147483647&v=beta&t=h9IamwWMNWvnkpn9D8WtQF29CXxt4e2niCbUk0XcFt4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Katie',
        lastName: 'Ehrlich',
        emails: ['kehrlich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGaVen8ZIzQTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543951462645?e=2147483647&v=beta&t=8iG2zQDcPuZFcjdqI6tzCQmCnujvPLXpEYuxIbj7PCs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anthony Rao',
        lastName: 'III',
        emails: ['aiii@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGjlGpvUFms2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602505457352?e=2147483647&v=beta&t=9z1KMLjq0ku2mqINtr6ROfb8lw4pywBL8DVW4jJOSCM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Melissa S.',
        lastName: 'Barber',
        emails: ['mbarber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFYDMPLaadbmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1562154592947?e=2147483647&v=beta&t=_3SEGXuZiLYLuin8RIqgMQtIe2eXHARrpMwSPZb7bmk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  slf: {
    name: 'Senior Living Foresight',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'Kitchen',
        emails: ['jkitchen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFTBK0tBltdnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596599345669?e=2147483647&v=beta&t=9A8t0nXyAEX3X1mmV0zRac5oMsOlOXEJF6_gUNExuRY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Macey',
        lastName: 'Mirante',
        emails: ['mmirante@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHLU04USIl2BQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682442759384?e=2147483647&v=beta&t=lWU2ru4xW4Gt29xrDJ65aRdSb8HaGZzgyZT8-uM301Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephen',
        lastName: 'Gorham',
        emails: ['sgorham@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Leanne',
        lastName: 'Siguenza',
        emails: ['lsiguenza@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lucas',
        lastName: 'Maki',
        emails: ['lmaki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQENdUQB1oKy9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710799361748?e=2147483647&v=beta&t=uR-RkdJF0k4zsUvbgR2SuJx3b1SQqnNJe7BEjPiw_8M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  segwise: {
    name: 'Segwise.ai',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brijesh',
        lastName: 'Bharadwaj',
        emails: ['bbharadwaj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG8Ywd1Ch18cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729784981046?e=2147483647&v=beta&t=3-UFYS8xtEzSgexKbpLFNs0vrmD5780LO91JIKZNP5w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shobhit',
        lastName: 'Gupta',
        emails: ['sgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHRbz7fX4MnXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722051198850?e=2147483647&v=beta&t=AsJnN_ICqVFHs9Wr8Y-eVaMLAjXMiZLFTFGbHjdufO8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Angad',
        lastName: 'Singh',
        emails: ['asingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFm3P8hWNaJlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726049993382?e=2147483647&v=beta&t=O59bkJ7xr0KLDEDYixoGnJR7c48JEIkhiYgycm1XU8Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kush',
        lastName: 'D.',
        emails: ['kd.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEowhWY_5HTlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604746052031?e=2147483647&v=beta&t=s-RiCYRhzNuw4Vs_XFVIQrAINplmAWd_nc5o8m3VuwA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mohamed',
        lastName: 'Shayan',
        emails: ['mshayan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG4PZY0lA2tJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607528922361?e=2147483647&v=beta&t=xBzjOlCuv4X2YTmPoh8GVmyIavWCNsiQPye4KXXJBxw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  brianferoldi: {
    name: 'Long Term Mindset',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Stoffel',
        emails: ['bstoffel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHRNjmPepVIVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702033414764?e=2147483647&v=beta&t=eD1PneVLUnAweRpjovbHuUGVttK9EsObmwXsxEry6ko',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brian',
        lastName: 'Withers',
        emails: ['bwithers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHsCztSZsx3vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685801579335?e=2147483647&v=beta&t=4BPjN_lNLonEIaeGOrFb3HruTMQpoygNnOgJty0Xa5g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Cochrane',
        emails: ['mcochrane@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGBjRMkMWp0Uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694194933312?e=2147483647&v=beta&t=PSfpwWnC9Ap5Oz9KfGHhRREoAKwX3THkzDmFyvrn2Zw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eric',
        lastName: 'Ries',
        emails: ['eries@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFc6Csl2Ck6aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516258313019?e=2147483647&v=beta&t=L7Sg9d2tEVxONDjVTesFWYvgeAq7mv0nmu6RYL1_BF0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'Feroldi',
        emails: ['bferoldi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHU_vrnNtv19w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704451388877?e=2147483647&v=beta&t=U67ipN5vmyi8TVNmhE8y3XqPVZxboikJWzDRQLTVH7I',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  riseupforyou: {
    name: 'Rise Up For You, SHRM-CP, SHRM-SCP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nada Lena Nasserdeen,',
        lastName: 'MA',
        emails: ['nma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG3bTaJEKJ1qQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724013406343?e=2147483647&v=beta&t=J5URggt6qbCF9I1ePPS9XY8wfYqgKXk_rGcsSKli68A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lauren Sweeney,',
        lastName: 'SHRM',
        emails: ['lshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE_0Pjkx6VxiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704489243616?e=2147483647&v=beta&t=rEyLUc6I2ZsVnRoyyd8KNAQTqXLrPnfSefQHDnMCVOE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tammy',
        lastName: 'Moretti',
        emails: ['tmoretti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFxMOrbnXeM6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664467665487?e=2147483647&v=beta&t=FO45EVHE8OKqXL19OAdpS_oht7xn4IQFNjJWak7jQOM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mona',
        lastName: 'Halabi',
        emails: ['mhalabi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF2l228rrAkeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702662922357?e=2147483647&v=beta&t=YDMHzfR66pufzWZuAF0HeAux5M4fahgw5v7J9jVVKeY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Richard',
        lastName: 'Hilton',
        emails: ['rhilton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFvFQLC2jMrfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702343240917?e=2147483647&v=beta&t=JoxPsFl0k6ekPIFR54IggXE1nnq5ptAHbKDPliizHdA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  leadeveryday: {
    name: 'Lead Every Day',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'Miller',
        emails: ['mmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQErI8RAlbytgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621450938572?e=2147483647&v=beta&t=EDxJp_DrznFSev5Bcg8szGk3Ysi6_gOmurY-Jj3gOnU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Fred',
        lastName: 'Turner',
        emails: ['fturner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHQ9q_-StZhWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728655581326?e=2147483647&v=beta&t=UxQAp1MdYUu27Nbyy3pDjkwZEj1H6OPrTLhV15Q9M8Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dan',
        lastName: 'Wilkins',
        emails: ['dwilkins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF9vHhW7E3XDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715640630552?e=2147483647&v=beta&t=Bqwz_Bpl38XIe-G7Spj5geBdOxoLX0yw-pJ_AtnFwmc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Randy',
        lastName: 'Gravitt',
        emails: ['rgravitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFYfNCFBV4xgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521818250923?e=2147483647&v=beta&t=BIj9oLnt3m_IZmjKWXMNrWdjdTWS81KgZkEGMYjr4Po',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bobby',
        lastName: 'Hobert',
        emails: ['bhobert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHckBOIJR8bFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705432935418?e=2147483647&v=beta&t=mukUIAd7xfp2WMap8Fn98t8XUQN1QBYgCPkLzRMGKgk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  abodehr: {
    name: 'Abode',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ben',
        lastName: 'Siegel',
        emails: ['bsiegel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Evan',
        lastName: 'Deere',
        emails: ['edeere@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'JUDITH',
        lastName: 'ABODE',
        emails: ['jabode@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kyle',
        lastName: 'Stoner',
        emails: ['kstoner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGvl70MQOR-eA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664991588039?e=2147483647&v=beta&t=RUMGay4G8iBIyVqiwVwsdq-fGw25Kqe7buwHHYkNQ8M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ruchi',
        lastName: 'Sah',
        emails: ['rsah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHbdN9u3fLNpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517417066386?e=2147483647&v=beta&t=9HhHYvmRvGk0Bgyl4tDLJHIHwdACoMK99uyrZ-awXKE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  happyrobot: {
    name: 'Happyrobot',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pablo',
        lastName: 'Palafox',
        emails: ['ppalafox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGjnrYI0wUNXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689822887240?e=2147483647&v=beta&t=OkcP6apVfjB9yc6J962opRnLyEx3GHPA-FaIerjlIMA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Javi',
        lastName: 'Palafox',
        emails: ['jpalafox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHoboKD6KHf2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689979013812?e=2147483647&v=beta&t=F68W874BBrCz-1p7OBfrgpo0YoK91MY3AZwCndAxekQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Luis Paarup',
        lastName: 'Peláez',
        emails: ['lpeláez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFp40kQ4phKeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689987734380?e=2147483647&v=beta&t=TCLzSwR9Z15MqJWyKuGrwSMCj0BrYz3WtaE72a8fV7k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Taher',
        lastName: 'Poonawala',
        emails: ['tpoonawala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQETWRcQU_ITeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728015643264?e=2147483647&v=beta&t=VQLkTsCnSrvFKZuHr3uak1st1u9KiXEsu_3qjRJMI4M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shivansh',
        lastName: 'Soni',
        emails: ['ssoni@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGWwZqZ6_tSCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676878390737?e=2147483647&v=beta&t=iAJq4vwtQjM-9geI8ZXaNDM-PJaJSwtP9Dh4JbU2vEo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vspimpact: {
    name: 'The Black Sports Business Academy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vincent S.',
        lastName: 'Pierson',
        emails: ['vpierson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGAkDdHJyWDfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656629816555?e=2147483647&v=beta&t=1vZk8jr7gwMT68ZfqSY_xk3iN-BQQa_zXkoSDkMiQ5Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sarah “Heaven”',
        lastName: 'Wiley',
        emails: ['swiley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHxv-qo-_yJdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726771157589?e=2147483647&v=beta&t=LhByI_uDgFWgeGrB-IXvD7A2DDqsqYvdG6KOgiD-FSI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chayse',
        lastName: 'George',
        emails: ['cgeorge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE9LKo_4ij4og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628996266096?e=2147483647&v=beta&t=gg7dE6bFDUYGb5bJZTjKWL6e4N89g0SJ09tpDBbnItc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bret',
        lastName: 'McCormick',
        emails: ['bmccormick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFNirziPZd8Jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556984431409?e=2147483647&v=beta&t=vLXApfm9_DPEaZeJ--yswt1bqMMvJiwM99nahUJmWig',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emily',
        lastName: 'Cole',
        emails: ['ecole@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEGNLIAccDdpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517612881741?e=2147483647&v=beta&t=YZcQtYxVBucRmXOJ2yPR4RF0EOrnfSVP_Y5qSNnMDJ8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  greatquestion: {
    name: 'Great Question',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ned',
        lastName: 'Dwyer',
        emails: ['ndwyer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Harri',
        lastName: 'Thomas',
        emails: ['hthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFbMJG5ohu9oA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700145789419?e=2147483647&v=beta&t=xHIoKf2GjDb0AhOx5xVr_Fz-s4ZrAMog26igpjIk3Sc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sarah',
        lastName: 'Cunningham',
        emails: ['scunningham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG7AI7o7r-J8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658340037258?e=2147483647&v=beta&t=c6Vn8JvVyrIrqBMbfA8XwgqkN6pIwO8O-0qGwU70V8I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Emily',
        lastName: 'Andrews',
        emails: ['eandrews@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF4Rb4Oo_ekgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1525434934514?e=2147483647&v=beta&t=fcEa5tivn_QOlW8E-ondWsvkeszvhqfOTMclu68clyw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eric',
        lastName: 'Schell',
        emails: ['eschell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rfphomes: {
    name: 'RFP Homes',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ryan',
        lastName: 'Romo',
        emails: ['rromo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHDnb5damWdQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715555508753?e=2147483647&v=beta&t=j9JxaC3FOsCVT6XKE71RHWCLjL9pvVx296wF9mqo7Is',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nick',
        lastName: 'Poulos',
        emails: ['npoulos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHyZmLObiVS3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628171359687?e=2147483647&v=beta&t=cY63J5r2p5MHhfvxj4R6AaE3xM2IeEoHPX91IeTmKfE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Francisco',
        lastName: 'Becerra',
        emails: ['fbecerra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHwsiCDBiJkWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722912000706?e=2147483647&v=beta&t=HTht9p_4N-d_W22g1au4BGeXnG9u6NQEbTB9XA3M_-M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexander',
        lastName: 'Werth',
        emails: ['awerth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH1wYgipgHNDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688184149424?e=2147483647&v=beta&t=P9HUN8CgRrvTI3jW_tuP9JM9xr8t8TR5e99NGtCq8IY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Meghan',
        lastName: 'Sparks',
        emails: ['msparks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFt17MUQZpEww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724085534259?e=2147483647&v=beta&t=2M9YZWXLQ2wProAn_M8teAbVZ8EfB09kZMdZDdrqdHE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  inequalitymedia: {
    name: 'Inequality Media',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Reich',
        emails: ['rreich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHzns4et7TgXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612981120716?e=2147483647&v=beta&t=7pF_qQDDr3LecApO5GAhjJFeNMJDTdiIwf1lLMHbm2I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Heather',
        lastName: 'Lofthouse',
        emails: ['hlofthouse@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dan',
        lastName: 'Davis',
        emails: ['ddavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHzD-cdNEBBrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609387426055?e=2147483647&v=beta&t=oL-aqnEQn3VspVUUmeCmbShfCd6S2tvaH1IHcfg7w2k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matty',
        lastName: 'Greene',
        emails: ['mgreene@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFW3u8L1urU6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614468221187?e=2147483647&v=beta&t=mSd9YYYym4L79u8lOpMd1ubHP1CPnDXEkU0T96BkD7o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Allan',
        lastName: 'Piper',
        emails: ['apiper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEK0a9dJogLzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625146997887?e=2147483647&v=beta&t=8H5TdGb33arZYDXsNdyoni3ZJI7PY9JmhNQeVLdokpw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nanniesbynoa: {
    name: 'Nannies by Noa',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jo',
        lastName: 'Barrow',
        emails: ['jbarrow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHOVzgGVnom6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516868365988?e=2147483647&v=beta&t=PRyfDBWk4ZdsanhJ_Ei5tzr3UGDr_AlQ9CAzBUahoGM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Noa',
        lastName: 'Mintz',
        emails: ['nmintz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEhq__4M4tX2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728873679269?e=2147483647&v=beta&t=wfwLnyOLB9dqoa7yu50plymeFNqfZ0K1bkdBCVb70cs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Liza',
        lastName: 'Monge',
        emails: ['lmonge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJ8T4W9vdcEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656124539971?e=2147483647&v=beta&t=VDvolvzcAKWZN0Zt8tB6UXJUnkXGQIcOhiG9pLDQtDQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tiffany',
        lastName: 'Classen',
        emails: ['tclassen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGul-dVZYACMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516609867190?e=2147483647&v=beta&t=VO0lkA9_EWmPPUxOgtD3ZbsZYqSMlHCx0emyoEIhHJM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nina',
        lastName: 'Page',
        emails: ['npage@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFbVMkyy0iSQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705086894781?e=2147483647&v=beta&t=VPvNmuBqWNmaon6dcm1NdGmB7lC1eySQ-3i8Z2FwTE4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  drinkhalfday: {
    name: 'Halfday Iced Tea',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kayvon',
        lastName: 'Jahanbakhsh',
        emails: ['kjahanbakhsh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF8JVIUXpYuvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664841594452?e=2147483647&v=beta&t=d-BFrAeeirKO_CMyKVsImSZZ29nrtsJpSIPylhkJ9yo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sean',
        lastName: 'Lynch',
        emails: ['slynch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGvlEazoKq7pQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631185675840?e=2147483647&v=beta&t=MfafSauko_qdbtJce3Z4-mHWONDndM_Dfg7X3AC0_mE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rebecca',
        lastName: 'Collins',
        emails: ['rcollins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGoBQ3cnOt0Ag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710293671796?e=2147483647&v=beta&t=2Dg1EUJRw2JZQtzyMU_G6Yk6-IU60DDYXqFIq-9Un1w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Lombardo',
        emails: ['mlombardo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Romano',
        emails: ['mromano@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGWBhB5xjDDhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582682166148?e=2147483647&v=beta&t=WqyWycPAk8KWN1yk8QPHsm23VpJdr3DofoaTy8XWPok',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fibr: {
    name: 'Fibr AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vraj',
        lastName: 'D.',
        emails: ['vd.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHEHxvkBgt0nQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722403062968?e=2147483647&v=beta&t=0sh868DaMX6krYTd7ipLUdnLx__E1Ixcy-NJNw4cpis',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sushim Mukul',
        lastName: 'Dutta',
        emails: ['sdutta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGitepxRlKUnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726083257464?e=2147483647&v=beta&t=lQdhwDUWZaX3z-I6kMFkbKI5-aKx-Skodskn-jcdvHM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rohan',
        lastName: 'Gupta',
        emails: ['rgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE67dzcKsZGqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722222444779?e=2147483647&v=beta&t=pMc1egcbNMALSCbiV_3yV1Tnch8ktjWXPeqU0xgVk_M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Harsehaj',
        lastName: 'Dhami',
        emails: ['hdhami@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGjRuNQgMzwzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702835970068?e=2147483647&v=beta&t=BGUUSu8yoyE5NppWgyqV220czJVnZ6CLyw7IzFFdpX0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hawa',
        lastName: 'Drammeh',
        emails: ['hdrammeh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pathpresenter: {
    name: 'PathPresenter',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rajendra Singh',
        lastName: 'MD',
        emails: ['rmd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEERKbMgVi4bQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516964865632?e=2147483647&v=beta&t=6SaRnofMpcQRcc8TGtziGYnmP2C2ngBCUSDperVxL1U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rahul Garg (MD,',
        lastName: 'MBA)',
        emails: ['rmba)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEpWUMllz5kow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696886594073?e=2147483647&v=beta&t=4tWPoPtYfvFljpwbyoG4pLZ2joU2nuaeBwq-F_9W6lg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patrick',
        lastName: 'Myles',
        emails: ['pmyles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQETCCNuCP7dKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650914810739?e=2147483647&v=beta&t=1QchYQjVFefHjhcyBeODNbNhyCRYD3d8Bhx10hQ0Wyo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cory',
        lastName: 'Batenchuk',
        emails: ['cbatenchuk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGsRZpUeL6Hng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664555647966?e=2147483647&v=beta&t=O_h8NkE8YOxzXxk0-4rwcdmiJA5_dw15hunjcEc0UhM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sebastien',
        lastName: 'Latapie',
        emails: ['slatapie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFtvfD3TcEliQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559067867376?e=2147483647&v=beta&t=isQwxC9gufxp0llvwfbc0eTgKeJVgsI9vDIk7qmbIPA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  animalsaroundtheglobe: {
    name: 'Animals Around The Globe',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Weber',
        emails: ['cweber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGzxzc0ZJkRBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688233834782?e=2147483647&v=beta&t=TZfFZp1-pNT20PsWX6_h-eZItcmeG5Ce6522tIkarDU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert P',
        lastName: 'Andersen',
        emails: ['randersen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHYdOldmymLdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517539283769?e=2147483647&v=beta&t=IsynodU-OXlX-bh5GV6-sS8Dc1jTzsNdxuU3_JeayRY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Freddie',
        lastName: 'Hiney',
        emails: ['fhiney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGxpEJmcbjcPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707412338726?e=2147483647&v=beta&t=e4ff8FZM-74d_vtaTE5xiMjh0M0FCJWtF1XBkMU6Cik',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Esther',
        lastName: 'Evangeline',
        emails: ['eevangeline@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Victoria',
        lastName: 'Williams',
        emails: ['vwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHEMNRRQIPQZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693903391088?e=2147483647&v=beta&t=uvd7HW4RnKEvMbaJtABp5Fjwidrf9RBm6BPLf7k8G68',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  brellium: {
    name: 'Brellium',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Zach',
        lastName: 'Rosen',
        emails: ['zrosen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFiqBbEEhK6xg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606848475754?e=2147483647&v=beta&t=Zxg3CrEn1HZlYij5MABnzvKf6xzpVBSsR1TEgQ4nJkg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Henry',
        lastName: 'Kasa',
        emails: ['hkasa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHZE_OsZi23Ng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689972273461?e=2147483647&v=beta&t=Zyccy_NmsfTOxoRrbf0LzL6pPKNsS-yZY1yzZ8lrPvo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Justin',
        lastName: 'Greenwald',
        emails: ['jgreenwald@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alex',
        lastName: 'Le',
        emails: ['ale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF2bacx_OV4bA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605664893467?e=2147483647&v=beta&t=6QUH9zwZLq7-7d-tyMliY4EUli207Mbwkz2TVYiUGQY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Max',
        lastName: 'Katzman',
        emails: ['mkatzman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFFXSRZIQ5VHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686334095006?e=2147483647&v=beta&t=DX5aK-v6DmmVz-Xk_NtZtI0lh4hg-pqakDbpj9888uM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  frontier: {
    name: 'Frontier Psychiatry',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric Arzubi,',
        lastName: 'MD',
        emails: ['emd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jen',
        lastName: 'Whitcomb',
        emails: ['jwhitcomb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEA8vGYrsMAsg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707074080283?e=2147483647&v=beta&t=o9BJ4arvy9pzO5I8eWKSI2dUnfOyNkqwrpgBUGk3aN8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew Thompson, MD, MPH,',
        lastName: 'FASAM',
        emails: ['mfasam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHr-fbDI4nt4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682708965214?e=2147483647&v=beta&t=Ef4hRfOjboayLfym9C0jLCfLPcRpMCx6bX3LEcZGY4E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt Perdue',
        lastName: 'MD',
        emails: ['mmd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFo4z6Z1oAjbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595296854055?e=2147483647&v=beta&t=HaI38o8vL7BB-Pwab4U-IQBhYUBG9OlbZE2xibmS1Yk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Reza Hosseini Ghomi, MD,',
        lastName: 'MSE',
        emails: ['rmse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFShLTuFExIkw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518635416747?e=2147483647&v=beta&t=FkVlV101agfCtj1AEunhpNgOdGGYQGDvGJlLtuWLPLk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  elitesportsgaming: {
    name: 'Elite Sports Gaming',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'LeMont',
        lastName: 'Joyner',
        emails: ['ljoyner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGhNPkjndWY7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564416437117?e=2147483647&v=beta&t=Nf9viZpUmjFr4Fu_OKENA1_4CFoYGNzysmPcwEeirQM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Ryan',
        emails: ['lryan@example.com'],
        image:
          'https://media.licdn.com/dms/image/D4D03AQFZtBJjV2Rhtw/profile-displayphoto-shrink_100_100/0/1714464145763?e=2147483647&v=beta&t=R7Xn8niC_xqPFUAl-8GFve7ibcMlNjLxFzkG0hKIUTo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rikoe',
        lastName: 'Thomas',
        emails: ['rthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHuHo4RQj2NWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656119141287?e=2147483647&v=beta&t=8BFFpWREm3biLvvbofjmyVhvXrVMAv3qduNVwH8aqpI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robert',
        lastName: 'Rose',
        emails: ['rrose@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGMDGxYOZcdpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701875326993?e=2147483647&v=beta&t=ytGb_ctgjKPUcaLdWN75_ti51TEkFyht-JW9juqu_2w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mykola',
        lastName: 'Levchenko',
        emails: ['mlevchenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQErRniwiigKHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709251591519?e=2147483647&v=beta&t=AdKT7wOM0c_fdClV8F-JzquGb0sITOOPmbeeX4u-GVM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  caimera: {
    name: 'Caimera',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kirti',
        lastName: 'Poonia',
        emails: ['kpoonia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF08O_RU9d9ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517735803056?e=2147483647&v=beta&t=1FjDlogLdCsPslASBk_god_mXphSI6ix0CFb0plXjsM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Prateek',
        lastName: 'Gupte',
        emails: ['pgupte@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anustup',
        lastName: 'Mukherjee',
        emails: ['amukherjee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGbwybX5Y0uuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692956684090?e=2147483647&v=beta&t=3DhG08nTxT86S8j7Qq850NYm9SLcPKiQ7MTacUKzoSo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Swesha',
        lastName: 'Sharma',
        emails: ['ssharma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nikita',
        lastName: 'Ingole',
        emails: ['ningole@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQF1TBbq2kYeJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557663156936?e=2147483647&v=beta&t=tNfvIMnpQtXss0UK3JSWNPT_Tbrd3d52dO1a0knY2Bg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  conversionadvocates: {
    name: 'ConversionAdvocates',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sina',
        lastName: 'Fak',
        emails: ['sfak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE49WD2ZjMp8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601922787675?e=2147483647&v=beta&t=7_vIx_bijtHz9H1pVc4Ua-1NB8Aq-arhul6S3tzxWGw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Simon',
        lastName: 'Girardin',
        emails: ['sgirardin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHOqm9HpyzoIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706570019174?e=2147483647&v=beta&t=8MyvRD0D0heHcIdzOt_YUWjdeli0kIvtKM-eDC45cgg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeremy',
        lastName: 'Epperson',
        emails: ['jepperson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHG4W4n42-KUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603486752725?e=2147483647&v=beta&t=gOnvpa-fWhDVP3DcwZq4xu4MimSxeIVX-Iw76rk56NQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mathew',
        lastName: 'Shurka',
        emails: ['mshurka@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH9hrnvP7UjAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1538578913388?e=2147483647&v=beta&t=jOftRrOge_IX28taooMFCg2aHurUKI2WDk84M-eSxHA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bill',
        lastName: 'Babeaux',
        emails: ['bbabeaux@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sheJobs: {
    name: 'SheJobs-Diversity Focused Organization',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Swathi',
        lastName: 'Nelabhatla',
        emails: ['snelabhatla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anusha',
        lastName: 'Santhosh',
        emails: ['asanthosh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGKo2Mt1qMV6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1640722280615?e=2147483647&v=beta&t=7a0pceDZwF4AkkmAse4wrWzDTOi9ntBZvgUBfpFr1so',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sireesha',
        lastName: 'Nelabhotla',
        emails: ['snelabhotla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHSeNnqta8h_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647764869669?e=2147483647&v=beta&t=fXwY0ono9-rYALvfaYyKGiiFUKlqAmoubmR0qTSzMfg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tejaswini',
        lastName: 'Boddupally',
        emails: ['tboddupally@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQErShQtt_6-bw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715276077062?e=2147483647&v=beta&t=zOf2sLNQOdJoioH93PfNB6mZa3EdQq4y5mrdOPKpOvQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Srividya',
        lastName: 'Jaligama',
        emails: ['sjaligama@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  levels: {
    name: 'Levels.fyi',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Zaheer',
        lastName: 'Mohiuddin',
        emails: ['zmohiuddin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEp7M11PtwfUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516858291302?e=2147483647&v=beta&t=bBO8MAP-jBLmp1oOkaReXfRnx6G1MywVGy9uV7btnr4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hashir',
        lastName: 'Baqai',
        emails: ['hbaqai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE2b--0yCrifw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559860983820?e=2147483647&v=beta&t=7tdWTQOH3rGspPv2FXEkeJYUbWRbcB9mfOCRYfuAIpI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Zuhayeer',
        lastName: 'Musa',
        emails: ['zmusa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFzMBZIGIoooA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720478706592?e=2147483647&v=beta&t=3rwXsCMIkfUHeVwGVjWFuuCStp4JbV660tJKCdakiPE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brian',
        lastName: 'Nguyen',
        emails: ['bnguyen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHyklsCOoTKnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584126303586?e=2147483647&v=beta&t=kXRcNWnvWOWamXN2mZlNGjiwxW9vl9xWme4pOe5nrZc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alina',
        lastName: 'Kolesnikova',
        emails: ['akolesnikova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE-jOvltGPrFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716429354377?e=2147483647&v=beta&t=wQH0NPQ8gt0lTz1btVPaoBKFxIenA-a_Ic_RraU3Gvs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  polycam: {
    name: 'Polycam',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Elliott',
        lastName: 'Spelman',
        emails: ['espelman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGCt7f7Lg73fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1528244831769?e=2147483647&v=beta&t=Mrq070q5jhZjA-Ulnkrj84H2GQMvOQpUw0WwuKoOI3A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Heinrich',
        emails: ['cheinrich@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Elizabeth',
        lastName: 'Bowden',
        emails: ['ebowden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF8J27X-ScWhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712963612057?e=2147483647&v=beta&t=KSvjZy57mY0IU1yOMzwghOS3loC5L0uqz2z-sFTmWvQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Taylor',
        lastName: 'Edwards',
        emails: ['tedwards@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFT-OtEWoyWFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666884772249?e=2147483647&v=beta&t=jk-ZyTIcTFfrJD8hvfzHFTZHuhBSDuarDoDkFtvxaPg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ethan',
        lastName: 'Goldspier',
        emails: ['egoldspier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEjx9Il4YV-gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714497461363?e=2147483647&v=beta&t=KNGkCcEFgSL7_DCxTxP4pLbWIRhXBpUWiSOGqw7msRc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  seaAhead: {
    name: 'SeaAhead',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'mark',
        lastName: 'huang',
        emails: ['mhuang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGMxGwtiuAjTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568512874737?e=2147483647&v=beta&t=owTN6ORkSQ40N8U2PXbs-ZeTjuKquW5-36ktkihe-9k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Kelly',
        emails: ['jkelly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFINNdsQKnAPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604698703075?e=2147483647&v=beta&t=ySTXVCFDyMiRzwojn4zQqaMlv97C535x0wnMx1Jlj4A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Edward',
        lastName: 'Cesare',
        emails: ['ecesare@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHrh80Hmk_muQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516313810578?e=2147483647&v=beta&t=tx7_GlIyL7A9aG0hwcziPdeIrPRb4kldTea2n8BMtKo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alissa',
        lastName: 'Peterson',
        emails: ['apeterson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Peter',
        lastName: 'Carson',
        emails: ['pcarson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF7UicVewihyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711137657672?e=2147483647&v=beta&t=WNgT-wjIHu3ISdksXcQqz5Fh_SMdx4TyRCioRxfMgbM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ombigroup: {
    name: 'OMBI Group, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Otro rollo',
        lastName: 'Films',
        emails: ['ofilms@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHbo_UIeuFN7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680357800497?e=2147483647&v=beta&t=sh9p-ZH_uqVJ8AK82VL4gq2jqvzbDrsmEKpwEo6riFg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Shilt',
        emails: ['jshilt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Leo',
        lastName: 'Nielsen',
        emails: ['lnielsen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Johnny',
        lastName: 'Deffinbaugh',
        emails: ['jdeffinbaugh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFzEnwwrGl6PQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724430541437?e=2147483647&v=beta&t=ZtfRzLGFp2g3M1bmqJTlugfGsfN_54uaZocWfebLQDo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joel',
        lastName: 'Passman',
        emails: ['jpassman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEJWwOZ4ewhLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683931619547?e=2147483647&v=beta&t=_s6i1-XKfoIHcmWo-dycNsY4ejI0H6GfA-xrX7mCZQQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bosanet: {
    name: 'Bosanet',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Francisco',
        lastName: 'Borrero',
        emails: ['fborrero@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE3Nl1DsrGvkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659224053657?e=2147483647&v=beta&t=p2f4zprqwGEGk7gDvrtLvi2FyFc1h5mklIuzcRguFeM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ivini',
        lastName: 'Granado',
        emails: ['igranado@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGh9PXvGMeF0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719115705244?e=2147483647&v=beta&t=Sj4dKn_4otYL1G-sbNx91fQw6aHGcFRQkoMKxvJKpH0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ana Cristina',
        lastName: 'Benito',
        emails: ['abenito@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGCFPAoWduSUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686524606553?e=2147483647&v=beta&t=nv3GQKBqsIJ4Nsj-HvmmujLRK4ZIyRIoc1v2y0x7OhA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ivini',
        lastName: 'Granado',
        emails: ['igranado@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGh9PXvGMeF0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719115705244?e=2147483647&v=beta&t=Sj4dKn_4otYL1G-sbNx91fQw6aHGcFRQkoMKxvJKpH0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Claudia',
        lastName: 'Candela',
        emails: ['ccandela@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE6f33UVFQjdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582323020553?e=2147483647&v=beta&t=8AzP3aOD2dq1q1rgNrWpjU58n0V-RVl9ql-Z34ukHoc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  voiceoc: {
    name: 'Voiceoc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kush Aggarwal,',
        lastName: 'MBA',
        emails: ['kmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Samarth',
        lastName: 'Bhalwar',
        emails: ['sbhalwar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGYyF21Tmqb_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590302586073?e=2147483647&v=beta&t=DmfexNh7nQaaImXOJ2OmgdUvYgFnuuygZytpnf5AqLA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aman',
        lastName: 'Dhankhar',
        emails: ['adhankhar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kanik Raj,',
        lastName: 'MBA',
        emails: ['kmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG8RnGyB0DKjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693648416897?e=2147483647&v=beta&t=Za8xR2vbTisdLYRJfyE69mH5ns95j-wleRkhlfTFDDw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Manisha',
        lastName: 'Gupta',
        emails: ['mgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEES-SlWT6h5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724602126550?e=2147483647&v=beta&t=-QGCU17texTEyFIiZbDC3H2g-FjH2LGQbM8h9j4byV4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  globallanguagesystem: {
    name: 'Global Language System',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jean',
        lastName: '',
        emails: ['j@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF3x-gXnjSbGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1540173705032?e=2147483647&v=beta&t=WBeA61t8vxo9Yfvnh7A09cLlDLi_3ESMcpYrjKmNbak',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Christabella',
        lastName: 'Kyuu',
        emails: ['ckyuu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEHcSUBMWIR4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726313185320?e=2147483647&v=beta&t=iWkfqp7oNvhUhM77FvBTKLGElSMRkXTOMR0bNUDbzi0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Felicity',
        lastName: 'Knight',
        emails: ['fknight@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGXXORYXTjRqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516623588878?e=2147483647&v=beta&t=XRr8lOJl9ltoKxeroVudQe9IG3YayQXfLyCTsHnWRyM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lauren',
        lastName: 'Radke',
        emails: ['lradke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEJfVl-p0FmHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727123513365?e=2147483647&v=beta&t=Lrnaihuz61qstFYOZEgzgj9Uw9rVKb3AFO0i7GD2qcI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stephen',
        lastName: 'Grant',
        emails: ['sgrant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGXANW7aTSsDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670549189979?e=2147483647&v=beta&t=jyLDKwTZeX7cyqZir8WHOzoIVqXIC1oSV3YrDPvg_Sw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thehomeedit: {
    name: 'The Home Edit',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kenna',
        lastName: 'Bouknight',
        emails: ['kbouknight@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG_16-jsjTsNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620827954101?e=2147483647&v=beta&t=G4uuZfihgwzLUVd7HcsG9vEQZx1hYW4202e-NDmapxA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anastasia',
        lastName: 'C.',
        emails: ['ac.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQENLn3HkSkCYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1565112969549?e=2147483647&v=beta&t=bFOhLe2oJQpcIdsI3f0nrSgd5HJ33YDIbHdVRwDoQy8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christine',
        lastName: 'Korniychuk',
        emails: ['ckorniychuk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQExrvVdEotMOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725053557398?e=2147483647&v=beta&t=Bvo4iczvjnIKP8IDXDcwyINUFSc7Lsn0USsYBWbMD7U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Johanna',
        lastName: 'Piard',
        emails: ['jpiard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGo_u4dLZUh5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672522178226?e=2147483647&v=beta&t=gYLE7kijvvEnAFeyF5ed4HdXlgEgbFPG6V4FGW8KYEM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rachel',
        lastName: 'Koons',
        emails: ['rkoons@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  a2ztask: {
    name: 'A2Z Task',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lucy',
        lastName: 'Beck',
        emails: ['lbeck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGfV26or9VzRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516338330324?e=2147483647&v=beta&t=7MI-NVKESTUqI63F4MNOWrjVof1MqT94QfF4AoHXXFk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Becky',
        lastName: 'Cowlan',
        emails: ['bcowlan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEHIROFQibJBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705681674279?e=2147483647&v=beta&t=-whZ1f4vfoyj70TtaTMjzUgpp82zwSlOjyDJUM1LyVs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kieron',
        lastName: 'Jones',
        emails: ['kjones@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Steve',
        lastName: 'Matthews',
        emails: ['smatthews@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHUhzq7sDgjVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517672279974?e=2147483647&v=beta&t=nZL4grvehqABpfNRgjByC0ZQtHIO9A48LtdAilRK1eU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dashiell',
        lastName: 'Bunyan',
        emails: ['dbunyan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sansartec: {
    name: 'SansarTec',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sarah',
        lastName: 'Eberz',
        emails: ['seberz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF5O35AnTPiqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516946908433?e=2147483647&v=beta&t=bHglCrWPxwygO0Q6Y6as5ZcIs0fxLMALbM2RsrKNAFw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tony',
        lastName: 'DiSanza',
        emails: ['tdisanza@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sandeep',
        lastName: 'B.',
        emails: ['sb.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Kucsma',
        emails: ['jkucsma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHeOkyJ7iuJ5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516484851529?e=2147483647&v=beta&t=dKGkBn5axn7mow5zq735l0khIfjfL4gKRLB83VTr-ug',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shalini',
        lastName: 'Ramachari',
        emails: ['sramachari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGFCTmDSy-kjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517553211130?e=2147483647&v=beta&t=lq2KOpphdAwdfW3VqAHtiNdB4BBwxCl9nbIl56tywTw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  athletesthread: {
    name: 'Athletes Thread',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Karthik',
        lastName: 'Shanadi',
        emails: ['kshanadi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE92vO52Tb7Yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665144089857?e=2147483647&v=beta&t=VewpBz4cVno4knN5HZL4_HMOWgOTGG20WeIR4Z4NSM4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kismet',
        lastName: 'Tuli',
        emails: ['ktuli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFSpCxURQuSmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548225187051?e=2147483647&v=beta&t=7qzSV9kf87t0IK8wLlbRHwJ-j_y5gtCK-75VH4JqOY8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Luke',
        lastName: 'McGurrin',
        emails: ['lmcgurrin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEelqr_AG7IXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517431172275?e=2147483647&v=beta&t=oBjRYZc-oNr8_YgDZuAgMxH6cfzo4xkmmnOB9iXyXT0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carly',
        lastName: 'Cox',
        emails: ['ccox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFZY-W9Vub0vA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689093502049?e=2147483647&v=beta&t=heqMb2Fq31SS6jx7dHTXFF7aIS7ud32rWzBMkeVyhDI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ian',
        lastName: 'Collins',
        emails: ['icollins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGHqBpgoQOr6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583390462891?e=2147483647&v=beta&t=YU7CJDSk4ga3qhG0HqFEPpbtGaYE5StlICJ65-DRhgg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  traveltech: {
    name: 'Travel Tech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rohan',
        lastName: 'Rajgarhia',
        emails: ['rrajgarhia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGsn1kDMiQGEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517755602832?e=2147483647&v=beta&t=kNNorgnbjT1zWMy_UXZaAa4fL5ym2qNmmQtkvUikxQU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joe Ahmed',
        lastName: 'Youssef',
        emails: ['jyoussef@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFJbUDQzuGINw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698688054007?e=2147483647&v=beta&t=2b46sghA-a1hI4zvqKqwr32GnVsuNOOWtJrULl8l-tU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Abhilasha',
        lastName: 'Jhunjhunwala',
        emails: ['ajhunjhunwala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEfJDBvC7gAyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612771421620?e=2147483647&v=beta&t=Pvka3od4hivsiK-0zJmvVcINh6CCz0UL4Xp1msKu_wU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rohan',
        lastName: 'Kumar',
        emails: ['rkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/C5103AQE-DqUCokT55g/profile-displayphoto-shrink_200_200/0/1582885819514?e=2147483647&v=beta&t=O0_P8-NbWMPOA5ZGR884_kSS6InlrA6v_mIK4Sq_X0A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vidette Adjorlolo',
        lastName: '吴彤',
        emails: ['v吴彤@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHFmNw2hzxhqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673981558721?e=2147483647&v=beta&t=iUzVTgtp3BJkByOux4K-blemiVXI00CDX6X6U1B1_hg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  scinvestors: {
    name: 'Sunrise Capital Investors',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Spear',
        emails: ['bspear@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH1xlDHpFrFIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603404761983?e=2147483647&v=beta&t=T3TOJwTzIQwtcxYLFvW1QPp2vAuOuWZZLZvLb2tsiGU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kevin',
        lastName: 'Bupp',
        emails: ['kbupp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEBAf_9DUY9lw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517657417903?e=2147483647&v=beta&t=BJ9yjPDEZnrqCf7tMw4I3o5l7rnQJ4yvKnL2yeqSkLQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bronson',
        lastName: 'Picket',
        emails: ['bpicket@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG97Iaao_hDvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517670144456?e=2147483647&v=beta&t=fFrxcUjtLU0KbNgQ1H49QhTGht1BHJ_fHAD7BKwT-9I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Todd De',
        lastName: 'Leon',
        emails: ['tleon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kirk',
        lastName: 'Zandier',
        emails: ['kzandier@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  infinitecxo: {
    name: 'Infinite CXO',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aaron',
        lastName: 'Boggs',
        emails: ['aboggs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGK0R5YH0kqVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695935330411?e=2147483647&v=beta&t=ij1O3k-RNznMtPhKKC4sA3_tGckUmF29t8x3qDzNqAY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jack',
        lastName: 'Shade',
        emails: ['jshade@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEmTps660B2XA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517727371897?e=2147483647&v=beta&t=TmyIhsGzvOIg3Wxyh8sBqNw7ZRndbr4vucPOVru9LvQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jim',
        lastName: 'S.',
        emails: ['js.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrew',
        lastName: 'Bleecker',
        emails: ['ableecker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGpzl2AFCOo2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642796550082?e=2147483647&v=beta&t=HCBEkGrkd5DqXuGVek9IRfem9qKXt2Z6c468WCs9HN8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Wallace',
        emails: ['cwallace@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHcE8HmDkLfkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713020566059?e=2147483647&v=beta&t=GP6S4-y83HLd5KoiEMU66XatVjD6k9AK759gXjH8P2o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  renegadepartners: {
    name: 'Renegade Partners',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Susan',
        lastName: 'Alban',
        emails: ['salban@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tim',
        lastName: 'Miller',
        emails: ['tmiller@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matt',
        lastName: 'Savage',
        emails: ['msavage@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQErjMeaTgOV3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713373801243?e=2147483647&v=beta&t=GdT_-8Pbg6inc2YkPGKOZekewzp-qS8_l45PXEOcgAs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Love',
        lastName: 'Maia',
        emails: ['lmaia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFn7R5c6OuOmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727965688961?e=2147483647&v=beta&t=5MoFC4ahkczgBq5wr1z8ZyCNOh8YPhGrr8kWhpX3yQ8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Danielle Sercu,',
        lastName: 'CPA',
        emails: ['dcpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHQW5NNOMdmcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715277898813?e=2147483647&v=beta&t=SNIIaQAtMkpDySXthdsBohrFzscXbrEs-tC8e1ewCQc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  adaptedmind: {
    name: 'AdaptedMind',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rex',
        lastName: 'Ma',
        emails: ['rma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nolan',
        lastName: 'Frausto',
        emails: ['nfrausto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGMDK1XilxhvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706027815115?e=2147483647&v=beta&t=PlASVh7E2UwBvIMvN2VEgJuuknXTBIoHzBUA4iOr3Mw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nathan',
        lastName: 'Irvin',
        emails: ['nirvin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHvqsOLA-2PDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708882293821?e=2147483647&v=beta&t=dtgyahBEMJB1jZ5JqjSNQFTGOkhfFf5pgfZgfnjN9Lw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yuriy',
        lastName: 'Timen',
        emails: ['ytimen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEpCQVbgpgFTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667628132377?e=2147483647&v=beta&t=JgkUW3CG37M4rZ8E9Bemil83_dY7fsgslAdMb0AZyh8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Greg',
        lastName: 'Intermaggio',
        emails: ['gintermaggio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGiyWCwHHT4Uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709057465082?e=2147483647&v=beta&t=yjIJskx1ZZCloGPC3ZRENdatEInb5W5At_CVX3Ww92Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bunkerhillhealth: {
    name: 'Bunkerhill Health',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nishith',
        lastName: 'Khandwala',
        emails: ['nkhandwala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHIaWHU3fsyxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636511648587?e=2147483647&v=beta&t=_AybbNmBJTKVZ3_EtSZ0s0xm6zJLcX_Ua6GXoIA-yoc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bradley',
        lastName: 'Gordon',
        emails: ['bgordon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEibbgq69MnlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697566742115?e=2147483647&v=beta&t=D4D5GnI3AoREoLnsUCt6E4NYN4-EQP9u29eQ5QhTtEs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Judith',
        lastName: 'Li',
        emails: ['jli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG89aFP6Iax6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711988883402?e=2147483647&v=beta&t=di4tYYQ5Vi4fXofrl4F2Z0POwD5983tuK1jAUi1W6tk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Eng',
        emails: ['deng@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brandon',
        lastName: 'Powell',
        emails: ['bpowell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEE5jOQJwq1Fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683938354435?e=2147483647&v=beta&t=U9616C9qI3l6Tp60Y1LQRsC6nm6zyQtEWa_w3Eq1VOE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  leadforce360: {
    name: 'LeadForce360',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kelby',
        lastName: 'Steele',
        emails: ['ksteele@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEiheBxrxdMKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709400424162?e=2147483647&v=beta&t=XP0Lzmhyhmb_oQ7BC0ryi9sO8B8m8AMjOIXsO6tee_Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ryan',
        lastName: 'Matonis',
        emails: ['rmatonis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGUhOSjQdv32g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517015895259?e=2147483647&v=beta&t=eo17sU-aH6bIxUCyxE5Hglu3cLLXoF1vabwZ0NNmVEY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Skyler',
        lastName: 'Ashley',
        emails: ['sashley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHoBZ26PCi7hQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559606745119?e=2147483647&v=beta&t=dJ7jl9JzyQURDGRn3YkmggFj2oF5KAY_vQFbX-nOTaY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chantal M.',
        lastName: 'Steele',
        emails: ['csteele@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGHZLO2utZW0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531955735356?e=2147483647&v=beta&t=VoQVGfPn2aYNTaBc1gIKrme7FrW_4-67HIc30T7YBy0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kristina Landon,',
        lastName: 'RPA',
        emails: ['krpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHnuckUq7hAFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678807445833?e=2147483647&v=beta&t=STs9XkkwKK8JNxm5gDvJN8PGulOjA9_x-xSaig7IKmY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nuadvisorypartners: {
    name: 'NU Advisory Partners',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Meredith',
        lastName: 'Rosenberg',
        emails: ['mrosenberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGJwO61UqvBMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585878671757?e=2147483647&v=beta&t=M8f6QDfjbTHPc8vyUXWnF0xPV7x9cM3fCJnVRHK74K0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mar',
        lastName: 'Hernandez',
        emails: ['mhernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFz_WYGnAgtVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684447629140?e=2147483647&v=beta&t=88beLP2OfTDpjlH0HG26QTnunKnyuCfsLtonX7-XHPg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Libby',
        lastName: 'Naumes',
        emails: ['lnaumes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFqP9P0H3kTQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689951153023?e=2147483647&v=beta&t=28zanL537qx9lK2xAYiO1Ss7_GO_kFA23z1DzrCEKiE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jay',
        lastName: 'Hussey',
        emails: ['jhussey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE1PbqcoOdwJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516253729756?e=2147483647&v=beta&t=QK4UInhy2Mp-Fabotz3Bp7yuuHIji4g1VuXKo5jS47Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Penina',
        lastName: 'Remler',
        emails: ['premler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  canvasapp: {
    name: 'Canvas',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ryan',
        lastName: 'Buick',
        emails: ['rbuick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF2gWiW4RFc0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645044839667?e=2147483647&v=beta&t=JhY4w0ER7kbgCsBpe9C9AbcA6Xugs8LFf7tqDG6lGZg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Luke',
        lastName: 'Zapart',
        emails: ['lzapart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGJFX9RU9L3qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632253830342?e=2147483647&v=beta&t=r02JcYroJRKFHMERc6I4l3UyTcSYUTvmvKB7xc6gPoY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Melanie',
        lastName: 'Perkins',
        emails: ['mperkins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFKMlvwPhqm5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516350216557?e=2147483647&v=beta&t=azljBnBlI4lqIgJS3__Erlw8XVwsaiFlCUDaE_n1Zd0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ron',
        lastName: 'H.',
        emails: ['rh.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFbwitWsZgfeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603416671966?e=2147483647&v=beta&t=wjYb7ghxDNnkPBzye4faaQSUkZMjtfXX5ofvKQcNQCQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gleb Krivovyaz – VP of Engineering – Canvas |',
        lastName: 'LinkedIn',
        emails: ['glinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH8P7b6xiS9Ig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517060452183?e=2147483647&v=beta&t=sGPoRFxvRft09WZSpOJJpVoVGaVP6u78r9ZEBatHLt0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  toothlens: {
    name: 'Toothlens',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dr. Manoj',
        lastName: 'Rajan',
        emails: ['drajan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGlIURm4NtjMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722226200682?e=2147483647&v=beta&t=bIKO8irEunK9rqauun9RG1PXXvr11munUsjREWQW900',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Deep Mehta – Toothlens |',
        lastName: 'LinkedIn',
        emails: ['dlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFDK830G8QZog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712738646457?e=2147483647&v=beta&t=-la5Pw_RqIhCFT-QzheNzSFUAC4LmZ8VUlW95TlFu68',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Upendra Shetty',
        lastName: 'Balija',
        emails: ['ubalija@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHy8m0W-1k8RQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708068760992?e=2147483647&v=beta&t=vSHugwYy9RvwVnPZ2sIeU76ObWCEnxJfwWFaxRFLzoE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Arpan Shantilal Dholakiya – Machine Learning Engineer –',
        lastName: 'Toothlens',
        emails: ['atoothlens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGup4Bjt5fw3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709118571376?e=2147483647&v=beta&t=w2P87dz58_hJIqvBI8uVryLtX9Oi0Yj5eSXR4IWENbQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amith',
        lastName: 'Pandey',
        emails: ['apandey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE1HGjEa_ZYXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623483986313?e=2147483647&v=beta&t=ypDglcIvY89KCxw1Gd6uDvNzk2TV6Y6yVs-_Bj1Dwsc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  formed: {
    name: 'FORMED',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joel',
        lastName: 'Schipper',
        emails: ['jschipper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHSZf93sreAPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708354730073?e=2147483647&v=beta&t=o66r7CghXfKFKIv-s8ag7U2Iiy_dvTBIOXbei6y6GI8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stephen Krause,',
        lastName: 'PE',
        emails: ['spe@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Beth',
        lastName: 'Krewson',
        emails: ['bkrewson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kirk',
        lastName: 'Reineke',
        emails: ['kreineke@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gordana',
        lastName: 'Janus',
        emails: ['gjanus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFKa-HGEWJIRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715703275164?e=2147483647&v=beta&t=b2FTZ5MaJoBwI-3aSY1Bhd6WLx3s2eHkKiNGGsWzbpg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  getquid: {
    name: 'Quid',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Swanson',
        emails: ['cswanson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEPof1FaKUx1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687718312430?e=2147483647&v=beta&t=Xmp2zNOhArwGtuHIe8-pxi2bNfk2Oqpg65ORfw-n8gs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stephanie',
        lastName: 'Biran',
        emails: ['sbiran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH2y9NMNCeu8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688168294938?e=2147483647&v=beta&t=ntnv2N01Y7rM3oJfQHop98sFELD3P5IvcHVFRUmkt1k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Robbins',
        emails: ['mrobbins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG76xhXDRGGuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658858691214?e=2147483647&v=beta&t=ODQk5tr9nm56x9WYaxx7odogiI56q5W72xFwXSEYWKk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Katie',
        lastName: 'Conlan',
        emails: ['kconlan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dustin',
        lastName: 'Attwood',
        emails: ['dattwood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHFvwjHE3SuBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647454020435?e=2147483647&v=beta&t=MsbTkd_rn6pDP-T7OLVY9bsC-7PQLpOiMPviq3I9XGo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  salestransformationgroup: {
    name: 'Sales Transformation Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ryan',
        lastName: 'Groth',
        emails: ['rgroth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG0vR_7OfTbWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707284431017?e=2147483647&v=beta&t=-1AdPomH7p-E2R6ZQysFJ2w23B04YdeEyKnGTIEYZsg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Reed',
        emails: ['dreed@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sarah',
        lastName: 'Ehambe',
        emails: ['sehambe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHKwoPYWZmU5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728366864617?e=2147483647&v=beta&t=cGr1MAYzj4ipcyovUL6g4BxCDFE7OizU9C8ouIvdu8c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Keith',
        lastName: 'Knutter',
        emails: ['kknutter@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kyle',
        lastName: 'Robinson',
        emails: ['krobinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFl8mo3r6xsYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693877465902?e=2147483647&v=beta&t=xrcNbvGZ3es45AUwI9-LA1qYQaozEt0d6iF3x_BYAb8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gildre: {
    name: 'Gildre',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Lee',
        emails: ['blee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGDqGZHT5FOVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718239143695?e=2147483647&v=beta&t=sWxKaGqG1eEE2MbA8ZwNmJm6nc7MtySWkNZat_2lUbk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dane',
        lastName: 'Wagner',
        emails: ['dwagner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFqCsUpg5XF-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627484866933?e=2147483647&v=beta&t=qvWY_xHMHGtsWVfdfX3nivs6V85bkVBAQOaul_CVRAg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Trevor',
        lastName: 'Robinson',
        emails: ['trobinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEwyMatuE2Efw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668463159714?e=2147483647&v=beta&t=F0wnM3fRlLv5w0KpjNmBxHHtD5Vi2temUce7QSxpnyI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matthew',
        lastName: 'Savare',
        emails: ['msavare@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH3kWJXwIwrYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516500168298?e=2147483647&v=beta&t=oLy54auGWFz9pImk6XM_K2LP7t3TGcSGOyxqyA96IRk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Walter',
        lastName: 'Hangad',
        emails: ['whangad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHftHALms499g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550629960730?e=2147483647&v=beta&t=lteO8R-zEiuRQcU4l12-MCPD9xqbdjeVc6f92XwxzOg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  terraformindustries: {
    name: 'Terraform Industries',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Casey',
        lastName: 'Handmer',
        emails: ['chandmer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHnoeN9xxliRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517575810843?e=2147483647&v=beta&t=60x0oTkf5XMzUGR1BM9OI1jNH4UFsLbPDcOz7eUtg_I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kyle',
        lastName: 'Adkins',
        emails: ['kadkins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGITsjAHAyClQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714076907119?e=2147483647&v=beta&t=51h-hA4P5f1hCbQaEooe--h4C2q5U8x6PM0jkSfTuk8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alicia',
        lastName: 'Lomas',
        emails: ['alomas@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephanie A.',
        lastName: 'Coronel',
        emails: ['scoronel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGVYf1X-wUqmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714534551638?e=2147483647&v=beta&t=-9onzMl7vZ7CkscWbThIPFJouEacUrzPM_src5XKVyI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lucie',
        lastName: 'Nurdin',
        emails: ['lnurdin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFNW20WSDAbIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547499207092?e=2147483647&v=beta&t=_9XZC7wddjV886uawVq76sUsrDw5rujOS7GQSaQPKAY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hillbot: {
    name: 'Hillbot',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Zheng',
        lastName: 'Han',
        emails: ['zhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFSoPvefd2ArA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589972825270?e=2147483647&v=beta&t=KXJVLDbt7X_sSR9DhVgnPmZa5d9lWDXBquwXeGqT6YI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hao',
        lastName: 'Su',
        emails: ['hsu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHMk2jyHx8DlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715724804815?e=2147483647&v=beta&t=8PqoMh16cLhPbipixVWPmXm2_u_p2Bfqx4YLMXjTKkg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jiao',
        lastName: 'Shi',
        emails: ['jshi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFZZXPV3YqY-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516527769931?e=2147483647&v=beta&t=kAshFdLns1vFrNgidkktjPkXXmSGB0dgZ_7rRYnXsRI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kevin',
        lastName: 'Weng',
        emails: ['kweng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEQm3pPsrkOyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721941367516?e=2147483647&v=beta&t=eqC7gJqXNxPWIQDhc6cnIhp79n3yrep4rm3SeuCEr2s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yuan',
        lastName: 'CAO',
        emails: ['ycao@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHNZ0wafFjgWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1526591691507?e=2147483647&v=beta&t=_X98I1OSoIvaKIn97p4zDxVlLtyxUfvsyDVaVhGUBx0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  cruxclimate: {
    name: 'Crux',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alfred',
        lastName: 'Johnson',
        emails: ['ajohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH5cE7yQHVhZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602279559590?e=2147483647&v=beta&t=vRPkfL0cziKUeHZfyJincM_rSr2shZESZiAwI-DVw1E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Allen',
        lastName: 'Kramer',
        emails: ['akramer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGwsyOD-s-mBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649584802401?e=2147483647&v=beta&t=w5966PRqg1AfhDSeu2UNX392fpozjwD7QNkwX3irQMc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James Alderson',
        lastName: 'Smith',
        emails: ['jsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHKJoCQa9A7iQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1599687138063?e=2147483647&v=beta&t=xLbz5QwFNfEbroYXHdjUUHJmzn9wy-zRMYFQYvFWpP4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robert',
        lastName: 'Parker',
        emails: ['rparker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGVpOXIJZCNsg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638920046141?e=2147483647&v=beta&t=MbMmfr1xA7C9RaFvycCBRS6P_TqqQ9uEYlh-UINPwmA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Katie',
        lastName: 'Bays',
        emails: ['kbays@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEGWi6l1CBLYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705600004648?e=2147483647&v=beta&t=KPwbaUk-TpysdZih4EQxSiC4m92c5fO0VudL_wP_yYk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  supersmalls: {
    name: 'Super Smalls',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kaitlin',
        lastName: 'Enderley',
        emails: ['kenderley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEPOUxpgFik8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531189603253?e=2147483647&v=beta&t=W5ceOpG4N6w9YUjqB_C6W2bzrb6b_tQsjIM0IbwCiNQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maria Duenas',
        lastName: 'Jacobs',
        emails: ['mjacobs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEN2oGhE6galg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620088225584?e=2147483647&v=beta&t=TESfMf6uNNnTGOkt7QdRap53tbJdACNIswUEC7aL3Uw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dan',
        lastName: 'Hanlon',
        emails: ['dhanlon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHAOF3RCFzGnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516815851463?e=2147483647&v=beta&t=eNgeaEZKuCOnn0Pzp6etIdwymcoHjaZChDI8jP3L-9g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Fiona',
        lastName: 'McLaughlin',
        emails: ['fmclaughlin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH4HS47vd5kYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703736267595?e=2147483647&v=beta&t=nLvoWDNvNQVeV-MJA1-QmuaNttwsM2k6K8FYpK-s-tc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bianca',
        lastName: 'Gottesman',
        emails: ['bgottesman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  whitecitysoft: {
    name: 'WhiteCitySoft',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pooja',
        lastName: 'dhobale',
        emails: ['pdhobale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGNueLiTuQ9Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589866565743?e=2147483647&v=beta&t=O1g4mxtIvvTRoTdOD10hVcT_S6Eoy_9Mx-ZtJQiNJcw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tim',
        lastName: 'Felkins',
        emails: ['tfelkins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH-GyzDFz0t3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575925447608?e=2147483647&v=beta&t=udUeeCYG2Gv7CR_mgVxhoUBBk2phNp5k2EHP82RCft0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ilija',
        lastName: 'Bogdanovic',
        emails: ['ibogdanovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHyhKZBc0s__A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704469950539?e=2147483647&v=beta&t=laQMxnXTK8hawtoeX2NTF0-tgCZZymzLNn6H7zHoPjY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anisur Rahman MBA CISSP CISM CPP PCI PSP PMP CFE CNSP',
        lastName: '...',
        emails: ['a...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEiNacAyXt0qQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715157717323?e=2147483647&v=beta&t=gt1l0FeCrNm4x8IBfYXeT3oJCxScod8QYpD7JAkdPKs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'George Hummel,',
        lastName: 'ACQI',
        emails: ['gacqi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sendhark: {
    name: 'Hark',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christine Hark',
        lastName: 'Engle',
        emails: ['cengle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGUU7vhhWAubQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711068266895?e=2147483647&v=beta&t=27NLuQqUxLyLvdmAL7ID0EYlhUhsAxNCE8iHAqFhuIY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mariah',
        lastName: 'Harkey',
        emails: ['mharkey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGmF63Aaaxnpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646630141722?e=2147483647&v=beta&t=E93MD6yDRpzQYn2X988IkNbBh99ncdloLLce6R8EB9w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Juliana',
        lastName: 'Sheff',
        emails: ['jsheff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH-k5GQncbdzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578192033255?e=2147483647&v=beta&t=YCmMWDwfEdO7RkikuC5hsNUcB5chL9emxJbmgSZh6zs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Hark',
        emails: ['dhark@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Minerva Hark,',
        lastName: 'MPA',
        emails: ['mmpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF_GJ6B0YqUdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582693446808?e=2147483647&v=beta&t=HIoZtPDgmfPgDw5gtttJkeMIi_PQ3MMN5tSx-Xasb18',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  goldcity: {
    name: 'Forefront',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rohit',
        lastName: 'Baride',
        emails: ['rbaride@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ragini',
        lastName: 'Baride',
        emails: ['rbaride@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sai Teja',
        lastName: 'reddy',
        emails: ['sreddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFjiDZTxw-i1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674552034727?e=2147483647&v=beta&t=8xL1JaYqa57O2jf_P7-WzD0W-v6l6Qc70UBRvO4xmkA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nursing',
        lastName: 'undefined',
        emails: ['nundefined@example.com'],
        image:
          'https://media.licdn.com/dms/image/D4E03AQF3nii5s_f6nw/profile-displayphoto-shrink_100_100/0/1708335240941?e=2147483647&v=beta&t=TmCL68iXczkfU4hyqqXfN6MehJ_m94vrVsHbqpN9A1U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Esha',
        lastName: 'Ghadge',
        emails: ['eghadge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF0UpfypfTMAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727007848019?e=2147483647&v=beta&t=EIEwcdZXhqw_RsjL0zVxWLWOxvv1i63ef8PQhudSXUQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sounddecisions: {
    name: 'Sound Decisions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dan',
        lastName: 'Parker',
        emails: ['dparker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFwbsHbKnYy4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718252837140?e=2147483647&v=beta&t=mgYFFiQeoYnMAThobrjn8Vnl_L0dG3dz4daOUgLuh_k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mike',
        lastName: 'Castino',
        emails: ['mcastino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGU5RWqRCpb0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684165651301?e=2147483647&v=beta&t=r2KmidpvGhJBwEdhc4JI9B5na7RdvnrrPq-CeZhAsCo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Philip',
        lastName: 'McIntosh',
        emails: ['pmcintosh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joe',
        lastName: 'Yerardi',
        emails: ['jyerardi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE5QBT22LZncA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516421911782?e=2147483647&v=beta&t=8Hh3P8kxu3TecKnwGMWAx9BXpdQ3D4ovStl1X8noTyg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rob',
        lastName: 'Sims',
        emails: ['rsims@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGkmKZsAYJ5sA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516854886053?e=2147483647&v=beta&t=WUSVYs4Sx-2bbwjIgMgpoiaA2AmczL1jYVDhX1jmfAM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trybree: {
    name: 'Bree Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alexander',
        lastName: 'Li',
        emails: ['ali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGSzADFAqymew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653779869190?e=2147483647&v=beta&t=KKc29f0UKV6FwLX_UEAFr33OnlwHCBwaeMwruy8g2vk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Holly',
        lastName: 'Lin',
        emails: ['hlin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEcnhLg-KEcow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1640111169017?e=2147483647&v=beta&t=kaTQTXew8c5KjY8eEtTZiN_KVQUUhEpNa0bXbiaebKk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ryan',
        lastName: 'Han',
        emails: ['rhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEPDM4qZqH7Ag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714595964169?e=2147483647&v=beta&t=CBcq8Fh2cFEEY_9VmZD750amJlviGDzR83_tltpX7sA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ryan',
        lastName: 'Hu',
        emails: ['rhu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGon3teArmb9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705561769153?e=2147483647&v=beta&t=Enm9K3ctNJ66cCY-uFM9kLsZWU4rJdPfkblS8qZY6aM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Minghao(Virgil)',
        lastName: 'Li',
        emails: ['mli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE9N3FW83hBcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654722619461?e=2147483647&v=beta&t=Nl5bkm-dXCt9vmv7dvcHWh7176_dLrZU34TBpgf7GbA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  seagatemasstimber: {
    name: 'Seagate Mass Timber Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Scott Comfort',
        lastName: 'GSC',
        emails: ['sgsc@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ralph',
        lastName: 'Austin',
        emails: ['raustin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFo1Mn9935CAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671558124869?e=2147483647&v=beta&t=Kb524AlpzrzcsMTAle2HeReEt4ns8ObVjDRabAWHxfY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jesse',
        lastName: 'Wills',
        emails: ['jwills@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGZwH7lDsl0Xw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704940411301?e=2147483647&v=beta&t=pNnXM1Rbbctrs5HHHlKS4vLK6FaB-pok_B7R_PJfpNQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kassie',
        lastName: 'Fraser',
        emails: ['kfraser@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFzgx4SQawXcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605198355860?e=2147483647&v=beta&t=bKkGyox9pAPGH-UI9NELZ2EQC0i3Zz32UcowMdsD8TA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Troy',
        lastName: 'Kozuki',
        emails: ['tkozuki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG85dxNyImfOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521308266207?e=2147483647&v=beta&t=agGP6G83SdxZGFlieliAWuNWEvo6iM0OY05rEzI_4sg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mypureinsights: {
    name: 'PureInsights, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rick',
        lastName: 'McGlinchey',
        emails: ['rmcglinchey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHaBzmAvsSxJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516890970183?e=2147483647&v=beta&t=ATAHRf1r_qAYMcOQz5O2MkFy43N8cawgSaSh4e38iVw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rebecca',
        lastName: 'Bearden',
        emails: ['rbearden@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stuart',
        lastName: 'Walters',
        emails: ['swalters@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHZF1VhO-wZqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662500377098?e=2147483647&v=beta&t=vsRBmJFG7s3ksq6k54_nAKX1aZvxCHB6oWPwcMqsr7A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jamie',
        lastName: 'Drury',
        emails: ['jdrury@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGCRlI4t12Stg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686924859628?e=2147483647&v=beta&t=B87M0PDbNoukuyKbnp3ov0mI-H2O1VVrlkXBdrQJ9bE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kevin',
        lastName: 'Glinski',
        emails: ['kglinski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGLcpGenWoJxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517424630766?e=2147483647&v=beta&t=B_AWEDWaGM3D5GTE-XtXp0F-s8YSnQRL7tTYJMBQVuQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  togetherwebloom: {
    name: 'Bloom',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lihong',
        lastName: 'L.',
        emails: ['ll.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'bloomus',
        lastName: 'enterprise',
        emails: ['benterprise@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lori',
        lastName: 'Bloomus',
        emails: ['lbloomus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFJbfQZfq7nnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666796882172?e=2147483647&v=beta&t=0Jv0WUN6EJhy2U406pSSLk00Az2Gzqxo7mlHcu3uRmI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ben',
        lastName: 'Bloom',
        emails: ['bbloom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF8vBDXzPhW9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670427183582?e=2147483647&v=beta&t=jSRpmKb9GdqWol-1XANEDSpGTlgSaw3ERCSBGML0_v8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jaime',
        lastName: 'Bloom',
        emails: ['jbloom@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ravek: {
    name: 'RAVEK',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joshua',
        lastName: 'Ravek',
        emails: ['jravek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHtxqnh1skJrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719014252797?e=2147483647&v=beta&t=qCWpg7HL-haQPoxKWx6MzqnkwUJjP9CTj6-e3thjaXw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Harry',
        lastName: 'Goldstein',
        emails: ['hgoldstein@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Liana',
        lastName: 'Ravek',
        emails: ['lravek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEdMkr59hDCYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603149651931?e=2147483647&v=beta&t=oMsmI3WbPKW6H8rdn2rFcqE5HvJkG3ZbDOBkygXmQSs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ricky',
        lastName: 'Goldstein',
        emails: ['rgoldstein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHVcX0cP_ziCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720101090113?e=2147483647&v=beta&t=9V1KVu_uIybSRsA2GRbURvMYLVPxfytNvymVK7np3QY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aimee',
        lastName: 'Ravek',
        emails: ['aravek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE24qCDK2G7Dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516876601772?e=2147483647&v=beta&t=barJh6gj8lFc-0MXLK-eD6OuttKq9CyXPYEXL2eiLY8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  writemybooks: {
    name: 'Streamline Books',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Will',
        lastName: 'Severns',
        emails: ['wseverns@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGATIZ0aCg-aQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656765074610?e=2147483647&v=beta&t=0rey4JXKOcUG1YKNZz2cXUgOAEdhJ4wyn4rHcvD2XJ4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alex',
        lastName: 'Demczak',
        emails: ['ademczak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGHP8vaVqLh_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669693897140?e=2147483647&v=beta&t=mqypU7pCM8tXoj3-pwTxUpg2KQAMM1uB5ClsSV79mxU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeffrey',
        lastName: 'Miller',
        emails: ['jmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHrZGtxFma4HA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686336158610?e=2147483647&v=beta&t=HMfaDYk1O7bwEyLeFnu0RfS-BBmHr--MUENa5Wf3eHc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Donnel',
        lastName: 'McLohon',
        emails: ['dmclohon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH7CnJ0u9onUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727200582164?e=2147483647&v=beta&t=vraA10T6sWB6BYxyDAYnVqMy4MkTem35Ye_s22pxI8A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Annika',
        lastName: 'Bergen',
        emails: ['abergen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gopromotive: {
    name: 'Promotive',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amy',
        lastName: 'Gerardi',
        emails: ['agerardi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGbgCTNjHTifQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673149067289?e=2147483647&v=beta&t=YEKLqvny4OO-YA5DISw3dTmOyePEhdPGO1m094Q6GvU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joelle',
        lastName: 'Pollak',
        emails: ['jpollak@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joy',
        lastName: 'Robertson',
        emails: ['jrobertson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFhUqqG4S_wzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696960108679?e=2147483647&v=beta&t=Cs5HTp3MKixkE_7gqwSFTp5-4x2whQnKA8UpVkeaH0A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daryl',
        lastName: 'Michals',
        emails: ['dmichals@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQESxmR9R0P2hw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516870674125?e=2147483647&v=beta&t=1YTX6n1iBfUHdjUSxkVQUUC9KHm1XKRLQW_6E2w5Wzk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Katie E.',
        lastName: 'Byreiter',
        emails: ['kbyreiter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFikg_0Mk_F7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688234752889?e=2147483647&v=beta&t=0psyZoKc5bENQqRoymRNH9lDmR50qr4GKNaBVK4AoKg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  primedentistry: {
    name: 'Prime Dentistry',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dr. Mohamed',
        lastName: 'Saad',
        emails: ['dsaad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG3z56BOciucA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517363863492?e=2147483647&v=beta&t=BDNHVjIXftd_QKagucoYKkzJxn2KLT2WP4uuXrTmnQo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dr. Ali',
        lastName: 'Saad',
        emails: ['dsaad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEVR-IV3GqL3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626188024092?e=2147483647&v=beta&t=i8NriQB9qUGSKIWKRSghQiDv_sllT9HY8_ehRaAVX4o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mia',
        lastName: 'Girard',
        emails: ['mgirard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFBPsNr4jY25g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628435267254?e=2147483647&v=beta&t=L-sPz6ahVrowTTpctii0d94vyfssKkktTT0eti7KIM0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jessica Rivas',
        lastName: 'Plata',
        emails: ['jplata@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEpaPGCwoD_MA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572381904367?e=2147483647&v=beta&t=-Rg-J6Nl2B3YiDwU55DMniCVa8QqpPCo8FirfK_XMZA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Suki',
        lastName: 'Kaur',
        emails: ['skaur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEBBCRiqqrbjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649275560055?e=2147483647&v=beta&t=zJQlcFI6eZHBWEjqwV1yQ5CxjuCEPkA4Gd-jSdKpxso',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  solcomms: {
    name: 'SolComms',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kylee',
        lastName: 'Kaetzel',
        emails: ['kkaetzel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bruno',
        lastName: 'Solari',
        emails: ['bsolari@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sophia',
        lastName: 'Fabian',
        emails: ['sfabian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEEQPuURPdniA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718250179608?e=2147483647&v=beta&t=SGyyhKYBQ9z-VjXhztJHdXhHSdoszec-U4ScmoKOMKE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Isabella',
        lastName: 'Morreale',
        emails: ['imorreale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFL9m9D00cOnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718226601015?e=2147483647&v=beta&t=-91kxFJ6cqElD8WV_tfnt-BuHb0W2L_Wum5vr6XfTS8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elizabeth',
        lastName: 'Snyder',
        emails: ['esnyder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEuhHzQW8o3yQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716412493534?e=2147483647&v=beta&t=A6tOGoCW19IOYpUtMmGHT0aNur6KomdwFyIk2jMHi4A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  theambitiousexec: {
    name: 'The Ambitious Exec',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Whittaker',
        emails: ['jwhittaker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE-J3eSRlL5RA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727991529937?e=2147483647&v=beta&t=8_vqMAdQ_X2lPH5msR9siubTiOMVavTqXZGE0jsG07E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ryan Petersen,',
        lastName: 'MBA',
        emails: ['rmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEubkgHVv_LNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706565424234?e=2147483647&v=beta&t=rfiJdlTTFfo9o63ovAr8S4WnP7qGVPLXcagMzsksLk4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cody',
        lastName: 'Werho',
        emails: ['cwerho@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHPHO0U5m7Wmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723565995545?e=2147483647&v=beta&t=f5Lx7wsNWdkXt-OFPn72ec0_5uzWiTCJy9IG1PmE5yE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dr. Brady',
        lastName: 'Salcido',
        emails: ['dsalcido@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQElV7JLg_AgeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567873296819?e=2147483647&v=beta&t=V14EmWWRriMn_wNOJWmaU-CkFvHnRb8qmjF8chpV0gQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Salcido',
        emails: ['csalcido@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGVscipsnPmEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712545045610?e=2147483647&v=beta&t=zC2XlKiVTdXqVwckVdATKt5VtQWx8n7d9dWz4TdB62Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  reposite: {
    name: 'Reposite',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Heather',
        lastName: 'Stupi',
        emails: ['hstupi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGecG-DoanZtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607733495569?e=2147483647&v=beta&t=BGGL1dr2KoeXPav5rcfEYH4UU9oEZynQ_r4lJycR8X4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexa',
        lastName: 'Berube',
        emails: ['aberube@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFGLsZ0BdwK5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707599661744?e=2147483647&v=beta&t=9qOnR2eXL98LrgYCQaARPvlGVlCTCAylxdhS2o6bESY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paul',
        lastName: 'Klopfer',
        emails: ['pklopfer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE-dVjt3QOTaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638572157905?e=2147483647&v=beta&t=cBh_Kw-drS1yAKJ1PWG7vTBYJKsyXW8OUCVWipNdQw8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Makenna',
        lastName: 'Starmack',
        emails: ['mstarmack@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE4KM8HrXgN-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674801012548?e=2147483647&v=beta&t=oXX-PG3nhnmbZp9RWuR6NtTmWzzFoW5KUPzaY8EvVss',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Janal (Montagna)',
        lastName: 'Kurincak',
        emails: ['jkurincak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHLW2Q5ZUt9pg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667847556073?e=2147483647&v=beta&t=kQFai0zTSNUSFM_3QKzW8tUsEYNAE7WkOkcnBrak3gw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fanownedclub: {
    name: 'Fan Owned Club',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steve',
        lastName: 'Paris',
        emails: ['sparis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHs3x4GW6kXHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667396880742?e=2147483647&v=beta&t=IU69c4kAPtjdjXfYh3R2cdYUl7QhSiGMZe-4HvSWSLQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Sydney',
        emails: ['csydney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kevin',
        lastName: 'Doyle',
        emails: ['kdoyle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGMMIW1nVa8Rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639073408246?e=2147483647&v=beta&t=Du0ocQyV-ev8oPT6XUwddKuCyQIaV1qfGa6HwfNEExY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Scott',
        lastName: 'Sala',
        emails: ['ssala@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zafar',
        lastName: 'Khan',
        emails: ['zkhan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  creatorsagency: {
    name: 'Creators Agency',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Apple',
        lastName: 'Crider',
        emails: ['acrider@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH25S1fwqAJbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687202921022?e=2147483647&v=beta&t=mn61hsymZ8vyWzY_I9EMnUwGbGwncdJZoaphLHkLKFs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Davon',
        lastName: 'Travier',
        emails: ['dtravier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGE0q2_Cv10Zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727718717373?e=2147483647&v=beta&t=rZLxmcwqcwhUfenneSTP8m33S3npmnP9EjcfyLu4o6U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Hayet',
        lastName: 'Chebbi',
        emails: ['hchebbi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFlA_pszPHsGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619644191622?e=2147483647&v=beta&t=YRiXBTaK_ASqwctyktYNNzgNVIz3AVIKNbPz0XcfEXU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hallee',
        lastName: 'Smith',
        emails: ['hsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHvGv_IwlSmeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709698365434?e=2147483647&v=beta&t=9MmQySHnTD1eIYpOEFHKv_rEEGfVwtXF9bFmQkBgSro',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Donald Aduvie',
        lastName: 'Eferere',
        emails: ['deferere@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE0e90322odgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707112059771?e=2147483647&v=beta&t=nvpdFslB9aKWOpYpDzompvTI09oRb1sSsrsL0lqQ1G4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  askluca: {
    name: 'Luca',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Luca',
        lastName: 'Ceci',
        emails: ['lceci@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFs-p5AmM1_aQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673346255784?e=2147483647&v=beta&t=3VKaQx_U8I7BIXfAlAMg8WBYJXA0kdqc12Pi4d1VkTE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Edward',
        lastName: 'Luca',
        emails: ['eluca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFtguqa3cr51g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690130387280?e=2147483647&v=beta&t=4IZ054o2sxceJj36bl9Voh_XtMJ9PmFX1K9wpt5kSAs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Angela',
        lastName: 'Luca',
        emails: ['aluca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF8U6oqRNrxAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693097344625?e=2147483647&v=beta&t=d9BX4O6rySpEq6r2h3qiSBCHoEHc12CzVqsxLIUSDXc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Denisa',
        lastName: 'Luca',
        emails: ['dluca@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Roxana',
        lastName: 'Luca',
        emails: ['rluca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF3nMzz1fuHVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682515079516?e=2147483647&v=beta&t=yN82C6MvaEo-TlAK_30VNJbvRJlepPocbl9yzhZ4tto',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jamesswanwick: {
    name: 'Swanwick',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Swanwick',
        emails: ['jswanwick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGOZNkWwzWt5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676959018040?e=2147483647&v=beta&t=G2lRNAR4ISyoG2JcFFktnhIq-zGtZS1TAHWAJzOt0ig',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rebecca',
        lastName: 'Rolon',
        emails: ['rrolon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGEuWLS3saPEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728137923274?e=2147483647&v=beta&t=8LjHfVbh3OKlnRYXh877XpFdJcFDwpFIavvTFoMxfTg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jim',
        lastName: 'Miller',
        emails: ['jmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEWdjmj0x1jMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642177025961?e=2147483647&v=beta&t=2fS3pFejyB5MMRWBkoG4_DfLfwqV0bJqwkeq4LGrTYI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bryan',
        lastName: 'Thorp',
        emails: ['bthorp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGQI4ecdYd6TA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516655570710?e=2147483647&v=beta&t=NzbgSUsqe22wIqS25VOAumsdP2EWWo5m0G8iDddeQhI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abdisalam',
        lastName: 'Abdirahman',
        emails: ['aabdirahman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  myva: {
    name: 'myVA Rocks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ashley Quinto',
        lastName: 'Powell',
        emails: ['apowell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHlJ1NBBi51bg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588807977173?e=2147483647&v=beta&t=neJeoL51Wwsc_8OhQQCV_l0u8pAMSU2jvRFi5WUFzi8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emily Elsner Twesme,',
        lastName: 'DBA',
        emails: ['edba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFBBACGGGdDZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714144063472?e=2147483647&v=beta&t=zIqsaENA8g97HuhiS2rMmw6MgRjCpmEUgiiwyyQR9jI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sarah',
        lastName: 'Hardy',
        emails: ['shardy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH-BeatTk6Exw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676869143086?e=2147483647&v=beta&t=0HMGk_pugKiE2fNp1wKQ-A-qSRkjObjFF8IYMnd0Bao',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rebecca',
        lastName: 'Duncan',
        emails: ['rduncan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHRMbkmu8oobg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677455005588?e=2147483647&v=beta&t=GDeO_xaqaHT5_4Ec8kRB08Fxk1FjfdwRYj5pIrGA2Zk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jessica',
        lastName: 'Moryl',
        emails: ['jmoryl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHWNGuitb7ysw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707330556150?e=2147483647&v=beta&t=gN-aQXXfnqHTDn1A7HTGhf1wStSDi-OK694dNU-1ow8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  maestrogroup: {
    name: 'Maestro Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Geoffrey',
        lastName: 'Gerin',
        emails: ['ggerin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG_oyC9s3WKgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1527081500978?e=2147483647&v=beta&t=FNT9AM3k6q3PG4VH6u90eVU-8QR7CVGbQUQnqqg4wmM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rachel Golden',
        lastName: 'Smith',
        emails: ['rsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHIlkVNh48mGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626111980051?e=2147483647&v=beta&t=cQxPkFx4_QFiuXbZlgA5CrER-OOswsIpqh4PUKzlwTw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Will',
        lastName: 'Fuentes',
        emails: ['wfuentes@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mokhtar El',
        lastName: 'Bissat',
        emails: ['mbissat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEmbOpMJXqQmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699995017659?e=2147483647&v=beta&t=3wLf5SDudGNAx3Sw1xcNIUBzxD6Av67C3W4LS-WMVP8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Asad',
        lastName: 'Ali',
        emails: ['aali@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jukeboxhealth: {
    name: 'Jukebox Health',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Oren',
        lastName: 'Shatken',
        emails: ['oshatken@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGpJQYDqObi0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675442966576?e=2147483647&v=beta&t=AzyhLowdmn3qDsXXr10MsTnoRZ5Myg235FNcSac4Iaw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Seung',
        lastName: 'Bak',
        emails: ['sbak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGcUyK18zXgeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516240333474?e=2147483647&v=beta&t=UtRRn397AMKxDl1Is-nPdgsnNEQY79PXroRKBU3huF4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daniel',
        lastName: 'Elliot',
        emails: ['delliot@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEL4yxZBR_6aA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636676113351?e=2147483647&v=beta&t=HF0KIbJGBqE_qC9gH2wVvwylkuVe1GX3fbwKB6xy_RI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daniel',
        lastName: 'Weinrieb',
        emails: ['dweinrieb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGMZgkW-UOLtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516325258863?e=2147483647&v=beta&t=XIxwAXzaaoZGrIzlv5xns4dL3ooU4bioLnpxD0Xbymw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adam',
        lastName: 'McIntyre',
        emails: ['amcintyre@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dmsmiles: {
    name: 'Plaza Dental Group, West Des Moines',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dr. Steffany',
        lastName: 'Mohan',
        emails: ['dmohan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGsFIQkvNUISg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1534770679763?e=2147483647&v=beta&t=mXPQonFuZFfQABS4fx_cFPSCfYQn6Sm9wTdjqqGJiP8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Deborah',
        lastName: 'Dietrich',
        emails: ['ddietrich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHsibKBchz5mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570128081033?e=2147483647&v=beta&t=_cEX1EtUdhAMJbdzcoDJXv77jiwg3gNNI_jU3tJrJyQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joshua',
        lastName: 'Mohan',
        emails: ['jmohan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEO40gFwfCCbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684504215007?e=2147483647&v=beta&t=12NbcOtyhHOtO8PBgLHs0PT-oEtRr7OL9mI9ZJ9V3gM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alana',
        lastName: 'Havenstrite',
        emails: ['ahavenstrite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFLnSXg2yPHXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628650996929?e=2147483647&v=beta&t=7J018IbGhWDv14OaRtKWY1oVBREJFLnJWJ4kTxzv6ks',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chavelle',
        lastName: 'Galton',
        emails: ['cgalton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  morphenterprise: {
    name: 'Morph Enterprise',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hardik',
        lastName: 'Patel',
        emails: ['hpatel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Charmi',
        lastName: 'Rathod',
        emails: ['crathod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHWj78sd1bWGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672839037339?e=2147483647&v=beta&t=WtAqL_cAD19etWm_EH8DGwaAktSuk4_3sY2fv5PgjsI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Krishna',
        lastName: 'Patni',
        emails: ['kpatni@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGsSEb6kz3hJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703495494032?e=2147483647&v=beta&t=FpijYni6BAOySGnOPi19Mx9KzyFwTgOsf09_k56RTQo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dhruvi',
        lastName: 'Gosai',
        emails: ['dgosai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEHU2DfIhmh_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672839461585?e=2147483647&v=beta&t=HGe2V_AK7QPVQoMcjsvMRuUkMfLKBmIo3eZJyGbYh8k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anwar',
        lastName: 'Chakra',
        emails: ['achakra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHnRKQYqwggJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631822893089?e=2147483647&v=beta&t=njI1XU1718DGidfO-MpLn4Rzz16a53NPTy9dab_G8X8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  futurehouse: {
    name: 'FutureHouse',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Samuel G.',
        lastName: 'Rodriques',
        emails: ['srodriques@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFuGlAFuvTZ0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590009085753?e=2147483647&v=beta&t=FYCh053Aq5Xel2hi0pZP3T2n9moMJEE0SMlCOy6pgyc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrew',
        lastName: 'White',
        emails: ['awhite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG7s73areHklQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728504238189?e=2147483647&v=beta&t=MJSDXRs06w30lZHDVxkhXnGiMjFvVHj320hqCqY7iE8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Skarlinski',
        emails: ['mskarlinski@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sam',
        lastName: 'Cox',
        emails: ['scox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEfh4xRveCC4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655836550589?e=2147483647&v=beta&t=W5eawyIDSIQQF8mS_kryYPbMIXJBsFNak4JID8HSLfM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lauren',
        lastName: 'Jaeger',
        emails: ['ljaeger@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  mowitoAi: {
    name: 'Mowito',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Puru',
        lastName: 'Rastogi',
        emails: ['prastogi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adityanag',
        lastName: 'Nagesh',
        emails: ['anagesh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFuc3C5nnJeiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517689521848?e=2147483647&v=beta&t=7O3f3vPyM7pzqLDlV15VomS9VthtfxXtsbq6TTWX4bU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Midhun',
        lastName: 'P',
        emails: ['mp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFJd66Gnrb07A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609818797707?e=2147483647&v=beta&t=ExcVVI6RExB9szPkkK31EabhZYD9rc04HuyQ10FMB4w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'likhith',
        lastName: 'sugganahalli',
        emails: ['lsugganahalli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGkNsVuBylHXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642150160178?e=2147483647&v=beta&t=oy-E6kw7_CskQor9LkFRW95fpRGcf9OKAJdHXXbdAlE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'safar',
        lastName: 'v',
        emails: ['sv@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHPfueK0YEbHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661136353195?e=2147483647&v=beta&t=4ZmP9elv2h6fK0H7X5eMvXbSx5BRU5XuHMRC-XTmCJQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thinkhpy: {
    name: 'hpy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Madison',
        lastName: 'Thomas',
        emails: ['mthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHTHXWttKH_GQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681092692192?e=2147483647&v=beta&t=oHkTAjTYDinQX4kO778Db7qRwqNn6NRkajt_cH2CxOU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shachar',
        lastName: 'Perlman',
        emails: ['sperlman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFMTG5a2OElXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516276967452?e=2147483647&v=beta&t=b0zuUYMgS-yBwVKD8fvGyUlwVCwKKOi3yApxVzp5C-E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ashley',
        lastName: 'Utz',
        emails: ['autz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Croft',
        emails: ['ccroft@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF7k6ohmwj7BA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668899753370?e=2147483647&v=beta&t=XSJTDpTw8VTedM4W5wOrjfDEEQESMpZIEzxs1sDo0dc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Josh',
        lastName: 'Fauolo',
        emails: ['jfauolo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGx8s9sE0aLuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567809031673?e=2147483647&v=beta&t=38ijHa3RiA6LY4GHWNpQliPeyAMzfpCb2SeZ1xBVtb8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  citomedical: {
    name: 'CITO Medical',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kenny',
        lastName: 'Aptekarev',
        emails: ['kaptekarev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEdARxvaT02fA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549390786109?e=2147483647&v=beta&t=KZX8mtswGa6kYDZQA3dGubUskkXB6Tw_X8RPsMxktDU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nino',
        lastName: 'Bakuradze',
        emails: ['nbakuradze@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGsp_VKeiSozQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1540024908330?e=2147483647&v=beta&t=KmjgwsbYNpxlTJuwVtDjHs2a3cT2r806cBKPotpgDxo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sankara Venkata Krishna Prasad',
        lastName: '(SVKP)',
        emails: ['s(svkp)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHewum183c9wA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517752734693?e=2147483647&v=beta&t=82gMC-fiHmAaDOcvs4ljQE-B4qT1MbNFeEtciMbKyPo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cito Medical Centre |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGJQiFU8AtY1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586262931733?e=2147483647&v=beta&t=EWwOCkcRpZX4Pr6celLkQYvObKVQy5A2WjVhQlUrhkg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'George',
        lastName: 'Saldadze',
        emails: ['gsaldadze@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFei4dW5ip3Og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593928873875?e=2147483647&v=beta&t=ffGxoOgWmmKCpB3CnHPnkNt6C21kshc9ldU5PsoUlOU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  smrktg: {
    name: 'SMarketing',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cynthia',
        lastName: 'Tello',
        emails: ['ctello@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF4LBkTmNcPYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705963598252?e=2147483647&v=beta&t=9XfnU1t6M_vRDX6Fkr00_TVBDIcyfTSz0xxvi_f6TF4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Randye S',
        lastName: 'S.',
        emails: ['rs.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Felisha',
        lastName: 'Daemer',
        emails: ['fdaemer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGGK8A51HLiEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709404771326?e=2147483647&v=beta&t=GYmbQhpfYQF6ki5Uj3AYYWOfr6botrUEr1ISOSeVGkY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Fred',
        lastName: 'Hess',
        emails: ['fhess@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFwXGjZaUkBIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516647327233?e=2147483647&v=beta&t=Mw0p9HBA7gev-YEgBQedyF-Oz4ZtAyGrjy-4_oln00g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jason',
        lastName: 'Gregg',
        emails: ['jgregg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQED7J1PGXSC-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698182969129?e=2147483647&v=beta&t=tGZpp9WDdGKklItazF6gZ9wfimrgf9rlYCLovvfW9Vk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ndacommission: {
    name: 'National Dialysis Accreditation Commission',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Curt',
        lastName: 'Anliker',
        emails: ['canliker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHB1HtvOA2uLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518020836913?e=2147483647&v=beta&t=dlYW_0IY8pqLMts8ERiBnEdbeFJKFPNJVoIA42TtCQE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jennifer',
        lastName: 'Payton',
        emails: ['jpayton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE2uS9fJX8K5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624280815124?e=2147483647&v=beta&t=ZrBlRr923I2OgEX_7TFk3J483OFDeTLbMUCFuMJGgls',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Allison Hunn BSN, RN,',
        lastName: 'CNN',
        emails: ['acnn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Donna',
        lastName: 'Painter',
        emails: ['dpainter@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jean DeStefano, BSN, RN,',
        lastName: 'CNN',
        emails: ['jcnn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEQLfce_zagyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597597208113?e=2147483647&v=beta&t=D0KHOnTVnaPMqT--3YPHbf6zceed7KiLrqmB_YYHpz4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  lastmileai: {
    name: 'LastMile AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sarmad',
        lastName: 'Qadri',
        emails: ['sqadri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEF4hIbeHKJcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1535771142003?e=2147483647&v=beta&t=5ZlivdR4cwB8SXSwIIV8-AOpEYqbwt2wABBSaT-7vkQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrew',
        lastName: 'Hoh',
        emails: ['ahoh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEnNZrUPZbm6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516490988559?e=2147483647&v=beta&t=q2O0R3iAp24IiCdriYjXtCSnTVbu8ItVRxiL1xd0TtM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tanya',
        lastName: 'Rai',
        emails: ['trai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEUsPN6y3mA_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693860648621?e=2147483647&v=beta&t=kVX_CFcOxCfdHki2FGzDirGpusYMJ5rrx-Yg4rgaJGQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Elaine',
        lastName: 'Zuo',
        emails: ['ezuo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Phillip',
        lastName: 'Chang',
        emails: ['pchang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE9wQQWGAVfyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722537927187?e=2147483647&v=beta&t=0HpC10ZAp6AdZrapccz2QQCafmz4kc-3SkmC2w-2n3g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  codeandtrust: {
    name: 'CODETRUST',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrew',
        lastName: 'Strickland',
        emails: ['astrickland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGwZup5Uh0nng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556457313551?e=2147483647&v=beta&t=yR5AAgjHSPJlZXQTgmkPFfV2ZJpfTzmPOaOsnlONUEE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Patrick',
        lastName: 'Bryant',
        emails: ['pbryant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE7iG9Sqe3sPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517689965414?e=2147483647&v=beta&t=PP_R02N4wbxoAnblqHKODa1zBqImEEzG2Pa7-48lvQQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eric',
        lastName: 'Vaughn',
        emails: ['evaughn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bob',
        lastName: 'Hilscher',
        emails: ['bhilscher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHKllraR_kyfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571669821145?e=2147483647&v=beta&t=yjDy5b4AZIaf9wa7ofh0kfTV3zMRFDeZID4JIZgDmaI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Preston',
        lastName: 'Carlton',
        emails: ['pcarlton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHgFUldAjqu1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572356270166?e=2147483647&v=beta&t=4i7tTNwk63Rp_Gb3vSayrJSiWyev1GsrMUZN5eZN8pU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  neeviq: {
    name: 'Neev IQ',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Manasi',
        lastName: 'Somalwar',
        emails: ['msomalwar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shubham',
        lastName: 'Bajaj',
        emails: ['sbajaj@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ashmeet',
        lastName: 'Kaur',
        emails: ['akaur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGsHOW1RwkeoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692341896076?e=2147483647&v=beta&t=HOyrgTERvy6Gqw3r7lqGYiKxJMt7yYBTDr9jow-eWOM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ruturaj',
        lastName: 'Hegana',
        emails: ['rhegana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEPXzgLJWn9Tg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721331259374?e=2147483647&v=beta&t=SJluFt6LJo6OaZlQVafAoeSBKKa5U6HiOspPEE7KDAQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Parag',
        lastName: 'kudale',
        emails: ['pkudale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEBOFXyHsHoXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516894969066?e=2147483647&v=beta&t=-ymwRM2rVN6sBK1smVzsY1_kW3XfE7fzKQZhN--u8Rg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  kahawa1893: {
    name: 'Kahawa 1893 Coffee',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Corey',
        lastName: 'Stary',
        emails: ['cstary@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEjGtngcObbxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724082405141?e=2147483647&v=beta&t=PB73doll6khBRBbbYJG9oDnL35fACt0CEGE_s-nztgI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andia',
        lastName: 'Simon',
        emails: ['asimon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHS58luJtaRYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665071084764?e=2147483647&v=beta&t=JxPMYJ2CBN0537Y0RJzKnTf-JDqvYsRrn8EksmkwNNs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anne',
        lastName: 'Husmann',
        emails: ['ahusmann@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'mucyo',
        lastName: 'Joel',
        emails: ['mjoel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGyVxvzkUGlVQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1715838650083?e=2147483647&v=beta&t=_NE7ZBd9v3jBD8ZN6BOAVtRxMqqL_LEGarA60zlEqY8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brandon',
        lastName: 'Leeds',
        emails: ['bleeds@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  motioncue: {
    name: 'MotionCue',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Osama',
        lastName: 'Khabab',
        emails: ['okhabab@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEcc_vGO7v8vA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643710741536?e=2147483647&v=beta&t=XaKfsMe__EMnm6mc1nMfGQ9yS3AlS-DlMexr9-DfEIs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emily',
        lastName: 'Warren',
        emails: ['ewarren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG_4suUXZt39g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1535482019959?e=2147483647&v=beta&t=DqqF3tqmBVTXv6mWALgqGRtA_zXI5HCSBjmfKGrWdDA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Julia',
        lastName: 'Hartz',
        emails: ['jhartz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF6SLVwM1wGSw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1721401165567?e=2147483647&v=beta&t=fej4E1d3UwIVSOKAPTyvrsHIJ6lW3GoOf4FhKCYQZNc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hafsa Zahid',
        lastName: 'Umar',
        emails: ['humar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFZ8FOZ_YRCpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695067953734?e=2147483647&v=beta&t=bTGe3EBC8RKMBFkNQqeorgU0FK4R7U4UnlJzYyIS1Ao',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aisha',
        lastName: 'Malik',
        emails: ['amalik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGEZnG29o2vog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706201255391?e=2147483647&v=beta&t=_UKQ-LNsJvnxGrfXFhJVSdZvCpsd3HmsZvKHLSflBv0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kleinepsteinparker: {
    name: 'Klein Epstein Parker - Digital Bespoke',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Miray Kafardian',
        lastName: 'Bik',
        emails: ['mbik@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeroen',
        lastName: 'Bik',
        emails: ['jbik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE0unlvzN-iHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660942987857?e=2147483647&v=beta&t=pZORffM4VufrsRm1cT_7t5D2Ph4rT5ckAN48zfgNCiE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Francisco Perez',
        lastName: 'Alas',
        emails: ['falas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG85j_qPb7pmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641946302962?e=2147483647&v=beta&t=R77Qn0drEuYOsqgZgjSDkMoJE2bc7Z5AtL1agFWhobY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'James',
        lastName: 'S.',
        emails: ['js.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mathew',
        lastName: 'Hermann',
        emails: ['mhermann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGNeTXidwJRKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517402596507?e=2147483647&v=beta&t=3X5_mLHMZbcCwOBpnzJEIUb8HR9KuD5lGOP_65saBRA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  meetalix: {
    name: 'Alix',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alexandra',
        lastName: 'Mysoor',
        emails: ['amysoor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFjiFkQs79dug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691787395449?e=2147483647&v=beta&t=jIrJdcOwJpSG94kYbBjapqXEVULDUFYstr5Ep71AmwU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Karin',
        lastName: 'Stiles',
        emails: ['kstiles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG0WFMQJaYAlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691788043984?e=2147483647&v=beta&t=g1bYDpv_WH5icP9Bihgs3wS-VWw8Cj2dB96LP7OBfwU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sharif',
        lastName: 'Nasr',
        emails: ['snasr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE8QRbQeFZp9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697477032165?e=2147483647&v=beta&t=jM8gQ5VgFkPGz8eiSlwl65N6-fAAv41d4hY5r39fJDk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hugh',
        lastName: 'Tamassia',
        emails: ['htamassia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG6fWnOIKhRnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691786294488?e=2147483647&v=beta&t=TKWk5jnryVZYqt1HnqQ4-P3u9RDsrQdRlAkOP7cg5sI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hannah Marie',
        lastName: 'Lindquist',
        emails: ['hlindquist@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEuXlLhVaoBZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684454590148?e=2147483647&v=beta&t=O4KFQnfT6AiEcntbGbQaiZ2LAZtJLTSMPZSBCqs5J-c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tunego: {
    name: 'TuneGO',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Kohl',
        emails: ['jkohl@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alison',
        lastName: 'Ball',
        emails: ['aball@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGp3gN433NXfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670267752209?e=2147483647&v=beta&t=iHLQwxxfdDUj1HhkmOtl0j-L8vK6QWRcxAY0LPPABXw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachel',
        lastName: 'E.',
        emails: ['re.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFRv2QqM4AP0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720801938772?e=2147483647&v=beta&t=_KIZexfaD7kq-S7_k3E43YhDNDMOgEAj9vxFyf0KuAM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Parrish',
        lastName: 'Robertson',
        emails: ['probertson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHXRxVVIzzHnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517624209850?e=2147483647&v=beta&t=WLSX9PzQfsp9dzVqIkOcA4hMMxaNsOdkbC-6gJY6oPU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Harold',
        lastName: 'MacKenzie',
        emails: ['hmackenzie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGCuGi0wLjseg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568521466098?e=2147483647&v=beta&t=s3Z2U-I6O04N5fOWmh01arRm2Go9InHRDtyQ50Ixt5s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  betterwealth: {
    name: 'BetterWealth',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Caleb Guilliams Founder l',
        lastName: 'Speaker',
        emails: ['cspeaker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQENuxE-9VIGZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685554329765?e=2147483647&v=beta&t=VLap4xTlzZV-vFjuTC2cSoB5F7-Uouc1piwtPRebYyU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alex',
        lastName: 'Landsverk',
        emails: ['alandsverk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHniokEJ_8MyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728425874320?e=2147483647&v=beta&t=GwvDDuSxgpvpDS6RHLxx9gh0m_Jek-dwQ3vOf-ATlwM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ezra',
        lastName: 'Cieslak',
        emails: ['ecieslak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHC6C8jU77gLg/profile-displayphoto-shrink_200_200-alternative/profile-displayphoto-shrink_200_200-alternative/0/1529340832879?e=2147483647&v=beta&t=ZVNU9cpDXCxnsJw1v9BkI0DpXDEHOy9eLyipYJ6pqgs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tim',
        lastName: 'Wollbrinck',
        emails: ['twollbrinck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFjgm2y_oTpDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705349413483?e=2147483647&v=beta&t=xhrrnSOsUFwUG-XMAs9NLLReb2aL8gUwsY8vzP0qM6Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Russell',
        lastName: 'Telfer',
        emails: ['rtelfer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mintleads: {
    name: 'MintLeads',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jack',
        lastName: 'Hynes',
        emails: ['jhynes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGKQaqCAghbxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572003782080?e=2147483647&v=beta&t=yr6492vZiHuNXodmZ7frqq578HX_yYK8weZ3Q2_tXJA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Natalie',
        lastName: 'Turner',
        emails: ['nturner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHJSvlLvVjR2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692725242048?e=2147483647&v=beta&t=JuiPI9pKi19w0oaglbCPW-2l32_t3gR62904y_58fXA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jordan',
        lastName: 'Defty',
        emails: ['jdefty@example.com'],
        image:
          'https://media.licdn.com/dms/image/C5603AQExOCPqc8r2Zw/profile-displayphoto-shrink_200_200/0/1581341973514?e=2147483647&v=beta&t=7xzNhHcDUSYfKjNQTWponxTDefxTkuskSNq6niP9TzM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Reece',
        lastName: 'Whitby',
        emails: ['rwhitby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGepWbwvQ09kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516643997924?e=2147483647&v=beta&t=-3W5fKEtbQnD_QPfz3PuqdQngSxyEOSya6GvAEfg7cw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tanner',
        lastName: 'Blumer',
        emails: ['tblumer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEXxpR0_WaxXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629468741006?e=2147483647&v=beta&t=I6bPyS4krTmrmmg4bR4iPBgoJcZdOWzq9JRFPcpJa7U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  astuteng: {
    name: 'Astute Engineering, LLC.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Saurin',
        lastName: 'Parikh',
        emails: ['sparikh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEHP0Y8wa5uKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517404366140?e=2147483647&v=beta&t=qWHAN4UcMMPrJeUbG-Wovte9mR3SDs8NELbti4AnOeo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Haresh Umaretiya',
        lastName: 'P.E.',
        emails: ['hp.e.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kishan',
        lastName: 'Sapariya',
        emails: ['ksapariya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHkpZkbY6L0jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671900747329?e=2147483647&v=beta&t=koXxy-tP5ogrvPFjQnTpoziWzQpv13nSuFnMgM1_f2A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mohak',
        lastName: 'Parikh',
        emails: ['mparikh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE_ocpPgaaZYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698964443908?e=2147483647&v=beta&t=hjXQ49apJl2hwEq4v2XhWWF_ySWHpn7rImGlsVwj2BQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Fashan',
        lastName: 'Fahmy',
        emails: ['ffahmy@example.com'],
        image:
          'https://media.licdn.com/dms/image/D4D03AQFYV9T9aMovUg/profile-displayphoto-shrink_100_100/0/1715841194960?e=2147483647&v=beta&t=RczfUjpo7hV53iZ_1YZz7bq8e8quPArN5JQ6hKvb31s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  plumenetwork: {
    name: 'Plume Network',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Yin',
        emails: ['cyin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGDraF8v1sdRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646433525743?e=2147483647&v=beta&t=hj1DNedS7FXME8Ni-4NYkp9d0jXC9B-ms6EuXdm6KzA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Teddy',
        lastName: 'Pornprinya',
        emails: ['tpornprinya@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ivy',
        lastName: 'Kang',
        emails: ['ikang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGgXRpq93nU-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718291515518?e=2147483647&v=beta&t=R8A9K5yPXqQDXIpB28WISG5YgpOAQSnX9vbtfuXlSSo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tiffany',
        lastName: 'Lung',
        emails: ['tlung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFeQHIxpXoqrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727661294290?e=2147483647&v=beta&t=dei10BqD7Rt7jyoNXhX3T1D_-13URqPar9UReOqigPc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eugene Y. Q.',
        lastName: 'Shen',
        emails: ['eshen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEiq7EqmJuFLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718235485679?e=2147483647&v=beta&t=qry2cXjmOnJ0knOzIU6Sp5_9TuZAaIgym8DSxaYmCsk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  madewithglimpse: {
    name: 'Glimpse',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Peter',
        lastName: 'Attia',
        emails: ['pattia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE2YQY-tWaWVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727193421448?e=2147483647&v=beta&t=QA7YLsjNzxlcp0h5a7PjjbdHgRW-YivePgNjxd4LHR0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eric',
        lastName: 'Moch',
        emails: ['emoch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGN55jPZ5mHLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708882641437?e=2147483647&v=beta&t=cTF1G14x_ONXxWBT4jZUIcdKt75ZP5icsH4IaBMXrUI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patrick',
        lastName: 'Herring',
        emails: ['pherring@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFqZV01Ge_ZRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708462320583?e=2147483647&v=beta&t=G-H2aiB_NYxhu8Fc_qYx7DmXrAGjZvYvit60G0Ffces',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pirmin Ulmann –',
        lastName: 'Co',
        emails: ['pco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEGJTq5KPbdtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602498324911?e=2147483647&v=beta&t=krHcu3qRnDDJeHC9jyAUldXym_GnKEmUrBPRsgjA6Zw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Keith',
        lastName: 'Hochhalter',
        emails: ['khochhalter@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  regionalaa: {
    name: 'Valley Regional Anesthesia Associates',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joe',
        lastName: 'Martin',
        emails: ['jmartin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFXDgG84tlhMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674374238454?e=2147483647&v=beta&t=SX_BOyyWQi9ph1W2QMfLThiJA5qik0EqeyPO1x0prMM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ryan',
        lastName: 'Walter',
        emails: ['rwalter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFrGACr4rd8Zw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601423188408?e=2147483647&v=beta&t=f-TjGbldTRh6-7W92MoKy9H27cpEsdjpIshRZWH-B-4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kathy',
        lastName: 'Mayo',
        emails: ['kmayo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Drew',
        lastName: 'Washburn',
        emails: ['dwashburn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFKRtDvp98Zeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696914805423?e=2147483647&v=beta&t=UmrrgGVp4jCbOSI9RFac8WaPLgyqVWUMPfnAfUdIbJA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aaron',
        lastName: 'Lipinski',
        emails: ['alipinski@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  joinnimbus: {
    name: 'Nimbus Health',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paula',
        lastName: 'Ithier',
        emails: ['pithier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFezrV9ts1dZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517027652658?e=2147483647&v=beta&t=dMhlG6vlSYjMm1pfyyCHdS-Pq2ktPUnQRIM822jmiQI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Indravadan',
        lastName: 'Joshi',
        emails: ['ijoshi@example.com'],
        image:
          'https://media.licdn.com/dms/image/C5103AQF4U-AwSf-9rg/profile-displayphoto-shrink_200_200/0/1586317191378?e=2147483647&v=beta&t=ucrDbgzaQcf4hxcbElE1f7spOeONWiPBk2aQuz7BQkA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ganesh',
        lastName: 'Marasini',
        emails: ['gmarasini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGz45k9aBrp9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691026557497?e=2147483647&v=beta&t=wsc1crPr7yhfFI8lnRGEScjNTmV9zkDlrmbO7XJf_rA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mohit',
        lastName: 'Agarwal',
        emails: ['magarwal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHO1idUCkkQ2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659851438999?e=2147483647&v=beta&t=MK1EMElOrGFbokoSE9Oj9JUF2DTCnoHNZb6eDBjWAhE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Guru Prasad',
        lastName: 'Prajapati',
        emails: ['gprajapati@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHd90osEd5JCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517528090621?e=2147483647&v=beta&t=ll7y45R85TDlivGmdwWtU_y9PI-aRQSUL28NSDkDRk4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  syndesisHealth: {
    name: 'Syndesis Health',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'George Zarkadakis – Syndesis Health |',
        lastName: 'LinkedIn',
        emails: ['glinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHABlBGCuqBLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631214883471?e=2147483647&v=beta&t=A4RcGQTWZtl5xNy9FgL3DxLYOls030EXnJhL-ZqPbvQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sean K.',
        lastName: 'Rice',
        emails: ['srice@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHf0XQJjSVrCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728515783202?e=2147483647&v=beta&t=lPg1nL2rmw8Z3HKCDHO5DloNA6Gf1moJ9E7hTs4oh20',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sue',
        lastName: 'Beecroft',
        emails: ['sbeecroft@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEvjaHaAp7YFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548840859924?e=2147483647&v=beta&t=_1R3UPCRlWAT_-nbk0QIaN4lwCUB3LEdhAubXaOSQw0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carolina',
        lastName: 'Fierro',
        emails: ['cfierro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHXIqTHrHShmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684257685188?e=2147483647&v=beta&t=SPFM12BVzZ02cFFvlw1g5o3tYfNKUGvQXCRMs_HIuG4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nicolás D.',
        lastName: 'Franco',
        emails: ['nfranco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHyhmO_ELYf9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661467951246?e=2147483647&v=beta&t=hfLJB6Wg5v9pi64Iwd0hb32mvlyUKF7AJ9SueXlsN8Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  untitledsecret: {
    name: 'Untitled Secret',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Catherine',
        lastName: 'Crozier',
        emails: ['ccrozier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHHE9PyiexOFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709516985911?e=2147483647&v=beta&t=y5dIXyafUmCXMZ5XWjSsuMyBfNh8-_pIP-UCKUdl0oQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Margot',
        lastName: 'Wynant',
        emails: ['mwynant@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Robin',
        lastName: 'Hellmann',
        emails: ['rhellmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGX-XRQYOmf4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715813322161?e=2147483647&v=beta&t=Fvodi3KQ3hinLvcuBN2yR-Inl120gec50sDFK_M2seg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alison',
        lastName: 'Gamiere',
        emails: ['agamiere@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHFKFSzyYH4vw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716229657531?e=2147483647&v=beta&t=BITWGFbTnDbxOMj1GQ1zakuOmP7RoG7cVTp4KPBeHa4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'Lessner',
        emails: ['alessner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFVb7tw5P3yqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611540953423?e=2147483647&v=beta&t=LHqM3kEMfk-ZuORzQvrDM7RI9PhDTjSkFJ0Nb2EJG8w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  arenacollective: {
    name: 'Arena Collective',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Stephens',
        emails: ['jstephens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFPMZmjb4FCZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673827099108?e=2147483647&v=beta&t=xz7rde6_G6fOCMjelnA95MRDfkmsaPID5EAH_FFNBOg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Audrey Gitz',
        lastName: 'DeAgano',
        emails: ['adeagano@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rebecca',
        lastName: 'Norton',
        emails: ['rnorton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHKawIG5GZfBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673876728534?e=2147483647&v=beta&t=MeDS7EM7RiZw4s2ftY8Cf-hU4BU02lXeew-mQZm1Dkk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jared',
        lastName: 'Miguez',
        emails: ['jmiguez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG2VD3gU5xqWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674863635870?e=2147483647&v=beta&t=IjMK_06rxyMnRgYX4MfDCkwJr_yQ9A-R6BPqzTJT7co',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lauren Kaufmann',
        lastName: 'Navarre',
        emails: ['lnavarre@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE0UVgM0tMhvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673828572989?e=2147483647&v=beta&t=g3I_Hg4qvf4wcMxr8QPhxd4M33ijXRiQGB2ibbb1Wyc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  socnitymedia: {
    name: 'Socnity Media',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Heidi',
        lastName: 'Magyar',
        emails: ['hmagyar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE4oUzciE_tzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713297216568?e=2147483647&v=beta&t=HoFtYA8Kwr_4DRnmjgDhcFn90e3nc6KqW28tjLPymm8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jaime',
        lastName: 'Guzman',
        emails: ['jguzman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGNZCCxWeFcYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729199825996?e=2147483647&v=beta&t=J-zkoPU0IWr3h0Zv4I9-jD5UO-61fuDeJ-ZnrZP9jiA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jed',
        lastName: 'Karban',
        emails: ['jkarban@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEEVnRWWJaO-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699254163353?e=2147483647&v=beta&t=ENQFlJv5HBSJ4FjVzNXn32u7j9lXviWHaeQJuxcH2q8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tracey',
        lastName: 'Warren',
        emails: ['twarren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEqLwcCCOSl8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713715604709?e=2147483647&v=beta&t=D7qUPWYQpklDDm6jMtTRFLVaxAbS67RinxqJVVfhZWw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mateah',
        lastName: 'Kittle',
        emails: ['mkittle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE9zh6vPGw1Qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676660645412?e=2147483647&v=beta&t=C3a7NoTgoFNq1m-6A0KU8jz4OE8-6xg4q6ywQeBHlMQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  listoglobal: {
    name: 'Listo Global',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Landon',
        lastName: 'Pitcher',
        emails: ['lpitcher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE3LOePRaY7gg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654120561256?e=2147483647&v=beta&t=81zAvsOkLq5Ou0fik5hRTcjG-bjG1ONcJ_4meKXyaUM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Freddie',
        lastName: 'Ashby',
        emails: ['fashby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEI_7-S-e0Oag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683905816612?e=2147483647&v=beta&t=9qNxTWiWery-aQHjq2Z9ZzG-tziCmSEGx62Un7PnP20',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tyler Dixon,',
        lastName: 'JD',
        emails: ['tjd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Judi',
        lastName: 'Owen',
        emails: ['jowen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFod6GpadJvfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687273008133?e=2147483647&v=beta&t=VC-gO53P-NoOnA4Njs_yOkNNDonYcOKdu26wV6QaAe0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kellie',
        lastName: 'Bishop',
        emails: ['kbishop@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGsNsL3Q6pV1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686336531997?e=2147483647&v=beta&t=EV_131BjqZYTxaAkiPBAXEfypfGSnY4No653Iy_lfow',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rocketdevs: {
    name: 'RocketDevs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ali',
        lastName: 'Elzeiny',
        emails: ['aelzeiny@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFh1ix1NCDp8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706886491635?e=2147483647&v=beta&t=L5hSmuvHVbTeckf0TkEXSwd0v2eL6UFOaeH5uxI1vss',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ian',
        lastName: 'Kitembe',
        emails: ['ikitembe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGeIcyQZvJeFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680178827542?e=2147483647&v=beta&t=JhpYvhDQofYNRH844ICslBpzN7sgPTw78abaA5_8llQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jackson',
        lastName: 'C.',
        emails: ['jc.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Naomi',
        lastName: 'Salami',
        emails: ['nsalami@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGjFjfoGjarRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616604381432?e=2147483647&v=beta&t=N5qEkUlL1br-Qjc8E2PqT6ZKJKyyVNy-4CKpkqJX-YU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jude',
        lastName: 'Fabiano',
        emails: ['jfabiano@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  intellosync: {
    name: 'IntelloSync',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aakash',
        lastName: 'Chaudhary',
        emails: ['achaudhary@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFe0LoPkUZ7Sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580378673753?e=2147483647&v=beta&t=QpWV2zxPhq4WOubVQmia-d_PSAB70HWO1Ks7htRn6qk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tanul',
        lastName: 'Agarwal',
        emails: ['tagarwal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEG2sDjbtAFQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709808564510?e=2147483647&v=beta&t=ZhUoX1VRaktef17oVrHB8pQ4gJvci3UTWlHV1Wfejsg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ritesh',
        lastName: 'Kumar',
        emails: ['rkumar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chinmay',
        lastName: 'Mehta',
        emails: ['cmehta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sagar',
        lastName: 'Dhawan',
        emails: ['sdhawan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEGLN0oO5_GCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711399352640?e=2147483647&v=beta&t=F35xLCJchxmwiFx1hLoFYTHS7v0KlpKAR7V-GfshPjM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eventible: {
    name: 'Eventible',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hanif',
        lastName: 'Shaikh',
        emails: ['hshaikh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH7Al6vuSd56w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619689499728?e=2147483647&v=beta&t=Isv_SQZOdrHf-nPODBN2i45_xosE-EdyjjaS7WgjXu8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aarzoo',
        lastName: 'Bakhshi',
        emails: ['abakhshi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFedggWM4hXHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685427435012?e=2147483647&v=beta&t=zVT4QyMm-HrJZA4NpzNPT_5jlJFUz-DDFru1gt7Ta68',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ishani',
        lastName: 'Bose',
        emails: ['ibose@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGuFNb0baIM-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727786920725?e=2147483647&v=beta&t=YpGSiKHn77Q1RieXHzNRZ_hnB8O5X9TTorkovFCCLUc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ankush',
        lastName: 'Gupta',
        emails: ['agupta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mohammed',
        lastName: 'Saif',
        emails: ['msaif@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGiTkVKLCnmeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687423523102?e=2147483647&v=beta&t=S97V_1rZjjzixioUGv967LF3YkuwNFylLPvg0roLSeY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  dmeltzer: {
    name: 'David Meltzer Enterprises DME',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Meltzer',
        emails: ['dmeltzer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEVy1d702ozMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580857364577?e=2147483647&v=beta&t=FJiGds5pyCYb1xWNIk23cBsw_FXVwfChdLYK-KUt66Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nic',
        lastName: 'Glazer',
        emails: ['nglazer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHC_fLvQNKd4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714803848652?e=2147483647&v=beta&t=wo7-tUKhcEfDNUoCG2_PtyPD9RFrHFJYrExtHqK2Yiw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marissa',
        lastName: 'Meltzer',
        emails: ['mmeltzer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGxhH8KuLYLHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677864886038?e=2147483647&v=beta&t=v2cFltsoEcFKCuph9Po3pggC7X97fkH8GanvtzBtDGY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Justin',
        lastName: 'Pugh',
        emails: ['jpugh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFViXc_AY3wlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615945497416?e=2147483647&v=beta&t=rDNcV1iTzOqxMHorNrSsTlWzEpSMjJiC_tz5DHwrOcg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Colleen',
        lastName: 'Maloy',
        emails: ['cmaloy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGMJJquNok24g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673458050249?e=2147483647&v=beta&t=6G7JHLOe0WZVAPpaows45mQT9JmXKEdHiMBp5oOyfHE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  kyriestudios: {
    name: 'Kyrie Studios',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Burbage',
        emails: ['bburbage@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFebAljiSomfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703080791600?e=2147483647&v=beta&t=D_aCvTPpoYD8ef_16IiJclmDoa8ntDHP0hJgvjv5930',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dominic',
        lastName: 'Tramontana',
        emails: ['dtramontana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG3y_uUQCZgQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719528577771?e=2147483647&v=beta&t=WAo-N7vxVHHceA3R_WW9PFm3tS-PhmptMVXdwI6a4KA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Martin',
        lastName: 'Castro',
        emails: ['mcastro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH8uRam7ooiFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719042516685?e=2147483647&v=beta&t=oFMMCfrQJtpGYR_iXqiW_kB_r99ntlwVyFmfylJL0H8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joey',
        lastName: 'McNair',
        emails: ['jmcnair@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHlRTj_Nflw5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616608131619?e=2147483647&v=beta&t=PSJ9K9a0X4z59ku24CDsaVel5bpnz44y8b8ybnEASUQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dylan',
        lastName: 'Tanner',
        emails: ['dtanner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF7O3T1wl85Ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712931562569?e=2147483647&v=beta&t=Lii4gan1joA2mExMSTwBWP09m4leKWFebTWRoKDAlFY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  reupliving: {
    name: 'ReUp Living',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ryan',
        lastName: 'Sawchuk',
        emails: ['rsawchuk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHxFDQ_2Vt4-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713988784276?e=2147483647&v=beta&t=BXb2oE3OM-3uWdEXoWflAiDdvT31_Gfs108dmndDJWk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jenni',
        lastName: 'K.',
        emails: ['jk.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHCkMtR-ZVshA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713897082258?e=2147483647&v=beta&t=dhYo2Jc26gu0gkD_Ockd6qqUMgYjLbBQYvB8lTfcK6s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alex',
        lastName: 'Kassem',
        emails: ['akassem@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEr4XRVzFFVQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624658811333?e=2147483647&v=beta&t=uz9duj-VrrzmcRbsI2bFetaA4Cd-aX5QhmYdL5vMpLc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brette',
        lastName: 'Abshier',
        emails: ['babshier@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew W.',
        lastName: 'M.',
        emails: ['am.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  allprorecruit: {
    name: 'All Pro Recruiting APR',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adam',
        lastName: 'Bickel',
        emails: ['abickel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG7lBKxoVM7_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606833734047?e=2147483647&v=beta&t=np19oS3qPJIWn46MT-Q1vvY4BD46-gvsj-DhoYa4tCE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mike DePaulo, LSSBB,',
        lastName: 'CDR,',
        emails: ['mcdr,@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFDJzYZuDa2sw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696888161036?e=2147483647&v=beta&t=PBN8fImE1boClg72H5--g0OeSRSvrjVHazjoiOWuMFY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paula',
        lastName: 'LaRich',
        emails: ['plarich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG-0NOh8ySgoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680633964856?e=2147483647&v=beta&t=ySXIHG4Vt6NKfvvMX_J2OlwD5n6vjyb6po-h_ncHlXo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lindsay',
        lastName: 'Verstegen',
        emails: ['lverstegen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE28qhXc4xJ2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655928263656?e=2147483647&v=beta&t=tcdelOjP1fb3r6v4W3WECRJE0iOxydNYbtLPkpvK_YY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Scarlett',
        lastName: 'Englebrecht',
        emails: ['senglebrecht@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHuDet16t2eDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727228158888?e=2147483647&v=beta&t=z6qdER5WcTQ8tSNvDwCKwLn2hu8GA-2yA-O-8LEI5ck',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  surfaice: {
    name: 'Surfaice',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alim',
        lastName: 'Uderbekov',
        emails: ['auderbekov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFhwpGOZcfyeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719375352827?e=2147483647&v=beta&t=Xfyn3V0P8_r2E-uAiUyAh3kQapCUCSdl035HOc-sHM4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Slava',
        lastName: 'Solonitsyn',
        emails: ['ssolonitsyn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHYGzY_J65eIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579434518188?e=2147483647&v=beta&t=kmup3ehJSSvghOY0fXUYb5C_7MTw9Y3PKZCbHEX3-jY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Genevieve',
        lastName: 'Davis',
        emails: ['gdavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGczQYURMADpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694805418788?e=2147483647&v=beta&t=GZHe3ipdZnX2tA62WC6IvkQQxsdILPMi5Mamwgnosko',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zhanel',
        lastName: 'Zhoshina',
        emails: ['zzhoshina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGoXLJfYN5vSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713145238362?e=2147483647&v=beta&t=fd6ZtZe5SeHceL9MnSkD30KWThQgRyGP_N9xI3ZjGB8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alexandra',
        lastName: 'Khlebnikova',
        emails: ['akhlebnikova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGH9ctIf7z_GA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653554553213?e=2147483647&v=beta&t=qEV-5rLv54iEBiYxmzQ05hdSUPMVPpFb946LH6soT6k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cyclecpa: {
    name: 'Cycle CPA - Landscaping Accountant',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steven Majoros CPA,',
        lastName: 'CA',
        emails: ['sca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFh0omvMHK9XA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625687766605?e=2147483647&v=beta&t=IGU5IE1sorp9feW-AmYmfmmZKGc_lW7d2h9jhvU4Xkg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joseph',
        lastName: 'Policastro',
        emails: ['jpolicastro@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Nielsen',
        emails: ['mnielsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFDbT68eNxVWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516828282486?e=2147483647&v=beta&t=zQ7h_-0SuhY5DciJeQPIny15FCeGJXaJ7PrMCFWnZXM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carla Policastro,',
        lastName: 'CPA',
        emails: ['ccpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEDmhq4rFjDUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610763951843?e=2147483647&v=beta&t=np0Ql14mkP9y9sPAl3bTbLzvLn3Qe9YrXzVySOyKrLg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rebecca',
        lastName: 'Porter',
        emails: ['rporter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEkbY5gE-5O4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705928691654?e=2147483647&v=beta&t=lQlJ1mKKBqbuCIaPDZDKCcxwySMb8cA9mDsiaLq48As',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  zerovfx: {
    name: 'ZERO VFX',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Viera',
        emails: ['mviera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF0pPyqy8VK2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660056459988?e=2147483647&v=beta&t=V107zPJUhFIjyDUWpJ0Lw0Yol9xgelVgwtJbDByVQkw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Geoff',
        lastName: 'DuQuette',
        emails: ['gduquette@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHJarwgi3qmnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709084395630?e=2147483647&v=beta&t=oi7daBnr2TwU_p_EBymdF4BLCW6xKiDwzTcyeDNOQ7I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rich Hardy',
        lastName: 'Jr.',
        emails: ['rjr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHtiUEP12vmlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536200149190?e=2147483647&v=beta&t=naFox3uC2hiO08N6oS9NGD90lvaOJMwjRTsxfXx9G_8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dan',
        lastName: 'Cayer',
        emails: ['dcayer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ashley',
        lastName: 'Knehans',
        emails: ['aknehans@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEUhFyMdKeMpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1525731270859?e=2147483647&v=beta&t=i7AVvRisEZJ4u3ywBGTPXWR79f5Mb6vpYqs7W3z7Pj0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  witzonetechnologies: {
    name: 'Witzone Techonologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'George',
        lastName: 'Bailey',
        emails: ['gbailey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQF2B6eCWjEZ5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559774894631?e=2147483647&v=beta&t=AS-UcZveHGJgDr6c73RhFhSEZbIWrY7QnrpIFsp7drw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hephzibah',
        lastName: 'grace',
        emails: ['hgrace@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFHVdpOLCIllg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708350512484?e=2147483647&v=beta&t=oHt2txumECtZOfq41nAl0wD3Wx2NwIQvTB8LhxyFwEY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'vivek',
        lastName: 'kannan',
        emails: ['vkannan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gayathri',
        lastName: 'Ganapathy',
        emails: ['gganapathy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG1nBZPsayROA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707473384124?e=2147483647&v=beta&t=qjXcEX22vua5b24xYKjmUsYqKDIZ9DYK_cAttIWhpRY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'bhanu',
        lastName: 'Bhanu',
        emails: ['bbhanu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  quitsure: {
    name: 'QuitSure Tobacco Cessation Platform',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kriti',
        lastName: 'Bajaj',
        emails: ['kbajaj@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ram',
        lastName: 'Chandra',
        emails: ['rchandra@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nargis',
        lastName: 'Jahan',
        emails: ['njahan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vaibhav',
        lastName: 'N.',
        emails: ['vn.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE516LmSpLACw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718284605370?e=2147483647&v=beta&t=d0mm-qIAIgo-nhaJl__QS_qL4i0juGLLXQCcpp2NFBU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Suraj',
        lastName: 'Pawar',
        emails: ['spawar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF4d0RMyCnPlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651732127355?e=2147483647&v=beta&t=SKEDoqXUYeP5aEJutRPF3NFab2UJM58VXTgbKXvZiBk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cybersecuritymarketingsociety: {
    name: 'Cybersecurity Marketing Society',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gianna',
        lastName: 'Whitver',
        emails: ['gwhitver@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFyIzmseccz9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702790837195?e=2147483647&v=beta&t=YE6G9Gmk4DifPkJ_MS-5kbY2VRytD9AJfoaGlQJ7O8M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maria',
        lastName: 'Velasquez',
        emails: ['mvelasquez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Melody',
        lastName: 'Kaufmann',
        emails: ['mkaufmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGGI5nogsjCeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586547573489?e=2147483647&v=beta&t=iInKkrzABZ-2iBO7nUeroqVzFYUUQWzGU01uj_5IGsQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sarah',
        lastName: 'Thaler',
        emails: ['sthaler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'Hayter',
        emails: ['ahayter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFtdCEzXQPE-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653654041963?e=2147483647&v=beta&t=ji571Z4O63Hla7b_CZdUhJNS29REhf_zcCIwfnBylDQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fal: {
    name: 'fal',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gorkem',
        lastName: 'Yurtseven',
        emails: ['gyurtseven@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHmO6cyr0Pgig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639611430116?e=2147483647&v=beta&t=hvjN2k2M3Gg8Sm2JL6-GixwXnTN-5ESKPYzCRuUscOg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Rochetti',
        emails: ['drochetti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFgB5M0QnBioQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1523844569825?e=2147483647&v=beta&t=I5Nbi-zSxrQqtZI7PSYfE8nR2B_hHj2EFoOO6tBU6zE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Batuhan',
        lastName: 'Taskaya',
        emails: ['btaskaya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQESr3qDdbdQmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700707110472?e=2147483647&v=beta&t=zkuKYeG9_tZ4taZyz0U2tChptaHPdJIXYufTGgitkwE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alfredo',
        lastName: 'Andere',
        emails: ['aandere@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFFtx8bALW9dw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698988888887?e=2147483647&v=beta&t=q0pgvkrL5pGL3bIwum9CbHAf7ATDmCjmo0SJcU1qxwk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Monika',
        lastName: 'March',
        emails: ['mmarch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH_GcMHeD6I4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714931405109?e=2147483647&v=beta&t=X_n2NRKFlcGNZ46vqVSGWPLlrHihov4jX0smGQHEOeY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sloaneStaffing: {
    name: 'Sloane Staffing',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Max',
        lastName: 'Spanier',
        emails: ['mspanier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGx5HWCnMfaSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727813572756?e=2147483647&v=beta&t=_Tx-jkLdfcvoUog-u8DRDijlbGgvLVVEj_5s0qalyOI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Scott',
        lastName: 'Townsend',
        emails: ['stownsend@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFZC5b1WBwUSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727118876688?e=2147483647&v=beta&t=z75KVfQyvoKrdBpKWw0GFn0Ilto39m1K7u7MMXmMEFI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jennifer',
        lastName: 'W.',
        emails: ['jw.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHRcIQcD4z8Qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680528504808?e=2147483647&v=beta&t=hhyteYyHO3rtgoSJa2M677aakJ7hCHsloGZN6mh0QdM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shea',
        lastName: "O'Donoghue",
        emails: ["so'donoghue@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEVdN5XkYzJNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727462290743?e=2147483647&v=beta&t=c7cAIqIL7t24vIx5KnYveANZBJhF0_Jnm7-KQcYwQuU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lindsey',
        lastName: 'Spanier',
        emails: ['lspanier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH9RFrFXrTD5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618927036207?e=2147483647&v=beta&t=ORHSc-nG2BdFK1VHp_JYluulu43AeeLh6Tavgyl5h6k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ipstyle: {
    name: 'IPSTYLE Patent Law Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mariya',
        lastName: 'Ortynska',
        emails: ['mortynska@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEsymFibmWn_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631692785512?e=2147483647&v=beta&t=AABln5906engF_w7D6NyUdrZC-QT157cqtdtXEqbIgY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Olena Polosmak – COO & Founder – Pocket IP |',
        lastName: 'LinkedIn',
        emails: ['olinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGoYDvm1JovhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724229017357?e=2147483647&v=beta&t=zJMvMKLFjgd9iK_qjkFA-VZ6QPWDfRFRHJOiY4jl7ms',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Мария Ортинская – Board Member – National Association of Patent',
        lastName: '...',
        emails: ['м...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEsymFibmWn_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631692785512?e=2147483647&v=beta&t=AABln5906engF_w7D6NyUdrZC-QT157cqtdtXEqbIgY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Anastasiia Pavlyk – IP Lawyer, International Department – IPSTYLE',
        lastName: '...',
        emails: ['a...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGw0mq-XtI7Tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694155942903?e=2147483647&v=beta&t=Ufb0IQyXp73mWXA41a-Ow0hiGRMbr5tK16e5RIuWsB4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ivan Nikitchenko – CEO – Pocket IP |',
        lastName: 'LinkedIn',
        emails: ['ilinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vitalbev: {
    name: 'Avana',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sundip',
        lastName: 'Patel',
        emails: ['spatel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sanat',
        lastName: 'Patel',
        emails: ['spatel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHX4fnCbXMxLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665536985659?e=2147483647&v=beta&t=4Qi9fceUXTf5RV8jPsHAAftW_ZGQC8GfVVesdCOwR8E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Tansley',
        emails: ['mtansley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGJZExgRQ1uFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516891753803?e=2147483647&v=beta&t=tcUPF_CcQfEKnkn1ZpOaiU1Kr38koDR4z36gTx61KmQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'Hunt',
        emails: ['mhunt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEh5WcoM5SvUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727905440139?e=2147483647&v=beta&t=RmbNrDyhTaMTC2ainl0VGaubdEG5LwpMnf-xvuqLBuY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chad',
        lastName: 'Witcher',
        emails: ['cwitcher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHP0d-GerfoAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519145678900?e=2147483647&v=beta&t=KXQWgaOQHVd11LUltc5pybcvqZU4H653K7iVDVdglpA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  epicamed: {
    name: 'Epica International Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Holly',
        lastName: 'Whitaker',
        emails: ['hwhitaker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Craig Glaiberman',
        lastName: 'M.D.',
        emails: ['cm.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF2XpDJX-bBuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706524246865?e=2147483647&v=beta&t=rjuQ4XZAc8GclAXiG1l4qgvvaeUIsom-uJB8LOtBrQU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Greg',
        lastName: 'Stoutenburgh',
        emails: ['gstoutenburgh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG5CFYRvinTCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722464671445?e=2147483647&v=beta&t=RfG74_H8OK_IcfiifJCZWiAnlAmpZ5am6S9iyhMZGbo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joe',
        lastName: 'Soto',
        emails: ['jsoto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE4CR_VKD3rpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712247403949?e=2147483647&v=beta&t=FwrN-K_NilRhwtsXjvKetcm4_pCqJ3Qy4b_AAldVSQQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dan',
        lastName: 'McMahon',
        emails: ['dmcmahon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEvjJ7irqFsDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675105972944?e=2147483647&v=beta&t=5h_-ifhtjYQtridcBiwGuWiWVbsBvju-vPx6-KCjYcw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  truenorthsocial: {
    name: 'True North Social',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kenneth',
        lastName: 'Bost',
        emails: ['kbost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF49Q7Rc1gw2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596549949073?e=2147483647&v=beta&t=Azful2GEjwc_u0_AWrrIz3k64XoIgF1lZ2fQBgJZb4w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Willy',
        lastName: 'Evans',
        emails: ['wevans@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHTDzHhGQLNyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516969064683?e=2147483647&v=beta&t=lgLHnVIPiaAvnBqhZnVLkonwMTYDor_TAxa0eJ-y6lk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rebecca',
        lastName: 'Lopez',
        emails: ['rlopez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGLZDaGzKjbDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617999006237?e=2147483647&v=beta&t=aiYc7engq990osrjG9neVLFMg5eL2LofOlIAvNKkvIA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jared',
        lastName: 'Magers',
        emails: ['jmagers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHkQYikw6wMOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718273786204?e=2147483647&v=beta&t=ueRx4UyFHjhOtyYxUCTQDYRl7mrxosJvRMfmrIfOrBk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Raella',
        lastName: 'Rayside',
        emails: ['rrayside@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEykVwZkc840w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638238811049?e=2147483647&v=beta&t=53MsK_EU417FAzLaj3xgXG2-xhOM3jRT-15dYZDZapo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ballardproducts: {
    name: 'Ballard Products',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Peter J. Boylan',
        lastName: 'III',
        emails: ['piii@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeffery',
        lastName: 'Giavotella',
        emails: ['jgiavotella@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sarah',
        lastName: 'Slater',
        emails: ['sslater@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFndaRmW06-jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1528551629652?e=2147483647&v=beta&t=_8PW8FwQy8uowCkmFIDlNDr1YAv6MQyFJ9wLgWblvsA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Reid',
        lastName: 'Nolte',
        emails: ['rnolte@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHnVmRyKTL2eQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696346646758?e=2147483647&v=beta&t=UiISO7ypAGIf5rAmbZLbQgQ0rewxeO8dpaMAEraQyh0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Mesa',
        emails: ['dmesa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dripjobs: {
    name: 'Dripjobs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tanner',
        lastName: 'Mullen',
        emails: ['tmullen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFJvurFNEGyuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629929273431?e=2147483647&v=beta&t=7F4ccqqbEGKYcx8PIZLKNVjp_lzsxsarz9SmTPmKfTM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rick',
        lastName: 'Schott',
        emails: ['rschott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEaC0h3J3w_Ng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662143202266?e=2147483647&v=beta&t=U705rRsnNOfVT-XlaFz7XHfU3Cb_MY-S9vgLxwJyjGo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Danielle',
        lastName: 'Walls',
        emails: ['dwalls@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGCodA8s__yzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677162594401?e=2147483647&v=beta&t=QQeRlxuRVctvx8m9zBJkezh6cFII161srq4qFMq9oXI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeremy',
        lastName: 'Harrison',
        emails: ['jharrison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGsgtFubtVEeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708401443569?e=2147483647&v=beta&t=3Zlm4KWzfZSy9Q9qFiU_QR6eYbTpivQ_XE_SNxBFZ7g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Mermelstein',
        emails: ['mmermelstein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFMkk2Y1anqLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592000762915?e=2147483647&v=beta&t=jkc6A7jTQvZf3JZnmPmWVJHT6ItJC5dGOA3pD-2DkiE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  learnlux: {
    name: 'LearnLux',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rebecca',
        lastName: 'Liebman',
        emails: ['rliebman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEefPOIx3yihA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597690938062?e=2147483647&v=beta&t=bweidJxHL9sGGktkdAjkS3kCWeT_stR8k09S1tuhxLg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gray',
        lastName: 'Levine',
        emails: ['glevine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE8zvja4r9aKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583939497866?e=2147483647&v=beta&t=4zzHvHvIHY1K1bzKK83-lwbRkWZQdPJWg2xJ0Q-cza0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Liebman',
        emails: ['mliebman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG6E7abkwvlsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597687130413?e=2147483647&v=beta&t=8ua0DGfZHpKKa8U3yLMc5JpbKqlxOIEXllSiq0mSOu4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jonathan',
        lastName: 'Herman',
        emails: ['jherman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFB8BiEgxXAog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541269898252?e=2147483647&v=beta&t=tmJE8LgCMz-Z9jfLGUQSAWcuOxGHmqh8YD4sKT81pgs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jane',
        lastName: 'Lund',
        emails: ['jlund@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEBX5aXs8Uc6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584721085690?e=2147483647&v=beta&t=pMg7_2zVruzxGCm-6bILdj6LvR4E6Cn270Jk2JVps34',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rankwatch: {
    name: 'RankWatch Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vaibhav',
        lastName: 'Kakkar',
        emails: ['vkakkar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGAmIqnJRHxaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652652038513?e=2147483647&v=beta&t=9T2yl9xbFc5l6wdie2RfCFNay8umD8tgZq4WhCMp264',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pratikhya',
        lastName: 'Moharana',
        emails: ['pmoharana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEgazdfKWcfWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627478113579?e=2147483647&v=beta&t=igz2RZZ2SOiN_Uk7CjLCgwa4yGjWTsXF060OrUMqz3c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'shivangi',
        lastName: 'vaid',
        emails: ['svaid@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sahil',
        lastName: 'Kakkar',
        emails: ['skakkar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEURotX1y8IxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624471880334?e=2147483647&v=beta&t=B28-jVMao36My1OFVdlDvR7DCII0crinT1NlMeub6O0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sarthak',
        lastName: 'Sawhney',
        emails: ['ssawhney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHn69eDE_UAIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516547866208?e=2147483647&v=beta&t=6lGa4HI7Zd0748-8aAp3foZ9j56-b6-CpXULHgBzBPc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  niloom: {
    name: 'Niloom.ai formerly ABXR Engine',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amir',
        lastName: 'Baradaran',
        emails: ['abaradaran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHZsqGVOSCSsg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516277476888?e=2147483647&v=beta&t=u95aPQ5NBf-CFgbh2KaZHPaX6_QFCxnjSPDrCHCbEP4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Levine',
        emails: ['mlevine@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David K. Park,',
        lastName: 'PhD',
        emails: ['dphd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rick',
        lastName: 'Kleczkowski',
        emails: ['rkleczkowski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGp8B_Y8QDcew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707074344877?e=2147483647&v=beta&t=dS1Wa4E_250FCwb1du7hSC_U05zwsVJ-fRxzDRRX0Tc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tapas',
        lastName: 'Joshi',
        emails: ['tjoshi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFQkc494mSBoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705785165309?e=2147483647&v=beta&t=sC5qeoVE8g-GUrIlcdLzb-aFyMDXs35wVQT0_PNusIA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tradetrax: {
    name: 'TradeTrax, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Henry',
        lastName: 'Dziuba',
        emails: ['hdziuba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHcCdnlujjCBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700257943713?e=2147483647&v=beta&t=lICkdZvqDan262G4UF1xadhksWlRNLH9f1C_bnP4onE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Evie',
        lastName: 'Bickes',
        emails: ['ebickes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEiDPfh4JRe-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689096827416?e=2147483647&v=beta&t=urhfQnmgAo3tz7X5hQ4evX6JH11MABHEL0AapEXCpsg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rafael',
        lastName: 'Colmenares',
        emails: ['rcolmenares@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHoorvclPOc0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705556965634?e=2147483647&v=beta&t=rx8CbU9gfPwuQ2DeV845DHfnTmRCWD1r-wf_Bu4apj0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jordan',
        lastName: 'Blanchard',
        emails: ['jblanchard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHbAOZHHTvS8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694810776192?e=2147483647&v=beta&t=Wb6SeoFSAnoqAlBmTzWLtKT3WbY2-aG4xvTYG0Tjkzw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jack',
        lastName: 'Smithson',
        emails: ['jsmithson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xalta: {
    name: 'Xalta',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Krutika',
        lastName: 'Soor',
        emails: ['ksoor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE1Z6_43C5LNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669777709165?e=2147483647&v=beta&t=xR0FU7-WDhErB5oX-q7f07CdqmnJpjMghINetLqVLE4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eshwar',
        lastName: 'K.',
        emails: ['ek.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Omkar',
        lastName: 'Mahajan',
        emails: ['omahajan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGthdNI-3aV_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648989451089?e=2147483647&v=beta&t=UrU3hDgAJ52YaX5HNdeWu-CaSCpfmtLyaj9GhxWqGss',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sonu',
        lastName: 'Vishwakarma',
        emails: ['svishwakarma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFLbFOu2r0ShQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719543836314?e=2147483647&v=beta&t=Ypk-md347E656XXczimi57DS_rRoPaVK18MdYKV17_4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vibhu Priyadarshi | Head of Technology &',
        lastName: 'Delivery',
        emails: ['vdelivery@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGDLh0YTM65tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621241759632?e=2147483647&v=beta&t=-7dTRvOT_0i4bFJveYZkq0IZ0UG4kM4A35SSrEB5pz8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  shyftsolutions: {
    name: 'Shyft Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cathy',
        lastName: 'Ludwig',
        emails: ['cludwig@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGOND2_W1Nq4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541077190235?e=2147483647&v=beta&t=FUzFl8eiP5HHUkWiaoGtST9IH41OQsRaxWR2sLbpV1Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kelly',
        lastName: 'Hendricks',
        emails: ['khendricks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGtahzyRGiH1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567889776229?e=2147483647&v=beta&t=Be2C6a0z2LlNjyqo9kT0fAJv0VZyfTDnfdj-61lUiUM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jesse',
        lastName: 'Schulz',
        emails: ['jschulz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGbVKOzLcl30A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670990880321?e=2147483647&v=beta&t=v3jWDtu-dbLa4SzCoE9LQaWWH8SHErng2ZWQt9i-x8Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tristan',
        lastName: 'Jones',
        emails: ['tjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFJJy_CYhhwUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707940106689?e=2147483647&v=beta&t=rjCqS5AV_Rf0j7IjhhaFN3zLpPxSKZxCY_Gnnf5aEHw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Uche Daniel',
        lastName: 'Okobi',
        emails: ['uokobi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trilogymktg: {
    name: 'Trilogy - An Experiential Marketing Agency',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeannie',
        lastName: 'Guzman',
        emails: ['jguzman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sean',
        lastName: 'Segal',
        emails: ['ssegal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jennifer Scott,',
        lastName: 'Ph.D.',
        emails: ['jph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEvpC2aTPiQ4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666978159051?e=2147483647&v=beta&t=fni_PfFBRAu7AUtjvA51zy7kQPeYuPkHUg8Bccm7V_8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sienna',
        lastName: 'Daniel',
        emails: ['sdaniel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHc1yc4TLYV5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517545814129?e=2147483647&v=beta&t=TfPpCuIW38_zsj3d6HL3JybYbAgxJU9JKb6E1NWr-p0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tony',
        lastName: 'Schell',
        emails: ['tschell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHdHmOTOysbIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603541777902?e=2147483647&v=beta&t=BsPP__9fqf82ssfg6pHqFs32bRQs7tmPg03IGTX-H5M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  digitaledge: {
    name: 'Digital Edge Marketing Agency',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dylan Kohlstädt – Founder and CEO – Shift ONE Advertising',
        lastName: '...',
        emails: ['d...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHQvqCcIrhsGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710254934033?e=2147483647&v=beta&t=HF2mwF0nkLpfeC6OnCdpBuzKLEjD2xsCWxh3wKtfSCU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mya',
        lastName: 'Surrency',
        emails: ['msurrency@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE654sSvCqnPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658699680460?e=2147483647&v=beta&t=LZaTxGu5bR17vXBzNVLPhMZUJ2WQuNdyppQL1nmluT0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shirley',
        lastName: 'Smith',
        emails: ['ssmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFnK8lHrlp3ZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666793868385?e=2147483647&v=beta&t=etMykMwEUjK08XBv_D9t3HWCZKJKlum7RQMzmmgsOaY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mae',
        lastName: 'Bodine',
        emails: ['mbodine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFk2DN-1gueGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654708635761?e=2147483647&v=beta&t=WobJfyK9EsEz_cu9ARMsX3pYexN4_awI-jWKtquXm6o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gathan D. Borden,',
        lastName: 'MBA',
        emails: ['gmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGTuUrcC1fy-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672849874994?e=2147483647&v=beta&t=KzfzpoEaP_YMWLhkynR5Qpc7vst9CcZyQhAPayfNV4E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  applausehq: {
    name: 'Applause',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joel',
        lastName: 'Ragar',
        emails: ['jragar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andy',
        lastName: 'Williamson',
        emails: ['awilliamson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFU3_jvtNfM2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657826341661?e=2147483647&v=beta&t=hqrytFfQVrVCgrROeQqInJQvMVJrq3nCcezxXFt5OUE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'A.',
        emails: ['ca.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHFhiIHwlRI6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516650680568?e=2147483647&v=beta&t=SMfAOh7CyP9srnZD3azNa5GH84H5Hh0WW4vYDuZRkDk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'J. Taylor',
        lastName: 'Olson',
        emails: ['jolson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFg8VjI-R8L1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636994726075?e=2147483647&v=beta&t=rRJ_oJUMHfE64KHJIgkJP25sdBxjMiNnN4yONe_Y7Es',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kyle',
        lastName: 'Pentecost',
        emails: ['kpentecost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEW1vwBEFpyzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657170290771?e=2147483647&v=beta&t=xQ1Y2qrqnSbNc1avQSs3VJvAtwF3mGtoeSlCzkXcihY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  taiyo: {
    name: 'Taiy.AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matjaž Krč, M.',
        lastName: 'Sc.',
        emails: ['msc.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matjaž Hegedič –',
        lastName: 'StreamUnlimited',
        emails: ['mstreamunlimited@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEAZNhCuCZhOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516614719835?e=2147483647&v=beta&t=fmNZHKlGRtF2awqW1_58pfMUC2pvHL60ePxfGgmzK-Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Boštjan',
        lastName: 'Prijanovič',
        emails: ['bprijanovič@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF68m_uYhbuUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654595659465?e=2147483647&v=beta&t=aBFzyokaj8qh8Gs-b-UByGDlsO5YSamRvigwxHz0JSA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amin',
        lastName: 'Merchant',
        emails: ['amerchant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGVsbJgLUMqTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649813769023?e=2147483647&v=beta&t=ER0Hg_gjCngi-sjoCG-u5jAorfm53iTptda6RivvgwU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matjaž',
        lastName: 'Petrič',
        emails: ['mpetrič@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQELmFIFkvR4Lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516312472966?e=2147483647&v=beta&t=NrB0c7GM8jR1yXmfqF8O3_W6s5Q-Uha9V8a40fMFtLY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  press4kids: {
    name: 'News-O-Matic',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Troy',
        lastName: 'Lynch',
        emails: ['tlynch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEnSJ0K6sHW-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723220342674?e=2147483647&v=beta&t=23wJWmmUjULuDV_ZOpaCf4wQebQebZ-msO49cfTRUeQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mireya',
        lastName: 'Villarreal',
        emails: ['mvillarreal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFMd7dBGn51Aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708479562894?e=2147483647&v=beta&t=luXt8j-Yztt4tu2ZeZlHuw8CQwgX0x8ISxNxd2DSTrM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Katie',
        lastName: 'Greifeld',
        emails: ['kgreifeld@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGJlCQ-XwZdvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635517118656?e=2147483647&v=beta&t=nGTjmho2Mg3uUKU9qU7n5w5R7rhGetpLp7Pa0Xz4xHI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lotte',
        lastName: 'Jones',
        emails: ['ljones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQECYyAE-KqTWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729103452838?e=2147483647&v=beta&t=xjiUBns7xxj5ZGUUNI0mEJEvwCG-fsPinB-H0CoeDUA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Arundhati',
        lastName: 'Parmar',
        emails: ['aparmar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFzG19H4MwUXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708452641944?e=2147483647&v=beta&t=EiE7LSRopj_ssBAWMUBSNEslroJnDgBHWJqPeNEhEpc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  deeproots: {
    name: 'deeproots partners',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matthew',
        lastName: 'Shterenberg',
        emails: ['mshterenberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Danielle',
        lastName: 'Meyers',
        emails: ['dmeyers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHBsFDDOtCv3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571615252688?e=2147483647&v=beta&t=MkXbKc9vQx9c_WeHO83YLLB3WAmEjb3iWkGtgMIVzuY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'JP Donahue',
        lastName: 'Esq.',
        emails: ['jesq.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHCLJIfhNgNfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713541569743?e=2147483647&v=beta&t=8T6338QK69Xgq31cbxRAJVZCLOI5O1NdcbPz6FQBv18',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeni',
        lastName: 'Valtinson',
        emails: ['jvaltinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEJzT7tHSEO8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713628303459?e=2147483647&v=beta&t=Nm9-_zk3EY-ulsvFLWDz8ZX8zv-688NyJd9YDhd6LIc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian Paknoosh,',
        lastName: 'MBA',
        emails: ['bmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF6kbFhP071zw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593059214309?e=2147483647&v=beta&t=z2zCRAfG55A4xlktt8V02jIHDD_lqfqx-0TXMDCWyOY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  route2digital: {
    name: 'The Players Impact',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tracy',
        lastName: 'Deforge',
        emails: ['tdeforge@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joni Lockridge',
        lastName: 'Yates',
        emails: ['jyates@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH6KCNH8Ne9Vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713807351781?e=2147483647&v=beta&t=gEsqshIb7nBDCuny20D5FuPkfHz4dczfJCFRgvN1P0E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'George',
        lastName: 'Ricciardelli',
        emails: ['gricciardelli@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Corey',
        lastName: 'Gross',
        emails: ['cgross@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEv8LBhVEgc1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559052802373?e=2147483647&v=beta&t=JYZ1NWYQwfe--2HULGUMz9zTnQN2tHjbhETOXxwhZxU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jenny',
        lastName: 'McGhee',
        emails: ['jmcghee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFIWvRuVNU6GA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579100243184?e=2147483647&v=beta&t=8mZEaQPpxa0z_PGMHWxG7cFI7LMxSWAJ8Xd2yk1u5aE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  truideation: {
    name: 'Kensaltown East NYC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Yu',
        lastName: '',
        emails: ['y@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGzkby1j46ChA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693248423195?e=2147483647&v=beta&t=9w6zwhwoNnfntyHPft3cmFLLChLIjUGhyKMYqbsnwgQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Camus',
        lastName: 'Celli',
        emails: ['ccelli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFYTNj9w2wgeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589758481248?e=2147483647&v=beta&t=WuxZBYmQSwmalfnoL3DwgKwa5URcZoOCEKRKXud4sKs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'McFadden',
        emails: ['jmcfadden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF6wOXyyyHQ0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516287086099?e=2147483647&v=beta&t=fhuXPBDLnKZArT9XH50ehibDYh2KrSULwOq8IdgCivo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jade',
        lastName: 'Driver',
        emails: ['jdriver@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFXkjdXvybgDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517742508738?e=2147483647&v=beta&t=M-UyTT0xV-m5xyuFbbsdSMudS4fvmTHpSHFyKZzAGG0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tracey',
        lastName: 'Zucatti',
        emails: ['tzucatti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFY3jhLOKzp8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689356496622?e=2147483647&v=beta&t=kQGgYFlK7UJcMTVTQ4yRI0QbxAfIh_3kwzhQ9qRHKOs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  caspiatechnologies: {
    name: 'Caspia Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Beau',
        lastName: 'Bakken',
        emails: ['bbakken@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGBmre8eXnOyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673221357467?e=2147483647&v=beta&t=wtVQXlmQeoy_MzvkeQdNzkv1cHClggbqhrtl7LKSTL4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Trent',
        lastName: 'Wyatt',
        emails: ['twyatt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG_5k8DNqCV4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571689272879?e=2147483647&v=beta&t=C62JbcAibVDUAQeKHGkxBMAoEMV_v5d5opa0fPc3Mgo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Steve',
        lastName: 'Galette',
        emails: ['sgalette@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE_Vnqrj_S4RA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517612436326?e=2147483647&v=beta&t=HF8Hjk8TYC0A9-1Lq7gIPg7KwXrJFsXZFqOlG1zOcm4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paul',
        lastName: 'Ramirez',
        emails: ['pramirez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF9pNEAzuGhlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517024148442?e=2147483647&v=beta&t=ySPG5rOjhZEP40_mRTHw1ij40mhzjdGyo2tu6bCyC0k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ceenu',
        lastName: 'Shaji',
        emails: ['cshaji@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wacoservices: {
    name: 'Waco Digital Hub',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Melissa',
        lastName: 'Hefelfinger',
        emails: ['mhefelfinger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFc2W0iuBhnlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516955738124?e=2147483647&v=beta&t=C99mL_rdp3g3JZ_Ss-uDktjKyDGmDBSEuptO9Md_IpI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cathy',
        lastName: 'Witt',
        emails: ['cwitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGPjgYs9ZV_Hw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715015973105?e=2147483647&v=beta&t=B2aj1dXE3_Mq5DH5nKVfX2YbQOxGgMSOGjEbe4730Es',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Georgia',
        lastName: 'Coffey',
        emails: ['gcoffey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFj6lTunVEkww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637276209203?e=2147483647&v=beta&t=sY7JWnTphPqNtdloZHUIzLa-YYzJbod1RdbgiKYLkY4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Julian David',
        lastName: 'Vargas',
        emails: ['jvargas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHvqlqb7EOnqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673410035257?e=2147483647&v=beta&t=11CD8ApCrCJkTpIDF-9I9TZ8aU8J62QHdxubeCxuODk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Maria Paula',
        lastName: 'Olano',
        emails: ['molano@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vanetechnologies: {
    name: 'Vane Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Carlos V',
        lastName: 'Lembach',
        emails: ['clembach@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rogfel Thompson',
        lastName: 'Martínez',
        emails: ['rmartínez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE4WWqkCshFfw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517274716877?e=2147483647&v=beta&t=4O-88kDzRR2VwTf8Gy4kb8rzRYAd4gzE3isOEyaovGc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Simon',
        lastName: 'Vane',
        emails: ['svane@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHOPID7EnEInQ/profile-displayphoto-shrink_200_200-alternative/profile-displayphoto-shrink_200_200-alternative/0/1528737335100?e=2147483647&v=beta&t=jeGskyMWkOQQBQFhfa9VI0oaT3CmbVELN_7OP0oTAAQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Katie',
        lastName: 'Vane',
        emails: ['kvane@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG1WggfncALrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648058133086?e=2147483647&v=beta&t=gwM_hyjKE1zhkKxzn0EvYoht3tcTVeNPoBDOFrpfVb4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jalal',
        lastName: 'Umer',
        emails: ['jumer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH3ERsfAdmieA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683542079779?e=2147483647&v=beta&t=xE0ivEzRgjag1ZZQzeMbPqB41-D5bhCk9qn-59beJCo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fashol: {
    name: 'Fashol',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Numair',
        lastName: 'Hussain',
        emails: ['nhussain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEpKJBRZ2l00A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559167002818?e=2147483647&v=beta&t=uRestiH6lit7TLqDfb7g1Bv_BLKCYMvqbUhVmBPnDi4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dickson',
        lastName: 'Patrick',
        emails: ['dpatrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHwRwSM5OwVgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596169820106?e=2147483647&v=beta&t=2r8YJer2bFBP6Bi_WSV2UtubDCV3iF9yDnJ8csvDJRk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sakib',
        lastName: 'Hossain',
        emails: ['shossain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF8aLDZkcNtfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726454155065?e=2147483647&v=beta&t=wcdLLYxNCWO1yVYxDrDnHog0SlkP3iMkpvQFv4CehZs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bright',
        lastName: 'Gideon',
        emails: ['bgideon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHMEXvUWd09JA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685475310764?e=2147483647&v=beta&t=uxboWxgsHXJkRATBxGCxxBMaWTRl-7XwzE5oCOzaoA4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jonathan',
        lastName: 'Fashole',
        emails: ['jfashole@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEiKyCLQk7c_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517582707993?e=2147483647&v=beta&t=vEVgukl-pcZNTzLlK9nP2_ejLy-Q6SWjHfajk4Y6pxU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  goakala: {
    name: 'AKALA, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Barbara',
        lastName: 'Kalmus',
        emails: ['bkalmus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHOKLNjI8qNNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607561411224?e=2147483647&v=beta&t=Ga5zAD4xaJ6_3lZ9go50tHUfG653SgwABTYf54TIiBQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Glenn',
        lastName: 'Frese',
        emails: ['gfrese@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrea',
        lastName: 'S.',
        emails: ['as.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGrDM_l47wGNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661806004594?e=2147483647&v=beta&t=VgMB7nsEeFX3h1yR9FrdQEqLL0QUpmcJ2FFx9PrXW88',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Olivia',
        lastName: 'Stoneman',
        emails: ['ostoneman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dhanaraj',
        lastName: 'Chandrakant',
        emails: ['dchandrakant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFm1alR1jNcAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687421156272?e=2147483647&v=beta&t=GX5PG1BaybWGA16MwDhpMEfbow3BJ2GcU6sPcYWWe6o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  artpopstreetgallery: {
    name: 'ArtPop Street Gallery',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wendy',
        lastName: 'Hickey',
        emails: ['whickey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emily',
        lastName: 'Andress',
        emails: ['eandress@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHXS5bBotFaRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516560710534?e=2147483647&v=beta&t=gPeXjgexxfUr9Cf5IoWEOE8BkbT5FQu2C0w8W-JKFLQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brooke Sloan',
        lastName: 'Gibbons',
        emails: ['bgibbons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGhd85PwKlMNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718927940368?e=2147483647&v=beta&t=i8O4k8xzZvaS7qC7KRfBfVnwQCTm4FmBaefvrsOthlc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kristen',
        lastName: 'Watts',
        emails: ['kwatts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE6waotVkXO6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516493346687?e=2147483647&v=beta&t=-m6KWWYczFfQ7ILAjoMBIcWk32dKI7CP6QRL_vSE9Fw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Maria',
        lastName: 'Campagna',
        emails: ['mcampagna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHMJS-r9Vg4lw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648765901304?e=2147483647&v=beta&t=ZLZ_OTG7kjFj_k500wob6kS39HTjUb9nN1AOyhLrAwA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  herondata: {
    name: 'Heron Data',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jamie',
        lastName: 'Parker',
        emails: ['jparker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Johannes',
        lastName: 'Jaeckle',
        emails: ['jjaeckle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEdcoQzO6MqUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666705862569?e=2147483647&v=beta&t=Uo3XKtMJSHbrdnfsQqfq76VGhwc_Ol-IkM-qgSf4AaI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dominic',
        lastName: 'Kwok',
        emails: ['dkwok@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGLyRqbEFyAzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708449877464?e=2147483647&v=beta&t=FeM1-LseOaCoyi9y8fsOT2v6GxvdyoFwMxBYF50p-Q8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Genevieve',
        lastName: 'Simpson',
        emails: ['gsimpson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE51GDmhO1vsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581594095322?e=2147483647&v=beta&t=OQFtg1peSH9NOLInXXgm29NGHttml5tZgZcou_tJge0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jakob',
        lastName: 'Muratov',
        emails: ['jmuratov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF5DCpwfwsuLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706030469065?e=2147483647&v=beta&t=bHMYiNR2SnWDRtjWjE1xETPGE68DQ-YxsMWPBLqFPXc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ideaslab: {
    name: 'Ideas Lab',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John Spencer',
        lastName: 'Williams',
        emails: ['jwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEDe4xpx9ESHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553282551150?e=2147483647&v=beta&t=SrkQUU0S2mObPbslwTpN2PTgiNt0I_UXlej8toiJTnk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Amalie',
        lastName: 'Kae',
        emails: ['akae@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHzPGNTR6UT_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675599315424?e=2147483647&v=beta&t=N7zSqX0GieqDAI4NmFCc8eb9IjkpLVNx2XlhiAgDkPQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Haddad',
        lastName: 'Miladi',
        emails: ['hmiladi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGJ4DsOq5iYTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699576046736?e=2147483647&v=beta&t=Eh1uW71B4-LsYc9S8Bq8Ke2PTsfpA1PcmiFrkEnlaCw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Francisco',
        lastName: 'Delgadillo',
        emails: ['fdelgadillo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lana Schrattenecker – Social',
        lastName: 'Media',
        emails: ['lmedia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHkEzSjhd2jHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706367056167?e=2147483647&v=beta&t=AHfy4ON1VQHKMN3yDco42JmZS9RNEGm-WcQK0TXxW40',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  anecdoteai: {
    name: 'Anecdote',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Abed',
        lastName: 'Kasaji',
        emails: ['akasaji@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGN5bURcOg-gg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595680513940?e=2147483647&v=beta&t=cnVKuYkTxxPWuhbeuGnsXxqVhRSF6cZ9qQZHlFxuizs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pavel',
        lastName: 'Kochetkov',
        emails: ['pkochetkov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFwnWujmVKpxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553150782191?e=2147483647&v=beta&t=M-uj4b28H9s6Qxv5RVFBYgXflFkiLW3g1ptKtN4lMxE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yair',
        lastName: 'Kuznitsov',
        emails: ['ykuznitsov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFKLkeCaBokFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657200107930?e=2147483647&v=beta&t=6womsKpmTj8NeGGp7RQoH00fmcaMw16EyQWHMx3eVA0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jon',
        lastName: 'Feldman',
        emails: ['jfeldman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEP37QRrs0uhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662046008668?e=2147483647&v=beta&t=cjF-dxfq2I0GbL3HTyiWGGGUo98OljwgKihN_xk_xI0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shrey',
        lastName: 'Gupta',
        emails: ['sgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGInp1DazTmfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614764506563?e=2147483647&v=beta&t=fa0uzahyP1gRo7oRne260DIwAq8MJMqQbkeJv0XTF60',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  baobabConsulting: {
    name: 'Baobab Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Helen',
        lastName: 'Hershey',
        emails: ['hhershey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF7q3fN9O7CbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516821978642?e=2147483647&v=beta&t=3od5kn7Dt2OVWcetmZ5PzSxcyeqiRQtQQGhr442K-PA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mia Rose',
        lastName: 'Pskowski',
        emails: ['mpskowski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGOKJ5IyfO9RQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568481758104?e=2147483647&v=beta&t=cJd0d3C_6q8tgKnakxnuRtJCzkQtWQJ6WNBl4nqapp8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sarah Hartman',
        lastName: '(Boutej)',
        emails: ['s(boutej)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEvW9RAxrrqcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725015148490?e=2147483647&v=beta&t=7HzkD2kR_Ffk0NOiXRux1R0MRhLK_R_Qrm7qZl1ym54',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'mc_baye',
        lastName: 'oficial',
        emails: ['moficial@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFKQAGih6IYNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571670252804?e=2147483647&v=beta&t=4Oux_F6FnU9zGf8S-e1GqUxVY2_7yg0RyENKBuwDKn0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Wes',
        lastName: 'Holden',
        emails: ['wholden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG1aWRk0mx_Qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616193035141?e=2147483647&v=beta&t=5k6qT6i16ls-sZmN7qCYx_jC46GS1Y0e1mxSW5vBz94',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  relevance: {
    name: 'Relevance AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Vassilev',
        emails: ['dvassilev@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Scott',
        lastName: 'Henderson',
        emails: ['shenderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHOvVyoDBUbmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712208607849?e=2147483647&v=beta&t=HIeT-D--86mEn9KHc_QQ0vPpgwX3dokHdftskbZDA64',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rosh',
        lastName: 'Singh',
        emails: ['rsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF00hgePtieUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604638435525?e=2147483647&v=beta&t=fs44EepgvpZxuHtzP7uok4N_0GeU8b4dnqEch248uPg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jacky',
        lastName: 'Koh',
        emails: ['jkoh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Troy',
        lastName: 'Malone',
        emails: ['tmalone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF2Boxl3SKaMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673892702114?e=2147483647&v=beta&t=4BMkUq7zYYm5-w30qvgXX3VdPBs21dDi0ysihJU1kV4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '1776seniorcare': {
    name: '1776 Senior Care',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nat',
        lastName: 'Truitt',
        emails: ['ntruitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE46Nm3EFuG5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729177814534?e=2147483647&v=beta&t=3OMZJoz5hmJHvOOWodZeG9hw1IK_HWZjX-u5wU8X9I8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carissa',
        lastName: 'Davis',
        emails: ['cdavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGu3ntA6yoeNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1530045393115?e=2147483647&v=beta&t=oKpI0ihZ_EOSNSORTKbyiaQuWnRJxEbCIvamlXhKbrU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Latonya',
        lastName: 'Johnson',
        emails: ['ljohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cecilia',
        lastName: 'Escobar',
        emails: ['cescobar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHz440agbFmFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691072805472?e=2147483647&v=beta&t=hAwAB8j0FxJGvc15GWNRDRUO46L9PGSHwSL1At1j9ss',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Odalis',
        lastName: 'Amezcua',
        emails: ['oamezcua@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHRaFrxRGuGSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612129914833?e=2147483647&v=beta&t=SKoOPqRJLf8Slea_Kd7pStPbzbYm3uxjLaGO9PSeIQ8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fiftyhills: {
    name: 'Fifty Hills Real Estate',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jill',
        lastName: 'Machi',
        emails: ['jmachi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHvqGC2KoPFMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517735120021?e=2147483647&v=beta&t=LK0afe2J8__1vixaziSeqF-zNzBfbCsq_ULUlhnpR8g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lisa',
        lastName: 'Spearman',
        emails: ['lspearman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHQpgI1tBlvmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516238651246?e=2147483647&v=beta&t=rs2Pt2bDc25BMk0WVZ6hJaZpCN1l8AzZGh50LaTFc9g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sarah',
        lastName: 'Pena',
        emails: ['spena@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG3oykQMGY23w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719037322004?e=2147483647&v=beta&t=diVfIV7FQXmjjdV1P-1LvDX3HflPtqN9G7nNpML0EIk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sara',
        lastName: '',
        emails: ['s@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGYCdSHZgUR2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517596698068?e=2147483647&v=beta&t=ytXzZ_vGxXn2zT-uUCYxFubtBNCrjVoCWaSE86Lsbdo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bridget',
        lastName: 'Madsen',
        emails: ['bmadsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFd-3pHltD-Hw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655518162387?e=2147483647&v=beta&t=jcTmbbQq9OiNKNVq26JxcrMmEe35T3Jr_1W-kYdgvMM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trybenable: {
    name: 'Benable',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lukas',
        lastName: 'Širšinaitis',
        emails: ['lširšinaitis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHoaCf6wB_NUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711369395909?e=2147483647&v=beta&t=00kslUerSYFfoPgn2QzApoiER0O5YPk1dXjQhrYoapo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Delkou',
        emails: ['jdelkou@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEyphFuh2C4hA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516990977204?e=2147483647&v=beta&t=1rskNyIG8SJflcltSg0aJw-kxQV_ByyAzgRhRs9lFnc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Hannah',
        lastName: 'Stevens',
        emails: ['hstevens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGWOBSwrNbGLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1562169872367?e=2147483647&v=beta&t=XkysCvZ4mZ2ZVl1585kZ_o00W1wM3eirxpa7gaoCgio',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Madeleine Grace Flack – Teamassistenz Technischer Service',
        lastName: '...',
        emails: ['m...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGURU50-Z5_8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726174144543?e=2147483647&v=beta&t=HrnOTlQZ_65Ic5XDhhwRzYUaOnzsC7Q-vfr1dkdYUKI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lazic',
        lastName: 'Vesna',
        emails: ['lvesna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHGQrQ1CmjVyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585179511503?e=2147483647&v=beta&t=QpSmQpmYhzLH4DALPHkkt9-VWByg52x4IfSnbSihPPs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  blacksheep: {
    name: 'BLACKSHEEP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeffrey Pritchett, PE,',
        lastName: 'PEng',
        emails: ['jpeng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHuV8GegqxdBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585413194053?e=2147483647&v=beta&t=zeudM95s6RQLhjwSETwVG5IThXunbm04uBefY26wGRU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Grills',
        emails: ['jgrills@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFiOvhAiRQ7IQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517039203271?e=2147483647&v=beta&t=VyS5IjHz4pkH32D20XQrbpozb7xQcmi29eky2satSY4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jon',
        lastName: 'Treadwell',
        emails: ['jtreadwell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGb2HXarvjc4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694275461035?e=2147483647&v=beta&t=FPz0xholTyMECf9EBpL-4tzExWre_uYqA7wVi7_M5K4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Scott James',
        lastName: 'Holden',
        emails: ['sholden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE-P0eR8DcDSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522266093624?e=2147483647&v=beta&t=nOy8Bdsa5LIB6Rs0KqU7hoHQXrRYmtq-nSh1W5TB6Ss',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andy',
        lastName: 'Moore',
        emails: ['amoore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFXCQjoiU-47w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600793680477?e=2147483647&v=beta&t=dT9FfggLRVQ6j5OoltmtNxiSnnDPnFyWEC7WnwwmPH0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  camcollective: {
    name: 'The CAM Collective',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jalen',
        lastName: 'Ross',
        emails: ['jross@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH66h2oOd8uPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586319508426?e=2147483647&v=beta&t=3odJcXBIjs-LG5L3lG5FAPj9blBAtPlh4aPYjB_nXJs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'BJ',
        lastName: 'Marshall',
        emails: ['bmarshall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE2_bBkmHtDiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714426009125?e=2147483647&v=beta&t=WosuSczPDJCWwuCUxJ2IPmqKI0uglaCrE921TS2XzAQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Orlando',
        lastName: 'Remak',
        emails: ['oremak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGH2QIvG5YEzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635948689945?e=2147483647&v=beta&t=3mr5bCdgX5n4O9_6J58s3jPn7qveA237N4qFFrMrIB4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Luke',
        lastName: 'Clayden',
        emails: ['lclayden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEtxt97kbfKAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561720175758?e=2147483647&v=beta&t=tM3-EC674h8rXkVg8-TTkTevpD5KUCf64Yqd20etBBo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Frank',
        lastName: 'Moneymaker',
        emails: ['fmoneymaker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFonHOUY8TM_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671923889111?e=2147483647&v=beta&t=HTIGVhveR_AaApGSjDTL5ZEjBqpX7YYhPbw0B8i1QZU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  shinebox: {
    name: 'Shinebox',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jason A.',
        lastName: 'Cook',
        emails: ['jcook@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFR4GRV2u98FQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661194434831?e=2147483647&v=beta&t=lTbTGi2rcKUvGVsjqC9iZZZvreEVsJgIqvDDH6d2EGE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vincent',
        lastName: 'Night',
        emails: ['vnight@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF_ad7S3jLuaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682002468291?e=2147483647&v=beta&t=QMwfrlQFLN93qETrVmzk4aDV50JAitmnw1ZQUV6UP1Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brett',
        lastName: 'Schnacky',
        emails: ['bschnacky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGJPSiHjWdphA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556156194464?e=2147483647&v=beta&t=mvK4bBTCu3hmSTG9wHIRX4FDF-olzW62PYByHA0vlAU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Keelan',
        lastName: 'Campbell',
        emails: ['kcampbell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF-xX5GVjBAjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570043040100?e=2147483647&v=beta&t=u_BQLXZtNT2fhdgvg9V4bVc5suKXhrAV1NID9Cm_t8s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Freelance (Book)',
        lastName: 'Designer',
        emails: ['fdesigner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFsUGa5-IkhQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516560042768?e=2147483647&v=beta&t=rxNCC6aALgRr-mCt6lNfwLZ3VrEDxw0Yq-4CiDOe84w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  herhighness: {
    name: 'Her Highness',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Laura',
        lastName: 'Eisman',
        emails: ['leisman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHvS7r-QyZZRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517735038406?e=2147483647&v=beta&t=7Sd4ErG81ZIEV6iElB9V63GcngW0eT17kQ2-HAKDfuc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maria Lambert',
        lastName: 'Bridge',
        emails: ['mbridge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGSAJuh9pFCTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710340386632?e=2147483647&v=beta&t=Ujlt7-innYuMqQyNtELHm1Sm9tz22ZMbR8CzUcX-YSo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Katalina',
        lastName: 'Mayorga',
        emails: ['kmayorga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQETZuMohG-nAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670523537193?e=2147483647&v=beta&t=SHMwpH77RKRq_qw1DS0JKQ58YLxSFXGQPIzysXbO9_0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Charlotte',
        lastName: 'Tweedley',
        emails: ['ctweedley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHRq3IQ3E6Qug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664140779019?e=2147483647&v=beta&t=H5XHGxwhC7SMWMvdVN3_uqMAdTpt_N6c_B8kWJRnZSU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aine',
        lastName: 'Stapleton',
        emails: ['astapleton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  wallstmastermind: {
    name: 'Wall Street Mastermind',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sam',
        lastName: 'Shiah',
        emails: ['sshiah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHHejPr7Cc7Dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697488389437?e=2147483647&v=beta&t=Rjs9WFpZgEimyICSOI7yy-pcH8bQsRqRMMIrjlCuQNg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Patrick',
        lastName: 'Foote',
        emails: ['pfoote@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFNH_xpX-yV2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691166698686?e=2147483647&v=beta&t=Gk2tEmAPV-dp-umbKEP2LlTecv1suIrOplI6poozP8w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matt',
        lastName: 'Higginson',
        emails: ['mhigginson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sophie',
        lastName: 'Chen',
        emails: ['schen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGq2B4NDbBQoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517515230117?e=2147483647&v=beta&t=_Y0yS9xBFRMgV3IhD5tYEnepbOe50YEV6kVX2m5RLCg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joseph',
        lastName: 'Meacham',
        emails: ['jmeacham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG4nw4DZOyBFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685713569258?e=2147483647&v=beta&t=_NskSLEx_MqvlUyU_h0_hB7IoQKn7IQ9LKUy7Z3J3IY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  saleassist: {
    name: 'SaleAssist.ai',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Georges',
        lastName: 'HASSON',
        emails: ['ghasson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eran',
        lastName: 'Shachar',
        emails: ['eshachar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHMIYqLmQAHQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655916872041?e=2147483647&v=beta&t=IgQ5QMkrfLq97QuMZru19pgWOzbnlBkyhcWZDGv_0cY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jose',
        lastName: 'Zavala',
        emails: ['jzavala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG1a7OYzq9KbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606090770126?e=2147483647&v=beta&t=Cp4S8qAAhQoTfRyiGnX0LWs1qNSqL3dEjJhYyFuD6Iw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alicia',
        lastName: 'Reid',
        emails: ['areid@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHUJzcva6lMUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688451136081?e=2147483647&v=beta&t=HKGmmeGjnCVIeM009lc-mFujjklSS7Rcf0Idx77LIcw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Craig',
        lastName: 'Hagan',
        emails: ['chagan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHgEeqzTuQiSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701413960771?e=2147483647&v=beta&t=JpQkcyKwAH_Lcmr72Z814e8jtGEF2l_NBajA2MS7bwQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  beardbrospharms: {
    name: 'Beard Bros Pharms',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Isabella',
        lastName: 'DeChard',
        emails: ['idechard@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeff',
        lastName: 'Levers',
        emails: ['jlevers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGaYUzcVYNRAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712100074410?e=2147483647&v=beta&t=l1vZFFCxtz9fGYcPyASbaYqzL58KRbfVTjLQNmfepXo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brian',
        lastName: 'Holler',
        emails: ['bholler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGEoBXqfSOoaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725986737959?e=2147483647&v=beta&t=6jgKMI_ssf3a6POY8RyGxG56sIyzGzKZcnp6TWB9_38',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bill',
        lastName: 'Levers',
        emails: ['blevers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEyqnRyqt38jQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1566800942138?e=2147483647&v=beta&t=KuGk0-6e7kowokIvl_ODPsjc5SCugHrz-hGumcV07c8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chelsea',
        lastName: 'Mulligan',
        emails: ['cmulligan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEUx1axtuXsKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727721014125?e=2147483647&v=beta&t=5Tr_c3sMKN7vLQRodQMH0R3S4PMzjj9znFXRK1lnm7Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  jgarecruitment: {
    name: 'JGA Recruitment Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tom',
        lastName: 'Croughton',
        emails: ['tcroughton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGi8yVncmrqVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686856359831?e=2147483647&v=beta&t=LF7yXbthZ_fWdNOMrduA2R7l0zkGkQ0vRyntb125tE0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abu',
        lastName: 'Choudhury',
        emails: ['achoudhury@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEUAWOXO2Co9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516635382232?e=2147483647&v=beta&t=tQH5h4_vgPg1CAbUiV7U3sz2tCeqzJBYWhgaISbDamY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nick',
        lastName: 'Day',
        emails: ['nday@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQExIG9-vmcPoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709322721781?e=2147483647&v=beta&t=44q5K66MUZHXTfx4zaqIA6gz7JO32_fYdb34fn0lQLo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zoe',
        lastName: 'Lewis',
        emails: ['zlewis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Beck',
        lastName: 'Cutting',
        emails: ['bcutting@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHaxoAAoZ--Sw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665325246988?e=2147483647&v=beta&t=ug8C5PafYWgT45GG-CJD9IhM93Hbf3G_3WQ5l4kPpHY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cornell: {
    name: 'Energy Transition Club at Cornell',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adi',
        lastName: 'Pasharel',
        emails: ['apasharel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHqAocKsEDufQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666188367793?e=2147483647&v=beta&t=DjTqKMFdDjv0PQf4gXm5UDRJBJzj-VIK4-wHqqbMqzc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Iqbal',
        lastName: 'Abdul',
        emails: ['iabdul@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Zheyu',
        lastName: 'Lu',
        emails: ['zlu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEP4TNkUQZCJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701215592180?e=2147483647&v=beta&t=e2fD2JMaH6iFSKg2S-2YPFcyAOwxqCqIlQb-jDVdBOQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rocco',
        lastName: 'R.',
        emails: ['rr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHp9cfYxKKcww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641873904408?e=2147483647&v=beta&t=WJ6y5nBlgMTR8bo31cZrkc1WlC-_1yPd3MWP9aAGMS4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zafar',
        lastName: 'Ali',
        emails: ['zali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEbmXBYM_e4LQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516783456089?e=2147483647&v=beta&t=3P39DONnzpC5r96EnpaDb0Z1c8vwmUc-IJuv7lqrSuE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  sqrx: {
    name: 'SquareX',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Menotti',
        lastName: 'M.',
        emails: ['mm.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vineeth',
        lastName: 'RS',
        emails: ['vrs@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Arthur',
        lastName: 'Loris',
        emails: ['aloris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHfqrC_wgfmSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657702615365?e=2147483647&v=beta&t=Pd7RUqNea01G_6hu4YpTxzApbnxd9TQksoNs9BKp7yU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jocelyne',
        lastName: 'H.',
        emails: ['jh.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Raiford',
        lastName: 'Storey',
        emails: ['rstorey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF2v8H7gKi7KA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714585397435?e=2147483647&v=beta&t=kTfkJ-yd5y3rOdR2eVsFnzjnwArLP7RunG1zt5D177Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  srvhr: {
    name: 'SRV HR',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anthony',
        lastName: 'Costantini',
        emails: ['acostantini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEoFBjUVdMwnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720463404069?e=2147483647&v=beta&t=92Atlz71mQOdT6VzN21Dkspl0pZuMQYY3NrBMQvY_Rc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Debbie Thompson,',
        lastName: 'SPHR',
        emails: ['dsphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFQT97w3qPjxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696601916151?e=2147483647&v=beta&t=PZUPw6wd7K-bnob7131K6aC_zUEgApqfrMbPFCE9T3c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dee',
        lastName: 'Saylor',
        emails: ['dsaylor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH028Z1koUDPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705883131944?e=2147483647&v=beta&t=7lXBZaTGaZQi3ri9NSTHR1MDq0Ug3CArltMKjO0Ckz8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Mills',
        emails: ['cmills@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFEU9sFHgiPsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568985063774?e=2147483647&v=beta&t=Ubq3F5bKM8xrJWCD2djoYa7IZlYYLTahI1H8DsXNCj4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Richard',
        lastName: 'Krout',
        emails: ['rkrout@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  midofficedata: {
    name: 'Midoffice Data',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ramki',
        lastName: 'Jagadishan',
        emails: ['rjagadishan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGIPwdyYxLp6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583805328872?e=2147483647&v=beta&t=i7q10vZ8E3d9FaTHJBXRDRxJAcXUKO6WGHwqbFSGUCc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rahul',
        lastName: 'Pareek',
        emails: ['rpareek@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rahul',
        lastName: 'Parashar',
        emails: ['rparashar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sateesh',
        lastName: 'Tatavarty',
        emails: ['statavarty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFkB9qUbdEaCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653486655975?e=2147483647&v=beta&t=Xrlgrf4yc26ISFhY3Cxt5XsNEh9VYiEogNrcaHZy3io',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Priyanka',
        lastName: 'Chhattani',
        emails: ['pchhattani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHSQx7BMU1A9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661385578062?e=2147483647&v=beta&t=XHufML1I5_47aWvaB2CEo4WyM49wMKHaYRCnR27xcG4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bldon: {
    name: 'BLDON',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shiva',
        lastName: 'Vunduru',
        emails: ['svunduru@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rahul',
        lastName: 'Khandelwal',
        emails: ['rkhandelwal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG0AsEAbqV16Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650811225879?e=2147483647&v=beta&t=OzKHYPxn6yit-AMMSb8Pz-y9Ho1fevrrxBvq0HhHWCA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jahnvi',
        lastName: 'Gupta',
        emails: ['jgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHnKhMOTiUbdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712289674594?e=2147483647&v=beta&t=lhsYPrvS5anuc6-RYyV8HAfMQ--NtJyEpVcBHh1xuJs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nakul',
        lastName: 'Das',
        emails: ['ndas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEKnpdKd8W0LA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686974742825?e=2147483647&v=beta&t=R7kspjTM2tM9VwOFEKsO16_6MVUklcfzG_i_lkEjIvw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aamir',
        lastName: 'Tinwala',
        emails: ['atinwala@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  newmajoritycapital: {
    name: 'New Majority Capital',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Allegra',
        lastName: 'Stennett',
        emails: ['astennett@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Darryl',
        lastName: 'Lindie',
        emails: ['dlindie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGylm8U_O78tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699319888871?e=2147483647&v=beta&t=chMSmM8-HTeDsaVw33T2KWF-MZBa6Fjhsn4kDdLZC0M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kris',
        lastName: 'Schumacher',
        emails: ['kschumacher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH8Upuw3PrLkw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668021031795?e=2147483647&v=beta&t=TtuoHMj7VxwpksDRfiRX1CnSdKt-DNMBc9-zu7LLLIA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gene',
        lastName: 'F.',
        emails: ['gf.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHr6WdbBQ7Njw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726087859054?e=2147483647&v=beta&t=MrWjXWyGkmHByza65sxcjv93z7p_SJyFrZfGXvVdk1A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elizabeth',
        lastName: 'Letailleur',
        emails: ['eletailleur@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mtap: {
    name: 'mTap',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Roopak Naresh',
        lastName: 'Gupta',
        emails: ['rgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHVLl-ed8Kdeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517248961235?e=2147483647&v=beta&t=n8TTzZPzRV21VhqFe3Y3v2e2TIDlwfxnTvG4Jjsd3tE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexis',
        lastName: 'Porta',
        emails: ['aporta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFCwzVwz4CKEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659719532168?e=2147483647&v=beta&t=J8VebJXhMf4nf4W4KPXPsTxqtiiQ43Zigf8i-cnFeJw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Julie',
        lastName: 'Bonsanti',
        emails: ['jbonsanti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHJAee13ypr_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659475539689?e=2147483647&v=beta&t=HpLfv1oblh2wzJ3AGq8ECB5b9xkuwem6qHK1q1Cxnvw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christine Brine, MTAP,',
        lastName: 'PCP',
        emails: ['cpcp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Suzanne',
        lastName: 'Hendrix',
        emails: ['shendrix@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  silverside: {
    name: 'Silverside AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Wrubel',
        emails: ['rwrubel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEMRLtVJXArCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1525923564701?e=2147483647&v=beta&t=uTcUNH4QvR7FX8fG_SNI-gOtVg1tanIDw-XgTFw6H5U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexander',
        lastName: 'Wrubel',
        emails: ['awrubel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHo2iiCfU1K5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634150705604?e=2147483647&v=beta&t=Y76pBCPzli0__T73qNGzW5a7vALkgtKj1XsGWabRMBc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Siara',
        lastName: 'Nazir',
        emails: ['snazir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFuJ70eMtY4iA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689996134359?e=2147483647&v=beta&t=esy8vMCC0CsqXJQrDRU_zpXNp14e7wZXSdtPDCPmfVM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Johnny',
        lastName: 'Rohrbach',
        emails: ['jrohrbach@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGkbC5yJQPBsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630089230922?e=2147483647&v=beta&t=zKoG-w9_834dc5vJK51gTSgP0b9zgnNdPvG7kC42rmM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Timothy',
        lastName: 'Niko',
        emails: ['tniko@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  civicroundtable: {
    name: 'Civic Roundtable',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Madeleine',
        lastName: 'Smith',
        emails: ['msmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHAjFotk0JRWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692733835137?e=2147483647&v=beta&t=FiSl2tStG5NoWOGDCD-rmxLwUcV5fcLUmdQly-7uobw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Austin',
        lastName: 'B.',
        emails: ['ab.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEGEbinOYDjew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684508618866?e=2147483647&v=beta&t=F7VN4CBAV7nAWexa1Mpky66UHLIRjqsGAz7itl9v10s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joshua',
        lastName: 'Seiden',
        emails: ['jseiden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF2TBvi9mPM8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517434411634?e=2147483647&v=beta&t=mydBd2n7-FKzd3S9ExHNMNTD-7SG2BGkH6ZrTT1w9hA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Emily',
        lastName: 'Benz',
        emails: ['ebenz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFf-5Swb-to9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629326279233?e=2147483647&v=beta&t=q1PXIEDOyrk348MK407TSUWuI2ypvkdZfn8b4bo7QvM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alex',
        lastName: 'Stein',
        emails: ['astein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH4CKMzF5U3Kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691176047609?e=2147483647&v=beta&t=6eJhc7m-qU8Z9ksJka0maPn8V390jisCx72h5bvYD5A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  austinSol: {
    name: 'Austin Sol',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Malek',
        lastName: 'Ben',
        emails: ['mben@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steven',
        lastName: 'Naji',
        emails: ['snaji@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE88HMSjLXXJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705961618504?e=2147483647&v=beta&t=MqcVk6c_PINtH-B_8GoHCTf_AAF1xhYijh5Z18oK14o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rich',
        lastName: 'Slaton',
        emails: ['rslaton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGEE9Gc0EWp0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517631691335?e=2147483647&v=beta&t=kjprL_p4yXqvWEWo1WVDNxoHrCqQsbB0wN6kcqBXKm0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Fayruz',
        lastName: '(“Fey',
        emails: ['f(“fey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHF8y0ttlpCsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701232745380?e=2147483647&v=beta&t=SDi2HcvymxB_vaILc3MXl4RMrjgnCgKmjPrAn9kQhoM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jake',
        lastName: 'Worthington',
        emails: ['jworthington@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQErpI13ECGTdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625605622732?e=2147483647&v=beta&t=e3LWWw_4h7F0MEseX_9GotIpXD1dOD93ab6rrmzGYfE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thequalityedit: {
    name: 'The Quality Edit',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lee',
        lastName: 'Joselowitz',
        emails: ['ljoselowitz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG-VE2VmHQR0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661278387673?e=2147483647&v=beta&t=7XghptECFqDCNXcLGuCyrnmwtdBYhe-y5JO-UFmXfPQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Scott',
        lastName: 'Silver',
        emails: ['ssilver@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mia',
        lastName: 'McManus',
        emails: ['mmcmanus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEPTf4S-DOIKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714339504852?e=2147483647&v=beta&t=ItpNvzKXNnjxJ1Ud1YUPXPcdC1oKaYCCHWlXFbrZKZg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zahra',
        lastName: 'Shivji',
        emails: ['zshivji@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFl10-BHFnuvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630614470930?e=2147483647&v=beta&t=be662OVmKTlHQXHOfD2awxF5dgB5IPwDSW-cAJ-DuR4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hallie',
        lastName: 'Katz',
        emails: ['hkatz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE0-4mA8-xeoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721769301691?e=2147483647&v=beta&t=UoT4O4zx0tnrpAyZlcMZPA_iNHq-UUyA50cY0JcB2j0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  hyrgpt: {
    name: 'HYRGPT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Debi',
        lastName: 'Kar',
        emails: ['dkar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFpqWBywmjvVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698995691355?e=2147483647&v=beta&t=6GH8ziCHX9M1xuyowoNjP0Hv2PQH4UWh8YVed9LSoo4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Neha',
        lastName: 'Mathur',
        emails: ['nmathur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFi1miXROhZjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699271045628?e=2147483647&v=beta&t=b6m4gM0vo79TU4la8V-5QocmdxSi16Ciseza4kXGUQ8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shipra',
        lastName: 'Mishra',
        emails: ['smishra@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ramakanta',
        lastName: 'Kar',
        emails: ['rkar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFqLnBSOWE5TA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661320589834?e=2147483647&v=beta&t=jaSZFqVkEtSyv0_rpu4lXz_rSZOfRo5v6t7jZNdvt3A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abhishek',
        lastName: 'Baranwal',
        emails: ['abaranwal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGXs1-iS69ZnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723897522530?e=2147483647&v=beta&t=yuD6WVy_9nD6W15QCSogsr0dYfoR0kD7EiqKXeEYFJg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aiwellness: {
    name: 'AI Wellness',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Abby',
        lastName: 'Aboitiz',
        emails: ['aaboitiz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH951LmYpW2Mg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705799971132?e=2147483647&v=beta&t=xfFNOsthTFs3EwzsTwKqd-aJRXxU9byx9x26uXmcv1M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Swathi',
        lastName: 'N.',
        emails: ['sn.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kate',
        lastName: 'Hancock',
        emails: ['khancock@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEob6zg6zuPnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724983159078?e=2147483647&v=beta&t=tva35SfOafryUPVIshnDZ7HvTvrapdQk3IS60AoIorM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abigail',
        lastName: 'Aboitiz',
        emails: ['aaboitiz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGTj20ATVhXQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644272348255?e=2147483647&v=beta&t=iWNZbYRRTJ-enCV86ZbMWmGaT97mMV0WuB-YUGrp3Ak',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kayla',
        lastName: 'Peterson',
        emails: ['kpeterson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH4hpl2JYGaWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726515894800?e=2147483647&v=beta&t=tqylOG4-0J0HW4C_G0BweLx3vbyUSa_w_UpkcwVWoKw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xamplify: {
    name: 'xAmplify',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wayne',
        lastName: 'Gowland',
        emails: ['wgowland@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Keys',
        emails: ['dkeys@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHtGYjQu6sfLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646128228070?e=2147483647&v=beta&t=V6zfsEYflh-f7VNWOrCj9-zY79mHNYyIwgSOdz_coJA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dmitrius Garcia,',
        lastName: 'JD',
        emails: ['djd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGBGMMZF1pvcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591087128660?e=2147483647&v=beta&t=agla63XStvMhLYUkbSaRWPLxZ3fb5eRwHhqJXq-47E4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bridget',
        lastName: 'Tuomala',
        emails: ['btuomala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHfB6shT_insg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579151678661?e=2147483647&v=beta&t=EcxvS96cwrlJSO72spUqs-1b3bf_YhupI4Kwk8tgy8M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ben',
        lastName: 'Tyler',
        emails: ['btyler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFmZWf4gGjYDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674207458240?e=2147483647&v=beta&t=2wvtG80Y_UuVkfHxnUMvO6nliB17KgC8CxY9aD6hxAQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kanbrick: {
    name: 'Kanbrick',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tracy',
        lastName: 'B.',
        emails: ['tb.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHOOb7UWN71OQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585236505439?e=2147483647&v=beta&t=Kb5BRgc9N-Hl8XeVx6Sz_9LMp0vBoKRr__vvl9uG6jk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sarah',
        lastName: 'Jensen',
        emails: ['sjensen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFno-na62_Gcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699057716427?e=2147483647&v=beta&t=P7yxz4GvhrfNMEMU5rTdILqHKZqKcvxJbQ5kx4p_43U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aliya',
        lastName: 'Esmail',
        emails: ['aesmail@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFf30iBSPQ3OA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517482823668?e=2147483647&v=beta&t=szwRJeLzIxfPZtu3PC1-S0EjQEjM8w9xY1O1Ne576Yo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Meg',
        lastName: 'Harris',
        emails: ['mharris@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'H.',
        emails: ['bh.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  quicktakehealth: {
    name: 'QuickTake Health',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rand',
        lastName: 'Ragusa',
        emails: ['rragusa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGBJIjmC7c4yw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707973172964?e=2147483647&v=beta&t=FQgMehQFtjoBr69cCGaWQuz-oV1NCZN2a5KmCmDPTVY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrius',
        lastName: 'Srogis',
        emails: ['asrogis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFnAot2CcuY_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1540278616690?e=2147483647&v=beta&t=ROX3dJ9mFeDolCLo05JN6ls4NHPBfPNFUa_oP49G4j8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Noble',
        emails: ['jnoble@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHqoTI8-2snIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707248211934?e=2147483647&v=beta&t=unXoP4ahW0ghcRM3vDu0sB5qqoyVgX1WQ5cWFC8BLcM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Derek',
        lastName: 'Spillane',
        emails: ['dspillane@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHM2xHIDgF3Uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559843864738?e=2147483647&v=beta&t=CwBpNCM4Zom2HcDudX-sjWJQF4q9dgWdLIe-AuPZz2s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marie',
        lastName: 'Jackson',
        emails: ['mjackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE3Li_nAOqblQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674909352156?e=2147483647&v=beta&t=cU64ViTE9JbuXp7ew0P3TLJRSie04O9yyPN4zzWUePU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  premiumghostwritingblueprint: {
    name: 'Premium Ghostwriting Academy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nicolas',
        lastName: 'Cole',
        emails: ['ncole@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGo6P1Qtvso1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721964691276?e=2147483647&v=beta&t=BuzHa6AywFcWCIvEdX8aobpkdK292S_WmxyeT6Lf4Ow',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dickie',
        lastName: 'Bush',
        emails: ['dbush@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFjX-BmKVA8sw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681174265529?e=2147483647&v=beta&t=GYdps6kCqbpYiI_dv8gqKYQcR-iv1QPz5QBib18lSj0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Katie',
        lastName: 'Hardy',
        emails: ['khardy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHQNcq2Jc7LoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699965711378?e=2147483647&v=beta&t=m5Es4h7xTm7RXfpIbsEme-F8sjuYyb2frAxjvX6vROo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daniel',
        lastName: 'Bustamante',
        emails: ['dbustamante@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH82mjTF_2M2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724029248289?e=2147483647&v=beta&t=gQAIuZLwiYuZtR5XmXBj2N9g3fhyJuc4zDV9EEk6JIw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dr. Jamie',
        lastName: 'Wilkey',
        emails: ['dwilkey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHqj_8jI375uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707765289772?e=2147483647&v=beta&t=1e3yXlIFhx0pWmEpc8zVboxtwPjyKWPpEcca0OkIDsM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  redcapeco: {
    name: 'The Red Cape Company, LLC RedCape',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vickie Sokol Evans,',
        lastName: 'MCT',
        emails: ['vmct@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQESDaGxmZHUdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715373021310?e=2147483647&v=beta&t=EoLv9B7sw2nBGBHq8e8g2RXahe3WxtZsQ9bcwuhCfug',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alan',
        lastName: 'Cross',
        emails: ['across@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF2gh76XeYnkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646420049230?e=2147483647&v=beta&t=H5q1voDsrEsGrWUTgMoO0nldKc4oK4yYyguDcUUM6nA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Veronica',
        lastName: 'C',
        emails: ['vc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEjILH3GlCUSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516934570746?e=2147483647&v=beta&t=XF-Oa5TbF3Bctn0GLdV-OALueNSwzH2rgMaFBzotQ1c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gwenda',
        lastName: 'Garrison',
        emails: ['ggarrison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGzQAUyqxELiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718216634478?e=2147483647&v=beta&t=QYV-RO0y72o2qObXEiFq92ZTYbPwGH_XEFT2XA5Fvno',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Unger',
        emails: ['cunger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHiBT3ibXIPbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687922338297?e=2147483647&v=beta&t=ohN5pw3SkQ_806FHc9sy0F6fSnCSH1YC6JVOuQfaCVk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  firmpilot: {
    name: 'FirmPilot',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jake',
        lastName: 'Soffer',
        emails: ['jsoffer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF4Pmq1RfI8sQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718268214800?e=2147483647&v=beta&t=wgtQ7N7l4WoX5S6QYNJ7YMXeQ8Btv4P7_Vw04mmrWJg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jesse',
        lastName: 'Rubin',
        emails: ['jrubin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE17rQSpfOprA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556163319616?e=2147483647&v=beta&t=cDw-CdSZDm47lpuKtPBlnMvlEfFsaoJDyIuRcpqk-Lg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Fly',
        emails: ['jfly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF5QT8InDgJ-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624476384841?e=2147483647&v=beta&t=Q0K1FFfZH6mJT484j17NSTFVu_qvw_Urylt2MRBAWuI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maclean',
        lastName: 'Earle',
        emails: ['mearle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF2Z0QE6QH3dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646004890028?e=2147483647&v=beta&t=8miv8NuN_P21nLF9JOp3WOE1lOqPcfyKGFUCfJPraE0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Lemus',
        emails: ['mlemus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfiJsHkmonWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699126532887?e=2147483647&v=beta&t=KGR5nIxN0CoG_LY7EBVTBQy57XX4B5vjOuF_5waKJyA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  amplisell: {
    name: 'AmpliSell',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joshua',
        lastName: 'Rawe',
        emails: ['jrawe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHArstd6c9HrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648056811097?e=2147483647&v=beta&t=z4vBLKVFql7SYtghduI-Eu7nrb8LXYyMnaAIbeqUbhI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Geoff',
        lastName: 'Cersoli',
        emails: ['gcersoli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGzHCL82fkinw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668095056770?e=2147483647&v=beta&t=9SIz7zu7akL4YIdSjcej1wYIZ5wBnbdx78Yu1S0CEmU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brady',
        lastName: 'Tieland',
        emails: ['btieland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEolzYReS8z7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574540844127?e=2147483647&v=beta&t=RB0DAhDqOrntNkIMQgmXxPDqVD_4a7WKTUVBfLEnGVI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Courtney',
        lastName: 'Duskin',
        emails: ['cduskin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEhxvpuYacQLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718290997551?e=2147483647&v=beta&t=pCmXPdUuu4GAlnlhwKjcoi_ZRjxSw3mX4jhZctKYmAQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shota',
        lastName: 'Furuhata',
        emails: ['sfuruhata@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHXuZG-hEMwYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517589615440?e=2147483647&v=beta&t=fQtgjaFX0L-33AgMKXV4tQUpXes4XtnoGz40VrRxTO4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jakeandgino: {
    name: 'Jake and Gino LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jake Stenziano,',
        lastName: 'MBA',
        emails: ['jmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGgm00peix3bg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662910665881?e=2147483647&v=beta&t=048Z3m_NjQp5oQl3unGURmUXQVSJoZb7iRB1DCEeS-M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gino',
        lastName: 'Barbaro',
        emails: ['gbarbaro@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'McKenzie',
        lastName: "O'Connell",
        emails: ["mo'connell@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGDm27u4FJ8nQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705600509928?e=2147483647&v=beta&t=KIgN1bJ8B2SQoN4dQOnJEvicMl3CF_O2idvOOlr3oXw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Scott',
        lastName: 'Szpyrka',
        emails: ['sszpyrka@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGtAhCeDk7FKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691690948465?e=2147483647&v=beta&t=5p7QUhez4Yv-3KZT2Av1uCviNx-Fa6ulElK5XBJgIjk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'Mullen',
        emails: ['bmullen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH2SrPdJOxMOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555275301170?e=2147483647&v=beta&t=dh9iqz-zJmoAcj_F8b66afXnGA7GhDXa7-pH8CRsa2Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thelaundrysf: {
    name: 'The Laundry',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gerald',
        lastName: 'Snider',
        emails: ['gsnider@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFlTFFOszWLoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516776666951?e=2147483647&v=beta&t=nbZ8kD3TxWLxtLYzMcjuVuYrYhyHC_liqAAimkuulnc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brian',
        lastName: 'Cohen',
        emails: ['bcohen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sam',
        lastName: 'Cohen',
        emails: ['scohen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHhvhhA2BIysw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516895046752?e=2147483647&v=beta&t=ejOBLcXk-aloAXPxM8L_b2LCnS2odDGnUnu-jpuDzA8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joseph',
        lastName: 'Farmelo',
        emails: ['jfarmelo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFOSFuhSBl1Rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609885616394?e=2147483647&v=beta&t=SvPDmgLZreS1M95oPh6Df6YfRJ3jUbWPKDYjf9rMn_s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kristie Fields,',
        lastName: 'MBA',
        emails: ['kmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEddrIz6YdhIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517686356464?e=2147483647&v=beta&t=QE7fL0Q4AJIPhOsH6jncS5-tIrqPV08jqgfluaynXdA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gomarble: {
    name: 'GoMarble',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Darren',
        lastName: 'Marble',
        emails: ['dmarble@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFSWDUdKdyEKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591318531900?e=2147483647&v=beta&t=3lrpFyTC40Uk4YrBrl9HV3kybUifzJeA2RVDEmaXytU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tom',
        lastName: 'Marble',
        emails: ['tmarble@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG5BSrajvSe_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571265729432?e=2147483647&v=beta&t=SXwHKFitZvgt-veVYQ_cpZtbaWyZkTnzZKNOIqf3LUY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Calvin',
        lastName: 'Richard',
        emails: ['crichard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFkyJrRydppiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721061396718?e=2147483647&v=beta&t=M1v6hWrcMm4sfBk8zBdBkWDk5V0v3MYLwm5A4Bq_3hk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cansu',
        lastName: 'Atali',
        emails: ['catali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFNfc2qvLyj-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519272382027?e=2147483647&v=beta&t=DhVIw_34il9vvVwlkQQmLSrYySEeWMZLAN_4omG3ua0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jesse',
        lastName: 'Marble',
        emails: ['jmarble@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEiIgKTSwhQgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718924573829?e=2147483647&v=beta&t=2jffUTZZGrOiPscrHJ0cil_hZIYm5inTjGxQ1e4WrVg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bellsant: {
    name: 'BellSant',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'Fellowes',
        emails: ['mfellowes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH4ScZxxfGtJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703782826359?e=2147483647&v=beta&t=qQu90Lu2wHf47KCCt8pTqfz48mCi9dpn5fi4thLjff0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rick',
        lastName: 'Bowman',
        emails: ['rbowman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEJmh08tb7y9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555596385083?e=2147483647&v=beta&t=rUK5FJyGzsDq-muqok1bmmCe6rH-Ne1Cz1uNW8EMVlE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'W.',
        emails: ['dw.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE95IfiRMvanw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707756971256?e=2147483647&v=beta&t=TSUOmw-YRtR6A2bbqunrA5gKdgCTif_EHVMlAGT-LYk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ryan F.',
        lastName: 'Jeffcoat',
        emails: ['rjeffcoat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEkZgKRVRndKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559098888552?e=2147483647&v=beta&t=oLxpQ1VXMTeDyF94jvFE1oTj070C8a9DDxQAp3IeMNU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kaijah',
        lastName: 'Hougham',
        emails: ['khougham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFTAwVungyx7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582128033716?e=2147483647&v=beta&t=9luucf0EZJTJpcJ0idjbFXL03-JjKAqdKCq3_Jg6LKw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  benjaminsteakhouse: {
    name: 'Benjamin Restaurant Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Benjamin',
        lastName: 'Anderson',
        emails: ['banderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE4g2wzYelp2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578325124141?e=2147483647&v=beta&t=YTdRC-I6f6oPW2k8gW7cs6_L4DwF2Fw5ZczEBWKWUNE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Benjamin',
        lastName: 'Prelvukaj',
        emails: ['bprelvukaj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGbyS603tnZEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560355399679?e=2147483647&v=beta&t=hVhVI8bk8WAAQAnV1EtksUGstF9h0io0BvgOzByYZ5I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Benjamin',
        lastName: 'Rodney',
        emails: ['brodney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGd6W-UFo8VLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548794924579?e=2147483647&v=beta&t=0o7UsBz1mTIvl7lMZHGjmeJjfLS8JB39bUHql9gjyoQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eric',
        lastName: 'Benjamin',
        emails: ['ebenjamin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFARPRDH03B7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642014165041?e=2147483647&v=beta&t=6FB6ScbH4ifK5x_RuM4dh7irRKUl37MU0QU605b280Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Benjamin',
        lastName: 'Devlin',
        emails: ['bdevlin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFxErYkrm01KQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517509947293?e=2147483647&v=beta&t=7z7wjEEMBfiRcRTp5AHcoZU_n1_C72tsXiY_EbIVUJY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  krostnewyork: {
    name: 'KROST',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Samuel',
        lastName: 'Krost',
        emails: ['skrost@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chelsea Krost Personal Branding',
        lastName: 'Wizard',
        emails: ['cwizard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH9GiymRHVFTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718242575986?e=2147483647&v=beta&t=kQRGmXiWfvJ_NI9LMVsi_6EGenmUFSUXy6flXpc-_Wc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Batel',
        lastName: 'Braun',
        emails: ['bbraun@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFvLS9qNzMwTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627663925699?e=2147483647&v=beta&t=-_aRMys7L9YQAmHH-Z2GUh3u7HlTvHViFVVc5l1icz0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Samantha',
        lastName: 'Krost',
        emails: ['skrost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEL3PiOjau3qQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665168589767?e=2147483647&v=beta&t=1Teacgvu0rB_b62nHdiVecU0NprD7fgA06ujJ4cjW54',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Donna',
        lastName: 'Bateman',
        emails: ['dbateman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEAh4QYEnfWtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516632687557?e=2147483647&v=beta&t=SemdtjH2ijZxr-QpVdtkLU6pbwVtU6LNFITFVgZb6IQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bwdb: {
    name: 'BWdB',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Irv',
        lastName: 'B.',
        emails: ['ib.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rahat',
        lastName: 'Ahmed',
        emails: ['rahmed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG_UfusIvml2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728419773000?e=2147483647&v=beta&t=yBd58c8YWP1MYiKTGOnxs9ePqLdSz-ylEDXLQEHikv4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ejaj',
        lastName: 'Ahmad',
        emails: ['eahmad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHzAmtTGqINXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660242130586?e=2147483647&v=beta&t=YsvFoYe-EPlix2e9O7joyxCSSm0mJUIVcSLra5nOMyQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Farmina',
        lastName: 'Hossain',
        emails: ['fhossain@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shumon (Suman)',
        lastName: 'Sengupta',
        emails: ['ssengupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHkeaNMemChtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622127327004?e=2147483647&v=beta&t=wdlU-39OBQ5LL1zZKQlkYVoFnko1tRhsbSKILO_1EMg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  growthmarketingpro: {
    name: 'Growth Marketing Pro',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hailey',
        lastName: 'Friedman',
        emails: ['hfriedman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHt9VI4MxT2jA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680091713659?e=2147483647&v=beta&t=vjoG0gg50QFdFaFKBopbrQstEHzhKiLNvjfnQB3lgUs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Madeline',
        lastName: 'Hogan',
        emails: ['mhogan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFWuwSvdrj0zQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713887297208?e=2147483647&v=beta&t=o6_X59CZJQqiZ2wfQHVRU-VXaDclOvkI7AqifwKPTXE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Adam',
        lastName: 'Quint',
        emails: ['aquint@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGaXYjsOEqBvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716132978946?e=2147483647&v=beta&t=53j9h47jqb4pCbNK0T435CbpFkNgWguoFSjyGWnGSh8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'Spera',
        emails: ['mspera@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elizabeth',
        lastName: 'Melching',
        emails: ['emelching@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHpOY7I82yd1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622820243214?e=2147483647&v=beta&t=XXeRVBKAQMXZC6Ym4LfNW3A0SQOgNI0YI9HUgt1c8Mw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  womensrein: {
    name: 'Womens Real Estate Investors Network',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dayana',
        lastName: 'Hansley',
        emails: ['dhansley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH7MjmN9rRurg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662921898881?e=2147483647&v=beta&t=PO--QMx4UlZPuJJUz8sih8lTUajhEvRmvwGgTV_ekec',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeremy',
        lastName: 'Rivera',
        emails: ['jrivera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE4jZkNEJIz-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634840046424?e=2147483647&v=beta&t=USqUjSLPpDqyOJV7yd1I7cPt3B1YCDh5IkPmO3VQTUY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tresa',
        lastName: 'Todd',
        emails: ['ttodd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFL1hLIW_qE7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715110851089?e=2147483647&v=beta&t=neJPaQ-NRwTcVe4Wn82FSbKXeZu6Vqo4Fl5Fh_AATeU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Samantha',
        lastName: 'Espitia',
        emails: ['sespitia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEokRyjINOPPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705763754017?e=2147483647&v=beta&t=kb3eH80utx1xZE-cPDxmz_qE1YuQ_AlO3hqk-4AXzVc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adriana',
        lastName: 'Candelas',
        emails: ['acandelas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFWfWaviIepyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688594851352?e=2147483647&v=beta&t=U0vCH2YC7KqVzFsI_UX6sfhnNsjPzKIHB1kLLu2-2Bg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  motom: {
    name: 'Motom',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wendy',
        lastName: 'Wildfeuer',
        emails: ['wwildfeuer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Julia',
        lastName: 'Crawford',
        emails: ['jcrawford@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Teddi',
        lastName: 'Welch',
        emails: ['twelch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQELvEc5bdRyHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724863278694?e=2147483647&v=beta&t=ZORFmJ1Qq7QuvaYdWNxR3jp3irqlLWYLy9cnBkMEOVY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mary',
        lastName: 'Vui',
        emails: ['mvui@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hallie',
        lastName: 'Jackson',
        emails: ['hjackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEp1oiJluN-9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711305892409?e=2147483647&v=beta&t=4WtR454ODP7kxixW7nF1BO_xmIm1EvS1kwNIwGiGZ4o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nusunpower: {
    name: 'Nusun Power - Global Empire',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jordan',
        lastName: 'Binning',
        emails: ['jbinning@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGMmxX34XB0pg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728226398585?e=2147483647&v=beta&t=Ncczzrb-cw8pQ9lXT_vvkYmObHC0p3uLdLBcsi_ncog',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Arthur',
        lastName: 'O.',
        emails: ['ao.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGgT4jgVq5Mig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702386457107?e=2147483647&v=beta&t=EgfeZaUJKeD7SDjwD6-Bx3Tm83ZfiSrjtr1i9m3FAmo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gabriel',
        lastName: 'Pfeifer',
        emails: ['gpfeifer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGlQHTZd7II8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638205322848?e=2147483647&v=beta&t=VSXahHzg1pwyDZWqwbcwR4YH6gFJmVTi75hRMWUvx0w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nick',
        lastName: 'Pass',
        emails: ['npass@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Corey',
        lastName: 'Reed',
        emails: ['creed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH8x2DU4bw2oQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697486753537?e=2147483647&v=beta&t=Q_GOV5x-083iiWRF_quIAgmKSGNsMoXUbmnXJkEIvOI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  musiccityroofers: {
    name: 'Music City Roofers, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'Richfield',
        emails: ['jrichfield@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFV2GS4jRPZ-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574724296685?e=2147483647&v=beta&t=O4tVVROHXw34zJ5NtOpoZ0TcvFOFoBrkdbGT3QUfHOs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Taylor',
        lastName: 'Ammons',
        emails: ['tammons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGA5clgtnRb5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639230167077?e=2147483647&v=beta&t=rAINBfVn3e8uX79yGcRjq2AgSj1catVdnXKYtwKzuFc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rick',
        lastName: 'Rury',
        emails: ['rrury@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFMIICfSsXueQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718313003035?e=2147483647&v=beta&t=H4sq1Ltwpwa8fss3Y0H7wjhaxSr_uOW_ZwSlTffTPOw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tim',
        lastName: 'Mlynarski',
        emails: ['tmlynarski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFSyk8_resy4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710878097244?e=2147483647&v=beta&t=iXu9oFiSyn-PBf4TUSzQH4bT7y2LpyLx3UjCEWyNWxQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nick',
        lastName: 'Milliken',
        emails: ['nmilliken@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG5IrL1M2iemA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714659771485?e=2147483647&v=beta&t=sSVsMnj_nGbEAyulvI6AOnzVc8AR1EEeH2zcjk-O6X4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  orbitmarketing: {
    name: 'Orbit Marketing',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Louis',
        lastName: 'Shulman',
        emails: ['lshulman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGAmgMyrTkFUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685914850113?e=2147483647&v=beta&t=w4bgqPOUgq6Fa4J38ZXs8eL6-oLLifAdv9VCM-yACHw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mitch',
        lastName: 'Lord',
        emails: ['mlord@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHCYR-glcXj_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516323193734?e=2147483647&v=beta&t=pEyQ-o_AgP7JdVHf681WAwz_xFkexgrs524LPjuhXCA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andy',
        lastName: 'Crestodina',
        emails: ['acrestodina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHnMGceCdjJQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719051230364?e=2147483647&v=beta&t=guHIncB9yokiHX7ebp8DrZHOWtQAiQMiO9ogumXlCTQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shaun',
        lastName: 'Brown',
        emails: ['sbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFoxA-KLBR16A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671646279297?e=2147483647&v=beta&t=J-iG1Cp4btjev2KYXjq0X8gnr8831ReNXp_jfCyXKQE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sean',
        lastName: 'F.',
        emails: ['sf.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFg2iShRbnUDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1532009687772?e=2147483647&v=beta&t=8N3S8V0e2Qc6HFX2qVI6gxnemYPjpP1BVYs-1ojrfho',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wctherapygroup: {
    name: 'The Therapy Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jennifer Chaiken,',
        lastName: 'LMFT',
        emails: ['jlmft@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE0U3EQYsdkjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713226310575?e=2147483647&v=beta&t=jKTc4ublFkD2ZexcXDgjNiI6SD68YQlKESMLvBgIwhc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Angela Sanson,',
        lastName: 'MFT',
        emails: ['amft@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH-TcN12LHCvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698870267729?e=2147483647&v=beta&t=QV3Aa4MUzSza-qnJ-tagQ0L79Y4eFwfXg5N_Qlk5TSg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Julia',
        lastName: 'Rose',
        emails: ['jrose@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brynne',
        lastName: 'Schellenger',
        emails: ['bschellenger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHaeYrNj2pBTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709241440420?e=2147483647&v=beta&t=jYHLjikjP2QM5EeY2MjioGeqd3fLSBd_DmAuMpU1Wgs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Callie Fraser, MSS,',
        lastName: 'LCSW',
        emails: ['clcsw@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFmzrg0mPD73Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617849844696?e=2147483647&v=beta&t=Bl7XWa0VW5Tp2XNw23dYX01Ub5JVviikRnIlbWYC69c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sportscardinvestor: {
    name: 'Sports Card Investor',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jarrod',
        lastName: 'Sport',
        emails: ['jsport@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG19f4lw0v8lA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682445949104?e=2147483647&v=beta&t=npR3KQCg5AjY2vu6byoE4kElagRVVS9DNaPUiw29q2c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrea',
        lastName: 'Hopelain',
        emails: ['ahopelain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE6x4ccFppguw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617337205237?e=2147483647&v=beta&t=uALjzMnfWgO37YJkTKmJibd0zxq9FvcMKQ048oZs6wk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Edmund EJ',
        lastName: 'Wright',
        emails: ['ewright@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGa4Nu2zGf7IA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661929873900?e=2147483647&v=beta&t=ljy1vwKIz_CynpE0fPH9_ySH5M1txqtjCH_MPjJ_yvU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daniel',
        lastName: 'Turner',
        emails: ['dturner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brianna',
        lastName: 'Shackelford',
        emails: ['bshackelford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFH9a-yiqmmNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681829510145?e=2147483647&v=beta&t=pAD0jSH5FfdpjUT6bhYkFUQPr2A5DV1EU5koPLAG754',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  breef: {
    name: 'breef',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alana',
        lastName: 'Adams',
        emails: ['aadams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF4QAwOzAsnSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696106704369?e=2147483647&v=beta&t=RyhKgs74m172SM0l5aptNHRvX5bdyhGwbG_qC2-epCs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'George',
        lastName: 'Raptis',
        emails: ['graptis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF0MWy0USvvlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675731129203?e=2147483647&v=beta&t=fVb45Q0Y-zJ3QAF2P1a1xUb8vURJPygY2q8ez4XrBvU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aditi',
        lastName: 'Datta',
        emails: ['adatta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEVcSz-TR04zQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698335702126?e=2147483647&v=beta&t=VhYQSPDeLZeuiL0amaVLO0_KhZ5ZrSqtYHccYDyFo3Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jerry',
        lastName: 'Breef',
        emails: ['jbreef@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEf3eeJOf0OQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675879573561?e=2147483647&v=beta&t=iHoDJHIq1nUDTxWRWzbtsTTYHeGWz5uFjjUgdw0uajs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jenn',
        lastName: 'Epps',
        emails: ['jepps@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH-AEGnOXGu6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594959729126?e=2147483647&v=beta&t=cnXBFz5lPnmESNAMkjniHSXzXvjmV_TuqjqcHO1QfNY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  primeset: {
    name: 'PrimeSet',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Babak',
        lastName: 'Hamadani',
        emails: ['bhamadani@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rohan',
        lastName: 'Kumar',
        emails: ['rkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEbkz0h7DZ3JA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659861395512?e=2147483647&v=beta&t=JLXM3OyUoVbYjqgOy1c9KcavcPseAxDtQ5rxhCZ-wtY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Harry Blake',
        lastName: 'MARLA',
        emails: ['hmarla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFOpfPfIsbsiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678287404300?e=2147483647&v=beta&t=srNPHvPHq8VZyK5eYHbB1Vf6SJl9su0DChbCER00tcU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexis',
        lastName: 'Bodard',
        emails: ['abodard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHkUHTOjFC0-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638374131328?e=2147483647&v=beta&t=KmZtgj10qPrbzc5l6gj1yGYhQCH5XcX0cYIluLeHHOU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carolina',
        lastName: 'Vilela',
        emails: ['cvilela@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF-KN2ajXqx0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714063602948?e=2147483647&v=beta&t=3ipSSak8u9kZxFT6XGMewXaav-7qsWJLRW9mhEvWpik',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  venturefuel: {
    name: 'VentureFuel',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Fred',
        lastName: 'Schonenberg',
        emails: ['fschonenberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shira',
        lastName: 'Averbuch',
        emails: ['saverbuch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGOIDdcpaE9Pw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561576267970?e=2147483647&v=beta&t=51qy2N5PzrWkpJfJMd1SoyWp6ySVUvh48N69Ln12808',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vanessa Ivette',
        lastName: 'Rosado',
        emails: ['vrosado@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFMlgFYMN8pPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643742054310?e=2147483647&v=beta&t=-seTdHnZZLcrFCzL-Qc5qKAz4QXZ_xfeEbGCxT7thjI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Allie Gallagher,',
        lastName: 'Ph.D.',
        emails: ['aph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH_1jo9dSzC6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661625121897?e=2147483647&v=beta&t=nOlQgx7Ql0N-iOOOCAe6gY0nEkL6zc7uEkwIKLiyYZI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pritam',
        lastName: 'Bhattarai',
        emails: ['pbhattarai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFmY9Lxih30sQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542255766394?e=2147483647&v=beta&t=CtdfuHOj439A1Jq-sdUb0Yhx92Iu8lv_0NYQFGn3Hfc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  swiftsecurity: {
    name: 'Swift Security',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Naveen',
        lastName: 'Bachkethi',
        emails: ['nbachkethi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGDXjk9Hz-ccA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707795936277?e=2147483647&v=beta&t=D9KrW6ZGswZZ0VAhXKNL8dABDiycn45GeAcnKfoW-Zg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Neil',
        lastName: 'King',
        emails: ['nking@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGqGriFUZWmAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531429693203?e=2147483647&v=beta&t=kjPkj302ZU4f_uqfLpXMD-OahtmWWvfbKPkw1cccGqA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ranju',
        lastName: 'Biswas',
        emails: ['rbiswas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG5CwbmHC4_3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708636989112?e=2147483647&v=beta&t=zqeR-4YVVBRN9lMkTl7EaIAcG5weur4adQ__jUJhnME',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eric',
        lastName: 'Bartel',
        emails: ['ebartel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFLzNXrS3Pnqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605095514542?e=2147483647&v=beta&t=tSAyArL_czDkXPTVwMVxR95pNchEkqaCHf-6xNDeZtg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'B.',
        emails: ['ab.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  highbeam: {
    name: 'Highbeam',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Samir',
        lastName: 'Shergill',
        emails: ['sshergill@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gautam',
        lastName: 'Gupta',
        emails: ['ggupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHuGcd55CykoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625626391273?e=2147483647&v=beta&t=P5DrLZ2u4_3hIj5m0Pdw6mI-Xxlp27zUa5q-FZKoNSU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yasmin',
        lastName: 'Y.',
        emails: ['yy.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Geoffrey',
        lastName: 'Kwan',
        emails: ['gkwan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ben',
        lastName: 'Tonak',
        emails: ['btonak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHFe7bnmlcyEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729711012176?e=2147483647&v=beta&t=oUV7M6XjbVZQYW9QKAGnmaDGtWfdrzLdNR6Rql5lWIA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  unusual: {
    name: 'Unusual',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Javier',
        lastName: 'Bidezabal',
        emails: ['jbidezabal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHG2-df21u6Ig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653072917217?e=2147483647&v=beta&t=jMQKNwkAhX-2qN3fX2LxyWURYarRJQsuayYkTkBteNk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tim',
        lastName: 'Stiefler',
        emails: ['tstiefler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bethany',
        lastName: 'Wilcox',
        emails: ['bwilcox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEwSIva61C1Mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652994338398?e=2147483647&v=beta&t=HK9PU-OJTBrWtW1Fctzs-dxcRWVI8UWNdkQdX0o-UPo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Erin',
        lastName: 'Miller',
        emails: ['emiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEWX7nZli43NA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706130669149?e=2147483647&v=beta&t=VnYaK_-TVh6pt-jmQrxGy3KcYLXo3VZY2vw9zGmOOOw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abiram',
        lastName: 'Brizuela',
        emails: ['abrizuela@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  oxedent: {
    name: 'Oxedent',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Biplab',
        lastName: 'Poddar',
        emails: ['bpoddar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFGciJ4c_c1PQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699683444373?e=2147483647&v=beta&t=c6AxXpTKNDFQ1CmqdHDKkKEPWuaQJS5KcUZ14ejEID0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Saheli',
        lastName: 'Haldar',
        emails: ['shaldar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ayush',
        lastName: 'Nathany',
        emails: ['anathany@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tulika',
        lastName: 'Bera',
        emails: ['tbera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGejuIp-8ilnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617186243561?e=2147483647&v=beta&t=gdOwDCsBxVroJyvC9QXyixH9ihazU2Wz4N10EVEkxZs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Santu',
        lastName: 'Bank',
        emails: ['sbank@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHe7645fHJo4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615252814746?e=2147483647&v=beta&t=36OQR2boL398TQ-g0mt-i3W-RdCfCDHQDXFxJbYlHnM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hellogoodwin: {
    name: 'Goodwin',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nate',
        lastName: 'Ebner',
        emails: ['nebner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paige',
        lastName: 'Knoff',
        emails: ['pknoff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH9zDtMwZx5dw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708280678439?e=2147483647&v=beta&t=eOD3T-6wPjNn8Udtw2Kqw2q42tqARuBkM447bXWddSE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alexandria',
        lastName: 'Mason',
        emails: ['amason@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHKkLtuSacSXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516536441478?e=2147483647&v=beta&t=0Oly-HV0CpjywuCVjIm5JMq-kVWbuEovGKlYoICLyhY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christine',
        lastName: 'Langston',
        emails: ['clangston@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dalton',
        lastName: 'Laine',
        emails: ['dlaine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF9JUhaSiSzzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708040575333?e=2147483647&v=beta&t=f6A8ULua2pPxviurE9SE55V9OiDVwnIFFtG0HipoN1M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pianat: {
    name: 'PIANAT.ai',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sherif',
        lastName: 'Shaalan',
        emails: ['sshaalan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHAQ7sqLhOtUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560036039262?e=2147483647&v=beta&t=K1oQ2BY5kmTa5Jwp91jseVd-ZtmolcPlrysatcszQA4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rewaa',
        lastName: 'Hamed',
        emails: ['rhamed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF75x1J-vRasQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711862680068?e=2147483647&v=beta&t=dWhbG-Rwuj_VRiHioPQjHD8HJzV5R0nWULJKpzREpLY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ahmed',
        lastName: 'Abdelbaky',
        emails: ['aabdelbaky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGnXL50V6ThdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676890684691?e=2147483647&v=beta&t=gca8JpXSESZ1H3J7yFDWOpNVWTUVFlPG05_ieWPIGj4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mona',
        lastName: 'Soliman',
        emails: ['msoliman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGFLU_l7mExfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713556359832?e=2147483647&v=beta&t=9Mt5f74EO0VMG0uaWRW8zJU4kanaOqokLSKu05c5K6w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abey',
        lastName: 'Koolipurackal',
        emails: ['akoolipurackal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHbsAcqsCETCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728100728406?e=2147483647&v=beta&t=Qjb2XOguP71i1H6z5XN8R64ywLa8wYE9kybu12Y7yTE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  growitpartners: {
    name: 'Grow IT Partners',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alessandro',
        lastName: 'Cutri',
        emails: ['acutri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGfp1FTQCawjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725917030834?e=2147483647&v=beta&t=7M8ZbbJBtOQ9FCrMshzr52UbWYYgnXHazi-RfqR6cc0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Terrence',
        lastName: 'Branley',
        emails: ['tbranley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEjtmP3hKmJgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711162191229?e=2147483647&v=beta&t=0wG_xbeeof4n60R1c6hhWSHFDezxOFZoYof9y3ik_Zc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Austin',
        lastName: 'Neudecker',
        emails: ['aneudecker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFjYWWXIFMXpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516218530515?e=2147483647&v=beta&t=9mN1qH6FsiAuC9sJj0jVxeX1tPOHhWV1z5uNdlLFbyU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Srikanth',
        lastName: 'Seshadri',
        emails: ['sseshadri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHqji5kNrsRwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553707870399?e=2147483647&v=beta&t=2ITQ0l2SnGZH3XMgTt4zySckKgFYKFTpHfKIkwb4Vew',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: 'Conroy',
        emails: ['pconroy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG9fmM998UFig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560363639753?e=2147483647&v=beta&t=gZcKQL6bg_TYczYdQy_suwFpMNJZwVQ-QoVaCimEKXE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  scalis: {
    name: 'SCALIS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Parker',
        lastName: 'Amoroso',
        emails: ['pamoroso@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFsUOj6YFy4-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712669554033?e=2147483647&v=beta&t=QbXE0MFvDeUhrDNbgvsqx-si1g1pHDfHzPvhasWwtJ8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brandon',
        lastName: 'Amoroso',
        emails: ['bamoroso@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lizzy',
        lastName: 'Mann',
        emails: ['lmann@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chirayu',
        lastName: 'Arya',
        emails: ['carya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGFsxtyG3kmLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714947242442?e=2147483647&v=beta&t=QyXJFOe5EYJ5lx-x7UIp-PxHAGRHiNnZJt294da966Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mathias Cohen',
        lastName: 'Scali',
        emails: ['mscali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFI6KS6dMqplg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680456875028?e=2147483647&v=beta&t=W5xvwAF43k15_wS8jS0BJw7PnjKDs0J5srxZLUNmc3M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  untappedlearning: {
    name: 'Untapped Learning',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brandon',
        lastName: 'Slade',
        emails: ['bslade@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Katie',
        lastName: 'Zak',
        emails: ['kzak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFRwlGwcXJFUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622140209499?e=2147483647&v=beta&t=q08m4bAIqziqQ-Gdeu5zE2uSXefOMvEOaPcS-p259KE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Emiko',
        lastName: 'Patterson',
        emails: ['epatterson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHn8uWQbyt2UQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727138547514?e=2147483647&v=beta&t=izYBWPsFhhbx1zyyN2pmPSbzK6LKWVVwi7kLO1oo6EY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jim',
        lastName: 'Carlson',
        emails: ['jcarlson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG_U_hSfYth1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558018590767?e=2147483647&v=beta&t=-Fr-ni1BgaIg8AZgV7iJt0ooj0bXK2_4jK0McAP2R3E',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Devin',
        lastName: 'Saunders',
        emails: ['dsaunders@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFu1-NangVk5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663708986835?e=2147483647&v=beta&t=qIO-vxC8XSTlxKwGuxxhlQIPMh43HUQjD2ovjFGEVuA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  indaynyc: {
    name: 'INDAY',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Inday',
        lastName: 'Williams',
        emails: ['iwilliams@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cristina',
        lastName: 'Botero',
        emails: ['cbotero@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Emily Sloane, OTR/L, CHT,',
        lastName: 'COEE',
        emails: ['ecoee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFBFyOSD4wK-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569294515619?e=2147483647&v=beta&t=HyyfLX5lHfzn9UCevHNxOPCMvbmV5F7Ff0aBIen0sHk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sangeeta',
        lastName: 'Sehgal',
        emails: ['ssehgal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHSjf93s_vH5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708455322016?e=2147483647&v=beta&t=EPwKWVEoZRmcSSixc7e41q5f6pxmEXVFn-qGrZFHPg4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Blair',
        lastName: 'Dixon',
        emails: ['bdixon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEEjU0Rd6nbxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697825755036?e=2147483647&v=beta&t=8DdFfMTB80iJw13Q7oLAYpqKhNfBRlK81UFD0aSXKgA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  resemble: {
    name: 'Resemble AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Zohaib',
        lastName: 'Ahmed',
        emails: ['zahmed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHfrg2l6s9Kqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638207082425?e=2147483647&v=beta&t=OV8QmnNNvzrjMGgxCPM01VQrysv4skl_neAOcTPNSuc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Edward',
        lastName: 'Un',
        emails: ['eun@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGQQg_mnIhDZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573059818904?e=2147483647&v=beta&t=2ylqO5RgkglFL-juRyi8aaEhjHi-25bR1sjNQ-zeB6c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Saqib',
        lastName: 'Muhammad',
        emails: ['smuhammad@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tiffany',
        lastName: 'Thai',
        emails: ['tthai@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tedi',
        lastName: 'Papajorgji',
        emails: ['tpapajorgji@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rtbanalytica: {
    name: 'RTBAnalytica',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vivek Singh',
        lastName: 'Parihar',
        emails: ['vparihar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH_zB9NAIPqlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710487472287?e=2147483647&v=beta&t=SN0-71Ja6RkdW9yxTfOi-XM2l5Yx-CpzNuHLotJoFpc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeet',
        lastName: 'Singh',
        emails: ['jsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQF7oRHruZ3CFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575997130372?e=2147483647&v=beta&t=yvEvedpWFPnnzHW723Wy7Uc6e_gPWXTdEnrBtMrvhI0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachit',
        lastName: 'Matani',
        emails: ['rmatani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEg4cC8sWV3xQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517344442004?e=2147483647&v=beta&t=JVYsRlX7wiSjUiafnKidvGDyb7-iGOCp552LmP07t9o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sakshi',
        lastName: 'Valecha',
        emails: ['svalecha@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Harshit',
        lastName: 'Singhal',
        emails: ['hsinghal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEAniJqtnZBNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546517414807?e=2147483647&v=beta&t=k9V_XIDOtHDbXXsYqJFgqYsbPyIwDxPxVmUcEKkxeA8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  closesimple: {
    name: 'CloseSimple',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Abby',
        lastName: 'Fox',
        emails: ['afox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHP5mcla5rstg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693535001070?e=2147483647&v=beta&t=L9ODD7YzWW22W89eXYdiEzxCGe_aGUCE-bg6gJTMkSE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paul',
        lastName: 'Stine',
        emails: ['pstine@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bill',
        lastName: 'Svoboda',
        emails: ['bsvoboda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF6kgOrCDE4Ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670531630888?e=2147483647&v=beta&t=tF8BXn9JkgngIybHb8xXzKI6ZJTwbkJQuRRJdjY2ewY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maxel',
        lastName: 'Schultz',
        emails: ['mschultz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE7eWZWSQvBag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668738030972?e=2147483647&v=beta&t=WXxz1RmhYnJEGdzdSUO0ZcjMc_MoCQZ8k850c6BWiNE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nate',
        lastName: 'Niemi',
        emails: ['nniemi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFY-XQUF-SNhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668729728265?e=2147483647&v=beta&t=ZLNXlAUI5SCjmLV_997Fo6jnRsC6UN1hco4AHskECW8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mynxsoftwares: {
    name: 'Mynx Softwares',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'ABHISHEK',
        lastName: 'SRIVASTAVA',
        emails: ['asrivastava@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGEcqXySunWLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724104662802?e=2147483647&v=beta&t=vATXylmaLyfdzI0hQCtNrflm3Ge4oEZtHI9M-Iy_FrM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gomti',
        lastName: 'Verma',
        emails: ['gverma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHAgI20edATvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712762489073?e=2147483647&v=beta&t=LAhGvSsb9AnuCJfLDh0hEqNwnCJ0oNvV3mLVYOO3DOQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Danish',
        lastName: 'Mithani',
        emails: ['dmithani@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Devanshi',
        lastName: 'Garg',
        emails: ['dgarg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHwNcZ57Lk8Ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704861349129?e=2147483647&v=beta&t=JTYr_OnmGSMxkHLGBfHCXtCXhd5V0DxTXJUmFPCzDIc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Neel',
        lastName: 'Kashyap',
        emails: ['nkashyap@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGzdbAojLGCvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718306306592?e=2147483647&v=beta&t=E7vakLsogYPCwGpKAuMIrV8ERbXc6__i2yoQAziwyY0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  flybeverage: {
    name: 'Uncle Arnies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alberto',
        lastName: 'Esquenazi',
        emails: ['aesquenazi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Theo',
        lastName: 'Terris',
        emails: ['tterris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGQSTcwJ3gXGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667480425695?e=2147483647&v=beta&t=KGNH3KPityfJ5TGQn959z1GX2HRsXKUEgpvSWzcdxuM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nikita',
        lastName: 'Truitt',
        emails: ['ntruitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGQicm_4fVz2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694009375019?e=2147483647&v=beta&t=eHgJfnCcLWLV4BNxPKrRK3ScIt3Mzh1qQkgAXpU1t0I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Bull',
        emails: ['jbull@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jimmy',
        lastName: 'Kleiman',
        emails: ['jkleiman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGL0zsf5GscSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673548520685?e=2147483647&v=beta&t=s-jtHg1MiQUUif2oqSz-0ErdTQGBLBuUVXOrP1QIO9U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  moksa: {
    name: 'mokSa.ai',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nabeel',
        lastName: 'Siddiqi',
        emails: ['nsiddiqi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE723JGfEIIrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1554172132371?e=2147483647&v=beta&t=U7DuTkOEFGv2i5KLoEfndjj734RxUq5HDE7OELQsN8A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nikhil Teja',
        lastName: 'Kolli',
        emails: ['nkolli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEUDe_wVYCO7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688605604232?e=2147483647&v=beta&t=Bp7H5i5vKWU3ciak5aCCvSj2I8w2RRadMb4IY8BfCzY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kranthi',
        lastName: 'MOTTU',
        emails: ['kmottu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEWdQSE4fM9KQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593029751312?e=2147483647&v=beta&t=84LNYfIc7W6WuPQbl02gpoLcI5aykYcS3FJpxOsl7Bw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nandini',
        lastName: 'Masani',
        emails: ['nmasani@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'SAI THARUN',
        lastName: 'ENAPOTHULA',
        emails: ['senapothula@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF6F9kIR36Btg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716133384911?e=2147483647&v=beta&t=yvfd6_Y6qa8J7xYmRNKRdjYl-2gEbcm29HmYl73mnjY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  antfarmservices: {
    name: 'Ant Farm Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Teddy',
        lastName: 'Smith',
        emails: ['tsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFiUCmrFw5KaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626549923724?e=2147483647&v=beta&t=qsmvO5MkNWMKQplgU-3EXZh0GdI7yZJgJ8qn7FCHPPY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Susan',
        lastName: 'Arthur',
        emails: ['sarthur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGvv2PTqP4cpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653841734263?e=2147483647&v=beta&t=O8sx_eDpdTxCwREC0mr4jWdj65-6o9B2Yaq2mENtDto',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anthony',
        lastName: 'Stevens',
        emails: ['astevens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGHlB0TUUbvEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517764296003?e=2147483647&v=beta&t=F4Pp0IhoXwV0sanxmxyJ68Mag_zIWmIs6LvEjG2j1Ls',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anne',
        lastName: 'Stevens',
        emails: ['astevens@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stephen',
        lastName: 'Doherty',
        emails: ['sdoherty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEjwouOJxjYpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703360195218?e=2147483647&v=beta&t=g0wVOyzzQctG7P8Zz5ZnlZrIhU6gws4z58rdoGGEtC0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  shieldsandstripes: {
    name: 'Shields Stripes',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jennifer',
        lastName: 'Byrne',
        emails: ['jbyrne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEJCRgnI5jUZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715147339096?e=2147483647&v=beta&t=rIVRQ8DKpN8_DOhYwljHkAH0FCh0oI0fA1Je0lo1aKs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Katie Balash',
        lastName: '(Decker)',
        emails: ['k(decker)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGsWBijUj3dvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517561612772?e=2147483647&v=beta&t=1r_cNHNTxI8WVcVIkaCTbw5zGf4iudRXE4mWVwhcBDc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rory',
        lastName: 'Berke',
        emails: ['rberke@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Steven',
        lastName: 'Nisbet',
        emails: ['snisbet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHmUdPPW76u4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722265826779?e=2147483647&v=beta&t=q9gevSBBK0JNlilus_6qplH-pc9T8z7tOuszcSHUBh0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'Dean',
        emails: ['bdean@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF6J54oCwYTqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726006486694?e=2147483647&v=beta&t=nioZTQnolxryt478dTW_qudukC1frwWpvy1LPjLummA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  empathmsp: {
    name: 'Empath',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kyle',
        lastName: 'Christensen',
        emails: ['kchristensen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEylS0-YTozdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707577325907?e=2147483647&v=beta&t=WOpeC3Mpatu8oEgefJjvk8E9h3iMYfk-oRILKrfa78E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lisa',
        lastName: 'Gregory',
        emails: ['lgregory@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFXbec3XnFjXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681172426991?e=2147483647&v=beta&t=9raxw9TDUQeX-Ef3qpgZGhI4EYqao-8w994Mc_i9Uts',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'Anderson',
        emails: ['janderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHNdfjZKUHydg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701460226186?e=2147483647&v=beta&t=p2C_yp66AOVdR_o0Caq-C2Z72AXqo75grXgJ6mNbKvM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Wes',
        lastName: 'Spencer',
        emails: ['wspencer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHgAXNbr6h8EA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712062819090?e=2147483647&v=beta&t=XAB6MwxyVZz8Hhe4wpz1A4abQ2eN0Qoq0Zs_Au8WuFU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'William',
        lastName: 'Choi',
        emails: ['wchoi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGZ8BzBOdAoqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516501166262?e=2147483647&v=beta&t=JrjoHXw7NvGSR791y9QEZP5OUiHyx27ECdgGBNwqYVs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  insightrevenue: {
    name: 'Insight Revenue',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Zachary',
        lastName: 'Gropper',
        emails: ['zgropper@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'René',
        lastName: 'Nießen',
        emails: ['rnießen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGyeU__EufoKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719952268683?e=2147483647&v=beta&t=QfgvnqwvxQ9GYYWCTxwsxcGfUhNYF-ukg9mGdqtle1w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Timur',
        lastName: 'Hicyilmaz',
        emails: ['thicyilmaz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHuVk_p1BSAFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699292245382?e=2147483647&v=beta&t=nJEjav_ijt-GbOWYaqTwGUd9KAFhZQNjOcgkqfsj5D4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Justin',
        lastName: 'Chong',
        emails: ['jchong@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ekbal Alnajar,',
        lastName: 'MBA/MSLOD',
        emails: ['emba/mslod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEbciN6HJa6UQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1515792396091?e=2147483647&v=beta&t=Z15rDmxNStEJg6fHiWI_I992EczqC5ioTB5IW-ZXnUI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  madestudios: {
    name: 'Made Studios',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Casey',
        lastName: 'Worthing',
        emails: ['cworthing@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEt1KTa9zLffA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626719499000?e=2147483647&v=beta&t=gtjKJl64E0_gsJnshjk81u28b1PIOGs6MC4GX8PLU_E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Drew',
        lastName: 'Meyers',
        emails: ['dmeyers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHsyUV5lFH0tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543530662083?e=2147483647&v=beta&t=Odv7Ze-k-t_gNtfr-ZamRJ8Pu5kquKBWEDTz83mhr6g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael Convey',
        lastName: 'CPA',
        emails: ['mcpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGTi2wNj4X42Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517674105578?e=2147483647&v=beta&t=FDIiqvEng12VeT0gnEKYx85yk4UPO9Q-P7VkRJpS4V8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Colleen',
        lastName: 'Dolan',
        emails: ['cdolan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEpUIFsScuwmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517770568852?e=2147483647&v=beta&t=hFWdGgQLfwrX4RD9vEwNw0bQDz1qlyKHJUMkpoRxV1Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sarah',
        lastName: 'Hone',
        emails: ['shone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEZT2UCzmV-1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582572382938?e=2147483647&v=beta&t=K3pgomnqavtJqxqv3-J1IPYq5SFCqA_OTM86co498OI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  berkanaresources: {
    name: 'Berkana Resources Corporation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cheryl',
        lastName: 'Whitney',
        emails: ['cwhitney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQH1wevOYprEpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516708199103?e=2147483647&v=beta&t=TNztEiD8Ul5h89PFU7Ggkdp1Dax-v5ohmBvF0YjY46c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'William',
        lastName: 'Giles',
        emails: ['wgiles@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Turner',
        emails: ['mturner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHSMwdwscfICA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714577724864?e=2147483647&v=beta&t=EoavLYF4yZwJzJI-bDPE9jsJuzKCmmP2KmmbRPr7snM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Billy',
        lastName: 'Wirth',
        emails: ['bwirth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFfrnyTT9PTpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516859480164?e=2147483647&v=beta&t=-yLsn_i8gYBhnUN8euiIAZwZZtRicZ-0EwLXwBOW-CE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daryl',
        lastName: 'Shaw',
        emails: ['dshaw@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGmXEMNuHTDvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516319257347?e=2147483647&v=beta&t=LqF9rfHSVtC7NimNMZHj-3wwxsJSqI-Wz0iX3dnM-ls',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tonyseba: {
    name: 'RethinkX',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tony',
        lastName: 'Seba',
        emails: ['tseba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG_7f2QLMd0Yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666404380798?e=2147483647&v=beta&t=eA0DdLDc1B7ProY0469j26WEVoqT9EpoD8ga7rMpym4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'Dorr',
        emails: ['adorr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHO_nSqrReblw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596900779325?e=2147483647&v=beta&t=75Yg-WtfyrmzbVVVL3O06A8jCyG5MLzbO-luhtx0Vao',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'Arbib',
        emails: ['jarbib@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEtz1WGDn09rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521388359146?e=2147483647&v=beta&t=j2dws2Xh-59K5Oo2qrGGPzL-QIHaX3azXaSTm3Smugg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Richard',
        lastName: 'Gill',
        emails: ['rgill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFTBg2KYtAM9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665365090694?e=2147483647&v=beta&t=2LFF1jHpBDfCk8Cp3atjrYNbdKEvLdbF6zWBVT5DdS0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Taylor',
        lastName: 'Hinds',
        emails: ['thinds@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFeeBN_pvVqtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597090702001?e=2147483647&v=beta&t=0z8S1SSF-IElekzv7ktt6Boduu-EfCXftXdY8VT7g14',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  getclarify: {
    name: 'Clarify',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Austin',
        lastName: 'Hay',
        emails: ['ahay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHlyDbW-6QHqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701958256118?e=2147483647&v=beta&t=uuL4h7iICs6MgSTviQa5ol2GIo-waM-NYkmtXzhytlA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Patrick',
        lastName: 'Thompson',
        emails: ['pthompson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGN-QJwIGQBeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630361840209?e=2147483647&v=beta&t=0ejKhZ7fvonXjHN14L_HEfq_Nl0KqNgVKH1Y7XU6S9A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ondrej',
        lastName: 'Hrebicek',
        emails: ['ohrebicek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHg27DoBcFCGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517746436969?e=2147483647&v=beta&t=ZqtZ8N74ZpaO3hBei-YO2ZKSSKn9qsj1pt-7VODrkqs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ashish',
        lastName: 'Pandhi',
        emails: ['apandhi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF-NZptdhGCFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517556567738?e=2147483647&v=beta&t=sTuG15WtdW_5_pD4y7PZVpDM_S0MGMRnvDUrWPkfc10',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'SRINATH',
        lastName: 'M',
        emails: ['sm@example.com'],
        image:
          'https://media.licdn.com/dms/image/C5103AQEUiTWgXbDUdg/profile-displayphoto-shrink_200_200/0/1522759807165?e=2147483647&v=beta&t=u7f2jolqOqp-dCIi0jswrrKtjJZ-jAYfqaW2VdVwFLM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  questapp: {
    name: 'Quest Labs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Soumitra',
        lastName: 'Dey',
        emails: ['sdey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFDOq533zdyrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669293507863?e=2147483647&v=beta&t=dEdFG9Jw2notqf0-rrwpKOn8oHa0csKSoIGEUihDAQk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pragya Gupta,',
        lastName: 'CSPO®',
        emails: ['pcspo®@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGWPjL_xWPZaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689130734133?e=2147483647&v=beta&t=QDjngcwqCYqPBLrQQ9f6vajZZaoHZ8hjnwF3FEuaV1Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aanya',
        lastName: 'Gorai',
        emails: ['agorai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH9oWLlDZdQxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620600386128?e=2147483647&v=beta&t=fHxVjo6hoHh1Ol-KcNOtNM8ZdIpELWaCUE74nYbgFWE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Manini',
        lastName: 'Gupta',
        emails: ['mgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGiQMkoNXVxgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726421906887?e=2147483647&v=beta&t=qSU0_ju2JM3Ge3_d_ixx_Hh9LhVvhUdvm_V0LurlGeQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Praveen Kumar',
        lastName: 'Patra',
        emails: ['ppatra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEIsGPkcHkngQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719337372869?e=2147483647&v=beta&t=r0gAkLyhpL_CF7P_xDVjVcl8w_P4mw71qT87WY-I_u8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  clarityrcm: {
    name: 'Clarity RCM',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jessica',
        lastName: 'Stanley',
        emails: ['jstanley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGUlIR5NKFaJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650647007498?e=2147483647&v=beta&t=esu2nyCOLJ8cLW0mCu7ffeDw1gCpAv1UhIh5i9dcKiY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Estelle',
        lastName: 'S.',
        emails: ['es.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tabitha',
        lastName: 'Williams',
        emails: ['twilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGR-feHqmKF8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644434409991?e=2147483647&v=beta&t=wV-HGk_RZF1KhIHjUto-6JWuaBgw4vI-oVysxkVjyDA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Wendy Montoya,',
        lastName: 'CPCD',
        emails: ['wcpcd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Laura',
        lastName: 'Rice',
        emails: ['lrice@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFuiDKWmBJbNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694477160316?e=2147483647&v=beta&t=UlFI2stxgo9So_PF4EvRuCNbpFlydy0hUgFHOT_GAWU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  readthepeak: {
    name: 'The Peak',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alex',
        lastName: 'Blumenstein',
        emails: ['ablumenstein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFbfi0XXsB7hQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678140686120?e=2147483647&v=beta&t=qD-24df1H2ChOeefQ9RmjJhZBK1302d2icpYaP28dVQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joyce',
        lastName: 'Read',
        emails: ['jread@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGAA73tak1iuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541667735828?e=2147483647&v=beta&t=uVzYYJvduU2cm4xtiFdjd-KcDyeG5Dl-fZYPFfldzXk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'A.J.',
        lastName: 'Peak',
        emails: ['apeak@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Katarina Peak,',
        lastName: 'ICE',
        emails: ['kice@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zat',
        lastName: 'Astha',
        emails: ['zastha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHe5jfN4YT7xQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719791497184?e=2147483647&v=beta&t=0qn_oSkFzZ6JP4-zpwjfBnP6CuCQ1YRwkHu85pDYPiE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  techstack: {
    name: 'TechStack',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Allison',
        lastName: 'Troy',
        emails: ['atroy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGofMbxDDdztw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606481687813?e=2147483647&v=beta&t=MP8VgkqIwPhhV91orrIriKtSsx8bHuR58Gjb0EiYzFU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jon',
        lastName: 'Cooper',
        emails: ['jcooper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH0-2TJDEV-7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516234966722?e=2147483647&v=beta&t=y7STHMYW4dKVqzoNCAFNLLNzRzmBKv5TQoZXnP9pZd8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Emily',
        lastName: 'Ruyle',
        emails: ['eruyle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHiHhXjfu-X2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702529818198?e=2147483647&v=beta&t=3CMd6q7axX9rsoDL9pX1q-p0FuTlTbwysedoh6RhftI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kit',
        lastName: 'Stevens',
        emails: ['kstevens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFJA4UroV1azw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664829318498?e=2147483647&v=beta&t=2rFv6PGcm5Bk6utXAil9QRzRnybZOq3gBAmS29AITm4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sal',
        lastName: 'Downing',
        emails: ['sdowning@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGA9QptmhXiJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705270724346?e=2147483647&v=beta&t=ozTQp4ilQlfW-Y0pzUVACZarTp9KWdUHNbkSBVtjVFU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dwelloptimal: {
    name: 'DWELLoptimal',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Craig',
        lastName: 'James',
        emails: ['cjames@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFNDuirVDOqYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702312988100?e=2147483647&v=beta&t=iiubiEvKaCHlbZwowmTtoMPr_SZAgopbe-rbn1rAPF8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carey',
        lastName: 'Rouse',
        emails: ['crouse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGzBANBKTOMww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1640756502816?e=2147483647&v=beta&t=l6aheJEu4JFU4vAjlTuw7S190npXFAIF3O8UngPz9RU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Meghan',
        lastName: 'Ryan',
        emails: ['mryan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFr-IauuGAsGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700880516525?e=2147483647&v=beta&t=HCx6BnzIPcjEN_INgMKRbWIIKpZNvgUzu3szNUZpKvk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stacia',
        lastName: 'Burton',
        emails: ['sburton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGRiQW96v2YfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518318084805?e=2147483647&v=beta&t=8sAInSZhs5QuTHv-8HWJFtuVQgqw6caJvM7GrV_PN7Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vidit',
        lastName: 'Dave',
        emails: ['vdave@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGE5vZR8qjAJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679080007848?e=2147483647&v=beta&t=EtE0J-WpA-eApGIQZRbDRu2HD3Gfi8YVS7-6608_xVA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  growwithimg: {
    name: 'Integrated Media Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jillian',
        lastName: 'Shulman',
        emails: ['jshulman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHajQwILWZigQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634253569561?e=2147483647&v=beta&t=yvFLApllKxOlqmVzH_lfXgs1Z9GPJGFWVERERn78Hx0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michelle',
        lastName: 'James',
        emails: ['mjames@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHfbVC51hHFkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677546872099?e=2147483647&v=beta&t=UwxtpwucaLeDzIK57miOaIX_Lvda0mY9CetTaCdnnfE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kristin',
        lastName: 'Darcy',
        emails: ['kdarcy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Melissa',
        lastName: 'Seidel',
        emails: ['mseidel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEtSNqOC7O6ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677021988815?e=2147483647&v=beta&t=1K-QZfnwBTJp3Xk8zKYeWFWvl5z88e6LIRFX_vVh9fg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emily',
        lastName: 'Hasty',
        emails: ['ehasty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFySuYX1YmEnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624641696443?e=2147483647&v=beta&t=FPbJEy83CcgxSAGHds2PQG4cjox0-TDz6dD3izPdan8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  flytevu: {
    name: 'FlyteVu',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeremy M.',
        lastName: 'Holley',
        emails: ['jholley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHBiMN3nVmYoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700837606860?e=2147483647&v=beta&t=_IwVhkV3Ly5dvuaHZyGUnl0Rmq7WUEWjIGwls62UUzk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Laura',
        lastName: 'Hutfless',
        emails: ['lhutfless@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE11MUug_LJJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536690217588?e=2147483647&v=beta&t=Z0IbT2BzROCbr9P2pK24daZ1RFmKxDS4MkQguNnjKuM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ally Venable',
        lastName: '(Rodriguez)',
        emails: ['a(rodriguez)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEF5OuEp-zxyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702335839223?e=2147483647&v=beta&t=irj-sYfE_qUvVU4Faid-ZvVpac1mlzWk_KhynpIAd3c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jen',
        lastName: 'Amendola',
        emails: ['jamendola@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQECCEITxIz1_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601995965445?e=2147483647&v=beta&t=M9-cn9RVyziAI6LQWz2MSMEU-uZzHjyeTJUiKnG0tW4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brooks',
        lastName: 'Parker',
        emails: ['bparker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFdR8v7Y3mYHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649189790488?e=2147483647&v=beta&t=EqLwuzgeBe58b0y65HObFZwDeUdI52hl4aY05EYVtdM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mjsreps: {
    name: 'MJ Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jon',
        lastName: 'McCombs',
        emails: ['jmccombs@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jim',
        lastName: 'McCombs',
        emails: ['jmccombs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE2I0thTCcg1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660403822579?e=2147483647&v=beta&t=-iWgE-Wt6D8wws_AxDuwlk5eQ5IgVKc7fQjoj7CjKBE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eric',
        lastName: 'Dahlquist',
        emails: ['edahlquist@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH_sPzzMttgEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714650575394?e=2147483647&v=beta&t=FeriwdCAu15puCymKr3Iv1FyaBf-Bgaa-S_2csnlr-4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carolyn',
        lastName: 'Franjko',
        emails: ['cfranjko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFLbugDpTBbbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683136941536?e=2147483647&v=beta&t=6uHOIwqKx-vlTwGZStMhOr8AWCD5eJ7c91BNpx54uh8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Frank',
        lastName: 'Chiaramonte',
        emails: ['fchiaramonte@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  beamtlc: {
    name: 'Beam',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'M.',
        emails: ['km.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEqDHs0RuRspw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553171718949?e=2147483647&v=beta&t=76q0WQEsaVPFHCUEOis0R5Y9P6RklPBW80iYxBabU3k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'Eagle',
        emails: ['aeagle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHjs9FzlQ-f2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676946363127?e=2147483647&v=beta&t=8ui7OrxRoxJEn9nJUrqFOSUu2fTDWVjKBYJw7wOB4Dc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amanda',
        lastName: 'Goldstein',
        emails: ['agoldstein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHQcyEMoC2eoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629824183431?e=2147483647&v=beta&t=HFIIW0Y5vjT2UE_Gmun9E6zvLHZxzyLHCwswh0CtnVI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'Lombardi',
        emails: ['mlombardi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Connor',
        lastName: 'Siler',
        emails: ['csiler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEWgNmycqAsLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633356497109?e=2147483647&v=beta&t=gLkoO9mr_S2daeft3i4hiQYHsgNei3jdfbEt0ARnD9U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lelchav: {
    name: 'Lelch',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alex',
        lastName: 'Lelchuk',
        emails: ['alelchuk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFUPSnT5HndzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649976621926?e=2147483647&v=beta&t=BTPD3aDgVbZTpQOyQhrUxG691gUawoMxRjp2qoaBRDU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jesse',
        lastName: 'Steiner',
        emails: ['jsteiner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Adam',
        lastName: 'Pitzen',
        emails: ['apitzen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGYLXhL4Z080A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698798912475?e=2147483647&v=beta&t=PldH4eWnm11biKWDm_CgRsDszru00Dz2osz4W2teZTE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lucas',
        lastName: 'Cervi',
        emails: ['lcervi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHMh0KojzQWgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661709851334?e=2147483647&v=beta&t=YA5I-N3-42gLr7Z0dJJyATSHQFdfVuQWzvAwNear_1E',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeremy',
        lastName: 'Rosenberg',
        emails: ['jrosenberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEisXfLs346yw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697311433654?e=2147483647&v=beta&t=E3eTAi1lh6kPEgiQLbz2PCmlCTOWALH-fZ41FTp2Vs8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  paretotalent: {
    name: 'Pareto Talent',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alexandria',
        lastName: 'Schneider',
        emails: ['aschneider@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE64EX13WUgWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687525531062?e=2147483647&v=beta&t=TXzY54XriXwr7qy04kkCs1-32KoMADtl-M8PSuOS6XE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kasim',
        lastName: 'Aslam',
        emails: ['kaslam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGejWKAA4e5ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672701354051?e=2147483647&v=beta&t=kZBKgQ-XW-v10sgMAwJVU5t8RkAX-eg4B8_j_M68ewE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Philip',
        lastName: 'Woollett',
        emails: ['pwoollett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHw3fNd4a3Biw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637336852657?e=2147483647&v=beta&t=H7M_o34tm9-eDe6VDHVYfR-j1PTVFDbgIFRcyALc8pY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sara',
        lastName: 'Uy',
        emails: ['suy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHHWtrV6NC8jQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724120835349?e=2147483647&v=beta&t=n2iitUaa9G8QlfdLt0GCc9-8sOp6-5-4ClRPP-NnXrk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lydia',
        lastName: 'Lee',
        emails: ['llee@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  midwestgames: {
    name: 'Midwest Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alyssa Padia',
        lastName: 'Walles',
        emails: ['awalles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFBOrNopHk81w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681658402602?e=2147483647&v=beta&t=vDZyb-ktjqzPp6YisvTSt8ZWeq7yOA3fN3arwWRwXMU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ben',
        lastName: 'Kvalo',
        emails: ['bkvalo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGHZZ-teCwjsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694654995728?e=2147483647&v=beta&t=Jy2f94--Go2CT7MQf4nXxOhp9e0uQwgrZ-3sNfr3tgM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Klimecky',
        emails: ['cklimecky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHB33FQtwhzfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718262659587?e=2147483647&v=beta&t=hrQwihW6v4rUlxMeGWPTpjdj-z3ZC47TAeQMLE-GYDU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jennifer',
        lastName: 'Corbett',
        emails: ['jcorbett@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kay',
        lastName: 'Bell',
        emails: ['kbell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH3ps-ER_GVmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715660801487?e=2147483647&v=beta&t=0ESJqNKhQByqj-0ag90we290kopZFhFoeWWEZ11zALQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  facets: {
    name: 'Facets.cloud',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pravanjan',
        lastName: 'Choudhury',
        emails: ['pchoudhury@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Deepak',
        lastName: 'Saini',
        emails: ['dsaini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFpYF4PeHw-ig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729063673336?e=2147483647&v=beta&t=0KK2TX-9R4nCfJlL_u1jVZSeROjy66VLVUnwh1seR7k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Prashant',
        lastName: 'Dhanke',
        emails: ['pdhanke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHBLRbTGRHlFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690519418077?e=2147483647&v=beta&t=S2uyK7CJcboon3Hw55KhJmv5JPVpxwaBNzuS3In1wOw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mohita Nagpal',
        lastName: '(she/her)',
        emails: ['m(she/her)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFIr43I60B8aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695296754282?e=2147483647&v=beta&t=k6iB4vEJPlhxyViFbnhV1Y-FiE5b5onsryi1qSMbyE4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rohit',
        lastName: 'Raveendran',
        emails: ['rraveendran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHVIbS21kBvgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708265738404?e=2147483647&v=beta&t=429W-2d99ChnHUh0IcpNs1_Lu02Z2AGHI8pOppY9j04',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  getflank: {
    name: 'Flank',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Frederic',
        lastName: 'Colledani',
        emails: ['fcolledani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF1SgfecygCvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718198282855?e=2147483647&v=beta&t=4ZJ2PzgcOo2pMN5XeYCScv3VdRbPMyWYZu7Tjhon4Ck',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mohamad',
        lastName: 'Ali',
        emails: ['mali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFXmEzkn5gtOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696640351154?e=2147483647&v=beta&t=3gfNbEN0rgcSHhVEOSIAxE3vFYvn4PsBGysxamVW0NI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Liz',
        lastName: "O'Donnell",
        emails: ["lo'donnell@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE_MQsTCan8-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570705551188?e=2147483647&v=beta&t=AyDciOF1A1aTg5RUxUEmAA-1Xz6VHnm8DITjMLFs0dk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jen',
        lastName: 'Horton',
        emails: ['jhorton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHzbvzFCq7dVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724936398790?e=2147483647&v=beta&t=y4NOII-hJWOK0RNfAQZRtH7sFbClm9iamZCbe2gxvy0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  galactica: {
    name: 'Linq.gg',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Cubitt',
        emails: ['kcubitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG-5G8RFQ8vVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716363320743?e=2147483647&v=beta&t=8kXEai2QPIHBmCy0nCpsly5oQSkpV30YcdjTJrEYGJ4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dmitry',
        lastName: 'Vysotski',
        emails: ['dvysotski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQElenFNuFum0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709111130646?e=2147483647&v=beta&t=aLbCyr8fptHE5x687igz_7XFX09InWosanUxji2IdKc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: "O'Shea",
        emails: ["co'shea@example.com"],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stanislav Basko – Delivery Director – Linq.gg |',
        lastName: 'LinkedIn',
        emails: ['slinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFwcsvHkQDxSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542374637716?e=2147483647&v=beta&t=AFudHtnhQylhziGllQVkhIYguHpOb2tqEPwddlJYdnk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ivan',
        lastName: 'Klimchuk',
        emails: ['iklimchuk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF5lxj-mGFhIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675014777602?e=2147483647&v=beta&t=oipi8K9-FGMSbH5vfo15ypwr9abhpCJM39jjEtBB9LA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tynybay: {
    name: 'TYNYBAY',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gavin Oliver Dawson',
        lastName: '(GOD)',
        emails: ['g(god)@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rohith Reddy',
        lastName: 'Gopu',
        emails: ['rgopu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQELb8bofPKmBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708099362535?e=2147483647&v=beta&t=tWrYOWTZt6hEminzX7w30GcxGvXA0FsD5Or88cmQI1Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Zhibek',
        lastName: 'Tynybay',
        emails: ['ztynybay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGfcyGy-AoXZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701447792759?e=2147483647&v=beta&t=9mc4pPHjz-Ejg5Xl91UhJGfE51ILLt-WT6-wKYJ26Po',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Girisha',
        lastName: 'Chilukuri',
        emails: ['gchilukuri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFXcX9BDJ2pew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686910696717?e=2147483647&v=beta&t=_1W1BxepSBycmMbpZZbWC_yXLGJRTZEuY-KboKYi05s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hari krishna',
        lastName: 'Ravipati',
        emails: ['hravipati@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  affiniti: {
    name: 'Affiniti',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aaron',
        lastName: 'Bai',
        emails: ['abai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEonMDU7OpLdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724961517938?e=2147483647&v=beta&t=uobZhs-TCNGN4UV-lQ0FJXgB7mzN24J1A3wwx55Ttl0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sahil',
        lastName: 'Phadnis',
        emails: ['sphadnis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEoljp-QhHYqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724963228132?e=2147483647&v=beta&t=lO3njpOTgOr4TJUq4171pyuIigyQen1f-l2dG1i6KHw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nicholas',
        lastName: 'Shapland',
        emails: ['nshapland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH4XWwHbORoJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517481335252?e=2147483647&v=beta&t=awPJzQ61qlv4RABU9X5hsqm1XQY4kJsdm3bIM6acQUU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Evan',
        lastName: 'Zhang',
        emails: ['ezhang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEk21oVO0TVpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675403667926?e=2147483647&v=beta&t=necyw-3sWUrjpzx8yxAKHMQN5giD-82drAcHqRivCdM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shobhit',
        lastName: 'Nigam',
        emails: ['snigam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHFX3dQukYe1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697713408106?e=2147483647&v=beta&t=WM7VHrzjM-3gxoh6AL3-x99xUeyqhi-o7V369TKlt9Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  lincLa: {
    name: 'LiNC-LA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniela Del Carmen',
        lastName: 'Rodriguez',
        emails: ['drodriguez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFvbFCzpOrgrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671402215949?e=2147483647&v=beta&t=zmMOdyObTj79a2aC_08eEnVjOx20r0dWCQRYX90VKbY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Baptiste Le',
        lastName: 'Bihan',
        emails: ['bbihan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ethan',
        lastName: 'Pineda',
        emails: ['epineda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH1IBE4erIy4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718589796539?e=2147483647&v=beta&t=9OnWNoUSbVST9oZ8adVBohnoZ_w9j--P9LDpxepcuqM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christian',
        lastName: 'Yim',
        emails: ['cyim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGOPylC4dzsDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657668743508?e=2147483647&v=beta&t=Gf9Se3X-YVegAkRWOJax3wx3S2Kw_uHMSaZBchPGNMo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Xavier',
        lastName: 'Chung',
        emails: ['xchung@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  servicedynamics: {
    name: 'Service Dynamics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tim',
        lastName: 'Oaks',
        emails: ['toaks@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Philip',
        lastName: 'Venables',
        emails: ['pvenables@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andy',
        lastName: 'Dalton',
        emails: ['adalton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE4d2FC-uXVRw/profile-displayphoto-shrink_200_200-alternative/profile-displayphoto-shrink_200_200-alternative/0/1528861443462?e=2147483647&v=beta&t=d0rRZqOJ5EmmAK9UU8m7CL4yaq0L2boPuFVVH0KkLns',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Grace',
        lastName: 'Lopez',
        emails: ['glopez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQES3GtmVLO15Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697420212788?e=2147483647&v=beta&t=DmL5HCUPwZqE9DwtDMr0CUqUWVqRqOyHDHH9KrDLmk0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Derek',
        lastName: 'Leitch',
        emails: ['dleitch@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  myosin: {
    name: 'Myosin Marketing',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Simon Yi',
        lastName: '〽️',
        emails: ['s〽️@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHeMUVR81Kfkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715263072523?e=2147483647&v=beta&t=ffATo9TbtkZC12aa3WfwOIBZ0Q1HTVoUKBVTSXu-aS8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sean',
        lastName: 'Clayton',
        emails: ['sclayton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHRGDV2Y1-ljg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723010772641?e=2147483647&v=beta&t=oFzLzyoQZpDGwMxl96J1J9_tB6RPzVmWr_D4ZrpVXeQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Courtney A.',
        lastName: 'Miller',
        emails: ['cmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEAMzDlPUR0jQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617883022526?e=2147483647&v=beta&t=LHk_gcWkkAD0aOoO20KycJH0Bdf2GX2FaRhiM5tQVXU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Barry',
        lastName: 'James',
        emails: ['bjames@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHd_muZmwsWcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1528725485443?e=2147483647&v=beta&t=gCc1UI6p1kuJ86tFZWLMHxYiTLzRDJ5LevRpuwhU8EM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sophia',
        lastName: 'Powe',
        emails: ['spowe@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gymclassvr: {
    name: 'Gym Class VR',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Justin',
        lastName: 'Hubert',
        emails: ['jhubert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEskT5MVepONA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659660464827?e=2147483647&v=beta&t=0hF-fLw03PO7km3lERBGBkKoLK6rAIDu0ud6ONGP4iU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joe',
        lastName: 'Signorile',
        emails: ['jsignorile@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFl121e1XAKiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651873420556?e=2147483647&v=beta&t=dMy7fsopVA0BEUf0k1EI6XxXXN5RAwD16qnp6svXTno',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Harris',
        emails: ['mharris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFET51xMYF4oQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702089769239?e=2147483647&v=beta&t=X1QriBSCYuBpt-fYbI5WnA3EMfBYc6dB1fI3UcDMy2s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ted',
        lastName: 'Gabbard',
        emails: ['tgabbard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG-0kuKu_C8pg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723494660522?e=2147483647&v=beta&t=lf1cYxE4Xqf41nrzYKbahADPz2laIuMwsvdrgl14sE8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Katsen',
        emails: ['pkatsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH9FkLEFRbIMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695392223497?e=2147483647&v=beta&t=4PlBH_yeZmIaImxrml7QUSGMqXCfaPYr99hxt1RbiC0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  iyk: {
    name: 'IYK',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Iyk',
        lastName: 'Azorji',
        emails: ['iazorji@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGCO508YoC_Gg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652464274177?e=2147483647&v=beta&t=ddTXxK9M9dhuS_ivKEJm56_7zfnx-n7ZdKCbV1k_gVY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Will',
        lastName: 'Hunter',
        emails: ['whunter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFAPLaVluwngA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724959758121?e=2147483647&v=beta&t=ippY6g8HZCRRMV9XeuL9O1zYtjChHcJ6HJgikmnv0gU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ryan',
        lastName: 'Ouyang',
        emails: ['rouyang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHBmfIBnaG42w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695267614466?e=2147483647&v=beta&t=cPZ3N8eV4R9VCSY0YBBJG7Yyp02-Kqia0orI4WXxBR0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christopher',
        lastName: 'Lee',
        emails: ['clee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEdl0wtqWR5WA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693750002053?e=2147483647&v=beta&t=jkX-kPCwxq-ZAtTiThDwbRqeO8eY53twGpERms2QQ7g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Austin',
        lastName: 'Marchese',
        emails: ['amarchese@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFh-N_ZBGUfwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694389318292?e=2147483647&v=beta&t=V97z2UAokVfEn8XfznBPVwbdYc7EbuEno5vYwOm9prQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  joindistrict: {
    name: 'District',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Ma',
        emails: ['mma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHPxbPPglN5Iw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549390644747?e=2147483647&v=beta&t=Z8mOiQ7OInEAS-lt0EPRqrHxnxNa3YnrswUblAs_Q2g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Drake',
        emails: ['rdrake@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHZzdbwAfBZNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517721814092?e=2147483647&v=beta&t=YM82ou3MXEz1qY6-WlIRaU3Bih8UurhJrzk7r0WrOH4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erin Boothe',
        lastName: 'Lenart',
        emails: ['elenart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF8BgX0u6TDMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614376422333?e=2147483647&v=beta&t=dOW9h-uZhTU9Cav0W5_JNu3h4FS9rXJnKexHbJ4VgYE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gregory',
        lastName: 'Bagby',
        emails: ['gbagby@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sara',
        lastName: 'Alepin',
        emails: ['salepin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mile: {
    name: 'MILE',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joe',
        lastName: 'Wilkinson',
        emails: ['jwilkinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFi0x0dah6DBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666220619292?e=2147483647&v=beta&t=hEj-dlj6aAwULVefzrGMUJaJQIUgZkSg5zma0jZn1Gk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Scott',
        lastName: 'Woolfolk',
        emails: ['swoolfolk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE1C9zgCNHv3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548890119575?e=2147483647&v=beta&t=n55fncIJicba-2oEYfcHPNxWyL0m9cuuuBROVjWIxZg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joseph',
        lastName: 'Mile',
        emails: ['jmile@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGEBViug49CJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602358664751?e=2147483647&v=beta&t=Y7m7C19QYCZFXe92TAm5HyOMVo6YW33aT06FiBakVLo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'BJ',
        lastName: 'Walker',
        emails: ['bwalker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE4Klsa97QIbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563925046377?e=2147483647&v=beta&t=Ce_0SmM51_Me-Jtxmpa1kqSiHqcDqIx9LfqRlCUV-c4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sr',
        lastName: 'Suraj',
        emails: ['ssuraj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF1akq9lfZCzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704215609712?e=2147483647&v=beta&t=okQ_XTF9szaLJLTsfItH7RzmYCutXr7rpUTJOfbyBQQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  goodpour: {
    name: 'The Good Pour',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bree',
        lastName: 'Watson',
        emails: ['bwatson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGsQrWFNAmSwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661879137731?e=2147483647&v=beta&t=1oBgD3bkU-UgkUOuDMlQltLsY5ez76IVmSuzY0z59ow',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Susan',
        lastName: 'Boucher',
        emails: ['sboucher@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ron',
        lastName: 'Boucher',
        emails: ['rboucher@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jigz',
        lastName: 'Patel',
        emails: ['jpatel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFU88fPo54MqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694722186582?e=2147483647&v=beta&t=BYiSwGCAmJwhHR4j2f38D3ffk4AnAcFgEz8SkW-Yrps',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ray',
        lastName: 'Horal',
        emails: ['rhoral@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE08eKLV0bsOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643802038481?e=2147483647&v=beta&t=GsHSLWKvdR9zFAkBthRNcvAkOGZfmKt_CCMS7E6aV9g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  synnada: {
    name: 'Synnada',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mehmet Ozan Kabak,',
        lastName: 'Ph.D.',
        emails: ['mph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHOS6TkK8x6kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1640011535417?e=2147483647&v=beta&t=rR8Y_sdewPG30N2dKTi7JQU7WrlxB-aCMcY5YZ-GJoY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sami',
        lastName: 'Tandogdu',
        emails: ['standogdu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHhdxAzCgiQHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683222801373?e=2147483647&v=beta&t=rtrcCsIN3YlMEaRbAmy82-D9MHTIehLpzgV3wrEhUl8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pinar',
        lastName: 'Bayata',
        emails: ['pbayata@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mehmet Oktay',
        lastName: 'Özsoy',
        emails: ['mözsoy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHlDJvHojNPFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656165429371?e=2147483647&v=beta&t=P0-7Poll5ue04US_Rkh-UpVkrjFQyAzMdCAZkeVqWXg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Barış',
        lastName: 'Kaya',
        emails: ['bkaya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFYKJAPEU8Frg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673960931794?e=2147483647&v=beta&t=j1DQa4Bx41FmP5gbtuF0l0QfBpFXFU2khef2ss3mtFk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sellermate: {
    name: 'SellerMate.AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Akash',
        lastName: 'Singh',
        emails: ['asingh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Avinash',
        lastName: 'Saproo',
        emails: ['asaproo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEUudSGx3s8IA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594055099231?e=2147483647&v=beta&t=k_A0HI_dmUVd1AnWKe5G5H7QPbyY8R-kSp5Ww1g5FUI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Navya',
        lastName: 'Kuthati',
        emails: ['nkuthati@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mousam',
        lastName: 'Narula',
        emails: ['mnarula@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF71ovv2s6cYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707069962557?e=2147483647&v=beta&t=E7T9hJRgxRtmJeZJ8RR_huvjc1w5SVQ6Ng9KqHmq5WA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rushikesh',
        lastName: 'Taralekar',
        emails: ['rtaralekar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFwXcT41HExJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708354694113?e=2147483647&v=beta&t=g5LnjaHAbtJSs9xFdUKLNsPfH1XstEVwRk_QSBAK1_k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wholechain: {
    name: 'Wholechain',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jayson',
        lastName: 'Berryhill',
        emails: ['jberryhill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFHeBuQukd89g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517708623777?e=2147483647&v=beta&t=2E4m6i58s81rgCSc9DuXwfTfKcD0E3mf3jHtPXN8iG0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Athena',
        lastName: 'Giani',
        emails: ['agiani@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erin',
        lastName: 'Taylor',
        emails: ['etaylor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGr1jS0VE2y9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563826893100?e=2147483647&v=beta&t=0HKK9L5Wll7dFmYkKdFo2DhHhEVfv63SM7Jt5MX8AMA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Claudia',
        lastName: 'S.',
        emails: ['cs.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Keaton',
        lastName: 'Blovad',
        emails: ['kblovad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEi2WGSvL9yQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715267636700?e=2147483647&v=beta&t=B8S_A3L0bf4TXA7TV7Q_6JngBO1mFRuZTxHyD52S2Gc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  getpow: {
    name: 'Pow',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Selina',
        lastName: 'Cleary',
        emails: ['scleary@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEj4d-CfgI6Uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559663650906?e=2147483647&v=beta&t=nXHZTX5PAtZ7Ky1E5g-OOM5fkmGZxqySQ5h2zAWADwA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Monica',
        lastName: 'So',
        emails: ['mso@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHSANZ9BWdEFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655089784449?e=2147483647&v=beta&t=IMRjTcl6WfGpcQJM0rAFHbV9LjQsVQqe6Q4GQRKvmz0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Luohan',
        lastName: 'Wei',
        emails: ['lwei@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFCX6Ep7BIxMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646945284881?e=2147483647&v=beta&t=FSoN_fdBYxhLjchxlpdML7ghZBRcWr35e1m9mk_9GC8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alberto',
        lastName: 'Capetillo',
        emails: ['acapetillo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEN6bBcjscRRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590765621835?e=2147483647&v=beta&t=bviVnm-DzYuSax_poNhut87eecqyfc-IOsw8Po5IMjc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zoe',
        lastName: 'Shankweiler',
        emails: ['zshankweiler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ie: {
    name: 'Donor Money',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kathy',
        lastName: 'Pan',
        emails: ['kpan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFwIAZlZXiucw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544890155916?e=2147483647&v=beta&t=zwRk5MHL1AzWo3iCBy2uVoBdub1HTr8ZTjVuu8sBuJg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rebecca',
        lastName: 'Lively',
        emails: ['rlively@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFzj4muvLwHUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575383913533?e=2147483647&v=beta&t=En2ZlR7yBGlXV_eum5RZonS_rm_ul_VF14K6KxejS7s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tracy',
        lastName: 'Wang',
        emails: ['twang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gary',
        lastName: 'Ireland',
        emails: ['gireland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHCMD824Kb7MQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516814312324?e=2147483647&v=beta&t=n8C4eh6trEHYwhzZxbkORSb20URsdq7eM24cYo3i8MY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ge',
        lastName: 'Zhang',
        emails: ['gzhang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEvelIM1or6Lw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727153838295?e=2147483647&v=beta&t=F1du4C1txVCEZSccnMDpfKs85inCimTctaGmaloW4No',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  joindaba: {
    name: 'Daba',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anthony',
        lastName: 'Miclet',
        emails: ['amiclet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHLhdHz7q3GFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659321753008?e=2147483647&v=beta&t=zVQ9TZG_Fwvt1llD9o1KX1tNRS2anpvmX82S2XGhUwA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Boum III',
        lastName: 'Jr',
        emails: ['bjr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Xolani',
        lastName: 'Ndlovu',
        emails: ['xndlovu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Adama',
        lastName: 'Balde',
        emails: ['abalde@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHiRt-trhvW-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595455774719?e=2147483647&v=beta&t=LCAIrRBFXU8XxeEsUhSG9AckZCFvlSBYi4b31k6SBNo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gelana',
        lastName: 'Daba',
        emails: ['gdaba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  prophetlogic: {
    name: 'ProphetLogic',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Kivlehan',
        emails: ['ckivlehan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEpYLQ1pSsr2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624386126832?e=2147483647&v=beta&t=Q_X7c0RA3g_HYZd3nIsjI36q-z4_oGj-xDOezO_sAT8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cory',
        lastName: 'Bachman',
        emails: ['cbachman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF0CtcuepqhUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637697418557?e=2147483647&v=beta&t=PR89MFT6wwr6BwuXZ_FBX1TGMpBg_RmQNO3nPlsMXDw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marc',
        lastName: 'Mitchell',
        emails: ['mmitchell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hannah',
        lastName: 'Dalton',
        emails: ['hdalton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGwHKKat5tMwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706622670606?e=2147483647&v=beta&t=d4EA_HMVYBPd3QBS2PrMPv9RyEFkwE-dDdubFdTp_8s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amy',
        lastName: 'Bitar',
        emails: ['abitar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFwxOPQDQ0hlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619579008392?e=2147483647&v=beta&t=rBa6wyUKOS5WQj3bDbjiYH7q5uhCfKBcWa5ROsEYEY0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wildbird: {
    name: 'WildBird',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robin Rhys Eddins DC,',
        lastName: 'BCAO',
        emails: ['rbcao@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFvwpPAiF2vuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720598295779?e=2147483647&v=beta&t=EH4tU3JmztkaHAhv0wwl2BuTQgloLxA8rLnuQQEH1g4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Wild',
        emails: ['jwild@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kassidy',
        lastName: 'Bird',
        emails: ['kbird@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH3q2YodcstZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709915487869?e=2147483647&v=beta&t=V3F_aiHBqZOOc5O3yxkQ4bvknjqehYE1V_BuzqI9PMc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Adam',
        lastName: 'Bird',
        emails: ['abird@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGrqXnNA5cIkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708540684211?e=2147483647&v=beta&t=jvX19vKd6abO2nT3NVt9DMCuEUV7007aEc4Cs5_zoWA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Damien',
        lastName: 'Bird',
        emails: ['dbird@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFNilIKK5FOUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636097933652?e=2147483647&v=beta&t=lW6ZqzU3ChUPA3ywyTIRvkv-hPXCMqoVW3-de-SsyQc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pstgm: {
    name: 'Postgame, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Danny',
        lastName: 'Morrissey',
        emails: ['dmorrissey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHHjyfFwgtxXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682989685848?e=2147483647&v=beta&t=NjOhCUK3Ssq1o4lbSQ0zEyY5K-4COsrMLDkhWhn7_nM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Peyton',
        lastName: 'Jula',
        emails: ['pjula@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG8g5B80Rw90g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721679031966?e=2147483647&v=beta&t=xYnochWyFCztdiUIJpR5eozDJ4P15JsJC7QLFKqGKLk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jake',
        lastName: 'Taraska',
        emails: ['jtaraska@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aaron',
        lastName: 'Hackett',
        emails: ['ahackett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGxaBsuwKRmxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718824333626?e=2147483647&v=beta&t=BJmD6wl0q_gji3VHrNd4BLPAO7EQoxhi70Z8Aa-PQdM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shelby',
        lastName: 'Banks',
        emails: ['sbanks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH_CuhtTQlwxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645370729882?e=2147483647&v=beta&t=NANTrx98Sfhst0JewldLK_1i06JDhvJnNJhqJGjjMT8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rensair: {
    name: 'Rensair',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christian',
        lastName: 'Hendriksen',
        emails: ['chendriksen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGFWKZ6dlwEXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707388364094?e=2147483647&v=beta&t=_hGJSXLfb-zHW8toQVf2yAB12jXWXWDfSWfTAdfoiz4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Frederik',
        lastName: 'Hendriksen',
        emails: ['fhendriksen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH3qOsvdBKTVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567774201205?e=2147483647&v=beta&t=TcOU1DL8M1JrOmXxa9casI6IWDe2LJcMCe8spvarN3w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christian',
        lastName: 'Hebenstreit',
        emails: ['chebenstreit@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Simon',
        lastName: 'Harris',
        emails: ['sharris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHLBSm_jB0HTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727866920049?e=2147483647&v=beta&t=v_iZ8mnlzbViH7Txl7GzQykIROqPRCNsMehLjdDCBQ4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Allaoui',
        emails: ['pallaoui@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQExo6YIwjGVHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644837219884?e=2147483647&v=beta&t=uKCCwN7aW-XOMSdqmbIu5FGk1wB0YTl8PK4BxFVekRY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cyberspatial: {
    name: 'Cyberspatial',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ricky',
        lastName: 'Tan',
        emails: ['rtan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Fulton',
        emails: ['dfulton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG8l9q0Lh0l0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686578956675?e=2147483647&v=beta&t=jRmaF5NHEOzLO9qOYrUwL2Aoegi8-41TSMCAmOnzlKU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Steve',
        lastName: 'Henderson',
        emails: ['shenderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEB0_EVEg7AYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655124476754?e=2147483647&v=beta&t=eFb6YwIQm9iOrTuLTvyzP4rHIQjy4U7hOnbAYrVfaGw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Charles',
        lastName: 'Wu',
        emails: ['cwu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGNF0SZo7SBcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673373829678?e=2147483647&v=beta&t=-GvFZXBY48bh6ZNOo8d12L2ra5lNpDH8lQJmKDpZMa0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aubrey',
        lastName: 'Falconer',
        emails: ['afalconer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFNwYY0Fdd8Bg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573712267254?e=2147483647&v=beta&t=S6m-KoVOZqdUxXYSwL6ujy66OxDIysymd4a1rRxzkEc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  letmedoit: {
    name: 'Let Me Do It',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Leeba',
        lastName: 'AB',
        emails: ['lab@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHRFUTHYDO2QQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633767952949?e=2147483647&v=beta&t=G0OldQsSmZKjhzHccwVTt_a0wKqBWBylm61dNOh1dzg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anandhu S',
        lastName: 'Kumar',
        emails: ['akumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEN6Z0WUtVYFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621851058915?e=2147483647&v=beta&t=2Mv0iz01fq_F7NGo-6Awij9fYJCRVU-EF9AcaUkg72k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'alka',
        lastName: 'suresh',
        emails: ['asuresh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFI7A1fzvr_CQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666847301419?e=2147483647&v=beta&t=lniz1Fg5wFkmEQfTvdZuR_MjPqIWTredXIUgXGm40z4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jithin',
        lastName: 'E',
        emails: ['je@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHKrvxCQCVY7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633842154316?e=2147483647&v=beta&t=Pu-Yk7YH1l7-a9QmuKdjlT3HRUPO2OK4JWMNp0GEyWI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Milu',
        lastName: 'Siby',
        emails: ['msiby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHn5G-qStGW6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719021378232?e=2147483647&v=beta&t=vb04vKb2RyYnuSov_mNpCgPYGqL7tsTx1DtrwmYdbEI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  livetheresidency: {
    name: 'The Residency',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jace',
        lastName: 'ECAj',
        emails: ['jecaj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEwNu48Iqwv8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548284039704?e=2147483647&v=beta&t=gBpL3W4BkrfcLgMg-XoJ1rTanih4QgpfsRodUFj4hZQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Julia',
        lastName: 'Silva',
        emails: ['jsilva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH38xgBEnLtxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516860579145?e=2147483647&v=beta&t=kq62zG6xGYfSHwt6rHdtfM9e7UoA6y5cLRv1dA3_6eg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Meli',
        lastName: 'Darby',
        emails: ['mdarby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEsaYdQeyygVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657756972814?e=2147483647&v=beta&t=REFxJMyyeCLoAlcv2tITGtuWjSmifnh2zFyIZ0Kk7ks',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lynne',
        lastName: 'Bateson',
        emails: ['lbateson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEBO4a3Gy7Yag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707155558230?e=2147483647&v=beta&t=AzNBCF34CnB-uXxttI1WuMQyyPqAN9OFglQi2yLXJgY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kelly',
        lastName: 'Allen',
        emails: ['kallen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFPtL_1JAmKdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517269665854?e=2147483647&v=beta&t=mRD5mk0GStZ1_ReCH6ulWj-QDhqPpww4MpBL4rB-J0Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  studentathletenil: {
    name: 'Student Athlete NIL',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jason',
        lastName: 'Belzer',
        emails: ['jbelzer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEBc7dXaTvorQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691175292042?e=2147483647&v=beta&t=u9Ied10ahjHEhIOe630tbFWchYJXt5fIwDYWwGXxmCE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Lumpkin',
        emails: ['mlumpkin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Brown',
        emails: ['cbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHQjKKimarmwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592250186653?e=2147483647&v=beta&t=QSLCV4Ym-MKNylAngWjhNuK3kHT-MknzkkY6aPr0X3k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brianna',
        lastName: "O'Malley",
        emails: ["bo'malley@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHGrWYh7FddDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1538016922735?e=2147483647&v=beta&t=nFl3_reKRjWs0Ck5h4oJ0ciOd7nTtqWM0FD-Y4pSl0g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pat',
        lastName: 'Finn',
        emails: ['pfinn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFArPeaApMH3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695330496326?e=2147483647&v=beta&t=_mEG7_rGWxEZQ_3Zp-eNvYhAbbPN4_zQpf9o2ce714c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rollemup: {
    name: 'Roll Em Up Taquitos',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ryan',
        lastName: 'U.',
        emails: ['ru.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFCFqmkX4aacg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684949763012?e=2147483647&v=beta&t=xO3TRFPHrLCVYfMs8xF8w0FooQLR8tjF2TvPw-XlzGM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nassri',
        lastName: 'Jaber',
        emails: ['njaber@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Adam',
        lastName: 'Owens',
        emails: ['aowens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGmuhCqtdBzrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610731097157?e=2147483647&v=beta&t=-Xpk9zmlmoKRPLrClmUni9MtPpqexNc7JGpnOeeH59E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Morgan',
        lastName: 'Cappadonna',
        emails: ['mcappadonna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEFUWa8EW1CcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695402575264?e=2147483647&v=beta&t=bUrvdiDw-MksD2LvZcZjbgo9v6vWZRm6I6UNekeu8MQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gregory',
        lastName: 'Ohnersorgen',
        emails: ['gohnersorgen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  alkemehealth: {
    name: 'Alkeme Health',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ryan',
        lastName: 'Mundy',
        emails: ['rmundy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHslOJNGJXalQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620841748636?e=2147483647&v=beta&t=SufhnwdRuH_R_nF1YIaWOkES4oraQSPWL0YWWegYFcg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Neechi Mosha,',
        lastName: 'MD',
        emails: ['nmd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Torian',
        lastName: 'Robinson',
        emails: ['trobinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHJcVaN_l0Xdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614635257864?e=2147483647&v=beta&t=ErxneEfPlf5yrAA5uvRzZvYPxS2Mnw_jTDxLw8Rn1xA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nishta',
        lastName: 'Giallorenzo',
        emails: ['ngiallorenzo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Walker',
        lastName: 'Hale',
        emails: ['whale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFsO6wiVXprIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664388641520?e=2147483647&v=beta&t=KrIqTRg3VXBoIcLPIFegCDPqOdZcfLLdvyaOQxppUO4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  captivatetalent: {
    name: 'Captivate Talent',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jay',
        lastName: 'Desai',
        emails: ['jdesai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHHK1QWQ3rIwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699916717218?e=2147483647&v=beta&t=rY_-NvgU415XkIjLhHdUvMYALqjKiCgm0KoxHpG4ptk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Christopher',
        lastName: 'Gannon',
        emails: ['cgannon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Casey',
        lastName: 'Erickson',
        emails: ['cerickson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE9x-cZuFoNrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659631229937?e=2147483647&v=beta&t=PefjniWvOYAdzcACgRhTftwukGVBYP7VJpUbndJ5c2s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tia',
        lastName: 'Cianflone',
        emails: ['tcianflone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGxTghv7f1G-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707318017743?e=2147483647&v=beta&t=rHCopgtLyWnP-n7Tb6gRZ9nRcEyRmScgbfCBJXJ0Ypc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kelsey',
        lastName: 'Mooney',
        emails: ['kmooney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGMp6auyMhjcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660245872852?e=2147483647&v=beta&t=MA4alVrOgNHg0VTmk_qEQvX3U91XF4805LY2SfZHbwE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lattitude: {
    name: 'LATTITUDE VENTURES',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tiq',
        lastName: 'Chapa',
        emails: ['tchapa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH3cxw9tHjypg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641656482256?e=2147483647&v=beta&t=0uCkSfKAFbyYpcM5EQ7AgkiHTXUFCfwktW6H9gRpskY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Laura',
        lastName: 'Lucas',
        emails: ['llucas@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Fabián',
        lastName: 'Ronisky',
        emails: ['fronisky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFoC-NMX4BCig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633625613449?e=2147483647&v=beta&t=04ycVslFKAT03z7R-mN1rgLMyT7AeyL_fGonAYMgAzw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cecilia',
        lastName: 'Sanchez',
        emails: ['csanchez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kennie',
        lastName: 'Blanco',
        emails: ['kblanco@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kovocredit: {
    name: 'Kovo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nimit',
        lastName: 'Jain',
        emails: ['njain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFwdaI1Lze1rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516338745672?e=2147483647&v=beta&t=LSfiCM1bEZsCT4UxvuNy2RGFRDiDn_gLMdx4v86Nqag',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kova',
        lastName: 'Kolondzic',
        emails: ['kkolondzic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGAfqodHbA6-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1545325569315?e=2147483647&v=beta&t=4uE_eeVhOFq2c1sn2Z0eGkwrExQZHq4p4B6X4EeTFbk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Charles',
        lastName: 'Li',
        emails: ['cli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEsZiQSLVVu6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517487825185?e=2147483647&v=beta&t=DDCbon6Ihn1msjWk9TcxmK83Q-ajWHXiYoHbdIFefMg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nika',
        lastName: 'Kova',
        emails: ['nkova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFXN_c9ZRMPCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516359875677?e=2147483647&v=beta&t=eZtDPGQ05Vt1nLADg-Bi1rgXq0m_d3YdxpAOegmVY7c',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elan',
        lastName: 'Kovo',
        emails: ['ekovo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHetPyfyVDfEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1523477433121?e=2147483647&v=beta&t=-5UDCJNc0SvYKeUfi9J6wKzU8jPnDdk4hfWzlRfEp20',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bottomlineconstruction: {
    name: 'Bottomline Construction Services, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kyle',
        lastName: 'Daigle',
        emails: ['kdaigle@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Zack',
        lastName: 'Gaines',
        emails: ['zgaines@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFbVlFkfI5Vhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679842438819?e=2147483647&v=beta&t=ejCyTfomG5jQu6SfNL7FgnDCn3WBLWtcriNAJPGLSs0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Givens',
        emails: ['mgivens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGwVD2sZ-I9Kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707326495306?e=2147483647&v=beta&t=CPpdhlk70lMnQNGPGNDLx9OE9qAeUhkMl0uULsUsMLY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Austin',
        lastName: 'Flachbarth',
        emails: ['aflachbarth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFQOyX3w-blEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663612115702?e=2147483647&v=beta&t=z9NNv1j1e-SLcJAG59YVUGJ6GMzLtYBdkFCmXR7FMd8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Darin',
        lastName: 'Brenders',
        emails: ['dbrenders@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQErF-vBVnpsgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516993499913?e=2147483647&v=beta&t=dIYriGp_FmX52eBg1tNRFXd327z7ZCvqIhueiwiHNYM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eventnoire: {
    name: 'Eventnoire',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hameed',
        lastName: 'Bello',
        emails: ['hbello@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Simone',
        lastName: 'Johnson',
        emails: ['sjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFrmxAsWyI40w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692459592256?e=2147483647&v=beta&t=BXoZDBRcypFa0_VmNlSmMZG2gKZrAszg6h4mvYYedPA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeffrey',
        lastName: 'Osuji',
        emails: ['josuji@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marquan',
        lastName: 'Windom',
        emails: ['mwindom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHrYVZe2JLK8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713462434654?e=2147483647&v=beta&t=lbY2xUYuzPSxIgjrgygISSucGkofhSOD0bqJW8oUNZY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shameka Jennings, MTA, CMP, DES,',
        lastName: 'CAE',
        emails: ['scae@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEYyWIsmdfIpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602016290764?e=2147483647&v=beta&t=xZqX8-9uXbchHslESoxDwIdgDw5FhofsfpksdqvH41c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  keploy: {
    name: 'Keploy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shubham',
        lastName: 'Jain',
        emails: ['sjain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGrI56B7L5N4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716044710879?e=2147483647&v=beta&t=-T5Bmlua2dB9qJgztL0UmUfpRJ5vbY-pEurZaw_wC_8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shivam Sourav',
        lastName: 'Jha',
        emails: ['sjha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEJrMRZcIUszQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705346650044?e=2147483647&v=beta&t=Ty_b4A29sL9d93e2jpuApX31x1aqjFfU2MOS09Zetnk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sarthak',
        lastName: 'Shyngle',
        emails: ['sshyngle@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tvisha',
        lastName: 'Raji',
        emails: ['traji@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGGzQ_bl_XnyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727383179919?e=2147483647&v=beta&t=OC_mKHIs2Kh9NswjIj7lVQqwz4UxylXZB2BnwXB4Pl0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hermione',
        lastName: 'Dadheech',
        emails: ['hdadheech@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFYwgXpj-Ikxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699554500315?e=2147483647&v=beta&t=7QCES1nzU4ydcCLtYkBoysVyYsYb2TZbRU9IckYDl8g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cultivategeospatial: {
    name: 'Cultivate Geospatial Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tom',
        lastName: 'Brenneman',
        emails: ['tbrenneman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGc6J95fKPtdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517693079236?e=2147483647&v=beta&t=fHEho8WPsLPvYfVmVNII_fZVAcyhXXvfA6w729xsaV8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ashley (Littell) Hitt, GISP,',
        lastName: 'PMP',
        emails: ['apmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH6WibRSbQ4PA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712150657356?e=2147483647&v=beta&t=nuupxmtzr-bei3PbY90EKP8-umkM3qrSbfJVIwo-x2Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chad',
        lastName: 'Kostner',
        emails: ['ckostner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEgrkJHQBiyDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587585034001?e=2147483647&v=beta&t=DI914axnC3a95cyJVVpUJqJlpuUNBjz3Vg_1ILc4-TM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tripp Corbin,',
        lastName: 'GISP',
        emails: ['tgisp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFu6wt5S5EdUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660258329840?e=2147483647&v=beta&t=5oEK97VkwNDW0SprdQ6G2uY4gdHf4F9Q9yHWD_og66s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Thomas',
        lastName: 'Wesp',
        emails: ['twesp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEjE2n_qtcB0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516284745350?e=2147483647&v=beta&t=D1URG_G8ulcHT78lIL6fi1dBeWJL9x2q35nbBLsV1wU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  onelinedesignstudio: {
    name: 'One Line Design Studio',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Susan LaFleur,',
        lastName: 'NCIDQ',
        emails: ['sncidq@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG424cGXTPlHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724115441165?e=2147483647&v=beta&t=0p1B5fRlFIm5Je23lPMFB0e4Xx66dDtdhJT42cTzPKc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Karrie Drinkhahn, NCIDQ, LEED',
        lastName: 'AP',
        emails: ['kap@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHziy6reop51w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724116654251?e=2147483647&v=beta&t=ygDEsG1UP7vzdlYsofhRQCMKwFiQlPYlx42yRzL9lqs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marcy',
        lastName: 'Rice',
        emails: ['mrice@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHMq1r5um-EPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636467251298?e=2147483647&v=beta&t=263PgZvcNoiJWgv_i8WY8FZHdwPl-jTawVMOBOZvaGc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kendall',
        lastName: 'Temotio',
        emails: ['ktemotio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGIiZ6X_V6hlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583208790820?e=2147483647&v=beta&t=y5h3HgMlh5qbB0UbvPO5_QdpQKr5z_fkmJ0ty19arzk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Claudia',
        lastName: 'Bragg',
        emails: ['cbragg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF1OMFSK0y_mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516847262280?e=2147483647&v=beta&t=m1m84iiGS2yVhLjJrnZarrc3hodXsldk8nqx2WUyJPo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  matrice: {
    name: 'Matrice.ai',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amar',
        lastName: 'Krishna',
        emails: ['akrishna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHZdeh-iR-Xuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719380542958?e=2147483647&v=beta&t=AOueVO4dMFtLL6VPvC-bGhF-FXYlkTE7uX3kGQHLdLE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Reda',
        lastName: 'Al',
        emails: ['ral@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFAeg1jufulPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579056714120?e=2147483647&v=beta&t=d6FwPsQgDrJV-oCVuTXqYBi2fYafI-pUu8B7MxKnrzw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ayush',
        lastName: 'Singh',
        emails: ['asingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGmX_mNLbh6dQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718231296187?e=2147483647&v=beta&t=iNbWBZPdvq73LaMfKQfQDcvfXfzr-8SYP8CUH-VMz54',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anush',
        lastName: 'Deokar',
        emails: ['adeokar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH_55U3pqTe4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653224744919?e=2147483647&v=beta&t=cw_QlE-xL-A2dIOl1x8fq7iJ_7JwdgChckGzjF5OBFQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Himadri',
        lastName: 'Pathak',
        emails: ['hpathak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFKgZgPQjXoQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685635138010?e=2147483647&v=beta&t=5mIgZAippgdLjFkQjSndsmhel6HMJhL6I-gpfW7XWlQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  elgruposn: {
    name: 'El Grupo SN - Hospitality',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nathan',
        lastName: 'Leong',
        emails: ['nleong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHQDA_IUp5wDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578364659248?e=2147483647&v=beta&t=608_qjhV_X3AZmFcmIHog-lKnG3gNquJen4cLODDig0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Katie',
        lastName: 'Alden',
        emails: ['kalden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE48bysR0UXQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704841446580?e=2147483647&v=beta&t=4Xrl21HBPmClPOS6HDHspXLdghUhIffCTBrq2ckWweU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Charlotte',
        lastName: 'Zaumeyer',
        emails: ['czaumeyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFwFaGe2J1irA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713980782352?e=2147483647&v=beta&t=r0cKst_qH_iKf6N4OXDAd-F4bMxg-UCXF8EEnAVNM9E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matthew',
        lastName: 'Marsico',
        emails: ['mmarsico@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFnZIz4fVUl4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675616342281?e=2147483647&v=beta&t=UK9Mmu7Nm5w8IyXLun7LiekaDgfnvtgAEGGHXFVppg4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Reyna',
        lastName: 'Ahuja',
        emails: ['rahuja@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHZQTCfbENMIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718833013081?e=2147483647&v=beta&t=oB59GnFgVKbnKvpxZw6xrcOcDlBdgSLW3gcUQKlAdM8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  subrosacyber: {
    name: 'SubRosa',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'McCann',
        emails: ['jmccann@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'P.',
        emails: ['jp.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alexander',
        lastName: 'Pagoulatos',
        emails: ['apagoulatos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF3J5NZWI3Fcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627502901237?e=2147483647&v=beta&t=_TVjiMsgT3XT9a9bGfJmC9pvV6UyYCtMwwfaFFiuppU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jaques',
        lastName: 'Kleynhans',
        emails: ['jkleynhans@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Donovan',
        lastName: 'Phillips',
        emails: ['dphillips@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  thenewprimal: {
    name: 'The New Primal',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Krista',
        lastName: 'Arnold',
        emails: ['karnold@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEzrxuODlB2PA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729782564772?e=2147483647&v=beta&t=-eSRfpBylDc29R9g-a1EIpiHVHG2C4-3czV2aix6w28',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Bonino',
        emails: ['cbonino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGicjAX-93Lfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677615764597?e=2147483647&v=beta&t=n9l4ysZh592jhzAKgWC0p0ojZ_pFE6md376N_xLFyI0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jacob',
        lastName: 'Henriquez',
        emails: ['jhenriquez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGmsuU8rjdbcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619119610835?e=2147483647&v=beta&t=FlT9VR1GjR-lxCz3BKpyUR14n-ftgnbM_Atwm98NrLo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Melissa',
        lastName: 'Miller',
        emails: ['mmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEw1tUBs6Zfmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671216359340?e=2147483647&v=beta&t=Z0qsXUxVzQRFiEkr0PcsntvfHIQLoe0Rf2ZUORw5JWU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jessica',
        lastName: 'Osterberg',
        emails: ['josterberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lexiconlegalcontent: {
    name: 'Lexicon Legal Content',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David Arato,',
        lastName: 'JD',
        emails: ['djd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Victoria',
        lastName: 'Bartolomeo',
        emails: ['vbartolomeo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEIdfYXw00QJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517575402882?e=2147483647&v=beta&t=erh1o-mJHxt_W3n2atDAUWLkNk3Q8PjqqGCqzcyRlSg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cassie',
        lastName: 'Nelson',
        emails: ['cnelson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFGnN0K9YqdNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692975146977?e=2147483647&v=beta&t=cq6BZhrm_wVwxlHG6loXDS2rRHSdTpeKxAxudh2fwKc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jim',
        lastName: 'Vassallo',
        emails: ['jvassallo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGQDq0KKkZXjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516479750997?e=2147483647&v=beta&t=T4jpWBr36UWFyYAQNvFaxMmGuJkf3E_nzvitNU5Fhh8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael R.',
        lastName: 'Martin',
        emails: ['mmartin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF1UVFPlv0UBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674222121093?e=2147483647&v=beta&t=XerYTC-o5etlGGfo5jDHlC1F4XiQKxoD3DQOKbxIQEc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  startupsteroid: {
    name: 'Startup Steroid - Deal Flow SPVs Demo Day Platform',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anshuman',
        lastName: 'Sinha',
        emails: ['asinha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFS1hQMhz4hJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596836096663?e=2147483647&v=beta&t=Oc_7NWfXWVu0gSiGU_7yI6_DwL3eXjHpqBger33bBMk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aniket',
        lastName: 'Sinha',
        emails: ['asinha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGJdwqsIHyBAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677386785691?e=2147483647&v=beta&t=MxySRp-RJl64YMshFqOhixbnXGoN6ysnTeoXZFqdQjo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Krutika',
        lastName: 'Panari',
        emails: ['kpanari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE9DGqOx9f92A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705688668860?e=2147483647&v=beta&t=5OKaAkcaK3RNrbd74NWz1O9YeS6JrEIcRCsqdfF7eYA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abhishek',
        lastName: 'Sisodia',
        emails: ['asisodia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG4P_mjLloLFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705343550723?e=2147483647&v=beta&t=W0Qu5DAdpBpMSi4__cd4jmbitjERYfOsjCAPOPGTQ5M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shubham',
        lastName: 'Shah',
        emails: ['sshah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE3BhUPBtj6hg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643285661496?e=2147483647&v=beta&t=7AYrqVAA-08UUuiDb7FJkt8IvO7IpGPrv7WuW8trGuQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mintlify: {
    name: 'Mintlify',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ronan',
        lastName: 'McCarter',
        emails: ['rmccarter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQENox86EQAgCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718285774430?e=2147483647&v=beta&t=ze5i2Qz3FEex0rEjuuezFpAtMsK8v8Z_sV0wgOree8w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hahnbee',
        lastName: 'Lee',
        emails: ['hlee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHMOTtie_a6OA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719044832518?e=2147483647&v=beta&t=qcJ729m4J8WytHOPszVCd4MKaTvOVhuXhkOn3XvOrME',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Han',
        lastName: 'Wang',
        emails: ['hwang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Aviles',
        emails: ['daviles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGg-xIWqf66kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517370002134?e=2147483647&v=beta&t=GEZycR9fFZ8KKwO-1Ak9c-kgtjK1LAvhL6ZQfQgOuIk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anahita',
        lastName: 'Sahu',
        emails: ['asahu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQECI2gupTx2RQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691201357058?e=2147483647&v=beta&t=3cDpcnNxQqg9pmXPZGdpU0yv6fkcfwShtfisyETuw6o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  exactbuyer: {
    name: 'ExactBuyer',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Edan',
        lastName: 'Krolewicz',
        emails: ['ekrolewicz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQExiDS56SV4Vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573064551605?e=2147483647&v=beta&t=ifijXDPrLQzxFe-8BcuVDsHLEGk8haxOKN0nhAbxmxw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eric',
        lastName: 'LaCava',
        emails: ['elacava@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE68i4MZXthUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642967270160?e=2147483647&v=beta&t=S_BrN4unkS5tlv_Z8KVXXGXrsFBx531yUqbBBvz7_LU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jim',
        lastName: 'Phillips',
        emails: ['jphillips@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQECn8sE8NqgjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729520958343?e=2147483647&v=beta&t=QnDszsZg4-asODcqql5rCfKHVQS3Ak2zpzCU4vsZm58',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aliyah',
        lastName: 'Brown',
        emails: ['abrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE9ZxTQGrx4Iw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619051839617?e=2147483647&v=beta&t=rvqSYx3ZFiPvrD4ja9nDi2GycECvxmp0S8fJXxWzZoI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sharjeel',
        lastName: 'Hassan',
        emails: ['shassan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEaL5A94p1hyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557586158413?e=2147483647&v=beta&t=LcJctmw4wqBkbapekdr_sGAv56dL33CEUHEAM1SHEgI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  socialsnowball: {
    name: 'Social Snowball',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Noah Tucker',
        lastName: '☃️',
        emails: ['n☃️@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE8szhKMN_dVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712024909732?e=2147483647&v=beta&t=7TftF2hBFK1M9z3agus8ZNiHXEdahK8__uBVJd3zfOg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Zohaib Rattu',
        lastName: '☃️',
        emails: ['z☃️@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH2YdCzmCjcyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719558172680?e=2147483647&v=beta&t=wXE4uacUO9yLaypB9yPCiSbDqQAtsQmiwFYsvj5iuGI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nathan',
        lastName: 'Philliber',
        emails: ['nphilliber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFsOJssKCmIsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701288691920?e=2147483647&v=beta&t=Xpb5KV9n2T-ByHgbAnRKDXkH7FfIOsMWOxAk2wfpiI0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jared Holstad',
        lastName: '☃️',
        emails: ['j☃️@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEIaBu0_rRBLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715282921152?e=2147483647&v=beta&t=KEiBMBWR7frQOuwzWeZKBKF1Hi5IvscGRNCdaovaNuo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Geetansh',
        lastName: 'Saini',
        emails: ['gsaini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF71me-1y8G-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689874450761?e=2147483647&v=beta&t=-8zP-CEAuSfo3XcRDg2nVqIWYpyWztpJTNUr92k5ZeI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  activist: {
    name: 'Activist Artists Management',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Liz',
        lastName: 'Norris',
        emails: ['lnorris@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Grace',
        emails: ['dgrace@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE2HVERxF4o1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726249808869?e=2147483647&v=beta&t=fMRBTno6UoYs-HvfP3TCnrF1giY-fJjppPHJaEHAxa8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Ruff',
        emails: ['cruff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG_3ZlZ929Pvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643216132619?e=2147483647&v=beta&t=mUCeTe_F38x-hNnrychGiy6m57fUNTQGkx6Go_8N64w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anna',
        lastName: 'Kolander',
        emails: ['akolander@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF1UzOR7pRXoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601911141817?e=2147483647&v=beta&t=VNZ2hMSSdfpV52kQC11wFoJuxtNvao9M_OIKnmj6Q0Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Caitlin',
        lastName: 'Stone',
        emails: ['cstone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFY5Xs0myyqlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682387994098?e=2147483647&v=beta&t=Z-40b2erPr0dSp-S2dioP51xnjOLJl3K-GM5IiNWego',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  activeraconsulting: {
    name: 'Activera Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jonas',
        lastName: 'Georgsson',
        emails: ['jgeorgsson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHDsgstUUv6xA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706543846237?e=2147483647&v=beta&t=S4fX0D-kVY66_4PovYvpgoCwFmuI4vAyxD1Twax1LCg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tommy',
        lastName: 'Ogden',
        emails: ['togden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGha_OeO2mW7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681135605208?e=2147483647&v=beta&t=dhjDbuTMk0gfD_KvqMTbDhFdN-_MawmDBtxowOGpAv8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aldwin',
        lastName: 'Hardian',
        emails: ['ahardian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGNy7kIxTWIrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601061654296?e=2147483647&v=beta&t=ktgyt-BsvBxi0UqGCXH1SMd4J5lQ3shbwLF2k3MSEw4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Drew',
        lastName: 'Upah',
        emails: ['dupah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFaYo9kd1snsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695993973116?e=2147483647&v=beta&t=K7taN1jDoZHcECXWZp-0sVSIrX34E45NJ06f13t5FPU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stephen D.',
        lastName: 'King',
        emails: ['sking@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  quaeris: {
    name: 'QuaerisAI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rishi',
        lastName: 'Bhatnagar',
        emails: ['rbhatnagar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFb_HFGW14vKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572364985097?e=2147483647&v=beta&t=szmnGNyP0DZPeyP6xPow2ZVvdavI6kWYl-tpLnPCvYI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anya',
        lastName: 'Bhatnagar',
        emails: ['abhatnagar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mike',
        lastName: 'Penny',
        emails: ['mpenny@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHzql2m7TmIOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656348862957?e=2147483647&v=beta&t=uiE7_0F6eCHjheUG8tM9VYWignIdtYeOdTA1yf2wMik',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rishabh',
        lastName: 'Rai',
        emails: ['rrai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGCMoGdNYjMBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667765403520?e=2147483647&v=beta&t=aNVFOVLGnWF3OaL26BBo7-SIrB8xhsj0U45nkmhkoVs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Manish',
        lastName: 'Ballav',
        emails: ['mballav@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGz_koFZ29x3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522391707716?e=2147483647&v=beta&t=vddUrgJ9T9rvbSbprmH7Ah0zLvYPGn1hSmCB_HQBvFE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  usemogul: {
    name: 'Mogul',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'Ponchick',
        emails: ['jponchick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFzVQ3vk2w4YA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516804765257?e=2147483647&v=beta&t=b1tLUALxdmmZGKgK51Y77NNWhBPYlwGJ7KoD0COLqJI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Reza',
        lastName: 'Kashani',
        emails: ['rkashani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGIqrlOromi6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616205138905?e=2147483647&v=beta&t=n7xMNg7KrLQvTS61zPi6dfDMineMwJzCOdKk-_GJbOQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andy',
        lastName: 'Collen',
        emails: ['acollen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGDz1VXg9Rdxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517755638578?e=2147483647&v=beta&t=pwSpspcnQt6VwSt4DGw-zzkJQsCSJu17XjDYncJp_DY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jules',
        lastName: 'Demanet',
        emails: ['jdemanet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHM6rpxyCAgLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657281620367?e=2147483647&v=beta&t=ZLkkub2t_c_Y1KK24WBYVRMx8G3MzZpQj_E9G-U_Q9o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jorge Mario Uribe',
        lastName: 'Martínez',
        emails: ['jmartínez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEiXzSpDFfWlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686257232568?e=2147483647&v=beta&t=4GBMPR5o43fSy0l1o9r_-z6bstIkhFG05mt5v82ZQIw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  investforbetter: {
    name: 'Invest for Better',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Whitney',
        lastName: 'Sattel',
        emails: ['wsattel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHJlP5Gpn_V7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550179921828?e=2147483647&v=beta&t=vWl5LlR3S6iTLs82RvqFp_bvHOj549aAhZDIFOiIgME',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Janine',
        lastName: 'Firpo',
        emails: ['jfirpo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFcZlPAhtLR-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722736906963?e=2147483647&v=beta&t=4COHomilIuovQNcdZH2qNnoxddlA7r3TgbBwK066s3s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Susan',
        lastName: 'Davis',
        emails: ['sdavis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lorelei',
        lastName: 'Cheung',
        emails: ['lcheung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGmc8Kp-C_Wyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657279864604?e=2147483647&v=beta&t=OrdcNFwTRHDlF4A5T6bgDNpytP239S0C7Szm3JBcmsI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jensyn Hallett, CFRE,',
        lastName: 'MS',
        emails: ['jms@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF16GdyMn0qQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609876639383?e=2147483647&v=beta&t=XT9tIx3Oe462FQTLbKtTyconox7Xe15_E903aENFQjM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  govastly: {
    name: 'Vastly',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christina',
        lastName: 'Rosario',
        emails: ['crosario@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFQUX2PcirMpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697205899220?e=2147483647&v=beta&t=TFbWGob6JzT0dY8zpKEHk3ZbIr4hwq2dvFo7fz1fFco',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ming',
        lastName: 'Xiao',
        emails: ['mxiao@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHMT7V8OCo2iA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1562894177913?e=2147483647&v=beta&t=NuqD8mKIMSKoVYPEbvB7WHZFwlw_ooOCpSO0Yi24Jmw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bobby',
        lastName: 'Duffy',
        emails: ['bduffy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Fiene',
        emails: ['jfiene@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Keith',
        lastName: 'M.',
        emails: ['km.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEI1DZ6Pmsq0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517539476466?e=2147483647&v=beta&t=wCDNIab5F9huz1K4MaZSRT9W0eq-TurhQeucg7oJoOE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cannatellibuilders: {
    name: 'Cannatelli Builders Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Edward',
        lastName: 'Cannatelli',
        emails: ['ecannatelli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEmaPd0S-f9dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611840575913?e=2147483647&v=beta&t=Ll39GAlY-VWicMZrPdFId2cpcFB9aApKqboZPEIU1jk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steven E. Tiezzi,',
        lastName: 'AIA',
        emails: ['saia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFxrYHrV62GGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658417722335?e=2147483647&v=beta&t=-L1lmfATera-a9VnSO54YRgJIlzvyWNYX1vk_LwsIyA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pat',
        lastName: 'Paduano',
        emails: ['ppaduano@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH4ijuV39yxcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574447730690?e=2147483647&v=beta&t=Rn0_44xPERS3o-wY8gAHhFqjzkQMJX9X3j__18HQ4EU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Isidro',
        lastName: 'Garcia',
        emails: ['igarcia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHcKERYJsWsWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704407172119?e=2147483647&v=beta&t=yDuElbFjoXsfEuBRk5ARgqncbyCASAo3wPrmii1CKZU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Claire',
        lastName: 'Reeves',
        emails: ['creeves@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGNycx-yUdIQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723034389901?e=2147483647&v=beta&t=0B8J5tHgF7HAV59JkGsyiCm3yLdGqw8XQJW9dx0PNr8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  arcee: {
    name: 'Arcee.ai',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Benedict',
        emails: ['bbenedict@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE1SGnQZBsNKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705940353615?e=2147483647&v=beta&t=K8CWxbvVggmiAIiFSx7i5ZL_lc3ANluUBWCWsjlqV8Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jacob',
        lastName: 'Solawetz',
        emails: ['jsolawetz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'McQuade',
        emails: ['mmcquade@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFAVrMWoj__1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718729649291?e=2147483647&v=beta&t=0mpTViOlp_6FMMTq1G9HP56neZJdR7Ao5da05I0cWXs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mary',
        lastName: 'MacCarthy',
        emails: ['mmaccarthy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQENo7XEKM-6CQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684814723639?e=2147483647&v=beta&t=LcFLENi7rUs0fZTDj8lUzPSLYdUpX6EJj869lrylMCg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dino',
        lastName: 'Lamela',
        emails: ['dlamela@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGFLCXVJ7-8Zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726519999217?e=2147483647&v=beta&t=1eK0ERekCfcrAKYAMss63_yPz1Voi67f5f6eslCe6BY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ccube: {
    name: 'Ccube',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Karthikeyan',
        lastName: 'Dhamodaran',
        emails: ['kdhamodaran@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Karan',
        lastName: 'Kukreja',
        emails: ['kkukreja@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHO6AeyzN0SOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517695606821?e=2147483647&v=beta&t=Mcl7TwnanY0qTQJVHVprqg_2fOrPh5Zzk8CIV8gNmfs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rahul',
        lastName: 'Tiwari',
        emails: ['rtiwari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEfWJiiaQardg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698433996728?e=2147483647&v=beta&t=kkFol4KJQdSsYoZR8sVEo4o8-4EPL1IOB7nCmTBYpbM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mahesh',
        lastName: 'Lalwani',
        emails: ['mlalwani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFrk3pQK2BL3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713122500244?e=2147483647&v=beta&t=3GY15P0OJHKsIgm614lAoLD5ApVXodDJpq2S5jexAu8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eddie',
        lastName: 'Darac',
        emails: ['edarac@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEXSANacdSEJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702925078591?e=2147483647&v=beta&t=q-hHerZM56_USKG8kTuY-5urbroFYwBPbD2CCRhcUI0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mosaik: {
    name: 'Mosaik',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rachel',
        lastName: 'Knepp',
        emails: ['rknepp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEowhgeu2mEmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648000861548?e=2147483647&v=beta&t=u94URMowkZ_AreEghr1mg4WJs_nNBh6T6K813wYlMbk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marcus',
        lastName: 'Cobb',
        emails: ['mcobb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFsPNHQZ6ljwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517661139099?e=2147483647&v=beta&t=u-WwJoiKaQOEgF3SzmtY8ULF6ae8Bm1SzPwV5sSVHQM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Véro',
        lastName: 'Aloe',
        emails: ['valoe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFb87n2fhcmrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682112975030?e=2147483647&v=beta&t=N_pt3k1Z228Ucsra4iQ5zQYibIe55APvEqhvaaN0ths',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Madeline',
        lastName: 'Castaneda',
        emails: ['mcastaneda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEdfvjRaZVHyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579580252445?e=2147483647&v=beta&t=FyMkJ_T1EC1F0LuvoLb-lVso6ldNIlWObv-HPoyB2uk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daniel',
        lastName: 'Rosales',
        emails: ['drosales@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH4EyzzK6vbjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517668883924?e=2147483647&v=beta&t=0_uiSKkvDKRltWZ8iFRSxhx5DMfHFC43ceoT-iYTwEs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  framework: {
    name: 'Framework Ventures',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Anderson',
        emails: ['manderson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vance',
        lastName: 'Spencer',
        emails: ['vspencer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHMNpi84yWpng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593734394538?e=2147483647&v=beta&t=JgIUQGvHtmp7gBXosRNznfIGel48qF9NH4jchfgZw00',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Roy',
        lastName: 'Learner',
        emails: ['rlearner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGhKePKhazepQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517472548099?e=2147483647&v=beta&t=IS9bcpwn97Gt24_09PlB9okHXtRwH_hYRIxIZqsl5CE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tracy',
        lastName: 'Wang',
        emails: ['twang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brandon',
        lastName: 'Potts',
        emails: ['bpotts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF0okC3EJpO2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690414472749?e=2147483647&v=beta&t=E7ODJdo0jpYVx9atTpmwQik20gBWo1K9jpWIbja8qf4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  smbfranchising: {
    name: 'SMB Franchise Advisors',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Luciani',
        emails: ['bluciani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQED0QRVP_3bCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707836592224?e=2147483647&v=beta&t=3R0Za377gVBKAphy-AKtPy-ttu2pAoZ2Rb2Fqyiy9-w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steve',
        lastName: 'Beagelman',
        emails: ['sbeagelman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dawn Abbamondi',
        lastName: '(CFE)',
        emails: ['d(cfe)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHiGHSfhyqJXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707567168074?e=2147483647&v=beta&t=gvDaDu_-LQkqSckNAjTyRmFD1EN1SDNg0QUgvGPFA_I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kenneth Moran',
        lastName: '(CFE)',
        emails: ['k(cfe)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGiFgP3gAEgIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516366502869?e=2147483647&v=beta&t=qPNjB1125sk93jJllpAGqaHyo94A0V-UCv_fB3aNXYU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shannon Wilburn,',
        lastName: 'CFE',
        emails: ['scfe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG7Sg2XC_yLOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703533984569?e=2147483647&v=beta&t=pQ5KSOq3gzQcvbrMhg2RaEELlBykkWVcZDU-aFdBowE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  secondtalent: {
    name: 'Second Talent',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chan',
        lastName: 'Elton',
        emails: ['celton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eric',
        lastName: 'Cheng',
        emails: ['echeng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHTOce7M0FDIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688956966463?e=2147483647&v=beta&t=812xmcA8k5jat103EH5NtaGRXvHhluFwDgBSr7pgUXE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jack',
        lastName: 'Ng',
        emails: ['jng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGUmSmkMZWjdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672839445290?e=2147483647&v=beta&t=p56DQmQceVtRIK_ex_UAC-ZLCt5iVKK0uS6cv8tF2u4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tara',
        lastName: 'Madden',
        emails: ['tmadden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGjlzhhXu3FBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600358122641?e=2147483647&v=beta&t=78K1l_gmXlmYAwye0Ei4aegnBn4e1EYrJB6vMW6CBlw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christine Kozak CSP, Talent Specialist',
        lastName: 'II',
        emails: ['cii@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFNISKM9E9HXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516850884830?e=2147483647&v=beta&t=VYCautU2wYygf9AKYxLfh_lbSiifyYtSip1xk3v4FHY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  luckandco: {
    name: 'Luck Co Email for Ecommerce',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kasey',
        lastName: 'Luck',
        emails: ['kluck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQETYMAeVOQH4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618956491198?e=2147483647&v=beta&t=4zEniBrBCWLu1OYLefo1Si4IhvoJ8R3yeFT8-rnyj_s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michelle',
        lastName: 'Kleiman',
        emails: ['mkleiman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHir41sIkiveg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669932819629?e=2147483647&v=beta&t=KDjRebBynaF55ZoDf0FrfPP3gGRqFwX8ehuOqjCswMs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Nikola M. – Managing Director – Luck & Co | Email for Ecommerce',
        lastName: '...',
        emails: ['n...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE-6XlnIbfsRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583713331749?e=2147483647&v=beta&t=pDPAYs4RdBRfIuuDOJzsr0DIug1UIosJh76b8Kr-8ys',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ian',
        lastName: 'Luck',
        emails: ['iluck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG4cuP7PRfgpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676054440506?e=2147483647&v=beta&t=Jv8vwy7jTAi0VBWl6vBnnp_wfXWLRr0iBnn3gBzSyKM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hannah',
        lastName: 'Luck',
        emails: ['hluck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFUBIrmQg1uiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517457251091?e=2147483647&v=beta&t=jI7NzIO4LdQGDJpfhl6EPQtUnb7OdPCTAAvMn-BGue4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  relentlessDigital: {
    name: 'Relentless Digital Marketing Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Josh',
        lastName: 'Crouch',
        emails: ['jcrouch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHC7IEpxvpB-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726944069822?e=2147483647&v=beta&t=bSFGfuHDPY-QDFoW4Dn7FmC4_tXR9W3smSmh3AZ6HjQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lisa',
        lastName: 'Watson',
        emails: ['lwatson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGvI-WIFGGiLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715640968621?e=2147483647&v=beta&t=IOu1xLB2pZSkC39Eo5sTDB7gxQ4AtSaYskraZkRg5H8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brittany',
        lastName: 'Mcgovern',
        emails: ['bmcgovern@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGtCSLrTEh4DQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553891241838?e=2147483647&v=beta&t=SoqGCjYksVafIFlujsb_PnUiEe41ZJI0PdHH5GvaUhA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Manny',
        lastName: 'Simone',
        emails: ['msimone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQELHptuKWArew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555525541349?e=2147483647&v=beta&t=qaOq2cgrqcYTbekUypVVu30rzbhFbR1f07i2eoAdAe8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dawn',
        lastName: 'Gaddy',
        emails: ['dgaddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEHDKB16PbFQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724511899150?e=2147483647&v=beta&t=MlsTSkw5hA0Pwe5EVpz1rnZR6pPqfkZe6EyrRsJuzkU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hydr8: {
    name: 'HYDR8',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amy',
        lastName: 'McNally',
        emails: ['amcnally@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFriqNP0BMELw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729746460772?e=2147483647&v=beta&t=Gght-sKXyKu6DSw30bAs6Hx-xaTlEhpB9gKh9ZcKygA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bryan',
        lastName: 'Daniels',
        emails: ['bdaniels@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE4zbAzriiKJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588029527108?e=2147483647&v=beta&t=qa9bL2Wl_lBDgyFTi-mYTNnewwhs7iSPwchN71tnSto',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'barbara igbe',
        lastName: 'adanu',
        emails: ['badanu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sean',
        lastName: 'Kennelly',
        emails: ['skennelly@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michelle',
        lastName: 'Thyne',
        emails: ['mthyne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEkHCP2mFWZBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556502736070?e=2147483647&v=beta&t=tDmdIRuFIu0BFcK1AElQ-W0-0DXLvXKBHpxgwokY37w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  planetcents: {
    name: 'Planet Cents, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Leslie',
        lastName: 'Sheridan',
        emails: ['lsheridan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHuMBMFAMZyyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654187234427?e=2147483647&v=beta&t=m98ugujz4fxF8oGQY3V3DCnS-0zD1eTXoKLlpz5V8HA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Thomas',
        lastName: 'Booth',
        emails: ['tbooth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGBFLpvbTvJPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704815297492?e=2147483647&v=beta&t=gOQ9fHbKDPaexunBDi2aDTrFbMA82_-0QRHMv_3wGww',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daniela',
        lastName: 'Rodriguez',
        emails: ['drodriguez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGPJyyVqua_2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659133930263?e=2147483647&v=beta&t=M6C0yHJ0Qbu7KTW3IquLiyBLHcCwVDlo01_ARH3SvIg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sobia',
        lastName: 'Rehan',
        emails: ['srehan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHiCqwOu0XbGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689865072100?e=2147483647&v=beta&t=53HzljdWPbqw82KUyQtmrC4_TcSlu_9IgpyW3QRscIU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Xinhe',
        lastName: 'Wang',
        emails: ['xwang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGxdoHCFHgj2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692957409540?e=2147483647&v=beta&t=4mV35HGvXkPFntZ9yyHHeGIpts9FFck2GcO4eUfvYr8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  winstonlaw: {
    name: 'Winston Law Firm',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeannie',
        lastName: 'Winston',
        emails: ['jwinston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEiDtqwlBzr2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516430231559?e=2147483647&v=beta&t=iCgwAzKfXJenXDA9Ua0qwMkrbn1x2uc06ewb4AZcLnk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sylvia',
        lastName: 'James',
        emails: ['sjames@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF_Q3Rpi3a_Fg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517682338158?e=2147483647&v=beta&t=gkv97RysaPNbINEzspp7pWiwXIZIXN3YZlsI1fnzWMY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michelle',
        lastName: 'Toro',
        emails: ['mtoro@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jamie',
        lastName: 'Dean',
        emails: ['jdean@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQENAUsUdIjT_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626227024237?e=2147483647&v=beta&t=D1-BL6ymqQyE9KrItUAzKrz7_WnXxzEbX0o5jZY3iCA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Winston',
        lastName: 'Weinberg',
        emails: ['wweinberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEObLR238UaPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705344816154?e=2147483647&v=beta&t=yAV5cEvL3cfgbIwAdguy9Plhk66xso7M2-1vEzzMomI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  adster: {
    name: 'Adster',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Girish Vishwanath',
        lastName: 'C',
        emails: ['gc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGl2ciHFDfVxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680514685843?e=2147483647&v=beta&t=zx8Yzo1LzavFtS6G0okHYOnAVZJ9Wuw1IqegxCCHFEg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rajiv',
        lastName: 'Kumaar',
        emails: ['rkumaar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHvujW9xc02Lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624038495345?e=2147483647&v=beta&t=7wTbsSfPtd34OiSImC6iOERKEwLPhWlQ3QtGd5QN59A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ketan',
        lastName: 'Chandak',
        emails: ['kchandak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG5y8RgZsBoMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1533054005254?e=2147483647&v=beta&t=W8B4uRsWbyY-ReQEu3dTRa_2NDScyoSdLNLMVnAjJfg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sam',
        lastName: 'Bell',
        emails: ['sbell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE05Y7AlotU0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516372227798?e=2147483647&v=beta&t=4qaTeiglMTQfFMYac6bD3fotaIbaoVJIYVy4udrCdhY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amarnath',
        lastName: 'Bapu',
        emails: ['abapu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vitalize: {
    name: 'Vitalize Care',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Veeraj',
        lastName: 'Shah',
        emails: ['vshah@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nikhil',
        lastName: "D'Souza",
        emails: ["nd'souza@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFkt3FBn8zOEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677550110822?e=2147483647&v=beta&t=ONKPX1X88iD5oAoJqtnMDqiZuaGQcEo_7ze6pRbBakU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanketh',
        lastName: 'Andhavarapu',
        emails: ['sandhavarapu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFGsdOzIe5XdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678946939335?e=2147483647&v=beta&t=_gV7PoWB91Hrtig4Bhn4qp1vJd6yj2Ub4AoEuwnqlc0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Javen',
        lastName: 'Ho',
        emails: ['jho@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tanner',
        lastName: 'S.',
        emails: ['ts.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG3g-i05vC7AA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623947388269?e=2147483647&v=beta&t=4RrTrOQXinpGU1c2leryNKM5kkUBCqnULgKQRHfSgxI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  midnightmarketing: {
    name: 'Midnight Marketing',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tim',
        lastName: 'Taylor',
        emails: ['ttaylor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHzzkVbtktU-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724204356377?e=2147483647&v=beta&t=3GulON7Wx75aITnVKG-WRONVIrHFeAP6_Y4asgas9Zk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rob',
        lastName: 'Strickland',
        emails: ['rstrickland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH08SDSL2wuiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516519313685?e=2147483647&v=beta&t=VtrAtac4MIW0pcuTJQOEaqDdjq1n0fqhv2VH_RLlhA4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'Falk',
        emails: ['mfalk@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joey',
        lastName: 'Z.',
        emails: ['jz.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGjZLf9_A1z-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619036989041?e=2147483647&v=beta&t=ASQHk5kE7OGxHWII1J4bNiNaDEDKHQoKTBXKpKYPZjY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Allison (Alli)',
        lastName: 'Goings',
        emails: ['agoings@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFo62actv8Fmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679342654451?e=2147483647&v=beta&t=C47FsZL-UqPl1PKL-no_zo_BEafIQMeX67-nVpBc7mY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  jakehuber: {
    name: 'Venture Scaler',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jake',
        lastName: 'Huber',
        emails: ['jhuber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQElpUmZ1BwVRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685136246920?e=2147483647&v=beta&t=91APTVuXUmlzcgPk5fAPb2IaVYDirsyw3jCetYQlsnQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ben',
        lastName: 'Johnson',
        emails: ['bjohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Ventures',
        emails: ['mventures@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF4MMZdIG3ufA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657543065961?e=2147483647&v=beta&t=bj5CRCtFmU1rjNJazed7rY0-3AVJ2dEYgHC8Tk8UfnA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jonathan',
        lastName: 'Ellis',
        emails: ['jellis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lauren',
        lastName: 'Lawson',
        emails: ['llawson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGG0kFx2xosNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704758599327?e=2147483647&v=beta&t=9g082hu30uc5nEQ9KIZ4FVDfeBQscwPdHVImTuDj2J4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  centerfpl: {
    name: 'Center for Purposeful Leadership',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Deidre A. Kellogg',
        lastName: 'Ketroser',
        emails: ['dketroser@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Craig',
        lastName: 'Neal',
        emails: ['cneal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFQsNdd2r_MTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516249706194?e=2147483647&v=beta&t=xyalKadgEYFWO6EOh04Y0oUw6uDGtXCY7sR7i9PD2hU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patricia Neal',
        lastName: '(she/her)',
        emails: ['p(she/her)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHJKUzigX32Sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697310688208?e=2147483647&v=beta&t=LJTfoTJ4Cu8pqOsGIhEozZ4pfOQPoOQxGmLMxw5QSfs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rochelle (Chelle)',
        lastName: 'Kotlarz',
        emails: ['rkotlarz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHjRuxclyoAkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708612969415?e=2147483647&v=beta&t=twpgh1489DNksQmgex0Chmg_TrkbNARNtHxVvcQbMXY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Claudia',
        lastName: 'Eisinger',
        emails: ['ceisinger@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  leen: {
    name: 'Leen',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Luke',
        lastName: 'Shardlow',
        emails: ['lshardlow@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Fredrik',
        lastName: 'Velander',
        emails: ['fvelander@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEde1UR50Zr2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660864442905?e=2147483647&v=beta&t=HFB1cYCNVUbfvnIfftsQmMjkNSqQiUH5kYTfLHSrk5Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeff',
        lastName: 'Zupancic',
        emails: ['jzupancic@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carmen',
        lastName: 'Y.',
        emails: ['cy.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEUVX2_4CO5VQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713486862771?e=2147483647&v=beta&t=iAvRrHV5Tvh9GSYmbvpqRIrrHapDRlVfxJl0lZ3kxPw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Helder Sebastiao,',
        lastName: 'PhD',
        emails: ['hphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHYIMDSL9_IHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653605903178?e=2147483647&v=beta&t=yKfF2ffCChaV3KzVSLIGO7--mnRKW5zj0mFKIVLWKFg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  growcounseling: {
    name: 'GROW Counseling',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wendy',
        lastName: 'Dickinson',
        emails: ['wdickinson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jackie Dunagan,',
        lastName: 'LMFT',
        emails: ['jlmft@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGHIdJaIfy40g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585146404855?e=2147483647&v=beta&t=tRqy2893L34KCHN0NEs5GaOhCyFYcOOeGCnEtj-G97A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bre',
        lastName: 'Vergess',
        emails: ['bvergess@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sabrina',
        lastName: 'Low',
        emails: ['slow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHiDiAglBxFIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574029318959?e=2147483647&v=beta&t=bxsakdQvbhJxzr2_i5VkWIWDThf4YWGYh8q4v7CliGk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tyler',
        lastName: 'Pennell',
        emails: ['tpennell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFThXmjfNKiiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607097804495?e=2147483647&v=beta&t=C5cA_3KZhLC85aKBFHf4odpm5veCAvauoYQNQl1ccH8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  clientascension: {
    name: 'Client Ascension',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Peter',
        lastName: 'Hartrick',
        emails: ['phartrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQETYi9u3YajWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660138958974?e=2147483647&v=beta&t=8s6sbhZnzMXj2IMfml_dmJHKYoICDJn1wsDeCbJs80I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Fazio',
        emails: ['dfazio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHo2k26Y4qM_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668001026694?e=2147483647&v=beta&t=Gljg4sipNcFNI3cQiEeaJrNj3UVZ2YdcBEbPSvoAsqs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Antwon',
        lastName: 'Cunningham',
        emails: ['acunningham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEd9VKbolL4bQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661350204033?e=2147483647&v=beta&t=541NKhDwNkVZp8tpTJ5ljp2ozuJ6PA0rexHPbObpFFY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Taylor',
        lastName: 'Bozarth',
        emails: ['tbozarth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQETSUtAhgN2cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718933506724?e=2147483647&v=beta&t=azKJeIPETooY0ycyMk2a6K1CJSZXPYcVzNrhkMcEzs4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andre Haykal',
        lastName: 'Jr',
        emails: ['ajr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGKtNGwbTpwTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714659470364?e=2147483647&v=beta&t=LAiNeZEeRBMCrzOXFjaxzSOXB-3JRItW0uP7rmmKXJ4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  decibel: {
    name: 'Decibel Partners',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jéssica',
        lastName: 'Leão',
        emails: ['jleão@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH66vKQUAfb5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693668711135?e=2147483647&v=beta&t=7nhuX-5iXJRWJDosoAfII_H9TetSaLX2_UaJKqZSnk4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lauren',
        lastName: 'Ipsen',
        emails: ['lipsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFI-31Bq7jB-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718238647400?e=2147483647&v=beta&t=FzQqKbv29RLCryY5uzX9Q5bw3P3OSOdpF12ZR7C8jwQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jon',
        lastName: 'Sakoda',
        emails: ['jsakoda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGLGTZmFyXBzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553583068069?e=2147483647&v=beta&t=qyvQn7LHo-IwALcMA2pucK3oq2X_ZxCq7_U7plYqvoc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dan',
        lastName: 'Nguyen',
        emails: ['dnguyen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Menaka',
        lastName: 'Raman',
        emails: ['mraman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  winstonAgency: {
    name: 'Winston',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Art',
        lastName: 'Bloom',
        emails: ['abloom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHbrKkFqsSGIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719941403089?e=2147483647&v=beta&t=D-dJJXQ66nPWPXXnXQt9gf-B08ITvVWzT2HKrmnNeio',
      },
      {
        id: 'U077TRRJQ3G',
        firstName:
          'Winston S. Williams MBA, ChFC®, CLU®,CIAM, PMP®, MFA, RFC',
        lastName: '...',
        emails: ['w...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHYvdTW0GibBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696441605890?e=2147483647&v=beta&t=iINIXFqroNEFxZ8gbFrXHTxOc2ceSRvsdoP4CIuaJYk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Debra',
        lastName: 'Shore',
        emails: ['dshore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHzgIJEw3g5-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516785110643?e=2147483647&v=beta&t=WLKU4BeBSBKBeTLN3U-AVje9XHyrdTDa9RKuMLqbxk8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Charles',
        lastName: 'Orandello',
        emails: ['corandello@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Winston',
        lastName: 'Cook',
        emails: ['wcook@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEOuufWYt2FAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645579190950?e=2147483647&v=beta&t=Ta5V4ETbEHwRkz4aN_DZPRX3ycQ_iX7ZUzCvdhwWLSU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  integration: {
    name: 'Integration.app',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniil',
        lastName: 'Bratchenko',
        emails: ['dbratchenko@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shanna',
        lastName: 'Xu',
        emails: ['sxu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFiCSUJxSYyXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517624002137?e=2147483647&v=beta&t=exXo7_Ns_EG5esqY-KyGvq-exnscR1HnlVO5ZJMiWV8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Vladyslav Ursul – Head of Customer Experience – Integration.app',
        lastName: '...',
        emails: ['v...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGUCyjkaJ3tCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708007888960?e=2147483647&v=beta&t=5d21z1vOVIENdbLwQxejySZZZFnarhLliI6shrRFot4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Johnathan',
        lastName: 'Fisher',
        emails: ['jfisher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGUZlL9z3sNxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601537127227?e=2147483647&v=beta&t=1zgB-P2M9nsNlJhczokjBWYTCyda9b9-r7LVrlhTsWk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ryan',
        lastName: 'Colla',
        emails: ['rcolla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  murphymeyers: {
    name: 'MurphyMeyers',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lindsay',
        lastName: 'Murphy',
        emails: ['lmurphy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sarah Ann',
        lastName: 'Fraser',
        emails: ['sfraser@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG14_tEGkuNdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682106372257?e=2147483647&v=beta&t=wERT1d_qHbSCusUMfoLwDSMxJtNpmGW_0YF9wJR7yuc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nikki',
        lastName: 'Davis',
        emails: ['ndavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHjaPjZRipS2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665534467231?e=2147483647&v=beta&t=SFBNcwOI8bd3Hik4em_7lvyNqiVwBM-vBCQ50W8qt2M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bailey Vaughn',
        lastName: 'Desai',
        emails: ['bdesai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH_MHolawItoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673452943546?e=2147483647&v=beta&t=taPoSjuNMBiBUKIBiA75Zrl4Y6xKtoln9j3bcKrg1wY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeanette',
        lastName: 'Meyers',
        emails: ['jmeyers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGQPOFag5oaXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643508687970?e=2147483647&v=beta&t=1xqXmwV2bf0quKzwBoynbrxjKehdFd-aqL1rACuVJv0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  goerrand: {
    name: 'Errand',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Goerrands',
        lastName: 'Services',
        emails: ['gservices@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kason',
        lastName: 'Larsen',
        emails: ['klarsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGgIAUFxORnXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665334154680?e=2147483647&v=beta&t=37zSUapa9EklDOLoZpkgdME4a1MvMOaI5kONl-QeO4Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Korrie',
        lastName: 'Ford',
        emails: ['kford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHRob2oHzZDXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516841247860?e=2147483647&v=beta&t=R_HpFeO3oRrwjSl5A2AwchW7VfAkcC5HOcPjWEL-LNo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Engr. Salami',
        lastName: 'Kingsley',
        emails: ['ekingsley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHvqjS4PmdHzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693305471532?e=2147483647&v=beta&t=JDm7H647GSAfNKw3jVx9f1lck-Dbyy3KsH59pmzu-nk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jean',
        lastName: 'Guay',
        emails: ['jguay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHFdJVHeSgsNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517442139537?e=2147483647&v=beta&t=s4JwChLrukfiwYeaRtR4UAtooYF_lxAaS3xZmQb9Fqo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  chemistswithoutborders: {
    name: 'Chemists Without Borders',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ramin',
        lastName: 'Sedehi',
        emails: ['rsedehi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEFBzk1wa5bTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718211182968?e=2147483647&v=beta&t=Ly8pv17tetBBcui6DOOOIpXyTLZ6zhFAQHAY4KDyw1o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bego',
        lastName: 'Gerber',
        emails: ['bgerber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHHGxfNGgt0dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686596200443?e=2147483647&v=beta&t=5Pz7ZHzBHdCZgNG5e_LPZ3Bt5mNRVLzBnEIJdUR49MI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dan',
        lastName: 'Lent',
        emails: ['dlent@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHOP2rPmuH4-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517495364380?e=2147483647&v=beta&t=zJfq_2MpAs7MKib7sn3nu9VjfWPu-tkIG9n8SoqXgBE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Achal',
        lastName: 'Garg',
        emails: ['agarg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEryFWWdfscMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517359159050?e=2147483647&v=beta&t=jtGNFeGBMbQVJSd_ZEDHvK4AqTe0vvunZnxxtM0ArV0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Louis',
        lastName: 'Ciabattoni',
        emails: ['lciabattoni@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGSv4yuvpQdmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579099452395?e=2147483647&v=beta&t=ehSQJeRxyylWCGLfaJB4F-QmQCzEDPn3_JR564O5g4o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sjpcenter: {
    name: 'Saint Joseph Parenting Center',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Maureen',
        lastName: 'Decker',
        emails: ['mdecker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHyLB_qxUKl3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1530560752565?e=2147483647&v=beta&t=vFT2Yo_r2j3GCkvcd3Rkz6_pKUalrCNEZyZkzU5jjvQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'RHONDA NEAL, MA,',
        lastName: 'MBA',
        emails: ['rmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHF9i4JnWqUzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706187872681?e=2147483647&v=beta&t=gbD-aLD9Xn1tVd_qpAppV9E_TlIMzUaer8SfL6HWUSQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rian',
        lastName: 'Drenzek',
        emails: ['rdrenzek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGSZZjrg7lcig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721903826620?e=2147483647&v=beta&t=vrYELgAEX2CMfM7qyKk9VQFPrlpSiR__bqv4rqauOgs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Micaela',
        lastName: 'Malin',
        emails: ['mmalin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG4K2H3PVsraA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696106482689?e=2147483647&v=beta&t=77cFG2nrXw50FgcvZ7xRmCFOoF87zYbxojrDrzgG6PU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Maribel',
        lastName: 'Sandalo',
        emails: ['msandalo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFovgjoNCKY4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636771773296?e=2147483647&v=beta&t=nVk-JSbjO2r3MEk5H5J3iDAJapAi53Gt7aDzpiXHwrQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  patburnsco: {
    name: 'Patrick Burns Co.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Patrick',
        lastName: 'Burns',
        emails: ['pburns@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Patrick',
        lastName: 'Burns',
        emails: ['pburns@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHcfajUfzNU_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681397468764?e=2147483647&v=beta&t=_2qprZpmOz06JSYinbLUVJgIZzGw6ZzJJCOGoInU16E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patrick',
        lastName: 'Burns',
        emails: ['pburns@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH_nyguxnuGsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516862450956?e=2147483647&v=beta&t=mt5NXag2fuAOlVfy_jQfpbbhyysv51BitmIKPc7aSb0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Patrick',
        lastName: 'Burns',
        emails: ['pburns@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGvrS_C996RPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583857126585?e=2147483647&v=beta&t=OrC1x5EtjXMS3pjDzKbscJ3igpNyA85MxuaDkWLZpIU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: 'Burns',
        emails: ['pburns@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sagetap: {
    name: 'Sagetap',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sahil',
        lastName: 'Khanna',
        emails: ['skhanna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHd0_MilEeDTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695173481582?e=2147483647&v=beta&t=1D1369VbSQRzsQ-IJdgqMCnArg7AoTyHUBJIQo-HgF0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kevin',
        lastName: 'Hughes',
        emails: ['khughes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGPX0orTAs7AA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673027036079?e=2147483647&v=beta&t=Bxlo29qCYpBqE8YEjUI4U6UH_5W1Sm-Gqtipj2TevRY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mike',
        lastName: 'Goodman',
        emails: ['mgoodman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE8e_URwX9R0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714661220343?e=2147483647&v=beta&t=m6LyZ3SnKsKyTzabDLIOO1pmsJnquEa0YOfWqiYK2Dk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Evan',
        lastName: 'Tarver',
        emails: ['etarver@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Gifford',
        emails: ['pgifford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGBgLFKFCPYyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667593414903?e=2147483647&v=beta&t=RsxKpQ9QbA887xWCYVS9TvJz5ldR10W6MsgaIJwxhaM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  numericengineering: {
    name: 'Numeric Engineering Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Onur Guvener,',
        lastName: 'P.E.',
        emails: ['op.e.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHt9rlEE7cpvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653184445362?e=2147483647&v=beta&t=_ake9ZXTZNrzJ_5dnSQWV0QVH0PmNz4HXD3dDHYC0lQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Asena Hazal',
        lastName: 'Kahriman',
        emails: ['akahriman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHUgz_gc4MImA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1562414647711?e=2147483647&v=beta&t=JiFd-0lvOpNwnVo_o68JXeJiX_Sx0emld7TbQy-TiYg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Irick',
        emails: ['mirick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE4xAag3T06yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633012663920?e=2147483647&v=beta&t=mHmps7a1phdey6QC35Gr_SOsFZTOASYE9MmwNnCeuIE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daniel',
        lastName: 'Zamora',
        emails: ['dzamora@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEoOyWbpejuJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687636392238?e=2147483647&v=beta&t=8yYiEB-z-7RrvU761qv4_khA8nm6pwafG5KzIZrSohk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nurullah',
        lastName: 'Saglar',
        emails: ['nsaglar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFSrVHyMjWyVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674677357771?e=2147483647&v=beta&t=QqUDXxPo5yZRGax7ql1F5IOzO2g42RCy_5PxhiSqNJk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hraffiliates: {
    name: 'HR Affiliates',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jennifer',
        lastName: 'Wheatley',
        emails: ['jwheatley@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paul',
        lastName: 'Gordon',
        emails: ['pgordon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paula Agee,',
        lastName: 'SPHR',
        emails: ['psphr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amy Olds',
        lastName: 'SHRM',
        emails: ['ashrm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shenikia',
        lastName: 'Thomas',
        emails: ['sthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF-_K_sBuIqSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577858080?e=2147483647&v=beta&t=EVaQJdNhTtPJmiYOTavdNUsGFgy8eGyBdzYPdneoPuE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },

  practicesanfrancisco: {
    name: 'Practice San Francisco',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nina',
        lastName: 'Kaiser',
        emails: ['nkaiser@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Haley',
        lastName: 'Friesch',
        emails: ['hfriesch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGldOYVy0vSIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658779500645?e=2147483647&v=beta&t=og9VnfbsIK44p0iHhWtHSiR7kuCxiNuxMwOAbr4XHYs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Miranda',
        lastName: 'Fuentes',
        emails: ['mfuentes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFIlYzRitgWKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594444475571?e=2147483647&v=beta&t=HvOe2aXVWPW67mQOxl0o_BYMHrCZXCB4fmlHKTuZS90',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dr. Stephanie',
        lastName: 'Rooney',
        emails: ['drooney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEN5GTKjkBVwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702182355662?e=2147483647&v=beta&t=IP8kXMFAJU_MLqMdUQIZlnWbevQwvns5FYPfbN4_LN4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amelia',
        lastName: 'Aburn',
        emails: ['aaburn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHLQgM-mcqypg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711047960324?e=2147483647&v=beta&t=tiSmxTAAa3gwM_jje8JmMlYKdUR_D25L9SdPOqoua0g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  beamcomfort: {
    name: 'Focal',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Raj',
        lastName: 'Tilwa',
        emails: ['rtilwa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Julien',
        lastName: 'BIGOT',
        emails: ['jbigot@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEivCaU01-z3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517345371268?e=2147483647&v=beta&t=T9Cl1_YH1ROEi-rfToC5idOzT0xoH1HDr8i8fgXWxMY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: 'Larsen',
        emails: ['alarsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHrvAiOG1Whlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585603802121?e=2147483647&v=beta&t=YFdLBXprmHFRC18fxITytgfT4bYQ0jsFnOWQpPXec1Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Siddhartha',
        lastName: 'Pal',
        emails: ['spal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Ward',
        emails: ['jward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH58ndGQ97kwg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516970604740?e=2147483647&v=beta&t=mh1E7WTJ1Y_ReouCFCTXOtyjg3ONCFw7NVLNNgMjGfI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  synthlabs: {
    name: 'SynthLabs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Priyansh',
        lastName: 'Gujarati',
        emails: ['pgujarati@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pavle',
        lastName: 'Kravljanac',
        emails: ['pkravljanac@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGmO0N_0G5FRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652055652768?e=2147483647&v=beta&t=dZQxJMbvYh-Cg5epNVxhIeoHwwa7INulJhaxtru7BjU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Francis',
        lastName: 'deSouza',
        emails: ['fdesouza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHbw2E-pchGVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719908465814?e=2147483647&v=beta&t=rbNolwszdMn_S8xVlRUXT0v8xdaYnbeo8_HF-SEqfwE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nathan',
        lastName: 'Lile',
        emails: ['nlile@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGcDPPGtmixuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707865317574?e=2147483647&v=beta&t=nXvwcACHDn-gvpvaxxpeCBNA_55gfbjI3KCztZUVhz8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Francis',
        lastName: 'deSouza',
        emails: ['fdesouza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHbw2E-pchGVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719908465814?e=2147483647&v=beta&t=rbNolwszdMn_S8xVlRUXT0v8xdaYnbeo8_HF-SEqfwE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trioseo: {
    name: 'TrioSEO',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Connor',
        lastName: 'Gillivan',
        emails: ['cgillivan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steven',
        lastName: 'Schneider',
        emails: ['sschneider@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE6wOV0e5f56w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706371897483?e=2147483647&v=beta&t=xBomJRAOzq85Fsbu_QZ4hFshZcq6JLsjmdtan83qIEE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Naman',
        lastName: 'Khanna',
        emails: ['nkhanna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFjyH6aV5Lnjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674806360413?e=2147483647&v=beta&t=MAKfVy4VeMOWOCiTDmQ2hdb9xd4DaFjbOyaHxzEpCtI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tanvee',
        lastName: 'Kulkarni',
        emails: ['tkulkarni@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bilal',
        lastName: 'Rafique',
        emails: ['brafique@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGNsTFWCgD3iQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702541251287?e=2147483647&v=beta&t=XxUQ6k73SE0qg8hrMOTCrdjiuYBIIzkiILg-6j03l08',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  flytech: {
    name: 'FlyTech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'donovan',
        lastName: 'zhuao',
        emails: ['dzhuao@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHEKiGTLGZ_7Q/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1697711145475?e=2147483647&v=beta&t=KAaK3uZ5x7Q6YMBoVj0XIxibO4bdqGkQg84r56RJ9c8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'TRIPPLE IT',
        lastName: 'TECHNOLOGIES',
        emails: ['ttechnologies@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lewis Tawanda',
        lastName: 'Zhuao',
        emails: ['lzhuao@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dean',
        lastName: 'Shapero',
        emails: ['dshapero@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFwFklsFnP34w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686016196224?e=2147483647&v=beta&t=xm8Z_kIM-MeFu83EHQjm4ZP_mepfXrp8nZYLggNPcvs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vinu',
        lastName: 'Varthan',
        emails: ['vvarthan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEukCY1bmG6rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671660811277?e=2147483647&v=beta&t=a1zs_dBjbh-Gbe06Ff8A4G2XhnJZ94BDJT7LoayRqRU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thekennedyforum: {
    name: 'The Kennedy Forum',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Lloyd',
        emails: ['dlloyd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nathaniel',
        lastName: 'Counts',
        emails: ['ncounts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH3dmNbfjHN6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596835332815?e=2147483647&v=beta&t=QsumhOilY8xtz-C5a2AOwxKAEz8biVdbDPWzqRDb8bA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lauren',
        lastName: 'Finke',
        emails: ['lfinke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHO1KDIvs9tyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682468007870?e=2147483647&v=beta&t=NPlHljQueeweHcjQqsHVF4tzZ1Gy9Zn4Gpxloqyei0A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amber',
        lastName: 'McLaughlin',
        emails: ['amclaughlin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amy',
        lastName: 'Kennedy',
        emails: ['akennedy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFdQ-o2xIzrEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613063431627?e=2147483647&v=beta&t=oL5QRi9jdn2FHWwlTz76caQIu_ZWeZT4ElBogwezjVE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dpgpartners: {
    name: 'FUSE Workspace',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mike',
        lastName: 'Daugherty',
        emails: ['mdaugherty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHEID4OSawxAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604869216762?e=2147483647&v=beta&t=ytC5oif-KLGAKJsmrICoHQ-AwaVZYmjZ_5q_XjcPNeM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kris',
        lastName: 'Thomas',
        emails: ['kthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFsq6KjUNr_3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646947381501?e=2147483647&v=beta&t=jf-os1RKfbgb0ermnKRDl9BJurAP7zZA5PPlTso0mJE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Herring',
        emails: ['jherring@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGRUK5ZrjFO3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698200148079?e=2147483647&v=beta&t=oNydGh2Evo7IMSIMnbZmDrGN_y-9iS9sg8tqvAmZE14',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ivy',
        lastName: 'Brown',
        emails: ['ibrown@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emily',
        lastName: 'King',
        emails: ['eking@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE9lY8Z-pCPUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595340304677?e=2147483647&v=beta&t=7W41CMHMpSKIJaOx_85HdkiA7zqLEbu43OKyxfKimuk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  branchoutbh: {
    name: 'Branch Out Behavioral Health',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Samantha Dalfen, Ph.D.,',
        lastName: 'BCBA',
        emails: ['sbcba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFD51AXwx8LQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681148401890?e=2147483647&v=beta&t=ke8phTYvbZRnJtyHQB-e72ZUGYt0c_xl4p_eoEgkcLM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sarah Camp BCBA,',
        lastName: 'LBA',
        emails: ['slba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH_BRToebbfTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692658791726?e=2147483647&v=beta&t=xmOw8jrkUEE7NLrvMufrq_0-h9FMBYc1oMyEiOfmB0M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anthony',
        lastName: 'Velez',
        emails: ['avelez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGoWOGo2-Qj_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688058899086?e=2147483647&v=beta&t=EttXeh1LrA29mufNOJMMsilxW6lJAhnrxvCOQnUDMYU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Adrianna',
        lastName: 'Beno',
        emails: ['abeno@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE7RA8Kwsipvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726013638422?e=2147483647&v=beta&t=v6LF4w_qsm6bYd8ZOcliCApRt_T10nWunERy0v0d-ZE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pooja Darji, M.S., BCBA,',
        lastName: 'LBA',
        emails: ['plba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  retirable: {
    name: 'Retirable',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tyler',
        lastName: 'E.',
        emails: ['te.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cullen',
        lastName: 'Clark',
        emails: ['cclark@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ian',
        lastName: 'Yamey',
        emails: ['iyamey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Harrison Schaefer,',
        lastName: 'CFP®',
        emails: ['hcfp®@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEw1DOFs-V7kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688151656406?e=2147483647&v=beta&t=ZDL2BKJJ3vGJK-UzV4mFzq4cWGeZhGh6p2laU5seOk8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sami',
        lastName: 'Boghos',
        emails: ['sboghos@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kimberlywahlberg: {
    name: 'Kimberly Wahlberg Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Miranda',
        lastName: 'Andrews',
        emails: ['mandrews@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFEWvcr6YV0_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704666763349?e=2147483647&v=beta&t=VA1DGTzfZggTdGqQdiQit-X8U16WZXgE04lkdG3Z5Z0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kathleen',
        lastName: 'McNutt',
        emails: ['kmcnutt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEJ24e9zjvx8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517061305202?e=2147483647&v=beta&t=o7ogHbliWokhdEuv4mPo81X-07L8gDaNmtf7sGpo7jc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erika',
        lastName: 'Dluhi',
        emails: ['edluhi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH3klxAS28HJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692827862294?e=2147483647&v=beta&t=qz54rUCwzh2jHDjgEhsf959NAUDkdvEGa9lyTmuY-90',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nancy',
        lastName: 'Perkins',
        emails: ['nperkins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG4XW1gFZkmIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516437996306?e=2147483647&v=beta&t=WnlBUhnOt6NqKlzIwsV7kqMiRksB8l6eA6BDTnD3mbY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carrie',
        lastName: 'Lane',
        emails: ['clane@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEvkhgVTOE6pA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666227509192?e=2147483647&v=beta&t=JiBBYoWjRe28E-s2j-UdRzpF-0vFNNG9eB7kTxnRnFA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  twchcm: {
    name: 'Time Wellness',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ryan',
        lastName: 'Cierzniewski',
        emails: ['rcierzniewski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFaNMjfKE4CvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665514153774?e=2147483647&v=beta&t=CBm2vICLed_Vh91XUUV4Q43X_brh-kUD1WXbs479UlA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matthew',
        lastName: 'Newcome',
        emails: ['mnewcome@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG2C6XJD1bkiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672170843670?e=2147483647&v=beta&t=KLH9DtqyxSc3SQTykv501447CJtPre9DdImxEqqRA5Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christian',
        lastName: 'Brown',
        emails: ['cbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHCQd-3LrKQNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707793327826?e=2147483647&v=beta&t=wm9eZJTbo-Fww0bUvFLn01ne8Fz4LiE1_G8WXhs-lps',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Danny Mills',
        lastName: 'II',
        emails: ['dii@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHRBgtxY6155g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720061589907?e=2147483647&v=beta&t=mtuOplM9amjphXnWlkYNOokURkKXeHbAKIIusDeZt2I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emma Heming',
        lastName: 'Willis',
        emails: ['ewillis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hubcredo: {
    name: 'HubCredo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shashikant',
        lastName: 'Burnwal',
        emails: ['sburnwal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHWek96mx33WQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721369047748?e=2147483647&v=beta&t=YZwhuXnpOfn5M-B4FW1wXISYA4VpJmomnw9j1_FCboY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hansamedha',
        lastName: 'Ambati',
        emails: ['hambati@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF6jMuiQT1TSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712606683162?e=2147483647&v=beta&t=nlxRr5rPomQxyEq2ocamyhRZSimDr4kBIGK6WQ52_AU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shreya',
        lastName: 'Chaturvedi',
        emails: ['schaturvedi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Prateek',
        lastName: 'Shrivastava',
        emails: ['pshrivastava@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH-tWrroF4Ncw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695683887892?e=2147483647&v=beta&t=VsXKYNeMOrlkmhtvJ1aVmTsmtnfqelYG6fUl-JZC-2U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anurag',
        lastName: 'Bhatnagar',
        emails: ['abhatnagar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dehealth: {
    name: 'DeHealth',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wen Chen, PharmD,',
        lastName: 'BCPS',
        emails: ['wbcps@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Craig',
        lastName: 'Lipset',
        emails: ['clipset@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE7CS2Dx4M59g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693613527935?e=2147483647&v=beta&t=hu2xelFMkaHFluxxOIMqlme0V9MFTl_UEtp6CyieuVI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lindsey',
        lastName: 'Edwards',
        emails: ['ledwards@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGBf3JazSCrZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611104537069?e=2147483647&v=beta&t=8TWiAX_WKiPmZLAvxPmhMFm1CE2qiBQYxZFATi94-_Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Suzanne',
        lastName: 'Sprott',
        emails: ['ssprott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFFp5gxkH8mTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683989924319?e=2147483647&v=beta&t=EAdiPbdkyvLGmagV5hzUnY88SlN2BtqXVJlzHDHF9t0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Liesel',
        lastName: 'Groninger',
        emails: ['lgroninger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFK8s_qt4LX1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687112888746?e=2147483647&v=beta&t=oCvXoOrGwCMIkys3_J3u2aZwS3kqI4dTmpssMFP8v7Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bondbloxxetf: {
    name: 'BondBloxx',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: "O'Donnell",
        emails: ["bo'donnell@example.com"],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Elya',
        lastName: 'Schwartzman',
        emails: ['eschwartzman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFfkH6M5B_Oeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633559533444?e=2147483647&v=beta&t=e5Ndt7IxHC8cs_n0G3x6Wch2b5Q0lSM0k5FWl5j43eE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Katie',
        lastName: 'Abbott',
        emails: ['kabbott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF-YhmtGxgOqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689872633570?e=2147483647&v=beta&t=KmGiZuzAiVTYrLIZs2L7-IZhx4GxnX_1NbwqULmCR1c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sarah',
        lastName: 'Concklin',
        emails: ['sconcklin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGADBer9ZqKpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673501267691?e=2147483647&v=beta&t=xf5Yv1DpDEwE2BsIuXf2SYDB1WvdsaY3UFCiVVncmuo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Robert',
        lastName: 'Eaton',
        emails: ['reaton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHgXRVzg3ROvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677609442490?e=2147483647&v=beta&t=Ixgl0c9UEPYkS_qDTiOclh9jEJLKBWmawskajHrQz9s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sectoredge: {
    name: 'True Market Insiders',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rob',
        lastName: 'Grimes',
        emails: ['rgrimes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFGDlhIa5JA5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674413476475?e=2147483647&v=beta&t=6zMK4Iab9sZLeC7P9LdoSfRu99qypZZm6owwtxJcOpA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lauren Selman,',
        lastName: 'CMP',
        emails: ['lcmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGLMd9rcw2ajg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646687836182?e=2147483647&v=beta&t=QjZ8O1SZBIyFmGgBGovuYcac0hEqWf_Nv4HIZePnHUY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kathy',
        lastName: 'Alcaras',
        emails: ['kalcaras@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Terri',
        lastName: 'Fisher',
        emails: ['tfisher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEkEpCaGd0_eQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517574795829?e=2147483647&v=beta&t=ahktPn6TBAoGoL3ur9OS8XLcOM94h1abyj-dEfvqFqE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pankaj',
        lastName: 'Patra',
        emails: ['ppatra@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  infraveo: {
    name: 'Infraveo Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anand',
        lastName: 'Sharma',
        emails: ['asharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF14E5qJEfn4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662604530127?e=2147483647&v=beta&t=mwZjmTkmeKsGc0HiISMKCOScBCCwWsvKwTGeBDozTiA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ridhi',
        lastName: 'Choudhary',
        emails: ['rchoudhary@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEiHkPdiwpTow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708261272657?e=2147483647&v=beta&t=fUfSxsbTlOXTXb9SWgv_NJWrtLkKnHvzz_58x5mXOuY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shifali',
        lastName: 'Sharma',
        emails: ['ssharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGcX-IJIfhjzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657776695888?e=2147483647&v=beta&t=NWpgH5O-mH3LRk7-rvfMo25_teJElDbz9GsSTQB2iSA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Heena',
        lastName: 'Joshi',
        emails: ['hjoshi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHphrMwGnFxgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708419655079?e=2147483647&v=beta&t=bk7Xi7ZaxgDmXUr_ZestvzvJh8DuAQ0GxriHFhzvmCk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aishna',
        lastName: 'Vishwakarma',
        emails: ['avishwakarma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mogul: {
    name: 'mogul',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alex',
        lastName: 'Blackwood',
        emails: ['ablackwood@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joey',
        lastName: 'Gumataotao',
        emails: ['jgumataotao@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFkVyOPSLUWmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726682365599?e=2147483647&v=beta&t=qr2lIuu1G4w4G1lIdyR8xfFrXP2rkxcEShsPygLh4GQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Suzette',
        lastName: 'York',
        emails: ['syork@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanaa',
        lastName: 'Khan',
        emails: ['skhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFFA78aX3ModA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647779183753?e=2147483647&v=beta&t=PXlIDSDQ0vPPG5QGhFEYFNnN6vSFVOcEKYQjaM0ad4o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Thomas',
        lastName: 'Horcel',
        emails: ['thorcel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFu7PZKMM01Ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673282513439?e=2147483647&v=beta&t=XtLGSgpWG6iE21W6GSMEYmQG3oRWHqj0Ifio7XHimr4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  synclabs: {
    name: 'sync. YC W24',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Prady',
        lastName: 'Modukuru',
        emails: ['pmodukuru@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGtjVk8OXE-yw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664996310528?e=2147483647&v=beta&t=y6SJXlkF5ppLXfm-SZcD8nUeB9u1lyL3n86CNCqiHtE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pavan',
        lastName: 'Reddy',
        emails: ['preddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHQu6lF0-L-iA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727390179031?e=2147483647&v=beta&t=ihcMPghPtE5da2KikQwQEI9eQ4lIkz3Ukmf1pkqcK9Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rudrabha',
        lastName: 'Mukhopadhyay',
        emails: ['rmukhopadhyay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGE-RQnpxVC1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531476355252?e=2147483647&v=beta&t=H72AWXykeDhsWY3wjiWDWQYsMGYtxsMBuGv8SlkfaUM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Melanie',
        lastName: 'Herbert',
        emails: ['mherbert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGPOyz1DtKRgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699139325051?e=2147483647&v=beta&t=dXy7Q7hAw88QXtAC9a7WeshT8Wkf6Z56_RFlqtizJVA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Prajwal K',
        lastName: 'R',
        emails: ['pr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFpKuaOF-lKdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572427166148?e=2147483647&v=beta&t=WnOR3zPVjXmZ2LYzKu0-9UbeeMS0bnAKfvhAnsEm3cY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  neuroreef: {
    name: 'NeuroReef Labs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Satwant',
        lastName: 'Kumar',
        emails: ['skumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGU0ljtxNiYiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709242423309?e=2147483647&v=beta&t=R4GZbfYzLFzhE570rEW1E_5GTR6UmInpNJwBQ_exjVM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hadia',
        lastName: 'B.',
        emails: ['hb.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Arendia',
        lastName: 'Ruiter',
        emails: ['aruiter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEpoJU9F5kmaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710013668721?e=2147483647&v=beta&t=2U7mXZ7SaMyoSd5qvx7jxQfjCx_OWs89oMefDr2BS9k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sayyab',
        lastName: 'Mohammad',
        emails: ['smohammad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF6UonDMqJqNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725226242732?e=2147483647&v=beta&t=tbtahfTRdeXd1g-kSV9P4QOKDxBIKyCv6nfs3I0mihM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sadaf',
        lastName: 'Delawar',
        emails: ['sdelawar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQExVtTFazOQ8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722107152907?e=2147483647&v=beta&t=Gmfg7tEvHvTeQWAXPevmvX1p4hw-6pzkuvTF1nVE7mI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  astuteveterinarysolutions: {
    name: 'Gold Coast Search Partners',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anna',
        lastName: 'Brady',
        emails: ['abrady@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHSz96N8jx9QA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705605265391?e=2147483647&v=beta&t=07Ildu7dE3hErVpJpNZWBOIeSfhzg6vBEkiJgh6PANI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Katie',
        lastName: 'Casebeer',
        emails: ['kcasebeer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE6rQ_pfVyoHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626885778949?e=2147483647&v=beta&t=mgYXYiq395oyy9qGmzEr-kY7t16YEBfQEQkcOkX5Mo8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Janelle',
        lastName: 'Matthews',
        emails: ['jmatthews@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amanda',
        lastName: 'Tran',
        emails: ['atran@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alexandra',
        lastName: 'Sawin',
        emails: ['asawin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGhxYH6ivW7hA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626892777973?e=2147483647&v=beta&t=EYIHXxzGCCwR4p9pXPV_HeSEZ3Y6wvWR9nus3sadHGM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  indosole: {
    name: 'Indosole',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kyle',
        lastName: 'Parsons',
        emails: ['kparsons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEQdxCz7W4w5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630558705004?e=2147483647&v=beta&t=8pajneM3iyi7_71a70Ci74VRgILTAEf9I71HyUot9fw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kai',
        lastName: 'Paul',
        emails: ['kpaul@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEepOKxICZrYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572508366278?e=2147483647&v=beta&t=wxozVQOH68peLVNOWHJiNIStf5aj4_nvpxjbaSNETfM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jennifer',
        lastName: 'Gregory',
        emails: ['jgregory@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeremy',
        lastName: 'Urone',
        emails: ['jurone@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Photographer |',
        lastName: 'Director',
        emails: ['pdirector@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEcKyMjGr4HMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600659151159?e=2147483647&v=beta&t=pnwnC5AZrRCRIoHXB3wzC8DVqz8dCTj80LB9QeMqyWY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  apollopartners: {
    name: 'Apollo Partners',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric',
        lastName: 'Perko',
        emails: ['eperko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHOj1BguMHyuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705422209568?e=2147483647&v=beta&t=5YgAKq5qSVLiEZ5SUsZY4HtDpsMGFiOg9tQt4oKUrjw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Katharine',
        lastName: 'Painter',
        emails: ['kpainter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGp0akQlHxSCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692295290115?e=2147483647&v=beta&t=0yYhO8gqXx9PusxybXKF2tcmUcq-_mvCv3Uu35atmfM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Thomas',
        lastName: 'Miller',
        emails: ['tmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEWMRlJ6ZD4WA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516775107813?e=2147483647&v=beta&t=H_IMMbYmeyCA2YqtrBOQ77cr6sW_yhN9r9xxmWG4xGk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jennifer',
        lastName: 'Rhima',
        emails: ['jrhima@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH-yuBSYpWoOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1640808444422?e=2147483647&v=beta&t=apt3mlAzI2R5HfUJUZkH_LGh1UZS5YUoSzzThvLbIWc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Noël',
        lastName: 'Johnson',
        emails: ['njohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFfym6KdYtx2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728621283853?e=2147483647&v=beta&t=dOJEefynjq4sURuMN4G-V-byN5Wv1ylxDHnDgn5oJVY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  welcomelend: {
    name: 'WelcomeLend',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jamie',
        lastName: 'Talbot',
        emails: ['jtalbot@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGedtuW32KfZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620131268477?e=2147483647&v=beta&t=AMxdnY33-tIGSo0VSVMVjXppF_S6KbX9TtObwDS99-4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Jaffe',
        emails: ['cjaffe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFxHTgg8RsaHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652830873955?e=2147483647&v=beta&t=34DhD3JHb82G0lO9wwjizyQ0-jH5N_G2YP-ydawvqbA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'Gallinaro',
        emails: ['mgallinaro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH1cL16Mr3wsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699715491511?e=2147483647&v=beta&t=k8wYesdJmS0qNpKjtj1I2bu4I4oeL2sR3DW2DStQyZs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zak',
        lastName: 'Blechman',
        emails: ['zblechman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG_24oc66HYTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678721330823?e=2147483647&v=beta&t=D-zD6exulrJ7t0T2zcVF8PDR1vT15MUX_iDvW0IE6wA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Young',
        emails: ['jyoung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHCs1Jv6-XCOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676300419127?e=2147483647&v=beta&t=WECOUOn00fv_HRmJTznhiE8nxjKVvcrlei4y1sHsiGk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mlbenefitsco: {
    name: 'Main Line Benefits',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steven Leong Seng',
        lastName: 'Chuen',
        emails: ['schuen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEzop_vmofLSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638797821480?e=2147483647&v=beta&t=W_5sOf5lPBrmAbXm8SIwVp-LoGAuR-g6IC-Bvs3D8JQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mary Novak – Digitalization Expert – AnyforSoft |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQExjaWIX_1K7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629208534618?e=2147483647&v=beta&t=ZqEFqmsPcAY_PodKFnQqKh2hYMzSRNmJ7yWQ43zD3WE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yana Chorna – UI/UX Designer – AnyforSoft |',
        lastName: 'LinkedIn',
        emails: ['ylinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFSrmHIrhFEpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634766419758?e=2147483647&v=beta&t=PpGF_RlqKbbY6_OKgmbXLF7C7XPCRzOsFgvl2HAynuc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Alona Drokina – Kharkiv, Kharkiv, Ukraine | Професійний профіль',
        lastName: '...',
        emails: ['a...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEUHmhr5SW7qQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617827378714?e=2147483647&v=beta&t=x6FLgKS9Rig9ApYeoeM_yd8QswRBe5wd3MIognopnS8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yeqiang (Kevin)',
        lastName: 'Lin',
        emails: ['ylin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFSjLZVtlyLJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585724026449?e=2147483647&v=beta&t=TB6tkTEPQyoioNa0_s7xUVnVkqwQZmZiTEUZXOSfUmI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hellotulip: {
    name: 'Tulip',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Roey Mechrez,',
        lastName: 'PhD',
        emails: ['rphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGfm3nR7N8VVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682873774235?e=2147483647&v=beta&t=tQvOhts_Ylcl0V0_ABXj2eoeDWaMTuAjpMUB3DRInYQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Yuval',
        lastName: 'Marcus',
        emails: ['ymarcus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFoX6FSObt8-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689689219446?e=2147483647&v=beta&t=oSwOgo6ULD-8PCCHMjJAVwaRBYN7GlbmOPCDHENBe_c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Natan',
        lastName: 'Linder',
        emails: ['nlinder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGXYVl6De8aYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1576593342480?e=2147483647&v=beta&t=0ylDKP1ez_fzY18-Ab-V9NHNJrih7N8YE39cpZ7TYZ4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mason',
        lastName: 'Glidden',
        emails: ['mglidden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEeswqkZKlLwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694717808429?e=2147483647&v=beta&t=yqWfHEerNJ0x-3U4p44u2l9E7qs89oIQho8zzDChfqM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Erica',
        lastName: 'Jordan',
        emails: ['ejordan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEcu1qXhTkpIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648158445615?e=2147483647&v=beta&t=JcyGWvBjYM_OCCoJs7uKokJAmjulNqgct5mCfURsgBA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vossevents: {
    name: 'Voss Events, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brandon L.',
        lastName: 'Voss',
        emails: ['bvoss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFXG2rgu0YexQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516244727843?e=2147483647&v=beta&t=Y7DxOG-wzWMMbMC5zTBQKUSlmecxi_7HYV2CUO2wRz0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rachel Finke',
        lastName: 'Chambers',
        emails: ['rchambers@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kenneth',
        lastName: 'Voss',
        emails: ['kvoss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG-1pV-T0P6Sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516272013850?e=2147483647&v=beta&t=l8oJTe8XaCsbmtLwsS8q7XgMJvez7psKnALchZ5q-0w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Karis',
        lastName: 'Griffith',
        emails: ['kgriffith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGV25xYDN2TCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611064554664?e=2147483647&v=beta&t=8sXJIJBA3Fwq5nBQ3f3ASwGBvrJ_pjRey6fcynMsbAE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jon',
        lastName: 'Norris',
        emails: ['jnorris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEoscR_a2QoBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517489217196?e=2147483647&v=beta&t=HsZ9ji7qbWTKhMAZsHrNCLVcEIUAmifoCcyGTudL7Ak',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  snappycx: {
    name: 'SnappyCX',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Emma',
        lastName: 'Mutuku',
        emails: ['emutuku@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ayomide',
        lastName: 'Arowolo',
        emails: ['aarowolo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH_vwNDNm0MhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692114796572?e=2147483647&v=beta&t=KZlq0TG49yxniA1T4W3Kcnqvt6hRwHFMPcK7XN1Dli0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Zubaida',
        lastName: 'Aliyu',
        emails: ['zaliyu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Randy Yaw',
        lastName: 'O.',
        emails: ['ro.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFhBCBlISlPSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649654903536?e=2147483647&v=beta&t=OUOT1fA-1zDHCbYFYkQSR0nc0r5jqrTUyHXyvF7EAY8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daniela',
        lastName: 'Agyare',
        emails: ['dagyare@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGP1GBtGpTmMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721222304109?e=2147483647&v=beta&t=9hCXLSJQJAAyiIf3RvpKpLUubycY-A3al-LlZowKLoI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  leadersintech: {
    name: 'Leaders In Tech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'Goldsborough',
        emails: ['mgoldsborough@example.com'],
        image:
          'https://media.licdn.com/dms/image/C5103AQH6pgfpArtbcQ/profile-displayphoto-shrink_200_200/0/1516516282924?e=2147483647&v=beta&t=o9Ht-JOPgbIzeAReRXsaqfL6kaWqQdl_3rXBorgMb1g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abisayo',
        lastName: 'Adekeye',
        emails: ['aadekeye@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG9m6DU0lLWKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635346990114?e=2147483647&v=beta&t=acqmHY3IELpXOlSHM5VEj6KhsGXFX_m4WtqyNKwH_cc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'Imrie',
        emails: ['jimrie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFNpd9sISRf6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715612673393?e=2147483647&v=beta&t=jODHOkjl4ZDqUNZhxpGDVFr3NToUapUM_DbpKXZgfO8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Warren',
        lastName: 'Weeks',
        emails: ['wweeks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHG58fL4Z33lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516266671285?e=2147483647&v=beta&t=lYq5S3uT6U9vUGvuYAsEZeJwx-cXaMQd7Mr9LFWbXrY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Julia',
        lastName: 'Suppa',
        emails: ['jsuppa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH8gOVsUzL2Xw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687130643707?e=2147483647&v=beta&t=9CGi_6H6QCUMilDucH5irJNaaFsA8Z6DtATfkMaw_bc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  monachilpartners: {
    name: 'Monachil Capital Partners LP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ali',
        lastName: 'Meli',
        emails: ['ameli@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jacob',
        lastName: 'Zeldin',
        emails: ['jzeldin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGbmI7zW1wZwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714704019946?e=2147483647&v=beta&t=062JEDhErnNYehR1fl8VSUZNfIc1DNRH4srI1YmnLQk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Maneerat',
        lastName: 'Wongjaroenporn',
        emails: ['mwongjaroenporn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Misha',
        lastName: 'Karasev',
        emails: ['mkarasev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGK2IJBa-GBpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724081841860?e=2147483647&v=beta&t=X2J8tOVU1nZI2-5hzundCRmLZaJokHV9Rywp5qCMRVo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mohsen',
        lastName: 'Ramezani',
        emails: ['mramezani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGWxVcOI1551A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516326144207?e=2147483647&v=beta&t=nPAx_B5lllVo-vTx-yWSCuRW2_hhB0ndjU1pgNpWkKk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rows: {
    name: 'Rows.com',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Heath',
        lastName: 'Row',
        emails: ['hrow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEW01phtYzgbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619134549656?e=2147483647&v=beta&t=rrVbxEqqUrbLups7yehOyDPTJken2DzIRlYCA6_a9KU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Row',
        emails: ['rrow@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Peter',
        lastName: 'Grubb',
        emails: ['pgrubb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHUgJLIYw66wg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516357384777?e=2147483647&v=beta&t=wnTJxLxwHDpiyW5rBowhDjgkY5zWfuEKmTx4uMbOhoQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dustin',
        lastName: 'Row',
        emails: ['drow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFb6mIiYCpc2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517594187212?e=2147483647&v=beta&t=queA8gLxSSWpqCKHCIxk3gyVb8ST3FEODD7dBVXhQGU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cynthia',
        lastName: 'Row',
        emails: ['crow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEgBGOpK2bW2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517376473874?e=2147483647&v=beta&t=NUcyF0XODOZEnlKxqDp67845O8Briqs8Slaafw6J-E0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tstpros: {
    name: 'The Solar Team, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jasmeet',
        lastName: 'Singh',
        emails: ['jsingh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'GURJIT',
        lastName: 'SINGH',
        emails: ['gsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEBM2BICN0Qxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674516322455?e=2147483647&v=beta&t=WOnBX3nGP2Sary3kqq0ykvew3yALvT8jyQn0nTVVUc0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jason S. Trager,',
        lastName: 'Ph.D.',
        emails: ['jph.d.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Antonio',
        lastName: 'Baclig',
        emails: ['abaclig@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Giovanni',
        lastName: 'Amenta',
        emails: ['gamenta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGIlX7pFfdNXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718778853168?e=2147483647&v=beta&t=Mx4wCssHVyVVpmO7F5juu18L7ESh2dyswMB2ytPy3Wk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hostai: {
    name: 'HostAI YC W24',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jared',
        lastName: 'Levine',
        emails: ['jlevine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEkQHXTfSe9Kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726156642101?e=2147483647&v=beta&t=uv4zkSTTcvbm1fGBCyOV2ReuD1TDv2tOnJt2j3qF1ak',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Yumi',
        lastName: 'Wilson',
        emails: ['ywilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEUAdovOUcGXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726259961667?e=2147483647&v=beta&t=bVmMpWbjj61sJqDQ0gQ8dd9kbVA2LiotDTaklly-JGE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Taylor',
        lastName: 'Host',
        emails: ['thost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEkRwmR9aNnPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1528303390413?e=2147483647&v=beta&t=F9jMWWH8yiCZkmW__Lr7P6QZYfnsQHWRO85cm5OPsGQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jenn',
        lastName: 'Mishra',
        emails: ['jmishra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGt1jxUD0wTmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696168407544?e=2147483647&v=beta&t=hl15o6iW0DbR45IKvutbPpg36mk1R1R_GpX7dcvGNzM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sarah Clarke',
        lastName: 'Stuart',
        emails: ['sstuart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHtF1rJJDUfwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681737621904?e=2147483647&v=beta&t=7KnXZkFWlP513jvwZ4n0k4Oqaod3cJiR3GxY3ABf3Nw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gs1pt: {
    name: 'GS1 Portugal',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christine Hardy Hutchinson',
        lastName: 'Vega',
        emails: ['cvega@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEvBm-wY-MxMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709322780110?e=2147483647&v=beta&t=VTBvgoyNicvj44KhQnzHwq3bSuBxxa0-XSinVs4NzTE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rogério',
        lastName: 'Proença',
        emails: ['rproença@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFAd2UNmpmUIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706520734153?e=2147483647&v=beta&t=YjIOzQk12kyTIq4z-_HcmAKaDpE3mxt4ZCD_D4pI65Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Beatriz',
        lastName: 'Águas',
        emails: ['báguas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQENWxh0ZUw8KQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680019120856?e=2147483647&v=beta&t=v-tX3fdCo3hBiBGBm_Hg-s4fy73cxPgICp2wr-M69s0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paulo',
        lastName: 'Barata',
        emails: ['pbarata@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ana',
        lastName: 'Ramalhete',
        emails: ['aramalhete@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  skillsAgency: {
    name: 'Skills Agency',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Husna',
        lastName: 'Kouser',
        emails: ['hkouser@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Spencer',
        lastName: 'Mehlman',
        emails: ['smehlman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGZuZsQEI2RwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728417068228?e=2147483647&v=beta&t=opxEZ_3vXPEbk-OvcBdS_qkovLEVgigmdd6FIgW0tfI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daniel',
        lastName: 'Merriott',
        emails: ['dmerriott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGOhn9-adhmgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710832188727?e=2147483647&v=beta&t=LdGHFj8q6GiyCkOH19ITZ47q3hZL7El-_O1JLRg1Eac',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joe',
        lastName: 'Simmonds',
        emails: ['jsimmonds@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGO76ckoZCsZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693555777944?e=2147483647&v=beta&t=bNbqWIe3YKh7Q6SsAFjAQ11B446vT03YXAs5E-FqjM0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tristan',
        lastName: 'Boot',
        emails: ['tboot@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  axiaconsulting: {
    name: 'AXIA Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul',
        lastName: 'Jorgensen',
        emails: ['pjorgensen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGf44vvEkjpKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680724278917?e=2147483647&v=beta&t=Cpunb3kwgG_LspKOxgCVoXQ835pURA7ta33ci4LwTfg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Libby',
        lastName: 'Woods',
        emails: ['lwoods@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFKWzuZ2VJlcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720619230028?e=2147483647&v=beta&t=7hX8z3VvOLCRA7fH0Z-Zb9ay9ngXL2Lkm6REd2qxrHw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Justin',
        lastName: 'Browder',
        emails: ['jbrowder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHlMuy2sulDCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657158567186?e=2147483647&v=beta&t=Zr-yZ3FkvVgZlMsW1ijPdBG27bc9a0Aw7WBvc5hZY9c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brittany Marcellino,',
        lastName: 'SHRM',
        emails: ['bshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFxWyLG4r451g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635300391053?e=2147483647&v=beta&t=uHVCtQqWUIKrpWjJNRYeVUs1ztp3Ev_oeW6naRs1wjQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cheryl',
        lastName: 'Myers',
        emails: ['cmyers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGFFtF6wuK7BA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723132651487?e=2147483647&v=beta&t=SnFRB9dZ_l5V26qE-obyjc_fl5oTAIs5frioyRbAztc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  docuphase: {
    name: 'DocuPhase',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dan',
        lastName: 'Gaertner',
        emails: ['dgaertner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEEuQh7xnTPqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719718305607?e=2147483647&v=beta&t=Jd1lL6Uzvx2Lr0ZaPcRAJJbOc8KJKPchsyM81Wkz77A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bill',
        lastName: 'Rooney',
        emails: ['brooney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGQUIyNMKfjrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635601685774?e=2147483647&v=beta&t=7YXfGTNM3a2_L1ODIE2Niujqxeccj087QdbZnWH663A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachel',
        lastName: 'Curtiss',
        emails: ['rcurtiss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE4nSPVQr_CQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700085749780?e=2147483647&v=beta&t=t6o7MGcl-FjMJ1Pod1qhR0JtOG-x1xn50Dxd0rCVVwo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kolleen',
        lastName: 'Doucette',
        emails: ['kdoucette@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Meghan',
        lastName: 'Furtado',
        emails: ['mfurtado@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEUDIy_IoDnGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689382467650?e=2147483647&v=beta&t=qMTANoXmexXi2hMqjWlCI1u6RQjc204aUDy8yySXjaQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  promx: {
    name: 'proMX',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Keerthi Punna – proMX AG |',
        lastName: 'LinkedIn',
        emails: ['klinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHI48jsK5cUAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688008794866?e=2147483647&v=beta&t=RGrfYOPHXqSrCLOoXJ3GElVuCa5cVU-Wzf_PRsq70iw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sofiia',
        lastName: 'Sknypa',
        emails: ['ssknypa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH98JdWQzqTmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632746806280?e=2147483647&v=beta&t=1BHwVmalEsKPtnF3tXws8uYhPqT6hVYRoxQRU_8G01I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Armin Odorfer – proMX AG |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael Schlund – Recruiting Manager – proMX AG |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Raju GS – proMX AG |',
        lastName: 'LinkedIn',
        emails: ['rlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEVXsfRZOX2oQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663080445401?e=2147483647&v=beta&t=PYLUCnYU09fCdOryefeAlaiNBaUwHRhoVKYjB9yKXzs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  webuters: {
    name: 'Webuters Technologies Pvt. Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kirti',
        lastName: 'Mehra',
        emails: ['kmehra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHYXzyRpUpTSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649050612853?e=2147483647&v=beta&t=0u1pponFrTPV6KlEt6p_7rZOIZWbPOo6mwUkEDc1qdo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kandukuri',
        lastName: 'Shravanthi',
        emails: ['kshravanthi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nisha',
        lastName: 'Bhatt',
        emails: ['nbhatt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEzY_TY0RehYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694921979923?e=2147483647&v=beta&t=ZN8Ch-AveHGUYloM1dHMQ2iPpEUnFB-vXVC_ZoXc0s0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Neha',
        lastName: 'Singh',
        emails: ['nsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHFv-xo_jc6jA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560085359089?e=2147483647&v=beta&t=rLhBQMCe7YmYsOMBzSorfGRKVT7T0vwZAP2UMfkNkgI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Himanshu',
        lastName: 'Kabdwal',
        emails: ['hkabdwal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFNDTF_6JHhRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681646112259?e=2147483647&v=beta&t=xDO36ny3k8UrqcwltaXWQozWhHWIn7m80kwmGImIVDw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  webllisto: {
    name: 'Webllisto Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Akshay',
        lastName: 'Sharma',
        emails: ['asharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEHdpmFCSfXSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657189047645?e=2147483647&v=beta&t=BY9vgtXVTPtQixV7GZI47A5QjzhMeSfeA_lJZzp-IpY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Richa',
        lastName: 'Singh',
        emails: ['rsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGJwF-iwQ5C5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690365313523?e=2147483647&v=beta&t=A97YQDxLfAy8kZnj1iYLAfAPcHDZ8FoUOeNTdEuDKFw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Danish',
        lastName: 'Ali',
        emails: ['dali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEz2Ghk8rodkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668580522156?e=2147483647&v=beta&t=6s7klShxKDpYjnCSOR_kf72wlgCPWy5CKVDArmaedPA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sudhir Singh',
        lastName: 'Rajput',
        emails: ['srajput@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFXBrGzd_982g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708678022182?e=2147483647&v=beta&t=02S_9x5KPPVF0TZU0yvD6rEnYjwlNAqNsfgy7DaU3Cc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bhupat',
        lastName: 'Bheda',
        emails: ['bbheda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE0-nzxkzNlMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657534406301?e=2147483647&v=beta&t=QtBhgXjUyfMftXq1AW9Q93OzoXbj6dit2Xuoa5AQ2Xs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  videopro: {
    name: 'Videopro',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cameron',
        lastName: 'Douglas',
        emails: ['cdouglas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGJI4CqMumq8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580711323661?e=2147483647&v=beta&t=gYXlT3ehuEfPiuJf-Apjt5pncwJpsEM83PE5ZqPff4g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matt',
        lastName: 'Brown',
        emails: ['mbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE0cGEzQybm6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638326987835?e=2147483647&v=beta&t=o8AZ6wrT73knzjIUtTemfMuQ-sS_4ZBr1hPwoGHutnc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matt',
        lastName: 'Lynn',
        emails: ['mlynn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Massimo Rosazza',
        lastName: '(MAHRI)',
        emails: ['m(mahri)@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Chaplin',
        emails: ['mchaplin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGfr59-UeF8Og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516956021344?e=2147483647&v=beta&t=AYHa2QjZ0m4A4s4Y49q0jgI--pz9qOsIuDdfBEToxE0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  invokeinc: {
    name: 'INVOKE',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Johnny',
        lastName: 'Ramondino',
        emails: ['jramondino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHnFPMtwJfWxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516260803754?e=2147483647&v=beta&t=VnKbYLjXzVTyR2pI5dFFiSmtXrykjPbI7pqRWoeGeA8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steve',
        lastName: 'Holt',
        emails: ['sholt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHkyc89AIVW3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589827851867?e=2147483647&v=beta&t=3tAuWeEhQU9S0GXOGmOJTz6Ow4Xkx3DnGKzGU0cu6RA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Harp',
        lastName: 'Brar',
        emails: ['hbrar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGgK1GUhtJiHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573831119047?e=2147483647&v=beta&t=4uTQqCGl2ScTObrXUgcMIDv-ADSKN48ts8ScR5yY8gI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jimmy',
        lastName: 'Patel',
        emails: ['jpatel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Barrett',
        lastName: 'Hall',
        emails: ['bhall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEMiCkZNiOWmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722532266029?e=2147483647&v=beta&t=Nk_NjTcVngG6qeSDFxNrT1ScRajr-qWTNDXYj973Ax4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  infina: {
    name: 'Infina',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dale',
        lastName: 'Hopper',
        emails: ['dhopper@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Infina',
        lastName: 'Health',
        emails: ['ihealth@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sue',
        lastName: 'Meeks',
        emails: ['smeeks@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sudhakar',
        lastName: 'Shanbhag',
        emails: ['sshanbhag@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH5fjHZt4Otnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614675168470?e=2147483647&v=beta&t=7i_9jMeFrTQatMCw9RapUYeHzFvNHi5F4qIq_Gr3GIM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Keyur',
        lastName: 'Gandhi',
        emails: ['kgandhi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  obligeit: {
    name: 'Oblige IT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Abdul',
        lastName: 'Mohammad',
        emails: ['amohammad@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hakeem',
        lastName: 'Mohammed',
        emails: ['hmohammed@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mohammed Naseer',
        lastName: 'Ahmed',
        emails: ['mahmed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG0QdobrvizBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710499391805?e=2147483647&v=beta&t=3KBCq7jgAQlMVJYANoTAOIvEJ78hZ75GFvFTwPYmI-A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mohammed Faique',
        lastName: 'Aziz',
        emails: ['maziz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH0gwgyK3dnhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630329013629?e=2147483647&v=beta&t=wEJPNWJmz4ezDFJSM2FInGbd7xuzcHiI0oH6bh3LqNk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mohd',
        lastName: 'Hassan',
        emails: ['mhassan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGQVHTzn_lqoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673522120119?e=2147483647&v=beta&t=InBuL5RpY4fWu25YjQ0_dFrFPUCEuCW7p1mmfl3Rl54',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  symfa: {
    name: 'Symfa',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alexandra',
        lastName: 'Shpak',
        emails: ['ashpak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH0yqckM6YnNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697706729849?e=2147483647&v=beta&t=R3Y_DMaO5Lk9u6-aIS19vIU-M24YBzxVNlIWwn--OAs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ilya',
        lastName: 'Mokin',
        emails: ['imokin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHTAe2Z-RD9VA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661260638524?e=2147483647&v=beta&t=qFcUfjBsGOexxhR03CnUfHKVy4xh78MUmFmVSQjS1HQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Olga',
        lastName: 'Popova',
        emails: ['opopova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGK_VURyRqqjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676237169073?e=2147483647&v=beta&t=g8W_USzVbKpGX3oI0P9TePyh4chDBP854XX80hGasCI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anna Aleksina – Symfa |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH7zw16KFHWtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669327249607?e=2147483647&v=beta&t=GYG_kjt_VwUEnmmfQL_xBAiqvqRTvBU0g3QVFFWEKJg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Asya',
        lastName: 'Dziuba',
        emails: ['adziuba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHy9UvoXmVepQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724276707293?e=2147483647&v=beta&t=Hl63lCw8UZUHYd-U8_i1Jr-UqA0eqJL0AjimVTVVErs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  antsglobe: {
    name: 'Antsglobe Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Khushi',
        lastName: 'Sharma',
        emails: ['ksharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQERR3bY3UaWxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692346090344?e=2147483647&v=beta&t=ypC7m_hrI5yE3mX7z5EBw51-amms0Y3T3IjybKVtrXA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Florina',
        lastName: 'Anthony',
        emails: ['fanthony@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGtnMBf-3z0OA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634975662933?e=2147483647&v=beta&t=3r22mIXPZZUjGIVCI9jWHIKtovxJfN-uX-hiEeJ8xVQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shivani',
        lastName: 'Awasthi',
        emails: ['sawasthi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHe7tVeEL1bmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622988550311?e=2147483647&v=beta&t=uOjDpV6qo5FVroWHzpuwIbwJqQhnbrlVQWLLZbO46fQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alpana',
        lastName: 'Dutta',
        emails: ['adutta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEhHA6x6YoPiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681065573623?e=2147483647&v=beta&t=MmGsgmL4atoxLizr08QcAJZdHzNXTUMPH_MKd_UJLf4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sheena',
        lastName: 'Pathak',
        emails: ['spathak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFWdM4-PqJxjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730190089519?e=2147483647&v=beta&t=OgYcXz5rKRLx5S08mZVCElkBDaikpFJkJbw2UdTszEY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '5d': {
    name: '5D Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Abhik',
        lastName: 'Ray',
        emails: ['aray@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF9UgKj5X3tvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517448219792?e=2147483647&v=beta&t=-_EtGTAbkdLFBD-Js8iCA1uFO2s38V7VIcu5GzymeSM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sagar D',
        lastName: 'Chavan',
        emails: ['schavan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mahima',
        lastName: 'Waghmode',
        emails: ['mwaghmode@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shoeb',
        lastName: 'Shaikh',
        emails: ['sshaikh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQESmVCvpWDNuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591013420435?e=2147483647&v=beta&t=9lvrs3MRRjzeiKoIWg8BOGgAiz3Av32go8B2hHmDM5o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pranav',
        lastName: 'Parikh',
        emails: ['pparikh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jungo: {
    name: 'Jungo Connectivity Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Steineke',
        emails: ['csteineke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGVHLxIo1FnbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727366745603?e=2147483647&v=beta&t=55G5MDD3rOUnbCIsnaSDSfuN2Objq1OYQaYM0hTIrpY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Yuval',
        lastName: 'Rom',
        emails: ['yrom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH_1PVitm-Gng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659982367808?e=2147483647&v=beta&t=K99nIWNLijA9Ir3FswqJt2WEIX_Kprq9nLyIPzXRRoU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Leora',
        lastName: 'Schwartz',
        emails: ['lschwartz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEfex7ndn7J-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725197085143?e=2147483647&v=beta&t=OdQOMImVDjffJTDw2d-5YXQ_zK0ISVLj67W7zz4b88Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Opher',
        lastName: 'Suhami',
        emails: ['osuhami@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Uriya',
        lastName: 'Bartal',
        emails: ['ubartal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  meridian: {
    name: 'Meridian Solutions Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'jijish p',
        lastName: 't',
        emails: ['jt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEitGAJIUvjbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546960795942?e=2147483647&v=beta&t=zZrRWbPHMv6a78QDLlmDioFrWRA_dNlWMmRPOT82cWI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Business',
        lastName: 'Owner',
        emails: ['bowner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHxPjAejlCoZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625204866802?e=2147483647&v=beta&t=G4XVANyUqmRDmRccAhcLQFOlCYCVWXPJG_NdavtDGsk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'PRAVEEN',
        lastName: 'MENON',
        emails: ['pmenon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHQcTqn3Ho0yA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686134092023?e=2147483647&v=beta&t=j7gQkxxxhOoYvxHXCtqI2jV_Y3Z7ncxu5yo5SYO1GeQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Muhammad',
        lastName: 'Fazil',
        emails: ['mfazil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGGI1wAmJbYew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634801648904?e=2147483647&v=beta&t=kp7kf7O49tfIsXXC4eNfNYQRb_HQ5OeCQRRSPX7UOdI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rajeesh',
        lastName: 'Punnath',
        emails: ['rpunnath@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHqqXUguE0b5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572008094551?e=2147483647&v=beta&t=_SCKLelTLxw4gx-ivkfbzx7aRmx28A0aklac5jUcnf8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vmock: {
    name: 'VMock',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Salil',
        lastName: 'Pande',
        emails: ['spande@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH1mRQY9Yi3yQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564698259511?e=2147483647&v=beta&t=tj4yAc-lj4kBFQ6B97zOw7CvfQExMEf11a6mhmhgBIo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kiran',
        lastName: 'Pande',
        emails: ['kpande@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGASnXZepJXdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517723188973?e=2147483647&v=beta&t=hapvhPTE_zvMGGoJeP9YDOjAevX9ayWZBkdKVHTbOiE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'jay',
        lastName: 'shankarcc',
        emails: ['jshankarcc@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pallavi',
        lastName: 'Railkar',
        emails: ['prailkar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQERWIt_K2JgPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568307120139?e=2147483647&v=beta&t=MrphlHXS0U3n8Htuc0DVhED-NZs12Ovcy2qqw91T7Og',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Manu',
        lastName: 'Varma',
        emails: ['mvarma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cinntra: {
    name: 'Cinntra',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Laxmikant',
        lastName: 'Dixit,PMP',
        emails: ['ldixit,pmp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Harsh Vardhan',
        lastName: 'Sahi',
        emails: ['hsahi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEyMavlAjrDZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721448220697?e=2147483647&v=beta&t=58WkuSaBRt7QoYZAb05WcDI1zDRQcahXUqdM12xcr_0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nipun',
        lastName: 'Dixit',
        emails: ['ndixit@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEyyZ0pZczf5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689769694941?e=2147483647&v=beta&t=jkjqCBM1wHj1TW5nDp2YTr2eN91oj_Zm4Y4KADN2R3k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sagar',
        lastName: 'Jain',
        emails: ['sjain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFjPYK2hJX7MA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661577133811?e=2147483647&v=beta&t=jHn2XxrsntlY72uY1i3jWhom-Td05x7-eKsEQqOGLwk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Siddharth',
        lastName: 'Kashyap',
        emails: ['skashyap@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGn_4dv6mM6Wg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718274636552?e=2147483647&v=beta&t=ZyLN3pF1xdBW-13jh7TdhcXNbkPzA8EEnQylZMgwcJU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  celcomsolutions: {
    name: 'Celcom Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Baranitharan',
        lastName: 'Krishnamoorthy',
        emails: ['bkrishnamoorthy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHJEJgeQSaTZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705995709699?e=2147483647&v=beta&t=krVNvW7KSIsGSVeBgt5fVud9305IiE-Rr9sMrGBU8QY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shashikant',
        lastName: 'Acharya',
        emails: ['sacharya@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anand',
        lastName: 'Beewin',
        emails: ['abeewin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGx8oj7IFr5vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653937184816?e=2147483647&v=beta&t=yrKHZDcPmm9O8ffqSd5Y7w94pOVlkMjVhYnWgtTcY7Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Subhash',
        lastName: 'Kakarla',
        emails: ['skakarla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEQoXE8Si73hQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723755608851?e=2147483647&v=beta&t=eou08hOwKQmC7Uvp3RzRycpzJ9Wx1n6hvZT_kRkLwIo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Karthikeyan',
        lastName: 'Natraj',
        emails: ['knatraj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEjRof8QUU1ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516843859977?e=2147483647&v=beta&t=K_EQczxLg4w73J5ju1rkBL_qV804lMRgAae6uAfvbPc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zilliz: {
    name: 'Zilliz',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Petre',
        lastName: 'Sebe',
        emails: ['psebe@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Churilo',
        emails: ['cchurilo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Charles',
        lastName: 'Xie',
        emails: ['cxie@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Xiaofan(James)',
        lastName: 'Luan',
        emails: ['xluan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGy2879O0cDNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516457057070?e=2147483647&v=beta&t=Z-nes5BF3Ah9cHFsNr9inFA0SsUnCe7Z4Y14AvbHD3g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yujian',
        lastName: 'Tang',
        emails: ['ytang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trustwell: {
    name: 'Trustwell',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lawrence',
        lastName: 'Cohen',
        emails: ['lcohen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFsE47SESxiHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541161630882?e=2147483647&v=beta&t=GDfGly3hOIRh7Ahh2rMcCIgXbAsQ07YRPgCFHhfQ0cY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gary',
        lastName: 'Fernandez',
        emails: ['gfernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHO-enZZSQRZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516771871501?e=2147483647&v=beta&t=pzUGYGGN53FiDNL_gthgp7Z3bwLeGnHlB6X0Syikrxo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Katy',
        lastName: 'Jones',
        emails: ['kjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFCLlOmeBBrrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674338941255?e=2147483647&v=beta&t=4A0r4S1DB-igpv2fiwOoCqbw9X7fD7MxNLI1yFhFRkA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexa',
        lastName: 'Wheeler',
        emails: ['awheeler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEOpTaRrueUBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718239633306?e=2147483647&v=beta&t=_p9-QXnROUHqZES4b7repCC29E897PWkKTyQ7JvcMto',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Nicholson',
        emails: ['mnicholson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGErHMWHkZuHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516958541680?e=2147483647&v=beta&t=J-iZuCTAJyyAOy1_K7-2SbvJcoFIYY3jBA31Ka_CoWo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  victorylive: {
    name: 'Victory Live',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Malin',
        lastName: 'Luca',
        emails: ['mluca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGsjASKIrNhCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706300553984?e=2147483647&v=beta&t=BPsKNfKbTQFVo5J_fZ7FGLprE4Yv2wHcLzsPTKFkAsY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Weisbaum',
        emails: ['dweisbaum@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG7GVX9Bzmf1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680478506123?e=2147483647&v=beta&t=fmMEtP2_erx2QCVPXcsMZ7dqqhGoio4WzdDa-PqqnD8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sathish',
        lastName: 'Gaddipati',
        emails: ['sgaddipati@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Curtis',
        lastName: 'Cheng',
        emails: ['ccheng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFsEv1XATvxDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560375785185?e=2147483647&v=beta&t=33Uza6FiVwVBIlNlb2aK1If3eDe1X5tTWv4q5TKKxhg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Manohar Reddy',
        lastName: 'Dendi',
        emails: ['mdendi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hyperpay: {
    name: 'HyperPay',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Muhannad',
        lastName: 'Ebwini',
        emails: ['mebwini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF9VsBy4eUJ4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730818166810?e=2147483647&v=beta&t=XzqGfazIOazOO-sL8qaZsmRslV6WGsaXxDT7hYI1ev0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nile',
        lastName: 'Younis',
        emails: ['nyounis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEfDSAPB9Pq9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648792656949?e=2147483647&v=beta&t=SW3AAmVnn9HoZkma9v6UHSWjf3l-qreGBz_lUQN2gW0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dave',
        lastName: 'Harrison',
        emails: ['dharrison@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Esra',
        lastName: 'Sehweil',
        emails: ['esehweil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGXHaD_ojOJ9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580911846528?e=2147483647&v=beta&t=dkp7qRDX_bntBJBJ5rmkmyBUqwAFGHZeKRCA47ZrD_k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dalia',
        lastName: 'Khammash',
        emails: ['dkhammash@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  digitalapicraft: {
    name: 'DigitalAPICraft',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marco',
        lastName: 'Tedone',
        emails: ['mtedone@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bharath',
        lastName: 'Kumar',
        emails: ['bkumar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sreelatha',
        lastName: 'Maikkara',
        emails: ['smaikkara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHRnb0ss-HsrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719384712718?e=2147483647&v=beta&t=AYFwGW8B6lkNFU3_C_oACzeDj7-XOIqC1T6wCaarmIg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gishnu K',
        lastName: 'Nair',
        emails: ['gnair@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE8HjNaTXbc0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714315417344?e=2147483647&v=beta&t=T5wPqxf4a-dj5eEh6HhmCcZKlOVbJfcfTLSzIqu1szM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mansoor Basha',
        lastName: 'Syed',
        emails: ['msyed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHgN4w8U0N8SQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671470274064?e=2147483647&v=beta&t=zQ8ws_-LjN-vsfrq2sx3mgqpB1UhLBvImPzgsJjweQM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lera: {
    name: 'Lera Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shanthi Narayan',
        lastName: 'Batchu',
        emails: ['sbatchu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHWmVxbE45Jdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585188581190?e=2147483647&v=beta&t=rIGldopLGNXKD5bg33gi4i-S-go-HVI0IyyPowoLdsY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'lera',
        lastName: 'technologies',
        emails: ['ltechnologies@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lera',
        lastName: 'Technologies',
        emails: ['ltechnologies@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG-dpY-lvXTPg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517566469236?e=2147483647&v=beta&t=CzIyyuE9yeDduomavuhmG9_1Goer7zb1XvbT6_5xCKQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hari',
        lastName: 'Puli',
        emails: ['hpuli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFM6EdL7eNmPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710940315484?e=2147483647&v=beta&t=4r9yBMf4jis2RPo6f1y4S1-OjiL-XxVV5JgC0283MVU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Victor',
        lastName: 'Fernandes',
        emails: ['vfernandes@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  armenotech: {
    name: 'ArmenoTech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mery',
        lastName: 'Berberyan',
        emails: ['mberberyan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE-QmLCR87v7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710402778504?e=2147483647&v=beta&t=rNE3eoag2Jk6b5KECrnDXP1tao49qXK_TPRUxiZaF-Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vladislav',
        lastName: 'Abramov',
        emails: ['vabramov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEN5628kpBv-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669285067322?e=2147483647&v=beta&t=N155q6A8DQgdD09okUcRe2cQTboMTnOTELSzS2ITKL0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alexander',
        lastName: 'Karle',
        emails: ['akarle@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marina',
        lastName: 'Livshits',
        emails: ['mlivshits@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF1a7jaL05rIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700048957949?e=2147483647&v=beta&t=S8ekxA6ZM-3wP1tmid554EtqK36XCnHwVUmBni_J2OQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ani',
        lastName: 'Nalbandyan',
        emails: ['analbandyan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGgdwoawSrteQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708327293958?e=2147483647&v=beta&t=NKbZnIm1nfXQknJWZHQljtYps4aZpSIXDHtj8BPtaoM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  quantropi: {
    name: 'Quantropi Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Nguyen',
        emails: ['jnguyen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH-OK7cZryq7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728217471781?e=2147483647&v=beta&t=xh4pDODBl6-OCkV0gpCvMdUXISJt-UDShK73TA5DWos',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dat',
        lastName: 'Nguyen',
        emails: ['dnguyen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFSJHzUfyArwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730532164683?e=2147483647&v=beta&t=m6c8MlV1bdJVFvi9FRaYw6te6xS9t0TCr0vzVvG04eo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jay',
        lastName: 'Toth',
        emails: ['jtoth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFwaccc3EAYrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696335915015?e=2147483647&v=beta&t=99coSLRNWZdMQrdDRKZHc2-j9lBSsxDjVYhBFvh3oq8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Le Minh',
        lastName: 'Ha',
        emails: ['lha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGy5bI-0ern0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543686426969?e=2147483647&v=beta&t=DeoX_vww_zjiVLnavbfrh7yYw4tIVLX1eHyB1PPnoVc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pauline',
        lastName: 'Arnold',
        emails: ['parnold@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH6cpZpraikBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610562727230?e=2147483647&v=beta&t=NQ-V2tgQmB9r0wYeDKJ7kHb4o8Ey5_ls2mHfoUZU318',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kissterra: {
    name: 'Kissterra',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ifty',
        lastName: 'Kerzner',
        emails: ['ikerzner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF6PiI4J38fdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634655996527?e=2147483647&v=beta&t=3YUQH0BQKOV5ZbwjvWle7Aqb0nY8At5MgDJszW0rb44',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ziv',
        lastName: 'Chen',
        emails: ['zchen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bonnie',
        lastName: 'Moav',
        emails: ['bmoav@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQExulrDwYufZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516990567256?e=2147483647&v=beta&t=E_56LZbAa7nra0Qn821kWlIAA8_NYuMX_AM2UOG5Hvo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ethan',
        lastName: 'Jones',
        emails: ['ejones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFxIPspKolnEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651509651875?e=2147483647&v=beta&t=dBpIzdc-_NnVC6M1fJVCqsoR8CugIa7KKPPHj_3pLaU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eric',
        lastName: 'Seidelman',
        emails: ['eseidelman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHcFff4CmuTzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659641599624?e=2147483647&v=beta&t=qESNDDYxYeHiXpJz4oyRE8vBVHaM7fj9ioc6OKIDFkM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ctidigital: {
    name: 'CTI Digital',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Woodward',
        emails: ['cwoodward@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rik',
        lastName: 'Hopkinson',
        emails: ['rhopkinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFqovqiWzEsyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697105077230?e=2147483647&v=beta&t=dNPtUl5Vivd3l1ROEZA7Kt4m-HwfWEQRW8oaW07NKSY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bobby',
        lastName: 'Blaney',
        emails: ['bblaney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHDVQOEhbBavg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701700973615?e=2147483647&v=beta&t=RUWfVcShcRtysHunfo8W-2Iz-oTJA-0ysEJ6So-rlYQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'Davies',
        emails: ['mdavies@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHsV0g5_JeGhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704833401216?e=2147483647&v=beta&t=SAs8ekeQBjXviuPTl3n0yCWSb_HROje6Q2w7ghgFNxc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'James',
        lastName: 'Tillotson',
        emails: ['jtillotson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cybergizer: {
    name: 'Cybergizer',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sergey',
        lastName: 'Sergyenko',
        emails: ['ssergyenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFs-2nZXrIP0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721669975225?e=2147483647&v=beta&t=DN-mgTGJWhCbDVG6muxMZxhVIt17PLiao8TsA8MLnPY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vladimir',
        lastName: 'Sazhin',
        emails: ['vsazhin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHWSYJfePuSgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653914143782?e=2147483647&v=beta&t=3k21SsA0QyPQPFY2nOC5Hed0-gHlrLYvfW696_Lbk0s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ekaterina',
        lastName: 'Veretennikova',
        emails: ['everetennikova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFGKHorpzQQPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633873152205?e=2147483647&v=beta&t=1CTCbSAwFwpXe44rEnRBaFjOV4HzTyebbMwJNgNvgIw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yuliya',
        lastName: 'Sheremet',
        emails: ['ysheremet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE-rMWjKkafKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605465093103?e=2147483647&v=beta&t=Mt9J4_fGVLskUuE5usUYHsZ2cTR2jMADx4HqeiH1B2E',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Viachaslav',
        lastName: 'Khrenov',
        emails: ['vkhrenov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHgodVzUrmOCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517395188249?e=2147483647&v=beta&t=l41CWgHRUabF0-3Q27txs4_sxisLFzMiwiaigzkEUf8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  atrcorp: {
    name: 'Advanced Technology and Research Corporation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'T.J.',
        lastName: 'Edwards',
        emails: ['tedwards@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFShzdJlQuVwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516499363473?e=2147483647&v=beta&t=b-CC_OZ8mq_nGiWTO89pLLEZT1g8OhvtIh9qt2vvd5k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jackson',
        lastName: 'Yang',
        emails: ['jyang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGvqsqNgBX6Lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517629543210?e=2147483647&v=beta&t=vA1eI9ccgc8YSu_9Mrq5pyGcb1A04O_hKAwHmORqNBQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lauren',
        lastName: 'Almes',
        emails: ['lalmes@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brett',
        lastName: 'Holt',
        emails: ['bholt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jesse D.',
        lastName: 'Story',
        emails: ['jstory@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  visualdx: {
    name: 'VisualDx',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Art Papier',
        lastName: 'MD',
        emails: ['amd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHf-CONEPKAbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597773405762?e=2147483647&v=beta&t=CXHvNO1rbY0FCRmn1GecL6Th7Q-GDa4InHhTpTPtuWs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bernie',
        lastName: 'Mangano',
        emails: ['bmangano@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHNJLaIPraTGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570455445614?e=2147483647&v=beta&t=63HeIIlVnjc9QQmpVPkSw7V9wuTjpG4yA41AB2cN6SM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kate',
        lastName: 'Edgerton',
        emails: ['kedgerton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGoeI3eeI0tEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612034434231?e=2147483647&v=beta&t=tjXBjIxVM2D9ciTpC82shEMyMQaJJR3KpeO9KiPaMhA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bill',
        lastName: 'Nussdorfer',
        emails: ['bnussdorfer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE9TqBoephLhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634749033157?e=2147483647&v=beta&t=EjHXc5eAcRrZCQHsoah0C0QRpBbqif6cW2Mvf-DCg4M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joe',
        lastName: 'Spadoni',
        emails: ['jspadoni@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQENOGJ1cU1RSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663080440426?e=2147483647&v=beta&t=z-Qn2CHA3cH8sZjWCfJMtSj1M0QZyDmjUGYUuR0M00w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mobisystems: {
    name: 'MobiSystems, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stanislav',
        lastName: 'Minchev',
        emails: ['sminchev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEQ0lqI7e0stQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567163730191?e=2147483647&v=beta&t=jOB1R02dQnqU4aHMBletvPNDR50JhyH0FLnJ6RceUlc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matey',
        lastName: 'Mateev',
        emails: ['mmateev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFuHeMTuKscSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517058351262?e=2147483647&v=beta&t=xSfOs4At3C46WXhh957vC7G4U1IsqoQeHVqPb7xsgqw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Justin',
        lastName: 'Priestley',
        emails: ['jpriestley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGRwW-FcY7PHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517687732878?e=2147483647&v=beta&t=4L84-msYD8ueoLZ2OmSpjLVQ3JzugAReOUKNlDm4pYI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kenny',
        lastName: 'Smithnanic',
        emails: ['ksmithnanic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHiPJn9C1qCiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574692566043?e=2147483647&v=beta&t=GzZcDwy64fMw5-QysOAP3p638DRoe3D79WBWvxEF3n0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rob',
        lastName: 'Wolf',
        emails: ['rwolf@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFgGUoLqi5olQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718309975961?e=2147483647&v=beta&t=nnjlOg8lhDaEUaJx7yOybJCWnxz0wuQAJACeuwY8fN4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bigbang360: {
    name: 'Big Bang',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrei Banea,',
        lastName: 'M.Sc.',
        emails: ['am.sc.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGFN9iXKu1Zbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660659413742?e=2147483647&v=beta&t=5G2cC2Ai1bgWtrm8HOtKyD0YqKZjXxD0TtdtQunoBco',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gabriel Tupula',
        lastName: 'Yamba',
        emails: ['gyamba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGVoV3BLfZcwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1527174531344?e=2147483647&v=beta&t=BTtD1cf0vgAHN54XVerGe6mpnUBOK_BjDwCg7CxBTvs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nadim',
        lastName: 'Barakat',
        emails: ['nbarakat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEm-7ofMXCAng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634231254120?e=2147483647&v=beta&t=RAqOJssuMwlBgcexkISjYuxcEV__PMSfCP0Y79BBBeg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Selvanee Murthen',
        lastName: '☁️',
        emails: ['s☁️@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE5A6vDUP8vjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692703140361?e=2147483647&v=beta&t=ozi7-PqUjT8HVlaCxLeyQt7wP6VgPuQzyWmtbHPp1pk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cedrik',
        lastName: 'Edwards',
        emails: ['cedwards@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGRrdqsAZYGEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714057814024?e=2147483647&v=beta&t=PjGqfMwPHnJQMgO7cxhzMv2bZbIraIsrfjF15f44AfU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  uula: {
    name: 'UULA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dr. Ward',
        lastName: 'Ulmer',
        emails: ['dulmer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGOjBzaUDjxMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652471238262?e=2147483647&v=beta&t=7ldYfnuynx4dcJi00s4fjyY2J4hfS-Sk9014MHVNWcQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Manuel',
        emails: ['rmanuel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHY-qCCxe8CDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698604365501?e=2147483647&v=beta&t=t47DEUY5JFVfqjwWjLRR1DVoQipspie06uDzsWnG2cA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Irma Becerra',
        lastName: 'PhD',
        emails: ['iphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHSzGJRvrl_IA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550417390549?e=2147483647&v=beta&t=HykIQgfC_OsDIjDjmbrnqKvtNb9cpQm5WCn3TKMsgJY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bob',
        lastName: 'Sutton',
        emails: ['bsutton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGYnZO9sw6JFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521221998719?e=2147483647&v=beta&t=wC5kHcDwkPAZneKtAGqZUOJKSFQt_8x5_czfjFv24bk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Missy',
        lastName: 'Cummings',
        emails: ['mcummings@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  neev: {
    name: 'Neev',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'NEEV',
        lastName: 'GLOBAL',
        emails: ['nglobal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHkvbQP65ptcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608793264825?e=2147483647&v=beta&t=SYyqHnF6kLurVfmAJPZwRZ9_213YMTvtomFrWOANmNc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Deepak',
        lastName: 'Kadam',
        emails: ['dkadam@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Arpita',
        lastName: 'Verma',
        emails: ['averma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHgrOEeljd2fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591714155047?e=2147483647&v=beta&t=A5CeV48DpilsmTobuI6-kT7aMIL9iWFwUI6hR8l8uyU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'JEEGAR',
        lastName: 'MODI',
        emails: ['jmodi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFHkxCHuB7sqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618613708175?e=2147483647&v=beta&t=9S1tixpCyvy7JF1GwUNSjfMlJx1hZqGUhDxH4_rnymw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Suzane',
        lastName: 'patrick',
        emails: ['spatrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG0BgF8JyGUAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517473736525?e=2147483647&v=beta&t=fdYGMuHueMAQIw8w-QCEd23mf0bHF53IbCv3ReY24Uw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  unitedConsult: {
    name: 'United Consult',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Reza',
        lastName: 'Abree',
        emails: ['rabree@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Russell Griebel, P.G.,',
        lastName: 'C.P.G.',
        emails: ['rc.p.g.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHWyp6-Wtmi8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728218117695?e=2147483647&v=beta&t=1BR7yHGmIfZ2GoioKyEIf304nHYlbGjskBZpnkt_lfA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cassidy',
        lastName: 'Cross',
        emails: ['ccross@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGghUxGuc2A0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642363023189?e=2147483647&v=beta&t=TeO02X3M1wz91Uwd-dcuhR0bQ9ixyY4n90nGfFBxAxE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike Farrell,',
        lastName: 'CPA',
        emails: ['mcpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH2sYEfKqXWQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544472681952?e=2147483647&v=beta&t=BqdAWtdYORsQqU_dLSP4vFrGU-GC4O1G6G9esVUnOb4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'SHOKIN',
        lastName: 'KHAN',
        emails: ['skhan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  haulogy: {
    name: 'Haulogy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cédric',
        lastName: 'Pierrard',
        emails: ['cpierrard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHbi9RYVFMP1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669364991331?e=2147483647&v=beta&t=F8AITh-oDJAfKhN0YL6fCwBPFmnf7ntmLErx5tqbDik',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Quentin',
        lastName: 'Gemine',
        emails: ['qgemine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFVvyQjh6XV3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637580780146?e=2147483647&v=beta&t=MM0V8Kc7tuqbeo9gDqYlXX4Av_YYe_Po79A82e-AQcM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Laurine',
        lastName: 'Duchesne',
        emails: ['lduchesne@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eric',
        lastName: 'Tang',
        emails: ['etang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFYfaWK-rT9vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522595622078?e=2147483647&v=beta&t=lhl0twC7mGic2-tqllUtzB-pldeWEq07syCYEQFZUKo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eric',
        lastName: 'Vermeulen',
        emails: ['evermeulen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  geozilla: {
    name: 'GeoZilla',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Geo',
        lastName: 'Concepcion',
        emails: ['gconcepcion@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Geo',
        lastName: 'Derice',
        emails: ['gderice@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEzXJz1h9hi2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596292880204?e=2147483647&v=beta&t=s99wdDxVruhTiYPIsZAQHmhR5YowHtGIgsebGXeWrKA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Geo',
        lastName: 'Marquez',
        emails: ['gmarquez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG5y_zR_nfHyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713134477947?e=2147483647&v=beta&t=tQMyZ_vDGEis_B_6dz0iaWNHIAYFA1Weuw0I9xDEpqk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Geo',
        lastName: 'Panchame',
        emails: ['gpanchame@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHoHE6XHkyh3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727773646651?e=2147483647&v=beta&t=liySg_-_69NCyX3bnaeOx6jsYRtH9ox5nXCoVzd2Lnw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Geoffrey ""Geo""',
        lastName: 'Licciardello',
        emails: ['glicciardello@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  amptalk: {
    name: 'amptalk',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kensuke',
        lastName: 'Kuroda',
        emails: ['kkuroda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEgnreecGI-jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547099532954?e=2147483647&v=beta&t=HmcohypN-6YSbQ1PmDgGNtY0NpMzgus0R1Cr-y9FisI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mariam Ben',
        lastName: 'Slama',
        emails: ['mslama@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEUAYLIHNRvmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516926893274?e=2147483647&v=beta&t=GqSeFP5mcLprgGwZXFhH1CvIcUX5WALzq9Yg0N0me24',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nagisa',
        lastName: 'Koike',
        emails: ['nkoike@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Quentin Januel',
        lastName: 'Capellini',
        emails: ['qcapellini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfYa_cw3Qnrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685291063458?e=2147483647&v=beta&t=vGCE5X0lgxNlm8NGA_Dh7JOF0dSukxxM0DCIte9BFyk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ryoma',
        lastName: 'I.',
        emails: ['ri.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  altrockstech: {
    name: 'Altrocks Tech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anandavasan',
        lastName: 'S',
        emails: ['as@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gage',
        lastName: 'Altrock',
        emails: ['galtrock@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQED6nfPMsrFWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662729910940?e=2147483647&v=beta&t=TtwIIZcPSy1nwAOUp54-q6X3lBsgBH37AI7N6XSQkzs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sajin',
        lastName: 'S',
        emails: ['ss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGfCfGT28fiaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660210279923?e=2147483647&v=beta&t=JCHm02XGdhKVOHl5kcEV_8Ah0sNfAtzTM0htnmCrZoY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Altrock',
        emails: ['caltrock@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEqzjtlFmB9Fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699191907768?e=2147483647&v=beta&t=wvknlbkXwCMASaHkj9qNc2LQFsBT1SLMsQLM3mJgxdE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Varadharajan Valpet',
        lastName: 'Srinivasan',
        emails: ['vsrinivasan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  motive: {
    name: 'Motive',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Justin',
        lastName: 'Reed',
        emails: ['jreed@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bruce',
        lastName: 'Toyama',
        emails: ['btoyama@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Maroon',
        emails: ['mmaroon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEhHgixdidh5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700164589972?e=2147483647&v=beta&t=PyxwFsMEVPG92fIFcVbjzfRtmYwQJtoKoS7okebMEmY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lisa',
        lastName: 'Albinger',
        emails: ['lalbinger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFfunPGuSyuIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675892045459?e=2147483647&v=beta&t=sege4Nllh-qFMj6znalJOaMeFRgnwawGlQnyMx7Ishg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Katharine',
        lastName: 'Geramita',
        emails: ['kgeramita@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGt_90324daig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675296816711?e=2147483647&v=beta&t=74abGYLO0akYtbq3DREx9BFI7_ADsI46pgmaW6IA6tw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trellint: {
    name: 'Trellint',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Martin',
        lastName: 'Gaynor',
        emails: ['mgaynor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG7C0-Ytp8kRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629982052055?e=2147483647&v=beta&t=lyzsUHSkWtfAHK1_Anw9vNHwEUJ4T-8KRfxiWGaHo8w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kirk',
        lastName: 'Strassman',
        emails: ['kstrassman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lauren',
        lastName: 'Weintraut',
        emails: ['lweintraut@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFH1eFrCaYUDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701109224116?e=2147483647&v=beta&t=l6HLPkVpGi4xvdlj5B1bW-Ycznd1jHKlrZ0Cu_TkKGA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'Darst',
        emails: ['mdarst@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOpGgpsUFDrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718291803025?e=2147483647&v=beta&t=8xVTcPLyt-Uxv1LJ8gd8Ih1A8lI8qb8nLSKtA4-FFH4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adam Isen,',
        lastName: 'CAPP',
        emails: ['acapp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFsuAXxEoYURw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517552212471?e=2147483647&v=beta&t=XQKhDTKpI5ABUAlbGHhNDdNTumSniHn2ivCoRlEFTwI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  virtexstadium: {
    name: 'ZENOS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Simon',
        lastName: 'Bennett',
        emails: ['sbennett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHcwg9iy2MDVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688997698090?e=2147483647&v=beta&t=xng5vcZRPEvYtetMT72PhKijYRF4uKCTP4ez4SJAt1g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tim',
        lastName: 'Mcguinness',
        emails: ['tmcguinness@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mel',
        lastName: 'Hunter',
        emails: ['mhunter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHNxE-Zm5Np6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568311260991?e=2147483647&v=beta&t=qHqDFmDQqXaajVHafNZKEnmahvNm91of3HAoLfoj6Yg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'steve',
        lastName: 'osoro',
        emails: ['sosoro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGhoycXXa7ZLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658818902086?e=2147483647&v=beta&t=VH-ptmZ0EAywA9yW8uEopNKv2jpwDKnOrsTlwhYwzCs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zenos',
        lastName: 'Tech',
        emails: ['ztech@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFiAAyrC2c9gg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614341801018?e=2147483647&v=beta&t=589zf_EwawyZJafkXpHQ3K8m1xNoSMwcv1vpW3bNA2M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  posicube: {
    name: 'POSICUBE Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'SJ',
        lastName: 'Oh님',
        emails: ['soh님@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eunyoung (Eunice)',
        lastName: 'Choi',
        emails: ['echoi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEuP_s7LmEJvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696004001272?e=2147483647&v=beta&t=0jLB2sFh04MvmVcr05Imjf5f8A_U3N0fzqBaJdWxgkk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eun Jik (Jake)',
        lastName: 'Kim님',
        emails: ['ekim님@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eunyoung (Eunice)',
        lastName: 'Choi님',
        emails: ['echoi님@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEuP_s7LmEJvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696004001272?e=2147483647&v=beta&t=0jLB2sFh04MvmVcr05Imjf5f8A_U3N0fzqBaJdWxgkk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: '추현진님',
        lastName: '',
        emails: ['추@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFoJivw49YkIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617335146552?e=2147483647&v=beta&t=TV-qWjTnkpUPSGxITRQUjuMwYYoViL0HQPxZmzviePg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bettyblocks: {
    name: 'Betty Blocks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Davy',
        lastName: 'Nys',
        emails: ['dnys@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Obdam',
        emails: ['cobdam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHDY169zVIKAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1539613456798?e=2147483647&v=beta&t=1dJuA93Oy2WpEiwp8FJPuekgbSzzheExKWc6xHPeHtw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ralph',
        lastName: 'D.',
        emails: ['rd.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF91ex0pSmiVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688138986098?e=2147483647&v=beta&t=MIX2l6yd5fghEEb6n8Hit3bIvFUd-FOoC0OnA1HDGMs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daniel',
        lastName: 'Willemse',
        emails: ['dwillemse@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'William',
        lastName: 'Bonet',
        emails: ['wbonet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFnRpcl2YOXVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706103568634?e=2147483647&v=beta&t=EHjWav45GHyI3NWACt7w6fcT-6bek9VeaWf2DpfkcYg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  coreitx: {
    name: 'Core Technology Services, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mohammed',
        lastName: 'Salim',
        emails: ['msalim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ali',
        lastName: 'Dhoon',
        emails: ['adhoon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF27nXM9pHACQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724899376806?e=2147483647&v=beta&t=CMWDOVPae0x3hqgDJCHwoT5pIDPROXBnnr2x3VOl-pw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Husain',
        lastName: 'Dhoon',
        emails: ['hdhoon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQERmVGLnNOCLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683525144268?e=2147483647&v=beta&t=tlryKBFyxmWiAVLLZSizcD4RRrVJ6_YLDL6E7fdHLB4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yusuf',
        lastName: 'Hyderbhoy',
        emails: ['yhyderbhoy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Malek (Malekulashtar)',
        lastName: 'Kheyroolla',
        emails: ['mkheyroolla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGk5wVhDL-RcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668820876327?e=2147483647&v=beta&t=BIyE5DXaEL6D1GLP5AIWK9vWLKqorvfrZE8T_SwKivI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  commandposttech: {
    name: 'Command Post Technologies, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Scott',
        lastName: 'Bisciotti',
        emails: ['sbisciotti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGt_F_0PMVbTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517627436353?e=2147483647&v=beta&t=adLv4dqXypm0HlDg9IC2hyJgp3aFpXs1hRtJ7_PLfpc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Benjamin',
        lastName: 'Fisher',
        emails: ['bfisher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFXxq7Wv2PtLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697108786524?e=2147483647&v=beta&t=eJpWU1BW2Jce8rqUKjC_IJdjsloJOecRdkFGnABpsrg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jordan',
        lastName: 'James',
        emails: ['jjames@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFoKborXArS5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608925140994?e=2147483647&v=beta&t=CJVfA0OAUgkc2ktZSvxq_K2dkdFT_JWeyGsLMla8BRk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joe',
        lastName: 'Pizzoferrato',
        emails: ['jpizzoferrato@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mandy',
        lastName: 'Spivey',
        emails: ['mspivey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG97bzUsXJOVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719576526701?e=2147483647&v=beta&t=e1TcDbm7H4PXkTuyPUPQ3e_9WXZpjuIxK-ucvHx8HyU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cegedimrx: {
    name: 'Cegedim Healthcare Solutions UK',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dionisio',
        lastName: 'Acosta',
        emails: ['dacosta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Samir',
        lastName: 'Dhalla',
        emails: ['sdhalla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFsoBI7DylNug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727367570958?e=2147483647&v=beta&t=m_Vh1_iUeteRIXrKoz-R20qcLvyzf_ZzdMffyYZl-wM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lana',
        lastName: 'Chubakha',
        emails: ['lchubakha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHZM0_zjNewjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517467475189?e=2147483647&v=beta&t=uO_hbMGrfm06FO9T7QAnppWPor7wzDN717KXWkdhgX4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kenny',
        lastName: 'Lawton',
        emails: ['klawton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGtwtJ5Y0C8Kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584523612693?e=2147483647&v=beta&t=HxpOyFjo8vQoLtRrQBQbE6pgmoxs7Kbb0xYqHgGupCs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kevin',
        lastName: 'Willoughby',
        emails: ['kwilloughby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFAzQxcbKFLnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694205867297?e=2147483647&v=beta&t=C9t2uchXvZSVs9onFZrZ1B9WHCWB1H-NYlsjWid4Bc8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  socialmobile: {
    name: 'Social Mobile',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Morcos',
        emails: ['rmorcos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHzdV9AZke9tA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704204251800?e=2147483647&v=beta&t=jvn8aAis13L71V0VUlYhfaZsqDw_OQeNoJO58HJygk8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'Kaplan',
        emails: ['akaplan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHkKPonOVdiFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729108711435?e=2147483647&v=beta&t=aBjQqtm2n0p9C7ZebNAbcRrIZX8FaspATto9GeRZMI0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bahron Alipour,',
        lastName: 'MBA',
        emails: ['bmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGS8QAaxkNqtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695906494992?e=2147483647&v=beta&t=WqoKyDgM4ncpSuDmqeDgXSFHfwkjQCVpUCzFnJReR2U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Freddy',
        lastName: 'Morcos',
        emails: ['fmorcos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGVAtJzf6gmbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585685511936?e=2147483647&v=beta&t=yk0-f3TobkSSppBQ4XpcWgUYpFk77Db-jpS62usjTtQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carolina Thomas,',
        lastName: 'MBA',
        emails: ['cmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFYWyTSVp8DdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516337983737?e=2147483647&v=beta&t=xWg7W-KKY2tiRxO4mrN_X6tFtxZS882q4uLoCjUpRRc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  incredibuild: {
    name: 'Incredibuild',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Duncan',
        lastName: 'Huffman',
        emails: ['dhuffman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tami Mazel',
        lastName: 'Shachar',
        emails: ['tshachar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Regev',
        lastName: 'Yativ',
        emails: ['ryativ@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFUx6rRzNat8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714977913827?e=2147483647&v=beta&t=1zyhgDgR6bcPZZXnR-kqGmeypeuC0KYJ7IrkYTnhNsQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dana Weintraub',
        lastName: 'Rochman',
        emails: ['drochman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQECfx9fxdrT6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614808483241?e=2147483647&v=beta&t=T9jfqy_VEcJwkf0BPJlus2A3qmTK6dB5eAhRWRSidpI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Smadar Ludomirski',
        lastName: 'Esteron',
        emails: ['sesteron@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG-paU9andGXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688033056160?e=2147483647&v=beta&t=a3i_qodULWakxZYp2vnNYRBIyX3stDCEiwuPb67TVA8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zoftsolutions: {
    name: 'ZoftSolutions Pvt Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Zoft',
        lastName: 'Solutions',
        emails: ['zsolutions@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGtnyROTVwgFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629094220435?e=2147483647&v=beta&t=NH8DI8yrOGHcyW38MGIRKJa2aYk416VZgQQ5Mi8Xpxk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dilip',
        lastName: 'Ramachandran',
        emails: ['dramachandran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQE3rYdj7dxgwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573717084307?e=2147483647&v=beta&t=4scPvvmjyvjQin3mpSbYij8O-indmW55iT9esyTrs3g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Priyanka',
        lastName: 'Joseph',
        emails: ['pjoseph@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHqIHIUScghiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572848649149?e=2147483647&v=beta&t=0Dria31jkZ1ghWxHiXvm7laD8LFcWJruE2x2VkuPffw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shine',
        lastName: 'SS',
        emails: ['sss@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kiran',
        lastName: 'Kumar',
        emails: ['kkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFfLUO-_mH3dA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709408290997?e=2147483647&v=beta&t=7_wDl16Ka4pHpG4eqpZ6HAsr0Io2GqJk-11IQPym7Cw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ippathways: {
    name: 'IP Pathways',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joe',
        lastName: 'Shields',
        emails: ['jshields@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sarah',
        lastName: 'Tucker',
        emails: ['stucker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGRhtB9mt-Z5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652993297452?e=2147483647&v=beta&t=VaEj-IJ2FO3j9SYSD7EMO8YhnNr64pT_bvAxhmxbyCI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Isaiah',
        lastName: 'Howard',
        emails: ['ihoward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF0XURIkyvn4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611658483432?e=2147483647&v=beta&t=zC8fFQ6sfYsiDYMHLRft7MZx-Zu613BZrl7xT-iUcDA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kyle',
        lastName: 'Kubiak',
        emails: ['kkubiak@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Justin',
        lastName: 'Ruby',
        emails: ['jruby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGWN36-lTi5dw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650036662512?e=2147483647&v=beta&t=flPohDJaXOJ_uUVBnUIeqoHbrrkOIhMPRIU4T_MHVcM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  darewise: {
    name: 'Life Beyond Studios',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Benjamin',
        lastName: 'Schirtz',
        emails: ['bschirtz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH59jBbnXEKBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619692741178?e=2147483647&v=beta&t=QyTwrZMY6drr1li4JCH0Fr1RTl93EXLvKWbCSB9icaE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Álvaro',
        lastName: 'Jimeno',
        emails: ['ájimeno@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFWp8CAdzM4jQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1539180953879?e=2147483647&v=beta&t=4NqwWVLahNtCbvFZvX4Etj9YkgrDzeoPSUark8JG8aY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Belinda',
        lastName: 'Navi',
        emails: ['bnavi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE1N367xrCTsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531768259432?e=2147483647&v=beta&t=JMvQnH7MfpzUxK0RqELjjt70A5hQNkq-1e9zJ9PPeFs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christopher',
        lastName: 'DuCrest',
        emails: ['cducrest@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anfray',
        lastName: 'Lapeyre',
        emails: ['alapeyre@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGniVlDYyrZjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729251779494?e=2147483647&v=beta&t=4jSe92DmRcm_Su8veie9knV5Uy_njer_4sRyAjdtaO4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lightningfibre: {
    name: 'Lightning Fibre',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert De',
        lastName: 'Gouveia',
        emails: ['rgouveia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEiIq1gUukHaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594816616005?e=2147483647&v=beta&t=mYeSfVh5gQLFRosxDEjlqC5dxiM-_5JXYkobrYzvDIw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tristan',
        lastName: 'Goddard',
        emails: ['tgoddard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGT0k5TMFt8NQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596447363234?e=2147483647&v=beta&t=EFAWUWMlDcwp1BgjQgra6CgaObKqHqRV0UWoQ0R6KK4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Emmaline',
        lastName: 'Pollard',
        emails: ['epollard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFay2rW9i_T8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693256282722?e=2147483647&v=beta&t=i5-htwOsHtkMGOcrmVpXmZjUskRfUGr3FAaZSHOrrk4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Luna',
        lastName: '',
        emails: ['l@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHVFL_BoLefEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730253314842?e=2147483647&v=beta&t=tRkS7CnMFW3M0HRb7VtfN-_aoQvC8vVOw-z9EEBHkcQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Thornton',
        emails: ['pthornton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gbh: {
    name: 'GBH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sam',
        lastName: 'Brewer',
        emails: ['sbrewer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGBcOmKwC-DXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728007556300?e=2147483647&v=beta&t=0hFDuCvUnnMwBJpXede2WsbTmuD1sIkmJ_Dd_pb-jfU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Susan',
        lastName: 'Goldberg',
        emails: ['sgoldberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGgWLU9J3PI7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616513932944?e=2147483647&v=beta&t=TDWyyXlpJHPeEA-kFYBW-q3oZrsGjnTLP7o54rr3HCE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paul',
        lastName: 'Singer',
        emails: ['psinger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHGjtx-eZkQfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724040108309?e=2147483647&v=beta&t=PYAIFyqSFfCLvB6DNRCMlHtWzgBp_4j71lC3aOSPXrk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paris',
        lastName: 'Alston',
        emails: ['palston@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kenny',
        lastName: 'Johnson',
        emails: ['kjohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  foxconsulting: {
    name: 'Fox 📊 Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cher Fox (The Datanista),',
        lastName: 'CDMP',
        emails: ['ccdmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFq-CD96k3tCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729645185732?e=2147483647&v=beta&t=90md_mQPCsEXSPGjGyEZxrqTpZwkg1yaAot0hKEnD3o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jay',
        lastName: 'Fox',
        emails: ['jfox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGgUMsgSQqlRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517446426026?e=2147483647&v=beta&t=Xp9l3DYEpVQdPpdA3mV7NywWnzdNL9cY7yAN8k-mn2k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dennis',
        lastName: 'Fox',
        emails: ['dfox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG8-pgJt1oSZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517687081597?e=2147483647&v=beta&t=VD4gtP83RkCjXoasxbU-RQb4SzvQDHapXhURcYySD3c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Langreck',
        emails: ['jlangreck@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stephen',
        lastName: 'Krieger',
        emails: ['skrieger@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ebenefitsnetwork: {
    name: 'OneKonnect',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Cohen',
        emails: ['dcohen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eric',
        lastName: 'Ronci',
        emails: ['eronci@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Smack',
        emails: ['jsmack@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHpon0kp9ajFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588703733905?e=2147483647&v=beta&t=-I7X37QMNwtwERzbBnW8L1ihTGuXXoGNWo9P0N-Pgt8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdelrahman',
        lastName: 'Tarik',
        emails: ['atarik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHVKsbL5ypJjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657619577862?e=2147483647&v=beta&t=ts-fyls38Hh8QIViddaVIa8yiLnbx4402J1tBrM5jnA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Calahan',
        lastName: 'Kieley',
        emails: ['ckieley@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pixagon: {
    name: 'Pixagon Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kirill Svidersky – Pixagon Games |',
        lastName: 'LinkedIn',
        emails: ['klinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFxobQCVi53Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664221760889?e=2147483647&v=beta&t=mkyv_wFHtaCDE0IJ9ZsPpCaGySFoxj0QRCfoXE_xzP8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gayane',
        lastName: 'Nalbandian',
        emails: ['gnalbandian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFPZE-QUclY-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690254773140?e=2147483647&v=beta&t=b6lrxpmkHFVXGZdDDFaaxNA6k3FhNvG4OmA56BLMdh4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Michael Khaimzon – Owner, Creative Director – Pixagon Games',
        lastName: '...',
        emails: ['m...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGk8hA6SsIvPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697918283551?e=2147483647&v=beta&t=wANEaciJfwUNCBP3N-3EwFf9hnuT1N0wWDEUrZpL3bM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sergey Sobin – Pixagon Games |',
        lastName: 'LinkedIn',
        emails: ['slinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFx9e5TbFAw6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712855161615?e=2147483647&v=beta&t=k1hlMZbb3ocbXv44RcvgivvcCTQggeiN98sk0anWLe8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Irina Palatchenko – HR Director – Pixagon Games |',
        lastName: 'LinkedIn',
        emails: ['ilinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGmJejfeGUegw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684163232098?e=2147483647&v=beta&t=zmQvEDwe3bEphZQmO6bZSjmg7UuaFl9Q8KKtGxP9rsA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zetadisplay: {
    name: 'ZetaDisplay',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Per Mandorf – ZetaDisplay AB (publ) |',
        lastName: 'LinkedIn',
        emails: ['plinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marius',
        lastName: 'Lysholm',
        emails: ['mlysholm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFch0wMrl8KOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678131488793?e=2147483647&v=beta&t=PWhNSDfzPGZGyyTrfqH9c0wPKGwTH4NWFwUM6LxdFSw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ali',
        lastName: 'Jetha',
        emails: ['ajetha@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ola',
        lastName: 'Sæverås',
        emails: ['osæverås@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHfIUJdCapGqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711559436742?e=2147483647&v=beta&t=94w5z6MJcB8McIDt5D2Fcg18cWeF2T_tfRU6z_fmmD0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Preston Webb – Product Manager – ZetaDisplay AB (publ) |',
        lastName: 'LinkedIn',
        emails: ['plinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGU6i18vyxFLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560672591359?e=2147483647&v=beta&t=MMUKw0DEtJtEPYhlr5KAoyEdaOIuFhNtBHPRoA2QKpE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  convergentusa: {
    name: 'BRIDGE Healthcare Partners',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tracy',
        lastName: 'Finn',
        emails: ['tfinn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHUhQtxZOkxCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516336625752?e=2147483647&v=beta&t=Zm9d5XF_R6RmW5UFZOIZAFy4dIM-jJoWm7H3ruq-azQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lindsey Haase,',
        lastName: 'MBA',
        emails: ['lmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Adam',
        lastName: 'Williams',
        emails: ['awilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEPl3i7zYUJHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517656428903?e=2147483647&v=beta&t=QtMws44PQ0DrUO2VoUzSmsJMghQQ2aKLhZTFXFMLG3c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dorothy',
        lastName: 'Luna',
        emails: ['dluna@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tanya',
        lastName: 'Dickerson',
        emails: ['tdickerson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF6CUlwl9Dz_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573841446133?e=2147483647&v=beta&t=y2-dcqtcT_KHEPkiDzKlNEfM2m-fXpU74tKZbPvQuJ4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '3rdPillar': {
    name: 'Third Pillar A Globe Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jennifer',
        lastName: 'Ligones',
        emails: ['jligones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHGChYXyx7MEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685605307962?e=2147483647&v=beta&t=_fOUgscixdihf5p0UG_hnn-rnmHFwCouCWBHIZUSuSk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mike',
        lastName: 'Gorres',
        emails: ['mgorres@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH_C9syAGRxbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517399642703?e=2147483647&v=beta&t=LXz2c-hNxuvgoUrlrU-x7js10MBZC4KqDiNv9eLs7QM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Desiree',
        lastName: 'Beltran',
        emails: ['dbeltran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG-9Z3g4WIJyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1640748625266?e=2147483647&v=beta&t=9eNYi_l9OlnpzSfz8m1qc6r-yBEc1gXiTfwm5XBPmWc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jonsy',
        lastName: 'Filart',
        emails: ['jfilart@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Roselle',
        lastName: 'Tan',
        emails: ['rtan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF3k3TdKG5D_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517046057155?e=2147483647&v=beta&t=FPmMci-o52Vu035xacCClIb-sAv9tVh8dtmUSpfqjDs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  efigence: {
    name: 'Efigence',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marek Lesiak – efigence S.A. |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHctCZxRz69Kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689330664007?e=2147483647&v=beta&t=XE9nrn8iYa7UGN0IdI7F5KMwpIGOo99G2fmFNCxVNzc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joanna',
        lastName: 'Maciocha',
        emails: ['jmaciocha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHIEnWhvrcDzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681236672554?e=2147483647&v=beta&t=lpc3CL5jDlX9gZpdNzzP7vZ5M4UlrvrtJP0gO2BDmuk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sylwia',
        lastName: 'Dobkowska',
        emails: ['sdobkowska@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGYOjySm671rQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690382480918?e=2147483647&v=beta&t=AOlzevw9Zw-V6TtMWnz5zO_OyM2qes3XxMsATiAvuL8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrzej Szewczyk – efigence S.A. |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGEvFbIywzPTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688988546006?e=2147483647&v=beta&t=pcakxDi0xFQH8nNPxM86kfCMUqEYwqG1nwPppSlqVAU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Karl',
        lastName: 'Abbott',
        emails: ['kabbott@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  signupsoftware: {
    name: 'SignUp Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rob',
        lastName: 'Ashe',
        emails: ['rashe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH-e2C0Eo6Lpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724356473204?e=2147483647&v=beta&t=oQgoQEzwx02VkiDrm-TDUhUTSFXLG5EnvPKXMxUyjYc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Laura',
        lastName: 'Daley',
        emails: ['ldaley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGJ-kD5zrCMqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630015014115?e=2147483647&v=beta&t=burP2IHoG-FL1omyWYX_mMF4tPRcQP0bO3XQsQAVF40',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Steve Lorenzo,',
        lastName: 'MBA',
        emails: ['smba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEd0FJLcrdPJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707474012587?e=2147483647&v=beta&t=FOaGOIgoQ7nv88de5i1gimV5_MuIR9WAGqJP4D4COq0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Scott',
        lastName: 'Harvey',
        emails: ['sharvey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEgFDkFf_TO4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728339799008?e=2147483647&v=beta&t=-6UsLXamC9sfVgPlru_iReoFb3rnJvGgiwHae4EJ01A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Morgan',
        lastName: 'Jonsson',
        emails: ['mjonsson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFKj1-MrhGUew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687956665045?e=2147483647&v=beta&t=Qa4re87tVpI-YuehL-CHhss-VsUBpd8U3QIxlYU0Zjo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dynapps: {
    name: 'Dynapps | Experts in Odoo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Karel',
        lastName: 'Hendrickx',
        emails: ['khendrickx@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGlbtDP3M5Hzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634817452334?e=2147483647&v=beta&t=t4Yl9n-B40R87vnU4a3ulFy2ko596Vc8CxHnx8OQvuQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Isabelle',
        lastName: 'Lambrechts',
        emails: ['ilambrechts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFfQoWRs1-_QQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696324947687?e=2147483647&v=beta&t=K3x0VVbwI1vo3eaG9uonLoP65Y8hsqtRmOBfOynIB-c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nikki',
        lastName: 'Jacobs',
        emails: ['njacobs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHaVBbEYMusdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689614577731?e=2147483647&v=beta&t=38yt2ZcsmjtIJITpmql8VaqDlXsnc6Y2j-b4rCkr_1o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michelle',
        lastName: 'Cuypers',
        emails: ['mcuypers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGKDe4WSorhEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665225685439?e=2147483647&v=beta&t=CXmeCAsZ2p9gQyF9DVZCle4u7Z58zvt7rLGb2W88o2Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Wim',
        lastName: 'Gabriels',
        emails: ['wgabriels@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ecochain: {
    name: 'Ecochain',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pedro',
        lastName: 'Príncipe',
        emails: ['ppríncipe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGPpKb0OgWUgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658277187389?e=2147483647&v=beta&t=lWQQV5CUY0FhyS1XOA_0M5nDlsEPPCBStyvBm8VZamw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alicia',
        lastName: 'Wanless',
        emails: ['awanless@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEp2Bnw5Y7bng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681843378564?e=2147483647&v=beta&t=2iXr3-2LaQcm8JSQn6uaYqlw-h8Fa_OA2fyPA7yx-os',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  expressiongames: {
    name: 'Expression Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kieran',
        lastName: "D'Archambaud",
        emails: ["kd'archambaud@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFtw9EmcGJ1zA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690386616141?e=2147483647&v=beta&t=kg4zCMs3EBNHhHTOI3x0J1lAY0A-87f5UwwBpFlXJp4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tom',
        lastName: 'Hicks',
        emails: ['thicks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFhdOXwCDiKJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612178149435?e=2147483647&v=beta&t=Rt9G4X9lxEvvpPJIfaSI7di6X_Y-pk5XJVwg3N7TIAg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daniel',
        lastName: 'Conroy',
        emails: ['dconroy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQELWfIitWx1sw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723998047601?e=2147483647&v=beta&t=WbjFWA9w3Qu8oQajwmHyz2SNz56j4gSHNZGGa031P_A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maxine',
        lastName: 'Lugg',
        emails: ['mlugg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEcpg155MRbNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647708986398?e=2147483647&v=beta&t=XjoPITnlfsgG8S7Px5EyoScnY_02Pus6M2SmFT9ODHc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sean',
        lastName: 'Kirby',
        emails: ['skirby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH6z_v3jQqmBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714087132840?e=2147483647&v=beta&t=Etpv__lZO6z6V0lW_6df8enIK_nAaS3orB0-E3d4Tz0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  covenanteyes: {
    name: 'Covenant Eyes',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sam',
        lastName: 'Black',
        emails: ['sblack@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGJDDlmSCgy1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588344836857?e=2147483647&v=beta&t=0wqtJZtCGsWyogeS2j0Codc-geW7znXKNgMNQq5CcgI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carlos A.',
        lastName: 'Velasquez',
        emails: ['cvelasquez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lisa',
        lastName: 'Eldred',
        emails: ['leldred@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFYT6j1239Htw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719834081978?e=2147483647&v=beta&t=DA7INEAiyEIa1APJtcYw_l61rwLahqXSlqrK_0lLreg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'Ernst',
        emails: ['mernst@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bill Meade',
        lastName: 'ph.d.',
        emails: ['bph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHPKzOwiffekw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550596204552?e=2147483647&v=beta&t=wHTtiCBMoR7nyiIHIqFvoiauueHlid2IBi9reaioSdw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  contisystems: {
    name: 'CONTISYSTEMS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Louis',
        lastName: 'Conti',
        emails: ['lconti@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Duarte Conceição | Executive Board',
        lastName: 'Member',
        emails: ['dmember@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFV_5Md2mrScw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729856587249?e=2147483647&v=beta&t=PZDpTpQOMqpCSzd0mKHbPA5SNe826qCkxKwDaXluPLc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sam',
        lastName: 'Parrish',
        emails: ['sparrish@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF9Oz_C_v72nQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687324599587?e=2147483647&v=beta&t=1bfXLnPV0XK4R6Pd2bRCDCblqlgvpwwgD1IQ4OkJeeA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Louis',
        lastName: 'Conti',
        emails: ['lconti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHeIxuqOHB04w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704404389430?e=2147483647&v=beta&t=NWSP-31vVYY1IjLagR_qxpnuZlRoYHPM4CA6UX3adFE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Javier Echebarria',
        lastName: 'Gangoiti',
        emails: ['jgangoiti@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  agvantis: {
    name: 'AgVantis, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cameron',
        lastName: 'Murray',
        emails: ['cmurray@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David A.',
        lastName: 'Oles',
        emails: ['doles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE9DtlXxj0LYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698694720851?e=2147483647&v=beta&t=c6a7i0pZO0GqwgkjA08UVRAZ-DO16cLA_r7tsM0v_MM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Darlene',
        lastName: 'Myers',
        emails: ['dmyers@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robert',
        lastName: 'Mulheran',
        emails: ['rmulheran@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christi',
        lastName: 'Nicholson',
        emails: ['cnicholson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bccdc: {
    name: 'Black Canyon Consulting LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Haley',
        lastName: 'Esser',
        emails: ['hesser@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGZGylJgXAetg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711472308024?e=2147483647&v=beta&t=s1-EiopmQSkJBShjMR_RA-yW8FqYxBzoptSiieM0Q-g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Martin Leach, PhD,',
        lastName: 'MBA',
        emails: ['mmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGFUFrVTGfjSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709566610736?e=2147483647&v=beta&t=3_tpf3amp1I_zlEru9CFwbU-Evq3h_ekG5XRlGlUVGg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Garrett',
        lastName: 'R.',
        emails: ['gr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEjsLXoeLU4uQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1576665332907?e=2147483647&v=beta&t=KIeSVZnlJkQloqTmbXM1llS01i9hDsf1MyqnXU6PS1o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Black Canyon Consulting LLC |',
        lastName: 'LinkedIn',
        emails: ['blinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jaycyll',
        lastName: 'B.',
        emails: ['jb.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEz2gC1k5zbRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712186605358?e=2147483647&v=beta&t=51numDYwzZ8jPuXn3oIya2TJxh4zGi26Zwqs_Cll1pE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  amic: {
    name: 'Software Company AMIC® GmbH - a Cultura Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Igor Häring – Anwendungsberater / Professional Service',
        lastName: '...',
        emails: ['i...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFQJOAbY4CIQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560325179528?e=2147483647&v=beta&t=yprOpJWY-FIvjz5Ad_wzF23r0Cx5xoAQqCLBjVQTRXw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Olaf Hilmers –',
        lastName: 'Frankfurt/Rhein',
        emails: ['ofrankfurt/rhein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG826nr02f19A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559915622552?e=2147483647&v=beta&t=lVYjJTbgE7FJKGxvAg7mPQYn50SDuH0RvS_psDHM2Jo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nina Keil – Software Company AMIC®',
        lastName: 'GmbH',
        emails: ['ngmbh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGN3xqoeDKRrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689011695865?e=2147483647&v=beta&t=FkaR6XvuFX2BpM8DcSzW8rMT4-TCcbtwFL806X_TYQA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rafal Swistek – Anwendungsberater / Professional Service',
        lastName: '...',
        emails: ['r...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEIdetPkE6EVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700843016956?e=2147483647&v=beta&t=ZJRCBvbhTB2zGix0RuwMRp6a0BEnbr7Gm5wWUxW1690',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daniel Gänsler – Head Of Research And Development',
        lastName: '...',
        emails: ['d...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQENhT0P1hVHsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563575544618?e=2147483647&v=beta&t=jBxw-kZF546KLjHis0f37Pvey8KINgf2qq7VxyRy1Es',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dataforest: {
    name: 'DATAFOREST',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aleksandr',
        lastName: 'Sheremeta',
        emails: ['asheremeta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alex',
        lastName: 'Multykh',
        emails: ['amultykh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGvQR6QZ0G17Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622043084341?e=2147483647&v=beta&t=fZMtXuCbFDZbesfj0EZWtaoNr1m-VvkMvaVlNVozu5o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Svetlana Lavrinenko – Business Development Executive –',
        lastName: 'DataForest',
        emails: ['sdataforest@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vlad Zinchenko – DataForest |',
        lastName: 'LinkedIn',
        emails: ['vlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH_BMaFZNmLsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664402959685?e=2147483647&v=beta&t=ZjiRoaHOocwb2TwgzDupR0ko8mO_pW0fw-fQSEbTE1Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Olena Bilousova – DATAFOREST |',
        lastName: 'LinkedIn',
        emails: ['olinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFxTRrwuE9icw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705416127314?e=2147483647&v=beta&t=59siUa55zk2xaqO4v9zbqpoxiMRiJJPr4wnFSglghD8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kron: {
    name: 'Kron',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Terisa',
        lastName: 'Estacio',
        emails: ['testacio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEnDJpAFYzMbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648938714744?e=2147483647&v=beta&t=bIC7uXg5XmHDa68S1Y__ortMpCpeRCEV1uHyAyK_ywU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sara',
        lastName: 'Stinson',
        emails: ['sstinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG3aV4cfo82HQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667577753224?e=2147483647&v=beta&t=BjOdOqUM7Utd-VEzgRwB0Pt7mXtlkdFsaJo8jZlGnTA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Will',
        lastName: 'Tran',
        emails: ['wtran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFdIpU7Trs6hA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612134380808?e=2147483647&v=beta&t=VzHKv2lz-ZhCYpcEPwtqMcjxklw0hExj_u9qdBSCKV4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sharon',
        lastName: 'Trentino',
        emails: ['strentino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH-dlOd-v-fhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516357407286?e=2147483647&v=beta&t=OEs8CyTOubBseitKItZT8jCK-ncdVw96jNhLm-pP0Bs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kelly',
        lastName: 'Kim',
        emails: ['kkim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEOnhkm8SjtRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665425925627?e=2147483647&v=beta&t=0XVjOZ8Hog3zZMeV9TZ4HxmPA2czNTyNcXEfmsGCFO8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  salient: {
    name: 'Salient',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Amisano',
        emails: ['jamisano@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jim',
        lastName: 'McDermott',
        emails: ['jmcdermott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGzq6cIQfhTvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704747242436?e=2147483647&v=beta&t=GyxjCvLeOcC7ipX-Bq2ydu0kEr20UgJOnqWp39f2YXQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jim',
        lastName: 'Waltersdorf',
        emails: ['jwaltersdorf@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Guy',
        lastName: 'Amisano',
        emails: ['gamisano@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Melissa',
        lastName: 'Panaski',
        emails: ['mpanaski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGRIbjb7CyGaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704808275907?e=2147483647&v=beta&t=GtuldUymc8CL-jjgQzuBB3i85zCmr98zx-nfzT5gUjg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  centaris: {
    name: 'Centaris',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Curtis',
        lastName: 'Hicks',
        emails: ['chicks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHgUrvryCPidQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634832648224?e=2147483647&v=beta&t=2u6nNNyMUCOdtWmj-7KSl8J751OyqnO9xRwsFLt9qc4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Bamberger',
        emails: ['jbamberger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGDYgRHRznvng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585657656420?e=2147483647&v=beta&t=Td3MGFOJy9gEXUtSAXHi53hjVXgxGt1XfS3wXDRDyqo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patrick Casey, MBA,',
        lastName: 'CISSP',
        emails: ['pcissp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG67sGPZtsrIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618846145123?e=2147483647&v=beta&t=nUQ75O69SpljSWBplT6JQoh68rVuVH88ZDyJey5-WWo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Del',
        lastName: 'Ingalls',
        emails: ['dingalls@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jason',
        lastName: 'Barnes',
        emails: ['jbarnes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEWDhdpJbUlHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684776254951?e=2147483647&v=beta&t=tzyJb6L1Vxft518Aa-1sdZOlu4GTzhZDEDAJkM08y74',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hutchisonT: {
    name: 'Hutchi',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cory',
        lastName: 'Hutchison',
        emails: ['chutchison@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nicole',
        lastName: 'Hutchison',
        emails: ['nhutchison@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tai',
        lastName: 'Hutchinson',
        emails: ['thutchinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGKzET08W7mVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659643469084?e=2147483647&v=beta&t=MFhRyF0dMBiwhZxTTXWkuz89eUL6Nmw6Qg2SplGYM1w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tom',
        lastName: 'Hutchison',
        emails: ['thutchison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFY0KcEQvOtLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579811930897?e=2147483647&v=beta&t=rzG3OW1_A2uSQ689T-cQJDZ7BwVKa-9mi5rnXXAZUPs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jack',
        lastName: 'Hutchinson',
        emails: ['jhutchinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF4Eh5DJhqJrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685755199551?e=2147483647&v=beta&t=L2ofZJA28YjDCEWZ8Rg6TOdnsrmvXRZ6zFaAShiR0EA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  odine: {
    name: 'Odine',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aydin',
        lastName: 'Pirinccioglu',
        emails: ['apirinccioglu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nick',
        lastName: 'Cowley',
        emails: ['ncowley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFJncPXJR0lTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704924585649?e=2147483647&v=beta&t=fiHPZEKyVM2GrcoI0RkBz9zUS1NzLnEG4edPKch_ORU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alper Tunga',
        lastName: 'Burak',
        emails: ['aburak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF7eHIRgynWlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705472270573?e=2147483647&v=beta&t=syz8gfRQWLBA3Uvx9sgFCA_-06yPEbYexWJcftwo85I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tarkan',
        lastName: 'Alagoz',
        emails: ['talagoz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFPmYgHPZYpcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728409893252?e=2147483647&v=beta&t=ko0Z6cY5phO586h5mtsIKFxv1xL9zLcoGylZAs45WgU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ecem Yazıcı',
        lastName: 'Ertürk',
        emails: ['eertürk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGEKJ6gNqsfng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517470922801?e=2147483647&v=beta&t=tGQqViOtksnaBcFoe-mKBlxcECOuwLI2GfFbjqcZoPM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  numeroeins: {
    name: 'NumeroEins Software Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Manu',
        lastName: 'Ranjan',
        emails: ['mranjan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFneXTFwQWfLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553328499514?e=2147483647&v=beta&t=3dSnVU5L90ZgN1I9Rj6i9mJ-69HcfJqdX93AhwHao7U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aman',
        lastName: 'Mourya',
        emails: ['amourya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG2O3iWg0FmPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725249474030?e=2147483647&v=beta&t=FS0wXMyIaSij83ud1AiGbl6xTdbVfMdTCnYSusUiULk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Richa singh',
        lastName: 'rathore',
        emails: ['rrathore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG40H8swM3iiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584949400113?e=2147483647&v=beta&t=HEtyKTNnNnTU1r0YQVuKBEL6nrlWS5Hzja7qXvlFM8Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sant Sharan Singh',
        lastName: 'jaat',
        emails: ['sjaat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGD_TVM5PJW2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613059792790?e=2147483647&v=beta&t=NFO_m6H7-f5NQzVK5e6590SxghrFf7LdlIxe2FhK-_A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Piyush',
        lastName: 'Rai',
        emails: ['prai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGORvvg5jXXNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602509224195?e=2147483647&v=beta&t=arERL4QclZNk-2Oes51wDEcqHvfO8mnzhDj9icyANXg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  techBridge: {
    name: 'TechBridge Consultancy Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brandon',
        lastName: 'Antoine',
        emails: ['bantoine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGNgjl41YsSog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625074106444?e=2147483647&v=beta&t=R1nNFxPsQpaAmDj6q9kEN7vK-Mjgn1hfRJP3A6jDAEo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emily',
        lastName: 'McLeod',
        emails: ['emcleod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGJEA2xD0Vvrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516589548281?e=2147483647&v=beta&t=mVx2W9QLji8WF6Dd7E-51kj0damSaa16Y2mRhxjNz7k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Maggie',
        lastName: 'Phipps',
        emails: ['mphipps@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jennifer',
        lastName: 'Juarez',
        emails: ['jjuarez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHI7uU5kOfYUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698769309242?e=2147483647&v=beta&t=CriOakPF8N4CKvDfm8A16Ix6hVDfMYFn7XwQzG_5e4E',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jacquetta',
        lastName: 'Moss',
        emails: ['jmoss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGIPTodyTZ7Wg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637700337461?e=2147483647&v=beta&t=D29eK8R3LD5k7GJUbzKNWwZRcG8Xs1vcjpeIDteFOJc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thunderfulgames: {
    name: 'Thunderful Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gemma',
        lastName: 'B.',
        emails: ['gb.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFjH1XeubL9Rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723639795985?e=2147483647&v=beta&t=Oam7YLcJySXOm0nbm5mEJwIXWmVAmVGXDOb7EaL-Qjc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'James',
        lastName: 'Bartholomeou',
        emails: ['jbartholomeou@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGz-CK9rdoBmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706298168180?e=2147483647&v=beta&t=VjgoM3NvLYpmzLe6JY58Iw0brGCBFwd7tYwcQti4K2M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Karla',
        lastName: 'Sutherland',
        emails: ['ksutherland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE-ftlPRGrs0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689609416712?e=2147483647&v=beta&t=g6ESXev49hMtDqIGLRA9T9BM9Zz-HtIKKJjrdwcBuGM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anna',
        lastName: 'Ottewell',
        emails: ['aottewell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGcFHygpXnyQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517430515198?e=2147483647&v=beta&t=jDYr6ZtKMb696T2dQhkB1AMUVXHKSqbhilvGWQR1ZgQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Robert J. Olsén – Senior Game Designer – Thunderful Games',
        lastName: '...',
        emails: ['r...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEOks-_nNuaug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603209524903?e=2147483647&v=beta&t=48RpyzcjiSBv2r8fpTFIZP9ebvxS9bkOeRhmBGubGhs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  helindata: {
    name: 'Helin',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ivan',
        lastName: 'Helin',
        emails: ['ihelin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF_741v7SuAyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517360263216?e=2147483647&v=beta&t=hZGN0d0tTfgrxz3yJRvA2C8E53XJVzgeyAxlv7YsgYA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jake',
        lastName: 'Helin',
        emails: ['jhelin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFFrDUnZ-KlaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727468527706?e=2147483647&v=beta&t=EfXTcXZCP_WNpc7umSPWva1uwkao0wjPoJLmb3IayQA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joost',
        lastName: 'Lasschuit',
        emails: ['jlasschuit@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bram',
        lastName: 'Masselink',
        emails: ['bmasselink@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jutta',
        lastName: 'Helin',
        emails: ['jhelin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHiriwQuqO-3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666035937663?e=2147483647&v=beta&t=M_4TUnp8JaTxJscqC5Rm26WjdRvFeRawQo6gaohUgK4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hundredstar: {
    name: 'HundredStar Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Liza',
        lastName: 'Socican',
        emails: ['lsocican@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG1nnRKHtNuBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670719015698?e=2147483647&v=beta&t=qxFRJnxNHMiCa1UJ3He0eG820A59S2NAnq6oT5urXYE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tim',
        lastName: 'Hanagan',
        emails: ['thanagan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGYzjp_UI7yyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516272905986?e=2147483647&v=beta&t=USpz5RjdxCq4D-KlUrO8N3lLInlVQU82J1m6jfEnQnc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lucas van',
        lastName: 'Muiswinkel',
        emails: ['lmuiswinkel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHXiooJ5cWswA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517721015972?e=2147483647&v=beta&t=35W-5PSprzGcZkjhus8lZ1OXlmZQNvE8FXtWj-PCa0A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Adam',
        lastName: 'Vincent',
        emails: ['avincent@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGirQwbxeZcDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633597582819?e=2147483647&v=beta&t=DMFuf-pCgYke_fqDXC-HInhM6487Mb6RDvSuTCFApik',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yaser',
        lastName: 'Hussain',
        emails: ['yhussain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHGuzvcmkGP_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636676586461?e=2147483647&v=beta&t=7p8BFj5Dss1ve5Ya4JNUXeazZzDgSuS20epxtDuysBo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  a3consultingllc: {
    name: 'A3 Consulting LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adeniyi',
        lastName: 'Adeyiga',
        emails: ['aadeyiga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGAwx0Cp10wRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604682405590?e=2147483647&v=beta&t=n0x2i9Y30-53esUT8SJuGWfXeKnHIMbFis8uGOufzX4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mary Tran,',
        lastName: 'CSM',
        emails: ['mcsm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEv4tPcCYLqWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517556077409?e=2147483647&v=beta&t=ClhqswyWupdeVTvgPxV5dltMtuY4qbLNHqYmN_lMIKE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bryan',
        lastName: 'Ward',
        emails: ['bward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEk51-aktnr1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516302815727?e=2147483647&v=beta&t=RGEUgBoVeOL4tzpFup9LQftMllWbV4If5pP8-h2B1qQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Theresa E. Davis, MBA,',
        lastName: 'CSM®',
        emails: ['tcsm®@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alex',
        lastName: 'Casiano',
        emails: ['acasiano@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  redclay: {
    name: 'Red Clay Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Leeanna',
        lastName: 'Gonzalez',
        emails: ['lgonzalez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lynne',
        lastName: 'Powers',
        emails: ['lpowers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGd6BeknWollw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593532145070?e=2147483647&v=beta&t=hr4VzlLuk2ihFwInuu0K1V9dGOdumdsy-JE4xDnzb9k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nour A',
        lastName: 'Moustafa',
        emails: ['nmoustafa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEL5KeMK1bMuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661804011723?e=2147483647&v=beta&t=x8_3qKBwdbnEVNeC71KlZhAghVNjUc2UtE5M7nizLSc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paul',
        lastName: 'Marnell',
        emails: ['pmarnell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Skyler',
        lastName: 'Shuman',
        emails: ['sshuman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  foresightintelligence: {
    name: 'Foresight Intelligence® & Fleet Intelligence™',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dale',
        lastName: 'Hanna',
        emails: ['dhanna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGTMiDF6UTJrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620168967785?e=2147483647&v=beta&t=lnIlHR1utADcC8z_pqRY7jzliDORUqAPYsFx9xDptTA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jackie',
        lastName: 'Church',
        emails: ['jchurch@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dr. Johannes',
        lastName: 'Gabriel',
        emails: ['dgabriel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGtjj4Btp65JA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714479582370?e=2147483647&v=beta&t=Hs7nkuOyg3L1aaZ1RgXPTISf5N2D7v47rNkHuiV_4Fo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jared',
        lastName: 'Kortje',
        emails: ['jkortje@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marcel',
        lastName: 'Hadeed',
        emails: ['mhadeed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQErAlLOe2PDrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688548273989?e=2147483647&v=beta&t=9ZjY0gJ8aeobH8xEY1H7bWSTYdOV4_dkHb59gDnmQsk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ingelli: {
    name: 'INGELLICOM',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pedro',
        lastName: 'Príncipe',
        emails: ['ppríncipe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGPpKb0OgWUgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658277187389?e=2147483647&v=beta&t=lWQQV5CUY0FhyS1XOA_0M5nDlsEPPCBStyvBm8VZamw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alicia',
        lastName: 'Wanless',
        emails: ['awanless@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEp2Bnw5Y7bng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681843378564?e=2147483647&v=beta&t=2iXr3-2LaQcm8JSQn6uaYqlw-h8Fa_OA2fyPA7yx-os',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  beyond: {
    name: 'Beyond.pl',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dmytro',
        lastName: 'S.',
        emails: ['ds.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEu54tIh-ckhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680198395211?e=2147483647&v=beta&t=Oc3moEL9_cQcrzlz-nfer2yU0ocMxoc-tgDcI--CcUI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ondrej',
        lastName: 'Sika',
        emails: ['osika@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHjNniMC2cwiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581755541334?e=2147483647&v=beta&t=nInrz5uVkrafsAK7hXK198nKZWI7bYj7i52BdwlYEGI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Oleksandr',
        lastName: 'Semencha',
        emails: ['osemencha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFLIAyayWdF6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1535700267106?e=2147483647&v=beta&t=BeaZbQthhBLRjnxUH2NOykIdzxQlgH09Nz6hjS3Sl8E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hrant',
        lastName: 'Kocharyan',
        emails: ['hkocharyan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adrian',
        lastName: 'Celebański',
        emails: ['acelebański@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH71PTR3bV53g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639657486130?e=2147483647&v=beta&t=mM3wecE9UlBtTpztzP_U-TfNupO0BB01-6tGhcnJs88',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kubeark: {
    name: 'Kubeark',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alexandru Dragos',
        lastName: 'Nanuti',
        emails: ['ananuti@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bogdan',
        lastName: 'Nedelcov',
        emails: ['bnedelcov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHroSLQ-SkSVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671314001934?e=2147483647&v=beta&t=0M3gdjUZom1bgz5KoKUrop2_rFyhjLlCx2ehHMm15J4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Torben',
        lastName: 'Sebens',
        emails: ['tsebens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFEoxge6tqQiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555349870752?e=2147483647&v=beta&t=ulnirZZ_5hMC1wQTqE_QIdOiQ1Yox7t5OWj3t5w8QKs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Giesler',
        emails: ['mgiesler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEa2i2BLh4CYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597144004419?e=2147483647&v=beta&t=KCPI4aTUbDP1yIIcO1dfLoTgOhrSjjL_v82G8ZlqK5A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andreia',
        lastName: 'Stavarache',
        emails: ['astavarache@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  epson: {
    name: 'Epson Middle East',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jason',
        lastName: 'McMillan',
        emails: ['jmcmillan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG5-fQKUU6TYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697179248957?e=2147483647&v=beta&t=d42VPOalr110EqjlJ6iz-zBaGtIzo_rjM3Ijbezm-Yg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Neil',
        lastName: 'Colquhoun',
        emails: ['ncolquhoun@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHL8Ms6ybqbPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600582000218?e=2147483647&v=beta&t=x6vNLU-BqpY6UwGNFAXIq6lGYIKhCFdocFfZ_XPyN0U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anke',
        lastName: 'Brandts',
        emails: ['abrandts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFYJxQ7tXfNnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643363135099?e=2147483647&v=beta&t=j1qNG0rPN6NRT_iY1KIKlFRUyeCi8hzFW_VlSa2-wWE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Frank',
        lastName: 'Oliveira',
        emails: ['foliveira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFM5VxWHdnc3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679519023904?e=2147483647&v=beta&t=Mf8C0S13TZatjKkHmNZMo-Lg8tTOJSY4hRcE31H_j4k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abdulnassir',
        lastName: 'Ali',
        emails: ['aali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH1pMUlaFCh6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623439322281?e=2147483647&v=beta&t=VAnou3xyySd9WPkhFjtJu_oesxE49E3kpIqmo6vRR6c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  airfinity: {
    name: 'Airfinity Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jennings',
        lastName: 'Heussner',
        emails: ['jheussner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQExfqAGG_5icw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635470655454?e=2147483647&v=beta&t=Qj94Wa5iVqEyzE4El0SEkfbgGOk1qIBi8MMOUiD34go',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jon',
        lastName: 'Moser',
        emails: ['jmoser@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHsYdmVngFJlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649411205054?e=2147483647&v=beta&t=Cp63nRNtLq0v9bvZSZ-du2AmVCe_6G4AYeyfm1cDndE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michelle',
        lastName: 'Holko',
        emails: ['mholko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGA9Q0uwFDfVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625165043269?e=2147483647&v=beta&t=pZpCHDS2fDBYXcXSItKtzEKeWMQQGe2QkQgkwJeH-vA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Konstantin',
        lastName: 'Hardenberg',
        emails: ['khardenberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGUbm40fHdhAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603962625388?e=2147483647&v=beta&t=WEoPwk28ejzWuoyBUjD4rLF0XVARfG1cFfY2j21OIrw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dirk',
        lastName: 'Bernhardt',
        emails: ['dbernhardt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  planethowl: {
    name: 'Howl',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Herman Aparicio',
        lastName: 'Jr.',
        emails: ['hjr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHCrhB43pyesQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668465813563?e=2147483647&v=beta&t=LLjZLZIvhQBZU5wXF-BFzd2rkXvz84IsFrZrsn6fhAY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Li Haslett',
        lastName: 'Chen',
        emails: ['lchen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF0J9-e0cGsBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730207292648?e=2147483647&v=beta&t=C68hetQF1_f22lU6zDL6lAU8Dxio_WgwWx8p76TdB38',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rob',
        lastName: 'Post',
        emails: ['rpost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHAKyY37c8tHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663693415418?e=2147483647&v=beta&t=kxnKXlBzLtwyEU9JeKugBtdQlOAdaFaa-MPl6iTMG3Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Justin',
        lastName: 'Robey',
        emails: ['jrobey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFQExYxbMxtpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728927955438?e=2147483647&v=beta&t=qFEdlC_QdiAULdT-ZEZ_SqiDf1ooUnyxq1HdhvjYBUs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jerry',
        lastName: 'Liu',
        emails: ['jliu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  otorio: {
    name: 'OTORIO',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gail',
        lastName: 'Kosloff',
        emails: ['gkosloff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF3ExD7KQGsCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628042643757?e=2147483647&v=beta&t=EVS3UqoNDcprUIPEHXNzLGM7yXTuQych6Xv2a-ta64A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Yair',
        lastName: 'Attar',
        emails: ['yattar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHx-FEL0DWV9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679058194936?e=2147483647&v=beta&t=huuBEsXLkak7WpN_7D_g2r-Ozg6t76BBeQdMfxtWrXU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Craig',
        lastName: 'Hissong',
        emails: ['chissong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHHYjr-MpAD7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721737792699?e=2147483647&v=beta&t=n__1uKJpm4ij7N32GZhKIdTtwe0Nay5tj06An1bIeo4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matan',
        lastName: 'Dobrushin',
        emails: ['mdobrushin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sara',
        lastName: 'Flack',
        emails: ['sflack@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEl2_J1yZDk3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664377167890?e=2147483647&v=beta&t=1sC5TSQa_Jd-HzedmnQXHvAuTiemXL0HU5Jmd2shcVQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eyec: {
    name: 'EyeC - Print Inspection & Quality Control Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nico Hagemann – Product Management Director – EyeC GmbH',
        lastName: '...',
        emails: ['n...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGHSXx5iWDMGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672564209459?e=2147483647&v=beta&t=NDPmu4_Ij9MjyTCYv7xsBzqtFjydDlx5dRk0MXTFJEE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Thomas Albers – Service & Application Director – EyeC GmbH',
        lastName: '...',
        emails: ['t...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEdOEAtCH_seA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635500039739?e=2147483647&v=beta&t=1ytrEN-Zx-wtg8WDZpvMSkvNGjLQi7lNihAzshWfiFo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'michael klein – Field Services Engineer – EyeC GmbH |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEPJRnxov0PXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657270155000?e=2147483647&v=beta&t=jOHHiEkv-A0WVI05PJFct0qMfS3PvZmNHmdiQC4xQNI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lukas Petersen – EyeC GmbH |',
        lastName: 'LinkedIn',
        emails: ['llinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHjPeqhjtaKQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720781293244?e=2147483647&v=beta&t=S5ChWxl0lnP5JQ7tHvjmG0S3DzzMAdDfrOYyOq9A-1o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daniela Hafner – Web',
        lastName: 'Designer',
        emails: ['ddesigner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE9-cUPhkj92g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711049714025?e=2147483647&v=beta&t=hnjt3kNyyHYmtzSVLW0xYeQR3IxVsdL4YfHIBxc6JGE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  breakawayltd: {
    name: 'BreakAway Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jenn',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Will',
        lastName: 'Cook',
        emails: ['wcook@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lloyd',
        lastName: 'Thompson',
        emails: ['lthompson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGIardD9GJVVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706457660683?e=2147483647&v=beta&t=AO_izKimTawLM-dN2vIIcOuv_uWjhbNadEcmqjL7LKY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ching',
        lastName: 'Lau',
        emails: ['clau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEQ5RuFvptOGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648084791211?e=2147483647&v=beta&t=fsQ2gQrOpVOZ48Ved1NJRHrijlIzZMoQngBD4gYxCSA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dan',
        lastName: 'Mycka',
        emails: ['dmycka@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHgwFbkIkrzaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657395077319?e=2147483647&v=beta&t=MvbZBsxxoc-LJWSX1g8xi8s7ksJkljIP7mVfDgI-3Xo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nectari: {
    name: 'Nectari Software Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sergey',
        lastName: 'Yun',
        emails: ['syun@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEVT0v22SulBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623072637176?e=2147483647&v=beta&t=ZVuzYIeUtf17M64VidTs3GVi0v1EXGugD5O_gS8iRJs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nima',
        lastName: 'Massih',
        emails: ['nmassih@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF24gLiOlrgBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614624480469?e=2147483647&v=beta&t=mEcIfhKGB-TQA4W5cTGg5DiTuKu5OQK29QYcHXa5Zds',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Greg',
        lastName: 'Brown',
        emails: ['gbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQERrxNK-fEcqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516294083012?e=2147483647&v=beta&t=HW9iAE_ciodbtVj52kryXnGAH7b2wBvZRaGyeK2jO4M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Geneviève Vaillancourt',
        lastName: 'M.Sc.',
        emails: ['gm.sc.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFqG6_yr8e_cw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605301675411?e=2147483647&v=beta&t=EgMzKD8UrqWZ7KDsa_Socihn24oWOjc5iVaK3g4p-uk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Olivia',
        lastName: 'Brown',
        emails: ['obrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFwAzx01deRVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590764624807?e=2147483647&v=beta&t=aeRilRFgdnNDDeFTqxU19jFoGKAUMCzgi92q2LJNE0M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  comtecsystems: {
    name: 'ComTec Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Vertolli',
        emails: ['mvertolli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFPmCPRPGx58A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675181203275?e=2147483647&v=beta&t=wmSlyPhWjJZlr9Z5_7YIEXSwE41neBk2FWnS0HXt5U0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carl',
        lastName: 'Nuss',
        emails: ['cnuss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGbOGKtTefppQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594072935156?e=2147483647&v=beta&t=mDKqSD7y3k0u1PalDGqV7orUqdxq2MHCdLP4Q_FHv4E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Fritz',
        lastName: 'Schulze',
        emails: ['fschulze@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGcKr6Z8YhgxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517675543438?e=2147483647&v=beta&t=m4HYcS5fNMR9h0FUcbn1q07N4YBMmsjShFVCmrih0X8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lucky',
        lastName: 'Miller',
        emails: ['lmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFOJA_uDWArpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662150392522?e=2147483647&v=beta&t=5KeZEiKg-FOYI3AuBR8L5P731Fc85unfRYsxGkuSL08',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Dalia',
        emails: ['mdalia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHpn8x8hKu67Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679410898138?e=2147483647&v=beta&t=1DgkUiaPsyfIG_oRneueIwcBvl9ZXBkgf90b6A7SXA4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  enetworks: {
    name: 'eNetworks Srl',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Oksana',
        lastName: 'Turianska',
        emails: ['oturianska@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHJE2uK_DGoLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729799249290?e=2147483647&v=beta&t=ybDnVkU01_vhuWEWHS6mSOfmhB3ocVVsGyzESbeHQ7w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stefania',
        lastName: 'Papa',
        emails: ['spapa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGVIWojj7MZYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623396274348?e=2147483647&v=beta&t=RNfIDNZ_ft0Urv8nmtcpS3xfbj26BiYqu4pVbsMe8us',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Simone',
        lastName: 'Casarosa',
        emails: ['scasarosa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE7hn383771ig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624605180519?e=2147483647&v=beta&t=V-wh6IW0MAlOA9NCrGyFw4DD_SOROTdrk5U5QOqbiaI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amaya',
        lastName: 'Singh',
        emails: ['asingh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Simone',
        lastName: 'Astorino',
        emails: ['sastorino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFnJU3xKWZ34g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625678431278?e=2147483647&v=beta&t=vLEWqkI-YlxMNDfdrKt-BloHkF-KJLb3jORrfozFo3A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nextbasket: {
    name: 'NEXT BASKET',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ekeka',
        lastName: 'Ende',
        emails: ['eende@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Zlatin',
        lastName: 'Hristov',
        emails: ['zhristov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEGy6WFkHTgFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517069174126?e=2147483647&v=beta&t=0Xurc4BOPqU1ocyahdYoCd4aYwmzr-PRGXOh8dShD5c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Borislav',
        lastName: 'Malinov',
        emails: ['bmalinov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rose',
        lastName: '',
        emails: ['r@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFQjNu9qX1kFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686311473424?e=2147483647&v=beta&t=VUVm2Fo6dhH7TpwoVUAUvZgctIS3zW5BiYnYZQNg3ZY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Petar',
        lastName: 'Ivanov',
        emails: ['pivanov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  advisiondevelopment: {
    name: 'Advision Development',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brea',
        lastName: 'C.',
        emails: ['bc.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Radowitz',
        emails: ['jradowitz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEFklj3QR4VGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590923588369?e=2147483647&v=beta&t=s_ca2sj6zokgDXr3oMMjaPH1U31iO22rm9buYArn6oI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tiff',
        lastName: 'Perritt',
        emails: ['tperritt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHlLghD8HzD8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715488678631?e=2147483647&v=beta&t=0KCjqT8L550h8Tdg9QMcF2M5CsRbp4rwjLaKQiquOq0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kenneth',
        lastName: 'Coto',
        emails: ['kcoto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFzN9GsVSaMYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610904174157?e=2147483647&v=beta&t=mYW8iUmLlkau48ivh2lH3xRgEuPwg-OxDJ2lKimOEas',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michelle',
        lastName: 'Estrada',
        emails: ['mestrada@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF6WlqdqAIkOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560012255659?e=2147483647&v=beta&t=nZoQwF0aYEBDQ5pmyesfa5YoP659LIQID75a2gVEuQ4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  myaestheticspro: {
    name: 'AestheticsPro',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Graham',
        lastName: 'Budd',
        emails: ['gbudd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHsxn5uIY5Kyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633851877476?e=2147483647&v=beta&t=anda1FooAXtTVHmO0qt_mxWYuw2ff3XXpDuEDvcmp4M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lora',
        lastName: 'Budd',
        emails: ['lbudd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHTlfDNQ-cRvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615263235254?e=2147483647&v=beta&t=T8yEl6HcaEawemtFBl-QH2wKsdD9Qb-h_sODbIVPQ5I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kendall',
        lastName: 'Budd',
        emails: ['kbudd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHbO9IU5hOfvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719968846995?e=2147483647&v=beta&t=_4E-m_seMeUSk31ep2Skmzb2taSeV7kW6C0OSNb0H-4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nate',
        lastName: 'McAuley',
        emails: ['nmcauley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEPj0r3DJPUBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585005709432?e=2147483647&v=beta&t=uAqBbHEN3aE0vjW6wWADqzHq2uOsy1avtxRvr_q9e2M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vincent',
        lastName: 'Gregorio',
        emails: ['vgregorio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE7_1h2xKSjXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572661786044?e=2147483647&v=beta&t=YDNrqKhx1ZQV5A9TXs-CZmwfWyHzeHA1DPTtKUUoYgo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  icsinc: {
    name: 'Integrated Computer Solutions. Inc. (ICS)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Philip',
        lastName: 'Chila',
        emails: ['pchila@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFY8v7ZtyEMng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669830291040?e=2147483647&v=beta&t=w81rcGl0Z0EEIo3dvF5frYhd9ddHV5SYKTXjrkQVkDs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kristen',
        lastName: 'Smith',
        emails: ['ksmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG0vjX9pGXBwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642013910610?e=2147483647&v=beta&t=WK0K-DhKuJivyKB9lnXc6oh_0pGc3mbZvrZ42LYJeyM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dan',
        lastName: 'McGillivray',
        emails: ['dmcgillivray@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEvPI9aZZUfIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516354295740?e=2147483647&v=beta&t=TjVKz3d_ALnMTBA9incKLLLc5_rDQbMyrtWQzFVZA8E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jay',
        lastName: 'Noble',
        emails: ['jnoble@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG-C_LRKaKyRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578397095995?e=2147483647&v=beta&t=KknPqwysWtqI0R3oxd887k3fQZZebpS2WWTP2y5f5CE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zhivago',
        lastName: 'Johnson',
        emails: ['zjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGWztwbhUnUpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549817552399?e=2147483647&v=beta&t=780Vz0TCAuouvs_DNxfx9ZzsTpZXyVWjE2vgLjPiwfM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  build38: {
    name: 'Build38',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pedro',
        lastName: 'Hernandez',
        emails: ['phernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH8mYMDRzm7kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696922384085?e=2147483647&v=beta&t=vRE9nQYjgIb69EIBnAEVwiPw8WbehRFJJYuLEvoX-GA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Leticia',
        lastName: 'Barcia',
        emails: ['lbarcia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHAGj9Lga3Slw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622992479642?e=2147483647&v=beta&t=MxP7uzfn0ZzEghf85uxuTgW3QqqpTvIkN_joo4XSTjI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mencey',
        lastName: 'Morera',
        emails: ['mmorera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEFXuGU9Wf2Fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670484842726?e=2147483647&v=beta&t=tGAr4pvLSCkdwm5dgxmlfzl3WXXo6XeGZOzBT5tFqR4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Torsten',
        lastName: 'Leibner',
        emails: ['tleibner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEvp5di62lrcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722000999376?e=2147483647&v=beta&t=2buINeOya-Ko7MxSFLkO-kCMPlAOovHFMvs0dkE2-vc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mireia',
        lastName: 'Puig',
        emails: ['mpuig@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  epicsoftwaredev: {
    name: 'Aubrant Digital',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dan',
        lastName: 'Abdul',
        emails: ['dabdul@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFdDJHiu1KyEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694271633688?e=2147483647&v=beta&t=Y_qs7Tg061s3GQDOmpf5FuVYjTQmN240tmAv57k4ADU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrei',
        lastName: 'Hanzel',
        emails: ['ahanzel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGhe0wLnDvyqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698434279011?e=2147483647&v=beta&t=x1b_8vCfxhpZ4wD3d_kjrx-OW1yiwSMAF7464Z5ngUI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vivian',
        lastName: 'Torres',
        emails: ['vtorres@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGEAzgmVK9ckw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617637070537?e=2147483647&v=beta&t=rL40H97YvnuLdm6x-5dts8-36nsAIBc70odu1dazei8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Hutchinson',
        emails: ['jhutchinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFdPjeSQVcGSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729079278261?e=2147483647&v=beta&t=6_CoeyHYdYuTUOSBG5EZJHSESj6j_8z75VJSEp2wdJw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jorge',
        lastName: 'Valverde',
        emails: ['jvalverde@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHReplFYK-tQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518705339756?e=2147483647&v=beta&t=uO_6mdjRDX0v7hlLPHZM4MVD76CTbYkZHUJJOoUgdqc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  digitalenvoy: {
    name: 'Digital Envoy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jessica',
        lastName: 'Farrelly',
        emails: ['jfarrelly@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rob',
        lastName: 'Friedman',
        emails: ['rfriedman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEN3fW-LKz5jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625671704951?e=2147483647&v=beta&t=kgtvbWzhWXbrYNPDIMuaodVSyzw2AmSD6Dq93a6jv5c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cory',
        lastName: 'Kujawski',
        emails: ['ckujawski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFFejujC_gv_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516976930813?e=2147483647&v=beta&t=H1jGie-sP6ugsRqTaGRfP1YjtY0FsfOwkAVCNrna2-c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Frank',
        lastName: 'Bobo',
        emails: ['fbobo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFv5l1PMwVfRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516231300725?e=2147483647&v=beta&t=FFlilkKZg9RGT5QsVVK18yDFJywI8WzbAf2YSmLP-QI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Casey',
        lastName: 'Provost',
        emails: ['cprovost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQET3Jva-DMh7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591814397577?e=2147483647&v=beta&t=Gz5yTMdIQPamM5--TU4f7WheyBdY0XsnnvF8Vtrkmag',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  antavo: {
    name: 'Antavo AI Loyalty Cloud',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Attila',
        lastName: 'Kecsmar',
        emails: ['akecsmar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHy0a6k7xXInQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712154932743?e=2147483647&v=beta&t=mNbHNSKEvDdbMWj2SNY8R9Ss5HXDJWrWcGUvb7LA9lY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kathryn',
        lastName: 'Wright',
        emails: ['kwright@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Zsuzsa',
        lastName: 'Kecsmar',
        emails: ['zkecsmar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF9rYfhLb3y8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681282172269?e=2147483647&v=beta&t=KXRwTLx4y0wVtEoZ6aJkoA3lx26BiCMkkL5HuSY5ICE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andy',
        lastName: 'Nemes',
        emails: ['anemes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGVCUsl4j8G1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517284152919?e=2147483647&v=beta&t=Qaw8l0IIC6QwC-1JRzolnhacK-TrjwM9ONegFke23hA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michelle',
        lastName: 'Ellicott',
        emails: ['mellicott@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  phonexa: {
    name: 'Phonexa',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Gasparyan',
        emails: ['dgasparyan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEaqB5cSz6iJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574788530848?e=2147483647&v=beta&t=tArFBno90IlB-X807jMKldkdVQ0oZmLoB98YJdBktbY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeffrey',
        lastName: 'Schaffer',
        emails: ['jschaffer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEprvAo-uAuqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638594948810?e=2147483647&v=beta&t=uWm9VEb-Y3cFtfR-LzdG49y9GgW8Udw2CnZWlbzWLE0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Talar',
        lastName: 'Malakian',
        emails: ['tmalakian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF-mAfZSOyrag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728413035064?e=2147483647&v=beta&t=amGKPC3uAd6R-RgLc2hZsIo8v14Kq2oMpDlBsb_6er4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Manouk',
        lastName: 'Akopyan',
        emails: ['makopyan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF1Y5GhWeFhKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684625295332?e=2147483647&v=beta&t=Kl6NnpVkH_EXa0NxIjawOjDy3WiijWVqfD8ROSk9kLo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mara',
        lastName: 'Garcia',
        emails: ['mgarcia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEl70N72WB5fg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729348442014?e=2147483647&v=beta&t=5voM4E4tLfXK-_n5raK_LiPw8nLRTa5We4RqP65dTMA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trionika: {
    name: 'TRIONIKA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pavlo Markasian – TRIONIKA |',
        lastName: 'LinkedIn',
        emails: ['plinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEEpMVVJvUkeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607942150458?e=2147483647&v=beta&t=BP4KniEb1gAuwYKJw4H2AOMBYjI5RpIL6Ez2vfWtvoQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Yuliia Stroina – QA Engineer – TRIONIKA |',
        lastName: 'LinkedIn',
        emails: ['ylinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH5FW25SyXLjw/profile-displayphoto-shrink_200_200-alternative/profile-displayphoto-shrink_200_200-alternative/0/1529744643117?e=2147483647&v=beta&t=_YrVZ1nK5yuzUlIdDYzIWCTRreAPCDtJzrpA1HZWRGc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yelyzaveta Ivchenko – Senior Recruiter – TRIONIKA |',
        lastName: 'LinkedIn',
        emails: ['ylinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anna Chestniisha – TRIONIKA |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anastasiia Liashko – CEO – TRIONIKA |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEL5wu53a4B9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675245608269?e=2147483647&v=beta&t=bpHNewY5FShZ8Ue_aJxeKlv2Ew6Us_eG0xJQvxqeRKo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  appello: {
    name: 'Appello',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cameron',
        lastName: 'Woodford',
        emails: ['cwoodford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEHBB0FF_PjTw/profile-displayphoto-shrink_200_200-alternative/profile-displayphoto-shrink_200_200-alternative/0/1528961958916?e=2147483647&v=beta&t=_gwmNvDyn6SDrEvJZvIKbNpA8zBNmFrVcBzcTGZcTts',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marco',
        lastName: 'Viglione',
        emails: ['mviglione@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHi0mKNCvvZqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718625832213?e=2147483647&v=beta&t=u-K7wGuuZIzGuGnqSQuDj6gCX7QQWi7jTZ0vh2KT9hs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yaro',
        lastName: 'Zolotarev',
        emails: ['yzolotarev@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lucy',
        lastName: 'Shahnazaryan',
        emails: ['lshahnazaryan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFnpuA-6WcUbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689971773676?e=2147483647&v=beta&t=WBSevg5oluMV_7rtCpnpXcq9xaNANP1Qcn0mf1dzAxw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Misha',
        lastName: 'Parunashvili',
        emails: ['mparunashvili@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  journey: {
    name: 'Journey',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kristina',
        lastName: 'Coleman',
        emails: ['kcoleman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFkD20V0xlSEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693143543201?e=2147483647&v=beta&t=VPeg_Ou8ZQE2a5IlvLWzfs3Xu9KHhmxlzkf5W_SpW1U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kristi',
        lastName: 'Peek',
        emails: ['kpeek@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrea',
        lastName: 'Cassidy',
        emails: ['acassidy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG9FjhxO6f97g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605418347007?e=2147483647&v=beta&t=PhKZRSK4XQuYkQnIBU4AZAtQ0ki_R3cP_9n75mGeYog',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Diane',
        lastName: 'Caldwell',
        emails: ['dcaldwell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHKLzyNprbLQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605643981215?e=2147483647&v=beta&t=cbOJa1qfLplieXv5i1HzyNNMMJ7Kq88_Ka_2-ou1-ao',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Janis',
        lastName: 'Bailey',
        emails: ['jbailey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  deidentification: {
    name: 'D-ID',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lebatt',
        lastName: 'deid',
        emails: ['ldeid@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHwddUvg6NyuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575663901385?e=2147483647&v=beta&t=LXkq7TcQqEsnSe54qSHNkNmEcOQ9lyelStDh4cAdfyM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Crissa Marshburn, DHA,',
        lastName: 'RHIA',
        emails: ['crhia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFfsOYJwlAY1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517736462977?e=2147483647&v=beta&t=Wldpg3itTXIsM1eVwTOHkicf0Iwen0Jk2yhUUiW04P0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bridget',
        lastName: 'McInnes',
        emails: ['bmcinnes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHT7FzczemVZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624639582368?e=2147483647&v=beta&t=XrbKUxeXBtV_3WpjoMVs_UwLBG0j4x2d0kxnMeV_arA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anna Osipov',
        lastName: 'Heymann',
        emails: ['aheymann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHnEY68RpX4Sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645598408827?e=2147483647&v=beta&t=H2U3YhT5FBmabmRXXkEMTyam18t27xyyTtK9JG9g_cE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Surendrabikram',
        lastName: 'Thapa',
        emails: ['sthapa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFipD0WA41L9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726872479723?e=2147483647&v=beta&t=nXLeC6OLYWkA3TAPITLtYEsdU7IXuB0qkGyX8Yiptrs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  quickops: {
    name: 'QuickOps Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pedro',
        lastName: 'Príncipe',
        emails: ['ppríncipe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGPpKb0OgWUgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658277187389?e=2147483647&v=beta&t=lWQQV5CUY0FhyS1XOA_0M5nDlsEPPCBStyvBm8VZamw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alicia',
        lastName: 'Wanless',
        emails: ['awanless@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEp2Bnw5Y7bng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681843378564?e=2147483647&v=beta&t=2iXr3-2LaQcm8JSQn6uaYqlw-h8Fa_OA2fyPA7yx-os',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  scrut: {
    name: 'Scrut Automation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nicholas',
        lastName: 'M.',
        emails: ['nm.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tanvi',
        lastName: 'Anand',
        emails: ['tanand@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF3GOSXzd_qxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729200494030?e=2147483647&v=beta&t=RPeBERxQb0cr2IE7tQVrIPuE5wiOCKHUqeq7leK3o1Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aayush Ghosh',
        lastName: 'Choudhury',
        emails: ['achoudhury@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHMx4LrLswuCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724712072339?e=2147483647&v=beta&t=VPRE8-UfL94L_47-9xuOfSsKObVbuRNSYKy8WSGN9fg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Praveen',
        lastName: 'Kumar',
        emails: ['pkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGusNjU95BjsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623346407166?e=2147483647&v=beta&t=pDUfck6p3h7fKDvJEONwRRXrxsxJIuuxc8LtYDJXWz8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nithin',
        lastName: 'Shetty',
        emails: ['nshetty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEdh9YQn9YdoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725369568732?e=2147483647&v=beta&t=iTSeNxeAsJF-r8XhmHM8A9VIaF4e3sHWUtLhbVzGf94',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  touchsides: {
    name: 'Touchsides',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sasha',
        lastName: 'Barnett',
        emails: ['sbarnett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFVJeyr9zNwkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567174615910?e=2147483647&v=beta&t=0lgo-nWJfhmlDoqFmtUXSNptJ9ngQv_4V7b0v3t3iss',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dirk',
        lastName: 'Veldhuis',
        emails: ['dveldhuis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Helen',
        lastName: 'Danielides',
        emails: ['hdanielides@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEPtUmqDLaxKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517355549570?e=2147483647&v=beta&t=d7XN9tak7ZnftD8J3qjMSrYu-sVBYsReUOhmrgGGbGo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Murendeni',
        lastName: 'Luvhengo',
        emails: ['mluvhengo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHggAwJLbCNmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695669472615?e=2147483647&v=beta&t=YUfTFvqJmOBZmpu_n2nkJ-WS3hZ_Mev8C65d25wEm_I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christian',
        lastName: 'Nee',
        emails: ['cnee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG2sfvqjZZX6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721340039392?e=2147483647&v=beta&t=LVu1DTsn127moWP6OuakfzGc40wugkS4CmBFEc0o7uY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bluepiit: {
    name: 'BluePi',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anjna',
        lastName: 'Bhati',
        emails: ['abhati@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEB-BGOS4ZXlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626763187169?e=2147483647&v=beta&t=tyvU6xZZy_rkywdOSiLStbBedvxkx-rKRjBhCbciNxk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Monika',
        lastName: 'Marwah',
        emails: ['mmarwah@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gaurav',
        lastName: 'Batra',
        emails: ['gbatra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHjKKJCxAmpeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728562057914?e=2147483647&v=beta&t=1hMGOSq-5ObMSNMALjzOyLEwV-dDmHbrotLn4crV4FA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ravi',
        lastName: 'Girdhar',
        emails: ['rgirdhar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pranav',
        lastName: 'Rathi',
        emails: ['prathi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFVWL7hwehdVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710657456152?e=2147483647&v=beta&t=CIoeyelY2fGtWJqx9hSB-dU3LmrNLgPMftNm2IVU9Nk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  servicepath: {
    name: 'servicePath™',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Kube',
        emails: ['dkube@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFkMXcAT_GT_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573682479077?e=2147483647&v=beta&t=Kpsc70Ui9MZQsMPt4tHUbrEpK8YFecjte3BdVwdNhcA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Huda',
        lastName: 'Javaid',
        emails: ['hjavaid@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jim',
        lastName: 'Latimer',
        emails: ['jlatimer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Molson',
        emails: ['mmolson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEobDnrX3DDTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516491251956?e=2147483647&v=beta&t=jBXybMBjufQawIUZfL5gUrSXjhr2Szw5QmruOApCxCk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ben',
        lastName: 'Buck',
        emails: ['bbuck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHVoOhJNuZERA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658387008804?e=2147483647&v=beta&t=6ed48AWSDrokeVzkeusFvaZ7_E8_Eyku1AE-OUOljW8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  syberry: {
    name: 'Syberry',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul',
        lastName: 'Vasiliev',
        emails: ['pvasiliev@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sergey',
        lastName: 'Laptik',
        emails: ['slaptik@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Roman',
        lastName: 'Demidov',
        emails: ['rdemidov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH30pkEhYAGow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718249218985?e=2147483647&v=beta&t=pi6jsNi_A4Hh7HiGKCKZqw4rm3fQqcxTmz882o508Zc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Evgeny',
        lastName: 'Bogdanovich',
        emails: ['ebogdanovich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHu-fWA7I9pLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684005384036?e=2147483647&v=beta&t=L0aVzj7yHSwFEITbT_TZU4iEkAipjLZtiRUDeEqSR7w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alexander',
        lastName: 'Pankratiev',
        emails: ['apankratiev@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lehibou: {
    name: 'LeHibou',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pedro',
        lastName: 'Príncipe',
        emails: ['ppríncipe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGPpKb0OgWUgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658277187389?e=2147483647&v=beta&t=lWQQV5CUY0FhyS1XOA_0M5nDlsEPPCBStyvBm8VZamw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alicia',
        lastName: 'Wanless',
        emails: ['awanless@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEp2Bnw5Y7bng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681843378564?e=2147483647&v=beta&t=2iXr3-2LaQcm8JSQn6uaYqlw-h8Fa_OA2fyPA7yx-os',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kodly: {
    name: 'Kodly',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Luna',
        lastName: 'Martin',
        emails: ['lmartin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH4CBQkc1kKgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629123257577?e=2147483647&v=beta&t=V5BYN36zqJkh5GXU0hKoKKEP90pGz_QsH15JemzWdWE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Luna',
        lastName: 'Martin',
        emails: ['lmartin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH4CBQkc1kKgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629123257577?e=2147483647&v=beta&t=V5BYN36zqJkh5GXU0hKoKKEP90pGz_QsH15JemzWdWE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lucas',
        lastName: 'Andrade',
        emails: ['landrade@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFt_BwrsLCpHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663968324868?e=2147483647&v=beta&t=4XzXnkYqpXXXCc7PkRFzn-kOUp0ddWNi6y7GcnAfFeE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tiago',
        lastName: 'Ferreira',
        emails: ['tferreira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEYjH6-grFh1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718310937601?e=2147483647&v=beta&t=8qEX3A0vqQnM-AlWqHPXPQSsFnZDvyIm8VcwIbMf-p4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hugo',
        lastName: 'Carvalho',
        emails: ['hcarvalho@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHHDrBAvi9z5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700649369075?e=2147483647&v=beta&t=sTXZq4Otp9-YMtYmR8HLT2Qknh099xAcc20lUWhOoUk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  telesens: {
    name: 'Telesens',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eduard Rubin – Telesens |',
        lastName: 'LinkedIn',
        emails: ['elinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFp1e34AMJogg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516267085911?e=2147483647&v=beta&t=t276AxjNbh5VNVZKGkrDbaYoIwIHo-wgmpfyvEcfcMY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName:
          'Valentina Nesterova – Head of Marketing and Sales – Telesens',
        lastName: '...',
        emails: ['v...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH7ZXw6pL3SlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706126127154?e=2147483647&v=beta&t=E3yaoHyHrWn02zxJAkjMxHUFJSqMpVMZAap9HWAcdPU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alexander Paleev – Frontend Developer – Telesens |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFNW3FmZL0YCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650622689270?e=2147483647&v=beta&t=xNZwySpJc2Z8O9n7ezeSO2VN0dEKxSK8xNS0Gy1BwnY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yuliia Vinnyk (Redko) – Head of Key Account Department –',
        lastName: 'Telesens',
        emails: ['ytelesens@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Denys',
        lastName: 'Shvedchenko',
        emails: ['dshvedchenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF_8m-RAfHGLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647020280418?e=2147483647&v=beta&t=0Y7Vpu-Cl8VYvOcimrMzPdDLXENhNIi-9mBS4gpnv7w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  smartcitytelecom: {
    name: 'Smart City Telecom',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nick',
        lastName: 'Lenoci',
        emails: ['nlenoci@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Christopher Tyson,',
        lastName: 'CPA',
        emails: ['ccpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF_smN3OwG_uA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660566017700?e=2147483647&v=beta&t=aZ_cGXbS0ks7fObUALhvbqIsIk5lQ872x9t_YcbaQs8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jonathan',
        lastName: 'Sullivan',
        emails: ['jsullivan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG_8kn8tTZV9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651579452763?e=2147483647&v=beta&t=B95zb9igabiyXQj-UTTXrcJh16lDkJQYpgojuNndOnc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Luis',
        lastName: 'Garcia',
        emails: ['lgarcia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHV8tbYaCaSgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516262253368?e=2147483647&v=beta&t=MTvPj8GgmHgdC4Xpgml1-_g-0gb_PzpWer36na59d64',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vlad',
        lastName: 'Luković',
        emails: ['vluković@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  improva: {
    name: 'Improva',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Blake',
        lastName: 'Riley',
        emails: ['briley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEGInZVVE0g1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516699127416?e=2147483647&v=beta&t=mUaf7zPmeek-Wwj_i3eVYfqP6_vMFij_gwQExWV3F-0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emily Riley',
        lastName: '(Murfin)',
        emails: ['e(murfin)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFaNKrlqzubPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516871914296?e=2147483647&v=beta&t=UP7QJIh7jvnFwsO8oXSGDiGYwMPEI1F1ssuIa5Sx18M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mathew',
        lastName: 'Nelson',
        emails: ['mnelson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFnHQLoouE-uA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617350882902?e=2147483647&v=beta&t=i3swgOc1iEKlSj0r7d5wcn5J5f_ee5yGn_swdmpIsI0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alicia González',
        lastName: 'Tomé',
        emails: ['atomé@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE2D29lpHLfwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660812213215?e=2147483647&v=beta&t=xd_9p3xly9NUahDQcKvBJD2Lc409BKiLfZdVQ-RLT7A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chanel',
        lastName: 'Marlow',
        emails: ['cmarlow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG3BkgiPcPlUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707207002507?e=2147483647&v=beta&t=Y1ngBp4bggt3QSrAZUptC2cqTv-aBaLV4Mxqmc7ad6w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eleken: {
    name: 'Eleken.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ilya',
        lastName: 'Dmitruk',
        emails: ['idmitruk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFp4xlcRVYoKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711776797847?e=2147483647&v=beta&t=rONMn-zOdQy0Ol__7uOJ-eeO9iXL5YgZT6Vc3H6X09o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Asghar',
        lastName: 'Siddiqui',
        emails: ['asiddiqui@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGnhX38qWVuqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516634662446?e=2147483647&v=beta&t=V61I1OMYrhUmYWGFkoZxuHxUP5iga7QIFQnUcTnCJ4w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Maksym Chervynskyi – Eleken. |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anna Demerchian – Eleken. |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEVVYR5mfz3MA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564252556595?e=2147483647&v=beta&t=zu0PW1xTkBNdsi4axLItOgFOKsvH6NjqYGfrLBHsKb8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Muhammad Sarwar',
        lastName: 'Nafees',
        emails: ['mnafees@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHahhbdr2Q1vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517581705303?e=2147483647&v=beta&t=Di0DkuMY6yWi7NXG_bZcxvS4k6K1bttSqTOL0E9rcL0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cyanous: {
    name: 'Cyanous Software Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Swetha',
        lastName: 'Reddy',
        emails: ['sreddy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Harish',
        lastName: 'Singilidevi',
        emails: ['hsingilidevi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mani',
        lastName: 'Kumar',
        emails: ['mkumar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Prashanth',
        lastName: 'Kumar',
        emails: ['pkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE0HEOJiBJJUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612334910852?e=2147483647&v=beta&t=QRWMRP6wvIjntG4CuzQoxvI8A9e-wnx_iXjIc4ITyMI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Raji',
        lastName: 'Rajeshwari',
        emails: ['rrajeshwari@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nuventure: {
    name: 'Nuventure Connect',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adam',
        lastName: 'Kuhn',
        emails: ['akuhn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGIQdLxZAlW8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730204513999?e=2147483647&v=beta&t=buNdeRBfhQKdzEGStgOq_29V-qTczFG0u6S323UOMH0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam Pierce Nubern,',
        lastName: 'CPA',
        emails: ['acpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHMwc1ra68EQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516620838905?e=2147483647&v=beta&t=PRcdjuoseOU5hYKaQ0KQwqu-Pfy2Sa4vC7j30HjDHuI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tinu',
        lastName: 'Cleatus',
        emails: ['tcleatus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGFy0ZsrTeQ-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686449519193?e=2147483647&v=beta&t=COw2b4poEZ2a5ESbAJkM8jg9xTaLeJLpyUij3TGHRhI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bob Horne,',
        lastName: 'RICP®',
        emails: ['bricp®@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEvXM8wFEe08w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581905062273?e=2147483647&v=beta&t=WRrZOlsF2vbqaWMaa9_e8zeZWrbRmRvK5R9EkRzxSh0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rebecca',
        lastName: 'C.',
        emails: ['rc.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF8Ch06sG0kyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656532322805?e=2147483647&v=beta&t=u227g7vKSk3I1gWwuUZ60WFIQXEHlDy3EqglHeZenUM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  firstnet: {
    name: 'First Responder Network Authority',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'Bratcher',
        emails: ['jbratcher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGIowvKcW_jEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727648708414?e=2147483647&v=beta&t=w3eYeh8pt3mJnGdNEuWVFAI6brtam2N_pdWWOv-tSBc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jim',
        lastName: 'Gwinn',
        emails: ['jgwinn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Derrick Edward Wilkerson',
        lastName: 'Jr',
        emails: ['djr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFCjdj49L8Vwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595072345771?e=2147483647&v=beta&t=Oi5_UVjHodvuJY8DHzBpU9A4OxmD9Xm9vgzEYbut93E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nathan',
        lastName: 'Fenstermacher',
        emails: ['nfenstermacher@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dennis',
        lastName: 'Alvord',
        emails: ['dalvord@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHFo-iGeKFisQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726509495280?e=2147483647&v=beta&t=8iXHQ5Qip24cwXpF36RVQpwARVrqSW8W78llAl0zogA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  netis: {
    name: 'Netis Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Claudia',
        lastName: 'Tan',
        emails: ['ctan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lily Chia',
        lastName: '（謝瀝厲）',
        emails: ['l（謝瀝厲）@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gang',
        lastName: 'Dai',
        emails: ['gdai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH4j7tcJayXhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701631978373?e=2147483647&v=beta&t=gl9QjZCwZqaZ_xGAx9UIDuswYB2WT4xRuUgpzxVNqXo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'andregide',
        lastName: 'koumassi',
        emails: ['akoumassi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Melita',
        lastName: 'Gulja',
        emails: ['mgulja@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFeT31yg0lOTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712861813843?e=2147483647&v=beta&t=AZwmyMkw97YBfoowvNrYzsTlsWWYuFLCfd1S-xlPtEc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  teamporte: {
    name: 'Porte',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Donato',
        lastName: 'Macheda',
        emails: ['dmacheda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGsEawoBha0rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567145851596?e=2147483647&v=beta&t=XMxi3U1acdobnIVuCCxnwLrKCuLOeOl_nw3WKoYxGik',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alp',
        lastName: 'Çalık',
        emails: ['açalık@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHAsDcaaa-9HQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517373285245?e=2147483647&v=beta&t=hl7sbtVmhsyWMcIBk8efO5b1UdgqWP1U4WWQqgFmWDs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pritam',
        lastName: 'Gujadhur',
        emails: ['pgujadhur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFuEgdNq0PrsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683067102942?e=2147483647&v=beta&t=hfLqDv-Vc27iEkg7Jl-iVi0ANubstiSYmN0753eG2xI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Luc',
        lastName: 'Bouillon',
        emails: ['lbouillon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEpgW5asXVCXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606422641047?e=2147483647&v=beta&t=ScGDUmkzzwwZA2-kxxyk-n0LJJMrNDWevZa1KDqOt88',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Çağkan',
        lastName: 'Öztürer',
        emails: ['çöztürer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHFcSZF1vSxcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580121102803?e=2147483647&v=beta&t=SVFMztShuKG7Tgn2_xPDUVioLu6AkgDTn4YbGoEBmsk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  innoware: {
    name: 'Innoware',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dmytro',
        lastName: 'Popinako',
        emails: ['dpopinako@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF8XcTMPYlGVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516313653678?e=2147483647&v=beta&t=MS7jgDmgYKWTAEFLA58hbV-wFsADEruS99cFQ0Uy4TE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jinay',
        lastName: 'Gala',
        emails: ['jgala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGN3ilUq8FaoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517549406335?e=2147483647&v=beta&t=E3mf2cD3EyNUzcGALtUUt97GIPwQTk0OlvqFgbRq8BM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tina',
        lastName: 'Shearer',
        emails: ['tshearer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rajan',
        lastName: 'Ponnambalam',
        emails: ['rponnambalam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEzFz7nWHKANA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702650675250?e=2147483647&v=beta&t=VQPzMGL6zxak97WAONIeL2FXuUFp-M6u2Jk1YhBNQXE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vitaliy',
        lastName: 'Gram',
        emails: ['vgram@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH70KzZ-GUfiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629378271573?e=2147483647&v=beta&t=-l9KTehdLMQn7qwjbctjU2ZWWLGCkAPI_qrejBlejUQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  advantagesport: {
    name: 'Longbow Advantage',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Leigh Chesley',
        lastName: '⛓️',
        emails: ['l⛓️@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEGOc0CND_0qg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720704424684?e=2147483647&v=beta&t=DxO9FT-2Q0_bOxFdhDNYvfxPW8KAXSbvUqUlMJLhfAE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joe',
        lastName: 'Tate',
        emails: ['jtate@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGlHBqtcL3OXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689794504022?e=2147483647&v=beta&t=OB0mcZCXh6PkvUdof-0_AyZTAJX_zKraeGkUryGoK9w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Travis',
        lastName: 'Hinkle',
        emails: ['thinkle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH3slfqiuLZBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725644279030?e=2147483647&v=beta&t=xXkXyjfoG_134lyaxedLJCZu0b4ToPXoXkISD9yO4F4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shawn',
        lastName: 'McLeese',
        emails: ['smcleese@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFtMzzomEWFRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729518168053?e=2147483647&v=beta&t=8Oz-Zfl3m7Z2U5ZFrcWDXf0ZZ3VwMzUee9rT99dVy20',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chelsea',
        lastName: 'Brady',
        emails: ['cbrady@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFgqmeOK2u_Tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721578960681?e=2147483647&v=beta&t=IAL-Zh-lJtDX3BRfSRRJ3hL-F83zmAtooY8dS5eUwNM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vaultspeed: {
    name: 'VaultSpeed',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Piet De',
        lastName: 'Windt',
        emails: ['pwindt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sanne Van',
        lastName: 'Broeck',
        emails: ['sbroeck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFJPmq9s956eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713530792197?e=2147483647&v=beta&t=YMMs5HLjvnwzZMhIeyYqKDoMKkq39ah2_sGpzwO_aMk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marc',
        lastName: 'Coppens',
        emails: ['mcoppens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFcEGu9SlhXsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673605367888?e=2147483647&v=beta&t=Dp98w6_OAWa3Yt9paaQAQqqk3u090RuPmQeRrA8rcaQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jonas De',
        lastName: 'Keuster',
        emails: ['jkeuster@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bridgit',
        lastName: 'Potter',
        emails: ['bpotter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEynupjGPfz7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711666987358?e=2147483647&v=beta&t=iM_r0yJ7O8XrdWlJzI7clQGYbx5xEfLACZgQHgLo1qg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  programista: {
    name: 'Programista; JSC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stanislav',
        lastName: 'Dakov',
        emails: ['sdakov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHziFv_6Gg1og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517043993383?e=2147483647&v=beta&t=KQ8pP_vU8JQD1AbLMiEKGixs3lwVskpR61DQYgTqrY0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Iliyan',
        lastName: 'Galabov',
        emails: ['igalabov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHWvf818paLoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516872134478?e=2147483647&v=beta&t=nKAOcODa5h1NLvAt36luvt-alZm7rx4OGh6dPbW5xVU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cvetelina',
        lastName: 'Nikolova',
        emails: ['cnikolova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFtYRFmbNuKJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521555660271?e=2147483647&v=beta&t=HCm08Q_lZMUnwDGFA8YclyoRbp7iKzvuVYUd9jeePJc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nikolina',
        lastName: 'Kurtenkova',
        emails: ['nkurtenkova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHQo1ixyRmN0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634668779221?e=2147483647&v=beta&t=v5Dq3-_YBqwow4ZlZxRdPfQHB20bvTk0q1vV2I__3AI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alexander',
        lastName: 'Traikov',
        emails: ['atraikov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF6jqKTmtuJ0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517244565210?e=2147483647&v=beta&t=loJYB_vOhFggo2Duv23t_OvqJijHTyPWJFQDImmOmMk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  weblab: {
    name: 'WebLab Technology (WLTech.AI)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Islam',
        lastName: 'Osmonbekov',
        emails: ['iosmonbekov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGuCIfstb4odQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660823842334?e=2147483647&v=beta&t=QELPMcF9IQ6edtx3oL25VQbPlDUSF4cxflOvTI0CmCA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Inna Davydenko – Finance Manager – WebLab Technology |',
        lastName: 'LinkedIn',
        emails: ['ilinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGcYxLu9YszyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709568858435?e=2147483647&v=beta&t=v_p6UlIIYeawVnTHFcfsR0F7sL6d0sySPwUagWxyIjI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Semen',
        lastName: 'Salychev',
        emails: ['ssalychev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFz-DqLUaAOgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627400739097?e=2147483647&v=beta&t=qNRoJuBBSvOCWy_76lpo_6G2w9qCWweczipTEcNxrt4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Islam Osmonbekov – Full Stack Developer – WebLab Technology',
        lastName: '...',
        emails: ['i...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGuCIfstb4odQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660823842334?e=2147483647&v=beta&t=QELPMcF9IQ6edtx3oL25VQbPlDUSF4cxflOvTI0CmCA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kamil',
        lastName: 'Salimli',
        emails: ['ksalimli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFfVyhSeWqjZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713292041445?e=2147483647&v=beta&t=Jk_vG_hytckBzFMbb4ZD-eDrME_St3jqPhNQX2mYRUQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  boostinc: {
    name: 'Boost inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pedro',
        lastName: 'Príncipe',
        emails: ['ppríncipe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGPpKb0OgWUgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658277187389?e=2147483647&v=beta&t=lWQQV5CUY0FhyS1XOA_0M5nDlsEPPCBStyvBm8VZamw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alicia',
        lastName: 'Wanless',
        emails: ['awanless@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEp2Bnw5Y7bng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681843378564?e=2147483647&v=beta&t=2iXr3-2LaQcm8JSQn6uaYqlw-h8Fa_OA2fyPA7yx-os',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  citix: {
    name: 'Citix',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amy',
        lastName: 'Haworth',
        emails: ['ahaworth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE9dxvjLuRrLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637095349819?e=2147483647&v=beta&t=daHcq5zr27GTEztdyhHdWJgrmfRcNM7rCy6G32jQYxM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Roy',
        lastName: 'Greier',
        emails: ['rgreier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGpR8ZFQhFhGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723154619583?e=2147483647&v=beta&t=Ybc2_KBr3dFUMasgWwTXaVYe0DCcxPAkMMJuO2VtsRI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Avantika',
        lastName: 'Nair',
        emails: ['anair@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Farhan',
        lastName: 'Syed',
        emails: ['fsyed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFadHZec-6HAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671656545172?e=2147483647&v=beta&t=py78n0ZcRZhFesxXp3pTWoU6CtMZ2cqJKfJlpCF6jSo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lindsey Boggs',
        lastName: '☕️',
        emails: ['l☕️@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFBeA-X11XCEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726866035649?e=2147483647&v=beta&t=WO3qOdWpne7DahWT4MEdEYRVqT8CNrxL6Rzv0mzPtNc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gridlogic: {
    name: 'Gridlogic',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pariekshit',
        lastName: 'Maddishetty',
        emails: ['pmaddishetty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFrhFLZKx92jA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688380668745?e=2147483647&v=beta&t=CUYjkCitejVR6Df8cuiv5iyHaLqZz3tOG9xcJAYLO4c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anantha Krishna',
        lastName: 'T.',
        emails: ['at.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGDQct2R_iaww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644601056733?e=2147483647&v=beta&t=NUswu7PPF8fkNX_I8xuD5Mw6CO_hMaLeCYeutLa6rCU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Harish',
        lastName: 'Beeram',
        emails: ['hbeeram@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEOgHC5g2FWIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516993486668?e=2147483647&v=beta&t=KkcJNO8Ogo1DrRQpIN76Wahq9GCb_RIDP_PvdKhh2BA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Partha',
        lastName: 'Madishetty',
        emails: ['pmadishetty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGVt8kA_dYkFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638192396799?e=2147483647&v=beta&t=hmhSd_cuMBeGEwlML2g0369aodpY7OP_gEhHlch04o0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shalini',
        lastName: 'Vutukuri',
        emails: ['svutukuri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFLsAutRfVgmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688287976389?e=2147483647&v=beta&t=uc3tHZKnp-WT4WtekCUwM1oq6LaX-zrR1vxQ4AwXWEY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kaushalam: {
    name: 'Kaushalam Digital Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mrinalika',
        lastName: 'Jain',
        emails: ['mjain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEj36GD4rXMkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668095165473?e=2147483647&v=beta&t=02QZU3Nz7HNMr-hU_wZGoAkMjgt6EkORgZ19YD_yWT4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hetal',
        lastName: 'Patel',
        emails: ['hpatel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sukhdev',
        lastName: 'Chavda',
        emails: ['schavda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEteWvofe6PzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516923709367?e=2147483647&v=beta&t=4CTLfp6Yk3sLITqMwyflQp4N6F19lGOJw0aUvpVQm6M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Priya',
        lastName: 'Thaker',
        emails: ['pthaker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHdQdXYa84W4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708062185601?e=2147483647&v=beta&t=kV2qfPoWtJPNQrOABmm17DTXinwwt7UhuGaRSLHPYWo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kaushalam',
        lastName: 'Foundation',
        emails: ['kfoundation@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH1WrEGAY-OBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633693453552?e=2147483647&v=beta&t=L9vwAC0duYkYbf7EpauZGTuAYMMHnMmgHOk95uI7r-g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  optivor: {
    name: 'Optivor Technologies, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dearest',
        lastName: 'Chandler',
        emails: ['dchandler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brian',
        lastName: 'Clark',
        emails: ['bclark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGkeMPcgmAaZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516351175996?e=2147483647&v=beta&t=PD0eCuYdTFWBJI5o9ZagQZDXh_W-EutcEor3o9myrJY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cynthia',
        lastName: 'Brandt',
        emails: ['cbrandt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFY5nD7kFMJ1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521811641551?e=2147483647&v=beta&t=pJJZCZ88JZ4WK51TPAwGhVlx5bfl2qPlq7rStZXk9_U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Charles',
        lastName: 'Brown',
        emails: ['cbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFzcT0j3VD_PQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516614503543?e=2147483647&v=beta&t=-QhaYgs5HhCHlqr0VfcCsRJrp4Xw_aAf056UJL6xxdc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michelle',
        lastName: 'Bueti',
        emails: ['mbueti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEWfGhC5CpEuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692024459449?e=2147483647&v=beta&t=xGAYHzQQR2tGqYoS0X-EP_cfxOHzdneYF2VEsz5eakQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kajware: {
    name: 'KajWare sp. z o.o.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Barbara Rak – KajWare sp. z o.o. |',
        lastName: 'LinkedIn',
        emails: ['blinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFFG4auHWvnzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681906166653?e=2147483647&v=beta&t=yUCu2OUJ-jpx6M_NNh3pgmb9WMOP7kk67pG9VU3SQW0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName:
          'Ewelina Suchy – Human Resources Director – KajWare sp. z o.o.',
        lastName: '...',
        emails: ['e...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kajetan Woyciechowski – Head Management, Founder – KajWare',
        lastName: '...',
        emails: ['k...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHbbToivxHe-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536430677279?e=2147483647&v=beta&t=LpBbmXeee4aoqbUwYHApLcIm4nIf8R7DDCtGkkgsSrs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Patryk Bielski – Technical Support and Project Management',
        lastName: '...',
        emails: ['p...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGA4dDxrKzCbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680309698996?e=2147483647&v=beta&t=dHmBbRE8EoCSYCDd3lQ3URr3L9DdeWXBiW9aLQtRCDU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dominik',
        lastName: 'Trela',
        emails: ['dtrela@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE1R3S6FPVK_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628185585335?e=2147483647&v=beta&t=K20P8ccv3nU4fu-EGKPfNQIgrkoDX7t6jyVRMmtdXMQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  viva64: {
    name: 'PVS-Studio',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrey',
        lastName: 'Karpov',
        emails: ['akarpov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGPh7MEt7HVdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708278966941?e=2147483647&v=beta&t=A3uA9YWacTSiReluYgM_y-njZd95Ntx4G3FxD8yzPOY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Christopher',
        lastName: 'Beller',
        emails: ['cbeller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEJvvlat-6taQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660925295073?e=2147483647&v=beta&t=GPUwJBU943MLCbLZfoQLeOYRizRAq7I14fUEdV-B9bk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Troy',
        lastName: 'Benscoter',
        emails: ['tbenscoter@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephanie',
        lastName: 'Shelton',
        emails: ['sshelton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEbp95PpIZ3gA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595980315152?e=2147483647&v=beta&t=mNqWdKVsY8BrUoUWaAj0EkoRJFaPDAcDjCVr1yagtaY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sergey',
        lastName: 'Vasiliev',
        emails: ['svasiliev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH5t7wkNRrHBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654267826958?e=2147483647&v=beta&t=JZ8Nwtac4gmekI_s2pJHp5UXBI3wlaKkbPaswqwJI10',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  motivity: {
    name: 'Motivity South Africa',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Walter',
        lastName: 'Mutyavaviri',
        emails: ['wmutyavaviri@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Phoebe',
        lastName: 'Wessels',
        emails: ['pwessels@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFUYl7Kh3vW9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711458892230?e=2147483647&v=beta&t=HgYpKX184cYhyA1Jj8_8Mk_eV9rSoT3EFaft7dUM_Nk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Denver',
        lastName: 'Gabriel',
        emails: ['dgabriel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHqKcDtmJd-Hw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541503272675?e=2147483647&v=beta&t=Lo0fduIzmAkbfkjJgBHQ4AiqrZ3vGf46zbTkUsim_KU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Arleze',
        lastName: 'Flynn',
        emails: ['aflynn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFk2IrViDwAVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666207426728?e=2147483647&v=beta&t=gdzyisuuTh72GqI2G7yZsWjrdkQFXKu7fjJivJH25l8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Xabisa',
        lastName: 'Khampha',
        emails: ['xkhampha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH-A56jQ8cvaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579679472579?e=2147483647&v=beta&t=9fRl1X7GmxMe5l2nGyBy4ZM4qx3YZ0QULy2PntAiRCs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  convertgroup: {
    name: 'Convert Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kim',
        lastName: 'Dang',
        emails: ['kdang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEBV4QFbCdXaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632710956650?e=2147483647&v=beta&t=2GtY1A5_aBcCzYM-S5noG1THFzVJOp92DePENhvYO6s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jacob',
        lastName: 'Levis',
        emails: ['jlevis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGfnVwgDaXbhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631778474767?e=2147483647&v=beta&t=rKn7Qsjx1m7gYx-VhcGmZ99hS1COZeH_6eUL9bo8X7s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Panayotis',
        lastName: 'Gezerlis',
        emails: ['pgezerlis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEK9yJRXWtCRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728248527749?e=2147483647&v=beta&t=YuaT7kUO3LeYH63gCPcKF626LWwwoYNzu203AryTpDI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kane',
        lastName: 'Cooke',
        emails: ['kcooke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE0p8aLZSvZ_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696001213729?e=2147483647&v=beta&t=HIMJpwCP-0WTd1IVqiljh3z53u7YDmp7oq4w0kc8Rz4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elena',
        lastName: 'Chailazopoulou',
        emails: ['echailazopoulou@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFJ8MTYf3QjVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580547016417?e=2147483647&v=beta&t=zgGLDBPutEza3JShmUeWKwHI2LzyNhCcbH-4iuZhyzo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xoi: {
    name: 'XOi',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kristin',
        lastName: 'McKinney',
        emails: ['kmckinney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFRPSrdOg-WGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700175210079?e=2147483647&v=beta&t=eBWBl_NRsGAyH6oGGahiIfdFCs9cWeqWy6HmRbG3aE4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'C. Aaron',
        lastName: 'Salow',
        emails: ['csalow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGppLGe3Ryihw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579918436798?e=2147483647&v=beta&t=EIXPSm3f3nZV3OP0Wa81cam65t2ABucAC3t1UDVQlbY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Sloan',
        emails: ['jsloan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE3yHTJbhoYXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620599362220?e=2147483647&v=beta&t=BdPOqe42ya7E35yNNssx-DccqcQ7OyJKU9ndmClBO28',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paige',
        lastName: 'Collins',
        emails: ['pcollins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFwx2g_9oATZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637333947751?e=2147483647&v=beta&t=zRuqx-qP2Q0l6ObsiWI0E-qoQ1vPQYvqFVdO24h26F4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Caleb',
        lastName: 'Gregory',
        emails: ['cgregory@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cimpress: {
    name: 'Cimpress Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'Regan',
        emails: ['mregan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFiJZcad2xV1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709842511925?e=2147483647&v=beta&t=0Kx4ShrOs045Uiy9ClvWkM9V1iP8IF0MJzKgF9BhYe0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Oliva',
        emails: ['joliva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEa7rSSYbYHcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636864715434?e=2147483647&v=beta&t=KO1vo3T1E8KvGJXFzS2sRyxP8rTb_Q9BPBaIIkNkQtQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Takwa',
        lastName: 'Ferchichi',
        emails: ['tferchichi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEiaLHZgpHsjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729528182262?e=2147483647&v=beta&t=lA7zi8AsGQJTuXoM_mL2j_LDVciq8j9bMOX_ZBDYHGE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eric',
        lastName: 'Goodheart',
        emails: ['egoodheart@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jordan',
        lastName: 'Mackie',
        emails: ['jmackie@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  polarissoftware: {
    name: 'Polaris Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'McCallum',
        emails: ['kmccallum@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGTBePI3ZICtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665603820978?e=2147483647&v=beta&t=nwHQsQDsWsyUKerwUyZ8mDKQ4i6b0hCVwozZ2xl4Brg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: "David 'Miller'",
        lastName: 'Lowe',
        emails: ['dlowe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFSkDA4riP4Cw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557427130166?e=2147483647&v=beta&t=ZjYKpYoR0NOaeUIxpUEu0uDd_njv84aS4dgR_86Uz1Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Steven',
        lastName: 'Schiebel',
        emails: ['sschiebel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rohit',
        lastName: 'Sabnis',
        emails: ['rsabnis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'polaris software',
        lastName: 'solutions',
        emails: ['psolutions@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG-xqQy7umI7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626075903851?e=2147483647&v=beta&t=HZibpkZtcxx9NHx5bLcMUuMw6UOoqM8Qi0fqIrEd5LI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  idocs: {
    name: 'idocs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dave',
        lastName: 'Brown',
        emails: ['dbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHkv-30Ko09NA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531857519366?e=2147483647&v=beta&t=dNFHnD3rAB6MOlOkOnwvHuwrsUoD8ZQAGB-Tl-vrRrQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kelly',
        lastName: 'Connor',
        emails: ['kconnor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEEFwqVgFKZ7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689599038256?e=2147483647&v=beta&t=agXniOiGSc4-idww8GZn74uCF6ePmZeGqhql7mG5N-s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nella',
        lastName: 'Gonzalez',
        emails: ['ngonzalez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Steve Vargo, OD,',
        lastName: 'MBA',
        emails: ['smba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGlGBN4doJvvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516501190835?e=2147483647&v=beta&t=MpzSxuutXQuyTLfizJr7PGjewgQQvgmxU7jEV5gii7o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Craig A.',
        lastName: 'Belt',
        emails: ['cbelt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGsGWA1DX--wA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517285440207?e=2147483647&v=beta&t=H1tpvvMc0EbHojRehZI-6GqyVLsn-gWlVbozekJotck',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gosaas: {
    name: 'GoSaaS, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hassan',
        lastName: 'Ramay',
        emails: ['hramay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH-NzedFFAKEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516315195489?e=2147483647&v=beta&t=0JhW6HV9qXwtHquVHKLGytVqGZTykSMA_8RUvXeQpxU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Tolmachoff',
        emails: ['jtolmachoff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF6fxGxwWOFrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704464754914?e=2147483647&v=beta&t=L6VaX2wnDi_5ReLV5djO1Ar11fP8GVBJdnt7Y6DUv8M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rabia',
        lastName: 'Malik',
        emails: ['rmalik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFRO7pyxcQDlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553077536479?e=2147483647&v=beta&t=65N0DgzQsyWbHVz_VyAW2An5pORKOsRRF0ZYX7nE-RI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Avery De',
        lastName: 'Marr',
        emails: ['amarr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQH620aatl0sbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516356399707?e=2147483647&v=beta&t=cUpxsPcy1RfQ1wSg7oshrr_IddWJCC7g-6G4wulmSKs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mohsin',
        lastName: 'Aziz',
        emails: ['maziz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGbT_QWWAdQnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674215849953?e=2147483647&v=beta&t=Z0u-AuqazZX781RlpKPYTsoig1pUg62gVPPnWNuB7_c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nec: {
    name: 'NEC Argentina',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Enrique Octavio',
        lastName: 'Moressi',
        emails: ['emoressi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG25OhvuyPcLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665176147823?e=2147483647&v=beta&t=9_nba1eYit-FXe4C5sSu3Is18tumLEbtA1PfoEH4gZ0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tomohiro',
        lastName: 'Nomura',
        emails: ['tnomura@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH9galHqHnFhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556806503714?e=2147483647&v=beta&t=uCwQs3zaNyCHi4AwGPPRuh1J9nlXmJp6CAwY63DKuUA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alejandro',
        lastName: 'Aramburu',
        emails: ['aaramburu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHSiU9OZTVTUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570060089070?e=2147483647&v=beta&t=nqS0xtxeHtEaYCKffqxKENcIrajP8gli37a6Mv9U-P4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mariana',
        lastName: 'Lagreca',
        emails: ['mlagreca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFIgs3Ocfs7sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727885416632?e=2147483647&v=beta&t=0zXuShzGInjCSF2VK2Ix1N9zlXbm3rFs7iqFjtWwWIo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nicolás D.',
        lastName: 'Egiaian',
        emails: ['negiaian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQESXt9Mud-OSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638197060035?e=2147483647&v=beta&t=miP3pkM6a7frNolyr8Nvtlu5-gTbXkdeU7KQP4bfJdM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trinisys: {
    name: 'Trinisys, a Harmony Healthcare IT Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'William',
        lastName: 'Bartholomew',
        emails: ['wbartholomew@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHdA548FsNIFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684298853974?e=2147483647&v=beta&t=Eo9Wg2B24gISCxs6Ur9t7agKCYlNmyDayyaUTs7ZlTg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ryan',
        lastName: 'Roth',
        emails: ['rroth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEXqJSfS6In0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728923026209?e=2147483647&v=beta&t=Fh-Te6dstyka0V_5K3HdpZYiP-TZ9r-HT8x5c1t1XZk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Beth',
        lastName: 'Hoeg',
        emails: ['bhoeg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEHH4gsFLHM9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705680659413?e=2147483647&v=beta&t=z6hRGAPwNRwGfqpHZ4fjco6cQLbDv5dalDI2hW40_ro',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amy',
        lastName: 'Carden',
        emails: ['acarden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEWSYgpEkVPHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580149230492?e=2147483647&v=beta&t=qeXFE_SuP7jgZX6PChDZMdVa2oXaGl8IjxRKEiJ-MWs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adam',
        lastName: 'DesPrés',
        emails: ['adesprés@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGaO4YtxYXbiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710282543857?e=2147483647&v=beta&t=b_dNjiX0YPiajWDwlGMFDcBUjveayLhtpq8f4icS7MQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  abilitynet: {
    name: 'AbilityNet',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'Walker',
        emails: ['mwalker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ashley',
        lastName: 'Peacock',
        emails: ['apeacock@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Adam',
        lastName: 'Tweed',
        emails: ['atweed@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kelly',
        lastName: 'Chan',
        emails: ['kchan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGAosVQ9INSFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631368697898?e=2147483647&v=beta&t=kAdDaD_j6g3xGZjRczmXt6e3O3n8u4Tbmz4NTjAbDJQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amy',
        lastName: 'Low',
        emails: ['alow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEwZviJG-kURw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642172811600?e=2147483647&v=beta&t=pWWc2Nn1EoHe3wCGfKE_wun-bziiElpXoCktKhorbK8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  techfabric: {
    name: 'TechFabric',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Preetham',
        lastName: 'R.',
        emails: ['pr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEr7EsuReb-Tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702777250419?e=2147483647&v=beta&t=zU1dPNqoSTenzTYp7ai_v7OfWsiRpJuh9S-hIoSrIYM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alison',
        lastName: 'Conley',
        emails: ['aconley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGZwkJ5rwLixA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639165438722?e=2147483647&v=beta&t=ADIX6M1t0qus2ft2ImbciI3LCyFyL7aJIX9CbgIDAgs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Karan',
        lastName: 'Sharma',
        emails: ['ksharma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Praveen Reddy',
        lastName: 'Kunta',
        emails: ['pkunta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHg66Obk5AHLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673710337419?e=2147483647&v=beta&t=-xe9qlI_0kyBDzKQTiLt-YnzTSZNuEUUn7OX3oJkv78',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abraham',
        lastName: 'Penuelas',
        emails: ['apenuelas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEXKpIWWnnekQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704739280449?e=2147483647&v=beta&t=i0zkxhy718P2mPBnZ7oSYte9tAsV3V_2k03eWRvVggQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  arkusinc: {
    name: 'Arkus, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Justin',
        lastName: 'Edelstein',
        emails: ['jedelstein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG1NMN7BwzBxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575731286954?e=2147483647&v=beta&t=vHusWDN8v5HwT8qMXNJCTg9ocAblM06FhwTw5eVQ5No',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Atwood',
        emails: ['jatwood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE9BWbOz-R_VQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516273995746?e=2147483647&v=beta&t=5uWXXCkizSlBSxBqcY7sGbjPxHB-I59hcSi-MZXPYOE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ryan',
        lastName: 'Boyle',
        emails: ['rboyle@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Angela',
        lastName: 'Hatem',
        emails: ['ahatem@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHpLcArwMhzFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596930994108?e=2147483647&v=beta&t=_UlqKtEMk2yQWzJUaeaRTSX8HK3TbW-PFEjSPSjDHdU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eric',
        lastName: 'Safin',
        emails: ['esafin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGp2oBpx3k40Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614621071526?e=2147483647&v=beta&t=yktzfVtJBdRyAzqC2SMHDv2_Q5iho7m58o6QsV2Lus0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mhance: {
    name: 'mhance',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'Evans',
        emails: ['mevans@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE-Y8GLEuEkfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708113795136?e=2147483647&v=beta&t=G0WOP-p4LhzQiolxqtHcPh3CZIOMnrzjiU54m-TlWrs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Curtis',
        lastName: 'Elliott',
        emails: ['celliott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGkKnnoxPgLZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701774120177?e=2147483647&v=beta&t=IN83aRfwIMSW6WLUQYsHcU5zIe4rmMoqrefmkKnlAAI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Terence',
        lastName: 'Stevens',
        emails: ['tstevens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGKs7B0pRi8jQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598702173890?e=2147483647&v=beta&t=8tyFFzmZkaH01zHrtBnJZnhj5aX26Xv84r7pk2HoBxE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alan',
        lastName: 'Moody',
        emails: ['amoody@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHYPS6zh25CsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656669302221?e=2147483647&v=beta&t=aZ8Vt5WmMTCJ-m3GhT3_mJzwJwXVRaA6Ccex_BXypVQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rachel',
        lastName: 'Sayer',
        emails: ['rsayer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  binalyze: {
    name: 'Binalyze',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Emre',
        lastName: 'Tinaztepe',
        emails: ['etinaztepe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGfvqm4CVi2Vw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634415847466?e=2147483647&v=beta&t=JVhCRM425MTrUw8licsPJLrSEvOhgnBSGyGVTCKZEJs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gregory',
        lastName: 'Vlahos',
        emails: ['gvlahos@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Steve',
        lastName: 'Jackson',
        emails: ['sjackson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robin',
        lastName: 'Trickett',
        emails: ['rtrickett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGbz2ge4bpRXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572532366045?e=2147483647&v=beta&t=dTBr8Lbfq4wkj4Z1AyJBWwt4FdeCWo4S1ViRg37lHBI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marie',
        lastName: 'Wilcox',
        emails: ['mwilcox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG4mQDPNA9eBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684854344912?e=2147483647&v=beta&t=Sz0yiCiKat1rjh3BMMZyWmniGO_8KJBOQJsbusSvosk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vectorsynergy: {
    name: 'Vector Synergy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Zuzanna',
        lastName: 'Marzec',
        emails: ['zmarzec@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHEhUH83q82Rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728552164548?e=2147483647&v=beta&t=eMDlA3FodFkAwTwbQDk7a3lVVCD5iPZyJcQaiSb296o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Wiktoria',
        lastName: 'Szydłowska',
        emails: ['wszydłowska@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEQyZbEeqQn5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701947378843?e=2147483647&v=beta&t=ZsVHLRbca7ACbh5YXYOLeyiQHW0uO-aKAkPMWSqDtk8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gabriela',
        lastName: 'Rollestone',
        emails: ['grollestone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH2sQsroBRhaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728913847705?e=2147483647&v=beta&t=AaGKEvo_KeeWgxz-5PQZZMXpiiy9pdhv9r-PSlSaVMg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Natalia',
        lastName: 'Czornak',
        emails: ['nczornak@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marta Kuczynska Djemmali – Program Manager – NATO',
        lastName: '...',
        emails: ['m...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  grassdoorlogitech: {
    name: 'Grassdoor',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Abhik',
        lastName: 'Gupta',
        emails: ['agupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF6M6Qce_xp9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648130195591?e=2147483647&v=beta&t=posCSu_Sg7t3L5-3DKIaW6GOHIluYlEhASn7_zujmag',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tilak',
        lastName: 'Sengupta',
        emails: ['tsengupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFIcyiayA848w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517677342859?e=2147483647&v=beta&t=i6BLmYLyyPqmKug_E48oHvpSsSxHnA4i9zZ6f_dROn0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Makrand',
        lastName: 'Patwardhan',
        emails: ['mpatwardhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHPzgP7O52MgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720609107768?e=2147483647&v=beta&t=48mwfbyT5LQKf6jbvQGaLAheP5kbmQdoyNtXSDC7v6c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tanvi',
        lastName: 'Doshi',
        emails: ['tdoshi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGD2tZVMZ_YrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728151007667?e=2147483647&v=beta&t=eMUQ6M-ipNYXtGHbwsxdvIRv1A41AiJlwHgo_sj88Gk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Saurabh',
        lastName: 'Vasudeva',
        emails: ['svasudeva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFJEaLzvH3kxw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1650892057371?e=2147483647&v=beta&t=vhDzD-LHNlaT9ExUHkCwmyO8LiA8XJz9hfLFAGIysiw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ivyPartners: {
    name: 'Ivy Partners SA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Maxence POULIN – Ivy Partners SA |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFUR3CfL73hmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729278415752?e=2147483647&v=beta&t=TdGv7q0scVY61kqDqrcZgB0dsnElC7b5hAudutP1a0k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Naguib',
        lastName: 'MAOULIDA',
        emails: ['nmaoulida@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFiDFYuDS8WEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728376586537?e=2147483647&v=beta&t=fem3BY-droksbvLkBj7OaGy9kH7PczLzkLcyF5G88Y8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vijay Elumalai – Principal Consultant – Ivy Partners SA |',
        lastName: 'LinkedIn',
        emails: ['vlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF9KFwsmtKSsg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728318503591?e=2147483647&v=beta&t=2YQWzi6Eatz9XoXuz4HT6rAkjisEhIzEmPAOq-FF4Gg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jean',
        lastName: '',
        emails: ['j@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName:
          'Mathilde Auvray – Business Unit Manager – Ivy Partners SA |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHWYfkINfGkvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729181304816?e=2147483647&v=beta&t=u2aP7IIwGof-op9jXfsUNZmxqT9JD9o7-nEOkL591T8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hctc: {
    name: 'HCTC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Susannah',
        lastName: 'Fox',
        emails: ['sfox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHY8QsT-tTS8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516228949322?e=2147483647&v=beta&t=Zwvk_Jt7khFL6Ar5To3djOIhh4bs8Ew54lq0joH8DSo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carmen A. Peralta,',
        lastName: 'MD,MAS,FASN',
        emails: ['cmd,mas,fasn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erica',
        lastName: 'DeBoer',
        emails: ['edeboer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHaaSpQbP3srA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648045649298?e=2147483647&v=beta&t=fw0HzOOjU_PtEwTPz03dsh8rTwIGkVWV9PkNSje86pQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joey',
        lastName: 'Johnson',
        emails: ['jjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEKZ0-XirUCnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1565277538270?e=2147483647&v=beta&t=3OMW4mEAQBpWXZL-u12GcZ3tIHc4Zu6Bzii6s_aDsAo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Wes',
        lastName: 'Bailey',
        emails: ['wbailey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGH5ddeFfk4PA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677521583253?e=2147483647&v=beta&t=Y4zCnoKTwaiqONSofkNyUPDxNgnsfSvcFfVAZTALeqs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pitechsol: {
    name: 'PiTech Solutions Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joshua',
        lastName: 'Spencer',
        emails: ['jspencer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHSwjLkTmIV2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618935185724?e=2147483647&v=beta&t=VUcapJ3HV0PrVBXG0cg-HaDg0oY1GytY40xuAe3NWIY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Loretta',
        lastName: 'Moye',
        emails: ['lmoye@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'AJ',
        lastName: 'Valentin',
        emails: ['avalentin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEWgUKyAtxHKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694806267198?e=2147483647&v=beta&t=KGcJVfZ0aKFz1icMtkcqVFK60YFBYl0JywOFusKO7ug',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'LaToyia',
        lastName: 'Crafton',
        emails: ['lcrafton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEKQPdOWQbkBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516947993711?e=2147483647&v=beta&t=X6eZs6n2i5TjBu77zbuTF4slAp2iKC5Q6cqJsCZe3_c',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'VR PI Group of',
        lastName: 'Companies',
        emails: ['vcompanies@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGGsN-Ur9CZCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703955406427?e=2147483647&v=beta&t=c7o9EJk-sqlwC03LnU6bYOj7dNeTNW0ojmSbx6iU5Ac',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  staffcapital: {
    name: 'staff capital',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Carolyn B.',
        lastName: 'Stanley',
        emails: ['cstanley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFjXk1p0LujeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541723305774?e=2147483647&v=beta&t=lyg2waIAMxs73i1CnimZqK15g_V1Gm0IenyLeYwjnzo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cindy',
        lastName: 'Park',
        emails: ['cpark@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Maggie',
        lastName: 'Tucker',
        emails: ['mtucker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEy0AoQVHcZXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670710841862?e=2147483647&v=beta&t=YnouiiNWoRD4TtVp8UeV897kQE9_aaGH8fyB4JC1RZ8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexander',
        lastName: 'Staff',
        emails: ['astaff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQElOTLcLefUng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692227182943?e=2147483647&v=beta&t=ZdR5EBgBVrdYCcFRomQLNyxlvloKuu6XRY9u9rxRstw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'JoAnne de',
        lastName: 'Freitas',
        emails: ['jfreitas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHETxnGSEl_kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696455002641?e=2147483647&v=beta&t=SZD3IcrvKPQhPpQ6FOiYYvaPrpm8v-xJDFxjkYQkGS0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  infolojik: {
    name: 'infolojik Yazılım Hizmetleri',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Damla',
        lastName: 'Akarlar',
        emails: ['dakarlar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGm5Msx21xUDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723438602654?e=2147483647&v=beta&t=M4d6AVmxmkpJFXURPurrWodgmR5Ni0Mg2CaLNu5dVfQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Fatmanur',
        lastName: 'Çetin',
        emails: ['fçetin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE-YKSqPR09Bg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690876080507?e=2147483647&v=beta&t=PaJjtxvZVJp-sFHaOspOLGGIV_uznfObcBCXqiNssew',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Hazal',
        lastName: 'Aydemir',
        emails: ['haydemir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG1UDNyMkqmhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572275207253?e=2147483647&v=beta&t=n7-NYvUyA11F7JcqPMdKQtibQ6S7ue0sMmxvkzeg9l4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yasin',
        lastName: 'Galitekin',
        emails: ['ygalitekin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQET-mfbj4Rj7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668159998121?e=2147483647&v=beta&t=Hhei0XSW6xwhgNiwu_m3DeaOJ_RVVfai6-7NcTLXB-w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ali',
        lastName: 'HANBABA',
        emails: ['ahanbaba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEIHwolGkv9Eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606496998618?e=2147483647&v=beta&t=sOLwihL9Y2uwmavqK-xKwhJxzkeAaRuVWBEbs-3GClA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pipeworks: {
    name: 'Pipeworks Studios',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul',
        lastName: 'Whitehead',
        emails: ['pwhitehead@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHirXmjBv2uag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641575339395?e=2147483647&v=beta&t=hJ9r8QOmvGj7UhF8nWoevXN6Q2L-AEbeAbYx6hM1mC0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Monica',
        lastName: 'Fan',
        emails: ['mfan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Benjamin',
        lastName: 'McNulty',
        emails: ['bmcnulty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH-Ra8x6Kl6PA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611273999367?e=2147483647&v=beta&t=7heLUWkmaOO9TSpjJZ7uvMIxF3abybHLDdoODjkapoE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kevin',
        lastName: 'McGhee',
        emails: ['kmcghee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEz6EC4F4phag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668794831990?e=2147483647&v=beta&t=SQSh7IIGUcEo7XU2oPL3VHpP4k07eAw5Q0A9GQcmUds',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emma',
        lastName: 'Overmyer',
        emails: ['eovermyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHouA4NDQxbcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626885257153?e=2147483647&v=beta&t=rhL2V6uT8Fyn9GxOfF8rhZxx0X6SnCODJG_jzZ7PWK0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fxbackoffice: {
    name: 'FX Back Office',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alberto',
        lastName: 'Arana',
        emails: ['aarana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFRZnRwxc3Jeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517517089043?e=2147483647&v=beta&t=fStrONyo20MWg2aBx-9dTBKfBGA3EB3ZyR_ixZHd5cE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Danielle',
        lastName: 'F.',
        emails: ['df.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEYdIg6GAupDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517511692800?e=2147483647&v=beta&t=Q6aaTTl2bvraMx3TLbGR1y3TkApxi4o--N3XeFxjGWY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'Knoop',
        emails: ['jknoop@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFW069_QZ0KfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548868278939?e=2147483647&v=beta&t=ytYDCRnM-kXxePjAgYZw1d2yqB8gFYmigHl4v6pkzeE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Polina',
        lastName: 'Demidova',
        emails: ['pdemidova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGES7RlTqZGKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669887838272?e=2147483647&v=beta&t=rPjiD7qe-aSFYKTAmREUIbIc_cZOGBoMSolgjCZJ5L8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anastasiia',
        lastName: 'Kryvko',
        emails: ['akryvko@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kenificC2: {
    name: 'The Kenific Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel Lopez,',
        lastName: 'MBA',
        emails: ['dmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGdoezbnPsu2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320696620?e=2147483647&v=beta&t=-j8I4zV4M2EM7h0XgVecZKZbPnD57hlKpQGnQwyODqI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Colton',
        lastName: 'Keyser',
        emails: ['ckeyser@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joshua',
        lastName: 'Burch',
        emails: ['jburch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGQQh6g8xvC5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662405367456?e=2147483647&v=beta&t=iZodHrrfSK10IBEbq6zg3B6weGgN9uD2n-UNpEo0l-4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cedrica',
        lastName: 'Jackson',
        emails: ['cjackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHvvzky9MDp1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570398410345?e=2147483647&v=beta&t=5z_pvcn3NuAxu0V1z4C5Tf2JBDCPvCZWz8ulMHbeNxI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jack Robbins,',
        lastName: 'SPHR',
        emails: ['jsphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEsf5udpG6Jdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517756054446?e=2147483647&v=beta&t=TLKyliSMjOHNsKdRgOarC1kLLM1rgo2GalnPzzG5L6s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ideaware: {
    name: 'Ideaware',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrés',
        lastName: 'Max',
        emails: ['amax@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEdB0DQ5ZFusg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714662109183?e=2147483647&v=beta&t=GZAWVVaw5fGAzjpN-B63LckaIjQP6YAeSex1M4UWkyE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gabriella',
        lastName: 'Lignarolo',
        emails: ['glignarolo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFLgCl1iliePg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621646636563?e=2147483647&v=beta&t=gbZJs-P4w1WPQLkhbSkgFM3Kz0mRkDFwY8lDcKEOj40',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Juliana Ariza',
        lastName: 'Daniells',
        emails: ['jdaniells@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE7gw27neiOBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680359136387?e=2147483647&v=beta&t=nwcmT89vmLuGgNyo3MKfDU436A588bJDC8FMqqw1Sl4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maria Cecilia',
        lastName: 'Abello',
        emails: ['mabello@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHOUvFjY5JSaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517450144536?e=2147483647&v=beta&t=nYKlJSV0J_acJl7gGqXoWNf44qfGqvq7TQvBQQ1wlx0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ali',
        lastName: 'Peerbhoy',
        emails: ['apeerbhoy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bintime: {
    name: 'BINTIME IT Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'April',
        lastName: 'Cosentino',
        emails: ['acosentino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFiegdAnGc2hg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516589473338?e=2147483647&v=beta&t=zQhkDiPuPA0u1xZIEwW3qnbVz6ZWDyn8vgKmpyC1cRs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cortney',
        lastName: 'Bean',
        emails: ['cbean@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE86UyGrUxMOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598917866573?e=2147483647&v=beta&t=bZY05MEZaYc2lhL42_hrwc0R7QHqA6X-wi8_ah7t1P8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ivan',
        lastName: 'Sierov',
        emails: ['isierov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dmytro',
        lastName: 'Mytko',
        emails: ['dmytko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEu4N8ZE6Trtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516306955786?e=2147483647&v=beta&t=HXCSthPF8eI9_FDqdySi8e6RqYYif8kOePPh21hvF8Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dmytro',
        lastName: 'Yakub',
        emails: ['dyakub@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHbmw-CCvVnug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517790113980?e=2147483647&v=beta&t=8g3tYnccCYXv1t5A69Oi7-wqacHI291_3yplm0Pwtn8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pantheonData: {
    name: 'The Kenific Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel Lopez,',
        lastName: 'MBA',
        emails: ['dmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGdoezbnPsu2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320696620?e=2147483647&v=beta&t=-j8I4zV4M2EM7h0XgVecZKZbPnD57hlKpQGnQwyODqI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Colton',
        lastName: 'Keyser',
        emails: ['ckeyser@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joshua',
        lastName: 'Burch',
        emails: ['jburch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGQQh6g8xvC5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662405367456?e=2147483647&v=beta&t=iZodHrrfSK10IBEbq6zg3B6weGgN9uD2n-UNpEo0l-4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cedrica',
        lastName: 'Jackson',
        emails: ['cjackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHvvzky9MDp1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570398410345?e=2147483647&v=beta&t=5z_pvcn3NuAxu0V1z4C5Tf2JBDCPvCZWz8ulMHbeNxI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jack Robbins,',
        lastName: 'SPHR',
        emails: ['jsphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEsf5udpG6Jdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517756054446?e=2147483647&v=beta&t=TLKyliSMjOHNsKdRgOarC1kLLM1rgo2GalnPzzG5L6s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  secuinfra: {
    name: 'SECUINFRA GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jona Heitzer – Cyber Defense Consultant – SECUINFRA GmbH',
        lastName: '...',
        emails: ['j...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEk2xKPW7jPlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618496151056?e=2147483647&v=beta&t=cPj2lhF6mfS2_uNKmWuwivaFFR1RlCTwbNpaKwE1BsM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ramon Weil – SECUINFRA GmbH |',
        lastName: 'LinkedIn',
        emails: ['rlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGi3NmPMFXYFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516308548043?e=2147483647&v=beta&t=dp-1DhHP2yo_OPyeC0uXWnZfWNHuFbGKpVr9MPeZ8gI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Semiramis von Michaelis – HR Director – SECUINFRA GmbH',
        lastName: '...',
        emails: ['s...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFCY1-QBZNbfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677570136906?e=2147483647&v=beta&t=WwK6dGdk1HBvz2TYLkb46Q4mEju35-Kb_BJOcJ7ztso',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christina Hatzigeorgiou – Sales Director – SECUINFRA GmbH',
        lastName: '...',
        emails: ['c...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hasan',
        lastName: 'Hekim',
        emails: ['hhekim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHiEsNwrmXTdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681488563493?e=2147483647&v=beta&t=5qPyEW-UDVUCeSIUr-0NjomplG1FUchq2uJ1MLBMeY8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  digitonics: {
    name: 'Digitonics labs (Pvt.) ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mohsin',
        lastName: 'Sarfaraz',
        emails: ['msarfaraz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGVIV6OpXM1kA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638565809959?e=2147483647&v=beta&t=nfBnOOOoMVM7-_5U4Tnvz7h4oyvDkQMu8Qg8XggsKxU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dennis M',
        lastName: 'Pereira',
        emails: ['dpereira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFNMwdr7j7Yww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607636805560?e=2147483647&v=beta&t=qsgZIeEkuoOdl__QFPLMxAzYAyaBHueKsM1vsmk7kJ4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Hassan',
        lastName: 'Mansoor',
        emails: ['hmansoor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGCvN_2qMLziQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516778533883?e=2147483647&v=beta&t=JkpaW2mSHoAL-vNuBEBots-p0BpecYaO8mSIDUdQKRw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'khola',
        lastName: 'Azwar',
        emails: ['kazwar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shahrukh Tareen – Digitonics labs (Pvt.) ltd |',
        lastName: 'LinkedIn',
        emails: ['slinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGNERgGrSqcNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517619125476?e=2147483647&v=beta&t=0z6vkW6rz18IHtxrsEHzCP_bznAKr0mo_OsSNm83ADE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  krafton: {
    name: 'Studio KRAFTON Montréal',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kim',
        lastName: 'Derome',
        emails: ['kderome@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQElugc_rZ9dAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666011607349?e=2147483647&v=beta&t=mHB1L0iAAIQ2lcliGzeg79H_L2WBtk0V6UKxheEu0-Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nury',
        lastName: '',
        emails: ['n@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFQHWP3fYf2qg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691098163925?e=2147483647&v=beta&t=dBHGY_5CS5JDw32ucJTim6gQ_SA65mrLm9Z4e_0NnWU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amelie',
        lastName: 'Beaudroit',
        emails: ['abeaudroit@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGcrwxNqPxIHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704746391817?e=2147483647&v=beta&t=esZFSHrjFUrLaFusTnQTDNmZwVo0YEmFNeeFNXI1hUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joey',
        lastName: 'Deom',
        emails: ['jdeom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH8MSE9jy2vHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710248645139?e=2147483647&v=beta&t=4yS9ceFlbDlsWcejELPs87SPsuoZimCHguw2D-XyhWA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Walther',
        emails: ['jwalther@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHNBtxuTgbMzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703468429597?e=2147483647&v=beta&t=dVGLmmE2QMRURrOHx5Iljt1RgmOv9c1ugvkqbs2BGcA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  senkron: {
    name: 'Senkron Tech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Fatih',
        lastName: 'İPEK',
        emails: ['fi̇pek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGFqQC3iWPSGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564603031932?e=2147483647&v=beta&t=IXrwbrio36mOSc2Oh0H0k_h5L1kdulQgAlCmj52ULDo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Omer',
        lastName: 'Adiguzel',
        emails: ['oadiguzel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFj9OOEPKLmsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667242411251?e=2147483647&v=beta&t=Kt8qSfHBI3bsE9gC6j7p7cSU3GKCsHcY_m6VKm77MA8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Buğra',
        lastName: 'Dere',
        emails: ['bdere@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHCODAaE0tA1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616052865679?e=2147483647&v=beta&t=_NnUfZfANG8hKY8onhcBN4u1otcg3ikJkhbZLbrdseY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Erdal',
        lastName: 'Ozkan',
        emails: ['eozkan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHrtcB4WQgZMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672037094760?e=2147483647&v=beta&t=gldTsln69WRb7viiLVlacD6uZ6TB4GGVoXeNa_gTPmk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Betül',
        lastName: 'BEKTEŞ',
        emails: ['bbekteş@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bluebridgeone: {
    name: 'BlueBridge One',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jon',
        lastName: 'Ryan',
        emails: ['jryan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHD3SnBTszSVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517689197425?e=2147483647&v=beta&t=ghv85QBnrj2QaYQqhhAqpPWrCGh2zHa8reTteoxpnmM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Derrick',
        emails: ['mderrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFm2mtDfKTUEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670419267725?e=2147483647&v=beta&t=pGoY-SpXGdYNr5-Q4f_roKJW209EIGIa-DaS3FOMT88',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeremy',
        lastName: 'Travis',
        emails: ['jtravis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Emma',
        lastName: 'Barnard',
        emails: ['ebarnard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEP_WdAWYen1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632221160992?e=2147483647&v=beta&t=I6gRxpSHOO5-VrXZqCSzQnz0obfQ91G-JREthe1zaYU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Annalie',
        lastName: 'Frost',
        emails: ['afrost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEixTHbPJfDyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573292133670?e=2147483647&v=beta&t=U1CWEG_K_0lJ2Az5vW6w7oXqhKXTykVdNlZ3D24wUf4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  yapiapp: {
    name: 'Yapi',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Dorfman',
        emails: ['ddorfman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jack',
        lastName: 'Montgomery',
        emails: ['jmontgomery@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Lucia Yapi – Search Experience Marketer & Trainer – Yapi Web',
        lastName: '...',
        emails: ['l...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Umut Seçkin – Project Manager – METAL YAPI |',
        lastName: 'LinkedIn',
        emails: ['ulinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHR4EeTdmvYpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703520587318?e=2147483647&v=beta&t=7hXYMJqCJu-XYl8LDOyYPnMMUTodNtnt66I_75pFQeg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sharon',
        lastName: 'YAPI',
        emails: ['syapi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEaGOktiklT-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720222009839?e=2147483647&v=beta&t=BPpMfnPxwsKio_5S_5J-DWixN6xKInUb3-rZCwBuM_Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  developertools: {
    name: 'DeveloperTools',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Haoran',
        lastName: 'Zhou',
        emails: ['hzhou@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Callum',
        lastName: 'Gordon',
        emails: ['cgordon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGu2OETifLpxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675181932856?e=2147483647&v=beta&t=AdYjqo46grvqo77duEWX_biSk6WyGcQQ_-_TaYQCrck',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nargess Shabani',
        lastName: '爱达',
        emails: ['n爱达@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFWx5J7n02usw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716322683615?e=2147483647&v=beta&t=_U1HPFgumVp8yDwpD3mLi2IYiwFw6yir7PvVad1BkBY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Harshit',
        lastName: 'Tripathi',
        emails: ['htripathi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gal',
        lastName: 'Cohen',
        emails: ['gcohen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF7wqe7hGk2jQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602845570256?e=2147483647&v=beta&t=I5KIf0CvBOKb7Cma-oeMw4EsfnC_dCi8VCeovU1GU2c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  itcservice: {
    name: 'ITC Service',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Peter',
        lastName: 'Anderson',
        emails: ['panderson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kate',
        lastName: 'Anderson',
        emails: ['kanderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFXUSZ4EBdidg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516927467101?e=2147483647&v=beta&t=Wu0g8Hx8AthT-ty8LAtC6VoHezJjI4PEpzHWjMpieGw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dean',
        lastName: 'Collins',
        emails: ['dcollins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEN0Y23HL5KQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709563713019?e=2147483647&v=beta&t=OuVzSFKtyB9xtHHvXcQQACgk12-c0BLo5maHUBAwiYU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nathan',
        lastName: 'Wilson',
        emails: ['nwilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHmNaEbcenWnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697632106624?e=2147483647&v=beta&t=tFj8azbCE5Fu21RKHSK79JEVr809c7hVsZb7CseYAv0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christopher',
        lastName: 'Potts',
        emails: ['cpotts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH5-7YnE3Czew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518866692083?e=2147483647&v=beta&t=k3jR7YipgfNKd0rlk3qE8-My9k58b4Tt6Q4NUOXos3s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dierck: {
    name: 'DIERCK GROUP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wolfgang Ehrk – DIERCK GROUP |',
        lastName: 'LinkedIn',
        emails: ['wlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHAFrlbT-bWmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639073684553?e=2147483647&v=beta&t=JwSbIwJVFf0ujFRLXy8sJDE-347028t8sqRRVJaC9lM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Amy Allen',
        lastName: '(Diercks)',
        emails: ['a(diercks)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFDh2Kq1tPNyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517724934345?e=2147483647&v=beta&t=NdHUr7090y2t443IDf0Qfg2fjkqjWCmFQxa0XfwLdUs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Dierk',
        emails: ['ddierk@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Niels Hansen – Technical Lead Voice – DIERCK GROUP |',
        lastName: 'LinkedIn',
        emails: ['nlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHuVWTQn50iUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705566600230?e=2147483647&v=beta&t=ttpGuAWu-VLd0KKd1cA7TXHSdxp8Kf7lzs4kWdS_2Y0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dierk',
        lastName: 'Hohman',
        emails: ['dhohman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bigbluebubble: {
    name: 'Big Blue Bubble',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dylan',
        lastName: 'C.',
        emails: ['dc.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFoEtFyB0kBeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603830021954?e=2147483647&v=beta&t=lSDUwxDe-ilTBcMBbFsauTbq7kn1Ntn3rd_0WqdcNV0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Fardell',
        emails: ['dfardell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGbRH3qGi0_6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615040450441?e=2147483647&v=beta&t=zwl07Gs3LD5IMSNMg-5pN_n4gr00gSvvyzdiGQioW8I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew J.',
        lastName: 'Stewart',
        emails: ['mstewart@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Claudette',
        lastName: 'Critchley',
        emails: ['ccritchley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFH5qMKwDRdwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598991872541?e=2147483647&v=beta&t=W-0Vsojp4Up0kuW8VnuRGPEgXmn6ws1rT8ZqaQGuxJE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jake',
        lastName: 'Macher',
        emails: ['jmacher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFUf4Qd8Jj_QA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598708109802?e=2147483647&v=beta&t=vku8dYWHKJzO_CwLzRw2DrClScs_yRIosdNAOP55bQU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  iktex: {
    name: 'IKTEX LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Elxan',
        lastName: 'İsmayılov',
        emails: ['ei̇smayılov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG37Xd7HTyqZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575455885480?e=2147483647&v=beta&t=Vkpt6h3iaRR8pJQ6QdUXDJsNEy9p5WowkSZ9LxJBO2g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Yuri',
        lastName: 'Cuellar',
        emails: ['ycuellar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Laura',
        lastName: 'Withers',
        emails: ['lwithers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH-eXZ51MUDZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517454877040?e=2147483647&v=beta&t=R-RYJNYfNAjDzQH9ZmR80Q8cvQHSKnY7_sECMtJF90A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dominic',
        lastName: 'Barnes',
        emails: ['dbarnes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFdoxGpTTxvSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586953532510?e=2147483647&v=beta&t=JKSDd7wzE2ubT_-LpzlgfAjwVIGIHTeOc-efPLui_O4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ebru',
        lastName: 'Gultekin',
        emails: ['egultekin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGRNCV-mjSZSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516831321674?e=2147483647&v=beta&t=EQcxhza-4bkBYHSgtPgdoCIlB8LNAM3Jfdm4jkHZpYs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  copperriverss: {
    name: 'Copper River Shared Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Mclaughlin',
        emails: ['kmclaughlin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHFJB9a3WEi1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719434398976?e=2147483647&v=beta&t=5z95ic0_sMLSjxBkcd6O37S4DpVHa4pu6b83eM8PoW4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jacqueline',
        lastName: 'Combs',
        emails: ['jcombs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEiuWY4xNxFjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559754319369?e=2147483647&v=beta&t=e8Stf8u4JTngSlJQOuKh1q4JcspHTrYGaeqGI6Uxf-c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Janice',
        lastName: 'Young',
        emails: ['jyoung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH9hk7n1AidSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584998155354?e=2147483647&v=beta&t=Pl8id5QWfi3QB-O33JlVmuRuWbZlv7akxP4hUkyRdPw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Heather',
        lastName: 'Hanks',
        emails: ['hhanks@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Trina',
        lastName: 'Colie',
        emails: ['tcolie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEsr_Z0PcxGNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672878024878?e=2147483647&v=beta&t=py04bxg6ctfTMibZt1T-9slUkAISzYtIQUd6iNnFOao',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kepro: {
    name: 'eQHealth Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pravin',
        lastName: 'Bhosale',
        emails: ['pbhosale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFdpCQQv10LgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517749366447?e=2147483647&v=beta&t=7oDkyxwA77PjtfwLHQHffbNy3AADwXzxBQnTY2EMAJA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Glen',
        lastName: 'Golemi',
        emails: ['ggolemi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ila',
        lastName: 'Sarkar',
        emails: ['isarkar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cynthia A.',
        lastName: 'Yannias',
        emails: ['cyannias@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGF-hkC-VBOwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517558916035?e=2147483647&v=beta&t=JvPib1onHgcmV0uNfKIxpmW64RmHJGCzk_Z7TM5ovs8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christine',
        lastName: 'Gatlin',
        emails: ['cgatlin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEx7HAGdKiIfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551100432926?e=2147483647&v=beta&t=KkhMeOgpGl0hlahReht5bc5_uSE8_vuL8oXs1QgjTCE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  agentvi: {
    name: 'Agent Video Intelligence',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Leon',
        lastName: 'Yalin',
        emails: ['lyalin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEZl1ZmrfjjAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699367155910?e=2147483647&v=beta&t=xHjZJhN8kc7LKTSbqb72Myukjf2pm5iSmtsSSwi1ZeM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ariel N.',
        lastName: 'Frischoff',
        emails: ['afrischoff@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anat',
        lastName: 'Pollack',
        emails: ['apollack@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Greg',
        lastName: 'Skarvelis',
        emails: ['gskarvelis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ilanit Blumin Keren אילנית בלומין',
        lastName: 'קרן',
        emails: ['iקרן@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG4dKIqp2HXLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559503438379?e=2147483647&v=beta&t=DcMfUowlKxi4H_5uNA30682aoQ3sULTXSa9EocOCSK0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  orkla: {
    name: 'Orkla IT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Josef Šuber – CFO and Head of M&A Orkla Foods International',
        lastName: '...',
        emails: ['j...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEfyTGIoHo5KQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517756750681?e=2147483647&v=beta&t=AkELFSyzSuQ9mZlY87i9Pw24yoKL-uI3xHBYjLFIpZ8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Siddharth',
        lastName: 'Borkar',
        emails: ['sborkar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFUiUaNz2xXfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623517444743?e=2147483647&v=beta&t=x8mKf6KOFhuHIW-3zKysfdh-whDlMvRGNCYTWXLC2Io',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Henrik',
        lastName: 'Zander',
        emails: ['hzander@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG4zy1yaREmyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597863097229?e=2147483647&v=beta&t=Km1F2B6cE3hqEPDJoeOxKA1BW2irilV4RfukD_UodLs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ashvin',
        lastName: 'Subramanyam',
        emails: ['asubramanyam@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ankita',
        lastName: 'Sharma',
        emails: ['asharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEElIpGmnierQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683597581869?e=2147483647&v=beta&t=j7MneS7nCqe-dawo73GktVw-SKXXDRQOM_qeaCslu0I',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  safetonet: {
    name: 'SafeToNet',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Richard',
        lastName: 'Pursey',
        emails: ['rpursey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ted',
        lastName: 'Hailey',
        emails: ['thailey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGzykz2ylhZiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517590405678?e=2147483647&v=beta&t=Dfw_1IAteCHqN9WcNojAXT8a7V3862cbGZcwRNkeHv8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sharon Pursey',
        lastName: 'OBE',
        emails: ['sobe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFsckYUe9nSfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727877884927?e=2147483647&v=beta&t=z4CcRM8rpti1cu0WpgSQUA4sAUFr-v_wwI5ra6C_wOE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ian',
        lastName: 'Brown',
        emails: ['ibrown@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ruth',
        lastName: 'Lutterloch',
        emails: ['rlutterloch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE-mwWctnesxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702391542189?e=2147483647&v=beta&t=IOQQmiFX1wISb_J1jpbxW7VJn7kP_YB01o2v2EuZM-o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  viatechpub: {
    name: 'ViaTech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Heather',
        lastName: 'Ashley',
        emails: ['hashley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG3ygFC5ILjGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516623746106?e=2147483647&v=beta&t=Db1KSmMYmficxsfBKiSZH_ZHa_twf5M-m6pPkPbWfgU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kirk',
        lastName: 'P.',
        emails: ['kp.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tina',
        lastName: 'Seabourne',
        emails: ['tseabourne@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sarah',
        lastName: 'Tippett',
        emails: ['stippett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEgcIAVH0gcwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517465892873?e=2147483647&v=beta&t=Hah740Zf8tukLkpJM-Z53o8DKS_oIPOkJxh9v_jjxfM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Julien',
        lastName: 'Carney',
        emails: ['jcarney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  themindhubcompany: {
    name: 'The Mind Hub Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Federico Aranega',
        lastName: 'Juesas',
        emails: ['fjuesas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEuOk4k80ib1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701100636291?e=2147483647&v=beta&t=rVrTIJnw0V6-U7KDgfyxgzVFMHqCSzvGJi3sUEaO6aM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Berta',
        lastName: 'Mateu',
        emails: ['bmateu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFbHurO5nlMmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604306220699?e=2147483647&v=beta&t=0eijHXDEA5hOluV6JOeoZhuYvNMI4HXzwmV-JSsrutY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Francesca',
        lastName: 'Fortini',
        emails: ['ffortini@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tricia',
        lastName: 'Wilkie',
        emails: ['twilkie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFJChiiBMgszw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553811332818?e=2147483647&v=beta&t=YWI8OQcdvZnxyxbZLMJDNuQRPEPu73CthkyxrkM_7MA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Giuseppe Di',
        lastName: 'Carlo',
        emails: ['gcarlo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFimh9vk4PXAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522682778790?e=2147483647&v=beta&t=TkZvCOk6FCm-kz-whWpekag3r3N2FlHFzvq_-N80xCE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  symplast: {
    name: 'Symplast EHR',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Facundo',
        lastName: 'Formica',
        emails: ['fformica@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHuFq3NWrPVIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596633187456?e=2147483647&v=beta&t=fmnEUBpBV0xjNQc8Aav69C3UGXY32X9dICMK9RmWBA0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michelle',
        lastName: 'Ruiz',
        emails: ['mruiz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG3tTvMQN-QKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685534582328?e=2147483647&v=beta&t=c2wKe4Jq3_0_nbagdTNqy67eNox1-pO8w3NihzIrYww',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'H',
        emails: ['mh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE5RfCJvfYZ7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724762380365?e=2147483647&v=beta&t=HoAK2gWv4Y9IZoCiDAqZZBZ5aa8qE5L5Wveord5GnrE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Margarita Santiler',
        lastName: 'M.S.',
        emails: ['mm.s.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Miguel',
        lastName: 'Barcenas',
        emails: ['mbarcenas@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  imsdirect: {
    name: 'IMS, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Derek',
        lastName: 'Power',
        emails: ['dpower@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Francis',
        lastName: 'Coudriet',
        emails: ['fcoudriet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHCGjlevWIGTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720574909211?e=2147483647&v=beta&t=zC3u1NOgvuwdSn_diW63DIh5yH6rSFJE0ctgA_ET424',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Warren',
        lastName: 'Vanderpool',
        emails: ['wvanderpool@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'W. Lee',
        lastName: 'Vanderpool,Jr',
        emails: ['wvanderpool,jr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFKsFxz6PpRbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694007770538?e=2147483647&v=beta&t=T1AHGUXlUxIV2qnv-oPM_W2UhArX3NTRehTHUykmMno',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steve',
        lastName: 'Schiano',
        emails: ['sschiano@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  conceptplus: {
    name: 'Concept Plus, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Theron',
        lastName: 'Johnson',
        emails: ['tjohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joshua Temkin',
        lastName: 'PMP',
        emails: ['jpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHkjwLaeqhusg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705886245173?e=2147483647&v=beta&t=aNHtlyp55CmVNlkr2luxgXnlFiqTZNnpA5Yya_9P06Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Greg',
        lastName: 'Turner',
        emails: ['gturner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rick Hill,',
        lastName: 'PMP',
        emails: ['rpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG2FNTywWcCyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651788851563?e=2147483647&v=beta&t=f1BXsbFHDrx7MTz25JUgn3ZlmcsIc86J-38s4pdgZg0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mike',
        lastName: 'Quinnelly',
        emails: ['mquinnelly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGKigkASSb-2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723052243401?e=2147483647&v=beta&t=JPo4IuIRREVKxKGOg3dYqkhiJckwg1DNd-Kqyq2_Yw8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  akytechconsulting: {
    name: 'Akytech Consulting LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Meenakshi',
        lastName: 'Arora',
        emails: ['marora@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Devendra',
        lastName: 'Arole',
        emails: ['darole@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFrSF_kHsUHag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713650951709?e=2147483647&v=beta&t=LvG0CAedx7i73j_Zak-lkygV9WRvVCKxtKGXvJ78enM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shedrick',
        lastName: 'Amin',
        emails: ['samin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sharon',
        lastName: 'Crawford',
        emails: ['scrawford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHQAD-39Tab6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682430795493?e=2147483647&v=beta&t=eueta9PFvMI80GKS1DdSxh54xuJ0pknNQb-6AyMzgSg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'RICK',
        lastName: 'RANA',
        emails: ['rrana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFwgVj3O0OcgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727901008504?e=2147483647&v=beta&t=qUjskQKl3uD3FImyy_LAyiyn-8v6kEL4ITbu_cqqf1o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dealerservicesnetwork: {
    name: 'Dealer Services Network',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joseph',
        lastName: 'Palumbo',
        emails: ['jpalumbo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Strochak',
        emails: ['jstrochak@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brooke',
        lastName: 'Barnett',
        emails: ['bbarnett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEhYhcLbS1UyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707080571187?e=2147483647&v=beta&t=Dv9o5IoVXpTKXf5Yo5q3dN-6WGoq7mRLXzVsboeupZI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stacy',
        lastName: "O'Neal",
        emails: ["so'neal@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG5Vlgp-il5LA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716008421662?e=2147483647&v=beta&t=LmzD1nxsPNveRdLuAazE2xDpWYSF8d2_rKRNVkfbzmE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tyler Lee,',
        lastName: 'MBA',
        emails: ['tmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  intergraph: {
    name: 'Atheeb Intergraph Saudi Company (AISC)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'SA Halac Iron Works,',
        lastName: 'Inc.',
        emails: ['sinc.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'The Van',
        lastName: 'NGUYEN',
        emails: ['tnguyen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHtwqRY3MHo8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696950243812?e=2147483647&v=beta&t=hu_ERSFZXY2kN54nFuPqvLqGqRJ-RfiBBwJgmw7yzbU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yousef',
        lastName: 'Almutairi',
        emails: ['yalmutairi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFvMKcVa68FQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708980582674?e=2147483647&v=beta&t=ytAl3lpAqzUWnE3o3Z4JYE-U0cwBVIafTyrt4huLRR0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Roger Aying (Radge)',
        lastName: 'Bantulan',
        emails: ['rbantulan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ruel',
        lastName: 'Alejandro',
        emails: ['ralejandro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHo6GFHooclFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661012622001?e=2147483647&v=beta&t=dN0uO5nYXtZXnn-I7vMvw5VL_TosmasG0HiLERSXeXA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mobiusIt: {
    name: 'Mobius Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tyler',
        lastName: 'Moebius',
        emails: ['tmoebius@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Advanced',
        lastName: 'Eco',
        emails: ['aeco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHtuleZw6KUfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517703167410?e=2147483647&v=beta&t=Q28z9cUj_QNBoeQk6mNK_4X-rHr6-zU62wq6-YVLaik',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tom',
        lastName: 'Fletcher',
        emails: ['tfletcher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHZ3fxUXCCs5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680971388790?e=2147483647&v=beta&t=qnCMJuuFmAuKfiZLX15HkQ6rk-IjurUD9bPSpvPvUo8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gavin',
        lastName: 'Johnson',
        emails: ['gjohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Neil',
        lastName: 'Peck',
        emails: ['npeck@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kurmiSoftware: {
    name: 'Kurmi Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Micah',
        lastName: 'Singer',
        emails: ['msinger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH5TyfyopG6ng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724938192868?e=2147483647&v=beta&t=PCaXStAzdT7aT-cAcvNNib3rK0pymcIT0XddW7rqq7o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marc',
        lastName: 'Haimsohn',
        emails: ['mhaimsohn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFLKm30kWvK8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516226998841?e=2147483647&v=beta&t=Ick44voOdJ_6JZvTeMYu52gVr0q2zXkmcvaC3fkXvnw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Antoine',
        lastName: 'Perrier',
        emails: ['aperrier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH6FV1FF_avug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516791312315?e=2147483647&v=beta&t=8q35B9miy-E7yPYwSVv2cKjkIvCOLK1bZ6CEq_pLqks',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jose',
        lastName: 'Burgos',
        emails: ['jburgos@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Garrett',
        lastName: 'Walsh',
        emails: ['gwalsh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGxjpUZHnGmOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516553821280?e=2147483647&v=beta&t=-0txIXXhTU6FZtD2ZIWS7pv9W1BLVSH2hJGuNcMiP1s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sysgo: {
    name: 'SYSGO - Embedding Innovations',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vladislav Boltinov –',
        lastName: 'SYSGO',
        emails: ['vsysgo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHd1_maiDntmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588862595294?e=2147483647&v=beta&t=Mz5VwVXa3D0-31j7przSem_9wtg-DN0NZwRNA6OFvEU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joe',
        lastName: 'Richmond',
        emails: ['jrichmond@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFeYh0J2B3eGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689172051635?e=2147483647&v=beta&t=NCeLB3cyzxJB_1KK4Sz7GjkD_NwZ3gO4W9i5-9WUnLU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Enkhtuvshin Janchivnyambuu – Research Project Manager / Project',
        lastName: '...',
        emails: ['e...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jose',
        lastName: 'A.',
        emails: ['ja.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH6TMbl1U7Lzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605782833771?e=2147483647&v=beta&t=pJn6N2RybgFFMzvyMg57UJse5oZIniHAubHDapZHPQw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abdessalem',
        lastName: 'Mami',
        emails: ['amami@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF24yTrzsKJVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726755640222?e=2147483647&v=beta&t=mBwuayOUh10dQJu-YWYlV1IkppOSaRe7n91O5bnpIPQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  oxosolutions: {
    name: 'OXO IT SOLUTIONS PRIVATE LIMITED',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'SGS',
        lastName: 'Sandhu',
        emails: ['ssandhu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF9U_yKbnLCIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516559877795?e=2147483647&v=beta&t=f8qWUieorTwqHr2BCiV6MnJB7yRlyX8-5c09xBGsswk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sarmail',
        lastName: 'Singh',
        emails: ['ssingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFoFjQ9MC9vpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626920308667?e=2147483647&v=beta&t=D2rOTNmCBvczv90K5LUeECPcZZ8kswvx0Z_NtCmDMhM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'harpreet',
        lastName: 'dadial',
        emails: ['hdadial@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gurbhej',
        lastName: 'singh',
        emails: ['gsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFrfqP3UK3V5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600771194031?e=2147483647&v=beta&t=eRZh3JHHX5kWFOP0afl8dbhz35lqr8w1yZuQSVGITuk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Harpreet',
        lastName: 'Kaur',
        emails: ['hkaur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH30p69c3jFqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669802750237?e=2147483647&v=beta&t=tb0EzF8gyxZmg9rBk3yrbGqDfokZn9UtHONYFUM1Jcc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  namossolutions: {
    name: 'Namos Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Mason',
        emails: ['cmason@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHRKZ2CdW761w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654719093524?e=2147483647&v=beta&t=Fl63kMUpO1Ne2AFqYHD7jsE1By66RZhV7gnPQKUNe0Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Richard',
        lastName: 'Clayton',
        emails: ['rclayton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEqvXECgm6IaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668796614641?e=2147483647&v=beta&t=jM99C-oqL1ek_z-mVdcsCAGY5IMbLGB7u4fGoIFWjsA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Richard',
        lastName: 'Eltham',
        emails: ['reltham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHoZrdQ2zsBKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628583529369?e=2147483647&v=beta&t=1y-zRArEnKQYPdf-EBzWHF64br2a_rYQ2AYwNKUrjCQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sam',
        lastName: 'Haigh',
        emails: ['shaigh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGuAHEe-uTb_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629707616675?e=2147483647&v=beta&t=QU3DxnxhojIfPD-gSvkbu1Xgv-SK-HD66LIGxFJLc1c',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Maisy',
        lastName: 'Ward',
        emails: ['mward@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  intrustIt: {
    name: 'Intrust IT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ricky',
        lastName: 'Phipps',
        emails: ['rphipps@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHbSwhz8gw3Uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517754332170?e=2147483647&v=beta&t=9fYJPxZqCL-ubZerhbsWxbCBsXbzEhpdps9uLxrUAjQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jack',
        lastName: 'Davis',
        emails: ['jdavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH0yBwjeT9GYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617628961237?e=2147483647&v=beta&t=MpSq0W3pkfXE3C19mAoL1JF-nmULrAYpioHCUouWV8M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Josh',
        lastName: 'Needle',
        emails: ['jneedle@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tim',
        lastName: 'Rettig',
        emails: ['trettig@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFUKk0Z08eSrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602640255863?e=2147483647&v=beta&t=vvLBF7cA9PqI9xjhsfc3rB81nTeYSW-B5yVAeWFedHo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Guli',
        emails: ['mguli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH9L3NN8cGs9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681351343068?e=2147483647&v=beta&t=7cQBaU5DEHjh1IgGXht5pGEwhp3TWR2zFxdVs9AL57g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pubplus: {
    name: 'PubPlus (Acquired by ClearPier)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alex',
        lastName: 'Slavitski',
        emails: ['aslavitski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF0TzvMbnHDrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570001917737?e=2147483647&v=beta&t=xBB2oW44CsI6xvDzs0aWxVpXg3jolTwnANGJwgScTJM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gil',
        lastName: 'Bar',
        emails: ['gbar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF9Y8cf087cMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653390862029?e=2147483647&v=beta&t=4pQKytdGzPE4sU2R__mz2w-SoWPuG7ImDuTXp8DcUrg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ron',
        lastName: 'Assa',
        emails: ['rassa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHWDjd8IhF-ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663210098955?e=2147483647&v=beta&t=Gq-qTSWeA2f9NcgNpCARAAFwcqNcBUFKN70H8QLiZCY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michele',
        lastName: 'Shaked',
        emails: ['mshaked@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFRVBdkrdDD3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667476555107?e=2147483647&v=beta&t=TaMYOqpw7m5TGKyyw_klueyrtdaOUeLf5Rtu08F1vYM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Omry',
        lastName: 'Aviry',
        emails: ['oaviry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEIbD00duMBYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655385737084?e=2147483647&v=beta&t=p0jkgOXKPn2nqOGDBYzo7UGMJIANDkKdPpMd_BR5wB4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  edna: {
    name: 'edna',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Edna',
        lastName: 'Conway',
        emails: ['econway@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEZ35MprIki8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659359761621?e=2147483647&v=beta&t=B4vmtNg3_xsjkJ7XCq_6CQhPOlc6yh9idn5x1jbsU6w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Edna',
        lastName: 'Lima',
        emails: ['elima@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHaJxlNYsjfhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658689917377?e=2147483647&v=beta&t=XcghwueHYbpEvO0XEOLBJ2RSoO7AIM-9VDWYgapptEw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Edna',
        lastName: 'Perry',
        emails: ['eperry@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Edna',
        lastName: 'Diez',
        emails: ['ediez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Edna',
        lastName: 'Karanja',
        emails: ['ekaranja@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  medone: {
    name: 'MedOne',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Diego',
        lastName: 'Medone',
        emails: ['dmedone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGoGIfJWF-5sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516310279499?e=2147483647&v=beta&t=5FlXDPE1dDsQiVxaynFjF6P9QL99tBJ9zlO1Bb9EFjE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Wes',
        lastName: 'Hartig',
        emails: ['whartig@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF8Z1jbx8GF7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594953687974?e=2147483647&v=beta&t=pnHxs6SBqDil2SJgvQERcMnfegzxRfLb3ZUG8NvOfog',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Antonio',
        lastName: 'Medone',
        emails: ['amedone@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nathan',
        lastName: 'Harold',
        emails: ['nharold@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFI-RiULAuPhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594954174754?e=2147483647&v=beta&t=MOXVQTZneQ1dGYBQYWCppYWupThj4_2T9dqOmAyTLcA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alan Medone',
        lastName: 'Sparrow',
        emails: ['asparrow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF1ITUvo5MGug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559707850649?e=2147483647&v=beta&t=dtd7vhrrDdWPNOU7j0GMmqG9PUtHmxVpb8ewnhAsRMc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  frista: {
    name: 'FRISTA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  anattadesign: {
    name: 'Anatta',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nirav',
        lastName: 'Sheth',
        emails: ['nsheth@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Laura',
        lastName: 'Bousman',
        emails: ['lbousman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG4H6WVPtKBPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726924906509?e=2147483647&v=beta&t=_Fx84oeDjU06Xwp089Yg03uDRzcB_f9qph2N4F6SQg4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paul',
        lastName: 'Baranowski',
        emails: ['pbaranowski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEZTS1j0moMtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624902361587?e=2147483647&v=beta&t=XQBLubTPHnYnj-OWNuH3VGriW78bxaqTDvER82PnxcI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jared',
        lastName: 'Hellman',
        emails: ['jhellman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF9YufLW133eA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691075300010?e=2147483647&v=beta&t=aB6Sdx_0LqjbrHM86bUW7UEVHEga-3JRTOcyJMEiWIU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anatta',
        lastName: 'Tantiwongse',
        emails: ['atantiwongse@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  maplworld: {
    name: 'MAPL World',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Varinder Singh',
        lastName: 'Jawanda',
        emails: ['vjawanda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFtli2JmxWN0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718877154925?e=2147483647&v=beta&t=RoPFJ6b56hhP3T0TrMEi1LZBqfVMM-ODVBjLmSJQaGA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vasanti',
        lastName: 'Rao',
        emails: ['vrao@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Harleen',
        lastName: 'Jawanda',
        emails: ['hjawanda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEuxsmzZGaRCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676038830863?e=2147483647&v=beta&t=ThggYofx810gZ6ewdzcrUNNVStqafupm5vk17x1af0Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Saroj',
        lastName: 'Singh',
        emails: ['ssingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFZDawk5eKCgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725079352774?e=2147483647&v=beta&t=hG1BnnsRIa_CnHIGlIRlPLp3DXOmCrQUVrksWVmAc4A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hershey',
        lastName: 'Jawanda',
        emails: ['hjawanda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFunYiF6_pLHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581370652896?e=2147483647&v=beta&t=daA5ITlu30tqYTYA-cJl8sPW6iZcmVA-LBWRo24lhxI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  screencloud: {
    name: 'ScreenCloud',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'Needham',
        emails: ['mneedham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE0np2-V3ClSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516245559487?e=2147483647&v=beta&t=A9tmUrJD-rxmO2ugED6m5voIx8Cr4cvKqVRuNu_qtNQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'McDermott',
        emails: ['mmcdermott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGCf_c0fUI2uQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673627631393?e=2147483647&v=beta&t=TrZZV3tP7GWX7dpDvegrsgGjSUYGjuWoTnm4adsUle8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Turk',
        emails: ['mturk@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shabri',
        lastName: 'Lakhani',
        emails: ['slakhani@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Connor',
        lastName: 'Strapp',
        emails: ['cstrapp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGKwEGHtjHOnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689542353021?e=2147483647&v=beta&t=XMM4RUQyDQ6up9ZBNmdord1_IuQjUX7h5J7zFetToEY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  visiontek: {
    name: 'VisionTek Products, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Banez',
        emails: ['jbanez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEQYJQeBTMtbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517561216225?e=2147483647&v=beta&t=RH0jCFqfhW98rOkCh-IRYOkQHJhBArVzsxcEFnJ5UHA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nicholas',
        lastName: 'Brill',
        emails: ['nbrill@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Innes',
        emails: ['minnes@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christopher',
        lastName: 'Rosman',
        emails: ['crosman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alexander',
        lastName: 'Carel',
        emails: ['acarel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHssN1uZq6ysQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698376091100?e=2147483647&v=beta&t=K5LPjs3yXtYAgn0I50649bTc0Lyzv8mxn1Cu72eTX-U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  soulunileaders: {
    name: 'Sustainable Outreach and Universal Leadership (SOUL) Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Higgins',
        emails: ['chiggins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE9YkCEwCYhJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516632497797?e=2147483647&v=beta&t=i5MP2NP6W-jOgW9tfsPLUDsBdcynk3OFVHZJVboIj5k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Geraldine',
        lastName: 'Stokes',
        emails: ['gstokes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGJnjCG55pm2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603740312665?e=2147483647&v=beta&t=VI8R_XLMj5ejmJ1Pn4CPzYCs8l_B8Dc4PBtUXtzfnvY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Prof. Sasmita Samanta, FRSA,',
        lastName: 'FCSI',
        emails: ['pfcsi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGlsqw_NsqH7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661330123762?e=2147483647&v=beta&t=9cCmJOXrVoBy8l_aAXlXHeIoeO15FDX3iJ2yn7hnx4s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'SOUL',
        lastName: 'LTD',
        emails: ['sltd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Himansu',
        lastName: 'Tripathy',
        emails: ['htripathy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFRUIfH5Q2Spg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517689199123?e=2147483647&v=beta&t=Tizd7OaRScZq1zzeTe74diCAkUW6h_rKBkVUKh0DfOs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  infomc: {
    name: 'InfoMC, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff Durkin,',
        lastName: 'MBA',
        emails: ['jmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHiTs24rx8iFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572287274128?e=2147483647&v=beta&t=G6F01A7bcO_nXIsaD-uzasDCvZiZsWbAFCXOVUXpjM8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Angela',
        lastName: 'Madara',
        emails: ['amadara@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lauren',
        lastName: 'Barsa',
        emails: ['lbarsa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGIY36IoVJdUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689022020853?e=2147483647&v=beta&t=_10At8HmsMZZIeVdNydpl6L_0p4hL98SooHR98a8b18',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jason',
        lastName: 'Mobley',
        emails: ['jmobley@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Danielle',
        lastName: 'Mulvena',
        emails: ['dmulvena@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHZYI5_4GoIeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516882652465?e=2147483647&v=beta&t=d1Mb-YogSTOSqeQ7XcoJG7Yw6eUdvwHNspyrGdrrKiQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  biz4solutions: {
    name: 'Biz4Solutions Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Parija',
        lastName: 'Rangnekar',
        emails: ['prangnekar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFSsjVPjiETnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516768395445?e=2147483647&v=beta&t=TS7F8Zv5NDCN-Q-g63nlwUe-g412RNdfBSJKJ6vS_-0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pranoti',
        lastName: 'Ambegaonkar',
        emails: ['pambegaonkar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF8GnbhGI-gdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589527845978?e=2147483647&v=beta&t=T8vzqmMadYkMOPM5Py4CoAIkL35mySVqjPq7YWOlbio',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ashish',
        lastName: 'Rangnekar',
        emails: ['arangnekar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Minal',
        lastName: 'Joshi',
        emails: ['mjoshi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHmrQW_pHTbqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516858873785?e=2147483647&v=beta&t=wNv4BIer3gwPSkPjaOIHbz69bAzYIRUvj0JhUyDZQL8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jayant',
        lastName: 'Kaduskar',
        emails: ['jkaduskar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHKagtnaRkV9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516281791181?e=2147483647&v=beta&t=9uy_aE6xDGrlaCFCKflLbkljGZX4X4s9oJsFvcoMcKU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  futurmaster: {
    name: 'FuturMaster',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matthew',
        lastName: 'Hughes',
        emails: ['mhughes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF7A8xZ-A-5VQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698141200682?e=2147483647&v=beta&t=kWUMo0mY9_T_cAp9Ujy3sjZNhvGY3Yukz_xujlhHxIg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Yacine',
        lastName: 'Zeroual',
        emails: ['yzeroual@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHFYy6azSdJ5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1554897113631?e=2147483647&v=beta&t=4A0nTtoqp71bPNuJpBVNxhm4n0mQqf2AuH1Ocx9TxOw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bruno Vinicius',
        lastName: 'Gonçalves',
        emails: ['bgonçalves@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG3RSWxb4GVsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616702541866?e=2147483647&v=beta&t=OIHj_ej_I39KDfnQq97gr23-Hjoc2My6mP3kNGjVG_k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Julien',
        lastName: 'Chidiack',
        emails: ['jchidiack@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFgiSqaQhmJhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664545444486?e=2147483647&v=beta&t=hdEwqNJiEJ7N3imwOT7tviqtpQORmh16INQL0IhOmUI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sam',
        lastName: 'Whitehouse',
        emails: ['swhitehouse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFZH_enTDJgVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725438771677?e=2147483647&v=beta&t=xXEm1TCS88Z8R1hDLKsO2CA2yWx21hbScFMe2IU2FY4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  shivsoftexperts: {
    name: 'Shiv Software Experts',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Siva Kumar',
        lastName: 'Gattupalli',
        emails: ['sgattupalli@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shiv',
        lastName: 'Singh',
        emails: ['ssingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG-j-vkcRz4TA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729794804280?e=2147483647&v=beta&t=ENnqxHWTdn6IQoZGKAWnNKhtFIr4kF-sblrFVQGhjJ0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shiva',
        lastName: 'Thavamani',
        emails: ['sthavamani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHDJa6ApUuYgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1565404861620?e=2147483647&v=beta&t=ok4neMnYNDrDlZSkVrtoRIt8COPuuJ0rQovEAq56_34',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shiv',
        lastName: 'Shenoy',
        emails: ['sshenoy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHKUkFgY4GJAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691083931880?e=2147483647&v=beta&t=Se8HhLObzsjEzlWZhDyAiY63j3T1_twfm-ZCoa9Icoc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shiv Kumar',
        lastName: 'Somani',
        emails: ['ssomani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHZiP8MT0dphg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705817124204?e=2147483647&v=beta&t=2Kpr5h9VbpnPsCOcChXhvuCh4jsCJSb2SmNCDpu3Pjo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  notraffic: {
    name: 'NoTraffic',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tal',
        lastName: 'Kreisler',
        emails: ['tkreisler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kris Milster, PE,',
        lastName: 'PTOE',
        emails: ['kptoe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEUuKFo5U_5gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567082126786?e=2147483647&v=beta&t=IMLmC2DdXfv_VPIMb6Hr6zwmgWPC00s4Y67GS4mP8WY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tyler Houston, PE,',
        lastName: 'PTOE',
        emails: ['tptoe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFeShs31-umOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689954712067?e=2147483647&v=beta&t=-ckgWTMPGMfvwXFSClXExR8I-Ze_MFnS_hRfFwkbA7Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Blatchly',
        emails: ['cblatchly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGktCi9NUlzCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568041072710?e=2147483647&v=beta&t=byBK_S_GXbeTpkNWUMDDkCXhPIM7cTMtH1zmdjIG5qc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Fred',
        lastName: 'Kreutzfeldt',
        emails: ['fkreutzfeldt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cybeats: {
    name: 'Cybeats',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bob',
        lastName: 'Lyle',
        emails: ['blyle@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dmitry',
        lastName: 'R.',
        emails: ['dr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFTzJxIOvWSDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687906478259?e=2147483647&v=beta&t=XGLAgPFMbrv_AvPTzqRgVo3ngLcGpB-Zh5dcOaksyYE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Blask',
        emails: ['cblask@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG7ePvvnn-M2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516237527497?e=2147483647&v=beta&t=DlIIYVFcolxcIwpnHlCeiXtTaAQ7HDtTv2m6XffB3uw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Justin',
        lastName: 'Leger',
        emails: ['jleger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEllw5DC2sCdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691691116497?e=2147483647&v=beta&t=GNUwAbpjOOlphi75cf-UI9zUamQ6Ei4V-nkdRWwZqpE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yoav',
        lastName: 'Raiter',
        emails: ['yraiter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHasDLRF1ZTtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638327576637?e=2147483647&v=beta&t=O3TWDZLFUvw4p5IShnhWcBwaskubGC2ku-YqpJjh8fc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vinciEnergies: {
    name: 'VINCI Energies Belgium',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dominique',
        lastName: 'Tessaro',
        emails: ['dtessaro@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Benoît',
        lastName: 'Lecinq',
        emails: ['blecinq@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEJnUlHb7yaSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681736125850?e=2147483647&v=beta&t=GvPL0mENytPZB6buBJ9jqzkG1AfDaZ3Yu0614hwF7kk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christine',
        lastName: 'Lutian',
        emails: ['clutian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF7Zkp4f3dT-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729890380666?e=2147483647&v=beta&t=wBKYQ7sxT_vdpKLXzPW4hHtQp0bDn7naA3XSfZfbu2U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christophe',
        lastName: 'Gaudefroy',
        emails: ['cgaudefroy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFIci2yuJy-Dw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626369598895?e=2147483647&v=beta&t=C2dNibpe44RF1VRxNJkTf06T2FTgYBFxonRqHXWjmto',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Véronique Matignon',
        lastName: 'Ménard',
        emails: ['vménard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEw9OFBieigoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613039423440?e=2147483647&v=beta&t=8yTTtv0NvOhVHgjgeDvqOrKEWnYyYieSdho19KaNZO0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xtrastaff: {
    name: 'XtraStaff',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Clif',
        lastName: 'Baker',
        emails: ['cbaker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nicole',
        lastName: 'Lombardi',
        emails: ['nlombardi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHw9319O8EtTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572021428650?e=2147483647&v=beta&t=ugv8aydFesu1-VYuMObfpQPjj3RD9ACgpOPylJzNmGs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mel',
        lastName: 'Woods',
        emails: ['mwoods@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFvN9VV_R6kPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713578978568?e=2147483647&v=beta&t=HlWODmnLbhqxZH7_xGtzc6vHx2WWgd_BaxfELjlBFp0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Angelo',
        lastName: 'Austin',
        emails: ['aaustin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEwrBKxfROUUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713337737272?e=2147483647&v=beta&t=-vxuY5pwsFrX1mHGMCZPWE5J0KsxtQ2pJmiTS92XEp8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Luke',
        lastName: 'Westaway',
        emails: ['lwestaway@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFIQpNzAoTreg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516626303278?e=2147483647&v=beta&t=IEg_QtjmTGmofW5KAQR3bz9waXUuUs-7e-_ziIRXTmQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aserit: {
    name: 'ASER IT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aser',
        lastName: 'Samak',
        emails: ['asamak@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aser Bruno',
        lastName: 'KENMOE',
        emails: ['akenmoe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF-RQrBwDYBwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690235335668?e=2147483647&v=beta&t=T3YnMRfXSlfGWV4xt2NAbIRy9dGm_1LQcmRgKVVT97k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'gakire',
        lastName: 'alain',
        emails: ['galain@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aser',
        lastName: 'LOUISSAINT',
        emails: ['alouissaint@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEl55Aw4K4ldw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643658409793?e=2147483647&v=beta&t=_7oSRJxFwvkTM9oW-OtYPte3I_urVIDBb0-eYMUNu94',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aser',
        lastName: 'Elshawi',
        emails: ['aelshawi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGNbNlBIXM82w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635530911673?e=2147483647&v=beta&t=JoDnY_Z4-_7YPujSVv73P3sSFWQBwDNVGKeVGAhD4ic',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dlrdmv: {
    name: 'DLRdmv',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Ward',
        emails: ['jward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGdGY2iPeXY8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1532458369665?e=2147483647&v=beta&t=z7bU8w7NkA6QfmQWP0ziHatb-HaSRGNk7w0P7vvhqzk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'C.',
        emails: ['jc.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Johnson',
        lastName: 'Berry',
        emails: ['jberry@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Donaldson',
        emails: ['ddonaldson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFgnqCF9o9uTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517751202741?e=2147483647&v=beta&t=Y_C7c6YkuYCP9BbsIuiX24IOUNPOnub1WChkglYBg_Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Justin',
        lastName: 'Davis',
        emails: ['jdavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGb-7f0lvNPFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703184602207?e=2147483647&v=beta&t=EKOoI8JZ3qUG8gnp0RC4ojmWtw7QEchidSlGkH90hIc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gridly: {
    name: 'Gridly',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jake',
        lastName: 'Gridley',
        emails: ['jgridley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE13JKq9w4LtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715823450703?e=2147483647&v=beta&t=Rp_jTCQyiUOANlsRUVEkO05MPH7yVp7qRHO6ueAhHLk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joseph',
        lastName: 'Gridley',
        emails: ['jgridley@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'john',
        lastName: 'gridly',
        emails: ['jgridly@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Audrey',
        lastName: 'Hoage',
        emails: ['ahoage@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Souto',
        emails: ['msouto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHsWwls18O2BQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516352476146?e=2147483647&v=beta&t=Skiq1QDx_bjaUku6HjCUjIOt8nektB6gB-OXD6fWRgM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dankosys: {
    name: 'Dankosys Technologies - SAP S4 HANA I SAP AMS I IT Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ravindra Varma',
        lastName: 'Dandu',
        emails: ['rdandu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shashank',
        lastName: 'Kongara',
        emails: ['skongara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGmnAuTMlH0fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662145657476?e=2147483647&v=beta&t=Sk8Mfc36U0EtfNi2QR7XFiMacL46D-3tvvT5m68CxU4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Harshitha',
        lastName: 'Turaga',
        emails: ['hturaga@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bharadwaj Kolluru,',
        lastName: 'PMP',
        emails: ['bpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEx0XdUZjoiaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516607629602?e=2147483647&v=beta&t=MW9f-PZ7TOjDPtWFIUgOrp3vNPDRiEew4IQO38X4be4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sai Ram',
        lastName: 'Mutyala',
        emails: ['smutyala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGAgOtc5rZc-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517516860750?e=2147483647&v=beta&t=GSS8XaKFEYCpwr4SyAIPNs4DHU5waeK_rZCGsKKqcqo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  evonence: {
    name: 'Evonence',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vijay',
        lastName: 'Shah',
        emails: ['vshah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEMs1GEfMZmqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719849970716?e=2147483647&v=beta&t=sJoQcflITioL_2SXciLJpfI6Z8D2g2LTUUqXugDwmvg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Derek',
        lastName: 'Gaudio',
        emails: ['dgaudio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHQeWJHSPqt0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620938345079?e=2147483647&v=beta&t=jXRouJ-FmGTnH8q2Ui3Z0dPP7hgVyMYx-rQ8FKOwqCk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John (Jack)',
        lastName: 'Harris',
        emails: ['jharris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF1rPOpOlHysQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564531222794?e=2147483647&v=beta&t=5DKQgIsl10Qfeez7Qh2myK-t0gKH-dyBAx06akqzq9s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanket',
        lastName: 'Dantkale',
        emails: ['sdantkale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGFlyFGXksRxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624816711665?e=2147483647&v=beta&t=lOFMDqH6Sy6tEy7pxOGALIOXAp__U8XucdEN124hexc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ankit',
        lastName: 'Shah',
        emails: ['ashah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFNoY_l8ldlYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707193635131?e=2147483647&v=beta&t=V8wHfIhcXLoxt-5uImkmC82Lwtk8bgIlASO4Fhi-ZcQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tornet: {
    name: 'Tornet Co.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Talar',
        lastName: 'Bahaddeen',
        emails: ['tbahaddeen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lara R.',
        lastName: 'Saadallah',
        emails: ['lsaadallah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF0RFeXtALgCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671116669214?e=2147483647&v=beta&t=ji3EAlgI1QpAGRmBLPQL-RomkoWAyuTgBFH29V8V688',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sarah',
        lastName: 'Shakir',
        emails: ['sshakir@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mohammad Amin',
        lastName: 'Aljaff',
        emails: ['maljaff@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Savio',
        lastName: 'Sami',
        emails: ['ssami@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE4fgXOnRNppQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517403001968?e=2147483647&v=beta&t=7AU7oYIwbdmxKPzCWGKJgE22yiLC5XDf81oZ5384WNs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  quarkslab: {
    name: 'Quarkslab',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Fred',
        lastName: 'Raynal',
        emails: ['fraynal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marion',
        lastName: 'Videau',
        emails: ['mvideau@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Philippe',
        lastName: 'Teuwen',
        emails: ['pteuwen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG-amG637nMxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652478499544?e=2147483647&v=beta&t=IeUSAtmZAgPZHYb_q1ChCUAEHxDgtiuXdreyj-0pcps',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexandra',
        lastName: 'Reynaert',
        emails: ['areynaert@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Prabhleen',
        lastName: 'Bhatia',
        emails: ['pbhatia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGevLRbwKLeSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718271028891?e=2147483647&v=beta&t=z9K-Zt7jxG0cvHwrEh0nczMAuCf2ulk-iAMY7vYl8pE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gloat: {
    name: 'Gloat',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ruslan',
        lastName: 'Tovbulatov',
        emails: ['rtovbulatov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEM7eJL4h05JA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712165454879?e=2147483647&v=beta&t=Hs393HO3246IEDX3_m7S1reYIV5MkMr0gTd6U4g6SWY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ben',
        lastName: 'Reuveni',
        emails: ['breuveni@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHHjQhWMivPZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712312402291?e=2147483647&v=beta&t=3sYlQzZi1NEpZ9L4NpEF9tl05jMGTuAGX931P7mPdXU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brian',
        lastName: 'McCarthy',
        emails: ['bmccarthy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGQqi2f7NnsoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712238404207?e=2147483647&v=beta&t=Oubbp2HnUT94h10nuRgwnu2W4cnmjh-WCWd2tqqcFWs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shlomo',
        lastName: 'Weiss',
        emails: ['sweiss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEqxI3aKp7_QA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712476857236?e=2147483647&v=beta&t=8duGBoyyAj7lh25JsCKttOgrxAU5mIOGHsub379qAhM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'Hershey',
        emails: ['bhershey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  workforce: {
    name: 'Workforce.com',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brandon',
        lastName: 'Crouch',
        emails: ['bcrouch@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Liz',
        lastName: 'Ryan',
        emails: ['lryan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG4-C-Dr5bF6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718275888662?e=2147483647&v=beta&t=oI1DNR04c4fGQ3WDMgPU-uuZwXgJx4zE6xjooLomsic',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kaylyn Williams,',
        lastName: 'MBA',
        emails: ['kmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGLBo0gmunvPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594228124980?e=2147483647&v=beta&t=Ki2qCw-Pu7x5LtJrlmgNoFwGnteJ6rZUUAPY9P8S4yI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alex',
        lastName: 'Riggs',
        emails: ['ariggs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFaziUQnzBpCw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517548931343?e=2147483647&v=beta&t=kwI_kmS8e9oAJRw5m8CLeGZ3ahcD8tLnqNmvvveXIIM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brett',
        lastName: 'Rosenberg',
        emails: ['brosenberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  loft3di: {
    name: 'Loft3Di',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gordon',
        lastName: 'LaPlante',
        emails: ['glaplante@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEOx5lgO1Lp2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694913200199?e=2147483647&v=beta&t=biFx5BQdu55sYClVepJZBPQr7BJkb20vYafF6qehIJY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dave',
        lastName: 'Little',
        emails: ['dlittle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFZVEtZ6Vgd7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579671238436?e=2147483647&v=beta&t=NwwihcdD_LV5ArlQWDKxG9-tAfNa-6O95vbi8u4SR8c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'stephane',
        lastName: 'fauve',
        emails: ['sfauve@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'stephane',
        lastName: 'fauve',
        emails: ['sfauve@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sven',
        lastName: 'Rand',
        emails: ['srand@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE9dGaw5gFIOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517251594557?e=2147483647&v=beta&t=tOHUqAkaJcPcXc4vfc0poCU2w40H4YauJiVFLZnJoPU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  evnedev: {
    name: 'EVNE Developers',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andriі',
        lastName: 'Scherban',
        emails: ['ascherban@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFld7WLde7GrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686832576583?e=2147483647&v=beta&t=nWzndvcIDOpx-McFSqzoBMmfvnt2wlQhilUcIOJgptw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Oleg',
        lastName: 'Shiryaev',
        emails: ['oshiryaev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEWhwwKwKtjmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689694463531?e=2147483647&v=beta&t=A7qAo4DxDYpqp_R7TxXCZq0mC7wdVlR1B9SHqGSnxgE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Iryna Ivanova – Head of Human Resources – EVNE Developers',
        lastName: '...',
        emails: ['i...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGapAEqlyMoVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694095069209?e=2147483647&v=beta&t=EkNn6iwKf404hZtScBlPGrX1KJcK6MznMLFnf3xAE6c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Iryna',
        lastName: 'Ivanova',
        emails: ['iivanova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHiK155rmMVxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664279131503?e=2147483647&v=beta&t=ydutBFv13kpOCQkWoto1tVF-NApMqpQYEiMvBGlUECM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName:
          'Victor Kyrylenko – IT Solutions Expert – EVNE Developers, LLC',
        lastName: '...',
        emails: ['v...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFByJzfMDVigQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613896560684?e=2147483647&v=beta&t=3l-rrwoWW3Sk47W_ycdlD6b0OvQIv0XrMSZaFh18pqw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  spiritdsp: {
    name: 'SPIRIT DSP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Spirit',
        lastName: 'Novel',
        emails: ['snovel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEz8blCkKz5ZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729282587244?e=2147483647&v=beta&t=4e5ZElwOl7tvSixjdXwIAlwzZSMkb3rXT7oFyPoSzUA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ken',
        lastName: 'Maguire',
        emails: ['kmaguire@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bill Dolan (Spirit',
        lastName: 'Media)',
        emails: ['bmedia)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFvCM-0mHpSxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643615194299?e=2147483647&v=beta&t=GTf9KZNqziGEcSwtdOiKMblt5RSjkLjw53HNtPA8rng',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'K. Maria',
        lastName: 'Harrigan',
        emails: ['kharrigan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rob',
        lastName: 'Kaczmark',
        emails: ['rkaczmark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHnWsYaXosJog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695315650637?e=2147483647&v=beta&t=WNKppCHDfZRgunWKgneGRGwNF31aFYUoaYdOGeZogF0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  khodro45: {
    name: 'Khodro45.com',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ehsan',
        lastName: 'Borhani',
        emails: ['eborhani@example.com'],
        image:
          'https://media.licdn.com/dms/image/D4E03AQG-CnP2WfFDaA/profile-displayphoto-shrink_200_200/0/1690991567067?e=2147483647&v=beta&t=higZYmAZvtMnn0uyFQtFlEORhbtnOcSslJH8zERd554',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alireza',
        lastName: 'Alizadeh',
        emails: ['aalizadeh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEWFCtpJTKoAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658040529893?e=2147483647&v=beta&t=XTb0sBZ8mzoWll1Qdv18M0J0QDbWkJWtZhevvpA8LLA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kasra Hashemi',
        lastName: 'Shabestari',
        emails: ['kshabestari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG0PX8-noH3lw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725970771322?e=2147483647&v=beta&t=tlPw7Kltzk7MeiBnIxXEWxkPoW3yoN6W8fr8A9PNUQQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sadaf Boustan',
        lastName: 'doust',
        emails: ['sdoust@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGBb3wJY4D0uQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718226165688?e=2147483647&v=beta&t=cUfUJVwlCckG6fqpsAyzV7mumPL0WjPGXQP3Qy-6ya8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Negar',
        lastName: 'Hosseini',
        emails: ['nhosseini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHV4AzcckToEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718222995198?e=2147483647&v=beta&t=dZiDvxiy3tYy2xkS1tB5jWC2GQ60MNn6h8S4NINne4U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  solvencia: {
    name: 'Solvencia Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Radhika',
        lastName: 'Chintalapudi',
        emails: ['rchintalapudi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF_FVGRbDY-Cw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516621805255?e=2147483647&v=beta&t=RtxRosiQHhMa3Jyb6iWQvcdDxmVw7VACYMWiXACV7ek',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sampath',
        lastName: 'Chakka',
        emails: ['schakka@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGwo5qzBP26Gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516531803610?e=2147483647&v=beta&t=tWG-MRPz8rkNQDwZZ9coKxrpxXXOiOhI9VwbxRA95iU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mounika',
        lastName: 'Kunchanapalli',
        emails: ['mkunchanapalli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG_O8gYi6tdhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690807247148?e=2147483647&v=beta&t=P3UM8bpo9xug8IdYBHBX-kgIY6JIepyVHuHgS3UmwQ8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jagadeesh Kumar',
        lastName: 'Tangudu',
        emails: ['jtangudu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHmineuLg0tpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709916778170?e=2147483647&v=beta&t=2CY8_Kn_hICIiG6KtOMsYaGcqyvAhSgroRw7EspryA0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'SANDEEP KUMAR',
        lastName: 'GOPANABOINA',
        emails: ['sgopanaboina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHqdtqo3fcJZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596548266957?e=2147483647&v=beta&t=cioZePcJOsdjX5V05QQfq6LtwIK0RzUo7sWFEQRefDM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  softgile: {
    name: 'Softgile',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aleksandr',
        lastName: 'Rozovyk',
        emails: ['arozovyk@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Valentyna',
        lastName: 'Falko',
        emails: ['vfalko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEGxS2e0YzCgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655023411046?e=2147483647&v=beta&t=9Woueh2S9FHVDclsh1MvkepS9sFvgXHT0bMbba7A0Lg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Salvina',
        lastName: 'Alimardanova',
        emails: ['salimardanova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHW3Sy1aw0w4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655108706236?e=2147483647&v=beta&t=dzoDi8M8zaxbvVDh-YWrqDHQW67UCSdUCakEUyRubj8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zaur',
        lastName: 'Rasulov',
        emails: ['zrasulov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHYb6t8P0B_1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636575486478?e=2147483647&v=beta&t=FmcFlS0ek0t8ZbxIE9Sl2p0ccSCzaNdXW2xo0xQROOY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marina Pigol – Head of Marketing – Softgile |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFVtVqiXAzUJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727468521796?e=2147483647&v=beta&t=Db65m0TSVnLX9GOW-W4w3KE0esMYWfGbprGI3b-cVgY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  discovertechnologies: {
    name: 'Discover Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Truitt',
        emails: ['dtruitt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emma',
        lastName: 'Smith',
        emails: ['esmith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Martin',
        emails: ['jmartin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Wanda Brotzki,',
        lastName: 'PCCMP',
        emails: ['wpccmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFeThGvi5WzJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517680338521?e=2147483647&v=beta&t=R8Al_5NHnv0hWKR_TnQpvp-kxxzmgpQ-NMxhuELMMq8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amy Stark,',
        lastName: 'SHRM',
        emails: ['ashrm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  civitfun: {
    name: 'Civitfun',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Silvia Soler',
        lastName: 'Carrillo',
        emails: ['scarrillo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mariano de Oleza de',
        lastName: 'España',
        emails: ['mespaña@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pere Andreu Canals',
        lastName: 'Picornell',
        emails: ['ppicornell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEtzUFaTMM28Q/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1569571632056?e=2147483647&v=beta&t=EgSWq_LupHgeq8ABxSdzbA3qMsDr-yZdibqCwSCoM9o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Massimo De',
        lastName: 'Faveri',
        emails: ['mfaveri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEiVcb1UwbOxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517344963915?e=2147483647&v=beta&t=FQqwK1jyZ-3QgjRnVe2M1SyW49WJzDB9fadF2H-IKAE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chiara',
        lastName: 'Vurruso',
        emails: ['cvurruso@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHOESs0VDkaIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712129515935?e=2147483647&v=beta&t=BabCdMwey2bmwqj-uPV1kmkuHhJ1O_53Gd7gW-ahobA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aginode: {
    name: 'Aginode',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tibor',
        lastName: 'Mikula',
        emails: ['tmikula@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH4eG6TAaIewA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645002158641?e=2147483647&v=beta&t=rkTYgeS6v8UMUHGyFvHNGO4s1jEL6PzUaJRBHP0vtvw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mike',
        lastName: 'Holmes',
        emails: ['mholmes@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Krish',
        lastName: 'Buchet',
        emails: ['kbuchet@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jean Luc',
        lastName: 'Hennes',
        emails: ['jhennes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF3BPzbf0rd2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573306341074?e=2147483647&v=beta&t=xcJlJa05b4H2o6r9dVSsy0LrqW9lirhET9tC-bUsvE0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Konstantinos',
        lastName: 'Galatianos',
        emails: ['kgalatianos@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  spiritCo: {
    name: 'Spirit for Consultancy Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Johnny',
        lastName: 'Walker',
        emails: ['jwalker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFqtE8wX0ESYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517489306871?e=2147483647&v=beta&t=Faug-UyYXN7noCIMkjwd6zyLthe1k1gUXdgQBS4cSao',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrew',
        lastName: 'Hughes',
        emails: ['ahughes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGMHqRloh-jvg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516267131757?e=2147483647&v=beta&t=1um7n_c4YM8WfFAMGBM8Y6tNAQPUPHqGbqQbOecpJRY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lici Iankoski',
        lastName: 'Tomkiw',
        emails: ['ltomkiw@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH-SAnkTFpl1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660840462624?e=2147483647&v=beta&t=1xPojziwHCQFaGw3Fxp9rIQYUWAdra7pxU0GQ9tOeL4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mohammed',
        lastName: 'Mohsen',
        emails: ['mmohsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE-hq-6Bo1z-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517055039008?e=2147483647&v=beta&t=hsZ-Dzi0ry55CZ_h-uTqmmK-q8r7t1B5oVN24rApOoY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lindsey',
        lastName: 'Annable',
        emails: ['lannable@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG9gd6OMMaRiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517752726946?e=2147483647&v=beta&t=JRIAARp2EnIH2_-RBSxssV5BFwCPB6TaCpKKUMGDMzU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cloudriven: {
    name: 'Cloudriven Oy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Charles',
        lastName: 'Kile',
        emails: ['ckile@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHVIUPC19SqhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569005099942?e=2147483647&v=beta&t=IEHGRp3PSx9GrCJ2WMPt101cXNi5W71sh6HaNGUuzAw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael R',
        lastName: 'Mancini',
        emails: ['mmancini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEpPEdVxbS9hA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517676814315?e=2147483647&v=beta&t=rfqj-tJGXZnERCs7Yza9blCr_qW227nF-0by1-Ec2UM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Shepherd',
        emails: ['mshepherd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEGqoTC_jbhOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516244358361?e=2147483647&v=beta&t=7ZnL53xelV7Uws7k8d1fUeklF9Br34pzOgVOpt8cxhI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Bloom',
        emails: ['jbloom@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Isaac',
        lastName: 'Ross',
        emails: ['iross@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHWkKR0I2HCDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564236528031?e=2147483647&v=beta&t=-lR8vJnJaH3Y0Ka2CC7kGJdyDN3Fq9EpuoCk4QCEE38',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '365Holdings': {
    name: '365 Holdings',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kelcey',
        lastName: 'Lehrich',
        emails: ['klehrich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHnVu0NaWok-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644175926666?e=2147483647&v=beta&t=PuTXocBhp4Bktt57lh6mluvWnVFTdAQeLzjCF8XSzPo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Justin J.',
        lastName: 'Scheeff',
        emails: ['jscheeff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEoZqrrBTINVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547213132438?e=2147483647&v=beta&t=F9n_KXNaZj3qhtll7dR6jBN-dS9gTJSk-yBdW0MfFwU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lindsay',
        lastName: 'Clark',
        emails: ['lclark@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ryan',
        lastName: 'Benes',
        emails: ['rbenes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFpT7zVpOsh4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643314238447?e=2147483647&v=beta&t=_dDb7pMHKwkrEuUqTU8H1j5QcoJO3VxHt2vm3IfAsr4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'James',
        lastName: 'Johnston',
        emails: ['jjohnston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFgJlQpNZGLtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693935047800?e=2147483647&v=beta&t=lkDt0i1Squv2BKWjfRcwd9IU5roq9gb8by0JO9rG87M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ventureslab: {
    name: 'Ventures Lab',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shegun',
        lastName: 'Otulana',
        emails: ['sotulana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGSASXU1q5tPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718487267747?e=2147483647&v=beta&t=u_0t4vr6UD4MVZ3gaKMc-6LEMFi4Z2io073q6mdQ22M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maria',
        lastName: 'Gorskikh',
        emails: ['mgorskikh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH_vOKy-0Lvyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692395016484?e=2147483647&v=beta&t=vlxiBxUSRo0lov7BteZ89k4PBrRmmk2C-xNb9vIEFEA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'J.Todd',
        lastName: 'Smith',
        emails: ['jsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF9VJh-O7wvpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706219140819?e=2147483647&v=beta&t=FJFcsJ2i0FAaRxXZuhNdcxevJ_5QJSKT17UxI79KMpU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Will',
        lastName: 'Little',
        emails: ['wlittle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH3lhWfI_K6Pw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612466873257?e=2147483647&v=beta&t=AvFSALwkTwV6MYGCwGF7uPlqMqSEkL4iWfUQTEIf1Hg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Noran',
        lastName: 'Nazir',
        emails: ['nnazir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG3X63N-JiuLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694882420697?e=2147483647&v=beta&t=2tG2QZvm3PXujEDpLu3McT60Ek9_zzLfRKdNVUn2FIE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eTcc: {
    name: 'TCC Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Molly',
        lastName: 'Fields',
        emails: ['mfields@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFTdj87VMuMtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664628673965?e=2147483647&v=beta&t=NkW8XMC0pyCnTuQnsXECUAGGzuiEuNfTRdfpxxwBiH4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nina',
        lastName: 'Gollapudy',
        emails: ['ngollapudy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEi-RrFv6aq2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695936559075?e=2147483647&v=beta&t=nRUCJhxT_8Vqpw9ga_wmjFkhsGnSIU9_NMwEe8DlGZQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dr. Audra',
        lastName: 'Barrett',
        emails: ['dbarrett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEkY2pBmPiP1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614279519128?e=2147483647&v=beta&t=5K0V1FGeGrft1QWSJI2c2DmYoZiZbeP11b5Y-uj2QdQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marysol Fernandez',
        lastName: 'Harvey',
        emails: ['mharvey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGfdxe9NtjYlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622648897940?e=2147483647&v=beta&t=7mN4CZKUbQtbaTR-45YPrjlEXKXlCXct3eRwHcKGIUM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ryan',
        lastName: 'Jones',
        emails: ['rjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGihfL1VwZcPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712760735025?e=2147483647&v=beta&t=0cP9F-LA7Wr5fmBv8UzJ_-ekDJR2mJSlgrC60SFEtWs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  getunwired: {
    name: 'unWired Broadband',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Peter',
        lastName: 'Sorensen',
        emails: ['psorensen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'J. Matthew Firra',
        lastName: 'CPA',
        emails: ['jcpa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ivan',
        lastName: 'Robles',
        emails: ['irobles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFiMgSzA5Scgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727240875583?e=2147483647&v=beta&t=BPX6LxhDJ8c7XSKicLQHfWupFJ6Y8RBiu_jNHmPNut4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nathan',
        lastName: 'Coates',
        emails: ['ncoates@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEInc7U7_DKDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726075085806?e=2147483647&v=beta&t=1AK0O3yNLkTjp2ydd0w48ahZ9b4CSea3HU2T5kIKjRk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Robert',
        lastName: 'Carstens',
        emails: ['rcarstens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF40LoUmeF_2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707779222730?e=2147483647&v=beta&t=CUeGKr9nusHx8DgkceOJxvLy6M3WWHNjXvW-H4rx_9Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thein: {
    name: 'Thein',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Scott',
        lastName: 'Thein',
        emails: ['sthein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHAZqy52eu0Bw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628701670908?e=2147483647&v=beta&t=UcXNiAPVbt4b_pvR2LcPyQKRCpPzfyIP3pQxuaNIDSQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mike Thein CFP, CLU,',
        lastName: 'ChFC',
        emails: ['mchfc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFjF0CtfwHvDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549552430152?e=2147483647&v=beta&t=QbQway1pd-VhttZ-BBDxDQ4CXqn85ChgwFjqoc9OER4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Barry',
        lastName: 'Thein',
        emails: ['bthein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH5-WfsSR3j7Q/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516863844558?e=2147483647&v=beta&t=hTeZufgNSziyvRXd0f7hiKDJ_pdyewlHiaDB-Art3cw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zin Mi Myint',
        lastName: 'Thein',
        emails: ['zthein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGyn4i4w1HN9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722446359950?e=2147483647&v=beta&t=JERVItcsdniZfXX47aSyOOx8vaLMFtDR-DLVBIps5a0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jacklyn',
        lastName: 'Thein',
        emails: ['jthein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFGOFMtf0hreA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719209194613?e=2147483647&v=beta&t=-BHKqHD-_bO2pIcFYum6WTPMOtCQAiB8ss9wmHLusHs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eastwind: {
    name: 'Eastwind',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pat',
        lastName: 'East',
        emails: ['peast@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHrXuNaHtVJ4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636679257005?e=2147483647&v=beta&t=YewOGGHgaZ9b9vSFObo-Ra0-INdXT7Qenhfu6gP_D7c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steve',
        lastName: 'Potting',
        emails: ['spotting@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephen R.',
        lastName: 'Novacki',
        emails: ['snovacki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFhbhlFmDX6kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618622463762?e=2147483647&v=beta&t=rPcBwalbBZRHXXjwraEU-qW513zS4GyIrMW2iHjJA-0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John ""Jack""',
        lastName: 'Weir',
        emails: ['jweir@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matt',
        lastName: 'East',
        emails: ['meast@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEuQ5W2sNrcvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704901698411?e=2147483647&v=beta&t=gtKiqQMNSZlKH2ch5vmf6p87kNRZJlOFmLGTZFoTrwg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ultra: {
    name: 'Ultra Information Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ron',
        lastName: "D'Ercole",
        emails: ["rd'ercole@example.com"],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Global Ultra Logistics',
        lastName: 'LLC',
        emails: ['gllc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEhxm29A4lJyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517374644954?e=2147483647&v=beta&t=SId2jnvbd0n2gLParDmPHnU2sP9jenrCKPS6S57l5Hk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rhonda',
        lastName: 'Hammond',
        emails: ['rhammond@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHyiHbpXmLd8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600958706399?e=2147483647&v=beta&t=3imJQtRDqi7zSvjUQNN_5mA0ZUwxYYvz4yKFnXfvZsU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Prasenjit',
        lastName: 'Mazumdar',
        emails: ['pmazumdar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ravi',
        lastName: 'Sanganeria',
        emails: ['rsanganeria@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEHqN49s0RcIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728666647964?e=2147483647&v=beta&t=zMj_XdibRJHyhI-FxoTSxtzZjtwHJ-7CDqE4CJosrgo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fairfaxsoftware: {
    name: 'Fairfax Imaging',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steve',
        lastName: 'Chahal',
        emails: ['schahal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFbXh-3lv6Sjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1599704789109?e=2147483647&v=beta&t=fZZ-OO6NrAzXm4i2XVwV4ia3ZP-aOk9fAZ0OjLMrAR4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Donna Castello,',
        lastName: 'PMP',
        emails: ['dpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHg0KNSqR3VBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639768511472?e=2147483647&v=beta&t=52Y3cJaca-HjHolw4sxVnha1tC-S5ZANXl_N5opKrSw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'William',
        lastName: 'Cobb',
        emails: ['wcobb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFsa88RrC1Z2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720456929919?e=2147483647&v=beta&t=9NLLvgS7h1ix8xZgfHDDLf15GkC_HkzdITF9MSHEgCE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robert H.',
        lastName: 'Appelbaum',
        emails: ['rappelbaum@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGLoQKxgFC4-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516283964453?e=2147483647&v=beta&t=a-Y4DnXyFkjLFl_uV_UBzFFWulzzdUorrodyEP4wWDU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stephen Fry,',
        lastName: 'MBA/CPA',
        emails: ['smba/cpa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  appficiencyinc: {
    name: 'Appficiency Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marco',
        lastName: 'Muñoz',
        emails: ['mmuñoz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGDp1meTeHJig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516287084717?e=2147483647&v=beta&t=LIjZO5Wh6If_7rrF5qs-UPRVyODQfHzxT-ZjsorguPo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carlos',
        lastName: 'Palacio',
        emails: ['cpalacio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFQ_4oqnCqNMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573124859978?e=2147483647&v=beta&t=f_4sktVD2aesevfoGtW7xj6cpK1QGRD11F3n6YbXbEw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Olivia Joy',
        lastName: 'Lehnertz',
        emails: ['olehnertz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHlwKACJ6_dDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698169577232?e=2147483647&v=beta&t=7zoYMMecf4-d7KYJJRwOLR_KMKtrvWrgk2BzgVb5vGE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Than',
        emails: ['jthan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Diana',
        lastName: 'Alarcon',
        emails: ['dalarcon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEPfTUHt8JPnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706588033428?e=2147483647&v=beta&t=xzbAEwjN-FJecsueLqE-aEe3cDj56SsX1XjTEvNljGs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zendrive: {
    name: 'Zendrive',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pedro',
        lastName: 'Kroeff',
        emails: ['pkroeff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFu0pbkzjF33g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1520120016678?e=2147483647&v=beta&t=HLZThlVyEmxyU7N4V1Nn86xD12G0_rB81jRAdN-bcgQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sean',
        lastName: 'Fleming',
        emails: ['sfleming@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Divya',
        lastName: 'Mittal',
        emails: ['dmittal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gina',
        lastName: 'Minick',
        emails: ['gminick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEmFUNI-goHjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730076143951?e=2147483647&v=beta&t=tun_zuf1npoP5560tMvAGWgIlZUHTEceeALGbkAksms',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Prateek',
        lastName: 'Gupta',
        emails: ['pgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFKS9Tcg1n00g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595749880638?e=2147483647&v=beta&t=z9rLhJm40J81tQ1Z3XA0qj_XHNrBvYVs7bNPlNp2cb8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pakenergy: {
    name: 'PakEnergy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pamela Hughes',
        lastName: 'Pak',
        emails: ['ppak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHUBLzuhw5yvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616172531498?e=2147483647&v=beta&t=_heWJIPrV3OAcuBPovpJwYNMTZcrkD5qyWn7vfcro7w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pak',
        lastName: 'Leung',
        emails: ['pleung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFJspvLGR7gcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517673922471?e=2147483647&v=beta&t=sx2l66lp9xBqNOc9uYY2Yx3RsELBkrCzTIAzUz--nyE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Santosh',
        lastName: 'Nanda',
        emails: ['snanda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHgXqiG0UEBcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638492278084?e=2147483647&v=beta&t=6G1EJBvittdRKJbeTfD6Z303bc9cPfPavOQ4C0InrCY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrey',
        lastName: 'Pak',
        emails: ['apak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFOJbxJ9GkBog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516969998477?e=2147483647&v=beta&t=t27rdpZJeIJ_aeB9GJHC2CTolnZt39LHpyfHcB1F5HU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tannaz',
        lastName: 'Pak',
        emails: ['tpak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFIsmp25fjTCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624447582100?e=2147483647&v=beta&t=pROs2MaOiQEW-O9ur8B3LmBiGA7W9Dp_5_ennPiQtMo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  halvik: {
    name: 'Halvik',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dr. Madhavi',
        lastName: 'Bathula',
        emails: ['dbathula@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Laura',
        lastName: 'Warren',
        emails: ['lwarren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH6kNgetqrX_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517719846521?e=2147483647&v=beta&t=rifO2w9NFMvFAR1oGPcFckWJwOHSoocDR5u0dfj392A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cathy Moortgat,',
        lastName: 'PMP',
        emails: ['cpmp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Peter',
        lastName: 'Santighian',
        emails: ['psantighian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEOl9Z37412MQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721908447344?e=2147483647&v=beta&t=F5DdZupesJvpjGyYLrE32TssJ4n4wSATFkMYJEh9Ws8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matthew',
        lastName: 'Hunter',
        emails: ['mhunter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGVUViJPtHiaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516286479407?e=2147483647&v=beta&t=bUIr2-BWfbLkSESDK10HOiG9qov4EHzUWENdBgP2Y-M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  inpglobal: {
    name: 'Innovative People',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dan',
        lastName: 'Vaughn',
        emails: ['dvaughn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHzZMLF4cPFWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693597200351?e=2147483647&v=beta&t=3J65K59q9iSfVXa3ijhc-Ij8-565bVHsi-xBfXzIqtg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tessa G. Misiaszek,',
        lastName: 'PhD',
        emails: ['tphd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andy',
        lastName: 'Pearson',
        emails: ['apearson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFPHZ9oPAfYTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548703793480?e=2147483647&v=beta&t=hDx5EnvfVP3M_jOSjUF9QUtBjjtS4jVcvvSvQuIw_lY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shawn',
        lastName: 'Samuelson',
        emails: ['ssamuelson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGYE304_MECag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652816785993?e=2147483647&v=beta&t=MUuRJxWGRZFpA7YudptRixqtjGodzeAjjNRYueWORh8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'ANAND',
        lastName: 'POKHARKAR',
        emails: ['apokharkar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFeN-yO1Z_JkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516338633062?e=2147483647&v=beta&t=dPRxcG9r9DxX7JiJN_4oBGMVy2FQLZTEYdjdoRHW0u8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  patrianna: {
    name: 'Patrianna',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'Whelan',
        emails: ['mwhelan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH_Jpv73pnHRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674554355980?e=2147483647&v=beta&t=nCiqStL1jWZZy7kCZRAWIeNh9a-dCQ_8K4e7u-dSG0g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Viacheslav',
        lastName: 'Sakharov',
        emails: ['vsakharov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFhwlWadNN4hg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1520764440046?e=2147483647&v=beta&t=PcMfX7kUYMF-fbAF0i42kgJCu0-nWB4NirZFKS8DhKc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patrianna',
        lastName: 'E.',
        emails: ['pe.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zarah',
        lastName: 'Patriana',
        emails: ['zpatriana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHUFAX79nHwbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629140515475?e=2147483647&v=beta&t=uUbSXcBbbfCyBUvG23SaiQMlHYsA9gbswrWYONnO8V4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chinmay',
        lastName: 'Gandhi',
        emails: ['cgandhi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEmn2hRbUdx5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699785922087?e=2147483647&v=beta&t=tYx9MQqSieOmRWkj9AtUzvCYBmzJTQwylQHqtVB2FVg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  orisline: {
    name: 'Henry Schein One - OrisLine',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andreea Florina',
        lastName: 'Timis',
        emails: ['atimis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFj89-DWMA9Dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715338506823?e=2147483647&v=beta&t=f37h88O4HCpgdWn1fJFoCU65tqSRyblYIdoNr2jnIRw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Silvia',
        lastName: 'Piccirillo',
        emails: ['spiccirillo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG8HotY39JPLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638229114759?e=2147483647&v=beta&t=FxOU4I6KYRpe6sgKnqyUwqrx9ucN4jjV4OzYHwRUd4c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ugo De',
        lastName: 'Concilio',
        emails: ['uconcilio@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lidia',
        lastName: 'Leggieri',
        emails: ['lleggieri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFrI3DDvlWYaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631813211585?e=2147483647&v=beta&t=TKq5Q08JjMESMwYJl803OA9ChrzdgXGCMRUtjqxxzIg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'antonio',
        lastName: 'gerundino',
        emails: ['agerundino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGEImSdH5B9bA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517865430874?e=2147483647&v=beta&t=tysHgIQmfPzULpYLpSxNXOcda6GPiOJwlmcUGihmoaU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  directpl: {
    name: 'Directio',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brett',
        lastName: 'Jakovac',
        emails: ['bjakovac@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Al',
        lastName: 'Natelli',
        emails: ['anatelli@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rogata',
        lastName: 'Feliho',
        emails: ['rfeliho@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Wojtek Biedziński – Delivery Director – Directio |',
        lastName: 'LinkedIn',
        emails: ['wlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tomasz',
        lastName: 'Banach',
        emails: ['tbanach@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHqKS3K_1zR6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652960291750?e=2147483647&v=beta&t=IFFj3ZU6TENxbxNxengX4bIt5uH4WFZePGN0SktlmzU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  appear: {
    name: 'Appear',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Greg',
        lastName: 'Kraft',
        emails: ['gkraft@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFfv5RAqh2hig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516973257008?e=2147483647&v=beta&t=3cvHvZ8lUvyioN9T9PelWLhXyLm164WWyVaRTu9vma4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Louise',
        lastName: 'Moggio',
        emails: ['lmoggio@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Alner',
        emails: ['calner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFOz6dJbNbJNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696839278518?e=2147483647&v=beta&t=l0zS-JJytrUt_wTiJis0a9gLsLm8SFbVAYsZ-vDWD5g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Prof.Vilas Sudrik (M.Sc Drug Chem,',
        lastName: 'CSIR',
        emails: ['pcsir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEqBIkCdZuW8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629218893544?e=2147483647&v=beta&t=ztfC15fLZfe5YI2smEy2wiLCySlAmVYGaSlheglL_3A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Madison',
        lastName: 'McGivern',
        emails: ['mmcgivern@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHm6qmFLYX8BQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610582464506?e=2147483647&v=beta&t=zzMKQnqjmJFpRy191WPB4gfx22yCa9T-DSja6BiaE5A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  qantler: {
    name: 'Qantler Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Samy',
        lastName: 'Peris',
        emails: ['speris@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Magesh',
        lastName: 'Periasamy',
        emails: ['mperiasamy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lydia',
        lastName: 'G',
        emails: ['lg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nithish',
        lastName: 'Kumar',
        emails: ['nkumar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ezhil Karthika Sathish',
        lastName: 'Kumar',
        emails: ['ekumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFAkvH1qeGAoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701489750666?e=2147483647&v=beta&t=0xTkPRLuiUCIJNu5z6lDkC2WkEdEdR-RkvEwP9NkiSo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  skyhawk: {
    name: 'Skyhawk Security',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marco',
        lastName: 'Nolasco',
        emails: ['mnolasco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEtzH-yH5x_ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581696013643?e=2147483647&v=beta&t=AKc5Wiy7b4i4LbbRnSQOsR9C0Z8FW6E3qRNT0m-5sOA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jose A',
        lastName: 'Colmenares',
        emails: ['jcolmenares@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGkMRrqfde2lw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712852556552?e=2147483647&v=beta&t=vseyWt3GzJqyWkKQ-SvIhHkmoC_7FgvpnEunT0HlMiQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'JhonMay Pierre',
        lastName: 'Jr.',
        emails: ['jjr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHw0qf4N9Lr0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708101695409?e=2147483647&v=beta&t=Tlj7cAMqXTgsg-9oecR2rsw8jzLnS-8xQw4SAzlVCeU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jamie',
        lastName: 'Crompton',
        emails: ['jcrompton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGgsb8eFPr2FQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727100015927?e=2147483647&v=beta&t=hcvjxBcUXGhEJsHMApoTjovo7Gr7HxiJSOZW5dV-YOk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Robert',
        lastName: 'Zega',
        emails: ['rzega@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGNG4kWSN3csg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713393511480?e=2147483647&v=beta&t=K15uhrzgU4L-k9LGfJBtdoapZ49BH_AW5CbgDviVa2s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  stordata: {
    name: 'STORDATA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Olivier',
        lastName: 'TEICHMAN',
        emails: ['oteichman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Richard',
        lastName: 'Cohendet',
        emails: ['rcohendet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEkxMa5ip63pw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1535565667289?e=2147483647&v=beta&t=EW9rUSK5Og95bAgxOGBDeMKYfMlbxZ5GBk4_PnPBtuw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Frederic',
        lastName: 'Chollet',
        emails: ['fchollet@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Suzie',
        lastName: 'NICOLEAU',
        emails: ['snicoleau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGYXAkz0YDT7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698999053057?e=2147483647&v=beta&t=QFNqC21Sc1ovvycS-4NdJz8suoqgIQt2Wz5IdyuTcRg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cristelle',
        lastName: 'Bournet',
        emails: ['cbournet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGTXLM7YCc5rQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549375016218?e=2147483647&v=beta&t=9SJoaZsfwJGC0eiivpnGxGVYbVf4qswlr7lBXSUfWbM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  enhancedvets: {
    name: 'Enhanced Veterans Solutions, Inc. (EVS)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'Whitty',
        emails: ['mwhitty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE4jSwvNSBKgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516313759571?e=2147483647&v=beta&t=Rti7U9Hld-llZwvhYhkiCxG1t3LAyEA_MSihfB_M_bA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Taylor',
        lastName: 'Stoddard',
        emails: ['tstoddard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGaeTvSEzLhTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706364076632?e=2147483647&v=beta&t=xUVYgixkYN06QyTHRYb4tdRUX3UI0gwjflJH_YOMcKM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Melissa',
        lastName: 'Zizzi',
        emails: ['mzizzi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFUNJRyg9d1Dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704990456514?e=2147483647&v=beta&t=GphwlM2Qru0m6Lo01yU2b7pb2v8bOZ4ye5UHDeGmsXI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'William',
        lastName: 'Whitty',
        emails: ['wwhitty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFyLAD70oMtIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611084355224?e=2147483647&v=beta&t=_6JNvfjxSOfaswHiT5EXq22G8TqbmJyMmp-W9CC3IlU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Keesee',
        emails: ['mkeesee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGvoUckxQRibg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604952643618?e=2147483647&v=beta&t=elEj1tCe2JbboMJr3IdsH5remII6xFPPml-64_VKaYc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  voxteneo: {
    name: 'VOX ASIA / PT Indo Online Mitra Usaha',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rendy Yohanes',
        lastName: 'Rachmat',
        emails: ['rrachmat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFn9VSmGncVCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580268719696?e=2147483647&v=beta&t=loMgr6M1HdhGEFECgFpFG4c4eMg1jfptqJnR0-xFABw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andy',
        lastName: 'Sentosa',
        emails: ['asentosa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF9bDn5fpa5aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726221109680?e=2147483647&v=beta&t=OqwJilXx-zzlO8N9G3fttQaSY7cgmeZeT12dJ5Cnko8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Naftal',
        lastName: 'Yunior',
        emails: ['nyunior@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chandra',
        lastName: 'Permana',
        emails: ['cpermana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGnsIhtrJVmgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629813869706?e=2147483647&v=beta&t=NQlhBuQfqOIpK87GPftvwbOVX3g616Zqkw_vpFVmHW8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kharisma',
        lastName: 'Pratama',
        emails: ['kpratama@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFqxIohSJ6-Dw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697159145088?e=2147483647&v=beta&t=YOyEqi4E_DAJaPv59RRPEMwaV79RkmFH99-TfKKNGrU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dar: {
    name: 'DAR',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mohamad',
        lastName: 'Itani',
        emails: ['mitani@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Talal',
        lastName: 'Shair',
        emails: ['tshair@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE_-16MAvGwMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687432820953?e=2147483647&v=beta&t=FPaJxbRZfD8sTpdxWiKk_Vh8QvfOwaWxJzNfirfY8I0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Gundry',
        emails: ['dgundry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLQLIi6gz7ew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1520856039937?e=2147483647&v=beta&t=_COtds--13OZhPUCc77uE1Z7XkALK0z-eGmylyonqHQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David A Hansen FAIA, NCARB , LEED',
        lastName: 'AP',
        emails: ['dap@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQH3Kxibg_977A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551944144341?e=2147483647&v=beta&t=S49qnAo3QbfTejYq8kRQBE5lAPAE5Q0jbEztQ6QGemk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mona',
        lastName: 'Hirulkar',
        emails: ['mhirulkar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  storm8: {
    name: 'Storm8',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chak Ming',
        lastName: 'Li',
        emails: ['cli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE1R31oq0CKJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628538114609?e=2147483647&v=beta&t=t3GSNL7T_Rxl0fud9V7LGDJdtY1ZSwSBxjxN0Y01sno',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ariel',
        lastName: 'Alvarez',
        emails: ['aalvarez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEWnttk4k8ZXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614645557546?e=2147483647&v=beta&t=atYDJepX_Vb1D4YLyqUKptORsi3seAEkvEAMurRP7nw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anna',
        lastName: 'Koan',
        emails: ['akoan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF9cJdsDevSQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597892849063?e=2147483647&v=beta&t=-adfbcTvDbnbvSv5rTXCzTekpA4r5ecl2JgaiHERIGk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Serv La',
        lastName: 'Mer',
        emails: ['smer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFa5UzUK7Z4hQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728506046009?e=2147483647&v=beta&t=sZiXau1pMmCdn-npFvGVwPR9AWo0ybmID5bT-HeV2Qs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Leslie',
        lastName: 'Nguyen',
        emails: ['lnguyen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEoLHgfW_CBAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591142398845?e=2147483647&v=beta&t=2--cnVTM0EEUh570kTbfcaCznvBTKDnb38KeKjfRXQI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rivigroup: {
    name: 'RiVi Consulting Group L.L.C',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bhushan',
        lastName: 'Mocherla',
        emails: ['bmocherla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHXnTxfpqAM-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516314587056?e=2147483647&v=beta&t=a82iFFzYBMfF8O1XIJEMiknSrXB0GXuF4SFVQ98mt4w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tadanki',
        lastName: 'Sarat',
        emails: ['tsarat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHbUHdzUd-3aQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667486876944?e=2147483647&v=beta&t=ypJNNpqPtvwPWbNDfvrw-SC9ntIWc7ZOR158HqyEV0c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sundar',
        lastName: 'Thondaman',
        emails: ['sthondaman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHRwKBTZicopw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636645043305?e=2147483647&v=beta&t=I-VRT50y_rRMJ8Y_iC9gw9uaBNCWs0dD5JWGHFk0s80',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Srinivas',
        lastName: 'Pachipula',
        emails: ['spachipula@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH_coI5nK-eXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670531254969?e=2147483647&v=beta&t=VQRvl5IR5O8N5YmI9ZHB7EfKuffZfEBCdUQI4aNR0Bg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sashibhushan',
        lastName: 'Mocherla',
        emails: ['smocherla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sk: {
    name: 'SK hynix memory solutions Eastern Europe',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alexander',
        lastName: 'Sapozhnikov',
        emails: ['asapozhnikov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFQpn7pGRgGdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517354868872?e=2147483647&v=beta&t=mkX28ml8JnCbalnQUTYIRtrZvuB-hx3zI0zV-koGdso',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dmitry',
        lastName: 'Pinchukov',
        emails: ['dpinchukov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEUB2fqOAI_qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727550525549?e=2147483647&v=beta&t=sfBdvu7_kUBbIHXZ8EI3xdmCqNy74ghOiC3rfHotEMs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Natalia',
        lastName: 'Ryzhenkova',
        emails: ['nryzhenkova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHZxUkBZGj83A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516833379835?e=2147483647&v=beta&t=f9zLUxi7PbgkdcjAWi-WGPsDjiT-GHlIXw-0R8xQe4Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eugene Klimov',
        lastName: 'Slach',
        emails: ['eslach@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH1nbGjfqKjNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516973145977?e=2147483647&v=beta&t=f11LKHN_ZkayvHCljeOVeNaPweim3PBvOytpq-msKxc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dmitry',
        lastName: 'Zelenyak',
        emails: ['dzelenyak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGJBYywmhJwvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516964298602?e=2147483647&v=beta&t=ClmdarCuToBxQ7087_igv9jKCUhwahqgo3PyqeKILJQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  clicsante: {
    name: 'Clic Santé',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alex',
        lastName: 'Southidara',
        emails: ['asouthidara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG_HjXoI2oXRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647877507984?e=2147483647&v=beta&t=77_y95z3GejrVqYkCa-AgDkBX96-HsfsUUpGxOh_y8Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jessica Rivard,',
        lastName: 'PMP®',
        emails: ['jpmp®@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGTzi0cGwrrxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653589184979?e=2147483647&v=beta&t=hHkSLivjaVIt1S7uNvV0tFHrK93HsmKeFj95vdzG24g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aria Adhami',
        lastName: '(Ary)',
        emails: ['a(ary)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHr903Y_0WCmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627440981029?e=2147483647&v=beta&t=EdqwPuvCmZ4XLDUiBAgH739Tdy_glcvqG8ilrd03F6k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zoé',
        lastName: 'Meini',
        emails: ['zmeini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFuOunb7y9Hdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700687094597?e=2147483647&v=beta&t=J7qUY6phpChLE2JOA5j9Tk5HzJjUQArDH7Y_cuh29xA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'André',
        lastName: 'Calado',
        emails: ['acalado@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  crossbarInc: {
    name: 'CrossBar Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'Davis',
        emails: ['mdavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGJsG5tpTF7nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517687504935?e=2147483647&v=beta&t=qm74RuyH90Zqf85bGKGNUqHNxtCHOWScj5pl8y6xVlI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sung Hyun',
        lastName: 'Jo',
        emails: ['sjo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sherman',
        lastName: 'Wan',
        emails: ['swan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ashish',
        lastName: 'Pancholy',
        emails: ['apancholy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zhi',
        lastName: 'Li',
        emails: ['zli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHnRAr0iO2TxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702955839096?e=2147483647&v=beta&t=XlwJwtBN8ZpgFRGZB99IzM7EWIk81CombTNnrJEIMEY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ldpassociates: {
    name: 'LDP Associates',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adam',
        lastName: 'Rainer',
        emails: ['arainer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Danny',
        lastName: 'Martinez',
        emails: ['dmartinez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christopher Gaudette,',
        lastName: 'PMP',
        emails: ['cpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG7wbOu9MilFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516942415221?e=2147483647&v=beta&t=_s0szr9JPh0szRp33XUHvP70xWBnUDybREPIjcUk7DE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Sanford',
        emails: ['dsanford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGLnQf0gU5Wzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607128314777?e=2147483647&v=beta&t=5DGcdZieMNAcKSg9vApZhhfMEpBIB8zi2ACujJ7RP1w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeffrey',
        lastName: 'Prezioso',
        emails: ['jprezioso@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGhAHVN7fF2Sw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546128033948?e=2147483647&v=beta&t=45esZm-hBecTAi_tnCqBoeJHkqCe7Q5lG3rg_f74Vhk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sensetime: {
    name: 'SenseTime MEA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'George',
        lastName: 'Huang',
        emails: ['ghuang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Deborah',
        lastName: 'Phoon',
        emails: ['dphoon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFCJ1gLYRsiLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647196952299?e=2147483647&v=beta&t=An58U86K3LkX0-p90x9kNtweQapSxbUO2nCoVOjxpE4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tiancheng (Taj)',
        lastName: 'Xing',
        emails: ['txing@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFurAG1jS6LsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569345191054?e=2147483647&v=beta&t=IZnEJD0jdwOGJyxkKQ4_UqsYcbv24wiRL46xefF4E1c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ganesh',
        lastName: 'Muruganandham',
        emails: ['gmuruganandham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQET7-z3tcoYgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705002886017?e=2147483647&v=beta&t=U60yCsjuhy5RkGARjUvBL_C-W0TuYSujeLAGdfjjD7E',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Xuanlin (Roger)',
        lastName: 'Yang',
        emails: ['xyang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGjQcyCajCLeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684854703971?e=2147483647&v=beta&t=oLaWw8uIGKAfh4v05e0HV8l184wANrJ4XUhbJ6moO8M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cambaycs: {
    name: 'Cambay Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Priyanka',
        lastName: 'Radhanpura',
        emails: ['pradhanpura@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG8T_7WwOV-lw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591355884795?e=2147483647&v=beta&t=GXH0pP9sM3yXUzbNJslLe8mktOfS9E3paAZdCDFlPac',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Christopher',
        lastName: 'Nicoletti',
        emails: ['cnicoletti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGyjbrpYYXgUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710951076728?e=2147483647&v=beta&t=pu4a9TCl6razEahykW9NrvbD_186HKmBUImQCm5E-zY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jorja Reid, MHRM,',
        lastName: 'SPHR',
        emails: ['jsphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFB0XKsiChKdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597789181572?e=2147483647&v=beta&t=EqZeJTkLRME10aZQxrSJDHX34LDcjyKy9LY0okyWhL0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mudassar',
        lastName: 'Kazi',
        emails: ['mkazi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Antasia',
        lastName: 'Ciresi',
        emails: ['aciresi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  uzum: {
    name: 'Uzum Tezkor',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Khanda',
        emails: ['kkhanda@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Djasur',
        lastName: 'Djumaev',
        emails: ['ddjumaev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHdZFgO4DV9rQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682413703583?e=2147483647&v=beta&t=o9aDzxHx11nLqMRYvJxND33-IpDbwJfUWGmKqx8m3gQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dilshod',
        lastName: 'Tadjiev',
        emails: ['dtadjiev@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Roman',
        lastName: 'Lavrentev',
        emails: ['rlavrentev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGfkJine4Iscg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684166109403?e=2147483647&v=beta&t=kjsT99mOta-yEZWBO4bEJsToAzlTQAMjNOlXjQ4nGwc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pavel',
        lastName: 'Zemskov',
        emails: ['pzemskov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFrpTt_HM1APA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619037190506?e=2147483647&v=beta&t=XQ7OFmJYpgUD1ItuNOJdKyPU5mP1lrTFZMEbEB6OLYA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  indigoit: {
    name: 'Indigo IT LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jamie',
        lastName: 'Indigo',
        emails: ['jindigo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFBOLcll2-RDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707147395756?e=2147483647&v=beta&t=FbJGpKd0Gjg-yUZbprP2ZrdGHZsP0xsnPUO532aKeyo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Indigo',
        lastName: 'Carr',
        emails: ['icarr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEbiip0S2PoKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685463415201?e=2147483647&v=beta&t=sNA-4iWu4ChyLDzicNbKrJKUQyDWSb51s7KG9LyE89o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erin',
        lastName: 'Bakst',
        emails: ['ebakst@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeremy',
        lastName: 'Bonner',
        emails: ['jbonner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christopher',
        lastName: 'Zamora',
        emails: ['czamora@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQElSCaVlh8E0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718240271715?e=2147483647&v=beta&t=odkPM-2t2BKbYjt1nrr1QrYnA69LlxyQos5Qd0lFUUE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aztek: {
    name: 'Aztek Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jonathan Ben',
        lastName: 'Ami',
        emails: ['jami@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEZdSatiIXPtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687342958861?e=2147483647&v=beta&t=aXz-NW1yY2oegSVfbjrUKTvMINnRfYWb1yzvonz8K20',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Blank',
        emails: ['mblank@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aviad',
        lastName: 'Fenigstein',
        emails: ['afenigstein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHplDAUMXQyCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516962987525?e=2147483647&v=beta&t=R8gqb5s3FfNb2IciXMYEWijr7NuLfnRqyZFLOYfOhuM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yuri',
        lastName: 'Sadovski',
        emails: ['ysadovski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFloeA_rETZlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639342078668?e=2147483647&v=beta&t=mdPfXT1WIwlQLBpouRrXTzcMLgUgMMOU8Vjh753AvU8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daniel',
        lastName: 'Kotliar',
        emails: ['dkotliar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH9xB0srSf52g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596473993793?e=2147483647&v=beta&t=-jA6CSGuXOMhjJDe-rx3VHpbaSA7Tk0N3X1QkbwwGB8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gimmal: {
    name: 'Gimmal',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Craig',
        lastName: 'Carpenter',
        emails: ['ccarpenter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF4HUnhFNfNIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516157845824?e=2147483647&v=beta&t=StypsNoVTfnd_08qbx6S2AWod57yzR-h5lVRfNvnEMs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Quackenbush',
        emails: ['dquackenbush@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFz31HgDDhf3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516244579110?e=2147483647&v=beta&t=2O0FeLbsD6fCW6itzgHobMRs69U3NUdDatiEOKmxZWE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kurt',
        lastName: 'Thies',
        emails: ['kthies@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGbvUndf821HQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516514294030?e=2147483647&v=beta&t=gUolnRu674sqTQkScVjMHWbf9DN2DFW5u4IDjNtaEKU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Richard',
        lastName: 'Rans',
        emails: ['rrans@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQErWqiPAgQMmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671678357420?e=2147483647&v=beta&t=4A7b6VfHHG3dGuhaqDnuW1EuYOMYiuh2f_wAcPLuDTg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael A.',
        lastName: 'Cummings',
        emails: ['mcummings@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  twistedpair: {
    name: 'TwistedPair Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sharon',
        lastName: 'Woods',
        emails: ['swoods@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGzrrGjkqbU2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687441708801?e=2147483647&v=beta&t=s-Ib-KOKi5taC0tWib8B5R098hru1z6X5v4E0JXNeMY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dan',
        lastName: 'Draper',
        emails: ['ddraper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGk5kU4fk3jig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687535558304?e=2147483647&v=beta&t=kbUO8SyZ1t9YRnuHdox5pF0C-7xfZtzERGjgvNPiAWA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Hannah',
        lastName: 'Sharma',
        emails: ['hsharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH_P4wF2Pxhzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718222977653?e=2147483647&v=beta&t=qy8eEuN-4TazYxagj7zgPgM4YlbGdmYf_giOuDghbr4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ian Howard',
        lastName: 'CTS',
        emails: ['icts@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Burchett',
        emails: ['mburchett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGu7HlUmKIILA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728667698438?e=2147483647&v=beta&t=Sx5ZVtiRDGHhaWpRkJmVxqi9x4Rz5_yfqHbpw6vJaIw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nagomi: {
    name: 'Nagomi Security',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Emanuel',
        lastName: 'Salmona',
        emails: ['esalmona@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHE9w5EKG0qKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597307338935?e=2147483647&v=beta&t=w3C4PceAaBED0I80_qYDq5ym-B6x9qQDDc5D-k90n-M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Melanie',
        lastName: 'Iwaniszyn',
        emails: ['miwaniszyn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGjh2t0E2aWAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713909753583?e=2147483647&v=beta&t=KPTgOo-tTrXfYsNqvl4zDxA3H2mfgLyXy0rlfGMu8W0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Zamora',
        emails: ['czamora@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Neal',
        lastName: 'Morris',
        emails: ['nmorris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGweNNxnuWRuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695224987137?e=2147483647&v=beta&t=pvcKtlzDl_LVHiw5bKUa3_GAKeIKG74M6X0X_y44CYg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Or',
        lastName: 'Naim',
        emails: ['onaim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  imagry: {
    name: 'Imagry | Autonomous Driving',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eran',
        lastName: 'Ofir',
        emails: ['eofir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE0qn1sSkx_Sw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598235546384?e=2147483647&v=beta&t=zTroemGldM6sWbPaVcgsa4FFP6CeNppNl5yn9Y0vhA8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ruth',
        lastName: 'Bridger',
        emails: ['rbridger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH8D0SSDZVcoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672648587520?e=2147483647&v=beta&t=VHjbhQK5tA6Y9rZ5RXkCA2eGpsIA4v1rTliFFn0pKds',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Justin',
        lastName: 'Liu',
        emails: ['jliu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGUyK508vyjeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594956224563?e=2147483647&v=beta&t=GuHIFkCcEcXfv5MKbn0zXqc7sfGaGRjyqZjKi_H22I4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Suhail',
        lastName: 'Habib',
        emails: ['shabib@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHvi08eNzHNjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670352679926?e=2147483647&v=beta&t=37ERynAiXI-oL_q6h9k8fXxJaAc3uMyDIDGkP2-9zyg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ilan',
        lastName: 'Shaviv',
        emails: ['ishaviv@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  avalonsoftwareservices: {
    name: 'Avalon Software Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Charitha',
        lastName: 'Maredupaka',
        emails: ['cmaredupaka@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFx-d4pZjM8Mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697487560678?e=2147483647&v=beta&t=C-1DKSPvTdW_-tiGcFdkcFLDycZ3S4vt_xKp-a1Tnxk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anoop Reddy',
        lastName: 'Sarvareddy',
        emails: ['asarvareddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFrnpTMGmM2HQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551368063035?e=2147483647&v=beta&t=3GgwD2MQjZRGmTfh6-eQZriO2ZXaoYb-Mreee6ISVpU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'KISHORE',
        lastName: 'M',
        emails: ['km@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sapna',
        lastName: 'Atmakur',
        emails: ['satmakur@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Phanindra',
        lastName: 'Kaza',
        emails: ['pkaza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF5ARe2_9v_iA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719483147858?e=2147483647&v=beta&t=gfuInAoA0_FOyCmcxyHu0hyAAbJ4SDCkdfhtcFkVrGo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ecsorl: {
    name: 'Engineering & Computer Simulations, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mick',
        lastName: 'Golson',
        emails: ['mgolson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFt2eWiAru-Dw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516327955261?e=2147483647&v=beta&t=o4faQoH2kzkjFBKXEhP0yePMochTaV_AeEMWL5qpCCI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'William',
        lastName: 'Espejo',
        emails: ['wespejo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGW68nTA4sT1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727382844292?e=2147483647&v=beta&t=6wrNxuBP99kR0mS-On3RxFbFFeqBxw9PhDEjXfQCs78',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Fahr',
        emails: ['dfahr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH2jlZBpNmnkw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517627605342?e=2147483647&v=beta&t=kRGWh71RaZ9XoHP0iPYTbWnjsI_EbdF-BKeEguxsGjY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dannielle Gusler MSM,',
        lastName: 'PHR',
        emails: ['dphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFaDMydKdpdtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516766117384?e=2147483647&v=beta&t=LlB2G2HBaoHZi0QPh4rgzWbA--tcpYORNHlirIUbU6U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mick',
        lastName: 'Golson',
        emails: ['mgolson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fev: {
    name: 'FEV Romania',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  alawar: {
    name: 'Alawar',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mazen',
        lastName: 'Alawar',
        emails: ['malawar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHPUVEzTE7A_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698808966465?e=2147483647&v=beta&t=pCnC5ajHhGgzl9dDE6s8im3_mWLbzw0oQ4Azu1TI9NI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sara Shouhayib',
        lastName: 'Alawar',
        emails: ['salawar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Adonis',
        lastName: 'Alawar',
        emails: ['aalawar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHujC8nJR2Jvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667497444924?e=2147483647&v=beta&t=pPlAdMs6dkeRfDwPLafbXn5_W-46xsHOlWqdu79Yu0E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maya',
        lastName: 'Alawar',
        emails: ['malawar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF7e5RjlxEXqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696632991737?e=2147483647&v=beta&t=SRnlDhBMMu72joXOu1lGoyWVjvpU_e0sFx6QTdOSDk0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kareem',
        lastName: 'Alawar',
        emails: ['kalawar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHLw6fZQta9fg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633550537835?e=2147483647&v=beta&t=emEuP5cht9RjRaoXNNWIcqqc8jw4svN7Ri8qBlJhMBQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xmxinfosoft: {
    name: 'Xmx Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Drashti',
        lastName: 'Korat',
        emails: ['dkorat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEBEYJ7i5ZIRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683126965436?e=2147483647&v=beta&t=WRGWeOwgZKUY2YN5Uf3Uw16REwu9BeWAkx2STD3-yQI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tulja',
        lastName: 'Chaniyara',
        emails: ['tchaniyara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGeZ2MOzNIgsg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517279374196?e=2147483647&v=beta&t=xU8DJGgeK3ShzyX2Vc1QjpYJR-JbKSzfzGS_rhlnkIE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Binit',
        lastName: 'Joshi',
        emails: ['bjoshi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFwUGP7aAyxtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517253722706?e=2147483647&v=beta&t=RjOoMOp45-AcShxg7S9ZZUrnzAlE_RWRuWgYsa8O3rQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hiral',
        lastName: 'Joshi',
        emails: ['hjoshi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Komal',
        lastName: 'Jasvanee',
        emails: ['kjasvanee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQES3Qh57e80nA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719491545217?e=2147483647&v=beta&t=EVhQwidsOre3znn4c1hxU8Dw5VGoJFhtTRXROuPVuec',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sciant: {
    name: 'Sirma',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Angel',
        lastName: 'Mitev',
        emails: ['amitev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFtmzoQTazqLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713435967072?e=2147483647&v=beta&t=BN5QZMGPOOGMaQV1euDSmZrotkItoGQ1zQ91fFyisIQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rumyana Stoykova, PMP®,',
        lastName: 'EMBA',
        emails: ['remba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFZ1YLJgnyMYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591801398780?e=2147483647&v=beta&t=D84bGXRxoprWMt5FGPWEewuw0R8uXFe-WqphT9LNd1c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Darko',
        lastName: 'Bosancic',
        emails: ['dbosancic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHtC1cELEBqrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646404828585?e=2147483647&v=beta&t=5uDK2RPtbrclURqxsifU7l2K8DS9RAHsDFu3d9HE4Kk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Konstantin',
        lastName: 'Vassilev',
        emails: ['kvassilev@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ivan',
        lastName: 'Bratanov',
        emails: ['ibratanov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQErAg-LO_4N3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675689448631?e=2147483647&v=beta&t=6tmSmfpxInDHElvTmKernB5hRUOKfdVVTMpCujVSnP4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  filliptechnologies: {
    name: 'Fillip Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Deepti Mai',
        lastName: 'Swain',
        emails: ['dswain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGrZZHbhdQ1Kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1599142056396?e=2147483647&v=beta&t=gRA9yMPsIotKtmmCHl5sxp5gDebglhuLAMIcnwaEL3U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shifali',
        lastName: 'Goyal',
        emails: ['sgoyal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vikash',
        lastName: 'Kumar',
        emails: ['vkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEGokWdm2Lquw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517032484808?e=2147483647&v=beta&t=v_PK0U86_66Qp97AwjfwN3EDpKfmONsfFr1OK1Hb-_0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'VIKASH',
        lastName: 'KUMAR',
        emails: ['vkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFqXPKqO-hGbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517463057863?e=2147483647&v=beta&t=-_YuVhEE2v5Uc1zYOzJ_NdCPP5jvc2YazFyM9IEJHNM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'BABLU',
        lastName: 'KUMAR',
        emails: ['bkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGZLV52QVtIhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689401844656?e=2147483647&v=beta&t=7_9NBKaNrFwvpAEVKxcFQVV4ipUzoRV6hwQgcrrkAp8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ocient: {
    name: 'Ocient',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Gladwin',
        emails: ['cgladwin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHULepSaUr3Pw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568406263663?e=2147483647&v=beta&t=XhoToL5zgxmq6HRL0fQGQ97MzyTCS-Bwz0TTAUdSnRY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brian',
        lastName: 'Hand',
        emails: ['bhand@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHO6Zxu258fTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1566938458313?e=2147483647&v=beta&t=yS8h_xlJDhdYrJfmPBzzIqkYDdYLhHL_L7hKzYywhxc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ian',
        lastName: 'Drury',
        emails: ['idrury@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEndxl73INcpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666638700730?e=2147483647&v=beta&t=Kn2CtE-9qrm5uU24Yz6QLoRwSfGlbYhPcWgj3XI49g0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maciej',
        lastName: 'Mleczko',
        emails: ['mmleczko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG4cs4mwfI3mQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659978322433?e=2147483647&v=beta&t=nYF9g8B9wvFQMH0zcnR9og-wJReNcMOsr-Bt4SuyCFc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bill',
        lastName: 'McCarthy',
        emails: ['bmccarthy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGX1am1AEbN9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567516862313?e=2147483647&v=beta&t=LzUeDsfN7gKWOnKFr5gA3ersJWeO0GxUCCjfnx5N22Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  voxai: {
    name: 'Voxai Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pundari',
        lastName: 'P.',
        emails: ['pp.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Baker',
        emails: ['dbaker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQESKYbW3wofpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711048537486?e=2147483647&v=beta&t=lTvvCxmCQxq1mzlaCJ3VLClkPjHHMqI3xT5I5LBPv9A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Edgar',
        lastName: 'Garcia',
        emails: ['egarcia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFu0ppi6CcZiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702883655642?e=2147483647&v=beta&t=RQweMUjvmPfHgC-ZK2TFTrcJTXmNXzE_kELwOnEXTFA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sujatha',
        lastName: 'Kalidindi',
        emails: ['skalidindi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGKSmRGryT_zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516302490953?e=2147483647&v=beta&t=yakAnoZLCvej5weF67i3a8JvlVI5jcTS8NgMzeAQhbc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abhishek',
        lastName: 'Sharma',
        emails: ['asharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHKGNBRWIjk6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643328165439?e=2147483647&v=beta&t=CrsLRWJs2BdEXs6vIRLTqWw6P3LL7Stv5vIqgvFRq6Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  injala: {
    name: 'Injala',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hyder',
        lastName: 'Naqvi',
        emails: ['hnaqvi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFxQnefTPk1AA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693939942718?e=2147483647&v=beta&t=DpqD39AJCpJG-fK0DaNw07x21nZW9PjbutjdIZYmRxI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rajeev',
        lastName: 'Varshneya',
        emails: ['rvarshneya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHMZqIDjqi2pg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712952472988?e=2147483647&v=beta&t=1AFPFCWP9p7LsnuZNYDnNXmEX0jDbx-0cybBU-sCFZ0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris Fanchi,',
        lastName: 'MBA',
        emails: ['cmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH7wjRmQbhDcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544482713125?e=2147483647&v=beta&t=i_rj1wz0TjbkeYGFTPQjfuFVFtoVuFlTMHpqgDJMrTc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nicole',
        lastName: 'Bird',
        emails: ['nbird@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH7f2bC-XTcFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707780864261?e=2147483647&v=beta&t=KsOszkdD-REju6eWh9zwcOy7a1F3GCw24tRF1c0bqcc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shivani',
        lastName: 'Brhambhatt',
        emails: ['sbrhambhatt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  getrinc: {
    name: 'Global Enterprise Tech Resources, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Baker',
        emails: ['jbaker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHGqJ6DnRzdXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587238176844?e=2147483647&v=beta&t=R5-2t2im1IYkJ1eZgn85hXDGyBr7nyeSpOHNjHGZV8o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Raj',
        lastName: 'M.',
        emails: ['rm.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeffrey',
        lastName: 'Haddock',
        emails: ['jhaddock@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEtOJjOV3lz2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695642439524?e=2147483647&v=beta&t=ZOpwS7XCHtmhdQMHzpziozivcKtl27ecM4OX30CAMi0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vishnuvardan',
        lastName: 'Vemulapelli',
        emails: ['vvemulapelli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE3aERKnlsPjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704807081407?e=2147483647&v=beta&t=Bo4HRmbI-ROhTfA1kB8UBV5HM5TLANJ_Gg9p_JpqV38',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anirudh Swaminathan',
        lastName: 'Ramesh',
        emails: ['aramesh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  farlinium: {
    name: 'Farlinium',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Farzad Aref,',
        lastName: 'CCXP',
        emails: ['fccxp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHHZ3NiEy5yqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720713104957?e=2147483647&v=beta&t=zIFUJSwKrwUSReIVsoyml-aBH8Tf8NHq4xTgqFQA0gE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'James',
        lastName: 'Schafroth',
        emails: ['jschafroth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEuksvL_1XmlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629313264051?e=2147483647&v=beta&t=yTx0_9-YJqAqdBo82p2LIOLy00sm3MARXHS4HKs3LBI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Victoria',
        lastName: 'McAllister',
        emails: ['vmcallister@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEPjlOWT1EZVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573483020517?e=2147483647&v=beta&t=43nF_7McUdIr0EePVb3zAyLO6Nn_D_VXmu8mVwwoOzU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chesley',
        lastName: 'Smith',
        emails: ['csmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHHUnrN7N3zIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608303988724?e=2147483647&v=beta&t=xXWuhuHo6zsItrDdj_0364n_KrcfW4JSVzcRIiWoz-w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'LuAnn Lessman,',
        lastName: 'PHR',
        emails: ['lphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFstpV6BNsLSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713753588393?e=2147483647&v=beta&t=VmKD-7OtJe0B5jT7B-PVmTxTKjdaQ-HZUoEjqJdAhu0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  baidam: {
    name: 'Baidam Pty Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brad',
        lastName: 'Strauss',
        emails: ['bstrauss@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jack',
        lastName: 'Reis',
        emails: ['jreis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFUUQcwAUz-CA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678872974840?e=2147483647&v=beta&t=_Givtp28Ovdc8htGNFa1K5QP5UgHVWXJTGVbDUkPqUM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carla',
        lastName: 'Reis',
        emails: ['creis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE9xpQ7quOHzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660009953561?e=2147483647&v=beta&t=GE-Eanpvz2HL7vcKd_i0I63N8GwnYpBMU5dUS1nm-vg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pip',
        lastName: 'Jenkinson',
        emails: ['pjenkinson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rochelle',
        lastName: 'Brooke',
        emails: ['rbrooke@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  agencybloc: {
    name: 'AgencyBloc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adam',
        lastName: 'Lewis',
        emails: ['alewis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFlCawxHt9DSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586969259565?e=2147483647&v=beta&t=ojoMqO2-ygffN8Ie4eVlzYCGQOJ64pwCOYlFMXYy93c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Burns',
        emails: ['rburns@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFHrP9BN6IIuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603906751432?e=2147483647&v=beta&t=ZigDZUA2ntiw-SaRHljOaCcOMr3x8c3IOdHM9JUR-G8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bernadette Maloney,',
        lastName: 'SHRM',
        emails: ['bshrm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Megan',
        lastName: 'Bruington',
        emails: ['mbruington@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG9F9vD5mx4eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689822492140?e=2147483647&v=beta&t=Eu2lIQnk0SUHbgIgXrKd1IOlRoium9vA3BS0IaF-rrs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kayleen',
        lastName: 'Popp',
        emails: ['kpopp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEl8Jdk0YVCiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677431575037?e=2147483647&v=beta&t=bf9CbgXQQPekBFgrSDfxwd7EYRmwGZL7_YRM5DZUhls',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  switchfly: {
    name: 'Switchfly',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael Audia,',
        lastName: 'CPA',
        emails: ['mcpa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nowell',
        lastName: 'Outlaw',
        emails: ['noutlaw@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFDhs3EVgvo7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650332757461?e=2147483647&v=beta&t=tmqS62bHi4AVgv50kgaP2ZdU6idSxnqVDhUmPORpFuM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'Smith',
        emails: ['msmith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ashley',
        lastName: 'Martinez',
        emails: ['amartinez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alex',
        lastName: 'Johnson',
        emails: ['ajohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tiqtoq: {
    name: 'tiQtoQ Ltd - Quality Engineering',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Naveen',
        lastName: 'Bhati',
        emails: ['nbhati@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHiWWjyl-i0gA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730090487136?e=2147483647&v=beta&t=EVgs324B573hrpHH4YsKiVdwEgu4wxRy-viJXVYCeJM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Oliver',
        lastName: 'Bidlake',
        emails: ['obidlake@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFC1TO-WjlzXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665136750331?e=2147483647&v=beta&t=0hcqSZZh9z1BDxo10PyH3R5Q8MlDHHXHIBqpIXgq-3k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shakeel',
        lastName: 'Jiwani',
        emails: ['sjiwani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEbF-K_gkucWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695371896785?e=2147483647&v=beta&t=TVWW1NRVYLUzoXe2yPUMiNSAmT5BIBGs5kWREdeqEVc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Neil',
        lastName: 'Martin',
        emails: ['nmartin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Peter',
        lastName: 'Jones',
        emails: ['pjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEfqY3LsB6CfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695222185173?e=2147483647&v=beta&t=ezQ2UxBZTacJL0emP-0aG-jOlChoPkPBgE5gj2R-AmM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  soteria: {
    name: 'Soteria - Security Solutions & Advisory',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Morin',
        emails: ['bmorin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEYeM0cXXpwVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654617424589?e=2147483647&v=beta&t=EC29RYM_2dw-9A8uFmogG1yuGaKfQyMvGhpU-6SX6ZY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abby',
        lastName: 'Zielsdorf',
        emails: ['azielsdorf@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFYMHL0mjxIgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690904958722?e=2147483647&v=beta&t=CUsuhGLx9-LUMPs9WO-SvWWWUl5Oh4YUjhgD7O6hnfg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'William',
        lastName: 'Nobrega',
        emails: ['wnobrega@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Voyce',
        lastName: 'Hendrix',
        emails: ['vhendrix@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFAVGkRqRmikQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517514879719?e=2147483647&v=beta&t=IPtnOd-TD1p5LWpWerU9xULBakV08T6B6daK5WXog5U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aaron',
        lastName: 'Schoenberger',
        emails: ['aschoenberger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHjDz4cBYnpnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517726426341?e=2147483647&v=beta&t=epE8YXDXAA4UnWUMWJpwcgbbpeyAFxZF7Pzx_pehVxM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cyberNet: {
    name: 'CyberNet AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ivan',
        lastName: 'Belokhvostikov',
        emails: ['ibelokhvostikov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGkOCGKSZMK4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725572172245?e=2147483647&v=beta&t=Ed8oCE2VhK8HJgX6ZFU7SZ9AbrgaErQUHZ2BD4g0E9s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aigerim',
        lastName: 'Kambetbayeva',
        emails: ['akambetbayeva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGZ4mfDHv_xyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635258687738?e=2147483647&v=beta&t=Kw8eld-tsQpw7kqJzK9ZIuqOScmMJeBWRwNaRXm1XRw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gulgaisha Temerbekova | Machine Learning',
        lastName: 'Engineer',
        emails: ['gengineer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH3d5dAoMHf2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695991182115?e=2147483647&v=beta&t=Bnw0ivGy0xqZda2aDEeBjQD4p-KO2eQ3rA6jlWfnxjQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daniyar',
        lastName: 'Omurzakov',
        emails: ['domurzakov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFxveb8WkKOgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698636882956?e=2147483647&v=beta&t=Hj5bgo8H7cEGBoTlVdGFdEqqirPW2Xk96OhAWyvna8M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aruzhan',
        lastName: 'Abylkassym',
        emails: ['aabylkassym@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG3YDLbsCxbFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707880812938?e=2147483647&v=beta&t=s8Kg_zNZLfq8Bjd-FqrAUm7re2y8y33-oq4u6j7JxeQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  synacor: {
    name: 'Synacor',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Drake',
        lastName: 'Harvey',
        emails: ['dharvey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH-mq_ZiEAm7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701304590974?e=2147483647&v=beta&t=jfcTOmddlbI7xtty_9Sd35nvsrFeQ_BhiXeWF9r6vIk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Himesh',
        lastName: 'Bhise',
        emails: ['hbhise@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHj0VNmKFnppA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516159426570?e=2147483647&v=beta&t=JlDRIy8e-hhflcs-soAy9IRZhSMc4beQBTvv-jf3yRo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ian',
        lastName: 'Mitchell',
        emails: ['imitchell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFIfPheX9q2Pw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611667467337?e=2147483647&v=beta&t=GSEs77QJazB3IPmFHhcPIjKhL_X2PMUys2xvJSVFx58',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Bak',
        emails: ['jbak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEmhHcZZYMXCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522540983618?e=2147483647&v=beta&t=tXMzH8Lt4692qrjioHUxNnQjeEzebl5hqlq1-MjJ3Q8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amy',
        lastName: 'Andrus',
        emails: ['aandrus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFTFI1yj8ZViw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634401532987?e=2147483647&v=beta&t=BUwhcl24blikUQrZaXbKplocwEZWGfYkntVu3DyJM3A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  shift5: {
    name: 'Shift5',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'Zacuto',
        emails: ['jzacuto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHiDMhTX93GXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672942106876?e=2147483647&v=beta&t=qyIF1GS7sHXMBgNqLZtEzFMxVWWpLvomRU6dVIaeDwU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Josh',
        lastName: 'Lospinoso',
        emails: ['jlospinoso@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEJ3uKt_AeYiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651003693443?e=2147483647&v=beta&t=_I5fbHBqgeB1emIVIc2Roz6XyqIxhJ3HepaNaIV6YSk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Zach',
        lastName: 'Greenberg',
        emails: ['zgreenberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robert',
        lastName: 'Sison',
        emails: ['rsison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHXGNwtEd_ikw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678118150079?e=2147483647&v=beta&t=04KyfS23O2-32FFR2hwrx302Yikg680SBmheGBn79kc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Katie',
        lastName: 'Garagozzo',
        emails: ['kgaragozzo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGNJAKOtEgTCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593111384283?e=2147483647&v=beta&t=Pryf3WTLNa2Tj8OsCER-4RO4CQVFJ45vOdws6q6huiQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  txtgroup: {
    name: 'PACE Aerospace & IT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrea',
        lastName: 'Julian',
        emails: ['ajulian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFgiQYfL2HbZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705460706300?e=2147483647&v=beta&t=A7Ym8TmCI4v_Pypelxc-hJYL4vBx9YFZPIi9Ffp62Os',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Christian Beuther –',
        lastName: 'co',
        emails: ['cco@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Adam',
        lastName: 'Sylvester',
        emails: ['asylvester@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEzWlJ5-bVBzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550009438077?e=2147483647&v=beta&t=rahlr5nYbmONsC5cJZVNHTpZAqzl9Iuljftoj4BJbw8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dr.',
        lastName: '',
        emails: ['d@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHZdo5QxByCjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557688620419?e=2147483647&v=beta&t=DBahuNl9KpDtgj2aul193CJV6fOOpCmCIkyzpbhkcxo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jürgen Bilo – Managing',
        lastName: 'Director',
        emails: ['jdirector@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFjFiXzN2yV2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517077542443?e=2147483647&v=beta&t=mpJqAwaFonKyuu-jOkyKpygGywI25kCFpk0F6hJ8oP0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  novade: {
    name: 'Novade Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Denis',
        lastName: 'Branthonne',
        emails: ['dbranthonne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE_5LrLl6Udag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587529049022?e=2147483647&v=beta&t=KjqZ0sTM-6Lf-WFvMXqQ3hivUPOqmF6dG5WEsmqJOZI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Denis',
        lastName: 'Branthonne',
        emails: ['dbranthonne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE_5LrLl6Udag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587529049022?e=2147483647&v=beta&t=KjqZ0sTM-6Lf-WFvMXqQ3hivUPOqmF6dG5WEsmqJOZI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bruno P.',
        lastName: 'Suard',
        emails: ['bsuard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHFrQbybS-qXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546778770375?e=2147483647&v=beta&t=dwo7UCtk0KritPo2orLlJHzdlPsFfSo3m4_bI-bxU74',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tejas',
        lastName: 'Thomas',
        emails: ['tthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFCW52F5oL3cQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727237925714?e=2147483647&v=beta&t=BIVd_mSxx_27lfTVJVs1VIpfQz4IGCLtLbZNptiOs78',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Harpreet',
        lastName: 'Kaur',
        emails: ['hkaur@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  capeannenterprises: {
    name: 'Cape Ann Enterprises',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Edin',
        lastName: 'Halilović',
        emails: ['ehalilović@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEYFGc6tFfkKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630255697095?e=2147483647&v=beta&t=dc4m7xUHbFvMaVVM9ir5VhWuQlwsrzekKjdcwRUr91o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ado',
        lastName: 'Mesalic',
        emails: ['amesalic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGCtIUieKPesQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569186453577?e=2147483647&v=beta&t=HFdMU67SKDmATcUU6Vi5EVwJynEnZviTdJjtCwQtkpI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mujo',
        lastName: 'Osmanovic',
        emails: ['mosmanovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHSoODzdiPRKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516931213706?e=2147483647&v=beta&t=ipvpiRMFjr8lio7RcJ0tj0xBqyXNTnRWZvjHvxNkybs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Faruk',
        lastName: 'Ahmic',
        emails: ['fahmic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG7D0FSN7lvjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544685271529?e=2147483647&v=beta&t=k7UCcFGONdF3tKWTn0_gee8QoSeOJ8T57ZAJ-pdox_A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ajdin',
        lastName: 'Omerkić',
        emails: ['aomerkić@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFbxNQpb1mzqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652947110125?e=2147483647&v=beta&t=yMxsy-Cr5EdPGrnEb2lrSsw40zeDLeAcWa56LZChBoQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wavestore: {
    name: 'Wavestore',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Williams',
        emails: ['cwilliams@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Julian',
        lastName: 'Inman',
        emails: ['jinman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Simon',
        lastName: 'Steer',
        emails: ['ssteer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHwdqY9I8-7Lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600370094962?e=2147483647&v=beta&t=Hpeg_vZrcDz6Ym_hUvJSvMaMmf1W0bBknzj_Kfg2ad4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'Claxton',
        emails: ['mclaxton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kim',
        lastName: 'Loy',
        emails: ['kloy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFJQMql_DK9Ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727974957583?e=2147483647&v=beta&t=S61bMlmhXKrBmY3QHQasJYSxJJkEDQZ9hhiJ_ZvEXNA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  opensoft: {
    name: 'Opensoft',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kuntal',
        lastName: 'Das',
        emails: ['kdas@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ana',
        lastName: 'Delgado',
        emails: ['adelgado@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rui',
        lastName: 'Cruz',
        emails: ['rcruz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rodrigo',
        lastName: 'Marques',
        emails: ['rmarques@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGxPuZNSWhEhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726436232551?e=2147483647&v=beta&t=OyaqjFuFHyX-ATqIU4EQ5xzXKUV2Fw2ngU3AgAsB7SU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Addy Van',
        lastName: 'Doorn',
        emails: ['adoorn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFFVC0J92Ub8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516334488715?e=2147483647&v=beta&t=Nd5NhBb1SC8MxsbDLy1Z6bqBmLcT9vqcxMIfRu-mZQY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  inpas: {
    name: 'INPAS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gerrit Jansen van',
        lastName: 'Veuren',
        emails: ['gveuren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHv9yuVUrFRTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688550366206?e=2147483647&v=beta&t=n4cXexUQCdyCRxkSh_NQGUb07EbFD2aT_pUYjRabIMA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rom',
        lastName: 'Impas',
        emails: ['rimpas@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Irina',
        lastName: 'Barkalaia',
        emails: ['ibarkalaia@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vignes (Inpa)',
        lastName: 'Inpanathan',
        emails: ['vinpanathan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFkrdvoWboyHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605244110817?e=2147483647&v=beta&t=DcmmyefdnS-72xReqMgir7PUzdAa8xh1hEhadX_Xyb4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Domenico',
        lastName: 'Iovino',
        emails: ['diovino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGVOpvYzNK0LQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604226376295?e=2147483647&v=beta&t=gv4WUKt986zMVEQF9mvt6oc5pNXzOu8VWlpBREMfWwc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fingersoft: {
    name: 'Fingersoft',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Isabelle',
        lastName: 'Finger',
        emails: ['ifinger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGahQkD3zAkDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516287876533?e=2147483647&v=beta&t=ntEbSSu7NGOXPbQw8u2KaQx1mqsoa_bzTLSc5hNwRDo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jon',
        lastName: 'Finger',
        emails: ['jfinger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQETqFKoK2PCDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702669932983?e=2147483647&v=beta&t=hnd0jRmO1-XkgQE4VZd09KUOdBlPGmk0rIoE9mS20kE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aria',
        lastName: 'Finger',
        emails: ['afinger@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Finger',
        emails: ['mfinger@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matt',
        lastName: 'Finger',
        emails: ['mfinger@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  brandDocs: {
    name: 'TrustCloud',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sravish',
        lastName: 'Sridhar',
        emails: ['ssridhar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFHXBHO41W48w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709770217979?e=2147483647&v=beta&t=RobSTk-0h-_q3-aFPRElu8qCROIJI8kJIYRiOm5svSw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Norman',
        lastName: 'Kromberg',
        emails: ['nkromberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tejas',
        lastName: 'Ranade',
        emails: ['tranade@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF0t_7HbtxTvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713189405286?e=2147483647&v=beta&t=AqvA58vj1-ohgMnDkj5DuNG14OB_rWJ8JsJSNS9OGbE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ivan',
        lastName: 'Stoyanov',
        emails: ['istoyanov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHEF0sACWhoWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593715677240?e=2147483647&v=beta&t=ST03-jVixmHQDSqjEVFsHjPNUwnW2iCa-iHWmRlD_so',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cristina',
        lastName: 'Vega',
        emails: ['cvega@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHSoRH6RyjgoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691602439947?e=2147483647&v=beta&t=-7aMYiq-PfMFHRfbZB7QRbTnriX_9Lc_lJbQctVd4pE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  abridgeitfirm: {
    name: 'Abridge',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sakibur',
        lastName: 'R.',
        emails: ['sr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHxbW5Kv9nyRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627158022842?e=2147483647&v=beta&t=WpE8Tl-xkcWUo58WSQxulX-Ie6gk4Fat-Jxoitl23ko',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sadd',
        lastName: 'R.',
        emails: ['sr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGG7pa4dLZ8ag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679825559137?e=2147483647&v=beta&t=-bqGOEzrAyyjSNlyxSLQiXuOXXlFjUTfdblzqD7FR1U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'MD. Sagor',
        lastName: 'Morol',
        emails: ['mmorol@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHi4UMnEOnpWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699974032646?e=2147483647&v=beta&t=nvMiuVgMtfb4_iaFKPbC1ZAKvMgiasN-f7mG9RLAQRU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abrarul',
        lastName: 'Hasan',
        emails: ['ahasan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEQLCEMOeufgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1528555619830?e=2147483647&v=beta&t=SzY9N6q-hzJN_hAT2g9NIX8ZnzsZgmnEy0CJHWmWOlI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Toyor Al Quran Model',
        lastName: 'Madrasa',
        emails: ['tmadrasa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH8piKijKsExA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627226260620?e=2147483647&v=beta&t=kIA74xrLev_bjZOQXP-jk2--kSWwo92X-yxpfowSPwQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  smartrak: {
    name: 'Omegro',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ted',
        lastName: 'Lee',
        emails: ['tlee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHzkxW1C8dVGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660386701991?e=2147483647&v=beta&t=3skylwi8Wo-au8KUMS8jwVEsbd7enkaDY9GhdEnMs90',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrew',
        lastName: 'Price',
        emails: ['aprice@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHvTqSd_kZmbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605106198679?e=2147483647&v=beta&t=hb5uz7bTpsIc0GOqVLZgmXbOIlG-qskpejbMStyQa1U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Perkins',
        emails: ['mperkins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGvcMZEL1sJpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668061545807?e=2147483647&v=beta&t=0quB38CN-hBJY8InAInaxA4N6c9Fi7hRcwFAsdfVL2U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Judd',
        emails: ['cjudd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGE3KDlvFHaFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555439894665?e=2147483647&v=beta&t=nGsIRyOWZXwVQ5qWyPSLSF_M5sr2ErgLe0nr8eFEvCI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Feeney',
        emails: ['cfeeney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMfUD7_aQx0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707158014388?e=2147483647&v=beta&t=uRLZLFUIT5kMfh1GX_j8ixAtJE5YQRKxKJztx0duVsQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  infrascale: {
    name: 'Infrascale',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Peterson',
        emails: ['rpeterson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aaron',
        lastName: 'Jordan',
        emails: ['ajordan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGBNIKtN_-IMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552344237199?e=2147483647&v=beta&t=feAVnLViotHr8sHgXeNQagEUwLUJUF8k32HKwFIIqwY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael L.',
        lastName: 'Clark',
        emails: ['mclark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH12vu-QhzyLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636750070154?e=2147483647&v=beta&t=OGMpuo2pO-t_BGQafv1u4mz1zlDj1-P0PRLIltvl3rU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ashwin',
        lastName: 'Aiyer',
        emails: ['aaiyer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ken',
        lastName: 'Shaw',
        emails: ['kshaw@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHBbouz0xO3Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725760645733?e=2147483647&v=beta&t=vE8pUIAYD0xMDNh7ri2C6ExnC6xUIeAdxFp1UQxdmys',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jMack: {
    name: 'J-Mack Technologies, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Charles',
        lastName: 'Stone',
        emails: ['cstone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEH2u7MrHtf0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629301903886?e=2147483647&v=beta&t=GFZaxMJovz1EqsZ6vuBwd17K_yDj6j8MKxt57p9zrEw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'THOMAS',
        lastName: 'KELLIHER',
        emails: ['tkelliher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFLPuHLnV9AkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610132803672?e=2147483647&v=beta&t=wukL5rWikmeCzrXzFFtT6Tb2GlBy9m8gwXmKfstQ-wA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cade',
        lastName: 'Foster',
        emails: ['cfoster@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGLsgAr_9vtKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687934818805?e=2147483647&v=beta&t=wWrKcaJ_ucz4yIr5fzU3KSOU37GvJun1VnakngtbSh4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'McCormack',
        emails: ['jmccormack@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shelly',
        lastName: 'McCormack',
        emails: ['smccormack@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHjAMJdI2X24A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517353847279?e=2147483647&v=beta&t=_oZ68WqTnJQVfruHpCGzHUNCBL0PaYXecvZ8fQBvNLU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  carbice: {
    name: 'Carbice',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Keshia',
        lastName: 'Loth',
        emails: ['kloth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG6I_5yR8SoGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1576038305895?e=2147483647&v=beta&t=LSf97swZunQxkTMFb9RjT7FwZXa1CrOQbQBP302U17s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Craig Green,',
        lastName: 'PhD',
        emails: ['cphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGgYHMrYFDRlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517351624589?e=2147483647&v=beta&t=qHLYb9BU-puVZWbPGGbnaedeXt3Q_3B2PFlAXG3d0Ps',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marcus',
        lastName: 'Walker',
        emails: ['mwalker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHHKhmTDpJBMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673391807333?e=2147483647&v=beta&t=mHKsg8V6Ww31J2ZmU37xc5G7sSCqbjaS6Bru7MFW-28',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rafael',
        lastName: 'Spears',
        emails: ['rspears@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Leonardo',
        lastName: 'Prinzi',
        emails: ['lprinzi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGApVfffnzNGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543037949835?e=2147483647&v=beta&t=b7rNdUw7mvUpQMsY-d36PczlqTZh7OrUGshvggXn87Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  landytech: {
    name: 'Landytech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Basim',
        lastName: 'El',
        emails: ['bel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anuja',
        lastName: 'Rokade',
        emails: ['arokade@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFc2w8PuYsmSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661260320176?e=2147483647&v=beta&t=vuhlvoCl1Re2cx_EWtM_vYolkh1DPjURFd5gtW5P3CM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ziayad',
        lastName: 'Akhtar',
        emails: ['zakhtar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH41cIat1JfGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676013637829?e=2147483647&v=beta&t=atirGWEF02wnm_MKQZR9GhzxlT3RThQES0EOMdkNwEk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sam',
        lastName: 'Franklin',
        emails: ['sfranklin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHdUdEs77hCWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616142837991?e=2147483647&v=beta&t=x358YGxLfjgxITGH67YEc5a3nhUuu9NPcFxnW3kOlLU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Benjamin',
        lastName: 'Mouté',
        emails: ['bmouté@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  asbis: {
    name: 'ASBIS SK',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrej',
        lastName: 'Buchamer',
        emails: ['abuchamer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEi4lNW7Iodrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560274248277?e=2147483647&v=beta&t=SocYH_AQ89xQJRS9bbW4Be5Yf5OQjM9d2x9qU-X500M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Macho',
        lastName: 'Jozef',
        emails: ['mjozef@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Igor',
        lastName: 'Zeman',
        emails: ['izeman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGYDhq6HgyVxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516767560999?e=2147483647&v=beta&t=pdr9vJIfhv3eUr5wR5b-VYT6n8Mfch7ZLGGxMDERxu0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michal',
        lastName: 'Drnda',
        emails: ['mdrnda@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emil',
        lastName: 'Gajarsky',
        emails: ['egajarsky@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  implan: {
    name: 'IMPLAN',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Justin',
        lastName: 'Helmig',
        emails: ['jhelmig@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFScIvfh4w3Ag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682088502142?e=2147483647&v=beta&t=1n1oFTgmgPO7UCHK2yKG_29FvxFxBLyN8g3FMiw1Z9Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matt',
        lastName: 'Shuster',
        emails: ['mshuster@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jennifer',
        lastName: 'Thorvaldson',
        emails: ['jthorvaldson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEtBCiYlIZOIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683557627576?e=2147483647&v=beta&t=eHv1GzEC_FjRhrCDP_uNPTb3w7O8RV22F1uphAI3ZQ4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bjorn Markeson,',
        lastName: 'Ph.D',
        emails: ['bph.d@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG7Z-q1M4jGKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603895781002?e=2147483647&v=beta&t=_V5AwpeNSVfouVvUJc1xiG_uZUY-SFTIPX_1WGmeE64',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kenneth',
        lastName: 'Groom',
        emails: ['kgroom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFKYVbXDvrnVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721592687544?e=2147483647&v=beta&t=_Pm-TY0yILvKoZCpC1XW6b8ld81K1JMDIqBoaQtd-QM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  allevar: {
    name: 'Allevar Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Careers With',
        lastName: 'Allevar',
        emails: ['callevar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF8jEIIrwhGGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653484403659?e=2147483647&v=beta&t=bj5ND4_AHmAprbjeYCkbiBV7AToBrG2yDB64fN53jLQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maureen Binder',
        lastName: 'Kotopski',
        emails: ['mkotopski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG-KiZAZ7loJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712886629844?e=2147483647&v=beta&t=iZElSszeQt3HQW8x64r6r-9qDyy2u2Q5HhiYGAx9X_s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Connor',
        lastName: 'Wood',
        emails: ['cwood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEljdpt7UmN0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685130059676?e=2147483647&v=beta&t=wE5M4VJOdMaVdobhf2ZF_CpuxiUbkCa5f0C2ZBjNM2c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Craig',
        lastName: 'Todd',
        emails: ['ctodd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ramar',
        lastName: 'Periyaraju',
        emails: ['rperiyaraju@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEJg2LNmIMKaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727726804553?e=2147483647&v=beta&t=FFzKSEqWUqPISpLHOB09LvdqZ4DqI6XBGoxzNE-edzs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ospreyfs: {
    name: 'Osprey Flight Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrew',
        lastName: 'Nicholson',
        emails: ['anicholson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matthew',
        lastName: 'Borie',
        emails: ['mborie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQET9hnfdAuDYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629731221626?e=2147483647&v=beta&t=2pZWpoO-dZtZ2VN8QlgVVjSLXEmo4Z4FCK74TbOP-HU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alex',
        lastName: 'Burton',
        emails: ['aburton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGwN1YHDPaBzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689320053420?e=2147483647&v=beta&t=a5qRGos2MuxwgE_k6q9BKAfFSwKNmurjaPZAJUbdkhs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt Stutely',
        lastName: 'OBE',
        emails: ['mobe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLTv_EwNJHjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650555521997?e=2147483647&v=beta&t=U1bnZ2nh-nD3F9gH74SFdCNxDCMxaWBL8mIZg7ImjcM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tatevik',
        lastName: 'Revazian',
        emails: ['trevazian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHZYBSvSMeeEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655541452035?e=2147483647&v=beta&t=4UO75tJU0QjwkqlL0Lr9v65wL1GJHeCAuITe2ucjOvo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  marineinstruments: {
    name: 'Marine Instruments',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gabriel Gomez',
        lastName: 'Celaya',
        emails: ['gcelaya@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Victor',
        lastName: 'Calvete',
        emails: ['vcalvete@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jorge',
        lastName: 'Perez',
        emails: ['jperez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHV--PurH9ReA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712929109362?e=2147483647&v=beta&t=HjPnUOq4h05IzfL_hng2EX55iEMoTi0GLzmCkzU0rAI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paula Soto',
        lastName: 'Carujo',
        emails: ['pcarujo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF77bMohdRdAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709727142111?e=2147483647&v=beta&t=Tl0-ew8dx0MxzaDynMcRPjm2bXtc8Zr9wNc3uCSaULk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Reedenauer',
        emails: ['mreedenauer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHYz8Qwks-fmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517676093322?e=2147483647&v=beta&t=vA5Ve3UOXrXk6VPTiBTur4fk5eySHCUIOiFiDSlPvH8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  raffel: {
    name: 'Raffel Systems LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'Stecker',
        emails: ['mstecker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFwYGm9pSmmKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650559833315?e=2147483647&v=beta&t=udOxPJNws8OVjGLWb516VxTxINEO9ehqP-HUgB_IDl4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kat',
        lastName: 'Dierksmeier',
        emails: ['kdierksmeier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGcqaJPZgg6mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690551176859?e=2147483647&v=beta&t=LUzFEJhTBMU6x_6OJx2q8mEBNQ3tBTxa6ChNEC2r81c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ken',
        lastName: 'Seidl',
        emails: ['kseidl@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Peter',
        lastName: 'Otterberg',
        emails: ['potterberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Katherine Peck, CPA, CMA,',
        lastName: 'aPHR',
        emails: ['kaphr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  doxnet: {
    name: 'Dox',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vicki',
        lastName: 'Hemmer',
        emails: ['vhemmer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHiGkhfnqd2iw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1554316752422?e=2147483647&v=beta&t=-nrSNH4lWeNlbsEla6eU3UttoiZg8GSerpXJ3QEDQhc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Patrick',
        lastName: 'Shaw',
        emails: ['pshaw@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHoS-BktGlFRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655498076418?e=2147483647&v=beta&t=I6IqiZvu07K8Qq16sOGDJ5JrLbRhrTH0p5kkwY_BYvk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jorge',
        lastName: 'Lopez',
        emails: ['jlopez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF_9x9OmDICYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706150030578?e=2147483647&v=beta&t=cE4feHd1QjawPU9CqJ6rU_o88hzEstrf6ufRN0G8wzo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Fred',
        lastName: 'Dox',
        emails: ['fdox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHD02tlkSVjwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517427725124?e=2147483647&v=beta&t=-eyxxnuQ1M2di2fW6F9W_4XuHZcDjSUrQGW4y5_tBIM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ken',
        lastName: 'Michael',
        emails: ['kmichael@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gritterfrancona: {
    name: 'Gritter Francona',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Richard',
        lastName: 'Burke',
        emails: ['rburke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG-bl1uWAFZ4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516969072004?e=2147483647&v=beta&t=duneGWnnXPukijsYFfOvkO3f8w5als-cFsjXOAdW6Fc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sebastian',
        lastName: 'Thomas',
        emails: ['sthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF7ffEFz1aioQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1526392905442?e=2147483647&v=beta&t=6WmJMwhMfTC7VB9VFD7wC_mrVX78SXKtP_v9hUPDSR4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cory',
        lastName: 'Gritter',
        emails: ['cgritter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE2TDhRvQtbVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673883424935?e=2147483647&v=beta&t=3jfvjx1H9fqA1FJJYlcRI_2lc57Pb8H77VKI6HXBOMQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nancy',
        lastName: 'Ellard',
        emails: ['nellard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFrYD46-6qq8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718313608065?e=2147483647&v=beta&t=XZ6BOe2DsH-NZAol0ujO_BVKQwL6WMzL_RaLUlBd7Js',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Don Raleigh',
        lastName: 'PMP',
        emails: ['dpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEUhh8y4FY1kQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517757841448?e=2147483647&v=beta&t=4ZR6r8a6peahXs95ObZkFacHikiN3WIb1fh-v8xg1Qs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  caltek: {
    name: 'CALTEK Solutions Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Monal',
        lastName: 'Patel',
        emails: ['mpatel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGJ77LByqd2fg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605830887527?e=2147483647&v=beta&t=tkC56-n2sb-r5XdVkWUN4Xcy0v-vZpuGMEYMbX-NM7A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shraddha',
        lastName: 'R',
        emails: ['sr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHir4CiE17hyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723062026095?e=2147483647&v=beta&t=oh8W1xc6aKz4AARa5ciO183kxfMNW65QBJm5kkm_gx0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'M',
        lastName: 'Gunasekar',
        emails: ['mgunasekar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQExByO7FNqaGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648821248111?e=2147483647&v=beta&t=9B_ZydtikqJZ3GBb24nK71nm3wYN3eCxIA8PLQn1p5w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Srilakshmi',
        lastName: 'Ponnusamy',
        emails: ['sponnusamy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF3uW3SdT-0ew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678850729738?e=2147483647&v=beta&t=zaLFBjaKYbxVYO-p9kGCeqXTP627p9XY5ck11fyNDMs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Guy',
        lastName: 'Diaz',
        emails: ['gdiaz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEvcAaUilSkaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1532487686213?e=2147483647&v=beta&t=0DmQ0Muy4O7kqpUvLMsUFXrbW3j5U0eOilaAzVABZT4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  watchcomm: {
    name: 'Watch Communications',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Daniels',
        emails: ['cdaniels@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFeKXubsG0tqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516233659191?e=2147483647&v=beta&t=rMzCPKi0WpMXkJzUjhIbJ7AJxgIBSnLIMI2tIGWQGu4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brendan',
        lastName: 'Martin',
        emails: ['bmartin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGCM8nRA3NgGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647782229207?e=2147483647&v=beta&t=HW9jYd6czM3RJLsEiNvmmLDHB5GzFRnobZa8FZ-IIJ4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Phil',
        lastName: 'Lambert',
        emails: ['plambert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGIpuBb8r3i0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1554731643773?e=2147483647&v=beta&t=JB4beG5q06puhkiaNJactB0ADK58ZRRPPws2U5i-jNs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robert',
        lastName: 'Moore',
        emails: ['rmoore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEK-NCaHzeRIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1537746940323?e=2147483647&v=beta&t=ivZd6lkjEzQa9lRjtEWb6BdgHjK8yj62z0OhnEdk81w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Angie Martin, SPHR,',
        lastName: 'SCP',
        emails: ['ascp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE885N_dG3HVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704592908666?e=2147483647&v=beta&t=eOe9Kt1tOzNi8St7Pmycqo31D1YsyUCTE1JC_v6wepY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gftpl: {
    name: 'GREAT FUTURE TECHNOLOGY PRIVATE LIMITED',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Praveen',
        lastName: 'Kumar',
        emails: ['pkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGCKVnnDx_Vrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670158126079?e=2147483647&v=beta&t=yUPd-KLe7F36pesZUW8XfwV6GSm9mrlVWX0hoZGB1bU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Proud',
        lastName: 'Wadhwa',
        emails: ['pwadhwa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEkQr3fUCgFwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726406800585?e=2147483647&v=beta&t=hIc6BDRxZWX7TvNuJVipkgld2OvLpPC40gvanB0t6hU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Annapureddy',
        lastName: 'Jyothi',
        emails: ['ajyothi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF085rcxtbctA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723115669477?e=2147483647&v=beta&t=1iu5LjjT57U-bBs5cwEW6B7Q5cG39EE77a7U4bfMOIc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vishal',
        lastName: 'Bajaj',
        emails: ['vbajaj@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aditi',
        lastName: 'Gururani',
        emails: ['agururani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHsVP2q1xg-Eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702824277896?e=2147483647&v=beta&t=-MYWeaQ28HG_EGctH10UGYjGhjY3zZ8S5_DA3mHe8Q4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  techneaux: {
    name: 'Techneaux Technology Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ben',
        lastName: 'Johnson',
        emails: ['bjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFajWaucwWPLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598621299915?e=2147483647&v=beta&t=bgPS6DO3P7oO2xng5x0HCTmFl6ddW-i7RP6IHny3Si0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Dunbar',
        emails: ['mdunbar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHulJzS67zMvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728768002389?e=2147483647&v=beta&t=oE67Oqsi7LrjYUTTRqFlo5-b3lcShW_1Bv4XHfGUuy8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tyler',
        lastName: 'Oliva',
        emails: ['toliva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFqFou1Aag62A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673301173060?e=2147483647&v=beta&t=pTVG57mjkEsVODEQCqwYNlT5i3fxLWODJYsPCDZLG0Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Elric',
        lastName: 'Holderfield',
        emails: ['eholderfield@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Austin',
        lastName: 'Cole',
        emails: ['acole@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFRU15Eh8K77A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700575849830?e=2147483647&v=beta&t=5o22HMrL_4erABuP5zukDgFKDZLnHiroZh28O694mbc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xait: {
    name: 'Xait',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Geraghty',
        emails: ['kgeraghty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHHlJfcoQuGng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517669725720?e=2147483647&v=beta&t=-52aTms3KXUBKFut_cdTM0zWpsOuR_2kgbe7LQkb3xg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paul',
        lastName: 'Towne',
        emails: ['ptowne@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amo',
        lastName: 'Chauhan',
        emails: ['achauhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQETij43x5Pq4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653646408313?e=2147483647&v=beta&t=JmGaowM-ATDT_IyT2WPCwVVD6pab7RrXYzy5lfxrH9I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mandar',
        lastName: 'Sarangdhar',
        emails: ['msarangdhar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zach',
        lastName: 'Thatcher',
        emails: ['zthatcher@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  connextllc: {
    name: 'Connext Fiber',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ryan',
        lastName: 'Slayton',
        emails: ['rslayton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGrO4YzfmNZKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703130019454?e=2147483647&v=beta&t=Xrs2yqOk3jNVNnvxcNHvOgMNSJyOZRJ4exTGQsNyLwU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Scott',
        lastName: 'Cymerman',
        emails: ['scymerman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQE0I6L3YOzdDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516287888820?e=2147483647&v=beta&t=pbgx12GwsE4LEU8tuCy0vFb7CBGVMlqOS0UksuHg5ks',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paul',
        lastName: 'Kortman',
        emails: ['pkortman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEgCHXx-hj8uA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659964032677?e=2147483647&v=beta&t=10cg4QbLzxOjJ4HTNhcvttwKLCJlyG5Gh_AAQH4L0lc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rob',
        lastName: 'FitzGerald',
        emails: ['rfitzgerald@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGnmn5rMhcSfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516245249547?e=2147483647&v=beta&t=yzce35ZU0UF5pLxApkcoLu6HySnwZbP8K9DIAACqAoc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brent',
        lastName: 'Openshaw',
        emails: ['bopenshaw@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGti_ZwSyMk5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517270577850?e=2147483647&v=beta&t=cYY39RETtSISxERxKQnzOrhUeYto1pKsQR9-fGOrsdY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jackrabbittech: {
    name: 'Jackrabbit Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tracey Chantry,',
        lastName: 'PHR',
        emails: ['tphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHrm5At19VRXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517447545295?e=2147483647&v=beta&t=rvyiDnDUEl7igZPSK1LJLxOwBLyCH2jhGaE4-4vbLhw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Mahoney',
        emails: ['mmahoney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE_4AnjGnWRuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676042343785?e=2147483647&v=beta&t=H5OVUvQu4BAsApOIioFnWDxCx09ZEyQYWqxSieZkdKQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jorine',
        lastName: 'Jones',
        emails: ['jjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGGom74P22zxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561050527878?e=2147483647&v=beta&t=CV-Im30IZWQThmYVi4TVfWCFeynSmEVbe2uVGYsplYA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Melanie',
        lastName: 'Pinter',
        emails: ['mpinter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFXWlTNCOc4cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517037257869?e=2147483647&v=beta&t=VzQW80huaTyWXVNEx3v7OFD4sFNJxxlaRFMOc4oxwVQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Independent Fashion',
        lastName: 'Retailer',
        emails: ['iretailer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEBa9kmd5nF8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516523943472?e=2147483647&v=beta&t=AOLt_aItfMVXcMMvARw1Z5PImjaOx_6XNVtQlonmPh4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  adagetech: {
    name: 'Adage Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Roy',
        lastName: 'Chomko',
        emails: ['rchomko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHCnJe27PglQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516244562544?e=2147483647&v=beta&t=YbJvEjItVD3v_VRE7X-ALPc01wyWptquuEAy71-MXl4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Leonor',
        lastName: 'Alfonso',
        emails: ['lalfonso@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEmPBmK08De9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653649856061?e=2147483647&v=beta&t=NMAJBvaB1cpglG8rWTT1GFDbm5lW0japhZhyYvRVvU4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jake',
        lastName: 'Toohey',
        emails: ['jtoohey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFYr_2Y55Rg9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517715108503?e=2147483647&v=beta&t=LRs2yp3aCAurVZAS_cPM13hKlZLdRP9TLo0jZqRR13U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrea',
        lastName: 'Chang',
        emails: ['achang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Victoria',
        lastName: 'Sullivan',
        emails: ['vsullivan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEhuFulLSqatw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516931135157?e=2147483647&v=beta&t=QPJklmtp4fS-f1E1cPap0EYRcTWVyvmBrQRnz4aA2sk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mdpc: {
    name: 'Memorial Drive Presbyterian Church',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Trevor',
        lastName: 'Harris',
        emails: ['tharris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEvMHSkIr5yxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593036172978?e=2147483647&v=beta&t=E9j564nMj5X_dSv5MJssjSMiD3cvILah27Idl42wZUI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tatum',
        lastName: 'Sunderlage',
        emails: ['tsunderlage@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFm7BF4UqlzQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676170988184?e=2147483647&v=beta&t=bd9hAqRV8MN5E_lqL6vrFChBVfUg2TWBrce1-pX0tqQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Beth',
        lastName: 'Case',
        emails: ['bcase@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mitchell',
        lastName: 'Kearney',
        emails: ['mkearney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE5-PCROp7adw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649105805033?e=2147483647&v=beta&t=5JPU61lCZV_PEVxsfT5My0F5rtl-1m9Z4y1CuccUYbo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lyndsey',
        lastName: 'Dottavio',
        emails: ['ldottavio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEN2k15hu0grg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678411901193?e=2147483647&v=beta&t=m-u1UL5RmsWlmFVqQwNq6ir1vFWqwZ5_C8Vul02nOJo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  phasedn: {
    name: 'Phased n Research, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bassem',
        lastName: 'Mahafza',
        emails: ['bmahafza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEYsqfCP5k72g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628279920273?e=2147483647&v=beta&t=-bvHLXhYzz9s2ivzPcF3SkVHc0NIs9vpA4mXhIVEqig',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carol',
        lastName: 'Holland',
        emails: ['cholland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF40fQWlEcEHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548857355031?e=2147483647&v=beta&t=3S7LqBetdxjMve6wEdAsymYxDTDSWg6rXjKbHIBZKp8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Geo Alan',
        lastName: 'Underwood',
        emails: ['gunderwood@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cindy',
        lastName: 'Golub',
        emails: ['cgolub@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE2gt59ve-NSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517532187160?e=2147483647&v=beta&t=pvmUObR8RhXKDOQ-DKVsi7tgWFm1jMF_wsPqz5cELFI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Heather',
        lastName: 'Bolton',
        emails: ['hbolton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHbspE7YUoHAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561406048945?e=2147483647&v=beta&t=wXsxITtPrk8xPSGkX-mLiv44jfDALHjg2sLbMFWT0RU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dcit: {
    name: 'DCIT, a.s.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Monika Zabadalova – Human Resources Director – DCIT, a.s.',
        lastName: '...',
        emails: ['m...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Filip Tarasjuk – DCIT, a.s. |',
        lastName: 'LinkedIn',
        emails: ['flinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE5W7yH4PWa7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1640134410964?e=2147483647&v=beta&t=-gfXny-QEdOwXIbmpllgcYHcv0cIbbpq5lNMx103uB0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Josef Vasica – CEO – Stream Circle/DCIT |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGV3d-LWTsZWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517671267607?e=2147483647&v=beta&t=EEzj8VZPepuqy9DVRljeUuKrmHqMJemrWc8x9DD2_h8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michal',
        lastName: 'Machala',
        emails: ['mmachala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHdZfhBq_LC-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686214651589?e=2147483647&v=beta&t=HSihi-wldzg60aq5_Hn-AyaQBKAgvlWhiS30VeWJp3o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jan Meloun – IT Consultant – DCIT, a.s. |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEMmnbD8qIqGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522877120466?e=2147483647&v=beta&t=Jiq464kh_HkPkA4gBlzL4SCBVooh91PqlqlnI5xrtWM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cleverconnect: {
    name: 'CleverConnect',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Veronika Eibl – Head of Global Professional Services',
        lastName: '...',
        emails: ['v...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEgc7LDYIYPow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652875322910?e=2147483647&v=beta&t=yGTt9UwENOWEpJwbFpWdKKLaW1NXNqfsTp6EoCh7quc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marko',
        lastName: 'Vujasinovic',
        emails: ['mvujasinovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHMkCGmP1igTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517353507661?e=2147483647&v=beta&t=zQNwWAMM6JRXgDWj1bLtSggUXanSVf-qRzy-3CUC3gQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anne',
        lastName: 'Perrin',
        emails: ['aperrin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE-r_kw4UruFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670795492811?e=2147483647&v=beta&t=UmuET_JzzPwgegEIEziDrsrLOfYV5TuLHDNOhqlS1Jw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carl Hoffmann – CleverConnect |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stéphane',
        lastName: 'Brunner',
        emails: ['sbrunner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEWGruG8kU9_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633112326868?e=2147483647&v=beta&t=aLUZODbxjZSuAQnsuM2s6PsLCr20uw7xY0CZGTjcOEA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vchain: {
    name: 'vchain',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Niklas Millerton – Head of Key Account Management – vChain AB',
        lastName: '...',
        emails: ['n...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGNa_qvESpQlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605013613392?e=2147483647&v=beta&t=L08C6s1p1tOprRDllYmMMuWwaBCg5y0D2P_I6p5K4hA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexander',
        lastName: 'Trofimov',
        emails: ['atrofimov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHu30_9jkCpKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517582823575?e=2147483647&v=beta&t=25xngLXtQhEjhaXWYnveW0ogMxJAvUAV44YvGvSYqDw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anastasiya Shapar – Team Lead Supply Chain – vchain AB |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFzo5tFbh8ZdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680847448650?e=2147483647&v=beta&t=AXBjjmEHN42-KzQxnXQwlIG4ZPweI8aqid4D348Hl_0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maria Petrova – Business Systems Analyst – vChain AB |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGQhHQoyEELEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646234960186?e=2147483647&v=beta&t=QDaBZpx5pSX5_4u8Uajm8mlw36vndUY7Gh74ZBSZIIQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elin Bajlum – Customer Success Manager – vChain AB |',
        lastName: 'LinkedIn',
        emails: ['elinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHrLGja-b85uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585053951110?e=2147483647&v=beta&t=T4ZhZqmzGQpKSOw5GvmRlLXQ5fIxlBy6Ka8wI6N2eaM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sanlab: {
    name: 'SANLAB',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dr. Santosh Mukherjee Semiconductor Applied Nanochips (SAN',
        lastName: 'LAB)',
        emails: ['dlab)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH8FTogP4evCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704507453899?e=2147483647&v=beta&t=WSyvJW6ql9hwgaFg-SPUXJ7CtyEPkbWab21G9fyEpUI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Salih',
        lastName: 'Kukrek',
        emails: ['skukrek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGBfWDQHExVKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516934704009?e=2147483647&v=beta&t=TZ82Mz1cIbDWVsMz3tj55tNnQwiZi_gdcD4Vv1_I7T0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Karmo',
        lastName: 'Ollema',
        emails: ['kollema@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Evren',
        lastName: 'Emre',
        emails: ['eemre@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Massimo',
        lastName: 'Bonacina',
        emails: ['mbonacina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG-uxrcgyohHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516758364625?e=2147483647&v=beta&t=MUkU4cL2Xk3LbgIrXxskIcngGZuJOh-0Wadouhn5hKs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  praxedo: {
    name: 'Praxedo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cédric',
        lastName: 'VENARD',
        emails: ['cvenard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG0vMVI8JPkFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648232238566?e=2147483647&v=beta&t=28mmE0dnGP7TYfJ89YKXjF6V1muSPbnogp1OEq7dMP8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Justine',
        lastName: 'Arscott',
        emails: ['jarscott@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Claire',
        lastName: 'Kelly',
        emails: ['ckelly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHFTxLaprIK1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691172651531?e=2147483647&v=beta&t=IQuZG0TZGame_xJq82mHXLNHsNdAEaUIKxiGttCoUKI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brian',
        lastName: 'Connolly',
        emails: ['bconnolly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH05y-qWKgIOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657199685580?e=2147483647&v=beta&t=Ddcq-BK-3_Hhfvam4qrU48Sg3ri75k-1VbWYibYJPrw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nicolas',
        lastName: 'Eparvier',
        emails: ['neparvier@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  consultrcg: {
    name: 'Ryan Consulting Group, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bill',
        lastName: 'Ryan',
        emails: ['bryan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEqKh8k-IMvWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516508952960?e=2147483647&v=beta&t=AYVR10Y42tRTdizM2F7-tCCVE6lNPVC7jd5Fvj0HdPo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gabriel',
        lastName: 'Rendon',
        emails: ['grendon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEX6PGbwWoH_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516892703044?e=2147483647&v=beta&t=vPBUUfSGqN2Gqnxo7jp2IJFVkkg7o87967FEwwKd7m4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Allison',
        lastName: 'Kramer',
        emails: ['akramer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEIxFQU8dKBMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667923765451?e=2147483647&v=beta&t=wOKD_FLQhXmzWLcVZXHt9E78HBc50d-ZGim_N8bRwFI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brendan',
        lastName: 'Ryan',
        emails: ['bryan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGihknS593hkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672948732708?e=2147483647&v=beta&t=yH3DidD-sPHCE5gjvH0uGFxW4_DiW9YAc6aAWoMDqVM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ava',
        lastName: 'Swart',
        emails: ['aswart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGMFMmZlieNUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683131745181?e=2147483647&v=beta&t=_ayq3poxew7Op84283iTr6uEYryRKqc_OsB-05KTeVc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  p2p: {
    name: 'P2P.org',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Mylnikov',
        emails: ['dmylnikov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFIqXWvkbsvQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579773753023?e=2147483647&v=beta&t=xuKqRO_QM2Ua--s5g9EvHMOhTrOVNxDKJkvnHCec6hI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Divya',
        lastName: 'Subramaniam',
        emails: ['dsubramaniam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEkTYNk75-QDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727180305991?e=2147483647&v=beta&t=YsROSj_bDY1HiisEfnqY4yq89cIkRrh50U-qanAaxVI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jonathan',
        lastName: 'Reisman',
        emails: ['jreisman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joshua',
        lastName: 'Betancourt',
        emails: ['jbetancourt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGwPjJud0rncQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650897635166?e=2147483647&v=beta&t=pN8TMgqwkPk-7s2J0tnr56xOr5L5923Gqk1FlWu1JtA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alex',
        lastName: 'Esin',
        emails: ['aesin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFXMgFxDM4eNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617190987057?e=2147483647&v=beta&t=viCIhzaQq_FBSxmY8Q1KvQlp9Ia4QTUKc-FXXk-ydOU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  profession: {
    name: 'Profession.hu',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Béla',
        lastName: 'Kummer',
        emails: ['bkummer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFdFRUKAXguTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547328199253?e=2147483647&v=beta&t=dazxfe_5yzpTY16kLFwuuTtcxqWY__RjNll4FqJv9jQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Zsófia',
        lastName: 'Petz',
        emails: ['zpetz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH7mpAQacc6wg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1565337166049?e=2147483647&v=beta&t=WlX_IzbYMd9HcAS3_bD_uRTHNcDh7U8u5vTQPN_o4qY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tamara',
        lastName: 'Üveges',
        emails: ['tüveges@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robert',
        lastName: 'Beck',
        emails: ['rbeck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF7nVFWQ8_qtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605526036231?e=2147483647&v=beta&t=xhFqxxwBWrmB8xiMzQZ4QkyVCeABkW2OK_Yel3CaI20',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mirabella',
        lastName: 'Csomor',
        emails: ['mcsomor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEMrolp3M5uag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517840172662?e=2147483647&v=beta&t=SlghZPas5N2rC2rcIkH9rYhjKDqN3ICl-rDT8w-MEUs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  collabasia: {
    name: 'Collab Asia, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eugene ▶️',
        lastName: 'Choi',
        emails: ['echoi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEJ9zm0VvmXSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516775385162?e=2147483647&v=beta&t=rXVS1t7e-cR0YpZa3XG5h95qgXJlLwP-Z4kbq7M5IBI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Raffi',
        lastName: 'K.',
        emails: ['rk.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Samantha',
        lastName: 'Tan님',
        emails: ['stan님@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHUqQEdVXYW1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551089009801?e=2147483647&v=beta&t=zYG22OhhKY7Um4_5iJAP-J8C26E5ekXbbE4yU5D7QWI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: '최유진님',
        lastName: '',
        emails: ['최@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEJ9zm0VvmXSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516775385162?e=2147483647&v=beta&t=rXVS1t7e-cR0YpZa3XG5h95qgXJlLwP-Z4kbq7M5IBI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Goeun Eunice',
        lastName: 'Kim',
        emails: ['gkim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHjEIngG_d9nQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619408016345?e=2147483647&v=beta&t=5rK71wSOON88_yvqR3VJHbhbwVvlNKNxoEstWOheonM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ravendb: {
    name: 'RavenDB',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gagarIn: {
    name: 'GAGAR>N',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul',
        lastName: 'Estes',
        emails: ['pestes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH5m11IwVOmDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720820399686?e=2147483647&v=beta&t=zOprbjNQqUgXEr96yV0PDyRCQBOKDcct9p3KIV49cNA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stuart',
        lastName: 'Burris',
        emails: ['sburris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHsbdPzw2ZeTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517434732718?e=2147483647&v=beta&t=h0LBztVndA-5oVEIlQCgjDhBnQn3vab4vihsYm2JSI0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Thomas',
        lastName: 'Graham',
        emails: ['tgraham@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Hampton',
        emails: ['jhampton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Schoenemann',
        emails: ['pschoenemann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFrSFHpRRb4jQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1528644108809?e=2147483647&v=beta&t=zeLnJQOfODaTq-A58BAdWdKxtktSa3Vce5kywPuDQyc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fulltruckalliance: {
    name: 'Full Truck Alliance',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Simon',
        lastName: 'Cai',
        emails: ['scai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQH3Hq0Q2fGuGQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517569422575?e=2147483647&v=beta&t=bejdWji4nXcjE277LRewFWK2MglnRn8zN-0_qFZGDqs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Fan',
        lastName: 'Wu',
        emails: ['fwu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGcmJayRMeLGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575532254936?e=2147483647&v=beta&t=D9EHFRtKU6Ip5ObTjH4aZv4K0l8sAB7Z6pHeoBPzXyI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Z.',
        emails: ['mz.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFSJISfVvd_uA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694532555984?e=2147483647&v=beta&t=RZ91OmBltOhl9o9wcsJfX0Tehu8wRX3cYnIWljeVGsQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Wen',
        lastName: 'Han',
        emails: ['whan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGGvep1WbJHqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718229193289?e=2147483647&v=beta&t=gYOjSkHlVFwubOQR6jodMgEF5wMuES0xTMK3Mpm5HEc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yuchi',
        lastName: 'Yang',
        emails: ['yyang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFDiusQJx-BMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561349133966?e=2147483647&v=beta&t=dc6IdTm-zHuNyyrl7oSKzF0HRnLEhdm8Na00B0rmu8A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thefloow: {
    name: 'The Floow',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aldo',
        lastName: 'Monteforte',
        emails: ['amonteforte@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Glenn',
        lastName: 'McWhinney',
        emails: ['gmcwhinney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGqwpB9nWj-8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578091767750?e=2147483647&v=beta&t=aPZ57yJ9Ye8hvYZ40oGs8l3mZPCgJOYAIq47Zz1gcvY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Phil',
        lastName: 'Venables',
        emails: ['pvenables@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE7zYx6Q-uxPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600619713449?e=2147483647&v=beta&t=61i88fhYS65aWLMiKZxzB-q-aOsFmOmOMmbC2vW_d9s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sam',
        lastName: 'Chapman',
        emails: ['schapman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJz45T8Q_Tcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516237266867?e=2147483647&v=beta&t=M9-Ts54rHMe3QZPVHkEvUyPDpnrNLpcn4YB8EWv2K3s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matthew',
        lastName: 'Chalk',
        emails: ['mchalk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEuph3Evc5afQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722602320450?e=2147483647&v=beta&t=ZlVyJbO5n71wGTccRHEznfg4uaVJHyafgSE1X956ghg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  goconnectit: {
    name: 'Sogelink Nederland',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ricardo Maldonado',
        lastName: 'Sevilla',
        emails: ['rsevilla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFKWnbd7Ehj7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701356090106?e=2147483647&v=beta&t=ch74xJiT7V2xAcKK39RGgBlqGqtxp8XIKBG24nKu6jc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stefan de',
        lastName: 'Graaf',
        emails: ['sgraaf@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEYdp-yVPTkSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516769501416?e=2147483647&v=beta&t=nagrtIlTxXDKlhaK03tk64YS-MiuFCt4oLwCGRLJ-E8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeroen de',
        lastName: 'Kwaasteniet',
        emails: ['jkwaasteniet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEKoDNJfPQOKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605101390280?e=2147483647&v=beta&t=saF_GJgG6JLIBUDPgrc82XKQu9ef89ZQb4x2xVwMjck',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jorn',
        lastName: 'Habes',
        emails: ['jhabes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF9PqPLPUypRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696923706320?e=2147483647&v=beta&t=Ttdba6MBjneKQyxkuKLnNqPZ6zXXWZEW6AUSXJH3vE4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Miguel',
        lastName: 'Tillart',
        emails: ['mtillart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGDCY-jOiX-Fg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546013932124?e=2147483647&v=beta&t=mkWiT0LqfZaQeWBQZqtbkt-RqEddWNA-lyu1Epea6Jc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  linxup: {
    name: 'Linxup',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Drew',
        lastName: 'Reynolds',
        emails: ['dreynolds@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'LaMore',
        emails: ['alamore@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Steve',
        lastName: 'Diogo',
        emails: ['sdiogo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sherri',
        lastName: 'Schultz',
        emails: ['sschultz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Inman',
        emails: ['pinman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sescorporation: {
    name: 'SES Corporation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Suketu',
        lastName: 'Sonecha',
        emails: ['ssonecha@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ethan',
        lastName: 'Rowe',
        emails: ['erowe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHWgn8MAsVwTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517678070048?e=2147483647&v=beta&t=ZyT42iluUmdQ-XU0wHrXLWy_pDjTTuHzs78JPxqtiTc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kang Xu',
        lastName: '(许康)',
        emails: ['k(许康)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQELeeJ5cekCzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517694491184?e=2147483647&v=beta&t=6bAbGygmVWSrTZODh9_brVPEr2BdxLDe7Tv_6-QG4fM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Camille',
        lastName: 'Bryant',
        emails: ['cbryant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE7kLc-9lxypQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635450391274?e=2147483647&v=beta&t=LrFnPvqGB6z-AxVnTVfshDktFMldAQ162JNR2MClDyw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Qichao',
        lastName: 'Hu',
        emails: ['qhu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGBsVF2ACGuzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701258427074?e=2147483647&v=beta&t=2dOprlm7akj4dZTUCDZg6JbxKkrLu7MmnU1MB5GqA6w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ntara: {
    name: 'Ntara',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Samara',
        lastName: 'Bolling',
        emails: ['sbolling@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGsrKnjGmQSCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722434134043?e=2147483647&v=beta&t=V0KyTHeKdMaBwcNvAsbVWMWEqaoh5rITbkHIfND-qMA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eva',
        lastName: 'Ntara',
        emails: ['entara@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Neil',
        lastName: 'Owen',
        emails: ['nowen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andy',
        lastName: 'Didyk',
        emails: ['adidyk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHYn8pm4pSD9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724161989900?e=2147483647&v=beta&t=Nv9x8gbhOBrlG4GRHhXvJIDi4iBklIhSyn2Y3rg04Qw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shane',
        lastName: 'McCown',
        emails: ['smccown@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  embriq: {
    name: 'Embriq',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Scott',
        lastName: 'Kerrison',
        emails: ['skerrison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFOyEo8_xb-Fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597001492728?e=2147483647&v=beta&t=JK3PP-rBINUHBttxYZY702DLkuRYVQsLkBcHZ9db-K0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeanette Johnsen',
        lastName: 'Bratli',
        emails: ['jbratli@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tom',
        lastName: 'Peterson',
        emails: ['tpeterson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ketil',
        lastName: 'Aasen',
        emails: ['kaasen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGoSY92EvMkLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516243296630?e=2147483647&v=beta&t=p2xeYKC3LdxQ4dEqQTQauTIxn3sBDgjNBJnvAL49Aq8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Thomas Lilleng',
        lastName: 'Gundersen',
        emails: ['tgundersen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGeYyEuhmkH1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536323324600?e=2147483647&v=beta&t=iaTJ5xf8KLPEFe1SYeHhAbOw5s9iZkhGMDfmvBKvNak',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  widepoint: {
    name: 'WidePoint Corporation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'George',
        emails: ['rgeorge@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jin',
        lastName: 'Kang',
        emails: ['jkang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGRIbaQa1xG_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559328476240?e=2147483647&v=beta&t=uU7D-TEoTnGmTURfwEYF70dnxVCgTU2LSav6c3e1qjQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Randy',
        lastName: 'Franco',
        emails: ['rfranco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGZh2L7Q50iGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639596628524?e=2147483647&v=beta&t=imTOdK6PpwU9tn61bXY6vUFLIs3CiXBCj6evuvSAs6U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lance',
        lastName: 'Buerke',
        emails: ['lbuerke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEcgm2tV2_8xQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581685085161?e=2147483647&v=beta&t=HjYYU05oFLR0G-SJAS8eliybJ6RvPf3z-uFrtbbrM0E',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jessica',
        lastName: 'Blanco',
        emails: ['jblanco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFXvyV3i_zcMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516901587282?e=2147483647&v=beta&t=eUhBmXi96r3bndZoTurdIeVSuugoFhMPJIEnEYsPHew',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eagle: {
    name: 'Eagle Dynamics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marvin',
        lastName: 'Brown',
        emails: ['mbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFSShMS24vmDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517572861945?e=2147483647&v=beta&t=VJXac2ETzXZrbvC-4DcvNQiHdMXG4-o57BLJ9bcr-TU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matthew',
        lastName: 'Cawley',
        emails: ['mcawley@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daniel',
        lastName: 'Goolsby',
        emails: ['dgoolsby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEd0wPF3UwSaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670622574592?e=2147483647&v=beta&t=gNpwwKl1_8rz9_qE2zs68iZ0d3vKb2uDSDXS4wfiSiA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Trip',
        lastName: 'Brown',
        emails: ['tbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHPLRQhWCTAcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516481110715?e=2147483647&v=beta&t=CnQ4F_buBLj-PwkmHEfj_nGFXQAf452ZGHgfePg5tsI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Glen',
        lastName: 'Trahan',
        emails: ['gtrahan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  quorumdms: {
    name: 'Quorum Information Technologies Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Yvette',
        lastName: 'Martin',
        emails: ['ymartin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFi8G7eysA3rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517601922011?e=2147483647&v=beta&t=5Njd0nQSXpRTeF5GUpksa2q0z2XfdJe1np0ouFN4SMo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maury',
        lastName: 'Marks',
        emails: ['mmarks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFxkkV61xOdag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517706123467?e=2147483647&v=beta&t=369npEHmDly4_D0DOeZ_RgBiiOxm54OMJZznKe5P4Bc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Darren',
        lastName: 'Hawthorne',
        emails: ['dhawthorne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHL3ztBbw8vAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517674668357?e=2147483647&v=beta&t=QDKEDexCsVtcJOJNp0WrC208Vo8T1s0g09b6SzBk4oE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brittany',
        lastName: 'Parsons',
        emails: ['bparsons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGnn95RMDPbsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705933910590?e=2147483647&v=beta&t=gJD4wBzTLIYSWyWrKBRwUjxnh7Tpl0f6bN23M39FyPs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dwayne',
        lastName: 'White',
        emails: ['dwhite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF4jZ8xjWIzNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516571368188?e=2147483647&v=beta&t=EEn5SfY3dZSmXzhnH7W3SUCehOVIiv6AP0TPqYj33RQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  osacommerce: {
    name: 'Osa Commerce',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Padmanabhan',
        lastName: 'Raman',
        emails: ['praman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Yifat',
        lastName: 'Baror',
        emails: ['ybaror@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kavya',
        lastName: 'Bhavaraju',
        emails: ['kbhavaraju@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGQvY3Bbv1Bvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592759190150?e=2147483647&v=beta&t=DO3Tu43Arcb6E4au_D1uEQeKQRn-qZYz1_MM9JfMDk8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chelsea',
        lastName: 'Mori',
        emails: ['cmori@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGouY6i7ki_-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680212328485?e=2147483647&v=beta&t=CFSeefzEkm_q6K5zEuVhxqBTxuIe0bIpfFZiiPoQ-jI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aaron',
        lastName: 'Waters',
        emails: ['awaters@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  markleygroup: {
    name: 'Markley',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeffrey',
        lastName: 'Markley',
        emails: ['jmarkley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEE0GT2GngQ0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517757378106?e=2147483647&v=beta&t=ukhPcxTQtOCfb1jmnYYdBL78eX6c-QeM2ePrmVMK8pk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Sullivan',
        emails: ['msullivan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEP51MtWqyC7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700597200673?e=2147483647&v=beta&t=hfUlr5_TXy4IW7J1olaGIneqdiKOzloCS9FFdNSuzZs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jack',
        lastName: 'Montanaro',
        emails: ['jmontanaro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFQyv0mRrmayw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516485550336?e=2147483647&v=beta&t=xAWk2QboO8pIqqHDoE-iVl-EI1G6RZb8GSLlWxJlh68',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marlon',
        lastName: 'Pearson',
        emails: ['mpearson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFoQPEka5gEdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516927905570?e=2147483647&v=beta&t=ZXsFP2QjwXOvrTw4TcY2p-tvnSsLfFvtDfYp-_YGDyo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeffrey',
        lastName: 'Flanagan',
        emails: ['jflanagan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHHrVdA3PO-Mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517603707538?e=2147483647&v=beta&t=8FQsw5Y0RQXo2hR6sYccqCQJsG0sHRB0O_7F94SVKdI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pns: {
    name: 'PNS, a.s.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  atompoint: {
    name: 'Atompoint',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Loucas',
        lastName: 'Louca',
        emails: ['llouca@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniyal',
        lastName: 'H.',
        emails: ['dh.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFgmf6ChkC_gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718318921734?e=2147483647&v=beta&t=ebUXUSbBuuwE7ByRjHdcZ5Le7iz-XLwd4jOStIW-w3Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'M Khizar',
        lastName: 'Naeem',
        emails: ['mnaeem@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFc9PVBOFWnaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710488857387?e=2147483647&v=beta&t=nHielZlOb9lEwdUuOlQcKvZ81Qo8S-Ta-ap2xZJQWL0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Muneeb',
        lastName: 'Khatri',
        emails: ['mkhatri@example.com'],
        image:
          'https://media.licdn.com/dms/image/C5603AQFf0y2VlFKqHQ/profile-displayphoto-shrink_200_200/0/1516927479328?e=2147483647&v=beta&t=nrIRdUoDigCW4Gckbm08HT3GTtFf4Kmw4peeqbEnLbI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ahmed',
        lastName: 'Zarrar',
        emails: ['azarrar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGDbM-pgL-YBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609758335102?e=2147483647&v=beta&t=UmtCr8hSS1Icd60N_mAVOxVTrglLeEB9gr-en9AnUww',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ardes: {
    name: 'Ardes.bg',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Plamen',
        lastName: 'Banov',
        emails: ['pbanov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFBu1Mubg_gzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721743000403?e=2147483647&v=beta&t=bvMvJ9TzVvNDYoJvjHhDGiNSytYUmXcV_zdixOAneTU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lydia',
        lastName: 'Dimitrova',
        emails: ['ldimitrova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEguEeS-hYv-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728118692484?e=2147483647&v=beta&t=rZeeyDl7B_kwONAX_BHjn3QyIzQ5K1ydPDT4LPuUQFM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Martin',
        lastName: 'Velikov',
        emails: ['mvelikov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGCzTNGkLL9XA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560857010826?e=2147483647&v=beta&t=Ci3puSwH6RRikKvucnA05CgF11kp8eplGcgJJ1iI4qo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nikola',
        lastName: 'Andreev',
        emails: ['nandreev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEA0_aZ1GUsEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661348451363?e=2147483647&v=beta&t=zVtTihKY_G-a7WJSJxzp9UVyEIqVBLSy38mDuNYEQ1M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tihomir',
        lastName: 'Traykov',
        emails: ['ttraykov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nextecgroup: {
    name: 'NexTec Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric',
        lastName: 'Frank',
        emails: ['efrank@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH_YTJaWlT8xQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516982700968?e=2147483647&v=beta&t=xGAZhL2mNdvrqeoI40PM_2M-R4Zdi_X_Q4Sa6VQ4OSs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alan',
        lastName: 'Subel',
        emails: ['asubel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Russell',
        lastName: 'Harper',
        emails: ['rharper@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jamie Lianne',
        lastName: 'P.',
        emails: ['jp.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chuck',
        lastName: 'Oliver',
        emails: ['coliver@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  enterpriseKnowledge: {
    name: 'Enterprise Knowledge, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sara',
        lastName: 'Nash',
        emails: ['snash@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Benjamin',
        lastName: 'Cross',
        emails: ['bcross@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE8rDrEDym_tQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581962222602?e=2147483647&v=beta&t=yFA0zI0lIkAm0S4IMZ5-m76d7ZrQR4u8y2AZhtjOcdc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Irene',
        lastName: 'Chabina',
        emails: ['ichabina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG-NUt_YerGHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516259912204?e=2147483647&v=beta&t=tqvubjNvKLJZLqhmyyV6UaNVNbfPZdLWxTcFiU5FBsM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Benoit',
        lastName: 'Gaussin',
        emails: ['bgaussin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE3GjGQ3HMFeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674162253113?e=2147483647&v=beta&t=kqAKL4a43BswaG1oo0rtMeK_WAWu89zZGw3QzW0dryQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Thomas',
        lastName: 'Mitrevski',
        emails: ['tmitrevski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEvH1XMAvjtlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1530664930808?e=2147483647&v=beta&t=bXsMIfaWvtN18tgJx2SO-xG3VnG9sjztw1fB0hzKZrg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  imcva: {
    name: 'Innovative Management Concepts, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Emmanuel',
        lastName: 'Romulus',
        emails: ['eromulus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGH_cYv0XvLSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536806974490?e=2147483647&v=beta&t=f0BL704R4GID5l2cYgcAWntMmVpOlxB5N2yJpLguLPw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'DeAnna Niderost,',
        lastName: 'PMP®',
        emails: ['dpmp®@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF7Kq2_D86GXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680037647053?e=2147483647&v=beta&t=dSr1JUGTG6oq4JJzQjktMbbwQQ5Rc5J3qQhygKUIv-4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrea',
        lastName: 'Walter',
        emails: ['awalter@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michelle',
        lastName: 'Might',
        emails: ['mmight@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG8Nz6FY6Qc4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517469465148?e=2147483647&v=beta&t=FGxomQy2vcSE-Vz3vPUjSp_gSYlIYJkN-E9MNuNbmJM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ashley',
        lastName: 'Sandberg',
        emails: ['asandberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHcodZWhGsoqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601300697207?e=2147483647&v=beta&t=l5W1dudIy4sQpSym4UevhWbCorPpQWMAiRoNL2ARGVg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ndscognitivelabs: {
    name: 'NDS Cognitive Labs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rafael Perez',
        lastName: 'Coca',
        emails: ['rcoca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHtP17EY7xs-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1577740394721?e=2147483647&v=beta&t=b496-ltgEi9ebW_RB_7YSxi-8sBGCbD2a0NgpMTBCOg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tom',
        lastName: 'Arc',
        emails: ['tarc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQETt4Dhdb5ozg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709528605375?e=2147483647&v=beta&t=UNNFnBeRm_vr4-6eNSxX1hg6hyj5UZgwIgy-VrooPSw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gustavo',
        lastName: 'Parés',
        emails: ['gparés@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jesús Miguel',
        lastName: 'Arzate',
        emails: ['jarzate@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFF5SjOabBhEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699995375207?e=2147483647&v=beta&t=7RJuXYKmzZfIAayzIexAMXwemkiBsIf6uqHL-Heqlps',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jesús Miguel',
        lastName: 'Arzate',
        emails: ['jarzate@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFF5SjOabBhEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699995375207?e=2147483647&v=beta&t=7RJuXYKmzZfIAayzIexAMXwemkiBsIf6uqHL-Heqlps',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lakebusiness: {
    name: 'Lake Business Products',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jesse',
        lastName: 'Lakes',
        emails: ['jlakes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFvn6ZBAdcquQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516283780403?e=2147483647&v=beta&t=pHj3PbI-DVqdkxyjrTPUi6GNL6prcUuxKQXB5q7o76U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Greg',
        lastName: 'Lake',
        emails: ['glake@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFEnYy9GGA5vA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716414099264?e=2147483647&v=beta&t=Jw29W6J4z7sMShc5vDVIIsGZDbGMFvgMFap4NTPzu20',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Courtney',
        lastName: 'Lake',
        emails: ['clake@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQENvzwXGzsBYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597991046727?e=2147483647&v=beta&t=s7rWNdZH6uhBMR3umVesO3Xe-Fq-1FN341Uez5gSGA0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephen',
        lastName: 'Lake',
        emails: ['slake@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFAbgadgjh3Dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705087831939?e=2147483647&v=beta&t=nmgc_JsKeuQ7ozzcdXkGLsDT2c_ChmKeaY1Sw6ma9MI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jason',
        lastName: 'Lake',
        emails: ['jlake@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHxb9PYs2sI7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625751220863?e=2147483647&v=beta&t=d764ct4TurvdONwzM3W60xuBZ-QvvDy9ou4IgH-nqvo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pollsound: {
    name: 'Poll Sound',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brandon',
        lastName: 'Savage',
        emails: ['bsavage@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aaron',
        lastName: 'Kopp',
        emails: ['akopp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHgOLQpQIANpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665763878870?e=2147483647&v=beta&t=mpX_Rb8ZlGXak5tLOsmBGVHHsvVFOb2cwHRpMchqSao',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Deward',
        lastName: 'Timothy',
        emails: ['dtimothy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG8fT_xfKs2YA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518716283694?e=2147483647&v=beta&t=bqT1KxrjSDnLx-NwY5SpzUJLh7_B0MNgwl8GZmvsnQw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matthew',
        lastName: 'Kupferer',
        emails: ['mkupferer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gary',
        lastName: 'James',
        emails: ['gjames@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEP_zVZpf4kFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517344270724?e=2147483647&v=beta&t=cQOs_oTKlzftiyHPfRPCKQrR2fJPouyXz2Af2bIZRhk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  platinumsolutions: {
    name: 'Platinum Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mary',
        lastName: 'Millholland',
        emails: ['mmillholland@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Mohan',
        emails: ['mmohan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFyZktPSL1HXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712345933327?e=2147483647&v=beta&t=wbGdhMq2qKUYvahEUn9z0-cgAfWK8ISCU1trv0w88_g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Platinum',
        lastName: 'Solutions',
        emails: ['psolutions@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGR_KRsXQvG8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669933101262?e=2147483647&v=beta&t=LcMeC3zMMeqh8kpM9RmWQQ_GF0hSTk6JJn5fjrJNwLU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Phillip',
        lastName: 'Alvarado',
        emails: ['palvarado@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John Oborny,',
        lastName: 'CPC',
        emails: ['jcpc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF6y52lNb8nzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517658664881?e=2147483647&v=beta&t=JXPFx4dMfvMPSwCvhEdZg6e5NyRdGwhPHqRjGvyinPE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ronenbekerman: {
    name: 'R-One',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'K.',
        lastName: 'Miyamoto',
        emails: ['kmiyamoto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQETi4FlKuWEbA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1600824662792?e=2147483647&v=beta&t=9Q2AZT5X0AThs2FHzL2cpZK1a09l2MoDzzKS4fCpZwY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cary',
        lastName: 'Millsap',
        emails: ['cmillsap@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHadGWboZn3Eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709158505266?e=2147483647&v=beta&t=8P7l65y0Lu5xqMMNwXyz6923H9zMWzYAXSeNFUCySRg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rone',
        lastName: 'Chiu',
        emails: ['rchiu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHExBgwk3OYwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701090891852?e=2147483647&v=beta&t=TRW8jfXX995n88eQXGYmh1iYMOqNhRhAoN1tBiF46Hw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ir.',
        lastName: 'Lau',
        emails: ['ilau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHlfAOmhe9Kkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585212951825?e=2147483647&v=beta&t=aMTIGEQVH5rmH1dRKV-TVyEVWJVAYt8xp1e45lXqoLc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Gray',
        emails: ['mgray@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEnMfTnfrbATg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516927794388?e=2147483647&v=beta&t=GIcKndmlGjxW-aRRweoBU4krZUNCeC62Cim03vhsNtA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  swan: {
    name: 'SWAN',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christopher',
        lastName: 'Dee',
        emails: ['cdee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGvQ739ndD8ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516257080265?e=2147483647&v=beta&t=2TZ47XGUmfUOz8Hr43geFhp2BK6F4Ngz6pcPzACM9uk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'James',
        lastName: 'Swan',
        emails: ['jswan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGBQx0ETtvrdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625550253206?e=2147483647&v=beta&t=BMzRVVhKwvf1DNfB13zapFKrF2dchU8uwo6PBjZbsA8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Isabella Rios',
        lastName: 'Bustamante',
        emails: ['ibustamante@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGnPKvdyKOQyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668689528341?e=2147483647&v=beta&t=RnGqa1REswzQMf2KWoDNl3nfWcSmK9bMOTer2L5pXqA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hadrien',
        lastName: 'Grandgeorge',
        emails: ['hgrandgeorge@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mary Katherine',
        lastName: 'Swan',
        emails: ['mswan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFjMSR2swPJRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703170444295?e=2147483647&v=beta&t=Yb8R5vKYQ6UrwTbi4JaAMb0Ai1AZ7WhPfBq5GWLhtrc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  symatrix: {
    name: 'Symatrix, an AST Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jonathan',
        lastName: 'Snowden',
        emails: ['jsnowden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHMPRx7hqPvig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685529438262?e=2147483647&v=beta&t=WZif2jJCSLYw0O-sVbjm29kBDyjqUt68aOgfvY9JOOU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexander',
        lastName: 'Blundell',
        emails: ['ablundell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGxzIXXvW8Jmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517610000640?e=2147483647&v=beta&t=QngfE1pImjVmDQBS8P-bzT56n2SlkfbtZF0U_0bx-y0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Richard',
        lastName: 'Dutton',
        emails: ['rdutton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFfALReDH9uWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593185691913?e=2147483647&v=beta&t=ndeQJSlHYwKzRm-dI_voD0mRpYE9HWfMB2z6EX9BjA4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kelly',
        lastName: 'Blake',
        emails: ['kblake@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEppIOyh5wppA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632322463200?e=2147483647&v=beta&t=-6ZxdwuOB7E8OoaMuMa9jMOzj08qrBSFoDey6J2DIHA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Charles',
        lastName: 'Courquin',
        emails: ['ccourquin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  goftx: {
    name: 'FTx POS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Danielle',
        lastName: 'Dixon',
        emails: ['ddixon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEwQucnLKADbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617156834058?e=2147483647&v=beta&t=zovp5t76FiGV1nVLdERWWL0wV3hicSOwzRAdKVu8aPI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Szymczak',
        emails: ['mszymczak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGaRNmq93udsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683806918438?e=2147483647&v=beta&t=NGJzxPAedDSJWIYzcwKUwSCcePTUQMI7PzBmJnfBdxE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jessica',
        lastName: 'Conklin',
        emails: ['jconklin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Darius',
        lastName: 'Godley',
        emails: ['dgodley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHXsLmiYy6rCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659458044828?e=2147483647&v=beta&t=cePt6gekUJbJba_xfKK9jYTC8qBJ8JUCkDxQPWYKNL8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tony',
        lastName: 'Randall',
        emails: ['trandall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHl31CWR_MQjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672787537945?e=2147483647&v=beta&t=WIaT2gv1vFT-h67UnhWPobhb0Yp3LMHgIlTsyC_6Tqg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  surflextechnology: {
    name: 'Surflex Technology Pvt. Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Surflex',
        lastName: 'mapondo',
        emails: ['smapondo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF42pd-r-tP4w/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1729531312170?e=2147483647&v=beta&t=2cK7CnPD04yBlH1aFEUqbyPXw6jVmT6UsPHql17-wOc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Constance',
        lastName: 'Laloy',
        emails: ['claloy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHZiiHvQteinQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727861137709?e=2147483647&v=beta&t=jMfcwamJk3QPfwF9w6DtX6CBsbc_eqs9vSNj2dJInaU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sudip',
        lastName: 'Mitra',
        emails: ['smitra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG3mr9dAa339A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703146785038?e=2147483647&v=beta&t=hDbRoYgeUpITZLMyIIyI5L1OfYowB0hFMneo8fAobbo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ajay',
        lastName: 'Jain',
        emails: ['ajain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEOShb-9inZRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682972389202?e=2147483647&v=beta&t=BNoFFRU9CDH207wlX-oTc37ca9e3wPhdci4RamO5Kzc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Company',
        lastName: 'Owner',
        emails: ['cowner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFH2X2vE6Y0qA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542964150054?e=2147483647&v=beta&t=RraJmCOJxbonNGlChenI2nABqJz-W56Ufnj2JbXKETU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  renovaGroup: {
    name: 'Renova',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vincent Battaglia,',
        lastName: 'MBA',
        emails: ['vmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHQnT4J7hU3CQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665455363730?e=2147483647&v=beta&t=l_R8wAhdojR-XdsZNvdcmfr7y1maXRg3UQVypHD7TJs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hunter',
        lastName: 'Travis',
        emails: ['htravis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHyE3B2X1M-yQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557464751669?e=2147483647&v=beta&t=_PD9Aqgqbadn7dGATotUlvw4sDEMZY_kf_R_74ggTcg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erik',
        lastName: 'Halverson',
        emails: ['ehalverson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeremy',
        lastName: 'Mularski',
        emails: ['jmularski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH9HbMSkGMcmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606751475845?e=2147483647&v=beta&t=5grwSmU4f4nxPTu1flMK058mRuJgLJ7BlCVkoB7uW6g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jessica',
        lastName: 'Mularski',
        emails: ['jmularski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHFU2N67Cm1zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630938159635?e=2147483647&v=beta&t=65kkKCBZhzAhf401nGWG4-r3y7efGT-nmrSxf-xCmfI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bitWizards: {
    name: 'Bit-Wizards',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Louis Erickson J.',
        lastName: 'Jr.',
        emails: ['ljr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF7MlPXCI8cWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516363209848?e=2147483647&v=beta&t=wsazJKJZeRyCuHCpBaUrysiVXTQe-Wdqtkdq4Szfaf8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brian',
        lastName: 'Schlechter',
        emails: ['bschlechter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGTBavHXm5B4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575403634271?e=2147483647&v=beta&t=na9OTHdM8E7VA12kKEc_g15CUOxXCSpqPMg_bfLPFCY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Heather',
        lastName: 'Ruiz',
        emails: ['hruiz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEYa4HRxQe8DQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705115498083?e=2147483647&v=beta&t=rfEwZJwch_2lLT514OMkWs31lOEjtMZFMYIkxgSzMHo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Jackson',
        emails: ['jjackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGHnrUV8EBWKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516866636460?e=2147483647&v=beta&t=o1Cxx0rx796AtZ-vlb2XdyCOMfYRFoEbHWercV2kQw8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Todd',
        lastName: 'Ladner',
        emails: ['tladner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH54iFzQqQ_3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516525673576?e=2147483647&v=beta&t=cyDqtUR8P_4R2XAxh3WgkG2BwD6DJDZCfcp0QDS6cis',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  risetekglobal: {
    name: 'RISETEK',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Frank',
        lastName: 'Pinero',
        emails: ['fpinero@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHNibomsEAXaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609882101980?e=2147483647&v=beta&t=BhMZg7VqE7F5mmiPtOyWmibgT4EmRX_HnZCacjKTeV0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jada',
        lastName: 'Dukes',
        emails: ['jdukes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQELkokqgXipkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655273528055?e=2147483647&v=beta&t=EuhyuJjCIEX1TTCKZTCQ_8FnpHwGd2DNs3GkQFm57dQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Teresa',
        lastName: 'Mancini',
        emails: ['tmancini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEUciYg7HYAHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595007616412?e=2147483647&v=beta&t=xW3VbbdXWtO1lSiU4tkg6uZItTvLbFEIjFREacncuG8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Garrett',
        lastName: 'Hofeld',
        emails: ['ghofeld@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGJesmqI0TTtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649083977304?e=2147483647&v=beta&t=PrSnTl2jnBTVO_8Y8UwOm7MPfQe7R8bxJCSuvI1trHY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Venancio',
        emails: ['dvenancio@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  etechInc: {
    name: 'E-Tech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Timothy',
        lastName: 'Pessin',
        emails: ['tpessin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGtx-wroP-x5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516202476270?e=2147483647&v=beta&t=R_dGmSzW904cUcd_0BKZ3_jv-X7wgsr2jQRfBAluD5A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marcus',
        lastName: 'Billetter',
        emails: ['mbilletter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF0ZA7LrNva5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1577039140625?e=2147483647&v=beta&t=U2GbMsVRlZWq7NRmnjMgLkXAeZ5Ga6zCfHtopTxBWpQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dean',
        lastName: 'Abercrombie',
        emails: ['dabercrombie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFL9RPQo2J-JA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579532356962?e=2147483647&v=beta&t=Ysy0GW6KUK3X8pqn5NBVVnKFYthFyUxWlT53c-y4htg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eric',
        lastName: 'Rebenstorff',
        emails: ['erebenstorff@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elizabeth',
        lastName: 'Richardson',
        emails: ['erichardson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQELTLA7K0vLqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578073218559?e=2147483647&v=beta&t=LIqFayjhWQU7HGhXwtgd6YOk6pbRQC8zNI-oFQunJo8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cubeitg: {
    name: 'CUBE.ITG',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Karolina Piątkowska – Tester oprogramowania – CUBE.ITG |',
        lastName: 'LinkedIn',
        emails: ['klinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ewelina Sosnovska – Consultant – CUBE.ITG |',
        lastName: 'LinkedIn',
        emails: ['elinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Oskar',
        lastName: 'Skubij',
        emails: ['oskubij@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG2KCbFSd7gBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705579660026?e=2147483647&v=beta&t=_gozaVpk1nM2Ua_abUXmlUlhBok4aiC1iGqUAW3d8_Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dominika Baranowska – Software Tester – CUBE.ITG |',
        lastName: 'LinkedIn',
        emails: ['dlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jolanta Woźniak – Starszy konsultant – CUBE.ITG |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHFLPLR4bCOtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517275356472?e=2147483647&v=beta&t=jjW1GBRr75tYIBqH_3dLC-wlG5lEPhMdAFbGDtjTeXs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  metronomeusa: {
    name: 'Metronome',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jennifer',
        lastName: 'Virga',
        emails: ['jvirga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH529T55yt18Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679931094165?e=2147483647&v=beta&t=9lvrTwf_riv2uIcxyt6OcU9oDjQhPb9EQciTRC3QY5o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Justin W.',
        lastName: 'Miller',
        emails: ['jmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG4xDloOn16eA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676686070009?e=2147483647&v=beta&t=UY7qbFDf8uWN5aXy64JXVnbOXo3HsFKz00aDvRqUc2Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Allison',
        lastName: 'Krug',
        emails: ['akrug@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHE2y9S51lq1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608157542260?e=2147483647&v=beta&t=7DmrPatC1nD5jqiGQOfd59amcm2uoerzV6NNODSqYCk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'LeAnna',
        lastName: 'Lyons',
        emails: ['llyons@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeff A.',
        lastName: 'Rosenkranz',
        emails: ['jrosenkranz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFnmPpZEWrjcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516975201392?e=2147483647&v=beta&t=7eI6fCa2t4KdJ9ke6p2g1DirD1icNwgVLtKeUDzD03k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  garnetriver: {
    name: 'Garnet River',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ken',
        lastName: 'Hausam',
        emails: ['khausam@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steve',
        lastName: 'Richards',
        emails: ['srichards@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGLTLckS9TWaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727295710053?e=2147483647&v=beta&t=zQYOnnNTDO-xT2_3vEiAZp1ruhzfh0OxzXAEcJWAFbE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nicole',
        lastName: 'Campbell',
        emails: ['ncampbell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carrie Smith,',
        lastName: 'PHR',
        emails: ['cphr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael Weisberg,',
        lastName: 'CISSP',
        emails: ['mcissp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHUEPAmFNRnNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676036407567?e=2147483647&v=beta&t=_jRI0CdWh-sAvRHZxp4OG3TuPyIE6BHXtAzzurQNjWo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vintory: {
    name: 'Vintory',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Katie',
        lastName: 'Bien',
        emails: ['kbien@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGnLV0Lp2iTIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705538717147?e=2147483647&v=beta&t=pABc8XXpsI8qyMnxnyxljAUNq0MbgvO-XXglBmAksoo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jade',
        lastName: 'Wolff',
        emails: ['jwolff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGscgHAjxkZew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704430118464?e=2147483647&v=beta&t=aODJYYJotkVN3TwOh0k5HIzASp-DvubfvsPxs8hw0aM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vintory',
        lastName: 'Moore',
        emails: ['vmoore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFdbV-ONuTVhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705589245060?e=2147483647&v=beta&t=-Xvm16Z68QJd7l5LVErbAftUsugd3IWcskdvOOb4cTE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brian',
        lastName: 'Riggs',
        emails: ['briggs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE2WQjeWklLWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595443817205?e=2147483647&v=beta&t=826tbJuu2kBPZ09W171Gp65hdAcpNc3dPe3IIIZIjJY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'JJ',
        lastName: 'King',
        emails: ['jking@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGjvhHrygt5og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715720126701?e=2147483647&v=beta&t=-mIK9V6PD5ZA-Xam5q5vPc0AvLbGOjDJ2rliWxjUfJA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  infSystems: {
    name: 'Infinite Investment Systems Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ali',
        lastName: 'Zahedi',
        emails: ['azahedi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGaq9WQfwQY2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639164282234?e=2147483647&v=beta&t=3IozvrnU6uZPASl8aLt9YjfnBPgM3hUobDy6fG_sD3U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hossein',
        lastName: 'Riazi',
        emails: ['hriazi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHkn9zLy6nMDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662554032180?e=2147483647&v=beta&t=xSMkJBbdG9WZp75gmh3vESmfBYM3K7OEMWGL-uw8cxA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ryan',
        lastName: 'Law',
        emails: ['rlaw@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHNjPMPrANinA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662485107647?e=2147483647&v=beta&t=_7V8nW4SX1risSdZ1s-t7Kt0_KIcKErHEFKo9nHVWXg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joseph',
        lastName: 'Ariganello',
        emails: ['jariganello@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFXhkqdVS8Oyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707835873006?e=2147483647&v=beta&t=0WIvFLrRjKFCms90NZvwZUPpWFff7xLoqldDtjtW_y0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adam Beach,',
        lastName: 'CFA',
        emails: ['acfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHBzZMLffdO9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720094630581?e=2147483647&v=beta&t=6VFiR799ALcAFw7c7WmT9_c4fsA9CwtUcvbOGbTpLKY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  accurosolutions: {
    name: 'accūrō Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lisa',
        lastName: 'Gran',
        emails: ['lgran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFc91pN1RHwHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568297841310?e=2147483647&v=beta&t=eI7K8BjldN4ZERSuyQOwbirC0p08mBhVpbpA5HSL-Cw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kenneth Hannigan,',
        lastName: 'DBA',
        emails: ['kdba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFQeMaYs2CUJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707002514651?e=2147483647&v=beta&t=gwjYTaWpoVbyJAzIsKLls0IS_UGNtfSYwImqtTCbr80',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Quinn',
        lastName: 'Boyle',
        emails: ['qboyle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEmIoJkJ3o_Pw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579904173106?e=2147483647&v=beta&t=itzhs8nKCk_1ZhvfcPXku6vFQt7TUgq6FFMGO3dTtdY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Feeney',
        emails: ['cfeeney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tom',
        lastName: 'Ryder',
        emails: ['tryder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE3-ylkJ0jgmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553705081180?e=2147483647&v=beta&t=qdrGS_l5P3ZGAguBUte9M6L41XZUeolm-SGaTgraGbY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  staffordservices: {
    name: 'Stafford Consulting Company, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stephanie',
        lastName: 'Grimes',
        emails: ['sgrimes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEp4sMUm9HKxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646173189543?e=2147483647&v=beta&t=OCZnY_ENcpHoej6Cu1f2fEgKPXQ6JPnOSe10jFPtd5Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dick',
        lastName: 'Richards',
        emails: ['drichards@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGco_7CjCaDPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516853223369?e=2147483647&v=beta&t=JVeZFmgz2GHZ7CdUXwz-_Q4ZQH332Myl8YAzjHqgt74',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jackie',
        lastName: 'Barnett',
        emails: ['jbarnett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGnDo3I0sRBrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690325974316?e=2147483647&v=beta&t=dyQvjmU_gsKnO-bguiJ__X6gFml2U4uG3boNUR-kXbM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Erica',
        lastName: 'Heath',
        emails: ['eheath@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFwYcveXYuvvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707679495644?e=2147483647&v=beta&t=TaOQ8rs02tIQIn5AmN9UlDyf7aN9EICzoRe1tsQncmY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ken',
        lastName: 'Whelchel',
        emails: ['kwhelchel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEkGnrP9wCKsg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644018656178?e=2147483647&v=beta&t=kO9-965KFJ8_LEBdJiXvpnNVvGHC30kYADmhOFame4k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  novusnow: {
    name: 'Novus Entertainment Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Norio',
        lastName: 'Sakuta',
        emails: ['nsakuta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGO6Rjjrs2nOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517434891143?e=2147483647&v=beta&t=Q-bsdLTEiAT1II1jgEL2psuiNwZWwh_d_9FZF1RgC-k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alfred',
        lastName: 'Kruger',
        emails: ['akruger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGa4G2z29fWdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696613718993?e=2147483647&v=beta&t=c_EApP5ZaQV3jdSauW1hIFLl0LdNDcJhS2eUYbee4fw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Roddy',
        lastName: 'Ouano',
        emails: ['rouano@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ron',
        lastName: 'Sherwood',
        emails: ['rsherwood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGLEY58GXw1gQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516306093147?e=2147483647&v=beta&t=V9Xk-qpan7DmiFrCBImGo1RS0Ptlg8Rkx2j-d4CEnAc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Navid',
        lastName: 'Soofi',
        emails: ['nsoofi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEdh2kspBF_ZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1537205369975?e=2147483647&v=beta&t=-kSLWTTfk5QbugGE90NGU3LppDRtsddBYXcj9LXRQsg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  availtec: {
    name: 'Avail Technologies, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Glen Parrillo,',
        lastName: 'CPA',
        emails: ['gcpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG3R4Nngfyheg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597002241340?e=2147483647&v=beta&t=19zpdnxiwLqECIrCIJYFnPbcHPrL8SPZFXMf7keVLAQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dorsey',
        lastName: 'Houtz',
        emails: ['dhoutz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGtCOsjXJQnmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517406625938?e=2147483647&v=beta&t=Gde4joHoAh3lq2D2JWLE6QFPSRK-viE-FBpZeEt5Ni4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rick',
        lastName: 'Spangler',
        emails: ['rspangler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE0tVX52LH4Dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622671922692?e=2147483647&v=beta&t=S5IgoJbJwBZpNp2m59UjW7-xaPRyLpj0Rwg4YJ4T9VE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jason',
        lastName: 'Hollick',
        emails: ['jhollick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGr8DMj5xNBNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517574333381?e=2147483647&v=beta&t=1CSgP9K6fCvF-TVp7LxBY9xbetyG7K_ezIXf4yMmPYU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kevin',
        lastName: 'McKay',
        emails: ['kmckay@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sprint: {
    name: 'Sprint S.A.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robbie',
        lastName: 'Kermis',
        emails: ['rkermis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHloK3SKgktvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610019304499?e=2147483647&v=beta&t=tH0A9jhHYyfIilr08BcuKow21v-muevzxP9cGGzMrn0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paweł Szostek – Sprint S.A. |',
        lastName: 'LinkedIn',
        emails: ['plinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Olga Zawadzka – Specjalista ds. marketingu – Sprint S.A. |',
        lastName: 'LinkedIn',
        emails: ['olinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEvykDDUOKM9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718284402711?e=2147483647&v=beta&t=LckeSOXer_Bf14uKpg359H5ncg1Zakbk81-gE4nsP6Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paweł Paruszewski – Serwisant – Sprint S.A. |',
        lastName: 'LinkedIn',
        emails: ['plinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGWL88_YP9yIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718553771218?e=2147483647&v=beta&t=cXtY4Z0bhcpPXKloS1n0-yEMmNt-7xEYjAgJ685xY5E',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anna Stodulska – Asystent Help Desk – Sprint S.A. |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHsrNmiId1eCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694093435229?e=2147483647&v=beta&t=Qk-rhMp3ZycjJ8UqY8Zsa74CysQi1wXyZ6tYwYMDE6Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  seachange: {
    name: 'SeaChange Polska',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'Szynkowski',
        emails: ['mszynkowski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHMPZnOK8UWFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517476858904?e=2147483647&v=beta&t=P87PyWenDJm3dmeP-1rryT1MEvKa0_3G5sCiEsxc9RU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paul',
        lastName: 'Cammuso',
        emails: ['pcammuso@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Peter D.',
        lastName: 'Aquino',
        emails: ['paquino@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Conor',
        lastName: 'Draleau',
        emails: ['cdraleau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH73ENBFk3DjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712103374579?e=2147483647&v=beta&t=sCJMPZxh0Xx_zIrwIUCwKp5UKCeKJQh2DD-iuW8DVio',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Nicol',
        emails: ['dnicol@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFzSyGAwUj5LA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522792641750?e=2147483647&v=beta&t=Rqoreddtmk2d-OwLM7Zf5hqGbwKJZu2sRY_S3NLz9PE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lighthousegames: {
    name: 'Lighthouse Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gavin',
        lastName: 'Raeburn',
        emails: ['graeburn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFiALJAY9pbnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680706151672?e=2147483647&v=beta&t=TYQ0XZiAGCE67u8OMj9GudX7L2YqV4dMNnAatWXWblw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lydia',
        lastName: 'Beharrell',
        emails: ['lbeharrell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQExepXCmKJ6Bw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681991568843?e=2147483647&v=beta&t=1jQ9APL8zDEga_D7yQE85Sogs1OYrE4t9uNXIPUDNbs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Annie Tessier',
        lastName: 'Clare',
        emails: ['aclare@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFbUrXcCdFhag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679921911220?e=2147483647&v=beta&t=apYliYX3_LNZ6-emi7ph29kLMtET0pP7zA8_Xf1xWiI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Peter',
        lastName: 'Ridgway',
        emails: ['pridgway@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE-cJrfzgUKOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679924390685?e=2147483647&v=beta&t=AlMGPubaK47d12DknvSEOGAvw0o-vKLmsrDfRF4Veb4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nick',
        lastName: 'Craig',
        emails: ['ncraig@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  impactgroupmn: {
    name: 'Impact Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Will',
        lastName: 'Craig',
        emails: ['wcraig@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sara Gunn,',
        lastName: 'MA',
        emails: ['sma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEtess_4k8NcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707175978264?e=2147483647&v=beta&t=62XlSQl_OfQjdgJ9PGFZKvat8u1sLh2vENhEoozTMGU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Roni',
        lastName: 'Chambers',
        emails: ['rchambers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHtBzZosyMlvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711731571377?e=2147483647&v=beta&t=kD0pPYeYE-FEun6xaWdpfqw4l0n-5XNuVwW_MINI5jU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joe',
        lastName: 'Golemo',
        emails: ['jgolemo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEPa_GZu3ls5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692825272289?e=2147483647&v=beta&t=nCreiQO50vOMmghPw7deG9815MLAbvQe8ZRMR24SpH0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Russ',
        lastName: 'Green',
        emails: ['rgreen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  stmicro: {
    name: 'Strategic Micro Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ted',
        lastName: 'P.',
        emails: ['tp.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHkMQFqOsR7oQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560221717213?e=2147483647&v=beta&t=b641gPLJEilg_tXGumwNpm1T19K9EQnzmHnkVD4zNJk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Victor J.',
        lastName: 'Saccomano',
        emails: ['vsaccomano@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachel',
        lastName: 'Perez',
        emails: ['rperez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH7L9IUcOGWeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691769085860?e=2147483647&v=beta&t=Z3xRcgYD4GuNI2lnAh5HP9f-y-hkjztM5QbVPZ8EjOA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Frank',
        lastName: 'Pollara',
        emails: ['fpollara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGZgGQHISaEZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596200256516?e=2147483647&v=beta&t=oH1TSzkAferXs5ONu-puGkdT48BlUIoq2OpGC07hZQI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jillian',
        lastName: 'Boyce',
        emails: ['jboyce@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFL5UhOaB5ZVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568302649499?e=2147483647&v=beta&t=uHEteFyJMJ0pXaZx9JN6Xh85g5p9HGzFIHpFaFGQsbw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  optisolutions: {
    name: 'OptiSolutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alan',
        lastName: 'Weeks',
        emails: ['aweeks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFyMIgtTmK4nQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679075491402?e=2147483647&v=beta&t=upBfwO597tdv23KToSXDkBQSvTMADT6VwFzo-Y88Fjg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Blake',
        lastName: 'Benford',
        emails: ['bbenford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG0znYNxuehuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557246713259?e=2147483647&v=beta&t=FDXwtVLiUvC6dLaTyV8Wcdkw1a0am1uKPfS_ZuoTl8o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rob',
        lastName: 'Thompson',
        emails: ['rthompson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE7ksqvctvwTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601347839481?e=2147483647&v=beta&t=rjGZyKCRECxC2yRmxqXAuVt5xTXExluwXB7NQ3aY0Ls',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kyle',
        lastName: 'Julies',
        emails: ['kjulies@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dean',
        lastName: 'Adams',
        emails: ['dadams@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  crewhu: {
    name: 'Crewhu',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stephen',
        lastName: 'Spiegel',
        emails: ['sspiegel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHks2j1CxObNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593466335983?e=2147483647&v=beta&t=OJ5TmzsxNsZQH_fhkymFK29jVrNYe0etcI7ZJIKQOGA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ryan',
        lastName: 'Denning',
        emails: ['rdenning@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFwqrIDuMq2Cw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715368343579?e=2147483647&v=beta&t=KBs3pGr9nkzDNMfNRu1EUcMChZBGsdMMk2S-IJ5Qe9A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brock',
        lastName: 'Randerson',
        emails: ['branderson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jorge',
        lastName: 'Alvarez',
        emails: ['jalvarez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGw2iFLNRti2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681480097862?e=2147483647&v=beta&t=pYM-XVh-R9q5A1qIKDimqFjhsytPOXXf1bjcb5AUNWA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Samuel Carmant,',
        lastName: 'MBA',
        emails: ['smba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhbZwwq2DwHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645157049669?e=2147483647&v=beta&t=wYctCD9GI_Hd2d5hIChPFVpUyfXITZ3QDnrn94Lcgz8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cloudlion: {
    name: 'Cloud Lion',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Fabrice ☁️',
        lastName: 'Vanegas',
        emails: ['fvanegas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFQGLikNW-oHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669925714142?e=2147483647&v=beta&t=nXSKu6NG7Wmj66OYgpwW7hJAwLKwQQTchnvY0tcaYsg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Krishna Venkatasubramanian,',
        lastName: 'MBA',
        emails: ['kmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE8mVpQv9a5ng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713456390994?e=2147483647&v=beta&t=wX0l3_NCzJHspAZVqOO4zWZSiF6gMl9osje_nc6H28c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Phillip',
        lastName: 'Campbell',
        emails: ['pcampbell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGzStcAZdX08g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728802460114?e=2147483647&v=beta&t=V6CRXm0oDOWeDmbPJYGL3QJF0C1DL46ryJpnSa-Yrso',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shree',
        lastName: 'Sampath',
        emails: ['ssampath@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQES6fkEqw0jAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642999744565?e=2147483647&v=beta&t=MYTqInafw3DODDDHMPpjJuse8Dp4Z08tEiEVTYQALZ4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ahaan',
        lastName: 'Brahmwar',
        emails: ['abrahmwar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFicVyYl0CpGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656054619971?e=2147483647&v=beta&t=Isn8PiWpgnmEzZnw9_dq0uEBH6G6Lm5UKKAqhPvYAsk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  element78: {
    name: 'Element78',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Manik',
        lastName: 'Damle',
        emails: ['mdamle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHI9s1etHHrGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699087369420?e=2147483647&v=beta&t=EOwsjWKVrDT4SowBTcQNb27AypNgrUZ1-EVpLquzPSk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Glynn',
        lastName: 'Smith',
        emails: ['gsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG9RtC08g6tTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519385022404?e=2147483647&v=beta&t=yypz0977TqzOMJeS42fM0Nv08_F7O-_25IQp6dkCtKw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marija',
        lastName: 'Staneviciute',
        emails: ['mstaneviciute@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH0NARox5johw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648505071997?e=2147483647&v=beta&t=AWj8kVYFHdBvp1N13rftNNZBTgNKbmWKcL6t9492S8k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vicky',
        lastName: 'Russell',
        emails: ['vrussell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFZpAj3tnnA2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516320269688?e=2147483647&v=beta&t=_kFGjUp0_rCeV9y2HUMSCP8t4vVOmYVjMkFkipUuMs0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matt',
        lastName: 'Trowbridge',
        emails: ['mtrowbridge@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  inetlte: {
    name: 'Infrastructure Networks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kyle',
        lastName: 'Barnes',
        emails: ['kbarnes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHts8oPTomTMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679663368968?e=2147483647&v=beta&t=u1kCz9AcAo0ZwnI03yoNHBNHzK2q5b4HND23JBld4gY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mike',
        lastName: 'Krenn',
        emails: ['mkrenn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGXsG-5W9VS5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706764913414?e=2147483647&v=beta&t=EgpOOSDt11a3HdOh5730w5Mc_gI_NYSLj_Qt6E5EPc0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stanley',
        lastName: 'Hughey',
        emails: ['shughey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anant',
        lastName: 'Patil',
        emails: ['apatil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEGqr-EnlQYiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625318786972?e=2147483647&v=beta&t=ywEyU4oZRvqIk8giho8jGoi86BnD-qGsI_-QJq6EQJQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Benjamin',
        lastName: 'Fuoss',
        emails: ['bfuoss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEyPpcoVFnGeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516779462851?e=2147483647&v=beta&t=ZQa1tm3SGu1Ju9PJQi-BRTXrjxdTCO_DWFEaM1OtkRM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sestek: {
    name: 'SESTEK',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Barthelemy',
        emails: ['dbarthelemy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG2UVljxWBI6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681170845553?e=2147483647&v=beta&t=cqABoSeFPCADNKcjb73ZVxit2uLKlB6xveOFQqppd0w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Yalım',
        lastName: 'Eristiren',
        emails: ['yeristiren@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'BARAN',
        lastName: 'BURSALIGIL',
        emails: ['bbursaligil@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'G.',
        emails: ['jg.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Valerie',
        lastName: 'Daniel',
        emails: ['vdaniel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHtpEHYfCEJyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1524753028902?e=2147483647&v=beta&t=HET3sNu8QQhNYdDh0o_-CiFJXqGNFqaJOHq-BYQ-pdc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  securitycode: {
    name: 'Security Code',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pieter',
        lastName: 'Danhieux',
        emails: ['pdanhieux@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGTjLmkdbbLWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610941992152?e=2147483647&v=beta&t=Adm754x3dAaHxXWzOfeDM9D9rGzXTM1W4jariNBABcE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Liz',
        lastName: 'L.',
        emails: ['ll.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHYddT3_zXD-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687163344503?e=2147483647&v=beta&t=qbGAVn7FBqcoCI-8s76dI5Jw0ZUvsrPUDDz5P73lWd8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daniel',
        lastName: 'G.',
        emails: ['dg.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'James Oliver',
        lastName: 'CTSP',
        emails: ['jctsp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vivian',
        lastName: 'Chau',
        emails: ['vchau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFW0vycE3E3Lw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516551616481?e=2147483647&v=beta&t=nAFKjM7FhlKCAenSTJNGFNZoTs9kTMkGDoinK6OEaDc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thinkresearch: {
    name: 'Think Research',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Saurabh',
        lastName: 'Mukhi',
        emails: ['smukhi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH--sHUP7VdVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517739878050?e=2147483647&v=beta&t=tymj1WULFSJE5AEWDGjeqhTOVx2a1MpANolwHWUgPGo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jacqueline Nestico,',
        lastName: 'MSc',
        emails: ['jmsc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGzMXglPRNIqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687921667441?e=2147483647&v=beta&t=BoiSyUNJzZhoUHVfAN_AabNdbUksfPaPZ4bZ_aWWOkA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Conor',
        lastName: 'Mullan',
        emails: ['cmullan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGMoMxwhR49rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550094587458?e=2147483647&v=beta&t=-ZvN8HUEIAsG_7wxkF0xdvUdBAhHvDFWkgWdJs4--Ss',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brynne',
        lastName: 'Eaton',
        emails: ['beaton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEJ8z_tzvmuGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517514634476?e=2147483647&v=beta&t=5FZBVZmQsfa_uH-0DqwHVaN6agMxswXeUIxj9A4kP7k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Krystie',
        lastName: 'Park',
        emails: ['kpark@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  teknicor: {
    name: 'Teknicor Corporation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ron',
        lastName: 'Miles',
        emails: ['rmiles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHJGsvXOAObLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699540760257?e=2147483647&v=beta&t=RV0HOa5g5b0siq3_N24XWY7iEa8QBOWideN0t7LoUNQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alan',
        lastName: 'Fullerton',
        emails: ['afullerton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jason',
        lastName: 'Godliman',
        emails: ['jgodliman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFpBOE1RyZ98A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517569995169?e=2147483647&v=beta&t=TeP_LKe_H6LiXBaU2wLd4682Xs1VUZGK7GsrQFdyhjQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ryan Tibbetts,',
        lastName: 'MBA',
        emails: ['rmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGgbSOp5MUxRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603811242424?e=2147483647&v=beta&t=Dtrhe8f0kgr-GF5Ow6KOX57BrZs_auFdOTQy5e5W9U4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Mohen',
        emails: ['cmohen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEPxRvH91L9uQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517654172083?e=2147483647&v=beta&t=aeYP7qodQiT7t2OjQWR2ZAV53OsTmIuchdKIxfPQ2_E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  microstrat: {
    name: 'Micro Strategies Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Beverly',
        lastName: 'Geiger',
        emails: ['bgeiger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF0skdXC6D_9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623690517280?e=2147483647&v=beta&t=owN1qYC8YzcUtYXFme3pau1tr6CrbQzhSM5kgmLVIk0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anthony',
        lastName: 'Bongiovanni',
        emails: ['abongiovanni@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sotiris',
        lastName: 'Sergiou',
        emails: ['ssergiou@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGNqqwyMcabkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517430110341?e=2147483647&v=beta&t=qafht0cuSqZAgo5vCf_N6KLRqjxYIYfXh0ewjffddkQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Adam',
        lastName: 'Storch',
        emails: ['astorch@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ron',
        lastName: 'Mente',
        emails: ['rmente@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFYQnoEfMCZ3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516323264803?e=2147483647&v=beta&t=2MfwiYoLjnCaxPlSBQ2uNng13eoJaOQunC3MEnFLNIE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  artificialcore: {
    name: 'Artificial Core',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'Porter',
        emails: ['jporter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHsJMAtWxoEQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585865966583?e=2147483647&v=beta&t=A0Hz6NDlFQqnCHly5HN0OgPhQ2xeUSs_zK3-8FCF2Kk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sam',
        lastName: 'King',
        emails: ['sking@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHRoLXW6cMGZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699002584040?e=2147483647&v=beta&t=jGXBYB716pkAOMkJfqwodJUQK-zHRVRM1HgW18_RBIs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nauris',
        lastName: 'Āboliņš',
        emails: ['nāboliņš@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Khrystyna',
        lastName: 'Statnyk',
        emails: ['kstatnyk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEBea8rqnIOng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657450836558?e=2147483647&v=beta&t=gyJJlGpQsXW2lSMRrf6Wcvr17sTYrHIx1zKlcIOlWpQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName:
          'Volodymyr Dmytryshyn – Senior Project Manager – Artificial Core',
        lastName: '...',
        emails: ['v...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHffa7WZJeDEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517517918776?e=2147483647&v=beta&t=bw3GjR3jmYhDqOhxyJnZdBkY6hJ_iL3YuRDqlSX4hYQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gatewayticketing: {
    name: 'Gateway Ticketing Systems, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Andre',
        emails: ['mandre@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Josh Block,',
        lastName: 'PMP',
        emails: ['jpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHf9sid_l_lHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665451543965?e=2147483647&v=beta&t=tmAzpxUM9P9gEXWPCW5U4-9rLCkVt6ArMQe8NiH2JkQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris Izzo,',
        lastName: 'ICAM',
        emails: ['cicam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGTsbbyFBtLjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598294352045?e=2147483647&v=beta&t=W9olBuCkhb53QPUs3wNccNoli7PDhxVpSs8Srsb8aYY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Asa',
        lastName: 'Engleman',
        emails: ['aengleman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFS-JoMcIoJVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516255078568?e=2147483647&v=beta&t=WjNsU7LgvpyjRhcX-of_-wxtgbj1GPf4xL8iUVYRDMQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dan',
        lastName: 'Haviland',
        emails: ['dhaviland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHdfK5Wf5GWXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516779386128?e=2147483647&v=beta&t=GCz1U4frVhV-pNkksDCSjjxsUm6SB257gVyaWK_JMJ0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pehutec: {
    name: 'PehuTec Oy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Otto',
        lastName: 'Multanen',
        emails: ['omultanen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFpmrUoQb0E-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628107707560?e=2147483647&v=beta&t=HuFG0U0Arf95EGTY7P4f-9DsB4Wq0ThokEL7IRB8nTA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Markus',
        lastName: 'Kyllönen',
        emails: ['mkyllönen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFy8PCFrH_DYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621190838529?e=2147483647&v=beta&t=TNYquPtmr5it3XHfghMDzl1GDmTIQE1yXU8oU2UX5qg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Leo',
        lastName: 'Viljamaa',
        emails: ['lviljamaa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGDQLgJjBD0Lw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517370673587?e=2147483647&v=beta&t=v2ksjyPF0azZZGlrLT1G4TCvpueysFf43vd70VdBp0g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pekka',
        lastName: 'Päivärinta',
        emails: ['ppäivärinta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHAHLcLwkACpQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516510229589?e=2147483647&v=beta&t=-jgl-sCST7PV2fj69wc6u2yZ9xrxrTZekVKQBQi3Hhw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Juhani',
        lastName: 'Leppänen',
        emails: ['jleppänen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dsigInc: {
    name: 'Data Systems Integration Group, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Uday',
        lastName: 'Krishna',
        emails: ['ukrishna@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Manoj Kumar',
        lastName: 'Pocham',
        emails: ['mpocham@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shane',
        lastName: 'Collier',
        emails: ['scollier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFGFqn3hOqFYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693512367576?e=2147483647&v=beta&t=46LnWX_EQk-_VM1rBm-TBUIibht3LQHPMLyDxiSzNqA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Meghana',
        lastName: 'Marri',
        emails: ['mmarri@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christopher',
        lastName: 'Anderson',
        emails: ['canderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFhT8xpSPsUQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543259114559?e=2147483647&v=beta&t=qxg7gbJcjOveFbJz_0BWWVbZ8KNIR4AHWRSlwiHZk1s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  leecountync: {
    name: 'Lee County Government',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kyle W. Edwards,',
        lastName: 'CGCIO',
        emails: ['kcgcio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHHJXK4AZ2YSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646861370414?e=2147483647&v=beta&t=jl5DMJ95HwM2SMU6x2u81rn_OuOsRN8Xeik6ZfjNt3w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Newby',
        emails: ['rnewby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEWfWHR6v6hFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724006967976?e=2147483647&v=beta&t=xJ7ryfqbeHOoVJoWJ4HWu0R5QaOUgK9YafubH4wO4a8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dolores A. Hostert,',
        lastName: 'CHES®',
        emails: ['dches®@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH7WbjMmSDCjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654605276397?e=2147483647&v=beta&t=jqXzCxohkRKAcUM-Q1GZ2jGmflFUnKWvIEGvBZPqKsQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris Dossenbach,',
        lastName: 'Ed.D.',
        emails: ['ced.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEOJiieIYu4rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690856934017?e=2147483647&v=beta&t=BlvQkBIQRcffQLFNUfgwYBwqkHMd7DEpsVknZiNZNr4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aaron',
        lastName: 'Bullard',
        emails: ['abullard@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gotoci: {
    name: 'Communication Innovators',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'Larkins',
        emails: ['jlarkins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Barr',
        emails: ['cbarr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE5Mn-vTUlcRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517709812515?e=2147483647&v=beta&t=h3uwxE1oXb9-VY0KVOypP0CCbu0QKe29MsBemfeLr5I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Josh',
        lastName: 'Knight',
        emails: ['jknight@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kirk',
        lastName: 'Glawe',
        emails: ['kglawe@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cody McCormick,',
        lastName: 'PSP',
        emails: ['cpsp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFODOv4P9oryQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699329203697?e=2147483647&v=beta&t=TnIoYSL45CR-GC46qeIJibXLP9r0VOLe2JQoStxRSU8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  edge360: {
    name: 'Edge360',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Rezzonico',
        emails: ['jrezzonico@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFrF3uogANqLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611676097693?e=2147483647&v=beta&t=-pbfjLcRNXD_d4n94hfDdMfth-cL6Kv-hZrToMS0PCQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Wall',
        emails: ['rwall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEE7H56gJ4UqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613071615550?e=2147483647&v=beta&t=5Mo5T_vRrL0g-0HB7D3r1ADPkXKTbeYEdwNoZ8biS28',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chad',
        lastName: 'Edge',
        emails: ['cedge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEvJo5tOt4plg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656031761289?e=2147483647&v=beta&t=CoD_ZhP18ugTJc8A7q3_zPNHptA346hPaMHZ5RWrUzg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Leroy',
        lastName: 'Hite',
        emails: ['lhite@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Karen',
        lastName: 'Edge',
        emails: ['kedge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGwxdSEvBxCgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517720997142?e=2147483647&v=beta&t=ViDC1i91tFnFJNMRFZd97PwSA0yQGWUUxYfN1IW8PQU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kfi: {
    name: 'KFI Key for Industry',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Fabiola Di',
        lastName: 'Francesco',
        emails: ['ffrancesco@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniele',
        lastName: 'Lorenzato',
        emails: ['dlorenzato@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQENfXM8UhIJIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516840687577?e=2147483647&v=beta&t=5tllRd3pxiNo4TRINCEGiO7DcGEZfYKg1-uiTaFfv4A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Simone',
        lastName: 'Pavanello',
        emails: ['spavanello@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH1E8KIx5nXDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516532329193?e=2147483647&v=beta&t=jN-08rNG-H93oM_mTYker-nHuU8IiIm3LInTJGD9vzA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cinzia',
        lastName: 'Barbieri',
        emails: ['cbarbieri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFKyfWkhZdxeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728481201772?e=2147483647&v=beta&t=fJkyj4pC4SEt45FolKCyu_negWX2NRgnBSGOgpvjTb4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Veronica',
        lastName: 'Lombardo',
        emails: ['vlombardo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGTIuwGWgsZFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696858181679?e=2147483647&v=beta&t=AroSgkRQC4SnJE7sShO03qJJ8KMwcXUl46QmcpzaEps',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  clearsale: {
    name: 'ClearScale',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jimmy',
        lastName: 'Chui',
        emails: ['jchui@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEtGZn4yMAdMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516295023810?e=2147483647&v=beta&t=Wa7BKkebCLQy_CQAKbUlCnBBKYZ3YAOMmCVw67WGVz4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeff',
        lastName: 'Carson',
        emails: ['jcarson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGE0jIXqIzdeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711507356782?e=2147483647&v=beta&t=h1l-Qm3JtY7EavQcagU3DT3vBV2lbx_LMF5TbrWJMcU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tim',
        lastName: "O'Brien",
        emails: ["to'brien@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHHX2CTx3kiQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706210671454?e=2147483647&v=beta&t=IaO5gXdA1yYh9kywVL8sDSF5rGk3qtIN338oeYTq9Zo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anthony',
        lastName: 'Loss',
        emails: ['aloss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF_QWrHaWRFJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666630885293?e=2147483647&v=beta&t=NNh96YnRa1TqNhY9dZIwOgb8npf4DfoYRT13-3nWf5M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Charles',
        lastName: 'Reeves',
        emails: ['creeves@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF4GiznsFKz_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568768872086?e=2147483647&v=beta&t=8FoemHq_rjKFvZQ46v6qMy5junJnt4KtKyS_tahmvr4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nextgenit: {
    name: 'Next Gen IT, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Harikrishnan',
        lastName: 'G',
        emails: ['hg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'IT support / Cloud / Cyber',
        lastName: 'Security',
        emails: ['isecurity@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEsg1vX9t0Rkw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594379533540?e=2147483647&v=beta&t=88Ob73IBb5rQVSbtyNQXNbhJSwOZ2qosp7G9mPUkSsg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ratnakar',
        lastName: 'Rao',
        emails: ['rrao@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrew',
        lastName: 'Dean',
        emails: ['adean@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGy7NebZe52Og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638473637223?e=2147483647&v=beta&t=f1EmrgsuK-96ojhfrJ6PD1pCLRFvnDNQK5ThXhoSO2w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Scott',
        lastName: 'Card',
        emails: ['scard@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  visionaryinc: {
    name: 'Visionary Technologies, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nichole',
        lastName: 'Kelly',
        emails: ['nkelly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHtN2c9gQLyDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721321322062?e=2147483647&v=beta&t=ka37A5AL1_rocr0CIn3-F6d_RbiX3rKHjrkqsYWIHOk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brad',
        lastName: 'Kessler',
        emails: ['bkessler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHuAqwQf95kDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516342559609?e=2147483647&v=beta&t=itE_pqLwozTj4ba3XOf6cpNFY9Pc7N14wMS-LYeXvNc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ashanti',
        lastName: 'Hall',
        emails: ['ahall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEhj68ojlSN6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690396908243?e=2147483647&v=beta&t=uPweOKvrZNaRf0HCGPfb5MBrkaUII1nv3oFmRwt6v4Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mollie Q.',
        lastName: 'Coleman',
        emails: ['mcoleman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John Ross Dike',
        lastName: 'I',
        emails: ['ji@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEudy9xTmmifQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631318381363?e=2147483647&v=beta&t=wm9WULtB3XaAGIh2GEcud5_cmOlyNdfOOH-vmeKIBU4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  theosCyber: {
    name: 'THEOS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Félix',
        lastName: 'PERRIN',
        emails: ['fperrin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Theo',
        lastName: 'Nasser',
        emails: ['tnasser@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Theo',
        lastName: 'Soumilas',
        emails: ['tsoumilas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGyz02888az2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727191986765?e=2147483647&v=beta&t=QWWbS2PYtQx7oX4pHOyr-vV36wTpw8Zf26YayJUeiTw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shane',
        lastName: 'Hermans',
        emails: ['shermans@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGFDf3BHe4vpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714380126126?e=2147483647&v=beta&t=2NuJfOm1lsZVyA3VszhS1klVURStQhcqiDDFcUfCcxs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Theo',
        lastName: 'Nassiokas',
        emails: ['tnassiokas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHC6ACbjyiBRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720234659397?e=2147483647&v=beta&t=4xSVQ0j6eyzgdC_YoUzSRatDoe0LGFrpbn7XfzVFqUA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zpgroup: {
    name: 'ZP Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Justin',
        lastName: 'Jordan',
        emails: ['jjordan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGe2_lRfZyjKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687049796214?e=2147483647&v=beta&t=QpBUaAqCAEx3ujJf-Q2IjJNKE-28ojexbL5Yz2MVUW8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alex',
        lastName: 'Ferrello',
        emails: ['aferrello@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHgqhSRVHq4oA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729705974932?e=2147483647&v=beta&t=MQg7VXVQ0LKxDc1i9deVjctq8DCmGTEKhZco7P_g-Qo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mareli Cornelius,',
        lastName: 'SPHR',
        emails: ['msphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFN8DFX8X5-OA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606441078617?e=2147483647&v=beta&t=MBdsMYLjg-Di7vxDiWGpBPj69ZdQCmxCHCA0PkiGGLI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Welcome',
        lastName: 'Dundas',
        emails: ['wdundas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGfn2gl2pg47g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707773006486?e=2147483647&v=beta&t=3ec3QIYtZ-1esWsLG2UAD5ehXIPHN-ldKTMQ6hnnx_Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sydney',
        lastName: 'Horton',
        emails: ['shorton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGgppWhRUBQag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663189460848?e=2147483647&v=beta&t=yJc8Y07eowtP-sWFERqRMDL0xQLS6n0Z1bqRrMG-G6Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tekfive: {
    name: 'TekFive',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tony',
        lastName: 'Taylor',
        emails: ['ttaylor@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Corey',
        lastName: 'Baswell',
        emails: ['cbaswell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGqr67q-4rO4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516279843983?e=2147483647&v=beta&t=Wf6FvuEct20Pw0U_BGf8N6E5soA6PHoCxotOGYtOSQI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Heather',
        lastName: 'Joslin',
        emails: ['hjoslin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG5HknXh-9zlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706208068631?e=2147483647&v=beta&t=OExC7TdrFcujRJuXNITlSEXMqu3dWx7DKnnnpRo2o7w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'Norris',
        emails: ['mnorris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF50pkMZIYGrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517738812349?e=2147483647&v=beta&t=EsprzZ6hClL8xoFKxQtg4t19GAo4JJIq1A-Z-P2fIH0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rebecca',
        lastName: 'Osborn',
        emails: ['rosborn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGpTSxY96qtTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647543296520?e=2147483647&v=beta&t=IT6TSxre9N6ggwHiXLMHsIbgKeTQ5TRZ1xcwxRXSUIY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dbconsultinggroup: {
    name: 'DB Consulting Group, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Shekari',
        emails: ['dshekari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGIWiVeW5F6yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551695764713?e=2147483647&v=beta&t=PxxMtJeiLMZ0SxTD42V46NX-TNLyl6VpkAl6G54_Bis',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Iesha',
        lastName: 'Bolling',
        emails: ['ibolling@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHR0o7jxrw9ZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727380600655?e=2147483647&v=beta&t=Bw3wCOyT52HjpxjEBnkDJ7vjC0RkOGmN6OTQC1zuw-4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erin',
        lastName: 'Sigouin',
        emails: ['esigouin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEhDw1h21cLUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516826377051?e=2147483647&v=beta&t=ep-Ah6PxC15DZhv-ASkTWV5EJuQZB2aoXAMOxIy4rAQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gerald Boyd',
        lastName: 'Jr',
        emails: ['gjr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shawn Boyd, Ed.D,',
        lastName: 'PMP',
        emails: ['spmp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  scielectronicsinc: {
    name: 'SCI ELECTRONICS INC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Barry',
        lastName: 'Pistone',
        emails: ['bpistone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHADSpn9W59YA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583808562699?e=2147483647&v=beta&t=6KbhK1GKVjTxNdf0mp8ytrNoMpdf9rZCdiZZ0qfR8ho',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Toohey',
        emails: ['jtoohey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Daniel',
        emails: ['sdaniel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF9wv6_Ie8qcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705698542240?e=2147483647&v=beta&t=os-UspXjw4j8V19YSieYWXlfHd8fQCfG3blydES77JU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tyler',
        lastName: 'Bruner',
        emails: ['tbruner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGiuA9CfuZFHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720384693203?e=2147483647&v=beta&t=RdH1h3BIp1tnmPlZc24ryFnAH6dnV-Rko9jeFe5-gNY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ken',
        lastName: 'Colson',
        emails: ['kcolson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGtqumyeIVV5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519080812633?e=2147483647&v=beta&t=O7Km6YQ7-sjmvLcSdxndFW8h9NnEUhCk_OYNSPCVhpY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mastersservicesinc: {
    name: 'master services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mary',
        lastName: 'Nissen',
        emails: ['mnissen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jerrod',
        lastName: 'Furby',
        emails: ['jfurby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHz70xr_venWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517539933665?e=2147483647&v=beta&t=sEyDwK6hW0a21A9ObzLB4di7DskVPQdVg379ci76LZc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amy',
        lastName: 'Southern',
        emails: ['asouthern@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFXIHiTsIniXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516933563158?e=2147483647&v=beta&t=XEP86TZB2bQ0w67hK91uKZmavgJBRJ72LaTJXrV5Gkk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dave',
        lastName: 'Judd',
        emails: ['djudd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFpEJagL9kI9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601911047188?e=2147483647&v=beta&t=B9aP-i1luospgcQrNd0sHsBq_ezRZBhlSC3wJf9xgW0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tye',
        lastName: 'Thompson',
        emails: ['tthompson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGdpiveYxMKCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676475510501?e=2147483647&v=beta&t=4KqSYWLCrB8PMxEd89B76VfSOTFP6hqAcHsjt5Gl7bo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cloudcarib: {
    name: 'Cloud Carib Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eamonn',
        lastName: 'Sheehy',
        emails: ['esheehy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHAeFIA18EXrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663147036210?e=2147483647&v=beta&t=FXOBxf625n4tz7pOXl_azzfM-DjWD_N_i-DllCkyTFY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Victor',
        lastName: 'Kovacs',
        emails: ['vkovacs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEgL-78fJyEwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720285722170?e=2147483647&v=beta&t=QJXQaZLjlta8SIgyXYPu3ZUSY46d1ufixdXud3MTMvw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Osmany',
        lastName: 'Goderich',
        emails: ['ogoderich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFUcEJZ0UGykw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687781318002?e=2147483647&v=beta&t=eyB3_nPflGkyUcPbkkMTlpwNCAL1PPpGnJB0sQS5G44',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephen',
        lastName: 'Darville',
        emails: ['sdarville@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carey',
        lastName: 'Callahan',
        emails: ['ccallahan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEGh6u3rkM61A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546903230468?e=2147483647&v=beta&t=4IoR67dW_K9155zs6m7Hb51hv1doYINjeaKw3CNSp9Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  futuretechllc: {
    name: 'Future Technologies Venture, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alyssa',
        lastName: 'Lewis',
        emails: ['alewis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHTKtpvTcdCaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620395696717?e=2147483647&v=beta&t=heLeOLxCjlgQ7PyUbH_DAQbXfjIzXza-TZ1aopO0yUc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Roger',
        lastName: 'Rushton',
        emails: ['rrushton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tim',
        lastName: 'Huffman',
        emails: ['thuffman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jared',
        lastName: 'Soffer',
        emails: ['jsoffer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFxA0s2X3Rr7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550809172986?e=2147483647&v=beta&t=nhnS7tmOwy1jitLKmf1HL44UMgY8iMqI4dCQm4Jcio8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Prasad',
        lastName: 'Golla',
        emails: ['pgolla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGF1-URB7AvZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647521972213?e=2147483647&v=beta&t=byPpk4JCH_sJeDAIhjJy3zdVEM7WaNY5OZwmpUnEEEE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bvhllc: {
    name: 'Berryville Holdings, LLC.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christopher',
        lastName: 'Delaney',
        emails: ['cdelaney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steven',
        lastName: 'Pritchett',
        emails: ['spritchett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQExlHiZoGYZQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517489847103?e=2147483647&v=beta&t=bZ_RbyqvS1YTV5TsbQoMdbqXvtzbymgPi7vLwmO2t58',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mary Jo Eidle,',
        lastName: 'SHRM',
        emails: ['mshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH6XnrtnSKbjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665767298143?e=2147483647&v=beta&t=HyGUz5MZs4TW31K9RmjV7fR2kM-aCPqlf7mBBqxeu0k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'DeLante',
        lastName: 'Fooks',
        emails: ['dfooks@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ian',
        lastName: 'Nott',
        emails: ['inott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG7XlfTHHaFaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618581620691?e=2147483647&v=beta&t=WcOsPRcc9g4nygt7clv0u5RdkxE11DzSaTaG3omJzYw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  evosus: {
    name: 'Evosus',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dan',
        lastName: 'McManus',
        emails: ['dmcmanus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGQDx6pt-8CXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572428535271?e=2147483647&v=beta&t=xx7IVk0aE2CaUzns4AXdSM1PLrYmKLIGBnmMpduWesU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Corey',
        lastName: 'Holton',
        emails: ['cholton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH6jCoxjQGrsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590783156082?e=2147483647&v=beta&t=RIYaUJaws-0JuFnmFBkpMrgFQ9jVGnrixqhJUS6pMN8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Conor',
        lastName: 'Quinn',
        emails: ['cquinn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHYnRuCF9dbSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663192294346?e=2147483647&v=beta&t=UNKoWYqw2iokNwzfGrLaeJhVDNEEPlmlFrrvuXFaEYg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paige',
        lastName: 'Olsen',
        emails: ['polsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQER34jnydusQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625688353947?e=2147483647&v=beta&t=X8wRGh4mC7YhrgPloN4r6MUibGExOOUdGNU0GPAK0wQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shaila',
        lastName: 'Ray',
        emails: ['sray@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFt-NiYYWnZAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610410651756?e=2147483647&v=beta&t=cHqg0bRnYl5Fc_3sD5F7jTPqlSDGdL6bTSrmUzFbu8o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '1dssi': {
    name: 'Dynamic Service Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Darnell',
        lastName: 'Armstrong',
        emails: ['darmstrong@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nicole',
        lastName: 'Commissiong',
        emails: ['ncommissiong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHxFw9k2_96eA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709792294316?e=2147483647&v=beta&t=J2_zIie5c-g0uasC1i-H8AL0XS0rbwoW-JexMB1EHi0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dynamic Service',
        lastName: 'Solutions',
        emails: ['dsolutions@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nejwa',
        lastName: 'Wilson',
        emails: ['nwilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEMJctrrSJMtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1577746723241?e=2147483647&v=beta&t=xRYhCe148xotsBKr0qnNLvTCwaffBB4MuO1cZoGpIKw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Junette',
        lastName: 'Scott',
        emails: ['jscott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHSWRAEY3F_Ag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1540328965961?e=2147483647&v=beta&t=LpiXaOjgncJwPPuRnX6mFuT6Q-cZD5p2ZrAe1z_MJm4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gitsolution: {
    name: 'PT. GIT SOLUTION',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'chandra budi',
        lastName: 'utomo',
        emails: ['cutomo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGrC3hNuRlWQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517610762280?e=2147483647&v=beta&t=IEtbfgtWs2k78Rvsl-1A5LhBN68d4ksAycIyZy7RvDI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Fiyas Mahananing',
        lastName: 'Puri',
        emails: ['fpuri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFzF8uzUBL_Bw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637230803275?e=2147483647&v=beta&t=EUUAcCw6eh5ltw_7sV4SPsMtA4l07M4y16jV1dXggEU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erna Yenny',
        lastName: 'Astuti',
        emails: ['eastuti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFE9OEcVZ1umg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670211480049?e=2147483647&v=beta&t=6aYI6DteADnqupM1GM4n-fSOs_qucIytRezEum2lINw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Elsa Mutiara',
        lastName: 'Nuralifia',
        emails: ['enuralifia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHH1z2VuLOIiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675422798224?e=2147483647&v=beta&t=dWCwABWK3C9yJ3AD74oLOKJysysQHakVBOZYu1uDDlg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Latif',
        lastName: 'Ariyadi',
        emails: ['lariyadi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHZE9fENkOznA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676708878951?e=2147483647&v=beta&t=oG1Me1wLG77ntUe5wrmL5mNg89N8lrQhKK-8u784Ju8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  appsbizsol: {
    name: 'The ABSOL Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Raghuraman',
        lastName: 'Narayanan',
        emails: ['rnarayanan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dikshita',
        lastName: 'Badri',
        emails: ['dbadri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQElXI_4yxKOPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671778251416?e=2147483647&v=beta&t=wZ9e-lOvfwcXdNkW4SgHtrqAAUD4maffi2bPKBRPzdg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'swaminathan',
        lastName: 'rajagopalan',
        emails: ['srajagopalan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Adithya',
        lastName: 'Vignesh',
        emails: ['avignesh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFYpe4RzbDWbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705137379598?e=2147483647&v=beta&t=_lnxYmjLD6BCpOT9o3poM3ELewvXoJkSBTmN_0DVQlo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mohammed Jaffar Ali',
        lastName: 'A',
        emails: ['ma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEFJkim24xsmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631609389238?e=2147483647&v=beta&t=lP2eNjGqCTlq5ihtLbUPwON75LZ_ZJpjgowDniPff6k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  centurygroup: {
    name: 'Century Solutions Group, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Curphy',
        lastName: 'Smith',
        emails: ['csmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH6UligXrRUuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632968563929?e=2147483647&v=beta&t=gg_eutzAndEjT20n3onIDnPt-WrHqMR5xPe_KuuPrg8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeff Wilder,',
        lastName: 'CISSP',
        emails: ['jcissp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alan',
        lastName: 'Hancock',
        emails: ['ahancock@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zach',
        lastName: 'McClure',
        emails: ['zmcclure@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEeIO8Nfj_LIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516927273773?e=2147483647&v=beta&t=gyMrsVNOMFaTR7Ovm8cRMa3SOoqsVEMVbJUm-EWb-1k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sanjay',
        lastName: 'Pant',
        emails: ['spant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFsdCP3zHYVcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702294813310?e=2147483647&v=beta&t=ecUpeoP0cr63CdEazNmb6FxVVVgUqHYnjA3St4r1u-4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mainstreamTech: {
    name: 'Mainstream Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brett',
        lastName: 'Krebs',
        emails: ['bkrebs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHA26MBqJXulg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691432486417?e=2147483647&v=beta&t=P7YC4OmKB9B3QZ5VEXx1T4lifWDGTkXDS-CayEJRUbE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Colby',
        lastName: 'Francis',
        emails: ['cfrancis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFPzgZIFhDutA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711985160483?e=2147483647&v=beta&t=pKCZso53Q0VNOKD_9wjUvKrzWK2gOzCk5uWQwmRIKP8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patrick',
        lastName: 'Linley',
        emails: ['plinley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE-Sfu-6znicA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680025523560?e=2147483647&v=beta&t=YgrGMQ9eza47kthZEhUQZn_sqfdqFE67J2T6sfojopI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chad',
        lastName: 'Duncan',
        emails: ['cduncan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matt',
        lastName: 'Stewart',
        emails: ['mstewart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQET_fl5OKtszA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681515473932?e=2147483647&v=beta&t=P3EN4y1gWX6zjpjqUFtnwSl4oZM7BRorajiClSm9GyQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bytesizedsolutions: {
    name: 'Keena Healthcare Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Craig',
        lastName: 'Luce',
        emails: ['cluce@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQE78sjI7Jkgsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516346654827?e=2147483647&v=beta&t=K38b4oLezqePMitHdgVoFIyw-vLjL0ISwsShZtjSS74',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steve',
        lastName: 'Buttitta',
        emails: ['sbuttitta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEfWRvqtjd_PA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596474751744?e=2147483647&v=beta&t=yA8AoeL29L8ZQuHJNERrMSkgOKA2RAOTalrnlDL7RVI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christine',
        lastName: 'Piper',
        emails: ['cpiper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF-E9BPJUDQVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646535649261?e=2147483647&v=beta&t=qhc_m39OLp_Urt39tufsPNVFFH1GKzz4YMXy0A9GZbU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matthew',
        lastName: 'Rahaim',
        emails: ['mrahaim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brandon',
        lastName: 'Luttrell',
        emails: ['bluttrell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  adafruit: {
    name: 'Adafruit Industries',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ada Chen',
        lastName: 'Rekhi',
        emails: ['arekhi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG24XhX5uMs9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653577147983?e=2147483647&v=beta&t=o3fb-u8rFlwpW2w3GLKfQV4ZtoHKSYP5e8R5aUzETjY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Melissa',
        lastName: 'LeBlanc',
        emails: ['mleblanc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFt9QOT-UYI3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517496323252?e=2147483647&v=beta&t=VuoSc64w7H1Bc10l7zZLnxAF-T9ohoj4tuCApYBNCKQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Limor',
        lastName: 'Fried',
        emails: ['lfried@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQElS-O4lXtVtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517882663743?e=2147483647&v=beta&t=53sBs3E0Q2xC48xwiqhSSo2tfbhX9JhOC10mXLbfQF8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ada',
        lastName: 'Yu',
        emails: ['ayu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHemxbv0Y8Z9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726876911186?e=2147483647&v=beta&t=gFp47XucoTryjkgINfb9NuVpHuHFf7nP9Yc7HAAULBY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ada',
        lastName: 'Ryland',
        emails: ['aryland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEDbOs6o3J9xg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729796533831?e=2147483647&v=beta&t=rwlKBshJSSm4B8eCVMXNCS9eDgW8nBs-o7BqQh5g6vg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fasttek: {
    name: 'FastTek Global',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Molly',
        lastName: 'Basil',
        emails: ['mbasil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHXEXvX7yECjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1524842798086?e=2147483647&v=beta&t=v84S0FaS_0P8kkYG3idDde3M1LvRR_MI6EBWG3xcLEI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Shaw',
        emails: ['mshaw@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Howard',
        lastName: 'Maniloff',
        emails: ['hmaniloff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGIfCrxTkdFDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1562029239955?e=2147483647&v=beta&t=XFrEF1TIRLg__9whLTnELFC9SHuh3qHQD7wgyCYU5iU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rob',
        lastName: 'Snar',
        emails: ['rsnar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH5E3rrW1zZ0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691417816203?e=2147483647&v=beta&t=5cNF5XVJreL1jAsFev_17SZvyIhPppfLeFZuXK2lfdI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Diane',
        lastName: 'Mabry',
        emails: ['dmabry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFw-GrX_rCZEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691420801826?e=2147483647&v=beta&t=NvgR_-bLDi-6Kz4W5x96KcV_YG_wUCA4YmiGvBRzskM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  corp: {
    name: 'Fibernetics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jody',
        lastName: 'Schnarr',
        emails: ['jschnarr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHHagwOAeytfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516302043641?e=2147483647&v=beta&t=ZJAjaLiWPw0hrejgv82JcM8_MIQPSwSLRBcjBleZCfg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Stix',
        emails: ['jstix@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG0YiUGOpomuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657736179644?e=2147483647&v=beta&t=2N0stXPwbEJAR93egthrR7HVPEybiRNh76AKUpMCdTw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jennifer',
        lastName: 'Wald',
        emails: ['jwald@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Margarita Rapoport,',
        lastName: 'CHRL',
        emails: ['mchrl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG_OOtGSCnJAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637543935776?e=2147483647&v=beta&t=dWhXCFe6HWkhjI_xHaRbxfLlnxYam3C5BiAsEgADtGs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ralph',
        lastName: 'Bergh',
        emails: ['rbergh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  legatosec: {
    name: 'Legato Security',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tom',
        lastName: 'Boyden',
        emails: ['tboyden@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jen',
        lastName: 'Greulich',
        emails: ['jgreulich@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: 'Tsui',
        emails: ['atsui@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEWPg4OE-zw4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606172594095?e=2147483647&v=beta&t=urI3Irgjd7ZUoGveY9pSoC9yTBCysVcDGPMYUJGzoko',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jordan',
        lastName: 'King',
        emails: ['jking@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGlSTa6xJuvnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620662681216?e=2147483647&v=beta&t=srSycT_So0cat1nz5nsY3WxDfuE5Q4kcoOxFzCdxnm0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Melody',
        lastName: 'Sterling',
        emails: ['msterling@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQElb7iL8OvqLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630813017824?e=2147483647&v=beta&t=m_B7BAuY64KXOW2YeUGv-5VpEaPriRAN-05YXajfXgc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  getcrs: {
    name: 'Competitive Range',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul',
        lastName: 'Brockey',
        emails: ['pbrockey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHz949T4M5NdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659980722230?e=2147483647&v=beta&t=zc-m8iKWcB5Hk4nQQ-lcKb48Vpr8nS0BZi5wJVvENSo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Wisner',
        emails: ['rwisner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFnqCXw0GF4Eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718250654619?e=2147483647&v=beta&t=P-rxL9CMLhNZkldeWdB_k-g8I_67te9vTEtC3YlcDo8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Priscilla',
        lastName: 'Ojeda',
        emails: ['pojeda@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ken',
        lastName: 'Mucha',
        emails: ['kmucha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGeCwhfyXZb2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722282311372?e=2147483647&v=beta&t=PvGrXCeRgfCQJadjzdODzj3w8ZE3a4HzeNMo1V4MXLQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Logan',
        lastName: 'Dever',
        emails: ['ldever@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFs2ComJAg9QQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517571770728?e=2147483647&v=beta&t=xIc1u95i8qUJKMFvuANGvJF-ZdAVDCXeWtlRo-caNdo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  iyno: {
    name: 'IYNO',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robin',
        lastName: 'Cassidy',
        emails: ['rcassidy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'melanie',
        lastName: 'wayland',
        emails: ['mwayland@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Toni',
        lastName: 'Rhodes',
        emails: ['trhodes@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Barbara',
        lastName: 'Laughon',
        emails: ['blaughon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Greg',
        lastName: 'Bissonette',
        emails: ['gbissonette@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  globalautomationinc: {
    name: 'Global Automation India Pvt Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'michele',
        lastName: 'acosta',
        emails: ['macosta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'putamadre',
        lastName: 'hijodeputa',
        emails: ['phijodeputa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sathesh',
        lastName: 'Sellathurai',
        emails: ['ssellathurai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFpOJqSyey52Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552115746121?e=2147483647&v=beta&t=BDXr5yD4wIt-98H4D3cOAplIPWX07f0jqdgvWnd6bos',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Reena',
        lastName: 'Pinto',
        emails: ['rpinto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFwpoC-Pt2B9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582782688450?e=2147483647&v=beta&t=ArsOPf7MXYbtw3lA2PkRS7V4JspaqsGH1wlZVHCA_mA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gopal',
        lastName: 'Gopalan',
        emails: ['ggopalan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFde_vHYatg2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516201511751?e=2147483647&v=beta&t=ZMIGEWFHhRXLWkPujVHp28oqAhiy2sT9I6U1FS7S99c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  redzonetech: {
    name: 'RedZone Technologies: Your Cybersecurity MSP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Crifasi',
        emails: ['jcrifasi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEoE4ruyD2OLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664910320667?e=2147483647&v=beta&t=ZfhvS5VAsNrYPkpyS41va-fdIyyJ3d-a-dLC1QsPhzs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bill',
        lastName: 'Murphy',
        emails: ['bmurphy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFPjW93AA8qBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715270259050?e=2147483647&v=beta&t=5Jn8YPuI2RUk-QXnzv47xJQXskEH3eNrdZdsS9hQivM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Natalie',
        lastName: 'Ripple',
        emails: ['nripple@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFQev6WB4kbtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703963464085?e=2147483647&v=beta&t=CJ-XataaR6lIBFrR_g3LXj-p2mL5jzQdmROEEbPuhKY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christopher',
        lastName: 'Davis',
        emails: ['cdavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEQIiJbDfQxTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585081734167?e=2147483647&v=beta&t=94Sv6KzCiigZXBzljd8frGI6IWWeDWhvhBwhZQinEOI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Melissa',
        lastName: 'Wowk',
        emails: ['mwowk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGNygTxpKPDwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650893906338?e=2147483647&v=beta&t=UFBUqkVWXN3tM9xk8pEG_4RGsYeM9XMpVs_CxtGwgeQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sotelsystems: {
    name: 'SOTEL Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Goebel',
        emails: ['jgoebel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE2LevSlwX7xg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693623276483?e=2147483647&v=beta&t=xlF05jpUKkoCV8zMwr-sDm1PKobuYQCh2794RYnK_7w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dustin',
        lastName: 'Kramer',
        emails: ['dkramer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEOjDfOYscqag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517755538135?e=2147483647&v=beta&t=goLxnvKvqctu8oBUv_F4N-RgM2cXOjNXK8CiAJ7LLdg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Belcher',
        emails: ['mbelcher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHxpFA0YR2rDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516962166696?e=2147483647&v=beta&t=KsgNJyiy-GoA6zaY6MwbA2umwyjsaA_9mvg4A2y7FLI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'TERESA',
        lastName: 'KARBER',
        emails: ['tkarber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFr1Yv3Sekurw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665019638133?e=2147483647&v=beta&t=ZIMVM2oqmezGRTgPzYyDO1M-bYzpJQ_-2ylMisTcZe4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Neely',
        emails: ['mneely@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHtx5oSxcwhFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517528058777?e=2147483647&v=beta&t=W_Z6uOXtiNAgMTOKx5j0Sz2ucb6lu4oKN6qdCyOA1sM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  shareone: {
    name: 'Share One, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kim',
        lastName: 'Young',
        emails: ['kyoung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE2txFwCSkLsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661524335564?e=2147483647&v=beta&t=bgl2Y_r_EaYWWu46OlqCUGAK97xlcGDFM3-JMXWcQ20',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jessica',
        lastName: 'Schneckloth',
        emails: ['jschneckloth@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marlon',
        lastName: 'Rucker',
        emails: ['mrucker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE9xIkj8b_XuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727158043369?e=2147483647&v=beta&t=AhmSEModuN-pTe_S5VwO2CtokthKE9qJtcCZOeziWGo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeremy',
        lastName: 'Harris',
        emails: ['jharris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEc4h5S-2GOSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610588277728?e=2147483647&v=beta&t=dRY6JO6ABxaUHgfWaK4TEehHavZ45EwnFeuHdW9nNKw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christina Hughes LeSure, MBA,',
        lastName: 'PMP',
        emails: ['cpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFLeP4kT0xBLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619540243859?e=2147483647&v=beta&t=Gm1RJWxLftdOTbq2o4pUUVHFQ7MYnSZT8pbu0YhQ4q0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tpmguru: {
    name: 'TPMGuru',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Maj Deepak Joshi',
        lastName: '(Retd.)',
        emails: ['m(retd.)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEUiZvjPChRgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694663647315?e=2147483647&v=beta&t=HBGNOWqHLjN3AkMz5Be4yqQzmapr15ChQ0OcAEDYM58',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Meghana',
        lastName: 'Chaube',
        emails: ['mchaube@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGWbf3ZlI7xZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724875348825?e=2147483647&v=beta&t=6_e3nZfN-KR2ORO5SqSdRzGj88DwAUlD4aoSfvPyNcg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'Martin',
        emails: ['jmartin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQET4Ef_KFHqZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618495121081?e=2147483647&v=beta&t=fiFLcs6eSCIINOM_pPF4DgffRCUgjNQ6nYdW9SVwJpg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shubham',
        lastName: 'Shukla',
        emails: ['sshukla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chandan',
        lastName: 'Makkar',
        emails: ['cmakkar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFIiBVv9_CJ6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713497101573?e=2147483647&v=beta&t=oHYTMN0QyxyipmNPaux1WiD2_Ax43NukEPEsGFMJY-8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  refreshedtech: {
    name: 'Refreshed Tech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kyle',
        lastName: 'Wainwright',
        emails: ['kwainwright@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHKYQs74zZQmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657626507834?e=2147483647&v=beta&t=If_LKSdFHf1xPrgc0N7juVF-rWLO1WO1GpqO5yv3Qqw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mick',
        lastName: 'Ventocilla',
        emails: ['mventocilla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFfFB_ru_2GZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724293039448?e=2147483647&v=beta&t=OHnqERiT0zUeYU-Ru7QS6175qWfKBOerzN2JnjDhOsA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kendall',
        lastName: 'Perkins',
        emails: ['kperkins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFVhpgZK50VzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702055523933?e=2147483647&v=beta&t=CA1KdZ0OUgN_QF0_azWm7WEPVt6nfe1Ucz5h9xZt7PM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ryan',
        lastName: 'Gillette',
        emails: ['rgillette@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kathy',
        lastName: 'Stellhorn',
        emails: ['kstellhorn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bitwisegroup: {
    name: 'Bitwise Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ankur',
        lastName: 'G.',
        emails: ['ag.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Neelesh',
        lastName: 'Desai',
        emails: ['ndesai@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mordechai',
        lastName: 'Danielov',
        emails: ['mdanielov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQElrpLBI46eIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563897206969?e=2147483647&v=beta&t=3VkpcOzKDCb27k81_3uQ5XYB3vbHDSAaV6Jm0_MHIvA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dhwanit',
        lastName: 'Malani',
        emails: ['dmalani@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nathan',
        lastName: 'Nickels',
        emails: ['nnickels@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dowsure: {
    name: 'Dowsure',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bistech: {
    name: 'Bistech plc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jocelyn',
        lastName: 'Brown',
        emails: ['jbrown@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sally',
        lastName: 'Bailey',
        emails: ['sbailey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHq_gDCX7W3zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659090964987?e=2147483647&v=beta&t=XjhqlQeFme6dK2EUlvJR72hag0SpMOQu-9DIeinlGxY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'Sanders',
        emails: ['msanders@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHHeUAVji4dlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597654780643?e=2147483647&v=beta&t=X1fmZ6CrVsnp1XdcykBPNPslLrkEmwtauPCrwvhOEB8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'James',
        lastName: 'Harrison',
        emails: ['jharrison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEFMG_FAJj3HA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550072754834?e=2147483647&v=beta&t=EJky_OmBs3N7HKPfpkSUdaFcB74T9no1oJhiFmKzTJc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lizzey',
        lastName: 'Cull',
        emails: ['lcull@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEGAq_-sLIZmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660922390078?e=2147483647&v=beta&t=1Hdqu-ynmui6e4-5feGSHqQ2Jd76Xgpd6gSNS-ZrRTA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cmaontheweb: {
    name: 'CMA Technology Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chad',
        lastName: 'LeMaire',
        emails: ['clemaire@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH1tiO8lpkOYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550092702034?e=2147483647&v=beta&t=QbIZzX7-94BHSJYT5bGjr_DQBYQkcfhaXEDSG5ru6nA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'Arceneaux',
        emails: ['aarceneaux@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFxbkN2uZoO5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549462615570?e=2147483647&v=beta&t=tbumh3V18Ldb1IgauPRt8m1It-i_SzD4s_RKHLaUwM4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ken',
        lastName: 'Stringer',
        emails: ['kstringer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHEDCVY94j51Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550162674831?e=2147483647&v=beta&t=XAs7Xxp3ZeyiLzV57XPCc1SYB68xrOP_-zV_I7HqYzU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jonathan Peyton | Solution',
        lastName: 'Specialist',
        emails: ['jspecialist@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQELVQiBUv7NAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708350350175?e=2147483647&v=beta&t=JB3qfLW1rnoNxKQwP5B3lDJ3lC6EeT7bRKN_tL0mRq4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ben',
        lastName: 'Cherbonnier',
        emails: ['bcherbonnier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHJAT6zRi__nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516355992313?e=2147483647&v=beta&t=6KK3AXDEyKq0iHjcC-5gdnIPPi5jqvRcvjvvp7HxdGM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cloudaware: {
    name: 'Cloudaware',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mikhail',
        lastName: 'Malamud',
        emails: ['mmalamud@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGi5tY16F2s3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557270129736?e=2147483647&v=beta&t=tDJtFOA67rjQswy0bjjL-TV5CK4ySCi8LTGLlHxQdyc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kyle',
        lastName: 'Burk',
        emails: ['kburk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHEKIhURrgUuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716140900215?e=2147483647&v=beta&t=kC0u8eHmmDRdi6L86142hIvQBvXjXumic-QlMVSbvqU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jordan',
        lastName: 'Spiers',
        emails: ['jspiers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHh_Pj9H8dHng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708553796742?e=2147483647&v=beta&t=ihe6URR8dmZH-Mj16Vi6NRSF8JVMfMP0CWrmzzfwCVc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexander',
        lastName: 'Urmuzov',
        emails: ['aurmuzov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEcP8hGY2T40w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552994367045?e=2147483647&v=beta&t=eifxAjIVKnVllMl-arkNDmQ9wNF4x-A0KRODscYtf28',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Boris',
        lastName: 'Lubomirsky',
        emails: ['blubomirsky@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mbblogistics: {
    name: 'M.B.B. Logistics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ryan McClendon ,',
        lastName: 'MBA',
        emails: ['rmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Melissa Umphress,',
        lastName: 'MBA',
        emails: ['mmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEyEPXZpn6uiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517752303486?e=2147483647&v=beta&t=NibDEfQcq3vqcyDcG4Qhwg35MC6sIln4A6ojXuKer10',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lee Rutledge,',
        lastName: 'MBA',
        emails: ['lmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG0FVgcvRvNFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721218058925?e=2147483647&v=beta&t=bEFmLZJ0uFoCsEjqJySJ4WfnBLtd7eesEm0E1qoOy-E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joseph Bell,',
        lastName: 'MBA',
        emails: ['jmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGy1ys3BWUiGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587345624608?e=2147483647&v=beta&t=TW_Uycs5a270ArvRuLI_xOIXg1qKS0Il_QId9xuy4W8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Will Waddell,',
        lastName: 'MBA',
        emails: ['wmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH_zCmPFig6bA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705625546851?e=2147483647&v=beta&t=14xCelo4zhYGdamyryhr2hXJE6KPytXSW5nPu3b7Lx4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  freeborders: {
    name: 'Freeborders',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Beau Giannini,',
        lastName: 'PhD',
        emails: ['bphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHidq306v1C_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685930885486?e=2147483647&v=beta&t=Cw1OPzhMLH3fkdBGaSa0rmZtIrqJYYGI1KvUzCiQZC4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Danny',
        lastName: 'Xiao',
        emails: ['dxiao@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFJ66NsS4GHTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516797400277?e=2147483647&v=beta&t=rzg3qr-Ei8SUWHjZUHI6plEY3-EV6mO2f4SRSAIERgU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Knudsen',
        emails: ['dknudsen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lisa Brumbaugh',
        lastName: 'Hill',
        emails: ['lhill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFX-EEdPyYcFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639273713709?e=2147483647&v=beta&t=Sfqeha5jfBCq5y57oSuoYdM6FE8VWjnGc6rVZMrjkMU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ben',
        lastName: 'Huang',
        emails: ['bhuang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  k: {
    name: 'K Logic',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anil K. Sharma, MS, MBA, CEA,',
        lastName: 'PMP',
        emails: ['apmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQElVacQ0BBacA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629984033381?e=2147483647&v=beta&t=22Lu_3jo1L_TjUZGMaVMMXjXt5Uj7CxylxCCye-aKek',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Golan',
        lastName: 'Caspi',
        emails: ['gcaspi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFTK1lOVj-B9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517561532651?e=2147483647&v=beta&t=lJBPTq_kPYqqOMT_wggFDYljzV2GK497VaG820kQYKc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Inbar',
        lastName: 'Sharon',
        emails: ['isharon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG_sFBdEZm5zA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705250864712?e=2147483647&v=beta&t=LUGbO2A8NnyjfZnEeRRTDnXQvlp4wA2QfSTahg9sSRU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Oren',
        lastName: 'Arielly',
        emails: ['oarielly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGAMsXByGEiag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653123757474?e=2147483647&v=beta&t=fbP-coo_py31AFX3R19vw9doF1ExSYl4Flwc_N66bO4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anastasia',
        lastName: 'Kozak',
        emails: ['akozak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHRiFKRUZwQxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635883971452?e=2147483647&v=beta&t=hsDz1mYxypMfoylnU29mPTOosoeLSiNZqwweoOom4rc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hughsymons: {
    name: 'Hugh Symons AV',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hugh',
        lastName: 'Symons',
        emails: ['hsymons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH7h_EBh7b83Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709026164996?e=2147483647&v=beta&t=M24dQCLlVvVHxcMQ9jNkr0y1WpgwFT4dPzgDy6Z1sFY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jo',
        lastName: 'Kenny',
        emails: ['jkenny@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alex',
        lastName: 'Dickie',
        emails: ['adickie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHw0Pzn8jqogA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592034568720?e=2147483647&v=beta&t=vQrd6FcfAODK5YmA4Nh6cd_275wpPBVYnIiat8_GJRo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hugh',
        lastName: 'Symons',
        emails: ['hsymons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGWW1EYfPVvTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611402209841?e=2147483647&v=beta&t=jQKJxR8bpHIHk4fb4XHqljJywvpZCItbKtxZCaEtvcA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Thomas',
        lastName: 'Ellery',
        emails: ['tellery@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFv2CCWEW-JNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671206468120?e=2147483647&v=beta&t=KRzycLDeqoinnuzKgim3wu95yX6njE7TI037XVZgE28',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ingrammicro: {
    name: 'Ingram Micro Lifecycle',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Diego',
        lastName: 'Utge',
        emails: ['dutge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG8U7MhPtgS7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730816178653?e=2147483647&v=beta&t=6itEo8PisuR8I_ywPEQ9hfGqr-4pQJwfnseX2d97dfs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Snider',
        emails: ['msnider@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHqpHyiyRdX6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619725371224?e=2147483647&v=beta&t=sYqlNsiD1b_bJykb27ce8bzYYBdg3v0wyelby0PgU9Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjib',
        lastName: 'Sahoo',
        emails: ['ssahoo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHILpjPLa5GSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730391338300?e=2147483647&v=beta&t=DAABcvt17flCNHTZXXcwpaRZ_XuIb9p24_iAuOn0deM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Thomas',
        lastName: 'Kanflo',
        emails: ['tkanflo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGP2b-ATHh60g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567853399948?e=2147483647&v=beta&t=dDYwtRy4pSr1KLIKpIrieYNvDXhtbA6gyWPxZeYiy0A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cathy',
        lastName: 'McCutcheon',
        emails: ['cmccutcheon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHc0PY7NDLfAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517676691347?e=2147483647&v=beta&t=OSqQ0wwOAbzz0cQjt-dF6yiA277kNrjIasIOl28vhho',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  omniquad: {
    name: 'Omniquad Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sujit',
        lastName: 'Jadhav',
        emails: ['sjadhav@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'B.Srinivasa',
        lastName: 'Rao',
        emails: ['brao@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEbaBEAw9iYag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709560213016?e=2147483647&v=beta&t=pGpTXfEq4AUCTkobM_ROhYhPugPgAQX3GajiRpUF6Gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pankaj',
        lastName: 'Pradhan',
        emails: ['ppradhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHgbZVOTt9yFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517579500435?e=2147483647&v=beta&t=Foa8yEFdJjkBSlNVQVhPGf2t111wxmwC5C-Tv9bzd40',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ameen',
        lastName: 'Mancheri',
        emails: ['amancheri@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tom',
        lastName: 'Bakowski',
        emails: ['tbakowski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFv_r2HIwTNPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698938789781?e=2147483647&v=beta&t=8gC4IbPLT8zabqBSRWJQZTWmb4ehGim1HwSI1wxQec0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  silverstar: {
    name: 'Silver Star Communications',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Liz',
        lastName: 'A.',
        emails: ['la.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeff',
        lastName: 'England',
        emails: ['jengland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGjS3bZwyMxmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517655678327?e=2147483647&v=beta&t=1kIX3PuSeGNsOnGVp7s_nE_ETMjuj5ZX8xU2W74wXck',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paul',
        lastName: 'Petersen',
        emails: ['ppetersen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF3D_FngjScmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516535641560?e=2147483647&v=beta&t=N2j6F88H4bqIXSCbVvyV8VE4ZR0znbtCMS3f8a4_91s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Allen',
        lastName: 'Hoopes',
        emails: ['ahoopes@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Todd',
        lastName: 'Pence',
        emails: ['tpence@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEiLFk1_TNHyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516780696927?e=2147483647&v=beta&t=98EUQgua_sNPTDpkCGpNN5NHDvy7GDWlgmJRi9ZJoag',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lernersolutions: {
    name: 'Lerner Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Lerner',
        emails: ['dlerner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGa_3zLYeRlGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594135799036?e=2147483647&v=beta&t=Lvz4wfB45CdGJ43Xhj3nfKkNJzTAzIeYVCmtyR7YjPc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'James J',
        lastName: 'Alcus',
        emails: ['jalcus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEGdKR7ZNIm4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517397839491?e=2147483647&v=beta&t=XHlrv631KtLDj4r7e4OcXjDsPyS0t1aGg2utpVTflck',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Wendy T.',
        lastName: 'Williams',
        emails: ['wwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGoNPy2valOXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663892095860?e=2147483647&v=beta&t=tvcag8OZCk-Nudz9k8BzlH-bHIo0emJeTA5_SiNTbtM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jonathan',
        lastName: 'Lerner',
        emails: ['jlerner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEuL9JHHjeFuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689858987873?e=2147483647&v=beta&t=nb807CE-3fmCIjFHAspT7zgrseX9RU79K3huv8kdnoY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Robert',
        lastName: 'Jean',
        emails: ['rjean@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE4rXKUePIhbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649438742640?e=2147483647&v=beta&t=lJC-2ce91qhczFP5_X7w3R9Q7LuyxWDIh2QnL8fVgEA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  six30labs: {
    name: 'Six30Labs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sreenath',
        lastName: 'Sreedharan',
        emails: ['ssreedharan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abraham',
        lastName: 'George',
        emails: ['ageorge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEDRz2ZAkDEuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622947374708?e=2147483647&v=beta&t=_Mv07962Xve_vVc0CvDUM40EFKbxraXdTR-NvCnsKS8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bharath',
        lastName: 'Kumar',
        emails: ['bkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEGh7-z469eaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654816107163?e=2147483647&v=beta&t=m06qJc9z21KhED-a3-UmY7wdsSwYjln_ZkyvcE-_8tY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ria Vijay',
        lastName: 'B',
        emails: ['rb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFx0SOigtujsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721803654967?e=2147483647&v=beta&t=w-lCfXyj9Q7Z4tWR837N_jLtbj2zsYMThrHxtTNOKNs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amal K',
        lastName: 'Santhosh',
        emails: ['asanthosh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGtQ9CnXg5Vxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720073711333?e=2147483647&v=beta&t=-ZRzqfOKvTRT8ccnxv8PMumWP0MTCEyTPfHO3cWfoTo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  techlab: {
    name: 'TechLab',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Art',
        lastName: 'Powell',
        emails: ['apowell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lucía Latorre',
        lastName: 'Salvador',
        emails: ['lsalvador@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH_NSgfNeRRog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656000387810?e=2147483647&v=beta&t=glSW2Zoo6O9Y1OOIplaPYEYEA-v3d8eYhPB_tHI0eV4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michelle',
        lastName: 'Welch',
        emails: ['mwelch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFXdnqhIL9Mjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715976299416?e=2147483647&v=beta&t=Qq7rG3zUG8IusRoSrgwrX5gxmFCJ7t5A2gkesU8D8Y4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joe',
        lastName: 'Topinka',
        emails: ['jtopinka@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFTPwu-khlIJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695303405553?e=2147483647&v=beta&t=4wwA6T2e20m0I1vh-AMdmwC3dGu7rqv0Fmx8AyMCieI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Casey',
        lastName: 'Crownhart',
        emails: ['ccrownhart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFhUVexnTCLog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704305491796?e=2147483647&v=beta&t=_YgFYDnBDHG2Y0IME7F5vJY6NOPUm9NWCkZvw2cgkzY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  banleong: {
    name: 'Ban Leong Technologies Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'U',
        lastName: 'Amy',
        emails: ['uamy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHw54R2pb8aaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615524272530?e=2147483647&v=beta&t=x43g7FYuU8v2ku75HUInKa8NkyIGbMasMT3CO2Mzkso',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ban Leong',
        lastName: 'Oo',
        emails: ['boo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jay',
        lastName: '',
        emails: ['j@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGB_3BOtu9hWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517021391323?e=2147483647&v=beta&t=64jr-xg3P60F2E7rD2M3wV9tXDoVmIgmJdMFRf5CpLE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ban Leong',
        lastName: 'T.',
        emails: ['bt.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ivan',
        lastName: 'Pua',
        emails: ['ipua@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQES6faqxP-UAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590654650871?e=2147483647&v=beta&t=IrxxXYZx0AY40JCbJi6X8juxn92heQqUS95jVIZO_bo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  radicalsys: {
    name: 'RADical Systems (UK) Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joanna',
        lastName: 'Kowalik',
        emails: ['jkowalik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEZmkgKWM7w6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557614953221?e=2147483647&v=beta&t=mpltB0Cs5mODNXPM8bRT47cZm_CmGIgWfE0PxGqm9Ak',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jane',
        lastName: 'Oliver',
        emails: ['joliver@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Esther',
        lastName: 'Perez',
        emails: ['eperez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rip',
        lastName: 'Bucks',
        emails: ['rbucks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEn1CuwhfxH3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517751810328?e=2147483647&v=beta&t=N-pE3xUt5mm_2mq7OFNqj4fZ5CVkeWXg6lG37KKjwbk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alasdair',
        lastName: 'Lindsay',
        emails: ['alindsay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH1PcGpaHCD6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517766612997?e=2147483647&v=beta&t=PKOK7gUgqWTfSjvKRkm9eGkWht9CkupJbqr9xGCXenw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pioncomm: {
    name: 'Pioneer Communications',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kasey',
        lastName: 'Krueger',
        emails: ['kkrueger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEegZCGnvx-rA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695154309290?e=2147483647&v=beta&t=BpOBa46Bi6hvcWb3TiDTUgc5nLR04nA81orCNYhcmyI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeff',
        lastName: 'Walker',
        emails: ['jwalker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Catherine',
        lastName: 'Moyer',
        emails: ['cmoyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQESfguDEILCoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600268366876?e=2147483647&v=beta&t=xaEflSc7-z2M6q7HP3fQpdpMe0d6m7e9JVFvJvw0LXQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Harold',
        lastName: 'Sowell',
        emails: ['hsowell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHAVgGr_db13A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573043647454?e=2147483647&v=beta&t=ui26_fagLdL8OoZ6T66Xv4evb4CU9O638KZqFYqlnRY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bravane',
        lastName: 'Phelps',
        emails: ['bphelps@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGV6a9POWQSLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684955938411?e=2147483647&v=beta&t=d1MQBwUuINaFlPcUqLHrfxN6GtJQBiIqvIzC5RjiWPA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fortunabmc: {
    name: 'Fortuna',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Seth',
        lastName: 'Waters',
        emails: ['swaters@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGkVeMTJiRANw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694633074464?e=2147483647&v=beta&t=z1KR8ML8_5OPM2BF5yZJqj-MN-1RKUruqEwr-jLP8hE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sue',
        lastName: '',
        emails: ['s@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGhe4TNTpyZhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666772844609?e=2147483647&v=beta&t=Tqxd6DgUg2x6-RqzXCMRCGBp_QuPJVds6hsgG04C9Y0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kenneth',
        lastName: 'Forbus',
        emails: ['kforbus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHLtpc0dDKXHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656208337210?e=2147483647&v=beta&t=kvEcvPNehPn44cQQRVz8mFzK8FYoSRD2oGy_wXI8XtA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Caner',
        lastName: 'Cihaner',
        emails: ['ccihaner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shadi',
        lastName: 'Youssef',
        emails: ['syoussef@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQElaoQNpSqSwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547474520416?e=2147483647&v=beta&t=EcwFb00at5ZbIqcd5GwUDYVCf3-ZI1ACm2tB66UoU9o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  calligo: {
    name: 'Calligo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matthew',
        lastName: 'Planterose',
        emails: ['mplanterose@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGa5E429Ulyqg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1537449533959?e=2147483647&v=beta&t=OdOeo53UevUvcTocJWO-rPSkUGFpEHXMTJC-luu_3IE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Donal',
        lastName: 'Duff',
        emails: ['dduff@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Elliot',
        lastName: 'John',
        emails: ['ejohn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tom',
        lastName: 'Luce',
        emails: ['tluce@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEivNpmcAcgLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557485160660?e=2147483647&v=beta&t=cjQGZu7Y9d6EaQ7_0hz0LVQVBnIokRQfZHtkxL1Nvtk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Comerford',
        emails: ['pcomerford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGnEdRabI2p3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609953167857?e=2147483647&v=beta&t=lwvumeOqKBjY2bg6oXPcOs6H0wsLxYgpPwO43EaowkI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  summitconsulting: {
    name: 'Summit Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sandeep',
        lastName: 'Sharma',
        emails: ['ssharma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alan',
        lastName: 'Weiss',
        emails: ['aweiss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH0jTtqHmMKTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682188079721?e=2147483647&v=beta&t=EgGEl12Rjwk5hpFmXQQ_zrHapvVHzvzVuG2V2S45kE8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Corey',
        lastName: 'West',
        emails: ['cwest@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEGUhq511cJew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616420393041?e=2147483647&v=beta&t=_mnsUfOWMCRyoGEm-ogbxi6644FRUFX5qCkT_4v7T_0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hari',
        lastName: 'Bharadwaj',
        emails: ['hbharadwaj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGqq_J5ocfC0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703318904565?e=2147483647&v=beta&t=HrIbMmfqseD19ksVcckv0SUtM0WVcbSTSlhbWsi-47E',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mohan',
        lastName: 'Ramu',
        emails: ['mramu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFfSLXk_9S9KQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701845829071?e=2147483647&v=beta&t=3M53-bJrG2u-11FtckYXanmfOQ-uLj4DsfbEiBTyR2M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  outerorbittech: {
    name: 'Outer Orbit Technologies Pvt Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shashi',
        lastName: 'Ray',
        emails: ['sray@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFVx_M_-v8fVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709765920245?e=2147483647&v=beta&t=c99v0u9VWvD4qwSiYkN6a1IM7Pmorg4bVG8kSMQYyAM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Poonam',
        lastName: 'Chhetri',
        emails: ['pchhetri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEQiXDeYEK2UA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673470065422?e=2147483647&v=beta&t=zXs7QMhY6XGe5b9s4eBz4-eQOLe7vGA_wT-1q-DwOR0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tiwari',
        lastName: 'Gudiya',
        emails: ['tgudiya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGsSB8JGTuXpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706886284112?e=2147483647&v=beta&t=UEvSHz-RDqH0hM0IVfj0qvvLqIlZ3-HMpOvlrjOHkbI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Deepika',
        lastName: 'Tirkey',
        emails: ['dtirkey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHSEi_LH1M7lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615914706957?e=2147483647&v=beta&t=kRYg2HTKQvyGfc2_DD5pmmAhC8g8f8eQ4cDwT1f70PI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shyam Kumar',
        lastName: 'Barnwal',
        emails: ['sbarnwal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGbeUOkp_yW3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688219149689?e=2147483647&v=beta&t=vkVXy3qnvzX8HGY42Qf8UzzjGgi-qW4mrQtTr4o4ugk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  evolgroup: {
    name: 'EVOL',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Khoa',
        lastName: 'Dang',
        emails: ['kdang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFCY6pd3MkDDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612365677120?e=2147483647&v=beta&t=x9XSi4axM_x1MCTFWThJateVbWqVd732Xq_jXHfKsEE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sagar',
        lastName: 'shah',
        emails: ['sshah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEeFqPZw3tssg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572867943112?e=2147483647&v=beta&t=hmIQ2mp8mDywRdzkVV3KwxBVg1J1U6beUrAj0VNxcf0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sonal',
        lastName: 'Shah',
        emails: ['sshah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHe2zY-XSnocg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563846452122?e=2147483647&v=beta&t=hrI5TUpuAM9uB4CJYKo9a0XU5UsFhOe6IRVAvAhkxes',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Drashti',
        lastName: 'Parikh',
        emails: ['dparikh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG1z3iUEVZ9zw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727341273741?e=2147483647&v=beta&t=Sm0jEDSLnCAr-6ymHGiFCA_LWKlsT5KY4BHsegtuvUU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mai',
        lastName: 'Phạm',
        emails: ['mphạm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHpNdTzoLCvVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701058715214?e=2147483647&v=beta&t=Z8U993GiC0goLLQvcPg0B0depjfppCbRhygolzI3Zj8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  propay: {
    name: 'ProPay SA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Peter',
        lastName: 'Wesseloo',
        emails: ['pwesseloo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Johan',
        lastName: 'Kitching',
        emails: ['jkitching@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEAyhKECxximw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595243571725?e=2147483647&v=beta&t=VtWm4i_fkfQkkoQSqRN6k_dfw0aUcGNBhE1DaKRAkvQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Juan',
        lastName: 'Rautenbach',
        emails: ['jrautenbach@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEK8qDvSzodPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689670990335?e=2147483647&v=beta&t=gsSwIALD3snQYDsLmAkzKZNCaBdtwk_qlzBOBHQqDeA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christina (Bernice) Van der',
        lastName: 'Merwe',
        emails: ['cmerwe@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sibongiseni',
        lastName: 'Msomi',
        emails: ['smsomi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  frogstreet: {
    name: 'Frog Street',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ron',
        lastName: 'Chase',
        emails: ['rchase@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hazel',
        lastName: 'Hughes',
        emails: ['hhughes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFd4NO9NZ-LyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646957601203?e=2147483647&v=beta&t=jx7TDhZU2-anzz154Dw02-aZAQV0t2kPQY1bf7D_hrg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sharon',
        lastName: 'Burnett',
        emails: ['sburnett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEe_P-6MX5S8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516526320225?e=2147483647&v=beta&t=rtB0MyRS4Hg_aC0Xs9LcPnT9PqKQU2ZIZCD-Twxe66M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lee',
        lastName: 'Ramsayer',
        emails: ['lramsayer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE5-4N9eLHQ6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705764434301?e=2147483647&v=beta&t=g7x5IWw_vMcveeOTW8R3yaUCc6Aa1TYVYplznEYI34g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carolea',
        lastName: 'Williams',
        emails: ['cwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEO054-80JjXQ/profile-displayphoto-shrink_200_200-alternative/profile-displayphoto-shrink_200_200-alternative/0/1529539680029?e=2147483647&v=beta&t=O3rVrWvMJQi00unmIYKTph0WlwXSNt6qu7u7cdAVscw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  choicetech: {
    name: 'Choice Energy Business Unit, an SEW Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eli',
        lastName: 'Couto',
        emails: ['ecouto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEhDjWecEWLug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727894568641?e=2147483647&v=beta&t=KSoQNtVXs-NfCImlNR2LkgIcwdnyconYnuPGVESyN1Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anna',
        lastName: 'Devy',
        emails: ['adevy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHgyc2bFQYOGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670628753512?e=2147483647&v=beta&t=PW72zvjVHdjCTWmpM11bg1MJP3EMZuj8ZBsXoHwM29E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Maddys Colón',
        lastName: 'Mendoza',
        emails: ['mmendoza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF_HWN13P2TNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516642139709?e=2147483647&v=beta&t=yLH-DYYFY75rTr7WcmHuht_MMIqrSbBw2P2_SIeYgOc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kate',
        lastName: 'Renton',
        emails: ['krenton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pip',
        lastName: 'Coleman',
        emails: ['pcoleman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEoDzwA6ZNdaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559523490079?e=2147483647&v=beta&t=RzRApzOGr52eTOPbrCVtenz-v6qGVS_CoefyUMtiMUM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nerdpine: {
    name: 'NerdPine',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Satish',
        lastName: 'Villa',
        emails: ['svilla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sushmitha',
        lastName: 'Godisela',
        emails: ['sgodisela@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHPjzo_wMu8tQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610463098609?e=2147483647&v=beta&t=NJ3bCH9fhwti3krAWlB4LAR08pMxVHPHHUpiO_QZx2c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rick',
        lastName: 'Jass',
        emails: ['rjass@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE8qDeTNo_D5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608153903685?e=2147483647&v=beta&t=8cc-WqBdFQ2imQllLcT3K7GALQWuMMyV3viTQwKcmuQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jessica',
        lastName: 'Pearson',
        emails: ['jpearson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Prasanth',
        lastName: 'Chitturi',
        emails: ['pchitturi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHA3sDW8OUWdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678685872106?e=2147483647&v=beta&t=ENzh_NI_9vdisb0kLD3i_2_NuVY87axH06wVGmbmy-M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  assivo: {
    name: 'Assivo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Karan',
        lastName: 'Vaswani',
        emails: ['kvaswani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEn5s4YhAYbRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686093409823?e=2147483647&v=beta&t=aNa1l4CF0hOCM6KhwWKjiUEZb_qnQg6VfrRVMT2OBxg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Suganya',
        lastName: 'Paulsamy',
        emails: ['spaulsamy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF3KDyFE6PLSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606220621428?e=2147483647&v=beta&t=jgJcCe_OfxjRhDx-fWn3dMM0pnxWW9ebXnxGPf3bQqw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vinitha',
        lastName: 'Raja',
        emails: ['vraja@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEDS-_JLuBTXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606229295544?e=2147483647&v=beta&t=0SPXMx0kf4B5NqA-m-xAxcl13TAAFu_EhYSw7jiOxvo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matthew',
        lastName: 'Fanelli',
        emails: ['mfanelli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFb1oFWL6LcsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619029231927?e=2147483647&v=beta&t=WHsomt2tPRFttdebltIjtgyAtQXOLWZSZmnw6H5PUKY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dalila',
        lastName: 'Lima',
        emails: ['dlima@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  itscegypt: {
    name: 'Information Technology and Services Co. (ITSC)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrew',
        lastName: 'Engdahl',
        emails: ['aengdahl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHsXDNka8yyPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660748089543?e=2147483647&v=beta&t=Ddwb4qczN8QWQZ66DyvdLq6veovSKSx89re0R6Sg0FY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Driscoll',
        emails: ['jdriscoll@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEQpZx8k9CSEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544568107386?e=2147483647&v=beta&t=l7AAUEuMIxgKAED_FheiPClU6m5_EhQXxmx9GJwaIUg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Green',
        emails: ['dgreen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGTMmuRgvn-oA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625690938476?e=2147483647&v=beta&t=PQTr7uhLy7HTHACdxXg6AfHzwqUE9hlybZoySC-iwt4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexia',
        lastName: 'Charles',
        emails: ['acharles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF9KdWX_f44Bg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633144041610?e=2147483647&v=beta&t=aD1xu4wV7TUopz_ztQoV5eleqvmYQGe_fXWBivJxDW0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cesar',
        lastName: 'Cruz',
        emails: ['ccruz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cantiktech: {
    name: 'Cantik Technologies Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Naveen',
        lastName: 'Sharma',
        emails: ['nsharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFxa6A_IKNXIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546434302884?e=2147483647&v=beta&t=9Tp_mSL23PaZX4A4LGxCQ6E9utnxiyAEeaXKMJ5eqUE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maneesh',
        lastName: 'Agarwal',
        emails: ['magarwal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sadhana',
        lastName: 'Rai',
        emails: ['srai@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ekta',
        lastName: 'Narang',
        emails: ['enarang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGunkSV4BPvqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589817299952?e=2147483647&v=beta&t=4spbBfXY65hS1sXetDO4Kv0lopvSnCclXxcZvg64hG4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vishal',
        lastName: 'Bangia',
        emails: ['vbangia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHfSOhLEpQ0Fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568800306604?e=2147483647&v=beta&t=Jd2pnUxinsW_VN3vsBWiE0a8MUh5UUy1uGVnEST5-dk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  overcome: {
    name: 'Overcome Business Innovation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sam',
        lastName: 'Schreim',
        emails: ['sschreim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGSwziiqvIlkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712383713871?e=2147483647&v=beta&t=3Tkh1v-dWSXVG4_hv2ugrZo2W6U5APPYTJONNi99MKI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Fedor',
        lastName: 'Sokolov',
        emails: ['fsokolov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Edwige',
        lastName: 'Sacco',
        emails: ['esacco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHiUcTb6d3plg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685481131220?e=2147483647&v=beta&t=5mRYuhdWqp1MG798TrpkeAn8QbDyomNvm07KmTmtqGY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gabriel',
        lastName: 'Campillo',
        emails: ['gcampillo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHLoS7P5a3oIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666361819380?e=2147483647&v=beta&t=lFUoyrnKiCeO7mrX2lgia8BeRSesOzxChdGyDUmhSkI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amanda',
        lastName: 'Price',
        emails: ['aprice@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHdfyJcDG87EQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724785601899?e=2147483647&v=beta&t=oc5CxlCMXJt-a-skBSFRFpIJ8WOkK1k5fSo3K065mhs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  shcMedina: {
    name: 'SHC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Deborah',
        lastName: 'Haumesser',
        emails: ['dhaumesser@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jennifer Roman',
        lastName: 'Anzalone',
        emails: ['janzalone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFk2m4uyVshGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666226955860?e=2147483647&v=beta&t=5QHb9eK-7stxTpkgLfgnkEVQUYniay5b0qEOegcvg4I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matt',
        lastName: 'Meuer',
        emails: ['mmeuer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mackenzie',
        lastName: 'McClelland',
        emails: ['mmcclelland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFtvymRZ0WaXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646951540614?e=2147483647&v=beta&t=Piy0JejZrUg9OvfGCF7gQJMqg-bNjQwP43QKMpTa5jw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sarah',
        lastName: 'Bastean',
        emails: ['sbastean@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF85U6CaPINUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667518472966?e=2147483647&v=beta&t=-epNFs_INice_GljSWAuLjQChceY0itsmZRAPGG3V2w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  datrose: {
    name: 'Datrose',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'William',
        lastName: 'Rose',
        emails: ['wrose@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carson',
        lastName: 'Regan',
        emails: ['cregan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEFJVKdjurEeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674500596536?e=2147483647&v=beta&t=vPH8K49y7HCbjeLEfKGiZoK_W2hIU6MJTWEmTlIX3V0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jessica',
        lastName: 'Kepler',
        emails: ['jkepler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Denise',
        lastName: 'Benedict',
        emails: ['dbenedict@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michaela',
        lastName: 'Dupre',
        emails: ['mdupre@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEYXOLw7P4JVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694570475798?e=2147483647&v=beta&t=aN4EgJgUSTFj2j2JpLvhr79UNln5_zNQrWpxLFK1fcQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  atlanticgrouplimited: {
    name: 'Atlantic Group Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Mayfield',
        emails: ['rmayfield@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHiXYeO4oI91Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666774499639?e=2147483647&v=beta&t=sqpymRAYv0WhFj8w8HB-6ZyghdtMS4xqnrH1yUrmkr4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrew Bain – President, Owner – Atlantic Group Limited |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David Morgan,',
        lastName: 'MBA',
        emails: ['dmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHUsRpB-HxG8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719210354140?e=2147483647&v=beta&t=GqtNPBHNBk2xa_UfrKx3HWsAnjuDMBveI02vFZN3mTE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Victoria',
        lastName: 'Sartorius',
        emails: ['vsartorius@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pascal Milord, BSME, P.',
        lastName: 'Eng',
        emails: ['peng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHzdi_edb9vKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601034467166?e=2147483647&v=beta&t=qnFpXcLiNaAfZJdMWbROoN6SlviG1rEBq1pFt09moTU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  contactcenter: {
    name: 'Contact Center sp. z o.o.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Piotr',
        lastName: 'Kempa',
        emails: ['pkempa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEjaA_R9mpV9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644315810115?e=2147483647&v=beta&t=6WuWxtWNi8jvqP_6ZaL_noSdSFOopBW5uMXT6JayloQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dominik',
        lastName: 'Pešut',
        emails: ['dpešut@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFw6E9JwntVzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670186911434?e=2147483647&v=beta&t=hMGOC0foljGJeSgfLGh8_zAVr_kXJexa0r2JNxHW3ew',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Kinga Nowak – Specjalista ds. rekrutacji – Contact Center sp. z o.o.',
        lastName: '...',
        emails: ['k...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Magdalena Karasińska – Learning and Development Manager',
        lastName: '...',
        emails: ['m...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHW4lPJt4hI4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709929948253?e=2147483647&v=beta&t=QfZ8CkXWKfSzI0tARpL_Aiy6tN87S3yN4BX8vktB_bs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Piotr Kempa – Voice Contact Center Sp. z o.o. |',
        lastName: 'LinkedIn',
        emails: ['plinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEjaA_R9mpV9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644315810115?e=2147483647&v=beta&t=6WuWxtWNi8jvqP_6ZaL_noSdSFOopBW5uMXT6JayloQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  royalarkgames: {
    name: 'Royal Ark',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Katya',
        lastName: 'Kovalenko',
        emails: ['kkovalenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE3UT21t_EJOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697041227445?e=2147483647&v=beta&t=BKzHLv-jr3c79LedA2RvtssOl-1_Q9zHIC3iAiVH64w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mikhail',
        lastName: 'Prikhodko',
        emails: ['mprikhodko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGPUkxabAhm7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692945908593?e=2147483647&v=beta&t=wZ5t-Bn_Mw8bq-MkWmo6GZ-Y3boGdM1uUwZKAAY9_Qk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aleksandr',
        lastName: 'Gaidash',
        emails: ['agaidash@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHR5gxM1-qS1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670534265474?e=2147483647&v=beta&t=od0mJYai-csgsxgwt0TPymOGofFpyo5Aicjt8ylmVsk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Doria',
        lastName: 'Tolkacheva',
        emails: ['dtolkacheva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE4uRrITmtUQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680846810838?e=2147483647&v=beta&t=pkEQhvQWaR3r-dlLLanFuEAEO9JNw48V9VLrwlKnz2o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Polina',
        lastName: 'Klimentyeva',
        emails: ['pklimentyeva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG1eJupAW-vNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705411024350?e=2147483647&v=beta&t=o0HbAQEbFIBcnhsmJ_4MKWuTDXiR9pqgtCwTEUKOgcI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dgrsystems: {
    name: 'DGR Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amanda',
        lastName: 'Dugger',
        emails: ['adugger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFJ4tEmXUXeyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710724891212?e=2147483647&v=beta&t=eP0ubyQnPUTCD9zUl6brTizt9nhRSjidC7UDyOax5X4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Dugger',
        emails: ['jdugger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEzX1YewncAfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657496400850?e=2147483647&v=beta&t=9s5FgfQwD0HLXavK_BEhEps8ECYhtLup5DXQ1s2-uFs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Logue',
        emails: ['mlogue@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHuhiIgaRMufw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670769350330?e=2147483647&v=beta&t=XHgAc_dHArj1_v-mak9_cRzLeBKbIk_jx0MK9KGUMgQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Phillip',
        lastName: 'Tobolski',
        emails: ['ptobolski@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rachael',
        lastName: 'Singleton',
        emails: ['rsingleton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE3eYoowuoFiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575985233901?e=2147483647&v=beta&t=NFIBkr0eSdw2QMuGyRSn29sKyKDSIdaOsMcOZJFCnqg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  enterpulse: {
    name: 'Enterpulse',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ann',
        lastName: 'Brink',
        emails: ['abrink@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kendra',
        lastName: 'Leffingwell',
        emails: ['kleffingwell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQExP2rtjQEkCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711393428528?e=2147483647&v=beta&t=xm3cj-0NvQnHOK0z1K3iQ3VqP2ZoZUECVIYfJearyuk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marc',
        lastName: 'Datelle',
        emails: ['mdatelle@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jennifer',
        lastName: 'Wilde',
        emails: ['jwilde@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHkNcTLC4yxTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638216158159?e=2147483647&v=beta&t=itO6CtBCJ3v6euy4S8-TrZLRYtKncj97QIRLSZL0M9Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kurt',
        lastName: 'Gabrick',
        emails: ['kgabrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGODclA9il_EQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695847957012?e=2147483647&v=beta&t=NDqkHyyZ_-xYBhfpS6jrG0sGXyx-Sv5oMu5FfwG1PHQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  proemtech: {
    name: 'ProEmTech® Infosystems Pvt. Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'renjith',
        lastName: 'rajendran',
        emails: ['rrajendran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEdjkmWg2zVwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517949544647?e=2147483647&v=beta&t=TrPsFC_bvkEEJjhWOHta5Dkp07eBn_g9zPk9bm4WtRE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Neethi',
        lastName: 'Alexander',
        emails: ['nalexander@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nibu George',
        lastName: 'Thomas',
        emails: ['nthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEEV-vnIfwDZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517732643973?e=2147483647&v=beta&t=XKAlyZrhieBxf7XPeulU-gsKt3rb8L9X4boIHfxa_ps',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tincy',
        lastName: 'Thomas',
        emails: ['tthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFCQtqzAF850Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517485433965?e=2147483647&v=beta&t=6JICS1poQfbvgtDGY1oBBeUtSipj2-by1J6CTQ28TRg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jino',
        lastName: 'John',
        emails: ['jjohn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHZrDgRM1znKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572063337058?e=2147483647&v=beta&t=jYpPbOTbOfH9g6I0HtmQgjd8pa3-Sd93yTw41VgVpdk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  appman: {
    name: 'AppMan',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  defendedge: {
    name: 'DefendEdge',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Taso',
        lastName: 'Triantafillos',
        emails: ['ttriantafillos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE9ilmoeE27ng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611676311736?e=2147483647&v=beta&t=GkWxnxd8qybv8xtz3i5OE1UPEH4gVmC_urzT927HoQs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'FioRito',
        emails: ['dfiorito@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHbcegMB9d1rQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713295969903?e=2147483647&v=beta&t=pIfXl2T-HpAs2kpIx2VVJuKFQStDDwgtYlHa2CvFT94',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: 'Trout',
        emails: ['atrout@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF59rw9KWrpgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715106703174?e=2147483647&v=beta&t=ddZwUiJpBuJHL7NCDxXp9ftbfCNGPg2Sj4TFfHJd7ao',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lewis J',
        lastName: 'Borsellino',
        emails: ['lborsellino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHuo1g82CGM7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516629737336?e=2147483647&v=beta&t=nVvs2_MPX3sNLpY79WrZJtTapw7VNMaDt5CofwhrpHs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jung',
        lastName: 'Wu',
        emails: ['jwu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHAhKK8M2lDbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683593102273?e=2147483647&v=beta&t=PabRj5uCtT2Ff5pF7IInFprX6c6N1afjojxLHk53U0k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  apptopia: {
    name: 'Apptopia Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jonathan',
        lastName: 'Kay',
        emails: ['jkay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGzClGjnyb9rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654526045565?e=2147483647&v=beta&t=-qBsiSTTtw_RbQR4ohbLYrIWJZUBebBRb9Kw9xYuX8o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eliran',
        lastName: 'Sapir',
        emails: ['esapir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEQihVGcXN8VQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517703368021?e=2147483647&v=beta&t=0AxE3A0x9mgYUUnBeUDqVLYVYtbkhcx9medc3VH0J-Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Connor',
        lastName: 'Emmel',
        emails: ['cemmel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHvc8C-UTBBWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517437909342?e=2147483647&v=beta&t=b6D1w0rRk2jnwlk9-RpK9KMqIftod8v0x-PXqxhEezI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tom',
        lastName: 'Grant',
        emails: ['tgrant@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Fairchild',
        emails: ['mfairchild@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  acondistec: {
    name: 'Acondistec',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  louisiana: {
    name: 'PUBLIC SERVICE COMMISSION, LOUISIANA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dustin',
        lastName: 'Glover',
        emails: ['dglover@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGRvKZCb_8rZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701847828956?e=2147483647&v=beta&t=yFQ6q3LpX2-hNBwjtHAZXEmH6nwgsS_EvG2k-x2bf8Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dan',
        lastName: 'Rees',
        emails: ['drees@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dickie',
        lastName: 'Howze',
        emails: ['dhowze@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Laura',
        lastName: 'Skaggs',
        emails: ['lskaggs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE1KxFvnVqbsg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689290209801?e=2147483647&v=beta&t=RjoDjK7nk0_ZW2ogzUKuYC9FeIjQ2_yynwSRcDrUQNM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nicholas',
        lastName: 'Joubert',
        emails: ['njoubert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFPWHMxzTHAaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614274754418?e=2147483647&v=beta&t=xDXDU-lFNnA8lSfIfnj-6X3cOxx0kC_2bll1YjbJIuM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zagruzka: {
    name: 'Rapporto',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aleksandra',
        lastName: 'Dimitrova',
        emails: ['adimitrova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFwH4BJYts9cA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601375565398?e=2147483647&v=beta&t=HE96lht0wUo9Rodc02F_Cu3vzpxO9x96NVTvjHMYr9I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Milen',
        lastName: 'Kotsev',
        emails: ['mkotsev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHuMYY1Kt1ZQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682767402571?e=2147483647&v=beta&t=HNr7fxANNNCdHwMhcjTNPu4m_0MJHoKwdP7Lnd1ZrgA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mari',
        lastName: 'Firkatian',
        emails: ['mfirkatian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFJ0JwHhNes3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518626270566?e=2147483647&v=beta&t=17v_0dRtpPGFDnzov7bOaJk0nWZda_2ecsvS-EGyG0U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Veselin',
        lastName: 'Karavasilev',
        emails: ['vkaravasilev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHE-YrCQ3vkZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706817684078?e=2147483647&v=beta&t=QiVTvvO8LRXD1w_TZxzFwh6zLWlzVUSp71ffYnDEBhg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Momchil',
        lastName: 'Kanchev',
        emails: ['mkanchev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFcN4qXhoc1QQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517021731573?e=2147483647&v=beta&t=rn6kyRIMSd_K8jpQtHA3-37u5Dn8K-xME5hC-rGxDAU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  omicronmedia: {
    name: 'Omicron Media, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steve',
        lastName: 'Miller',
        emails: ['smiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHIEZYc7Oqa0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684117002082?e=2147483647&v=beta&t=CTBmGE4cP5f2PGP0t0sKqOd7eAcL4bSqKUOWjCYIXPs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Regina',
        lastName: 'Castillo',
        emails: ['rcastillo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFJ2ofm4kOMaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682521371814?e=2147483647&v=beta&t=kWsTgnnVMKyXJpdNLmwmVosSnsGxIWn_ssLdqU6t6A8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jessica',
        lastName: 'Unkel',
        emails: ['junkel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGCsQF8-jYnHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651591591333?e=2147483647&v=beta&t=TwwhZWLuewm9VkWC0mCbSewa3MuGM_qq4Xp3_QgvXY0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gabe',
        lastName: 'Miller',
        emails: ['gmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFarNAz2Do5RA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516237286838?e=2147483647&v=beta&t=XHLb9aCXbriU44K9g804PVTyLY6QGDmnyLbQaiBsiwc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christopher',
        lastName: 'Hopkins',
        emails: ['chopkins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dbibyhavas: {
    name: 'DBI (DataBase Intelligence)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alfonso Salafranca',
        lastName: 'Laforga',
        emails: ['alaforga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGWsprixLoOjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692949437203?e=2147483647&v=beta&t=CYz4qg2WWjElfEi8b-c3ZO96yGPZ6yUqmiiAxwuvAWk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Miguel',
        lastName: 'Serrão',
        emails: ['mserrão@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGZx4Xws2F49w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516280536856?e=2147483647&v=beta&t=0P-2ZCtX903fPoWdv5dxJ88o94foYUrI6TPKqooWdVc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Léa',
        lastName: 'Lorain',
        emails: ['llorain@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jules',
        lastName: 'Bayer',
        emails: ['jbayer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEwtn0oIXf1_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666294056834?e=2147483647&v=beta&t=BggItxifSsMa6i7_7E8IG190nhmSuXBLsEg24piwNi4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steve',
        lastName: 'Zipf',
        emails: ['szipf@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHDjpg5tK7y9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607048798878?e=2147483647&v=beta&t=NPjbgSNzbGe3Kvjv57EG6Z4srhN5FnkiCT9sMOTNgf8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  loyaltyplant: {
    name: 'LoyaltyPlant',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vas',
        lastName: 'Diachenko',
        emails: ['vdiachenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEQo9D9ypXmjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516774871164?e=2147483647&v=beta&t=XIQlkgaZxEznh8bUspjvE62DYnhL1VKPabPmbUa4G3k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Evgenia',
        lastName: 'Mkrtychian',
        emails: ['emkrtychian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGhZNhcNrC3rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595759284034?e=2147483647&v=beta&t=-QCV2CqGozymVIzPTtf_NK39_ME7oM6bIuV1_bpx1Vc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daria',
        lastName: 'Bestugina',
        emails: ['dbestugina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEq_MkJIwJNiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711998302483?e=2147483647&v=beta&t=3w_X0IDb6i7wUlosRDp67Q50gEC42WfkNlkDm0xObvk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ekaterina',
        lastName: 'Ivanova',
        emails: ['eivanova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGpUq8bUpuQpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668010563828?e=2147483647&v=beta&t=AXIgmdyxaMQc9eeUOxasDH1-QWYuP5DkLZ-muLsqNcM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Wladislaw Vaisman – General Manager Germany – LoyaltyPlant',
        lastName: '...',
        emails: ['w...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGOJ1SB46vAow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541414024890?e=2147483647&v=beta&t=tTDMfIx1C7tcP7iiK0WEbx_8RfSCqotBRZjGM-z84GI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mafiree: {
    name: 'Mafiree',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chandru',
        lastName: 'N',
        emails: ['cn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFIbR5DoAJS0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728912141738?e=2147483647&v=beta&t=CKarZJSE3hBpiQqOosP1WURMi8BaQNt8OIQ2KHDGFiY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sukan',
        lastName: 'M',
        emails: ['sm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEKSL-x6ZHLdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690169290189?e=2147483647&v=beta&t=tVD_chJK4e5gWXv83RTbuI-HtHmXutiS-KWVjMCRvq0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ram kumar',
        lastName: 'Palanivel',
        emails: ['rpalanivel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Krishna Kumar',
        lastName: 'Nadar',
        emails: ['knadar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFroU9Cj174kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542348777650?e=2147483647&v=beta&t=-brHFDb__UWAPkiNoZTAdsDz7dOuxmewEGYg7NVmvws',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Divine',
        lastName: 'Steve',
        emails: ['dsteve@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEiG6o5A7wyXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727018488878?e=2147483647&v=beta&t=fY3S7Lp12ZNxZ3XeRTH--hSGC8vgWRGf6fVdmDnzRJE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  konnech: {
    name: 'Konnech Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dylan',
        lastName: 'Polcyn',
        emails: ['dpolcyn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGNzpWD_Mlsbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623252579488?e=2147483647&v=beta&t=nLCqk8HWLxm6tOEEb5ldWU_qI_2Eqaw-fGsw41XlbiY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shannon',
        lastName: 'Johnston',
        emails: ['sjohnston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEheiRiDiZkIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607526097453?e=2147483647&v=beta&t=yu-3rLdVfjsarOl_n1lRYOGMfDjLUz4HohihJYgvg0Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'shun',
        lastName: 'zhang',
        emails: ['szhang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrew',
        lastName: 'Iacco',
        emails: ['aiacco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGQQiXZ43gATA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517677637580?e=2147483647&v=beta&t=QEK2IjoqL2409ZTV-7DleKd9mN_iwvVGxLq2C7BL0oU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adam',
        lastName: 'Moore',
        emails: ['amoore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEhPERbL4SN5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688170670385?e=2147483647&v=beta&t=5ea7hFJC5wrxQfySObAUW0aT-xFU3YzPbl62cHxLIDU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  uecElectronics: {
    name: 'UEC Advanced Electronics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rick D. Layton Sr.,',
        lastName: 'MBA',
        emails: ['rmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matthew',
        lastName: 'Bakker',
        emails: ['mbakker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Homero',
        lastName: 'Lopez',
        emails: ['hlopez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jon',
        lastName: 'Dorsey',
        emails: ['jdorsey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGdiswF2S-xYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1528720385830?e=2147483647&v=beta&t=ebW9AhJDwz5e40sgbJLNl0-piG1XAt1WylMYm9lVUYw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Timothy',
        lastName: 'Creel',
        emails: ['tcreel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHYFI_rI2uXIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516514610852?e=2147483647&v=beta&t=n1wZU_gZNUVwo17mjzzLN5keJzS9JiN0CvFVrEkeIno',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  chibbis: {
    name: 'Chibbis.ru',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jason',
        lastName: 'Jones',
        emails: ['jjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGSLBojJ1YFOA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517674561209?e=2147483647&v=beta&t=DbMFHNEU2NnDKDL6_NC49La9pC9SFR4G7dRb6IbXAqg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alex',
        lastName: 'Chibysov',
        emails: ['achibysov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dmitriy',
        lastName: 'Burdin',
        emails: ['dburdin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGDMKSQHp4ZFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665937539476?e=2147483647&v=beta&t=dq6sud1deI2i5Jlj1DODiJsEEN0IME0UIDTRbYxsRnY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vika Chibis – HIPO |',
        lastName: 'LinkedIn',
        emails: ['vlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFOOK719Qvhuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588133952407?e=2147483647&v=beta&t=3FRaRrKvtl-9tU4N6JtJ_X3u_nn_L5bilG359BFxlXc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cindi',
        lastName: 'Chibis',
        emails: ['cchibis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  concentrix: {
    name: 'Staff Outsourcing Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Caldwell',
        emails: ['ccaldwell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGVxxgZofQFBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516318725510?e=2147483647&v=beta&t=i-Bo3EkcpRL7cR5oKxu9o-Wfe655PRicomtcuGlw89o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'James',
        lastName: 'Kim',
        emails: ['jkim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHkJ2P8RZCyEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725667842215?e=2147483647&v=beta&t=7nAbg_YKqZUZ2290EDhcsKdm6lHgXRb8GkwYnfCa7No',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rick',
        lastName: 'Rosso',
        emails: ['rrosso@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jason',
        lastName: 'Marasigan',
        emails: ['jmarasigan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brendan',
        lastName: "O'Shaughnessy",
        emails: ["bo'shaughnessy@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH6mTqe2AUJrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516261407823?e=2147483647&v=beta&t=RFDdG4_mjn8RUxqQET4X1IljXnx921kj3uqdXFTcRAs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  redinc: {
    name: 'RED, Inc. Communications',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'LM',
        lastName: 'Perttula',
        emails: ['lperttula@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHvxsrl_IA63A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582666733016?e=2147483647&v=beta&t=Orr5Fp0pTefCgO3yHy42v9XT50hHWCxNYTzXzZBZpPM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vanessa',
        lastName: 'Godfrey',
        emails: ['vgodfrey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHMmL_YTPzTzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550039557413?e=2147483647&v=beta&t=bTdeWOsJec6K3cqvcCEFRjfTBehQdiKDGGpA7O64SWo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Linda',
        lastName: 'Keele',
        emails: ['lkeele@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'McMahon',
        emails: ['mmcmahon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEjoN66zIBBkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598901846281?e=2147483647&v=beta&t=j7So5hLo7Q6782-Kn_mhdUJ7dLZpC6DQSwekfAwCj3M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jordin',
        lastName: 'Houlihan',
        emails: ['jhoulihan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHd-9CTtRKqVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665768112350?e=2147483647&v=beta&t=E-fHKE-uiEF3_yDRdhc3AWjXAy8Ksrvcdxb0t38dLCs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bridgevine: {
    name: 'Bridgevine, Inc. (acquired by Updater)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Julio',
        lastName: 'Bowen',
        emails: ['jbowen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHm7F1XXYswuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1524294461672?e=2147483647&v=beta&t=XaKiafRGHnWDZSytHxW-bI_0vm5fT-c_uZDpdOLVUxI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Alberga',
        emails: ['jalberga@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Murray',
        lastName: 'Hollis',
        emails: ['mhollis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'JR',
        lastName: 'Froy',
        emails: ['jfroy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHwGECyHj3A2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698334205936?e=2147483647&v=beta&t=UW1cFJYROmQ6a68uICrNC9EdoINDrmMm_qNAdFIV-GE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rachel',
        lastName: 'Fletcher',
        emails: ['rfletcher@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  abcgrp: {
    name: 'ABC Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mireya',
        lastName: 'Villarreal',
        emails: ['mvillarreal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFMd7dBGn51Aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708479562894?e=2147483647&v=beta&t=luXt8j-Yztt4tu2ZeZlHuw8CQwgX0x8ISxNxd2DSTrM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Olivia',
        lastName: 'Rubin',
        emails: ['orubin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQECq8RwnoTBGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659737631607?e=2147483647&v=beta&t=vvgExvfFcXMj77BAcKHQCbNckz3T4-Qen_uycwRP7u0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Emmanuelle',
        lastName: 'Saliba',
        emails: ['esaliba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Averi',
        lastName: 'Harper',
        emails: ['aharper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEIvdqPhEB-SA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712167403137?e=2147483647&v=beta&t=uCufe5Qb_BOH3akf3gq0zY8RsvzzUljooVGFUBfb2bk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gary',
        lastName: 'Hershorn',
        emails: ['ghershorn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHPHnzn1BlcVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516956303500?e=2147483647&v=beta&t=Ldohw5S05CQYDaeatxNWi6cRsQT7RXq13nzVztKV3W0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  idsi: {
    name: 'Intelligent Decision Systems, Inc. (IDSI)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stasy',
        lastName: 'Jones',
        emails: ['sjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEBwet8ymIVHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1545140027971?e=2147483647&v=beta&t=Z8LORqNndOrmKUcV6MLRlF2MFfYA6b6SN6cvGHNIWiA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Miriam',
        lastName: 'Plaza',
        emails: ['mplaza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEAYMRy794IKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517282493986?e=2147483647&v=beta&t=7YNI6odKEq9i23wIJ8s-xsM5WiEzeReaAxbN43H9znU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ben',
        lastName: 'Brichetti',
        emails: ['bbrichetti@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John D. Melvin,',
        lastName: 'II',
        emails: ['jii@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Craig',
        lastName: 'Doescher',
        emails: ['cdoescher@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  compat: {
    name: 'Compatio AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Timothy',
        lastName: 'Baynes',
        emails: ['tbaynes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG9I94vSNJVoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644351693256?e=2147483647&v=beta&t=5NFUohhHuF7hbOkib7-GlOeh4ps6w_rEKv8X7LBAQks',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sachit Swaroop',
        lastName: 'NB',
        emails: ['snb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG25f20iYRg8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692685476751?e=2147483647&v=beta&t=E_bk_ZpUz-Is3qiKnrvUgQ3xknYn2jX2pRSrUafCFr4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marina',
        lastName: 'Joseph',
        emails: ['mjoseph@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGF8hjXTmKhgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693890674897?e=2147483647&v=beta&t=-kxjDqrC09Y-Djy5k4MBgdSIXaEauMvQXoB96n0MI4c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Priya',
        lastName: 'Nair',
        emails: ['pnair@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHBhsZF9zP4Iw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1703076001896?e=2147483647&v=beta&t=cf2StWTDZW9LJ-7FybnSFmIX47UxBHkeuvm0I8JnGFo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sajeevan A',
        lastName: 'M',
        emails: ['sm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEH27Sr3RREXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595382746835?e=2147483647&v=beta&t=IMxdX8vIkPoahQvE-8MB-r9g-W0sDHKkmNmXX32urgg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  simplifycorp: {
    name: 'Simplify',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Yan',
        emails: ['myan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHRJBL--FoS5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719626274132?e=2147483647&v=beta&t=VKXCbMrWVO6ZAVbhE3NcKDvl2C63mG8_mwXhvdMlr5M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Amanda',
        lastName: 'Hughes',
        emails: ['ahughes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEOagZSS-ur2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516481972040?e=2147483647&v=beta&t=XjBk8ddGJ1UdBA-vCzyj1__Dydc4rR8Q-ocEmO95AnE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stacey',
        lastName: 'Beck',
        emails: ['sbeck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFiqEUTDMyydA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516314138068?e=2147483647&v=beta&t=nukNdPwXi84Su7RnZpSVUqNz1cktoTeGDrt1MLlFM7Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rushil',
        lastName: 'S.',
        emails: ['rs.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF_QR7I5O4IzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660887825081?e=2147483647&v=beta&t=T-x9giLGXNmmLsdgKiFF4-MNgQhq8uqh0hSE8F4uywQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Peter',
        lastName: 'Urbain',
        emails: ['purbain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHWMQT2YVEBUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726852225655?e=2147483647&v=beta&t=v4o0tX1yMmmywiyGuVTd4is7IvU_Unw-VTfR_Cic9Io',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  karatsoftware: {
    name: 'KARAT Software a.s.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Griffin',
        lastName: 'Hale',
        emails: ['ghale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFApXzdxiQWyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594051467709?e=2147483647&v=beta&t=N9aIQgFWmHYDx6tSDJrfytIMynyKQCxf9bSWas7BHdI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vivek',
        lastName: 'Karat',
        emails: ['vkarat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFCwOV_Py8v_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665232146551?e=2147483647&v=beta&t=ApxDAPzIxK36j159XOW9_l6Mf6SqUcxXKf30LraKhN8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dharmik',
        lastName: 'Gadhiya',
        emails: ['dgadhiya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHhNtlJgxUIBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680327489664?e=2147483647&v=beta&t=H_Q4rsMn_8aWJtX-JXafl4Co-3bL6vYsPm1s-xNNsEU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeremy',
        lastName: 'Umansky',
        emails: ['jumansky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG30Nhb-f3CnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618603170305?e=2147483647&v=beta&t=arszqo6CMI4U3uB1YLJHqkutUPs709FYiNEisLplcjg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeffrey',
        lastName: 'Spector',
        emails: ['jspector@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFxYTkYtYj4VQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633988923600?e=2147483647&v=beta&t=Acn10I6Kzry5-YES10ezKeA8REzce7Mf45jaK8qQLSA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  paperlesspaycorp: {
    name: 'EFGH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sarah',
        lastName: 'Broughton',
        emails: ['sbroughton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFvBVHaauj99g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701800252648?e=2147483647&v=beta&t=RT8AznLtivKG2479wxF7MzUGXzImWw8zAwA_l_tNtl4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nick',
        lastName: 'Joost',
        emails: ['njoost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFZ6gmnUAn0xA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517555517272?e=2147483647&v=beta&t=6qcB1t4z1e_87gB8WDO8SdY0bUWUHGVv8oBI873lTEE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jennifer',
        lastName: 'Matthews',
        emails: ['jmatthews@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHeodltQACF8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555697650291?e=2147483647&v=beta&t=4paTNzF0KquiXIelcQOdWacp6ErUhITa4e_fSUSxAM4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'W. Mark',
        lastName: 'Broughton',
        emails: ['wbroughton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Garrett E.',
        lastName: 'Sapp',
        emails: ['gsapp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH7vhASawaKVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692111116814?e=2147483647&v=beta&t=19LEDfCD-E0V0TvImp34T0pCMrBmFydKZNd2RiHQms0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  spectruminformation: {
    name: 'Spectrum Information Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Imran',
        lastName: 'Syed',
        emails: ['isyed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHDkywqPcS-6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559158070189?e=2147483647&v=beta&t=dyAJdR4IsmNf2omhP4y6S9G7jj844iEz_KNM5bYP51I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Patrick',
        lastName: 'Whiteside',
        emails: ['pwhiteside@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: "Sanaz 'Sunny'",
        lastName: 'Jahangard',
        emails: ['sjahangard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE5Pl-doy5DnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516544489967?e=2147483647&v=beta&t=3-rQVkgnMNkyt80xe7qczKeuvWWQKyYeuoCfbGEWJaU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kiranmai',
        lastName: 'M',
        emails: ['km@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG3QJNZuY35qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575428416907?e=2147483647&v=beta&t=3rl7EY7VsARt-QCyhYS2w989y8_KxWsWpe80DjJpTqk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ruby',
        lastName: 'Arcebal',
        emails: ['rarcebal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  southpointconsulting: {
    name: 'Southpoint Consulting Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Denita',
        lastName: 'Whiting',
        emails: ['dwhiting@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGgvEurjf1pmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704923175167?e=2147483647&v=beta&t=1l85p1zFGQJSZsjox2gpCLOsoWKFqX2ctGpi7NFj328',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mariel',
        lastName: 'Espinal',
        emails: ['mespinal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Olivia Coates MBA, MIM, PMP,',
        lastName: 'CSM',
        emails: ['ocsm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHp5G_zrZzU2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649165593114?e=2147483647&v=beta&t=-qKo-t4dsMGXUzj-9ZNRnD-BMHL-jqqrPrqsuyVe0k0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Deborah',
        lastName: 'McKenzie',
        emails: ['dmckenzie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGwL4-tARclLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695850633776?e=2147483647&v=beta&t=76VOrZfF6U6d4JFeYCYxQwFlK7hu4RvjS3Cz6jFklcw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Richa',
        lastName: 'Dubey',
        emails: ['rdubey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGYGpJoNaiwMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617305340568?e=2147483647&v=beta&t=0y6m6I6JuGw0DIrW64Q_ZLWuv4vqq2Y-0IajHyEeP-g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  unraveldata: {
    name: 'Unravel Data',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kunal',
        lastName: 'Agarwal♾️',
        emails: ['kagarwal♾️@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Keith',
        lastName: 'Alsheimer',
        emails: ['kalsheimer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFD77kMMeCQpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517701495598?e=2147483647&v=beta&t=UjX19uZsib4x8wcJ5Xv3zXEsFL5xnDFu89k8i68WtFI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kevin',
        lastName: 'Cancilla',
        emails: ['kcancilla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHs-PGXUaTsQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552930767628?e=2147483647&v=beta&t=mEAfINDYA4fB66478QiXwRfRPYUUCJG7xhQ-eEX2RdM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Don',
        lastName: 'Hilborn',
        emails: ['dhilborn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHrZBM9FnfxfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723167887042?e=2147483647&v=beta&t=70RITAJSWCgoth5KsNrKAyBlSpCZc2cs40A4QkDAqg4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Santiago',
        emails: ['csantiago@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vietnetco: {
    name: 'VIETNET Distribution JSC.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'hoa tuoi bien',
        lastName: 'hoa',
        emails: ['hhoa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHvtKrTEL8eUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517866175676?e=2147483647&v=beta&t=7ibtqUFavVyp6a-lgzsCPICSxKEKdkTUVfcKN68v8Tw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hai',
        lastName: 'Nguyen',
        emails: ['hnguyen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE3J-ejWrL3Lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517339116134?e=2147483647&v=beta&t=4qTCMfCFBsSMuFbCHSL-usNlWRQx7YDg47JXNWsgU70',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Giang',
        lastName: 'Nguyen',
        emails: ['gnguyen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE4C7MJA5Ku8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667809853341?e=2147483647&v=beta&t=ryQZ1MhOK_rkH2_8g2Ocs9VbEVZVZdJhnv25XzN0w-M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nguyen Thanh',
        lastName: 'Hai',
        emails: ['nhai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFRYi-eWJ_zJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516775817897?e=2147483647&v=beta&t=KapM8usL7sdE2hH4U9JUYyyI_f5c-Pd4nYFYsXcjpE4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Smart',
        lastName: 'Telecom',
        emails: ['stelecom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGPiPje9WGLfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516458593230?e=2147483647&v=beta&t=iiAcPs6WemiKVEvXmNv3ao9hrT-7_oG_yDYekwuTS10',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  codeacuity: {
    name: 'Code Acuity',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Venkata Sai Rajesh',
        lastName: 'Soma',
        emails: ['vsoma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHbTjFkWuttEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686168949012?e=2147483647&v=beta&t=GnaebN8zpe37t_L3-h4EmXYi1TZj2shFCbx7Phe4rB4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sai',
        lastName: 'Prince',
        emails: ['sprince@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG3r8fHaMyYCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672313754453?e=2147483647&v=beta&t=HNUPM3R15CzQJd2MOTXIcn1SUJer0R6g_bgQ3w1yTV8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sai',
        lastName: 'Prince',
        emails: ['sprince@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Faustina',
        lastName: 'Francis',
        emails: ['ffrancis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGw2fBGhjsfRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723091231587?e=2147483647&v=beta&t=4UuH0QeVH_GsAb7hIAbC4WEy06NTROVnzZVSeWRLJoo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Saikiran',
        lastName: 'Muthyapu',
        emails: ['smuthyapu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFD_fBSSIO47w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719370048250?e=2147483647&v=beta&t=wdk_86dHSnzeiXa_RMSyq1jucWNzb7NjzqgkBfDpaHw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  brescobroadband: {
    name: 'Bresco Broadband',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Tovar',
        emails: ['ctovar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFWyi0NYlcGUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547610713965?e=2147483647&v=beta&t=7ZXDwFA6frFWidsrDL_lraYdEn2VHWRPsh0B59rLSAY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Harmony',
        lastName: 'Igweka',
        emails: ['higweka@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHMaE1dZvSCxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722254303528?e=2147483647&v=beta&t=Ts-jdvCDKR-8wO9APLxL1wx--qBv-dVK8KDKtMdoYS8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Franklin',
        lastName: 'Camp',
        emails: ['fcamp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEaak4bAd9MaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656997158525?e=2147483647&v=beta&t=7RXHUIVY-nc2uZlfgMq2lCjYTSWJB9V94rUGaNyBv_w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nick',
        lastName: 'Massa',
        emails: ['nmassa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sam',
        lastName: 'Brey',
        emails: ['sbrey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kablelink: {
    name: 'Kablelink Communications',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kenny',
        lastName: 'Davis',
        emails: ['kdavis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Craig',
        lastName: 'Cuffe',
        emails: ['ccuffe@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jamie',
        lastName: 'Bennett',
        emails: ['jbennett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEX6WujnV3SDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517565584005?e=2147483647&v=beta&t=fAyeEZSuDShqenEZkFLY4POCtzrP0BvmFHSToTe7Pqk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'ARACELIS',
        lastName: 'ALVARADO',
        emails: ['aalvarado@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Leon',
        lastName: 'Hampton',
        emails: ['lhampton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFJtGdjhsSvsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516936506935?e=2147483647&v=beta&t=CnCqUXCEwsfFg5W3-rVzdNNR37YSbbVidrbMTOyFP8c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  myhealthaccess: {
    name: 'MyHealth Access Network',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lance',
        lastName: 'Butler',
        emails: ['lbutler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHeblL9UpdetA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517638698304?e=2147483647&v=beta&t=-nequMiIUyApRp5vq7fMl58VU2jqD-zNbkmL2YZWgos',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jacqueline Pereira',
        lastName: 'McDaniel',
        emails: ['jmcdaniel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGNXIPKd7mplQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629552274601?e=2147483647&v=beta&t=W8P7i0I9FTemy7zUjhTkmscxtHotNWW2i8qjONeKCmM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jennifer',
        lastName: 'Brock',
        emails: ['jbrock@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stacey',
        lastName: 'Kaze',
        emails: ['skaze@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFZG173AWn-SA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611861055166?e=2147483647&v=beta&t=aqNHCS_SRJRTUEi3GmAEHGUhXjVqFJIS7f1mUqobj6s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Julie',
        lastName: 'Ladehoff',
        emails: ['jladehoff@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  yangenterprises: {
    name: 'Yang Enterprises, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adam',
        lastName: 'Murcek',
        emails: ['amurcek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHqZQGfARxQRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516955563175?e=2147483647&v=beta&t=aj57vDe2tmisysU8Xhb4KgLvG8THjp4qfz3-XCVNtbQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rhett',
        lastName: 'Jones',
        emails: ['rjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHCUDLfueQhaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516884433283?e=2147483647&v=beta&t=wYgRnCCzb8mmp_wY_ZaKGqw1x8itWeWWAkt9wonIdAc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mike',
        lastName: 'Sonnenschein',
        emails: ['msonnenschein@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Colin',
        lastName: 'Blume',
        emails: ['cblume@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG4Ib1mYvgBag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550261540039?e=2147483647&v=beta&t=lnv7tmz7wtwF319Os1nRhZ1is5FaygbzFxiMYsa16T4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patricia',
        lastName: 'Blanchard',
        emails: ['pblanchard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFApjxqfpZCAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517574899077?e=2147483647&v=beta&t=Ua1xSXm___TqCoD4qlIW9p8E1gPhakvxjVzIJ1iCoAA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rStylelab: {
    name: 'R-Style Lab',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jing',
        lastName: 'Zhou',
        emails: ['jzhou@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Veronica',
        lastName: 'Gorbach',
        emails: ['vgorbach@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFLeP8_e_YveA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578901917098?e=2147483647&v=beta&t=RohKBpNvxsNFbjDpEL1rNLI-t_9yN7A4uWzMHJ2a4nY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alana',
        lastName: 'R.',
        emails: ['ar.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEynuaYusS-Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715706028528?e=2147483647&v=beta&t=1M_tH3jgkTemdp7tjltGcXS5xgYZEGQMBu5ItpXvbZQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrey',
        lastName: 'Rodichev',
        emails: ['arodichev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF0bq9BFmzm5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516048025315?e=2147483647&v=beta&t=h912z0cqben6S1R2pk5_2O_JaIyj90J7FDdr3vq_ld0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Phillip',
        lastName: 'R.',
        emails: ['pr.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gigacomputing: {
    name: 'Giga Computing',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'Truong',
        emails: ['mtruong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFm2iv8_lr7mA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681335361961?e=2147483647&v=beta&t=ivP41iGSUyWd7nCirNjz-Z_z_W6uB2Bzru0S37_jSrk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jacy',
        lastName: 'Hung',
        emails: ['jhung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEx9FIEcF2SVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687310344014?e=2147483647&v=beta&t=weQP61SFgErjus7y-yjKeK0lcbCIRx0q-WO_NOua890',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Serena',
        lastName: 'Yuan',
        emails: ['syuan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHDADyd7Yg4Vw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721401358207?e=2147483647&v=beta&t=9ARUX0yMrb8OD7eGQDMhcpYXhFhsUxkmpXTrGakUMeA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kevin',
        lastName: 'Lu',
        emails: ['klu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGIopH4xDSfXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595405308277?e=2147483647&v=beta&t=__GjkeRwzaW2ArDVyJfjEjUYYjEkPHKe9QbRCZDsOSA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mandy',
        lastName: 'Lee',
        emails: ['mlee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG4Q46AIq-jIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711075619711?e=2147483647&v=beta&t=FUVIWoh6jfJ7Ybazr4ti6OCMsPKtRsnUXTnMker9k20',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  integratedhealthstack: {
    name: 'Integrated Health Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Valenzuela',
        emails: ['dvalenzuela@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEPnzwVWeneOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574443323433?e=2147483647&v=beta&t=qw7dvG48EHnTkgTbUTMHBHlPPlZ54zqGAAcNqhnBa84',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'david',
        lastName: 'valenzuela',
        emails: ['dvalenzuela@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Valenzuela',
        emails: ['dvalenzuela@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Valenzuela',
        emails: ['dvalenzuela@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Valenzuela',
        emails: ['dvalenzuela@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFLibEufuNDMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718470244387?e=2147483647&v=beta&t=Z-H8pr2n3HpJX7DiL0m5NPh9fbZZ_v8fwAkrUIWI-jA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  radency: {
    name: 'Radency',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  iccg: {
    name: 'ICCG - International Computer Consulting Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bala',
        lastName: 'Anantharama',
        emails: ['banantharama@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shiv',
        lastName: 'Kaushik',
        emails: ['skaushik@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: 'MacQuilkan',
        emails: ['amacquilkan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFxaUpwAZum3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724768934147?e=2147483647&v=beta&t=jx51Y96vvA1NCeIqJFsOZHOPQMzyHqQ2ySKflv2UO2k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dave',
        lastName: 'Parker',
        emails: ['dparker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEX4Te3pAvOwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624478855021?e=2147483647&v=beta&t=q0hte4Fzi5hjmfQuB-pVk0nInGiFIu3T9A8axc2CGaw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kalpana',
        lastName: 'Balachandran',
        emails: ['kbalachandran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEXeCiVdQ1UNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721163014336?e=2147483647&v=beta&t=Esn3BGEJw1WJ_sShjEpnRg4WIbv5QCnahupv-Vhkj8I',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mieweb: {
    name: 'Medical Informatics Engineering',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nick',
        lastName: 'Wallace',
        emails: ['nwallace@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE04aNXKVSC4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718224386457?e=2147483647&v=beta&t=CLUPIzdC1crHCY-ut791C50WiXvdm7-bPIrtt2PzhXo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Doug',
        lastName: 'Horner',
        emails: ['dhorner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH6-2RenQfr3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648168806879?e=2147483647&v=beta&t=F4SJcddO8z6tns1ZYv_0cakyXQgmYEgV7SoxySqSqV0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: 'Horner',
        emails: ['ahorner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFatw8syzlkpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666561911008?e=2147483647&v=beta&t=V_cz6Rs4f5XguLtI8msPq7FXuBV5mO-TbkAUNHD0qzY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anthony Petras, CPA, PHR,',
        lastName: 'MBA',
        emails: ['amba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kate',
        lastName: 'Barton',
        emails: ['kbarton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGC0giRJ8-B4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632237636588?e=2147483647&v=beta&t=DOgKN0DrkVx1daRpn2XhovmZbktnPNGjZcV2lc4lWN4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  forwardslope: {
    name: 'Forward Slope, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jason',
        lastName: 'Mak',
        emails: ['jmak@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andre',
        lastName: 'R.',
        emails: ['ar.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEnVVOJZhjlvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635218781898?e=2147483647&v=beta&t=2JzUDeO-q6hTEgxI1RSA7e9N0Y_qYifrG1RQ6NejbzM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: 'Pidduck',
        emails: ['apidduck@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Karla C. Corral,',
        lastName: 'PHR',
        emails: ['kphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFYM8xie92xYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516513888175?e=2147483647&v=beta&t=iNfF1p1degqMEtRKm5MLAcCe8t_IE0OjnO1RXpnaTao',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tiffany',
        lastName: 'Rufo',
        emails: ['trufo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  uniscon: {
    name: 'idgard | uniscon GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tanel',
        lastName: 'Suurhans',
        emails: ['tsuurhans@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGwvO-Be9ooOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516517724737?e=2147483647&v=beta&t=gWrAKIiJD4h3BpgMzXEKMx8OYc-zZKaOivjJde9hF6s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tom',
        lastName: 'Denford',
        emails: ['tdenford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFZk0ZFMeoSPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676318910422?e=2147483647&v=beta&t=ziTN2gl6ruBkRNywh-5NFKX7-OIM87k8q16uNlY4zOo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stan Schrader',
        lastName: '[LION]',
        emails: ['s[lion]@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHleRFe1gAcSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682877291188?e=2147483647&v=beta&t=eA-GegRC6rJYjOIWOOB28RVKxP3_pXJrkD-dixNOHl4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexei',
        lastName: 'Czeskis',
        emails: ['aczeskis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFy4_kKHePEHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715024474652?e=2147483647&v=beta&t=UKQcu29OVXnVbKDUfVJRcRuTYThUlQiAr3KKf0lG78s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eric',
        lastName: 'Fernald',
        emails: ['efernald@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFCJpibfgzvGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517528083167?e=2147483647&v=beta&t=Z_L7S1t9aPFT1VFUYdu2XthEHn3uhUY_NQdswtHEcfo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cdis: {
    name: 'ČD - Informační Systémy, a.s.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Todd',
        lastName: 'Dobosiewicz',
        emails: ['tdobosiewicz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Devan',
        lastName: 'Adams',
        emails: ['dadams@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lydia',
        lastName: 'Volaitis',
        emails: ['lvolaitis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGighxc6OLZlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627493575390?e=2147483647&v=beta&t=M0lZgAmLS-TN40A50wQ86cxrdvTe0bfe-XRcqXI6SfA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ishmael',
        lastName: 'Sanchez',
        emails: ['isanchez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE62PhnuEY74Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651341157409?e=2147483647&v=beta&t=AWLSzyIP39TAUPvRAQYwD2rJ47SEft-FP8wn82m-rwA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Solenn de',
        lastName: 'Kersauson',
        emails: ['skersauson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  networkN: {
    name: 'Network N',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ben',
        lastName: 'Maxwell',
        emails: ['bmaxwell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHgHbfiMp15eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695289532652?e=2147483647&v=beta&t=bINbJuNLoBlSNRyQK1O5pyMxWp8-aCOiOvptyhKIQwQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Amy Dennies',
        lastName: 'FCCA',
        emails: ['afcca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFLMwRnmx9TiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516788039555?e=2147483647&v=beta&t=DBfwiEvV7H--Qm8j75447vaSuRJVz3GWXaZlVOdSec0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joe',
        lastName: 'Robinson',
        emails: ['jrobinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEBJ81WU7VTUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519145789666?e=2147483647&v=beta&t=BXtECNuh373lCLjh3L8kbuaoLDa7K4rA3GXflPBRksA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'James',
        lastName: 'Binns',
        emails: ['jbinns@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHK84Vr3i-BYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516233545656?e=2147483647&v=beta&t=tI3tQe98gAhKX_DI5J-28iUONg3VAAlQbYKFExNnoxQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anthony',
        lastName: 'Grimley',
        emails: ['agrimley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHIOUxaz9tOsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695139016260?e=2147483647&v=beta&t=GwUOGQSw9QtQbw6K1PoFeGtOkJrU_5oxvF0qRnhv3lE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  riproad: {
    name: 'Rip Road',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dennis',
        lastName: 'Goldstein',
        emails: ['dgoldstein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQECp6U85NDoTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665426689994?e=2147483647&v=beta&t=m36wO5tYjHAtH4Qn8PnKPKPCt4Es92OTKAuKlYZbsRM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bob',
        lastName: 'Gleason',
        emails: ['bgleason@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Diane Q',
        lastName: 'Curtin',
        emails: ['dcurtin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGAXZqJkiNfmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573848933508?e=2147483647&v=beta&t=jTbqEeuJoR1AswbWzjX6enNZz4GaGNgBDtjCCV1MZ_c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Stenzler',
        emails: ['mstenzler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGuf0Du4Qditw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516291947922?e=2147483647&v=beta&t=QAGNfLxJbwymuq3-gsY02s51d9GbyI1vrb_JDf67jFw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dates',
        lastName: 'Fryberger',
        emails: ['dfryberger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF0TnYSpJyKSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581450053146?e=2147483647&v=beta&t=HvZbd_5-x5VMFF0MgnOvWhWOGgZTE8406zQc67OPWas',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eduson: {
    name: 'Eduson',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Elena',
        lastName: 'M.',
        emails: ['em.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHSM_AMAApfgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516270755310?e=2147483647&v=beta&t=XkqxyZtMlVXDxu1KYNIfEh1iFsg16LNexgCREv4jdc8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Igor',
        lastName: 'Achikov',
        emails: ['iachikov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHnjr8PG2cLLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621528798484?e=2147483647&v=beta&t=KIZv7gXqUNeGxqEapcTg1srtBjdlhmvyB0V19V0nJyQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Armen Karapetyan,',
        lastName: 'MBA',
        emails: ['amba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexander',
        lastName: 'Makaev',
        emails: ['amakaev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE1-NzUqrndqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601410381202?e=2147483647&v=beta&t=L2i4w59qHh5JE37FyyLOYM-TUkItAZehSmVBSmRsDq8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Konstantin',
        lastName: 'Vlasov',
        emails: ['kvlasov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEEvb12RbdIDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680285102632?e=2147483647&v=beta&t=YMoDbp8_mEu22Y53iPqz2Is3m8RJW-rS2uFw2D5s79U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  datapmi: {
    name: 'DataPMI Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shari R',
        lastName: 'Ravi',
        emails: ['sravi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG1Lxo7ImS3lQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659442332822?e=2147483647&v=beta&t=J8H7kHr6TkhNO-KlG9J86q1Fk8Tpyb6BBD7BSSD79RY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Geethu',
        lastName: 'Raveendran',
        emails: ['graveendran@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Madhavi',
        lastName: 'Gururaj',
        emails: ['mgururaj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGZmESm5DM2vQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678272891224?e=2147483647&v=beta&t=Mg_xRpKo9SLp2XfuX7koZwAohaNydJh8GOTq0Pe3Bu8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ranjitha',
        lastName: 'N',
        emails: ['rn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFwQSoi8t5aeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663236686761?e=2147483647&v=beta&t=eEPHHWUtZ9m3wz6Na8S24beBFzh2QRTgmS07dQa-a7o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ashish',
        lastName: 'Sharma',
        emails: ['asharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGHHP_EYKotKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725777538478?e=2147483647&v=beta&t=OwyRaQF0fJ1zVricx93BJWYAAI5PXOx_t8q_N2D9JB8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  giotto: {
    name: 'Giotto.ai',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  callibrity: {
    name: 'Callibrity',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Carman',
        emails: ['jcarman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carlos',
        lastName: 'Martin',
        emails: ['cmartin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ryan',
        lastName: 'Wilson',
        emails: ['rwilson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Laura',
        lastName: 'Wooten',
        emails: ['lwooten@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGwYPhU1f3Ekw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879697585?e=2147483647&v=beta&t=4zLvG0ATS0bY9T4BkJa_uz8JxKGNxRYyOB6Ew7x0tzU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dustin',
        lastName: 'Kocher',
        emails: ['dkocher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGH1D87yzgThg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621282160611?e=2147483647&v=beta&t=SNo_gDp1ZtQfX1srQMtTFUiDq4b3-gl9tuR2VdrCNZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aqueity: {
    name: 'Aqueity',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'J. Maxwell Schroeder,',
        lastName: 'CISSP',
        emails: ['jcissp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHSucrdGWBiiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619706713801?e=2147483647&v=beta&t=74lff3gkVytm-PcT7gagUndZeWu8flVSGO1CuU4zFwg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dan',
        lastName: 'Dunn',
        emails: ['ddunn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHUjPqZmNrSGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591131049127?e=2147483647&v=beta&t=8Bh815bKU-4NR__htSR4jb2Vr-GxTmF6Qdi82UrndPY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Mask',
        emails: ['mmask@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Patti',
        lastName: 'Romanowicz',
        emails: ['promanowicz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGNYkwXZaANWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718898696172?e=2147483647&v=beta&t=i0a8UFSqzmUXU45o04KYFmXhE-QHqDCBejr1ryv0vmE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'McCall',
        emails: ['jmccall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHZPnQUH1n90g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695328339398?e=2147483647&v=beta&t=qQynxo6M7zPLhLNjS2DMefP9RPnpOh4raNO07nMXnJE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  scr: {
    name: 'SCR® technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lubos',
        lastName: 'Cerny',
        emails: ['lcerny@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEohwZZKgVlWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707747503368?e=2147483647&v=beta&t=DPYfTmLBe17u_s8iup1md4Whae0wGPDACZowiUzNLf0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jimmy',
        lastName: 'Cooper',
        emails: ['jcooper@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pavol Kuban,',
        lastName: 'MBA',
        emails: ['pmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGU0v5r5I6MDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604997396092?e=2147483647&v=beta&t=YI9EFBscHxhEx0K30qY-iOP_DYdUQdQEPE8loDXFpKk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'Roden',
        emails: ['mroden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFhc5paKgoYBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687214002671?e=2147483647&v=beta&t=4PU_bN5A9FAdIALIX3uHl16rGD-74i3Ty7D-QB9R4DM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Randal',
        lastName: 'Fischer',
        emails: ['rfischer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHI-YPcT-86Sw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516968164278?e=2147483647&v=beta&t=G30qC_MCBWK8FlL0omS5tfsAQE8WcPtXjDCKepJo44o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nextivityinc: {
    name: 'Nextivity',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Suzanne',
        lastName: 'Corbo',
        emails: ['scorbo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michiel',
        lastName: 'Lotter',
        emails: ['mlotter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFoRRQkpDsLSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647532600977?e=2147483647&v=beta&t=cbPxMoB3BKlFRwyEsS8LmQYQXlp8wIzaNqfQvnw84e0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephen',
        lastName: 'Kowal',
        emails: ['skowal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGW3vPNDrwaiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667610590134?e=2147483647&v=beta&t=xg6fg9bohl_PwJSCYLI9z0Xj8TTKoDXPZh0rhWKrMro',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jason Cook,',
        lastName: 'MBA',
        emails: ['jmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEo90SqtGefRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667607082073?e=2147483647&v=beta&t=tEGqhZvUyH8ctp5w-UUKA6V_dJjs_BIqkP25PwV7nQs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Erona',
        lastName: 'Liku',
        emails: ['eliku@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  miCase: {
    name: 'Mi-Case',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'DeClue',
        emails: ['mdeclue@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kwasi',
        lastName: 'Twum',
        emails: ['ktwum@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFycfPHjSPv9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666532114568?e=2147483647&v=beta&t=7JNZSSxZ-Tlu9_Xtj5qFHVnrKKjzrDEPuDUUqtRhUTM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mukesh',
        lastName: 'Murugan',
        emails: ['mmurugan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHItTggMQhLYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728697052340?e=2147483647&v=beta&t=Dd77J3N9EwtizdBXjSNxA_v3XyJwqPDs_R7bmdqh5eU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Williams',
        emails: ['cwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFbBpDO19-oQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729550384652?e=2147483647&v=beta&t=N_gLGyinFVYn4NYpwD9o5qL9eLHrBMZkbw145rk6zlE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sherrie Allen, MHRD,',
        lastName: 'CPM',
        emails: ['scpm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGoN4OnkH137A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718916436821?e=2147483647&v=beta&t=s8HSIHLHVVZgebVSUZloTXVwW1IRPe3EAM8i7BW3F7U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  medallies: {
    name: 'MedAllies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gene',
        lastName: 'Curtis',
        emails: ['gcurtis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGl64ApNzOwCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622230493881?e=2147483647&v=beta&t=ZeCHP19QIwEP2ccyWmms5ecUkv9WDmrkw9eWaeNxOCU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'A. John Blair, III,',
        lastName: 'MD',
        emails: ['amd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHRiTlhiY9pmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634149354652?e=2147483647&v=beta&t=Gb-iFmsSsswN-KbTQef5zi3RSVJCio4lbFYyhLRaVPo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dianne',
        lastName: 'Koval',
        emails: ['dkoval@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Holly Miller, MD, MBA,',
        lastName: 'FHIMSS',
        emails: ['hfhimss@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Edward Santiago, CHAM,',
        lastName: 'NCP',
        emails: ['encp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHCVRxpdQO8cA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517671310286?e=2147483647&v=beta&t=Kwoq6V9k46yacWeVw3XmTNezHRqE_wdhZVvVRsOwUtA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lbmx: {
    name: 'LBMX',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Greg',
        lastName: 'Dinsdale',
        emails: ['gdinsdale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGSFOVO7N26yA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676148305359?e=2147483647&v=beta&t=yx-nz7zuNli3Wo1_QUYv8wcUxIDEDmYKYYprmdnj_v0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tony',
        lastName: 'Feuz',
        emails: ['tfeuz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephanie',
        lastName: "O'Brien",
        emails: ["so'brien@example.com"],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Morgan Williston, CPA,',
        lastName: 'CA',
        emails: ['mca@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jim',
        lastName: 'McMillan',
        emails: ['jmcmillan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGNRXENR-_6cA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608458059358?e=2147483647&v=beta&t=5T38jNnok_NTwajE8cIAFZPr97bWqyrzAwLjoVc4VGA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fashionunited: {
    name: 'FashionUnited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jackie',
        lastName: 'Mallon',
        emails: ['jmallon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG4eSTz9phDDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685616020822?e=2147483647&v=beta&t=BcpMflj1rABTVdEBicKIa9JV9y_7i9ryMX2rK21OMUs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maggie',
        lastName: 'Schmerin',
        emails: ['mschmerin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jayant',
        lastName: 'Sharma',
        emails: ['jsharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEidwstZVEzdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702510057767?e=2147483647&v=beta&t=2_UYlMTREDmd5UwMHn6NZVd1AduBLQZmni7rnomccRk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Atul',
        lastName: 'Sood',
        emails: ['asood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHtZ7SBWLZYvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1537963454341?e=2147483647&v=beta&t=KhiyvtR9bXHo9gSTj-5MA8gwpPZUs1Yey0uY5-xaENc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elizabeth Joy',
        lastName: 'Magalski',
        emails: ['emagalski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEH39oLhFMwLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696000032166?e=2147483647&v=beta&t=GVoKa_xymy8-5bt2tQkYYC0Myhaf7QQfqmyibJxT6Bo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  stratusip: {
    name: 'Stratus ip',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Dlug',
        emails: ['mdlug@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bob',
        lastName: 'May',
        emails: ['bmay@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeff',
        lastName: 'Thomas',
        emails: ['jthomas@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jaclyn',
        lastName: 'Beri',
        emails: ['jberi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGaKwQNRKK5dQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709261402777?e=2147483647&v=beta&t=K1Rqkg2d_p12JbhUJIeLt_7SO6_1vy1enZpVh3WqvbQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christopher',
        lastName: 'Reinas',
        emails: ['creinas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHoM7AVpUAfDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691595641020?e=2147483647&v=beta&t=HxUu6jsQ667oUPlSxQWxFvy_8ZQHdy3u5rumcqeES-A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  oxfordcc: {
    name: 'Oxford Computer Consultants, now part of System C',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Georgia',
        lastName: 'Pocock',
        emails: ['gpocock@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGmHi08_fhAdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610540627557?e=2147483647&v=beta&t=RAiy_V_vu84ml7_72iIge49jKHaayk_2nLMhhgmGAp4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Boyle',
        emails: ['jboyle@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tim',
        lastName: 'Palmer',
        emails: ['tpalmer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHUydB3tcOzow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661099069034?e=2147483647&v=beta&t=5w2428DhHTNmzVJ-n8qi1OJl7VK7dGyfAdtWqUyb7Zk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rachael',
        lastName: 'Bartholomew',
        emails: ['rbartholomew@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHavTptlKXgYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590142842607?e=2147483647&v=beta&t=3ZYzsq7OMwij8p_LXe6gN2lRH_tnLH287ono0Ugjl8w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Siobhan',
        lastName: 'Farmer',
        emails: ['sfarmer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  portrait: {
    name: 'Portrait Displays',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Catherine',
        lastName: 'Meininger',
        emails: ['cmeininger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEo7V1HZWBl8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1538775619934?e=2147483647&v=beta&t=a72bhxBThpRTQ-XSy5bwBZbpAL3hCWBrpPD1z45Y6No',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alyssa',
        lastName: 'Geoffray',
        emails: ['ageoffray@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGLZVYKd6teuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546644309663?e=2147483647&v=beta&t=XcbShc4TxfKwm3UAHf90x0X0qY9WqZ0oPwoCf-EGGJE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Parker',
        lastName: 'Kennedy',
        emails: ['pkennedy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG__owiGjTQiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584515918595?e=2147483647&v=beta&t=vc9hrB91-iRepd0ggRPFL7ww3pjuW8_GlWCCTyvClbs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'A.',
        emails: ['da.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tyler',
        lastName: 'Pruitt',
        emails: ['tpruitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFL7hsDOn90fQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553110636684?e=2147483647&v=beta&t=_M3Cl0UanTH2umVq9KJ-p8GK8achCpRQvmJs6b4OHK0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  amaka: {
    name: 'AMAKA Studio',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tradinghub: {
    name: 'TradingHub',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Neil',
        lastName: 'Walker',
        emails: ['nwalker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nicholas',
        lastName: 'Buckner',
        emails: ['nbuckner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFQK_txt6vVOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718631998786?e=2147483647&v=beta&t=cIMxz0KCN9y1KNMIsZKW_qjZ1L4Tqyr_iykQcm2z99s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeffrey',
        lastName: 'Kramer',
        emails: ['jkramer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhhcFD0ZBtKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604885061753?e=2147483647&v=beta&t=04j4UJFgtQf8dvE4nrmTrig8jj2mBmPWhyifF3WINTA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Henry',
        lastName: 'Ashton',
        emails: ['hashton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHPpoAyU3tuiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517276906460?e=2147483647&v=beta&t=pbFYGXx0NIsRe4mN9ebJhg9E2tCVY96fTsuTMu1HrEA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stephen',
        lastName: 'Bergin',
        emails: ['sbergin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  amaxperteye: {
    name: 'AMA XpertEye',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Yannick',
        lastName: 'Liébert',
        emails: ['yliébert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFpcMpuYty-ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695571724580?e=2147483647&v=beta&t=n_RbtsbY7i_vUQYnl9WlF3dl2H9yFHkMth11C8Wp1rw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maud',
        lastName: 'GRANDIERE',
        emails: ['mgrandiere@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Lai',
        emails: ['slai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEh1QNEA7CYaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726809394517?e=2147483647&v=beta&t=-PAJCbzlaLafRuUxuBaL5pKUUN1Fe7MMyMWW-zAb7WY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Michael Nürnberg – Director Germany & Austria – AMA_XPERTEYE',
        lastName: '...',
        emails: ['m...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGDOBDNLnoEJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603592088442?e=2147483647&v=beta&t=Ka-uYqnst4ApTIjSAQq0AtHPlHhi9xMMOHtilP5wfw8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Solene Tilly – Sales Administrative Manager – AMA XpertEye',
        lastName: '...',
        emails: ['s...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  teamofficecom: {
    name: 'Team Office Srl',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Emanuele',
        lastName: 'Celegato',
        emails: ['ecelegato@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHJqMMeSRLL0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517554937571?e=2147483647&v=beta&t=djnrN1loByMNvZiXSnjHp-Qx3H0WljrOrYyZ4di1fv8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emanuele',
        lastName: 'Celegato',
        emails: ['ecelegato@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEl6rs9zr5M6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517526270094?e=2147483647&v=beta&t=-7IXyYMHVZmGhsUxPtMZnRiXC7pTqnUUWl7PtoFLJn8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carolina',
        lastName: 'Aiello',
        emails: ['caiello@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFoN3CwG5ouHQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517339704771?e=2147483647&v=beta&t=MN0Gv6ij77ZcIgYoPRwdp9B9Ldvy4VFl1YzwOB3DkCE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Boyana',
        lastName: 'Georgieva',
        emails: ['bgeorgieva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH-M1dGhQPzfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699758796592?e=2147483647&v=beta&t=hQRoZTj1eJdalK0-RKi0KwX1BNnTnfCdFHioE13f9Xw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ali',
        lastName: 'Hachem',
        emails: ['ahachem@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEd1YJA6RWKkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589802959747?e=2147483647&v=beta&t=ZMj8FjJMATbHzkFMXtBwzOU9J0r5Vtntw3kgxCxtdLA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trustedinternet: {
    name: 'Trusted Internet',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark A. Constantino',
        lastName: 'Jr.',
        emails: ['mjr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFIYxR7qMGCQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544149984221?e=2147483647&v=beta&t=y_f-wyw_cDS_L93C7sog5bg3AQTP2CV7bRFL3_NPBhY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'John',
        emails: ['ajohn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEd5vmDEND7FA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531924093522?e=2147483647&v=beta&t=HkjK8lNugjT841tStJ5IxJjhkc_XbwUY12YqFgXqEEs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeffery',
        lastName: 'Stutzman',
        emails: ['jstutzman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF2N-B-MAWuvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516251863844?e=2147483647&v=beta&t=Fhr7n1Yz0WgaFu1Xr93j3HQ6zqlXdzLXjEouKwlk_nA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Susan',
        lastName: 'McCrossin',
        emails: ['smccrossin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGW4p5OljHsUw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516277171345?e=2147483647&v=beta&t=WS--_lsQdbQNFD-Fvn119vHasPYJa1JYpuZ-MN_fYPQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nathan Paddock, CISSP, PMP,',
        lastName: 'ITIL',
        emails: ['nitil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFObtVHvNwODA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727820044918?e=2147483647&v=beta&t=ENl_aosLq_XllV6h-C3iA-C7xm-x6W6XABh6K6vOJ78',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ermetic: {
    name: 'Ermetic, a Tenable company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pavel',
        lastName: 'Tomshin',
        emails: ['ptomshin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGI1qbioc82NQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724508622448?e=2147483647&v=beta&t=W674eusdpb0MfVf5oRxY7fzbMMbwrhvZePN9hLJHoQo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Esia',
        lastName: 'Yosupov',
        emails: ['eyosupov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yan',
        lastName: 'Tesis',
        emails: ['ytesis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHWOxSkmBTAXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517238636916?e=2147483647&v=beta&t=ueJ5w66r2GJg89re2IYSpYfwbXU7sJHlZ6ZrXb-fPeo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ben',
        lastName: 'Blum',
        emails: ['bblum@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sagiv',
        lastName: 'Sheelo',
        emails: ['ssheelo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGMYYmKoQRmOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686960699526?e=2147483647&v=beta&t=y-oPfpmZ4tD91rXW17lJwc-GcSQN8s6MJXVTiu-maQw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  arkan: {
    name: 'Arkan Integrated Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hussam F.',
        lastName: 'Hassan',
        emails: ['hhassan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHGKOI3IYhZNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712528163386?e=2147483647&v=beta&t=tlxLhzt6mlYM3vyfHW_kh70rOMMY-0akffQMq45bJ30',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sandy',
        lastName: 'Gamil',
        emails: ['sgamil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGEczEfKYyQ1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729157018324?e=2147483647&v=beta&t=ev3V8VYuGWhwylrByWvuM-VjVyzPmT0_8n6tn0Mqq50',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Hoda El',
        lastName: 'Behairy',
        emails: ['hbehairy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGGdFd4ZMw7GA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725493536649?e=2147483647&v=beta&t=x0UnXrCpzOkNL0YQU-ylCWEiZW8kCf8lITLYYOfYO4A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mohamed',
        lastName: 'Hamouda',
        emails: ['mhamouda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHSFFWszDouOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689812659332?e=2147483647&v=beta&t=_XSUe7fnCfGQCzF5LD-UrWt8wjXuyw2__XaoojqtlsE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Erol',
        lastName: 'Arkan',
        emails: ['earkan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEuX5KINNOrkw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567503577222?e=2147483647&v=beta&t=NSOWNMS-FbHFUD5pGKiLRidjSNDrGlL1RYkxSzE1H1g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  farmaprom: {
    name: 'FarmaProm Polska Sp. z o.o. Sp. K.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Padriac',
        lastName: 'Farma',
        emails: ['pfarma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF47l1dpMVxwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680097820521?e=2147483647&v=beta&t=4nefbHuu7yGLusbLkToaiWr7dE2Faf78JfNZRP-b_fE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeffrey',
        lastName: 'Farma',
        emails: ['jfarma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFf4yu9ukLojQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516307695650?e=2147483647&v=beta&t=UTPZUepvsWFTvWnYBGSCDzcdF9EGGfhwkVLWFy-qwOQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Farma',
        emails: ['mfarma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHZKok5LHPUBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516481905504?e=2147483647&v=beta&t=_hXcOkHKP-Mza7k6zBOh7-Ek9-GL7OXtubr16gD9Lck',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Katarina',
        lastName: 'Trajkovska',
        emails: ['ktrajkovska@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFlGK9vvMaP0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582717612957?e=2147483647&v=beta&t=-Z7T0OpxfPVVVDr3ZugBlV5UfiTQM7pRpqkb3B343_M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tomasz Swatowski – Tester oprogramowania – FarmaProm |',
        lastName: 'LinkedIn',
        emails: ['tlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGQ1ARyPjQR9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1525116878654?e=2147483647&v=beta&t=CZpvp-7NMZysEurIGVfO5w-vu4CwkNHPt2uj8AuPvMo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cadcorner: {
    name: 'Innoptus',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steffi',
        lastName: 'Frenier',
        emails: ['sfrenier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGt981c4sztyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655900628858?e=2147483647&v=beta&t=N7DwS8o56VhMq_HE1tLrAzzVGli1-GVnA7_mFwiJ7yU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Koen',
        lastName: 'Coppens',
        emails: ['kcoppens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEvb0oqH6shhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729088474009?e=2147483647&v=beta&t=38WaDngsVTeomEVb5KS06n0d2LUI9OARiTak4jbCgzI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Luc',
        lastName: 'Verbeke',
        emails: ['lverbeke@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paul',
        lastName: 'Wijsen',
        emails: ['pwijsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG1nRIbUmJULQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1540883981088?e=2147483647&v=beta&t=Py3mS_HYlC4qEilJqqlf4YpI__J4MI4QfyUoLxC7blc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steven',
        lastName: 'Maertens',
        emails: ['smaertens@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ivisionconsulting: {
    name: 'iVision Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pallav',
        lastName: 'Thakkar',
        emails: ['pthakkar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH3Hrb9EHaSCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516278236019?e=2147483647&v=beta&t=LmAReilRPjUAQVoQKtFstHiS_nFAeRvRjUq4iSuCANw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lisa',
        lastName: 'Sullivan',
        emails: ['lsullivan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEdXmakO5r6ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516830427621?e=2147483647&v=beta&t=r80I3zl5CzVp6Rx345DuM4gh2WP2uvQJYIsNEb6Y4ZY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Omer',
        lastName: 'Samiri',
        emails: ['osamiri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHN-CtgQKODjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712347950055?e=2147483647&v=beta&t=snRp3tMOeUT6GX3ZFRmZo2P2e9FIeYHwo8WTsoBprEE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chaitali',
        lastName: 'Thakkar',
        emails: ['cthakkar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tom',
        lastName: "O'Donnell",
        emails: ["to'donnell@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHPP2Fv311rug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517744959464?e=2147483647&v=beta&t=_nHWQ398DAyZEaNb06vMl0Hp2j6I0R74QBUnC-Xsi1g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aavamobile: {
    name: 'Aava Mobile Oy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Martin Aster – Director Product Portfolio Management – Aava Mobile',
        lastName: '...',
        emails: ['m...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF_BBH7DDnMWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574624664381?e=2147483647&v=beta&t=FGKg7k1HNoKPMMlYHnjgG8yFUKcdUCvnjaZbhLq3l84',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Katariina',
        lastName: 'Laakkonen',
        emails: ['klaakkonen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFst4g_cyavsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602068326940?e=2147483647&v=beta&t=nPDYYLeEEorUb8xrFU7vgz9YSkqkw-j16q_uwIeCuFA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sushanth Sridhar – Project',
        lastName: 'Manager',
        emails: ['smanager@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEMahUw6oT1Pg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652981961970?e=2147483647&v=beta&t=KqAj-_PIvxDCA5WXu097NJ_iR8-c1UOUUCipUYytYLw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Roberto',
        lastName: 'Palumbo',
        emails: ['rpalumbo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGCbBSd0c7Iaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580150327889?e=2147483647&v=beta&t=L0sUkL42i4JIkgRVfQG0MNtvNp2QlsAU-GXwUIRoxqQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ida',
        lastName: 'Räisänen',
        emails: ['iräisänen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFXDlFKXzTp5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723968949083?e=2147483647&v=beta&t=5-ENeg1yn2QsfB4U1LakHpIVD3vhK8bUTMmhrF1noAM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  changecx: {
    name: 'ChangeCX',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anto',
        lastName: 'Raphael',
        emails: ['araphael@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEegMv8xDR6Xg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707450753949?e=2147483647&v=beta&t=Ec6kXDP94e9KGgL6waK0_7qVRt8MoL-W_5oEdrpgYqY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Christo Reegan',
        lastName: 'Raj',
        emails: ['craj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLthV-467rSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517538849105?e=2147483647&v=beta&t=QeFNkkSa6FDeIe3NjLekhKTsIn6u_y9zkwlMj3It6k4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Venuprasath',
        lastName: 'Parameswaran',
        emails: ['vparameswaran@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eswaran',
        lastName: 'Thangavel',
        emails: ['ethangavel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHM72ryPX8mCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548758471602?e=2147483647&v=beta&t=3FWgBkuwlwLds3pfhqfUnmMh8y_Zw3P2gFmjaQZGf_g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kishore Ashok',
        lastName: 'kumar',
        emails: ['kkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGUAMtUVo9Pzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664771069219?e=2147483647&v=beta&t=8x_xxWYalEbGvJ3lbID5cK_R92AaQtXHyXEohcALKuM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ipponusa: {
    name: 'Ippon Technologies USA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dennis',
        lastName: 'Ruzeski',
        emails: ['druzeski@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Romain',
        lastName: 'Lhéritier',
        emails: ['rlhéritier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEuEmzSp_lJ9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652291134199?e=2147483647&v=beta&t=jIR7Ir28ZmDaq-pqEGMBM3lxGvFBqdtgEUKDuDa4zlE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erin',
        lastName: 'Geoghan',
        emails: ['egeoghan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF7QC4vS9ARLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662754226120?e=2147483647&v=beta&t=tCOZf4ILF3cuOEa4qHinqpEIx5sRSNPiUFG5h1eemnA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lucas',
        lastName: 'Ward',
        emails: ['lward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGpyjbo3ITvrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585337933085?e=2147483647&v=beta&t=m0Lp7SzGClf813q3t-V8xc-WfP502JCOaOyZkRiWRm0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jake',
        lastName: 'Henningsgaard',
        emails: ['jhenningsgaard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHJOAlYmyIawg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573923457697?e=2147483647&v=beta&t=d9sKI1Hs2GKG0Xa_bwalasjaSwcbNynzSRpytostI5M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pantomath: {
    name: 'Pantomath',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sean',
        lastName: 'Spade',
        emails: ['sspade@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE-lArTxkaTnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591755067041?e=2147483647&v=beta&t=L2hPUYfUGIvNb-EafZYA8C0shETqMb4GxLNSQsK7Vog',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chuck',
        lastName: 'Mobley',
        emails: ['cmobley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEAOU5sdyYmVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517702945995?e=2147483647&v=beta&t=xp7BoL8qF8PihRYLQUdXpT3cZ3W3R-7zh8wXVrcjgWA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pete',
        lastName: 'Keenan',
        emails: ['pkeenan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG8ivPsnuHiFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622307244446?e=2147483647&v=beta&t=GKO3-ZL5Ap1Fz_72xaheRu-gg93JkcnS6oz7XpXbpZI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Susan',
        lastName: 'Critzer',
        emails: ['scritzer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGgx0an8UZZ6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685974559409?e=2147483647&v=beta&t=DVo8FQDeQKqcDwk9RbRmpobQBLla7YtGn2FVT5Q18x8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Peter',
        lastName: 'Walters',
        emails: ['pwalters@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFQH6bjNR5BAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516239295720?e=2147483647&v=beta&t=A5qODBNd0wD9ZiwGHO3ijt75bbn0Qn-JUhvUawMVUq4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  spherica: {
    name: 'Spherica',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Dearden',
        emails: ['ddearden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHi8exVO8kegg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652836150947?e=2147483647&v=beta&t=EEL1lQ50oip4K9P1DsNFcTaFWZ5AgoLFErCotO__aew',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nick',
        lastName: 'Apps',
        emails: ['napps@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF5A9AP5ACPRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516935853370?e=2147483647&v=beta&t=RM67Nqut00LuxoVKGKICc0yAEsVGtdHLMQ5llTeRnwM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Lewis',
        emails: ['mlewis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFVYGgDXBdYTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516482287571?e=2147483647&v=beta&t=fdKFXpAbYzcVOKPF5vOTbTpJDtVbXCZMrSmKkpKcDDM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nathan',
        lastName: 'Edwards',
        emails: ['nedwards@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Masood',
        lastName: 'Ahmed',
        emails: ['mahmed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF9UxQEN_1ScA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678122535545?e=2147483647&v=beta&t=aMWmMAxm_5QFjQpuN-EZEAaDpgUlahwF5nDzCpiR4nI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ibisInstruments: {
    name: 'Ibis Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Branko',
        lastName: 'Popovic',
        emails: ['bpopovic@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marko',
        lastName: 'Tasic',
        emails: ['mtasic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFqwfh70myyhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575328920472?e=2147483647&v=beta&t=rCiWyF6tlsSNX4EZ0lZLeClwF6mZmugohvYMhyVbwPI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bojan',
        lastName: 'Nikolic',
        emails: ['bnikolic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHTgkUlu-bUqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711375994083?e=2147483647&v=beta&t=lxSdumm0T2USmybf-SONH5h_w4mgSGcWs5nEBXFhvvY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanja',
        lastName: 'Zoric',
        emails: ['szoric@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGQykUB5NemlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712173141266?e=2147483647&v=beta&t=Tw7qkyi13zoN_SjjieshjJYRuno9hO1uAwlkMxpkUXc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ivan',
        lastName: 'Veselinović',
        emails: ['iveselinović@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF8VxNrzjbUKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633695457023?e=2147483647&v=beta&t=uwvShsoD0lDWZbT5e-mnfmAzEPgCXVAr_g3CQO87wh4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  exertis: {
    name: 'Exertis | Sverige',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aaron',
        lastName: 'Plowright',
        emails: ['aplowright@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGXbps7VoLclA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679610557925?e=2147483647&v=beta&t=E4jRLNLphmr0-sdPKZ-hOgSXQQtheZ0zFk8-mhkPvsQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Becky',
        lastName: 'Shelton',
        emails: ['bshelton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEpAT5pT_yYjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727341652969?e=2147483647&v=beta&t=ielD1yM0Ic0IIbGapagXyGpAuVk1R_pabpPu3_fcYMg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'Seymour',
        emails: ['jseymour@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFWeI2Z43DR8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637768263370?e=2147483647&v=beta&t=IvObheKatKRI3S9K3Lma93dLB5igEJ4Vhqjo2CujZHI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Louis Armstrong',
        lastName: 'Rogers',
        emails: ['lrogers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHdqAGvYR-9rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517371064064?e=2147483647&v=beta&t=BS2K7a9-570AaJvbg1gxmbouLzke13cqBKIOcGORKAo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lewis',
        lastName: 'Thompson',
        emails: ['lthompson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '4friendsplayrix': {
    name: '4Friends',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Craig',
        lastName: 'Starnes',
        emails: ['cstarnes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEyVtNyKtMkEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659451316504?e=2147483647&v=beta&t=tdVRMptMVjpaFgsZUronjXH3RfM0kJvwDW3E9kiO0t4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Luke',
        lastName: 'S.',
        emails: ['ls.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEJhqS6sqnAYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693878822373?e=2147483647&v=beta&t=2eBsEaNdYRwRCBVqi7NEqn93u0S2M15t_lv_sH-cNGw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Magali',
        lastName: 'Four',
        emails: ['mfour@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Megan',
        lastName: 'Lewis',
        emails: ['mlewis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHpVL_Bzse3Jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729353575029?e=2147483647&v=beta&t=R36fzL6dAS9ektsrGpzJY3E9kUQ3rgnOqxosvj_DQgQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jacqueline',
        lastName: 'Nelson',
        emails: ['jnelson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gigacloud: {
    name: 'GigaCloud',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Erica Wei,',
        lastName: 'CPA',
        emails: ['ecpa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vivian',
        lastName: 'Situ',
        emails: ['vsitu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Chorna',
        emails: ['schorna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHWQ7wTD6bSXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669832490883?e=2147483647&v=beta&t=15kjklHCjrJEYi09OfFxLuPEwX3oIfvFPZuOuEq2694',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Greta',
        lastName: 'T.',
        emails: ['gt.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kai',
        lastName: 'Chang',
        emails: ['kchang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ispsystem: {
    name: 'ISPsystem',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  principal: {
    name: 'PRINCIPAL',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'ian',
        lastName: 'Bearman',
        emails: ['ibearman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHoCnCWJ4oGSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624898153155?e=2147483647&v=beta&t=U6MI6X_lD6cbGxqcKN5gPzGhf605_OI43CNl30mBtbw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gabe',
        lastName: 'Hoffman',
        emails: ['ghoffman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEB4bmJhwHq1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679972721918?e=2147483647&v=beta&t=tElozv7W6GR8dlsT_krh3aWiIN-4CMDl4lfKfzxEYGM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Esteban',
        lastName: 'Smits',
        emails: ['esmits@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG6nAcXprofXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659583591785?e=2147483647&v=beta&t=RKjjAbmEkSinpqvhbjkTON9m0C-rF_LgAvd1EqLfZ-Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Sosby',
        emails: ['dsosby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE9cgXON95OjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727413236534?e=2147483647&v=beta&t=GNOUKn9YAf-F4QwvvDZSebUyYyAYxc6dS_HS892vh2k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carl',
        lastName: 'Dunham',
        emails: ['cdunham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE6sfePZDDpxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517004217945?e=2147483647&v=beta&t=sVO5m5zGOs9j_NZR_EN4k8IkEhKPqldXURw5w_kQRrw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  basekit: {
    name: 'BaseKit | B Corp™',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Simon',
        lastName: 'Best',
        emails: ['sbest@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Charlie',
        lastName: 'Bestall',
        emails: ['cbestall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHG1HLVYChayA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623244946363?e=2147483647&v=beta&t=pckE2arxWckPbPWKkJTCyQc1Dg8b34YmDWx3KTl2jnk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carl',
        lastName: 'Kill',
        emails: ['ckill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFYgkHG1oAmvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695821898680?e=2147483647&v=beta&t=Up8nqmmlbF__XWtpgHt3F9uc3uPpYFoLgsWxMZ0_Lh0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Wendy Weston Chartered',
        lastName: 'MCIPD',
        emails: ['wmcipd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGTwEexVdzh_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643801835305?e=2147483647&v=beta&t=F0p5KGF6cag9p3hqwXVjjXArqzRvXDl1uXuAhM2FXLg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Greg',
        lastName: 'Easton',
        emails: ['geaston@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eig: {
    name: 'EIG sas',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Roger',
        lastName: 'Fritz',
        emails: ['rfritz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFCAQICXVpgCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701350896296?e=2147483647&v=beta&t=R_7ig5gPMSj28ftcVX06y3WB1DMwgIGTEJU6KmQHg7Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sean',
        lastName: 'Murphy',
        emails: ['smurphy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGTR966xCscXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517756430321?e=2147483647&v=beta&t=HAdwVdf2tYyUwlmzkxemO1Ofa9ttayaXAg6TOIY3yZY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Fox',
        emails: ['mfox@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nicholas',
        lastName: 'Williams',
        emails: ['nwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHAtmxCYnqheg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517460081915?e=2147483647&v=beta&t=Fw7INDznu1Hwvk_62PJtaLFypjtYssxRBO2GRBUjhrY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rob',
        lastName: 'Johnson',
        emails: ['rjohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vectorSoftware: {
    name: 'Vector Software Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anna',
        lastName: 'Pozdniakova',
        emails: ['apozdniakova@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Olha',
        lastName: 'Fedorko',
        emails: ['ofedorko@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Verna van',
        lastName: 'Schaik',
        emails: ['vschaik@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matthew',
        lastName: 'Berrigan',
        emails: ['mberrigan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH5ndx8mgQFmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645505631554?e=2147483647&v=beta&t=liMxqZXgSyw1uXisYD6yJat8N1F1e9F6TRISi2X8IDM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tausani Ah',
        lastName: 'Chong',
        emails: ['tchong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG7ROP5vDvRFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646113032504?e=2147483647&v=beta&t=Ogwhfh3fI1p9EJiAnPSJlb0zhpuAIRjZMA9g7gtDLOA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  productdock: {
    name: 'ProductDock',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aleksandar',
        lastName: 'Popadić',
        emails: ['apopadić@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHmldP65m93fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718870152343?e=2147483647&v=beta&t=rJ4iJVouYea8ej_-K3f8q5J407DqBOUMC2qbZxG0PB4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ozren Gulan – ProductDock |',
        lastName: 'LinkedIn',
        emails: ['olinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGEDl1A1R7cHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669138967461?e=2147483647&v=beta&t=C0UNQgKUMOWB5smiUuP1hibhmlgyTxXmEDlIg_aojS4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Adriana',
        lastName: 'Martinovic',
        emails: ['amartinovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGA5gpp_PEwAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517571784921?e=2147483647&v=beta&t=9jXkXE7sbwyTzGL1ORAjMkq8cIxKKATCpslocVJ2t6k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Saša',
        lastName: 'Jovanović',
        emails: ['sjovanović@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGVgySR-XcrlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719027242720?e=2147483647&v=beta&t=K7RsC80AGIaYzY6Om7QxYnKI0-toqgzrRwypLMrt8fg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mladen',
        lastName: 'Marinkovic',
        emails: ['mmarinkovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHxfuW6wQfeYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721297736054?e=2147483647&v=beta&t=RgNmHS5jwk366AJ4i8gaUqFZDukrfBHDkS1MA-OtBOc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  parraid: {
    name: 'Parraid, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Parris',
        emails: ['jparris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEz2t3vkc8D0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517705240156?e=2147483647&v=beta&t=z3Dlf3tBqhF3AE-QnuIOLRjacvyRCpYP1iLVm6_XJQI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jen',
        lastName: 'Henkel',
        emails: ['jhenkel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Linda',
        lastName: 'Williams',
        emails: ['lwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGonr0P3vK46w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651674813070?e=2147483647&v=beta&t=e9zkMk_1fEtF11RUsou3zJYeuzfgdwELokhwOF3EJSo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Steve',
        lastName: 'Bishop',
        emails: ['sbishop@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matt',
        lastName: 'Molzahn',
        emails: ['mmolzahn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE9jACQMGXtng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605624160686?e=2147483647&v=beta&t=oImsDa5lmv0cAjl4jymuN1u6--GMR-boJ7TnWXYM89g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  padam: {
    name: 'Padam Mobility',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Javier Guimerá',
        lastName: 'Tena',
        emails: ['jtena@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG8Vj1k9hEYPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551371902491?e=2147483647&v=beta&t=Y1bMj-bdQfoY7Kg7y6dQac3eCukJBFj9rE1QeG8bQFE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Carnero',
        emails: ['dcarnero@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGPS2oy25BJjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516172441189?e=2147483647&v=beta&t=8JhkHgGe0b6SwDiZ5cufgQkGNa7IW2ID5FpniGXTrZI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Teresa',
        lastName: 'Gehling',
        emails: ['tgehling@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEwQpK8GdzhvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578697712121?e=2147483647&v=beta&t=nX2sP-XrMCfVi9wIlYaH3cunE2fOJt7xso3shHIMkcU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Fiona',
        lastName: 'Mauroner',
        emails: ['fmauroner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vanessha',
        lastName: 'Beekarry',
        emails: ['vbeekarry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGhfINsiJ_fDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644784742678?e=2147483647&v=beta&t=Kx0UIRQq1K3vZwo-8C07-25QIBSKXdRyphrAuMhRRSI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  horizonquantum: {
    name: 'Horizon Quantum',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amanda Chew Qian',
        lastName: 'Yi',
        emails: ['ayi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHzEqkS05TfGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705295709501?e=2147483647&v=beta&t=bTrB02cdfalkUwZOv5R7F4AO4RjrIgUwfn_SUGuIHsw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Yanina',
        lastName: 'Blaclard',
        emails: ['yblaclard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFfHzAs5wJ3pA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600944005856?e=2147483647&v=beta&t=2l6ab2wvrzExed2kdYB5TSZDAzbA0XmCLfQOGeblpDo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Angelina',
        lastName: 'Frank',
        emails: ['afrank@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEgjsWP2AZSxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695622697696?e=2147483647&v=beta&t=OIJ6RpocHdmRCcEkdSzk_BvzxDVZYRXK4-WshzE6jco',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amir',
        lastName: 'Azar',
        emails: ['aazar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF9eWTSXwI3bA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516551120454?e=2147483647&v=beta&t=dnfnOSWEAsl5iu38HJzm7bma1RndNxW0Ixufuxo6uss',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jansen Zhikuan Zhao – Horizon Quantum Computing |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF3xWhu_pZNow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661605925273?e=2147483647&v=beta&t=KzH9PF7uRvBa1tW1TwHiUc0lmgK5NfqDJ0iBKvD6wdQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  maguengroup: {
    name: 'Maguen Group קבוצת מגן',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Zvi',
        lastName: 'Michaeli',
        emails: ['zmichaeli@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gregory',
        lastName: 'Grig',
        emails: ['ggrig@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGWN3cE8Y7Ebw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702992246375?e=2147483647&v=beta&t=zuszvqyWdQcxUd9ecxQeLer-845d1o37nNuJwsg6mO0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shay',
        lastName: 'Erez',
        emails: ['serez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dror',
        lastName: 'Shikragi',
        emails: ['dshikragi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGPUTYA0-2g3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682052429693?e=2147483647&v=beta&t=PsNx6ZbYTtCxCeVACdVywNb3kX_7w4iZj4jIK2OTt9Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shlomo (Shlomi)',
        lastName: 'Asraf',
        emails: ['sasraf@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG7BG6N2MSd3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683591493157?e=2147483647&v=beta&t=dDQJvZOTNXwO3jUfKRr-yQfm1vPifXqHzULP0l8Il3g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  delphime: {
    name: 'Delphi Consulting Middle East',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'ANKIT',
        lastName: 'BHUTANI',
        emails: ['abhutani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEO4mYiHHM8MA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726220405270?e=2147483647&v=beta&t=tiGFUP6S--BQuBVnyFGFCGD41timjG0gruBCqDGsUSo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Company',
        lastName: '',
        emails: ['c@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Muhammad',
        lastName: 'Tariq',
        emails: ['mtariq@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rumi',
        lastName: 'Bhattacharya',
        emails: ['rbhattacharya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGwNDzPETOYAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656351522451?e=2147483647&v=beta&t=5PEHy_3BRqpT5tVsGDMO2P82bEYxOvrV19QzEIoSX3A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hriyaan',
        lastName: 'S.',
        emails: ['hs.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tribalco: {
    name: 'Tribalco',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Justin',
        lastName: 'Takasaki',
        emails: ['jtakasaki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGIGfIksXSSSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705365199361?e=2147483647&v=beta&t=GU-X7h4mOABq4qLZzZCiB1G6wTs20K-dUzf2S6RDhfg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stephanie',
        lastName: 'Vallar',
        emails: ['svallar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFTUWc0j2itMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634182402752?e=2147483647&v=beta&t=eLgbTf-Ub_4QDJGvD3GKiqz1KMssOtHEO0vxZOIYXmo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patricia',
        lastName: 'Becker',
        emails: ['pbecker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bryan',
        lastName: 'Hastings',
        emails: ['bhastings@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFm9FIi5mxo8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516629873416?e=2147483647&v=beta&t=lFHVmyVQCEjd-Dn4l1INFVuj-7UPGfq_ZUuDPblTXoE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alvaro Castillo',
        lastName: 'Donado',
        emails: ['adonado@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFnk5AoHQEZ6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653480496879?e=2147483647&v=beta&t=dyyilAZp97n-xm58sO9VotD_fh98dSK1G8roUq63-LA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ezops: {
    name: 'EZOPS Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stephanie Del',
        lastName: 'Mistro',
        emails: ['smistro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEIABNya919HQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517283818420?e=2147483647&v=beta&t=vTTVZV0F6SnOkL_rG8oNb33earvdgOkJrgHOAkWcs_A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Numaan',
        lastName: 'Sheikh',
        emails: ['nsheikh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEoCDdanz3pLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628971665899?e=2147483647&v=beta&t=Yo1UDduTTupUqZ8mH7a1Ev7gUTOeqStDEGvXdc0P05M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kiran',
        lastName: 'Patel',
        emails: ['kpatel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Louis',
        lastName: 'Mitas',
        emails: ['lmitas@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anku',
        lastName: 'kumari',
        emails: ['akumari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEuXraIrdwp4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723561748969?e=2147483647&v=beta&t=vAhslTH4tZ79_nGBvQ29siYeEohc_HqyiOuFltgywu4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  possiblenow: {
    name: 'PossibleNOW',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rob',
        lastName: 'Tate',
        emails: ['rtate@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG3JedsDHGDLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629213292016?e=2147483647&v=beta&t=CG_pH_0b5uDDVdEX6mivZhgroCi1gENz6ldq6tRasz8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Drew',
        lastName: 'Patrick',
        emails: ['dpatrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEyxc-vCXPnVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702996947160?e=2147483647&v=beta&t=lwQrU7vbdhJwFXtL5sAXxoYwH7iGSPBmqM22-_05zbA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Frey',
        emails: ['sfrey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHlByltxHzrpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623349502728?e=2147483647&v=beta&t=TEPon5pvQOwnuz0phOt6ElZy0upn1yLXxdwC6kFzpnU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Danny',
        lastName: 'Hitt',
        emails: ['dhitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGT0jhayUIZiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568658137790?e=2147483647&v=beta&t=mZC5Gt2MasMlZcT6FmbzIs1P9rPXJ1e1BFXRY4Tzv8k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeff',
        lastName: 'Jarvis',
        emails: ['jjarvis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGuqBjYL1x5Xw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1640296755882?e=2147483647&v=beta&t=4iOv8xVrSgKsHB2DQYl0bAJeaPN9rz1YyxFSHF9pO60',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vamonosit: {
    name: 'Vamonos IT LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jenny',
        lastName: 'Bunch',
        emails: ['jbunch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHI4yeS72NQrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727728674740?e=2147483647&v=beta&t=qVLlJBl9VNFEeADISI15AvYLQ-xgyqrg8zegc5veSlQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Trystan',
        lastName: 'M.',
        emails: ['tm.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sara',
        lastName: 'Saavedra',
        emails: ['ssaavedra@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joseph',
        lastName: 'Tyner',
        emails: ['jtyner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHD0hI8SSXznQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517600278622?e=2147483647&v=beta&t=QPc4TY2LB47G2TndFfwVh4G5WTJRcbUZM6xVCl-qnh8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Current',
        emails: ['ccurrent@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  accelq: {
    name: 'ACCELQ',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mahendra',
        lastName: 'Alladi',
        emails: ['malladi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHNteBzutE_7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645452942220?e=2147483647&v=beta&t=NHuQh6dtm8OEa6ULMPc-8hQG76Hmp1gKMn6PZsKrW5k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nishan',
        lastName: 'Joseph',
        emails: ['njoseph@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE17EXHC170Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593387024476?e=2147483647&v=beta&t=LfS8HfBxQy3Jw2qbCiwBTKbC6cKgnGBq5qkmElXxmQI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Geosley',
        lastName: 'Andrades',
        emails: ['gandrades@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG7ii0zImHnQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699172306536?e=2147483647&v=beta&t=YJsHUiFHAorXzOX7QhjW3-lBfewzNfhVf_TWDnN2zoQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'McGinnis',
        emails: ['jmcginnis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sham',
        lastName: 'Reddy',
        emails: ['sreddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFCz--pGMoTTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592386287160?e=2147483647&v=beta&t=5b1ZKSZ9QaP0NquHWdun6IyY8Jl4ZQ1ldpEPcg_SXFI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bisok: {
    name: 'BIS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dan',
        lastName: 'Rotelli',
        emails: ['drotelli@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Triton Imaging Systems |',
        lastName: 'LinkedIn',
        emails: ['tlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFljl-FEh-25Q/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517002673771?e=2147483647&v=beta&t=tCPXVmLa63EAyFKS6PeLFmPAB7__vSi-K4a2se9zZ9g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Orthner',
        emails: ['morthner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Candy',
        lastName: 'Parr',
        emails: ['cparr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kathryn Smith,',
        lastName: 'CDIA',
        emails: ['kcdia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFUkqg8JQwu6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517742103483?e=2147483647&v=beta&t=5W7iGhKRt9ANsxADmjGNMd0xKYDC4hkufvstwSybmSQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cloudtaru: {
    name: 'Cloudtaru',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'VSN',
        lastName: 'Murthy',
        emails: ['vmurthy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEQQl5RbBQIfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517362773981?e=2147483647&v=beta&t=rWjVSTO8uATdSeY-vhgmP-EK8FCxz60TE_SWiTtsVpM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Prathiba',
        lastName: 'Naidu',
        emails: ['pnaidu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yogesh',
        lastName: 'Dhingra',
        emails: ['ydhingra@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Prasanth',
        lastName: 'Nalam',
        emails: ['pnalam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE9bNyxh1c0GQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698767952245?e=2147483647&v=beta&t=xDriMnqOqN5iWd8T5hObo6tS-jwwHks7mMPiznMLZ3U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Venkatesh',
        lastName: 'Sarikonda',
        emails: ['vsarikonda@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  corinthcg: {
    name: 'Corinth Consulting Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joe Mario',
        lastName: 'Davis',
        emails: ['jdavis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rich',
        lastName: 'Sadala',
        emails: ['rsadala@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alexis',
        lastName: 'Mejia',
        emails: ['amejia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFE8AxnTrWm1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517260141458?e=2147483647&v=beta&t=em6k4txHIbcZ3RT4VzcI6SWY1WFQp6B2kHg-B8oAfqo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Williams',
        emails: ['cwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHL7nQxs_RUVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667397433798?e=2147483647&v=beta&t=bkBEEtHc7auhu8dy8u7lGimsGeqqi-8WqQkEFDqieZI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ines',
        lastName: 'Mrkanovic',
        emails: ['imrkanovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFDCkM6Z3xrQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705965515229?e=2147483647&v=beta&t=aSnyvVHrsfmfALS5hRxNKFo3MP9CCvWroDme_PEq5R4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  panasas: {
    name: 'VDURA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tom',
        lastName: 'Shea',
        emails: ['tshea@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Faye',
        lastName: 'Pairman',
        emails: ['fpairman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFbld2SJhu7Fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516498597415?e=2147483647&v=beta&t=yUi_a_f0tShv-ruWJLuZ6m6zwZatpVGDDTMx-JRuzaI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bret',
        lastName: 'Costelow',
        emails: ['bcostelow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGcO6OcAEr-3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636403265380?e=2147483647&v=beta&t=oUf3IuUIfUCNVjQVr6aMfwmZ3b1ZMJd6_j3tsC-uZAM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brian',
        lastName: 'Peterson',
        emails: ['bpeterson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Erik',
        lastName: 'Miller',
        emails: ['emiller@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aravo: {
    name: 'Aravo Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Saracini',
        emails: ['msaracini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGq5ZKNMsYYdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584315851524?e=2147483647&v=beta&t=mqdXsbTY8R5tfKMlPTjuPm4tysYn9g8iJF-z5-WEMnI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Christina Pirkle,',
        lastName: 'CPA',
        emails: ['ccpa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dave',
        lastName: 'Rusher',
        emails: ['drusher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHvrMvcth3QdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584025028826?e=2147483647&v=beta&t=rtuCWWCGiPyT56DHnvp-r7QxOO5EmRqkzdzGLOgb9f0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dean',
        lastName: 'Alms',
        emails: ['dalms@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'May Z.',
        lastName: 'Pryor',
        emails: ['mpryor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGBUJAXlU6XPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723302552605?e=2147483647&v=beta&t=YziiCVjsiyfpEivpSY7eXTbyKKsHk0sdudYYqrjklvw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  apptricity: {
    name: 'Apptricity Corporation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christian',
        lastName: 'Garcia',
        emails: ['cgarcia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHzayEuWgQGlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632493815722?e=2147483647&v=beta&t=3ajYz6XxMCkhrLDToqR5PiMyFcT3Dqf4u2wYJ38EsCE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tim',
        lastName: 'Garcia',
        emails: ['tgarcia@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Justin',
        lastName: 'Garcia',
        emails: ['jgarcia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGwdOrBCuoKeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553528160957?e=2147483647&v=beta&t=pJwSybGSe_9mssqo5NrZRxvwgt4X4I0ZEOMx7qGfOxI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anu',
        lastName: 'Datta',
        emails: ['adatta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFuMG9eZuOrvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720559071999?e=2147483647&v=beta&t=ghPGiCFYFowmWrofKGGIaybfIzOPHTqolQCI_0UL0bo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Makunda',
        lastName: 'Thapa',
        emails: ['mthapa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEsUNjuoj42EA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572906600031?e=2147483647&v=beta&t=82FLHU11CzfcRJ4nA2ToZWLR9z2kNuhzH9c-8mnsSpo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  niyamit: {
    name: 'Niyam IT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Doug',
        lastName: 'Bausch',
        emails: ['dbausch@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gautam',
        lastName: 'Muralidharan',
        emails: ['gmuralidharan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shiva',
        lastName: 'Dacherla',
        emails: ['sdacherla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daniel',
        lastName: 'Horner',
        emails: ['dhorner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG_qkz5uqFBUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684346040654?e=2147483647&v=beta&t=v2Ud7usr5ziZtlywv0AMpH6IzjfYDuaYxaEsGsdFDkI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anh D. (Andy)',
        lastName: 'Son',
        emails: ['ason@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFaINpHOrTg_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517489599687?e=2147483647&v=beta&t=HTX2bLU3ce3jrgSwqhjZfep58UiXrw2NK3y2ifQC8Yo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  msag: {
    name: 'MSAG',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mike',
        lastName: 'Crader',
        emails: ['mcrader@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQECKqi6H-ZS4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701983144423?e=2147483647&v=beta&t=aWS5BmxsshOiumICgAgHjZvuwkMXrswfyq_dsET-ae8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Leonard (Van) Van',
        lastName: 'Lowe',
        emails: ['llowe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFHMCoDolYDCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517725620947?e=2147483647&v=beta&t=o8kABKJlJnxpObVxqLTgq1r2rkmq9aTQRxFkg9TY9Vw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James (Jim)',
        lastName: 'Wyatt',
        emails: ['jwyatt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH9EirtWjhjFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677164713849?e=2147483647&v=beta&t=0zS9jtP20y5dbT2R3P9F_3ZuMPVl2tOtmVTZc0iCCJU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kent',
        lastName: 'Kettell',
        emails: ['kkettell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Ward',
        emails: ['mward@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  m9solutions: {
    name: 'M9 Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sean',
        lastName: 'DuGuay',
        emails: ['sduguay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGTfaMKYFXcQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594936549690?e=2147483647&v=beta&t=sJmGXWfLvb6RRLETlWZ-F_qFzLIZtbuMhwOyWzTjPi0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ryan',
        lastName: 'Smith',
        emails: ['rsmith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bryn Bernstein, SPHR,',
        lastName: 'SHRM',
        emails: ['bshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE80WTT5byePw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718284408396?e=2147483647&v=beta&t=sBBS8pxdi-OYM1vFuSAYwGQ8CqFnwqjnHqKfMiDRdO4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Richard',
        lastName: 'Chambers',
        emails: ['rchambers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG7qPvK5xEg7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611689599966?e=2147483647&v=beta&t=_nWD4Qb0esc-4V-HkZ7gIqlaNza8jb-lctTR7UefCkQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ethel',
        lastName: 'Formichelli',
        emails: ['eformichelli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGnEXJwR7p7VQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666742892175?e=2147483647&v=beta&t=86m3SEHhBMTp9zcLbxm8SpjcK5WoYpijQXM8RhW5J1A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mediatrust: {
    name: 'The Media Trust',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Olson',
        emails: ['colson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dave',
        lastName: 'Silk',
        emails: ['dsilk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEAij7Se3yf6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614366754783?e=2147483647&v=beta&t=UiG4JYyR-ZMBs940-tOW4XcX_qtFfLi8NR8zw6uj2ng',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gavin',
        lastName: 'Dunaway',
        emails: ['gdunaway@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGG9S7whdtfkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713626255039?e=2147483647&v=beta&t=gLMAq9bsb0XC7M0FAgxGha623q1FFr5jMMsyCFfxZwA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: "O'Neill",
        emails: ["mo'neill@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFLgODmwfclDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711552357755?e=2147483647&v=beta&t=mNvSYc--SvdnKjvloxFcllN0r7sZUfmAsMevgsWs6XM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Don',
        lastName: 'Valdez',
        emails: ['dvaldez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEp7TnHoZGItQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563919252468?e=2147483647&v=beta&t=sSX59YJKCMnm2q1s-dGqW_82uDesw6X37A_d6qQgbOE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  corsicatech: {
    name: 'Corsica Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Harmison',
        emails: ['bharmison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF4iFHiBiITHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661280492142?e=2147483647&v=beta&t=yiDwQVa1qQsvgN_kQLU-tLq_nzIdC2UlZ6kSyJYzQmw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Peter',
        lastName: 'Rodenhauser',
        emails: ['prodenhauser@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jared A.',
        lastName: 'Lopez',
        emails: ['jlopez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEqNU_txG-Mkw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728997331934?e=2147483647&v=beta&t=PcOgWmdjOpyKgH76U8sByJS2JZVQLmCk8zIEAienn40',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'George',
        lastName: 'Anderson',
        emails: ['ganderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEn9LyPHg7RYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692890678894?e=2147483647&v=beta&t=JpIQPB5Dg7GaFNVggomZa95HX549pHZluDs704XyZL8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ross',
        lastName: 'Filipek',
        emails: ['rfilipek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGgZK0NGb1_Aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650566585297?e=2147483647&v=beta&t=AfOv51SzfLJ9UwGtJd9cB71skjmGsftK0MPJUTdu1PU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  solarus: {
    name: 'Solarus',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Justin',
        lastName: 'Huebner',
        emails: ['jhuebner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFRr-PyXIj_1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718481375985?e=2147483647&v=beta&t=WmQOxaYxq2U-UH_w6ZbeIYf0mWNndd8tKCc_-KDYxls',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Laura',
        lastName: 'Pinsky',
        emails: ['lpinsky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG349FZO7hf0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516281990395?e=2147483647&v=beta&t=HQoUthxFl1-j34MDAqqB6YGfji_RN2dgb0xqxpca9G0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Halperin',
        emails: ['mhalperin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matthew',
        lastName: 'Nikravesh',
        emails: ['mnikravesh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQElF5LbxfnJFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716233421407?e=2147483647&v=beta&t=EcZgcHXReWJaQTk4FImBMoIKwL-SXsLYpDGo-mawJRM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jamey',
        lastName: 'Lysne',
        emails: ['jlysne@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trissential: {
    name: 'Trissential',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Craig',
        lastName: 'Thielen',
        emails: ['cthielen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEPulO2YxCt5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703947295483?e=2147483647&v=beta&t=xHlIYDTbJk9ZizgwSYNNSkUNjJhDUaNL-5UqriKpPlM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mecca',
        lastName: 'Robbins',
        emails: ['mrobbins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHJ7d3V6UCuqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633464916961?e=2147483647&v=beta&t=7sDkl8FXSWpGAMGQ44hIodcpzf2SplBVrHWoEvrvJ7o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'ELIZABETH',
        lastName: 'HARVEY',
        emails: ['eharvey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHISBC7WNK6VA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516282682450?e=2147483647&v=beta&t=fYPhLeiWt36CowjFAlf1sfwi9HtkmS3Ol-HTHNmtm1I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Frank',
        lastName: 'Cress',
        emails: ['fcress@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEM9OBn_biPzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579902240477?e=2147483647&v=beta&t=qRPEhcFYm7ib5tPx47TNCOAz2iS5ACOBapIdPJdPzNI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Harshal',
        lastName: 'Agrawal',
        emails: ['hagrawal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH0CK2N7aqhMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682432873718?e=2147483647&v=beta&t=p2dT6078EraQKrnQx2oLbc9GX5-W3e1NnXBagRtJiHs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  enmark: {
    name: 'Enmark Systems, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Donna',
        lastName: 'Myers',
        emails: ['dmyers@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joshua',
        lastName: 'Gruszcynski',
        emails: ['jgruszcynski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFR0eg0AIJm7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516923930821?e=2147483647&v=beta&t=ZgQvNDrkBtPJZWuu-a7qQR0uL-U_ZWN9bbDdYVwCEts',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Rybicki',
        emails: ['mrybicki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHIUSpfHtDhtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517381841503?e=2147483647&v=beta&t=nv5ZOJPU0RV8gwU0UFT0ZSF1JBhgjUZkcKa2GdqVnMg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Debbie',
        lastName: 'Williams',
        emails: ['dwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG4Ij1O1PPG6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516639739734?e=2147483647&v=beta&t=PlaF520Mttctiw8n2a1Su7En7SGgxuHBdwSppRP-h78',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Benjamin',
        lastName: 'Lion',
        emails: ['blion@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGWS-1-jozFnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642709191231?e=2147483647&v=beta&t=yeMdYqW5mPRxTKC3UryslnmkxLGyNuT-oQeUpqeUPfE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  chartboost: {
    name: 'Chartboost',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Richard',
        lastName: 'Izzo',
        emails: ['rizzo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF0ygBb3czY_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517733699310?e=2147483647&v=beta&t=hcfjKuwRxFmzPIiiyaZmJ6DV83OBhFSczxuyvDklYWU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Arnaud',
        lastName: 'Pilpré',
        emails: ['apilpré@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ed',
        lastName: 'Williams',
        emails: ['ewilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGDmZJO1cBGfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701206096857?e=2147483647&v=beta&t=a3gYbh1gNN_-yZVZEE1DUz2RGeR8oQgVmJ8qwMMZIrs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'Buckalew',
        emails: ['mbuckalew@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shelby',
        lastName: 'Blueher',
        emails: ['sblueher@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gibraltarsolutions: {
    name: 'Gibraltar Solutions Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ray',
        lastName: 'Norkus',
        emails: ['rnorkus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFZ5McdrQpdhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516317119415?e=2147483647&v=beta&t=cwNuw73-I0Ma-CVQJe5iSaqGkdFLxhrHkCO2MFlsn6E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Darek Bielski,',
        lastName: 'MBA',
        emails: ['dmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGsIgOYOJg-ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516936645867?e=2147483647&v=beta&t=3SHpCCZWFhmHe14CFbwSVGis7egyCiIsdgh4XOHGO5g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rick',
        lastName: 'Polite',
        emails: ['rpolite@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joan',
        lastName: 'Hughson',
        emails: ['jhughson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHT6Lz7ND9ppA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517691583793?e=2147483647&v=beta&t=ymdEIYRzc15bReierk0QfbQXVKO8-emEaxMFVxRS4ww',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shaun',
        lastName: 'Thomson',
        emails: ['sthomson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEj0tN3uNKHjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580968938475?e=2147483647&v=beta&t=IJOFlb4lACUGERKEMMfltHjEdDbuNhVKKTfCwBAzp-A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  verser: {
    name: 'Verser Technology Lifecycles',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dawn',
        lastName: 'Wright',
        emails: ['dwright@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHyQlFCdQncIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558055409200?e=2147483647&v=beta&t=2KjZLJa7ckxHADE1VnxA5_1Jq__dfxNkJwRz4TUiQJE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vivek',
        lastName: 'Vashisht',
        emails: ['vvashisht@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dragan',
        lastName: 'Kajtez',
        emails: ['dkajtez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGIC7w1hyNV_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690419638183?e=2147483647&v=beta&t=kUiojwZ0EyIZ4bnIBgCEY36u-_j78SD5PVRaLgDSiwY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Scott',
        lastName: 'Tasker',
        emails: ['stasker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rhiannon',
        lastName: 'Morrison',
        emails: ['rmorrison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHAxLl2KowXCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676856790298?e=2147483647&v=beta&t=ha9yoVjhcexdDcpSIVoCeOv71ze4NGTlMIgFJHZXHOg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fjorge: {
    name: 'fjorge',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tim',
        lastName: 'Barsness',
        emails: ['tbarsness@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHv1lzelIzTcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709059808453?e=2147483647&v=beta&t=NlSxXxaj3c3vyhM5PVnuU-FJ_Pn_BgfjWX1Igen-IOo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Josh',
        lastName: 'Keck',
        emails: ['jkeck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHZg8kTnryujg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662126897304?e=2147483647&v=beta&t=J2sl1mPAhuqr-EN5qDRtHxX50DX_Y1LKkEczi5WapQ4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pat',
        lastName: 'Kavanagh',
        emails: ['pkavanagh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFoCEAqgetQGQ/profile-displayphoto-shrink_200_200-alternative/profile-displayphoto-shrink_200_200-alternative/0/1529445236561?e=2147483647&v=beta&t=MBGn9OmesmYjGcTXd3xRie3NrNrVpfFU9MJerUceWsE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mary',
        lastName: 'Cooke',
        emails: ['mcooke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFxBdNKOXgerw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661875798415?e=2147483647&v=beta&t=_5gLkKqfRvqwiJfGX4QyavbpTlB8l48KqM4X7NMp0wM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jordan',
        lastName: 'Narvey',
        emails: ['jnarvey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEcIeAksVYAvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1525730755017?e=2147483647&v=beta&t=R4RTF8H4ba9a6zimlIlqdm7orot3r4IuoxdLK8ER8-o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  anadata: {
    name: 'Ana-Data Consulting Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lal',
        lastName: 'Vaghji',
        emails: ['lvaghji@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anupam',
        lastName: 'Mishra',
        emails: ['amishra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG02Va957qBSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551969406688?e=2147483647&v=beta&t=WZV1ImmbVBO-dalrxARJtfabUdb-Eahu7-s6R4Ziyww',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shubham',
        lastName: 'Gupta',
        emails: ['sgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGlBuAeCItxrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627685317369?e=2147483647&v=beta&t=xfgx0L3bq0dGswdx_R5OBsGRJ_EzTLQZs3MiPeqn9yQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'William',
        lastName: 'P.A',
        emails: ['wp.a@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFB78K-m99aTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552686655049?e=2147483647&v=beta&t=hOC2NtU-T-ov6zXwtZW2Y1xEeKvnrxuuj9F5bPiPsy4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Naveen Sathiya',
        lastName: 'Moorthi',
        emails: ['nmoorthi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  navori: {
    name: 'Navori Labs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Jerome P M. – Founder and Chief Executive Officer at Navori',
        lastName: '...',
        emails: ['j...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFpliKl4hs_uQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516548609381?e=2147483647&v=beta&t=I8xwAR-rZbKkCT-8ZptvS6vOAcI0p3eIO90JH8wJH6U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jinhong Yang Dias Da Cruz – NAVORI SA |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGNgoX5n9V3cA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516877258230?e=2147483647&v=beta&t=2qAEWzhjrT2DGpEVKW8i4mpP9h-RhbEzRMOZP-dCLNM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Neeraj',
        lastName: 'Saxena',
        emails: ['nsaxena@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFP8qi9XNR8Zw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692372781040?e=2147483647&v=beta&t=Lyi8q3IBUTMD4p-HzVPidKAVvMUOQ2i0eEoOXuMaml8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Issa Makhamreh,',
        lastName: 'DSCE',
        emails: ['idsce@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFq1VQAKkYXRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729682369165?e=2147483647&v=beta&t=twNmI-NXgOAhDhUHZkTBEoHOmEvtDXPJKpFIq_2hbao',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew Lee Ip',
        lastName: 'San',
        emails: ['asan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF-wazC8nSB1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560840437694?e=2147483647&v=beta&t=6eLrT0wrrn4znmiiPuWcdN75FIw0RiHjtP1gqd7Y-Zc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  edaratgroup: {
    name: 'Edarat Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Evelyne',
        lastName: 'Moubarak',
        emails: ['emoubarak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG60Fk_34Y8_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678704673306?e=2147483647&v=beta&t=ReKQSD8IRkpuRGgN7dnCyJxb-vKwy-PUvQT8zDLqIUo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hamed',
        lastName: 'Makki',
        emails: ['hmakki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFdRag_RrMieA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715514936324?e=2147483647&v=beta&t=owwugDT8gfeg-_N1C29eHpROxZ1Gz8aBZnWGzYhnjHQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'DANY',
        lastName: 'MAALOUF',
        emails: ['dmaalouf@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFSJnDmDgq_Qg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666005188334?e=2147483647&v=beta&t=qdw7at2wnDADemBRu_TzLHd4oH2vZ-Nk9Jp5f7PYJNY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alain',
        lastName: 'Ghamika',
        emails: ['aghamika@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHpovlMGWP8Gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675764155301?e=2147483647&v=beta&t=siEexcIVzKDZojtErpPd596iDCk4HpP-ANmRgrEEONI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jihad',
        lastName: 'Nehme',
        emails: ['jnehme@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGuAxcYlTjCcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666076458137?e=2147483647&v=beta&t=Iv0l5E_YiNj0G-AnS5otin-3bkVbAE93rPl4bVY-J0s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  telkea: {
    name: 'Telkea Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Olivier',
        lastName: 'Penin',
        emails: ['openin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anthony',
        lastName: 'URLACHER',
        emails: ['aurlacher@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Franck',
        lastName: 'Vigneron',
        emails: ['fvigneron@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGT_bLtQKOvMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719323538403?e=2147483647&v=beta&t=hgBHQtpYxwJyGuXtMzFGSze5-5-YOeLRlz6kKOK98xo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pedro',
        lastName: 'Canarias',
        emails: ['pcanarias@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGflnETP6EswQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579503329357?e=2147483647&v=beta&t=LebRx4D1S4pBNOuMW5ElI4wuSGocbVrdKrgy8CHclfQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mathieu',
        lastName: 'Rameau',
        emails: ['mrameau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHkzgTbuH2LmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635277404780?e=2147483647&v=beta&t=W01-Kpv9aUJyMqjdcAaO9oVlOh5eo0O2n1MAZcU8rxA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  exist: {
    name: 'Exist Software Labs, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Magnolia',
        lastName: 'Hernandez',
        emails: ['mhernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEfZIGll2svvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516823912265?e=2147483647&v=beta&t=cyBoOaP_rlF2rD6XnaU9f8gQk0NjVbT_JamSpJ26OHw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adonis',
        lastName: 'Gapasin',
        emails: ['agapasin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE-ikwot6ax2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654519854622?e=2147483647&v=beta&t=Z159-s9C2Qa78Jg_8aDDRf4ag63ZXJCRszIN6ubQZ30',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Fernando',
        lastName: 'Reimers',
        emails: ['freimers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFYv0t2I-S4-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718241338745?e=2147483647&v=beta&t=ZbOH-7wae37uRfnvzP8c9AJhqiUmd6_atYEz8SguVFI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jonas',
        lastName: 'Lim',
        emails: ['jlim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG5fp6PKCTLMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516313389915?e=2147483647&v=beta&t=ddcR4Zzg-3uIG-gSN-OytJMZiBA7mrWXHCi4P1ZrJLs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jessica Trisko',
        lastName: 'Darden',
        emails: ['jdarden@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nsusgroup: {
    name: 'NSUS Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tian Zhao,',
        lastName: 'CHRL',
        emails: ['tchrl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGC6LJem_XlqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710469718453?e=2147483647&v=beta&t=3lzyLPf_VJaYiGsug1DbKrfpkDgiZcYZPl6NBrADgnY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paul',
        lastName: 'Burke',
        emails: ['pburke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGv_UXCAJAeYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516230880225?e=2147483647&v=beta&t=9QGO-CAPuYBBWhejdLqA3GbwaeH5neaJvitgO77Mr4s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sean',
        lastName: 'Kim',
        emails: ['skim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yuen Lin',
        lastName: 'Man',
        emails: ['yman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFSJ1ObFF-N4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691945480346?e=2147483647&v=beta&t=hyogKDtv37sG837hTXkzBQ6rz0mBuI0lHnl0pkbVBBs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nicola Ravarino',
        lastName: 'Guagenti',
        emails: ['nguagenti@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gatehouse: {
    name: 'Gatehouse Maritime',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lauren',
        lastName: 'FitzPatrick',
        emails: ['lfitzpatrick@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Heather Rogers',
        lastName: 'Meister',
        emails: ['hmeister@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFymKADaihbtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660747887980?e=2147483647&v=beta&t=NhL6yfjGUDfTGcxARiFgCxm7jivaNTmNwejW0m3HgFc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brian',
        lastName: 'Gatehouse',
        emails: ['bgatehouse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHJ27M9pwEhbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516614761244?e=2147483647&v=beta&t=YtQOhGkeuo8Li-RrMUlqZVUh4swgY8tQG0wFdI7yAwg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Theo',
        lastName: 'Gatehouse',
        emails: ['tgatehouse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGe9f4kIfaITg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516962762886?e=2147483647&v=beta&t=qRTDwIPPa2E-cbkEM2A2omOpwO9hwKq96w_ROqqHm8g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Coleen',
        lastName: 'Gatehouse',
        emails: ['cgatehouse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHidAyKU6WfVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650302681377?e=2147483647&v=beta&t=aMHyb6WIVmttno3q9IQxxrkLhRUs1sQqBrzx12sc_RY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  essexmanagement: {
    name: 'Essex, a Veridix AI Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lisa',
        lastName: 'Burke',
        emails: ['lburke@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eve',
        lastName: 'Shalley',
        emails: ['eshalley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEz6OY4t7waFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516801000292?e=2147483647&v=beta&t=cn6z9K5nmgSp59O-If34ueCOO2po2Vb3SYw64-7ANmo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brent',
        lastName: 'Coffey',
        emails: ['bcoffey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHmCCZwki9S0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715341748940?e=2147483647&v=beta&t=gYed2Jx2lYU-Hx38-WDmZ3er4br4gDNlWW08sT98lOs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carol',
        lastName: 'Finnie',
        emails: ['cfinnie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFHFbrxoy0m2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517684114119?e=2147483647&v=beta&t=bYP887Mj6ZHLSF0vQvh2-Az0MiXnHJiqdFCdullsqY4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Davin',
        lastName: 'Johnson',
        emails: ['djohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wyplay: {
    name: 'Wyplay',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sébastien Le',
        lastName: 'Breton',
        emails: ['sbreton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQETy1iiii3HhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692627142019?e=2147483647&v=beta&t=0xF3MHu-Ifg-joqjevCdBMKN0BenJe_RQdE6aMjRj0U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Muller',
        lastName: 'Christophe',
        emails: ['mchristophe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEXrwO4mnaFiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650817806395?e=2147483647&v=beta&t=HHMJQuhbZSyb9J6DzyOutDsw5uEd9cdqk5nuX-8PQUo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Julien Poudade,',
        lastName: 'PhD.',
        emails: ['jphd.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFksx4hMKCGKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543401607379?e=2147483647&v=beta&t=jTxOUhXyWGFq8PclyiRK_cwKJxCwZzfYP3Mli05Bbjc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mohamed',
        lastName: 'MELKI',
        emails: ['mmelki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGeQxWuV-ykZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516338261155?e=2147483647&v=beta&t=ftygV-VG-yHyEsCuHiUkQVFs0L8l2h8f6W6Fr1-F54g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pierre',
        lastName: '',
        emails: ['p@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  groupjp: {
    name: 'jp.group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Clemence Peau',
        lastName: 'Hosokawa',
        emails: ['chosokawa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGlzkzrF_p_3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688072256746?e=2147483647&v=beta&t=1AzddZYUFcPAAyz8ZmIGqW-C5h0SPIOhmk2IBcpuQ5E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Oliver Ernst, CFP®, APMA®, CSRIC™,',
        lastName: 'ABFP™',
        emails: ['oabfp™@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEeJlvAfIZZlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516251664693?e=2147483647&v=beta&t=EMe-Rwk7Gb1C5l2ERR6w9bFgokdQPtIP7-HAkAJxtII',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'James Phillips – Partner – Alpen Partners AG | Switzerland |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHQurDQorjvSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714380678464?e=2147483647&v=beta&t=3m7xC0T6VY2JguYBKKUMbDgEB2gvihNSp2Fk6_PEHNk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yoshi',
        lastName: 'Sawa',
        emails: ['ysawa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHU7xpWCRJXdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516557838525?e=2147483647&v=beta&t=cbSsMXUJglIEnfq7bYjS1XC-SzeaR97gBIJKpD3D_gI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stephen',
        lastName: 'George',
        emails: ['sgeorge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGiKGZ6mkD41Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711422435989?e=2147483647&v=beta&t=045hhTRtRBSeU6XUAYj44loPOJRcWVghlWiFolagp00',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  imagevision: {
    name: 'ImageVision.ai',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Deeksha',
        lastName: 'G.',
        emails: ['dg.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Prasanna',
        lastName: 'Ammiraju',
        emails: ['pammiraju@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFB8F0Robu2NA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1577000244838?e=2147483647&v=beta&t=Z2Atc-nj8NCLKoo-_EILTdfX555t9jnQgGvlKPbd1O8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bhavika',
        lastName: 'Patel',
        emails: ['bpatel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGYoF0XoWoJYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672937263898?e=2147483647&v=beta&t=dwb-5t-tyEV9U93dS331VF5aRNK2EMGLpAriVh-Jmw4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vijay',
        lastName: 'Sadhanala',
        emails: ['vsadhanala@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Siva',
        lastName: 'Malasani',
        emails: ['smalasani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFp4V-LWTaqrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573645889620?e=2147483647&v=beta&t=MqyLANduJN4FncWBO383iLQX6JNfH3A4FMjbT4cvpo4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  microbenefits: {
    name: 'MicroBenefits',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cathy',
        lastName: 'McCutcheon',
        emails: ['cmccutcheon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHc0PY7NDLfAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517676691347?e=2147483647&v=beta&t=OSqQ0wwOAbzz0cQjt-dF6yiA277kNrjIasIOl28vhho',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Catherine Truong,',
        lastName: 'CBP',
        emails: ['ccbp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQESWg4uplFJ2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542162790053?e=2147483647&v=beta&t=hzH-wXwOU70ySbTUQ50bc6zHlFQPp2GmAgbCAA00-SM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cindy',
        lastName: 'Mendonsa',
        emails: ['cmendonsa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephanie',
        lastName: 'Price',
        emails: ['sprice@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGnhY0cyWE5cw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679447778971?e=2147483647&v=beta&t=oE7jxQzTcKhc2IXQuYts0zrj3pPVFmJ37Xxulo6Ar8k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sarah',
        lastName: 'Love',
        emails: ['slove@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEJDyRhxLcBAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516941826071?e=2147483647&v=beta&t=U30vPh0rla0yyAqOHOtIzQLkujTM2NxIeFj-yO68nSY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hamisystem: {
    name: 'Hami System Sharif',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Arman',
        lastName: 'Arbabzadeh',
        emails: ['aarbabzadeh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEYY7zX0ysesg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1545768576359?e=2147483647&v=beta&t=Tman6a6VSXcLSO684VNYZV8hgOTtNrR9-SvGhlXQz6s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Benyamin',
        lastName: 'Najafi',
        emails: ['bnajafi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mehrnaz',
        lastName: 'Yousefi',
        emails: ['myousefi@example.com'],
        image:
          'https://media.licdn.com/dms/image/D4E03AQGdeydHpt7R9g/profile-displayphoto-shrink_200_200/0/1719996414735?e=2147483647&v=beta&t=19yDIVpImrBny9wSGL6mXMD5NuGfwWud_E6jmAFlQVg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nima',
        lastName: 'Ravaei',
        emails: ['nravaei@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGH8ZBMoqTsTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704870801494?e=2147483647&v=beta&t=FhHk89iEOAvNkLwJycuO5IT3NGM18KIyNaYRNKtW0zY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kourosh',
        lastName: 'Ahrari',
        emails: ['kahrari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHZBKvsVtM7CQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701383218827?e=2147483647&v=beta&t=bYRWyG6tLiwWyRz2QJR_jVx5pxAeODefJmrVv9PC4uI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cscanada: {
    name: 'CS GROUP - Canada',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amine Smires,',
        lastName: 'MBA',
        emails: ['amba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eric',
        lastName: 'Mathieu',
        emails: ['emathieu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFv8exDvQIfvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516343180739?e=2147483647&v=beta&t=WYW8E0zNRX0-k28jgl4aKtSmnDNp5ClIwAsqRU0zg8g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Arnaud',
        lastName: 'Peron',
        emails: ['aperon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHXDOorqw_g6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673731160176?e=2147483647&v=beta&t=nxHb1sD0GQ33TprSTfQOhjHsz_0CNbPpgkDAhCQ-JH8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vincent',
        lastName: 'Besselat',
        emails: ['vbesselat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHtBvUjRpVqpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650912990348?e=2147483647&v=beta&t=2pWts4vcrbzTWiSk-8SKpFSYGPOF856_DILeEPvos4Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ajmal',
        lastName: 'CS',
        emails: ['acs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFfTobcX9Jr0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620197850882?e=2147483647&v=beta&t=FCIspCylV9BwlEuhMk_mGfyVSiGTwftLMQ9f4oZeihk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jetbi: {
    name: 'Jet BI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrey',
        lastName: 'Bosak',
        emails: ['abosak@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ruslan',
        lastName: 'Krapivin',
        emails: ['rkrapivin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEFA5rnjEEVtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612369076053?e=2147483647&v=beta&t=W8SeNNjaSq0wylGKBYwa-zdR_bwuUc3Qq1rA1J7OsZg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kate',
        lastName: 'Rusakovich',
        emails: ['krusakovich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF4J_5SxyZcaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622707812886?e=2147483647&v=beta&t=TEXUcKpSTdls0Skp-2L2T3dkCepEm9kaY4LJiDBBoQg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mary',
        lastName: 'Osobe',
        emails: ['mosobe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGtmzLYZhjwMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658916849114?e=2147483647&v=beta&t=GqnRN424HjmlMqoQ_TOtlStEIVB4FPa767_0H1OanGI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kevin',
        lastName: 'Lock',
        emails: ['klock@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFy6VBWYQ6olQ/profile-displayphoto-shrink_800_800-alternative/profile-displayphoto-shrink_800_800-alternative/0/1529416861423?e=2147483647&v=beta&t=gQcVf32buNplrUg6mq0ObSmz18EQWiJkrOymyQ8xWtI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fortecloud: {
    name: 'FORTE CLOUD',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'AbdelRahman',
        lastName: 'Elposhi',
        emails: ['aelposhi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEydFofoj4vkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676843200669?e=2147483647&v=beta&t=ijo4QVg5AzLLNndst9mfkWb4xaRaLTFpaHLBIAHgnM8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mohamed',
        lastName: 'Bekheet',
        emails: ['mbekheet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFvGDvLH55DMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702061617997?e=2147483647&v=beta&t=l6pByDcYbrExJ5XlAdxoDuFsMlVvDzRalZgawfUUum4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Forte',
        emails: ['mforte@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEQdCb4D9Kc6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517538298688?e=2147483647&v=beta&t=vYXfqSOeg4mTxfGj03LVH_1_8lEZlbp3babvA601e2o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Doha',
        lastName: 'Fahmy',
        emails: ['dfahmy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Eggerling',
        emails: ['peggerling@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF_PSIGrzV1GQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600970695383?e=2147483647&v=beta&t=SW9RejTyuZ738GcWfyw9VvAb62JDyeo9GHVQsgS8uSo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  morrisbusiness: {
    name: 'Morris Business Solutions - A Technology Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jacqueline',
        lastName: 'Connelly',
        emails: ['jconnelly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFLSRUzGxg_yw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664497599549?e=2147483647&v=beta&t=JyBG9GiOVyMDpiv9-33cyFPZ8TpABgZ0ydvH4ZFCEWU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jim',
        lastName: 'DeVuono',
        emails: ['jdevuono@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFpPuZLNmm8GQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551985981171?e=2147483647&v=beta&t=X5QKp1wZFVrDW61-b831ma-r8eerSMQxauharLfUWDA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lakevius',
        lastName: 'McKnight',
        emails: ['lmcknight@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEmRtPrXmLVXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635289778890?e=2147483647&v=beta&t=3VxIsG5rR39p0VsDVFrDbdiotUdziAAjhgEUerpSMIY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tim',
        lastName: 'Sharpe',
        emails: ['tsharpe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQELb9wM_3sBEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721937050826?e=2147483647&v=beta&t=qaqVcDffDySiWsv1lxUWhYDoqddSjTNRY_jVE8rHzxY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michelle VanRoy',
        lastName: 'Wyman',
        emails: ['mwyman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFKVb1v5UeH-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710252837358?e=2147483647&v=beta&t=NfiRu2LD2L2z-vuWCv3wcXGf8YBOEWwpYpDy3uGt0PE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nsi1: {
    name: 'Network Solutions, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mike',
        lastName: 'Stewart',
        emails: ['mstewart@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steve',
        lastName: 'Tadevich',
        emails: ['stadevich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGCwt6Otwn-Lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585330032161?e=2147483647&v=beta&t=dweCLSQLD4x_SE_mH9owiz5pA3jrXEVSzszKDfO8R8g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeremy',
        lastName: 'Ryder',
        emails: ['jryder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG7pIzXgyukwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522272494349?e=2147483647&v=beta&t=nWj1OVjaQ7onvsdS1F-UpLhuqTAOrjNHnTemkujhBfg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Phillip',
        lastName: 'W.',
        emails: ['pw.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHvdX94VC7_Mg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516616921970?e=2147483647&v=beta&t=MMF1x1AEUiflu3ZPX0DH7Dy5BDz13hR6s5fCWW6XNuE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Benjamin',
        lastName: 'Tadevich',
        emails: ['btadevich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGBJ-GvB5ihcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573700605387?e=2147483647&v=beta&t=rjx84Mrn2tzXHhRKQXLNcJ8YiLlh4k0TPvp-hibPwZM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  netiapps: {
    name: 'NetiApps Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Manoj',
        lastName: 'Pillai',
        emails: ['mpillai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEBR1ULHDghxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517754050872?e=2147483647&v=beta&t=U_XTnDY_yblrKY580uR4pbpqC7Bf3uO3vwk9wJCiN54',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Suraksha',
        lastName: 'P',
        emails: ['sp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'chetan',
        lastName: 'kumarnetiapps',
        emails: ['ckumarnetiapps@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shashank',
        lastName: 'Nair',
        emails: ['snair@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGw6tTV0I7Lcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683883385054?e=2147483647&v=beta&t=E4PCqhlOIToosJ8akEwo7iIcAOOGMajWSZJjOqUSK3U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Smita',
        lastName: 'Panicker',
        emails: ['spanicker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFHByjsy8W5mA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657118719845?e=2147483647&v=beta&t=xErEGU5UaT_AK6NALtyXYOHvDyB3XO4FfXepUtvzkRA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bitSoft: {
    name: 'Bit Soft',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pradeep',
        lastName: 'Java',
        emails: ['pjava@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bogdan',
        lastName: 'Stanciu',
        emails: ['bstanciu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE99AD3FrqMEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516775896026?e=2147483647&v=beta&t=j1_InZ2V3yR3oGt_DOYIbURm09QHoigIENblgdp_-KI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daniel',
        lastName: 'Erevena',
        emails: ['derevena@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEuoOgykbBouw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715421681863?e=2147483647&v=beta&t=_QqPH0KhhjQHYDtJMhYo5egTdfcb3mOYyhjMcSJPET4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rahul',
        lastName: 'Jain',
        emails: ['rjain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE5JT4mWivL5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516320379698?e=2147483647&v=beta&t=TtrKUuo5cevXJgosTOlJw-KI0ueiW-0Xu740_sPiYhw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'EHsanullah',
        lastName: 'YaqooBi',
        emails: ['eyaqoobi@example.com'],
        image:
          'https://media.licdn.com/dms/image/D5603AQGRCkwtU4oBDg/profile-displayphoto-shrink_200_200/0/1683557367664?e=2147483647&v=beta&t=wcZsGtZXoYMXnsGSCbkYZyWzb1P614fYApFPG-QHkqc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nowmicro: {
    name: 'Now Micro',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Patrick',
        lastName: 'Finn',
        emails: ['pfinn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEG1O3wiViusA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516260933824?e=2147483647&v=beta&t=Fk1j1zrF3oJ08pEiuW1Z6Cn2eq7wjb0qBP21_iuQ088',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Britt',
        lastName: 'Carter',
        emails: ['bcarter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHfGCMIedwDFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547666007867?e=2147483647&v=beta&t=2FTw_pLwyJjCIQiCSB-0mmI56Pl1Tbl-YcBSdRkefac',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jonathan',
        lastName: 'Anderson',
        emails: ['janderson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carl W',
        lastName: 'Smith',
        emails: ['csmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHaM8FsggNs0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568823233246?e=2147483647&v=beta&t=LoJ3EpeNn21ScOAOWQybpxUema86NJOrVzxNYSI-e1Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steve',
        lastName: 'Maser',
        emails: ['smaser@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFyjKoErSrIQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517669550154?e=2147483647&v=beta&t=Dto2fQfEmkbPnGk1qO0wDO3PleEOv-2t2gRx1Eb-pp8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  idegroup: {
    name: 'IDE Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lucienne',
        lastName: 'Ide',
        emails: ['lide@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEf13Tc3g-3sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660060050859?e=2147483647&v=beta&t=bZ9iP4W4UXoY-fsGbkFQh88oOEnYW7iZ1P9TB7h2jOk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ned',
        lastName: 'Ide',
        emails: ['nide@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'Ide',
        emails: ['jide@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEQwq9FfPloUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684345997302?e=2147483647&v=beta&t=sdEsRZEcyU13j4tD1I7lPO-OVESjn4jAnD24oQ_kR0k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jane Ide OBE',
        lastName: 'HonDUniv',
        emails: ['jhonduniv@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGEgBcMoTjYKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650489637230?e=2147483647&v=beta&t=OBN1v5_0RvpK0gDSOhYUua-odJWKAJbsnNVCBLFJXsE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Britt',
        lastName: 'Ide',
        emails: ['bide@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGy1tI2E6Janw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681918599142?e=2147483647&v=beta&t=GJMOCaR12Monu3O4GSLvFbNNGiARxHHrxdGIENGkSH4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  a3missions: {
    name: 'A3 Missions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Elizabeth',
        lastName: 'Raby',
        emails: ['eraby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGanTdO3SYQsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518709586248?e=2147483647&v=beta&t=4HSDsA0JfpKfjE9U2BRqjNUgmZdLGgUvD5uEWsYHSUE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Evan',
        lastName: 'Laufer',
        emails: ['elaufer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGJ-iJVwje8Qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575502518173?e=2147483647&v=beta&t=MkD3k4CX1yVwImxaWqZCwBvu_RR5GcpYpc9IkZvFChs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Merquiades',
        lastName: 'McLellan',
        emails: ['mmclellan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEoiYeLfDZFww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674435713855?e=2147483647&v=beta&t=-mI4uH2_UmYw6l7-dWzLgaSHpTsr_f7fC1Vguplqly8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jim',
        lastName: 'Norwood',
        emails: ['jnorwood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEzKR0SbObA6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517688417365?e=2147483647&v=beta&t=U-87-dfcYayHNEIIaNu_dyh44vhVQmCZeFF9ixNICzI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Glenn',
        lastName: 'Barker',
        emails: ['gbarker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGP0-td1W3W2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591046384491?e=2147483647&v=beta&t=ynhAQ0HrU9AR7HC6mT9p4ORMV5si7GtHeH2lJCCka6A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  clarityssi: {
    name: 'Clarity Software Solutions, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steve',
        lastName: 'Mongelli',
        emails: ['smongelli@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rich',
        lastName: 'West',
        emails: ['rwest@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHD-265Ofcjbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607731520879?e=2147483647&v=beta&t=_iUyBs_aNIG5YI0Cp4BnwBVdhZAUCKInlMop5irgFNI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Robert',
        lastName: 'Stepeck',
        emails: ['rstepeck@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sean',
        lastName: 'Rotermund',
        emails: ['srotermund@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dan',
        lastName: 'Overholt',
        emails: ['doverholt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gnrsystems: {
    name: 'GNRSystems Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Harry E',
        lastName: 'Parker',
        emails: ['hparker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sharath K',
        lastName: 'Rojanala',
        emails: ['srojanala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH6U-PyEmumFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670034480466?e=2147483647&v=beta&t=xtjFm4SfT2hFiqsKRJuqUgZFLRJ-je4X64nUuYNp_18',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vandana Reddy',
        lastName: 'Vemula',
        emails: ['vvemula@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGSIeDCvIV_MA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683265995316?e=2147483647&v=beta&t=ANnKA0hWrLrId81LczYDsYyA3foyCitXsVi65WCP_Lg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdul Rahim',
        lastName: 'Anees',
        emails: ['aanees@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sridhar',
        lastName: 'Vemula',
        emails: ['svemula@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEXnIpJpGRApQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716389862120?e=2147483647&v=beta&t=GRN_oYleGD7iO0qdVyrroBfN0oXnZxCJIkjKRhT9Fh8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aebs: {
    name: 'AE Business Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Harris',
        emails: ['jharris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGvtIxp7qmxLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569935656131?e=2147483647&v=beta&t=XPo2cvIUu_lUcPRkCnyONJak-JnKg-EO6pfrN4tFyEc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Josh',
        lastName: 'Gasser',
        emails: ['jgasser@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEe9CnozbQrWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683574771023?e=2147483647&v=beta&t=3bG1zpMhYv-FOKKQQ6tTkrNc9MH6Ej58aXyUlYHM9Oc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matt',
        lastName: 'Blanchard',
        emails: ['mblanchard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHxjxzBPLvGZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675142856021?e=2147483647&v=beta&t=HzUfy8-CLxG1v-lwhBkxUS6_e7XTo-7sldRuXhQQDbs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Drew Gough,',
        lastName: 'M.S.',
        emails: ['dm.s.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Morgan',
        lastName: 'King',
        emails: ['mking@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFnh43Sd8-QWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680812527917?e=2147483647&v=beta&t=vcoHXT2CXbliHQMZM4pkh0TIoH5ZdNNkUd6Ib4kx0iE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ezipper: {
    name: 'Zipper Romania',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Florin',
        lastName: 'Dumitru',
        emails: ['fdumitru@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFu9f-joyPEtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677579939072?e=2147483647&v=beta&t=ytcmQRiyGhtvJ2Y_A7Y9AEOhcRzQP3z3dkou0nj0-B4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vlad',
        lastName: '',
        emails: ['v@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFs-Ifo-7yEPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667572333577?e=2147483647&v=beta&t=CLEsASinUWrwA-ANgxwJ544Ng2FDWJyahRSU3FZZ3XY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carmen',
        lastName: 'Cotofana',
        emails: ['ccotofana@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mihai',
        lastName: 'Sonca',
        emails: ['msonca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFi2iL2rbXZOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686121337964?e=2147483647&v=beta&t=dLQvWWuVpyDMff633u_XpkVQ8d7kZ4pQlwS_IrCjZqU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andreea',
        lastName: 'Radoi',
        emails: ['aradoi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFvpx_6GvAsmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618837515279?e=2147483647&v=beta&t=esiI4YnaxSH48x30CwKKNo-k_4f4iODoFPXy4IDgqyg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sritelecom: {
    name: 'SRI Telecom',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steven',
        lastName: 'Gaudreau',
        emails: ['sgaudreau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFeA3o60l09ZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724160491888?e=2147483647&v=beta&t=UmBhsnHYiDhP2RnLYF5mo0h47bhndMR3-0_N_3AIU20',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Samuel Handy, PMP,',
        lastName: 'LSIT',
        emails: ['slsit@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGFOMlRRl3WPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516778879966?e=2147483647&v=beta&t=S4C6L1yrahH4Nw5SotRtlPbQNSI4THKWo7nuBOJQBok',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shane',
        lastName: 'Breaux',
        emails: ['sbreaux@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sarah',
        lastName: 'Breaux',
        emails: ['sbreaux@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Miranda',
        lastName: 'Hickman',
        emails: ['mhickman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEC5rfLpRlZhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639077957442?e=2147483647&v=beta&t=fybQgF9FzpZ_IectTRQoQ_1q6k-86xGJeA-1MkcRFy8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  salt: {
    name: 'Salt Security',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adam',
        lastName: 'Fisher',
        emails: ['afisher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFp4WLr4nJsQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728046440655?e=2147483647&v=beta&t=h1dOurcMddU-cjVZR6BN3AzUumnbV0E5hz0rE8gkZlo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Renee',
        lastName: 'Hollinger',
        emails: ['rhollinger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF70H4CQf7OCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726263745517?e=2147483647&v=beta&t=Ng2R0qE9ig_8a8jwruuJs4Qtp_nZddi7OJO8Yre0PPk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Callahan',
        emails: ['mcallahan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGIJ1LAXlwCyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588106529789?e=2147483647&v=beta&t=eluxvMUl3XF5rxwnkwAnPHCIEbvCM-ipB9lbz8xwuSU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brad',
        lastName: 'Salt',
        emails: ['bsalt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH9WfKWElp0Tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516241913469?e=2147483647&v=beta&t=cGG1wUq3ZBRe6Eg3c5zjyo0nLa-N5fx1UoKFBktTD9s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeremy',
        lastName: 'Lerman',
        emails: ['jlerman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGqLqoiap4u1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568110996053?e=2147483647&v=beta&t=ytu2Zt3Sug5F0q1JlYxnysEBM4eo4Wih5AaVaswYzg8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ePoint: {
    name: 'e-point',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  i3verticals: {
    name: 'i3 Verticals Healthcare',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Crystal',
        lastName: 'Bell',
        emails: ['cbell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF1ONRWtIKuHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634131658006?e=2147483647&v=beta&t=tmW5cLUwWV8YbwAX-oOjHfC5zK3BqchrezVZpRaifH4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pete',
        lastName: 'Panagakis',
        emails: ['ppanagakis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGdsAWlSdZ9dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687815279810?e=2147483647&v=beta&t=KjkTQYgqkrQm1kKmRqz8myv2noa9HHelj-ELUTCpPKU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rick',
        lastName: 'Stanford',
        emails: ['rstanford@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tim',
        lastName: 'Zarzycki',
        emails: ['tzarzycki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHputazutaBrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516313725485?e=2147483647&v=beta&t=GvTzmImCpgEACXYu2WXF8dh-tWwdUz11V2_ufIW8GMg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Greg',
        lastName: 'Daily',
        emails: ['gdaily@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF18DP4-IJjgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663164238668?e=2147483647&v=beta&t=NmEW2tjfKF9H9f1Wji_MwyHOcvp1CvL6Yqi-Kk1Iyq8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rainingvirtue: {
    name: 'Raining Virtue Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Philip',
        lastName: 'Gallo',
        emails: ['pgallo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHttC5MEXvxJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674071234756?e=2147483647&v=beta&t=hAWtarv7RyuS5RhtdsFLWjJRDr3gvI71emVImxO6Jy0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Ahern',
        emails: ['mahern@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHXVqMQEBYEXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696916666576?e=2147483647&v=beta&t=c_KopKDdxTNLMhdhlAQvuUy5M0-uYQoLdaKPlk7c4v0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James Matthew',
        lastName: 'Davis',
        emails: ['jdavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEc1TrDPNNAHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634496062160?e=2147483647&v=beta&t=M2C_y70FA1X6acA8OsnBQlHrRKG2SWXVXLGNGFuo1Mw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mihir',
        lastName: 'Reddy',
        emails: ['mreddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH0hcjKOzH5HA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602779685173?e=2147483647&v=beta&t=tC4R6OYaUOH78dgn-PVPf_Y95uRVx7FBGUeqXxJKtdc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Cashill',
        emails: ['jcashill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEcsJPe43otqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707431280260?e=2147483647&v=beta&t=a6oSyAUv_mJcAm-nMOOXfuhe5LTwfWNKr9UZDPlygZ0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  multiviewcorp: {
    name: 'Multiview Financial Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael B.',
        lastName: 'Johnson',
        emails: ['mjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFOnHoCCTnk4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516553724932?e=2147483647&v=beta&t=Uq07tkh8i17G6M6_YgUks3mdhTxuuOTAYSuc1ge1EcM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Abraham',
        emails: ['rabraham@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rob',
        lastName: 'Szelepcsenyi',
        emails: ['rszelepcsenyi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE8LbzcH-YtwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582462977514?e=2147483647&v=beta&t=veL9x-EBrBFmdjKf-KimMrzyI-Op_eQE82nZ93pSbzw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mohamed',
        lastName: 'Zeineldin',
        emails: ['mzeineldin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF2jcXfRUAtlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594788934585?e=2147483647&v=beta&t=fe34XWyV01a01O9S47-zzTQZtLx3zkwPEFoWYHw4NhI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dave',
        lastName: 'Nordquist',
        emails: ['dnordquist@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  uvl: {
    name: 'UVL Robotics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eugene',
        lastName: 'Grankin',
        emails: ['egrankin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFMrqm6A2yWkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656446783623?e=2147483647&v=beta&t=OjOGhNfQHGshKh--q4aYG9W-zMAN85zAsSB4JErxOQU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Serghei',
        lastName: 'Alexei',
        emails: ['salexei@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF3NxGqqh2VjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516626225513?e=2147483647&v=beta&t=LxAzc0usqaOgduMYresYyFJrNsJv6S_EK21LbG8LCm8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dimme',
        lastName: 'Taras',
        emails: ['dtaras@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGqLuCl4gSdGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606595351190?e=2147483647&v=beta&t=SCNDeit68OSNt5vaDRK6jqWLFGEmYzDE5PuNbBYSUcE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Salim',
        lastName: 'Riyami',
        emails: ['sriyami@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFXJhxewL2InA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670307555705?e=2147483647&v=beta&t=msCRKQ3I2kWSxuKeoC1qTtOcJpZb_wl1vxRuE-dQ2tw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Moosa Al',
        lastName: 'Balushi',
        emails: ['mbalushi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  beMobile: {
    name: 'Be-Mobile',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Corey',
        lastName: 'Flaig',
        emails: ['cflaig@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG_36rGcMJk5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516368525512?e=2147483647&v=beta&t=bmeq8-b0IqdyE-kt5TbyHijqJ2yfgr3p7cIAMXzG3xA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jake Miller,',
        lastName: 'CPA',
        emails: ['jcpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGTXvBPCBq6cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710450607072?e=2147483647&v=beta&t=QH1VklAVvHfZgLk1kGOMfiACDqRilfQXx9-agqlRhF4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jason',
        lastName: 'Prinsen',
        emails: ['jprinsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH01J-ZtMmPEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592525572182?e=2147483647&v=beta&t=8pm-dVIQLT8URhtw2C3UVKZGslIjgjSD6OCK3DYES4Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Trevor',
        lastName: 'Spooner',
        emails: ['tspooner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH_oDsv-JhH3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561669124500?e=2147483647&v=beta&t=vYsEow6R3ufNqn22eEtg64mlPIzMm8r19kHOigOnXz4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jo',
        lastName: 'Calhoon',
        emails: ['jcalhoon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH0G4otmstx6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686858429318?e=2147483647&v=beta&t=EtATfft6rxUDRjOy1OPOvTZVMiypELXhFkltFM2Knb0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  planhub: {
    name: 'PlanHub',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ro',
        lastName: 'Bhatia',
        emails: ['rbhatia@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sid',
        lastName: 'Nair',
        emails: ['snair@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH3qg9BEXdKrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583132266113?e=2147483647&v=beta&t=kNdjSxqSSIGoCgauY9CGbgXQgsKERDq8Uln-oMU3i3s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kevin',
        lastName: 'Priddy',
        emails: ['kpriddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHha7zUmFxeiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574202059087?e=2147483647&v=beta&t=orG1ti9l44UHh3rYwZmuXe76bisORiBpGw22bMGJBPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike Fecca,',
        lastName: 'MBA',
        emails: ['mmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEdh7cidH83kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1566481810754?e=2147483647&v=beta&t=dLRGRnEdQpTDot0FoVZ_pQh4zlPNj0qQXX1trkV_Fl4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: "Eduardo 'Edu'",
        lastName: 'Mazal',
        emails: ['emazal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEGxQLMM-pSLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548784176396?e=2147483647&v=beta&t=1rpRyFlZco5ilEVAbXgqxLCaP0K24dK0oANlq9rllxI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  relianceacsn: {
    name: 'Reliance Cyber',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rob',
        lastName: 'Walton',
        emails: ['rwalton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH31SfvGQDdgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584095544336?e=2147483647&v=beta&t=wEuHn1IEookROYcsrFjm3ytG4HaisrWIGG4Xc-YRGSc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Giulia',
        lastName: 'Foss',
        emails: ['gfoss@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alex',
        lastName: 'Wheelhouse',
        emails: ['awheelhouse@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'PM',
        lastName: 'Ramdas',
        emails: ['pramdas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHWUA9EcAAqIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716311705214?e=2147483647&v=beta&t=qxFbXahSV8QhQDTj1rVUFBFeECC4_CX3wyHiU3QR6tI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dr. Durga Prasad Dube',
        lastName: 'Ph.D',
        emails: ['dph.d@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFR0dA1haNVtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611127621195?e=2147483647&v=beta&t=hbKBDikE6qex6ekxhvPDxIKiJPi1QgfBpX_2-USzK2A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  conquer: {
    name: 'Conquer',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kyle',
        lastName: 'Skebba',
        emails: ['kskebba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEhMMNefcSFsg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704998056708?e=2147483647&v=beta&t=hntO9C3QkIDEKIiUT3s9g4oZ9cLqww8orFHWetmrIws',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joshua',
        lastName: 'Tillman',
        emails: ['jtillman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHwAwcF-YSmpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559942111875?e=2147483647&v=beta&t=HVUddDul7tS9GPAz3xpi_4F2Hk3kRIe9uTb34L_EyGM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Melanie',
        lastName: 'Darienzo',
        emails: ['mdarienzo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEyH-XImS9fqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699403074270?e=2147483647&v=beta&t=RYHuiaa08mNiUKc4LrS2hRgYPxI9vz-YVYM-MmfP71w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Richmond',
        lastName: 'Taylor',
        emails: ['rtaylor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQET_qibJDTgDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730206857949?e=2147483647&v=beta&t=RP0Cpv0QN4Jtj-cGr1D8Dtn5256GH2HLETsACGIoCGw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ian',
        lastName: 'Skebba',
        emails: ['iskebba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHXZIcOeSgiZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626557818325?e=2147483647&v=beta&t=5xgSRoXHKDxg3uoaElyi1QdpKbTnoOd7vDbJ3e1nVfU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cubicgames: {
    name: 'Cubic Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dmitry',
        lastName: 'Amroyan',
        emails: ['damroyan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGqShmxHbsy4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721217545554?e=2147483647&v=beta&t=-IszMEjpLZ4IdnLJDbB5aBQHmsXlN6q39QKSJmJFtHU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Natalia',
        lastName: 'Goncharova',
        emails: ['ngoncharova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHb9OKFAfiNMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596743834631?e=2147483647&v=beta&t=DGUBPwGaEBLIKCOdIsMqJZdAPd6RjrxTCu0nNUKIsdA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Valeriya',
        lastName: 'Berlenko',
        emails: ['vberlenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE6zNtpUNkhpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647719392417?e=2147483647&v=beta&t=b9OrkuaF1t8Xv05RtuscYYjJ9JAe9cBr6fzPE6LES30',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Polina',
        lastName: 'Davydova',
        emails: ['pdavydova@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrei',
        lastName: 'Kazimir',
        emails: ['akazimir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHxkc7ZABY8jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684697134107?e=2147483647&v=beta&t=fe7CcerlF6lgishnd8kY7PVTov1oozPfYP-6GjXLbzQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  oldskullgames: {
    name: 'Old Skull Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  housemarque: {
    name: 'Housemarque',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gregory',
        lastName: 'Louden',
        emails: ['glouden@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cory',
        lastName: 'House',
        emails: ['chouse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHkvClv_h5GtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728938062782?e=2147483647&v=beta&t=2x8_inaTuKCa6iBrOBOMqgEqwVW2mr69Sr06pnf8Hsc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Simone',
        lastName: 'Silvestri',
        emails: ['ssilvestri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGVaVwLSFpMXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606724384416?e=2147483647&v=beta&t=n43TuF1cQwQFDpcWJX8JeX-X7mCpxr5h5golyZ1Bwpw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Colby',
        lastName: 'House',
        emails: ['chouse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGku0O-8M7Hzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517487718680?e=2147483647&v=beta&t=SpRajOSWikNa-BFYJFGBWWh56QEC-SwMap2Eu5StHU8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rachel',
        lastName: 'House',
        emails: ['rhouse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHUxLp16SaDcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727042280461?e=2147483647&v=beta&t=86GwVJYeR7PptUu1A33KiUAcYcSWxBwxj_wawIfmoq0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  growin: {
    name: 'Growin',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pamela GROW',
        lastName: '(she/her/hers)',
        emails: ['p(she/her/hers)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFuC_uQ7YdT0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727697878753?e=2147483647&v=beta&t=Ec7Y3OFORzw-dsAzbLJh3UXAhQ-bOEL48t-xsgqgpYo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jean',
        lastName: 'Grow',
        emails: ['jgrow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFojDI6COo4kQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674261745896?e=2147483647&v=beta&t=enhlwZq2s3xF6BuA6qKeIO2q_z43KseJgkrWysnHYIQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brian',
        lastName: 'Grow',
        emails: ['bgrow@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Growin',
        lastName: 'Estate',
        emails: ['gestate@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGYAmJ4i6DIvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685467291500?e=2147483647&v=beta&t=WxCTmjuRmKip9Pzbk_Lj_7xOLgWhQxr-c9gfeWyL-HQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rolf',
        lastName: 'Buchner',
        emails: ['rbuchner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQECD2tJOP-reQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516307208438?e=2147483647&v=beta&t=5Dt1SrlxbmQg4jKAssP3St-djrqo7R6n3aZlUY4bUSc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  laserromae: {
    name: 'LASER ROMAE',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Claudia',
        lastName: 'Quintili',
        emails: ['cquintili@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Fabio',
        lastName: 'Antonucci',
        emails: ['fantonucci@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFDgrHiJGuHfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1554985686892?e=2147483647&v=beta&t=PsxJvyTG7IwebUNaGO4tXy-2D1etKZs--_XCpei3TJo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Giulia',
        lastName: 'Vergari',
        emails: ['gvergari@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Emiliano',
        lastName: 'Fedeli',
        emails: ['efedeli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFFGimG0cTBwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638108195035?e=2147483647&v=beta&t=9Bhwx9BwlvQ1is4MFJDaXy0gVIPbFk0S0CGiUgR7X7U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Federica',
        lastName: 'Bellavia',
        emails: ['fbellavia@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  proLine: {
    name: 'Proline Bilişim Sistemleri Tic. A.Ş.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nafiz',
        lastName: 'nafiz',
        emails: ['nnafiz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aydanur',
        lastName: 'Aladağ',
        emails: ['aaladağ@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Turan',
        lastName: 'Kişin',
        emails: ['tkişin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kadir',
        lastName: 'Soylu',
        emails: ['ksoylu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Üzeyir Hakan',
        lastName: 'Topcu',
        emails: ['ütopcu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG17ISfZgpSaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586346068677?e=2147483647&v=beta&t=lbeLi6lC0dciqZzh7C2iPbBpd5axvTTin_Nbx-Eg-Zg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hexaPeople: {
    name: 'Hexa Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joana Morais',
        lastName: 'Monteiro',
        emails: ['jmonteiro@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nuno',
        lastName: 'Moura',
        emails: ['nmoura@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEUc-3ebPLyVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637765745484?e=2147483647&v=beta&t=-yU3jq0UsvcmtpGTRjEFokHH9m6BzZe3pzOUmca7Gug',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Diogo',
        lastName: 'Oliveira',
        emails: ['doliveira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFl-kGoTDO0Ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517619802858?e=2147483647&v=beta&t=8Lr5NtpTiu_B_sT3LI0SSw3dOSmGu84r3tQG1zApdhQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Inês',
        lastName: 'Abade',
        emails: ['iabade@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFNIExj7xdjKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638814145594?e=2147483647&v=beta&t=I0qItXGEC47tkw2-joFjrwP9vqAHRo7sBGq4JKQZqY4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Francisco',
        lastName: 'Almeida',
        emails: ['falmeida@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFtLRtqeRTs8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675329682244?e=2147483647&v=beta&t=gaVIWQeTbn5KHK2d7C6im-NwTNFQtZ9A-rYT6qfck6U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  webcom: {
    name: 'Konica Minolta IT Solutions Czech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hana Jelínková – CRM Manager – CarTec Group a.s. |',
        lastName: 'LinkedIn',
        emails: ['hlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHr0afmKGhSHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715088551299?e=2147483647&v=beta&t=o_wkNwteF929BtIFdVP45WGC2UW__ZZLMgyxfWKF-G4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName:
          'Stanislav Hlobilek – Managing Director – BLANC Reality Czech',
        lastName: '...',
        emails: ['s...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFLehFNHLnZRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578578090442?e=2147483647&v=beta&t=YKEQ1qhYPWABW_WGy3wc6whGQL2FzyiuXwOsjlKLLFY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Lenka Novotná – Senior Support Consultant – Konica Minolta IT',
        lastName: '...',
        emails: ['l...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Roman',
        lastName: 'Haluzík',
        emails: ['rhaluzík@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jakub Křiva – Konica Minolta IT Solutions Czech |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE3YWyLI8k_HQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615533842288?e=2147483647&v=beta&t=U9dlOMYf8tTdnIp8A-wPaaVdA2EnMK3hVVFyHZzW3WA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cloudredux: {
    name: 'CloudRedux',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pooja',
        lastName: 'Mahale',
        emails: ['pmahale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGIl8PxnVfmZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546698425444?e=2147483647&v=beta&t=NLwyqK3xWSUNwpZN1nE3UsQ_M_MRNGKPvnn61ughQu4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aniket',
        lastName: 'Ashtikar',
        emails: ['aashtikar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFVIIB53Lii0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639996175542?e=2147483647&v=beta&t=P2hZq7b18SlerQpdzkxDkFn6Kuo-aWG_FeF-KDYiImE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nikhil',
        lastName: 'Gandal',
        emails: ['ngandal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGh9RwD6zd57Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720463927370?e=2147483647&v=beta&t=S9kUFAnkhj9JRfIjWI0f-yGMUKr79fc79E9oWg5q-JU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Swarup',
        lastName: 'Gourkar',
        emails: ['sgourkar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGavm1e1988XA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662547680287?e=2147483647&v=beta&t=Z-wvWYYS0GZkfQz8tA4DOzUpXeNmjAx5hbO-SeoXq08',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vedant',
        lastName: 'Raut',
        emails: ['vraut@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHUj2gNcJQSqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709577300469?e=2147483647&v=beta&t=ZhbufFD631baK1RwzKrH-3sjAV1H5ECGfJX6zcd3dcY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pathfactory: {
    name: 'PathFactory',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pete',
        lastName: 'Lorenco',
        emails: ['plorenco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEXMIvg6VKe-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719856068899?e=2147483647&v=beta&t=uUlqSnYLAQQw8UsiiZzNsyI3-U63C2SD_Ny9VyCJkpo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dev',
        lastName: 'Ganesan',
        emails: ['dganesan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGqzZW9KmrIfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671932516688?e=2147483647&v=beta&t=2eE2F5zH6q41-FVf6QVAcQir1F3Fq1aT1pbCyf_lumw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'VENK',
        lastName: 'CHANDRAN',
        emails: ['vchandran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHJkKmspZ0CVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705185388782?e=2147483647&v=beta&t=nZe5x9yksqlQfzzGwztTp5hB76T2WvAZ0V1c09rZmmM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christine',
        lastName: 'Polewarczyk',
        emails: ['cpolewarczyk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHydGRhE_aCEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1577982090348?e=2147483647&v=beta&t=OFTPBiRlgXlIKnezW1_ApHazqs7xVTvldlUt3fjbXsA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeff',
        lastName: 'Bashaw',
        emails: ['jbashaw@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF9fsvdp6FQDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712097886798?e=2147483647&v=beta&t=MKwbVUsA0tWUcFpxcmZRdY_HayXF8Uxwd9pKl_klQLw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  seeton: {
    name: 'Seeton',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kenny',
        lastName: 'Seeton',
        emails: ['kseeton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHod94aWd5MSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516889950785?e=2147483647&v=beta&t=T3c0jfHrvesEaAVrYI7S72pQ7Lnde5A6XLv8EwCtqaU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ann',
        lastName: 'Seeton',
        emails: ['aseeton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH43GIdyTjFmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517436780768?e=2147483647&v=beta&t=jkhhH8yN3K0BZcopWLnbSC9LxoVEO_RBxgpJ0jHXXJs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christopher',
        lastName: 'Seeton',
        emails: ['cseeton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Patrick',
        lastName: 'Seeton',
        emails: ['pseeton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Seeton',
        emails: ['mseeton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF95pDq818cRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578686624246?e=2147483647&v=beta&t=2XuLGd2AxKmjzgtAmI1GRXi0U3VjbsJXLKbIBOQWx94',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  speroteck: {
    name: 'Speroteck, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jacob',
        lastName: 'Polyanovsky',
        emails: ['jpolyanovsky@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lena',
        lastName: 'Kolga',
        emails: ['lkolga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGcP7C1BlKB9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517425254815?e=2147483647&v=beta&t=ONvLSXKr2zYAM7VUQNOVEOGPGCzn6KxHhMc3GlxtW2k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Galia Polyanovsky,',
        lastName: 'MBA',
        emails: ['gmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFFhoZsYy96Rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629992296879?e=2147483647&v=beta&t=iXnJcJMsdQWcBOvVZvUiWl_ck_i0nXbMSYuWlg0bM1I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maria Stronska – Speroteck, Inc |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHkUgv581qGbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605896699513?e=2147483647&v=beta&t=ddzsBEdz8bYJIlHMBTEVkVQ3mg0YsvPkpC8kSBkCsCs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName:
          'Katerina Krasnoshchok – Technical Recruiter – Speroteck, Inc',
        lastName: '...',
        emails: ['k...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  optaresolutions: {
    name: 'Optare Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'José',
        lastName: 'Cunha',
        emails: ['jcunha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEl2q-GoKtdQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721821168004?e=2147483647&v=beta&t=7h-D9Q5Y-JfgU_P3xUHZEQzgOiBpo7qGwOawHE-7srA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ricardo',
        lastName: 'Riguera',
        emails: ['rriguera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGfiaOQUhdq7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516241203563?e=2147483647&v=beta&t=tvlDoleMrBQxPdY-_VTq15H4fUnJvtaGwzmy6d-HUgc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Abel Fernandez',
        lastName: 'Perez',
        emails: ['aperez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF2Hxc_8xHpXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713281529864?e=2147483647&v=beta&t=Qw8r_NT4mhboRpE0BfmoAHwn1lqvv8jx3LVVL5ybL6o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Luis',
        lastName: 'Alves',
        emails: ['lalves@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG13YcYXSinHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726084230085?e=2147483647&v=beta&t=9qMFL4PB-rLGgTgVb5CYkaAjWl85xcu_miwBFoubsws',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Fernando Bastante',
        lastName: 'Pérez',
        emails: ['fpérez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF8kXj4OSYn7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516845063533?e=2147483647&v=beta&t=0Os--gh7vFoedz5HfvXGLjji4jNEJ4dgSo06pEVnT6Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fics: {
    name: 'FICS - Financial Industry Computer Systems, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Cheung',
        emails: ['kcheung@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dyan',
        lastName: 'Dalton',
        emails: ['ddalton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jacob',
        lastName: 'Montes',
        emails: ['jmontes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFQneW1CWQ2Dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697491756769?e=2147483647&v=beta&t=PWCTHgOa8ZqSx203sOkds7WKuStIuBZTu6235Q6YQ7E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: "Di'ana",
        lastName: 'Jordan',
        emails: ['djordan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHZTPGTLpKMVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517583260483?e=2147483647&v=beta&t=HzhVyqOxoN_-8BTDMTywPXjxb_IWBeBy852bbZGtyYQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Larry',
        lastName: 'Brazier',
        emails: ['lbrazier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHVJJsmxWcS6w/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516549731353?e=2147483647&v=beta&t=s6S_mtLckHmnGslSIijANRC2Jzmz1uWteTW7dezaYxs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cosolutions: {
    name: 'CoSolutions, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Walter',
        lastName: 'Constantine',
        emails: ['wconstantine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF2KVDD-x0_lQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516340058731?e=2147483647&v=beta&t=e5C_1BKM0RO91UgW45BKPaUxsnB5uOE4jwb19nOsIm0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nancy Nguyen, SPHR, GPHR,',
        lastName: 'SHRM',
        emails: ['nshrm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ingrid',
        lastName: 'Constantine',
        emails: ['iconstantine@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephen',
        lastName: 'Turney',
        emails: ['sturney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Wilbert',
        lastName: 'Brinson',
        emails: ['wbrinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHO1a5Cbh_9_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720482606305?e=2147483647&v=beta&t=X_SPeIO8qhlcQmpxfcgllL40lKVsm8L8aAuN1lE36uo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  prokon: {
    name: 'PROKON Software Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jacobus Oberholster',
        lastName: 'PrTechniEng',
        emails: ['jprtechnieng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGfMo2NcdSYyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729511078726?e=2147483647&v=beta&t=eQsoahxlEB1CvtJ7WZCmK0PUsuXgkm0JMpLGUX-B9U8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason Daniel',
        lastName: 'Barry',
        emails: ['jbarry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEaAmDrqI1SIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685428003007?e=2147483647&v=beta&t=jTqM2XYClLYAa_ys6VY_H7J7NJoA3Bo7cr6YGw6I58k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Révan',
        lastName: 'Benadé',
        emails: ['rbenadé@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFVxg94uB39Mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1533107803008?e=2147483647&v=beta&t=kT1HxMipJoh_M7X9lHStUA4wG992kO1GGJZRsHx4Z0E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Louis',
        lastName: 'Viljoen',
        emails: ['lviljoen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Philani',
        lastName: 'Vithi',
        emails: ['pvithi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  orbita: {
    name: 'Группа компаний Орбита',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Maria',
        lastName: 'Orbita',
        emails: ['morbita@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEbZlrzxtQ7yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563478756011?e=2147483647&v=beta&t=wh8V4xPZ9VJYSNOOeWdVOX8XIBfOE-poOmuH1Xq5zdo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'JESUS ORBITA',
        lastName: 'JR',
        emails: ['jjr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nathan',
        lastName: 'Treloar',
        emails: ['ntreloar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Patty',
        lastName: 'Riskind',
        emails: ['priskind@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Artem',
        lastName: 'Reva',
        emails: ['areva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGQw1VK1LR9Qg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627663621682?e=2147483647&v=beta&t=lwY96SvXyPYmJ4lZlRzDIznTdcUvcT5z-7vKcM0kicw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  artelllc: {
    name: 'Artel LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul',
        lastName: 'Frye',
        emails: ['pfrye@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Katherine',
        lastName: 'Deel',
        emails: ['kdeel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGqF8IKztlBLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517694607827?e=2147483647&v=beta&t=3KkaB9YZG4HAKH0AwXEfMIxS1QJ6sskoY8BaU8ruMm0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vladislav',
        lastName: 'Yelfimov',
        emails: ['vyelfimov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHVnFbNWbQA2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1539125408599?e=2147483647&v=beta&t=7S0dEtE-ueJp5B31nuVOxos7K-YTpK-AmKuesNSvwvQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ed',
        lastName: 'Spitler',
        emails: ['espitler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bernadette',
        lastName: 'Danis',
        emails: ['bdanis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  metric5: {
    name: 'Metric5',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'William',
        lastName: 'Malik',
        emails: ['wmalik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH55VPD0jFLyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727809639201?e=2147483647&v=beta&t=8gt9TTcgvStUnfmn6YHYb19H9Nxelf_Km9SlnlT7UUY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Asfa',
        lastName: 'Malik',
        emails: ['amalik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGEpAn7CGvbQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674523055186?e=2147483647&v=beta&t=LJ0AlgctZztv1xb5esORj9S2bPkMAHwtqxffyjtkm7g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Malik',
        lastName: 'Graves',
        emails: ['mgraves@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHdJ_4896G_Og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719347688784?e=2147483647&v=beta&t=z8JNTsWhdADSnTc0EAmp25a1wRIyJoxZpSNpLZ18IJI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hasham',
        lastName: 'Malik',
        emails: ['hmalik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHYcfqKiEFfTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644382928978?e=2147483647&v=beta&t=o992Wb-PdDpsCLIgqDPQJduHmiCTqAmdEzvE7t_129A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shelina',
        lastName: 'Malik',
        emails: ['smalik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFgVM_pZNymCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698870332658?e=2147483647&v=beta&t=1bjDJCLGpra411OykVHzyduKjGgNvJ_pXZrFAtDk86c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gosecure: {
    name: 'GoSecure',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Richard R. (Rick)',
        lastName: 'Miller',
        emails: ['rmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGMJzRc4m6WyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588357783187?e=2147483647&v=beta&t=W4N-x3szRxEyyrzuemT-veCrcpHWtrK9sJwmMGzJkO8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'DeRuff',
        emails: ['dderuff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHEtxDzvO7wSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617919308170?e=2147483647&v=beta&t=lOHp_6818fFhiNWwwxjl6PicRnUNQeFVnHNiTorbXeY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chuck',
        lastName: 'Gose',
        emails: ['cgose@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGqViu_In9eDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571419806450?e=2147483647&v=beta&t=PWmV8OgDhkwKRdW05Yr6I-XS-FLxf2v1k-Zk5r_F1dM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tadi',
        lastName: 'K.',
        emails: ['tk.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mathieu',
        lastName: 'Grignon',
        emails: ['mgrignon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cleeng: {
    name: 'Cleeng',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ragna Ghoreishi – Cleeng |',
        lastName: 'LinkedIn',
        emails: ['rlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexis',
        lastName: 'Gaï',
        emails: ['agaï@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGnIwpOAcjuJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675026652799?e=2147483647&v=beta&t=M_t19mMDWuLP8BX9ii3lxnzKb4xqr5uyJiEEZ4eNdPY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Damien Organ,',
        lastName: 'PhD',
        emails: ['dphd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maria Chardakis,',
        lastName: 'MSc',
        emails: ['mmsc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF-AWVqYkJzqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711662378336?e=2147483647&v=beta&t=u2SzwlK1aLPc59I1TS_hVNyAMnLB3h5j2D7Jj1-KHgA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Donald',
        lastName: 'Res',
        emails: ['dres@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF_efskQsNpVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582796592312?e=2147483647&v=beta&t=jiIHIJHoGJt40F9U0TjSPgYFZdi7DT6pFpZQlnwEihQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  plmnordic: {
    name: 'PLM Nordic',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'JAYDEEP',
        lastName: 'RAMANI',
        emails: ['jramani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQETnyL60FQeIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696614027409?e=2147483647&v=beta&t=tfV7rgoNCMZApwGnKT89qw5Pdn6lC5NyJACyiNXKFeg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lakshmi',
        lastName: 'Saranya',
        emails: ['lsaranya@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nadeem',
        lastName: 'Shaikh',
        emails: ['nshaikh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHelz4OUiqoIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726246274991?e=2147483647&v=beta&t=LSnpI8IA-rEf9PjLSLxi_PnL37vi8gsfCEL3g63iGy0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rutuja',
        lastName: 'Boinwad',
        emails: ['rboinwad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF_mkvUknnhlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676719163890?e=2147483647&v=beta&t=oaCdti-ctiTuM1KMVZ9Q-EjS6JErTNSIAX-7IgrsKCE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ambuj',
        lastName: 'Dwivedi',
        emails: ['adwivedi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFlqRINaCxnrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719216847315?e=2147483647&v=beta&t=PKqx0LKL72SkMyz4sjBHeH8YJtixz4xamYucJf3jbOw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  datawebglobal: {
    name: 'Dataweb Global Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Liubov',
        lastName: 'Kutsan',
        emails: ['lkutsan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHymhMVzxviow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610924362956?e=2147483647&v=beta&t=U8pdhDcXCfQTPfVO3Bg1Qctj0v0GcxZ8Dk7ywYMQVIg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dias',
        lastName: 'Rustem',
        emails: ['drustem@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHzqpBcbYwhuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723675311430?e=2147483647&v=beta&t=_OfJ96pox-NT03T5NqwcjkIqvhHi-7__5VtuQoibAfA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anna Piskun – Dataweb Global Group |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFBGZoYK8AePw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516315117932?e=2147483647&v=beta&t=_TZshLHG3z-ePizbBixpwwgFR5pyYHAXFUwpKYJmegQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nikita Miahkykh – Dataweb Global Group |',
        lastName: 'LinkedIn',
        emails: ['nlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGFJ8kAyBjo2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675248132329?e=2147483647&v=beta&t=XeokM5_Ac1dOSSx-gL-3WbG8Ml40SaFdTBd1y19AZCI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sergii',
        lastName: 'Rudenko',
        emails: ['srudenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE0Ciw5cay4bQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634579392776?e=2147483647&v=beta&t=LfikXZu763BBbP_6e03hAgjn5ZWSofirJarMgvBxXdE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trendNetworks: {
    name: 'TREND Networks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mike',
        lastName: 'Grindele',
        emails: ['mgrindele@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEosV6lhoQs9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606496272296?e=2147483647&v=beta&t=tWTC-GEl78KuJqrpRa7z0VSpAB4-BZWJGxUar2GWFBk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paul',
        lastName: 'Walsh',
        emails: ['pwalsh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Sullivan',
        emails: ['csullivan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dan',
        lastName: 'Barrera',
        emails: ['dbarrera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE0bc1loHqCyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696027567037?e=2147483647&v=beta&t=Wm81eBSNAPRFoG1oEalY9kBPzqGdkkCBq3thSnXYzD0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shabbir',
        lastName: 'Ahmed',
        emails: ['sahmed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHpD-HCfZ0k_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596580613547?e=2147483647&v=beta&t=fuX8ORsmtPWrF9icYN8xGxlUnnIi9xYLTDZ4owjSqtA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tractorzoom: {
    name: 'Tractor Zoom',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: '‎Adam󠄴',
        lastName: 'Best‎',
        emails: ['‎best‎@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tyler',
        lastName: 'Lowy',
        emails: ['tlowy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGy0Ytz1X5RxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726837601153?e=2147483647&v=beta&t=B5pEwNcD8OmqZpW-_IemH0hCV90oCEI6Vqtjp2g2k8o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carter',
        lastName: 'Roskam',
        emails: ['croskam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFyR0kvch4UAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726862602891?e=2147483647&v=beta&t=i-glfpWyCaRN-4f9X4UKtW30T58BmVNmyEyvPXZCSDY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Julie',
        lastName: 'Johnson',
        emails: ['jjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQExwoJHLL4TBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632504653586?e=2147483647&v=beta&t=efNe7zRMD1S96sN6qsihL3tNc8g66wwMH8fK9K6Z6Xs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zac',
        lastName: 'Sandvig',
        emails: ['zsandvig@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGUJjOh9MqKuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711145161151?e=2147483647&v=beta&t=jWeEGvRrU8_PJgxTjVOyIRY3ZeI_zBDoK20bI3UXvms',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fin: {
    name: 'FinDev',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anne',
        lastName: '',
        emails: ['a@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lori',
        lastName: 'Kerr',
        emails: ['lkerr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHN0lOY18j4zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561761712439?e=2147483647&v=beta&t=LRKFUJ8v69PXLsu4ll8chT4EMdBzBO1dHxYZkietk9E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paulo Martelli,',
        lastName: 'CFA',
        emails: ['pcfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF0-nUtqkV2zQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644892214858?e=2147483647&v=beta&t=3rpnuy4xz_BoGhnTWlrC8aUZP79hYCiHCX-QIrad4ns',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Bhamjee',
        emails: ['dbhamjee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGgOmeQkGt4zw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555727730085?e=2147483647&v=beta&t=SJxHTJAfEt26zhZjJ49mZPfJ73RLvot02eFwVJ1Ab1Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stéphanie',
        lastName: 'Emond',
        emails: ['semond@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGyYdJOBcixrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617240719507?e=2147483647&v=beta&t=KWDZLneFv6REIl9Nsx8W7dugJmeASA9_V1sb68dSi6E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  veltio: {
    name: 'Veltio',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Juan Antonio Lazalde,',
        lastName: 'LCB',
        emails: ['jlcb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHwE6TJzh3oYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674933675788?e=2147483647&v=beta&t=ANo6X_fNNY4HJnpI0jOMHYj98lSHcPsYrqeKuo-I8E4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Veltio',
        lastName: 'Consults',
        emails: ['vconsults@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHVvvOw5LQjCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517884629944?e=2147483647&v=beta&t=U7RSeQdHjNu60FfYnxyI2vjMfSMt53WgG1OXCz-1-jQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'VELTIO',
        lastName: 'GROUP',
        emails: ['vgroup@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF-mHkwzSIJrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542738467398?e=2147483647&v=beta&t=EkxK5h74tEKOzkZMyy5nfEcqdO-NY8k6HzKiO_wXT3s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anastasia',
        lastName: 'Antonenko',
        emails: ['aantonenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFtsaUQaGwoAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516905140566?e=2147483647&v=beta&t=tusJjGIQ09XMW_OkaoHc3YQ4fd2TPbasUjxOkSiGbAo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrey',
        lastName: 'Derevyanko',
        emails: ['aderevyanko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFlAKuoGtqw9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695302331704?e=2147483647&v=beta&t=auwogUuM4kN09tdMhTY4gvLtSzRvA6BTH9fVxVr186U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  polyworksmexico: {
    name: 'PolyWorks Mexico',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amar',
        lastName: 'Kulkarni',
        emails: ['akulkarni@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGECBgs2nsSDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691050560277?e=2147483647&v=beta&t=yUEoJ2ijtOvV6EdQ1YpaDOPBTUFbMc6SPXMJPrLheDo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Webb',
        emails: ['jwebb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEJJzWR9ON0kQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517487857010?e=2147483647&v=beta&t=p0bcB_aTqdJ2TCLTgj6Exn4YEmgGuCLRa0PUHdlIpDo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eduardo',
        lastName: 'Villalaz',
        emails: ['evillalaz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFjL2XLnczapA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684805149431?e=2147483647&v=beta&t=9h9SPfrj94VgpAIQsE_76egFfX_bMIoiuYPHTzScEnY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marisela',
        lastName: 'Francisco',
        emails: ['mfrancisco@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Diego Pantoja',
        lastName: 'Hernández',
        emails: ['dhernández@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG-yazLXFBFBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718307278573?e=2147483647&v=beta&t=4sB9_OdsLAFgnsoEM6LB246kgM84SDxom-IWPvWhKO4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tandemg: {
    name: 'TandemG',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aubida',
        lastName: 'Naalwa',
        emails: ['anaalwa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHfoGqhqyZxQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621449104255?e=2147483647&v=beta&t=JUCUEx0x8TwAjM6MYwiKhYtMJQeagmMwm_4imisQW80',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sergejs',
        lastName: 'Hatinecs',
        emails: ['shatinecs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFWIecWc8mrtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517213629009?e=2147483647&v=beta&t=jY4z1Koe6lUlEIZ621sThRmeGDcLKwC01vr9s4DsSzU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Adi',
        lastName: 'Bashran',
        emails: ['abashran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHlumVqv20Sow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668262784073?e=2147483647&v=beta&t=StQMSRNETKA3rTftV215EzOvp4IVzsXd7S1yeZzbEbE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chaya',
        lastName: 'Levitz',
        emails: ['clevitz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGBM1tVk1BX1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669135421446?e=2147483647&v=beta&t=KnuWMda1fal44_w5StJz6l_PAgQ9Wipz6nCRVf_NqZk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sagi',
        lastName: 'Zar',
        emails: ['szar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH3snlUP9cybQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517714821317?e=2147483647&v=beta&t=Ob46RyYtxtVuAll4N1a4lJS9AdZuTcAiLIyJKptltM0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  area: {
    name: 'Area of Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alex ""Magnus""',
        lastName: 'Lehmann',
        emails: ['alehmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGf1RWo19xRrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607807409677?e=2147483647&v=beta&t=O3Me_FHEklMlssb1zidYkYAN-MNuTrpyaiYlBUlsJt4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chase',
        lastName: 'Madrid',
        emails: ['cmadrid@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEmXfjI42ojOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605217974935?e=2147483647&v=beta&t=eHL_q7X3gfcD_v7EdOgoWROcNADvGfTrykfVDO7Ww1c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Noah',
        lastName: 'Rosenberg',
        emails: ['nrosenberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dan',
        lastName: 'Sutton',
        emails: ['dsutton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGZ_D2VHETGrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702669459252?e=2147483647&v=beta&t=jPck4L76VlOX-gAOlAGC51sDAQH1k0bVQzAeboBlK6Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steve',
        lastName: 'Henderson',
        emails: ['shenderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFZpw_M73Qxtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672268229653?e=2147483647&v=beta&t=J3Vb7B6O-sCoozQ88JbIVR3uOLBtfxHUBcrWTYmM06I',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aggity: {
    name: 'aggity',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Javier',
        lastName: 'Monjas',
        emails: ['jmonjas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFwMCUqHT_ezg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608555668201?e=2147483647&v=beta&t=N9w376zToCYxRZCfitD_so-OS0KoEVsoFe_9tZZ78-k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Katsiaryna',
        lastName: 'Zaitsava',
        emails: ['kzaitsava@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Toni',
        lastName: 'Parada',
        emails: ['tparada@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHrAHCbw7FTXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571008942213?e=2147483647&v=beta&t=mQwtm8U9hhVnlJCWZdLLc-1_pTqPks4DSAn07nzZGbE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paola',
        lastName: 'Sanchez',
        emails: ['psanchez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sharon',
        lastName: 'Federici',
        emails: ['sfederici@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFra58eHYPBVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715325997614?e=2147483647&v=beta&t=KqlJmDTB21T2CFsK39g_x2jBqBC-EY62C6cJ-zj_3BU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aadhavgroup: {
    name: 'Aadhav Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aadhav',
        lastName: 'Katthekasu',
        emails: ['akatthekasu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Prathees',
        lastName: 'T',
        emails: ['pt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGS4RmtN88ijQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558276719360?e=2147483647&v=beta&t=2NEc2Z60LQcnNvof3sXRiZmtG8Hd15naihZ8hEvg-zQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Suresh',
        lastName: 'Aadhav',
        emails: ['saadhav@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dhanikachalam',
        lastName: 'Kandasamy',
        emails: ['dkandasamy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHnY71NpwMBsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516857650906?e=2147483647&v=beta&t=P0UsiHLhZ24UYKwZ4CQQn5EWkJFLPxJ2joBHyTEMzQs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abdulrahman',
        lastName: 'Salau',
        emails: ['asalau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGc0eea1ENOlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652338828003?e=2147483647&v=beta&t=uaCNBtR8Ny8shMl2GgkMw_j23-OEhoLQzVH4xj5ANSo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  crestwood: {
    name: 'Crestwood Associates',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'McGuckin',
        emails: ['bmcguckin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEQpsrNkYgmsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516277238376?e=2147483647&v=beta&t=ZiMfv3fwkzttprCuYvbd0_r82ThkmTi3sWidMsUlVDE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Winston',
        lastName: 'Hewitt',
        emails: ['whewitt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephanie',
        lastName: 'McColly',
        emails: ['smccolly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFR0nZ_p5pO0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641843870263?e=2147483647&v=beta&t=QZwg3hPDkXy6zYoJ8bpf8c4ji7BwdMYrgMDekTWIuHw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nancy',
        lastName: 'Moss',
        emails: ['nmoss@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Heather',
        lastName: 'Contardi',
        emails: ['hcontardi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH2pv4AmmfZTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667600036223?e=2147483647&v=beta&t=7K_C0tz9UPOD4GGaVZtvb7hCasSFi1g__bxAgvRuUsw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thingsboard: {
    name: 'ThingsBoard',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rossilynne Skena',
        lastName: 'Culgan',
        emails: ['rculgan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHijlARPOmD5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675724263661?e=2147483647&v=beta&t=qtDYR9OfpS-KyZBTt9AuX_YOGo6s0ahy57_crso9E3Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tom Iselin (First Things',
        lastName: 'First)',
        emails: ['tfirst)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFBeEGHAFAvVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517325141960?e=2147483647&v=beta&t=KwQQJFeF-XLM0dVStvU1kEPUIsOQrkLvFSGrEgmRTZE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrii Shvaika – Managing Director – ThingsBoard |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anastasiia Antoniuk – Sales Account Manager – ThingsBoard',
        lastName: '...',
        emails: ['a...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFS02OXGcvbyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709034268610?e=2147483647&v=beta&t=NSIvcLh-shW8YOign3FuhVdvyiPPkE9dtm_EKm9gTbE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Karsten',
        lastName: 'Flowers',
        emails: ['kflowers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFOZzg9ENCBYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585652534266?e=2147483647&v=beta&t=xlNTobQJbm6SE0rsHbszXy5Fab4ZCWdC_xThgr1lryQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  acromedia: {
    name: 'Acro Commerce',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tim',
        lastName: 'Rutherford',
        emails: ['trutherford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF-I_oIosgVFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698966050968?e=2147483647&v=beta&t=UyIi8JfOwdKlQIy-ZA-CcujO1RU4Ff5SVZvUZpPS3FY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jared',
        lastName: 'Seitz',
        emails: ['jseitz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGv0GMblAdFTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673713505498?e=2147483647&v=beta&t=murqJxWs6_IRbuONe76797_LF2PRXX8Qw9Lyj1xKtcc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anthony',
        lastName: 'Vanoni',
        emails: ['avanoni@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Schaffner',
        emails: ['jschaffner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH3S12Vmr9Cuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637272307485?e=2147483647&v=beta&t=WOzvkyq9v8C-uqfPO4GSdyU1Nxx7sBxddekHN3-nY1s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeff',
        lastName: 'Clark',
        emails: ['jclark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFH11oOGg4OrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698080033460?e=2147483647&v=beta&t=rtCHNXIeScK0qKiHuVt9GHbQs95KwhuIFT_Mae1-R1s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  coleridgeinitiative: {
    name: 'Coleridge Initiative',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jessica',
        lastName: 'Cunningham',
        emails: ['jcunningham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQESKG0_QRrzIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598971964006?e=2147483647&v=beta&t=rfosSVFyg59E66EPtmm2V6_ql0rJi29FpmeThb4-TFQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ahu',
        lastName: 'Yildirmaz',
        emails: ['ayildirmaz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQElQvUA7JtiYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635187696292?e=2147483647&v=beta&t=A3xkSvhwbsE_3RY2tltrWKZkZgaroG4EEeFzuOKSQd8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Benjamin',
        lastName: 'Feder',
        emails: ['bfeder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQESpYFQiPqLEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716305383084?e=2147483647&v=beta&t=t4mhbZ1-mNHVhYwU_rP8sL4aKWT3nHPZ2ofNil1o2Rs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Julia',
        lastName: 'Lane',
        emails: ['jlane@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Corey Sparks,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGqOqaykYJo5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690493948523?e=2147483647&v=beta&t=yFdKFZDPdwJr6TFOpF0ZzdGl8VxJqwUKHMP93rY2Nwk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nimbisservices: {
    name: 'Nimbis Services, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Graybill',
        emails: ['rgraybill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGlwG4uvXa8RQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516343143149?e=2147483647&v=beta&t=-eSfuwP1HLBcWCjUZvVBKYkqycnxecKA08XrwceiK9c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brian',
        lastName: 'Schott',
        emails: ['bschott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGOAwvybio7pQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517721132679?e=2147483647&v=beta&t=AvmZxGutK9KgSxEiSIGPz5ZWl-fdQWpQgm14B9fqVjw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeremy Schoeller',
        lastName: 'Porter',
        emails: ['jporter@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stacie',
        lastName: 'Valencia',
        emails: ['svalencia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHLSnUQ7tHK6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610839358068?e=2147483647&v=beta&t=ULJ-ecVJjmsWQuPon7BlZnQlKyYl7KfGHQY5RsEylX0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: 'Burgett',
        emails: ['pburgett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF84c8sSiXoaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579797447925?e=2147483647&v=beta&t=YLQJkkeC2gZItZgKEM4r3LnvcPBrI_Mr6V7K0pJduUg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nfocus: {
    name: 'nFocus Solutions®',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ananda',
        lastName: 'Roberts',
        emails: ['aroberts@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kelly',
        lastName: 'Connelly',
        emails: ['kconnelly@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Don',
        lastName: 'Pruitt',
        emails: ['dpruitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFXhHezmcIJGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516274794709?e=2147483647&v=beta&t=sRw1mOmJIAAmeEiigzura58UPgvwhIhTS0vi-GUbi9k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rick',
        lastName: 'Reiman',
        emails: ['rreiman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF8756XgDeCog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687583105437?e=2147483647&v=beta&t=TMP81At1tTfQ67bAuVZhoCoWkCnp2lQupSpwIXOq7ys',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Taylor',
        lastName: 'Wistort',
        emails: ['twistort@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEHlvrEpZDj6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580419884304?e=2147483647&v=beta&t=p49Dz_CeBzt9JTDKgGNvFmlMrkw1VUgnBXdTQSWVkcU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gsStudio: {
    name: 'G-SPACE STUDIO LTD',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ramin',
        lastName: 'Shokrizade',
        emails: ['rshokrizade@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE9LrP54-AjDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516273238847?e=2147483647&v=beta&t=EvLtLJ05lGVUoz5yw_UbPEfBC4RyCGKgbkiz1XfMkOI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matthew Gatland – System Designer – A',
        lastName: 'well',
        emails: ['mwell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ethan',
        lastName: 'Bristowe',
        emails: ['ebristowe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHb0XOZYo7H6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618874738192?e=2147483647&v=beta&t=Cdc5nozpDrpr1vgoeY5M2CZCh5qysi6GVXasyRgTOcI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nik',
        lastName: 'Mikros',
        emails: ['nmikros@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEV6JmZUfySBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516283774683?e=2147483647&v=beta&t=Ox62foafELKr7J9pfVwlHJYWpNK5DaN0Cl8hi4aeKX0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steven',
        lastName: 'Harmon',
        emails: ['sharmon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQESh4jvLo-6tA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719016902926?e=2147483647&v=beta&t=MA6St8PPNAs03lEfcYjU0YN7SGc-BraxhjUklE5Cy9I',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  iflex: {
    name: 'iFlex',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Adelman',
        emails: ['jadelman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael Kemper,',
        lastName: 'MBA',
        emails: ['mmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGg5GF8fVPNQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685798878681?e=2147483647&v=beta&t=b-aj5GtpGfTttwhEv1bVBeOnZkN0YJHNn-ipVYRXtEM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Hatter',
        emails: ['shatter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGl9AkYABAW5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571257248987?e=2147483647&v=beta&t=-XM3GRNF0aHsxplGG16-2zLPAcaWiM7hiXj6FoEPjJc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joshua',
        lastName: 'Reed',
        emails: ['jreed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQElW9iMRB4aqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690845019116?e=2147483647&v=beta&t=VOjgIMm0vLzl7uxcEPUx4rntEY2H3ucL0ECgdZLCa0w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ryan',
        lastName: 'Cole',
        emails: ['rcole@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHkx3NsMyU8uQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645723628723?e=2147483647&v=beta&t=QAjZOSbhekKKM5YOe1fZKlq_w582gNMyFk0iRbrA-G4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vertafore: {
    name: 'Vertafore Canada',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hollie',
        lastName: 'Fitzgerald',
        emails: ['hfitzgerald@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGzCEydkJPYdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634224959664?e=2147483647&v=beta&t=N2hiqFx-wa8arsqAhasERdU-sWkiMiV5lvggvwRoP6c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Susanne',
        lastName: 'Seglenieks',
        emails: ['sseglenieks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEHizfEfL18jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694786790456?e=2147483647&v=beta&t=BCl8PSccReZIJfqADNq6a0LwKpUhJxs6llK3sDCsd64',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stacey',
        lastName: 'Miranda',
        emails: ['smiranda@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sylvie',
        lastName: 'Beaupre',
        emails: ['sbeaupre@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGhRytLp6PG8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1540491993542?e=2147483647&v=beta&t=tZEdl2ZqUXYwUr_J5F4GBcXEeEjtxblfjCPbpd1wqF8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Laurent',
        lastName: 'Nadeau',
        emails: ['lnadeau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUW5i8Esy9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685992244056?e=2147483647&v=beta&t=eGDZwB63dAgUc3f2elUkgwtJr4obkzDUq_zVbfmnEsg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  decerto: {
    name: 'Decerto',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marcin Nowak, MBA – Decerto Sp. z o.o. |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQELRBmbknFmog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583484573469?e=2147483647&v=beta&t=o4z47OOiNMipfNr5Nf2iPoltr8dCh39JhLjuI4R5PNE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Leszek R. – Junior Frontend Developer – Decerto |',
        lastName: 'LinkedIn',
        emails: ['llinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Klaudia Przybysz – Decerto |',
        lastName: 'LinkedIn',
        emails: ['klinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFCu40_WSYMlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707217604056?e=2147483647&v=beta&t=Gng-wRIqBgEHjz4fUxfdcaZft1G9MtUfSlCS9xokjkk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Łukasz Dziak – Decerto |',
        lastName: 'LinkedIn',
        emails: ['łlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGH2Ymkla_yiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517438524330?e=2147483647&v=beta&t=scA-vBk3224cvp0mL2qEw_q7oJu8B3c41_3vzyooku4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sebastian Stobiecki – Decerto sp. z o.o. |',
        lastName: 'LinkedIn',
        emails: ['slinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHDqmN2DCeRtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517024260089?e=2147483647&v=beta&t=4Kz7WHph1c2WXdmX9Yfw6c2aSbnnljUcWkNnNqy96Pk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wiz: {
    name: 'WIZ.AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jennifer',
        lastName: 'Zhang',
        emails: ['jzhang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF3qYUEhbOsPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633535310674?e=2147483647&v=beta&t=6RX6lF09iwApFlLi-CfwJ1tad80iOlk-VEpRQwgUlXk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aaron Nicholas',
        lastName: 'Zhang',
        emails: ['azhang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGgKfr8Nepkqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519998334529?e=2147483647&v=beta&t=tJBEMUl2tpP22nerohmhrCBJPr_l6ISIr5RNJ7qVMrA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alex',
        lastName: 'Song',
        emails: ['asong@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Clara',
        lastName: 'Lim',
        emails: ['clim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chenny',
        lastName: 'Xu',
        emails: ['cxu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  totalprosource: {
    name: 'Prosource',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amanda',
        lastName: 'Rahe',
        emails: ['arahe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEJCVHD6gEwKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688946903841?e=2147483647&v=beta&t=M8Yytj-ya03CxzeZq3_JrvkNV1dYZfHPipcc4DolBYc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ben',
        lastName: 'Russert',
        emails: ['brussert@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ellen',
        lastName: 'Nealis',
        emails: ['enealis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'Heidrich',
        emails: ['mheidrich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGZ0pMJM6y0zQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630531232517?e=2147483647&v=beta&t=Z2nUvuyo2SHmBQrRByIou1sowWeTEvHbRsbSF_K7494',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lee Anne',
        lastName: 'Franklin',
        emails: ['lfranklin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEG8wt3He_x9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522029593683?e=2147483647&v=beta&t=IKTn7bGocKcRrsuXd9Y9-i1aJhLAZTjo-PzrFg1AaEo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  moranlogistics: {
    name: 'Moran Logistics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Colin',
        lastName: 'Moran',
        emails: ['cmoran@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chuck',
        lastName: 'McCreary',
        emails: ['cmccreary@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bob Moran,',
        lastName: 'CWLP',
        emails: ['bcwlp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFszSx_WChKAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1525094718529?e=2147483647&v=beta&t=z0y6TYTnuPb-FPgXw_qxG_biGVnnjoI8evxz1yjlXMI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Gilger',
        emails: ['mgilger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE1ctw4Fs-mag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701292791907?e=2147483647&v=beta&t=6FcohpmBBgWFsM9A9r6kHLtktUgEtJx-bk9fGh3YKuM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Moran',
        emails: ['jmoran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFcw2hrbhwWMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729636245990?e=2147483647&v=beta&t=3c9v6MOQ2A2Z04fRVMTNbwSFk-9c_rnfbRt-k1C6eTg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  infoMatrix: {
    name: 'Info-Matrix Corporation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matthew Wingate, GPHR®, Six Sigma',
        lastName: 'Champion',
        emails: ['mchampion@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQESHT4NPbof8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625586748879?e=2147483647&v=beta&t=Rtz3eK4fhW66-LqkPgxjYj2UzCaUf7eCn8MqWoBwDNI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brenda',
        lastName: 'Ritter',
        emails: ['britter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGP61asGAsIRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553091275876?e=2147483647&v=beta&t=TjK3O25NYzF5UaT8QZNNhuBK7pZwnx9q11dOVb5vw-A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kimberly (Kim)',
        lastName: 'Doey',
        emails: ['kdoey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGrMf4AObdnNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730213632249?e=2147483647&v=beta&t=3e_WWPfrr6_6yXirXCyIaDrwpAbIuvZEaBFMidl53jY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paul',
        lastName: 'Jones',
        emails: ['pjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGu2m6tHNXmLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715968368883?e=2147483647&v=beta&t=nKxtgJR0YWCqYIB89oHFzbgyr804AnvdgSyQXkMZkSc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lisa G.',
        lastName: 'Crane',
        emails: ['lcrane@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF6beM9S6DSFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696946482416?e=2147483647&v=beta&t=PZKELioGdAMGxRbO_0fjuhUsy7Zlw9KXV0iMw9Cc6_A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  saphetor: {
    name: 'Saphetor',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Richard',
        lastName: 'Meyer',
        emails: ['rmeyer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aggeliki',
        lastName: 'Karabassi',
        emails: ['akarabassi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEY8L9nhWcVSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704373985445?e=2147483647&v=beta&t=mYZ_g6Aiky7T6fwPr5KtVZtntjCRSlEkb9Z05mjxRXE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Charles E.',
        lastName: 'Chapple',
        emails: ['cchapple@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG4pPWzTObIZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603295245735?e=2147483647&v=beta&t=BjqdLn1W54KGrk6ZmMsyCbyqpla_0BNEpIJx4LvTKs8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexander Joyner, M.Eng,',
        lastName: 'PhD',
        emails: ['aphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH4ZUVKJN0-Xg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550675463062?e=2147483647&v=beta&t=lol-Fr6PA955k5noWg5gtWuBwRhD_UocF9ubLyviKbE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alexandros',
        lastName: 'Kouris',
        emails: ['akouris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG-r_4QRzngyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517052941581?e=2147483647&v=beta&t=eVqu3_23JK2M4gqMrYpoEdFVFoRCRVN_jEFcrlNLkfc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  truenorthcorporation: {
    name: 'Truenorth Corporation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dany Villafuerte,',
        lastName: 'CISA',
        emails: ['dcisa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Christian',
        lastName: 'Vega',
        emails: ['cvega@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF7KX8PrTRbsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721357372482?e=2147483647&v=beta&t=cDvQ5p7y5CRvio1iiYs0hplnpDFYBNXZa7pN9hzRzQs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Edward',
        lastName: 'Herrera',
        emails: ['eherrera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEyKGPZJ85xhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646945587151?e=2147483647&v=beta&t=2owrVbUueNfDlmS42Jb0LTue_YanrhQ_wJw0aTAJxfc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ana Aparicio',
        lastName: 'Carrasco',
        emails: ['acarrasco@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carlos',
        lastName: 'Fernandez',
        emails: ['cfernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHfWZP7Bm9iMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516458321585?e=2147483647&v=beta&t=Z1Ehb3eT44ApGbz0-0HvBSpBOo2qrjJQZFHUx_3szCA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jaxel: {
    name: 'Jaxel',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Yevgeniy',
        lastName: 'Kapustin',
        emails: ['ykapustin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFs6ZcLy9Xiaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620068157122?e=2147483647&v=beta&t=rZPK3-b9MjxOhhcjysRD9znyZfgPEYf9pfEcoEFXPbo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brett',
        lastName: 'Jaxel',
        emails: ['bjaxel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jaxel',
        lastName: 'Rojas',
        emails: ['jrojas@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maria',
        lastName: 'Malyarova',
        emails: ['mmalyarova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH0TC2kmdzlRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718247688149?e=2147483647&v=beta&t=zPo5lE69tZe7-EorbqUuidfhX-jzQP3xG5sejlm51G4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dimitry Mishenin, MBA,',
        lastName: 'CPIM',
        emails: ['dcpim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  exely: {
    name: 'Exely - Direct Drive for Your Hotel Sales',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Maria',
        lastName: 'Chigina',
        emails: ['mchigina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEg_vrICL63MQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582310833577?e=2147483647&v=beta&t=ktNtP6I61jX2os_WqUPZVz7gyCwfA7MaKRdTqGW6Eyg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'C.J. Exely D.C.,',
        lastName: 'MS',
        emails: ['cms@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG9KukU0Hx2nQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711115222906?e=2147483647&v=beta&t=X98u0lNxzVGKZ7r-yt6SKLYAXjSHjGv8dWH7roQUXGM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Roman',
        lastName: 'Efremov',
        emails: ['refremov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHPsJSrU-Y8PQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517274074099?e=2147483647&v=beta&t=sNGG9o8eSS9qhmaWLNwD8djt9ed6Cq3GHcFI7o3jtAk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yee',
        lastName: 'Ting',
        emails: ['yting@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF311e8Oyv7rQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674293047864?e=2147483647&v=beta&t=9faLquIddZviIoZNM8z6N7uVJn8PCzDGkzinVzJoF14',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lada',
        lastName: 'Kompan',
        emails: ['lkompan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQELelJENXCNmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708435673051?e=2147483647&v=beta&t=f2EI7IXFRxBHgOxekHpkvvwcQuMtKPVYG5dMgHpqNz4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kalyte: {
    name: 'Kalyte',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  botsandus: {
    name: 'Dexory',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrei',
        lastName: 'Danescu',
        emails: ['adanescu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFwrutn1Tfoeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725050564985?e=2147483647&v=beta&t=bj_AGnRWesM-UlHKQFFp41BEma_vtJBtJUJcCntJi3c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Oana Andreea',
        lastName: 'Jinga',
        emails: ['ojinga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGvc0QOcNdRtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688553087029?e=2147483647&v=beta&t=KOE-sbjJD7ntzRlOoUU-slDMTNMTnYt52HcPim_L6KE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'MacLeod',
        emails: ['mmacleod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEr8edN4KhFdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715022324493?e=2147483647&v=beta&t=959zhyGFVb9K0EcJfESBDXW5QonAKt1JNyojaPxr5VE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marcus',
        lastName: 'Scheunemann',
        emails: ['mscheunemann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGX53t2qZZG2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594667166597?e=2147483647&v=beta&t=X8SnC2hg8lIuxIGn8-pqejiG0bo97UggrpAW23OU520',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Peter',
        lastName: 'Bushell',
        emails: ['pbushell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE0kfy9SJIsrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612919461954?e=2147483647&v=beta&t=a9ZXgaftSO5IJE5rK1Spzk7rs8tYOCWOyEzogQlZbMQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  axazure: {
    name: 'Axazure',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cilnet: {
    name: 'Logicalis Portugal',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'João',
        lastName: 'Martins',
        emails: ['jmartins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEoPiSwDBp1kQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690038592990?e=2147483647&v=beta&t=HzO5xsM1gWgN3XpzBti7zbbXa9EV5H7hH2SCxPHU3-E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Artur Martins | Cybersecurity Strategy Exec',
        lastName: 'Advisor',
        emails: ['aadvisor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHmnmBZoQ8Jog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729695369136?e=2147483647&v=beta&t=viInVX3TVQoYZsEVA0s6-AAeU1tm6hCXJFhn1oIVUVY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anabela',
        lastName: 'Jesus',
        emails: ['ajesus@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marcos',
        lastName: 'Sutil',
        emails: ['msutil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEf6n9vGMa9hQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679061894231?e=2147483647&v=beta&t=XjQg21Ly3nCuAkvhPKnc9DR9t05kjhZcXAEXoj8FBAU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carlos',
        lastName: 'Gonçalves',
        emails: ['cgonçalves@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEvVejKidEgww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615465466170?e=2147483647&v=beta&t=zX6Sx5Us8SNCDsvlCR4GFRgT5qUhGBFWQnnzr1NYLns',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cetitec: {
    name: 'CETITEC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stjepan',
        lastName: 'Gornik',
        emails: ['sgornik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHJ1o-Vsh4hNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707254492189?e=2147483647&v=beta&t=p0cYzXhEGrC7X2G1fw9BhHKuS2pQZS9h-IEdsZ7B9r4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rhushabh Raghavendra – CETITEC GmbH |',
        lastName: 'LinkedIn',
        emails: ['rlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH796WQP3xF_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611242564618?e=2147483647&v=beta&t=5l-f7Dkm3A2IA-fMekSPyH5CwwgDrEqPtn_QXnrbkas',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Joachim Leonhard – Chief Sales Officer (CSO) – CETITEC GmbH',
        lastName: '...',
        emails: ['j...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lucian',
        lastName: 'Iuga',
        emails: ['liuga@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jürgen Gerber – Senior Project Manager – CETITEC GmbH |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGw1eC6fqXasQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1533903211935?e=2147483647&v=beta&t=WnG_GdWzB_fCgjAIOFPuDBvt384USWSr8Hgv3179o3U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bloomgrowth: {
    name: 'Bloom Growth™',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Evan',
        lastName: 'Bloom',
        emails: ['ebloom@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'Bloom',
        emails: ['abloom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHyxXRMTJ1Amw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729971063461?e=2147483647&v=beta&t=8NCXrQvj-kTDGdgnsiQOHNwlPagIaiPmoL5Kyh3TxMI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'K. Adam',
        lastName: 'Bloom',
        emails: ['kbloom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG7lesaXweTVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683674806210?e=2147483647&v=beta&t=glzBfRrRFXIqIwHMKUk6IQL2EKjhETC3WBct4yp-o44',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Naomi',
        lastName: 'Bloom',
        emails: ['nbloom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHOvYgGyoye4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726669792962?e=2147483647&v=beta&t=TrCEXptDhZemURwagKTDAYRlNKetPi9NGC6WtIxSHn4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dr. Jabe',
        lastName: 'Bloom',
        emails: ['dbloom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEqtATdq_EwiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664043932751?e=2147483647&v=beta&t=IOa3fNHRMwx28167bK10doUL2dzNAjtvieTZTjuJB0A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jouve: {
    name: 'Six Red Marbles India',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Archana',
        lastName: 'Natarajan',
        emails: ['anatarajan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mohit',
        lastName: 'Katoch',
        emails: ['mkatoch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEk1jo9mam3GQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664801149105?e=2147483647&v=beta&t=2Y7bwOFsbOXD7jsXUwyTDcMsE9RKA9VkG3MmW7_IKLI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vijay',
        lastName: 'Pundir',
        emails: ['vpundir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHzrxEoBNHJIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720500292692?e=2147483647&v=beta&t=dp1MOZ-DzHbKDatCJ6VIokIE-2SfmTqFCCNuRdCUwoM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Danish',
        lastName: 'Zaidi',
        emails: ['dzaidi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFePhQ8oN1UmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563694010695?e=2147483647&v=beta&t=vqniKcqLhXoSLGmUj0FcMrQoY6MW_WB4LXlgSIo1lRc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Narendran',
        lastName: 'Rajkumar',
        emails: ['nrajkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFavLWKPlVUXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605705763352?e=2147483647&v=beta&t=UQRUhJhmfFaqcL_2M3KRVtmh6wOlJvSrQqKwtFINb2s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  enersis: {
    name: 'enersis',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Andreas Jaus – Project Portfolio Manager – Energie Wasser Bern',
        lastName: '...',
        emails: ['a...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHhT0mSEMhh7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516607552692?e=2147483647&v=beta&t=JCJo3d1Q-UQ0EWWly2D-m1NuHKPwh_Be0_Xvq7-pkqY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mansur Esmann – enersis |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Nils Leidel – Product Manager & Data Scientist – enersis suisse AG',
        lastName: '...',
        emails: ['n...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFWGnZO72JUpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517228296608?e=2147483647&v=beta&t=pLOpWh4ZtVQ14xHWAAphUHKLff5HC3PfZzczuhoKXEM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Metin Zerman – EquityPitcher Ventures |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHV-T9Qo0HTRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646000686362?e=2147483647&v=beta&t=QJVOPvx4vSg0d2aVGfJcSrMfka5CAgaVy425j5Kah60',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christian Thomann – Senior Data Engineer – enersis |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGhpKhFfW5_Gg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729664570591?e=2147483647&v=beta&t=_90DxuuZ7vG7KDgiTwdoLAZUlVq9sKOZbQThXiWlJL4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gravityer: {
    name: 'Gravity Engineering Services Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kumar',
        lastName: 'Gaurav',
        emails: ['kgaurav@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHyjgz1F4pL7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724994175203?e=2147483647&v=beta&t=Cszmj4msSrkfDwrHpna8L7_aKXgnFOFYR9-KRwagehE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sandeep',
        lastName: 'Kumar',
        emails: ['skumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFR2ml0nI8ZTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724996701884?e=2147483647&v=beta&t=SiIqBjDqvrHo3JfLT2Oict7pT14JOZINJgnl2SHQ93Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mounica',
        lastName: 'M',
        emails: ['mm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFWfM8RSLZsWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549876093308?e=2147483647&v=beta&t=dwlZ1M-3y4bS54hL7aapZAfsPeX9wGOyWXcSYrKg6FM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nilay',
        lastName: 'Singh',
        emails: ['nsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFzZf27Sc_xoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648959338596?e=2147483647&v=beta&t=GMS3XlcOpk7dvpnx9JG7IxzoM834nO-kAwjfXXGt-Ds',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'SUMANTH',
        lastName: 'M',
        emails: ['sm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGtGkUwthqZWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706171147525?e=2147483647&v=beta&t=2Q48VxaNmuZIjdJXZAUAUNLXdeg3d1EJjCAqy0vhbIk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kiswe: {
    name: 'Kiswe',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jimmy',
        lastName: 'Lynn',
        emails: ['jlynn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGghCi_Yy_a-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666886454815?e=2147483647&v=beta&t=1tW0YhF6O7oipBCdfO8mkNcBTKtLWnOeGtPl1ABe5PE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Glenn',
        lastName: 'Booth',
        emails: ['gbooth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF1eEDvxf6K6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685730191715?e=2147483647&v=beta&t=KhZAr9oZ8LiVcS5b8U-9neVqrATLfBSjXkVGgb2zSJg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mike',
        lastName: 'Schabel',
        emails: ['mschabel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFDJ2ctmrhFAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516287815818?e=2147483647&v=beta&t=2zCQiWBtNAr5DP9wSeP7wIAgSxA-Ufu9vrMayV7DXuE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Wim',
        lastName: 'Sweldens',
        emails: ['wsweldens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFi8J2Eo4B5Kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710253434236?e=2147483647&v=beta&t=aFMK1kHVIVowk4vjoUHNfTliQbN00jAWbcc3PshjGxo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Corey',
        lastName: 'Smith',
        emails: ['csmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEJ9udzSJvj1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639232515439?e=2147483647&v=beta&t=jh0CYCXG755dQr_MwX3K3FOn-LZFZhaccOLdb9gLZKQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mukellef: {
    name: 'Mükellef',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  viriminfotech: {
    name: 'Virim Infotech - A CMMI Level 3 Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shilpa',
        lastName: 'Anand',
        emails: ['sanand@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mayank',
        lastName: 'Pandey',
        emails: ['mpandey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vishal',
        lastName: 'Pandya',
        emails: ['vpandya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFPnOPbw34bZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681833475203?e=2147483647&v=beta&t=GOXbLliwGZ84BmI8Hve4QEsQnRiHc0aTmvN8H98pw3U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Neha',
        lastName: 'Pandey',
        emails: ['npandey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGVAUcoRSonsg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727312544469?e=2147483647&v=beta&t=Bqq_5Ni9T5QV7XHtCoEF9VHkyTUQ9L8TZ3wVk75Po_4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Suhani',
        lastName: 'Surana',
        emails: ['ssurana@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bicsi: {
    name: 'BICSI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Daniels',
        emails: ['jdaniels@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lucian',
        lastName: 'Bicsi',
        emails: ['lbicsi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHwb1Pm1XSaMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655408710736?e=2147483647&v=beta&t=ZjmprPpXqCXSnB_For7JycFU1uacy3ITt-sMAk3nO58',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christopher Hobbs RCDD, RTPM, DCDC,',
        lastName: 'OSP',
        emails: ['cosp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHhw5dJawlLZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516809797350?e=2147483647&v=beta&t=x_RunPC0CbynffZ20lm7ysWaOTeQZLGpYaGgUMQK4mM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Muratore',
        emails: ['cmuratore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGjIkrf_AyeTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602013203899?e=2147483647&v=beta&t=eqHUc6e9qpDGlrLRo6brAtV2W8uURmJvaDZEmiG8-88',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Melissa',
        lastName: 'Cummiskey',
        emails: ['mcummiskey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  measureone: {
    name: 'MeasureOne',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Elan',
        lastName: 'Amir',
        emails: ['eamir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE0FEY-IVcvoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521737331717?e=2147483647&v=beta&t=L0PsHB292Hj7j9vD-oE2rE-jsTbrp1Vo9C3NEfLEPh0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Yaron',
        lastName: 'Oren',
        emails: ['yoren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF9TYZmD3ml3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570543146121?e=2147483647&v=beta&t=8NdTrVC-VOTMkGnIRiGkKvJhRCMUJCWMvSXKbZDoGZo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matt',
        lastName: 'Donovan',
        emails: ['mdonovan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFTxFcr9U5qvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517654342043?e=2147483647&v=beta&t=HBsMogj3J2b_6YVMXn3SLq-YJk_KrmodVf7Dx30VHfk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kristin',
        lastName: 'Allton',
        emails: ['kallton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Khushboo',
        lastName: 'Bhavsar',
        emails: ['kbhavsar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  medefy: {
    name: 'Medefy Health',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'Scovil',
        emails: ['mscovil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFK-WDtEbLPsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702075667653?e=2147483647&v=beta&t=UJEEHPQzPexpljAhLz9ON9c5yA24FkufdgcguyJqcIU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Amanda',
        lastName: 'V.',
        emails: ['av.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF09ALo5gLh_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689623509880?e=2147483647&v=beta&t=s3Xe_AcgxcDHIcsLDiUXR5FeQgQZEbtiMXfL3RVqskE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachel',
        lastName: 'Ament',
        emails: ['rament@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQETqo2APD31Tg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674748849715?e=2147483647&v=beta&t=QKvdSM2q5zUYejAV_LBS73KgpKUClV1AFiu7qd_0iLA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jim',
        lastName: 'Barsness',
        emails: ['jbarsness@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF_eFCIhJ4mqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517689092172?e=2147483647&v=beta&t=bwfndlEhMmJFaRHDOctGR0WcP6St_DsiK1VhEITcPlU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anaye',
        lastName: 'Milligan',
        emails: ['amilligan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG5xJfiUjf1NA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614788133880?e=2147483647&v=beta&t=bmY0aLZBf9V806JQR_eMXyZ7Pnfnv6Ux59Rgkwne878',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  us: {
    name: 'Square Enix America',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Capri',
        lastName: 'Johnson',
        emails: ['cjohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'J.',
        lastName: 'Kartje',
        emails: ['jkartje@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE3WJmNFpIqzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629837124246?e=2147483647&v=beta&t=TAb-0P-E1kUrkOOw-vBvGQhnAF2AlkGfTGtaFGGUJMQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chad',
        lastName: 'Concelmo',
        emails: ['cconcelmo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF3i8_jmKfbcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679263671186?e=2147483647&v=beta&t=cah0t_2O5oKBCElSeg-10rX59JSYSzLvnbJqJyk65KQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Lee',
        emails: ['dlee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFc7PTdj00ATw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541204290682?e=2147483647&v=beta&t=QC1XysIrnlT6iPZ0fIUXT4Ub_rn9xLnLSft_NGNMeGs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eric',
        lastName: 'Inamine',
        emails: ['einamine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFq76FsN43uzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673289618373?e=2147483647&v=beta&t=TqIkeVyi5MtAXXk64MDnM4c-XR4vo4zJK_dcxa1gdhs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dcubeai: {
    name: 'DCUBE Ai',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Saranya',
        lastName: 'Nair',
        emails: ['snair@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Babna',
        lastName: 'Rose',
        emails: ['brose@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG6FAQlIyra4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697296510694?e=2147483647&v=beta&t=n9IVj5SA2KTBboKjYEBay9n5RCUYJyrCa05W35w9qWo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anjana',
        lastName: 'Thomas',
        emails: ['athomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEgEOIiCCrwYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713600466824?e=2147483647&v=beta&t=dwo7fQMXXQeJNDz4noZ_xmHDVUaPAMOX4DguTWs9JYM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Veena',
        lastName: 'G',
        emails: ['vg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGpcX9c076nnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683709394621?e=2147483647&v=beta&t=tJF4uRVKna1suz1ieshAAXk9KoyQRyOrLF3PLaSJX4k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Arun',
        lastName: 'Sankar',
        emails: ['asankar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFJw-4wZxSDjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573138716973?e=2147483647&v=beta&t=IGnFrziOtyltbgtrqQI1Bd6019_-5ksusSerGRQTjCM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  apvit: {
    name: 'A P Ventures, LLC (APV)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nandita Desai',
        lastName: 'Gududuri',
        emails: ['ngududuri@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Theresa',
        lastName: 'Boyle',
        emails: ['tboyle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGcbo0sHj3ACA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721235625060?e=2147483647&v=beta&t=_-OndRwwICilWqM_JMuNnWhyXlyDLW3iFdKU45brQa4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marcie',
        lastName: 'Bombelyn',
        emails: ['mbombelyn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Manuel',
        lastName: 'Miranda',
        emails: ['mmiranda@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christyna Copeland',
        lastName: 'Bobrick',
        emails: ['cbobrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHbq9QgV2i65w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516349367849?e=2147483647&v=beta&t=N296re679URWRivBKS8TA5diG4LE3cCIpLTGExbIydk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  conceal: {
    name: 'Conceal',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gordon',
        lastName: 'Lawson',
        emails: ['glawson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Barbara',
        lastName: 'Hunt',
        emails: ['bhunt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE-e4AVvgy6uQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564237789456?e=2147483647&v=beta&t=pN9KWD8zHf78OrM3ZQ1uw0DxERPQ0dQsanmqGncsf90',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jason',
        lastName: 'Shiffer',
        emails: ['jshiffer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE6RNqnpNtMvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614884028306?e=2147483647&v=beta&t=6dagtFMGTsmgYVhgPyABI1yopsNCkQKpeuUKdi2-_yk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Fullerton',
        emails: ['mfullerton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG8YNRKhlKRRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517586893690?e=2147483647&v=beta&t=ID9ZMQRJQnNwjinZjuA_T1oD5lkiFrkhlhpw2Rl7N-k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John Kellum,',
        lastName: 'MBA',
        emails: ['jmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHsVkMMcEG07Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708461626393?e=2147483647&v=beta&t=0OZiasqSRvdcYocIYvrLnfmx7MhfCKq1Z7usWb9rfVw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  torder: {
    name: "티오더 (t'order)",
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jason',
        lastName: 'Walker',
        emails: ['jwalker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGFG5x5gAX8fA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694727578296?e=2147483647&v=beta&t=oMOltH2eu4chuKCpSKZYre8MqGr0UvmLIoNCE1aD38k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Wade',
        lastName: 'Lee',
        emails: ['wlee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEhj5ulDYpefg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702262873829?e=2147483647&v=beta&t=ZQGjorhTQsAXprrOOobhWCudEF5owX8c4BTtZHOYsPA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Austin',
        lastName: 'Kwon님',
        emails: ['akwon님@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kyle',
        lastName: 'Jeon',
        emails: ['kjeon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Austin',
        lastName: 'Kwon님',
        emails: ['akwon님@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGuCYoO8h_d9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712708594310?e=2147483647&v=beta&t=YHpNudZ_qct4cBJ_Mj70Paewgux9oKy1T4fFZuyW9hY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dsitech: {
    name: 'DSI Tech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Laura',
        lastName: 'TARDELLA',
        emails: ['ltardella@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF87-7I0OEdwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686158807211?e=2147483647&v=beta&t=eRQ7ZG5tlM7-dFe3MaOGDbzD9I2kXEQytQs9tEay-rY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Firas Albouchi, PSM',
        lastName: '®',
        emails: ['f®@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHLDkg3G5tLxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555773172829?e=2147483647&v=beta&t=zshP51Hh7_gMpe_gjxfUM6RtXWSesgLA9kYVcrkNVfw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bassem',
        lastName: 'MAHMOUD',
        emails: ['bmahmoud@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEco68Fv4iOow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630227387591?e=2147483647&v=beta&t=9qH4bRnxjkZ8y5Gdm3BoU1a1OTKOcZOJyNftxZimh94',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sandrine (GURY)',
        lastName: 'PILLA',
        emails: ['spilla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFlVQZ1J9QyzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609708745221?e=2147483647&v=beta&t=gg-7lqBntNR7OJjYSgr-7dlZUtItBk9uBFbErNNbZrc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alexandre',
        lastName: 'Hilaire',
        emails: ['ahilaire@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH6bwej6vfZfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682349442531?e=2147483647&v=beta&t=vAUfAUSyXHFsUcutv6Yq-S62eVwSj8YKlCW4jFqrwe0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  knowland: {
    name: 'Knowland, a Cendyn Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'Bzdawka',
        emails: ['jbzdawka@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lauren',
        lastName: 'Wilson',
        emails: ['lwilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFDkLaJU-MxVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516578154283?e=2147483647&v=beta&t=3IeI1MJwC5GuWwkrU47qeDywGrwuk-OTBM2Y-Ow4si4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brandy',
        lastName: 'Gates',
        emails: ['bgates@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maura',
        lastName: 'Murray',
        emails: ['mmurray@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mary',
        lastName: 'Krenkel',
        emails: ['mkrenkel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFpKJ1UOQiRWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516332631324?e=2147483647&v=beta&t=jLki3i_xm3Bdnm4chfeloke4xaeMGpNK8wHfGe2CGS8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nens: {
    name: 'New England Network Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Josh',
        lastName: 'Oakes',
        emails: ['joakes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHiwIZ2XDEBuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687019941991?e=2147483647&v=beta&t=zj8klax-uAQlijJQKk4vYxklUmqNhwW4n5_X7o-68dc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dan',
        lastName: 'Adams',
        emails: ['dadams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF8uml2D-c3Aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687967080218?e=2147483647&v=beta&t=cxGePS6OwPbzZ_pg3RjbfEoGO_3iidI1jowAgS9BePg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Kourkoulakos',
        emails: ['mkourkoulakos@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brianna',
        lastName: 'Nil',
        emails: ['bnil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFpAzr12sazng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722993587520?e=2147483647&v=beta&t=wCm5oxRsilOvownu3J_s90pmO-x95wD8lrtMMZzaRYg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aaron',
        lastName: 'Norton',
        emails: ['anorton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGM4LSvWjWA5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618940744373?e=2147483647&v=beta&t=nGuCnxSwsjHG2yLA-7B276Nfd8VkUoZxYzJSEbt00Uo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cksource: {
    name: 'CKEditor',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'CK Lin,',
        lastName: 'PhD',
        emails: ['cphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGFj8cqFxEsxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604724925331?e=2147483647&v=beta&t=Y39wKpzwZebbOb2DYBPJklnwi3TsP3NaIlR88-JmAF8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Charles',
        lastName: 'Gantt',
        emails: ['cgantt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH2oqu5qJCTog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724945387973?e=2147483647&v=beta&t=PPIF0pe30B3LlPTXcrYCvXOXVlUXTuXG6voFY3UlE1w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'CK',
        lastName: 'Cheruvettolil',
        emails: ['ccheruvettolil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFzb41u9Ym4AA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544805242480?e=2147483647&v=beta&t=fAm7NB--hCqRb_KgRTXrcxMJ3dteqZFkgmbRqhWyFXE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'C.K.',
        lastName: 'Steefel',
        emails: ['csteefel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'CK',
        lastName: 'Zheng',
        emails: ['czheng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGtkFL5Lakwaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668185902283?e=2147483647&v=beta&t=4aovhCX-7i61E5xzzU79CqQVrxFC8DHcvhbAcZymRjo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  brightsign: {
    name: 'BrightSign LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Greg',
        lastName: 'Herlein',
        emails: ['gherlein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEC54n_Z8dwbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685887649214?e=2147483647&v=beta&t=f3MGao_5VGkUU-ocIKMcLRzLiNFxY9gS5xd2BqJVJYg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aaron',
        lastName: 'Rollins',
        emails: ['arollins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Karen',
        lastName: 'Fore',
        emails: ['kfore@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brian S.',
        lastName: 'Rowley',
        emails: ['browley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH3mp_6UqWFnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634249042053?e=2147483647&v=beta&t=vWSbjkwLP9DFM3bXnPE3JWqqgfUr3WCxu4bZ_xmnJeY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Danielle',
        lastName: 'Wargel',
        emails: ['dwargel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF_G6IHCouApA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598971454717?e=2147483647&v=beta&t=Y9f3l57btlOzT-m9iE0UG9C14OQAGIHoBozVhHYQ1N4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zomentum: {
    name: 'Zomentum',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shruti',
        lastName: 'Ghatge',
        emails: ['sghatge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEdbUf7SKMcrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629991396188?e=2147483647&v=beta&t=39yle8RYKEunZVyQYztCE-1BnUtKThM-I8Sz-Pe0sEc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Keerthi',
        lastName: 'Vasan',
        emails: ['kvasan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGZKDJu7SZCHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595238128977?e=2147483647&v=beta&t=X9b7uyF4lQOyHh2dwLwNBKf6FR5yH2OLEeO5dIhCy2E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rahil',
        lastName: 'Shah',
        emails: ['rshah@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ben',
        lastName: 'Spector',
        emails: ['bspector@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQECpyak9zwlLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606946397374?e=2147483647&v=beta&t=h2FGCucmbrvJo3LVX6ygX2RVdyOU0gwZQHxbmgN3kMo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Swadhin',
        lastName: 'Kumar',
        emails: ['skumar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mbaoutcome: {
    name: 'Millsapps, Ballinger & Associates (MB&A)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alex',
        lastName: 'Morrison',
        emails: ['amorrison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGWb4nxbjg_TQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548168602868?e=2147483647&v=beta&t=w3Mtkd5SJ3A_-RXsAudfdLf2_bh1OnnIY2CXvGeb_Sk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Misty',
        lastName: 'Rowland',
        emails: ['mrowland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFrQDdaisn7fA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602690591309?e=2147483647&v=beta&t=sQ5Cpo_xvHtruxLNISfxS14JRszDoAvm5015OFxje3g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jestin',
        lastName: 'Stephen',
        emails: ['jstephen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGCMqbVA1VB6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710780088961?e=2147483647&v=beta&t=jei2S-ZlR2Qm0nzriDjhaPMsf2jKDpA7lf51r0awPKs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark M.',
        lastName: 'Korf',
        emails: ['mkorf@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Maria',
        lastName: 'Zapata',
        emails: ['mzapata@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEPBTZHh0H_JA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1715370330669?e=2147483647&v=beta&t=rZ4ckV-_9W5_aXIlLlGtxMxQb1ztz6IacLIKp0hnR-c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  blackcape: {
    name: 'Black Cape',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jordan',
        lastName: 'Barlow',
        emails: ['jbarlow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEWCtRHoxYILA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516351931211?e=2147483647&v=beta&t=luxJaokkh7K1R_7Hb_LaBOsL8PstgSgnXDPUea_nq7g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abe',
        lastName: 'Usher',
        emails: ['ausher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG_05K0tlEiIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652102883168?e=2147483647&v=beta&t=ZnhWTG3r880ikOaFHiP7bsewgjYDIkfpXdEvjFuVBFY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephen',
        lastName: 'Clark',
        emails: ['sclark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFtumYW0m4-Tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728921261217?e=2147483647&v=beta&t=N-mFqW0pxtOVTxAZ5sY1039ZBJbfpcl9Mjg_cvJ5Ld0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Charlie',
        lastName: 'Dobson',
        emails: ['cdobson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGjNDaFjUTwvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628367836660?e=2147483647&v=beta&t=HFzSg8wKYVAC8A0-7ZMtquK9EDWaFHtfr_FeTwpO8jc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cathy',
        lastName: 'Le',
        emails: ['cle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFWaDXhBxXfUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568035981123?e=2147483647&v=beta&t=K5ZqXiwRmJdlhCg1U-DFwFFAUFfa6_wq7JFrIr1dzHo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sigmainfo: {
    name: 'Sigma Information Group, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jason',
        lastName: 'Sweet',
        emails: ['jsweet@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Parrish',
        lastName: 'Miller',
        emails: ['pmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE8sibnot-iog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714517621539?e=2147483647&v=beta&t=6mfMrBOXfED1fuB2sxOCWO5dGbV7LJUdVE78oeKmjkg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ray',
        lastName: 'Mitchell',
        emails: ['rmitchell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGPbeozw4e6Ew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729007052837?e=2147483647&v=beta&t=UVHAiJVqB_oqOUlcxLr0aoKtg5sZcVsWWWIWxj-QRVU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rex',
        lastName: 'Raymond',
        emails: ['rraymond@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHjJuS2JPvLTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1566083650919?e=2147483647&v=beta&t=lEg03NIQNai9kexwg3BQmwyLW1js8nicxB2K3G5WeEk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ava',
        lastName: 'Woodfin',
        emails: ['awoodfin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHodcCiZP-E2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677887015445?e=2147483647&v=beta&t=S6h9IqT3gCqioVsOTGE_90CQRFb5hPC0yrwtvZnF7AQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  canvs: {
    name: 'Canvs AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Janelle',
        lastName: 'Higbee',
        emails: ['jhigbee@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Beant',
        lastName: 'Singh',
        emails: ['bsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEV_hWagSFC-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638567117120?e=2147483647&v=beta&t=VNMKrme8Kd3aXYdj3HOYSb1WVC5cC4_icsionSC8k7M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ashish',
        lastName: 'Yadav',
        emails: ['ayadav@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHYVjkFBa5cPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690092287225?e=2147483647&v=beta&t=V5KMO_jSwk69Xg-kSLspXcfZf9VRug_jFsnj5HY0pm4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Senthil Ramaswamy',
        lastName: 'S',
        emails: ['ss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQE2IRlul0PUZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571574458826?e=2147483647&v=beta&t=NdRZRGr_ZlQ3W0cE-72DDG3wpg5lJJJx2aE2v_-Gvho',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jayant',
        lastName: 'Prabhu',
        emails: ['jprabhu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  coalescence: {
    name: 'Coalescence Cloud',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Flo',
        lastName: 'Boymond',
        emails: ['fboymond@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lincoln',
        lastName: 'Heacock',
        emails: ['lheacock@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEIU2OQKQUxkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516177405212?e=2147483647&v=beta&t=txvoOmVTJaQnFOLrXeYPR72j_Tkh4Tg1Gp6POomwk4E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Wnek',
        emails: ['mwnek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFz0sEkJcrrbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715032815019?e=2147483647&v=beta&t=9CWw6GX29exkI-fjiPsG80GMp_9bG0yNdeVBWoLq4Nw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paul',
        lastName: 'Wnek',
        emails: ['pwnek@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lauren',
        lastName: 'Balsley',
        emails: ['lbalsley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGJcxz7JmGaHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635399925581?e=2147483647&v=beta&t=FUOhSFffG4EBGUv1NzXs_tygg-5JZXgq70rZ3Ymr-iM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jcssolutions: {
    name: 'JCS Solutions LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lisa',
        lastName: 'Price',
        emails: ['lprice@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFbkOy9Hfynuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592771405532?e=2147483647&v=beta&t=VD4xmU5exN0TZ84X2gg14-dQHPk7Aa-tjVw4YXOihCg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Douglas',
        lastName: 'Walker',
        emails: ['dwalker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFiV9lFvtSTag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685278996263?e=2147483647&v=beta&t=AfChHyzZqaWgDLwDSlI9G7FjVuSGIR2EKSaOkgdnO3g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carter',
        lastName: 'Raines',
        emails: ['craines@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGzYePD0BMx8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516279394078?e=2147483647&v=beta&t=I4bF8-eFb_465g8xKgEcsyP4t6ctsNJUfJZEIyIk6Ks',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nathanael',
        lastName: 'Woodhead',
        emails: ['nwoodhead@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHr_wy81zb3Ew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1525875468677?e=2147483647&v=beta&t=YwYjMZvWEDCwDg9gsjqeC3fTjVrBKlokWEBo_XumMyk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Winnie',
        lastName: 'Leeks',
        emails: ['wleeks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHJnJk9cRNQ4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671487959516?e=2147483647&v=beta&t=fARFx8L5m_9o9TZRCmz7cbN0x4O3sjDU-iGPK7K4fLI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jmark: {
    name: 'JMARK - IT Support and IT Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andy',
        lastName: 'Whaley',
        emails: ['awhaley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGTkptQHhviNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517697180158?e=2147483647&v=beta&t=zy0lN_7Af84GIt_FHW1CrUQfww5A5j0aAQM6FtMUwX0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Thomas',
        lastName: 'Douglas',
        emails: ['tdouglas@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Robert',
        lastName: 'Farmer',
        emails: ['rfarmer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHdIrknZU5B1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638109363883?e=2147483647&v=beta&t=dLQDRCglEgAQxqV3pFuaB4H1IUWAHffdXhU_WrfvD88',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Todd',
        lastName: 'Nielsen',
        emails: ['tnielsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEf6_K9teBddA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516284689337?e=2147483647&v=beta&t=VHZfqX9PiNq0TBZpoxUvZmKamkXLyq7bQld-jhOgXRI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Huels',
        emails: ['chuels@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHguVCMYr5Wdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609821274355?e=2147483647&v=beta&t=yzVdMrqIvQ7R1vBtHVRTRxA95_n85oItRmVYP6r6yc0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dmdconnects: {
    name: 'DMD, an IQVIA business',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aaron',
        lastName: 'Gale',
        emails: ['agale@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Olivia',
        lastName: 'Bargelletti',
        emails: ['obargelletti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEESRPaA57zGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666711849559?e=2147483647&v=beta&t=IzsQ2Fb3eRYdkFrPz1QZPhF3JD5EQeThqHB3jZIAcCI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tayo Odusanwo BDS, DMD, MS,',
        lastName: 'MS',
        emails: ['tms@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bridget',
        lastName: 'Westley',
        emails: ['bwestley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFkyFeSNyQpjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1566595440993?e=2147483647&v=beta&t=qeGm8NQcUSFysMd_0eMuSKlt1ENgzgrJUrdgjJGkSio',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bonnie',
        lastName: 'Feldman',
        emails: ['bfeldman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEk-wbUuOwDOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705514725715?e=2147483647&v=beta&t=9vTi0qjvTxWA8V5NDYdGUsBX6TjxLgB9Zk9sAbeNalk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  evolversgroup: {
    name: 'The Evolvers Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Clint',
        lastName: 'Bailey',
        emails: ['cbailey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH7IzpEJPzgrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598900352794?e=2147483647&v=beta&t=de8vlTDvFcv_pHYdEpzXOwHKOYgPRUrfEGULH_cMEfI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rob',
        lastName: 'Bookheim',
        emails: ['rbookheim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michelle',
        lastName: 'Love',
        emails: ['mlove@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'KC',
        lastName: 'Walsh',
        emails: ['kwalsh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHFmu1nuAFZXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670872941487?e=2147483647&v=beta&t=A9zqJiJIQNIk_xbgDggV-JDpPrg85WEHabaUhvnjXZI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Teena',
        lastName: 'Rawat',
        emails: ['trawat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGYjg9IXyNMzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649948481860?e=2147483647&v=beta&t=YHQWc3yFb8DF4X7Hc71CTPdO44RmlJtRnizWv5yXo_o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kizan: {
    name: 'KiZAN Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Steele',
        emails: ['rsteele@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG3KHyMKjMrJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621868649142?e=2147483647&v=beta&t=TkUuHjbHWBWPLaluBIHl-YBAebm5aaePSDQ-W_68lig',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carlo',
        lastName: 'Baldini',
        emails: ['cbaldini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFffmBqw1QX_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516780200493?e=2147483647&v=beta&t=rm4ZIroYKV0i6xxYckpSIwvoDBAJ3knZ0Eee3ZDJSms',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alyssa',
        lastName: 'Coffey',
        emails: ['acoffey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFiD994pNsoDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638219018922?e=2147483647&v=beta&t=KWryN6fx-rYFlqxXVKOL_qXI6p_MyZFtPCv7rpjIY6o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bryan',
        lastName: 'Cornette',
        emails: ['bcornette@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jackie',
        lastName: 'Roberts',
        emails: ['jroberts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFT9-Bpso_PIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567529035479?e=2147483647&v=beta&t=fFUkV19lL3qzUsD2hRIamScLJg009nxUTUPKytJzeeM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  echoworx: {
    name: 'Echoworx',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rosario',
        lastName: 'Perri',
        emails: ['rperri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGxtmnkbEiWJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699032089521?e=2147483647&v=beta&t=fCrIveohSoCVpRCZ4I3jng0u7pbO3LbjclJH9cQWuXs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lorena',
        lastName: 'Magee',
        emails: ['lmagee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE8-aoDt3t3gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710964201531?e=2147483647&v=beta&t=BXijg3EoyJb-FVqtLam2e1bq3aTc3jH-MW1q7BehthY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Peel',
        emails: ['cpeel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Ginsberg',
        emails: ['mginsberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sarah',
        lastName: 'Happé',
        emails: ['shappé@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF93vQfrStPHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516356046937?e=2147483647&v=beta&t=HdSv8ckCrpmLe-_O5lpPtbYRZdYIaM3CdrmhyUaGMhc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  antechsystems: {
    name: 'Antech Systems, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Britt',
        lastName: 'Kirmes',
        emails: ['bkirmes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHDbh99jozijA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695395841592?e=2147483647&v=beta&t=X_654FQ7kLsUM0SE6MUxhgEBX_ryPLdltlRZQTTnuCQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vince',
        lastName: 'Martinez',
        emails: ['vmartinez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFqPM1i8S1geQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586128499507?e=2147483647&v=beta&t=ydNQreFkJjbG0ICF2R8XeqS5Jh2AYMkOn3-r6WuxSUk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeff',
        lastName: 'Self',
        emails: ['jself@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHa7B40gyC6hg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516327182796?e=2147483647&v=beta&t=XCruiayCbA4sTJTa2nXIPJH5-A22vW4HGWVsXdLuTbk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Melissa',
        lastName: 'Self',
        emails: ['mself@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGQ0km_FSLtaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517730598405?e=2147483647&v=beta&t=fFsmLJTm8Ms9IRDCQgFxdcVEDUO5fgs9SKayZHoXf_0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mike',
        lastName: 'Anderson',
        emails: ['manderson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  usherwood: {
    name: 'Usherwood Office Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lindsay',
        lastName: 'Usherwood',
        emails: ['lusherwood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF2vBBsJ91_TQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634915255233?e=2147483647&v=beta&t=che0kxx67dtsjFeDmODMpW_jINvIfbtLfEtjzEom_Jw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Erin',
        lastName: 'Williams',
        emails: ['ewilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEJzaXRhMQTCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516626014999?e=2147483647&v=beta&t=USK9VXTbHgfmCnAaVmpTUquME55Hch9iC1Q65eRbcow',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Titus',
        emails: ['stitus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFXfWbRsCpj_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671219504664?e=2147483647&v=beta&t=kzkfOJr0cDW_Fb21LAZDChXeOxRVwhBAG0fTwm7tJ_E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nathan',
        lastName: 'Hock',
        emails: ['nhock@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHRMAOOFx1Q_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584481380848?e=2147483647&v=beta&t=dM8Hp39SrAYrpbDwz6Aq_aRjmSKT00OIu3QqjDgPEYo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'Flamik',
        emails: ['aflamik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGZZDiwAgmyTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516433710361?e=2147483647&v=beta&t=7rWtGCti6PoqVE2EPZEsVKwFjMDqlOBPzIzy5un766A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sagese: {
    name: 'Sage Sustainable Electronics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Houghton',
        emails: ['rhoughton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEWFRD9DLO8Ig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682968081688?e=2147483647&v=beta&t=KcYqGAuW1nq5rvFIrkuJhTndzZVwIrvmOBLLvSZMw1M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jill',
        lastName: 'Vaské',
        emails: ['jvaské@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFQpNaN4ndVkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635306597704?e=2147483647&v=beta&t=vBB-nyUhUYIsx0I8lSmtUZW_PIrwd2EsfXeuu7A_GVE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patrick',
        lastName: 'Kara',
        emails: ['pkara@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'William',
        lastName: 'Moler',
        emails: ['wmoler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH1M61C706JPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720540159302?e=2147483647&v=beta&t=HSS358WD5LG_r2Na_4QnBw1bMb75bUJTCQWbmcEpM6Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'James',
        lastName: 'Morrow',
        emails: ['jmorrow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGU3LYYvL3FXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649173853146?e=2147483647&v=beta&t=T93UYLajS44rfjpxJ-qvCtNs85DwXmYRS5pYuFSG0XY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ecoreservice: {
    name: 'eCore Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tamar',
        lastName: 'Gill',
        emails: ['tgill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEPT4v5cmuDtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617339116956?e=2147483647&v=beta&t=o4zU9NHUDFJJjxeu-_kDEihc_uUeIqjuzqoqowMH4rE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joseph Gill, MBA, CSM,',
        lastName: 'A',
        emails: ['ja@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHLQBoT9UxHzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517439901760?e=2147483647&v=beta&t=hDt7fUw4ISiIYFr93LqhkB-s5I2YW0quxVv9ByFArww',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dejan',
        lastName: 'N.',
        emails: ['dn.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFfsD8KZ_rIkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657479674477?e=2147483647&v=beta&t=riH-OSHW2CBDL-tG_BW4ugRjcEPeV8dv4MnyRHFcEXc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Milos',
        lastName: 'Antic',
        emails: ['mantic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEJ_TwaV-M2Zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517468415883?e=2147483647&v=beta&t=Xx85XvD8OTMdjz2QQvwG0RNKAuSjjX3xSndMAXarO9s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kamau wa',
        lastName: 'Gitau',
        emails: ['kgitau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH7FPN5IIyJ5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658420572909?e=2147483647&v=beta&t=phONEIaZF836MsHSx6vYabnaiUEHy92oLLj6pdWdKXY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tegeria: {
    name: 'Tegeria',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'McDonald',
        emails: ['jmcdonald@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aurela',
        lastName: 'Agalliu',
        emails: ['aagalliu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joan',
        lastName: 'Shameti',
        emails: ['jshameti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHtBR2GiMIQbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719024416961?e=2147483647&v=beta&t=PQOaB-nIercXVXkF_joS9FlKT5hBOUjTCoHcVMSNegY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ana',
        lastName: 'Limani',
        emails: ['alimani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHt1_Dj5E6Gqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670007903913?e=2147483647&v=beta&t=bg54m5oy_Kl04lah33SAXmjyFf4M3yOSZeGzdWxZqoY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Renata',
        lastName: 'Kadiu',
        emails: ['rkadiu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF8qeFG8bTEkw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602443903581?e=2147483647&v=beta&t=ayFloQWL-0ZHZE9aWGVzg4XBltwzHaT7VnkqTzJD8wU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  infinitecfo: {
    name: 'InfiniteCFO',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Simon',
        lastName: 'Smith',
        emails: ['ssmith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexander',
        lastName: 'Harrison',
        emails: ['aharrison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHY8tkNnKJ2jA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684264398234?e=2147483647&v=beta&t=c6U_t72jCMjUkRd-lYpNqi7VYWOgQEVRGqfbP62FZxQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Leese',
        emails: ['jleese@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHAp6J2aSEzBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687192528499?e=2147483647&v=beta&t=FdPYOWeb5NGm87ONRD35PwWvXd_Q8V3KvKYTzTvGBgs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tim',
        lastName: 'Cadbury',
        emails: ['tcadbury@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Katharine Challen',
        lastName: 'ACCA',
        emails: ['kacca@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  circlecvi: {
    name: 'Circle Cardiovascular Imaging',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tim Albert, MD, MHCM,',
        lastName: 'FACC',
        emails: ['tfacc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFcLYd7nSH0Xg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727060524917?e=2147483647&v=beta&t=5BDxS31oW78wuNQ8-D0YSZLtw0lzAEmtZoFid6lQo2Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Greg',
        lastName: 'Ogrodnick',
        emails: ['gogrodnick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGCuWLERrGIOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544016321467?e=2147483647&v=beta&t=qk2yYx2scVxeP-yWGNTw46FhGeQjqp5X9IVDJgw-1DU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Randy L',
        lastName: 'Jordan',
        emails: ['rjordan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG5YnjJBzjIJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517688108496?e=2147483647&v=beta&t=UzBdaNAQXYYdFjDdUfwjLBkFF1GuHtnv56bN9tYuz10',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Little',
        emails: ['mlittle@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Radin Adi',
        lastName: 'Nasirudin',
        emails: ['rnasirudin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trek10: {
    name: 'Trek10, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tony',
        lastName: 'Reyes',
        emails: ['treyes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQED4ze6N55D0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668725710727?e=2147483647&v=beta&t=03qSMSTf8OECfO_i7arHWctxjbKe887k2uY7Ed-he2o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aaron',
        lastName: 'Caito',
        emails: ['acaito@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGiV9So3lsg3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672845922213?e=2147483647&v=beta&t=CUpkKi--QH5cnbCm7cmumCuXli0Bu7Zdarp3We0vhns',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'Bowyer',
        emails: ['jbowyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEaRfsUpBYF4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649089831427?e=2147483647&v=beta&t=oJ5K7zL0sIIrF08WDmx7DNYkEo8452CcumXqRh7XojY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Scott',
        lastName: 'Sawaya',
        emails: ['ssawaya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE2tQBF12kDNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647827309148?e=2147483647&v=beta&t=6R4Wzuj94YTvc4kw39EJg-wBlvKt2T8yzEC3nHaCras',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mike',
        lastName: 'Norris',
        emails: ['mnorris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHX44zV5cOI0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595515647258?e=2147483647&v=beta&t=ssiGhjQzfs6UCJ3lSjZ9dKwGAnRGKiM0Wmjlna6I3KQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  encoretg: {
    name: 'Encore Technology Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Russell',
        lastName: 'Young',
        emails: ['ryoung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEeBICfAgLKAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517580602351?e=2147483647&v=beta&t=T3karhAm6VWgJXK1gnTZj30dlieXYWSIoa3P4IPHiXE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Dockery',
        emails: ['jdockery@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG9MWKzaGNoCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557520867735?e=2147483647&v=beta&t=hTbUptgVcMdMloKaZEB-9qjXbZJ59jWqhuGhL3f70TA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carrie',
        lastName: 'Fesperman',
        emails: ['cfesperman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGvTyEQEu-6lA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597936232600?e=2147483647&v=beta&t=wUAjAfkKOBq38l8yBMKkRw88h8p-QvgoWL4YkvyKiik',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christina',
        lastName: 'Chandarana',
        emails: ['cchandarana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFPHH-zasRG9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517478891462?e=2147483647&v=beta&t=hZ3RhGgbY5wlFau4pOMAKGACv9pgDQU9RymC1iqL1nA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Megan',
        lastName: 'White',
        emails: ['mwhite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGe5wdj0O0T8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516949649268?e=2147483647&v=beta&t=BXLqlqyqWVAH5j3WIHnIX_YtdX7WYgkcdU__Es48MMA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  quiltsoftware: {
    name: 'Quilt Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrew',
        lastName: 'Stern',
        emails: ['astern@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHBXGTO6CGU5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624574747908?e=2147483647&v=beta&t=siYlyfpypD3LhXCl09DYn8MEaaD0GetZsszJnTk49w4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeremy',
        lastName: 'Sokolic',
        emails: ['jsokolic@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Biju',
        lastName: 'Nair',
        emails: ['bnair@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH_vAURwImORg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616709779240?e=2147483647&v=beta&t=H2AwLRMzydgzCdxVFqzYg0frMb5MERaDUAqnysyT5nU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kyle',
        lastName: 'Johnson',
        emails: ['kjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEDbwM9LpyYTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516970849132?e=2147483647&v=beta&t=5-5cCYu7_9ZK1B7S1uZOn2WlY8SlmeyLSXK2TxeRsUk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Josh',
        lastName: 'Garner',
        emails: ['jgarner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ondas: {
    name: 'Ondas Networks Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Martin',
        lastName: 'Paget',
        emails: ['mpaget@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFbYU4_0EXfyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516362856424?e=2147483647&v=beta&t=R4LaJQGt5TB4oRq5Jg6cbYn6JOmiy2tq0U_97hPNz04',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Yanling',
        lastName: 'Li',
        emails: ['yli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEg34ShF7CdEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517616156997?e=2147483647&v=beta&t=5W4g9qGDjNbOYQ3erT5QsPN4foed_pX03gkI-e8i0n0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Guy',
        lastName: 'Simpson',
        emails: ['gsimpson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eric',
        lastName: 'Brock',
        emails: ['ebrock@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'Monahan',
        emails: ['bmonahan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF1jO0NPemDEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517685497550?e=2147483647&v=beta&t=UzLqQhoO68VG9AXr-ZO2bO6VqDjDOAamHeZ3T3J-1jU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eVolvetechsystems: {
    name: 'E-volve Technology Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tanya',
        lastName: 'Johnson',
        emails: ['tjohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Deidre',
        lastName: 'Boone',
        emails: ['dboone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFgGudg2tZt9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716134502118?e=2147483647&v=beta&t=2D9yOhosB_XlzoZEdzFHBOVMNzmgsKkr6OsGXzKV0o0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeanine Callahan,',
        lastName: 'PMP',
        emails: ['jpmp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robert',
        lastName: 'Huskey',
        emails: ['rhuskey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGEsqy2FgpeVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675972732460?e=2147483647&v=beta&t=QlOKrrrev0zSXW4-EdYK0FyzJGVWG12efzC3_ojIxd0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jonathan',
        lastName: 'DeLauter',
        emails: ['jdelauter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEuSkOKa7Sp_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634771691081?e=2147483647&v=beta&t=DBszXpUxTtjZ8LSjWTMu-IhjcfDEXckETqyDgDjHTiA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '10zig': {
    name: '10ZiG Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Martin',
        lastName: 'Pladgeman',
        emails: ['mpladgeman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stuart',
        lastName: 'Pladgeman',
        emails: ['spladgeman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tom',
        lastName: 'Dodds',
        emails: ['tdodds@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFMAH32zarPlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670021110653?e=2147483647&v=beta&t=Ru9qqFzSVbC_5-6cCvdthvVy8_zFhshYkjd31HeMcEg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Darren',
        lastName: 'Fiore',
        emails: ['dfiore@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kevin',
        lastName: 'Greenway',
        emails: ['kgreenway@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bolster: {
    name: 'Bolster AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mike',
        lastName: 'Cabot',
        emails: ['mcabot@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHrH8gM0vvbiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516302483340?e=2147483647&v=beta&t=agWvdgnofkirXd23iu3zO8FECnIMN_As5SMus-N1-yg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abhishek',
        lastName: 'Dubey',
        emails: ['adubey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shashi',
        lastName: 'P.',
        emails: ['sp.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jack',
        lastName: 'Marshall',
        emails: ['jmarshall@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Arabella',
        lastName: 'Hallawell',
        emails: ['ahallawell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thenewberrygroup: {
    name: 'Newberry Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Steinbach',
        emails: ['csteinbach@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joe',
        lastName: 'Ziolkowski',
        emails: ['jziolkowski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQES0iG5S2gpbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516256836066?e=2147483647&v=beta&t=av12fB244BryIoalN8sU_7bbBtSMzK5aQToS0aur5rg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anthony',
        lastName: 'Hausmann',
        emails: ['ahausmann@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Mullis',
        emails: ['mmullis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFfD49SjcmzZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628621445666?e=2147483647&v=beta&t=Tt62A-ObRVclPjryTAF77H0fyoR2aWFyaulo1OYkioI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Becky Bland',
        lastName: '(bbland@thenewberrygroup.com)',
        emails: ['b(bbland@thenewberrygroup.com)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHP1pOfNtQInw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516319239123?e=2147483647&v=beta&t=GQY5duEq4PTbaYsgKvjYDhhGCWMQYw5Lhkr4T5kvUU4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fidlar: {
    name: 'Fidlar Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Inga',
        lastName: 'Harty',
        emails: ['iharty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFZ5idpGl09Xg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629488586231?e=2147483647&v=beta&t=bXAiMhORH3HSrdtwjPqd1CQrlc_3ra4lCUieKZNn2hc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dave',
        lastName: 'Steil',
        emails: ['dsteil@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Adam',
        lastName: 'Watkins',
        emails: ['awatkins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shelly',
        lastName: 'Bushnell',
        emails: ['sbushnell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFJH_RB3AcURQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644290598825?e=2147483647&v=beta&t=x6JaN6nnvQApfVKMr-bs9k2Eh0cQccQez5iVha6u0Lc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Peter',
        lastName: 'Ollis',
        emails: ['pollis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHXcDb9c5DcLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517580669996?e=2147483647&v=beta&t=Nk4LwZY_vsSiatgOnqyugGEOQGKRbrGLGkxLsanlMx8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  safenetat: {
    name: 'Thales Trusted Cyber Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'PRAVEEN',
        lastName: 'KRISHNAN',
        emails: ['pkrishnan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGR58rjWlT_3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692427850156?e=2147483647&v=beta&t=tKA-X0H5YJh6F-KYw-dS49v7oYE06VrPImh-eiPAhC8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'krishnalal',
        lastName: 'Krishnan',
        emails: ['kkrishnan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEa-AfvlHDA7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586780180714?e=2147483647&v=beta&t=W9vqgMXjw7oFFasEyQKLfOIu5QdOA78BebC3KKOMxUw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Praveen',
        lastName: 'Krishnan',
        emails: ['pkrishnan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFB8P3ws4Y2HQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692428962763?e=2147483647&v=beta&t=Zx-p9VXT8vN_vsHIoLvAj8vP5fklsGLVGaTlJgQf7_0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Satheesh',
        lastName: 'Kumar.S',
        emails: ['skumar.s@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHytzNwADe6uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725589438258?e=2147483647&v=beta&t=j3GXTjRIPFsh8yGLSrSWWs6dyDhSDVIv8bs2lAcSUSM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Soumya',
        lastName: 'Rajesh',
        emails: ['srajesh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFZaC5cQCk84w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690459988556?e=2147483647&v=beta&t=t0Ucd2vWC-E3RwxsRgjhPc8CQfAQOpOtHrgL2LzKYuE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nclouds: {
    name: 'nClouds',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shaun',
        lastName: 'Ritchie',
        emails: ['sritchie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF8noVSoaKjyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680756079758?e=2147483647&v=beta&t=tEBpbiU36A49czhpRea3sa0FWKoq3MfzW3bb8QBeiMU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Callin',
        lastName: 'Lindsay',
        emails: ['clindsay@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carlos',
        lastName: 'Luna',
        emails: ['cluna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGuIStivRT0Dw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517013638085?e=2147483647&v=beta&t=uA_LHt0OMqbYt8CZDYMoSItH0-oJPEk_g2EpNg6ScCs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Cesta',
        emails: ['mcesta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG0KJakXm8q-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547416353275?e=2147483647&v=beta&t=Oa5bCiSoFQR5bUMBsI423LOIyBGm3Ogc6paa4VzrCb8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Randy',
        lastName: 'Newell',
        emails: ['rnewell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dakcs: {
    name: 'DAKCS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Julie',
        lastName: 'Newson',
        emails: ['jnewson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGoVfte-qjaYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643136148336?e=2147483647&v=beta&t=E6NAO21ZOaZPJXDy8QLmA11HeIxhiZFggsb9FGZhD0Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Reid',
        lastName: 'Miller',
        emails: ['rmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGi0fuICfdP9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579805724482?e=2147483647&v=beta&t=3oQywGg9NcPWpq8yA6ShjbG1rlUBKmWbHcRGe9Y6PSI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Pizzello',
        emails: ['spizzello@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQECYCFCUAGOBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517532303292?e=2147483647&v=beta&t=wSACG-7OPutDtlzEd7z3cmjw_DYsKhTWuz1PTJ3G0M0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zachary',
        lastName: 'Rizzi',
        emails: ['zrizzi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jared',
        lastName: 'Marble',
        emails: ['jmarble@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFkUAdO_xxV7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516685222574?e=2147483647&v=beta&t=Zra7Rhrd374XNTZpflGM_07esYgnLH8I8MkxSD9irTQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  arh: {
    name: 'Adaptive Recognition',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Laszlo',
        lastName: 'Kis',
        emails: ['lkis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGaO2KmVA82mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619452365377?e=2147483647&v=beta&t=NC33r4yVLB19pyv6WmtjsxeK4tHXz_hZ5Id31tHt8MM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Peter',
        lastName: 'Raganyi',
        emails: ['praganyi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHnVioQdcjNtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648220112061?e=2147483647&v=beta&t=MvOTZZEh5XFawvmKTS6vNmXnDX4bHuXlWqRxdfRUiRo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jordi',
        lastName: 'Zarate',
        emails: ['jzarate@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH9MVNWWF7qyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705415781460?e=2147483647&v=beta&t=2X7FwS0j0fUneAkbjk9rhC9unEB_xPwNXzxaaGD_5Yw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Szofi',
        lastName: 'Mogan',
        emails: ['smogan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Francesco',
        lastName: 'Grasso',
        emails: ['fgrasso@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  humanz: {
    name: 'Humanz',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Liav Refael',
        lastName: 'Chen',
        emails: ['lchen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG_yReAv_f51w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593447102226?e=2147483647&v=beta&t=rt7HIXu-j1v_4giNFNSBJqUm0SAer3TIJP8A8tTybfE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Roee',
        lastName: 'Zelcer',
        emails: ['rzelcer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGea4bNBpCW3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722859902272?e=2147483647&v=beta&t=sjrH3wwPJKJQ57Hvsy-90jfEqny4QSoK2AFCvygYri8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Roi Emanuel',
        lastName: 'Naaman',
        emails: ['rnaaman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFL5IZ_nh5cVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612196172188?e=2147483647&v=beta&t=iYKdJhu7NBZkRpGI30C9G1gi4aHDWkQd2XrqkjEJJ0I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Liran',
        lastName: 'Liberman',
        emails: ['lliberman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE6QmefOxfuDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718282779746?e=2147483647&v=beta&t=qWREvgtFWj9PSa_jdv13LU2WJcry80e5_DIKfeFiwjs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elizabeth M. Tavara',
        lastName: 'Franz',
        emails: ['efranz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFAtVsHFVGoKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659115519689?e=2147483647&v=beta&t=hIQ_wEbWMYhX_S7tgAVJdu0qDvtQUIn11h2YygtBAZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  psci: {
    name: 'PSCI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kelly',
        lastName: 'Wetter',
        emails: ['kwetter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH4olawTf9d3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619105763889?e=2147483647&v=beta&t=4WKPz1JHl--N2C2PVJLnALMH-TP5N7Yf_FkT6huugcQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Leslie',
        lastName: 'Aharonian',
        emails: ['laharonian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFNXVECgAjKuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517750809924?e=2147483647&v=beta&t=ue5cug8aFqvNJ7yqS5Vb3g58DM54ObyxahopsJJRsc0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: "O'Neill",
        emails: ["co'neill@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFwfM9srVBsZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517653641304?e=2147483647&v=beta&t=GalbdH9fAqJwf7f2xG-RFYQFMEPOuQuQvLMoeGQ6cAo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: "Sean O'Neill,",
        lastName: 'CPSAE',
        emails: ['scpsae@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHnrKKegliGPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674146510959?e=2147483647&v=beta&t=bVPDsSWw-Z4NL2UhlX5g56nUpLVkQHpmBqvVtUoecO0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: "O'Neill",
        emails: ["po'neill@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQETUumPcj42mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700084374900?e=2147483647&v=beta&t=k0nRN-5Qs9D5IzW5jEfluTyACGwb3jGvd9-jzI_Wx0c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  conceptSolutions: {
    name: 'Concept Solutions, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Phong',
        lastName: 'Mai',
        emails: ['pmai@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Todd',
        lastName: 'Schaberg',
        emails: ['tschaberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Krahulec',
        emails: ['jkrahulec@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEgiGGugbx-Gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596725330507?e=2147483647&v=beta&t=ytWEFkvVf4IsxCmZ3XVjtWPjk-VdHL6v_mUtf6oDP_w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Glenn',
        lastName: 'Griffith',
        emails: ['ggriffith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFOerjmT81QFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661999404464?e=2147483647&v=beta&t=wcMxRzIp3hDg6R-lpwptbVXzLMgKgx-696Y-fRUJJB0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Danner',
        lastName: 'Claflin',
        emails: ['dclaflin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF4f5Ug_Tn3wg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517702529887?e=2147483647&v=beta&t=KlNiIWo1zTKYk2Bh5tMu2K7y03oQ2ruHfHkZZaSf7pY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  regnantinc: {
    name: 'Regnant Software Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Regnant',
        lastName: 'Technologies',
        emails: ['rtechnologies@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHckOA8EHeTNw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517578450191?e=2147483647&v=beta&t=kDqjeTj6VffhvMjA7RlqeMxUy0svDsM0SYVbFA3z3cg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Raj',
        lastName: 'Saklani',
        emails: ['rsaklani@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Puran Singh',
        lastName: 'Rajawat',
        emails: ['prajawat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEQ75KI6lbSzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516588209215?e=2147483647&v=beta&t=Am1cJonGkmqcAntRbqVqhd32zrL_GckMytzY7PTq0_U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Arjun',
        lastName: 'Singh',
        emails: ['asingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEik2lamIylfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560418136734?e=2147483647&v=beta&t=bxkcmTt5ZvDS-Pw9UFaKa7omldP_RLyRM98xRqVpQDg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'HARISHANKAR',
        lastName: 'KUMAR',
        emails: ['hkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFjOtVEfk4GcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696585245490?e=2147483647&v=beta&t=piE1eB5Kv-YsDcN3jhrZnfG-kP3HPl50aj3tEHmeq1M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  yardsticktechnologies: {
    name: 'Yardstick Technologies Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric',
        lastName: 'Frey',
        emails: ['efrey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHsexIcpqwfFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685209838517?e=2147483647&v=beta&t=NNPmlaL7KyaaRrlutgj00cKVaYodLEwMKq8U9yXKD7Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kelly',
        lastName: 'Woodworth',
        emails: ['kwoodworth@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Malcolm',
        lastName: 'Jardine',
        emails: ['mjardine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFUc8_nFEgCTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517244257548?e=2147483647&v=beta&t=VMSNiUGuU1Tyv-VCicc6nbDECynztzeEa3T0CBzEAm4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jaycen',
        lastName: 'Kuehn',
        emails: ['jkuehn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHbbI9QxklLpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603040966179?e=2147483647&v=beta&t=KwZ52Cd1G6kuDVfJwaE6947vEsdHJ3AX6Tfh0ltzBEU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Justin',
        lastName: 'Elford',
        emails: ['jelford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF2hYwyRiC_aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636755812037?e=2147483647&v=beta&t=LAVDVhKjTEJL8Ei-f4olIjHlI4obhJO8U772vvbXCyk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rmmsolutions: {
    name: 'RMM Solutions Inc. (Acquired by New Era Technology 2022)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Mann',
        emails: ['rmann@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matthew',
        lastName: 'Langdon',
        emails: ['mlangdon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEomH1fhkvYhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516831380999?e=2147483647&v=beta&t=rKj71l_LimwAGA3-op5Lww7XdwYo3xKJgNzHD71H19E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ryan',
        lastName: 'Carpenter',
        emails: ['rcarpenter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHgiW06i0T-dQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1526649306382?e=2147483647&v=beta&t=e6m272DseHzYT4Qvuo7Xd9pHXPvMTAvzuIhSFD0DBzA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Betsey',
        lastName: 'Jackson',
        emails: ['bjackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEUyyrkDYvCVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518035874060?e=2147483647&v=beta&t=_LHqEUJJsmE0aA4GmybA4xbzQfWaDVh1lJv97H6Y6PQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Thomas',
        lastName: 'Hartrich',
        emails: ['thartrich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGdjJgD5onEBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543461375315?e=2147483647&v=beta&t=sZ3S9_oMl2uNRJxYnymtIhevX4G2jtqxiyYisAHzTEA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  itrgroup: {
    name: 'ITR Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Todd',
        lastName: 'Montpetit',
        emails: ['tmontpetit@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE91x01fzNFvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729702795515?e=2147483647&v=beta&t=KzsEmQWzLINITgp_5gobzbojMnljhRU-qJv5B64eoRg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stephanie',
        lastName: 'Adams',
        emails: ['sadams@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lorraine',
        lastName: 'Hale',
        emails: ['lhale@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'McGovern',
        emails: ['mmcgovern@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGCNuw5z4KkjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652187155088?e=2147483647&v=beta&t=xaO4cTc_qOk4LczqB5siKd7mYM1qc5SvPyLuvukAD90',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sam',
        lastName: 'Gambino',
        emails: ['sgambino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFlsnGkxkkMXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1565272769794?e=2147483647&v=beta&t=Uoed6CYCi1nBs3c58hCNMo9zhqJb0_sqeGwbPMEIvTY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  versatechinc: {
    name: 'VersaTech, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tony Dillon,',
        lastName: 'CTS',
        emails: ['tcts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG3oMsR6oG8mA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541270231183?e=2147483647&v=beta&t=p4RO2iA7LUMtJnRKPpTwTzb7xRFcwB2nu3RnpM-iFvk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Peay',
        emails: ['jpeay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFkoPQtGm_kkA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517653882618?e=2147483647&v=beta&t=QwIQZcwPch9q_LvBXlen5hWMmOdZbw0Y1W_QIS52_aE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cephas',
        lastName: 'Jackson',
        emails: ['cjackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHcgg0lpHn2qQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703798505109?e=2147483647&v=beta&t=ia1EvBrJfbTLNK2x8DN_QTDEZ8YF03hVUU9hTmyuzwU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sean',
        lastName: 'Tucker',
        emails: ['stucker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ninette',
        lastName: 'Regan',
        emails: ['nregan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFn3aoekm9WpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552920431622?e=2147483647&v=beta&t=7oT-xc1xHN7corSARHv2aMHY41uaiOIbRyYYq1BmBNI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  catalystops: {
    name: 'Catalyst Operations & Analytics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Saenz',
        emails: ['rsaenz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF8eM94Q5M94A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630113610207?e=2147483647&v=beta&t=hUbkDcphUstLf-pugwfLnNrD_LOjfm96fl4UHdX8tTY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Colleen',
        lastName: 'Dill',
        emails: ['cdill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFGOKhLOBMh5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721674623345?e=2147483647&v=beta&t=OPTY6vsMuYLVsOlcsuYeiqInqVNdd2-S-pPgOTiFmx8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jason',
        lastName: 'P.',
        emails: ['jp.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Toni',
        lastName: 'Lowrie',
        emails: ['tlowrie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHWO7eaI60WtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571663374248?e=2147483647&v=beta&t=NK_cVSTUo5S3kWCE5_fWXasxjNwpxIbyRlgBClhxYe0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tracy Hammerschmidt,',
        lastName: 'PMP',
        emails: ['tpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFQxE1tg6DUGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674415495430?e=2147483647&v=beta&t=THIZZdkEkrJRbRKB3hVd8RBCUjBq1dIOH1GpogNlnK4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cengn: {
    name: 'CENGN - (Centre of Excellence in Next Generation Networks)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lisa',
        lastName: 'Klimstra',
        emails: ['lklimstra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGzNvbeGRctNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561650100994?e=2147483647&v=beta&t=rCOIrkylbx-hiMDWvpDc6JnFJCBYjZNNt8aXFnSgqBo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Victoria',
        lastName: 'Hand',
        emails: ['vhand@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH7YXPH1RI1MA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516880961031?e=2147483647&v=beta&t=9yG-F4MspCjDKLDJNzJR6RIM5HnuiQNA4ksM1s3rpGA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nathalie',
        lastName: 'Guthrie',
        emails: ['nguthrie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG5wGk5-2xPCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617247622182?e=2147483647&v=beta&t=HK1HvK4Z1qTVP0ziHDpOS_2IGc8q3B-RZLW4G-Jxb-M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Keith',
        lastName: 'Tyndall',
        emails: ['ktyndall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHBp1DZsRhXeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1545003076985?e=2147483647&v=beta&t=HdL8JzIWISEzO71LtwURUExhHZH1bCJKk2ut2BUaF3c',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ryan Davis,',
        lastName: 'PMP',
        emails: ['rpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHRE-FxZugXFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715959397435?e=2147483647&v=beta&t=xofacDvRq0ELvMN1YpEl7mQ9PQMOCGN-Dy-dBZlsMww',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  opentensor: {
    name: 'Opentensor Foundation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ala',
        lastName: 'S.',
        emails: ['as.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Keith',
        lastName: 'Singery',
        emails: ['ksingery@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eugene (Yuqian)',
        lastName: 'Hu',
        emails: ['ehu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGlpB7lQg3A-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592515292234?e=2147483647&v=beta&t=bnYtiL9_rhLqzWfrvTsg2J7yrXycgAM4E0UqRGxNeTs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gilad',
        lastName: 'Shoham',
        emails: ['gshoham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHEG5YIfgVTaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517430745955?e=2147483647&v=beta&t=aYnUUTWXGjSo1WtxpSIkfYDfQFUExwQ1H-0V9JlVGBU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jacob Robert',
        lastName: 'Steeves',
        emails: ['jsteeves@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  attackiq: {
    name: 'AttackIQ',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brett',
        lastName: 'Galloway',
        emails: ['bgalloway@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGr6ETfxGMW0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516236422862?e=2147483647&v=beta&t=rR1YR_3oEfCF3Kn2ujEhYMi1YqvrxICpw6wOXRWB6Zc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carl',
        lastName: 'Wright',
        emails: ['cwright@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGpEkbuEZwbSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516268341723?e=2147483647&v=beta&t=21BToHNLpqp1XEYwD0fyVhJz6WabVcJkQ6fS-54Ap2U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rupen',
        lastName: 'Shah',
        emails: ['rshah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFCC28d7_3ing/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1538837471680?e=2147483647&v=beta&t=lMJSu3OXr3kYAaAXwDlpmudd44wgnd154Gzh5d8LxNM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Keith',
        lastName: 'Wilson',
        emails: ['kwilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEVJH1UOzIj8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598540807319?e=2147483647&v=beta&t=uVeW-cV4G2r41hurhfrdQM2Yy4J1usBnuMiCKbzCMW0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jenni',
        lastName: 'Opel',
        emails: ['jopel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  adiadigital: {
    name: 'ADIA Studios',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  emblemtechnologies: {
    name: 'Emblem Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Muhammad Uzair',
        lastName: 'Arshad',
        emails: ['marshad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHtBZ7WwqvUZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665662755888?e=2147483647&v=beta&t=sm6LW_bZZXXE02vUsVoMmbeBbJYearxrmL_q1zwjVak',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Zoha',
        lastName: 'Javaid',
        emails: ['zjavaid@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGiJLqQPxF9bQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704042748635?e=2147483647&v=beta&t=ach70s7CeVKU5kJGqM_uGWekrPILYbitQA-czHa1wik',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Irza',
        lastName: 'Uzair',
        emails: ['iuzair@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHsyzvf2XZvcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723844070357?e=2147483647&v=beta&t=p0YeHmd9kc1qiTrIrsTqTczyEm9X5e-MxjQqsa7fV7g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Emblem Technologies HR Talent',
        lastName: 'Management',
        emails: ['emanagement@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'tane',
        lastName: 'tariq',
        emails: ['ttariq@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ctacorp: {
    name: 'CTAC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steve',
        lastName: 'Darr',
        emails: ['sdarr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rosemary',
        lastName: 'Cullain',
        emails: ['rcullain@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeanette Ryan, CTA, Corp',
        lastName: 'Immigration',
        emails: ['jimmigration@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEkRT_WfRtsUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516351483996?e=2147483647&v=beta&t=nj7QA7jw_S9QdMti2ZoRgApl3kujVrPyfB6wOt3MO4s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paul',
        lastName: 'Baz',
        emails: ['pbaz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFbgNQuVHFx0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654045754668?e=2147483647&v=beta&t=Sd9he2V_6dLZPSSDcvkPefeYdXYT6bzGgnyeFCksLMo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Robert',
        lastName: 'Richter',
        emails: ['rrichter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG6NvcD3vDFBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518206623345?e=2147483647&v=beta&t=g0Gw_s6JviB-HHv-mkPRXmen_wa2s-CuWT5nmnfwmxw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  champtitles: {
    name: 'CHAMP Titles',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Darin',
        lastName: 'Critchlow',
        emails: ['dcritchlow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGZxC9VePSqTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686580903642?e=2147483647&v=beta&t=CWwkyZ_reof9f4AM-8Qnh7gC5MnvxLbyhl83GnjQCE0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bo J.',
        lastName: 'Shim',
        emails: ['bshim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG9HZV5dhRRUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517323447344?e=2147483647&v=beta&t=cark0q-t5I5gi2UntqcH-7WLIN0CTaMzNofR5Clf0TI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'Clark',
        emails: ['jclark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEp0a1pXWqqkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654631762199?e=2147483647&v=beta&t=X7EMo6w_FRwMG-UsDCPmgD-40Ueua97KxRcKEKpu4E0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Elizabeth',
        lastName: 'Melhus',
        emails: ['emelhus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEePvUmlLsSFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547063002368?e=2147483647&v=beta&t=n_8SWRyPaAvo6IGJZ3brIFDUyh1AW2L1ml4IybG1oy0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matt',
        lastName: 'Klouse',
        emails: ['mklouse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG01oJnLtc89A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600719217929?e=2147483647&v=beta&t=5cNF5zHOBCihCBXB00x6g0CZVJNdmRe88LmX9CV-QvQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  celtra: {
    name: 'Celtra',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mihael Mikek – Celtra, Inc. |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGjN4mN4xgrVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516235123087?e=2147483647&v=beta&t=KfHUBV8Zecj1qoI5B-Cuw8RCcFld9m1zMFWAVukxRvo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maja',
        lastName: 'Mikek',
        emails: ['mmikek@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Cutino',
        emails: ['ccutino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFtkoNXvvb1pQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629407666323?e=2147483647&v=beta&t=P5uBNCRKuap1IGHwZGT3syQuQujdPi1sQvkfRt6EhCY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gavin',
        lastName: 'Banks',
        emails: ['gbanks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH4d-qPb1Ru9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603904300165?e=2147483647&v=beta&t=cQD05mNzH7MwCpiG8-n_pQI7tRPzPGRkL7bphNfcrig',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jaka',
        lastName: 'Jančar',
        emails: ['jjančar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHKNtQZHaqfTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516296582066?e=2147483647&v=beta&t=WLXdyqpg7vcGLHGD0BkSJkGGyMr_-b3vBSF-HopGP4E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tonkean: {
    name: 'Tonkean',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sagi',
        lastName: 'Eliyahu',
        emails: ['seliyahu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH2seHL8wning/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624550906056?e=2147483647&v=beta&t=46zMpaiaalI6m7xm3pJN3oGoVNIITim_hnf-C8orwuQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Moore',
        emails: ['dmoore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFyhceQnfcVTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718236154820?e=2147483647&v=beta&t=UaxQLeNK52HUYeElQEp0oXHU0uDqFUGCcAAurn35udc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marcus',
        lastName: 'Mayberry',
        emails: ['mmayberry@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Satendar',
        lastName: 'Bhatia',
        emails: ['sbhatia@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lev',
        lastName: 'Waisberg',
        emails: ['lwaisberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGTlIlrWS1IHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516307218408?e=2147483647&v=beta&t=fenwTWqasKYnAOuTcNiTqraZ9EkAl9G3w0PTQ-VSZ0Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  touchpointsinc: {
    name: 'Touchpoints',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Naren',
        lastName: 'Kini',
        emails: ['nkini@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Udayan',
        lastName: 'Chanda',
        emails: ['uchanda@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amit',
        lastName: 'Kachhap',
        emails: ['akachhap@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH0UIl7UBf9Sw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670583456074?e=2147483647&v=beta&t=AlcUn_PBO3RM9A_91JBlO79Jth0nNdghYKxO-YLUrH0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Seema',
        lastName: 'Chanda',
        emails: ['schanda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHEJBiufOFeWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517044922396?e=2147483647&v=beta&t=SPd6aIDTNkppTmBpB19_0mEL3J1xybuCCZZ125duoag',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Harish',
        lastName: 'Bhat',
        emails: ['hbhat@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  giddyup: {
    name: 'GiddyUp',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Topher',
        lastName: 'Grant',
        emails: ['tgrant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQETYurvjwLN3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677623901631?e=2147483647&v=beta&t=0GOncrvnZP580MXQ6aVC-VM2WT5WBuxmSfm3HglKZf4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Celeste',
        lastName: 'Reinhart',
        emails: ['creinhart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFGk4k9zCBYog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587438686210?e=2147483647&v=beta&t=Ngi0YpSDRwDu64ZPMzEJ4cHp-SITnDIOIllc5p2Wvac',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Todd',
        lastName: 'Armstrong',
        emails: ['tarmstrong@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jill',
        lastName: 'Dixon',
        emails: ['jdixon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEl2ggDB949Ag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590733213562?e=2147483647&v=beta&t=oSNw-KSxgCHTGZwEIy_c0XDJNzExtbn5JZmac6S94uk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kelly',
        lastName: 'Densel',
        emails: ['kdensel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQElofbasOovSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710948266046?e=2147483647&v=beta&t=Z0I-tYgTDIjTagtuLWoa_lS1eNNyTpHHVrOVKjigbKE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  globalcommserv: {
    name: 'Global Commerce and Services, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jay',
        lastName: 'Norton',
        emails: ['jnorton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF8oBxnLfEDFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610764718304?e=2147483647&v=beta&t=UxdzBvDUWzzIW_p71JXJdql-_227ndz-WFwXrCA89iI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joaneane',
        lastName: 'Smith',
        emails: ['jsmith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kevin',
        lastName: 'Ervin',
        emails: ['kervin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG-Xuk9NKy5ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517590330910?e=2147483647&v=beta&t=H4_SNPm2lPvj10xxRjLWSoD1BIzceYuSNq3jCRh15gM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Fred',
        lastName: 'Robles',
        emails: ['frobles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQH69aUp_pV8jQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517013482163?e=2147483647&v=beta&t=svz5VJ8sLkeZDkpQzjng6MvfSpYoFie4fi2MQMAmKog',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amanda',
        lastName: 'Austin',
        emails: ['aaustin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEWrtJEmVZSvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724440416338?e=2147483647&v=beta&t=Ping_2jk_sBjQwtdUsI44S3hpxXN1Ycddbe_sA25VQo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  corizonhealth: {
    name: 'Corizon',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Keith',
        lastName: 'Papendick',
        emails: ['kpapendick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG5Hy1i95RJZA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516466804070?e=2147483647&v=beta&t=BFfzzJBReKR8_vu-xExMVstcbPNXKCW0Mzwyxjt4YbI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Wesley Luffman, MSMHC,',
        lastName: 'LPC',
        emails: ['wlpc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEiW_OtgKthPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643331422513?e=2147483647&v=beta&t=VHbryGuw4jBqOO949S-vrf2XlYw54wvutSzkbNIU-YM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shalin Shah,',
        lastName: 'MBA',
        emails: ['smba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFdod206b7NLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629998907756?e=2147483647&v=beta&t=lHfk0YKIxiQKOm-1_Ohtf6LoqW-MVWN-0TRsSyOJrq0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Scott',
        lastName: 'King',
        emails: ['sking@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Timeka',
        lastName: 'Mister',
        emails: ['tmister@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGEh1BOR9cT5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654522676005?e=2147483647&v=beta&t=SsCN-LtVQQNQXKfUhrqUglUHv6sf8RLdR7GdQVlMIh0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sachtechsolution: {
    name: 'sachtech solution',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anshul',
        lastName: 'Kamboj',
        emails: ['akamboj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGiV7yJc1rvKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722047845261?e=2147483647&v=beta&t=DF3vUKpwS9pujXM4rvAv6HsvZHU09P9nisxAhG5hAs4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nardeep',
        lastName: 'Sandhu',
        emails: ['nsandhu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGPWTi-s5c3LQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517438639977?e=2147483647&v=beta&t=Jl-uI7twXxu0e7zOF987S9rBGBQ2U6gxCUG91FOuH6Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Abhishek',
        lastName: 'dubey',
        emails: ['adubey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFGdI_eoTQUNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654607006913?e=2147483647&v=beta&t=dxmkNTGxq2-LZhc_emKbxuA9EwB-076GIdd7jSEjklY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tanya',
        lastName: 'Kashyap',
        emails: ['tkashyap@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHxK98O-xoSUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709476515359?e=2147483647&v=beta&t=8lRzaYYyTYhOOnPI0ZZ1-2uRo2vNisSccQee-BgpeTM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ankit',
        lastName: 'Saini',
        emails: ['asaini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEs162_TIXZVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689858954947?e=2147483647&v=beta&t=xv1lpPz8MGAm3neFnRCUOkLZqWFfZFSao-9WPo7uDqo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  foocafe: {
    name: 'Foo Café',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael Tiberg – Chairman – Foo Café Foundation |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG8LmosWbZCHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516276886518?e=2147483647&v=beta&t=U3BZczEiVI2NTWYkNj-pw7MiFZrW8cQuXrbpVGLGCpI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sergei Zaiats – IKEA Group |',
        lastName: 'LinkedIn',
        emails: ['slinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFvENI5IQ2rVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563106642995?e=2147483647&v=beta&t=BmQnHK0hNgDl9SxbUWZuYo3ilLqE_Uef_0H05EJIpMY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Yaylor',
        emails: ['dyaylor@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Thirumurthi',
        lastName: 'Nagaraj',
        emails: ['tnagaraj@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yi Cheng',
        lastName: 'Foo',
        emails: ['yfoo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG88jeFMYNecw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721892519373?e=2147483647&v=beta&t=cgoy7jJC95bVC3Wj7szCfA-yhkHTYT6Vwx7C96xlObg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  domandtom: {
    name: 'Dom & Tom',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dominic',
        lastName: 'Tancredi',
        emails: ['dtancredi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEuvJ-vgq98KA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680227339424?e=2147483647&v=beta&t=d5CZKBTDIC48B4IaXY4_uebXv70n_qbUz9qJHFSatcE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joe',
        lastName: 'Tancredi',
        emails: ['jtancredi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG-2GxsJdZc3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656339720712?e=2147483647&v=beta&t=s1Vz2qKhZZ7dFNA0_mK43mFO5vjn6FfwuOtuofH7NEQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Drew',
        lastName: 'Papadeas',
        emails: ['dpapadeas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFC-H-6i4__9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662756783509?e=2147483647&v=beta&t=s0MToAsJc2KnV5wY14iw_21c59I92UrZXulASfCPfPw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Thomas',
        lastName: 'Tancredi',
        emails: ['ttancredi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFAC6q7sk3FWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521667570405?e=2147483647&v=beta&t=s6SdDWJyHtj_SMByB0s-oTMvoYfRrDmgtCE2V6KyE-0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alex',
        lastName: 'Giyaur',
        emails: ['agiyaur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFrIb1qei1yPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588707637489?e=2147483647&v=beta&t=okx6_HLmNWfv0BfFkRb8IAwgzeL1N6FdgKOVIK8P2xU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  softwin: {
    name: 'SOFTWIN',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pramod',
        lastName: 'Mahajan',
        emails: ['pmahajan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEmMuyTwzd5jw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517587820289?e=2147483647&v=beta&t=XB-yIFYS5i4O7D_fYO2XBOjl7C78alstEM5gyCg1acQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Uma',
        lastName: 'Mali',
        emails: ['umali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGvDOxfU7TgSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560571263847?e=2147483647&v=beta&t=g1P_aejyNpA8uDXMTRh-_HdG9OyltOsEhymRmwtO-QA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anil',
        lastName: 'Mahajan',
        emails: ['amahajan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFREkNTWOe_xQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719030410023?e=2147483647&v=beta&t=f6kPSgp201fDCxPcORSdkUs1zHm4U5d95wcNKIjQrBY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rupesh',
        lastName: 'Kotwal',
        emails: ['rkotwal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'SAGAR',
        lastName: 'MULAY',
        emails: ['smulay@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gocontec: {
    name: 'Contec, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rui',
        lastName: 'Marques',
        emails: ['rmarques@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEcfbPehT0oUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670584780672?e=2147483647&v=beta&t=1C2Ij36rxg2stdTU5LIj30UwgH1Lxl8d0O1HAGQEV_M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Plataforma de Customer',
        lastName: 'Service',
        emails: ['pservice@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFzykde88D5HQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517613646523?e=2147483647&v=beta&t=4cbmZL3KSTLkRACqu_vZEzypR45o0-qEnX3g44dOKrE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'João',
        lastName: 'Camarate',
        emails: ['jcamarate@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG-LJLspoucmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570220772317?e=2147483647&v=beta&t=9DMQlrBhfcnksk_yIUX5QJWLzoE4QzaDv4bShNUmAyA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daniel Peña',
        lastName: 'Garzón',
        emails: ['dgarzón@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFWOLsV9Ne_qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720584224354?e=2147483647&v=beta&t=iSA1ENi4deRu3vdiSZabkyRPfSJckOt68Ogr-V7BJmg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Judith Gonzalez',
        lastName: 'Planas',
        emails: ['jplanas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEpcJeTCKoVTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713866033115?e=2147483647&v=beta&t=fX95V7fdNt9xWyeoPhLbjMfx-eKiXMnZimnZRfYmIMY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  siriusholding: {
    name: 'Sirius International Holding',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  clearTech: {
    name: 'Clear Tech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Juan Franco',
        lastName: 'Torrez',
        emails: ['jtorrez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFWBRMwfWK5jQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708544510137?e=2147483647&v=beta&t=OkKb6JejMB7lSU0D9bX1Ipau3Vh6M9JBvNlm2ABo-uo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Federico',
        lastName: 'Paez',
        emails: ['fpaez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG2ifg9MJ2fAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516649388950?e=2147483647&v=beta&t=KqNnpaMHl_3ikx2wMrAYX1-hjYUMJEYosiTjqSUrHbQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matias Nicolas',
        lastName: 'Rios',
        emails: ['mrios@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGqCSla4lNVtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559095904968?e=2147483647&v=beta&t=WvI0pmyC4JanE_KW9huiZmys4Ws0RYrFCHZUHqLAVUQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jose',
        lastName: 'Albornoz',
        emails: ['jalbornoz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFbC5anIakRIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703635026911?e=2147483647&v=beta&t=37iIAS9o-twMq9InQ22izBNj1SjRCpzhQ3pTd0Laq-Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jose Luis',
        lastName: 'Barrio',
        emails: ['jbarrio@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  coldview: {
    name: 'Coldview Latam',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hexmodal: {
    name: 'Hexmodal',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christopher',
        lastName: 'Hariz',
        emails: ['chariz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG0GcpBaHkxoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610402679994?e=2147483647&v=beta&t=5q2lQo9pK-IHk3Svh_Yo9I0iakfRk55rVval2ZgwE0o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Utkarsh',
        lastName: 'Shah',
        emails: ['ushah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHWs2dC7cX9KA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638231744662?e=2147483647&v=beta&t=bOQ2MvMysT05RvjtYNs6gWIa1qi0TmXftUJg3zYE8zA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Taylor',
        lastName: 'Benay',
        emails: ['tbenay@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christian de',
        lastName: 'Abreu',
        emails: ['cabreu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGm0xif1Y463Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722104646545?e=2147483647&v=beta&t=XiZq6XV3XTjR3w6jfC0ICxq6YIMM0Wia4mbvV_lsmzo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Arturs',
        lastName: 'Ivanovs',
        emails: ['aivanovs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHj3GMKWyi7wQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591563992363?e=2147483647&v=beta&t=FqW3IbKL0Nt0hCu4WpDbbkweHUJygDhMrh3zwFq06Z8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tecknoworks: {
    name: 'Tecknoworks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Catalin',
        lastName: 'Pop',
        emails: ['cpop@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHrNdDn1HL33Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674814620905?e=2147483647&v=beta&t=3xEf1K3BNnHGWHr7Arbhusevyoryzcl6Q8LRMmDWesY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Edmondo Constantin',
        lastName: 'Gorea',
        emails: ['egorea@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Evgeni',
        lastName: 'Rusev',
        emails: ['erusev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGCZK7WxtaTcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705845372158?e=2147483647&v=beta&t=ZBWODuGbKt4TFAptlnNJ2P4XGyK58mff0Fjut01O6vg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Razvan',
        lastName: 'Furca',
        emails: ['rfurca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEOwhjxDPJf0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701701238253?e=2147483647&v=beta&t=0K8cyhBO6lifAVpiuqyEoUzyoLeJMpZjr4ffWfIvEcs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elena',
        lastName: 'Gaja',
        emails: ['egaja@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEAshm4iJx3_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696418768436?e=2147483647&v=beta&t=TRO_OGo0z9asVJd9SDEeSmNMGYwsYAixS8kD_207sZw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bfore: {
    name: 'BforeAI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Luigi',
        lastName: 'LENGUITO',
        emails: ['llenguito@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHStTNGltAhyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670712258009?e=2147483647&v=beta&t=DLubiDVbLDGfwuflbTmi1ZsBhz-5c3d1Ps_5A_C8IpA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexandra',
        lastName: 'Erman',
        emails: ['aerman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tommy',
        lastName: 'Smith',
        emails: ['tsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFj-S1SaJkMZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628538239350?e=2147483647&v=beta&t=fJr6g_kxzk9doMkU-wijBdR6Ynbabosa6tpczdSsHZg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andre',
        lastName: 'Piazza',
        emails: ['apiazza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHrtlpjXLQ4YA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654112473370?e=2147483647&v=beta&t=uINc9Hjsbyz8ZCqLIRA5KLxM9cIOLWttTKg5OxoPpLs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jarrod',
        lastName: 'Scuglik',
        emails: ['jscuglik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFMkAAsYFRikA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695299165576?e=2147483647&v=beta&t=KuGalaOV3e7eWMY9Ao9SHhH6m6SDI1yVUCrIeEYQazI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cornelisnetworks: {
    name: 'Cornelis Networks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Philip',
        lastName: 'Murphy',
        emails: ['pmurphy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEPvV3qUrV0Iw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605984531879?e=2147483647&v=beta&t=WecLpP5ZOU_WlBuD2DHHSPO2zeV-bw8COBSydf99VDI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Joseph',
        emails: ['djoseph@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Hong',
        emails: ['dhong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHBUALce5-LlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517581656427?e=2147483647&v=beta&t=X5s4VhuCnrkdR9XlRkAuHWNLHMJ4zG34sKrtMpeg05o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brandon',
        lastName: 'Singer',
        emails: ['bsinger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE95T6xmeWh-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598808104270?e=2147483647&v=beta&t=cvG7allex7Q_SuUuf2cg2hdXyswmxB2gMYJXX7oLxuo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Debbie',
        lastName: 'Gager',
        emails: ['dgager@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQECKfPwYIhu-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658926195150?e=2147483647&v=beta&t=ebwEdgKlQ4yaKH_Dho2N78pH6sNH5sI-q8WEx2_GOPU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  outliant: {
    name: 'Outliant',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Austin',
        lastName: 'Kueffner',
        emails: ['akueffner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGj4Pirs3Dr5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669167272258?e=2147483647&v=beta&t=18MklHKvVh9hWOyULepyV4Udb_HSD_7qyta-GaHgUuQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Konrad',
        lastName: 'Billetz',
        emails: ['kbilletz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEfGTTxQTrQ9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663359546436?e=2147483647&v=beta&t=AHlh492GbENskeZn3bakyAdmXyUIWRKV9QbhlsgAXbM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erwan',
        lastName: 'Lent',
        emails: ['elent@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGLprRBG7SPyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718830038215?e=2147483647&v=beta&t=xrhhUUm33F8qVGGgAHg_UrDrZDVYgHSAUKf38fYukL0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cooper',
        lastName: 'Anderson',
        emails: ['canderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGJxD27rc6hwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712936993045?e=2147483647&v=beta&t=tPoDhf5ou8HNVbo2VWxiHYi3aIaDGkCxcceLV2mhcnM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marcus',
        lastName: 'Jones',
        emails: ['mjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGl2ihbMVRGfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670524320843?e=2147483647&v=beta&t=i16aTz8bd3H8OnkINMQUm4pPND4GLXxCq2e-fHl6BvI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dinocloudconsulting: {
    name: 'DinoCloud',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Franco Ariel',
        lastName: 'Salonia',
        emails: ['fsalonia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHeHX_GfTwpSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1640802329332?e=2147483647&v=beta&t=58VwXbDkxYuiKkSJwiZe_RPC6EcdwPhr1XzzPPCGLSU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Katie',
        lastName: 'Attebery',
        emails: ['kattebery@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHYJl1l4p_iAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650578671984?e=2147483647&v=beta&t=DFxpTkPy88FAfo1eNSd94PWiHyWFX2O1bSuzaNI8wNc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nicolas',
        lastName: 'Sanchez',
        emails: ['nsanchez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEYe1rVEYokBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598017878114?e=2147483647&v=beta&t=vOd9fKFZezQTj-TcU6n12zEBpCIHQL6Wp6X8lX7ezhQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tomas Ignacio',
        lastName: 'Martinez',
        emails: ['tmartinez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFN1Aj6b4aaxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695670761742?e=2147483647&v=beta&t=s1R-pXAKrg8kSuACOLCLLosI8obFBTKxjlbgIydhi8Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vladimir',
        lastName: 'Marquez',
        emails: ['vmarquez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGhGqR1dhDV3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692822573651?e=2147483647&v=beta&t=vqti9f0h9vhuuLXyc6AtjqtaHs4btory0hFOmgGApRU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kk: {
    name: 'KuantoKusta',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hugo',
        lastName: "O'Neill",
        emails: ["ho'neill@example.com"],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vera',
        lastName: 'Tiago',
        emails: ['vtiago@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGq7JxNOkjPxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616171286751?e=2147483647&v=beta&t=GjwOWGM4g-FFnyRJmLKju268pr83QdEBiRQMajzSyDE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Júlio',
        lastName: 'Aymoré',
        emails: ['jaymoré@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQExHhB1nLQflg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714990684712?e=2147483647&v=beta&t=IuXmiwbHUu8jua2NePDDxJtQJT6IVlMVkBWnuLvRcks',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Janine Shepherd',
        lastName: 'AM',
        emails: ['jam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEvmBTGuwN1Ew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710304906267?e=2147483647&v=beta&t=Sk28YvElrNiEozzrHd3_Y_fUyBiPNm3zAIoENE87g5A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'João',
        lastName: 'Ramos',
        emails: ['jramos@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  streamlinebusiness: {
    name: 'Streamline Business Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Riley',
        lastName: 'Hayer',
        emails: ['rhayer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE2WE-tEFsCWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697655199195?e=2147483647&v=beta&t=1D5hcYC-MtP5xfFSyNEHIPln6sI1m0114rfk5SO1sZI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Simon',
        lastName: 'Bondo',
        emails: ['sbondo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHvUsevQKsE8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718659553170?e=2147483647&v=beta&t=QIt1ZcOIZYWS-Faqzh5A4zafL2WVcvVOqWqxtrtEcaA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Richard',
        lastName: 'Miller',
        emails: ['rmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEHDjCXL3mOtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568610057909?e=2147483647&v=beta&t=ZRKDyzFYCFdyCwImXSFwQvHdmogHQKUjm1mGIJeXkeM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'James',
        lastName: 'Miller',
        emails: ['jmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJMtUPAkFujA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586294299678?e=2147483647&v=beta&t=A76p0TOmQ5Tg3nGO2BrQgAKMkcq-OSngWigPvFIeqvE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Webb',
        lastName: 'Corbin',
        emails: ['wcorbin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  statespace: {
    name: 'Statespace',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Azzilonna',
        emails: ['jazzilonna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHhgYDlbrB9EQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720310762210?e=2147483647&v=beta&t=kpgQmeaRfjJoa5ovZz4MUI3RZwr7oxhYkGTth3_urCg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'CJ',
        lastName: 'Leth',
        emails: ['cleth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFEoDfUhWLtkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517381681510?e=2147483647&v=beta&t=0ttYOYaMTvyBieUTv0hNXfK387gDiDY4mUvX9PfD0hc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Romy',
        lastName: 'Hatfield',
        emails: ['rhatfield@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHCKBGMDY1Wkw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606252497874?e=2147483647&v=beta&t=pcjF7g9CrxTn4PGLqveWNy1hU0TS8qOzalbz6h3Dt4w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jason',
        lastName: 'Clemente',
        emails: ['jclemente@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF4pQEO9J54iQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718478391024?e=2147483647&v=beta&t=ra80lT-lbgsXs-Q2FRe-QbiMU9_7F8V7HG7rr0idb1M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Allen Santa',
        lastName: 'Maria',
        emails: ['amaria@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHl_RycpRHobw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718984504130?e=2147483647&v=beta&t=5QYhqSdfsZE9fiVa3cd15DjtgivzEZ5aHt6H_reDD_0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  socradar: {
    name: 'SOCRadar® Extended Threat Intelligence',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Huzeyfe',
        lastName: 'ONAL',
        emails: ['honal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andjela',
        lastName: 'Petrovic',
        emails: ['apetrovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFe3AU9xOD9Wg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617781950044?e=2147483647&v=beta&t=9zpn0Ksf9ZQsITxFp09cE3l31SEyAhjL3-Bi0x3Ikus',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rajeev',
        lastName: 'Mathur',
        emails: ['rmathur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEq-l8idEPBNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675825413599?e=2147483647&v=beta&t=zI1bAWiySxu0U_-jkVl_LdP0v7Xi_p2SXqFrGFe726A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Josh',
        lastName: 'Levine',
        emails: ['jlevine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGdgnlSUrelZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713610238340?e=2147483647&v=beta&t=DDzmPEsGzB5palG92n32VdfyiwamuJPQ7hkIMd3RUtc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Williamson',
        emails: ['jwilliamson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEBLWVvtB6qdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643846156752?e=2147483647&v=beta&t=095I4iHAmVpelXL3SwXCy7Gg2HHMKR-TupF_m_w04oU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  joindeleteme: {
    name: 'DeleteMe',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Reuben',
        lastName: 'Moretz',
        emails: ['rmoretz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kelly',
        lastName: 'Adams',
        emails: ['kadams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFaWe3KQ08WCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709581137666?e=2147483647&v=beta&t=UMHKPEO-t9PT7Ni5B1qLf3iq6de8YXXNtadWoHTUH3o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sarah',
        lastName: 'Sulsenti',
        emails: ['ssulsenti@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Erin',
        lastName: 'Sumner',
        emails: ['esumner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEnfWXm5kYo-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708014608234?e=2147483647&v=beta&t=IgS2g8iHMPVBanLPj-MI4yOFsXjskqcNXGlK2HEmjls',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Taylor',
        lastName: 'Kain',
        emails: ['tkain@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rapidData: {
    name: 'Rapid Data GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  widas: {
    name: 'Widas Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  digilytics: {
    name: 'Digilytics AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rohit',
        lastName: 'Sehgal',
        emails: ['rsehgal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Arindom',
        lastName: 'Basu',
        emails: ['abasu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amit',
        lastName: 'Gupta',
        emails: ['agupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEk6ohE362ykg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685180539476?e=2147483647&v=beta&t=jSgbyUDHiKUYmpoYsoXZjLZEm7xfDTaqOc_6hPCsSa8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ree',
        lastName: 'Chakraborty',
        emails: ['rchakraborty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFQubV9xY-lHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630581032196?e=2147483647&v=beta&t=FXD_LEwLnCfnthSCWIYAWaOO5EOst0cMoUrLSNBwRXQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Atul',
        lastName: 'Raj',
        emails: ['araj@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  infopercept: {
    name: 'Infopercept Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jaydeep',
        lastName: 'Ruparelia',
        emails: ['jruparelia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFPtJM_vhNUow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585855248336?e=2147483647&v=beta&t=YpViJs58nbb-d4Oa9V8wHB8lvIt8My56XLSpOsdV-f0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nitin',
        lastName: 'Pathak',
        emails: ['npathak@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jitendra',
        lastName: 'Bulani',
        emails: ['jbulani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFJhoH9BEvrjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725029427152?e=2147483647&v=beta&t=iZ99Es1E0sW7x7XRRUbADECrmV-p6cOhGojE4Md1Vrc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Satykam',
        lastName: 'Acharya',
        emails: ['sacharya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEmVDpRM2e8jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611834111837?e=2147483647&v=beta&t=SCCGnzskCQ5flAk9dDlKZTHdslgCfEIfSjFBbekOiFk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anjali',
        lastName: 'Bhalgama',
        emails: ['abhalgama@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFMvglzyR_zuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689772637829?e=2147483647&v=beta&t=4CB_48Damqfhb914Md_w_T3eq4uOqlDpg9viGC07Mmk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tapcart: {
    name: 'Tapcart',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric',
        lastName: 'Netsch',
        emails: ['enetsch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFbzarFcNjNWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573271353073?e=2147483647&v=beta&t=Wshnu2rM_Q-FjbPFlsNWiTTp3fZQ3DpV7IzF7dfBEv0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Olivia Spearman',
        lastName: 'Perez',
        emails: ['operez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGwDWSgtSxFPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666131222030?e=2147483647&v=beta&t=94Nn8SXc8uo_DvL5PDqYwr74LiG9_c7_fEbZva32G0I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Greg',
        lastName: 'Parsons',
        emails: ['gparsons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGEQKjcEMolNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729636042078?e=2147483647&v=beta&t=9JVlTYQ_VKgl9phUVHkT-6CmbXaFYYTT7CSeAYLYBv0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Keith',
        lastName: 'Powell',
        emails: ['kpowell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFLoi5AEEXr9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595142404645?e=2147483647&v=beta&t=KfYehCuZO07OOYWCEWM258G_fovuB2tkwufHWBdcXSQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ashley',
        lastName: 'Hildreth',
        emails: ['ahildreth@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  intellishift: {
    name: 'IntelliShift',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John M Cunningham',
        lastName: 'Jr',
        emails: ['jjr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE_Gh5WVoNACw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704836820198?e=2147483647&v=beta&t=BB-dRL22wbdarnTwgh_2wVBew8MwgzHpR5el-WoU4uI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeffrey',
        lastName: 'Cohen',
        emails: ['jcohen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ryan Wilkinson',
        lastName: '♾️',
        emails: ['r♾️@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHd_OWdRgeYRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582233545342?e=2147483647&v=beta&t=IYOTJilF5YzH1cJTJkYdH5n_ZNQNCxIw5ZQTYLxveDs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dustin',
        lastName: 'Sears',
        emails: ['dsears@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFjkQjK4QpZ5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692577587281?e=2147483647&v=beta&t=SwhNnZJVttcEyn48tRZGcgjGW3NNqMOQoOoDTfdM-hc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Wendy Whitcomb',
        lastName: 'Blatt',
        emails: ['wblatt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHShZfBIwakdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574627703136?e=2147483647&v=beta&t=_cWXjfZSKM6E_V9qKFGN1jXiRr4NksXyEWV1RRqEC3g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  promotion: {
    name: 'PTG',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'lynn',
        lastName: 'matson',
        emails: ['lmatson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Katelyn',
        lastName: 'Kazanowski',
        emails: ['kkazanowski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEDtjmK7EI96Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652288501062?e=2147483647&v=beta&t=oicf2rMR-juobE9gVBP3DayAjvhRjeg4NklPrBfB5gQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brian',
        lastName: 'Flewelling',
        emails: ['bflewelling@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEt98FYplk6oA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716295068991?e=2147483647&v=beta&t=QancXHOsuQWPWPtqIeQRuIwreyQZPSi6azGQrfGmO6A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joe',
        lastName: 'Loepke',
        emails: ['jloepke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG2OMdb69XFZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595612318856?e=2147483647&v=beta&t=HYSHEpBM2GLOTRipk3sl7EpD7Ql2TQ6ajlak6GuHi2M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shawn',
        lastName: 'Garrett',
        emails: ['sgarrett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGZgUuWxCssfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585228968601?e=2147483647&v=beta&t=0NPW7kmQe-bF5KdTPWPwhDlHXLCz_9hCKmftEBLzMmU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  qt9software: {
    name: 'QT9 Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jim',
        lastName: 'Ellward',
        emails: ['jellward@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Richard',
        lastName: 'Russell',
        emails: ['rrussell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cole Gardner,',
        lastName: 'MBA',
        emails: ['cmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Doug',
        lastName: 'Whitaker',
        emails: ['dwhitaker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEKc2PQH61BMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1535374487436?e=2147483647&v=beta&t=p1Yidw8vWr93pFsYPTpxlUTYYZwoSgCzXBETNKl4ZjY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'Kulaga',
        emails: ['bkulaga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH3JkZoWUX6Zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628533418736?e=2147483647&v=beta&t=Epz2OnuaTPyQ29tVC1mjjzSH1kfZr9o6ddaEO38o538',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  liveaction: {
    name: 'LiveAction Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Francine (Bennett)',
        lastName: 'Geist',
        emails: ['fgeist@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQH2tPknFiGN2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516277073273?e=2147483647&v=beta&t=qOgCSRWQ2dAzEQqI6jZj0khLvB9ksCn-PFajeHzHAkQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maurina Venturelli',
        lastName: '❤️',
        emails: ['m❤️@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John M.',
        lastName: 'McSherry',
        emails: ['jmcsherry@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paul',
        lastName: 'Gray',
        emails: ['pgray@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEbSiBPaKBexQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516170914457?e=2147483647&v=beta&t=Pz_clmdObVHPGAU1wt9S77rDbYk6Ep-xmHFALFwiNiA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chad Van',
        lastName: 'Patten',
        emails: ['cpatten@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEIpH7lR6LlNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674586364421?e=2147483647&v=beta&t=uiJ1MksPGf1Cvd0gCwNDjhRmWkqy4aMeMioA1NFgbrQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  odysseussolutions: {
    name: 'Odysseus Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Monish',
        lastName: 'Luthra',
        emails: ['mluthra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG6xlO0yt2RuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720785080221?e=2147483647&v=beta&t=kPJNSoy5CXf5k-QAYKDMgpyUo65HiboCqUzUgT7TI8k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jaynee',
        lastName: 'Kothari',
        emails: ['jkothari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFYkLFMyNwrlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689799369322?e=2147483647&v=beta&t=c6BP18IsxQDbEgVrhvta2ntO3ueDTpy__pwbmQVkhtA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vishal',
        lastName: 'Mehta',
        emails: ['vmehta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH3v83cKWtY9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695131882043?e=2147483647&v=beta&t=L2gYakdTQbSXvoGggGGWwUCmPT_n0qzf52crO6tCY1s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rignesh',
        lastName: 'Dave',
        emails: ['rdave@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGC3fZbXEE9rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614746847735?e=2147483647&v=beta&t=tLctSiWqXdHeVEWZH0awfR9TWkBp2LxuxGUbzICLxvI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bhoomika',
        lastName: 'S',
        emails: ['bs@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  assuredConsulting: {
    name: 'Assured Consulting Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Erin',
        lastName: 'Willett',
        emails: ['ewillett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQETPY85-muq3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608346174144?e=2147483647&v=beta&t=QLgdci0Dz_VRXuaMesUK9awg8fJqg_Oa5uyFlQa0r8Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tyler',
        lastName: 'Sgrignoli',
        emails: ['tsgrignoli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHDb-70rvCAXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686066365935?e=2147483647&v=beta&t=q4WoEvqyGQrO-0ggnZn8IihYYLa7Gxzw2Q1_pYD8wjA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'West',
        emails: ['mwest@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHaiojPLUj3Vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707777288861?e=2147483647&v=beta&t=eVEPLfAEuE2nmfPusgql8yirRjGZsg4qTzFYFjB7IOs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Donald',
        lastName: 'Wells',
        emails: ['dwells@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHuhGWGR6-7FA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660583692364?e=2147483647&v=beta&t=tE1a4ZAaf_9OVc7Ed3yjJcymYPiwWaLiI3jcevQ4RpM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daniel',
        lastName: 'Downs',
        emails: ['ddowns@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGJvF5Z4auRNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706823352736?e=2147483647&v=beta&t=qK-YADHT4yCMdlz_lMQbG9DFvvN29nCadfuEbLDh5Ew',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zspace: {
    name: 'zSpace, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Julie',
        lastName: 'Willcott',
        emails: ['jwillcott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG1bDEqeDu7sQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645724600689?e=2147483647&v=beta&t=s7tEMnQWxslGdPl2wY_WK1ds3WooH0gYLIprojCbYOk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Luke',
        lastName: 'Lu',
        emails: ['llu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carolyn',
        lastName: 'McCarron',
        emails: ['cmccarron@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFfxp_pEfrt8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547835003199?e=2147483647&v=beta&t=KiW7dCiNHVzqlz6DGCp-gZnIn1CwuUdpe7noFAdu_bg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dominic',
        lastName: 'Lau',
        emails: ['dlau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH0DNPWxSCW2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670306545795?e=2147483647&v=beta&t=EnaZbri-VtJAIsm9nMoTuFuGoqNfQN4B0PQ926oEbjk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Glenn',
        lastName: 'Anderson',
        emails: ['ganderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGiMfAdgpT8tg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665445581705?e=2147483647&v=beta&t=Wi1m8gVYd6ArwbTohSgKntBLJW7Q7gEEcUMbdY5ix7M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pierson: {
    name: 'Pierson Computing Connection Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pierson',
        lastName: 'Marks',
        emails: ['pmarks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHbQMXlilJVeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699337467783?e=2147483647&v=beta&t=rPomzjsj5XUAqIRVDkoyFpDMPt1IzWBfVwvqpRoKUyQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vincent S.',
        lastName: 'Pierson',
        emails: ['vpierson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGAkDdHJyWDfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656629816555?e=2147483647&v=beta&t=1vZk8jr7gwMT68ZfqSY_xk3iN-BQQa_zXkoSDkMiQ5Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Pierson',
        emails: ['cpierson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ramona',
        lastName: 'Pierson',
        emails: ['rpierson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHhdxtf7JJ61A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517695730733?e=2147483647&v=beta&t=mE9Xwte_9C4ikz-mQbFh4HfbXUZJJ_OJq5V6H4TPDxA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Megan',
        lastName: 'Pierson',
        emails: ['mpierson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFsjCrgljoAMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649339643207?e=2147483647&v=beta&t=R_-SU5NIA63vhQqLc5AQL5Ovjgy0xHUp-RvOXoXkwxU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tSystems: {
    name: 'T-Systems Nederland B.V.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anant',
        lastName: 'Padmanabhan',
        emails: ['apadmanabhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH8SB9PMg9AdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641033621020?e=2147483647&v=beta&t=x3OljpvuAvvIahE7CX2zyM5hUC49QUguk_8NXLsdzu0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Leonard Thiele –',
        lastName: 'T',
        emails: ['lt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEsbeJ97Yyc4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718977529484?e=2147483647&v=beta&t=s26dZUnWIEMDbzIabT8vMnYYtfAV_k7AYZxyLkNsEdA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Madhuri',
        lastName: 'Shastri',
        emails: ['mshastri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFiWT-E_PNOsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711965938143?e=2147483647&v=beta&t=VrNeGsFh5LIJ6OrrMYvcyyo6dro_zXNMTEQN5f7B5w8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alejandro',
        lastName: 'Martinez',
        emails: ['amartinez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGbzYfUkGDW7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712979409099?e=2147483647&v=beta&t=XczbmQWqg6sZK10imxRl6myzd7i6ru-JlOY7WT1oDpY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Casper',
        lastName: 'Malig',
        emails: ['cmalig@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  inspire: {
    name: 'Inspire',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lily',
        lastName: 'McCutchan',
        emails: ['lmccutchan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGkUjtMfqpDRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715126630939?e=2147483647&v=beta&t=e1i_SS6UQ-vITDGBKJI87gRsREJ7Lkz2gr07ugLFl44',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Heather',
        lastName: 'Holder',
        emails: ['hholder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFTym0UMBO0gQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1565622947825?e=2147483647&v=beta&t=R9V8UnEDvBcK8Zrkpx5jNA-LNSuW90BGWSpKMU9_pdw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Neville',
        lastName: 'Martin',
        emails: ['nmartin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGLN4nE5Gc8lA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631291199062?e=2147483647&v=beta&t=zgqxq5w4uiyuVfyvdkxHNil-aaJk7Q0z8gysFbq7YlQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Porsha',
        lastName: 'Ford',
        emails: ['pford@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ena Nicole',
        lastName: 'Binns',
        emails: ['ebinns@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGAwAJmEa6f5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685747131177?e=2147483647&v=beta&t=pY9wVraljNfCpqgYIrMU6Ku6ufZN7aM-ag1IlDpvgvA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  edgeconsulting: {
    name: 'EDGE Solutions & Consulting Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sara',
        lastName: 'Sapienza',
        emails: ['ssapienza@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hrishikesh',
        lastName: 'Zambare님',
        emails: ['hzambare님@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHEXX4mP8JdAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713137904406?e=2147483647&v=beta&t=twT4hiu3pwI_fn3yafDwMP1LcmhZnSshuXNo5u_Pfig',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patel',
        lastName: 'Krishna',
        emails: ['pkrishna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGZLErHXR-y_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561439904551?e=2147483647&v=beta&t=9hzbcYCLWno00o3BMh7V6AWs3WBxcz6UQioTJ3xhaxk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Scott',
        lastName: 'Klein',
        emails: ['sklein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFGsxmtsXtZmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697987956799?e=2147483647&v=beta&t=bmdhK5CPiSHBHNVAAn7qZH6n5cgPpiPkrnzWizoQ8cY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Karthik',
        lastName: 'Logasundaram',
        emails: ['klogasundaram@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  intelegain: {
    name: 'Intelegain Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'ruby',
        lastName: 'fernandes',
        emails: ['rfernandes@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Neeraj',
        lastName: 'Gargi',
        emails: ['ngargi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFBmR25l8z1yw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728992868573?e=2147483647&v=beta&t=zT8q9evf0HZ-lUT1UsiAMQpgm3S0hB6GCDPjah6g6sw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tarun',
        lastName: 'Wasan',
        emails: ['twasan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEGkRrJJ4FSbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517353449109?e=2147483647&v=beta&t=LKI5kI6iaNOhl6jkV8Whup5euW6Q6CY7R1rMvVR5i1k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ruchi',
        lastName: 'Chauhan',
        emails: ['rchauhan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kiran',
        lastName: 'Shelke',
        emails: ['kshelke@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  talroo: {
    name: 'Talroo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gino',
        lastName: 'Bustelo',
        emails: ['gbustelo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Lockhart',
        emails: ['jlockhart@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rohan',
        lastName: 'Chhaya',
        emails: ['rchhaya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHjw-IsxFOSyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723322168701?e=2147483647&v=beta&t=7_TZVrAF-7P3bEkbxipaLC-E3RF57kzulgrflRGbX7c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kate Hancock,',
        lastName: 'CPA',
        emails: ['kcpa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mukesh',
        lastName: 'Chugani',
        emails: ['mchugani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG-gQJp3VEkXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700808425544?e=2147483647&v=beta&t=jdIdWtpD6OlheMS0w2QycDP4cqH_-NtIxSD6i615FB0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cnp: {
    name: 'CNP Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mitch',
        lastName: 'Lemons',
        emails: ['mlemons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFRbJKJwTXiwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516283372239?e=2147483647&v=beta&t=htbdM7j-PJOlXkSyDu33IT9mCyE71_0Hc3ytdgYMLtA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Garrett',
        emails: ['jgarrett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEnSjgs2GhfZA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516266364569?e=2147483647&v=beta&t=A2mH__a2EDkkEw8Z_PL6uX-TlykJ-QcSylW0e4_kBCY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gresham',
        lastName: 'Worrell',
        emails: ['gworrell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH5DKPve4MHmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728479399478?e=2147483647&v=beta&t=8o02H1NbxBnWioCcV_v-dUaE_d6d4G5bfKn7nD5PdJs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeffrey',
        lastName: 'North',
        emails: ['jnorth@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Arnold',
        emails: ['marnold@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGjfGTXGCT9CQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567183388772?e=2147483647&v=beta&t=R4frC34Ol5MUqZddCybqoLLKL0RB_7fRc3f1zvNljmk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  whitepages: {
    name: 'Whitepages',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cam',
        lastName: 'Nguyen',
        emails: ['cnguyen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shomari',
        lastName: 'White',
        emails: ['swhite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQETirRWz_ksKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517673285526?e=2147483647&v=beta&t=n3Tawkd_f2vFCtb5wFwC6b8nP9J0SaX1kHxCDTQRAZc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tessa',
        lastName: 'White',
        emails: ['twhite@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'White',
        emails: ['jwhite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFha0fmP-uwOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663975014360?e=2147483647&v=beta&t=SQWBqY36-NnDQZCUSxSdTmrmxVG_gCZT58Raydha77Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joseph',
        lastName: 'White',
        emails: ['jwhite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFvoVeXk4ZyvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637597694672?e=2147483647&v=beta&t=3Brl_VkYyZ_pmNObYfp_fNzTRcbg5M97clhHlRCMU98',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  penlink: {
    name: 'Penlink',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Peter',
        lastName: 'Weber',
        emails: ['pweber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHXL8eV0NT22A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709732326842?e=2147483647&v=beta&t=91MZjzj8o3PyWWd2rxlYciGO02y_2K9tPHHEHLERKWU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kevin',
        lastName: 'Pope',
        emails: ['kpope@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFAF0OSsoQRPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695929236704?e=2147483647&v=beta&t=NXRAQDdAf0uLEiAQ0pxcoHlkkhP75h5sAalG7godlVI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Curtis',
        lastName: 'Jamison',
        emails: ['cjamison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG91Ih-ZRk7TQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516454231430?e=2147483647&v=beta&t=ql4x-eF_tjUD0IPP_cIQ00IbAff3j-fLgszWnf_Rbaw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Udi',
        lastName: 'Levy',
        emails: ['ulevy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGhv2W7J49J2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631732870848?e=2147483647&v=beta&t=YvVP7SxU911GD_K21HDZwy4AVQb6KLy8Zc6QdiR0cLI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stephen',
        lastName: 'Lerner',
        emails: ['slerner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eurovision: {
    name: 'Eutrovision Systems, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vas Narasimhan – Novartis |',
        lastName: 'LinkedIn',
        emails: ['vlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHOTWjUdF8u5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729247068172?e=2147483647&v=beta&t=vUQEovQq6ZUdWBo9j47pLzlNHn8bInkU33HK9MnGVxc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David Platt,',
        lastName: 'MD',
        emails: ['dmd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGxogDuHo0Sqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728075876166?e=2147483647&v=beta&t=uOo-75UlpgR2c0AuzJ-wbELyfF--0cVmANYBoVqBX5M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Victor',
        lastName: 'Bultó',
        emails: ['vbultó@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shreeram Aradhye – Novartis |',
        lastName: 'LinkedIn',
        emails: ['slinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHPK99GVpQlAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729252136369?e=2147483647&v=beta&t=5N6VoBWNg4m9pgqQuzOxtM0Z1DuO49LjxwTDgdk6Mpc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bulent Kiziltan,',
        lastName: 'PhD',
        emails: ['bphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGvdBwiuRzGaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569973762024?e=2147483647&v=beta&t=1czujYtuf_x09afh4Kt4wmMssyS3LFuB-zmR4WnlgVc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  starlims: {
    name: 'STARLIMS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Trey',
        lastName: 'Cook',
        emails: ['tcook@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marian',
        lastName: 'Veteanu',
        emails: ['mveteanu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH9udHP3IpkGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629503880913?e=2147483647&v=beta&t=gWmPvNP2B4TOWmzgy_h-VVCpUloubu-irbd1Pl_uKmc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brandon',
        lastName: 'Henning',
        emails: ['bhenning@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGQYtjuZcdZ8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663083636005?e=2147483647&v=beta&t=ceXBbxL6e7yk9WNuVz0O1iLAK7f5tKwqN-3SXV4xwDY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Peter',
        lastName: 'Tyrrell',
        emails: ['ptyrrell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEL9C3kOBZTTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516896542455?e=2147483647&v=beta&t=8blUPL-QZu6fuYAUEj-GGLMjT4nD1HgUlxWHFos5fyI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: 'McDonough',
        emails: ['pmcdonough@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQErq97rdGnw7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680010603171?e=2147483647&v=beta&t=6ZVTbkJZSI8bfFBH6PXfyB9mn8dGuJeSxI4X5q0pnMI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  miroxindia: {
    name: 'Mirox Cyber Security & Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daisy',
        lastName: 'Mirox',
        emails: ['dmirox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEDsz_WqjrNTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697215839215?e=2147483647&v=beta&t=Lbw26oDu26AgzSzt8ikl85j65NZpjhjqV2uluwwwCMY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'NEENA S',
        lastName: 'JOY',
        emails: ['njoy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rajesh',
        lastName: 'Babu',
        emails: ['rbabu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Athul',
        lastName: 'Nath',
        emails: ['anath@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'RB RAJESH',
        lastName: '.BABU',
        emails: ['r.babu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFdV14fmY7Jig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664847062834?e=2147483647&v=beta&t=MHpCzO1DH-1qKwh1HTCXf5V6zamvYpm21EcZ-74meoQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tiugotech: {
    name: 'Tiugo Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: '{{Jordan',
        lastName: 'Bergeson}}',
        emails: ['{bergeson}}@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'James',
        lastName: 'Lindrooth',
        emails: ['jlindrooth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFSlOaku1-lEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684169744401?e=2147483647&v=beta&t=7CfH1Bh4TjUYzpjCh8aufZpMUhj4nZ9JOXo0c50VbNs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patrick',
        lastName: 'Ryan',
        emails: ['pryan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHNVrDHt2zw0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696525835684?e=2147483647&v=beta&t=VAxJOIj4SMVSKg0Uci2GErlcziA1H4XqhJpb6diSdXE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Scott',
        lastName: 'Heimes',
        emails: ['sheimes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFHD45qOUOIFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642112911034?e=2147483647&v=beta&t=kTnQy5JIsg-epfugYzcPG2c50aQaeYLGD6cbe5N1Xzk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anne',
        lastName: 'McGoldrick',
        emails: ['amcgoldrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFE8KAHiH2Tcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666357591545?e=2147483647&v=beta&t=8M2A3Sv2fg7HsMjPtT4CZJHx9ytQ-dcx-WYe1CIXCjc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xobin: {
    name: 'Xobin',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amrit',
        lastName: 'Acharya',
        emails: ['aacharya@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Manjit',
        lastName: 'Kaur',
        emails: ['mkaur@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sruthi',
        lastName: 'Srinivasan',
        emails: ['ssrinivasan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aman Kumar',
        lastName: 'Tiwari',
        emails: ['atiwari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFBrsu-yQvvfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677739587908?e=2147483647&v=beta&t=rcNyWcrQwxhk1GbMNVytYI3q10pZm3v-5Ge6Ozyljbk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sarthak',
        lastName: 'Deshmukh',
        emails: ['sdeshmukh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEwPGISEqmRLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708329609196?e=2147483647&v=beta&t=uvP5eWH1m2KdSGuVGtyvSgYDF3MGsuoR0WaCcZNZDxM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  revizto: {
    name: 'Revizto',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Valeria Argüello',
        lastName: 'Brotherton',
        emails: ['vbrotherton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFg1cWWE80GPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676047772064?e=2147483647&v=beta&t=lQ-Q3fKP_nHg5Zvp8-FLp-IUtBdoI5O_xIgbOubVksw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tiffany',
        lastName: 'Willard',
        emails: ['twillard@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shaun',
        lastName: 'Hester',
        emails: ['shester@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGz1Bmf4CfsBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679327944773?e=2147483647&v=beta&t=XUp-0WrQyq2t_GeTin39UWvmV70aM2ztthSXDqkAlbY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rhys',
        lastName: 'Lewis',
        emails: ['rlewis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF4od6BzjiriQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712749384891?e=2147483647&v=beta&t=CTbe1Ug127t-7E9kninMbTEWYXrO004j5w0yjbfcIoc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anthony',
        lastName: 'Heller',
        emails: ['aheller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFv_nYtDCFEMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676051606794?e=2147483647&v=beta&t=xKK9z8LIKJg0sqcOQM-uO5NNNHh-SauOiCmDXlkctbc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  softwareone: {
    name: 'SoftwareOne Brasil',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Otavio',
        lastName: 'Argenton',
        emails: ['oargenton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEf67u8K4PltQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709053792485?e=2147483647&v=beta&t=LwUR8-8E2Jh_toPZggxBUc_anMScdO3b4L945Vyd-Xs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rodrigo Eleoterio',
        lastName: '(Rod)',
        emails: ['r(rod)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGhVBBSUL41Jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642072552016?e=2147483647&v=beta&t=D3rfl4Cgzk5OL3I63jzA0oFdK2OhvKdbnyphmWSko0M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eduardo',
        lastName: 'Castro',
        emails: ['ecastro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGaqwdbQ3ue5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727710186158?e=2147483647&v=beta&t=gD2XWXlnFLZnxdcHvJ8C8XcknQz389IdLAFOkJgtx5g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vinicius',
        lastName: 'Perrott',
        emails: ['vperrott@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nathália Jade Silva',
        lastName: 'Carvalho',
        emails: ['ncarvalho@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHpLr-w6AeHJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642433994715?e=2147483647&v=beta&t=F2j3GGsMeJ_L-TJVFO_H5m5ahFILxzdNZUVL8LbkLDM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  uzio: {
    name: 'UZIO Technology Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sanjay',
        lastName: 'Singh',
        emails: ['ssingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFTx43ThLi6Zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517736009557?e=2147483647&v=beta&t=EZgGKwGAY6HZWayjLEcWsPqgMKpDgymORgth1o5w3o4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sean',
        lastName: 'Bertrand',
        emails: ['sbertrand@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alysa',
        lastName: 'Billeter',
        emails: ['abilleter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFC08qNNzlCcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522081587853?e=2147483647&v=beta&t=5SJ27vq3PdjVXdiTnQpaQLQFBa5RQoeoYbjA-j6OCEA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ankur',
        lastName: 'Garg',
        emails: ['agarg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFisYnULcU8LA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546883369498?e=2147483647&v=beta&t=yridbW3ahb4Fp2ZH88iSAtVdKnaNDiqPTUk1Mun2gMo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ana',
        lastName: 'Singhal',
        emails: ['asinghal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHyd7bsvdIgbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618429197548?e=2147483647&v=beta&t=Mw9jkT8RMaD3a7uJuljzFfEXdV70b3cyPNQxwe2G0U8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  flux: {
    name: 'FLUX',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  artcomputer: {
    name: 'ART Computer',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Grégoire',
        lastName: 'Boisnard',
        emails: ['gboisnard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF4PrJwn-9a8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517530131546?e=2147483647&v=beta&t=JWZfQmiZauulHGVBmc7ZxvAUQoXiCezFQzWujDrLRVU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sylvie Dabbah –',
        lastName: 'HR',
        emails: ['shr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Claire Henneguy – Sales Director – ART Computer SA |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQETRF6QEhaaRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517937666926?e=2147483647&v=beta&t=gLNkth4FoRUnIU7STSks7qtizyOCvNeBcp5Bwz9oY6c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maud',
        lastName: 'Arrou',
        emails: ['marrou@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGmS2vtJ__hDg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1626786221082?e=2147483647&v=beta&t=v_FlebAzCS-Y6ybn9HWcKYqYaHHLpIRe7jZc6c1k17I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marine',
        lastName: 'LUBIÈRE',
        emails: ['mlubière@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHVb3fv__TNrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634216960641?e=2147483647&v=beta&t=F3_uKV-jlQuTk_yoUdAuVkNobUQj0XboWrKXzGl9ubo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tsys: {
    name: 'Pt Care Coord/ Project Manager',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brandi',
        lastName: 'Quimby',
        emails: ['bquimby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF2fJpn7D_bXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622002730634?e=2147483647&v=beta&t=IPbwBbv3aoibFJmx2GyfEC6VAljd0JYwtvJzt7yZppk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gary',
        lastName: 'Hellman',
        emails: ['ghellman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'rishi',
        lastName: 'deep',
        emails: ['rdeep@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Katt',
        emails: ['jkatt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sam',
        lastName: 'Maule',
        emails: ['smaule@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lytho: {
    name: 'Lytho',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Douglas',
        lastName: 'Thede',
        emails: ['dthede@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGI9b8n9md_bg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668167836206?e=2147483647&v=beta&t=nDVz2ayKgb_v-rg75MjWckLB8-xjzDStwO-Hw0vBHyc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brody',
        lastName: 'Dorland',
        emails: ['bdorland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE7BdPVgy2fEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623941358193?e=2147483647&v=beta&t=DvKJuc6BqPRkcaHaobRLaSYPmR8WU_qmfPgDNWLGJa4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stefanie',
        lastName: 'Hausner',
        emails: ['shausner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFWGvpAWb_sbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560344346367?e=2147483647&v=beta&t=jh_vDyNeG06XVrlhm3hKicpH4CqsjIkH_pPjYCA7hc4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gina',
        lastName: 'Selvaggio',
        emails: ['gselvaggio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFYxN2ZrcCwuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517002558211?e=2147483647&v=beta&t=2HlyzZsXp3DYkgv2WUWIMlTkYmHgzuBLSNhYOCs7N70',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Trauzzi',
        emails: ['ctrauzzi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHUT2YfCV8ouw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567955935254?e=2147483647&v=beta&t=s9nJlVfUTxqAz46bLtwxdzmt_nC6iSejOMJKktErEAM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ironsoftware: {
    name: 'Iron Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Richard',
        lastName: 'Spurlock',
        emails: ['rspurlock@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jacob',
        lastName: 'Mellor',
        emails: ['jmellor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHJ3BHq1Fi3gQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688360554372?e=2147483647&v=beta&t=OK04oY1FXc0r_ub-gEAsTxG5AKva3xzRRxrOv5nsq6M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aoi',
        lastName: 'Kamthong',
        emails: ['akamthong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGk_2JiHS2_8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715264557328?e=2147483647&v=beta&t=tdh46-CD2xYM7BAnIuY2Po2NYnpSXBMklWNV-7CryEk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nate',
        lastName: 'Varghese',
        emails: ['nvarghese@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFinfXa0lwtpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706532919878?e=2147483647&v=beta&t=F2io6bOdKYdZXdyBfS1wnVCY8fqxZmt7c8QmAZlvkZk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Thet Paing',
        lastName: 'Phyo',
        emails: ['tphyo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  iknowhow: {
    name: 'IKNOWHOW SA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Katerina',
        lastName: 'Fotopoulou',
        emails: ['kfotopoulou@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHMh2kSUCziYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721834168934?e=2147483647&v=beta&t=If8qNbKJ0qSUKZEvOxEGipNBWGtC4dCK0xEJ23T_Lm8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michalis',
        lastName: 'Logothetis',
        emails: ['mlogothetis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHWZmrSmnkr1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607772491139?e=2147483647&v=beta&t=OpKcswlMrTV6SmeOuXA7UHtHTIUhXhuqfYjqz8qeCIU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ioannis',
        lastName: 'Psomadelis',
        emails: ['ipsomadelis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHlJAnk-O1rUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704291435796?e=2147483647&v=beta&t=J8Y1C79pz53N7ceaN9qxusn_JeB053AnfNk0FDK43e4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Katerina',
        lastName: 'Pappa',
        emails: ['kpappa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEbsfHhufIFVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516493767716?e=2147483647&v=beta&t=qrSdqwu7UB1m_F76mNU4_pcwrvppaXb4FBro4kZ5148',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pantelis',
        lastName: 'Michalis',
        emails: ['pmichalis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHmF3bKzNC3zw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604555939739?e=2147483647&v=beta&t=y7jhQ5qxmbVkm_HRlgfoKUjl9Zd7Q5x34Ml07X-ExJU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  voxsolutions: {
    name: 'VOX SOLUTIONS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gordana Batas',
        lastName: 'Adamov',
        emails: ['gadamov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEcW1pUCd1cew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633652409967?e=2147483647&v=beta&t=312900atRx_Zp1JEnv_E7VJJAxL9XUOh5P6NGT7DJVw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Elham',
        lastName: 'Ahmadi',
        emails: ['eahmadi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQExdXMOAlogZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730281723637?e=2147483647&v=beta&t=RjZi8cyldicdGh8wBt3nDt0-sfuKPqwAZrIqfKYvBTE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Milena',
        lastName: 'Miladinović',
        emails: ['mmiladinović@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGwEY6u6bOH0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575992987244?e=2147483647&v=beta&t=vhiRFhcGW9KnZ2lBpptVsiTGCeMkzlQMaU48PXcSPKs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Konstantin',
        lastName: 'Milekic',
        emails: ['kmilekic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF1ZbwaGNteiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703778889717?e=2147483647&v=beta&t=trnL4gFE2ybiq7m4BPpEMv2ulryT7ncdS1tTAvAT7CY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: 'Clayton',
        emails: ['pclayton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ligazakon: {
    name: 'LIGA ZAKON',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Olga Taranova – Product Director – LIGA UNITED |',
        lastName: 'LinkedIn',
        emails: ['olinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEnBwFgdTSmMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618043488505?e=2147483647&v=beta&t=rTvjHmCu8EnZhKYUz2Adlyexw_sPTTd8TReUXFZ1m1E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maryna P. – Human Resources Director/OD – LIGA ZAKON |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anastasia Mishutina – Head of Corporate Sales – LIGA ZAKON',
        lastName: '...',
        emails: ['a...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFUFmhuslPgaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710176788286?e=2147483647&v=beta&t=jC9IUj91YN25wG8gFsdeDYujUKIvFTHVPKWTCCWmvg8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aleksandr Levchenko – LIGA ZAKON |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQErY6-xMX2uUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590320325320?e=2147483647&v=beta&t=JVV0qXShtnAptt9T4ielEyMXzo0viVNPaJ-LCZWhSRI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Igor',
        lastName: 'Gulak',
        emails: ['igulak@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  oneviewhealthcare: {
    name: 'Oneview Healthcare',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Fitter',
        emails: ['jfitter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFSc9ecdQfAFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531859667828?e=2147483647&v=beta&t=V3lCWFvhySfjeIXB1KldZmvwTyXBPmB0Fd2WXDdQvfs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Niall',
        lastName: "O'Neill",
        emails: ["no'neill@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGwsNfujZXwNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709304807904?e=2147483647&v=beta&t=qRJF1HIlE-d2c9jew66D_p9Mx2Lb-i3UM8x7RGMxL9U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachel',
        lastName: 'Broghammer',
        emails: ['rbroghammer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHMflCxlPCAOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682540568934?e=2147483647&v=beta&t=IwIeoQ1Rn__PtFdHy6Q5gdv2hKBae7JuYW37AVxtBhY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nichole',
        lastName: 'Fetterman',
        emails: ['nfetterman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGuGh2US0DLvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636466115952?e=2147483647&v=beta&t=DQyGPaDlCvJKUz00X9OGz7Qmhr_dSweN8jJIO3AeoCY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Miller',
        emails: ['cmiller@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  securityforum: {
    name: 'Information Security Forum',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul',
        lastName: 'W.',
        emails: ['pw.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Norman',
        emails: ['dnorman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sarah C',
        lastName: 'Taylor',
        emails: ['staylor@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paul',
        lastName: 'Holland',
        emails: ['pholland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFKCVRvO1nYpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631268652205?e=2147483647&v=beta&t=ZUKEnUUMRGaxVeFlva5mJ9HvwkNkxCW5Pj8fvFTwBLo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jenna',
        lastName: 'Lord',
        emails: ['jlord@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  excelliumServices: {
    name: 'Excellium Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ortec: {
    name: 'ORTEC Supply Chain Planning Americas',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Tuttle',
        emails: ['ctuttle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGhhh76jFsV4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516276214566?e=2147483647&v=beta&t=sCfsEjR_Pe0YSW4bECG3OTO5WsCZbWLtv26V8CclA8s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alison',
        lastName: 'Davis',
        emails: ['adavis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachael',
        lastName: 'Needham',
        emails: ['rneedham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH90cP5kvBIRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651526761944?e=2147483647&v=beta&t=m4VzQkYu_3HT3k716RbN8XPicPlVT-lRemga6hAZtlQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Melody',
        lastName: 'S.',
        emails: ['ms.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jason',
        lastName: 'Wright',
        emails: ['jwright@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHVA4HLdQtjVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1565655712449?e=2147483647&v=beta&t=59GOalT46eN2rY6Amz0Zct2Q2BFn4ADcS3LAdyIuckg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  upclear: {
    name: 'UpClear',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Thierry',
        lastName: 'Soudee',
        emails: ['tsoudee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE70XMW-BPcSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675894278761?e=2147483647&v=beta&t=9uhvBkthQk6Aw3WWf-LIx6SqclculVxj83SMqeiupak',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Craig',
        lastName: 'Giannantonio',
        emails: ['cgiannantonio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFkqURSUys_6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650412018915?e=2147483647&v=beta&t=IolJIYhWXXXC4HWgmcT9crX0Y7fayY1smeJtk4MKi4Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Max',
        lastName: 'Walker',
        emails: ['mwalker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHlJ7e4ur9CjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595502970434?e=2147483647&v=beta&t=TNRxfy0gH22fhUubMtZ8r3d4qNaafykWj-cye2koijo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jean',
        lastName: 'Hong',
        emails: ['jhong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGgbbDzjGUWRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521488819496?e=2147483647&v=beta&t=05St_5CAm4we4YyFdrVHrWJu02gZWeW5Hxx2TGl_TqI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'Dentinger',
        emails: ['adentinger@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  blockcerts: {
    name: 'BlockCertsAI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tim',
        lastName: 'Vasko',
        emails: ['tvasko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEjPYDB7XSFcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516235752873?e=2147483647&v=beta&t=mF87BxaYnsIVg2iulVfGteoFH9hD70ggb9JC20P_zlI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Gerrard',
        emails: ['rgerrard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGqiLcXgT7vRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517702033508?e=2147483647&v=beta&t=qyr6vnSk9QoetbL5mp2TCp9xI8F-pewjSPnGcR3lL18',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: 'Stewart',
        emails: ['astewart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG8VgR36cE2Sw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561318094310?e=2147483647&v=beta&t=SNjx8ln4BXV-6HrnWCAzrckksZUtNQUrUXHKKyJ2gtc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alec',
        lastName: 'Parsons',
        emails: ['aparsons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFHN55-HToOcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649173256747?e=2147483647&v=beta&t=pPZPn9heY-8m-R8S94pd7rvu6QZ247I1zZq1kOGlQMc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Gigliotti',
        emails: ['mgigliotti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEcwg_r7nHN9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516233636658?e=2147483647&v=beta&t=8yW7IqSWnTrfHbI3Og_tH4z0VCZJlz66df5wRd95JaU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cue: {
    name: 'CUE Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Melanie',
        lastName: 'Levis',
        emails: ['mlevis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGIsEF3kVzkXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671666776137?e=2147483647&v=beta&t=HnTH73-HMPBYXvZNHWWHpER5wzQrJQvdCPYl8l6MJaA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'ROD',
        lastName: 'LEVIS',
        emails: ['rlevis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGucE_kJx51MQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678147552139?e=2147483647&v=beta&t=iB1dQJsdx9ctd1_defcF7uOch_nB3DtqCi7-QhvtjgU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Philip',
        lastName: 'Cue',
        emails: ['pcue@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ash',
        lastName: 'Raman',
        emails: ['araman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFX_OvHmx-O6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718782139558?e=2147483647&v=beta&t=B6faW6KbhT6w4_IzVQi5XLFeIstqwrq0cvSSQlTclXA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Julie',
        lastName: 'Yeo',
        emails: ['jyeo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFCx-NRtyhWtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517048138039?e=2147483647&v=beta&t=OFMlLuVX7lvQA2td1ospkUfKKgn2u9J8w0xZ53I6YGk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sqreem: {
    name: 'SQREEM Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ian',
        lastName: 'Chapman',
        emails: ['ichapman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEzEyi3wLX7sQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641538516106?e=2147483647&v=beta&t=z19I0BoEslDRoPUq8pYsQT6WqsKF-RA3GcDJviYSdg0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rene',
        lastName: 'Raiss',
        emails: ['rraiss@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nisha',
        lastName: 'Desai',
        emails: ['ndesai@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Robertson',
        emails: ['mrobertson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mary',
        lastName: 'Kay',
        emails: ['mkay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHT-P3TIVQozA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655693054214?e=2147483647&v=beta&t=oO1qtsH7jnKQ4ODpfhpke_QzruWnJ3yLc7sCUGbtors',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bemyapp: {
    name: 'BeMyApp',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Justin',
        lastName: 'Langley',
        emails: ['jlangley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFrMxiP-D02qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1576685740812?e=2147483647&v=beta&t=GnPrbje9o4i4qC_z6jHCxoPeYFcV2VG428WfKfVXoWA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alison Granger',
        lastName: 'Ganansia',
        emails: ['aganansia@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sydney',
        lastName: 'Collins',
        emails: ['scollins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF4MnYb6I6SCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641501358899?e=2147483647&v=beta&t=6HAw_gcV3uetlRGfnq3QH4TSTMbZIvwKt8UyJxVggX4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rhianna',
        lastName: 'Martin',
        emails: ['rmartin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGwv3MFioiVuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650392591763?e=2147483647&v=beta&t=9FyO49bSDxClLbjVfGF8t-8zLpbY4kHiWEs2nsD5T_8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ram S',
        lastName: 'Machiraju',
        emails: ['rmachiraju@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGrXHErm_3QzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694742029564?e=2147483647&v=beta&t=48bs8RGc0RcvE-yZc_6EZEkXNZnGA74SZdow-ORW47c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  weCom: {
    name: 'We-COM',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul',
        lastName: 'Fleming',
        emails: ['pfleming@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF0XD17xpkzMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725567848862?e=2147483647&v=beta&t=SnIGsQJO6lp_Lx4zQOD30UcYvfdJq7eLbOAABfvzoP8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Strider',
        lastName: 'Denison',
        emails: ['sdenison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEpb-tQPDUIwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644009634318?e=2147483647&v=beta&t=LFkLH9MJOeE2qVKoSHIaMcR-lzxEcAWdnl3qxCx6Q1w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Keem',
        lastName: 'Ibarra',
        emails: ['kibarra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFXzYNomSsuXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721260802255?e=2147483647&v=beta&t=DOSq7efa9g4kgO3VcsJNppCBbGqCoi5UJu45N86GGHs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kristopher',
        lastName: 'Minnich',
        emails: ['kminnich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH3H_7pVhaU6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722022123541?e=2147483647&v=beta&t=UH2CSqKWNUCLw753A4y9dSksG2CvTu_j_N8OuOZ7vNk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kevin',
        lastName: 'Heward',
        emails: ['kheward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE-fQwxEq-EIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517400750347?e=2147483647&v=beta&t=hqv8SfvW-TRgxDD3LKnpxDhChovy9-ZnTK5f65jzvu4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cobwebs: {
    name: 'Penlink',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Udi',
        lastName: 'Levy',
        emails: ['ulevy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGhv2W7J49J2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631732870848?e=2147483647&v=beta&t=YvVP7SxU911GD_K21HDZwy4AVQb6KLy8Zc6QdiR0cLI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Avishai',
        lastName: 'Abahel',
        emails: ['aabahel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGeag1FtvX8Aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641550079659?e=2147483647&v=beta&t=G8FPl4gOc5meTonBs5cLv7a7SZUiFTtMVcJLZ4FWwsw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Moti',
        lastName: 'Raz',
        emails: ['mraz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGLsh7J7PWJeA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517723344897?e=2147483647&v=beta&t=pyZlVy66Znr-b9Yg5IYdTP6PKm6R1haOvzW7WyRt7lU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daphne',
        lastName: 'Rosario',
        emails: ['drosario@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEoIORjFVX0WQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544131857085?e=2147483647&v=beta&t=n0dbhKs3DNtAUuCyRRHQ1OQmd808Flcgp4lfwZ5NKl8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sharon',
        lastName: 'Damti',
        emails: ['sdamti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFZ6LfyMub_tA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686727423638?e=2147483647&v=beta&t=8f612M90AtOuVvjAHBAlbwW-WnqV-NfmdJRvGvANrNE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bostonserv: {
    name: 'Boston Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Boppanda',
        lastName: 'Ganapathy',
        emails: ['bganapathy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEampYzbRQ_zw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567543452085?e=2147483647&v=beta&t=72izXBH-aBhdWnNMa9_lCEdDYkbNvj8_EJycTiOV00o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mohamed',
        lastName: 'Sumaid',
        emails: ['msumaid@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chad',
        lastName: 'Johnson',
        emails: ['cjohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Olivia',
        lastName: 'Conti',
        emails: ['oconti@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Brazie',
        emails: ['jbrazie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGWMBKvHSvzrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517279970855?e=2147483647&v=beta&t=Pr3CSA9N_5qARwPMcCZGQXTuboi1_2ZkmbQn_HNQaRA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  clevercraft: {
    name: 'Clever Craft d.o.o.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aleksandr',
        lastName: 'Saraykin',
        emails: ['asaraykin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEtHI6lIT2uAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580403723569?e=2147483647&v=beta&t=prDnzzoOM9ULu02DfYzYDgMQbISX62WHcpEPGoNAPNk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sergey',
        lastName: 'Rasputin',
        emails: ['srasputin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGLUF5tYEww9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632893247525?e=2147483647&v=beta&t=kT4cC0KzU7stah59YEZCyV1T9EtHrC8pL7l11gXh3gY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pavel',
        lastName: 'Smirnov',
        emails: ['psmirnov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Svetlana',
        lastName: 'Sidorenko',
        emails: ['ssidorenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHUvABDGWvB3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716181001602?e=2147483647&v=beta&t=iGvPVevX_fjAx6xGWiBHihMlYZGH9KsC4-vFwL0hoVI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daria',
        lastName: 'Shlepkina',
        emails: ['dshlepkina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGyPTAMetUZTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694618072587?e=2147483647&v=beta&t=hnQbydgfeUkqWlzAHpliZTSo-O9kbPQ6tqb7dGjd-Hk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  asdWeb: {
    name: 'Advanced Systems Design',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'William',
        lastName: 'Thompson',
        emails: ['wthompson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGR6JFPYE2y1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517503766087?e=2147483647&v=beta&t=0qgv07Jp_X8G2Pe3DAQwgyErbGO-kVGNiQ1pjC9BqKU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Warner',
        emails: ['mwarner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEJtcQ_t8HmFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516323551162?e=2147483647&v=beta&t=xW35b3WnyEqm-4Vtcqdt6LUC2g8uWCYZi2QHS-tEuPA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anastasiia',
        lastName: 'Kostiuk',
        emails: ['akostiuk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFaPxyJwngxXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718904872456?e=2147483647&v=beta&t=O4AsMLwoiZqIt2HOn25FzDDQoAbw-ag4ViWkWLeooIU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'DuBard',
        emails: ['jdubard@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Will',
        lastName: 'Brookes',
        emails: ['wbrookes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFQuIPCNrIp7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721825222997?e=2147483647&v=beta&t=nblJ_xpNiIEzUhWTnkZttXfv58KAf_w87XDdEAytRso',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cadenceGroup: {
    name: 'Cadence Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bethany',
        lastName: 'Brown',
        emails: ['bbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEml3yJ0FGmsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517740299327?e=2147483647&v=beta&t=j6UHm_cZ08d0IuC7q_1d4VFEAkqOno8EyNwf4MG_TEA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marcus',
        lastName: 'Durand',
        emails: ['mdurand@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Conant',
        emails: ['mconant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFKL33hH4u79A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578437002084?e=2147483647&v=beta&t=8M96jq_WaZMy-PCt81PziRavuJLeyISAqYSOKEiOnNU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeremy',
        lastName: 'Caudill',
        emails: ['jcaudill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGq_3fINIddwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516356130014?e=2147483647&v=beta&t=9E3wURbUpe2aw2J9Fj8J91LK3M08ezZxtXQywrEAV8s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chad',
        lastName: 'Damerell',
        emails: ['cdamerell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQENrndAnOo9BA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517677713585?e=2147483647&v=beta&t=dwGP5dbT5-VCxD1UC2dM22QkM9Wc9SfBrJAQeA_pbik',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tgsMtc: {
    name: 'Technology Group Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lenora',
        lastName: 'Payne',
        emails: ['lpayne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGELSZc3OFldA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618500231063?e=2147483647&v=beta&t=JXmU8pCToM657KC9fkRBnz40Mwo8OXRWLURDVTdvdgE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bert',
        lastName: 'Soto',
        emails: ['bsoto@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lucas',
        lastName: 'Behrens',
        emails: ['lbehrens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHeyhD2YxqL-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516930058909?e=2147483647&v=beta&t=Vo5OSH_rKGq6Dj7nb8NLJQTgPJq9OK9XAKJpyurtT6Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Megan',
        lastName: 'Ellsworth',
        emails: ['mellsworth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHZcSAwbkXzTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656607757675?e=2147483647&v=beta&t=n2Bmjj_JB6OO00JUygdmQ0KcZYujX6DvM63g3U3lZwo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shaun',
        lastName: 'Lavish',
        emails: ['slavish@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHG2po857HDSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698708395682?e=2147483647&v=beta&t=q9gRytoF9QLYQsChYQIHJnibKZGz-CFE2a2Hm0n9n8c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aconsol: {
    name: 'Alackrity Consols',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Madan Mohan',
        lastName: 'Shanmugam',
        emails: ['mshanmugam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFOiX4E_rnC9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1540208450964?e=2147483647&v=beta&t=VrynHYdXtmoJLUMIdxfIXvHwN1GlYJpH7rUcJcc9__Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kandra',
        lastName: 'Sandeep',
        emails: ['ksandeep@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQF75XeqhFMl0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1528393469890?e=2147483647&v=beta&t=6vy5Jl07kcCP_E_7ns72u1S-cMNui8eq0hZ16CuD1jU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Harish',
        lastName: 'KM',
        emails: ['hkm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Arun',
        lastName: 'Raj',
        emails: ['araj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEK0byeMqL0yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567754711234?e=2147483647&v=beta&t=bPJTLDB6EAml8IGiNFITLs44WYQ_-1crVbZlarNMeLg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kirti',
        lastName: 'Madan',
        emails: ['kmadan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHL_pPTZaPEsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592911739728?e=2147483647&v=beta&t=1EUREzp_hKbm2J3QZanirzcpfVanpc7QnRHVIj8H81Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  promt: {
    name: 'PROMT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cachengo: {
    name: 'Cachengo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ashlee',
        lastName: 'Young',
        emails: ['ayoung@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Huseyin',
        lastName: 'Aksu',
        emails: ['haksu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGLnbNNDLhnFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708132589527?e=2147483647&v=beta&t=yoPh3GU-fDObTp8vcQsO5233LNGXh7dyrzYfUFr3Miw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Perry',
        lastName: 'Hayes',
        emails: ['phayes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEF3lmsgM1L8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1576706374417?e=2147483647&v=beta&t=4NGlrmbKolsJ7CvJkRORtgrjVEB8_78MHLlNzWvVn48',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dmitry',
        lastName: 'Lipkin',
        emails: ['dlipkin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHVOvg6LpOxzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605820887892?e=2147483647&v=beta&t=UtIZ1bK-FWIvrbAl729oyZAstpW2jHEIJx3TswEGIGc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Janae',
        lastName: 'Young',
        emails: ['jyoung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHgL_TCXwHtyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677113162634?e=2147483647&v=beta&t=HUmRP6q5_0j_mKvaAdLmI9JLG_OQJWfaTzJukBlxmdc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sroniyan: {
    name: 'Sroniyan Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chandan',
        lastName: 'Singh',
        emails: ['csingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGSF0iHWybtEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710439516092?e=2147483647&v=beta&t=nf1dCzv4cGean7QixZlIJjYAVN8ppl21YXKz6nTW57w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hemlata',
        lastName: 'Gupta',
        emails: ['hgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF41Dmaf5UsNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696682015491?e=2147483647&v=beta&t=i6ja-4OpXBCXJdi7CHIqo5lPOVepSyBaSYkH58UAEJM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sem van der',
        lastName: 'Worp',
        emails: ['sworp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEJJJunje_Ydg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726226072473?e=2147483647&v=beta&t=lfG9YM1q4XC4vn-SKzU0tHnMrEV86LODuT_UUp8V9ao',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eduard',
        lastName: 'Danielyan',
        emails: ['edanielyan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Janith Hansidu',
        lastName: 'Yapa',
        emails: ['jyapa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFqr5c0K4KAxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726063718826?e=2147483647&v=beta&t=e1FEtcml1ZkqT6zw7-QYtvu_A4P8rdEpQJoQSCyIFAo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '4bell': {
    name: '4Bell Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vibhanshu',
        lastName: 'Jaiswal',
        emails: ['vjaiswal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHIQYY7IebYZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688046990960?e=2147483647&v=beta&t=G2XJX2qBnUzIoqdXV_SFB_bqhJ05EkWZ9ZzxQyajcjM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shobha',
        lastName: 'Singh(she/her)',
        emails: ['ssingh(she/her)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFNpansfla5vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726629739453?e=2147483647&v=beta&t=-t38uBxmirV8hFlMFbpK1XetuJ6KQvTR07R8-IzHeRU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vishakha',
        lastName: 'Kapoor',
        emails: ['vkapoor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQENFZeXfElmDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648706385160?e=2147483647&v=beta&t=DnpBIqCkJarLuBBC5AcT2KbA7zNfsQYZzTZ60UmBeJU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Arjun',
        lastName: 'Singh',
        emails: ['asingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFX44s_1Nv00w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689928190727?e=2147483647&v=beta&t=K4t2IHsu30tC7eclU4hvC3YXhmZ-mVqgKmyT1Bnbr6k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aparna',
        lastName: 'Bisht',
        emails: ['abisht@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEuGZ9c6Olq8Q/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1675922717646?e=2147483647&v=beta&t=XtheACbh5aWo_3MnRwvo3vo3tamtM36i_7HqGb4PSp8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  psnpl: {
    name: 'PERSISTENT NETWORKS PVT. LTD.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pritam Kumar',
        lastName: 'Guha',
        emails: ['pguha@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'shradhanjali',
        lastName: 'panda',
        emails: ['spanda@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rajesh Kumar',
        lastName: 'Behera',
        emails: ['rbehera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHTJaU5ZflY_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604898731422?e=2147483647&v=beta&t=uNB2Ei9zVF0_jEea1nG8Lk1u6Pv93wwr6Wp9kZrMH5E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Deviprasad',
        lastName: 'Nayak',
        emails: ['dnayak@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paolo',
        lastName: 'Corchia',
        emails: ['pcorchia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF79RlAq3H6ig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721991057703?e=2147483647&v=beta&t=EBmtBVoEX3rW-fcoNGEDQHFmLN45oZv7wjSCGKjsWfU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aehltd: {
    name: 'Antelope Enterprises Holdings USA Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Spyridon (Spyros) Georgakopoulos',
        lastName: 'AEHL',
        emails: ['saehl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEANcCJbioE3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704613977880?e=2147483647&v=beta&t=IOHuAvFiypp5zQYT0H20g5hKsWt1IzT7P1aSKtZTYzE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vanessa',
        lastName: 'Aehl',
        emails: ['vaehl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGXhlToCyhOng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721061397010?e=2147483647&v=beta&t=AzchQ8XkMBIs2XYVF0p-FgmLURQt9eTg_bieb9QCouA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dan',
        lastName: 'Aehl',
        emails: ['daehl@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jacob',
        lastName: 'Aehl',
        emails: ['jaehl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEeRFeb6svRvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718311228099?e=2147483647&v=beta&t=pMXfgrJ0-E1WdKuoytwCBVQooy6_M3zvdhUr5n_i21g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bernard de Villele, AEHL, FIH,',
        lastName: 'MIoD',
        emails: ['bmiod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHdjINS_M3IAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517718246241?e=2147483647&v=beta&t=OclotWJtKUNfo3cbcjXT_7xfdqrkFZKG23gojcxTaYs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ibs: {
    name: 'IBS Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aguibou',
        lastName: 'Ly',
        emails: ['aly@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Haoussa',
        lastName: 'Ly',
        emails: ['hly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQERpJxHeKC3Jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588482028970?e=2147483647&v=beta&t=nMcD_5j91wVMLAuc9EogNP4bed84SenkHVCJh_w8k50',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Robert',
        lastName: 'Tavi',
        emails: ['rtavi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yusuf',
        lastName: 'Ali',
        emails: ['yali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF_AHpSWQo2CQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517544608472?e=2147483647&v=beta&t=agP-IAzPuOjp5zvH5y4jsPiDoXcENZkb6BPbDS_ofFg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'olorunshola',
        lastName: 'francis',
        emails: ['ofrancis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cosmictech: {
    name: 'CosmicTech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Praveen',
        lastName: 'Choudri',
        emails: ['pchoudri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFCEPpmSrKUdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595235643315?e=2147483647&v=beta&t=38ze6YhILFAXlmt-ginsrz3zsovoW2oYFrAvE2mlEfE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tej',
        lastName: 'Kumar',
        emails: ['tkumar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aditya',
        lastName: 'R',
        emails: ['ar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGzUJIPtuFxsg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689523554267?e=2147483647&v=beta&t=tG3t7_yyeW7-S1AbI-bqAFTmq2CRA_urQyO5D5Qpxv4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'cosmic',
        lastName: 'tech',
        emails: ['ctech@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFFy4kA9CeP_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573814141147?e=2147483647&v=beta&t=zWLsbAxxPlpedcIaMIVIG1VjD2uuXk7VKkHtodzqMPI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Surender',
        lastName: 'chary',
        emails: ['schary@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEz94rrkt2R3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579763715708?e=2147483647&v=beta&t=evrGV0dN364r-iVL90Lwk6Z5OGI_KG2NfzBJgcL58lA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  anaghtech: {
    name: 'Anagh Technologies Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Niraj',
        lastName: 'Patel',
        emails: ['npatel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Niraj',
        lastName: 'Patel',
        emails: ['npatel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tom',
        lastName: 'Gera',
        emails: ['tgera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEFKsJX9e9vWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667904699246?e=2147483647&v=beta&t=M1BsPyNStb0W74-q26ynGL87on-UAMJg1M3kwigxT-Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Prakash',
        lastName: 'Tiwari',
        emails: ['ptiwari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFz9h1-ENzSWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672394390235?e=2147483647&v=beta&t=b1Ourt9fx1CDbZE7j9v-oVs3v6dXMWC7DblPEWAffgA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kiran',
        lastName: 'Bolaj',
        emails: ['kbolaj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFMdZy7MZ0BuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697941452293?e=2147483647&v=beta&t=348wXp1lW3g-MfR57JxXgeLud46RvwJHAF4Ut35WKhw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  enprovera: {
    name: 'EnProVera Corporation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Elizabeth',
        lastName: 'Christian',
        emails: ['echristian@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jake',
        lastName: 'Wilson',
        emails: ['jwilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFTQPxSFmyhOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726462355368?e=2147483647&v=beta&t=7lCRO7lhOntOABiOTCeLqyat5iJYpHhfxGwjmn9VZRg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jessica',
        lastName: 'Cothran',
        emails: ['jcothran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHCOAKO5JnS1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543724494654?e=2147483647&v=beta&t=ZR3psDn0bANc3sQd-Cb7cQ8m57TKNsliRk7qnAurgH8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sharone',
        lastName: 'Townsend',
        emails: ['stownsend@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHHvsbCdPz-Rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643292857748?e=2147483647&v=beta&t=9XNK5fYlROmjM9fiXI82w0tiXI0KjyUVH5zodlQymaM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dianne',
        lastName: 'Grant',
        emails: ['dgrant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHVB0gS6Ujy7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591040413500?e=2147483647&v=beta&t=q9NIdKXl_QcoeyB5QcIa0TJ6locJObVyGJEiNG7B7kQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  monroetownshipnj: {
    name: 'Monroe Township, Gloucester County',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James DeHart, MAS, NJCEM,',
        lastName: 'CPM',
        emails: ['jcpm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Debbie',
        lastName: 'Bender',
        emails: ['dbender@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGVrhMiTFDS1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516872017905?e=2147483647&v=beta&t=JHEmgy5YRR_n8YXJdbjvISCBleYliLHOp6kMtfqKCTM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ninette',
        lastName: 'Orbaczewski',
        emails: ['norbaczewski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHhgZc4JbWw5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552208400079?e=2147483647&v=beta&t=MmPcRY8dseHTU4o6qyH6V4MQlr5bW-xmn9lGS06w5yM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Donald',
        lastName: 'Darcangelo',
        emails: ['ddarcangelo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gregory',
        lastName: 'Wolfe',
        emails: ['gwolfe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHiiRFzwkWgkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672583429730?e=2147483647&v=beta&t=dv03sR25gh1ShRLpHHBL9pmY_mk6NWGqkzOYGReW7AY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  quilogy: {
    name: 'Quilogy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Peverill',
        emails: ['mpeverill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFyQuqXxYDkWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516421766637?e=2147483647&v=beta&t=8l46fsfjf76l5YUVbKc6_oT1WEMvTIY0AhpP3y6xD_M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rick',
        lastName: 'Kight',
        emails: ['rkight@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFxUr7ehXR82g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558621940529?e=2147483647&v=beta&t=MrZ2Q_HuO_lPOHjyzBCn-9lsRGMyEefKhBDRkwx769s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brett',
        lastName: 'Gibson',
        emails: ['bgibson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG9vv2FbJfotQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516314850002?e=2147483647&v=beta&t=YsOgH6eQNSfYjkvcHKpvSvfxagLmP8BN_EjyUpxxfJg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'R. Michael',
        lastName: 'Querimit',
        emails: ['rquerimit@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dan',
        lastName: 'Hugge',
        emails: ['dhugge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGE9i3uw5RcgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517653679501?e=2147483647&v=beta&t=NrTzGft3HPuY2RcUaCJ434b372g2NrPX3aIiDPKpjAE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  singlesourcesystems: {
    name: 'Single Source Systems, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marty',
        lastName: 'Rhodes',
        emails: ['mrhodes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHnbvdD-gcdyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626986146719?e=2147483647&v=beta&t=HBT_hGIdX-QerFkPQwjjdiLtLmTaMm-cF5jDFyDwXlw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tom',
        lastName: 'Cavanaugh',
        emails: ['tcavanaugh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mike',
        lastName: 'Romeyn',
        emails: ['mromeyn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sandra (Ceniceros)',
        lastName: 'Rhodes',
        emails: ['srhodes@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Clint',
        lastName: 'Aswegan',
        emails: ['caswegan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHqoxSsbDqyGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701276512251?e=2147483647&v=beta&t=OLBhoym0DCqKk68lRNFyBGIO9gYyRBiu8Ccya5aaPLc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  boc: {
    name: 'BUSINESS OPERATIONAL CONCEPTS, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Carrie',
        lastName: 'Armstrong',
        emails: ['carmstrong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEMDx7Y1jqdJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660589123562?e=2147483647&v=beta&t=JqR8NmkCG4fq03eqxVtZnh7R2f8PwcWpnQGjBcmibqU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'Ketler',
        emails: ['aketler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFZhB8z8mR1qQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658367090819?e=2147483647&v=beta&t=VSt-S5_P_JGkT5byHgdRkYbEegYPNVBCn4kAAgUJjFY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brian',
        lastName: 'Burroughs',
        emails: ['bburroughs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFeBlymto_OTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516737233327?e=2147483647&v=beta&t=SF8flZokyLFTdFAtACWdva9tc3hZjL86deBS9CWTNXg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marisa Jackson,',
        lastName: 'PMP',
        emails: ['mpmp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joshua',
        lastName: 'Hardaway',
        emails: ['jhardaway@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGDBJqEGtP4rQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550433144164?e=2147483647&v=beta&t=SsUJVXrBgIu4q7gV--G6DT5b_RopGOO1fkh6Tfl6HoA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  open: {
    name: 'Grupo Open',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jesus Demetrio',
        lastName: 'Velazquez',
        emails: ['jvelazquez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG3Dcv1SvoxpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516795368319?e=2147483647&v=beta&t=DzU2HkJ6rr0pm_R5RNx1KRp53a6WsFSqyLgK6pcYYk4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jean Franco Di',
        lastName: 'Gaudio',
        emails: ['jgaudio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGoORwkaAv4Tg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586141473874?e=2147483647&v=beta&t=5TmIVb9yAZrWCqjdFZkl5GfNQ5K9qGJZAB9iMS5PiOM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Luis Lomba',
        lastName: 'Martínez',
        emails: ['lmartínez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH249PQlG5Zdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674680162939?e=2147483647&v=beta&t=YT-2Qy09bRLzNCvliqVGywc6RrOvhdA5X3kjYAwvCB4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Duda',
        lastName: 'Slawek',
        emails: ['dslawek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFpeSB2oQpybA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593674165969?e=2147483647&v=beta&t=U0h9fS-WdaxcQVwbuDZ6pxvazYWYm_rAZihcPqYehMo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Leandro',
        lastName: 'Calcagno',
        emails: ['lcalcagno@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGxSkU4AcGpLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617753127635?e=2147483647&v=beta&t=Xe2WAaUlapzlJ4LoPGGsaK-G5mjkuiPjTB4x94svf_M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  altares: {
    name: 'Altares Dun & Bradstreet Netherlands',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  castordoc: {
    name: 'CastorDoc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tristan',
        lastName: 'Mayer',
        emails: ['tmayer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Louise de',
        lastName: 'Leyritz',
        emails: ['lleyritz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH5NveZUwT3bw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697808451807?e=2147483647&v=beta&t=UUI49vu81SF6dd_CdhkBBf0YiRWA01riCXZhXtughEA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Hugo',
        lastName: 'L.',
        emails: ['hl.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Martin',
        lastName: 'L.',
        emails: ['ml.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGCn7VYhq0FgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686422255951?e=2147483647&v=beta&t=fHor4NuIuaDjBYsn5O4htRoUS_uu6Ccg8BMvvduFNP0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ovidiu',
        lastName: 'Bodnar',
        emails: ['obodnar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE3KpaoXKc-xA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651655255961?e=2147483647&v=beta&t=FYH0OxpR_YPaTRu8xMT6s2vveoQdSF0hMG0ynvapJjg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bornan: {
    name: 'Bornan Sports Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alejandro Allende',
        lastName: 'Casal',
        emails: ['acasal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE8UEtOH3a_MQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729328756239?e=2147483647&v=beta&t=LbLQhE4ln_3siXWuIDWF7OjXyZ4m8_sn8f5YEI0n7NM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marino',
        lastName: 'Chorro',
        emails: ['mchorro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGJMQc4ShiGQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718258113637?e=2147483647&v=beta&t=ae9zSVXj98dx2ihUbeTTQ3-okSjXkJQR6VmjppYq4zw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jose Roberto',
        lastName: 'Gordillo',
        emails: ['jgordillo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEz0Fkai04ylA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664790557619?e=2147483647&v=beta&t=gR8SFKwOt63nipRkzMlM9GsuRC0deSxN8c3mbrzbeuk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Haozhe Gao, PMP – Project Manager for Harbin 2025 Asian Winter',
        lastName: '...',
        emails: ['h...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHzcTKx4hb__w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699353738620?e=2147483647&v=beta&t=2o0bovJMUrcKHFATZEK02lzAirWhmXpdoAs2iBAtUKU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Fabricio',
        lastName: 'Lanza',
        emails: ['flanza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHtLlIV-OkOdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693252954980?e=2147483647&v=beta&t=DRPbSDqVypLpLS8-8MXTDKFFTpg5vyPAIdeG6r4BsoA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  acxiom: {
    name: 'Acxiom UK',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jed',
        lastName: 'Mole',
        emails: ['jmole@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHxP_D6ZrFYOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715680035793?e=2147483647&v=beta&t=ds_JmWne5Gq7sF4jQbJjv9iNQe89Sbfux4MnoatvXF8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Neil',
        lastName: 'Plant',
        emails: ['nplant@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jo',
        lastName: 'Robson',
        emails: ['jrobson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEUpbXZQ53uJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683296730716?e=2147483647&v=beta&t=wGimaF2aDh5xuoyPQld28gvKEL82QgoGc9RgPlSwZx0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dimitrios',
        lastName: 'Koromilas',
        emails: ['dkoromilas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEd5iZusXDWGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662382570312?e=2147483647&v=beta&t=jJ1dbAi1YewIzV7IgR6BKST_X67SQTK-LGs9KWb-8EQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alexander',
        lastName: 'Hazell',
        emails: ['ahazell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF-_l6KicZd9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592250285339?e=2147483647&v=beta&t=aGHRNetb2rW3U7mt_z-wKcqX8rc7oUSYfj6TzJrCuWI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  crystalcleartec: {
    name: 'CRYSTAL CLEAR TECHNOLOGIES, INC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Crystal',
        lastName: 'Culbertson',
        emails: ['cculbertson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE451idzRC2oA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517617430870?e=2147483647&v=beta&t=g9IW1-5T6jfs62y9T0KpKi8N0LdSo1zWFzGoTUXvBPc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Philip',
        lastName: 'Stalford',
        emails: ['pstalford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH9cbGRVMoPVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567630110701?e=2147483647&v=beta&t=vv2rEo-Mg9jBUnxvLeTu_1hD5f0dSF9ARi7HGQd29qY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'Christensen',
        emails: ['mchristensen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGcPoCxYoT3QQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516213705513?e=2147483647&v=beta&t=nmT7GtbRDFSj9KJGfMTMVxQ8T8_mKzjwoj8x0pjQXxo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Quinton',
        lastName: 'Obrochta',
        emails: ['qobrochta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHDRBxTS__fDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685421324333?e=2147483647&v=beta&t=FqVxdcKptqm4sym7TEPQnHVPhskzv-83VJ_zM3OM7cw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kallan',
        lastName: 'Casey',
        emails: ['kcasey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGX0U3W3qIUAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1565895983390?e=2147483647&v=beta&t=e4DSCyfhPplX7Wqh8fXwzSLoGB4NmvUOcBNgL7SeWkY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fujitsu: {
    name: 'Fujitsu Caribbean',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mervyn',
        lastName: 'Eyre',
        emails: ['meyre@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG7NQQi5iaaSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698290229357?e=2147483647&v=beta&t=R59sDDBMifK-brOMmSsJ9QJC_46RK0u7BMbNOaSw65w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ken',
        lastName: 'Manohar',
        emails: ['kmanohar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE2271lljsowQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517680966191?e=2147483647&v=beta&t=aNg2kcpTAmE6s9FMEZXvDhTtOKu_qjvKPHQm6wvrp2I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Crystal',
        lastName: '',
        emails: ['c@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Patrice',
        lastName: 'Watson',
        emails: ['pwatson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGQ2MlApruQiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516649497554?e=2147483647&v=beta&t=Ijni1bhv6LEhPRvlE5R1H2p838G4WW64iztIhw6-qUY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'Dixon',
        emails: ['adixon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHLvtyPskmEIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714052840967?e=2147483647&v=beta&t=ingb-2zhjTtJ6E8KkY2yKgBboFqrD8inCroJLttLMx8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  flane: {
    name: 'Fast Lane LATAM',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'renan',
        lastName: 'bin',
        emails: ['rbin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEcSArV0Bp5Rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593027122616?e=2147483647&v=beta&t=AbT_Itt_-Hc3N6CaV9TKv_VTW1ACjEsGZm7vslgvRYo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Luis',
        lastName: 'Campbell',
        emails: ['lcampbell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGyzXPkjzvS7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516615932264?e=2147483647&v=beta&t=IK797nf0HVfH8BJ5KnZ4eYgpKqD9i615ThZ7q03XUaI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ruth Castro',
        lastName: 'Siguayro',
        emails: ['rsiguayro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEL2Q7VGEhuOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710193740061?e=2147483647&v=beta&t=uRIXZhNgEyaLy2IjOaBE2DO8_1zlc6G76DhxEQGJO-c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maria del Pilar',
        lastName: 'Cortizo',
        emails: ['mcortizo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFpit2-u9u88w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517566415954?e=2147483647&v=beta&t=CF_V50xbDrc6TyjRiUlKIDD6VFsLJaXLbUbrrTTqG3g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'JESSICA ALEJANDRA ORTEGA',
        lastName: 'URZUA',
        emails: ['jurzua@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG3Fto3neerQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695929430379?e=2147483647&v=beta&t=lOUrIJTPozhHioVUcxq15N9TueJJUE0_Rr_pYWO7YsU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ead: {
    name: 'EAD - Empresa de Arquivo de Documentação',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ana Soraia',
        lastName: 'Coelho',
        emails: ['acoelho@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGwKIYKN38mnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708364721977?e=2147483647&v=beta&t=bznfQbTFJOph22UKFvbxx1spf__p-Vb7DiF3ouZw1LA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'João',
        lastName: 'Inocêncio',
        emails: ['jinocêncio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHihVIp08xzsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727693740189?e=2147483647&v=beta&t=SYNwmUtF39zaoOvDBw1yrB4zekeaF0MuD-vCN2935aA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pedro',
        lastName: 'Soeiro',
        emails: ['psoeiro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHcDPfZuM6CAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516843071496?e=2147483647&v=beta&t=QgvefFyPxGTxqNzoUVpFl8_eilvoyvg6aVDF-SS0LLw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Diana',
        lastName: 'Moreira',
        emails: ['dmoreira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFOrJaZuiD41A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683129202186?e=2147483647&v=beta&t=j8qtPmlINF9E5Y5YVsVP1_WtyXYaVV2YYdz_Rv8y424',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marta',
        lastName: 'Francisco',
        emails: ['mfrancisco@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  apgecommerce: {
    name: 'APG eCommerce Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Catherine',
        lastName: 'Graham',
        emails: ['cgraham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFwtORjl7V3LA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678414478401?e=2147483647&v=beta&t=PSPbqYixHR7DzxmkVWKTTX1146Op8PmxWfhhzLwVfzM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hayley',
        lastName: 'Howes',
        emails: ['hhowes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFUPtqpArlEiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655488785825?e=2147483647&v=beta&t=RxJrL7JERkqpjmV_AQlaS6AxsmYrlRqysOY-2yGwdAg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Emma',
        lastName: 'Mountford',
        emails: ['emountford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGdmccHQ1R7Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677844527786?e=2147483647&v=beta&t=6u2-Etlxkt19hF5XsGZBc3ZhNWwfZDzBW9s_M2QPL1Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rudy',
        lastName: 'Clauzon',
        emails: ['rclauzon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Samuel',
        lastName: 'Peacock',
        emails: ['speacock@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  captecGroup: {
    name: 'Captec',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Max',
        lastName: 'Toti',
        emails: ['mtoti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEebYqnTuInVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591977373174?e=2147483647&v=beta&t=Ts5Cqf3kRZlwVDQIQWG2YGMC1rbCiCllDA7zkxrDaSM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Patrick',
        lastName: 'Beach',
        emails: ['pbeach@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tyler',
        lastName: 'Mehl',
        emails: ['tmehl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFPSYMPwxQpFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663823319289?e=2147483647&v=beta&t=59kyQ-aJvc3uAj3VruJLSHZox5Ff8bGBd0qYSlwaOR0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Trevor',
        lastName: 'Staley',
        emails: ['tstaley@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Dotson',
        emails: ['ddotson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  digitalskillsauthority: {
    name: 'Digital Skills Authority',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Deborah',
        lastName: 'Collier',
        emails: ['dcollier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGZ4cehD6wu8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677254254086?e=2147483647&v=beta&t=Tfy329gAz_Of8zcvGdXjMuESPm3IVkBexoO_6Z96dgM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Merriott',
        emails: ['dmerriott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGOhn9-adhmgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710832188727?e=2147483647&v=beta&t=LdGHFj8q6GiyCkOH19ITZ47q3hZL7El-_O1JLRg1Eac',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Neil',
        lastName: 'Nunez',
        emails: ['nnunez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFMkAevuLckqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708014592092?e=2147483647&v=beta&t=5S9-FUa2pkQcJRtB4B-i5O53FiYr-mrXqsi4AX3nzys',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maame Agyemang',
        lastName: 'Adjei',
        emails: ['madjei@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHpF5EmWYLMXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706266336150?e=2147483647&v=beta&t=mSPCsugc5sqeMlirEmEPJ8iQXcioTJop0ZXO8IRBXzw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: 'Rickles',
        emails: ['prickles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFo4lSn-t3ILg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706622137502?e=2147483647&v=beta&t=TOjkRvS7CjSae9eN-ZEWMryGkzwBsVSplJxwmyoKWzU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  peaxy: {
    name: 'Peaxy, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joshua',
        lastName: 'Gallagher',
        emails: ['jgallagher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHu8t49Cu-rDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516217602792?e=2147483647&v=beta&t=STM2ryCTL1h3idpSynaNyc_LMtfK5vba3ysEWYEW45Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kyle',
        lastName: 'Tran',
        emails: ['ktran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH9yt0J10PjZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1554261594812?e=2147483647&v=beta&t=i0ULc2BNa3WGDEbXoQkye_gMeASl7gTJnax_xhoXb0o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Giovanni',
        lastName: 'Zara',
        emails: ['gzara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH2lyPrBsdxgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644620012351?e=2147483647&v=beta&t=og_8Ix7i2cmJadDwuZzUSfATgvsXk2K3T3yAgz4GWZo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mas',
        lastName: 'Fukumoto',
        emails: ['mfukumoto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH-A9xVt41A4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704459000518?e=2147483647&v=beta&t=qDbhKpCH8_GdSUyEsC5lvHxmEMXxel7zaZ2mOukSmpg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jignesh',
        lastName: 'Derasari',
        emails: ['jderasari@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lonestarcom: {
    name: 'Lone Star Communications, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Henderson',
        emails: ['khenderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFLwMjSqOB6HA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585097295906?e=2147483647&v=beta&t=OqxOY5VlD50dfXtcF5X0u2wYT7VY7xtmq-ZOYHz8pa4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ray',
        lastName: 'Bailey',
        emails: ['rbailey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG8CE2MSrQVVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516358859033?e=2147483647&v=beta&t=3VGrKFPotPHI_6gvXrvEavI8s3q2vjHJyNpAVS9GDzs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bryan',
        lastName: 'Barbeau',
        emails: ['bbarbeau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE8H9J9WRcHJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641221697342?e=2147483647&v=beta&t=yOqb9J6580l_JmS2xSRiHKVQELdm9r5T9y_0JjduLH8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Trey Meador,',
        lastName: 'M.Ed.',
        emails: ['tm.ed.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH51JHxg9R3iA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718478268743?e=2147483647&v=beta&t=Os0kMKca_1cjCwApYQtarWN9pqVMU6C7jaC6FdlTdHk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Donna Cook Montgomery, DNP, MBA, RN,',
        lastName: 'NI',
        emails: ['dni@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFE9SDbNZXXbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658454400619?e=2147483647&v=beta&t=dzIW2JJZJb8RDrX_IHbnFo6iPOHQ-Htr40Q2V-YSuDc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  appliedsystems: {
    name: 'Applied Systems Europe',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Chapman',
        emails: ['dchapman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGKPHAdbqTPCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574185793708?e=2147483647&v=beta&t=x4nHi1zZifHdJj48pbo8Is39H5Jk7nt_tjxdX9v3N_I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joshua',
        lastName: 'Seward',
        emails: ['jseward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG59pwGX4-8eQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548099340169?e=2147483647&v=beta&t=3jNIjA84Kd55Pl6oEt_pUp4doo6f-YXUIsqFd0qASV0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Peter',
        lastName: 'McAlister',
        emails: ['pmcalister@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHHTXAZxdeHOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517343746536?e=2147483647&v=beta&t=evLIDhIGdU38gKoUCzFt3TbsW9f-DABFuCMh03PFYfA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Fernando',
        lastName: 'Sousa',
        emails: ['fsousa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG-UIH7TRn_nQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631198549429?e=2147483647&v=beta&t=i-AoFmsLCJzo6eVNDc72FDmgNr0vxrC6jswucKkjFWU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Trevor',
        lastName: 'Bunker',
        emails: ['tbunker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHx-0pcxtA17A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1576371465023?e=2147483647&v=beta&t=LUxcilDCvqNQ5DShwFD52_Sax1vKD0LyOlzTO-3eFsM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  comitfs: {
    name: 'comitFS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jappy',
        lastName: 'Takhar',
        emails: ['jtakhar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFtGAKXYB8SxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517657413722?e=2147483647&v=beta&t=ihG3PyAI37p66apZZcXG3SD-AnzINENvpfzjyhhZK20',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eugene',
        lastName: 'Land',
        emails: ['eland@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'VENKATA SHIVAJI GANESH VARMA',
        lastName: 'NAMBURI',
        emails: ['vnamburi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGjMbR11Ob4uQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689154138081?e=2147483647&v=beta&t=XbogcvAApeMGSm0OM4a3RL8_8uITToZQdriB7f_k8Xo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sagar',
        lastName: 'Varma',
        emails: ['svarma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bendalam Sai',
        lastName: 'Teja',
        emails: ['bteja@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEO-mo2C1aXDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689430132347?e=2147483647&v=beta&t=Ga9BVhhQO_GL3cG3GSwKLqouxT2gdC8MDODOGZOqAcI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eurogsm: {
    name: 'EuroGsm',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Abdelmajid EL',
        lastName: 'KHADIRI',
        emails: ['akhadiri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHFgBkjXUaBRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665487978141?e=2147483647&v=beta&t=UnQB4fPZVuvH_ldJkMVHqxs5X54ri3v9MepkcfKMC8g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Raluca Maria',
        lastName: 'Nacu',
        emails: ['rnacu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'valentin',
        lastName: 'vali',
        emails: ['vvali@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Judit',
        lastName: 'Katona',
        emails: ['jkatona@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFFlmyxeBW_7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517672614783?e=2147483647&v=beta&t=NUxQCc1oovTW74F-EQnmB2Zq2nv4s8RRNATUxNyZO4E',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daniela',
        lastName: 'Gaina',
        emails: ['dgaina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE44dbKOuQPTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616703849649?e=2147483647&v=beta&t=GySX7pPXtvi7klbd6nMDC3R-6XCsXBybgXvKUb8WQ64',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nexteqplc: {
    name: 'Nexteq PLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jon',
        lastName: 'Jayal',
        emails: ['jjayal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHUlUEFdYihxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516265787387?e=2147483647&v=beta&t=1LH0OZhfEQ0DwMOjosZZyBSo8jK0n4NwZQvQAE-ChHE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Johan',
        lastName: 'Olivier',
        emails: ['jolivier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGY6TklavPzbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686667447694?e=2147483647&v=beta&t=2GFkuPJshMwA1_WvVUOtvDVAc1BuMqjzrW1oaHIOG1o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Duncan',
        lastName: 'Faithfull',
        emails: ['dfaithfull@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEtX8IWA4m7vA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705684260344?e=2147483647&v=beta&t=vFirC7Fd98Gz0xrQrBPgIvI1Kd1thLvgdLjqwuKr2-w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt Stiles,',
        lastName: 'MBA',
        emails: ['mmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName:
          'Tim Salmon – VP, Infrastructure & Service Delivery – Nexteq PLC',
        lastName: '...',
        emails: ['t...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFxegJKWbuPvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605616981538?e=2147483647&v=beta&t=1PHwk0N6WHT9d5AbDEf73-79QSt8tn8Lgc3PkKrGC64',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  oncehub: {
    name: 'OnceHub',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rami',
        lastName: 'Goraly',
        emails: ['rgoraly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE3YMPFbp-boQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694889814261?e=2147483647&v=beta&t=GVPPIVJQ98MjY6GtOecZpSspGdwiKfr0YXceO09tL_k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ishan',
        lastName: 'Bajpai',
        emails: ['ibajpai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHb8Rx2i73MrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681540169882?e=2147483647&v=beta&t=GMal68xSTpbA9fVaBCtlIRUNT8ckRtd_ClgfzJBr7ik',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dinesh Kumar',
        lastName: 'Singh',
        emails: ['dsingh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Allison',
        lastName: 'Neimark',
        emails: ['aneimark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGm67m1he_Gtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594657864120?e=2147483647&v=beta&t=BOJ4lQcbbJL_LVbGuGwT5D74xCx3l4z5k5Gb8MdefeE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nalin',
        lastName: 'Garg',
        emails: ['ngarg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tetramem: {
    name: 'TetraMem - Accelerate The World',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ning Ge, Ph.D.,',
        lastName: 'MBA',
        emails: ['nmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Miao',
        lastName: 'Hu',
        emails: ['mhu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF5ORmQRIxutw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516846393968?e=2147483647&v=beta&t=Z2llKCEloAH93JKZVJwdkWqKYekpQbltMSWfbb_UR7I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Wenbo',
        lastName: 'Yin',
        emails: ['wyin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hengfang',
        lastName: 'Zhu',
        emails: ['hzhu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG9a4LHbvK5-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625225890237?e=2147483647&v=beta&t=uVUYCB0XH2UhploTyhSKVqDnhc-sFJUfpdNjdo4qNHU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Weisen',
        lastName: 'Lei',
        emails: ['wlei@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF7pZnjWLll_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630628798466?e=2147483647&v=beta&t=3IoYothL5SPPfjf359Nqsh9XDmSwGI9lHOcotnYSYJc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  munetrix: {
    name: 'Munetrix',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tom',
        lastName: 'Potapenko',
        emails: ['tpotapenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHx2H4QZnLxQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666372963456?e=2147483647&v=beta&t=l4-rGzoQx5FMA3mvGeecTR7stXKkJJ0SDnMz7Xm2Lec',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ginny',
        lastName: 'Norton',
        emails: ['gnorton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEWP2drWNwDMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544477996190?e=2147483647&v=beta&t=8-3MF_8vULArrw-M7rFx9EnfHBOeg4CZmSGUc6121_k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alissa',
        lastName: 'Letkowski',
        emails: ['aletkowski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGuPoV0vPBxCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567623610081?e=2147483647&v=beta&t=5vQsZHUFGrBZmr9Vnz3jn_mFsIo8fZPetslm-Fom8eQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Taylor',
        lastName: 'Lynch',
        emails: ['tlynch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGKvkiQurj5Ew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649455763298?e=2147483647&v=beta&t=7fAQRTFsOMMf6gnJxVLv10zxAl9J7r0y465uhAAbrsY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Timothy',
        lastName: 'Freer',
        emails: ['tfreer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mass: {
    name: 'Executive Office of Technology Services and Security',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Evelyn',
        lastName: 'McDonald',
        emails: ['emcdonald@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEvmTqN2lHjiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1599140337198?e=2147483647&v=beta&t=oa9CjINKV0LrN9nZdZoljvnTmKRU8-2TMNH32emA9tc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Curtis M.',
        lastName: 'Wood',
        emails: ['cwood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHkDVx0lgS8Jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1534464459091?e=2147483647&v=beta&t=K95cZYHpDVsQAC2zr_sgmkp5qLPREep5Z9aaLpqhnj8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Susan H.',
        lastName: 'Cantrell',
        emails: ['scantrell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGE1dYXAMq3PA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605644986205?e=2147483647&v=beta&t=Ozv9EhHg3TxQb3YtoVgtDk0P6o4ycvoHRDLlAiVWD1g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bill',
        lastName: 'Cole',
        emails: ['bcole@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Micky',
        lastName: 'Tripathi',
        emails: ['mtripathi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ramssol: {
    name: 'RAMSSOL GROUP BERHAD',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Assif',
        lastName: 'Lilah',
        emails: ['alilah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF0daX49NQsAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719025194927?e=2147483647&v=beta&t=7WmI9HXOCACy7TEfp1dph2wwffEg9KUzz53SbkDj6JU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nurul Alia',
        lastName: 'Mohamed',
        emails: ['nmohamed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGvjNiTPoWr_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688719781868?e=2147483647&v=beta&t=JhdGdSH4ON2VjSo_26npnu_fPatjHN46rSl7-_8LMUk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: "NUR 'AININA BINTI",
        lastName: 'AZLAN',
        emails: ['nazlan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFDF0brSSTRKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718236221936?e=2147483647&v=beta&t=-mywyapvwwtyzteKF80Aw5D4R6UC_-nffh7pYQ6z1ug',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Wen Jye',
        lastName: 'Kong',
        emails: ['wkong@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Muhammad',
        lastName: 'Nugroho',
        emails: ['mnugroho@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGxekLNxGIdtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680798022953?e=2147483647&v=beta&t=G8xr_JjRxxfKYTHUye9wfubVKfaS2hTDilkH3EgSbFU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  paligo: {
    name: 'Paligo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ryan',
        lastName: 'Paligo',
        emails: ['rpaligo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ella',
        lastName: 'Paligo',
        emails: ['epaligo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFB93gPc9BuvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689131001599?e=2147483647&v=beta&t=XiBdKUFEOJ4VyDl9b76JTpe8Flrh6Ol2LbBUewdgnTQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark Pepper,',
        lastName: 'Cert.APM',
        emails: ['mcert.apm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFwLCZCOu6TFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691761124787?e=2147483647&v=beta&t=C2B4CDP1ruu34UEf8PtP_Qz0eY0_yZUlzjUAYtQnCHU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ekaterina',
        lastName: 'Frolova',
        emails: ['efrolova@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anders Svensson – Paligo |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHp1ron-9CwMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610947840540?e=2147483647&v=beta&t=-ViyT8D_q-K6Sp5B6T0hwI29PSz_7cxODOwF12m9MYo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ostechnical: {
    name: 'OSTechnical',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Debbie',
        lastName: 'Kaufman',
        emails: ['dkaufman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEHR699qGjxmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706635603735?e=2147483647&v=beta&t=tpT_Y3oUyJtcryMp6RQ4DgkZ_bet9TrUVJkIsasgrK8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nidhi',
        lastName: 'Singh',
        emails: ['nsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE4dUH3dqThbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543263793284?e=2147483647&v=beta&t=ZzjuV-HQZUaGFQlY8G1fAGC4agVSlMjyDDuOntDzGiU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sheena',
        lastName: 'Arias',
        emails: ['sarias@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF6VgCQCe_p4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672963287126?e=2147483647&v=beta&t=yZO366-TgxRXmbEOELJRssSOpkwneoCSQDlEnNV6JFs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marjorie',
        lastName: 'Pillerva',
        emails: ['mpillerva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHvbA2BZUcylQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659274767651?e=2147483647&v=beta&t=F5k8Dr432zV1YIusLBYtD_8cDbWnIRLY7YnFutH_6mY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shandra',
        lastName: 'Phillips',
        emails: ['sphillips@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGnrRWDSUVmHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1545086625823?e=2147483647&v=beta&t=O7Y-zW0Gmv09L5enjvh21ejNLBVN1DjYukN_oEYRDIk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vissim: {
    name: 'Vissim',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Max',
        lastName: 'Semenov',
        emails: ['msemenov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG1DJggmVXsVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708291837715?e=2147483647&v=beta&t=5R3icxlpUwj2-JERMjpdSku8mvtb7ch7-9EN8hHnEdI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vegard',
        lastName: 'Hansen',
        emails: ['vhansen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEad5pu6V8uUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516788028069?e=2147483647&v=beta&t=SI_UDUGdAo2O-fLEl4mo0xC0HFcBFtsP7UbEXfL96FA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Håvard',
        lastName: 'Odden',
        emails: ['hodden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHGaOUWXjbTOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516429773771?e=2147483647&v=beta&t=d-Zkh-ZPw75r3Gq2JSbHvfsTQudCT3QC4K04gGPwBvo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sam',
        lastName: 'Heyman',
        emails: ['sheyman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG4UUnzHXFOWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516304901256?e=2147483647&v=beta&t=DvJ7jFaldGL2FILKPlW4KztcQtYaxpilY7BUYO36M_g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Finn',
        lastName: 'Fjellheim',
        emails: ['ffjellheim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mufin: {
    name: 'MUST Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hannah',
        lastName: 'Hong',
        emails: ['hhong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF8H7YGwX3-tA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672962108437?e=2147483647&v=beta&t=gMPjuA4aremfL5HMOW1lAS2kqbcIVeN4BzkELSMXiDk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steph',
        lastName: 'Haddad',
        emails: ['shaddad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEKg0_Z781iXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719644834076?e=2147483647&v=beta&t=7_XG9H3q3Z2NKWes9Su3erfpzilS9BfRglpOlM7Tqrk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brenda',
        lastName: 'Thaxton',
        emails: ['bthaxton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQE3QAj97ie-hw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517446483833?e=2147483647&v=beta&t=2JyswAiz-XgMCLzN5vtwS9R1Kns-HJFnZ2OmnswU-bM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ray',
        lastName: 'Must',
        emails: ['rmust@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFMF7P7_afMEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536074920632?e=2147483647&v=beta&t=aJ4WVQDj-IgriFVYe1h1y5Q4FBd4KmixT1aPH7DRpKk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amani',
        lastName: 'Kanu',
        emails: ['akanu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG8FSu1fb4ApA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692710793101?e=2147483647&v=beta&t=jALVZy3awfZ3Lqz_EW4oiVAioHBpi2HTlpMtsKxEwGQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  techbuyer: {
    name: 'Techbuyer',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adrian',
        lastName: 'Rivera',
        emails: ['arivera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEtUm-jk76pfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677299120119?e=2147483647&v=beta&t=wW2GMoMBoqnUrhROxIJoQlXryIEqPEhnw0lj8b2JiKQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steve',
        lastName: 'Sexton',
        emails: ['ssexton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGqBWeNY_LXAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668421551148?e=2147483647&v=beta&t=70Hl3PwEOe1JbaPUW5kdKDn4Z3LNu9L-c7J--pEVF_E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Towers',
        emails: ['ctowers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE_owV-S75y0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570225633206?e=2147483647&v=beta&t=JnJ7MniYhsSsOL0BndJMbEPj9Zb2pMx1jBD3qhTcb8s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paul',
        lastName: 'Heckels',
        emails: ['pheckels@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mick',
        lastName: 'Payne',
        emails: ['mpayne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFPg99e8KMmOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675686110300?e=2147483647&v=beta&t=d8STFbhT9pNffU64dc121lMian6062aLZkJRNt7TU9Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  b2bsoft: {
    name: 'B2B Soft',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gary',
        lastName: 'Khabinski',
        emails: ['gkhabinski@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Barsoum',
        emails: ['dbarsoum@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Marti',
        emails: ['cmarti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQENue0uLio1sA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668091128151?e=2147483647&v=beta&t=9TjMhDsyKOJshNXtec_-GoN5zhtQ7eg1b4dO8qBPXj8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vincent',
        lastName: 'LaManna',
        emails: ['vlamanna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGT3XLvpqB79w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642650534308?e=2147483647&v=beta&t=8uNp1Lr3ehys4pLuqb4uLEbznQyrnAXafFxp6WPZSNY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gloria',
        lastName: 'Laureano',
        emails: ['glaureano@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFqkpDiaHVIVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711023357358?e=2147483647&v=beta&t=xTeFV3y8vr5-UJQQBUEOxethrvn5k7h4_qLlubaN5uM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  profilesw: {
    name: 'Profile Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Evangelos',
        lastName: 'Angelides',
        emails: ['eangelides@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Martin',
        emails: ['rmartin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHbx9y9yIHsyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516243267307?e=2147483647&v=beta&t=trPqmNNmS5oiLwjBDwjLZjA0yVrGPJ4GVRjn4iiw3l0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Utsav',
        lastName: '‎',
        emails: ['u‎@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHZ44EdafSZAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708488400049?e=2147483647&v=beta&t=645NjBp7mEg-0G8KaXIFIsnKR2aieIufrxBnnLOaIM0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dave',
        lastName: 'Todaro',
        emails: ['dtodaro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHQBqu3oaTp5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660856067686?e=2147483647&v=beta&t=ReiF8vYvEG1AIJ91kdbLsripFODCpkjjXNxyptKJRcY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Grady',
        lastName: 'Booch',
        emails: ['gbooch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFQce2kj8Ajug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611107800148?e=2147483647&v=beta&t=hRmjBtiKc36rmbjSckqKXzo7HidYyYYdBOQHtM51wIY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  equadis: {
    name: 'Equadis, the Only Omnichannel PIM',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eurobase: {
    name: 'Eurobase',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Subeg',
        lastName: 'Lehl',
        emails: ['slehl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGMCbHKS-7sXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723735786556?e=2147483647&v=beta&t=u2XyAer4Tg404fJ21NwBKcFTw9RJUlV545UD7nnaX_8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName:
          'Harry Cook – Senior Recruitment Consultant – Eurobase People',
        lastName: '...',
        emails: ['h...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGHKRlJ590WBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724079998218?e=2147483647&v=beta&t=zqSTvC1j1LqUeclynE4LS0h90BegIzWummiLP9jOdHs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joe',
        lastName: 'Locke',
        emails: ['jlocke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEtwBcqwOfJhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517722521478?e=2147483647&v=beta&t=uJoRnTKX4ty7XVNa5b2rrC0mCM5Q_tm1jrebSbTZAu0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ian',
        lastName: 'Ayres',
        emails: ['iayres@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHdSOUJp95F6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724315019790?e=2147483647&v=beta&t=XggLgytBk9jBaKfy-TRtf_Fu7CJESzo4nou2AJ-Cw9A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Henry',
        lastName: 'Stevens',
        emails: ['hstevens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGRz0cCap9PcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673539583200?e=2147483647&v=beta&t=mjWzSmDb2jPfSOlFYest6qLB_IOdiUgsGADvBrmqHIw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nimblRecruitment: {
    name: 'NIMBL Recruitment B.V.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: "O'Hare",
        emails: ["bo'hare@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHkKt4UIJA-fg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622448698806?e=2147483647&v=beta&t=tsmznSMYZjlCGQFw4k6raWBVa4RsdKE6e7fNFxMBUvM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chrissy',
        lastName: 'Repko',
        emails: ['crepko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGtQ5dBBKR0fA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658343961141?e=2147483647&v=beta&t=_qwOQvJ5RjS7wVRRp8jGQO1idVnJfmaIvQipeAZYh6g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Grant David',
        lastName: 'Collins',
        emails: ['gcollins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG5n_MQSPSOzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707795471080?e=2147483647&v=beta&t=J5ipv9Q1UD-9q2HGdGppxITrsouGXLeVY-1BOrQTnlQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dan',
        lastName: 'Healy',
        emails: ['dhealy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFvQW5G_ophgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1535040150836?e=2147483647&v=beta&t=wV-jO-0m9tzWuMekEjgE8s-YvCwISO6IID0Gwet6c-s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sandro Canelas',
        lastName: 'Alves',
        emails: ['salves@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF3ZLP4iozd5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721059228121?e=2147483647&v=beta&t=96G0hVUQ4uJkhV3p6Sz5ue-aJH4F1Od1EiurlU10h9w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  iobeya: {
    name: 'iObeya',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cyril',
        lastName: 'Daloz',
        emails: ['cdaloz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGzIC5WFuVj5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668155055225?e=2147483647&v=beta&t=3rDF61EPuhbcM9R1bQkiHtI_SkpktRz-zM_OsI4wxiI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carol',
        lastName: 'McEwan',
        emails: ['cmcewan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEskfCcmbx5gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719022724152?e=2147483647&v=beta&t=ZlqbXKS418MadnAEOkdg6XNxLsF_N-YsB2hpEH17LtI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Idongesit Obeya',
        lastName: 'RN,BSN,MSN,CCM',
        emails: ['irn,bsn,msn,ccm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Keara',
        lastName: 'Brosnan',
        emails: ['kbrosnan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zal',
        lastName: 'Pezhman',
        emails: ['zpezhman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG5jUMJhUbY5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516297180699?e=2147483647&v=beta&t=JkPkfzc5tBXvuTAVF7HGEa4nSICdgefIJm7uEtkBPVI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '3gi': {
    name: '3Gi Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Edwards',
        emails: ['jedwards@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGlVFm6L-UOjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588586671627?e=2147483647&v=beta&t=NmVoGidLAVBpoRLARarUh1-vdV7N8_xYL2u4tWo2U1Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jonathan',
        lastName: 'Collins',
        emails: ['jcollins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nathan',
        lastName: 'Costin',
        emails: ['ncostin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGk0Cybq7sSIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699632146235?e=2147483647&v=beta&t=K9lXiKkogeiErhiGnDbbzVLj_0G891BV9PkyErKzpyU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Liz',
        lastName: 'Teague',
        emails: ['lteague@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jon',
        lastName: 'Sturmer',
        emails: ['jsturmer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHMhTZthavJOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516522908175?e=2147483647&v=beta&t=VOBrpQVGcL9xD1SZ-tN7hAEGc7W5178QubR-aA3ofnI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cegid: {
    name: 'Cegid for HR',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sylvain',
        lastName: 'BILLAULT',
        emails: ['sbillault@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFXzKRM2io7Sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1530458424770?e=2147483647&v=beta&t=xiWl_3wb_I96NgWm1xInUNwEEoikzO8SVex1IRZYqLU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joelle',
        lastName: 'Gargour',
        emails: ['jgargour@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHUsDSmKK4Snw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536698446834?e=2147483647&v=beta&t=QD9Sex0JsMbJscvOKWot8fWGSkJ06o-eEG05HFDz6EI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Oliver Jukic – Cegid for HR |',
        lastName: 'LinkedIn',
        emails: ['olinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGrUmkq7VnOqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639475929857?e=2147483647&v=beta&t=W2wcKuQlrzPcAYe5ZV--9kwWlD281Qp_TBAHeHvEQ2A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lena FABRE – Project Director – Cegid for HR |',
        lastName: 'LinkedIn',
        emails: ['llinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFmfjVZJJeeeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517277283448?e=2147483647&v=beta&t=VqyNNLAZHBDuUXP-Ka5yVIAAS-B5VCpv7nShOnadbEU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Laura',
        lastName: 'Martineau',
        emails: ['lmartineau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFZ0reqEIAslA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670929444442?e=2147483647&v=beta&t=gy7PkO2QdkoSrcRue3XBdI7AK2VQhP0o975QM3U_KFY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jazzsol: {
    name: 'Jazz Solutions Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gargi',
        lastName: 'K.',
        emails: ['gk.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Evelyn',
        lastName: 'Spain',
        emails: ['espain@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeff',
        lastName: 'Goldhirsch',
        emails: ['jgoldhirsch@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'Cutler',
        emails: ['mcutler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tony',
        lastName: 'Barrett',
        emails: ['tbarrett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFam7onp4_TGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706113452199?e=2147483647&v=beta&t=3V3RkR3aXY-Xl3FCfVrnWiSr0rMfLIjx8o1zwQ8q5M0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  speech: {
    name: 'Philips Dictation | Speech Processing Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Christof Strauss – User Documentation & eLearning Specialist',
        lastName: '...',
        emails: ['c...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH7tEBuELQuxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602186048143?e=2147483647&v=beta&t=odBGANtWRlwVmAXvEJNlpA68OsnKfw-zd-3VRKa1qzM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matthew',
        lastName: 'Colonna',
        emails: ['mcolonna@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Wolfgang',
        lastName: 'Spannlang',
        emails: ['wspannlang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGsLeBfIEArHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516826428295?e=2147483647&v=beta&t=V7pk60pdDdgS9hl3zhuVCqqTZl6c9Nok6TL_r1yBTXE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Stinebiser',
        emails: ['mstinebiser@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE5_1UtzlQ4Ag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652487285386?e=2147483647&v=beta&t=zmXAxINvlXzfBFxCRiNY-XVJuBeC7rbjdt6e7wAMAqc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kyle',
        lastName: 'Berry',
        emails: ['kberry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFDM83B80JvrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725998142302?e=2147483647&v=beta&t=XhxzoqYariLJSKdF758gUhYAACvfRgOoPvLc9pgEHmA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  quest: {
    name: 'Quest Holdings SA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vassilis',
        lastName: 'Giannopoulos',
        emails: ['vgiannopoulos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFdirHcHqwx-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516340498444?e=2147483647&v=beta&t=J5U6mDvsvwCGCqox6bfcwl_tIabTJAAMzN9YoDJHtRY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Εfi',
        lastName: 'Koutsoureli',
        emails: ['εkoutsoureli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHer9kQw0wFrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623682326086?e=2147483647&v=beta&t=e3HIiFubCzn3WDJOga--jAqx7kfxGv9Oe69YmVK3R6U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Malamas',
        emails: ['jmalamas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGP9iWxX9QHKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517435477507?e=2147483647&v=beta&t=HpMdsoHZfUFVJW425kHi2CqFWn3rrEiRQyZkGxvzs6Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ioanna',
        lastName: 'Dretta',
        emails: ['idretta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFgDQkXxFTCxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709587362131?e=2147483647&v=beta&t=D5jjUNXJmUU3aC7-o6K1Eu0j6mL2M4QlV4YsbujmdtA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emil',
        lastName: 'Yiannopoulos',
        emails: ['eyiannopoulos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEXhfN_qFRZiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613395691245?e=2147483647&v=beta&t=EuEqKue2me9YgVy57B_1BXy6Ona5-k5UA2J6JZk9kpc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vocalbeats: {
    name: 'Vocalbeats',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Richard',
        lastName: 'Hung',
        emails: ['rhung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGopLkt7KDtag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655331064471?e=2147483647&v=beta&t=_eqaDhuGH5OhmpxSRSvnw9EMNxjapkYQwmI1mtHgeiM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John Keefe',
        lastName: 'III',
        emails: ['jiii@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE25M_xkbTnsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705300642389?e=2147483647&v=beta&t=a71AhFwose8gJyKC86eqllwfdNVTW20JJoI9KtZTRZU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yiwei',
        lastName: 'H.',
        emails: ['yh.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFRZuvYeS26vA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686191100093?e=2147483647&v=beta&t=C_BIHIWa3oCnRyQmpJJxYtc_00i0TYLuhmsX26Q-MJg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Xinyue (Cynthia)',
        lastName: 'Li',
        emails: ['xli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFte2GqN2on7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700492816105?e=2147483647&v=beta&t=9tMm3ZmDdQWKeplcZSsx85eZOW3oy6W6izyMr4PfE5g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lin Ziheng',
        lastName: '(Evan)',
        emails: ['l(evan)@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fetch: {
    name: 'Fetch.ai',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Humayun',
        lastName: 'Sheikh',
        emails: ['hsheikh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG7jshVypBcrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713644896972?e=2147483647&v=beta&t=Oi6KSclk9qjVSJQuYOGiKcEIgT7a1M95B8jDRpxgW4U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Diarmid',
        lastName: 'Campbell',
        emails: ['dcampbell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHo7HDERSodJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596111801151?e=2147483647&v=beta&t=p7-KY_aVyurJLURG1cHOomVoJpo5LDRUpCeTTcL_ct8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Edward',
        lastName: 'FitzGerald',
        emails: ['efitzgerald@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH9T6M1w3FDQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1533994229659?e=2147483647&v=beta&t=-1rWmjuKC1LWqwV3OY5lCCpsPXMOqVfr-wd8Bhiv1WY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maria',
        lastName: 'Minaricova',
        emails: ['mminaricova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGi8HIUAFRHRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661367667363?e=2147483647&v=beta&t=3zfm9r1y67ESHXjUcuKISIwJ9W2YyPWcPxf4oh-bbrI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Byron',
        lastName: 'Grealy',
        emails: ['bgrealy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFPnNhRdQuKCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659562486011?e=2147483647&v=beta&t=iaHYYdzFuzh6PHdkzEICUS2Pxd4EPE-W0UIslH2hrus',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vintedge: {
    name: 'Vintedge',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marlon',
        lastName: 'Hernandez',
        emails: ['mhernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEqxwi0Iwd2zQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584707684101?e=2147483647&v=beta&t=uCrufFqCl4AaVxzURXkIKAfLkK234Idp7BQH3hUNNUo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Travis',
        lastName: 'Pollard',
        emails: ['tpollard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG9S49-uKAwng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1566431378794?e=2147483647&v=beta&t=EaKFjPK-9ZW-BpmH-ssCKbAMxAaLe2S3t7VRBMBzlKE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ethan',
        lastName: 'Liu',
        emails: ['eliu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGtrm_TJEzuEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703124941144?e=2147483647&v=beta&t=aXYlMGjBtTvvifINIkB9ZGpXAq9Z8g90XcQVVczaHKU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Caitlin',
        lastName: 'Brax',
        emails: ['cbrax@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHWSp3xj1vSMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516929231776?e=2147483647&v=beta&t=Vg49omvYDoUMRbC8biB3UE7HPXzZ-e1dCiaxwwXo92g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Travis',
        lastName: 'Moore',
        emails: ['tmoore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEvvPzggHeTYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547422372939?e=2147483647&v=beta&t=JMu7E9yQNfws-BomxewNgmsWr_zV9wVYFn6Sae5pPgY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  firstdata: {
    name: 'Fiserv',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christopher',
        lastName: 'Augustin',
        emails: ['caugustin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Peter',
        lastName: 'Raj',
        emails: ['praj@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mary',
        lastName: 'Uslander',
        emails: ['muslander@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Roman',
        lastName: 'Siciunas',
        emails: ['rsiciunas@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Virgil',
        emails: ['jvirgil@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  anatomyit: {
    name: 'Anatomy IT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jason',
        lastName: 'Barroso',
        emails: ['jbarroso@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEzyvuWQSbnSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664373363814?e=2147483647&v=beta&t=YuQTSYmdrT_ZUK050sJH11NCgCujHYtpcxF7TlNIirk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Varun',
        lastName: 'Gulati',
        emails: ['vgulati@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jen',
        lastName: 'Driscoll',
        emails: ['jdriscoll@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQELQyGyYK4-bA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650557140968?e=2147483647&v=beta&t=ehe66qiDnqTJrortogWCktJpgb9b5L5RoXiU_180920',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chandler',
        lastName: 'Sharp',
        emails: ['csharp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEunuvmcsHf6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658493425859?e=2147483647&v=beta&t=7lippZTH3qndxsduUX-aAkfAiXHH0pfCRLieH-RaqDE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrik',
        lastName: 'Vagenius',
        emails: ['pvagenius@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHACt8zTKCeIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615904261727?e=2147483647&v=beta&t=DrVrxFx38r1XOmf-KhZoNeY57fUaxa7bVBiRsW_avYY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  intelliforceItsg: {
    name: 'Intelliforce-IT Solutions Group, LLC.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christopher',
        lastName: 'Palmer',
        emails: ['cpalmer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG5HO8ZWtZhHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686570636338?e=2147483647&v=beta&t=4kICa_opvjtuzAjBa1U19pr3DgD2OTkssHH-9xircf8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tia',
        lastName: 'Trogdon',
        emails: ['ttrogdon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carol',
        lastName: 'Coffin',
        emails: ['ccoffin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christine',
        lastName: 'LaPorte',
        emails: ['claporte@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHmShDuFgyqtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707005923777?e=2147483647&v=beta&t=AMurZIPXrNnO0qLk4wGYVo_6kKp3KDsqpeuTKkirDVA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christine',
        lastName: 'LaPorte',
        emails: ['claporte@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE2XiXteaRCgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517656410946?e=2147483647&v=beta&t=E4QjdC8U6Ivopd9_d0iQZ1Yi8XoSrZzvjEygthhScDM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  oforce: {
    name: 'Openforce',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wendy J',
        lastName: 'Greenland',
        emails: ['wgreenland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGEy5JPqBv7WA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696966287670?e=2147483647&v=beta&t=NNZke3qayVJRn49oLPjIT-2VW1NIC2xOyVgLFxdkceE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Matre',
        emails: ['dmatre@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHiWprS1Cun6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715797553782?e=2147483647&v=beta&t=BaKttEIDDnwWmTXlF5vqPQADhp-SfelbtUnZQtbQ1XM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mary',
        lastName: 'Bogdanov',
        emails: ['mbogdanov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHkTY-ieASmRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722748784827?e=2147483647&v=beta&t=TeT2T7sYP3qegQcXDS6Bl7r2JGy20nNBVrOsKU5XK6w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jaimie Kowalski,',
        lastName: 'MBA',
        emails: ['jmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEqKXcSWfnIfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707429557187?e=2147483647&v=beta&t=Qf9QwU4P5XOzn1W31r3MceYtjOgwLPF77OQAPaPM0Vc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sarah Schildts, MLS, PHR,',
        lastName: 'SHRM',
        emails: ['sshrm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  allgeierPublic: {
    name: 'Allgeier Public',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kasey',
        lastName: 'Allgeier',
        emails: ['kallgeier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHBVhCPhW8ccg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516957136742?e=2147483647&v=beta&t=F0f52ZO7lBRs8rkcnju3PtDDVCZzoKHPEyPQP-Pkw6Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Martin Huber – Allgeier Public |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Senia Gomez Garces – Coach Recruiter – Allgeier Public |',
        lastName: 'LinkedIn',
        emails: ['slinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEQndtD5kqgWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625222657579?e=2147483647&v=beta&t=me8R6chtQbefq71UsMrx9buUBMN1wWhVnvEwxs5cbR0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Katharina Staples – Service Delivery Manager – Allgeier Public',
        lastName: '...',
        emails: ['k...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ruslan Kuznetsov – Allgeier Public |',
        lastName: 'LinkedIn',
        emails: ['rlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH7xtaMxYUOmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721393770598?e=2147483647&v=beta&t=is8rbYvV24VTbug3B1JN2qPiiPOb_0A7KEP-P7-f2KU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  delcor: {
    name: 'DelCor Technology Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Norma Castrejon,',
        lastName: 'CAE',
        emails: ['ncae@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Ecker',
        emails: ['cecker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF-SF4CLLlarg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1599059786156?e=2147483647&v=beta&t=n5WyiXE5_NqGFNS9_jRiTgVD5EqlRHGTNdOZvTrd1Ac',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Katherine',
        lastName: 'Martinez',
        emails: ['kmartinez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Misslucky',
        lastName: 'L.',
        emails: ['ml.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG4Iq6yVhefuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657417446030?e=2147483647&v=beta&t=D0cYw-DljZGQR4V_xY4ckTliM9UVHoV3DrPPmTIEzJ0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Dolesh',
        emails: ['mdolesh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFDRPmi3Mpajg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623943502907?e=2147483647&v=beta&t=m5YvYkRtKfsio7mb5Car4qifwpoCCC_QbxtUElMcfS4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  element84: {
    name: 'Element 84',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dan',
        lastName: 'Pilone',
        emails: ['dpilone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGtBW7PBfxU2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1524514663619?e=2147483647&v=beta&t=jHbUqZxULGEAF4E6g_8rVL1KhNeeBHa7N-SgKFq2sFE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Reese',
        emails: ['mreese@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFVs5l5dM6vcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516614187687?e=2147483647&v=beta&t=4znCQp4js1Y6Kb5KG6KopV99LFaFAT1rg9_hyWwPf2U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Hanson',
        emails: ['mhanson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEnFYZrnli4FA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555362178558?e=2147483647&v=beta&t=7tEa273hor31FkrK0Wq849UTR5jzr3bYeKxMSbld34w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Adeel',
        lastName: 'Hassan',
        emails: ['ahassan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHG3Qq9N2SdEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649010812866?e=2147483647&v=beta&t=uJwMP9S8o0hgGWQn-xUiy9qL0VkYmgKyZUF32gj7o10',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Trevor',
        lastName: 'Lang',
        emails: ['tlang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHXCV9x_AgtiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517053909209?e=2147483647&v=beta&t=pFjxLRwOf6KqzC8oV-2aHbI_N78bbAUy2w0Rq_5xXnc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mbaLink: {
    name: 'Marcia Brenner Associates, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chip',
        lastName: 'Kling',
        emails: ['ckling@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHG5dohIdAFVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718261768083?e=2147483647&v=beta&t=hMTjDNzCfTsB6e2GTC3qtXu8mlfbhi63rKyumyRHoIQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nicole',
        lastName: 'Pupanek',
        emails: ['npupanek@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sean',
        lastName: 'Cawby',
        emails: ['scawby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFmnpM-0PuUYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649961616398?e=2147483647&v=beta&t=7Ynp5wY1YuT4Tt4jj23yfcv9TIWbuoiNNbn25agJdQQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stacy',
        lastName: 'Cunningham',
        emails: ['scunningham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEf17V_zz4RDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729256529111?e=2147483647&v=beta&t=TQWNwy4cVhsuED49wtvEe9-8n0E7iFe_T1gdAbhLPW0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tammy',
        lastName: 'Todd',
        emails: ['ttodd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEp4b-mwZ7KuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642730747242?e=2147483647&v=beta&t=67j_NBerJIOCCdKm8f_dEIm7BH4Lky0-kKYQCffWkyA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  whiteboardfederal: {
    name: 'Whiteboard Federal Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeremy',
        lastName: 'Sager',
        emails: ['jsager@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jesse',
        lastName: 'Torgerson',
        emails: ['jtorgerson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGgcokXC1lZ2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597499567579?e=2147483647&v=beta&t=616W3bgnTILYl-OaiyhiggEFomheMpUGwXyRsTnf1Ec',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachael',
        lastName: 'Ayers',
        emails: ['rayers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGEaBni-R7uhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516234575781?e=2147483647&v=beta&t=4-w0ZgNGBYJhbONaCIm_hgR1CzLWNc7t5Q0J_0qjid0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vincent',
        lastName: 'Mai',
        emails: ['vmai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFCzNMNTPQ3HQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715465843443?e=2147483647&v=beta&t=rSK_Zn4wrcVIbxwcXg2rBZ4brvQcrtgRSnMQlRzSf1Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Thomas',
        lastName: 'Roche',
        emails: ['troche@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tacEng: {
    name: 'Tactical Engineering and Analysis, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chelsea Crisostomo',
        lastName: 'Shue',
        emails: ['cshue@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFYvlJJefLkpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647146611414?e=2147483647&v=beta&t=AopCwznShS5EFPF-rJFdrc24A0BU6fyX4yKPk8Rhkhc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lawrence',
        lastName: 'Massaro',
        emails: ['lmassaro@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sherry Bravo,',
        lastName: 'SPHR',
        emails: ['ssphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHgCZzqyqrWLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517677036213?e=2147483647&v=beta&t=hrBcMCl6xDc-HOvaEDaLOyDZcjsEb0-aVOiR31Qhgvc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pamela',
        lastName: 'Perez',
        emails: ['pperez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF1-85hD15ulg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1646414449461?e=2147483647&v=beta&t=svbadLsvBEefPpTMw3kGzhSrxejYhvNFP94VzE-bzkA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stephen',
        lastName: 'Hanna',
        emails: ['shanna@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dornerworks: {
    name: 'DornerWorks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Deb',
        lastName: 'DeVries',
        emails: ['ddevries@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFOSfMlzG3U3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517657678880?e=2147483647&v=beta&t=FajOVbxvBTGVxDLreiVvW4dneuW-z5wU_TkihWg-9Yg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brian',
        lastName: 'Kachala',
        emails: ['bkachala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHc1JAJ4pYufg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1535326057139?e=2147483647&v=beta&t=E9hQ6CV7htmJcQ6A-WiwegoJ3gCiBbybOa4IUUYcWCU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Johnson',
        emails: ['djohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHDB7HbFSi8Gg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558621231760?e=2147483647&v=beta&t=S6gP1NtUsP7Y-c3DXEuOODsdOmn7djJZeCvdcGsyz3s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gregg Wildes,',
        lastName: 'Ph.D.',
        emails: ['gph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHmWAywtukXYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517748255337?e=2147483647&v=beta&t=-L61ZxvrNrAsnpdyAm8qLVnWY3lZjH6l9HuxT3nVe8U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Cotter',
        emails: ['mcotter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFHq038ILlsFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680122409677?e=2147483647&v=beta&t=gYPqojh9I1BxzBzRyyWFFsykgH6Psnc3VN0BMRMmpnQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vernier: {
    name: 'Vernier Science Education',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lily',
        lastName: 'Vernier',
        emails: ['lvernier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEZM1KVprijlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718286845780?e=2147483647&v=beta&t=IgRs8CFjUbDFUbkhyNq-3i6lB8h28cedGde6lXv19HI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Amanda',
        lastName: 'Vernier',
        emails: ['avernier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE6XSIpDCZEzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704996507554?e=2147483647&v=beta&t=ecAV4KgGFrfyAScgtrYn178RW1OovXAhIJpiAe0cAss',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gilles',
        lastName: 'vernier',
        emails: ['gvernier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEGC2VhhjAweA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693481123057?e=2147483647&v=beta&t=XVacseP8LLHCKn15m1bvVl8mq2IlN541fpClSnQg_ZE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Clara',
        lastName: 'Vernier',
        emails: ['cvernier@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Agathe',
        lastName: 'Vernier',
        emails: ['avernier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEj5oYLV_VEuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608844777975?e=2147483647&v=beta&t=qA36gS_Pj9wy_sqUXmTzyP4RCAP0PVq9KcQb9ITUZjo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  assemblysoftware: {
    name: 'Assembly Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kara Shavalier',
        lastName: 'James',
        emails: ['kjames@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFe0FDnhACKQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596481356071?e=2147483647&v=beta&t=cQ0eb4yLHBlse5J_SdqITamMYR5UxBY9nmP4PrGoC3E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Farrar',
        emails: ['dfarrar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Diane',
        lastName: 'Danois',
        emails: ['ddanois@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFG3D5X2yZR3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552217130655?e=2147483647&v=beta&t=j00NkyQ1onPJaCs4nPIYXo9eCMr3lWusaCBbq4iQ-P4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jim',
        lastName: 'Garrett',
        emails: ['jgarrett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG3otRg1-gRZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671413683656?e=2147483647&v=beta&t=9ObaLmqs2Y6yqVbc_8M3ac0fkS4pYJY0kUeM9Uw0rB4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alina',
        lastName: 'Hellstern',
        emails: ['ahellstern@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFJgffI0MVs7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645802964811?e=2147483647&v=beta&t=k8494kuHCyGp0qajpTenReueg78UD-EsfvwUKqF4jqU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zadarastorage: {
    name: 'Zadara',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Yoram',
        lastName: 'Novick',
        emails: ['ynovick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGf_7mgbuJ4Ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681826247286?e=2147483647&v=beta&t=0W26M9lwsYvl7mEslwOGwJzPuVMF-ysEIxoE5QayFXM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Karina Flynn,',
        lastName: 'SPHR',
        emails: ['ksphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHu7pWM2ejIdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712951544594?e=2147483647&v=beta&t=tvXsPA_rCQoFOfls2xfkJjdgg8hL89pMj74MeaepA_4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nelson',
        lastName: 'Nahum',
        emails: ['nnahum@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHw2cuRTpoRZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695839184573?e=2147483647&v=beta&t=yH8qf_LhQDDJWKeIWg9RAdAWhWUywNBh6e20Og2o67I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nir Ben',
        lastName: 'Zvi',
        emails: ['nzvi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marina',
        lastName: 'Zadara',
        emails: ['mzadara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEVKuoGikT61g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582813043656?e=2147483647&v=beta&t=d6PWmh7x3AFapI9Jhsk8RbS0kTJJVeyVJ6nvq-v1FgE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  active: {
    name: 'Active Computer Systems S.A.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anna',
        lastName: 'Kanari',
        emails: ['akanari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHtiUVPxlCFgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654931520888?e=2147483647&v=beta&t=2OJ4wjqbEWMjhyCDsxSukGKl7teHXrYrHOCk7yj1q1o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Petros',
        lastName: 'Pavlidis',
        emails: ['ppavlidis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEqJmnALYtumQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703231602477?e=2147483647&v=beta&t=hT48MX_cDEgD6siCI2M6E2Hygw6kS4PO85Bv_anZ0Ig',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eleftheria',
        lastName: 'Maggina',
        emails: ['emaggina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGUK10tBbsVzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715619663625?e=2147483647&v=beta&t=zGs5luF4OIl1P2eGImH6XjOyRIMWuoVkQ6xlXuUueyA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Magda',
        lastName: 'Iosifidou',
        emails: ['miosifidou@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHla6QNL3V9FQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685023154760?e=2147483647&v=beta&t=jfJ1Y27tlSyoVd2bN6BBhGAxsWC7gdiXfCzawq0u378',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Petros',
        lastName: 'Katsimanis',
        emails: ['pkatsimanis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHW8mPkAIWjDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636909056498?e=2147483647&v=beta&t=mFB5tJB1TLjbnvw7flQom71V-P-Jo00r-iLUZB9pt3A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jri: {
    name: 'The Japan Research Institute, Limited (Singapore Branch)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wee Ngee',
        lastName: 'Ang',
        emails: ['wang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shining, Alex',
        lastName: 'Lin',
        emails: ['slin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'L.',
        emails: ['jl.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joseph',
        lastName: 'Ng',
        emails: ['jng@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Danny',
        lastName: 'Tanurahardja',
        emails: ['dtanurahardja@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tradepending: {
    name: 'TradePending',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matthew',
        lastName: 'Davis',
        emails: ['mdavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFVyWhxpo2Y0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660768298212?e=2147483647&v=beta&t=E4E8K9pL6fiSaIo6Z8AW4XGJFt6uH9tNqIiy-g-ffiY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brice',
        lastName: 'Englert',
        emails: ['benglert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFaX-EunNzj2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551728226938?e=2147483647&v=beta&t=V3yOEqrSVu3jSgozzqnew1dq6env0INxjualYFbj4r0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Steven',
        lastName: 'Boles',
        emails: ['sboles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHJ--dsmxRoYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722713434237?e=2147483647&v=beta&t=2WkDUBPco-NeptsYiIeckaQ0r47jrYW_U_0imt0DSp8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alex',
        lastName: 'Grunwald',
        emails: ['agrunwald@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Madhu',
        lastName: 'Kota',
        emails: ['mkota@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  conexess: {
    name: 'Conexess Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alex',
        lastName: 'Christenson',
        emails: ['achristenson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEx2E16y_U9Lw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673567603351?e=2147483647&v=beta&t=jEPSOv9WBUAKp_GNHUjdr5_EtojeSyLeqUO6VlGpfOk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Austin',
        lastName: 'Meibers',
        emails: ['ameibers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHLBoRzJWs98g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516233861144?e=2147483647&v=beta&t=v-rXcaUAcxGSeHYKnXnm4QZoRaxHqhWwXz6E41_TbTw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ryan',
        lastName: 'Bossung',
        emails: ['rbossung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHc67Nl4-qv0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642195572379?e=2147483647&v=beta&t=Sz1bFI6kQ4UZR3O3fBAo5XI4PxOYHnFVtWMcyPZmKYs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shelby',
        lastName: 'Fielding',
        emails: ['sfielding@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQERKpzJaMPU4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635471023649?e=2147483647&v=beta&t=DvQiVl-e0VzGTRZ6xEecGITjcmXzTuYpGYpv42ng55w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Katie',
        lastName: 'Boyle',
        emails: ['kboyle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFRl-csD2CUUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699910651576?e=2147483647&v=beta&t=GoRnSEDJDLV8oi6pPjDAsQi_bY3Y2Mh7nOrHjNEW3rg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ac3health: {
    name: 'AC3',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Trevor',
        lastName: 'Forrest',
        emails: ['tforrest@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFYBogAkxpE8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715461923866?e=2147483647&v=beta&t=wygY631dsf_OE15UOIwuA2bY_d1mM252VjrB6fbh8-w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Corinne',
        lastName: 'Kereszturi',
        emails: ['ckereszturi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patricia',
        lastName: 'Kobalski',
        emails: ['pkobalski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF1ATcTBhu-AA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701871701072?e=2147483647&v=beta&t=mPeZNiHjKrYYK5nIPDbcd1TOHcbgstgIT3Ai8B_AVwY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Willie Mae Wright,',
        lastName: 'SHRM',
        emails: ['wshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHQ4rWGV3P3hg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718284946910?e=2147483647&v=beta&t=fkq95rIZVxVnpXTRao85QIG_gaSihLH9YnxddQfpJAg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jennifer',
        lastName: 'Munoz',
        emails: ['jmunoz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH4aZAlWZb9dA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541808785602?e=2147483647&v=beta&t=SsLnYT_T3bI-Z0Ip5sP1Xli6tsoDUrLqTajE0On3zLA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bigtappanalytics: {
    name: 'BigTapp Analytics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rajasundaram',
        lastName: 'Mathavan',
        emails: ['rmathavan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEs00R79y70Og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516585554811?e=2147483647&v=beta&t=kKwQlsXBIJ6EDa_nLenrnh5bs1zepKK7x6rrgre7Yf0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'HR BigTapp',
        lastName: 'Analytics',
        emails: ['hanalytics@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sachin',
        lastName: 'Dinesh',
        emails: ['sdinesh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGGH4BhoExGWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630316727919?e=2147483647&v=beta&t=BQyHfeVv8EmXaMe9ewJdTFzWicrJBB1PDWXxMHP5mJU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jackson Noah',
        lastName: 'Victor',
        emails: ['jvictor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHcRhzjDO8OJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516872538951?e=2147483647&v=beta&t=0if55uU0nrG5HEkZbDvZJyA-_mKq5dWyfAVAo-cBDDw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jayashankar',
        lastName: 'Daggupati',
        emails: ['jdaggupati@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHo2jGE2I4PVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516451754444?e=2147483647&v=beta&t=dp58ThFa4Sbe6LudWhwFaXfiAOSKL0-5JaZZtP4LcLs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  magnaPower: {
    name: 'Magna-Power Electronics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adam',
        lastName: 'Pitel',
        emails: ['apitel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGC6Mo-mG3HMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644683652350?e=2147483647&v=beta&t=l5diEWaXNNwjNUjbLMgQOxlHmvtwukwvTdDaF3vT2NA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ryan',
        lastName: 'Roberts',
        emails: ['rroberts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEU4E1j4VgJYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596034764108?e=2147483647&v=beta&t=ZtwscEAs65IrjmWc6ejcyiNV0usjhqLZZ0s8886AuUM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ira J.',
        lastName: 'Pitel',
        emails: ['ipitel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bob',
        lastName: 'Collins',
        emails: ['bcollins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEl2h4TrbrYLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517655533052?e=2147483647&v=beta&t=7n6tPFeVVM5MvGB3A6WonOf6smLt8Vn8t9N1YwLQY3Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adam',
        lastName: 'Morawski',
        emails: ['amorawski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHm6xTIK6K4Hg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516972482914?e=2147483647&v=beta&t=1t-lWtSp6Yw81S3Yi86EuJf8eZz7qURNwP7iM7Lr7tQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  feathr: {
    name: 'Feathr',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'Rose',
        emails: ['jrose@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aleksander',
        lastName: 'Levental',
        emails: ['alevental@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGr-A7kkkVg-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558544755132?e=2147483647&v=beta&t=EIsn1o1cLW1_qMAcPrDzfiQuDF2gLXi17AXmpvY3kZ4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aidan',
        lastName: 'Augustin',
        emails: ['aaugustin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hannah',
        lastName: 'Hoffman',
        emails: ['hhoffman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGDTbFfqQq0jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546011829449?e=2147483647&v=beta&t=TgqDRY6xOqb83eNSXEA2qAeLZoRjQAHN9X95h8rAEiw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sean',
        lastName: 'Groleau',
        emails: ['sgroleau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFc0MLQBzLDxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666314509612?e=2147483647&v=beta&t=DWvUOkNz9ceUqi9hAaJvKWFADnV5nGO1VHMUHIKMcOc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  starcircle: {
    name: 'Starcircle',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: "O'Mahony",
        emails: ["jo'mahony@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFY39lll4whUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548274672621?e=2147483647&v=beta&t=DxeyowkGKM_huDssyEnDwVBZZL205KhU9rbQ95SpzJY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dean',
        lastName: 'Dunphy',
        emails: ['ddunphy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQENzd7u8Phxfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677877392519?e=2147483647&v=beta&t=1iWcPeEewRxD4n3FEBMm3KSvdUAvb4mMSM-8JNTFHOs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ger',
        lastName: "O'Mahony",
        emails: ["go'mahony@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHvbS9ENkx6Vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686319420671?e=2147483647&v=beta&t=xvSfwxi1nZXsRip73xEV7Knv75L7YNledRTUTv4GGk8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ian O',
        lastName: "'Donoghue",
        emails: ["i'donoghue@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGu2daeHJyJCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517433713631?e=2147483647&v=beta&t=SGFEDbK_aKHoPPeoikxuKPLQO6YuCly8NZ8lncPmFsU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ronan',
        lastName: 'Walsh',
        emails: ['rwalsh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGopbCOJdVkqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555683165192?e=2147483647&v=beta&t=80cYtjke9HxaSAFfHRRw3RcqHHiN0PydwJS-x6wkB1Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  procedureflow: {
    name: 'ProcedureFlow',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniella',
        lastName: 'DeGrace',
        emails: ['ddegrace@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFiJA6N2_Mwag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516274823421?e=2147483647&v=beta&t=uNW3IX9FOjOVSkFpFia9H8LvfwntilC1nIOMchNopNI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aaron',
        lastName: 'Craig',
        emails: ['acraig@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH50GhzcbmMjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661281145511?e=2147483647&v=beta&t=Hdqh80-0G_QjqcRYKCCMV8Tezy78pQYsyOdA-sqM8G4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephanie',
        lastName: 'Gomez',
        emails: ['sgomez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH9LVE2kcQ_Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715258993427?e=2147483647&v=beta&t=HZnU3MddPp2jw50chGOfXT_cviMqnGcak8XMCidVCp0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Adrian',
        lastName: 'Phinney',
        emails: ['aphinney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jamie',
        lastName: 'Morell',
        emails: ['jmorell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGu6jityqplBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517724231246?e=2147483647&v=beta&t=IX4Dzi3L5GkY7Ho-i6r2mLbMid6csKBN0i3UXpHrcEs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cotorelay: {
    name: 'Coto Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'DANIEL',
        lastName: 'RAGAN',
        emails: ['dragan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nicholas Cetola,',
        lastName: 'CPA',
        emails: ['ncpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHKvBQkqJZkZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516494726488?e=2147483647&v=beta&t=30I7yoeYGb0uZDRzXygYov5JpNk3Y3LWpW8Ww43K5KQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Larry',
        lastName: 'Blackman',
        emails: ['lblackman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Enrique',
        lastName: 'Vasquez',
        emails: ['evasquez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEBTZ8_P0uEvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517531112852?e=2147483647&v=beta&t=msb3njCS6t6g_raXWePBvRJMc78QVGUbDIG8IbcJHXE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName:
          'Bernd Helbig – Vice President Sales Marketing – Coto Technology',
        lastName: '...',
        emails: ['b...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFi-ZIz9bXhiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516472818282?e=2147483647&v=beta&t=iKyM-HllzWuKmKPfC4wb1CiPCJMO0UIkk_Z8rxD-S2w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  peaketechnology: {
    name: 'PEAKE Technology Partners',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Knotts',
        emails: ['cknotts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHDrOQ5iyhyGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568062363515?e=2147483647&v=beta&t=4UHdQQoB-ZerezfTLyWepG8TXcMxUjhnBJ-FB-bHhpQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dr Tarun',
        lastName: 'R',
        emails: ['dr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Benjamin',
        lastName: 'Kelling',
        emails: ['bkelling@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFjPcyfMgdkOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718654584589?e=2147483647&v=beta&t=dEl8UVDuhjrp_p83p6TVCnhb1u8HrGQqjEr6llFa7Sc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Thomas',
        lastName: 'Maydag',
        emails: ['tmaydag@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHEay0unHND7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620910054822?e=2147483647&v=beta&t=TEDhBo2TUQB5ErId3Ht6m2lRtRp4RdiXLGg7Ki_9SRI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stephanie',
        lastName: 'Jones',
        emails: ['sjones@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  flextoninc: {
    name: 'Flexton Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Deepa',
        lastName: 'Arjunan',
        emails: ['darjunan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGO__yq-F1PHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551903384297?e=2147483647&v=beta&t=DUVRqBomlB3MaqTuXDafVg4vWp0Y7xhJbLwnJhlZMxw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dimitris',
        lastName: 'P.',
        emails: ['dp.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGQD6vquqhPPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710537531662?e=2147483647&v=beta&t=bUCcjqiGUGBM7D1pXEyRNNyxCHUXp6nEZG98Dus4LKw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vivek',
        lastName: 'Mishra',
        emails: ['vmishra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEfs300lnR8XQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710304686607?e=2147483647&v=beta&t=_eusyRwYBCqmlQ_W9fZDYznn6c1JSbmViGMGkdqOUmc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christina',
        lastName: 'Bass',
        emails: ['cbass@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Manoj',
        lastName: 'Tiwari',
        emails: ['mtiwari@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  softtekgov: {
    name: 'Softtek Government Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Elizabeth Mercedes Romero',
        lastName: 'Hernandez',
        emails: ['ehernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFTZGT7Z3nOOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519170561520?e=2147483647&v=beta&t=sYJMIvN455CLKHWxPvmDPz7k7qEFHZUEXrAwXQAsNnU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Liza',
        lastName: 'Shino',
        emails: ['lshino@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ian',
        lastName: 'Romero',
        emails: ['iromero@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'Grant',
        emails: ['mgrant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEypzQTne9j_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654733295676?e=2147483647&v=beta&t=t35Bgn1b4MiZTrlMvL69UpJHib-bRtQS14dd6ZhoFms',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Janna Banessa Rosales',
        lastName: 'Soltero',
        emails: ['jsoltero@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEr27cLHG21eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685193867041?e=2147483647&v=beta&t=TBGm0ohjQ6G-g_VbOzOU8o6cp_7Bq7QEWitomSSMoGo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ptfs: {
    name: 'PTFS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Yokley',
        emails: ['jyokley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG7g_68UuYWdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690996703553?e=2147483647&v=beta&t=eYgt6ZA814V3lPELduC4wR_2wdecXVM_Vnt5ZhrO39s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Fanguy',
        emails: ['jfanguy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGgW_bvWLJKVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721845564902?e=2147483647&v=beta&t=1t9ekg7vSd7BcT0bsqDuQTy3SSbRWbaMfzUDUxAE_3s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Todd',
        lastName: 'Glass',
        emails: ['tglass@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHvJZueLUKrCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517680098255?e=2147483647&v=beta&t=Bju6RHPPzO_EBcgn7yuTRSJEb7-zIkp-X3itEjWP3Q0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jon',
        lastName: 'Sievert',
        emails: ['jsievert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHcY__TFeH8QA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691065245704?e=2147483647&v=beta&t=qJD-vshGkaXEDV_QRiZefKVZC0UpSg17-8IiwrbQvAk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alfredo',
        lastName: 'Ignacio',
        emails: ['aignacio@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mobicom: {
    name: 'MobiCom IT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Akhtar Munir Khan',
        lastName: 'Shah',
        emails: ['ashah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFW1hOWC8T4yQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517574938760?e=2147483647&v=beta&t=ZuT9jIr9Fbtpc2hnMRVUFVWQwGR8dyb-3lMpxPevdJA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Muhammad Shahid',
        lastName: 'Siddique',
        emails: ['msiddique@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'amer',
        lastName: 'ahmed',
        emails: ['aahmed@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Minhaj',
        lastName: 'Ahmed',
        emails: ['mahmed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGdfqu6S7wzcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728894089759?e=2147483647&v=beta&t=0-I4H8RYwE39gOWduMdQ3neSd8C0fPnZt32u5M2InsA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Syed Muhammad Salman',
        lastName: 'Ameen',
        emails: ['sameen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGZhqrgb_kt5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586034436361?e=2147483647&v=beta&t=vifV7IqBlpFUf5tybs8REHcf5k-6s7Ulg7Z_z1R4sHM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nffinc: {
    name: 'Networking For Future (NFF)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alan',
        lastName: 'Edwards',
        emails: ['aedwards@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFPBAcoY5BwjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517731131443?e=2147483647&v=beta&t=IUMgJE7acIp9mcohznsN7AcpXhHeVqMJWzUIDcA8vW8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bryan',
        lastName: 'Work',
        emails: ['bwork@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGaxaeBYKNZ3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587751349525?e=2147483647&v=beta&t=ddBpqMHQLN_FbWaMe-0JrJd0u0vO5iAsnS3oTtxbl7A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Hannah',
        lastName: 'Haas',
        emails: ['hhaas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFCrDgfsUbIug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729706697261?e=2147483647&v=beta&t=8b-m-n6zLYa4o_L_To_1ZCPKMcKQieRMJWUaqWTCF2M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Pawlak',
        emails: ['jpawlak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFnF5kifghKCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673574152111?e=2147483647&v=beta&t=mdaTwXYDn0E8dbXLlG-9aavbG0BQ7LB0jUFDxngfAiU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kirsten Smith,',
        lastName: 'SHRM',
        emails: ['kshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGGGl3V2nUbWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692296385180?e=2147483647&v=beta&t=JOHMS8T3kKSfDm7W6hWswWCT2G-a4bdN85w-p26rWRQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  compunetix: {
    name: 'Compunetix',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gerard (Jerry)',
        lastName: 'Pompa',
        emails: ['gpompa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLk1PTzITIag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1576432431739?e=2147483647&v=beta&t=ZD3EWoqYlYHnRutg3M7DMB_VbrCtCuXObkwkNRkckSA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joseph',
        lastName: 'Kasunich',
        emails: ['jkasunich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF-_pZxy2Ehtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649105666647?e=2147483647&v=beta&t=GpNM70szg6OuH-Z7x_t_xMnVTaiTAZU8G2W1Y4cSKxg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Robert',
        lastName: 'Haley',
        emails: ['rhaley@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ryan',
        lastName: 'Klingensmith',
        emails: ['rklingensmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG3lpY3e6bAHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592829713476?e=2147483647&v=beta&t=-1QOkuAaZunnpEu4Sz4VHZveeT1CzKr847lnqT6qiIM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrea',
        lastName: 'Jackson',
        emails: ['ajackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHv4XIVRFImaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636210446564?e=2147483647&v=beta&t=xtkYeoB2DLDCg3eUYKxJfQ1p-RN4R9PVw6YxMsrk8io',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tantustech: {
    name: 'Tantus Technologies, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Buck',
        lastName: 'Keswani',
        emails: ['bkeswani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHXfk5MzkWOQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607711668802?e=2147483647&v=beta&t=u33qb4pB7p7IlWazOUGpZiw4EJ5qm64F4d61mF1_LZ0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paula Staudt,',
        lastName: 'SHRM',
        emails: ['pshrm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Karlene',
        lastName: 'Stecchi',
        emails: ['kstecchi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFAQvNp8twrfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709581238281?e=2147483647&v=beta&t=uH4h1VXmazfRv_gKr0__Yuz2mvR3OSvG1dBvBl5uUzg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Catharine',
        lastName: 'Robertson',
        emails: ['crobertson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFunoZoXbwFeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719595925273?e=2147483647&v=beta&t=o_gNGVTmk70txwBziFcVDbVXvxeWLL7OjsjA3jqIUYs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Garrett',
        lastName: 'Gilbert',
        emails: ['ggilbert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE5o3ben4ZKHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570495535424?e=2147483647&v=beta&t=ySq1vXtAmc_7iLC1bbec2L9A6g17YKfsRAFcgrp0a80',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pulsarml: {
    name: 'Pulsar',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matías',
        lastName: 'Castillo',
        emails: ['mcastillo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cristian',
        lastName: 'Bartolomé',
        emails: ['cbartolomé@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Max',
        lastName: 'Ludvigsson',
        emails: ['mludvigsson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHzyue8XV3caw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687773083003?e=2147483647&v=beta&t=JZayYue2ghxRZuJA6NyTLVtV7FoUF8-iEcbGCTCJXKk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Katja',
        lastName: 'Boutou',
        emails: ['kboutou@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGvuh0QRKDRgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698927727952?e=2147483647&v=beta&t=JbqXMW9brUO2S_M978QGLl2Qs2KZhYn3MRH_StaDVH8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Giorgi',
        lastName: 'Duchidze',
        emails: ['gduchidze@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGfxwBV1f31EQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708721756917?e=2147483647&v=beta&t=NGnywrGvv0mIC2buasptoB_7wXFzj2jy0TXWXvAkYeA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  khazna: {
    name: 'Khazna Data Centers',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  remodelhealth: {
    name: 'Remodel Health',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bobby',
        lastName: 'Barbour',
        emails: ['bbarbour@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHVlHU1rwssQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709936698797?e=2147483647&v=beta&t=RgDFBR-fM-bIFI_afVvLIlEcBLrtIKPSkpH5x1VJWC8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Braden',
        lastName: 'Carter',
        emails: ['bcarter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGXhfDwnEWXEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650564695338?e=2147483647&v=beta&t=zcHOjet9zbLT6xS6cYHkxl-3f4-r8pI44KZV8gbvFz0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alec',
        lastName: 'Brown',
        emails: ['abrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGQr8U3LBcDnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645131483628?e=2147483647&v=beta&t=IxU0nP62GinCYh-H8uGjsJrzDsX15F0GFYoLRQ9u43U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kelsey',
        lastName: 'Frizzell',
        emails: ['kfrizzell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHSU6101IHp0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715355544127?e=2147483647&v=beta&t=VKCZjIUbDX8_gPwSTL7-imcBMqK6_7OsceqkDRrXVT4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joel',
        lastName: 'Eakins',
        emails: ['jeakins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHNY0-iDTCxJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729202633543?e=2147483647&v=beta&t=2UHga3uZRJOSF4QdGVgP2UJFIgNAcJuyuxUlHLZy9Qo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  marquisware: {
    name: 'Marquis Software Development, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Larry J. Powell,',
        lastName: 'Jr.',
        emails: ['ljr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHwtgy4vVcw7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603287813886?e=2147483647&v=beta&t=dXQp-HB_KV3yyjzAiFt74gub5sDXpP__b5xl4TiY2hE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marquis',
        lastName: 'Livingston',
        emails: ['mlivingston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGH2LQlTP_bAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725937630123?e=2147483647&v=beta&t=_iOOzqP67YKVGFIgsJVAzBrxjk_F3SXB_kfC-Az0PqQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Zachary',
        lastName: 'Rothman',
        emails: ['zrothman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQF4p3syrTBK0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516958467699?e=2147483647&v=beta&t=WqjwgzCH90NspDtuzlYM7d7u4F_HlzGXkXN6dMWn08Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shawn',
        lastName: 'Christiansen',
        emails: ['schristiansen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'Marquis',
        emails: ['bmarquis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  visualsound: {
    name: 'Visual Sound Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Karen',
        lastName: 'Bogosian',
        emails: ['kbogosian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFUGENntVsBQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517494827185?e=2147483647&v=beta&t=YZiREHoNEN8pu6xO0jVKwVvtOCLRTI2Q81tlTRuFM08',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel McKenzie, CTS,',
        lastName: 'DMC',
        emails: ['ddmc@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matt',
        lastName: 'Lees',
        emails: ['mlees@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHtNy1meGGQNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517020804559?e=2147483647&v=beta&t=bK0-08X8gI6pWH-V9kt3LEOtFmlV_NR0o8eqcYxfnxE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Thomas',
        emails: ['mthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGYeBdN1rWuvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517238263812?e=2147483647&v=beta&t=kk8KZ-2J1ys-VFL-Sz0XOy9lbedvXIMRMa6Lh2bSd_M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'William',
        lastName: 'Katinsky',
        emails: ['wkatinsky@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fuel4media: {
    name: 'Fuel4Media Technologies Pvt. Ltd: Digital,Mobile & Emerging Technologies, Development, AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Naveen',
        lastName: 'Gupta',
        emails: ['ngupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEsZuOmxcrx6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647017279033?e=2147483647&v=beta&t=YmvGFTIQkImNF0THxsToHbFcM7fXtxu1W-LYHbL6n24',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Somya',
        lastName: 'Dixit',
        emails: ['sdixit@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGirsNMwjvAQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517877260882?e=2147483647&v=beta&t=Qmf03EsFuFfQB0SZjhYQBXxSnxL3bvpBQvrAlJTaV-4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mohit',
        lastName: 'Batra',
        emails: ['mbatra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF9PeVQEz1PhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516973120257?e=2147483647&v=beta&t=GB9Ri0I1VHrmxcqozjCm96vWGjmqUm548tGFcBMDYzQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shivani',
        lastName: 'Jindal',
        emails: ['sjindal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE_dH-7PpJAmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517225990175?e=2147483647&v=beta&t=62sfgY9PlfapBcUdyplZMNEzueVlbf6mYokLeRsgl8g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bheesham',
        lastName: 'Sharma',
        emails: ['bsharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHkELyAEgKRpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606813249405?e=2147483647&v=beta&t=XHw1ep8n5GqBGM25J4Wqb0NG1quSFmMkcLRvqU05Hck',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sunlightsolutions: {
    name: 'Sunlight Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Brennan',
        emails: ['mbrennan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFkCYRXeXyXIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707274943828?e=2147483647&v=beta&t=YJxYh3EuCyqDNpDAQNCASUL1gPREtNHBe6h82AQrPSI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steven',
        lastName: 'Franklin',
        emails: ['sfranklin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Troy Clements, SPHR,',
        lastName: 'SHRM',
        emails: ['tshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEZvoWvPfk_QQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698158668189?e=2147483647&v=beta&t=LB8trBikQgrqbo9IgpE4KICLzJvPADcdZQy7mRPy7Fg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Florence',
        lastName: 'Lamour',
        emails: ['flamour@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bernadette Leh,',
        lastName: 'MBA',
        emails: ['bmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEMxXxAXpGD6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598360464925?e=2147483647&v=beta&t=1CPZnXRELiYkXbLe5dIhTiwYYW1k-HF3ISniEIbURPA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ciphertechsolutions: {
    name: 'Cipher Tech Solutions, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kathryn',
        lastName: 'McMillion',
        emails: ['kmcmillion@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stacy',
        lastName: 'Connell',
        emails: ['sconnell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE_dFvgC0xkIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709139028832?e=2147483647&v=beta&t=uJ4IEAv85Pv19MFYG9zzWaZnZASaKvZU9r0A5k_t25M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Adrian',
        lastName: 'Valenzuela',
        emails: ['avalenzuela@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHdHI_qPMvlXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715135809779?e=2147483647&v=beta&t=CwWFH3rLXP_rCC-WhtW_e6mO4XWf5cQJGaBQ_RXgfKM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Keith',
        lastName: 'Chason',
        emails: ['kchason@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEOjQ3VApNDdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710206605187?e=2147483647&v=beta&t=5XVQekIqSuKTZ8-02zUhkAlYSi3mcNr4CDtBx-iDPI4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eddie',
        lastName: 'Curley',
        emails: ['ecurley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHXfd8Q7J4iKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517757399314?e=2147483647&v=beta&t=_RLW_wPl_KgVPtW1wBmWfpZL89eaY8OUhE6fiHuVgpE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xyte: {
    name: 'Xyte',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrew',
        lastName: 'Gross',
        emails: ['agross@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHN9bboHnJ-Gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671483869328?e=2147483647&v=beta&t=DYCB0Y5URcd_XRAA775bEdnvFMFSQzUQR3HDPyOGT9E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Omer',
        lastName: 'Brookstein',
        emails: ['obrookstein@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lital',
        lastName: 'Grossman',
        emails: ['lgrossman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joseph',
        lastName: 'Vieira',
        emails: ['jvieira@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Revital',
        lastName: 'Vadnai',
        emails: ['rvadnai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHOGIKfMa0QLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601050769158?e=2147483647&v=beta&t=M6VYjs3Z6SXFJxyjq9supb7Rs0IFvw3H9Ehx0d4Jh0g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thoroughcare: {
    name: 'ThoroughCare',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dan',
        lastName: 'Godla',
        emails: ['dgodla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Earl',
        lastName: 'Hutz',
        emails: ['ehutz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEVRcvRUIfvDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662470209955?e=2147483647&v=beta&t=fb-EXB7fJNHyU-etCfB9rmIuDJjk2QwGufkqVB2LJnc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeff Smith,',
        lastName: 'CPP',
        emails: ['jcpp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHdhBkf_N3_6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632236113094?e=2147483647&v=beta&t=iRl5ZWw8Lw9ogR0MAf8iKDjkEDgd4NHJuQtzi6-FNsc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Loran',
        lastName: 'Sekely',
        emails: ['lsekely@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGk5OX0BxZYCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669214549160?e=2147483647&v=beta&t=xJLw7JINfJXz2simJ0ZDUWBLVuu-ZKcAMgh15BK0xiU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Deepak',
        lastName: 'Vig',
        emails: ['dvig@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF8QJ5-lOuciQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516301846307?e=2147483647&v=beta&t=ML9p2dd3J3Owinzo9P4CqGkThYq1j8BcASYWDtwiKTA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  everflow: {
    name: 'Everflow - Partner Marketing Platform',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Cole',
        emails: ['mcole@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFCSODpQfvv5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641239723055?e=2147483647&v=beta&t=njT5Zw0Fo-Ro00M-8_4zkouAgVmUQBoHi6NWIbiEnno',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Irina',
        lastName: 'Sergushina',
        emails: ['isergushina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEU6DwNQt3OmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641315221128?e=2147483647&v=beta&t=qRWHotr7JwC3AD4MRoy0VV2pwEou_iO5nwtWitlhB5M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ross',
        lastName: 'Moore',
        emails: ['rmoore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG73S8nJXhuIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641318299488?e=2147483647&v=beta&t=alee3mBN06WZ5WOFyK9IUcvAN3ZgQ7uLQKwRhC76D6E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Genevieve',
        lastName: 'Finck',
        emails: ['gfinck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHq3TxSsVc9lw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641318789670?e=2147483647&v=beta&t=kUD7i-aHU-iQR3a5OxhTXrrnEMKhvV-Hwi2kcLsY9rA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zach',
        lastName: 'Measures',
        emails: ['zmeasures@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEleIsxfCDA3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724417821169?e=2147483647&v=beta&t=hjQhbYY_x3Ee7r2n9RTlLdMZLAVomtA0COq6y-kIqLQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  paperstreetmedia: {
    name: 'Paper Street Media',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Owen',
        lastName: 'Kavanagh',
        emails: ['okavanagh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michelle',
        lastName: 'Roman',
        emails: ['mroman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEBnifwfS1yQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551489092835?e=2147483647&v=beta&t=dNsp1bPv761xhLeK037pVxG0bOAR-Xr_w5Cns8mTM8I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Salima',
        lastName: 'Sawani',
        emails: ['ssawani@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Edward',
        lastName: 'Rodriguez',
        emails: ['erodriguez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEPBZ-y00Ib_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620239460978?e=2147483647&v=beta&t=j_BIDwTe8YnOPqguIGeyZ0wgJE6HSmfqdYm_r00rHBM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'ariel',
        lastName: 'vera',
        emails: ['avera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE-1uWsdWMokw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607554804171?e=2147483647&v=beta&t=MYDdyBSFoVOPtCg4onodcj8phutbcgONj3qaNNc9NWg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  neonet: {
    name: 'Northeast Ohio Network for Educational Technology - NEOnet',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dan',
        lastName: 'Niessen',
        emails: ['dniessen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFfkUEZFlRU9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674580538727?e=2147483647&v=beta&t=HSfklTQE0OKoUmHWCQmctPYP8ujVvSbJkNcCDStYMVg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kim',
        lastName: 'Fassnacht',
        emails: ['kfassnacht@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEJId3icaVI6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669525176834?e=2147483647&v=beta&t=RkSUsyiivLd5sywNu_zIP1bscmmO5RnecfQqBOPmi0Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tyler',
        lastName: 'Reinel',
        emails: ['treinel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG6QeuRrd4elQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612428549655?e=2147483647&v=beta&t=Dx5R19TmQEoXVHA2_wRskhBs_6etFEzqD6oVRGLUJSA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andy',
        lastName: 'Melick',
        emails: ['amelick@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steven',
        lastName: 'Foster',
        emails: ['sfoster@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHZkqrKc07HrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671460783071?e=2147483647&v=beta&t=zjGM9aSmO-iPul8GeeCAJR40kptZ88uqM-CAlVXmNro',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kaizenanalytix: {
    name: 'Kaizen Analytix',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Krishna',
        lastName: 'Arangode',
        emails: ['karangode@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeff',
        lastName: 'Anderson',
        emails: ['janderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQERjVDwIWw33A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690459678318?e=2147483647&v=beta&t=5mRaACw44vV5bOULEeW-hYQW-PkiCzNnbR2O2wMR9BU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sujit',
        lastName: 'Singh',
        emails: ['ssingh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jess',
        lastName: 'Schuler',
        emails: ['jschuler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alex',
        lastName: 'Soltani',
        emails: ['asoltani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGV9k0pSfNMrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726845635320?e=2147483647&v=beta&t=jtaVVj30X3S14FLtgbsjtmoOOoGUdZrtyasu1wYnPgo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  intellisofttech: {
    name: 'Intellisoft Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Satish',
        lastName: 'Manduva',
        emails: ['smanduva@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sabitha Raj',
        lastName: 'Devarapalli',
        emails: ['sdevarapalli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGD6Yg3X--Qig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718219749323?e=2147483647&v=beta&t=4FXp5D5hNIfN21YU-rrW7ZEFxVnEyJw0eebbjzlQOT0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dharma veer',
        lastName: 'P',
        emails: ['dp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGmUMZ6k2CMnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708537286672?e=2147483647&v=beta&t=t0T-1HST61FDb_VxPdfLLBXkRQfVRsI7en7nOLR807c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bob',
        lastName: 'Raj',
        emails: ['braj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE57kit-UVGHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708871951909?e=2147483647&v=beta&t=DZk_EFimUHNczJC6F0p27y2gvmd1Y3fHkNopt-GYA7k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Manikanta',
        lastName: '(Matt)',
        emails: ['m(matt)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQECa0pm3eM2jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698159598813?e=2147483647&v=beta&t=ef18oT7f6R6qJOf_zF6ahW62wqYCu4zX0L7jBZjdI_I',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  worksighted: {
    name: 'Worksighted',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mike',
        lastName: 'Harris',
        emails: ['mharris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGLkLwbrqbbkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723824963179?e=2147483647&v=beta&t=6Vu_SzQdW0mu5js-yqxNrVCzbveaI5u6H52soFIj2bU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Barry',
        lastName: 'Rice',
        emails: ['brice@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHR3iPLMshNQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680701112352?e=2147483647&v=beta&t=TYdxTI60vDN2g7BvaBm1PI7fsn3cPxK-2njt5cTqzSo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marci',
        lastName: 'Branderhorst',
        emails: ['mbranderhorst@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH3E8c_mMl7FA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517567215282?e=2147483647&v=beta&t=XfSRGD3XBY3a-IpdADJL42KIeZRyUxxeDvMWrS5Bh2o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amelia',
        lastName: 'Suarez',
        emails: ['asuarez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nolan',
        lastName: 'Coy',
        emails: ['ncoy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFNmwSRKYsTNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668546330520?e=2147483647&v=beta&t=KB7bNkukjSNufGW8rpIk-7WuIfxA3ozF07mUaZJvrhU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  qbcs: {
    name: 'Quickborn Consulting LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Harrison',
        lastName: 'Molefe',
        emails: ['hmolefe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHZK0D1lUmV7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593910239915?e=2147483647&v=beta&t=pP5XcVGeYXfq_WBFTMl-afa3568eEB4n4iIEw7M8uzU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gabor',
        lastName: 'Tozser',
        emails: ['gtozser@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQERMj_HfoYj9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1532694565141?e=2147483647&v=beta&t=ND5KPQlZn_jUlBU1NAXW1q_92VBUXqCda41drdnSFc0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mohammad',
        lastName: 'Rabbani',
        emails: ['mrabbani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFNV3M-QbCs-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623029551084?e=2147483647&v=beta&t=DCwElCIPYMYaJ74_RsUlKIUwXPWHymye2Bmht5QkBkI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kalam',
        lastName: 'ansari',
        emails: ['kansari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHV0N4iUlGwig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578393830556?e=2147483647&v=beta&t=S3WExYN2917zvPLrM-5HCpDTguDQbi5BLJC39k9QXeY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Perry',
        lastName: 'Chitombo',
        emails: ['pchitombo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  catalystsolutionsLlc: {
    name: 'Catalyst Solutions, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Laura',
        lastName: 'Calvert',
        emails: ['lcalvert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFoQpcF5qMDWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542512125138?e=2147483647&v=beta&t=HYU7X5tVJ5UCWFfAwOJlXQnDm76YrUjqWwPBRjpAoTc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Leonard “LB”Buck',
        lastName: 'McGregor',
        emails: ['lmcgregor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF6TIKMQQcakg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557695355705?e=2147483647&v=beta&t=Q9OuzVVn7UVMGevCUPspMP7ten_-RXO9gBwnoKQe05Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: "Tamara O'Neil,",
        lastName: 'CFRE',
        emails: ['tcfre@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF3eed6Coz-uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701998826002?e=2147483647&v=beta&t=I-vWugtWHI0UKBbGwZEnLd7WCgl3EP7z87zAn-HbaUg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sas',
        lastName: 'Mukherjee',
        emails: ['smukherjee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFHX8VoGlZSCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543813613589?e=2147483647&v=beta&t=qrW1R4zvAzP6y4q6QN64RRtPtiHE-GrKtFQjMZhMdqE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steven',
        lastName: 'Krutsch',
        emails: ['skrutsch@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cways: {
    name: 'C>Ways Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Morton',
        emails: ['rmorton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Read',
        emails: ['dread@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQE4Ybf5B1iGWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517224769700?e=2147483647&v=beta&t=47399tBCfzMS91TjJ9ByfNtwn4W_5KUFQn9LF069bjM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Karen Marsh',
        lastName: 'PCQI',
        emails: ['kpcqi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHSeUwRtL9xyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516798822688?e=2147483647&v=beta&t=qs41vqCmuJFYCwLcCWAR0vNP-F220wlWfOJj3Yj_BK4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ft',
        lastName: 'Sport',
        emails: ['fsport@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFgrv1P4hvgqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522732658341?e=2147483647&v=beta&t=M_i7laTz7Zl_wd02ghmDdKNheR92pooesfdCyFQ-RBU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael C.',
        lastName: 'Martocci',
        emails: ['mmartocci@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF94SIif4cQ1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715040669982?e=2147483647&v=beta&t=9TRNoCAE0xfCIYNsWQL7GmkZ0jZi-6ouotcl47rgdoA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  khipuNetworks: {
    name: 'KHIPU Networks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Guy',
        lastName: 'Jermany',
        emails: ['gjermany@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEeLaM0ARycsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516326464349?e=2147483647&v=beta&t=fho9hWXb0s-PDLDziDONDx-hhyTMkZVYR2A9KBdsL8Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Callum',
        lastName: 'Palmer',
        emails: ['cpalmer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFjxgoFzTYu-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691825789321?e=2147483647&v=beta&t=2u5PWZjdAzfEQJI6mrzlC2d7WWO6sRudYMqvFTUISq8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jason',
        lastName: 'Taylor',
        emails: ['jtaylor@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Spence',
        emails: ['dspence@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jola',
        lastName: 'Tkacz',
        emails: ['jtkacz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  outblaze: {
    name: 'Outblaze',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Margaret',
        lastName: 'Leung',
        emails: ['mleung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHmGtNhDgWW6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590380631542?e=2147483647&v=beta&t=hzy_ig9YoBph3YmUwcjOe68o1EMa8NCjBj_jChYTJX8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rita',
        lastName: 'Kong',
        emails: ['rkong@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yat',
        lastName: 'Siu',
        emails: ['ysiu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE-Iyj3RYzgAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643157797857?e=2147483647&v=beta&t=qeyJOY7xglAaAFwKffqYKCSwxApct65QnzDZvLejuXg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ferdie',
        lastName: 'Petalio',
        emails: ['fpetalio@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jacky',
        lastName: 'Yuk',
        emails: ['jyuk@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  subsystem: {
    name: 'Subsystem Technologies, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Subhash',
        lastName: 'Bhardwaj',
        emails: ['sbhardwaj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGRsfFnKjH0ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589817059810?e=2147483647&v=beta&t=1ZNfZfYq5Q_5oZfeNTwWIzllIe656lC_pzETaJjRKCU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert B.',
        lastName: 'MacKnight',
        emails: ['rmacknight@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFqlvaP-pRnqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516308644234?e=2147483647&v=beta&t=gyk8UafU25T7d0b-1xYRVHsrVJLK7qnz8HVssXsdjRI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anton',
        lastName: 'Mavretic',
        emails: ['amavretic@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marv',
        lastName: 'Ryken',
        emails: ['mryken@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEI5R17-X6yKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516932494310?e=2147483647&v=beta&t=Eln9pmJNWv-TEYcbRrPpEuzjzK3-JwlFbfJjK54RFQg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pat',
        lastName: 'Sussman',
        emails: ['psussman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hubtech: {
    name: 'Hub Technical Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joe',
        lastName: 'Lovetere',
        emails: ['jlovetere@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHkBn2t50FdKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553627571065?e=2147483647&v=beta&t=priPd2XilcrS79p3pm1ooAvMqEene_NNTtzG84LsB8E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paul',
        lastName: 'Shiff',
        emails: ['pshiff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFN3J2WM_C55w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718236090610?e=2147483647&v=beta&t=uMknXoByhqSOZ05Rs0MFSaBZLH0mO8nkalnUBU-vsfE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'Paull',
        emails: ['jpaull@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHm4cPyq68Gmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517250475478?e=2147483647&v=beta&t=xSCywPq_7kJA08FbnwwXY9xOhUGH5zlcbJGWRwFwRsQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joyce (DeCosta)',
        lastName: 'Benvie',
        emails: ['jbenvie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGELsqv8yXl3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644945040367?e=2147483647&v=beta&t=HPzXlaQcrD9YMQGgqH3NBPrRfa48jbXUUsLxJO4jWQ4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Richard',
        lastName: 'Fritz',
        emails: ['rfritz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  enduir: {
    name: 'ENDUIR Cyber',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Chada',
        emails: ['mchada@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEMhaLSzkYdfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656181964377?e=2147483647&v=beta&t=hmKU8RjKjGclg6su4cej5zwBa2u6-eUnYFBtB5Okb8M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sean',
        lastName: 'Curran',
        emails: ['scurran@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Picton',
        emails: ['mpicton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paul',
        lastName: 'Cotter',
        emails: ['pcotter@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Badr',
        lastName: 'Khudeira',
        emails: ['bkhudeira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGOT-6qJ2jaOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583171386140?e=2147483647&v=beta&t=ZQpH8OgDqjTmCAV1PNXKnXkzbcBCcl9QYorRu9ysfpM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tecace: {
    name: 'TecAce Software Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steniz',
        lastName: 'W.',
        emails: ['sw.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHFOhdZUTUzeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594760356291?e=2147483647&v=beta&t=c-bZOEwlVmyT-4FTFoj6J3_G96VQNKXjgZZjqo4AD-o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Zachary',
        lastName: 'Schmitz',
        emails: ['zschmitz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFYqjUMtMXiqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708143066574?e=2147483647&v=beta&t=Dyuaw7duIyXA-LJCqvWdP2pmcly7RHnFe3oXvcX25J4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jakub',
        lastName: 'Szarkowicz',
        emails: ['jszarkowicz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGe2WrgqilNEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721363934997?e=2147483647&v=beta&t=ETwTN5ytqOtZqLH6gfsYi5ddPvSW78hDf2PVRyrolXc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kody',
        lastName: 'Nguyen',
        emails: ['knguyen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFekfrKVZh6EA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557089204019?e=2147483647&v=beta&t=Z3hK6h9O-ZDT6GXoFKS0wKPehx3o_AHVkGInGSjzIz0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Taylor',
        emails: ['dtaylor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEStidwnR3ITA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705385149934?e=2147483647&v=beta&t=tVQZiMzqpGCMzXd8jR87X7x5SeOBbFve2fVgSGY5T8Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  acsys: {
    name: 'Acsys International Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jean',
        lastName: 'Mouradian',
        emails: ['jmouradian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHV9cBtB6PXZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694423352940?e=2147483647&v=beta&t=jcFXvUx3GwyGW2ppA2l1hNB5fQ5URnozdpz3CFB95dY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matthias',
        lastName: 'Stegmueller',
        emails: ['mstegmueller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF5p-lJ31KxMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712687023458?e=2147483647&v=beta&t=HX7JWaTgBYD9Qp3_tCnWBG6dHAW_6S7jffzDHToIk7Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Muthu',
        lastName: 'Samy',
        emails: ['msamy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGVUMwjExbVaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707732238579?e=2147483647&v=beta&t=zrhX1q4jES80brXRjFKX2hoyvjPgavrhbjMIApnKSuo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'oseni',
        lastName: 'taiwo',
        emails: ['otaiwo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Epiphane',
        lastName: 'Afiankou',
        emails: ['eafiankou@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQENkXq0VELJGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648464972841?e=2147483647&v=beta&t=L7ihalZnkr-ZhPYR_NbrGkeUA_GpBAd3RlGFnQ_xomo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  multimediaplus: {
    name: 'Multimedia Plus',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Harouche',
        emails: ['dharouche@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHmRxpumNRZ-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722377183968?e=2147483647&v=beta&t=4YI9dhQd0WwIEjAfZhTNGR-OdDdrSGDSZ_YkbfV_jgk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jodi',
        lastName: 'Harouche',
        emails: ['jharouche@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFRpFm1Xpt6vw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602165640598?e=2147483647&v=beta&t=xgxk-drnMIXYFh-oflpA0d5kjMTHuABoWKzx6uUvq10',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'stephany',
        lastName: 'sanchez',
        emails: ['ssanchez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHw0jGyDivsWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678664639707?e=2147483647&v=beta&t=Ahv4mXpJi4cNjnwGbP4eJhVzeS8s12LYerxOqSAQKyU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anthony Deluca',
        lastName: 'CPA',
        emails: ['acpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFAjXWJWqYRpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516268128602?e=2147483647&v=beta&t=cMg8dziRzMOekatMkwSxK2IZxMW3CNJGerIMsNA7jcc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Scott',
        lastName: 'Griffin',
        emails: ['sgriffin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGS0MeM7oOiAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516870460447?e=2147483647&v=beta&t=dR-pEnJlEGW31OiJxrqpglTREY2QArkFcOCQUoWBpeM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  necComputers: {
    name: 'NEC Computers',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Todd',
        lastName: 'Bouman',
        emails: ['tbouman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFPsTggKA6P0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714492440107?e=2147483647&v=beta&t=PaEjdvIhqW-omxf63SHrCr4F2yDfiUGA5MqXVxuujvo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shintaro',
        lastName: 'Matsumoto',
        emails: ['smatsumoto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH0jf1UdSr_fQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687787427214?e=2147483647&v=beta&t=KRunW7Rvpp79iGVEtUCLymymTQofkvwzH-iD1rCN_r0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Takayuki',
        lastName: 'Morita',
        emails: ['tmorita@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Naoto',
        lastName: 'Mizuguchi',
        emails: ['nmizuguchi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christopher',
        lastName: 'White',
        emails: ['cwhite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGB2YTjB3D3aA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626653812146?e=2147483647&v=beta&t=DOUuP4dPBQcQL3srGUtPjiHXnMnnfFuINAjLB5wOlfQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  b4corp: {
    name: 'B4Corp',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bryan',
        lastName: 'Baisden',
        emails: ['bbaisden@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Andrews',
        emails: ['dandrews@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHrQMXT5vvW_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544498501133?e=2147483647&v=beta&t=HRe50tfLgBMsGCpaSDubY8iQah0c3fv17GAFF5O0Qx0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kathleen',
        lastName: 'Ebner',
        emails: ['kebner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rasyl',
        lastName: 'Guinther',
        emails: ['rguinther@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHNwljO1Vu3Qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708221589766?e=2147483647&v=beta&t=j2tK6o-6WWB58WFusr9G-SBXJlkz9kL-GiYBiXTpjnM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Burcham',
        emails: ['jburcham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQESvETW5RGwnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684695704171?e=2147483647&v=beta&t=oIQ7zOKPtPIzmr1L9WRdNixFL1JNF9wyMpGqbIZOBiw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dyxnet: {
    name: 'DYXnet',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joe',
        lastName: 'Sze',
        emails: ['jsze@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG-vLtditRlGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710805926009?e=2147483647&v=beta&t=9Efi6rUcPLg-h-UWGWf1QwqcHsogol3Dm7923aDGMog',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'C.Y.',
        lastName: 'Tang',
        emails: ['ctang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG-Aw4cUcVxXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706976798175?e=2147483647&v=beta&t=zkcFe2GRvcJYhpIP3al-HGT0qfDbAD5dLwv4t4TZg2M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ivy',
        lastName: 'Lui',
        emails: ['ilui@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Fion',
        lastName: 'Yuen',
        emails: ['fyuen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF8Ttd420ZeCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516765549496?e=2147483647&v=beta&t=TFC9j_fNuOh7vxeFcqv2eQumX0IavgcKuBozRvx0LUQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vivian',
        lastName: 'Tse',
        emails: ['vtse@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  creatuity: {
    name: 'Creatuity Corp.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joshua',
        lastName: 'Warren',
        emails: ['jwarren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGQVVYlQSBFng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713981483718?e=2147483647&v=beta&t=wv0FPpTqeglSxmh0Wigo9UAGAmBfOWd_cmuuDyM_SQE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Darin K.',
        lastName: 'Newbold',
        emails: ['dnewbold@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEdICw0O3GIlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726327051947?e=2147483647&v=beta&t=Aolnr5D7TavWQweBV1tdv35S13rqsnjOeW54JGcx9FY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jenna',
        lastName: 'Warren',
        emails: ['jwarren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFUozSQhp_MpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653152923349?e=2147483647&v=beta&t=PSlmjn--oMPljObj15yoLf3XabrkcLDdIL4ofxxnml8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shelly',
        lastName: 'Williams',
        emails: ['swilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGa44_K8fp6KA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1539192264816?e=2147483647&v=beta&t=ZgIgUj2P2zRuPOYx2-K57Lv6Rkypi5GpNo4rR9GjuKc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Natalia Pitra – Creatuity Corp. |',
        lastName: 'LinkedIn',
        emails: ['nlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  threewiresys: {
    name: 'Three Wire Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bill',
        lastName: 'Ogletree',
        emails: ['bogletree@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cindi',
        lastName: 'Clark',
        emails: ['cclark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGbvpFuV-5ENA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637946116445?e=2147483647&v=beta&t=2DghOal3St0QAhsvjpn7i7dkymp1eGVqsmcLK7Vyz4g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gregory',
        lastName: 'F.',
        emails: ['gf.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFhIosKbqUmiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688073066654?e=2147483647&v=beta&t=jcF3zmY6uzrQQQn97lwDW_parXqJgng7vTOkFfLfl1g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Donna',
        lastName: 'Norris',
        emails: ['dnorris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE_nvDSM42O0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578059996163?e=2147483647&v=beta&t=SswMsUVRu3aaWVFml3quKfUpAIdlb2ScDcFrWVG-Opo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nga Tina',
        lastName: 'Phan',
        emails: ['nphan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFg3BnTF3KRUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633113302721?e=2147483647&v=beta&t=OQQf5elZwZOz0ldFM815zQazO1MB7GYrLfFhkcdOtTg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  algotech: {
    name: 'Algotech Czech Republic',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Maya Sharan',
        lastName: 'Singh',
        emails: ['msingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGuhORfHzlwVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715858409094?e=2147483647&v=beta&t=LUOxBBqRwq9dGHLn9M0K3KeAwaWPGqbTzDESk-Pz7QY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marko',
        lastName: 'Nikolic',
        emails: ['mnikolic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH-jVEYTMKccg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638119551965?e=2147483647&v=beta&t=eYrtW_XoPeFLcdr6sL-jMD6XduGIPtD28XlXtwfWAQo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nikola',
        lastName: 'Bourov',
        emails: ['nbourov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGwNdwFGnR6Bw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516293538059?e=2147483647&v=beta&t=3ShVYssJanIIPvZ9h3pfh_RaqgvfYE54hG8bwC54coA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Valeria',
        lastName: 'Boyadzhieva',
        emails: ['vboyadzhieva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFDGulSqosx2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632727497866?e=2147483647&v=beta&t=ooTLY37tcFNwpL2sA5fesyelEPDI0Xr5x-JB55OxjNw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ramel',
        lastName: 'Bilali',
        emails: ['rbilali@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vpon: {
    name: 'Vpon AI Big Data Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kenneth',
        lastName: 'Leung',
        emails: ['kleung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEt53nciiO5EQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641401002561?e=2147483647&v=beta&t=U7--0mqcDEDke5yKO620jdLryBPYwz5WqOkxMDvX9KQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Queenie',
        lastName: 'Chung',
        emails: ['qchung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHUS2-1IDTdjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622430611818?e=2147483647&v=beta&t=aI8-tU_omLOb34dafe7R6-7gtB0ByEuyF3qSc0jhgDc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shiva Naseri',
        lastName: 'Noshanagh',
        emails: ['snoshanagh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE-50ChjfC01A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718218073076?e=2147483647&v=beta&t=xVcaESydr2S0ZgglczDDxbhLo8-HOqsAF3eFJSaaaQw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yu',
        lastName: '',
        emails: ['y@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christine',
        lastName: 'Po',
        emails: ['cpo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFlBw2fziyqbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696570668251?e=2147483647&v=beta&t=Ri6MsR25O3OZwLRoa5p4pCz51znp3uZJ13qJvrwhFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hikvision: {
    name: 'Hikvision UK & Ireland',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lorenzo',
        lastName: 'Chiozzi',
        emails: ['lchiozzi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGkTRHP6soPrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679074375300?e=2147483647&v=beta&t=dWCGt-Kdq4LPCTtkrUklr2P-CPA8kh9tHQZvNr5536k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sawyer',
        lastName: 'Tao',
        emails: ['stao@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEcnSnZ6kd1cA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719863985996?e=2147483647&v=beta&t=xiXR04KBW_ZqXcluX7LGY-nZ4qMYtnXrq-opjKJFQZU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amber',
        lastName: 'Bristow',
        emails: ['abristow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFxTTAxMbrmRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636125633719?e=2147483647&v=beta&t=-HzB7nJ0fx2BJvnNdHerx_tfqKm-N2TXHaHuBb4Qx4A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Milo(Yunlong)',
        lastName: 'Ma',
        emails: ['mma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFA_ZLFZoB1Bg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682860477059?e=2147483647&v=beta&t=ooD_F7vth_U6iIUoaJSR2VmC1W0rAq1_nc6xKiFLhus',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Hayes',
        emails: ['jhayes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGD3Xlvr6eXEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652286090736?e=2147483647&v=beta&t=nF1jogcX0LXXt2HgSHH6BTtCH4n--nDENMXFSqXB3vw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  avito: {
    name: 'Avito.ma',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ksjtechnology: {
    name: 'KSJ Technology Pvt Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kalpesh',
        lastName: 'Shewale',
        emails: ['kshewale@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ravinder',
        lastName: 'Suhag',
        emails: ['rsuhag@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFAx_22IXzfZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1533032260335?e=2147483647&v=beta&t=TiXShA9uLJxqawmEBQZcWLsUri0D5r644zAbPVdKcyk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Purushottam',
        lastName: 'Sonawane',
        emails: ['psonawane@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQENXr4K59Pxlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657539817033?e=2147483647&v=beta&t=W9-KpfBW6RIJwS9VyWaOtUWHwNzWrR0Mbfx40in6f-M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rutuja',
        lastName: 'Patil',
        emails: ['rpatil@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nesar',
        lastName: 'Ansari',
        emails: ['nansari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGowNmZJNEE9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671189320415?e=2147483647&v=beta&t=dKYjFlNK26wTSWIayAvnqimboSd7POP5q2dK2qJCpdo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  intersog: {
    name: 'Intersog',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Igor',
        lastName: 'Fedulov',
        emails: ['ifedulov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEZHHCMkSrn3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726164370400?e=2147483647&v=beta&t=mIyeLl6fePu_rzExtuAsgHIMkugZia-4Mgo6rI3cem0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Margaret',
        lastName: 'Dickman',
        emails: ['mdickman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFSfFthMLH96A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516270478549?e=2147483647&v=beta&t=zHQ8w08GuuHMJRJUOGts-CfjQb0xxwss-NdMJIdiWJ4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anna',
        lastName: 'H.',
        emails: ['ah.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEzA0vJ4UXwfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673788922718?e=2147483647&v=beta&t=F2kpn8pqAH3QO-X0u6J5WKDl-s_G6A6hMwhytSnZT5Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vadim',
        lastName: 'Chernega',
        emails: ['vchernega@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFb50ueXerNLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516302957932?e=2147483647&v=beta&t=U6bFwsQpsf8MHXnx8lt0dG-v2CPfmBX9Ryhx3FNbvqk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hanna',
        lastName: 'G.',
        emails: ['hg.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHA6AgVZWsgjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693235777252?e=2147483647&v=beta&t=xhorcqO8Qrt0AWP855K-1rVP288EFWg6DpVDX43a5T0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  osaavaservices: {
    name: 'OSAAVA Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sam',
        lastName: 'Arwood',
        emails: ['sarwood@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joseph',
        lastName: 'Bee',
        emails: ['jbee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG9MkQZbQWg-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659108927646?e=2147483647&v=beta&t=pB17HBHpLmNX_xCKQrMxrlK5219dmUI-HOT6FTsRrBU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Thaddeus',
        lastName: 'Atchison',
        emails: ['tatchison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG_LEihf-tYBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721047040885?e=2147483647&v=beta&t=cvedYVuBOI9MR6nsmkr-1NuyTetXYUmvxBWgNOgv3ak',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zelnick Munn,',
        lastName: 'MBA',
        emails: ['zmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQElv2fIwEzzUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688060133109?e=2147483647&v=beta&t=AHjxnUV27ZUGXKin-T-61N4VXIiAeiiOQBaaVqFp8u8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Isaiah',
        lastName: 'Kennedy',
        emails: ['ikennedy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEoGfP08nb2xw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703249729107?e=2147483647&v=beta&t=58AWpdr95HpRFqiXFsfD6f0chJ2FWFIa8p8qq6SkBl0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  panini: {
    name: 'Panini',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Tierney',
        emails: ['dtierney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE1D_GUDW786w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633435353786?e=2147483647&v=beta&t=Ma4n4JHgOLdTDb2mpA0JDlbN_u29dMn8VAFH5ijthaM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Howarth',
        emails: ['jhowarth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHBNnKDHKidhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682731567324?e=2147483647&v=beta&t=Efh9roXihgWPNu1mHVMmOwlnxm7PnZmLd1kzZiX2k7Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nicolas',
        lastName: 'Grossemy',
        emails: ['ngrossemy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHn1ZnOVydv5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673587084904?e=2147483647&v=beta&t=U_5cpT0oCQd3yh_9ODLSqC2ecZdNGzgOn8W5SWZsgUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Panini',
        lastName: 'Singam',
        emails: ['psingam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEB6muS-dE8pw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656086636962?e=2147483647&v=beta&t=T9rb_62T2jj2MmJ7Ettvu7jGZjo8cCOvDUmaWoYmQr8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jason',
        lastName: 'Quinn',
        emails: ['jquinn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHUMoSCzAZy5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1525855718008?e=2147483647&v=beta&t=0euG2GPd3oiWUVn-PFvUahGNbYKtH-nvBVOFqjnTCJg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  macedontechnologies: {
    name: 'Macedon Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nicole',
        lastName: 'Walter',
        emails: ['nwalter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEbMHcjQjAJgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691183883459?e=2147483647&v=beta&t=3TaQX8Lr_7d3Z2IN2ScTQ7UTE9a28EqKwME8nHhE5b8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tarang',
        lastName: 'Khandpur',
        emails: ['tkhandpur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFrz9r_o8H5qQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693773137484?e=2147483647&v=beta&t=F_bQRZ-ol85KIYrNzaMGg4bBfwcMsbr1pwqTPkQHwhk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alexandra',
        lastName: 'Lang',
        emails: ['alang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGoRliG9-IzvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588183761879?e=2147483647&v=beta&t=QeFrHGvrrCyDKVcJvik8JMabgdr7A_VWF5R3ylgWNkc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sung Sik',
        lastName: 'Kim',
        emails: ['skim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stacey',
        lastName: 'Wright',
        emails: ['swright@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH5tHUedhXg-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553447137757?e=2147483647&v=beta&t=mrmXBuvruMlmfm7X6803UQPMq8LGM7o7nUzgbZNtY70',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  soteria365: {
    name: 'Soteria',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Beverly',
        lastName: 'Brown',
        emails: ['bbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHTc-cu8xrIOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695150092636?e=2147483647&v=beta&t=sAgW8a6dLxgI3uSgDPColyheiCh6gHYehd93G_9stew',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: '_ Carl',
        lastName: 'L.',
        emails: ['_l.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEE1jSIBFJLuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517250449561?e=2147483647&v=beta&t=qx3j8VZJAHndAtQAraBEndLrXi49N1nfP_opgKd0708',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Derek',
        lastName: 'Shoecraft',
        emails: ['dshoecraft@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHg1amR-_W16g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698191433555?e=2147483647&v=beta&t=WYF6srVA2hKpEX5zoZJpHAj0Jr_UWOg2P3-R_CQGK48',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Haris',
        lastName: 'Ćatović',
        emails: ['hćatović@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHHYIwyYoWxUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548285328583?e=2147483647&v=beta&t=9brmNecvb_xWvYkAvWLuLbsvF-3aspncpToRPuxgCyA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeff',
        lastName: 'White',
        emails: ['jwhite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEX2C42INp1iw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517763591591?e=2147483647&v=beta&t=Rm8jGOk1IuWF7uQPbsIKWUnHAzb0gk9AHWO0EspW4RQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rangeforce: {
    name: 'RangeForce',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brett',
        lastName: 'Jackson',
        emails: ['bjackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGgwrtbQ9VVBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517716242847?e=2147483647&v=beta&t=L4P_a835EcmgGs2n5mWgu_fR5t894FnY2jvvsWliOfw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Colleen',
        lastName: 'Calahan',
        emails: ['ccalahan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH0pDIaNGCf3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614955676276?e=2147483647&v=beta&t=wMkGrP9NBoivik1Wy00JGcnk_agD6eYkkcESPkK0oa4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kim',
        lastName: 'Speiser',
        emails: ['kspeiser@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE-E4VsFeqxmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629155231370?e=2147483647&v=beta&t=LsNm-IXtGeyz9JpiEcNiH456J-7AwQfQXE8BeDAm7qE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Etue',
        emails: ['detue@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Raymond Green III,',
        lastName: 'MBA',
        emails: ['rmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG70PHQZ4D2tQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718241539157?e=2147483647&v=beta&t=9HPX8ZNgV_YZ3FjKN45n_Tyce2-h7OzkjWcsh9NC_FU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  msp360: {
    name: 'MSP360',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amy',
        lastName: 'Olivares',
        emails: ['aolivares@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEyhIFT24YX3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636395928145?e=2147483647&v=beta&t=c4k6dUtgV2lp-hLjh_gZFYi4KTO0BH6AXHJjx33bIAs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kurt',
        lastName: 'Abrahams',
        emails: ['kabrahams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEs3uhR2JKugg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688650141902?e=2147483647&v=beta&t=DdVjSFk0tBe0IY-siK8DuL-Pl4R_5T7Uk2s6WzU0058',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christian',
        lastName: 'Molina',
        emails: ['cmolina@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mikhail',
        lastName: 'Krasnotsvetov',
        emails: ['mkrasnotsvetov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Samuel',
        lastName: 'Garvey',
        emails: ['sgarvey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEX7c0jPaySdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678202158720?e=2147483647&v=beta&t=fqu1QRbrGiWfQ_t4yZRrwvm6SA2wDs1EBvQeGJuo_Eo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  virtualmgr: {
    name: 'Virtual Mgr',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jacques Kouevi,',
        lastName: 'MS',
        emails: ['jms@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEEKchCtcoJLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589403112060?e=2147483647&v=beta&t=YgZG-83u4B91ckTrx3XSH3SgwSAo_pvpKwVN0QaWxZg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tony',
        lastName: 'Morocco',
        emails: ['tmorocco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG_OfZU9yCA4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623673103047?e=2147483647&v=beta&t=HiURJURH45-t-ifQ_UL08IePnM6oEREVkloz58KMrR0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Whitney',
        lastName: 'Bogard',
        emails: ['wbogard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEpgmfn9Miczw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628877037714?e=2147483647&v=beta&t=e2d5SMA3XDWGdqJZo2JKNTxU1kNARZ5ERg_GTlWflr4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christian',
        lastName: 'Torres',
        emails: ['ctorres@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF-thc92A4rwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680802737240?e=2147483647&v=beta&t=P05pF8uLJwVyOnCIttZ5L4U42MSkNZreml4thgb-_4I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steve',
        lastName: 'Fillingham',
        emails: ['sfillingham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGVFZsFNHwOgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587183346431?e=2147483647&v=beta&t=HPSyoUnXwM19IZ4_UwkIiWTtkozVLC5jrFZ9yRoU_XQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  accelLabs: {
    name: 'Accellabs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Bilokobylska Svitlana – Senior Business Analyst – Accellabs |',
        lastName: 'LinkedIn',
        emails: ['blinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEKYPMwncHaFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720783764563?e=2147483647&v=beta&t=nJXKqOEULWiNltjJREj78TGMAFM4RrUcZB9KlfG1QeQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aleksandre',
        lastName: 'Togonidze',
        emails: ['atogonidze@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFYLCvdC6S2vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632479133601?e=2147483647&v=beta&t=kg8LDJ5NaJcycTIE-9BSzldWIwCG47Va39jOVSBsmL8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anna',
        lastName: 'Morozova',
        emails: ['amorozova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFx90FVqwCFuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639037328277?e=2147483647&v=beta&t=DflZ_V4W57218XYYiZkiPJiPcY1xFuysINbjbZLb5Ks',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Zelensky',
        emails: ['mzelensky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHXvr_oFhNjXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517813704654?e=2147483647&v=beta&t=maoeXSrSQI0gZTRfODUn_bueF6gIkDPLowOX0hXh7zI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michel',
        lastName: 'Assad',
        emails: ['massad@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vv: {
    name: 'V V TECHNOLOGY',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jason Boon Kiat',
        lastName: 'Lim',
        emails: ['jlim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF57P3-eXKapw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705720871801?e=2147483647&v=beta&t=eSRx3iyQn3StGj08hgtEnjdCNF0ltEc5CDYSXF69bK4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'ATHIRA V',
        lastName: 'V',
        emails: ['av@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHsxJcOGyN_gg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1545915383879?e=2147483647&v=beta&t=VPiOKaNeGA-oEyP2k52ZmpQCYpuoXBnRC_fEy14u7wI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'V V',
        lastName: 'Anand',
        emails: ['vanand@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH7JkNjr2Xnpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633518739848?e=2147483647&v=beta&t=Bjdv9FN7FHFJeWHdBznZoXhJDtNknrrL3aK0PKE_8V0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Deepthy v',
        lastName: 'v',
        emails: ['dv@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGpzOtQiv2KOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605184979255?e=2147483647&v=beta&t=Fi0CtwbazXmWi69VoThJMKQF-GZlnAV0DNS6gle6JU8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vipin Krishnan V',
        lastName: 'V',
        emails: ['vv@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEKk3LpJgPsSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625400295262?e=2147483647&v=beta&t=dNhO0blsICBHs-oP7cQy8c-EZHCxURioR9O-kfKIY9c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  enable: {
    name: 'enable.services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joseph',
        lastName: 'Bushnell',
        emails: ['jbushnell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH3MZzxL-nu8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718195887538?e=2147483647&v=beta&t=9gj21Bntb0qseMrFdbubeJJSQd0L96VCrrVR2nOoxuA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Bushnell',
        emails: ['dbushnell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jade',
        lastName: 'Morley',
        emails: ['jmorley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHHdLvD5Wi9SQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668368430737?e=2147483647&v=beta&t=3R4iojU6CrRLKAn0Yph6PYfD60wjJZLR3ZzlLNOsuYs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sam',
        lastName: "O'Hara",
        emails: ["so'hara@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGlmOHYQ52C7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647524664352?e=2147483647&v=beta&t=2RIvSuWxpfJBI8sZ12BVB8An2XwS9vHBB9Cn3lO0EDs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Liam',
        lastName: 'Browne',
        emails: ['lbrowne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHram9AHBuT5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675197157732?e=2147483647&v=beta&t=Nuj3dChbifakWF2MtXqLr1dt1stcxA2AvPTUe9ZQIpc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nexuspoint: {
    name: 'Nexus Point',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steve',
        lastName: 'Smith',
        emails: ['ssmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEbfiVafiHo_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516274673460?e=2147483647&v=beta&t=tINfPIvVYwZLTC6xgL_6KRvIoynPGYOrtU_y4rrphNU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ben',
        lastName: 'Giller',
        emails: ['bgiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFjVbzOu40Yyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517235742923?e=2147483647&v=beta&t=6NWJEKLOtIznXoWxVPcH51Z2H5x4gGKQuU6e-QfWNwU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matt',
        lastName: 'Hanson',
        emails: ['mhanson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEi_bY1TzyrIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633214591560?e=2147483647&v=beta&t=Tq9tj-XU_51m_sW5ZWGRM1K-Mj_t9rKgG6MxCCNTT-s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carla',
        lastName: 'Corris',
        emails: ['ccorris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFcA7V3PgzofA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585734743325?e=2147483647&v=beta&t=iUSP8bpTAfFD_oXai98YbXg3Eh_LO3ZIUGXg6Cin9y8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Luke',
        lastName: 'Gu',
        emails: ['lgu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHdW2ljGQFdKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622998279410?e=2147483647&v=beta&t=fw5LpulSaLcdsdEtepkBV5Fkr3bOR4oLiO9Ihy0HSVU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  omegro: {
    name: 'Omegro',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Bradley',
        emails: ['kbradley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHC27xx84D67w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673386431724?e=2147483647&v=beta&t=J1lA0wcS43Qlw7HXKUAnWRA6eHdo0UOk_ZMUKBYdy7I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emil',
        lastName: 'Pedersen',
        emails: ['epedersen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEKMznF_B082g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656351345670?e=2147483647&v=beta&t=lUvY5cd9UEPdoLTvA25H09xBWPG975N6guJse3DF5VI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Turner',
        emails: ['dturner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHkKdEgm2fJgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595191426521?e=2147483647&v=beta&t=lokgGi_W4_ARHSSg0x1jG4OaEAfdSlNXouKSOaiRxKw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Clair',
        lastName: 'Dyson',
        emails: ['cdyson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Troy',
        lastName: "O'Connor",
        emails: ["to'connor@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH4VTh_HEocQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671408706976?e=2147483647&v=beta&t=OVndhlkIV9kh1tPNdzX-yDdTeybJ3oIE871K6h2zn-M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  coldbanana: {
    name: 'Cold Banana',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ahmed',
        lastName: 'Ennab',
        emails: ['aennab@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEqB4DK1tRwNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706001479561?e=2147483647&v=beta&t=eY-bw8DPV4oSxfGKJJBcMU7jaDKgt1fhFfvRFQkQ0iE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Luke',
        lastName: 'Fribbens',
        emails: ['lfribbens@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carly Keeping',
        lastName: 'BA(Hons)',
        emails: ['cba(hons)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFTlmz6FQ-iFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689707725618?e=2147483647&v=beta&t=oPESbu9ptcxh1rJHX1med8MzF2ZrTloUiatv5Q8qMTQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ollie',
        lastName: 'Storey',
        emails: ['ostorey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQERFAI4wg7QHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645538465580?e=2147483647&v=beta&t=gQUm2oO5dMwxyHyRBhzho4-cFt8ZzdDrjgkBAeWEyXw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andreea',
        lastName: 'Marginean',
        emails: ['amarginean@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHSSEC9-lyDag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656527407085?e=2147483647&v=beta&t=3UQfKuKdJkzGSo5WkgjZxVALK9aECzefK5EASquycbs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  exclusiveNetworks: {
    name: 'Exclusive Networks Deutschland GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jesper',
        lastName: 'Trolle',
        emails: ['jtrolle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFpuBivWoQrHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602472932948?e=2147483647&v=beta&t=FC-FWjCjJvbgIcWceEiGEcy80VitWEPk6wj8juy-7xQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ruth',
        lastName: 'Parker',
        emails: ['rparker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFWJFkSAv7Tmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695955823674?e=2147483647&v=beta&t=7MXUjSir2-9PEUBNYohDySJfnxXeXjnaUKfsEUf0srE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Danielle',
        lastName: 'Skipper',
        emails: ['dskipper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEGZ4Jbzaa9GQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718240321281?e=2147483647&v=beta&t=lbBavPDuQLaU7vxsgPKP4DkZgsph_fzIGwpTkj-_5_Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rich',
        lastName: 'Nedwich',
        emails: ['rnedwich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGzIoXcJz5q7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516283998863?e=2147483647&v=beta&t=-MFD_f8ufXOgmYjcZm9vVRv7LxO9xsjH0gftXSlQz_0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Laura',
        lastName: 'Trautman',
        emails: ['ltrautman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH0IR7j5nYb_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592273759464?e=2147483647&v=beta&t=OvMFN7lB0NY2LUCurTgGW-JYwhr9xgDvN8w16lPqVs4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rationalenterprise: {
    name: 'Rational Enterprise',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sophia',
        lastName: 'Isbell',
        emails: ['sisbell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHzklTV6eRQtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678134245586?e=2147483647&v=beta&t=eQihCLa1FYUIXOxBxnhqvKfZo6bD2F2aiY0_XsfbRUo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Todd',
        lastName: 'Montano',
        emails: ['tmontano@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEy1N3ygpJlDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595375596322?e=2147483647&v=beta&t=nGwEHlBKJ09HHVF7S4okdcqmMnF0ZZguCfvZRWISLws',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brent',
        lastName: 'Popp',
        emails: ['bpopp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGLFgegJKfHcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571283529633?e=2147483647&v=beta&t=GhJXhB39RgjYibgWhtXKxJDd4O21nDpl8478c59rBoA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tom',
        lastName: 'Preece',
        emails: ['tpreece@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEXqC2rZtL3JQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516890934772?e=2147483647&v=beta&t=O0EKppG-D-v_hKItL4loRleCqFLQTuAIz_uaBA-JZEs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Katrina',
        lastName: 'Kalashnikova',
        emails: ['kkalashnikova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHHkdfd4Gf-tA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1640037269191?e=2147483647&v=beta&t=Je9p8A18eJckHlLcANf9mfTP5bVn4p3a549Irz4YwHw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  valio: {
    name: 'Valio',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christina',
        lastName: 'Veldran',
        emails: ['cveldran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEjKaa31AWEJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642118336474?e=2147483647&v=beta&t=Z6caUzmW_3JOPLCV4RhoL0Wd1Wc7Y862yrdGrlE4Yhc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cindy H.',
        lastName: 'Young',
        emails: ['cyoung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEGdtKyEQHNlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721278353897?e=2147483647&v=beta&t=oUnp45wPsdU1ubJXXKDbgxtIs_OOMEkdVG1fP04w2lE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sam',
        lastName: 'Aquino',
        emails: ['saquino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEATG7NjIhnnA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516328296277?e=2147483647&v=beta&t=H2mYrMMuzf3GX3fNSaQeHtRfhvnJ7UihrZX0fMd77h4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christine Grenot,',
        lastName: 'CPA',
        emails: ['ccpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEjUC77naYSnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575311805859?e=2147483647&v=beta&t=R_xdIxyqY7HHvnwo0Zlvhzhjybe_1qeYCLXpAkWCrz8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mari',
        lastName: 'Meriluoto',
        emails: ['mmeriluoto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFyQMzTrXkeXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553212459358?e=2147483647&v=beta&t=XoshCOiI_9ze9I5y4wWbdrsNYQSZayyucqXLBct9ec4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  camnet: {
    name: 'Camnet, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mollie',
        lastName: 'Swisher',
        emails: ['mswisher@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Angela',
        lastName: 'Garcia',
        emails: ['agarcia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG-Yj3TmOWWrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516949472830?e=2147483647&v=beta&t=KAtY0tEsmxqZQdBW4tyrmKIqAKbHxvZUjJ-b75VxJ4I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ronen',
        lastName: 'Goldstein',
        emails: ['rgoldstein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFKizJp_kV6rA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516893814720?e=2147483647&v=beta&t=kR87Hs0tCMcWb1bMg_j0JB9s34__hqLeiMCCbihtAJo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'Fernandez',
        emails: ['mfernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHtYKGAaRxw9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702414545675?e=2147483647&v=beta&t=OJjFu0RJOyqxV8YmQrt4qlSwORKth-pA7POKdl6YMdQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emily',
        lastName: 'Burkett',
        emails: ['eburkett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH9g0yg8ikbag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721140858983?e=2147483647&v=beta&t=4ZuHTwtlWGGvXegckgwFXW3GQgz1SHic_8Jqceigmyk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ursi: {
    name: 'Ursi Technologies Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sam',
        lastName: 'Simadri',
        emails: ['ssimadri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGGDrSAlDiYmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728067434191?e=2147483647&v=beta&t=jGGZxr962n2LOPNueBwPLyKfWkL3_fFjzJgAgOl3frM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Thirumal',
        lastName: 'Bandi',
        emails: ['tbandi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFyMOoYZG9hxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517315833711?e=2147483647&v=beta&t=l639NmjHSg_IzHdgbVM1wgQdPiMD_MB1aZICV-eOVIg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Srinivas',
        lastName: 'Pulletikurthi',
        emails: ['spulletikurthi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFQNdKgGWfThw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644855256279?e=2147483647&v=beta&t=44hzjh8gwjPdhEyrWhFgKCQMiFHlKKcedPE1W1XGOEo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vijaya',
        lastName: 'Meda',
        emails: ['vmeda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFsFcry-Di1cA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654795620613?e=2147483647&v=beta&t=8L4LqrKk4uhSZIkSc7PFZQ2mP5P7MB4Uzo7eQeh5bVA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Harsh',
        lastName: 'Gautam,MBA',
        emails: ['hgautam,mba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG1_jN6djefeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711563693077?e=2147483647&v=beta&t=LTdzO0T4K_lczDDUa2yGjEThMhkMLIcGJ-8J3peTVlA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  usenix: {
    name: 'USENIX Association',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Karen',
        lastName: 'Wong',
        emails: ['kwong@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Casey',
        lastName: 'Henderson',
        emails: ['chenderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFmiGjm8YRKsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649866429950?e=2147483647&v=beta&t=2TyCqfrkwHW2czEURV7DxJH2tkPG7dEYEsSJuHOd880',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cathy',
        lastName: 'Bergman',
        emails: ['cbergman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Laura Maguire,',
        lastName: 'PhD',
        emails: ['lphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE2zF_tq24VxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516624857457?e=2147483647&v=beta&t=EFW235vdVYOpd0OgcA2hO-94cYaYBNFGj49cCxvJ0t8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sha',
        lastName: 'Sundaram',
        emails: ['ssundaram@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ktlsolutions: {
    name: 'KTL Solutions, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Timothy',
        lastName: 'Lally',
        emails: ['tlally@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrew',
        lastName: 'Lally',
        emails: ['alally@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGn_I5vcvAhtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517401137452?e=2147483647&v=beta&t=vDyzi72zsY2-_qzajqB0_xzfB9OE-5_sI4bSks_dbUE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lucas',
        lastName: 'Chevez',
        emails: ['lchevez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alec',
        lastName: 'Toloczko',
        emails: ['atoloczko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFCX9WnPJXz7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715398001433?e=2147483647&v=beta&t=klopWJNafa-rH7tqGi5WyijXA7mt3lRnkgVeMjMCq8g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mike',
        lastName: 'Gargan',
        emails: ['mgargan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  liquidstack: {
    name: 'LiquidStack',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joe',
        lastName: 'Capes',
        emails: ['jcapes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH7ouv2D716aQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698351838194?e=2147483647&v=beta&t=rX0pp1T-ge5AAyUyAToiH4kCdLNyBEid5eNB_WeVegk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Angela',
        lastName: 'Taylor',
        emails: ['ataylor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG71-gaRThNIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516260985103?e=2147483647&v=beta&t=FfQHx4o-7FDWK6wd7cXFotKQn4xIVgHvkJz5AVq_AHw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kat',
        lastName: 'Carter',
        emails: ['kcarter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE-Py6261w_WA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689545889111?e=2147483647&v=beta&t=OfoGtVTxgHP1lxVPJxymilQ_Q5_gX2UWFRdla8KD1_0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dan Walker,',
        lastName: 'MBA',
        emails: ['dmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nick',
        lastName: 'Schweissguth',
        emails: ['nschweissguth@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ablefreight: {
    name: 'Able Freight Services LLC.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gary',
        lastName: 'Bull',
        emails: ['gbull@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rob',
        lastName: 'Adams',
        emails: ['radams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFK-htQAM-oUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687213849112?e=2147483647&v=beta&t=ir53PPSYAN4d_yZ8GpCC2-4bGYPjVxcg-1429g5pq9E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'Pope',
        emails: ['jpope@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGKrTNFITCC-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568839428712?e=2147483647&v=beta&t=x_sReXHVg3-fKkXGkpndLbVoBRIKQxEXTeB6qLwk5jw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ling',
        lastName: 'Ho',
        emails: ['lho@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE7QwgwJ_2IOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635798727938?e=2147483647&v=beta&t=2hlt-OH7hGn_y-3LBhQhnKbbop2RW0wbp1lJXPjHSE0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cassandra',
        lastName: 'Trejo',
        emails: ['ctrejo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  diversifiedrobotic: {
    name: 'Diversified Robotic Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wilton',
        lastName: 'White',
        emails: ['wwhite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFT0IVc7nRYZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598625325959?e=2147483647&v=beta&t=xljW7xnsQn0QLqbLcaZvhxPscRGACNMV12y_EUATzt8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brandon',
        lastName: 'Garrett',
        emails: ['bgarrett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHM9Sd-5RJGcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1530891745878?e=2147483647&v=beta&t=xFOF923L8XHoskjEj9MoY95tyO50v5kuxjnT3fPR0rc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Zachary',
        lastName: 'Rike',
        emails: ['zrike@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGjatm6Jzi87A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718398413814?e=2147483647&v=beta&t=LzrokaIABxDrQFwmhKtTFsgJqGJLKGTYgsPosWzGj4s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joseph',
        lastName: 'Brancato',
        emails: ['jbrancato@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tom',
        lastName: 'Gallagher',
        emails: ['tgallagher@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ascendix: {
    name: 'Ascendix Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wes',
        lastName: 'Snow',
        emails: ['wsnow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGZyOhpzZn3IQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666003915057?e=2147483647&v=beta&t=NgracqWrdyUtCj7STwo3kacVclfiKoquGrikkQzYdCI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Oleksandra',
        lastName: 'Butieva',
        emails: ['obutieva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFJyh58NfNqDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723485656896?e=2147483647&v=beta&t=uV1LHMZskIAvRwG0GQ4rPXwy7VnUdSfRZiyMUN5pDOQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alina',
        lastName: 'Snisarenko',
        emails: ['asnisarenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE7OT5rSTMbcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656007977520?e=2147483647&v=beta&t=uuQvROz4_IHyDCCnnLzdbJzQkLptKstAkMIUiM0TnaM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mac',
        lastName: 'McGlynn',
        emails: ['mmcglynn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGJhLtEohQykw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516434952176?e=2147483647&v=beta&t=9U7900A0IJy1mPI0vGVDGYN_buRSyb8HhfQya5cBN-A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Artur',
        lastName: 'Ambartsumian',
        emails: ['aambartsumian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFvUHTjyCiWow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608739640302?e=2147483647&v=beta&t=gSQ0o1KLxGofSv63nAmCUufoZrTtM_UzOXHo5gzHEys',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bradycorp: {
    name: 'Brady Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kate',
        lastName: 'Venne',
        emails: ['kvenne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFNw3uOwmZY9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674599758878?e=2147483647&v=beta&t=6Aqkg0MjH6lXmpRSzIcWqG7W2yOQUhIXO5nVa979O1A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrew',
        lastName: 'Gorman',
        emails: ['agorman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEw-GDNSs8dUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516534038745?e=2147483647&v=beta&t=svG5qKrGSXwoEndshnmSr3B5bKydTT7qI8rB9TeTNQQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ann',
        lastName: 'Thornton',
        emails: ['athornton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGBSlbJHJa1Bg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1533653019081?e=2147483647&v=beta&t=6gIv775IRkw6QAFNY65Hg9E7osljnByceC6M7tVvtyY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Hendricks',
        emails: ['jhendricks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHyisQjoa_niQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709725471650?e=2147483647&v=beta&t=lL5rFE1nT5_Wm2Cw4CBquImSiNFzxXncWGUZiKsj9FM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Russell',
        lastName: 'Shaller',
        emails: ['rshaller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGj0s14sNnrRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552490588102?e=2147483647&v=beta&t=RsNzaIueNLMAyJu091cWPP7Z48DTGY38fGCdyA8O-sA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  positrace: {
    name: 'PosiTrace',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steven Lee, CPA,',
        lastName: 'CA',
        emails: ['sca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGtotHn-k9o9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516263207255?e=2147483647&v=beta&t=tGNOIKPxf7oTY3n10D0fEwT96AifVg_dvtF3y7s-spE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris de',
        lastName: 'Eyre',
        emails: ['ceyre@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mitzi Garcia,',
        lastName: 'MBA',
        emails: ['mmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGzwAuzFM93qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578664239810?e=2147483647&v=beta&t=Pd1Ydf0iLtWSWE3lqnRVwJn85AOMNp1Tg4ZsGlz4f80',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aleksandr',
        lastName: 'Mazalov',
        emails: ['amazalov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEXfjKc3aOqsg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521560770880?e=2147483647&v=beta&t=lNybrYEvw2nfWudqfOVIQLYdBqZf__NV27i361fYZ_0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chad',
        lastName: 'Gill',
        emails: ['cgill@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  theventuragroup: {
    name: 'The Ventura Group, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mike',
        lastName: 'Sierra',
        emails: ['msierra@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kevin',
        lastName: 'Bright',
        emails: ['kbright@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Samantha',
        lastName: 'Dorony',
        emails: ['sdorony@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Steven',
        lastName: 'Rolander',
        emails: ['srolander@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tatiana',
        lastName: 'Gervis',
        emails: ['tgervis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFA1Bnh417lTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580601026448?e=2147483647&v=beta&t=QmUsL6qLOmEnJVeQQRyDdOvzZ9Q48uMLSfKqTbajKKo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  westcon: {
    name: 'WestconGroup SA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Comztek Mauritius',
        lastName: 'Ltd',
        emails: ['cltd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gilbert',
        lastName: 'Khosa',
        emails: ['gkhosa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cara',
        lastName: 'Grobler',
        emails: ['cgrobler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aletta',
        lastName: 'Appel',
        emails: ['aappel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nicomine',
        lastName: 'Wells',
        emails: ['nwells@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  evansdenham: {
    name: 'The Hub',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tom',
        lastName: 'Wood',
        emails: ['twood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFoL7_mKfa0Ew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728067854309?e=2147483647&v=beta&t=DCJdGhpSRhiofbHRSf2Vw1qRYC3R1BeWpg-1t0aYsZ8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stacey',
        lastName: 'Wood',
        emails: ['swood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGBBiRXxJJlFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728067941277?e=2147483647&v=beta&t=wKZiyXFBX95_I7QFxpFyqUIHnQpjuNWwByyM5-pPEcA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sam',
        lastName: 'Rudder',
        emails: ['srudder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHqhecPDYpitg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583575434052?e=2147483647&v=beta&t=nZIBNjA-0AhZQV1XD-p0lLkQaNZuBLRHF3fghrKCOPc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Allyssa',
        lastName: 'Callow',
        emails: ['acallow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHSWZE4jiEPTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694086557013?e=2147483647&v=beta&t=J2gniyjqPDhtoZTlPQizIyl5TONQGoLtPF44Jm6V4DE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Blogy',
        lastName: 'Hub',
        emails: ['bhub@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHkdpzIPRfPRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697567420726?e=2147483647&v=beta&t=cYvDOfgye35VDgfydBpdKmWqyJo_76H-uvPfp-eH7m8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hpdlendscape: {
    name: 'Lendscape',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dimitrina',
        lastName: 'Petkova',
        emails: ['dpetkova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGI_PWqMVUJWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517556234995?e=2147483647&v=beta&t=fjKke_7e6Ei4tZVCZEYD_Ye3K11UVZCTJGbEWufQHME',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Geoff',
        lastName: 'Snowden',
        emails: ['gsnowden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEKUuxN6Z6vyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1539900914979?e=2147483647&v=beta&t=1buo5wawVqzaPFaCvYyn1900yaBGqoe3Kjeuey8wYT4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Steve',
        lastName: 'Taplin',
        emails: ['staplin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Charnley',
        emails: ['jcharnley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG6klhQBiZPlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516787188953?e=2147483647&v=beta&t=kGLoXqZP5z9s1c0nCWBz6hNZZI9oiov-JqmMYIXCpS4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matthew',
        lastName: 'Burns',
        emails: ['mburns@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  winkingworks: {
    name: 'Winking Studios',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steven',
        lastName: 'Downer',
        emails: ['sdowner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGlhOs1yT27Qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720718520628?e=2147483647&v=beta&t=se_MMOjSQKm4c-hRqEgC2bJCLxVCIPdYICcROcl4g4k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Johnny',
        lastName: 'Jan',
        emails: ['jjan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE3J6jjs6HY5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704203501721?e=2147483647&v=beta&t=vqusMhyrmCj9EzCBG1l9ItfsmXFK5XF-ne9XUcQk954',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Gibson',
        emails: ['cgibson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQESl0EkbMKFqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729177843373?e=2147483647&v=beta&t=tu7niNw4HdxmFHxL-r5qzhEs_IK56kw35BNbhci4PCM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Arun Balu',
        lastName: 'Kumar',
        emails: ['akumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEhAcp3oXt-aQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701816504751?e=2147483647&v=beta&t=YtO0kA7zRQmo_0slmy-Xgl5GkndjG6p5X20hkOUrL6o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tina',
        lastName: 'LI',
        emails: ['tli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH9D6p7YWjPkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600322357231?e=2147483647&v=beta&t=ztts0gqIpho7iDLPAzsT5tVQe7An42Fu81SGvO_Md7s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  detroittrading: {
    name: 'Detroit Trading Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'Raab',
        emails: ['jraab@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGzMdOn2NNp6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668458779868?e=2147483647&v=beta&t=QFg8hGWitHwEgP78arIhnxCoVyKvriLokBZVfST1RhE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Campbell',
        emails: ['jcampbell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFr1ewdlV8-fQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516271648952?e=2147483647&v=beta&t=WCRo1mO_y_Oyyu6LrDwm9T8BGRkOe_M43FrcLk0pi0s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nick',
        lastName: 'Frederick',
        emails: ['nfrederick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGoXRgk8TDNcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561571665294?e=2147483647&v=beta&t=Ztc4uTKRcNbsjD3m5jHGtZACBPTDKlcJPAUG4mqMf4o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pete',
        lastName: 'Bonner',
        emails: ['pbonner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH7tuMlFGJ1gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700494321354?e=2147483647&v=beta&t=7pchw3r3T2eMC1PNARNU30QqAPbq1_U4VYo_a8zlfxI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'Hainen',
        emails: ['ahainen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGNMTXgLhDz6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689796377842?e=2147483647&v=beta&t=iN0F8fd3ZyLSMOAj7N1vEOESNjNb8HzF83ZVSGPXb_I',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  swg: {
    name: 'Service Works Global (SWG)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gary',
        lastName: 'Watkins',
        emails: ['gwatkins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Annabel',
        lastName: 'Morley',
        emails: ['amorley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJFl676dxSrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516485523059?e=2147483647&v=beta&t=6mGtzVkO_WyfaqkFuVJKimRxjauvLINxYaL2FYUgkzg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anna',
        lastName: 'Roberts',
        emails: ['aroberts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGE35hbsEPuzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547460144599?e=2147483647&v=beta&t=01GlQ479kiA1pPpKy22MwwoR5mBSRs1aTJiAykQ14yA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hazel',
        lastName: 'Bedson',
        emails: ['hbedson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFNFl3giNoH6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517685763294?e=2147483647&v=beta&t=sHyEbVhko_1HloNKZkLs1da-MRxg5mM-q1eNcWkF94k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adam',
        lastName: 'Oueidat',
        emails: ['aoueidat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGYeYvrRNfduA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673854461986?e=2147483647&v=beta&t=0lQtdBoZUnpSpYklRVuh8eYIkOO3YYdV1BM6iZJGvZM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  francotyp: {
    name: 'Francotyp-Postalia Holding AG',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'FP Mailing',
        lastName: 'Solutions',
        emails: ['fsolutions@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFIyv5JM78zbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610038023702?e=2147483647&v=beta&t=kHJUQboeunA9jSFaXcqejHOGrKUNG6gB-ZDueyQ60Iw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Johnny',
        lastName: 'Heintz',
        emails: ['jheintz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHglh9JFyOSww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718339587746?e=2147483647&v=beta&t=Xdi108Moxn8NC4ZzTa1hpzhwgwWHVadrqfM2b2xH8mo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nick',
        lastName: 'Raso',
        emails: ['nraso@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFJYXgET6MPgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516938965837?e=2147483647&v=beta&t=0MondXawGGQXVMy7hfbR1DUWXn3G-4NqnFg5BlJY57M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nick',
        lastName: 'Panacos',
        emails: ['npanacos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGzcElB04rujw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516505801593?e=2147483647&v=beta&t=hkn9DLiOkbK9rvGceQfyeiRLwHUgxHRveiLAcW5RfA8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Murughathas (Gus)',
        lastName: 'Nadarasah',
        emails: ['mnadarasah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGWygcI9cU5cA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516544170129?e=2147483647&v=beta&t=lKJRtw1qCwPTs3sW4uOwrr-QfKedBffeVb5zdlRghes',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cdsla: {
    name: 'Complete Document Solutions, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Urner',
        emails: ['curner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFfSgwiy8seAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651529933273?e=2147483647&v=beta&t=5pS8fqKSWXWlrzf42681NAkPlbTL1K9MyPNNJiXz65w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'James M.',
        lastName: 'Valdivia',
        emails: ['jvaldivia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHormpVnIHO4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516545273001?e=2147483647&v=beta&t=QdbGOl5HWVVtUoNQDv_PGnqHU_yIzinuICKph_0CW0I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Valdivia',
        emails: ['mvaldivia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE76LwdLxWXHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517717499842?e=2147483647&v=beta&t=MmGGD6d_1cbw56_FKXHDVcJTJFNkMkBGg6ENW0zQqpo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Jager',
        emails: ['jjager@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGRUBKXJbLYtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630539221296?e=2147483647&v=beta&t=jBazDns_daXHw4YutsFlVti_k9wGsxBPKZfuzhXI_-Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Glen',
        lastName: 'Kanzer',
        emails: ['gkanzer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFxt9z9JDWWOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516276975943?e=2147483647&v=beta&t=7qhipKQMhrMvSzrPbOmXEaKDhAuFS2dphhfQxSD3OwI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sarasoftwaresystems: {
    name: 'Sara Software Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Maninder',
        lastName: 'Kaur',
        emails: ['mkaur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHHUPPZ4tG5oQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632436051104?e=2147483647&v=beta&t=A6KMkU9QfXavnptJoUvuV2Lw0PbN7IgnzZBxR6kAjDc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Arvinder(Avi)',
        lastName: 'Singh',
        emails: ['asingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFpccO-hSdOeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555468616513?e=2147483647&v=beta&t=QXbZw3YV8wW55VQUejR_A129wHBj51kNJo61Mjkia_Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cindy',
        lastName: 'Gaviria',
        emails: ['cgaviria@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEbCA5K-ITZLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689274981355?e=2147483647&v=beta&t=7lQCm_vKhE9qt_2mek3PuSCyBdFIe9AM3UhnK3wcG1I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dhinakaran',
        lastName: 'Sundareshan',
        emails: ['dsundareshan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEd4twAGRVsAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1562599338651?e=2147483647&v=beta&t=32ce36m_K-tOPhRtPSGlqFirG3Fy9voiGPcYlYM3aXo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elaheh',
        lastName: 'Jabbarifard',
        emails: ['ejabbarifard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHLEc04Q0zJAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548996452407?e=2147483647&v=beta&t=cmb_zI4xargkBy9UQhm2k_nmjVCM500CIFMnZiQIaQo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  implementmyidea: {
    name: 'IMI Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Subhashree',
        lastName: 'Nayak',
        emails: ['snayak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFH6JaI39pWzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727538291627?e=2147483647&v=beta&t=zKZ2U_fobpQJjovfveoKVqlWY-4ZK5N5cyPfbGJ-8Lk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Syed',
        lastName: 'M',
        emails: ['sm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'M',
        lastName: 'Ahmed',
        emails: ['mahmed@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Piyush',
        lastName: 'Suryavanshi',
        emails: ['psuryavanshi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF6b7iprRJK7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669878485596?e=2147483647&v=beta&t=Tjm29FlXkza9OxB6PO3L08_Jx_VtSw096hB7YTgpyvk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sapna',
        lastName: 'Jaiswal',
        emails: ['sjaiswal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  reveltek: {
    name: 'RevelTek Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ayesha',
        lastName: 'Malik',
        emails: ['amalik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHFXHqCa2KT8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730507331948?e=2147483647&v=beta&t=dz4rK7Kqyq4R4fLq4hQJ7xP7tH6bDNrLbFV-akxXKyM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Waqas',
        lastName: 'Ahmed',
        emails: ['wahmed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEMp_uqKTc_eQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672789419330?e=2147483647&v=beta&t=spvogjR9jfEp9AJyajt3Wkd7Qj8RG_y_fjPCDbL1cf0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'daud',
        lastName: 'ilyas',
        emails: ['dilyas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHXNSYy0zEorw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692622283540?e=2147483647&v=beta&t=-6z9K4cLb2NDJYVEDOv0KQPpXFlHbQZdRjkqKueDhXE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Awais',
        lastName: 'Abdali',
        emails: ['aabdali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGjFaZd4CtH9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692533603957?e=2147483647&v=beta&t=xX7bMgRBIB8ZwmpkzG1LTcf3BJo-SGfVEZVzbE4UUkg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Fajar',
        lastName: 'Tariq',
        emails: ['ftariq@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hsm360: {
    name: 'HSM',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Blazenka',
        lastName: 'Urbanke',
        emails: ['burbanke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGECZrTZK4EKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516267871238?e=2147483647&v=beta&t=yJtrzBAtBwteZNeBEKwUoMC2W0v82jvy5pPovTx2nSE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maja',
        lastName: 'Kovacevic',
        emails: ['mkovacevic@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lovorka',
        lastName: 'Lucic',
        emails: ['llucic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHYubiYnLwpEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516445525693?e=2147483647&v=beta&t=1v7sJlnS4bmbbD98QKhrmScJ-5REpYLXNc_BDYcUyhw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jelena Jelinić',
        lastName: 'Cvirn',
        emails: ['jcvirn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH791sVsGUXdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729070303131?e=2147483647&v=beta&t=YRHvMo51bRmhxr5XHaHlnL4VWL2Wkmpns0jwkgHbIaw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ana',
        lastName: 'Petrovic',
        emails: ['apetrovic@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  qosmos: {
    name: 'Qosmos',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Belgacem',
        lastName: 'HLALI',
        emails: ['bhlali@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Danny Mitrasingh',
        lastName: 'Chetlall',
        emails: ['dchetlall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGun_hVF5kS1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715359627170?e=2147483647&v=beta&t=Nt8zyRffly9YyJzj0N-VFcMlKtQg71D2qKj0kcmw9M0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Fernand',
        lastName: 'Quartier',
        emails: ['fquartier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE0RZZzURtwhg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1617353515566?e=2147483647&v=beta&t=gsX1gAz9JvC3pDRsfmx8VfzahTFpHtLFCMpSzkNloNI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Thibaut',
        lastName: 'Bechetoille',
        emails: ['tbechetoille@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emmanuel',
        lastName: 'Roger',
        emails: ['eroger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHHiAYYjtSvHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516243923201?e=2147483647&v=beta&t=lEC6V8Enwfz7HjNXwTdQqAEf9TokWZDNQCPAV3dNbvM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pitttechnology: {
    name: 'Pitt Technology Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Waterland',
        emails: ['kwaterland@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jesse',
        lastName: 'Thomas',
        emails: ['jthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG1lBoSA16UCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694916526327?e=2147483647&v=beta&t=kHJHhwyE2LbCC2DjGbxm9-aknQiVLQgj0AI60kurgB0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Randy',
        lastName: 'Baker',
        emails: ['rbaker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQESvqS7ms3kwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707532900845?e=2147483647&v=beta&t=yU5DjR0uE3BxvxJy69y5KBIm7OJbv74gKRWlleWmc6A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ellen',
        lastName: 'Pollreisz',
        emails: ['epollreisz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHaBCAA7m2gAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516308476244?e=2147483647&v=beta&t=8hyBUWaKNLmv1rCe_DJhrI_rnBMwbyIemWqoscyFzq0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jamey',
        lastName: 'Brym',
        emails: ['jbrym@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFve_ec_MHWww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669127222022?e=2147483647&v=beta&t=URSP4vrZrojmU5RxgtUnGnMTHrEWwND87P8DqQAaZh8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  insigniatechnology: {
    name: '9th Way Insignia',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bill',
        lastName: 'Branstetter',
        emails: ['bbranstetter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEgxQUhyJti2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723382610150?e=2147483647&v=beta&t=r1HDzTdCYkRyoYaRud8Zq0lCBtCMjiO0ECJ4YDD82uQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nancy Van',
        lastName: 'Balen',
        emails: ['nbalen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGm4B_wlsF8Jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517751979285?e=2147483647&v=beta&t=v4rqLyfNptUql9QdRdNw2q-i1axviZZENTSEkZNdmfQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Melissa',
        lastName: 'Chapman',
        emails: ['mchapman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEuvUaD2IwmbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686237751863?e=2147483647&v=beta&t=ApivDcXbKc0uEaSEasI8ocFG6ewH3UNBvkLaJEn1Dfk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Will',
        lastName: 'Williams',
        emails: ['wwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFl4FOH7x4v3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516728767040?e=2147483647&v=beta&t=8tlpHjeD1yEx14HrKOwO7Rtd8O_fQZ1oPQDb4zBmHLw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eli',
        lastName: 'Harkey',
        emails: ['eharkey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFipTweF3ZdhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722452512427?e=2147483647&v=beta&t=XWL4gvlX5MNbtBQuUHkpDlC4lhM6DGtKj5chQscZJPg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bambi: {
    name: 'Bambi NEMT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bambi',
        lastName: 'Smith',
        emails: ['bsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGWWJw1gOet3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578746139429?e=2147483647&v=beta&t=YP03BrgWQINlTP9XUSGXZQ6BxyxGUCeM6BhOIYf907o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bambi',
        lastName: 'Ocmond',
        emails: ['bocmond@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHL6BWiEIPpLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517564139473?e=2147483647&v=beta&t=qsFudPsfpvrU9hZKREEjkn4BYlqfZwl6kTYbv_H0zCE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bambi',
        lastName: 'Francisco',
        emails: ['bfrancisco@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bambi Chris',
        lastName: 'Cisneros',
        emails: ['bcisneros@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGV5gouFqVdFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727288168829?e=2147483647&v=beta&t=IEUMiw1GGntIC85gp1BoK_TyeSohBB8xHBR_us9P8-4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bambi Press, RD,',
        lastName: 'LD',
        emails: ['bld@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGFuQcAhRkVUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589459821344?e=2147483647&v=beta&t=p0m7_4JDPDP4CjE2zbP-8q9uuhrx8VrCoSdadNcz42c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mstech: {
    name: 'Mainstay Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jason',
        lastName: 'Golden',
        emails: ['jgolden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFWtI2WkeFfBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568143744302?e=2147483647&v=beta&t=X3ptRlRd8t6VR1HNne-mzGg32TlZtM4EdMsvps-kD9c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ryan',
        lastName: 'Robinson',
        emails: ['rrobinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHh8L2l27J5ZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727821573020?e=2147483647&v=beta&t=z-yNPxPLgFXVZEPIYmL_tbcDEGeVpDblKcComkJvCAQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paige',
        lastName: 'Yeater',
        emails: ['pyeater@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHSsedN-quY6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651504945204?e=2147483647&v=beta&t=GIl2ei7SlmdKwT5oHESk26JWHQ19fN3cJZZeo42qr5c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Snader',
        emails: ['jsnader@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEVgqJ38XiXkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531189960280?e=2147483647&v=beta&t=CRNZhRY6G98abwjIPntrsEIMAlMAH9B860LX1boedH8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ashlyn',
        lastName: 'Murphy',
        emails: ['amurphy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFhklQeYjzZGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563279168112?e=2147483647&v=beta&t=uELBQEZtytfp0SEokatzuD5Z5LGQ4vcqtXLZR-5EWdA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rocketcom: {
    name: 'Rocket Communications, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pierre',
        lastName: 'Granier',
        emails: ['pgranier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGTFTUmsKPm6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516676907632?e=2147483647&v=beta&t=sSRz_RdIsT9bpaHMoRxyJ0fpzdhwzEyUKfwpvY8WWcg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Melanie',
        lastName: 'Wold',
        emails: ['mwold@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Duncan',
        lastName: 'McAlester',
        emails: ['dmcalester@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEEoagU5qBYhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516263576026?e=2147483647&v=beta&t=KbkzuIYmjfzay2kd-1yvggE9kZfpQKv6OolvXjWIHzI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Micah',
        lastName: 'Jones',
        emails: ['mjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQElb8uti7dk2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567633156652?e=2147483647&v=beta&t=wKWTKDsKm6yepZiIED1FjeBGFUZtlEkZ3ZAiS9577y4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Frances',
        lastName: 'Morrison',
        emails: ['fmorrison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGIUPjndj6-lA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602782578400?e=2147483647&v=beta&t=oN3NBi8QN_htWNhpQ5G3Y0Cbzd1eChrmk8EVFhQTDLw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lifebee: {
    name: 'LifeBee',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  spacetalk: {
    name: 'Spacetalk',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel Space',
        lastName: '🏳️‍',
        emails: ['d🏳️‍@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFfJrENUX-HSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722264948525?e=2147483647&v=beta&t=ELpJ8lAaj1yC2lDUHAd2ypuU6VSsMvS0ym71VHlKeOk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Fortunatow',
        emails: ['mfortunatow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEp0C-D5tXrCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628657600171?e=2147483647&v=beta&t=czrpRzI85WT9Mk4Pg2mbvsyWG5yUXNgVUOiv5XKnVCI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Craig',
        lastName: 'Boshier',
        emails: ['cboshier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF88GklycO4cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595810776418?e=2147483647&v=beta&t=zT04PRzefC9JCBUjLV9Y8_nauH6NvpFlaKCekW4jn1w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Benjamin Guyot – Chief Executive Officer – Spacetalk |',
        lastName: 'LinkedIn',
        emails: ['blinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGNnqcyltzINA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643289403709?e=2147483647&v=beta&t=wB7hp_gfvED4af9gwL5IuDQ_BadVhUNpeg0KLNR-c2U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeromy',
        lastName: 'Grimmett',
        emails: ['jgrimmett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF_TQZ278eb1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598413387948?e=2147483647&v=beta&t=TiC4E4TY2jC-xqaG-_BQ9VgA8Fnzb1PDa1cbunDkS9k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  crif: {
    name: 'CRIF GULF (Dun & Bradstreet)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Almas',
        lastName: 'Khan',
        emails: ['akhan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Manjeet',
        lastName: 'Chhabra',
        emails: ['mchhabra@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Atrideb',
        lastName: 'Basu',
        emails: ['abasu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'SYED',
        lastName: 'FAROOQ',
        emails: ['sfarooq@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG3Zq0RyOdSfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517540622400?e=2147483647&v=beta&t=KvpJWnLHirlzv1XrUXTv8nz77qLuZiMciNe1E3-ftCY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Roberto',
        lastName: 'Ciraci',
        emails: ['rciraci@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHsEXhl9u8BRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1533076181461?e=2147483647&v=beta&t=tptMSlTB94BLZEmW8C9tljxlEJSJCTH_J5EpmcRn6fQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tachyhealth: {
    name: 'TachyHealth',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dr. Osama',
        lastName: 'AbouElkhir',
        emails: ['dabouelkhir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEeWNo6ZLOcFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714619532854?e=2147483647&v=beta&t=gyJNqyTVfIw_951oe1xCtDRxSpesSSLbvUvSO-QJqkU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mohammad Khadra,',
        lastName: 'MBA',
        emails: ['mmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jamal',
        lastName: 'Alnaser',
        emails: ['jalnaser@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEKzlAYoFY2KA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574374029058?e=2147483647&v=beta&t=VbV0H0bb6zVcWvBQacUgmnEBbGPf5QlFHciwLDrijFk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dr. Ahmed Seif',
        lastName: 'ElDin',
        emails: ['deldin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHMoO0QLkGbwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516950404181?e=2147483647&v=beta&t=fg34ZaIzHThDS36Um1KNUkk997PDwhpeLPYSCtYKZdQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amr',
        lastName: 'Fawzy',
        emails: ['afawzy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHaww8e0MKyug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557701160668?e=2147483647&v=beta&t=QD6smbZMo81M7UBgrB4YGAlK1To6FBg45hRqeqk0h90',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  generalsw: {
    name: 'General Technologies Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Carolina F.',
        lastName: 'Costa',
        emails: ['ccosta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Juan Manuel',
        lastName: 'Villena',
        emails: ['jvillena@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFCCwvER605VQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725461196500?e=2147483647&v=beta&t=bgg5Zp-7-CyZEeeM7Wx2Go8EWTBKNiKR1Kr2gDb2Izc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rawad Hani (PE,',
        lastName: 'TE)',
        emails: ['rte)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHTXG81knIkRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677364356904?e=2147483647&v=beta&t=M4Uc2Z0GTq0lMmsrHuUrM8kU7UrDFqT_qccSaFD-V7k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Filimon',
        lastName: 'Debretsion',
        emails: ['fdebretsion@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHHrFjbAWH_TA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597904191795?e=2147483647&v=beta&t=TvXKgxBYS1g7nybnYio3g_EiU_cruWYmrM__J-_hmDI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mar López',
        lastName: 'Martín',
        emails: ['mmartín@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  anonos: {
    name: 'Anonos: Data without the drama',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gary',
        lastName: 'LaFever',
        emails: ['glafever@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHB_5LjBu0UJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666127577794?e=2147483647&v=beta&t=jgyBQYCX0aPm4_3O3UzOVfOdeveymPgvRkeRj4Ys4h4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ted',
        lastName: 'Myerson',
        emails: ['tmyerson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHn4R9YRKNshw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666056995373?e=2147483647&v=beta&t=V9Nnp0hykOWjyXy33XvJ9KH_3YeIfFrlppE_S112pbA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'Little',
        emails: ['mlittle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFAmFYamjNZyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666051580518?e=2147483647&v=beta&t=Zz6yk3PE7C54ky5zSstuSemMs_zw8RFVKNhZt-toEBs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Weishaupt',
        emails: ['jweishaupt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGt4XjOCM3TnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666190263298?e=2147483647&v=beta&t=7h3EPprSD5NJ6iJ4zAfe5FPiB632AkSXgQt_KURusB0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joseph',
        lastName: 'Saad',
        emails: ['jsaad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFlUU_cP7aBzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666993813322?e=2147483647&v=beta&t=MSCQGCmJHUVLGlgrzoZqzY46s1tPBADjyPKO1i7VT7w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  saqara: {
    name: 'Saqara',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Thomas',
        lastName: 'Cassou',
        emails: ['tcassou@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexandre',
        lastName: 'BROCHOT',
        emails: ['abrochot@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHJmbgZJMidSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572269769605?e=2147483647&v=beta&t=RqPdEyjMuaYHpwUKE6gkPV3TCwfvDmrKjWnwsqDw8fA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ann',
        lastName: 'Norman',
        emails: ['anorman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEuH2aOZ2vQfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593466022576?e=2147483647&v=beta&t=4Q_aBmsLjlsnUUfOT3fSToUdq7hc8Cg08nH9yXjaC3Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Baptiste',
        lastName: 'PONROY',
        emails: ['bponroy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG6L5i-vmcO1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607543276818?e=2147483647&v=beta&t=xump7aUvOzxhEP8NKGtqQr8wqaX8eN337ALsscRBJ1k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mathieu',
        lastName: 'Duchesneau',
        emails: ['mduchesneau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG3c-xdKcVuGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696005807686?e=2147483647&v=beta&t=Oq8k8Asc3j_-6wG1qVQVM9mbGlNs4meZE4JxcHqjkZk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  brightpointinfotech: {
    name: 'Brightpoint Infotech | Microsoft Dynamics Solutions Partner',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Navin',
        lastName: 'Mirpuri',
        emails: ['nmirpuri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEG9L8D8dKlXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721224741062?e=2147483647&v=beta&t=9kHh55WeOX_Q7W-yx1Un_AsDfduEMFTa-zs-SpD9k2M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ashley',
        lastName: 'Ebenezer',
        emails: ['aebenezer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFSf9e99sKygw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620990708584?e=2147483647&v=beta&t=7gC1ki3rzMBs_fNgJeUPZxoUDfW4Qa9vZsfPCGO2JWA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shannon',
        lastName: 'Walsh',
        emails: ['swalsh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH9IBzUc6aKZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699319499120?e=2147483647&v=beta&t=_0zjjcZABJNq7nSdXaem1dNLkzaHUbFA-RMJDf63LcM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dishal',
        lastName: 'Singh',
        emails: ['dsingh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Harsha',
        lastName: 'Chandiramani',
        emails: ['hchandiramani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE-tt5_8QDeKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620385963932?e=2147483647&v=beta&t=FUhYIxNF6Uowk8LaGUCtnqXPBPbD22vrEmMID9L_LJE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eclecticiq: {
    name: 'EclecticIQ',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ciaran',
        lastName: 'Bradley',
        emails: ['cbradley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGpZEKO_JmHWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606860039914?e=2147483647&v=beta&t=9APuodBzMX0ZVRkJBVeKIFEYaEldcf0tMiggRZKl6-s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joep',
        lastName: 'Gommers',
        emails: ['jgommers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHzf2vnvq8VKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713812172720?e=2147483647&v=beta&t=b87uvx4eUZqz-TXWxNcqxmrwxtJdXJ1WiWnn3YCcTjU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Steven',
        lastName: 'H.',
        emails: ['sh.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHpydQNgQHCtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726169303009?e=2147483647&v=beta&t=Yl91m3uWlQsxO_H1Rg9QYcE6oYpPGDEzhGoxj3Bl_oE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'James',
        lastName: 'Collins',
        emails: ['jcollins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Preet',
        lastName: 'Mohinder',
        emails: ['pmohinder@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dotnetlab: {
    name: 'dotNET lab',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Harsh',
        lastName: 'Nagalla',
        emails: ['hnagalla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFvLy4EG3EfGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582888087061?e=2147483647&v=beta&t=0NIU2r5rIelGqY57EOQ0F4cONMp5WCEotkM5rpHFIXA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrew',
        lastName: 'Woodward',
        emails: ['awoodward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE8HdEXlU73Og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516430100900?e=2147483647&v=beta&t=FD36sMemGCxIEaXiqTasy2saXnPdqwhGzTGCzojG9Ao',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shpend',
        lastName: 'Jakupi',
        emails: ['sjakupi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEk6aOzDeCW8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517048138343?e=2147483647&v=beta&t=p--KS8ICd4rWFy-dYRxjGA7IvF_HyFuQF9EJIZJ8oeo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Phil',
        lastName: 'Rowley',
        emails: ['prowley@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'NUSRAT',
        lastName: 'PRAVIN',
        emails: ['npravin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFN8Dk0LkT8qg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707792871602?e=2147483647&v=beta&t=FLuYcN-jrpRrvvY7wiNxjNLsfttvIa1qlkAUjMFczc0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sqills: {
    name: 'Sqills',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Camila',
        lastName: 'Sitonio',
        emails: ['csitonio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQErcYWzSNFmFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705071111141?e=2147483647&v=beta&t=y3hW73qL4bcI8agot-ymBs-vlI2E1vBWWhCaPY0R7NA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Erick',
        lastName: 'Cuvelier',
        emails: ['ecuvelier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQESGNmpPLYtiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516233010795?e=2147483647&v=beta&t=32BmPLu8klKgoZnHTVKbG14VG16uxAH8DIXWwIGIY40',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jonathan',
        lastName: 'Poyer',
        emails: ['jpoyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGVl5_4YqkIQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679936593184?e=2147483647&v=beta&t=M0PN9MJUoGY7N9Gs9Kaxdi2SmWpx2w1spqhKFLT5v-k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Elodie',
        lastName: 'Basset',
        emails: ['ebasset@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bart van',
        lastName: 'Munster',
        emails: ['bmunster@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHKVbSXMp5tCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627578636665?e=2147483647&v=beta&t=b0mEdGZ5nHTlTMHE7jdTZ14M9UysSu5qagqbTXvWJaw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  futureconnections: {
    name: 'Future Connections',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ray',
        lastName: 'Zeguers',
        emails: ['rzeguers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG-6y6uFV0O8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556026920970?e=2147483647&v=beta&t=kUj83qLR2TLflw7PpuMsGAnAhGFRdzjPDGTfQSROo_g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rowan',
        lastName: 'Emslie',
        emails: ['remslie@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Beatriz Moreno',
        lastName: 'Martínez',
        emails: ['bmartínez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEg9CKfv8cWRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573756135648?e=2147483647&v=beta&t=MvWslB0C5ai-ojY25ZtuKxhAy5mT7qDBko54_ICF3yg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bert van',
        lastName: 'Hoof',
        emails: ['bhoof@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFCO5kR3x0Z-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517485407826?e=2147483647&v=beta&t=pc9LEC2foZPA-w98k_w3YlecuforsLdOXnm0MCSsmig',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Virginia',
        lastName: 'Stuart',
        emails: ['vstuart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEVkaSI740iHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619510733490?e=2147483647&v=beta&t=H1qnNTHsiP1AGjt6yvw7VBXLK6WgumvGhvCQGEexS8k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mdclone: {
    name: 'MDClone',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brandi',
        lastName: 'Meyers',
        emails: ['bmeyers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFudZo-6P8gLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697125852209?e=2147483647&v=beta&t=eTKN62IuPTFDKxTl8GCog2oDg-SoQUuFNgFhNIrxDYA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bruno',
        lastName: 'Lempernesse',
        emails: ['blempernesse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFMxvD8xMdn9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725986307334?e=2147483647&v=beta&t=kKmdV_MlvlkT8rzUHm0QshGS5iu47vuFvCGWbSjHqL4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daniel',
        lastName: 'Blumenthal',
        emails: ['dblumenthal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ehud',
        lastName: 'Pollack',
        emails: ['epollack@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF0HAxLiuZkrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694329739471?e=2147483647&v=beta&t=0G1FxSOzJdDVTsDxVUIGIrzCK40rKhN6HjQOjTxDD5o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matthew',
        lastName: 'Whitty',
        emails: ['mwhitty@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  scaleflux: {
    name: 'ScaleFlux',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hao',
        lastName: 'Zhong',
        emails: ['hzhong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHVCo9dv5q4Ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695840430267?e=2147483647&v=beta&t=tF0c6P_uOMchPs9vqwWoo4jBUzf-CuIEckjqzeNfjpA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'JB',
        lastName: 'Baker',
        emails: ['jbaker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Romesh',
        lastName: 'Mehta',
        emails: ['rmehta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eric',
        lastName: 'Pike',
        emails: ['epike@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yang',
        lastName: 'Liu',
        emails: ['yliu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dakotasoft: {
    name: 'Dakota Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Kimball',
        emails: ['rkimball@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFCVrrzjg7ksw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598037680621?e=2147483647&v=beta&t=qBcD2qO5-gGhJ2fPn8qUQdU8FisNZuEpfd1uOstBd1A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'James',
        lastName: 'Miller',
        emails: ['jmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGFmtH2Wtcepg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564405283308?e=2147483647&v=beta&t=4s-9vUVqyAPKjOxiXkr1x1h0jvkCQL5xdbWZDiCKI8A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Greg',
        lastName: 'Habib',
        emails: ['ghabib@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFnihoGe7OHcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645044917677?e=2147483647&v=beta&t=EL0juvqbhRDD3mmzcilzH9dbSa5LJIN9FvtDe9SeuII',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aaron',
        lastName: 'Bachman',
        emails: ['abachman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH8X3cPt33gng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651799834407?e=2147483647&v=beta&t=kCg7xUW-GlEafUNrCdeIG2MvXrgvhUDnPpqq6qdHGZw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Ganczak',
        emails: ['dganczak@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  concordeu: {
    name: 'Concord EU',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ivan',
        lastName: 'Raykov',
        emails: ['iraykov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Debbie',
        lastName: 'McLean',
        emails: ['dmclean@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGFT1VjbUm3gQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727038802560?e=2147483647&v=beta&t=zvF4-Muwn9Jbqekgqtyi9l45DKCKLkCN2emhDu9Tk0A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tanya',
        lastName: 'Cox',
        emails: ['tcox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGfCihxe772qA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726217197635?e=2147483647&v=beta&t=tdkBwmaubzdhSri4dHt9I47tbdVp_cU3YEmd1RzgxpE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maya',
        lastName: 'Tanovska',
        emails: ['mtanovska@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nikolay',
        lastName: 'Tashev',
        emails: ['ntashev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEUJ4eKrpXIEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1520697946258?e=2147483647&v=beta&t=aTnq3KJ0wphescAv7MgcYFnc0IlvApce3ThRaI41neU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  techCareer: {
    name: 'Tech Career Israel',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tobruk',
        lastName: 'Blaine',
        emails: ['tblaine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG4Xyho6smnkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627663878838?e=2147483647&v=beta&t=0jyTs_z4mNhYiAb0y5IgoaHxYyc2K2FP3WhYXQ1cov0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Elliott',
        lastName: 'Esparza',
        emails: ['eesparza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHeIOFZFu6sKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591041117055?e=2147483647&v=beta&t=KXz7wi4JytjaJwtKtjRvetWDssAfUW3qcZaBIX3n05Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephanie',
        lastName: 'Brown',
        emails: ['sbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE9pxNssSIMUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685093580647?e=2147483647&v=beta&t=EnD20eqUBVS9sHjruvTSFs4I6fz8KU7_53QblK_flrA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Julie LaCroix, M.A.',
        lastName: 'Ed.',
        emails: ['jed.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE0hNsuEY-GqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516363930441?e=2147483647&v=beta&t=Nig35J4ID0w2u6AXvMNJAeIE2onaN1iQYmsED4rR59U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John Tarnoff',
        lastName: 'MA/MSP',
        emails: ['jma/msp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFFhssD-DVf1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681260524033?e=2147483647&v=beta&t=TjnRAbsfEHLqnxRqzdwqRpmP_Dpq4xppMQ5ZEgf5HY0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  signpost: {
    name: 'Signpost',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pascal Van der',
        lastName: 'Vennet',
        emails: ['pvennet@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Albert Vidal i',
        lastName: 'Solà',
        emails: ['asolà@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEHyEmo5Am3Jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626449238374?e=2147483647&v=beta&t=g3nu8d90KVzXaNFE9S0ZSx_DA7MydW7ADqgoP02tkDA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Albert Vidal i',
        lastName: 'Solà',
        emails: ['asolà@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEHyEmo5Am3Jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626449238374?e=2147483647&v=beta&t=g3nu8d90KVzXaNFE9S0ZSx_DA7MydW7ADqgoP02tkDA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Arne',
        lastName: 'Vandendriessche',
        emails: ['avandendriessche@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFxQe7517PqVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654019025825?e=2147483647&v=beta&t=iw3AHURvK2_lw4EGrCxqQnrlk_aGWLQzs1FxlmgTg6U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Timothy',
        lastName: 'Steleman',
        emails: ['tsteleman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEYOJnYhDWWBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647369085094?e=2147483647&v=beta&t=EWozWEVwGOFffCNKid6ZkbrBTzwr_Pll9TFtuZ5MUqc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  im: {
    name: 'Intermedia Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Cucinotta',
        emails: ['jcucinotta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEPcDh8Tcp8dQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516351149008?e=2147483647&v=beta&t=jewdjcDAkP2uxgGIJAd68PGe1KuXHjXkBor8NE9fMEM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'D.',
        emails: ['jd.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Renita',
        lastName: 'Collins',
        emails: ['rcollins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH2jE8QjmfCyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697081859318?e=2147483647&v=beta&t=f71pIzx3RmRXeIg0m5pATvNmTjxjbL1r46pYwIWVVVQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Banis',
        emails: ['dbanis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEDXNSReNjMpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568727752340?e=2147483647&v=beta&t=9OUOUp95_YOyZwi3kgcUop17tbF2LRXf5BqFgN617Fg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Farnsworth',
        emails: ['jfarnsworth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFcNRh1nj7OCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572964586892?e=2147483647&v=beta&t=7fOUSlUvj_sJ3iv5_YejUdMSu2sEcQSobiUZ90W-CuE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  alertSoftware: {
    name: 'DeskAlerts',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anatoly',
        lastName: 'Shamkin',
        emails: ['ashamkin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFlgrJkkSzPvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698023294016?e=2147483647&v=beta&t=gE2ovnP1Gj_JYuTcl3xKCrTEafhHa7WGJV0ddxozJG4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Evgeny',
        lastName: 'Zolotarenko',
        emails: ['ezolotarenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFcYHy6IuSm6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714172140822?e=2147483647&v=beta&t=qclcctrw2WVIGTZyNdJGujqyXfTv2696FvAvQrWGLwU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Artem',
        lastName: 'Kashilov',
        emails: ['akashilov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQElpmO3Volyuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1530003421069?e=2147483647&v=beta&t=1YTf1gbCw4HtmcW9IpGPPxBWQDia5wnydt4oz-pgu1k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexander',
        lastName: 'Maletin',
        emails: ['amaletin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFAO6679hnk8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517068746545?e=2147483647&v=beta&t=CZqm5AJ0SAC65LANtnFkLLlgNh0-Uu3g-oCFEdJHz9U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steve',
        lastName: 'Wilson',
        emails: ['swilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH3PaMeL8Ksfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516497566368?e=2147483647&v=beta&t=AHsSgPnDFeGCx3m3vAfH3P0UBeFrdafJ_b0Z62iE5bA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  epy: {
    name: 'Telematic Srl',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Fabio',
        lastName: 'Valenti',
        emails: ['fvalenti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFoZUOjGbxdQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551366419369?e=2147483647&v=beta&t=Uagm6PPEoGz1EMouWidHOInq_Rvs7K2PprCNshk6s0E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniele',
        lastName: 'Lazzaro',
        emails: ['dlazzaro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGGaDmI6xHK0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656003045261?e=2147483647&v=beta&t=y8L778dWFml1Zx8Sie53AiSlcvexFbgnL8aXiamoh50',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Giuseppe',
        lastName: 'Marra',
        emails: ['gmarra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF5ZmMHOUDJNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727080068489?e=2147483647&v=beta&t=I-OW6a-zZOzY6I3KrhrqxST81fCTbQF-KPrRFHvnwZU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lucrezia',
        lastName: 'Murrali',
        emails: ['lmurrali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFMOOFh6rNQZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660586904547?e=2147483647&v=beta&t=5Q92tg5UTYhTb2W2qAdMygWlxXVApUCCvbXNoUSHahw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sandro',
        lastName: 'G.',
        emails: ['sg.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGJbFUbvnjeEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633533072965?e=2147483647&v=beta&t=ME1B6Dh_hjpNWUiXR0aehV9t-5JssLEtJ7IkiZ8stFo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mobiliz: {
    name: 'Mobiliz Bilgi ve İletişim Teknolojileri A.Ş.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Yetkin',
        lastName: 'Aksoy',
        emails: ['yaksoy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ali Rıza',
        lastName: 'KURT',
        emails: ['akurt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nikolay',
        lastName: 'Kichev',
        emails: ['nkichev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEijDC9L45klA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650900239566?e=2147483647&v=beta&t=XJvkiFQLgeGMBSIt5kPQnwsloIXALXNbbbc_SxD8aKM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kader',
        lastName: 'SAĞLAM',
        emails: ['ksağlam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHp6cmkSfawbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647706533675?e=2147483647&v=beta&t=HnQqryES6gCjGxkYmAQuoq07bovorqTkVqxe9FtQ6KE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abdullah',
        lastName: 'Özden',
        emails: ['aözden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGM-eZcMSjToA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684327266696?e=2147483647&v=beta&t=a6dCV3tfAbsJcHaJa5K2n_k0exVquFkBq5kH2uZjMVQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xillio: {
    name: 'Xillio',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adrienne',
        lastName: 'Robert',
        emails: ['arobert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF-aUpmbQnnOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1599661431846?e=2147483647&v=beta&t=YWETfELZSmi-XPYkVrJIXbrgIhMAm4eMEKqyQu33FKY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stacey',
        lastName: 'Davis',
        emails: ['sdavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGCeAnkb-wPjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653620793071?e=2147483647&v=beta&t=s3VsZr4StXYhMxFu8rO-cMG6k3i16HrcooQVR5w85TM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alejandro Soto',
        lastName: 'Castro',
        emails: ['acastro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFVcSsspYHzBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693224059213?e=2147483647&v=beta&t=NRwEcG6BrRq_rsUYHjS98-0p8A_gZU1_nweEJS1qAx4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sjoerd',
        lastName: 'Alkema',
        emails: ['salkema@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEsvfOJMMQGUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721828786528?e=2147483647&v=beta&t=hByocBQDp11W9iXS8wH2DBbitWEv7zu5-f0SVDwYsBg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nathan',
        lastName: 'McBride',
        emails: ['nmcbride@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGS3nTsbsuN4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695568988801?e=2147483647&v=beta&t=4yGigDqjGoU_3lYemf2a_KZN3U-kCy-B6UdB7alLekM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cybermetrics: {
    name: 'CyberMetrics Corporation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dave K.',
        lastName: 'Banerjea',
        emails: ['dbanerjea@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGWem4RAtQV1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516788859460?e=2147483647&v=beta&t=B9ps720mK6i0imvUdpR0mMetKhCuU6Gs-sEsQ8Rq1cA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Devin Brent',
        lastName: 'Ellis',
        emails: ['dellis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachel',
        lastName: 'Straube',
        emails: ['rstraube@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Steve',
        lastName: 'Mulligan',
        emails: ['smulligan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGV-oFLWNAQyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578781474149?e=2147483647&v=beta&t=ap7o1niL013amqyw2NDf7kJkxdLlJ7s7C6Kg4dyNFF0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jodi',
        lastName: 'Straube',
        emails: ['jstraube@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGE4NPgJx3iRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517537767562?e=2147483647&v=beta&t=vGiyHNJQPUUyYXQujTsTD42nn1TuH5JkeK1-mF092GY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lightech: {
    name: 'Lightech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alina',
        lastName: 'B.',
        emails: ['ab.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG8wowfqB0E-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632396966611?e=2147483647&v=beta&t=JQfOssc-5OCMkIBkN8dnbMoyatgb7Lx_2Yjgk0gI74o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kacik',
        lastName: 'Richard',
        emails: ['krichard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEszJ_9WbJn0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516794600155?e=2147483647&v=beta&t=R8QRHuWlx6YKTsFQjNQCPNKx6donqWv6HZFDvrxlodM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sheila',
        lastName: 'Zaccaron',
        emails: ['szaccaron@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFmDDKxyrPBQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517601782439?e=2147483647&v=beta&t=lz21dLnAwPpbUK3CGmrTrEaFGP0QsK7QC6MKRET35WA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Noble',
        lastName: 'Amaechi',
        emails: ['namaechi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEehglgOen0DQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656513563164?e=2147483647&v=beta&t=-b4oe8FGMHdFp8pi4WihJ312G2X-xwMafYEJ1uzyD54',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'k Faldonne Damien',
        lastName: 'SORGHO',
        emails: ['ksorgho@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGwofMshRlfOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648074315759?e=2147483647&v=beta&t=pcOycwQXfF4zappW6jEY61g1KEl3LmNxGz343gRCyrE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  corepartners: {
    name: 'CorePartners, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Peter',
        lastName: 'Oykhman',
        emails: ['poykhman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Theresa',
        lastName: 'Lock',
        emails: ['tlock@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE0Gf8DAfEpPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665426812965?e=2147483647&v=beta&t=ByU8YKOGmJC40x5z2qvcyy0FNlEJ4N7fbfsl_YMW8t8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lena',
        lastName: 'Simkin',
        emails: ['lsimkin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHgvTt4Vah37g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517689792448?e=2147483647&v=beta&t=FfrynOF45Et9GMqB5KIMqMegl22YFqLzwpKa8iX16wI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Veronica',
        lastName: 'Lisnevskaya',
        emails: ['vlisnevskaya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEK0QGz38TgAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547905041221?e=2147483647&v=beta&t=bSzJn3qM3D-0yGCs_t4RWWc0RpAbgf55nwtMgs44M88',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Czeslaw Janus – CorePartners, Inc. |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH2GyUwZqWl2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516283518312?e=2147483647&v=beta&t=D4ego0jEjTufpml3HUrjO4fR96j6lXHKRtqZEN4-x7k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  caribbeandata: {
    name: 'Caribbean Data Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jenia',
        lastName: 'Bello',
        emails: ['jbello@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFMK9cc2eeW8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709229457598?e=2147483647&v=beta&t=kdf6JVUzsQC6SZLA1dHS27F79IpHyqsDk8xuDkKgjAA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'José',
        lastName: 'Mustelier',
        emails: ['jmustelier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFDLt0Xt7TMGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1524067395008?e=2147483647&v=beta&t=tpp6ou5uVmOoGeVkInN3zVxj95ahNZ8dl3tCBW0dOSo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Luis',
        lastName: 'Marrero',
        emails: ['lmarrero@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHiOMl6CgYR3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702927771937?e=2147483647&v=beta&t=x-2jwCYvCFwGn6HutZ9WrXmNZneWO71N2N8bvDbEsrg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Whitney',
        emails: ['dwhitney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGMRB5HUWPqIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516851880205?e=2147483647&v=beta&t=b1C6ES7tsManVNtfBKoKU6Azfn3nwZubHvOG-nGOQp4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dario',
        lastName: 'Clarke',
        emails: ['dclarke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEDLI2UL5cocg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694180523736?e=2147483647&v=beta&t=GMok4i4koEqHe4lDDf7F6uppS8zwxJlfc6LAPdxCUFQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bisonline: {
    name: 'BIS an i3 Verticals Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'SCSU MIS BIS',
        lastName: 'Concentration',
        emails: ['sconcentration@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHSMccV-dqdXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548614979705?e=2147483647&v=beta&t=-ckuiPv8zaiGqUIPVybu1ldzQ-Jud5JZB_CFyPeQklU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrew',
        lastName: 'Ehrman',
        emails: ['aehrman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE0tLjs4WnENg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613639866253?e=2147483647&v=beta&t=Da7QTN67BkxdKnn2ObVQ3geh5PnM17QxjObIvlzMCY8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Makayla',
        lastName: 'Ballenger',
        emails: ['mballenger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGaAl3Ti-u_TA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692402123145?e=2147483647&v=beta&t=c06qN0j2MPYXKfWrgNu4RXN-tRWYPNWfpvSRX-GljEU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zachary',
        lastName: 'Tate',
        emails: ['ztate@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGDsisZFRL0Ng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606339708301?e=2147483647&v=beta&t=MfrLTzV4tz0dpBWm2idz06K38yQrAoFQwAuOVFr8OGc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Leona',
        lastName: 'Day',
        emails: ['lday@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHIX-lk3yRyOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634839152864?e=2147483647&v=beta&t=AYrEJQPCqbwMeN3bck_177YK9oQi1UsrjpG97q2ZNaI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  atsec: {
    name: 'atsec information security',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wendy',
        lastName: 'Hughes',
        emails: ['whughes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHbKwMsS8YUSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645814306790?e=2147483647&v=beta&t=9ED5jE63cxQSEyKx5yyXoJG7NxRrigGNqrCnWKijitw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andreas',
        lastName: 'Fabis',
        emails: ['afabis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEaTKKdLnfefQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516283149875?e=2147483647&v=beta&t=WiVueQWENUDTb-lFw5g3aSnO-Lf2GkPiISYMkN3uEpc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kenneth',
        lastName: 'Hake',
        emails: ['khake@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Rasma Araby – Managing Director at atsec information security AB',
        lastName: '...',
        emails: ['r...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHIoeKWYabFwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667819668398?e=2147483647&v=beta&t=4cp6nIB2JkD5DxXlqDcrr9ukzWL9pMoxDM-MKeVT_Ws',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Trang',
        lastName: 'Huynh',
        emails: ['thuynh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFQMQsmc69Wnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692490183936?e=2147483647&v=beta&t=7xrm2pReYFwqAhf6Q3kJ0mIj4wiZx0upekSVsNmlah4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  corcystems: {
    name: 'CorCystems Managed IT Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Donna',
        lastName: 'Healey',
        emails: ['dhealey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeanine',
        lastName: 'DeMarco',
        emails: ['jdemarco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGdjAjBpi35Xg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661363173455?e=2147483647&v=beta&t=uXI1nGn6URVnq6IZEyaUMC-bwHwkpabLTXEQ7aKhVfA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Deryck',
        lastName: 'Ali',
        emails: ['dali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEOab1hjMzrcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726259288819?e=2147483647&v=beta&t=OoQCROCbdhjVh9vBBol8B2pf8wGQr503ImxUWxRDek0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Cummings',
        emails: ['ccummings@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFazxhLwugIVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657804420370?e=2147483647&v=beta&t=b4ZtvnJ7fAn2L_Lj1xYS6DqHdDKgv4vWvWUKU_1PClg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bridget',
        lastName: 'Kasper',
        emails: ['bkasper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEvHJpo36bOfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718222021061?e=2147483647&v=beta&t=c4L41B2NCHK6fKWzJUKJJtiRXL2el0dQNPH0TOSPAv0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mfa: {
    name: 'Cumberland',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Keith',
        lastName: 'Jetton',
        emails: ['kjetton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cassidy',
        lastName: 'Sparrenberger',
        emails: ['csparrenberger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGCn_cqOBvRkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701654663404?e=2147483647&v=beta&t=RJHXG8WyjUOEpV5KUfRy9Iinkn0Q8LoXf54Frdyt4H0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Johnny Razor,',
        lastName: 'MBA',
        emails: ['jmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHwO0CuryJGHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517017025405?e=2147483647&v=beta&t=OjcBOk3HYCtikvszl3R3GgQXhl_z1C7TEa2wXNb925Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kevin',
        lastName: 'Beck',
        emails: ['kbeck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGIYJsJ9XNyGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728508645951?e=2147483647&v=beta&t=gJ6Uqz2PXxx_usYrNwTAramxIglt2i4gPgldujZapLc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kayla',
        lastName: 'Laughlin',
        emails: ['klaughlin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ps2g: {
    name: 'DigiDoc, Inc. dba Public Sector Solutions Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Collins',
        emails: ['kcollins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Janel',
        lastName: 'Merritt',
        emails: ['jmerritt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEzeQbgjqQJRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723297578471?e=2147483647&v=beta&t=k1utEV9R7y037iMER20aa_1KmDVF49wDCh-V2Goc91w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jessica Blischke,',
        lastName: 'PMP',
        emails: ['jpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGxkfjTd2ltkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613695476671?e=2147483647&v=beta&t=twq8HmcY67AOCfra0VXDwMrD7Z7JbaLephSmVRd6WZw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Deidre',
        lastName: 'Sherman',
        emails: ['dsherman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tom',
        lastName: 'Pathiyil',
        emails: ['tpathiyil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE78LCXziWymg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555345230263?e=2147483647&v=beta&t=L6nzbNVB45rxBASibduB7yyPLW5g7fWeq1ajBaZxx00',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mundimoto: {
    name: 'Mundimoto',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'mundi',
        lastName: 'morgado',
        emails: ['mmorgado@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFuwkieWS7VHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678125414755?e=2147483647&v=beta&t=rAg1sBnDR-D1P9InjTSLp6Dtt2sSTLGULRTE9gFFDxw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pepe',
        lastName: 'Talavera',
        emails: ['ptalavera@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lovejit',
        lastName: 'Mundi',
        emails: ['lmundi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHMB1JfwAeDvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580075039358?e=2147483647&v=beta&t=1-5AlplZZEXqaRy-dh9KdJgjOZOgyqk9sL7xiMGqABo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pranav',
        lastName: 'Mundi',
        emails: ['pmundi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Klea',
        lastName: 'Konci',
        emails: ['kkonci@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEeYcsziXkZBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600289870057?e=2147483647&v=beta&t=hUyy5PvJeND8rFREpKHZlCvpizmzXgfAJeZQhbK5rsQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  akita: {
    name: 'Akita',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Akita',
        emails: ['jakita@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Christophe',
        lastName: 'Boudet',
        emails: ['cboudet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGSZ5tXWfkQdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715858121028?e=2147483647&v=beta&t=UZ5Qvv0hWwn_eKjSQnzMo-l-kXQFXrDgBjDpDiVoOVw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'AKITA',
        lastName: 'TORU',
        emails: ['atoru@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGdssYvVaxSGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517452395848?e=2147483647&v=beta&t=HLf7bYJc_93B-z7ZRrc-TqQLPOpuMtt1C6kPGCN0UuA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Susan',
        lastName: 'Tickner',
        emails: ['stickner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Philip',
        lastName: 'Williams',
        emails: ['pwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEUuw1diY2pYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517777184027?e=2147483647&v=beta&t=qvXPP-pQ1n5pl4KbZ5zEp2zcp_NdvE7z9Dr2FZvzLhE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kacific: {
    name: 'Kacific Broadband Satellites Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brandon',
        lastName: 'Seir',
        emails: ['bseir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFhMS_bLzBKpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656742055514?e=2147483647&v=beta&t=dqmI3GjjYhMkIEYBPpq9KRRgv0rgpnAMO8kD0oVd7lg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bob',
        lastName: 'Perpall',
        emails: ['bperpall@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cyril',
        lastName: 'Annarella',
        emails: ['cannarella@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQETUolqI78qIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516236001783?e=2147483647&v=beta&t=C2Y3aBKbu7kK-3e0I7vLVSJZwMeYkoh4dCIiho2Cgzw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jacques',
        lastName: '',
        emails: ['j@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHdZ6kesaC41Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516769872563?e=2147483647&v=beta&t=dLV9AxCeg7r_8thnGRgNAOnyIH2Gcz6v4lDEhS5wfqw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Suzanna',
        lastName: 'Barrett',
        emails: ['sbarrett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHjL5cp-xGsQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607618398976?e=2147483647&v=beta&t=K-56i-COqAu2SRtqSAdQSBGHGEzOc0zQmtIDtsz9NEA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gradyent: {
    name: 'Gradyent',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ivan',
        lastName: 'Lisovich',
        emails: ['ilisovich@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marc',
        lastName: 'Schotman',
        emails: ['mschotman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHNOPHbAH6s_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1535118352953?e=2147483647&v=beta&t=FPf6-ZdlaajxHd0usreyNoL7WceGjFeQm9YMJqsnRXk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Volker',
        lastName: 'Clauß',
        emails: ['vclauß@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF4OVQmew95ZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664894020770?e=2147483647&v=beta&t=7ni15v_mwBJlDVLBGkK0T7L30h4LqHzF8PHAl_hd2qE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Susan de',
        lastName: 'Grijp',
        emails: ['sgrijp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGx-oiOO9acPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635255710885?e=2147483647&v=beta&t=YGQ3RTo7TlGJSHOvUnwyL4aNwweAMXmTLSbAUyhQy8o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Leonore Bovy, PhD – Senior Solutions Engineer – Gradyent |',
        lastName: 'LinkedIn',
        emails: ['llinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF9BHgZ_IJR2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710497468738?e=2147483647&v=beta&t=xh5tnbbc21nlgygyfz2ChZyQ8B9Z0sSecF5LdSJqRXQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  orisha: {
    name: 'Orisha Retail Chains',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'LaDonna',
        lastName: 'Powell',
        emails: ['lpowell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGRB6OnPmwBCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698224141892?e=2147483647&v=beta&t=k42Z-mah9lH1JjD5jnVK9US1-GlN-PphZTWZ3OGbORc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joao',
        lastName: 'Teixeira',
        emails: ['jteixeira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHpPz09uUJ1zQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517276775201?e=2147483647&v=beta&t=AbkrVZh9ZqiWJUpmF1yyBjzMUUwBY6ce9Vb_Boj5090',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: "Orisha Re'",
        lastName: 'Lampkin',
        emails: ['olampkin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHpefjfiuSz1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700164110793?e=2147483647&v=beta&t=G441BipUc52WYUaYlW67HP8uwueL8-lpmw2p4lzuyps',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jacques',
        lastName: 'Ollivier',
        emails: ['jollivier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEx53w-xtzOqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602572031142?e=2147483647&v=beta&t=fFOOJn05WD1JX6qKbeUiuPcQ81nCzayhLLm_yUfeGtE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Orisha',
        lastName: 'Clarke',
        emails: ['oclarke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF7HePPDOjPOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702693658146?e=2147483647&v=beta&t=GjeINYSfNbEoWH8dK18OjWyjBD9NEXiZMsJgtRCoL8U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  qnbefinans: {
    name: 'QNB eFinans',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brie E',
        lastName: 'Anderson',
        emails: ['banderson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'E.',
        lastName: 'Napoletano',
        emails: ['enapoletano@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGxW0n74UCKRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652372981653?e=2147483647&v=beta&t=h50lnnNBLwYZPTDHAAS9VBPuRROOvBjOWMZx4T1PSsA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Heather E.',
        lastName: 'McGowan',
        emails: ['hmcgowan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEzzD2Ho6olOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729554014926?e=2147483647&v=beta&t=TSnkOQLA5Iy-CeFiBsXew03AdG0WMCa9gzu5Sg_kuUA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'E',
        lastName: 'Slody',
        emails: ['eslody@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFCVXKZfpDqrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720098330461?e=2147483647&v=beta&t=E6Fo0GvBIaETuoqAdwHt4cfXtDF6NLnNZ6h-okQr9RE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jessica E.',
        lastName: 'Lessin',
        emails: ['jlessin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGKpbo3p9l9BQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516244103906?e=2147483647&v=beta&t=6Pza_wClSVmg8dn1YGlW7zmYh_1FWSfnMCw5Deah4Js',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  befon: {
    name: 'Befon',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Elia',
        lastName: 'Katsarou',
        emails: ['ekatsarou@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Panagiotis',
        lastName: 'Sartzetakis',
        emails: ['psartzetakis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGxnRSF6W9q0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569167440112?e=2147483647&v=beta&t=Y5OuCcDur53DdxBWEIu8TT02_3QxoQ8Y8xp_Z5U48FQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Angelos',
        lastName: 'Befon',
        emails: ['abefon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEzvuDxoeecrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516557400060?e=2147483647&v=beta&t=DmQ48fbVOjBNSUox7E5MuzfWJMTsyAVt61a9rlmdOFQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hari Ram',
        lastName: 'Dawadi',
        emails: ['hdawadi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGWBv6409iVUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719843028980?e=2147483647&v=beta&t=RadrnKn6W3rurhmOcCpy9yL4g1N0TOauFXAWYBl7Pzg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'George',
        lastName: 'Georgantas',
        emails: ['ggeorgantas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFKfufAoKPFlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516499070820?e=2147483647&v=beta&t=5aR6UVfsBgJEOp9PJOBDn1Afq3ez06DZgklz1_EJJ9E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fabricgroup: {
    name: 'Fabric Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shashank',
        lastName: 'Kapoor',
        emails: ['skapoor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEtjEVh_AzjuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636269771120?e=2147483647&v=beta&t=R1BKoBTdQD_5h9KfQkzNEzjFpes5cW_Fnvnupvecpu0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Olivia',
        lastName: 'Baker',
        emails: ['obaker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG7XoR0GRbTUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662349109615?e=2147483647&v=beta&t=fbNri6gPzzT2MgfHfsO-LGH4yFFjRfAz52e5dIC8794',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Simon',
        lastName: 'Greed',
        emails: ['sgreed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGMXcvRBLQ82A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574643447960?e=2147483647&v=beta&t=VZ69NQWcwNglTvDGEDCcFrccE0DC-QPnzWorJVjgbAw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anuj',
        lastName: 'Pundalik',
        emails: ['apundalik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQE_e43jac4UjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582454477540?e=2147483647&v=beta&t=B1TRfcc7I_laAuPMzatBrJYZtpF3RJnkSPo_xC3xPMI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rupali',
        lastName: 'Sharma',
        emails: ['rsharma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sulava: {
    name: 'Sulava',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tevfik',
        lastName: 'Cetin',
        emails: ['tcetin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE17wWB9FidAA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517712038968?e=2147483647&v=beta&t=N5XA0TUXOW4SUA2W8pAcguSoMgGOuU-KzSvsESzoaIE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Megan',
        lastName: 'Merz',
        emails: ['mmerz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGafTkvpALh-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585014489528?e=2147483647&v=beta&t=FR25d7u5sn84VvaR3cP42ozYxxhbhCFfbvbOgVUdC08',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ivan',
        lastName: 'Kuznetsov',
        emails: ['ikuznetsov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGIGY2XggzyYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661408803498?e=2147483647&v=beta&t=8rmwSmIO3NrSQVFeTKJnoK05qUwLR_ROJCE-PXavTSk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Branislav Chrastina, MSc,',
        lastName: 'MBA',
        emails: ['bmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGVd5AyMVKnTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727276119451?e=2147483647&v=beta&t=lLaQ6ZkFzCismqWQGCrCQOJQzJGy-oNIh-S78eUlSsk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Robbins',
        emails: ['probbins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHaQ-i3uUJwHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643735729241?e=2147483647&v=beta&t=onFNMzZGQ8YcT6fBWwh-WtmUwID-j_cJmilvyS7WGYw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dimensys: {
    name: 'Emixa Enterprise Solutions Benelux',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bram',
        lastName: 'Peeters',
        emails: ['bpeeters@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFpauGz3_E4gA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585037837502?e=2147483647&v=beta&t=yucBMkH_VP8NDQcMw1Q6gM_wH368qHo_BJyBE1i-EQQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cas',
        lastName: 'Box',
        emails: ['cbox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFqnEV90GLcow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694079869458?e=2147483647&v=beta&t=Ufs9fslPvmgUOH-Nk7Bu2-hEuRm8ny0dVOqwLuqAGeI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Geert',
        lastName: 'Heylen',
        emails: ['gheylen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGeddBTVPIsQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704899636002?e=2147483647&v=beta&t=nEJ1MozZDN4xwJ4JbkI0jJzBA0ku9lknX_irnMWxh24',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vicky',
        lastName: 'Melis',
        emails: ['vmelis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFjOj9iUVQLXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517709644610?e=2147483647&v=beta&t=sI8BI9b1pfR9P74Ylv_ZvKedq8wZrjDk8BvKnIoZ8Ik',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tom',
        lastName: 'Smet',
        emails: ['tsmet@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  actualIt: {
    name: 'ACTUAL I.T.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aurora',
        lastName: 'Chiste',
        emails: ['achiste@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF0BAAx8Bn5bw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617448997626?e=2147483647&v=beta&t=-bhGYaHkTJmUi97iBAOCRCNE50LmTGDg9dMDhPLOsD8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Karthik',
        lastName: 'Balakrishnan',
        emails: ['kbalakrishnan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF9c2bQjrFnow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517376096243?e=2147483647&v=beta&t=n4AIkkpNjH1M9U8Hg-S-uS7zd9UNINQnoG9dNuGNZ20',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bryan',
        lastName: 'Gilburg',
        emails: ['bgilburg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFKGAt0ZjaONw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516306351891?e=2147483647&v=beta&t=Iu5aw2zhsDzPgsruF5UmNkN3kn-MCS6_VYSJYGK7vfI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Derek',
        lastName: 'Lyons',
        emails: ['dlyons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFBntPdjDIcaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643250750165?e=2147483647&v=beta&t=qTuTyfyRV3V_umOzQR3jb5AQdqWgWulOg9iEji0orMg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tom',
        lastName: 'Bush',
        emails: ['tbush@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEFj2vjRZnRcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713032630033?e=2147483647&v=beta&t=pdrXr3sp7XH-bRog-WHdg8G2DcLSqc9u1-JTphbre5g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tekosol: {
    name: 'Tekosol',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kanak',
        lastName: 'Jinan',
        emails: ['kjinan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGbU9EK8P7c2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715428905663?e=2147483647&v=beta&t=tKWi5b2NFA4thFiKF-N8YLY9rEFKS8kQ1U8BVokZs6c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Viju',
        lastName: 'Kothanath',
        emails: ['vkothanath@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGJ7a6UHk1l1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698901470669?e=2147483647&v=beta&t=sLL2LUZy4wuxO3ViA-5bnkSY-DBWoTo1Ut-RY6ZaGRw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'gokul',
        lastName: 'mahesh',
        emails: ['gmahesh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFuDca95pNxNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688055338937?e=2147483647&v=beta&t=YxFR1SSbCi7klgSpn6po_DDYXtKbfDhQP50zVWRhYS8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'SUNAINA',
        lastName: 'SALAM',
        emails: ['ssalam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEQdM3xiWxOxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727759916159?e=2147483647&v=beta&t=44Ure2RjbHnzPoxHRF8Vq04aNPY4hpFFrlKt-UPNeOo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hr',
        lastName: 'Department',
        emails: ['hdepartment@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  avispl: {
    name: 'AVI-SPL Canada',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kelly L. Dickinson, RPR,',
        lastName: 'CTMP',
        emails: ['kctmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFzc5JIlmV1rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516239760504?e=2147483647&v=beta&t=9lO9rr17ngh_xc2p3b4CnmqgZ_Ukl1s1z5IvwVpEgtw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'James',
        lastName: 'Ginther',
        emails: ['jginther@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFueZ0u1n7BRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522337234372?e=2147483647&v=beta&t=ozycQpLwEJewp2dbG_puAO6uf2SMDxosCt4VL6Ull28',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mike',
        lastName: 'Thornback',
        emails: ['mthornback@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG_4Vyi4sTZaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682364379717?e=2147483647&v=beta&t=IRMdvxzzmy2GA6fEXG4XH1s2VMh2yS8Y-Tan6jjwFkg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Faber',
        emails: ['jfaber@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Karagh',
        lastName: 'Markovski',
        emails: ['kmarkovski@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  edetek: {
    name: 'EDETEK Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Munther',
        lastName: 'Baara',
        emails: ['mbaara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGqdI6PaM4Pdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724734775242?e=2147483647&v=beta&t=KMeTSof1OBG1Bdvo70pJ0sWDbp8VMmWKVNQ9pitnX_8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anatoly',
        lastName: 'Podstrelov',
        emails: ['apodstrelov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Peter',
        lastName: 'Smilansky',
        emails: ['psmilansky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGAlgzhMdwVhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721150230107?e=2147483647&v=beta&t=tsk_2HZ8fuF3UyaK9_TDpOLEG9oPO2BxgH40a8zodCA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jian',
        lastName: 'Chen',
        emails: ['jchen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF1G4NOGlDdlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716089004092?e=2147483647&v=beta&t=9dbPzRr98S8RnrRRU1UGXBz4w7JfNL9--YRhqu2WvJ8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kevin',
        lastName: 'Prizeman',
        emails: ['kprizeman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEnVwU5kmuN1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707831153271?e=2147483647&v=beta&t=Vraj8xpYZU0gGoubw3xbpopGvgO4IqpGh_bxnhnXofQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  softwaregroupinc: {
    name: 'Software Group Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Teresa',
        lastName: 'Zobrist',
        emails: ['tzobrist@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHCR_H5mZkY7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587435106733?e=2147483647&v=beta&t=rSXEn4ud4l8V2P4S5fzKzpPugC3PYTlTh-siV1OH_MM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anju',
        lastName: 'Vallabhaneni',
        emails: ['avallabhaneni@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andy',
        lastName: 'Schmitt',
        emails: ['aschmitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGgmUHQumWlQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517694746969?e=2147483647&v=beta&t=0EAAHc75xvYioM3ILYcdGylA7T6lbIPkJj6j0Pzuk18',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'James',
        lastName: 'Bradley',
        emails: ['jbradley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH0Gsv68BAkjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517692719580?e=2147483647&v=beta&t=f4VtCK-xn6xmAOOl78tNqVR4s4DQQMsFYXI9YKBBRTU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lalitha',
        lastName: 'Reddy',
        emails: ['lreddy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  terpsys: {
    name: 'TerpSys',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Edward Woods',
        lastName: 'IV',
        emails: ['eiv@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGpBXTtVBq3iA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517276146437?e=2147483647&v=beta&t=LiaOXpzzcanrdacQKRm-oPlYLNPgylzddwbkb0gw2pI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gina',
        lastName: 'Stepoulos',
        emails: ['gstepoulos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHx1g-o6ikykA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516306123146?e=2147483647&v=beta&t=T-4xnAueEyUBSTD44Yq5IuqDd7vMRYjNguyq_CH9xMo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shanthala',
        lastName: 'Somashekar',
        emails: ['ssomashekar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHpmeyeYly_yw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516228732461?e=2147483647&v=beta&t=SoLmq4y_ICzCxz4rih7uqPCeh_cdUxjioQOc-GQZ0EY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joseph',
        lastName: 'Brooks',
        emails: ['jbrooks@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ivo',
        lastName: 'Tadic',
        emails: ['itadic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFyyXcYiGl7Og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585073141365?e=2147483647&v=beta&t=CJ5y4TDuvAnBdXtKMLcuQOHRI5CQKx7c7vHjdFetvd8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  entechus: {
    name: 'Entech US',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jake',
        lastName: 'Spanberger',
        emails: ['jspanberger@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Spire',
        emails: ['dspire@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Buddy',
        lastName: 'Martin',
        emails: ['bmartin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHEvepxyqKnfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555351020993?e=2147483647&v=beta&t=aUurIGqdzSdl7DL7TeluIUkpm527V3AJK7ENEF8pkzQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Henry',
        lastName: 'Decoo',
        emails: ['hdecoo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEtGjppJendxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689784044601?e=2147483647&v=beta&t=SaVkMbB9UlDktmId8a2I_S07Zc9QHjRpmOcJyTVHs84',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ray',
        lastName: 'Ebersole',
        emails: ['rebersole@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFC3meTT-QDBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718296906416?e=2147483647&v=beta&t=qI4ncx4r1m29lzwm1WBGTmXs1vXNIjjB2FXeK8tao5g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bizzycar: {
    name: 'BizzyCar',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ryan',
        lastName: 'Maher',
        emails: ['rmaher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH5r1GGz3zEYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709670702940?e=2147483647&v=beta&t=4dUPYO_u2FqqTs4iarWujWZgvMRHecpdtoBDGy5Pwf8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Lin',
        emails: ['mlin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHtH7sTwT1r_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633034392264?e=2147483647&v=beta&t=ChuOIYTVmjF0Pg7iho-25K3CSsVJe1DTRUjuZ_yO1Fs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ronda Lewis',
        lastName: '(Stecher)',
        emails: ['r(stecher)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG-cpyw8xmemQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685124342500?e=2147483647&v=beta&t=HvEJIXA2aXEBmo_WWr3K1exXLty9kjxucnX0g2swedo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hunter',
        lastName: 'Swift',
        emails: ['hswift@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGJ_3w2HHc8Jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730309028880?e=2147483647&v=beta&t=WDce18TYcBwWwWdEDUbSSngi7jqnNJaJ6qNb0YQoQkc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeff',
        lastName: 'Freeman',
        emails: ['jfreeman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  worldsuntold: {
    name: 'Worlds Untold',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kitty',
        lastName: 'Lee',
        emails: ['klee@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sotaro',
        lastName: 'Tojima',
        emails: ['stojima@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFEuP5xUDA1bQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516483049016?e=2147483647&v=beta&t=uclUft3kec-8bcQj7Z29s8TWxuB6OZgwNLhjEgL68Xc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lianne Cruz',
        lastName: 'Horvath',
        emails: ['lhorvath@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGWQ4wBKR_DmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516294447277?e=2147483647&v=beta&t=N-MmRL7y74B-93LcI-h3V_XxNjDTE1BEXnCM1Mz2sRk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mac',
        lastName: 'Walters',
        emails: ['mwalters@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brad',
        lastName: 'Clark',
        emails: ['bclark@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  smbxl: {
    name: 'SMBXL Pvt. Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Preeti',
        lastName: 'Ubale',
        emails: ['pubale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEV8qR5sTZhxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704200701090?e=2147483647&v=beta&t=5scsFPPpkuZzHYJpWS9x_S2iUkdjw_HlnmY1D1mt1X8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anisha',
        lastName: 'Thundalam',
        emails: ['athundalam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG-AI3TGfpM6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660892131567?e=2147483647&v=beta&t=x-AWLB6Aje3_tmfaPrCYpJBAweft5sRfYGiY_4y5frQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Krishn Kumar',
        lastName: 'Yenamandra',
        emails: ['kyenamandra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH_P5pje5LS0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722660704944?e=2147483647&v=beta&t=Fvq2aSx4D7XUSIjR1ZERy7MzKCJMYD7ZyfvSdH1APr0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mrudula',
        lastName: 'Kasibhatta',
        emails: ['mkasibhatta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'sujatha',
        lastName: 'chadalawada',
        emails: ['schadalawada@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ccbtechnology: {
    name: 'CCB Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Helle Bank Jørgensen, GCB.D, CCB.D and',
        lastName: 'NACD.DC',
        emails: ['hnacd.dc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGlR2wzMIWXCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669167507961?e=2147483647&v=beta&t=B2ucavFjAt2je8rrm1wnbe7oi3Zw52eFgT5j4ri43ao',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sean',
        lastName: 'Ferguson',
        emails: ['sferguson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHZdOP1xy2Gkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517488351115?e=2147483647&v=beta&t=0e3_nqBPnmK12zWGCgLdzb4oMpu26Bfi8T_uFwdN6yU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brian',
        lastName: 'Boyd',
        emails: ['bboyd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEAxETsHduDYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655205556398?e=2147483647&v=beta&t=dz4F6d-FIJe3KPwiTGeqJkXcdRnhK6wbjvSn9quwT6M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Charles',
        lastName: 'Bernard',
        emails: ['cbernard@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeff',
        lastName: 'Canada',
        emails: ['jcanada@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  speriti: {
    name: 'Speriti Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Khaja Pasha',
        lastName: '(L.I.O.N)',
        emails: ['k(l.i.o.n)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGndvOEdf0cMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686562889626?e=2147483647&v=beta&t=AFWMfvSPMH_9uOSSLgMz1uI0MiW6kQ0DtiiiZq4bPLU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Megha',
        lastName: 'Gupta',
        emails: ['mgupta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Venkatesh',
        lastName: 'Ramamrat',
        emails: ['vramamrat@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chilakapati Geethika',
        lastName: 'Chowdary',
        emails: ['cchowdary@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQF9ts1BVWdhZQ/profile-displayphoto-shrink_200_200-alternative/profile-displayphoto-shrink_200_200-alternative/0/1528964355282?e=2147483647&v=beta&t=OeL336CDWmE49NOWd2K2vOpKCw5EGCficYUoFyyVp30',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rakesh',
        lastName: 'Mathi',
        emails: ['rmathi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  quanterion: {
    name: 'Quanterion Solutions Incorporated',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric',
        lastName: 'MacDiarmid',
        emails: ['emacdiarmid@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE748OjGqDvdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687272500059?e=2147483647&v=beta&t=Qn23MYOemQ50287F81HuE6VI4pT_QzYuLpCSSc23d70',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Reade',
        emails: ['jreade@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGEj95jIB5dhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718207212106?e=2147483647&v=beta&t=gFOTdf0WwN8G33mZngku1a-1I7O7P5a5cd5zqGahjIg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Richard',
        lastName: 'Wisniewski',
        emails: ['rwisniewski@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Preston',
        lastName: 'MacDiarmid',
        emails: ['pmacdiarmid@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Charles',
        lastName: 'Fitzpatrick',
        emails: ['cfitzpatrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFXbi5Dbsxtiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623699291920?e=2147483647&v=beta&t=nMOTbNSM2QP56hVCH_kz0jsXU2TP0S_s4J9ma5R5pNI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  giphy: {
    name: 'GIPHY',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Danny',
        lastName: 'Chang',
        emails: ['dchang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF4KsAhUHspvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569192398494?e=2147483647&v=beta&t=xE8_zvoAg4q4mxWumOpzhYCZIi_gp45N7N6qn1M2WB0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nick',
        lastName: 'Greene',
        emails: ['ngreene@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHu8a0Nm6KMZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666710166162?e=2147483647&v=beta&t=usWDlP8aTaHcn5PPr9ga-bjg6oo_00_KMsnmCOJC490',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Johanna',
        lastName: 'Healy',
        emails: ['jhealy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGZjz-3XVbRfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516811491293?e=2147483647&v=beta&t=_wkxFPG3uyPHZR_1rAGaoQ2fY1UgtzDdd8aYxzGikiw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hope',
        lastName: 'Sincere',
        emails: ['hsincere@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHpSS50ozwVmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516802036121?e=2147483647&v=beta&t=m9g8qQ9IiLNyRSwRqkNHon-Zx8hxSExi6CI7i2M4ohE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Caroline',
        lastName: 'Huber',
        emails: ['chuber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHtryc_j15rlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725473013332?e=2147483647&v=beta&t=QUErJD-xr7O9TZhhSbmGy3XkBGpZR7ZrUespmMirnlw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  statussolutions: {
    name: 'Status Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Laura',
        lastName: 'Hartman',
        emails: ['lhartman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Danielle',
        lastName: 'Myers',
        emails: ['dmyers@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mike',
        lastName: 'MacLeod',
        emails: ['mmacleod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE8tAsasR9iIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516881881101?e=2147483647&v=beta&t=F29-CmmwSS1Qg1DB7t3IYNZJ6mo_ooCSxgVpvNX4fmw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'James',
        lastName: 'Samilson',
        emails: ['jsamilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH-urxCWQhNGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516683296158?e=2147483647&v=beta&t=DJHkuE8UoOzE2uZg4Hrkwh6yg4TgmSmxqN0Qsi52yXU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Deric',
        lastName: 'Brogan',
        emails: ['dbrogan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH1x_HU3uHptQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645144551424?e=2147483647&v=beta&t=QQcShaaViY6HNLTP7AjcC7oNG-wRINzwu43dl0dxSmA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  advTech: {
    name: 'Advantage Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Cundiff',
        emails: ['jcundiff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEBfg45NyD9rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516610875315?e=2147483647&v=beta&t=H9foo3msUbGKtpglzVyEwglIXq12h20ozEP84JAvYMA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cevon',
        lastName: 'Wallace',
        emails: ['cwallace@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGKfIEBDcUoNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1533084859582?e=2147483647&v=beta&t=eq_889L9bOq72kj9JoOP6s23lrpX55kMN2SFNhgxl3U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bryan',
        lastName: 'Currier',
        emails: ['bcurrier@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrew',
        lastName: 'Penick',
        emails: ['apenick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHsBFnTrFbSkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662657081951?e=2147483647&v=beta&t=cn1w5NUpe4ceRoErSA7xBapAjk6fHJq0qfPgrtKxct8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'Tamm',
        emails: ['atamm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGkpSQBs1_c5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516524367703?e=2147483647&v=beta&t=dyjXcMQvDU3pvHbzP8MZXk7MT93N0mxi8hCXZOlEfRs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bradsdeals: {
    name: "Brad's Deals",
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brad',
        lastName: 'Wilson',
        emails: ['bwilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG5oRQ8uuxVLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643812997562?e=2147483647&v=beta&t=Sz9_kHtXblIpsnaI-Wqey65xAzvKsaTsqGBaGTWmEvE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jennifer',
        lastName: 'Lazuka',
        emails: ['jlazuka@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Keith',
        lastName: 'Mazanec',
        emails: ['kmazanec@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEdrtKofptCVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683084705872?e=2147483647&v=beta&t=S8htzy5vsXQgn9u3MfUkXloi9hDVQrKK6NDY1djXcb4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kenneth',
        lastName: 'W.',
        emails: ['kw.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHRZNmTqE1QEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516279445581?e=2147483647&v=beta&t=IH9_GcwKUQZ8Daoihu3i3Z5JsZis1VRT7WmNBgIpDY0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nicole',
        lastName: 'Brett',
        emails: ['nbrett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFZ99bYaH2kQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1523743557209?e=2147483647&v=beta&t=QbkvlBsb2wYjL3chjFnjd3wUi4r1raTokDjjaZNarHA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  k2share: {
    name: 'K2United',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ashton Jones,',
        lastName: 'SHRM',
        emails: ['ashrm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lisa London,',
        lastName: 'PhD',
        emails: ['lphd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Justin',
        lastName: 'Hopp',
        emails: ['jhopp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE0AIt20Q4ANQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1562540367088?e=2147483647&v=beta&t=Mtp02XbGH11bOIEukgFHtSSKhn6eXlaix8iQM3Aaxf4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Toni',
        lastName: 'Steele',
        emails: ['tsteele@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEeOrbOjVPYxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712155109166?e=2147483647&v=beta&t=JZwkkdsh5g8NRQ7e0M9NHRX-gv4usSDikjF1dwAlYVo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Melinda',
        lastName: 'Golden',
        emails: ['mgolden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFdp67u_9heBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690655726499?e=2147483647&v=beta&t=HpkJPzaxcaYGlcLq90gv3zuUAuaB_89TyrgR_Qz9nsw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  colemangrpinc: {
    name: 'The Coleman Group Inc.,',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Scott',
        lastName: 'McDaniel',
        emails: ['smcdaniel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFW9RDp2SVVyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516250511164?e=2147483647&v=beta&t=PNH9ez6DtMu98a-0W4F_WbZtb9A3NqruWjrtsEHaP9Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Belinda',
        lastName: 'Coleman',
        emails: ['bcoleman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Belinda',
        lastName: 'Coleman',
        emails: ['bcoleman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHzAYYkNwNABw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671482029205?e=2147483647&v=beta&t=a4lqZxG908H-ajfp1NpICgff1oE7yRqSqghU44S1L48',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jean',
        lastName: '',
        emails: ['j@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFFIq9vdQ58cw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516246442731?e=2147483647&v=beta&t=_m7piEpXeYLXxGp8304UpMmmxnvK9_FnDbH_H6KwvZk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Allison',
        lastName: 'Blango',
        emails: ['ablango@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFPYvlQRXseXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560785583113?e=2147483647&v=beta&t=zpNZfqySYicy-O4zkyA3NkUFTPAUS8VfYSCQqWh3EsA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  acgi: {
    name: 'Application Consulting Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Patrick',
        lastName: 'Mauro',
        emails: ['pmauro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE9lX8CRR6PvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726867354756?e=2147483647&v=beta&t=Sn9QKg19rWfJtprry_AnJHZv5FSClfNnkfMVCIPWPpg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michele',
        lastName: 'Febbi',
        emails: ['mfebbi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGqmD6q8whhCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517749529557?e=2147483647&v=beta&t=Vcfe7xi9G3o_9omMCzO05vw74MfKUXoi-SAAXkGZvNM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rob',
        lastName: 'Harag',
        emails: ['rharag@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Agustin Mendoza',
        lastName: 'Ortiz',
        emails: ['aortiz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHEpJuwF4n8xg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637097659744?e=2147483647&v=beta&t=I-RkmS0Zkh8dMgfPCCZ7rihN7X_dfLGuGEaZcKPQJh4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chukwudi',
        lastName: 'Okonkwo',
        emails: ['cokonkwo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGO0vQy0SEzGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720550603939?e=2147483647&v=beta&t=LQoSXgKoS6sODAsG2Rb8DsKXxeg_o_LAshxcegs6Gsg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  iralogix: {
    name: 'IRALOGIX',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Charlie',
        lastName: 'Gautreaux',
        emails: ['cgautreaux@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGl0gMO68rnhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589823176360?e=2147483647&v=beta&t=jcJF4v7zLQUpCZfAvOTXkKI_PbKSi8sYX61ypNepagQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Peter J. de',
        lastName: 'Silva',
        emails: ['psilva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEZvlpKUFpd0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633442706823?e=2147483647&v=beta&t=mbsnBjQJOKRdiSVIupp6iKXWAL7fMkBW1DV70KrbpPo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joe',
        lastName: 'Hipsky',
        emails: ['jhipsky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGR1TpolOze4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542318669989?e=2147483647&v=beta&t=o7sxU3VLp0i_OTIA0o6qWF1bDT5xw6DHVRt85rHE2lo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Keith',
        lastName: 'Haas',
        emails: ['khaas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFIvfq-K7cqWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706139066946?e=2147483647&v=beta&t=a7VdJn17FtG0tR6oTfhIDM0z-f4lJd2bBbx_hN1ZaMg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andy',
        lastName: 'Daniel',
        emails: ['adaniel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGw3Vb0ZIbWog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516246656033?e=2147483647&v=beta&t=xtIzVTTUdPkHyhcLEN82H8VydeH3LhbDU4p_MfwbAQE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  iflowonline: {
    name: 'iFlow Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jiju',
        lastName: 'Thomas',
        emails: ['jthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH4-rQmhObufA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695579224864?e=2147483647&v=beta&t=00vjddiLld_ddZ2_zpbdKLJXp1yr_9RJU1GfRb1OTO8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jigar',
        lastName: 'Shah',
        emails: ['jshah@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Steve',
        lastName: 'Bagshaw',
        emails: ['sbagshaw@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFv8mT1VumBcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1525612826850?e=2147483647&v=beta&t=sPxaGpcUtTMHz4lfm7rcKUw3WK256Jmwdi_-5PkYEnU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephanie Kline',
        lastName: 'CCWP',
        emails: ['sccwp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGwW2YyMBW3jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647799467938?e=2147483647&v=beta&t=yTA8rq-9e9Ey433pvNNM36pFgIM6QBRv2p_tFb8qo50',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ashveen',
        lastName: 'Kaur',
        emails: ['akaur@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  profitsolv: {
    name: 'ProfitSolv',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Emma',
        lastName: 'King',
        emails: ['eking@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFmgMjFLMxgfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697748858563?e=2147483647&v=beta&t=qDWLf1SnGEkiASnmlhRZctQUEkH7RtmV47HOIqv2RBs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kelley',
        lastName: 'Castell',
        emails: ['kcastell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tim',
        lastName: 'Warren',
        emails: ['twarren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHC7GE-pmI2ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1528805536862?e=2147483647&v=beta&t=nVEHO-a4wM6jWk6GkpLRCy0OI0L0NHiGEHTw3Xz_8J4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Randall',
        lastName: 'Robinson',
        emails: ['rrobinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH6OOut2fH9NQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671546486176?e=2147483647&v=beta&t=C5i8YyNvVgCvkPeTx1JhKB5uawxldaiw_u01DPs-clc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joyce',
        lastName: 'Brafford',
        emails: ['jbrafford@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  monark: {
    name: 'Monark',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Taylor',
        lastName: 'Underwood',
        emails: ['tunderwood@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ian',
        lastName: 'Ravensdale',
        emails: ['iravensdale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFiAw_WXZYI0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517245312908?e=2147483647&v=beta&t=Vn_YUUL7RRTPajSJGifaTgQdsTeC_WM3gXKTulvnVh4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mandeep',
        lastName: 'Khara',
        emails: ['mkhara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEjgXtpRBLdkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590855960624?e=2147483647&v=beta&t=_qHk83BeaYG2Tj1siKyCbXkJyYQ78iArGmecEg9EYP4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paul',
        lastName: 'Davis',
        emails: ['pdavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEVLB-YA4YpJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728347299460?e=2147483647&v=beta&t=NPR_5ppvyF4el-yc7uxlz7rXcAT9lDGJopfdzt9gDV0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Monty',
        lastName: 'Sikka',
        emails: ['msikka@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  stMarysAscot: {
    name: "St Mary's School Ascot",
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sarah',
        lastName: 'Clifford',
        emails: ['sclifford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFqJILhFrRX2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682699492821?e=2147483647&v=beta&t=NFabAUIPHU5KU7kMqrlukRAyFNquh5ewTTGXyfbP67c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Taylor',
        lastName: 'Evans',
        emails: ['tevans@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHAnkoeyXXLug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517270820106?e=2147483647&v=beta&t=P_f_3YVnyjXYeWgKRvmQQ9zVeqh6AkBMaHNtN7gtyXE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: 'West',
        emails: ['awest@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE8InyvLJFxcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715944415073?e=2147483647&v=beta&t=OS7njUMs4yF6f4-rnB9zzr16G_0nlgD0kJEF8q6D3Jw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robert',
        lastName: 'Wells',
        emails: ['rwells@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHHgVjikgXyWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516969705715?e=2147483647&v=beta&t=d_PfraHnZK7EOmmOsUmiR3hXo6zzcwMoyXgBV6ZNpmQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'Luther',
        emails: ['aluther@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHj0Q1Nd0IQDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1527859462920?e=2147483647&v=beta&t=4bowCSoHsUjerg3vDtiv2BiA40GJvxjuM3ARTx8K3J4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lemonsoft: {
    name: 'Lemonsoft Oyj',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aleksi',
        lastName: 'Sihvonen',
        emails: ['asihvonen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhiyrDmIv_yw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661749342130?e=2147483647&v=beta&t=XGbfYb_ZPqasuJJ7XvkC3-Mmzzr_E8HHy23SNakRq6M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mikko',
        lastName: 'Niiniketo',
        emails: ['mniiniketo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF3r217YIxQgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641298909331?e=2147483647&v=beta&t=spzDnmz4C4gwk0mpH8XK_WklcZmKlbKt7kDGnA4BMpE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ville',
        lastName: 'Viherto',
        emails: ['vviherto@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jaakko',
        lastName: 'Taipale',
        emails: ['jtaipale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGPhF2dt8iRRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655880896872?e=2147483647&v=beta&t=_e2gXaqkNPQ5yvrLtoJGOiBgNsakqnTtfi8v23L_VYo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alpo',
        lastName: 'Luostarinen',
        emails: ['aluostarinen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG2Mp8oyxTV8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634283584185?e=2147483647&v=beta&t=ij1lsQZCs-qtan6O-HiNbWqA8xs7CcF4NpHZKb6uD-Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kdab: {
    name: 'KDAB',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mike',
        lastName: 'Krus',
        emails: ['mkrus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHGb9hRGZhWIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516245194070?e=2147483647&v=beta&t=GYW7xBd7OIW-cobANCUC73UvSj3z8GI0KjBjtnH2lAo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Faure',
        emails: ['dfaure@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGQcWd7gqobNw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517738672205?e=2147483647&v=beta&t=osX6ZCTJj6g1O4NyEot4DbvDy16IDJbEWKfSltQWrls',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nate',
        lastName: 'Rogers',
        emails: ['nrogers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHb6o5BIvnDVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668289298437?e=2147483647&v=beta&t=REcBKGQ5P_FWpnLAe-kr1GAOrmtCzbUOwctjroj02kE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrew',
        lastName: 'Hayzen',
        emails: ['ahayzen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFNbgV1300pbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517273832335?e=2147483647&v=beta&t=GjHTjsihM811bG718BZPJvnejp-Mq3Sk_a1B1Ig7Otg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sean',
        lastName: 'Harmer',
        emails: ['sharmer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHUUDx9X3mpEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517715658331?e=2147483647&v=beta&t=dqI7jmotXIZQQNpyVgtiyeNNI_k9LYEcb_fHdi5wUtk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vant4ge: {
    name: 'Vant4ge',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michelle',
        lastName: 'Darling',
        emails: ['mdarling@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Ditto',
        emails: ['dditto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQErjWXgEBd-vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722269164497?e=2147483647&v=beta&t=ifbc7QSOA0mVh-z3pJBoyg61dHUTOrUKHQGKMtMiuUQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Isaac',
        lastName: 'Swanson',
        emails: ['iswanson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG7KiIduhz5rQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1554072472784?e=2147483647&v=beta&t=xNQVP-bp_z2H5Kdq-tFURDj0yxNBx5O9TxkyIxzHrLk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ben',
        lastName: 'Snell',
        emails: ['bsnell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEAjTbnXRsgmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627598353298?e=2147483647&v=beta&t=PluiU7jTiadPF-oD3ZCL4mHJ4ceIjDnTRbQnNoMyLiw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrea',
        lastName: 'Delmar',
        emails: ['adelmar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sweatworks: {
    name: 'SweatWorks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mohammed',
        lastName: 'Iqbal',
        emails: ['miqbal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFcHBCwOgiEyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666404981610?e=2147483647&v=beta&t=6ZvlTW5JBbrP1BGAPMwVAORJuDDdwg5u6_8LLsCfJu8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nick',
        lastName: 'Saridakis',
        emails: ['nsaridakis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEIyCKBAU4Asg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654648359988?e=2147483647&v=beta&t=cum9I9uhoJZQp1YFQbRbvIf7DBhew7koq-rfb6ZCu9U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ivan',
        lastName: 'Kurochkin',
        emails: ['ikurochkin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFI8iHQzlQUEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723208194134?e=2147483647&v=beta&t=xd0wf9JfsFh-fW_GI2VABLePsbutxdTLFuWp-ZcfK0s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Janet',
        lastName: 'Sands',
        emails: ['jsands@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGWHaFg9SHrcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648831268601?e=2147483647&v=beta&t=LTedG_LtIx3qhLDR1vUnrr_kvJ7LFnDzpv6SmzOyUGI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Richmond',
        emails: ['crichmond@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFnPkA1OgVCog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588705079259?e=2147483647&v=beta&t=N2WkmqpE30T3Yy6Xc4tqOBM5OFpXKFs1nmN_dOtoEy0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fullestop: {
    name: 'Fullestop',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rahul',
        lastName: 'Mehta',
        emails: ['rmehta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFfbtr5dwMU1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516275327867?e=2147483647&v=beta&t=01FbSCOOxPzYrIJ3i3hUf6rDfvuD3lLykg96V1x05Wc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Priya',
        lastName: 'Bagla',
        emails: ['pbagla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ashutosh',
        lastName: 'Upadhyay',
        emails: ['aupadhyay@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hemant',
        lastName: 'Sharma',
        emails: ['hsharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGvjYm2HUG0_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676427293165?e=2147483647&v=beta&t=D53okMAMafPkxdj26CjG3cTg2TV74cePQdnHghk0Ypw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Asrar',
        lastName: 'Khan',
        emails: ['akhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGImYS5GJPXIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718425659658?e=2147483647&v=beta&t=b_vjxi7y_Mq2jIG2oBDatUEfqrVbHh-THTbf_90bkeo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  catylex: {
    name: 'Catylex',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Rosen',
        emails: ['drosen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH6KVL5oF7ckw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1576677399370?e=2147483647&v=beta&t=vvOfW2HzIIttxWIWa1V1nWEeYEWX5pGtXI9TC40dWlE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jamie',
        lastName: 'Wodetzki',
        emails: ['jwodetzki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFVXZodhADHZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653076514692?e=2147483647&v=beta&t=fI_Ufze1JDtq-zeP0NewNafvgQZOxzGHyOS4Uf9Dc84',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dhruv',
        lastName: 'Sharma',
        emails: ['dsharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQErhLrRhevt2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517510420735?e=2147483647&v=beta&t=SrrEAbeQe8K5EJGL33gsc_J348jiNwJZAjoWa7NZkdM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andy',
        lastName: 'Tuckett',
        emails: ['atuckett@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nora',
        lastName: 'Steinman',
        emails: ['nsteinman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bharathbrands: {
    name: 'BHARATHBRANDS PVT LTD',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Karthik',
        lastName: 'Nagarajan',
        emails: ['knagarajan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF6ZEWc-TGniw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700728579152?e=2147483647&v=beta&t=UUpX7IwqVS_lB10fBhajkj5n2njxvHS990V_BmrsBwg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'ESWARAN',
        lastName: 'SHANMUGASUNDARAM',
        emails: ['eshanmugasundaram@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHZ68y8hzeqGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596415120167?e=2147483647&v=beta&t=ZlpvYd22k4_iPw9pRnatc49yBXRzdRXbsdQ4WIru2U8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anthony',
        lastName: 'Kimeu',
        emails: ['akimeu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHMjnHk3-i9xA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682276433848?e=2147483647&v=beta&t=9yKX_0DNYi1fJco1xRQ4wPT17IOfYzrCZJwfeSSHSYI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Muralidas',
        lastName: 'M',
        emails: ['mm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHs70Pjrp54UQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706990053194?e=2147483647&v=beta&t=-ONB-db56j_DcoFMFsjBiS_Dq_P9aGwAWKkV7inqxww',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jarib',
        lastName: 'Wetshi',
        emails: ['jwetshi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5W0vGeyTUpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694809625222?e=2147483647&v=beta&t=9up6O1SpOzICZIGkoC9eHN8XYsqgLDIB7vUKbTxUxeI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tmctechnologies: {
    name: 'TMC Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Clay',
        lastName: 'Vincent',
        emails: ['cvincent@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHx7vO52NZSQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617850396139?e=2147483647&v=beta&t=0JpVKLudWJnRRpk9iaglQFP6KB-EsH4U3D1iwhUtw90',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Dahlia',
        emails: ['jdahlia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGNCkSHze7GhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652796256678?e=2147483647&v=beta&t=KqhrxlkLDcbtLaodu3iW8ao2sYATh0HH7DxKsLaAJEM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Corey',
        lastName: 'Rexroad',
        emails: ['crexroad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHBIIxBejs17g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517444661050?e=2147483647&v=beta&t=zPRQMnSMI5t4RlwRBGCYHJGQG5Ozphcu9JImWxFk9DE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Edgell',
        emails: ['jedgell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGWAw_wHIFW_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517483181103?e=2147483647&v=beta&t=0t1I1x8uHpd-7CZV7kChgoEE4FQGoT0ViGy4SuiRrPY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Denise',
        lastName: 'Lindsey',
        emails: ['dlindsey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cyren: {
    name: 'Cyren By Data443',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cyren',
        lastName: 'Romeo',
        emails: ['cromeo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEAO0XvF3C2nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725652137253?e=2147483647&v=beta&t=QEGdRs-dwjjsx07dSqbYXRdrGJP-KjWKXnURPr4wUmo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kenneth',
        lastName: 'Tarpey',
        emails: ['ktarpey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cyren',
        lastName: 'Rico',
        emails: ['crico@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGtpOZUtuosgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516452073937?e=2147483647&v=beta&t=P8RwKh32aviymP3eHC1JIPMViwvMvhtY2l6RPwW_ddw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marcie',
        lastName: 'Killen',
        emails: ['mkillen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFjNJxmHt8npQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597359956790?e=2147483647&v=beta&t=tnOLo8F6sVDtXU6DnT49HWuIELCKGwaNy_sHnRvsPX8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeff',
        lastName: 'Karloff',
        emails: ['jkarloff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGf0Bt4L7FevQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553626408701?e=2147483647&v=beta&t=oh5pzy8E6LbaWXu4tdFhxkazyNMfxVtk_N9SRXZojUo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  digiteum: {
    name: 'DIGITEUM',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Grebennikov',
        emails: ['mgrebennikov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHGJk93xRmGNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697198226231?e=2147483647&v=beta&t=3Asaw9v38NcTQvKVBAVxX_3AE3x2Ad3tFnf28QZsRLk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Margarita',
        lastName: 'Tishutina',
        emails: ['mtishutina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHp508NuXQ-Sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639895573618?e=2147483647&v=beta&t=oAwkpcAsELix37eZfPFmW9mWgFjixXzR1oxpL3HCB3E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Katherine Lazarevich – Digiteum |',
        lastName: 'LinkedIn',
        emails: ['klinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFMynYAPAFlhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697204840223?e=2147483647&v=beta&t=Na1XSQ513sGMFm692_umHSFVJ43C5JGyFSCqAQ63ty0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Georgy',
        lastName: 'Romelashvili',
        emails: ['gromelashvili@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEevjSq0RpeOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696516056079?e=2147483647&v=beta&t=hXUTxFLqHMyz4ocYZnzSmqZ0ItxLvZiKXREfBMU2HFQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pavel Stoma – DIGITEUM |',
        lastName: 'LinkedIn',
        emails: ['plinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF91rXKqv0skg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687122801478?e=2147483647&v=beta&t=ZHhCFx_oEk9fZp3Od-sZ8uyRIVbDQ2lxuuHrawPR8VQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ascendsoftware: {
    name: 'Ascend',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rob',
        lastName: 'Southern',
        emails: ['rsouthern@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHMFp1CmVgGZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685741548231?e=2147483647&v=beta&t=0F2ZE0CmHsKfzFlnoakWZIoGXKB12zND9mwCMCtIhZA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Khan',
        emails: ['jkhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH-AmU4A6sDqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602083162073?e=2147483647&v=beta&t=OOW7SnYLHrY2L21xL9986TKC4sPhYt2R8RkKgZFDICU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amber',
        lastName: 'Baird',
        emails: ['abaird@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGdaCaZnzqg5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516652225116?e=2147483647&v=beta&t=Xiv1rGYmREvpdWdISN7gfckXQBClt0wv1nJYOMH_jjE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nikolai',
        lastName: 'Basov',
        emails: ['nbasov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHH-JRaPk6r-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1528261189033?e=2147483647&v=beta&t=pheDiAIzogEcY-iObYwWp017-lTONTEMFa8Px2zqG1w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Benjamin',
        lastName: 'Coats',
        emails: ['bcoats@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  chainbridgesolutions: {
    name: 'Chainbridge Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aarti',
        lastName: 'Smith',
        emails: ['asmith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stephen',
        lastName: 'Bailey',
        emails: ['sbailey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE7tu0-QVPKIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637202577131?e=2147483647&v=beta&t=q8x3t2w4hDq3caLOuO3qoEj90h9FXvq7dRT_ymrB7yU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Max',
        lastName: 'Caron',
        emails: ['mcaron@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQETrJsCGnjLdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587317273345?e=2147483647&v=beta&t=kLbPfdjCd889ehCMxGttLMgXt4gN2XWZ5GfaIEXk03k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tim',
        lastName: 'Fischer',
        emails: ['tfischer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFs79QPy-Uukw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606231890790?e=2147483647&v=beta&t=4Tp-U7Mc8ocdaKeuJMr81xkSiLvk8S0KXdE6aAr60Hk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Monica Janik,',
        lastName: 'SPHR',
        emails: ['msphr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  resourcive: {
    name: 'Resourcive',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Arnold',
        emails: ['marnold@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFCpfLduDkWrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652206261400?e=2147483647&v=beta&t=1f2b2fVHPF86Ieta4kbSzjUSdNiUGmEn3_TEDoLBVQg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexander',
        lastName: 'Doumas',
        emails: ['adoumas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGtfk7SOlyIqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619808980743?e=2147483647&v=beta&t=PIxpdaXzeDKz8ubFFAWMkaPfbXF9ehd6ZiALVSPtVVM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ryan',
        lastName: 'Madairy',
        emails: ['rmadairy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFFLJnipNvq3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679675511706?e=2147483647&v=beta&t=ptQQI_Nl6wJht5nuJP24BDv9C2L3ThWz3A-_HxR8-4s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Stonecipher',
        emails: ['mstonecipher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE3MZCJbzUSIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693412369632?e=2147483647&v=beta&t=1AVvklyLCiBb1eGVriMoGxfKIPG-fAI4egUduW-NR2Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pat',
        lastName: 'Carrick',
        emails: ['pcarrick@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sealingtech: {
    name: 'Sealing Technologies, a Parsons Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Edward',
        lastName: 'Sealing',
        emails: ['esealing@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGhf2i-X7vGlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695197514711?e=2147483647&v=beta&t=FMT9lMsRb816Po990a-nRIVX7DWlQB681kaX9EyT9mI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeff',
        lastName: 'Meister',
        emails: ['jmeister@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachel',
        lastName: 'Black',
        emails: ['rblack@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dr. Christian',
        lastName: 'Dickopf',
        emails: ['ddickopf@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG8bG1jvYlVkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698660889392?e=2147483647&v=beta&t=-9sjumjHimFV5FCsxMzwraKp76znIDyuBWz4cCkdbcY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dr. Tim Bremner, PhD (Polymer',
        lastName: 'Chemistry)',
        emails: ['dchemistry)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHzbWiaJTNegw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701463679600?e=2147483647&v=beta&t=8ZfUPGE3wc1iGG8ka_lnlyEhlVeZQmbUu_pTHBRsAKo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  triuneinfomatics: {
    name: 'Triune Infomatics Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rahul',
        lastName: 'Kuruvilla',
        emails: ['rkuruvilla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGD_8dM_Yl7BA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687412882274?e=2147483647&v=beta&t=IMm1-T-QopntIp9WjYR_KduSy4pc0ut_mflK3dbUMHI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gagan',
        lastName: 'Sharma',
        emails: ['gsharma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Susan',
        lastName: 'Thomas',
        emails: ['sthomas@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aswathy',
        lastName: 'Anup',
        emails: ['aanup@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pawan',
        lastName: 'Sonar',
        emails: ['psonar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aagama: {
    name: 'Aagama',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aagama Consulting',
        lastName: 'Group',
        emails: ['agroup@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG5jOJGfFxDWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645436873936?e=2147483647&v=beta&t=yKXmhwZFVgJfx3NlpVWSgETskQZGQHE2nRfMeC8mz5I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aagama',
        lastName: 'AR',
        emails: ['aar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEisAJa2Lyigw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726663884760?e=2147483647&v=beta&t=gKY3NzP5M1Cqa71KNxUae20Dyl1YFKdd-xlMX97xKoU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kusuma',
        lastName: 'Madhavi',
        emails: ['kmadhavi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG8swjqXsCODw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649075737297?e=2147483647&v=beta&t=NH9aWF2uGVLQmkDPu6lb68YbC-xCYBAJc3FGIip7e20',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chaitanya',
        lastName: 'Gundu',
        emails: ['cgundu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aditya',
        lastName: 'Mazumdar',
        emails: ['amazumdar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEd9UQ__AV9ew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610168570960?e=2147483647&v=beta&t=8DEyJXIn83SwQNV5pynWoPioe8VNCwzZdh4tzAn1WCY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  clearrate: {
    name: 'Clear Rate',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stephen',
        lastName: 'Oyer',
        emails: ['soyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEMGT_qSdM25A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602527766571?e=2147483647&v=beta&t=EIq5tFTHr2DvFHlrNtaWGkYv9kTnab6tLwwZ9GcxPPY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jacob Rattee,',
        lastName: 'PHR',
        emails: ['jphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHlDohYJmoNrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714588904045?e=2147483647&v=beta&t=6y3P0LScK3vbFvo2R3Q9uYOYALWoEWknO_DdgieXoek',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'George',
        lastName: 'Passantino',
        emails: ['gpassantino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFnSUN_RNhYng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516326543244?e=2147483647&v=beta&t=gLcAUec0T_jWPBahsUZe6WvnM53bz-9TzATjpZBTtUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sam',
        lastName: 'Namy',
        emails: ['snamy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Luma',
        lastName: 'Gorial',
        emails: ['lgorial@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHcaSFt-pPTlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517092048155?e=2147483647&v=beta&t=xfhVwVCly5dsozjiTOySolEZhWIPbBisJAt_79BZSAc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  clumio: {
    name: 'Clumio',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rick',
        lastName: 'Underwood',
        emails: ['runderwood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF0LPj7uNuQww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716163449884?e=2147483647&v=beta&t=2m9umYFaz8SwYtyhXRk0NWgg9Kl4r6Z71aFPiwlYCY4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Woon Ho',
        lastName: 'Jung',
        emails: ['wjung@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nick',
        lastName: 'Robin',
        emails: ['nrobin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFD9tfpOkJ0EA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1554492856149?e=2147483647&v=beta&t=V-j20_FIrvEro6F85GtJuSH3tTLCQwe06UT17dIvRAI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'Keavney',
        emails: ['mkeavney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEhBG4kuFcD5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516268889473?e=2147483647&v=beta&t=kMsdVVTajNUJaJPYPYwh3fucfI3HPVrzrhV0FUNc8Uo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ashley Roller',
        lastName: '(Murphy)',
        emails: ['a(murphy)@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  synetic: {
    name: 'Synetic Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matthew',
        lastName: 'Hefner',
        emails: ['mhefner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFV2orijWRXfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722365438470?e=2147483647&v=beta&t=ZjboOfY-zOpPHijK9bu8Zp2LLNoXV3-4DaHNeWX7K_c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Todd',
        lastName: 'Leach',
        emails: ['tleach@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alex',
        lastName: 'Dack',
        emails: ['adack@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHvoa3eJxzJSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715967567462?e=2147483647&v=beta&t=Jl2GsgBiiSj1nmRjnWgjErFIeH5mDPhD4XHVG0rqCv8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shawn',
        lastName: 'Bennett',
        emails: ['sbennett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF42E2oUgnmIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727802673299?e=2147483647&v=beta&t=eC0uIt1H4kOpJQCZ4jnF32UFpCSYh2xr9fwFsKsklm8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Synectic',
        lastName: 'Technologies',
        emails: ['stechnologies@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH0y2vO5S86Qw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517244684314?e=2147483647&v=beta&t=kCBgyvEXSHs3ZGyXyON55j-OqRFbnPgmxpjqQMnPkAI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tgwGroup: {
    name: 'TGW North America',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Patrick',
        lastName: 'Johnson',
        emails: ['pjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGhJDKmkSUwYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709919298068?e=2147483647&v=beta&t=FXW1Jg_JBWn-GtC5DDopbK8NALtISWb_Hsns-79bPlE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ross',
        lastName: 'Timmerman',
        emails: ['rtimmerman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE1arOsdGRcIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631463444209?e=2147483647&v=beta&t=b4a-_1nDgYRJUJzmcsmzRPTbso2U6fqLN_QMAKfBXsg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Philipp',
        lastName: 'Doppelbauer',
        emails: ['pdoppelbauer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE5iw8QxUJOKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718648002156?e=2147483647&v=beta&t=BHYddRr-id2hYOFb9JTaLLC2R9k4Uwea3N6t_h1rfZ4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marissa',
        lastName: 'Dierlam',
        emails: ['mdierlam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGtFGhdhSUJTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688046292279?e=2147483647&v=beta&t=m2u34MDdSF-UulaK24T4Tkj6nHW1FrybmwRsquFHJO4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stipe',
        lastName: 'Galic',
        emails: ['sgalic@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  outlookamusements: {
    name: 'Outlook Amusements',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jason',
        lastName: 'Freeland',
        emails: ['jfreeland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFhWdoxQfdsUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517437524792?e=2147483647&v=beta&t=GnlN6DwECaEWjJJLp3cFbgXhgeG8R9O3zV0vkGha6Sc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'F.',
        emails: ['af.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Leslie',
        lastName: 'Williams',
        emails: ['lwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFTqGywkJE_fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653379233701?e=2147483647&v=beta&t=zkKDjITuOvjlUXjdz7muKlfEyWf-KK_63-gakMzOnII',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Caitlyn',
        lastName: 'Hill',
        emails: ['chill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGGVNsGgikCVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710441051254?e=2147483647&v=beta&t=cqZkmdSJJXGgsEbwdg8eLgG3NdZ55sERkRHvsCxyTpI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Astghik',
        lastName: 'A.',
        emails: ['aa.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFH04aNMuwp-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662955208237?e=2147483647&v=beta&t=wTpNucwXD4xTGWbu6e6T9cdx_Qqy27hQlXSW58QliSo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  incat: {
    name: 'INCAT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pooja',
        lastName: 'Doda',
        emails: ['pdoda@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Martin Haden',
        lastName: 'Kral',
        emails: ['mkral@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHh3pHypkqOPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1554926558280?e=2147483647&v=beta&t=jqjJIGxl4ENo4ubiW0mi7R1pVCAawq2J-Lije_MaHX0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Richard',
        lastName: 'Rollins',
        emails: ['rrollins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEDTun5zTbocg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516619628099?e=2147483647&v=beta&t=i48_3dMKOsOsyQja0g9FQcGDbTNpm9WL6GCf8Uxg_PI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Elle',
        lastName: 'Baker',
        emails: ['ebaker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jason',
        lastName: 'Durand',
        emails: ['jdurand@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGMNkIxcPi-jQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618512681815?e=2147483647&v=beta&t=I8Hirtf1_AcPklkJBJCofDSo6dlvWE6J5MnS7iJFcTg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  abcmobile: {
    name: 'ABC Mobile',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mireya',
        lastName: 'Villarreal',
        emails: ['mvillarreal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFMd7dBGn51Aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708479562894?e=2147483647&v=beta&t=luXt8j-Yztt4tu2ZeZlHuw8CQwgX0x8ISxNxd2DSTrM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Olivia',
        lastName: 'Rubin',
        emails: ['orubin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQECq8RwnoTBGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659737631607?e=2147483647&v=beta&t=vvgExvfFcXMj77BAcKHQCbNckz3T4-Qen_uycwRP7u0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Emmanuelle',
        lastName: 'Saliba',
        emails: ['esaliba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Averi',
        lastName: 'Harper',
        emails: ['aharper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEIvdqPhEB-SA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712167403137?e=2147483647&v=beta&t=uCufe5Qb_BOH3akf3gq0zY8RsvzzUljooVGFUBfb2bk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gary',
        lastName: 'Hershorn',
        emails: ['ghershorn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHPHnzn1BlcVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516956303500?e=2147483647&v=beta&t=Ldohw5S05CQYDaeatxNWi6cRsQT7RXq13nzVztKV3W0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  clearspancloud: {
    name: 'Clearspan Communications',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jim',
        lastName: 'Alpaugh',
        emails: ['jalpaugh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGAF97tmTI6BQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517420015781?e=2147483647&v=beta&t=LdUTBEeyz1vMUr0CTsGmNwrQo1XrJMYA3el7KVZjcWc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ross',
        lastName: 'Hubble',
        emails: ['rhubble@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFHbNJCmYAPhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637683040904?e=2147483647&v=beta&t=_9iCFhyAMoJbXhu97NMKQbGMIrjOA6L1-T8BZoWGK68',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kayla Swanson',
        lastName: '(Knipper)',
        emails: ['k(knipper)@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Smith',
        emails: ['msmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE6udx5VLkyhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522090842472?e=2147483647&v=beta&t=3CPdw2JA91VdQAf5BUkkFpxqZFavuWezOWzCx6E9kaI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Debbie',
        lastName: 'Petraru',
        emails: ['dpetraru@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG836HE9cw2Zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517471723763?e=2147483647&v=beta&t=DHiqxL4Rt3SelGnwbgfOAGeJ9el9UhXHaxAw2bLFUU8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  smule: {
    name: 'Smule, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeffrey',
        lastName: 'Smith',
        emails: ['jsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH0BkA8LN36fA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608336246270?e=2147483647&v=beta&t=PXvpHu1aMX2ImaxDAOxLVKfShaSGtw5MANyEUp7Bv3U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marko',
        lastName: 'Gill',
        emails: ['mgill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG6CdxQ_gT7_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671296767921?e=2147483647&v=beta&t=RuyNv8HkOqfCSDB5kbefkA_lvZVAeMUh5ZJqmyYdkuo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: 'Platts',
        emails: ['aplatts@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Julia Bastreva,',
        lastName: 'ACCA',
        emails: ['jacca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEao7ccrigxaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516654213753?e=2147483647&v=beta&t=3myFIFvjHuJRt79lL3S4SOow2axtnz_wtGCJSY79qpc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Young',
        emails: ['dyoung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF-AmcSuxg_NA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563849831742?e=2147483647&v=beta&t=N-6Z9Lq6fEht3s8GefQ70QADL0IVEIgaYUplBva0IdI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  arrowdt: {
    name: 'Arrow Digital Transformation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Walid',
        lastName: 'Tawfik',
        emails: ['wtawfik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHt2CNsgGz_KA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718227916341?e=2147483647&v=beta&t=lykw1ffh5mrWXsw089C0KWI84-yiUwOAiECJsf2ighA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hany Elsayed, MBA,PMP,',
        lastName: 'RMP,PMOC,ITIL,CSSYB,CDTP',
        emails: ['hrmp,pmoc,itil,cssyb,cdtp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEQsPrB6_ZG3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730043906165?e=2147483647&v=beta&t=_-rGsG_tT3_23yeyV0Fk9raYaCOnvJrvkNsgOI4PP4E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Fawaz',
        lastName: 'Alenazi',
        emails: ['falenazi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tahir',
        lastName: 'Wani',
        emails: ['twani@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'S P',
        lastName: 'John',
        emails: ['sjohn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGMbMlhUC0cyg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1571828179190?e=2147483647&v=beta&t=lUmsThGOK8YPCQRHW5T3rWoQo9sS4dCsoXeHVC6lNPQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  loyaltypartner: {
    name: 'Loyalty Partner',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Samantha',
        lastName: 'Paschall',
        emails: ['spaschall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH6nxUYTTwTiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723556353292?e=2147483647&v=beta&t=Fs-T1uZ23ZU2B9JN3Y-WlT-V3KiB9QE21Cmcnz0ZGEE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName:
          'Arek Pietrzyk – Technical Product Owner (External) – Loyalty',
        lastName: '...',
        emails: ['a...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHxM1NwnowKQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632430215855?e=2147483647&v=beta&t=XPO9h8QXxhGbcELAPAU6juBReeJmuDYqYvCYqEUDp1c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Valentin Avila Rivera – Consultant – Loyalty Partner Solutions',
        lastName: '...',
        emails: ['v...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE8t92L6fZtTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606135203025?e=2147483647&v=beta&t=TJxYH8qv_MxowMEAincMjePGEp6Kj8Ahk2FcjtQCtLM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daniel Kahdemann – Solution Consultant – Loyalty Partner',
        lastName: '...',
        emails: ['d...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHJkYVCdGxXkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581841734632?e=2147483647&v=beta&t=RnfwjS5xNr-dd1RDn0MoTxbOurwyv7k-Cguwao2PgPE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Caroline von',
        lastName: 'Schrader',
        emails: ['cschrader@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  idw: {
    name: 'IDW',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Riley',
        lastName: 'Farmer',
        emails: ['rfarmer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEj6ZJuz-vxfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597728948696?e=2147483647&v=beta&t=G3-nNRTcOWPpZftPsQ3wp7jBrF-JMVnFfW9zI3OzBo0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maggie',
        lastName: 'Howell',
        emails: ['mhowell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH6Ug2TD5bPtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614292887348?e=2147483647&v=beta&t=S5qySa22j6YLVD02fHW2wPnZWdTyNVHuV3_hTsVN_1Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cassandra',
        lastName: 'Jones',
        emails: ['cjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH4IcVPmgp1Lw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697489573226?e=2147483647&v=beta&t=pxpzDNDqXWRFGy0ehOaKliue_TpxC0GJsR-hxMh1Ixg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jake',
        lastName: 'Williams',
        emails: ['jwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFwzP-Ptx1TbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627677693149?e=2147483647&v=beta&t=MfRCS2utBfuLvpnZPyeQiKWyKdsJ89rN9WNgpwlZB9w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shawn',
        lastName: 'Lee',
        emails: ['slee@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  erbis: {
    name: 'Erbis',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Erbis',
        lastName: 'Sarabia',
        emails: ['esarabia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGhESdgNNt3Hw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649472090635?e=2147483647&v=beta&t=2r-R7-kUS15h-VkSFGvyuhnc3VkpX11xuIzKeidqKRc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anton Afonin – Software Developer – Erbis |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Naveenkumar',
        lastName: 'N',
        emails: ['nn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEaUTHHz9FPIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567959365260?e=2147483647&v=beta&t=UydGWn8zIzuOt2stvDAwy2RYElhO8SB6mfVTZ3_F8ZU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Srinivas',
        lastName: 'Vardhan',
        emails: ['svardhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHjD6gR8EL7UA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516790389871?e=2147483647&v=beta&t=oQh3okb4zYsI8UpCmhY63LteEhOdyczhGlPxSnmEdmw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Julia',
        lastName: 'Bondareva',
        emails: ['jbondareva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFuMUpDTQ2zbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680881898315?e=2147483647&v=beta&t=kP4qorU6cD3b0iaqWmIrC_BvaivjtHIukeC-g4_ZHSI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  edgecortix: {
    name: 'EdgeCortix',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sakyasingha Dasgupta,',
        lastName: 'PhD',
        emails: ['sphd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeffrey H.',
        lastName: 'Grosman',
        emails: ['jgrosman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE9LG5lH0Xwlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643406686738?e=2147483647&v=beta&t=-UrZojhswWAFPa6t9CnPNd6vp58cnLcw_2yqp6YEyPY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeff',
        lastName: 'Milrod',
        emails: ['jmilrod@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Manish',
        lastName: 'Sinha',
        emails: ['msinha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFps7OMaKUikA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606789823097?e=2147483647&v=beta&t=TzRZ-uJZemTaD1pypEgUWQlK7FX9lGdQRunqNqdPItM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Seshendra Nath',
        lastName: 'S',
        emails: ['ss@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  moment: {
    name: 'Moment',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Melanie',
        lastName: 'Summers',
        emails: ['msummers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEHQ6R1xD_XXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706399076463?e=2147483647&v=beta&t=X7tSh9ZaBzUOW_xGtaAaP6G0MWfL4awrK25HxoHE7Bk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bart Van de',
        lastName: 'Wiele',
        emails: ['bwiele@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFJ_jEg66wTPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697741413149?e=2147483647&v=beta&t=rkszsHBKQCkYEShRvpBEicALGiQh3uRRFGfZ5pwOA78',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jacob',
        lastName: 'Turner',
        emails: ['jturner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHn-Z8VSjI5hQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665539589362?e=2147483647&v=beta&t=r7rnogiov36wLoy44GnZAUr1HVn9Rwj35qghA5VcKGs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Trudy Madden, MS, CPC, CCC,',
        lastName: 'CDP',
        emails: ['tcdp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Scott',
        lastName: 'Long',
        emails: ['slong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE4hZIlc41PVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516260778188?e=2147483647&v=beta&t=6TdP3l3Sd1QWrd3qGWzptCe4exedlKyVU7atuC1E09A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bluespec: {
    name: 'Bluespec, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Charlie',
        lastName: 'Hauck',
        emails: ['chauck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE53CEim2QPMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683215849062?e=2147483647&v=beta&t=6L9RYqhzW1UjhGEdmHRndo_9sww_d4kdZ6Xd-zzJxxY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rishiyur',
        lastName: 'Nikhil',
        emails: ['rnikhil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFnCuBf6F4Ydg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516279064813?e=2147483647&v=beta&t=qkzneOU_50H74GNu5ZVpU2orpLsdMFijzxUCmgl9PLk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bipul',
        lastName: 'Talukdar',
        emails: ['btalukdar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFsLZ5TSpeStQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675881106787?e=2147483647&v=beta&t=lyY1i7VT478C2nBhNsJ6HXW38J4eH39hGglGp4IiV_4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Loren',
        lastName: 'Hobbs',
        emails: ['lhobbs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEYfeRuh7HL4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613461411560?e=2147483647&v=beta&t=bxWf0bRlMZV88z2hf5qao8G9bz-mLYexx2oHmRriCWw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bill',
        lastName: 'Cibulsky',
        emails: ['bcibulsky@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  templus: {
    name: 'Templus',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matthew',
        lastName: 'West',
        emails: ['mwest@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGdVpVRv-nCpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1540744215798?e=2147483647&v=beta&t=rS_KiaDIKqcHE7E_84E-soGTH2u-0d8jNoIoq5OW3N8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Richard',
        lastName: 'Klinghoffer',
        emails: ['rklinghoffer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHI5rB8-4Xdlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578372235353?e=2147483647&v=beta&t=9OgN58ppMbEx8Izl2pTxp_cLeHXOj34PZ6J5DjfWLFQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chiranjiv',
        lastName: 'Singh',
        emails: ['csingh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kelly Jo',
        lastName: 'Horgan',
        emails: ['khorgan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHE1pWr4hJ2OQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729545516382?e=2147483647&v=beta&t=2KX6aWHOkET1VykZsSBGa4j2M7Om5m11t3wEyFz8ueA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bob',
        lastName: 'Lopez',
        emails: ['blopez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '8west': {
    name: '8 West Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Rogers',
        emails: ['mrogers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHPoR2OVbT7Bw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516277727002?e=2147483647&v=beta&t=I-OAlAT8w4aOHAzx_gN0wWRXIJ_S9enuuKXKIrIXvSQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Moynihan',
        emails: ['mmoynihan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEi-swjVgdDzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675275001070?e=2147483647&v=beta&t=Chaj9I8rw6P6mY3aJULn3dtg91ci_BG5AgflclpX-0M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matija',
        lastName: 'Grcic',
        emails: ['mgrcic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEfDnh-yGKaBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722262852953?e=2147483647&v=beta&t=rYGxm6iFPd8ProEoUb2f_lSV6BNLSRTT32sUF7Uwe6I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Davide',
        lastName: 'Dettori',
        emails: ['ddettori@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGkzcXoOPWy3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628629739078?e=2147483647&v=beta&t=j5jfSRVB06hOMrX5smOAK_1haiVY7cdAabxTZ4pET_o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michelle',
        lastName: 'Ruddy',
        emails: ['mruddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHmtovHgqRhHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517366984864?e=2147483647&v=beta&t=zyO9R0LJXMpYHV3w6vlhqxJCzcYO2nloAsLa4X5iCWo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  impactinggroup: {
    name: 'IMPACTING GROUP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ada',
        lastName: 'Ng',
        emails: ['ang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQH8L1O-TWZt-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557139195125?e=2147483647&v=beta&t=nd0OF8pggGbHegWNCPbn-iRpyD5TowA4_IJwCjTPPBk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joann Ng',
        lastName: 'Hartmann',
        emails: ['jhartmann@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christen',
        lastName: 'Ng',
        emails: ['cng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEQEZS7WyCwOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712706959445?e=2147483647&v=beta&t=G_lBAQE9drFnwUymDs01toM_ayUzGX1Pme2pBTaFcLU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gino',
        lastName: 'Pastori',
        emails: ['gpastori@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF0JmItHB8l9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695062526426?e=2147483647&v=beta&t=IzEpXWRNHMI--aPKgu8pnxaMBBimuaHXAxK-9-Nr330',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Angela',
        lastName: 'Ng',
        emails: ['ang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHYM8mUxYaarw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517711988746?e=2147483647&v=beta&t=N-B_vG9EJ6kGtlwfNML6tVJ18xM57jZ0Rp0lkLTWxHY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  antartyca: {
    name: 'Antartyca',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Álvaro Rodríguez',
        lastName: 'Barreiro',
        emails: ['ábarreiro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHxK4pnYCZIFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627992815709?e=2147483647&v=beta&t=GAi8XJRKcamkW8vOL3dZvhvE0ua4PrN5J1qqyW_k5Do',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Santos',
        lastName: 'Herranz',
        emails: ['sherranz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Luna Rodriguez',
        lastName: 'Cordero',
        emails: ['lcordero@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGQLLod88adiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670256297213?e=2147483647&v=beta&t=FKkQdfCBOV4Iv9tCHwKq4_SUkQKp7Tdd5vbCuvnuqJE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Luis',
        lastName: 'Aliaga',
        emails: ['laliaga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHJPL7v2A2RrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673537552875?e=2147483647&v=beta&t=e8pGCtcpk5lc3fQiAogMT_VnrEzW1SC4vg5QIeQx3GA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cristian Arranz',
        lastName: 'Arevalo',
        emails: ['carevalo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGXcGGWdgVwHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579774088867?e=2147483647&v=beta&t=_PG8mHWsqhB8fazGjG7ahlCENoTl_hvtRbUujrMFzII',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gsolutionz: {
    name: 'Gsolutionz Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'ALLEN',
        lastName: 'PUGH',
        emails: ['apugh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHK6SZC4u4D0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517773868799?e=2147483647&v=beta&t=kWjCN7qoI-AtQ5Zw3q_VVwKUdC8237hdvBvp1XnJzwo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Janet K',
        lastName: 'Fish',
        emails: ['jfish@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHmI0GY0v4xHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586465450324?e=2147483647&v=beta&t=uZQOZbiBqSS_6P7NF4BlWgKA0eBcrWUaD7VGNKSdjXk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Albert Zaragoza',
        lastName: 'II',
        emails: ['aii@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEAWYArLpnJqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690910165982?e=2147483647&v=beta&t=H_AIDwt7bfgKB7W4so1KtpiMYmSfk1B3jldo89y8aek',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rebecca',
        lastName: 'Sutcavage',
        emails: ['rsutcavage@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEoiW3hMnc16w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687895202651?e=2147483647&v=beta&t=TsuVyIOeTa6T0hNUgPupp3LKkmSqYz0Z6NxqbIl0mjA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alejo',
        lastName: 'Guilalas',
        emails: ['aguilalas@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  codeway: {
    name: 'Codeway SA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mehran Aliasgari – Codeway SA |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEQO5CIueSNRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706200040101?e=2147483647&v=beta&t=VdupmmK9jI5K7vid7vUp2iJTsrgAmM_2E4V5gxSC9iM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Francesco',
        lastName: 'Cristina',
        emails: ['fcristina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHS3rupJWjXBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701596967127?e=2147483647&v=beta&t=IXQ0nki9bB8anv0uu3a4LJbFifYgonoRL_MoOKgrIS0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alessandro',
        lastName: 'Sperti',
        emails: ['asperti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGtRU_6YcPySQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583846109953?e=2147483647&v=beta&t=eG2UvKYwQygXlGcevUOcBQassaFDTXcXsb81pp7lSrE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Elena',
        lastName: 'Rizzo',
        emails: ['erizzo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF_cHknNKq8-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571388252098?e=2147483647&v=beta&t=O58TVQTRZke3ekosdYPb96-EAWxlu71H3V5hRrn1iKw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Martina',
        lastName: 'Restelli',
        emails: ['mrestelli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEXF_VYHEr2oQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570704347844?e=2147483647&v=beta&t=4MNYwQXdlYud7aYNttbKsdDNIznY7EBJcT57POI11-E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  novolyze: {
    name: 'Novolyze',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Karim F',
        lastName: 'Khinouche',
        emails: ['kkhinouche@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFA1zf1QsdgMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680452835702?e=2147483647&v=beta&t=9DUepLysCqhOQEhxun5aJtPqGiJf8oFDUVKsYIuahxw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rob',
        lastName: 'Wallace',
        emails: ['rwallace@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHIOHg4_YNt3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654118589051?e=2147483647&v=beta&t=J3JDlRl5YyGBXd8THpYpv84jUWhyADxGTY0xTrgnsDY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jena',
        lastName: 'Roberts',
        emails: ['jroberts@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bojan',
        lastName: 'Savic',
        emails: ['bsavic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFZM81TRzpL9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594579556477?e=2147483647&v=beta&t=9B9iM6n5b_OSztkd26kuif8w31f-smfC2OXP3TZxv-w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ashley',
        lastName: 'Diamond',
        emails: ['adiamond@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  impianti: {
    name: 'Impianti S.p.A.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amit',
        lastName: 'Samant',
        emails: ['asamant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFdF1I4MUcCtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693508615920?e=2147483647&v=beta&t=ITph6sqMuZrHfeZc-DI1dPKyF95ojJqjMEzGquVAdDI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lorenzo',
        lastName: 'Petruzzi',
        emails: ['lpetruzzi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHsibKyCmzb3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571949773056?e=2147483647&v=beta&t=tfJBgTW1EcuVbukF0qYdwthGeKMs93P_W4A_fQO0cQo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'ALAM MD',
        lastName: 'JAHANGIR',
        emails: ['ajahangir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF8Op1AKwvjtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676635797424?e=2147483647&v=beta&t=Utw36eiL4PKwCN6JhOxtFh2p8irzPEQoTfka6loG1nQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'guido',
        lastName: 'bena',
        emails: ['gbena@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEyYcpg7ZN6IQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517597287455?e=2147483647&v=beta&t=eearPTyJruAZxvmESJO3YcbfeZ4HZC7JfT6k_BDbHpI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Fabrizio',
        lastName: 'Redaelli',
        emails: ['fredaelli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG9AbPSY4ourQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686587462259?e=2147483647&v=beta&t=otz9XYk4mGFfMLoGo1g9Iwz2MRvZk964GcdAFFoe0t8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  smartstudy: {
    name: 'The Pinkfong Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bin',
        lastName: 'Jeong',
        emails: ['bjeong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEnsfcD8HJteA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1520315127426?e=2147483647&v=beta&t=HBfrLI6BYkLSlzyl7cBzfCFAaRn8zYruNYOhhoNCwPM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rosa Youkyung',
        lastName: 'Lee',
        emails: ['rlee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG2TSqJS1kx8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704211426103?e=2147483647&v=beta&t=D0n9oiokkIFaMj7q3GDvivDuZhVqntvqFM2NwzANqQw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Levy',
        emails: ['dlevy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ryan Seungkyu',
        lastName: 'Lee님',
        emails: ['rlee님@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQERIzmzA8Pkwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664290615851?e=2147483647&v=beta&t=IAPK7Yk0Xe-9SpNHZbWFLyJ8BlGzwgFtNkY--eWRb84',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hyemin Gemma',
        lastName: 'JOO님',
        emails: ['hjoo님@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEZFtFqc28LZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709615577029?e=2147483647&v=beta&t=eQ1jtznVDj6f2HKLyIYjEsovPx6LT8wpeUp1tNB1U8A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mitechnicalsolutions: {
    name: 'M I Technical Solutions, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael Ihrig',
        lastName: 'II',
        emails: ['mii@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQETpBdg2bsT_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573770875383?e=2147483647&v=beta&t=kIXcPhnpTzz80ePqFmFfYUc39t7eqxEgjwap0TH1z5g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Traci',
        lastName: 'Thaw',
        emails: ['tthaw@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachel',
        lastName: 'Jarvis',
        emails: ['rjarvis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQF-AGj8r74S2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517023356134?e=2147483647&v=beta&t=uX2_oI11-eqQxqFGVhTr93a1vbJdXs0JRsatFFeNNZQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bill',
        lastName: 'Dailey',
        emails: ['bdailey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John C. Martin,',
        lastName: 'Ph.D.',
        emails: ['jph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQETwlJUP7Olyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517456274995?e=2147483647&v=beta&t=qjEuoS5XqHKOvAOY-T9swF4wSi-8zDMXcgt-02kCTzo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trilogyit: {
    name: 'Trilogy Innovations, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brandon',
        lastName: 'Downey',
        emails: ['bdowney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stacy',
        lastName: 'Downey',
        emails: ['sdowney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHvJ82qkldecw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722623776641?e=2147483647&v=beta&t=agOxNYDIRo_iVa1F3XM9MPfrRQYz2jW_MlKHP1LEs_g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Randy',
        lastName: 'Cottle',
        emails: ['rcottle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFSOjZXnr0KeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726502511465?e=2147483647&v=beta&t=-RxTQCS5jHXRiTUjL0IpQ0jYMu0PGz5W4P20nkV1RXg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anthony',
        lastName: 'Castaldo',
        emails: ['acastaldo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE2jTlCsOw7sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724082648723?e=2147483647&v=beta&t=ly96WYgZ5sjBBcj3WsD6IApqQYl6Mef1ORERXHQJJqw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Luke',
        lastName: 'Wilke',
        emails: ['lwilke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE_CuGbMGENWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558761739595?e=2147483647&v=beta&t=b942Ok6dSkJSO2fsCCLokgdP3eeyKb6SMp7rXxpJkSY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  liventus: {
    name: 'Liventus, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Danielle',
        lastName: 'Dolloff',
        emails: ['ddolloff@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Levin',
        emails: ['dlevin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEa5ruKM1-EYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722350446023?e=2147483647&v=beta&t=EeG5xfRspgy86T1uufxo2xTd_PuHJIzjGdCOKtbK5qg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Drew',
        lastName: 'Smittle',
        emails: ['dsmittle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHjrXGOQeBaGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711658371772?e=2147483647&v=beta&t=NN9Dtu9PIU-8ESrtCSS9p6na3On2QqZaCbyDxPHF_vQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Latha',
        lastName: 'Shankar',
        emails: ['lshankar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHSRjhVOEBHxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516322623546?e=2147483647&v=beta&t=yHPlnfJP-lAdXEdQVIOgDKaeBsJjXXZTmZ2BAZ2r9po',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Santiago',
        lastName: 'Pedraza',
        emails: ['spedraza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQELay2dYXp1Ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1532481619884?e=2147483647&v=beta&t=75GyOFx_gDWfseYRwAY8bQZjyeNcf69yeFsKM9W45lc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  korterra: {
    name: 'KorTerra, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Peters',
        emails: ['bpeters@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHvM2eLluWb9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720632860572?e=2147483647&v=beta&t=OYGszH7QzEfxoV-ILMC33LdPBk9pmJ6E5Agv2BjcQ4g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Connor',
        lastName: 'Lund',
        emails: ['clund@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF6fRHPqI-nVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711564080921?e=2147483647&v=beta&t=4KTH8-GfJMCTc13Wrh1XE94WbqtWS9AQ71UdK-TJPEE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Skyler',
        lastName: 'Savard',
        emails: ['ssavard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG0qeaxMY5RjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720631643022?e=2147483647&v=beta&t=2oWyLreinMUehDcoMFcJShWK993okvJt_dp6O5kdqDk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jacob',
        lastName: 'Cabbage',
        emails: ['jcabbage@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQETDaF4sQW9Og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560872130596?e=2147483647&v=beta&t=u6C8lDl2O8pXJx2bWM5mYIY4pqwFy_AhaK9ZTrqkJYw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brandon',
        lastName: 'Goebel',
        emails: ['bgoebel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFgxYKK6tMatw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517621162831?e=2147483647&v=beta&t=SGKt6VGTfnOZnPQUnnOZyZhUHuFuf9BBoW-ZGFArztg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  furtherllc: {
    name: 'Further Enterprise Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Keith',
        lastName: 'Frawley',
        emails: ['kfrawley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEi3D2TcWLjXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517696981697?e=2147483647&v=beta&t=z8mFPKG_Umlkf9PzTOQEKd4UoSd8gin8U8IdK9D_1Kw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tim',
        lastName: 'Courtney',
        emails: ['tcourtney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFqDkmbbXY2ZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586287721977?e=2147483647&v=beta&t=LHa1B6469S7RVYenhRZicu3Qvkk8KE8I9pD4aEAVcqI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brian',
        lastName: 'Woodward',
        emails: ['bwoodward@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lee Gummel,',
        lastName: 'PMP',
        emails: ['lpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFGZocCRRb4hA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701879487979?e=2147483647&v=beta&t=WUAWrnvw2CZLrKMsTN2giR2TTR6nOz3A3oQnhBKm5u8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Basil',
        lastName: 'lababneh',
        emails: ['blababneh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFTjJCy6im3VA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704926258366?e=2147483647&v=beta&t=WAEpzYIWAoPyWCrjrRd0X5n0un6Gapebqu1LfKeRqOI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ghsystems: {
    name: 'GlassHouse Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert Moniz,',
        lastName: 'P.Eng.',
        emails: ['rp.eng.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGR7D78LQljTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571696102908?e=2147483647&v=beta&t=YssJDCFC4rZjKK3qNl8zcz3GuNLlH8IIx2frsHnaFPo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeff',
        lastName: 'Wilson',
        emails: ['jwilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEH1pjv1dbcaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517673350862?e=2147483647&v=beta&t=7wbzQZFwXJg0DDKxhcdMxElcE27AI2fu-_tt7RYGHNg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Luis',
        lastName: 'Carrasco',
        emails: ['lcarrasco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFBpgXF2YN1oA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728401504178?e=2147483647&v=beta&t=hJbwJK8AMKuduRlem9w_s2N80ryINrK6r0YTDcoH_I0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Hamilton',
        emails: ['jhamilton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEtnVa5Ej7hWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642695356370?e=2147483647&v=beta&t=nM2CaOamjAVEMIAAEyjdUe96P4x5wMwhSlkOD3LkrS4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Fred',
        lastName: 'Robinson',
        emails: ['frobinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFUdNdDs2H7Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517680931546?e=2147483647&v=beta&t=5U3cZ0FAFXNXN4lGNK1bIYjHIAySzNpFFq7w8qgjgeg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vapor: {
    name: 'Vapor IO',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cole',
        lastName: 'Crawford',
        emails: ['ccrawford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEEJDiXEpVlTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709734535899?e=2147483647&v=beta&t=Q24L4T8TD_ICB7sm9mDVnY_PAPrlTrgk-S0bsI8zm94',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Whitehurst',
        emails: ['mwhitehurst@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGfzi3RXNj7FQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1554738595046?e=2147483647&v=beta&t=73HwKmxFR2KCMIa8gUZOLEg8stmxmj6AkWFLvk8TUmM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tim',
        lastName: 'Hughes',
        emails: ['thughes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHubT6Nh71onA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683217199105?e=2147483647&v=beta&t=n1dv3Tw2FcmNpp_kC1XTp3jbs_B1kkI-8RyfG3fRCzc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aaron',
        lastName: 'Hinkle',
        emails: ['ahinkle@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alan',
        lastName: 'Bock',
        emails: ['abock@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEZjlnBy7WRZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1566833256397?e=2147483647&v=beta&t=w0bwOb_AvlKyPMfwJ5H8q_P-l38GtTWQa1sZOI9Z0QU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wondersign: {
    name: 'Wondersign®',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kaspar',
        lastName: 'Fopp',
        emails: ['kfopp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHBeNCgQTKFuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697079178145?e=2147483647&v=beta&t=jUf_XXIN_GRd5ehux1Q42O5OHFvh1JvyMZDnJgM5fjg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andy',
        lastName: 'Reinhard',
        emails: ['areinhard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGzZT2FdrPQ_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550079337078?e=2147483647&v=beta&t=FeDKNXC7DJuTqY22Vip-yULPS7ZMDKXNnkkZpQQhgIw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lauren',
        lastName: 'Oliver',
        emails: ['loliver@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHsB0wD9LetlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685664894665?e=2147483647&v=beta&t=caqazFqsS4yYIaXn7KgPEvfJz7tiNqOIeazoMV001G8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jonathan',
        lastName: 'Serle',
        emails: ['jserle@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kayla',
        lastName: 'Ford',
        emails: ['kford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFp2j-W6IIW7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559148751291?e=2147483647&v=beta&t=ImlDWVjDc61RpgnBNlEJ4hAE2urM5WalvJshLDbounE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  greenlight: {
    name: 'Greenlight Guru',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Royce',
        lastName: 'Stewart',
        emails: ['rstewart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG-FkY2o6FlzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631545861737?e=2147483647&v=beta&t=znfYookltqS9grSUEXJS1bYg3sTEWnTEq0s0gwi3igU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Etienne',
        lastName: 'Nichols',
        emails: ['enichols@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFOP6Brlzw8Lw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730406080980?e=2147483647&v=beta&t=FRX2YkScC5D796jL-huPzbcrSugLtJLdmAfcMjVW_gE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jesseca',
        lastName: 'Lyons',
        emails: ['jlyons@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jim',
        lastName: 'Grey',
        emails: ['jgrey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHvT_uQkoxfYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651784004515?e=2147483647&v=beta&t=rgZ8KHtecENNfh4_vd9D6EiMp-iiivh2ek0_jTCW3UQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'Mullin',
        emails: ['bmullin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHUmhG9RD2a1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715044786923?e=2147483647&v=beta&t=EEJA9Hqh-xn8VpfohywRY6Mfb6oWCY9PsgMJogxmvfo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ontariomd: {
    name: 'OntarioMD',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Fox',
        emails: ['rfox@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abbas Zavar MD, MPH,',
        lastName: 'MHI',
        emails: ['amhi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGWQTlLeqw6qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719586124818?e=2147483647&v=beta&t=vpGm-p2BBqI2n1-fWtdT07HvpA09ytVG0s72V4fgt6k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Revin',
        lastName: 'Samuel',
        emails: ['rsamuel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF7mUn7RS0zRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558475584215?e=2147483647&v=beta&t=nDTzXY3YjQbSbeprKxgZ_j9EmmoMkderloYnnq0mYoI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Mota',
        emails: ['cmota@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Simon',
        lastName: 'Ling',
        emails: ['sling@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFxSmxogLBdMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661821607533?e=2147483647&v=beta&t=47Rg5hFEodmNqTqeQdzYrm_zNWgjVeZ4jcr_R-IwAGs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  blackcreekisc: {
    name: 'Black Creek Integrated Systems Corp.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Thomas',
        lastName: 'Lanphear',
        emails: ['tlanphear@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF52gEYSKvsAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516767442449?e=2147483647&v=beta&t=aFs1GG_t0uqAhd2P_AS6J_UKbcag8HGw7YimvUp2rXg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brady',
        lastName: 'Hughes',
        emails: ['bhughes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHwZo6qEBn2AQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1539641857261?e=2147483647&v=beta&t=5JPuPibNGk-4k7Mu-ms_bHRDJRSk9jNkkumAQISJ6EI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McClure',
        emails: ['jmcclure@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEQc8MDSrlMUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654517440053?e=2147483647&v=beta&t=vDRYhNJtp9Nh9lFpV6N8EEiv2vJpfQ-N9mr7VhHHxKE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Phillip Wilson,',
        lastName: 'CPA',
        emails: ['pcpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE6MEhBILHVnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715257071436?e=2147483647&v=beta&t=oGm_l0sUpbXsNgkXcJH-GAVC-9Jq8EG1p8JCLQs9wow',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Becky',
        lastName: 'Allbrook',
        emails: ['ballbrook@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHGzrr6PrilOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517735480241?e=2147483647&v=beta&t=d6yjGNeb4RFzrjGKuinavnDsS072rSmocZj6DR4W1eU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  landsure: {
    name: 'LandSure Systems Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric',
        lastName: 'Liu',
        emails: ['eliu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGPG2Z77KQIYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584073082151?e=2147483647&v=beta&t=VqtVBbZlpZUKVQZJnk9xL6U1x7mSDCUvXi78GjZT78E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Larissa',
        lastName: 'Oakey',
        emails: ['loakey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGF5hIPTO99mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564786697754?e=2147483647&v=beta&t=6WekcSGlpQQteFp6jlXBTqjDVqGTjKO56Cu_0EN_z4c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Easton',
        emails: ['seaston@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Vanspronssen',
        emails: ['jvanspronssen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFwgZjNOvz5dA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517441897339?e=2147483647&v=beta&t=W12rxV9_83pf6DMIpoqDHF4_gAzEExh6ImVvxp9S-pc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'Greening',
        emails: ['bgreening@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEm4j-7w_SuEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684989100411?e=2147483647&v=beta&t=HV8XzpDxIpx2RAh1x9RE7QkEISXtLtL_TXsdgUq_Xak',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  levadata: {
    name: 'LevaData',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Keith',
        lastName: 'Hartley',
        emails: ['khartley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHl0Zc2K76nrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687302342709?e=2147483647&v=beta&t=eTSlamktuMAsDPIvLFTIxA44w9xPK7HZW7LUMuuu1pk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shyam',
        lastName: 'Gadepalli',
        emails: ['sgadepalli@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Angel L.',
        lastName: 'Mendez',
        emails: ['amendez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Prathyusha',
        lastName: 'A',
        emails: ['pa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGo-VvO1qMkpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517411992653?e=2147483647&v=beta&t=-6h2rcT5ttsIU4BogVuFXW9NopjlBm45VyhYMVVY2-w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Venkata Sravani',
        lastName: 'Gurram',
        emails: ['vgurram@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  g2Ops: {
    name: 'G2 Ops, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tracy',
        lastName: 'Gregorio',
        emails: ['tgregorio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFh62rVado0IQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584464405943?e=2147483647&v=beta&t=ayqyc6--mk4d5flMHzY6nqqmTFc5yz5nckqWe2B-6TU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Caylee',
        lastName: 'Gilberg',
        emails: ['cgilberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJmjH5F687uA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654724720035?e=2147483647&v=beta&t=EHbkIUkgifXet0NU9zxgmbYzProyoMMGXa5_qg9AnlI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cate',
        lastName: 'Moss',
        emails: ['cmoss@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Diego Vildosola',
        lastName: 'Magana',
        emails: ['dmagana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEaLCPGpq9NrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706040695101?e=2147483647&v=beta&t=hQpmCxA6o-NA0xd3rfQh9uGkBWu0eMnjLiTGjwf-5lA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sierra',
        lastName: 'Patel',
        emails: ['spatel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFSwYzknWF15A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694806212429?e=2147483647&v=beta&t=kolRcvqG69lvPDVJWZDRpe5ivgbgOtKmJ0kC4RQK4lk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  epicgroupllc: {
    name: 'EPIC Engineering & Consulting Group, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Prasad',
        lastName: 'Chittaluru',
        emails: ['pchittaluru@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carey',
        lastName: 'Greenlee',
        emails: ['cgreenlee@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Echeverri',
        emails: ['jecheverri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHq5K-dklr0vA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630499949538?e=2147483647&v=beta&t=EiwOejWLntS6ihNR31PFxAICty5Q3uvikGVPpbLJc4U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marcela',
        lastName: 'Dominguez',
        emails: ['mdominguez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE8zsQC-NP-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1537755264726?e=2147483647&v=beta&t=PP95oB0ND_YG-6CYulA3TreLCGxiPDxejd-5lG8ywSo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eliana',
        lastName: 'Moncaleano',
        emails: ['emoncaleano@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEnnweTektoEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669222329724?e=2147483647&v=beta&t=K6wS_jWKiuZ_6Vr6zhmVEniyqVEXO1P3rEl2NvXDk9Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  btfpeople: {
    name: 'BTF Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chandana',
        lastName: 'B',
        emails: ['cb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQESrt5oJyZUgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695108954355?e=2147483647&v=beta&t=kisAmpJmIHfy_7qvdXhogw84VvVZN1aaRa_hE0JjKTM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rahul',
        lastName: 'KV',
        emails: ['rkv@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFurykGoCinNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686483575277?e=2147483647&v=beta&t=iDjFq1RjQ1zpnSg0-WOEW_rWKKCRa_E-HxeOWwEkOcA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'SRAVANI PRIYANKA',
        lastName: 'KAMBALA',
        emails: ['skambala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEUP71SpTpSjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713423129775?e=2147483647&v=beta&t=KO6Aap7K3HFQlK8Bwp-3viBOiTIAsh1Nr2rvRSoJsnM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mars',
        lastName: 'Wang',
        emails: ['mwang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tiasha',
        lastName: 'Saha',
        emails: ['tsaha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGLwXYcx1zxyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708760092509?e=2147483647&v=beta&t=GkJ22xIGCoGNK7FeRFyzwxhkZsKOPb04mNqXqrUsQjc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  skience: {
    name: 'Skience',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jon',
        lastName: 'Feinstein',
        emails: ['jfeinstein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFkrn6QUk6drQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720034032438?e=2147483647&v=beta&t=88X5QPnhw3m15YcvCMON4SR6t_upXQQgI5Rsz38niq4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sanjeev',
        lastName: 'Kumar',
        emails: ['skumar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Greg',
        lastName: 'Starr',
        emails: ['gstarr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Stephens',
        emails: ['jstephens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE9VghzM0OAqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663193847251?e=2147483647&v=beta&t=PB9PZkUgRlF16nXiRxsWIfaqf-Igwn60g8DNV4YXDc0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sahil',
        lastName: 'Shetty',
        emails: ['sshetty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGyVs5uBlLmVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642386874237?e=2147483647&v=beta&t=4pfdHL5owtwRjd7cNkc7M98C9wN7RMSFQWhbB_DBkCM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  allencorporation: {
    name: 'Allen Corporation of America',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jennifer Lloyd,',
        lastName: 'SHRM',
        emails: ['jshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEafzo_8l5CxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517625388851?e=2147483647&v=beta&t=tiCauwJdsksWx4z96s0SKkWUlg5x3o27gfa6NzDdvmA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Timothy',
        lastName: 'Schimkus',
        emails: ['tschimkus@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Clore',
        emails: ['mclore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHHIdzmwt7hGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1545092208030?e=2147483647&v=beta&t=Y4XkHHzWK4U_-wbsOR4tuFVOoQxWG8Wuc6a0z_jhmZ4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Geoff',
        lastName: 'Barron',
        emails: ['gbarron@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Font',
        emails: ['dfont@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG3H5z7BYTglg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517035584585?e=2147483647&v=beta&t=i9GI_JoCcVm5FesWIWc9vsMLAGoQGyEJfDmTO_83g1o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  intertrust: {
    name: 'Intertrust',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Julian',
        lastName: 'Durand',
        emails: ['jdurand@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG_Ktwi9pl6rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709164095899?e=2147483647&v=beta&t=Z50qe6bG10ETXwpRG6ji5f0hU7jU4cubekm_tbJAvaQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Florian Andreas',
        lastName: 'Kolb',
        emails: ['fkolb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHrdUDPi_Xv9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585365642053?e=2147483647&v=beta&t=SmpUOZprDsdKslIT0Jx21ieAfTS0cxfwa5cy0lgLjgs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ali',
        lastName: 'Hodjat',
        emails: ['ahodjat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHvt5BEbStqDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629398246843?e=2147483647&v=beta&t=TSJPPRkix2hDXmYG1ALNmm6TNSCf_xTeArEFXM3teKg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Talal',
        lastName: 'Shamoon',
        emails: ['tshamoon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFg5OAuk7og6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516763926540?e=2147483647&v=beta&t=Zu3Ifm6c63ZAJs6eeslN2aoc3Gfs62ccYQXb3jGX3_E',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ambriel',
        lastName: 'Pouncy',
        emails: ['apouncy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE1_nBfvAT59Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725230178775?e=2147483647&v=beta&t=dFB42tB1mVY1dAfCh0EaT_yRL7lNEMMnXRcbZhcTPi4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rycom: {
    name: 'RYCOM',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jennifer',
        lastName: 'Sicilia',
        emails: ['jsicilia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHqSc72FOGvSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587920211985?e=2147483647&v=beta&t=3OMT2vITa9LQ1sAdH5VddD2LOAAeuFoXRe8hqRaB6y0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Casey',
        lastName: 'Witkowicz',
        emails: ['cwitkowicz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Laura',
        lastName: 'Towsley',
        emails: ['ltowsley@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Demitrius',
        lastName: 'Marshall',
        emails: ['dmarshall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGqQjQcAzrlfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516496082075?e=2147483647&v=beta&t=Oha0RpOjFSN-XvQoiPeW_O32ur3b584n-N0cGs0IirE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ryan Garner,',
        lastName: 'RCDD',
        emails: ['rrcdd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ccintegration: {
    name: 'CCIntegration, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Johnson',
        emails: ['mjohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ron',
        lastName: 'Pugh',
        emails: ['rpugh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGjcKGcI57L1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542145223302?e=2147483647&v=beta&t=kogc_RAgkQp1QHuKbzHA4Ok-vak1JM3JwWTWVTIvOG8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amy Krueger, GPHR, SPHR,',
        lastName: 'SHRM',
        emails: ['ashrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFT8D3KUW-5rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628721064551?e=2147483647&v=beta&t=l8D4gBjNcPeP3XL2m1-iBwSAJ47x96_LVZ0C_ZFKoAY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Pennington',
        emails: ['dpennington@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQESpDspcQlMxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713214749808?e=2147483647&v=beta&t=tKajHFZoX-FtcukWGZD2KgguGAqQzWqvzQpvZIJowus',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matthew',
        lastName: 'Ta',
        emails: ['mta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  springml: {
    name: 'SpringML, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Charles',
        lastName: 'Landry',
        emails: ['clandry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHNIZyDs1R6NQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516276495221?e=2147483647&v=beta&t=g3HdBIYgiV56LG1wjrHBeplRvAkb9KTu5K5jktEQhr0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brandon',
        lastName: 'Brock',
        emails: ['bbrock@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFWRvMb379OVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588700641631?e=2147483647&v=beta&t=Rwu7lVKkku4IhWnPNvfKRlO6NFIm8JrC0KJOAyp8Sqo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amit',
        lastName: 'Deshpande',
        emails: ['adeshpande@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pravin',
        lastName: 'Nagarajan',
        emails: ['pnagarajan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEdw76EF9A4ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718219530339?e=2147483647&v=beta&t=MC80I1FTrBELQVVzCT9YZUsI7yYvoI-PHJ7usigSAd4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Namita',
        lastName: 'Shrestha',
        emails: ['nshrestha@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sunevision: {
    name: 'SUNeVision Holdings Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Helen',
        lastName: 'Lo',
        emails: ['hlo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHkTQVYhu5Svg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548136335123?e=2147483647&v=beta&t=NZ19P2jt6ecYfoKwLg8Xm42UHtBs8TxII4T9mNenTa4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bonnie',
        lastName: 'Lau',
        emails: ['blau@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sand',
        lastName: 'Tam',
        emails: ['stam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHZSWyTBgUWew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516460888996?e=2147483647&v=beta&t=5vAKkNh5SveL5YL9BMzOZqjhj2yClWDDjvNMtx_Rp4Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cici',
        lastName: 'Ho',
        emails: ['cho@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alvin',
        lastName: 'Hui',
        emails: ['ahui@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFHZnUVyur8bw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581753381853?e=2147483647&v=beta&t=Kf7QYJRverbOo48zssT0Y49Gh5uYmo6iYv-0Dvmb8G8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rekor: {
    name: 'Rekor',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mike',
        lastName: 'Dunbar',
        emails: ['mdunbar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG4uwpcnziTQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724421935470?e=2147483647&v=beta&t=vOCpJO1c7Ug2ZUeY2GKnKxxIZVMRW80i7I08eSBaOfg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Desharnais',
        emails: ['ddesharnais@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHl6oGWLUj_RQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722893788436?e=2147483647&v=beta&t=QeXM119u9Bz9ZLCa-8jrFb41WfF064rQXDriM3JGxfk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ben',
        lastName: 'Gohlke',
        emails: ['bgohlke@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robert A.',
        lastName: 'Berman',
        emails: ['rberman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHX0PNh1n0YNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650561686473?e=2147483647&v=beta&t=XI3Ts8S4pkOTFcJAWOvMt8m735W2k1slgc9-diKwHuI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eyal',
        lastName: 'Hen',
        emails: ['ehen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGV4gmMg3Qq7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713557032745?e=2147483647&v=beta&t=hExP5771RsAP0an2T7-flH-2GiFPB_-9gs6rbsQDaDU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eventionllc: {
    name: 'Evention',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mike',
        lastName: 'Baldinger',
        emails: ['mbaldinger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE2ox06m8eLTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663863560941?e=2147483647&v=beta&t=ZESj--fp_wiwWWA91x9CgK-jQJ9LXQEY36cbY1yXnk8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gisselle',
        lastName: 'Hernandez',
        emails: ['ghernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH-3Gmu14lwpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698697708795?e=2147483647&v=beta&t=lY59cevvJX2Q0iqB-OUOvAQK_7pWeVgRugqZObOAcoE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kirk',
        lastName: 'Nelson',
        emails: ['knelson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHj8ShNglYc4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587397756722?e=2147483647&v=beta&t=G9rQTH3-DHg4jzX1QQbBcbvIn4D1imUiu-7Luh7KK68',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jamie',
        lastName: 'Badgett',
        emails: ['jbadgett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEGrEVve8HFGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723641090183?e=2147483647&v=beta&t=xduFkA1ygu2jM_ro6a2gwwNCKEO6IUmfhRqvcEjP7wI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ethan',
        lastName: 'Porter',
        emails: ['eporter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHhikXOuoi-3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705512100220?e=2147483647&v=beta&t=9KOB_lUZs3i4Lyoc9np3I0OMyc2I5x7q0ZZmcJ_WeH8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mercurybroadband: {
    name: 'Mercury Broadband',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jon',
        lastName: 'Harding',
        emails: ['jharding@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG38wTZE7xRtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678209862673?e=2147483647&v=beta&t=xPoir_rTy25b20a3xCYE4FZb8yB_4WCBCPDKa8e15N8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Garrett',
        lastName: 'Wiseman',
        emails: ['gwiseman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHuIpMU8Jzu7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593658357389?e=2147483647&v=beta&t=9zSD_kFNS7oGdgFj_WeKZQubHOWufEULEys10cWOXBs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephen',
        lastName: 'Rawlins',
        emails: ['srawlins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGFnju6lRqX9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517483069472?e=2147483647&v=beta&t=vtUu9KDevdK7sVSoNEvUC9fXG-9l5LCPrc0XCAinDM8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Roger',
        lastName: 'Sikes',
        emails: ['rsikes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG-NjhsVpMUaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516246896204?e=2147483647&v=beta&t=ptu29vWcCfVJ1Z5MF2mlV6i3nRkhfQmEHhLNiTJuhx0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ann',
        lastName: 'Montgomery',
        emails: ['amontgomery@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  groupelite: {
    name: 'Group Elite',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pamela',
        lastName: 'Carreira',
        emails: ['pcarreira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH_i1HyZ2Q0oQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686175737222?e=2147483647&v=beta&t=Bq8st6VWFO2J5pPbeU39SDb7vOV8pGKlzihFJYZI7bs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sebastien',
        lastName: 'Comtois',
        emails: ['scomtois@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFFBnQBBBCoEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690471859038?e=2147483647&v=beta&t=kvZxBVEytnDP45n18IcpgFeTV_uaJIYR6PemJUGjtPc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Timothy',
        lastName: 'Rose',
        emails: ['trose@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEtrrg6-CrwxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563306888682?e=2147483647&v=beta&t=kGAa8RliZ5fsB5cf4_uhI5XNLhfGC3vXh_MJKHB825A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shane',
        lastName: 'Maguiness',
        emails: ['smaguiness@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEVHnvBIYYk_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612975285032?e=2147483647&v=beta&t=fH5dOTPfZkMEJyj9iw-KWSPd51HTXbijwvWNWIgTwKQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Victor',
        lastName: 'Ting',
        emails: ['vting@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHhfKqMum60YA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521884125122?e=2147483647&v=beta&t=YNDhN1WqqInZFkatGcpB5B_H06NgqSbQRZqSn2VFCrQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  acclivityhealth: {
    name: 'Acclivity Health Solutions, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeremy',
        lastName: 'Powell',
        emails: ['jpowell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGgcbLHHOjGdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1525279927886?e=2147483647&v=beta&t=SiaeCH1bkWdn_YDFi0h8t6Wi6be5eG0pVSTfEvEgrcs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stacy',
        lastName: 'Fox',
        emails: ['sfox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG0YJwfXKiMqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710440974871?e=2147483647&v=beta&t=xh4RcE-txnkkUCL6s8ihKtjUswJfNcXXqX_TjzPN9mo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kenneth',
        lastName: 'Weng',
        emails: ['kweng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHI3mwn4y5TLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516296447260?e=2147483647&v=beta&t=-LPVl1f4rMqGf45VsXM-b_Jr2vRa3WurHflL_mv0zWE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Dickey',
        emails: ['jdickey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Langer',
        emails: ['mlanger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGkhaxEZiYAxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516645524129?e=2147483647&v=beta&t=CAHWHwxhRRD46Pq-Uh72R4WMtAsJS27RdZmwYo-6PKI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ceriumnetworks: {
    name: 'Cerium Networks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Roger',
        lastName: 'Junkermier',
        emails: ['rjunkermier@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Justin',
        lastName: 'Nix',
        emails: ['jnix@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHNf3E0D5hv_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639683849732?e=2147483647&v=beta&t=hqaFROQJ2dALgvIkwp5nKSKde4kBYTgzb7f0-tm7zaE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'Vogl',
        emails: ['mvogl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGZAiZAH9eZmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516806666016?e=2147483647&v=beta&t=ToBhrxT6_Ixh8w_2PtVr8Y_dwctwsVz_ISxT2nBalOQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Clausen',
        emails: ['cclausen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFMc2kW1Fd4Wg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556736627749?e=2147483647&v=beta&t=1ZM4-IQvT_Z47j3Rv69IBmFuBlHwUjXBhM4LYGxYvKQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: 'McMeel',
        emails: ['pmcmeel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHBp6XdQdH2mg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592855615266?e=2147483647&v=beta&t=wTHiZu2aC4ERMSRYCRaY4hAiYFqJTeexac9NLNiMC8M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kapssolutions: {
    name: 'KAPS Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pooja',
        lastName: 'B.',
        emails: ['pb.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kirti',
        lastName: 'Vora',
        emails: ['kvora@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jonathan',
        lastName: 'Retzke',
        emails: ['jretzke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHJ5rIUNaKuvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603109118263?e=2147483647&v=beta&t=oMuvjMZs7U2dYwK8co4Wa9HtSUEXJr2aiYI7PVdWzhE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ashish',
        lastName: 'Baheti',
        emails: ['abaheti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEuy-MKR7EJkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714261611582?e=2147483647&v=beta&t=_H_9jN5M5uYkURguyzmT0lpJg-KMR5Rmv1zZypxUdKg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amber Sherwood',
        lastName: 'Fryer',
        emails: ['afryer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQET6dRfjDz91w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709036879985?e=2147483647&v=beta&t=wOe1oXeT_wMTPig1FNm7a2A507fu7dBYqV2TIyP6__g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  leapfrogservices: {
    name: 'Leapfrog Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Karl',
        lastName: 'Kleinert',
        emails: ['kkleinert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFnCQGBOaBaVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597284007928?e=2147483647&v=beta&t=Cqjn5bIyOobuOcgalVbqleGwYm9AvDu6NtS-M8tZ6IQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bryant G.',
        lastName: 'Tow',
        emails: ['btow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEtUm82awYTIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516768138096?e=2147483647&v=beta&t=qdd9rKGphp5rgYi86cES7diSR118dIyXvFvdimVeh6g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael Burnette,',
        lastName: 'CISSP',
        emails: ['mcissp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFNYwWfcZiAmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679876699502?e=2147483647&v=beta&t=2-dfNzIEMPVQwqD8uBcNgkvXVAmjc3urw6nSm1iOQ1U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Taylor',
        lastName: 'Graham',
        emails: ['tgraham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHcBznG75Bx1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698879657876?e=2147483647&v=beta&t=xG6DyIyANYzg7ntm179Nr6P0IaLtyrm3jpDN0akfD1I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'Bos',
        emails: ['bbos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFdcyJ8omhhgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684001198395?e=2147483647&v=beta&t=Hwj_T2hOFSNiVCSZzTZGA3ygS_E5OPgyXsKu1b3Zqio',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  itechag: {
    name: 'iTech AG',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adam',
        lastName: 'Byer',
        emails: ['abyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGl2Y3l1FVPFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728902359564?e=2147483647&v=beta&t=ehbMRQINlUXz_Yft4DMpTPMZs7RxxU2HIfTVoaQkOjQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Frank',
        lastName: 'Antezana',
        emails: ['fantezana@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Laura Johnston Stash, PMP, SPC, CSM,',
        lastName: 'CSPO',
        emails: ['lcspo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alec',
        lastName: 'Bradley',
        emails: ['abradley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFj3oR-t1MhDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609085079730?e=2147483647&v=beta&t=PWWVT2dB0NPlM_GF6cUHIKYPD08dfxK90QAuR6INhGE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marc',
        lastName: 'Villegoureix',
        emails: ['mvillegoureix@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGhi_K9y4186Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680554565865?e=2147483647&v=beta&t=6ZQVVcn1bj_-0ds7EusAD9z6sPZ_zIef9WXl2OAfRqI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kikoda: {
    name: 'Kikoda | Cutting-Edge Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Korta',
        emails: ['ckorta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matthew',
        lastName: 'Dufek',
        emails: ['mdufek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEU4rDNLCnxtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559691051675?e=2147483647&v=beta&t=YFiEv_p8YjCNcBhnF4kL279cFZErFUBv85HkuJ3xBqM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kimberly',
        lastName: 'West',
        emails: ['kwest@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'J.',
        lastName: 'Hazelip',
        emails: ['jhazelip@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFIsW6pgBUzdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654467706440?e=2147483647&v=beta&t=8TXXOjDw5owYIem65l4iUbj6wokjywjh8j_tFsmrdTk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeff Erle, MSPM,',
        lastName: 'PMP',
        emails: ['jpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHiubpRfLqJJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701360408434?e=2147483647&v=beta&t=fGOoSIcCBflnJS6Nfv-cevfp1Xw3QbNlsK9qUaoqjCQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rkmcinc: {
    name: 'RK Management Consultants, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nidhi',
        lastName: 'Kapoor',
        emails: ['nkapoor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFTl-nQ03xEsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621999832273?e=2147483647&v=beta&t=VW6ZZgS0yIZpG9FHsiZjQPswjETBCJD1SNqsnDQaqZ0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anil',
        lastName: 'Talreja',
        emails: ['atalreja@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ashutosh',
        lastName: 'Singh',
        emails: ['asingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGUbMlY3ZQ2IA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626418193713?e=2147483647&v=beta&t=facrzdD2dgoOlfXRPxQDrykpGyChUBz3nhdi-nW_sD8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Natasha',
        lastName: 'Khanna',
        emails: ['nkhanna@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andre',
        lastName: 'Walker',
        emails: ['awalker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEj6Q87IWUYVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686128740369?e=2147483647&v=beta&t=BqSJqu_lOMHDxcP7OZjti6417TOpEkQORS_LoLKLyNk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tiguainc: {
    name: 'Tigua Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Valerie',
        lastName: 'Alejo',
        emails: ['valejo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Richard',
        lastName: 'Aviles',
        emails: ['raviles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEEvX96N_uhLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516340061177?e=2147483647&v=beta&t=WDxucKG8uLINRD0q6S18oImy68X7WnANvaBm5Kw-l8M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christopher',
        lastName: 'Munoz',
        emails: ['cmunoz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH4i8vrD3bUtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697313510602?e=2147483647&v=beta&t=5LhgUMjgYs7RNdh0l_Wsrir4NMXE9LRXLHGZtyT1tyo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robert',
        lastName: 'Swehla',
        emails: ['rswehla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFuq5RBxnA8dw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516812676953?e=2147483647&v=beta&t=_BPaLtffh_nAwEImx5fgzjc28f4H4ZyRJ85hRKrV6vs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Ramirez',
        emails: ['dramirez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHnN2cGBR7QJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1565222789079?e=2147483647&v=beta&t=n0bw3fsua35gjAmmLLuZEBMO66iqZ23T-VJ727GJvNU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kofile: {
    name: 'Kofile',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Susanna Records,',
        lastName: 'MAHR',
        emails: ['smahr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGdoNA1W5CDFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627793369828?e=2147483647&v=beta&t=XhWOLqOR0loGrk-QFGQHstq1BhMAinpnrvoIepO3n8g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lori',
        lastName: 'Green',
        emails: ['lgreen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ann',
        lastName: 'Knudsen',
        emails: ['aknudsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFbjTpIK4W1-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643917204362?e=2147483647&v=beta&t=C49pIYme97NUoH-8dxUqiRSQMudj_UxVuD89tZ2y5zA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephen',
        lastName: 'Poli',
        emails: ['spoli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGL0Z5rgGJysA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709992295898?e=2147483647&v=beta&t=Ziv7Ni5goWv8uMJV4INNQqPg84aksGRpmIfE1bFO_No',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jessica',
        lastName: 'Dorman',
        emails: ['jdorman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEH2gL9yXB1lA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517457266377?e=2147483647&v=beta&t=WBYVa6oJxM8SCFZT6lWKBnI-O8tGOC4gEdRavvePeUs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  micromerchantsystems: {
    name: 'PrimeRx.io',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sylvia',
        lastName: 'Mendoza',
        emails: ['smendoza@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Darrell',
        lastName: 'Hyde',
        emails: ['dhyde@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEYB8DcGxczyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675897041365?e=2147483647&v=beta&t=52hS3SB9hARjW_7Qtc6GNp1uWaJx2IlrTV_re57ydLE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alessio',
        lastName: 'Nasini',
        emails: ['anasini@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sam',
        lastName: 'Pizzo',
        emails: ['spizzo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Maxime',
        lastName: 'Got',
        emails: ['mgot@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  atgtech: {
    name: 'Ameritech Global Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shish',
        lastName: 'Pal',
        emails: ['spal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHwtoh9JnlPUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516846001129?e=2147483647&v=beta&t=cJZ6v-C8FZNmapfCPY4DhZ8vAXm4h6ej-IAZy54H14Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'krishna',
        lastName: 'Grandhi',
        emails: ['kgrandhi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEvC8tVhYMkvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611332352658?e=2147483647&v=beta&t=bargIeKEoSZ-iNf5BH5EXudjW3T22s-eqpnvVf4em4s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Thulasi',
        lastName: 'Bomma',
        emails: ['tbomma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEkepuvks_lSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671307347504?e=2147483647&v=beta&t=bVpkZX5aDa_D-59PZB9KoBt03i9RO3vmOkbAT5uT19U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hussair',
        lastName: 'Kaja',
        emails: ['hkaja@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shashank',
        lastName: 'Gole',
        emails: ['sgole@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE5ECcb5_fviA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625170630075?e=2147483647&v=beta&t=BV7LrTV30bVC9_6tCgwaPlQ5g6tE9bPWU0BwsVhVLJc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  verrex: {
    name: 'Verrex',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Suzette M.',
        lastName: 'Pabon',
        emails: ['spabon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGiNXtxCxP0Lw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728317650460?e=2147483647&v=beta&t=dmYsczdenVLnW9xaHZtcKiQC2kfjHdnTdiriPkMKQvU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eddie',
        lastName: 'Santos',
        emails: ['esantos@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Thomas',
        lastName: 'Berry',
        emails: ['tberry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHoreFKuA-l2A/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516331038017?e=2147483647&v=beta&t=q4s2E7Mr75-BQBiG2vK168BLoSNJSY2idiXawe4P2_c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jonathan',
        lastName: 'Shor',
        emails: ['jshor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFEd7FWGre0yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516543124819?e=2147483647&v=beta&t=pDkI8_5l1nNDy8cG_W5vyFOY0OkdwgZwyaJs_hGB2p4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daniel',
        lastName: 'Baxter',
        emails: ['dbaxter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGsw90rVj674Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516519790426?e=2147483647&v=beta&t=o88mnL8jrDi4C2bYw2vl5YniFcWq63Z0iuI-cUfVEQM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  btgrp: {
    name: 'B+T Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Greg',
        lastName: 'Mccall',
        emails: ['gmccall@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Warren',
        lastName: 'Little',
        emails: ['wlittle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEEaN2DBXX87w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633516198473?e=2147483647&v=beta&t=ye844gmTxe5c4dksFbggXn1UYuEagLOqv5MxYMhaBHs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Armand',
        lastName: 'David',
        emails: ['adavid@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFbmPjA3msfMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721289921712?e=2147483647&v=beta&t=n0CTT7zR3JvdmvEl4jSPTrSyxiFLMl4--VjFGknsvzM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Fiona',
        lastName: 'Vines',
        emails: ['fvines@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHKeGTpTivOTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536126206743?e=2147483647&v=beta&t=DeTPWdxyiVab05Vzcejm3DEktGjBLXnbE7g5ZE14f1M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Helen',
        lastName: 'Whetton',
        emails: ['hwhetton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFzWxXTaNvApA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648802431876?e=2147483647&v=beta&t=KLBOKuMTqKBfdUjcwVD8YyDyPVspGtZnzbLIglmEuKg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  studiodesigner: {
    name: 'Studio Designer',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rick',
        lastName: 'Raven',
        emails: ['rraven@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGMCKBjHZtI4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703450060685?e=2147483647&v=beta&t=0Pefy--v1BCqZ-YPQnaZlVZoCTGzvLJJKeQSxLlA5EM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Valentina',
        lastName: 'Hernandez',
        emails: ['vhernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF1rsnwAZQnfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606146976402?e=2147483647&v=beta&t=bXSVMdepXQD4N2pzn3r-bFF4g37w-yReWLUfLRsShYw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shayn',
        lastName: 'Nicely',
        emails: ['snicely@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHk6MxJm9qSiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582818864464?e=2147483647&v=beta&t=SV3d5l83ANucmZVvY81VDU7IyU8kM6szhwwWhzltsl4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Corey',
        lastName: 'Bade',
        emails: ['cbade@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF75sUTsMoP3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1537137426167?e=2147483647&v=beta&t=VRG19XoTBw1MFUbGmneYQXoJj99WbnrZeApuZ3l8hMs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christian',
        lastName: 'D.',
        emails: ['cd.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH-9-VcnQ4VBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700931002357?e=2147483647&v=beta&t=RjYTIGnfDcEBpqh415h4MbbwCBVE1AEeHuXlqdNszP8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  unissant: {
    name: 'Unissant',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Maria',
        lastName: 'Genar',
        emails: ['mgenar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Manish',
        lastName: 'Malhotra',
        emails: ['mmalhotra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGkVWnwby7xBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701663201376?e=2147483647&v=beta&t=PSavo-HEIFNBJ9jurb-4vap1QI5lVJdWg8QZFZTVmdM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ian',
        lastName: 'Graham',
        emails: ['igraham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG9l-ts-f-Psg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681349203287?e=2147483647&v=beta&t=0cD8iPBw472YLRJvKC09gJJPah-7G7MgNuw4xd9a83o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sean',
        lastName: 'Gilman',
        emails: ['sgilman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF022-PNjJ4eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622572081894?e=2147483647&v=beta&t=NJo-mXx2kWf_ly6s5Zgi8D1NlifGl_1E13-C67g83rY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sumeet',
        lastName: 'Shrivastava',
        emails: ['sshrivastava@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEDCOHIvzA46Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516303333870?e=2147483647&v=beta&t=aw8a5Btt1DKnMaWxF0_9z9qLxwsHnrhGeq5_rtQXb_E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  keycentrix: {
    name: 'keycentrix™',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Binny',
        lastName: 'John',
        emails: ['bjohn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Taran',
        lastName: 'Taylor',
        emails: ['ttaylor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFz5lScyg2idw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663595972242?e=2147483647&v=beta&t=4m4rldofhubnHOLPNqZnSGhIV3C9jIJfO_hqXxko9z4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nicholas',
        lastName: 'Ruiz',
        emails: ['nruiz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQElw3ya3Vcsmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661796861953?e=2147483647&v=beta&t=Ke1V2a0bMtwH3N5g1AfbhY0M4HvEsJBU00JrnFNKpKE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'James',
        lastName: 'Kupke',
        emails: ['jkupke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQETRYiAXtQY9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649176022766?e=2147483647&v=beta&t=CiY61TAygHDngmP3a6hOvAV4AxwjEkOSbc_ATYAP5Gk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Luis',
        lastName: 'Rodriguez',
        emails: ['lrodriguez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xbyte: {
    name: 'xByte Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Timothy',
        lastName: 'Powers',
        emails: ['tpowers@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ryan',
        lastName: 'Brown',
        emails: ['rbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG7AahsSgW4uQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517667405490?e=2147483647&v=beta&t=fEYWXZxHyzf0gemkRanrhjYudg4Qaffrd6vKvLwwmcU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daniel',
        lastName: 'Villaveces',
        emails: ['dvillaveces@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGJBJNXxUpeHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586871699553?e=2147483647&v=beta&t=4birFv1ABJebNhQIy5qZNBYvDaSXA9JaYKdUdz5XsNQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lyndsie',
        lastName: 'Warren',
        emails: ['lwarren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFyy2z-OhGAyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600957209436?e=2147483647&v=beta&t=nbBpjIV682AQmfckbm3gmFwfMiOKzc3ehKStc5CmAGQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jason',
        lastName: 'Grass',
        emails: ['jgrass@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEjUTYO5FP88A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702868010506?e=2147483647&v=beta&t=SEafNah0L-nNILIIzJ1h_OlvY1V-1U_wt5ZkOhQDoo8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hitachisolutions: {
    name: 'Hitachi Solutions Germany',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tom',
        lastName: 'Galambos',
        emails: ['tgalambos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF8wquZ1f9JPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668450651049?e=2147483647&v=beta&t=hVz9svb7r2ykgXVDNJWEWprbd_To1l8mDq_lG71IDUY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joel',
        lastName: 'Lindstrom',
        emails: ['jlindstrom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFTxCLpyAQYkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693323782741?e=2147483647&v=beta&t=HRA4S78vXqVNyLxdf6wLnHAWw9BtLn_pvhV2q9K80mk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Azure',
        lastName: 'McFarlane',
        emails: ['amcfarlane@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGIxi0CnTndKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700500360354?e=2147483647&v=beta&t=uatggtuMQAJvxiWISkqys9Z8oGR91bxMOY9wihVNoDA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jon',
        lastName: 'Loring',
        emails: ['jloring@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQERFC82rHedpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666295986056?e=2147483647&v=beta&t=r6mvKx6snyn7n29yepihDgAXxZzf64wlbqEJWsO6Mnw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amanda Anderson,',
        lastName: 'MBA',
        emails: ['amba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF-T936ush4nQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690573270416?e=2147483647&v=beta&t=RKyHK499Z9cw-T2AAov3y7UxrQXACy14AYhg9eWH7VE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hansecom: {
    name: 'HanseCom Public Transport Ticketing Solutions GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Erin Kelley – Project Manager – HanseCom Public Transport',
        lastName: '...',
        emails: ['e...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFTDSB--RtPiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705576331787?e=2147483647&v=beta&t=vvT3THExbHLctu7G48VO6b1jisrqrNI2QQRwV3xXRW4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Camila Genaro de',
        lastName: 'Oliveira',
        emails: ['coliveira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF8UCbfVlcuyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637236403201?e=2147483647&v=beta&t=atFPcQ_GYbkwYhBgS9pD3K54byMz6nXO2CoW4-podIU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Juliane Fenske – Executive Director/Prokuristin Business',
        lastName: '...',
        emails: ['j...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGUOTJzeZPu5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646667636042?e=2147483647&v=beta&t=6YiZEteUPxpFDCUHAafR72yEy9itVNRSAWffP83koLs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Karin',
        lastName: 'Jansen',
        emails: ['kjansen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEXA91uGd5L2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691659725397?e=2147483647&v=beta&t=VEswOJwxVZLKlWuAFMx8CjS_hj808_E1r5K5gZOnI2Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kay',
        lastName: 'Paetzold',
        emails: ['kpaetzold@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '1up': {
    name: '1upHealth, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Donald',
        lastName: 'Rucker',
        emails: ['drucker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEjP3QYj2a03g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516975973856?e=2147483647&v=beta&t=asVJVOAFglNsIaXHwajpb2MBVin7I4MWHITS6hHLHkU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Graham',
        lastName: 'Gooch',
        emails: ['ggooch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHrWpc_LFWtHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620424728931?e=2147483647&v=beta&t=DnZWT0pXSCm6VNhetNkqWOL69iySIIVxnXpxegQFzs0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pieter De',
        lastName: 'Leenheer',
        emails: ['pleenheer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFl_6H55V6w0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729799191824?e=2147483647&v=beta&t=fDoYPzzJIYHuWNzUC4YIJXkV5lYncxwkB2yj0T-2YUU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ricky',
        lastName: 'Sahu',
        emails: ['rsahu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGawlx816Q2Yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636657011351?e=2147483647&v=beta&t=G6JSEd40SWFxM9SRbqV5QID4msG9KODPtNy4crAieIU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'Boyd',
        emails: ['aboyd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEZvbI2J7SLMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729202482097?e=2147483647&v=beta&t=mhWeWaYTtzAjuT1AMkj7vJ_v0FUkreyyuJ00epimi80',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  triyam: {
    name: 'Triyam',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Luka',
        lastName: 'Salamunic',
        emails: ['lsalamunic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEBxIbh-LMILw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687432216522?e=2147483647&v=beta&t=hq4pQmuF7x11SyxDXuaHzojZ0Nc57CQ8uUxoT8H4s44',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sudhakar',
        lastName: 'Mohanraj',
        emails: ['smohanraj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGebvPm3TDHuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683704703411?e=2147483647&v=beta&t=cAIJO1I01-3FpYXWZ4SOB7IbblynYD3JvyTKo9qABxA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lisa Williams',
        lastName: 'Garcia',
        emails: ['lgarcia@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Antonie',
        lastName: 'Katscher',
        emails: ['akatscher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFMXvMdTaaBjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652894606625?e=2147483647&v=beta&t=Ntd4BFeExE5QdTC8937lId_i_dIAgVu_I1BQ2sdAiKs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Veerappan',
        lastName: 'Palaniappan',
        emails: ['vpalaniappan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHYbTXz3XQEyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1523454848446?e=2147483647&v=beta&t=aiOe_FKS920ozzQb8BKrgEue04akWQfh0w4MdS4m0qQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  celona: {
    name: 'Celona',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Darrell',
        lastName: 'Gonzalez',
        emails: ['dgonzalez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHF8aP3bg-t4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708454662676?e=2147483647&v=beta&t=ibpl8baf_59Xt1SSjDevnM5f-idI_Ae-sg9uSOyXOyM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kseniia',
        lastName: 'Zviagintceva',
        emails: ['kzviagintceva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEKP6uKic1o-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517030451670?e=2147483647&v=beta&t=26peahuf69V57jQ-ZpAY7f_AMUYXFtS2S1L3AgQjuPE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Taheer',
        lastName: 'Khazi',
        emails: ['tkhazi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'James',
        lastName: 'Krahn',
        emails: ['jkrahn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEvkdkQR5bmXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517279320853?e=2147483647&v=beta&t=A5yu7WNR8IkwMd13KYz3tU6sHzTL0jOBskX9pl5jt7o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ron',
        lastName: 'Fowler',
        emails: ['rfowler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  race: {
    name: 'Race Communications',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Scott',
        lastName: 'McDonald',
        emails: ['smcdonald@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQE9G31cNWbJag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517418637550?e=2147483647&v=beta&t=fpHe1VoBBmNFE_PEzvHbcwAqm481F723DOpajemwY5o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kaitlin',
        lastName: 'Colston',
        emails: ['kcolston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHycKJinzgJ1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715722765735?e=2147483647&v=beta&t=nvWuOLuQGIno11gDxjaktNVLeIbNDkgES-INjXrFuws',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carlos',
        lastName: 'Alcantar',
        emails: ['calcantar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'William',
        lastName: 'Earley',
        emails: ['wearley@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paula',
        lastName: 'Poole',
        emails: ['ppoole@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHI7nDh1OkWgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517437933669?e=2147483647&v=beta&t=ZgMzfTU0pVTg5gtrZcMN3dBKZkLil-6CtNlJPlizo9c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  themooregroup: {
    name: 'The Moore Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Charley',
        lastName: 'Moore',
        emails: ['cmoore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHctp5StP5IbA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1525459729232?e=2147483647&v=beta&t=I0V-MgDDGgz2tV2YtnFFaKf-NTYNZjZPATORNIzYn_k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jennifer',
        lastName: 'Moore',
        emails: ['jmoore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEL4nSFQfwbtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581465943128?e=2147483647&v=beta&t=YJHo2yjg2bQgKE0-MI6j5OWG21mfiVNf7wCOwB9WbVc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark Moore',
        lastName: 'Jr.',
        emails: ['mjr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEq2FJzH46w5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1566264330696?e=2147483647&v=beta&t=wecYiJ_T-eSewOwsa7p9MBMA6JmlzF_Vkdp5CP9Rt_A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sean',
        lastName: 'Moore',
        emails: ['smoore@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jackson Moore,',
        lastName: 'CPA',
        emails: ['jcpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGBZakwLrUi-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652232510620?e=2147483647&v=beta&t=j5FMj9kYKjlyeJvv0ILnAB41e569ElYR5FUitw_43gE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thirdwave: {
    name: 'Third Wave Automation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Myers',
        emails: ['mmyers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF8RT0Q8v-HRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713378913154?e=2147483647&v=beta&t=2-DVYntsoi-SUg8mUhnNXZi5QYOXNfE-wRoAOSTVru4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nate',
        lastName: 'Faber',
        emails: ['nfaber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEVbU4JtSGaXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677262922482?e=2147483647&v=beta&t=J_n4jfX1K7l4FXHbGGD2Nixn6KoK_kV_KbIVIK7k5Ls',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Arshan',
        lastName: 'Poursohi',
        emails: ['apoursohi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE7Bk4u4e5IQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564610877835?e=2147483647&v=beta&t=c203D2FdXOTN3_mSbEXfnogxrz9DlTXQh9m4rLPkY44',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'Willis',
        emails: ['mwillis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEFTZegJwUD5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517501970412?e=2147483647&v=beta&t=le59mBnefs4AynafHvr-xPV8mUr4YtPENKWJxbltsRo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Julian (Mac)',
        lastName: 'Mason',
        emails: ['jmason@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHE26GfNy8m3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517044131523?e=2147483647&v=beta&t=NVouhZa6LyH_91igsY28RzcpnM3BPMIE1qJFlnyVMvE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  radcube: {
    name: 'RADcube',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Feroz',
        lastName: 'Syed',
        emails: ['fsyed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH84fakRV5O2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693262447918?e=2147483647&v=beta&t=BjAHYV1r3cHZyW7rzoMkBFtXijs2QWuJ_snVYgI5_3Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carmen',
        lastName: 'Prajescu',
        emails: ['cprajescu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQELbaRPRVwAlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684786534559?e=2147483647&v=beta&t=GmFWAQSIdUZ1XU-c_EQjMvMhMR8RSswQSWFkPhDZPt4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Derrick',
        lastName: 'Cash',
        emails: ['dcash@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Spencer',
        lastName: 'Andrews',
        emails: ['sandrews@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQECTno9n0cA_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718222334823?e=2147483647&v=beta&t=3be5zDG1mtuHPJ5HsBDnQqzDiOPTjhtHhKz7xl9EXGg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rakshita',
        lastName: 'Modugu',
        emails: ['rmodugu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHBi86apf02IA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729808617047?e=2147483647&v=beta&t=YVqM0MTAZjbfVX4v5gNVC3bwFkYVYTCQARrtA1ugFVA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  petrosoftinc: {
    name: 'Petrosoft LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Guenther',
        emails: ['dguenther@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFxqX1tTgbeQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531231540797?e=2147483647&v=beta&t=60hGFGBbsWuGLGDOt2otl6NoZJFofxSGpPlQABwxxuk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Donald',
        lastName: 'Rendulic',
        emails: ['drendulic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEo-lGJ0Zfayw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516532952559?e=2147483647&v=beta&t=Fz0f8Uk0rcwR131RnIzNM3l_SADdDs6jCBOrpppdkaE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sergei',
        lastName: 'Gorloff',
        emails: ['sgorloff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG_FKftrotyRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517523336735?e=2147483647&v=beta&t=GYcVl4pALfEeN-LxN7zDU4KSGl8FRKT5s7a231GxPu4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tegar',
        lastName: 'Harrington',
        emails: ['tharrington@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG7EmO-SjDNaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569246155325?e=2147483647&v=beta&t=erEPMz13b8HaFEx6UtrEZRA34EsDUGUPmkmsT82lWuE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Barrett',
        emails: ['jbarrett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQErALjgIh2Pkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726250422976?e=2147483647&v=beta&t=-ebUN9-pQc6J-3biqpsDEWa_w-MEdOFMa_fEpNvCpYo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gridgain: {
    name: 'GridGain',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andy',
        lastName: 'Sacks',
        emails: ['asacks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFQKpB5Jz_yCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609027214426?e=2147483647&v=beta&t=r1WsI9dNqyMRWsS9X6IdspFbgLAuPy2jt87VjArL9f0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Katherine',
        lastName: 'Rincon',
        emails: ['krincon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEARnT-22Eo2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517589667608?e=2147483647&v=beta&t=jkhOPoIInwk6F-cC-RY--OCuc2ci1Kogei81rN2Unpc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pete',
        lastName: 'Whitney',
        emails: ['pwhitney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGm3kP1BL29mA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522583637871?e=2147483647&v=beta&t=ziZFPpcEXbugpXlnwWfE0wcYxfNacLjjita5Mg53zIs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stanislav',
        lastName: 'Lukyanov',
        emails: ['slukyanov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF2Yi5pMoenXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571077059030?e=2147483647&v=beta&t=jZpyC1yVbk34paCuO6-9pnMMqXTTF7l5FxhEO8FphXw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abe',
        lastName: 'Kleinfeld',
        emails: ['akleinfeld@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFzci7zA9NcHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516522886534?e=2147483647&v=beta&t=-19NYkbHxWREYhMbX7Z8J6--mUedQuFZ_l6FO5yR-Js',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fuseeng: {
    name: 'Fuse Engineering',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sidney',
        lastName: 'Hall',
        emails: ['shall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGP8Tt5JMbpqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517616110635?e=2147483647&v=beta&t=7HDCwhGOimx5w5zb2NmOp53Pm96E9wGgjuJQ51nGaEg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Kohler',
        emails: ['mkohler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFt5A8ZgWOVlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623849204118?e=2147483647&v=beta&t=xxdRWYuqzN_4DGP5ulQZ2QNBI68o6JcRrWPplqL5wWs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ashley',
        lastName: 'Baldwin',
        emails: ['abaldwin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHTzuAE7xAugA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642550317087?e=2147483647&v=beta&t=NxlzVLhqHFEOH-QTLv1tGGkWXsNCGGTl8PMn2skUklk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daniel',
        lastName: 'Kerr',
        emails: ['dkerr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFEWx3_OmkdfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516930352194?e=2147483647&v=beta&t=2UOwGe2lmTuCvv_WCD7SEY9klEpB7T8978G14lfgDAg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gregory',
        lastName: 'McCusker',
        emails: ['gmccusker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFAa3ooeH23pQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636049507054?e=2147483647&v=beta&t=VC0LHr5wSZz9tLxZN0Tu4NzVDOEsQeaC_TYSkBCLhHI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  remarkets: {
    name: 'ReMarkets',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Benjamin',
        lastName: 'Ellis',
        emails: ['bellis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQETL4ZSbv7KHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624318581899?e=2147483647&v=beta&t=u5CxtyaB4Ue8ZgB8cVDnHGZhCrEwCDyTV1AX1_lu0ME',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joel',
        lastName: 'Dawson',
        emails: ['jdawson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Council',
        emails: ['scouncil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHQ10LPCcho0A/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517337891047?e=2147483647&v=beta&t=XLRdRjWIXC4N3EEwV2gRHkyZVGB-aMfYVYtEB1mKsxA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'James',
        lastName: 'Kelly',
        emails: ['jkelly@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Katherine',
        lastName: 'McCormick',
        emails: ['kmccormick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGLjSdatk_G6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685110079914?e=2147483647&v=beta&t=fYez8crD_mNZvwXoVXvPobOQ1JKj-XobVq8KaUWQt5M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  teesnap: {
    name: 'Teesnap',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jim',
        lastName: 'Wood',
        emails: ['jwood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFHJiRbCQ6Ejg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619186597491?e=2147483647&v=beta&t=Q0tCLOhVNngBKKcrjeyKRKyNt5rlqoDqvTjpDfmHUvM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gene',
        lastName: 'Pizzolato',
        emails: ['gpizzolato@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGkegIEgTnB6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516443892812?e=2147483647&v=beta&t=5tHVTMuVic_5M1DXdBHigTWmM9_ZpbiaPvOVYIFWLzY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sara',
        lastName: 'Sorensen',
        emails: ['ssorensen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHmHPnjqqXxnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666658992673?e=2147483647&v=beta&t=Iyoc-pNEwY5352j4-f1jyK7aQ4lhsUsUCbRMGLAMnW8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Greg Blackwell,',
        lastName: 'PGA',
        emails: ['gpga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFdL5PWBLziFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567097764193?e=2147483647&v=beta&t=VoVF7XkA5R3wU5f5N9Wy3psplqG1HhLZRkidG7U4lS4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amitsinh',
        lastName: 'Nakoom',
        emails: ['anakoom@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wsddigital: {
    name: 'ReFrame Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mike',
        lastName: 'Choiniere',
        emails: ['mchoiniere@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEfiWo09B14mQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610131089184?e=2147483647&v=beta&t=IexqmumcTlKnCM40ztPaPiFrKvzOjfDzo5NMkPnyU5Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Elizabeth',
        lastName: 'Jackson',
        emails: ['ejackson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stan',
        lastName: 'Miller',
        emails: ['smiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFx0F2lF09PNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658319038115?e=2147483647&v=beta&t=ZrVfrNaYdptDJICa38YT3gxIMomJ-7rOMPajSDnb-vg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kelly',
        lastName: 'Farr',
        emails: ['kfarr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEnqu9DGBZbJA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517431015519?e=2147483647&v=beta&t=fyTicDYYdqcwZ5pGZYVUze_20LSDXjKhPnrKER_8DHk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Scott',
        lastName: 'C.',
        emails: ['sc.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ghdsi: {
    name: 'Global Help Desk Services, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aaron',
        lastName: 'Shultz',
        emails: ['ashultz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEt3dllQv_Fog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521589921645?e=2147483647&v=beta&t=DfJl8VNwyMJdqnjSFEv0XOka4D5mJx3La99brpAXpjE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Karen',
        lastName: 'Wetherell',
        emails: ['kwetherell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yazmine',
        lastName: 'Soto',
        emails: ['ysoto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFIIN-AGTFWVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718249258680?e=2147483647&v=beta&t=hUNagEWn3CD1P2MUCGNPcYfKHcAfo1gIdRHHKSiIcs8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'Baxter',
        emails: ['mbaxter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEpYK-s-sKnpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673045549985?e=2147483647&v=beta&t=0-tl5eDZI4vfl2TGSVEsBzuE90WqxqoT9-nKEmSsbNw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kolton',
        lastName: 'Rauber',
        emails: ['krauber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE0Ll5XxblJRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644520237243?e=2147483647&v=beta&t=4IgCmM_VpmLhr7dJXilT5EHq4UJ5tOfpCGT8-uE6hnQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  radarfirst: {
    name: 'RadarFirst',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Don',
        lastName: 'India',
        emails: ['dindia@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mahmood',
        lastName: 'Sher',
        emails: ['msher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF-GoBO6D4TLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607101712501?e=2147483647&v=beta&t=yBkR8NAUmJzVH4z7SkxkJ35OaOIqlkviFch-ZPnGBxI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Courtney',
        lastName: 'Moss',
        emails: ['cmoss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFnWsIjD02b8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629478642876?e=2147483647&v=beta&t=t_StEE-_R9qnL5afH_yvdaCKXShCxzIHNNwKe2T4f5I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zach',
        lastName: 'Burnett',
        emails: ['zburnett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGwBIRZDaV0Rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579447027231?e=2147483647&v=beta&t=2do9qlpoUyaHWkO3c0Imj0jA22dq1QMOPzInMDD2PQI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lauren',
        lastName: 'Wallace',
        emails: ['lwallace@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGjzZ_75z4xCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718318034422?e=2147483647&v=beta&t=AfDEwjDlhezvjyBrrvOa8sDTQ9_Rsg-u_wKDjv0A1wE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  onestopsystems: {
    name: 'One Stop Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steve',
        lastName: 'Cooper',
        emails: ['scooper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFNDN-vVrtpTQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517663879996?e=2147483647&v=beta&t=aEKaqYLcXhLJ1VfCAicaC815psJ2Y6ALHLHozYZB4Gg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mike',
        lastName: 'Knowles',
        emails: ['mknowles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHP1lJzO3dgqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647250098021?e=2147483647&v=beta&t=PaIs-ZQgUcwdTskVgeld0NxIhjXmOekpzwCQdwQiFRE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Robert',
        lastName: 'Kalebaugh',
        emails: ['rkalebaugh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF5jsyw5GOdaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724258819733?e=2147483647&v=beta&t=RkVqqnHlR740fIVCbhW0b1Ug73A1C3xkaErMKG92Cdk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jim',
        lastName: 'Ison',
        emails: ['jison@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jim',
        lastName: 'Reardon',
        emails: ['jreardon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH89JZ_U8zH8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516262239398?e=2147483647&v=beta&t=LvWIuVRx6u6IWCUfhksZjxNDtuC8EUUwTtwkTj2IBSw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aquinasconsulting: {
    name: 'Aquinas Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert De',
        lastName: 'Carli',
        emails: ['rcarli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGjVtu5cE84aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548191456411?e=2147483647&v=beta&t=U5aIzcPp9ElP6i1_yVubpgK_RgSpsjDXJUkAdnGvK9g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Murphy',
        emails: ['dmurphy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH4y-waXTZX6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681762849030?e=2147483647&v=beta&t=tuDNRfRs85Q3OwKXuco2-EmfhCeVMnV9GBJnwT9XvKI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sandra',
        lastName: 'Carbonaro',
        emails: ['scarbonaro@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joe',
        lastName: 'Papuzza',
        emails: ['jpapuzza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFyTVA3HKKJFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516553691585?e=2147483647&v=beta&t=dWQmVu-9pozFfU-mss6gwRSOAt11snZcd8wifQxdD3M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pam',
        lastName: 'Rasmidatta',
        emails: ['prasmidatta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  techvtal: {
    name: 'Tech Valley Talent',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tina Dockal',
        lastName: 'Markle',
        emails: ['tmarkle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHsJ8ZGLZIWHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647998410540?e=2147483647&v=beta&t=2HORIoiKrWEj_GHRAaAr1ULyvQ1NQCNbMvXKjssEZaE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jennifer',
        lastName: 'Lewis',
        emails: ['jlewis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEi3x3Uyov9Jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706715604849?e=2147483647&v=beta&t=bdww796gMyuPt2jQJp0zmBaSaMVyeVmAIbCjLoO_Q3U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jennifer Wood, SPHR,',
        lastName: 'BS',
        emails: ['jbs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQELVfnU5LqPGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682457948421?e=2147483647&v=beta&t=-m2xoDuoSsu0SjzOwGsmXrK1eljYBjUm3nUhz6047pU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kevin',
        lastName: 'Carpenter',
        emails: ['kcarpenter@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tony Buscarello, PMP, CSM,',
        lastName: 'CSPO',
        emails: ['tcspo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGaLVbDE7RDng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651699433147?e=2147483647&v=beta&t=EUlWR0l1Iojn8Oh9sodL6AjBahLoMDNQkr0IpgE4daA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  weblinkservices: {
    name: 'Web Link Services Pvt. Ltd.™',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vipin',
        lastName: 'Shukla',
        emails: ['vshukla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH1rCGcFX9v6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672986732111?e=2147483647&v=beta&t=G5KqGMtT-twMbh3WmueGNqabn4f_ViRuR6NGTtmfMhQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mili',
        lastName: 'Halder',
        emails: ['mhalder@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Akash',
        lastName: 'Rathod',
        emails: ['arathod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE8JPnsukPTyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664337043473?e=2147483647&v=beta&t=fdxgroJboBCo-Vbhq5f6EiLOuZkm9btIfMd_yuv0Zvs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mansi',
        lastName: 'Jain',
        emails: ['mjain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGL3ulM8-3Hag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725560220714?e=2147483647&v=beta&t=n3nJv0HjrYlbrz9FkVIxNMAbrchXjbtpoqMuV0X0ujw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pooja',
        lastName: 'D.',
        emails: ['pd.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEte6y58LgDNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676536155845?e=2147483647&v=beta&t=77Dlj4FFAm2sZwoOl-sT20romzWwYoS6PJUKY5bwdZ0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xTechnologies: {
    name: 'X Technologies, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vaishali',
        lastName: 'Bhakta',
        emails: ['vbhakta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHx6xL3aEuM5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647890492262?e=2147483647&v=beta&t=axjSB3IW6h89n1X7YBqq4dDLo-3n2fCocVs7FUbzQuI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bradley',
        lastName: 'Styers',
        emails: ['bstyers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEThxxW75i4mA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517261818164?e=2147483647&v=beta&t=eNeu0OMJSp1k4Ig1-ttCSnciyFNLrWAaZITt0_xMoPE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Thomas',
        lastName: 'Clark',
        emails: ['tclark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG2SI30qfqA5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724260890509?e=2147483647&v=beta&t=WZpLAE03Fn2xlZ37quU_w3LOkkRyGa7QSbrjYBn6EMs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ashley',
        lastName: 'Ayala',
        emails: ['aayala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFXTpt-KMBWLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674509313766?e=2147483647&v=beta&t=c8QxXCZAScmFZj5MwjXRigQqTNo-FYgWIKn9xCuSGjU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jesse',
        lastName: 'Ayala',
        emails: ['jayala@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  addsys: {
    name: 'ADD Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bruce C.',
        lastName: 'Bott',
        emails: ['bbott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHc-_vhgddcsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331044018?e=2147483647&v=beta&t=59UKKdglkL2p2X_JUR8EDuTDexhujQLIWEkm8C0tSdE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Coyle',
        emails: ['jcoyle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGMnRPsLa2b-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517673503262?e=2147483647&v=beta&t=NsIx2fPtNPjvKR4kCnjnRbMTIw_DZsnuseB_XW87tZk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Kiernan',
        emails: ['ckiernan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGDIUslPHP1zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516954179540?e=2147483647&v=beta&t=6CzvOpmfUpAkbmg_sn3VpbSBbxrGwRHTuXdJ48iETVc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Regina',
        lastName: 'Balistreri',
        emails: ['rbalistreri@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Maqsood',
        lastName: 'Syed',
        emails: ['msyed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEBsmka82Z6CA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661389960668?e=2147483647&v=beta&t=gWDl1x9k9ouFlgv5IswYJQbxm0nrcRuVKo1X9wQMbgQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  roisolutions: {
    name: 'ROI Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Karen',
        lastName: 'Taggart',
        emails: ['ktaggart@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gina',
        lastName: 'VanderLoop',
        emails: ['gvanderloop@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chad Stein,',
        lastName: 'MBA',
        emails: ['cmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brian',
        lastName: 'Dockstader',
        emails: ['bdockstader@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tom',
        lastName: 'Cook',
        emails: ['tcook@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEDQ5jG529nGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615313935393?e=2147483647&v=beta&t=8Wb6-cCd-TpEKJ3Kpha9UZJ0RcdL0LuJXjmQ5zEEULk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  evolvingsol: {
    name: 'Evolving Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jaime',
        lastName: 'Gmach',
        emails: ['jgmach@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQESjJbiTQn6AA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1539197335110?e=2147483647&v=beta&t=_Yr4ilCFc1oksH7lWa-wu21QX51pIL0ero5xX98uBXw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bo',
        lastName: 'Gebbie',
        emails: ['bgebbie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGtoyFlD-o5lA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675467063163?e=2147483647&v=beta&t=AMhGhpIjGxaPcY1d08I_zsAy7ppVEkuk4aRTOAcKqho',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Susanna',
        lastName: 'Woods',
        emails: ['swoods@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Downs',
        emails: ['mdowns@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFrp0qgpJprOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692804483906?e=2147483647&v=beta&t=Htd7BCqYMSxqKWGkT45CFwYm79qzoHQU_dNSh2u5fL8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kelly',
        lastName: 'Hirner',
        emails: ['khirner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  woodcons: {
    name: 'WOOD Consulting Services, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dr. Margaret Wood,',
        lastName: 'PMP',
        emails: ['dpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFrhlroE-xz6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621363575012?e=2147483647&v=beta&t=g6ICmrs8wW4FqBCkLYTGNsdi_6gjjMpvL8L_g0KCqfQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sean',
        lastName: 'Gleason',
        emails: ['sgleason@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGZXvqDgQW6Ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516325970543?e=2147483647&v=beta&t=8tFBeXW_BLoyKGVvd-y6iy1gXrCUwrGZX4PMspV58ns',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jonathan',
        lastName: 'Graham',
        emails: ['jgraham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQExJx9-uD0AIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658418471203?e=2147483647&v=beta&t=92ZCnzNstuIgQLKVRgiSPIJ51sjgfQsLFEyCDs-R_Jw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amy',
        lastName: 'Parsons',
        emails: ['aparsons@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gary',
        lastName: 'Reeder',
        emails: ['greeder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQELsGQMfJzJwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517072696666?e=2147483647&v=beta&t=tFP4TWcceyQ9dJqyoi0oBM3hAJMdAdAPOB7rHQGgV7k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  networkoptix: {
    name: 'Network Optix',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nathan',
        lastName: 'Wheeler',
        emails: ['nwheeler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHRPFwuhigw_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719012893635?e=2147483647&v=beta&t=mEVAvggQuab_87AK-6iwSbiR9AxeRAYr6zB-buKx_sM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Richard',
        lastName: 'Fei',
        emails: ['rfei@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGi-XQS75jXJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670982941255?e=2147483647&v=beta&t=LSJk_DSQPGru7THzZBdvO228Gdur2j6Xzq8Ad2-BIHQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dmitry',
        lastName: 'Sidorenko',
        emails: ['dsidorenko@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anton',
        lastName: 'Nadey',
        emails: ['anadey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFSuu1-MYiycA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721419204518?e=2147483647&v=beta&t=bxnF1PDBvQ1Of5YdnrskiHAz2k6Bs03qvm_oHSKpF0k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'James',
        lastName: 'Cox',
        emails: ['jcox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFr7B08IpC2Jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730212857119?e=2147483647&v=beta&t=kbCYlubbhF8GLeIy0azsw0zlBIDGJ5EV9hgxygGoQ-8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mindsetconsulting: {
    name: 'Mindset Consulting, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gavin',
        lastName: 'Quinn',
        emails: ['gquinn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGVqUaunY0FvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660255756265?e=2147483647&v=beta&t=18G9SOnaREKEUNleoZJyADLNfscGSM7NzsVsvVXD1Ng',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ethan',
        lastName: 'Jewett',
        emails: ['ejewett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEUQxvFYV8qYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702930524628?e=2147483647&v=beta&t=rOlcbD2QFRu6tgqpd6i6X5KbDktR9ICsG1J6GTr7dCs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Parvathy',
        lastName: 'Sankar',
        emails: ['psankar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHNpI8ea7idng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718554363011?e=2147483647&v=beta&t=Dnvf_t6in9RcBqws-7BIrlh_04z8ZqzgMMFMIazdtaM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amy McNeil',
        lastName: 'Lund',
        emails: ['alund@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFfott6NxxIdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662090770642?e=2147483647&v=beta&t=VaovYLO64wyt8cMpMdmmtewWiDXvjWNsbFlJkxAjzbg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Peter',
        lastName: 'Brasket',
        emails: ['pbrasket@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFySvuc_dKW3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516221301652?e=2147483647&v=beta&t=jBMTiHuZttynUNqU55WsS_-528ZDoypQjjRuBIPMSww',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  axiomRm: {
    name: 'Axiom Resource Management, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tekle',
        lastName: 'Afeworki',
        emails: ['tafeworki@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cindy',
        lastName: 'Roberson',
        emails: ['croberson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFMr4kplw1ziw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542219443847?e=2147483647&v=beta&t=OSFRpirD9pZuNT4xWkedyLQwCreg3gwl1rQily8B6zo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Josh',
        lastName: 'Brick',
        emails: ['jbrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH30njzdYc63w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517593397788?e=2147483647&v=beta&t=Sb1TRxowLsIesUHMrYYxb3sy2gsbS7BwrFm2CLsMcvE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Doug',
        lastName: 'Peardon',
        emails: ['dpeardon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHdZnme-xhlUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569439842251?e=2147483647&v=beta&t=LNlrcTtXEP_jlcKo_sKMzenmnEUWb1-Ayf5NeH_nNJI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Laura',
        lastName: 'Murphy',
        emails: ['lmurphy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG7C1e4P1PhVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521504456647?e=2147483647&v=beta&t=moAVNV_Zw4yHDcHrPn5c0gpmc4oMSC-iNSSzLtZZxtI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  clasonpointpartners: {
    name: 'Clason Point Partners Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Albert',
        lastName: 'Cruz',
        emails: ['acruz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Raul',
        lastName: 'Collado',
        emails: ['rcollado@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGvHRYN4U3tPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516815080995?e=2147483647&v=beta&t=aZKIzOZvXfaVjjcQdirRT_o9037TMgHOouaLCio7m2g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachel',
        lastName: 'Grandwetter',
        emails: ['rgrandwetter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG06ULGOoJ_sw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516827047000?e=2147483647&v=beta&t=1iIG9E4KwvHmhymHQ2SgT01i-_oALpi3rX2Dr7SUYeE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carla',
        lastName: 'Kooij',
        emails: ['ckooij@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF4PZJ5g0pSRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648564676241?e=2147483647&v=beta&t=DVc6DMCeZdSjDdHGvAHIEkxZNNSJ0loyTpm_cJGjwDM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rhonda',
        lastName: 'Griffin',
        emails: ['rgriffin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE7uXs4X093rA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517726795477?e=2147483647&v=beta&t=zmTI9Y5K130BEbMWDW_F-7TkehYxhfZJ203PCPcDEso',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  onjoyride: {
    name: 'Joyride Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sriharsha Reddy',
        lastName: 'Devaram',
        emails: ['sdevaram@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHnjVj83BiIOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691639185882?e=2147483647&v=beta&t=HpUfrgQoPB6jr1LYv4C5QpKjYpvPsvZLCgZ-m3lEW4I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Charles',
        lastName: 'Suwannaporn',
        emails: ['csuwannaporn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHewYKfAQycfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553014881779?e=2147483647&v=beta&t=CUmV-8cgK_PtdYp5RiXUj8ujWyQEZsDs774K7QT7LsQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eshwar',
        lastName: 'Chandra',
        emails: ['echandra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGb16UQO7Dkvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608195283078?e=2147483647&v=beta&t=6F1B6vPTfxgTga8-MBAhgEVJFewxN59lA8W0RMxBNAE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Omar',
        lastName: 'Siddiqui',
        emails: ['osiddiqui@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEpchjscIR7Dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689807249382?e=2147483647&v=beta&t=bwFgmCba5tpmc0CAzyCawmDqcaOzrzUtpXtiPwQbo4E',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chance',
        lastName: 'Santana',
        emails: ['csantana@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  geeksandnerds: {
    name: 'Geeks and Nerds (GaN Corporation)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ben',
        lastName: 'Matthews',
        emails: ['bmatthews@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH_Vfz0YhODDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641846378091?e=2147483647&v=beta&t=tMqeS66Dx3f3qI-Nfo1Fr6L7waufJq97PLjfX5Whm2M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jacob',
        lastName: 'Schoen',
        emails: ['jschoen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEZY7rVjQCi5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683553477277?e=2147483647&v=beta&t=s74bTHPdSb3wI4dtbgVHMBv8HXXkJqlahOY1a_Zq--Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mike',
        lastName: 'Magatagan',
        emails: ['mmagatagan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFT_KGYoZvhVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644325796737?e=2147483647&v=beta&t=WqSAGXp8vw7dVruSAuvRybB5wLIqaJj9QeiV03471mU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Peyton',
        lastName: 'Cook',
        emails: ['pcook@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHleILqQ3GWAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713376446637?e=2147483647&v=beta&t=7HN97Z1DWJiwCX2W2C40gC1GIHxongMBU4j4yqFiYok',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jason',
        lastName: 'Olivar',
        emails: ['jolivar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG3JfdIHEqGEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1520127105615?e=2147483647&v=beta&t=HT4eO223c6_pj3DCpRNQT1wmhdXSYSDxlhsJvSdfSdE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dvsport: {
    name: 'DVSport, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hailey',
        lastName: 'Leitner',
        emails: ['hleitner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQENGEP-x5bR7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682911148029?e=2147483647&v=beta&t=ZjVO-cAVTZjnSK7OvZ52vIf6X-hrDt-i8wJJqmjN1I4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jonathan',
        lastName: 'Plaut',
        emails: ['jplaut@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE6zPGd-MCzMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560440392058?e=2147483647&v=beta&t=RfuxojKSgS8f8YqBuaZIJ0osWkJYWqueH4spdMk3fo4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Derek',
        lastName: 'Glover',
        emails: ['dglover@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFxbhZ9NERL-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516796800195?e=2147483647&v=beta&t=6liT7xKFgJzAtoheZv1tfe7HEakn0l_rfffaxVc20Mc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jessica Spadafora',
        lastName: 'Boone',
        emails: ['jboone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHdLetwQZA2bQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728934951615?e=2147483647&v=beta&t=eaP-Tc3gl447jJCL2r7Us99fLghRtdE2PX7w5T-N-1U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brandi',
        lastName: 'Phillips',
        emails: ['bphillips@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFjFaL0YOu2Qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518529748265?e=2147483647&v=beta&t=fhwKeOIr3QL_SnLtBxdR73cRx1b9dHfILquEU803tBc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rividium: {
    name: 'Rividium Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sean',
        lastName: 'Tourangeau',
        emails: ['stourangeau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGCFPSjtCa9Zw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652250954166?e=2147483647&v=beta&t=BZRP46_VWt2rry0sLsfz1VJ0GtIYJuWlxBs5Wop9Xu8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stewart',
        lastName: 'Bittel',
        emails: ['sbittel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sarah Rogers',
        lastName: 'Haviland',
        emails: ['shaviland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFrC6lMSnJHQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718211022037?e=2147483647&v=beta&t=thF9UoBMKzFzM-f8RtBzN6uCsYnfutSiBAoKx3hQUpY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anja',
        lastName: 'Lindsley',
        emails: ['alindsley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGQ4CeQ__QG0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517533618612?e=2147483647&v=beta&t=d5ndpRCXRnRx9e79obigX7gbLp0wkP-71zEgdXLLlVM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nioka',
        lastName: 'Hall',
        emails: ['nhall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFGDZji2z1i8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517482368815?e=2147483647&v=beta&t=nyFtRtZ-P9Sl5XfMF7_eYa8ARqzladMNbhxng-6-5xw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  qmarkets: {
    name: 'Qmarkets',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael J',
        lastName: 'Stilger',
        emails: ['mstilger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEcQ5sx8APgwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516232123768?e=2147483647&v=beta&t=pbudefmejHBnK1W_TIic-iM-vY9IoHNNhCe3C2XZKmw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Elliott',
        lastName: 'Wilkins',
        emails: ['ewilkins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Noam',
        lastName: 'Danon',
        emails: ['ndanon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHu80lZBNmrMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517659587647?e=2147483647&v=beta&t=XJ_4Xag92hQZIQwFPJULEBt-9Xj9BtGWPl9kKextWAs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Steve',
        lastName: 'Reed',
        emails: ['sreed@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Guy',
        lastName: 'Cohen',
        emails: ['gcohen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHtq-AWj_2oPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548921385617?e=2147483647&v=beta&t=NDmagrrpIGwM-r4befdT5xkYUUEGSv7e6Lw187FSQbk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vedasoftinc: {
    name: 'Vedasoft Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rakesh',
        lastName: 'Sharma',
        emails: ['rsharma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Deepak',
        lastName: 'Singh',
        emails: ['dsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQExHHrcGaAW5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659547860858?e=2147483647&v=beta&t=vFuyB0W094GnIMsQs1F8akjA5_lYH8Ol89yfd69k3pM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rubina',
        lastName: 'Ebenezer',
        emails: ['rebenezer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFtDVKwvpL46w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631173938063?e=2147483647&v=beta&t=o5WCqCC6SQN7NlLbzZhJ_CogRxHnhsdfACdAH0m_Mf4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'SUNITHA',
        lastName: 'KRISHNAMURTHY',
        emails: ['skrishnamurthy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHQ00wRUnKkaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661336281944?e=2147483647&v=beta&t=UroSOGCYrKxEI1RDzBkoQaU359U7j0WpMYXx9bEsoMo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vaishnavi',
        lastName: 'Pant',
        emails: ['vpant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEYR0Gjgw3p5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707812788477?e=2147483647&v=beta&t=LtHBC9SQUdveCj29HXthLjzIjOr1ZZ28vk7eGDrByHY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  smithers: {
    name: 'JETCOOL Technologies Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lily',
        lastName: 'Nolan',
        emails: ['lnolan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEPJwzgamEaOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617228467335?e=2147483647&v=beta&t=WLXHP8ZI--3h4zlNKJfhZj6YiQSlLL8fmwzlyD75c4s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Samantha',
        lastName: 'Tufts',
        emails: ['stufts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEIjUdNjxZayw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571875403328?e=2147483647&v=beta&t=6CwMErIS0clxOgGSkDnUrYoEk4Wg1BnXzJjqdIpYwRo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paul',
        lastName: 'Sullivan',
        emails: ['psullivan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGETeq4sdJzQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676405835523?e=2147483647&v=beta&t=jyMveymm2Yjg4va6uDpamTqMVe8rPXAm1lOh_ePgS5o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kayla M.',
        lastName: 'Cabral',
        emails: ['kcabral@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHfsVfZkCOLOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681432331980?e=2147483647&v=beta&t=2saI7VmLs1qkaYJj55bVLHvvDa_idw6mOPmYtDkvnkA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ryan',
        lastName: 'Higgins',
        emails: ['rhiggins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGF8gmUBjdv3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723481797710?e=2147483647&v=beta&t=dwvLZyKawvF0MWIxHSSM24j6Sme1SQqOaMvQCsxeIo8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jupiterone: {
    name: 'JupiterOne',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Erkang',
        lastName: 'Zheng',
        emails: ['ezheng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFQIZ_jNT2deA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613525293574?e=2147483647&v=beta&t=Tq6kZ5Z0YVWa1gwDqPw6LZvTh43TtnR-O1kjGsaGgYo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paul',
        lastName: 'Forte',
        emails: ['pforte@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHnWXrrct8Hdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564410616846?e=2147483647&v=beta&t=fo-PUrCmlkaX93zsgEkqrwtRk828vDaUahdpjdD0uH4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Valerie',
        lastName: 'Zargarpur',
        emails: ['vzargarpur@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jackson',
        lastName: 'Evans',
        emails: ['jevans@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFFoIBpdyCrow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610733553157?e=2147483647&v=beta&t=3607oDB_FTw6Hmqg7r2CGWfkXNb2IpeQsRAFdlGE8Yc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amy',
        lastName: 'Devers',
        emails: ['adevers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGtD0UUjZ-JDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634920856400?e=2147483647&v=beta&t=SIkpcDYvhOK7crkb7jl6WeY2YcMjHN1pVs3PH5T4iEQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tccsolutions: {
    name: 'TCC Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dr. Audra',
        lastName: 'Barrett',
        emails: ['dbarrett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEkY2pBmPiP1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614279519128?e=2147483647&v=beta&t=5K0V1FGeGrft1QWSJI2c2DmYoZiZbeP11b5Y-uj2QdQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Molly',
        lastName: 'Fields',
        emails: ['mfields@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFTdj87VMuMtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664628673965?e=2147483647&v=beta&t=NkW8XMC0pyCnTuQnsXECUAGGzuiEuNfTRdfpxxwBiH4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marysol Fernandez',
        lastName: 'Harvey',
        emails: ['mharvey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGfdxe9NtjYlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622648897940?e=2147483647&v=beta&t=7mN4CZKUbQtbaTR-45YPrjlEXKXlCXct3eRwHcKGIUM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nina',
        lastName: 'Gollapudy',
        emails: ['ngollapudy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEi-RrFv6aq2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695936559075?e=2147483647&v=beta&t=nRUCJhxT_8Vqpw9ga_wmjFkhsGnSIU9_NMwEe8DlGZQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ryan',
        lastName: 'Jones',
        emails: ['rjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGihfL1VwZcPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712760735025?e=2147483647&v=beta&t=0cP9F-LA7Wr5fmBv8UzJ_-ekDJR2mJSlgrC60SFEtWs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jahnelgroup: {
    name: 'Jahnel Group, an Inc. 5000 company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Darrin',
        lastName: 'Jahnel',
        emails: ['djahnel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emily',
        lastName: 'Smith',
        emails: ['esmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEynx24og4-cQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704395282458?e=2147483647&v=beta&t=la9Z122hhgWrIB5IhGcefH7WThd-qzL7IGqknM8_bEw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jethro',
        lastName: 'Breuer',
        emails: ['jbreuer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFpVrcv6EdI-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692815094594?e=2147483647&v=beta&t=-gwYgPTVkIVtjTJTaWqvuVxo5My23LGOSuY5AJQMUdY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jon',
        lastName: 'Keller',
        emails: ['jkeller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE0LpR-Li5vow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712360176013?e=2147483647&v=beta&t=DNDwzNO8XLTJmF6UqNfEPlpQ1XygfBM4uz6tkoQeOJ4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jason',
        lastName: 'Jahnel',
        emails: ['jjahnel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH5YMEspWk5kQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1534849703827?e=2147483647&v=beta&t=QmLC8LGQ2008eQhcUEMNna7JHu2Z6AgLp0Hu1ugBvpM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trijit: {
    name: 'TRIJIT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Randhir',
        lastName: 'Prakash',
        emails: ['rprakash@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cloud Sales',
        lastName: 'Associate',
        emails: ['cassociate@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFXnDRiuhf2Gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687002167862?e=2147483647&v=beta&t=yDW6C5mLKXQ6QoWWqUSETrC-F7YvaUT_UvdqqM-TDFY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kaivalya',
        lastName: 'ic',
        emails: ['kic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHMkPAgwaeqjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723087374660?e=2147483647&v=beta&t=pO2r5Rtoopo-3yEIG1YbBQxP6rO-Eh-qEinCXR9Wu_k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Trijit',
        lastName: 'Sales',
        emails: ['tsales@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEZGjJPkTXDGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686999349050?e=2147483647&v=beta&t=3tTkjV5TywIDDQk5V_YQcYHxYPFP9FOeI6tdO3wqXjs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Madhura K (Maria',
        lastName: 'Glory)',
        emails: ['mglory)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGQ45Be5YfeCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517816826225?e=2147483647&v=beta&t=7Zk1RASN95iG-aMQJ_vo5nZJo54pGe1okwHQABY4ngE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ergogroup: {
    name: 'Ergonomic Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sayle Weathersby',
        lastName: 'Roberts',
        emails: ['sroberts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGYj4onxRvb9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517683113813?e=2147483647&v=beta&t=xPz-Jazx5PkosYx3xi511dg0LQCcXV3bXnBmzRDHcuo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Frank',
        lastName: 'Scozzari',
        emails: ['fscozzari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQErwwGx0UW1bg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516241596633?e=2147483647&v=beta&t=lzlL2j8spIPMXUH7sjyTUxg0Yn9Em01bfcbjiTbP-HM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kim',
        lastName: 'Girards',
        emails: ['kgirards@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGOeh1QguN2mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516547568119?e=2147483647&v=beta&t=7tLdBFbIPh_0usaGoq-OnJrqX1n0ejSQdzJVovrQ-6M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gene',
        lastName: 'Gaffney',
        emails: ['ggaffney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gabrielle',
        lastName: 'Russell',
        emails: ['grussell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  matriximaging: {
    name: 'Matrix Imaging Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Hartwell',
        emails: ['jhartwell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG2-PA6EboC6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637161329813?e=2147483647&v=beta&t=HI_7ldFCk0yAMDF9bHf4mmbyjL_vRDNaP0mdoZ3v8y4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tom',
        lastName: 'Carroll',
        emails: ['tcarroll@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG8nCoHkI2iVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681758008541?e=2147483647&v=beta&t=LxuNYV2s2cgLt_v6VyeuoRXbq1bxZhajOOKWdRjA1zY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jim',
        lastName: 'Howard',
        emails: ['jhoward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEV9dYLXeAbjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516613798367?e=2147483647&v=beta&t=yXIupwBn4FBHLWVFEy1RiVAtB7auO0nu-UQojQWn3I8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jamie',
        lastName: 'Tremper',
        emails: ['jtremper@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Edward',
        lastName: 'Lott',
        emails: ['elott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFp76w7o0ocRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694472880577?e=2147483647&v=beta&t=nfQoTeCn3g6LQpVq7MeMvS1-YK5r7toX-dWQImGvWdU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  guideit: {
    name: 'GuideIT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bob',
        lastName: 'Kelly',
        emails: ['bkelly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFH8Yw2e5sviw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1576847562873?e=2147483647&v=beta&t=0ugtpjj9XjosuhdU2rlmzQYs8ryyoxwEGvD1wEQ-dZA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeff',
        lastName: 'Smith',
        emails: ['jsmith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Stephenson',
        emails: ['mstephenson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rex',
        lastName: 'Mills',
        emails: ['rmills@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Addison',
        lastName: 'Day',
        emails: ['aday@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  agoric: {
    name: 'Agoric',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'E. Dean',
        lastName: 'Tribble',
        emails: ['etribble@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHArLgMOfddxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698613367451?e=2147483647&v=beta&t=urkERh-DhRXGXFXXL8SfB4Vopk-jVMxhgpPbU_A_RlU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kris',
        lastName: 'B.',
        emails: ['kb.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFMNB3oA_nRyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683226617857?e=2147483647&v=beta&t=Fhw5c0ViC3_yGTtM96qO94zElgV-6_H0vSXiyd-Dib8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dan',
        lastName: 'Connolly',
        emails: ['dconnolly@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeet',
        lastName: 'Raut',
        emails: ['jraut@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHgxThn6Lv_QA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516904305259?e=2147483647&v=beta&t=xqcx2_ShmYVdoIAoZMIj6liw_A0mJKT93dDcLRJOD5M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Touseef',
        lastName: 'Liaqat',
        emails: ['tliaqat@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  o3: {
    name: 'O3 Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeffrey',
        lastName: 'Lippiatt',
        emails: ['jlippiatt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH7Tf_yGnRuiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665503266369?e=2147483647&v=beta&t=zv6UlL_HWHEmVa2-pQr2sqrjbvSnYL5le5jpD5P_Czk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrew',
        lastName: 'Foy',
        emails: ['afoy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGvpmxQM57-WQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620061160992?e=2147483647&v=beta&t=fjgMiHluYgi9GGKI2kAAyWuBEX8VN2iZOcdyxllwDKM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tom',
        lastName: 'Kirk',
        emails: ['tkirk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGvSDx-I9x9cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712067871385?e=2147483647&v=beta&t=3RQeN-WLXxN4l0ujN6IaIvkPoje32EOo3B89ibaB5Uk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ryan',
        lastName: 'Kubacki',
        emails: ['rkubacki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFcMwlUVakejw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690030875440?e=2147483647&v=beta&t=WOyigniE7WF6-5CbazkJZlEBF-DOK5jsBw1f6swrPd4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Hawthorne',
        emails: ['dhawthorne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG8hDj1flpqaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729645717770?e=2147483647&v=beta&t=cAv3nWdZaygd1iSwqTUAPjP8UkW1mRV6gfsW4ShtNo0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  icgrouplp: {
    name: 'IC Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jill Goldworn',
        lastName: '‍',
        emails: ['j‍@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQElbHySFnPSPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721320145316?e=2147483647&v=beta&t=87xww0qM9YTUmKr33R1Nqhl6t8IJ3aq3pY8r0RPpiGg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Macfarlane',
        emails: ['dmacfarlane@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGPn_fphS_2Tg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516978750576?e=2147483647&v=beta&t=bBGd8w4vmxVf0ZKKbYp-IsC0KPSnX-5fLEuGWCya1c4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dave',
        lastName: 'Loach',
        emails: ['dloach@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ashley',
        lastName: 'Derrow',
        emails: ['aderrow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFRCNXv4R8o9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687895565269?e=2147483647&v=beta&t=oLvjS7laiuXaH5WcaVzdnMKLqfBLy_j59pBWeBGb9fs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matt',
        lastName: 'Boettger',
        emails: ['mboettger@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  imon: {
    name: 'ImOn Communications',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Seth',
        lastName: 'Barribeau',
        emails: ['sbarribeau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH9i5rLT3F-nQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621837810337?e=2147483647&v=beta&t=aFbYqIACM7AzBGQN4OPUdolGV01eo-HguGgdTm1D0LM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bernard',
        lastName: 'Dutchik',
        emails: ['bdutchik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEEvrZvvNQPaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516243229774?e=2147483647&v=beta&t=-sW2BmKjW9Pd3i1bpFXd91fCl8De7VbCs27STJiVTLA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jenny',
        lastName: 'Conkel',
        emails: ['jconkel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGguaQKT7XMug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1535558800831?e=2147483647&v=beta&t=yky-BboDBRRCMhDifY0niqtrnwDENCgrt4KHRnnNRU0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jake',
        lastName: 'Ryan',
        emails: ['jryan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF1VA9G18Vlcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586282346130?e=2147483647&v=beta&t=hyxFUsZ6q7fJxiodK2eLgJ-HFXVfZjIJmKBtDbtT5AA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patricia (Patty)',
        lastName: 'Lynott',
        emails: ['plynott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEjIgMqArSIFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595620153355?e=2147483647&v=beta&t=OB5cZKjQ6gC--aEkYy9L8lwBgBscyYXpJm_gtQlwueI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kela: {
    name: 'KELA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kela',
        lastName: 'Ivonye',
        emails: ['kivonye@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH4Qr3wL76pQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626824477330?e=2147483647&v=beta&t=9U27RvILI6Hoz9wu2UxFYA1YqrA-IzpLxvbdb2LDOrI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ajay',
        lastName: 'Kela',
        emails: ['akela@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHEGY7E7nTdCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718294986477?e=2147483647&v=beta&t=3ELjWF1pVm1KW8wVNFYHyU6hiTQk7g09kiX4hX9CISo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kela',
        lastName: 'Powers',
        emails: ['kpowers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEQNPvaps0UAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564200786629?e=2147483647&v=beta&t=C-qf5vnQZxinbVI1b9W0elutbgBw_qtoyBewWI06prI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kela',
        lastName: 'Kealakai',
        emails: ['kkealakai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG7KQI0-GVyxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605057377446?e=2147483647&v=beta&t=XTGFcNjTEMA8KKztWhrYBj19deXuYHk3joqSOYa2BxU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Genevieve Kela,',
        lastName: 'MD',
        emails: ['gmd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF8L3wZo_X8aQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635690662157?e=2147483647&v=beta&t=iRG7afF2NuzdW8-xepq963Dlj0cuvIign0yF2etdHrM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mvpone: {
    name: 'MVP One',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ruth Hughes,',
        lastName: 'CMRP',
        emails: ['rcmrp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFVTgceL3sLBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582851432494?e=2147483647&v=beta&t=vn2Aznm_XYblcrFjHxlhRyc4-LUAOAnRalJaktt09ZU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Carlos',
        lastName: 'Degollado',
        emails: ['cdegollado@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEkQEjl-dQQ7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610665744991?e=2147483647&v=beta&t=vH2L-AIux9XmtE36ZX8wdThwV2nY4vVvgA9n8uFZrA8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jonathan',
        lastName: 'Clark',
        emails: ['jclark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGfRm_JgF2-aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548339440943?e=2147483647&v=beta&t=iDvv6ROAI2TFBRqhhckuVL3XoA74T6TXBoE4o9s4rkU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nicholas',
        lastName: 'Kissel',
        emails: ['nkissel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mitchell',
        lastName: 'Newsome',
        emails: ['mnewsome@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHGjzMftlJP1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585423643826?e=2147483647&v=beta&t=jZkSko7te-WbWTwdpmFGTmysoY57DxdlTtCwbalc878',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  flow3d: {
    name: 'FLOW-3D',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Allyce',
        lastName: 'Jackman',
        emails: ['ajackman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE8-oPyTPpiLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727978933217?e=2147483647&v=beta&t=eSlXYX-fJLcoXtTbo73sytAY-l9vIwovLT-aWyT4mnU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jonathan',
        lastName: 'Bouchard',
        emails: ['jbouchard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH3isBimZyzjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727214863425?e=2147483647&v=beta&t=l2R9-QH9cBo8LkChRvBUEGyXWBwy_BUPMq3ZTVbYCMY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aimee',
        lastName: 'Abby',
        emails: ['aabby@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amanda',
        lastName: 'Ruggles',
        emails: ['aruggles@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Souders',
        emails: ['dsouders@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF5gluNSrXFrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516346985574?e=2147483647&v=beta&t=CBV84fJlylJ-snljB9FwvxRYLAoue0zxQb-RIj8Zx0s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dynamiciservices: {
    name: 'Dynamic Integrated Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Lyons',
        emails: ['clyons@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'JD',
        lastName: 'Sullivan',
        emails: ['jsullivan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nicole',
        lastName: 'Carey',
        emails: ['ncarey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brandon',
        lastName: 'Carr',
        emails: ['bcarr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGIASrmoG79SQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517661980689?e=2147483647&v=beta&t=Yg4iVF8iNocRZymuA1w_eHJGnMrP7JJM2CmwyEPX5RU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stacy',
        lastName: 'Riggs',
        emails: ['sriggs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE9vy4eeFNDRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516808142229?e=2147483647&v=beta&t=mQyS8IRpEavejx4F2gLcKRin7ViI17kv8BVEP4-Y_Bw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  shiftboard: {
    name: 'Shiftboard',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sterling',
        lastName: 'Wilson',
        emails: ['swilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFDBiPJ75UTfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517749215871?e=2147483647&v=beta&t=FdBz-TYtaSrWdXYEOfqi5Mdnuun_jHwfmhjpBOZo86c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Travis',
        lastName: 'Shipley',
        emails: ['tshipley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH_sSLCjcg9nA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621828691385?e=2147483647&v=beta&t=lZ83a7UqvKqpnFjNuKiv7Tu1n14VRMzhecdh5g4otIU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Peter',
        lastName: 'Draper',
        emails: ['pdraper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQErvpKhklASow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517759911986?e=2147483647&v=beta&t=oxkA0MCjElOTjHXwdXTZuz3Gy6Iz9WeEKUAjqDExoN8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Douglas',
        lastName: 'Board',
        emails: ['dboard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG1t9oVxluZRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610991854459?e=2147483647&v=beta&t=PM7sFyM_8MTbIfpIZp8iskdF1UOmM-ZVGp-I57gBgw8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ben',
        lastName: 'Board',
        emails: ['bboard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEFQ--jQSyZDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698084182648?e=2147483647&v=beta&t=B_nkJq8-n8sKIZBa3pCysDmQW8w35M-EJgiJhU3sYX0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  greensea: {
    name: 'Greensea IQ',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ben',
        lastName: 'Kinnaman',
        emails: ['bkinnaman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG1DOJTaswXBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601383172570?e=2147483647&v=beta&t=xnCBXJBrIAVMJDpQY5iUlu5tDxriCt8Q_LdRlU54mgM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeff',
        lastName: 'Guyette',
        emails: ['jguyette@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFkPB9rpn7DNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647892273313?e=2147483647&v=beta&t=HviS1U2VYPVvXK3OvHxt69q9CPSqU4JeWhpzkeSpVek',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John E Dunn',
        lastName: 'Jr',
        emails: ['jjr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Pearson',
        emails: ['dpearson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFrLsaewJZqCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703430510770?e=2147483647&v=beta&t=J_G_7t4cYhJbEBwtB4ETHgcs-luf8VOeUdoFRGzV6AQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rob',
        lastName: 'Howard',
        emails: ['rhoward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGItm47baGLjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692984613017?e=2147483647&v=beta&t=ZrQZM3Hsp3JX-jUkM_C4QgSdxKIfxGgucqGqTEoORRI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cohereTechnologies: {
    name: 'Cohere Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ram',
        lastName: 'Prasad',
        emails: ['rprasad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFFlLqQtJU5ew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718295038481?e=2147483647&v=beta&t=7npU98ygTyQW1NDqQJ-dCpYchsHr8sOpw7trAfkuHRI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Raymond',
        lastName: 'Dolan',
        emails: ['rdolan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHvylcQd2JIMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638939072894?e=2147483647&v=beta&t=Aihip8M_tKgUMY8Co42XpAw23rjdEUoLlUkNdIdQ16U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Natalie',
        lastName: 'W.',
        emails: ['nw.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ronny',
        lastName: 'Hadani',
        emails: ['rhadani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG5lOHhHW_tHQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516489279198?e=2147483647&v=beta&t=cppXTg5Swu1W_Dv7Ynnj63GlPEySOYCrbDimiJPLMWs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Smith',
        emails: ['csmith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  omgroupinc: {
    name: 'OM GROUP INC.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sowmya',
        lastName: 'Hariharan',
        emails: ['shariharan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG6Zo4Cchis9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670920184100?e=2147483647&v=beta&t=5a9CraGPDF5M6v66yj3HoJvjmNdUYKqJtAwJ1WvKGLM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Skyler J.',
        lastName: 'Munk',
        emails: ['smunk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH6RXybrYLvzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693878317966?e=2147483647&v=beta&t=YpL6O84LLzW_-pR-VN7lr3_1JX3y6rvsuJBJDGJz30I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Irven',
        lastName: 'Ingram',
        emails: ['iingram@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ted',
        lastName: 'Gibson',
        emails: ['tgibson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHv0pZZKUcXqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674153906219?e=2147483647&v=beta&t=Qu4DitGsm-OFB5KXQqCHWU3WS1JOAab_M-bVi8fEAlk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jim Moullet,',
        lastName: 'PMP',
        emails: ['jpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH0DeE80Xk6Gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705516498137?e=2147483647&v=beta&t=dbFyDWuy-bK-0mj4Nk5wLYvoD8wbUzaUuoDW7QjUq_Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  novaders: {
    name: 'Novaders LLP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pradeep Bangalore',
        lastName: 'Thyagaraj',
        emails: ['pthyagaraj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHtPH1CqZQjJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516609492014?e=2147483647&v=beta&t=XbsnT1qZEdd1i9F0M1D8unQP07hZdF8GtF5zjFBHWG0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abhay',
        lastName: 'Naveen',
        emails: ['anaveen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGyetnZNwe5hg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516655412072?e=2147483647&v=beta&t=cx--GpQGRBRA8AYq_kiDr4ky4-MFzUxNUB-88dlWUro',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shwetha',
        lastName: 'Karanth',
        emails: ['skaranth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHUb1Ueqq_g9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675405785351?e=2147483647&v=beta&t=n_2ekGmjbJMf-y5IVbt5Ra0bE-VhFDJmnkOjHR79jdE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sunil',
        lastName: 'Pai',
        emails: ['spai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHfsMblhaHs1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517636838499?e=2147483647&v=beta&t=hK-THZXbK6pIj8XDbHmoTFYC9Cr5KC5_GdicnBQ0ErE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'MANOJ M',
        lastName: 'DESAI',
        emails: ['mdesai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGZ7abTi2ORMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542714755759?e=2147483647&v=beta&t=FJkQyBCWXyTdZiGeKFqVLYCA1-ZKUYrPz84UYIwvymk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  calloneonline: {
    name: 'Call One, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Danny',
        lastName: 'Hayasaka',
        emails: ['dhayasaka@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHkXIAgFe5uyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701439859115?e=2147483647&v=beta&t=mQMIdTTZWN6RDi9vgEYS7eq4YG-GUFLjpG0P2SjWuj0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dawn',
        lastName: 'Mays',
        emails: ['dmays@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Doug',
        lastName: 'Deker',
        emails: ['ddeker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHc-plJL2qOKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516304537416?e=2147483647&v=beta&t=yMEj-_x4Gbyrf5skzQNWY_2XiAY8WnfwQZVLjLpJL_U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephen',
        lastName: 'Mays',
        emails: ['smays@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGoR4OxTZfGqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517535944556?e=2147483647&v=beta&t=-c9LXhymNcV8A2kN2mN30-cDTzRZbHHOQ5-QBESr_Po',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kris',
        lastName: 'Torres',
        emails: ['ktorres@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pedigreetechnologies: {
    name: 'Pedigree Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John Elsner, CEO of Pedigree',
        lastName: 'Technologies',
        emails: ['jtechnologies@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGz9xcjXq1qiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669494797347?e=2147483647&v=beta&t=YAIlxrHEuveN32ZzLmz9PkJn7gr2HElctbbWSttMbzw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jessica Slyter,',
        lastName: 'MBA',
        emails: ['jmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jason',
        lastName: 'Schufletowski',
        emails: ['jschufletowski@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alex',
        lastName: 'Warner',
        emails: ['awarner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joshua',
        lastName: 'DeCock',
        emails: ['jdecock@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  qde: {
    name: 'Quantum Data Engines',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vivek',
        lastName: 'Raj',
        emails: ['vraj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEDlVRpoZXeuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690730214981?e=2147483647&v=beta&t=0sr5dQvgS-5pKsWPL90X0z3wqlrTjWlWlWuJ3V9QId0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sarosh',
        lastName: 'Nicholas',
        emails: ['snicholas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHj_0c1FeDNCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516828327240?e=2147483647&v=beta&t=UjzLWyHR1hgzMNSbq47DeLo7JDc8HikhR1EMtPKsrPA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Esha',
        lastName: 'Malhotra',
        emails: ['emalhotra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEwB3iiDpLywQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710834912886?e=2147483647&v=beta&t=5CvSpdAIiMMlOSwInwtXxVdIubx_laJn7iiQ-XAmNMQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sujay',
        lastName: 'Shetty',
        emails: ['sshetty@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Prerna',
        lastName: 'Shinde',
        emails: ['pshinde@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHQF7OVKSLHPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727375116538?e=2147483647&v=beta&t=ynYP4DnXXZu94K8ZLYatfgm-j9EQziEuEwXxRbQmdVo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  guaranteedremovals: {
    name: 'Guaranteed Removals',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alyssa',
        lastName: '',
        emails: ['a@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexis',
        lastName: 'W.',
        emails: ['aw.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Victoria',
        lastName: 'Marshall',
        emails: ['vmarshall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGCt2yuZVesug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678203608988?e=2147483647&v=beta&t=siTAj3kC0bWLmDfN24BHBlyoQn8BgI3awaIRczaH240',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Travis',
        lastName: 'Schreiber',
        emails: ['tschreiber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE-74zDyuEG7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686873773535?e=2147483647&v=beta&t=YdLerhyl_u_SmEFCyqv04VshJP-o7XIm6bmSMvHIXTw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rachel',
        lastName: 'Fairholm',
        emails: ['rfairholm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF6vcdW1UwlVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516869523462?e=2147483647&v=beta&t=KBcLCYyX7iXkaxu-1hw3KUogu9zR-xpngtu4vYVePhs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  drivesavers: {
    name: 'DriveSavers Data Recovery',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michelle',
        lastName: 'West',
        emails: ['mwest@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHE2Fb-ySHQFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516775449925?e=2147483647&v=beta&t=J6uCsfpqkzfLsaQE0K2GhaMHV4q71q16plbAuYgTfk8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mike',
        lastName: 'Cobb',
        emails: ['mcobb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFqGJluUU4wRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654353151199?e=2147483647&v=beta&t=VHsEqY6e6Rbu8Xc-v-4YSwjegwv2jJLwC1r9btwC1mY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Weston',
        lastName: 'Moyer',
        emails: ['wmoyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGj93PkE2E0lA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517617769293?e=2147483647&v=beta&t=31VfH5h66B2RuqC_vYzUI01btVVB6CjrGdjoqvfW8mY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alex',
        lastName: 'Hagan',
        emails: ['ahagan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFGiunOaKUuAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728778412228?e=2147483647&v=beta&t=M8s6R6HCQa-zS2ecrrlnHNpiVx7vTOwWASIDxnLfdgM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andy',
        lastName: 'Maus',
        emails: ['amaus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEsPnD5jx8o7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516285247693?e=2147483647&v=beta&t=CwNI0keTDj1a0F5K2PbEiNmoTBGSSBEk_tyIH-A2-bs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  upadpro: {
    name: 'Upadpro Software & Services Pvt. Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ritesh',
        lastName: 'Pandey',
        emails: ['rpandey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFfBJm6zYzZFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549177888334?e=2147483647&v=beta&t=4Q1YdaIAhHQ0zcAsLXdgU3ZEcf20EBC7kEt1-Fzs-Ew',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anuradha',
        lastName: 'Upadhyay',
        emails: ['aupadhyay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEKJ8hmvcrb5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517225375211?e=2147483647&v=beta&t=haCE_3nCSoG7m_4TIKpK8cP5orUdUpjWWmJCj73is1g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Faizan',
        lastName: 'Navasherwala',
        emails: ['fnavasherwala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHSm4mVS5Wweg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700138939082?e=2147483647&v=beta&t=lG4ODKuyxE5plbC3q2TYiK_pKupxaf0EjVZx0r77_Vs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nisha',
        lastName: 'Pathak',
        emails: ['npathak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHNG6mg6hnsIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516956119687?e=2147483647&v=beta&t=zvScOdbQTsDru_V7PCBJJp_CviErcOzV93ssxPkgyF8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'tkunal.',
        lastName: 'upadpro',
        emails: ['tupadpro@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  originutility: {
    name: 'Origin',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Frank',
        lastName: 'Regueyra',
        emails: ['fregueyra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGxMGb8m65K-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516301675176?e=2147483647&v=beta&t=JKOC3gO8JRqXHWoNffr6Du0erBhQbUTR1EWSSfCN8cg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Arlin',
        lastName: 'Nelson',
        emails: ['anelson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEkXsMgNc-WNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605991325988?e=2147483647&v=beta&t=iyB9dUReokCrfi0IpvkvlOMoJHpCgs7oqTrhSQ-Cbrs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Justine',
        lastName: 'Roxas',
        emails: ['jroxas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEvatwf1Cf7_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721930391437?e=2147483647&v=beta&t=ghgW0yVaXbXWtNI23p6EnKk0LLD59UZap_s-zKxypzI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Barbara',
        lastName: 'Shaddix',
        emails: ['bshaddix@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGk0XdR13Jh4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715635526675?e=2147483647&v=beta&t=lOvCrtEcxTQDy3CyW275RwxlYjvwUs-PAZuFpcc7HtM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kenneth',
        lastName: 'Sandlin',
        emails: ['ksandlin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE1a2Ly3XYaxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632600749752?e=2147483647&v=beta&t=nZrOesGrWoRIL_3MD221fSC5Tga3_w7mfLd3Cyskmlc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mysolutionsteam: {
    name: 'The Solutions Team, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Todd',
        lastName: 'Gooden',
        emails: ['tgooden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEetP0qKX2Ycw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647895860519?e=2147483647&v=beta&t=3-c4yMtbN9tUILwanBxj0TXQTVVuGkskr-ZudaqddXc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shane',
        lastName: 'Fowler',
        emails: ['sfowler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHMXBIoC2Zo2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516806196508?e=2147483647&v=beta&t=EsCdYd9F33OrxvZIJu9BIYnONi5syVRPP-3n8DQubd0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Abby',
        lastName: 'Barnett',
        emails: ['abarnett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHNHBo3tyM7SQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695567887211?e=2147483647&v=beta&t=T2iEfjwvCljJTx5kn7JZxrb_-llqyCmwBLCqxr493G0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kaelon',
        lastName: 'Harris',
        emails: ['kharris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFhGnwGx_5vQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518106541911?e=2147483647&v=beta&t=sIZQ-EKaNg7asf_L6GwuyQzlUud7-OP0wFZ8Z-xsnbY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eli',
        lastName: 'Gooden',
        emails: ['egooden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGWuSOHjPYa5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714088971456?e=2147483647&v=beta&t=7w3J6GCwYzCw1-esLJ9x9FOoXMBeKZUb9M_5Rp1R67w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  synersystech: {
    name: 'Synersys Technologies inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gova',
        lastName: 'Reddy',
        emails: ['greddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFoPOgGu6zr0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625592636119?e=2147483647&v=beta&t=yd9gSWx2KgyYQvAbGO4R0XeEOaev9mglWSoDJzyLbzg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nath',
        lastName: 'Pendyala',
        emails: ['npendyala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGiMs6CjemY1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516849113763?e=2147483647&v=beta&t=Ydmenep7ROD1b2g9bnelCEy_RQDjAeLHcvkO3wPICRw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Spandana',
        lastName: 'Pusuluri',
        emails: ['spusuluri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHlKYFUG5ValQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655225668377?e=2147483647&v=beta&t=wpP317NG-a3iTmUDqElzOqy9LQU9oZ_WpFYFeFD922M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Navya',
        lastName: 'Rajput',
        emails: ['nrajput@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHlOgL1zBgFdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701674088740?e=2147483647&v=beta&t=hGgt9uzA065MwJqHuqGpkgVmv3di6f6d_-fCln7ikkg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shaik Ajas',
        lastName: 'Ahmed',
        emails: ['sahmed@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jmares: {
    name: 'JMA Resources, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nancy',
        lastName: 'Martindell',
        emails: ['nmartindell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQExjeJFbvS0Pg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720018533895?e=2147483647&v=beta&t=cNdeoJ_7g_yrdiABMn0Ulhd4_YnmAxbX0DVlKRL0N7U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kristin Crager, CDR, CTR,',
        lastName: 'CMVR',
        emails: ['kcmvr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHDV92AgzACLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620075249792?e=2147483647&v=beta&t=HKeBo5domVkFZC-yfALKfaGenGAvNtKRv4S2lakHIy0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Todd',
        lastName: 'Yanik',
        emails: ['tyanik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF3jqikM__Uqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633122412102?e=2147483647&v=beta&t=DRRM7-k5n31Nm6MFT_XamvRqErwFKjgcujBj1ppLDUQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Asim A. – JMA Resources, Inc. |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHAM6wvwAXLWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696614936545?e=2147483647&v=beta&t=CCN__Dxu5uUbwhnp8IYkkJYlPB2QkN7hW5ly_4xpjws',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Robert',
        lastName: 'Duncan',
        emails: ['rduncan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQElIM38fiV8kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652127295543?e=2147483647&v=beta&t=NGI1IBnEoJR5iVMRytloiJmdlhON18uWLJfDbl-9asM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fivoshealth: {
    name: 'Fivos',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Erik',
        lastName: 'Linstad',
        emails: ['elinstad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGHfv2RI3N4Bw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727286911834?e=2147483647&v=beta&t=XgrBICd86yQVLZn56HnhI87HSL-CvYPYlYljzvSEJ_k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Allie',
        lastName: 'Fischer',
        emails: ['afischer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE9YgeEqUb_uQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517258140189?e=2147483647&v=beta&t=XDyO7Id9MmnqSjZyT_MgVHGMu2-yoshLgcH5FwNPy3g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erick',
        lastName: 'Lines',
        emails: ['elines@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFldt54ZSFqhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638833393477?e=2147483647&v=beta&t=nMBGMhdfhZtcb8U4GE_pzIRgJaXg3jcTNSe1LHUfuhU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jay',
        lastName: 'Colfer',
        emails: ['jcolfer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFy_1_l9_xdUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603996902227?e=2147483647&v=beta&t=HOicApTLk2oDpvoLbTMg12OdJk-DJJrM0QBSq9y5Sio',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rebecca',
        lastName: 'Torrey',
        emails: ['rtorrey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHfRN_c9iIGNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681987255128?e=2147483647&v=beta&t=9i5uxVCkRxiFlsor-QFJY6QxKMJYMEDkaRj2klPhyNU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  verticalscope: {
    name: 'VerticalScope Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jared',
        lastName: 'DiPane',
        emails: ['jdipane@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFqir56sRS7RA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672183988135?e=2147483647&v=beta&t=VNC8f7-yOkWnp24iKLkMWn0XNlB3sPlrTkj0eorE1vg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Justin',
        lastName: 'Nearing',
        emails: ['jnearing@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHD01YjC5aOOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719016652347?e=2147483647&v=beta&t=kJjut-17Eq93Fw9hcCwcD0Y9fsPBeDnOtHl5nyMR5BE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jason',
        lastName: 'Siu',
        emails: ['jsiu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGUnxzbrkieeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690135886280?e=2147483647&v=beta&t=vl6clJ2ft8Hg2DyPSnJ4z8uUjWAXzMP4zEL0SStCTlw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'I. Ariel',
        lastName: 'Pao',
        emails: ['ipao@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFbhKJ5I1gVjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719875664967?e=2147483647&v=beta&t=XNiNRXkB4MbQs1ZouPiKO-QB-EiKncpTUAkRLxnES_Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alex',
        lastName: 'Wilsey',
        emails: ['awilsey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGuNXbgYfB1yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603052515714?e=2147483647&v=beta&t=5mKz4eNYZAWHwbe_kazJuk5ccWpV5W3CQvL07HRpRsg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  onlc: {
    name: 'ONLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrew',
        lastName: 'Williamson',
        emails: ['awilliamson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEEJwVC4XFa-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682277596938?e=2147483647&v=beta&t=FkenDsRMHue7EMnmJNswXuFkuUG23V99ENKb4JBGLik',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Thomas',
        lastName: 'Brennan',
        emails: ['tbrennan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEg7gDKuFUL7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655687475144?e=2147483647&v=beta&t=itTpj4wnZwgWPf9DzXlnVEHCCgxsa7CpDaEMGEkBAno',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Glenn',
        lastName: 'Seibert',
        emails: ['gseibert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHcguEMPdaYaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683586960749?e=2147483647&v=beta&t=P5nFD3gGzqeZWwj9CBHH8XTq0zmdO6s5HH8Wq-5vRpo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brandey',
        lastName: 'Maness',
        emails: ['bmaness@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHtrz5bY617hQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693473039221?e=2147483647&v=beta&t=UtSDriowUVyvNuHcOGLdvW9l3GFDvS0e2vXea3os0-8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sean',
        lastName: 'McSorley',
        emails: ['smcsorley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHtPdfij8ka3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517683783117?e=2147483647&v=beta&t=o6hRBBuyPpUY9N_nayCAXkwEviOXeNINc0KTiUCoh5U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pmone: {
    name: 'pmOne Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Harald',
        lastName: 'Lakatha',
        emails: ['hlakatha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHiCuJZgdOS_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594743325894?e=2147483647&v=beta&t=1omHOVMbWzbEMEPbaLqC3BNk9x2-ThSduOH5IPMg_3Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dirk Zayko – pmOne Group |',
        lastName: 'LinkedIn',
        emails: ['dlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHnMiLBFWLRAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705331306776?e=2147483647&v=beta&t=zPEz9aXYFgI3v_wgHDUuURpk7dXmH65jtHxb-hQTxeI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kateryna',
        lastName: 'Shevtsova',
        emails: ['kshevtsova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHSgpVxbMy-rQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1599819996074?e=2147483647&v=beta&t=inywevjHo7CCCzSTMH1tn18_M_4k6E1U5kbMtxFiwg8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Danny Nyanda – Information Technology Consultant – pmOne',
        lastName: 'Group',
        emails: ['dgroup@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEwuQ_F4KcP5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651473738530?e=2147483647&v=beta&t=vo8qRSyWUiqw8HebESVvY324zOnnFgEOIvmMuv6TrhQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Wolfgang Laurer – pmOne Group |',
        lastName: 'LinkedIn',
        emails: ['wlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHBH2bueLkqLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650872299430?e=2147483647&v=beta&t=WNTi8nVzV5MpdjtEvpOs9qfHXKkAU985WE_XG28O200',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  starmicronics: {
    name: 'Star Micronics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tim Guella,',
        lastName: 'MBA',
        emails: ['tmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEacXEmJzcf_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1537368242531?e=2147483647&v=beta&t=RJaAbgyhAhF_oyDP3Mcg03tGsih_yp0jTD8XhT2GX9w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Hanson',
        emails: ['mhanson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGuE9TH07r7wg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711113830287?e=2147483647&v=beta&t=m6pw3gK5k8LdYexpo8DnGkyb1nToX7h0oNQj1jCy9hM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jon',
        lastName: 'Levin',
        emails: ['jlevin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH6zaCP57Ie4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659376457466?e=2147483647&v=beta&t=Mk3FH9Ebt09EaBDTY-Hn48fOhw0qyOrK9rV-BZeRwPU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christin',
        lastName: 'Dorner',
        emails: ['cdorner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christophe',
        lastName: 'Naasz',
        emails: ['cnaasz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF7dU4oKp21og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730505059331?e=2147483647&v=beta&t=qal9P-F44bpeJO67vtAlQ2S0eoYZvdR2-LBY_Av5UkU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eve: {
    name: 'Escape Velocity Entertainment',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'A.',
        emails: ['ca.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Geoffrey',
        lastName: 'Sutyak',
        emails: ['gsutyak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFEaoafCSOP8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718743152726?e=2147483647&v=beta&t=vs89Vi-_ySXTKbXllmPUSP2eXfq17jjernxkaPPl3hY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Robert',
        lastName: 'Liden',
        emails: ['rliden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE62K2D69VPLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675283788505?e=2147483647&v=beta&t=rPj6oWmz8RvuC5kYjT983I55AY5-VYexxQ0zimTkd8M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Allison Avalon',
        lastName: 'Irons',
        emails: ['airons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFID5KaiZIdZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725430230498?e=2147483647&v=beta&t=Qic7l_852XSvBJ1ZW2I_eHlVM5AES_NCMMrGMwgYZeE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'An',
        lastName: '',
        emails: ['a@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hunatek: {
    name: 'HunaTek',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mike',
        lastName: 'Torres',
        emails: ['mtorres@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGChx7yZ-e7aQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710778154414?e=2147483647&v=beta&t=y98ghtu2SFCe64tmvYxCFNvfkQx17SIy6_9IJDcGf_M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ryan',
        lastName: 'McGinnis',
        emails: ['rmcginnis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGZtbDZGTN5CA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722874690008?e=2147483647&v=beta&t=A6r-6W4rPZuoBc6Odj_-zLe5cgGFLmz4EQWARKP4i9w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anna',
        lastName: 'Jullien',
        emails: ['ajullien@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tanya',
        lastName: 'Johnson',
        emails: ['tjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHz4QFa5IAehg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595337390409?e=2147483647&v=beta&t=-CkXt9k0gzFNLcDRAdNxCMW_MQJ0DZZpIOjgPZWDnpY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Virginia',
        lastName: 'Conner',
        emails: ['vconner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEZp62CYBvLdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608733000839?e=2147483647&v=beta&t=FdABqL4lff1doxg0J7Jw0zejbyOfVfpeO9wllgkNhao',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vicimus: {
    name: 'Vicimus',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'William (Billy)',
        lastName: 'Reynolds',
        emails: ['wreynolds@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGtUmziV4RjKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715777349285?e=2147483647&v=beta&t=TLgD9jZMF2yGfgoqDvfczGhfTIEUwTEyZv_NjqfALCU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Craig',
        lastName: 'Hooghiem',
        emails: ['chooghiem@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHnAhmJg79O9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650459946719?e=2147483647&v=beta&t=HV-c5OtxuOUociSmXMxL1eoQePLVFhWXe-LLiCRMfBU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Khalid',
        lastName: 'Kadrie',
        emails: ['kkadrie@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tanya',
        lastName: 'Gray',
        emails: ['tgray@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHW6sqzn53Zpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516533164507?e=2147483647&v=beta&t=JMfYwaUxPsB5p9SmQgGYU8fbuz0TtT0kPKPDXOJjuG0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Curtis',
        lastName: 'Gibson',
        emails: ['cgibson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEzD_f2xC0G9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610057265533?e=2147483647&v=beta&t=kbmk-ygdHIkVCk_xXp9J0w4OQL_uhWSkDt1IZikfCJw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mobilenetservices: {
    name: 'MobileNet Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Syed',
        lastName: 'Hasan',
        emails: ['shasan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHISe8-zVvBPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614454962638?e=2147483647&v=beta&t=gEZ5s52PTV8Exfln_RWxlExe_x1qnDN1vk81VZ4h2bA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eugene',
        lastName: 'Powell',
        emails: ['epowell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG5v08em6EZKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516243206707?e=2147483647&v=beta&t=a2AGXWC5k1VP8dUqCS4EiIFGTJm1Yn5T56tt_EXUZW0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Richard Grant,',
        lastName: 'President',
        emails: ['rpresident@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFZQ0pZZB5HNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546544730172?e=2147483647&v=beta&t=Rd_qfxGlmokVtPF3xEuxiI0ks2Au3qV9L7nOit6mRMU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lorenzo',
        lastName: 'Mills',
        emails: ['lmills@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE9jTa_-p2kvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698509067512?e=2147483647&v=beta&t=Lri0Xuw9hWXmk3BjSeCmxi0lSS1LltiPCbDfGulUd70',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Muazzam',
        lastName: 'Siddiqui',
        emails: ['msiddiqui@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHzVZwj-Rg0-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517658666237?e=2147483647&v=beta&t=F67tQlzYYvEo_keaGf9nMRiBOo3WQJVKF00IsXOCoqs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  crypticstudios: {
    name: 'Cryptic Studios',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Thomas',
        lastName: 'Marrone',
        emails: ['tmarrone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHJQr0GQwmXFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698286944097?e=2147483647&v=beta&t=GFe2FCyceprusKZEdaRumkH0Nyc2DptDWo6DLfIAYEs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vincent',
        lastName: 'Malley',
        emails: ['vmalley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHqWDRl6LC3Rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644400717334?e=2147483647&v=beta&t=pm7NjUmbuIk4gkMPsjxa0BppByTLhX2TRSZ5z3kCB88',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Hector',
        lastName: 'Ortiz',
        emails: ['hortiz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHmVdWsQqNJww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724707232995?e=2147483647&v=beta&t=uWPkwqE8F6CYViGBBfjfB0Z3eYuxqsPqzfN5kxRSyCM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ken',
        lastName: 'Morse',
        emails: ['kmorse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGX-i6F4_PWMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713305378372?e=2147483647&v=beta&t=MVork_rmXQhGRy_mKYgHr8bIvfEtX-vEj71S_Dx1CT4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ralph',
        lastName: 'Lacy',
        emails: ['rlacy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  autonodyne: {
    name: 'Autonodyne LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steve',
        lastName: 'Jacobson',
        emails: ['sjacobson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGPKifJ6poaXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516337469870?e=2147483647&v=beta&t=_TsiM5MLxGqq9Cn2xGYIH8hgnTca_1Sgc3HuzWXipfM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Spencer',
        lastName: 'Gordon',
        emails: ['sgordon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGmRHxj96UsXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674246201137?e=2147483647&v=beta&t=VnHGJDuUhq39t8Dv5FgO3m1l_m0WPPwzFq8Bl7nCqJ4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Delaney Chisholm, MBA,',
        lastName: 'SHRM',
        emails: ['dshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQES24FSPd27gg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689281963157?e=2147483647&v=beta&t=ePE5-Y3Kgbow5cEXKXBAXkm_z-nfrNTSJ1Voql_20_w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jared',
        lastName: 'Herron',
        emails: ['jherron@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFIkdLFggu1Qg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552053853713?e=2147483647&v=beta&t=whgQNy2RcY6rjmjicn-xVu4kZSTNYa_GXwekJz99me4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zachery',
        lastName: 'Kern',
        emails: ['zkern@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  docomoinnovations: {
    name: 'DOCOMO Innovations, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Ramirez',
        emails: ['dramirez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGlUfANJgJw6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517508803534?e=2147483647&v=beta&t=G9gw7hwjOVZDXcfARM6xXnscdranoh6_rsgbTXW_svk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sanae',
        lastName: 'Muranaka',
        emails: ['smuranaka@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'Mavromatis',
        emails: ['mmavromatis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFvua5H3yJdmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547432120820?e=2147483647&v=beta&t=PeQs7hN8v8jzI_63_dj_3wyG49JSw3-1nje8dMauZBk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shota',
        lastName: 'Shiino',
        emails: ['sshiino@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHMgG_nDPdB_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613703150728?e=2147483647&v=beta&t=Tx_73HxTDXoNJD8f4HtxXG0Dg_MavfASq_Tqg3t4uOM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daisuke',
        lastName: 'Nishikawa',
        emails: ['dnishikawa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE7xm-cnO1VAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652473962349?e=2147483647&v=beta&t=2bQK_MjHZYxwGgpeIVCAxHaOkJJQa3RnBPmKU1SvAg4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  techsoft3d: {
    name: 'Tech Soft 3D',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Twanya Hood Hill,',
        lastName: 'MBA',
        emails: ['tmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFs859AcbU-Ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697734239066?e=2147483647&v=beta&t=JEhCtCQCEH6wrvNBzR3OL5k8j3ODNhkyN8EdMoI5sF0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jonathan',
        lastName: 'Girroir',
        emails: ['jgirroir@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Megan',
        lastName: 'Grimm',
        emails: ['mgrimm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGrEg7uqyPLLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517936615562?e=2147483647&v=beta&t=4df6t3I_21WS0qF5yqvIn8hz4kgLVjNfJY3l8S8-PdU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gavin',
        lastName: 'Bridgeman',
        emails: ['gbridgeman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adam',
        lastName: 'Drake',
        emails: ['adrake@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGgIdTAJ9cvmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543947980278?e=2147483647&v=beta&t=slN9QhnNcvlsX6TFqsVOvhYs558QJ4ikLxL2qZPzEzQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  knightfederal: {
    name: 'Knight Federal Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Baeten',
        emails: ['cbaeten@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nigel',
        lastName: 'Allen',
        emails: ['nallen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ira',
        lastName: 'Knight',
        emails: ['iknight@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE0aOH-7l3qWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516322360023?e=2147483647&v=beta&t=JJoTAa_4YU8SN9N-ANO1PBWVpt8_-5f49NzlQ9nHimY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Murray',
        lastName: 'Hess',
        emails: ['mhess@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEkceJ42n4ZwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516859353967?e=2147483647&v=beta&t=svB2aG8VEt4itAjU48D61OWUK9kRym7Qq8Au4bIaTdw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rachel',
        lastName: 'Phelps',
        emails: ['rphelps@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGC0dBKN6tvCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669910982746?e=2147483647&v=beta&t=j-6YLXJ8aEa1Bo0nYaUcZGWI294rWBmuMw6_47pP1d0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  breachlock: {
    name: 'BreachLock Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Seemant',
        lastName: 'Sehgal',
        emails: ['ssehgal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Christopher Clark',
        lastName: 'Jr.',
        emails: ['cjr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHwHMdktt4xvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706128432918?e=2147483647&v=beta&t=99pyBjK5W6ZBBbGOn5f3INfgjtG42sJ7VOjDtOAqOTg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vishal',
        lastName: 'Verma',
        emails: ['vverma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFYgyOYQhRoFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719989248464?e=2147483647&v=beta&t=WnUROtxnwZcOL26GdzGQr7dOtyLnG0E3i1Sa7aD7AEU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Richard',
        lastName: 'Landers',
        emails: ['rlanders@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Himanshu',
        lastName: 'Nautiyal',
        emails: ['hnautiyal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEWCmkN-rn76w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663595987386?e=2147483647&v=beta&t=WHPIiui41UtalwbyNgYRDpwu4q-yR__QfNI1cWMl4bg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  methodicalgroup: {
    name: 'The Methodical Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Natalie',
        lastName: 'Cox',
        emails: ['ncox@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Selvaggio',
        emails: ['mselvaggio@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kevin',
        lastName: 'McCleave',
        emails: ['kmccleave@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGYGGULKxzDyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517718504628?e=2147483647&v=beta&t=sgHH0OM3Z8t0H0DHbFfdezmlnSBOFoE9om66vTnTUt8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joshua',
        lastName: 'Guzman',
        emails: ['jguzman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Riley',
        lastName: 'Resnick',
        emails: ['rresnick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEecdHVWp_6gQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516952167631?e=2147483647&v=beta&t=Iw3s5r6uPtkdLVfBbF0S-CBQU-873UIwipDPrF9VO9c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nevaris: {
    name: 'NEVARIS Bausoftware',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  retrofit: {
    name: 'RetroFit Technologies, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Schaad',
        emails: ['bschaad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGscbxnWu2Hkw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516306914642?e=2147483647&v=beta&t=QSva8G5_0hr7nsuqH16bpL22-EawKlvEf6dKF9MjfvY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jacqueline',
        lastName: 'Lawlor',
        emails: ['jlawlor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFzreJ3MDRJvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521686356091?e=2147483647&v=beta&t=nji_ty_z4j4v7pZusp890PWo2ty9y7zpurVxj7Zjpdk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Timothy',
        lastName: 'Lawlor',
        emails: ['tlawlor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHnF_3tD3MqYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516508607369?e=2147483647&v=beta&t=XsV2Xsi9IKmUw4IksOU3lubYQJOKkqiAn9wSYxbrrEI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joshua',
        lastName: 'Andrews',
        emails: ['jandrews@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Justin',
        lastName: 'Le',
        emails: ['jle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFhSS0ovdugyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710218298815?e=2147483647&v=beta&t=izPD3B1nSU7lPX7bnMUzREjK5-RmYGpFCwBU-BaZNz0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  teleco: {
    name: 'TELECO, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'William M (Bill)',
        lastName: 'Rogers',
        emails: ['wrogers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFlPloECrosyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517227170731?e=2147483647&v=beta&t=9Km_tIRHvAGZkkQ-rbby1dG5YzWGXTovKPAg9RIMHTI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Florencia',
        lastName: 'Hernandez',
        emails: ['fhernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE9VnZaDhsryg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596045635993?e=2147483647&v=beta&t=GmWTkMIS1V8zXxV_J4N5OVtf6ost0WyvXjclQe3Wwzs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Steve',
        lastName: 'W.',
        emails: ['sw.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEtl75YQOle0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1562705730450?e=2147483647&v=beta&t=XgAzs2F-62SoNnIyR2W4VrT-Yhl6ltzUKxNkHVpzCEw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joseph',
        lastName: 'Buckmaster',
        emails: ['jbuckmaster@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Janice',
        lastName: 'Hampton',
        emails: ['jhampton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGtQ7RZqt6uog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516873976588?e=2147483647&v=beta&t=tl0rrgKRKFJg-oCbmfM2CvJzEymgH9ZYJE01ofRjeuY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  p2sc: {
    name: 'Phase II',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ahmad',
        lastName: 'Karmand',
        emails: ['akarmand@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGSGT_VyDzTpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723841229156?e=2147483647&v=beta&t=PQpswmmRIQZIPKQuWDwA3HhV8UTMrGkugtRohgSG2hU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joe',
        lastName: 'Vano',
        emails: ['jvano@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF70wFgZOmvfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636116114275?e=2147483647&v=beta&t=QNv4uxl4MRd2BVug3O3eYvm1tQBp9-gSTjHHCUmVupo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lauren',
        lastName: 'Osborne',
        emails: ['losborne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHCgZDYWSOouw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556211951824?e=2147483647&v=beta&t=HN3fzBUYEyqL4LtTHnHj6Re3ycLH799BHTl18ZaHoy4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Craig Mount,',
        lastName: 'PMP',
        emails: ['cpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFRw_A9tu-A3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658444975304?e=2147483647&v=beta&t=PA-JvBAPAzbkVQ9ul2GW8fL9NUuNl5UOv_22Luib2G8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Harry Horning,',
        lastName: 'II',
        emails: ['hii@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEN3SV2DZciLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729528998930?e=2147483647&v=beta&t=DhdgDqu1vGiyiQOm-blJQB-XmdVibsJ_eZm3d42NiHc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  synsec: {
    name: 'Vali Cyber',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anthony',
        lastName: 'Gadient',
        emails: ['agadient@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Dean',
        emails: ['jdean@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'MG',
        lastName: 'Howard',
        emails: ['mhoward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGs_JhADbDLhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516835067572?e=2147483647&v=beta&t=erv2N8KFkfI2FB4095wwhtK6wr6lz-GtR7iFw_w8Mq0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jim',
        lastName: 'Raine',
        emails: ['jraine@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Danckert',
        emails: ['mdanckert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGS_K21BoxT0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675278601171?e=2147483647&v=beta&t=QtdCet4--dtW_FTseFFSSkU3zRxcif5FLVajviLdUMo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mtasolutions: {
    name: 'MTA Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jess Gilbert,',
        lastName: 'MBA',
        emails: ['jmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHsHmVA0EhVsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681531460695?e=2147483647&v=beta&t=f6dXuE2_nToajtAco_-pl6pH5bouj-UtYQ1RWOq8cdE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emma',
        lastName: 'Corby',
        emails: ['ecorby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH7AfcFo1eHsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699308341162?e=2147483647&v=beta&t=JKb4myKfb8oULyFjAZba4fn3BJ5Io61EHMTlJMkNb0c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Elias Rojas,',
        lastName: 'MBA',
        emails: ['emba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGJub2o4jPrUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517729416169?e=2147483647&v=beta&t=E8TLmgLpOUEbvlVJqcumqI9RzaTky0mMV_Uh_x2tjEs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Olivia',
        lastName: 'Matson',
        emails: ['omatson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGmzefPElHI4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689802409747?e=2147483647&v=beta&t=U_x70Y0zWBZO9bJF1NoNoJvSCJVNZnOOpw9qblVggWI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ashley',
        lastName: 'Jensen',
        emails: ['ajensen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHyKl_NaCiDaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698873326643?e=2147483647&v=beta&t=1OzXxRlGwbQyvnHjNzn8LjsVIkaNVABpNLyx_StZDo0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dynatronsoftware: {
    name: 'Dynatron Software, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Devin',
        lastName: 'Poehlman',
        emails: ['dpoehlman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEpWNRaFdiv2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601318919403?e=2147483647&v=beta&t=wl-hywM1iWZlw0LlrbISaBWjT4s98xJjLa6gmDRxeoU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brad',
        lastName: 'Paschal',
        emails: ['bpaschal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF6NZfXFKFVYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681497586349?e=2147483647&v=beta&t=O1P7yt6J3xkRYvmhIqeXpxbiAy0A2MTEYAIf0PHIhrw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bret',
        lastName: 'McDermitt',
        emails: ['bmcdermitt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gianna',
        lastName: 'Anderson',
        emails: ['ganderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGV2MzLLk5V7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726781097117?e=2147483647&v=beta&t=RG4_EqAdodTQMbZEWn70CP06wgIpRRyKO3M1Z5lPwJY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tom',
        lastName: 'Ottman',
        emails: ['tottman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  totemsolutions: {
    name: 'Totem',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Leonardo',
        lastName: 'Rivas',
        emails: ['lrivas@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Wendy',
        lastName: 'Hendrickson',
        emails: ['whendrickson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE6U3eOYtfx4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517731833254?e=2147483647&v=beta&t=VCkgIH5CRTTV0VRDmAHnD1w3kxj-27gNLZSU2WnGMcI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Glenda',
        lastName: 'Perry',
        emails: ['gperry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFYnxMjYnIbRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517534350265?e=2147483647&v=beta&t=TRlyGFjlZmT2dEKkVTM1XWSyqyVPPusfFIyHVX49vN0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ar. Ranjitha',
        lastName: 'Chitti',
        emails: ['achitti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFZbRWOEzmzjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652022865577?e=2147483647&v=beta&t=9wh88zj6a1O93RT2La-cyCU68MZzVUv5cgdioYgkfj0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rosa',
        lastName: 'Lopez',
        emails: ['rlopez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cillc: {
    name: 'Competitive Innovations, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Russ',
        lastName: 'Weaver',
        emails: ['rweaver@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Patrick',
        lastName: 'Fulghum',
        emails: ['pfulghum@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Camden (Cammi)',
        lastName: 'Markham',
        emails: ['cmarkham@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rick',
        lastName: 'Savage',
        emails: ['rsavage@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGOJIFgi-Pa-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517716921041?e=2147483647&v=beta&t=KOyVBI58o-5eI_xbOv0iUEOX6FVpg2hCiXR-QMtH9W0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mike',
        lastName: 'Kennedy',
        emails: ['mkennedy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vowhs: {
    name: 'Virtual OfficeWare Healthcare Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joe',
        lastName: 'Macies',
        emails: ['jmacies@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vicki',
        lastName: 'Cook',
        emails: ['vcook@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGvmDXR3etcUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711031462808?e=2147483647&v=beta&t=6ORN9XPpCwUGGJXWLRBNy_GyG3SrNFdkHDMFMfeB16Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erika',
        lastName: 'Leroch',
        emails: ['eleroch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFqx_u9dp4QKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516956238361?e=2147483647&v=beta&t=QaJRyr007xMouFXR41SoC4bLpJK3ShVI3syzbmD9jtA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Julie',
        lastName: 'Hamill',
        emails: ['jhamill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEef0zm3r_iwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645932375710?e=2147483647&v=beta&t=OHIs0oN_3O-mHG7Mgv_0oK8QY1e4NrZIKHWTx4M7rRo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kory Murdie, aPHR,',
        lastName: 'B.S.B.A.',
        emails: ['kb.s.b.a.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aitI: {
    name: 'Applied Integrated Technologies, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marcus Forbes,',
        lastName: 'CIR',
        emails: ['mcir@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Christopher',
        lastName: 'Ficke',
        emails: ['cficke@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Regulo Guzman',
        lastName: 'Jr.',
        emails: ['rjr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGv-kT7Toj4uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517683520736?e=2147483647&v=beta&t=juZ9IkVh9ikzu99BdYeK_x68x4U02Lcis5VR_9L7EGI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Larry',
        lastName: 'Jaffe',
        emails: ['ljaffe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFnjVqFW7tZlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622051695748?e=2147483647&v=beta&t=PPijCQ6ee9MfsPTI8ZqKKfpU7IUz0v2UIcAwxsw-DW4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sheri',
        lastName: 'Daum',
        emails: ['sdaum@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHXV7KgZH9YXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683563529218?e=2147483647&v=beta&t=qBSGHK9XZWqLVNBNhptLJND-xS9ISdrCwvMLm0Yw6IM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trueVision: {
    name: 'True Vision',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kashyap',
        lastName: 'Vyas',
        emails: ['kvyas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGmBMkbn064wg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516577589981?e=2147483647&v=beta&t=twL4-J9QfLD1xtPxnN-I4-tyHsWD1nK0n2_1j5beF7I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Richard',
        lastName: 'Carlisle',
        emails: ['rcarlisle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG0XJHPkYXhLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1540499229355?e=2147483647&v=beta&t=FFdzhE5x-zEihWiDErf-e1KBsS2xLgnJsJlvIdZcXHY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjana',
        lastName: 'Kumar',
        emails: ['skumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE8m4sqyUxUeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688676269358?e=2147483647&v=beta&t=o0rhXQLeWpZ34d8dzwYPqffRN2UrYQJnKU3jG2tZTCs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'niyati',
        lastName: 'shah',
        emails: ['nshah@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Soham',
        lastName: 'Patel',
        emails: ['spatel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH25NmdJO6DBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701803465826?e=2147483647&v=beta&t=3_7FBS8Qk8oPp40S5WgrQ-6lghhXOnoW18YzYzoYuS8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  talentreef: {
    name: 'Mitratech TalentReef',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Skyler',
        lastName: 'Meyer',
        emails: ['smeyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGQx7fk9YkIHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694616670507?e=2147483647&v=beta&t=LLxzH22VPnsBDU0opQb-7wPLdVGPQEWT1JIGJpjaHaE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Reef',
        lastName: 'Colman',
        emails: ['rcolman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHj6kDkWLDiEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517599036882?e=2147483647&v=beta&t=dClpFw7_jzDYc9qq92hBWE2-j9VQSWzLCGc42RwTJKI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Talent',
        lastName: 'Elias',
        emails: ['telias@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEJPSf91ge3kA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672347192068?e=2147483647&v=beta&t=AqQ25tHflHuGbgf4sqQ8ZNGs_gwenhLLAasbKtDXGSc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Reef',
        lastName: 'Loretto',
        emails: ['rloretto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG4zp2QVVmX4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693953159898?e=2147483647&v=beta&t=xLLeuZJKiLpW3lx9ju1LWbCfdb754xp2CKndvT0JZlI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emma',
        lastName: 'Woeckener',
        emails: ['ewoeckener@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQECDZRiRbkWag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549219016948?e=2147483647&v=beta&t=uKM5wD2p4BLoG_NtZqk60xg_UDAyTiVp2LVicVr5NuQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wanderly: {
    name: 'Wanderly',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Laura',
        lastName: 'Holmes',
        emails: ['lholmes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHh_wssftR3Lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708563711919?e=2147483647&v=beta&t=UdMq1YLts4UbGIBv2EHqedy-Mg_2i2TiY1x_5xFagVA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Zia',
        lastName: 'Rahman',
        emails: ['zrahman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH2kkWm4m8xbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558379129337?e=2147483647&v=beta&t=lfs5mJJph1ZogWBehCHaUU299RBwn9VknLyq-UPCiE4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ravin',
        lastName: 'Suri',
        emails: ['rsuri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGFs2xpIRg4TQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663705681491?e=2147483647&v=beta&t=DngY49GIiUEq1PLYCmyNxfllCgaqvKHxWq9kWbIDYUs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kamlyn',
        lastName: 'Spivey',
        emails: ['kspivey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kajal',
        lastName: 'Aneja',
        emails: ['kaneja@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFXiyuT-t7BHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517491111244?e=2147483647&v=beta&t=yxk7ztdFijzIvISToCtW8qf218h3kIKadZTsi7HqhdM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  optechus: {
    name: 'OpTech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kelly',
        lastName: 'Carroll',
        emails: ['kcarroll@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGCz0-70-qeTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635438758761?e=2147483647&v=beta&t=QbZmD_yvZWk5IIOyih2rK7MQnWKLFgSXPFOOLb7z0Ws',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jess',
        lastName: 'Leads',
        emails: ['jleads@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dawn',
        lastName: 'Kopf',
        emails: ['dkopf@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHN3-RFZxRnOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516834720863?e=2147483647&v=beta&t=uWY1enbkFb-gRFj8dWcRypXr2Gn7_Sg8OwIAu8EEWtM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jennifer',
        lastName: 'Miller',
        emails: ['jmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHPpy9jPu4J_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635429805893?e=2147483647&v=beta&t=pXYKmKHPniIFOzfDTSqgunIjCMjMMOiBgxv3uEQHJ-I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marian Zadora,',
        lastName: 'CPSR',
        emails: ['mcpsr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFxf2TKyC-Mgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516553082006?e=2147483647&v=beta&t=dWcUek8quYp52EO9LtAoS-1U8VuEzeLUy7IH6x85YSA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thehanagroup: {
    name: 'The Hana Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrew',
        lastName: 'Dash',
        emails: ['adash@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHB8hJtcpONgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600104296351?e=2147483647&v=beta&t=4xk_QH9b3t2jZvka0TWZFj0YdKNzDxVqVgLHCG0l3cU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lauren',
        lastName: 'Fraley',
        emails: ['lfraley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFt2FJl4C2bfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591078200507?e=2147483647&v=beta&t=EvAyD0ytevw94KZTpicaY-t-db-HUOh18PzsaxtzZSs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pablo Manuel',
        lastName: 'Crespo',
        emails: ['pcrespo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHLKcVIIkLhAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709908125940?e=2147483647&v=beta&t=z-ou7r-dJHZOdiRReau8XF8dvM36k1_eB3kkNQxmlm4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'April',
        lastName: 'L.',
        emails: ['al.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ursula',
        lastName: 'Quan',
        emails: ['uquan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQErJAvJfLbpOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517697844862?e=2147483647&v=beta&t=QGdMAuZpiEzd6ROoiqP4hH_Jq2PUPY_DbHMGKW3aBxU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  probayes: {
    name: 'Probayes',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kamel',
        lastName: 'Mekhnacha',
        emails: ['kmekhnacha@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kevin',
        lastName: 'Derrien',
        emails: ['kderrien@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGs_LnnyPGboA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618307723482?e=2147483647&v=beta&t=EQVWzcEjF3jj0knhOBKpKKRw1YYmOA590Jky8_xLvlA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marion',
        lastName: 'Fréjean',
        emails: ['mfréjean@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGgJXX9M1FOag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516438768712?e=2147483647&v=beta&t=7Rem39wEcMO0I3iz2QaZ8o0uVKJ-I4fBd0Gw5mrsXns',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sophie',
        lastName: 'Ortiz',
        emails: ['sortiz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGFsbw3BZICfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517282061940?e=2147483647&v=beta&t=CBYKh5KfZ_GQAwb7VHjbZsG7UJfM04FzudK_X476YEE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ronan Le',
        lastName: 'Hy',
        emails: ['rhy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGWS27_xCuc2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517753753566?e=2147483647&v=beta&t=O9fnYrr3Ujl9GF57hci7BOzTzYlLlkc3nOdglKprDl0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  portfolioplus: {
    name: 'Portfolio+',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mac',
        lastName: 'Hilton',
        emails: ['mhilton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jenny',
        lastName: 'Hobbs',
        emails: ['jhobbs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHKF4Rk1PNZYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684328237255?e=2147483647&v=beta&t=775Cr3dJ2EvpOephxYuJc4F6MEr_DmmigfVfD6kzpkc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sue',
        lastName: 'Bartlett',
        emails: ['sbartlett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHgpW0QXL1CQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680026759614?e=2147483647&v=beta&t=1tot3ah_15Qs79w6rEwE4M4OI--zjimXe5FqukE9jvY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carmelo',
        lastName: 'Amenta',
        emails: ['camenta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zoie',
        lastName: 'Brown',
        emails: ['zbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHENM2gKgXxCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614714281600?e=2147483647&v=beta&t=uqmPlwU7bWgeg1mrBgmST-7aZnn6tuoopimiOKQbbTs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  imatest: {
    name: 'Imatest',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Norman',
        lastName: 'Koren',
        emails: ['nkoren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEDVrhpHCeAmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689090500165?e=2147483647&v=beta&t=Flbt6h4pg-NQNaFSDk4ABz4_0gXRZi1bbexFoia3xm0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rebecca',
        lastName: 'Dickenson',
        emails: ['rdickenson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG73fRcp-2xGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516771238267?e=2147483647&v=beta&t=UpW3z8YAIWIpJx49gpRBvgBmr56L3eRvmbNcaTkEvjU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jonathan B.',
        lastName: 'Phillips',
        emails: ['jphillips@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFPd56Ig1SfMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600463077488?e=2147483647&v=beta&t=qmn-ESd9gBQAPAniPtbHTnkiMAPBmHP01zPi7VFSm3M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Henry',
        lastName: 'Koren',
        emails: ['hkoren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGGAL2NrMjOlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685485456215?e=2147483647&v=beta&t=kmbX3ctOUTrnih1nRmrE0LGt5ZBAjPLW1ffmuwO5cBg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Megan',
        lastName: 'Borek',
        emails: ['mborek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFfsbgNXxjMgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718292884915?e=2147483647&v=beta&t=2oVd3zFHXkh7KWU4GgnmWgh8Du8wq2thomheyXpOsDw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '848': {
    name: 'the 848 Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kerry',
        lastName: 'B.',
        emails: ['kb.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHuyau-AgdIKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667771518980?e=2147483647&v=beta&t=ENpFaCpfuUAvfxikua2Sqo9I3jP6HhV-OdSw8cf3Krk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Louise',
        lastName: 'Kinsey',
        emails: ['lkinsey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHuah7oTJvDJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517685200833?e=2147483647&v=beta&t=BQUYZNlW4e5TDB-8WQ_bho03QDgDUxTcF6upqcBC3dg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'McGrath',
        emails: ['smcgrath@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGTAACIEpypRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541689559438?e=2147483647&v=beta&t=Mw1kC_RA25jDreXf381kLmjmuhIAE_bDefhDcyCiJ5w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrew',
        lastName: 'Mallett',
        emails: ['amallett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFaqht2bLWKcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636122882086?e=2147483647&v=beta&t=IG4N7dcVxgXnpsaVe7tPn9q1FiAoouomfX1ouGDdjxU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Fraser',
        lastName: 'Scott',
        emails: ['fscott@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hawksoft: {
    name: 'HawkSoft',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul',
        lastName: 'Hawkins',
        emails: ['phawkins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rushang',
        lastName: 'Shah',
        emails: ['rshah@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sean',
        lastName: 'Hawkins',
        emails: ['shawkins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Crystal',
        lastName: 'Townsend',
        emails: ['ctownsend@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGh6LSgEtiIpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718834681972?e=2147483647&v=beta&t=zTggKwJsCKTm_zTRwcGdm2vXF-_crmN4Of09vt-oPwI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dion',
        lastName: 'Innes',
        emails: ['dinnes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG4QTNGxAVVDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729050276405?e=2147483647&v=beta&t=snzs_k6R2Yy7otp_77VvJrUDnqGgDCTvFZf_tCMZ_UI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  libertyusa: {
    name: 'Liberty USA Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tom',
        lastName: 'DeGarmo',
        emails: ['tdegarmo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gail',
        lastName: 'Decker',
        emails: ['gdecker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'VanMeter',
        emails: ['mvanmeter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG9QsS9XUPHuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685897075566?e=2147483647&v=beta&t=0DRJDCCuxtYBHsvLE4hkRmxhLAZo0BB4hTZ6h1GIDRU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anthony',
        lastName: 'Murray',
        emails: ['amurray@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG33XvjbVy5yA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1532116772396?e=2147483647&v=beta&t=GNYmYZUpbLzg-EwXvNQXJZJHCpVAkSfCB2q2sfc7ams',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Winter',
        emails: ['jwinter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH7OhVGobNFxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517697289688?e=2147483647&v=beta&t=XBjhE3fVMYbM3AhH8LBR6UFfgWD0_SF_GOLqIaltkp4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  inmediata: {
    name: 'Inmediata Health Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Severiano',
        lastName: 'Lopez',
        emails: ['slopez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Angel G. Morales,',
        lastName: 'CPA',
        emails: ['acpa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mario',
        lastName: 'Adames',
        emails: ['madames@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH_y-1kS1X2Cw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516966060268?e=2147483647&v=beta&t=2HidWuQ3I1g3miz3xgFa_Y3pBVGcea2Ds655J4P3YdQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ivan',
        lastName: 'Reyes',
        emails: ['ireyes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEHfgba0P8Fkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604513006089?e=2147483647&v=beta&t=e2yWjwlS6OYcTlx8XI96PsKAgzjH0ZhYyuLXvkSjbEo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Juan',
        lastName: 'R.',
        emails: ['jr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHRWeI66yJsSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1527774275088?e=2147483647&v=beta&t=UIqhSjUWoSz_JigPJKUMlY3nYH2_xzuiHIPc7XmVY-M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tcnetworks: {
    name: 'TC Networks, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jason',
        lastName: 'Tyson',
        emails: ['jtyson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGT0C2g4hEuRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516357660089?e=2147483647&v=beta&t=6AyKKbiHmuAAXF_025mbIeEbW0VRWdNIjhHJVQv9cyA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jake',
        lastName: 'Slaback',
        emails: ['jslaback@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG1OOR-KfOnzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663181945018?e=2147483647&v=beta&t=idFdjeh96ZJjBHztb3FC2mI_Y7IuQRu__cv5cpb4TkU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Roger',
        lastName: 'Tyson',
        emails: ['rtyson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGYu9n3yO0-Iw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583263772363?e=2147483647&v=beta&t=FBljluyvIcHyg9bKk0M6tDieUHFveUBgULt-HrzP3pM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Autumn',
        lastName: 'Werner',
        emails: ['awerner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFAnCfi1Tkufw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624912207855?e=2147483647&v=beta&t=l8IinzV1C8CBRIHJksqhAzaoXMMl1gKEezROj6C0fNk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Crystal',
        lastName: 'Riley',
        emails: ['criley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHWjQJkkbE3LA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692205072726?e=2147483647&v=beta&t=Il_4TwsVai-3LmxsYV8bop4u_kRvT-v3kAVvfUzkTyw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  workwelltech: {
    name: 'Workwell Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chad',
        lastName: 'Buckmaster',
        emails: ['cbuckmaster@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGQNHkOivrMVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517756269345?e=2147483647&v=beta&t=elHhBckdoQoAVKpmvwvcgr8hJwbP6gVg1EaGgbP2x-0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jay',
        lastName: 'Znamirowski',
        emails: ['jznamirowski@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Berry',
        emails: ['sberry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGpVSq4KY7Ibg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516537290408?e=2147483647&v=beta&t=xJ1OUSYmz3vxyCD_QXCul1hpgWBdDya-MLw1T3sUgvM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Allison',
        lastName: 'French',
        emails: ['afrench@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG3tcIXrBREwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670860703603?e=2147483647&v=beta&t=d6ySVTQ5NsXQwLdi2pN3_oXO_RCRMTVOsOE3cabqb78',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jessica',
        lastName: 'Warman',
        emails: ['jwarman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mccremitone: {
    name: 'Med Claims Compliance Corporation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Bright',
        emails: ['jbright@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE12w2hsIb6mQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730249444864?e=2147483647&v=beta&t=bikx5IFxumBiIfXaATMfJXMQq-tvQHiYmcK-EH7UHVE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Holms',
        emails: ['jholms@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEnxfkvOfW3kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686791808422?e=2147483647&v=beta&t=qgakCFwEL1OzGb5Z7rfh1GbC1ReLjZKkMFYm-MT9uvo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Wallace',
        lastName: 'Shugrue',
        emails: ['wshugrue@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tiera',
        lastName: 'Larsen',
        emails: ['tlarsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG_vgsLG4-sAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730484808065?e=2147483647&v=beta&t=ysHJxsB-UyqO9ZftT9_y0wBtVamm5XsnS6oe8qzPWk8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Noelle Arenz, MS,',
        lastName: 'CPB',
        emails: ['ncpb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFaXy-bteUo3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549321426532?e=2147483647&v=beta&t=8JauPmC-ThCMfkaGy70KQHUWhM3PM6kKV-MiEk4S3sg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  clearviewlive: {
    name: 'ClearView',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Erick',
        lastName: 'Mathews',
        emails: ['emathews@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEBMQiqS9oT4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648830563201?e=2147483647&v=beta&t=b9q-PQ1eqPSvVZ1_McKq61JYsoltgWmj4yw2lBAwdZU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jake',
        lastName: 'Bentley',
        emails: ['jbentley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGRqSG6YObPDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517447748497?e=2147483647&v=beta&t=Jg6Ya-pBuAYOkT5AKUL737VthJTNei83YPHitkY_l4w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Karen',
        lastName: 'Hanson',
        emails: ['khanson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFdj8mWZSBwmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710877211220?e=2147483647&v=beta&t=D5QsLHUmnsEf4WyOlEtxzTpfHpvt3aUzNfstbGRE94s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lorena Esmeralda López',
        lastName: 'Guadrón',
        emails: ['lguadrón@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG0O71AeNKmMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581701907505?e=2147483647&v=beta&t=K05E-_4iBZMI8rcoSkahZyJ-QU5DpajTrHthBA2LhhY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joel',
        lastName: 'Eden',
        emails: ['jeden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEOm2l26Nli7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516357422129?e=2147483647&v=beta&t=g3M6kigb90EUOygqO3RcTpgo14H9Hb7R6nkLd2uD54o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gomaps: {
    name: 'Data Sales Co.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Savana',
        lastName: 'Neher',
        emails: ['sneher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH1eJBnHyM0Hw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706807591000?e=2147483647&v=beta&t=EW-4hi0btMxXNfIviHRiuLbNx2AUQo9FAaR1YtIdy7A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lee',
        lastName: 'Pankratz',
        emails: ['lpankratz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEbILoXlG6IWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516852863812?e=2147483647&v=beta&t=tMJwm8vckXk4uPWUDsfLwKzPrDEynNUW-O5WkxOP6JU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Katie Atkinson,',
        lastName: 'GISP',
        emails: ['kgisp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Giedrė',
        lastName: 'Beconytė',
        emails: ['gbeconytė@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEU0vFmB3lJQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706370143104?e=2147483647&v=beta&t=hu7lTAURXRAmv4tJE13NUtrmoa0YVlXqKUdm0dR8zIA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adriene',
        lastName: 'Bruce',
        emails: ['abruce@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE0aTw4J7-prA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686607942116?e=2147483647&v=beta&t=LUCi-dxdIYokkrzUptY19gZ0jdAuJh2KBhfM9MoWUZs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  env0: {
    name: 'env0',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ohad',
        lastName: 'Maislish',
        emails: ['omaislish@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFy6s2AqeMH-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702369048453?e=2147483647&v=beta&t=q3UJoM250AGn30n1ucrd-Jivc4nWK47j84KUcv-M1Bg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Omry',
        lastName: 'Hay',
        emails: ['ohay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEhDCTMHZ8cyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516531050615?e=2147483647&v=beta&t=qqQc1sx5As3Efwvin9KGfZmFC4MVUyAxBWSvaDARmcY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Steve',
        lastName: 'Corndell',
        emails: ['scorndell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFRj-6TEaxIIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706626268799?e=2147483647&v=beta&t=EByPaGg0aj6a7nut__UD6IzFUp4AvhUbQlt0QNBpgfk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jamie',
        lastName: 'Patterson',
        emails: ['jpatterson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH3ZMTxxeOBjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730125859233?e=2147483647&v=beta&t=w1L1pFe5egSbicSD69cjJmDmq26ZEAs2G-YFbwU2_pE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'Way',
        emails: ['away@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE48zVtribQmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641355911261?e=2147483647&v=beta&t=z_IPdbsYiE9rOoamGnBB6fEGZzi996sxF0859AfJmtk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fslabs: {
    name: 'The Open Platform',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrew',
        lastName: 'Rogozov',
        emails: ['arogozov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH0pcPShMgscg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626067980744?e=2147483647&v=beta&t=aQ1H9LRk3YwIJUnvmZXJwbMM9z3ZvHRotngvn7DZiSI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sharon',
        lastName: 'Henley',
        emails: ['shenley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHxRUTryRsY8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590528342387?e=2147483647&v=beta&t=0VK0BEdqMD7QqqM8ogBkPB-E5B29ufxrxM2HVUXMzAY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anastasia',
        lastName: 'Shpektorova',
        emails: ['ashpektorova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFfDwJUqjsNzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713782208037?e=2147483647&v=beta&t=8xoDx3p-OI7qwCK096781IXDv_205FjX9K1vMR01pNQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kirill',
        lastName: 'Malev',
        emails: ['kmalev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHZXgpFWXU0jQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668080731878?e=2147483647&v=beta&t=hP1rm03cGABzIf2if708EYLybXOCrAW0pIZIvKK885U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Arina',
        lastName: 'Petukhova',
        emails: ['apetukhova@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vidizmo: {
    name: 'VIDIZMO',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nadeem',
        lastName: 'Khan',
        emails: ['nkhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGg6OzRzT8tSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517775648004?e=2147483647&v=beta&t=GRzWOuXCW4f0NshRIgM2dTZ4d4f0fNMtLQRva4AWC4g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nadeem',
        lastName: 'Khan',
        emails: ['nkhan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Farooq',
        lastName: 'Khan',
        emails: ['fkhan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Farhana',
        lastName: 'Khan',
        emails: ['fkhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEp9NUAeDhK9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728568468292?e=2147483647&v=beta&t=X6CO0eoXL5BIj7MrFNAyu3Edqz0o0cuX0G7RyLQ20e8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rafey Iqbal',
        lastName: 'Rahman',
        emails: ['rrahman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFo65_nPlLWug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712743597047?e=2147483647&v=beta&t=g844f2fmYz9IN-1liFlP00t4jqrZJ57LeG3cUGX3uXg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  samgau: {
    name: 'Холдинг Samgau',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gaukhar',
        lastName: 'Ospanova',
        emails: ['gospanova@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Evgeniy',
        lastName: 'Maximov',
        emails: ['emaximov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHDzF6RHH2xWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646476781462?e=2147483647&v=beta&t=w8hv6-6txF0OLlNB2l_hO1wrapR6Q_69rsje52GjkSs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aidana',
        lastName: 'Nurlanova',
        emails: ['anurlanova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHlil2Oikt3tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660284129758?e=2147483647&v=beta&t=CkmglOf1ZBi7UH3w__fv_KXhCKcTn0J9WLBEq8BKJpc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Arman',
        lastName: 'Tleubergenov',
        emails: ['atleubergenov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEul6u5C0nj2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623238655712?e=2147483647&v=beta&t=rRZkpl1VstWbKTCPai_edcr0W0svjoJ_81Pe2pm0pgA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Denis',
        lastName: 'Severinov',
        emails: ['dseverinov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGrvhtQEOXdEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711205706411?e=2147483647&v=beta&t=rTeib_4d6NbLTng45fgqSpMnbXAWlINLfoIaeauO-S0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  agilelab: {
    name: 'Agile Lab',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chantal',
        lastName: 'J.',
        emails: ['cj.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'ChangSheng Sze',
        lastName: 'Tho',
        emails: ['ctho@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEf4h-pPVNOHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516653627666?e=2147483647&v=beta&t=Uoaky9t-yDbq0XiRMYDY44BfSaDuWsLl2_H92UWsK3w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Colin',
        lastName: 'Noronha',
        emails: ['cnoronha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE7l6USf87b-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607913192404?e=2147483647&v=beta&t=HMRYXPppsjn8bXaF0A-dOWyuiTUC26Q1Kl3Rf3KZddE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lorenzo',
        lastName: 'Pirazzini',
        emails: ['lpirazzini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFoPVxtwNIr_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695898946377?e=2147483647&v=beta&t=Mp9VsTjPUz79-Ynrt5A-1y-QCf5-ew_mdWuFcTcpf3w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Antonino',
        lastName: 'Ingargiola',
        emails: ['aingargiola@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGxh3mcq3tsOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1532509090957?e=2147483647&v=beta&t=MwYjx-V2fhwtzEIv9Ms5kX3m-kYhLsTDDdRcUNQZBms',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ibisSolutions: {
    name: 'Ibis Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Srđan',
        lastName: 'Suć',
        emails: ['ssuć@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHPL5OhQbYhSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720371131328?e=2147483647&v=beta&t=NeLapHRIwh2kNm6wOCDHMTjBPC0VI-LvmefBPMUAt1M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ibis',
        lastName: 'Reynolds',
        emails: ['ireynolds@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGIE8ObYk6cNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715617593154?e=2147483647&v=beta&t=d5j1jKsWUOSkm-zN7Vuy00ddgTJ5T-g-uQpDezYuOOw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ognjen',
        lastName: 'Popović',
        emails: ['opopović@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHf0c4SCv-mkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711379983951?e=2147483647&v=beta&t=Lkbl7BvJFbG2wx-aceTHvvxRRMX4VivoaVGxBK_8n6M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ilija',
        lastName: 'Lazovic',
        emails: ['ilazovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFlJB0A-Ronzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517673993458?e=2147483647&v=beta&t=IedjOJW_02ZBV1LfssBYo8CB2x28dLr1n8xkDXIpLcI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Milica',
        lastName: 'Cvitkovac',
        emails: ['mcvitkovac@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEt0j8gAw-ZNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517374332071?e=2147483647&v=beta&t=HU3xGKNfKztDv50kO5aqbjETOiUywW2wP1vS1rcZKUA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  livehire: {
    name: 'LiveHire (ASX:LVH)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jennifer',
        lastName: 'Byrne',
        emails: ['jbyrne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGkvpCYF0pWsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637762616135?e=2147483647&v=beta&t=z2totNB3z0s5JlExKt6eLmscpDqnORm7x7CrZpzagd0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Antonluigi',
        lastName: 'Gozzi',
        emails: ['agozzi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHapnCGTnw3ag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517670718139?e=2147483647&v=beta&t=CmdarwvVy_wtKNY03RlkWU2JpwsxXtOfr1_-70siVIo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ted',
        lastName: 'Bliefnick',
        emails: ['tbliefnick@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Delaney',
        lastName: 'Weiland',
        emails: ['dweiland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHMxEKtPfLmYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713373998412?e=2147483647&v=beta&t=loqRB59ftdUvvVB3ShLP8GOpmoHuLy9Za3LSI2x60t4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ben',
        lastName: 'Brooks',
        emails: ['bbrooks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFDP_J_3ioSUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682940779205?e=2147483647&v=beta&t=_dC_6-IC-z6VmGRQJNpiBbxUGIG70Y_rZXwDGOgCs3c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  emcd: {
    name: 'EMCD - Top 10 Mining pool. All things crypto in one app',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'EMCD Tech Ltd. |',
        lastName: 'LinkedIn',
        emails: ['elinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGDrHt0j2dWzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658325661446?e=2147483647&v=beta&t=SVRxO5cs26UBPPv4-013IwnNqBh49ySjYm3fuj5_v5g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Jerlis',
        emails: ['mjerlis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG9BXgi-_C7Jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701198571437?e=2147483647&v=beta&t=gPor9VzB6nKLPiXWtJhZA9jyWGSD5eC_JwbJjwoFUqg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Evgeny',
        lastName: 'Denisov',
        emails: ['edenisov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFpJBS3VmGaHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699556960804?e=2147483647&v=beta&t=vr2mP3l99O14bMxGc3srt4RIx3_p0u4QhuqUDaDZJEg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael Jerlis – Associate Founder – EMCD Tech Ltd. |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG9BXgi-_C7Jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701198571437?e=2147483647&v=beta&t=gPor9VzB6nKLPiXWtJhZA9jyWGSD5eC_JwbJjwoFUqg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rustam',
        lastName: 'Galiev',
        emails: ['rgaliev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEFwo_ViMBf6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696528636007?e=2147483647&v=beta&t=SE5U4w5p6oHKLQ8GCU8vyOlE-i1w7ewN8qtV3n6YVns',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fonetbt: {
    name: 'Çekino Bilgi Teknolojileri A.Ş.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sinem',
        lastName: 'Bıçakçı',
        emails: ['sbıçakçı@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF94LDBwm0g0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638561499250?e=2147483647&v=beta&t=EBV2l1TcvBCU8OUrbeYaxsmkOUshHQ4Y-YvQ5HYWMjc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Zuhal',
        lastName: 'Lüleci',
        emails: ['zlüleci@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF6Sv2wnBNmFQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1591969121186?e=2147483647&v=beta&t=aSobwIyoP5cCtAZ7kPfY1OTB4E914T7HrnozGkypl0s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erdal',
        lastName: 'Yılmaz',
        emails: ['eyılmaz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHfEg2TS-lQLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708527357388?e=2147483647&v=beta&t=_Y9B52hTFvJHmdpXKP1nTYQn7i45FRzSjpUFXMQPU0g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sayit',
        lastName: 'Engin',
        emails: ['sengin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFFFIyRKNoEwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641311332553?e=2147483647&v=beta&t=ua7i8LD3wFdRrYvX8EJs-KRbmNijwMUVQjB2IoapUQw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mehmet',
        lastName: 'ARSLAN',
        emails: ['marslan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQElgDBXmdYbHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633354429720?e=2147483647&v=beta&t=ixlAJBGfll_Pum5SQRajZgLaFSBTTRTRy72ImYFxOrE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  farcana: {
    name: 'Farcana',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cynerio: {
    name: 'Cynerio',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Leon',
        lastName: 'Lerman',
        emails: ['llerman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGQ8uvJKx-6ng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718242596122?e=2147483647&v=beta&t=eEz7COUEN4DNmS9ksKptfib20mO-vMn-sGHwJ1lj8Oo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Brodie',
        emails: ['dbrodie@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Maria',
        lastName: 'Guillen',
        emails: ['mguillen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGnlKiLrE8NRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1599253619725?e=2147483647&v=beta&t=mw1hbz92P8Cz3fkUOuWQCukX9t_95kUwR1L8Eopg4oM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aya',
        lastName: 'Naveh',
        emails: ['anaveh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFehXL_F5OksA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621709554567?e=2147483647&v=beta&t=E1vKs0Srsjuim3cF_e6Xk2aBm8dOrbaZuaWiRkkIOIg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim Bloomer,',
        lastName: 'CISSP',
        emails: ['tcissp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHVrxgLBmni2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701184104802?e=2147483647&v=beta&t=ZQvUL0V40nZUUQQ_Dm_uppLeX490sMaBd3migOJ1PG8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  velocitor: {
    name: 'Velocitor Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rich',
        lastName: 'Pacella',
        emails: ['rpacella@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rudy',
        lastName: 'Nemeth',
        emails: ['rnemeth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQESFmtSryU7Hg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632864980068?e=2147483647&v=beta&t=X7TtsksUQ0VfjX05gfBU-7_ulUDuaNwpogCGPVS4ZEY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Howland',
        emails: ['chowland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGccEJ4kdZx5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684538245795?e=2147483647&v=beta&t=VIIyFB4lYt8yLe3UiM_jeYouwz2wtpxkH1QLCwSvAZw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jim',
        lastName: 'Becker',
        emails: ['jbecker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFZYtWB5gCVVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621362861450?e=2147483647&v=beta&t=TeYrmmRm8AdSzi05z1WG4OIa6ZXb6ghUR9p16ccwuk8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dave',
        lastName: 'Thompson',
        emails: ['dthompson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  akveo: {
    name: 'Akveo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Volha',
        lastName: 'Lutsevich',
        emails: ['vlutsevich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEqVnhqORJ-cQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666854644049?e=2147483647&v=beta&t=lKtEHdD1k9LJdNWEOaAZw5UkL_yzO-NhEGPEFVPJHzs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marina',
        lastName: 'Dolbik',
        emails: ['mdolbik@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dmitry',
        lastName: 'Rutskiy',
        emails: ['drutskiy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGId7EzuCB28g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727871338560?e=2147483647&v=beta&t=dJOFWedCezVqEbuT3K70ZwnDxMAR_ZOvoe1R72zFCVg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rodion',
        lastName: 'Badak',
        emails: ['rbadak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE41CYyqhRQsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684604520198?e=2147483647&v=beta&t=Cof_2_TijGdywu2PwPKFMIbZTeqOEF4lux_ubli-Gqs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kostya',
        lastName: 'Danovsky',
        emails: ['kdanovsky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGZqSUx9DOvBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517245750966?e=2147483647&v=beta&t=_HeusyZC77BWZAeufPBSnVWN82JUn3rSFDJOs4OSiek',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  skyhawkkinetic: {
    name: 'Skyhawk Kinetic',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kyle',
        lastName: 'Wynyard',
        emails: ['kwynyard@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Julia',
        lastName: 'Subrian',
        emails: ['jsubrian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFEt4W08rGzNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657598706275?e=2147483647&v=beta&t=ArLD2QIhMR7cTuwkz-uoyswOH4BkFJ5IxcE5lVeDNl8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Suma',
        lastName: 'Narasimhamurthy',
        emails: ['snarasimhamurthy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQF5UXtmNarG2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568571939374?e=2147483647&v=beta&t=1beHJsEvm3hKctOwTg-F2hVSMwtxgyXniASDn6_aSnw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yash',
        lastName: 'Lodhiya',
        emails: ['ylodhiya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGN5sGHIKkP4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559812354674?e=2147483647&v=beta&t=RDFQpVUHMfOva_026qznpPKr5BP1Fkb_I7rqMwSQg4Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amita',
        lastName: 'Mhaskar',
        emails: ['amhaskar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEmu2qjAcg5TA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549486361243?e=2147483647&v=beta&t=XlQEvNNVaXmgmoigng1aSy11TWRArzq0Ds2CJ4gCmwQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thirdstream: {
    name: 'thirdstream',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Susan',
        lastName: 'Hennessey',
        emails: ['shennessey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Keith',
        lastName: 'Ginter',
        emails: ['kginter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEZ-684K8mOVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517623208731?e=2147483647&v=beta&t=h46PsV1OYhB7r61jG29yzr6gR70xlqhi7i0Ht3cBKiU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Clement Lim, MBA,',
        lastName: 'CPA',
        emails: ['ccpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH_n6mhQU4Xgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516321913191?e=2147483647&v=beta&t=6CoYVYCNaIbsQNJY18HPzkPwEMHqJrj1jBcfAVg3FYs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Coomber',
        emails: ['jcoomber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFJca2fA6FPXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642717601497?e=2147483647&v=beta&t=rcTd1iqDwA_ljXaDo_9b9rZPAoFRHcuCFs9U0WStG7w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ross',
        lastName: 'Hikida',
        emails: ['rhikida@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  huware: {
    name: 'Huware',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ken',
        lastName: 'Hu',
        emails: ['khu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEMhBQgQwxxXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708425780168?e=2147483647&v=beta&t=dcj0f0deZLWt_ijzlCNhNFVhdJz_rIh6MJyncN2nb8Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steven',
        lastName: 'Geiszler',
        emails: ['sgeiszler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEunVvVLRltzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661441107849?e=2147483647&v=beta&t=MP7Dge6Hwj_Oji6xPxxV7eMSBIyt4vjAorFviOFIN1o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'Warren',
        emails: ['jwarren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHAzs7U9eD5wA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588257593330?e=2147483647&v=beta&t=R-IfUpGGRf68oxy02s_9zvl1E7uN6G2c66l8r_LJSz0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ismail',
        lastName: 'Elezi',
        emails: ['ielezi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adam',
        lastName: 'Lane',
        emails: ['alane@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF7fZHVT4MG9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516158745051?e=2147483647&v=beta&t=_YB_CctFMjkHGIiLt9RsTqh18JlHbxqo1nhXgKeuzHg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  planner5d: {
    name: 'Planner 5D',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anna',
        lastName: 'Radionova',
        emails: ['aradionova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFIkGlIwpp2lQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663680269466?e=2147483647&v=beta&t=f-4sprmZeye772W37zGC17GjFPTyonoTA-V3m-rbugw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexey',
        lastName: 'Sheremetyev',
        emails: ['asheremetyev@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrey',
        lastName: 'Ustyugov',
        emails: ['austyugov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFWeqbyeil-cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603205951711?e=2147483647&v=beta&t=0GSeOpD1D1gyxJ0lr7SvlIHAZQnw0_mG4xRp8JpQA6o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'Kryvashein',
        emails: ['mkryvashein@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Evelina',
        lastName: 'Juzėnaitė',
        emails: ['ejuzėnaitė@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGiEoL4RdMZcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629117153360?e=2147483647&v=beta&t=6nebkT_d1c28Yq_XbFDDfTp-q7PbNihZ3SWNykbhrS0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  theloop: {
    name: 'The Loop co.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  storware: {
    name: 'Storware',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michal',
        lastName: 'Bambynek',
        emails: ['mbambynek@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paweł Mączka – Storware |',
        lastName: 'LinkedIn',
        emails: ['plinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHaVvj3tviQZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706819244781?e=2147483647&v=beta&t=OH_XSMhKfQj6fPL4uedrtLJj1LnzHS7axVXVXOoQdnU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Angelika',
        lastName: 'Jezewska',
        emails: ['ajezewska@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEq2y1UMhkfvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600426301495?e=2147483647&v=beta&t=UsYG5CAK3Wg92hNjfsDxoNd5QS0Nml3l_Ofk2E2uZsY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kuba Bogacki – Junior Java Developer – Storware |',
        lastName: 'LinkedIn',
        emails: ['klinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHegzT7P1yyLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657138488697?e=2147483647&v=beta&t=F9XuABNVKkyxYxqiTErBR1MkQ3fubBE2pffBKY83914',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mateusz Chmiel – Storware |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGGrAjo6utKlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573064550242?e=2147483647&v=beta&t=IchhRjGSPi_4CCkcTPw1hRwmHZH3HDhdu-_ZF9ttZa4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tradecentric: {
    name: 'TradeCentric',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Elizabeth',
        lastName: 'Segovia',
        emails: ['esegovia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHxnpuCnKK6Ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676364126249?e=2147483647&v=beta&t=pFuRTt-euC4n62CTCUFcIdOUIFfguvwLBT2MIknpYF8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jamie Gumson',
        lastName: 'Thomas',
        emails: ['jthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQENCoj4SUfcsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614970977118?e=2147483647&v=beta&t=a7utf1coiyAOBLssEqL0dWrh7yV6XbT1HqjGzXF8pkw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joe',
        lastName: 'Henry',
        emails: ['jhenry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEia-PqGiOV5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663613484435?e=2147483647&v=beta&t=NWmfjkKgpACyoOYUXjonsUXnheVT-nQR6_ffx4tmjUw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kevin',
        lastName: 'K.',
        emails: ['kk.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brady',
        lastName: 'Behrman',
        emails: ['bbehrman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG8C9dYz7OJhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729629001615?e=2147483647&v=beta&t=x4XVf2PisN8nNONkIIwysM5IKi2_jQJkyE-iqKXWWWY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  frugaltesting: {
    name: 'Frugal Testing',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rupesh Garg',
        lastName: '⭐️',
        emails: ['r⭐️@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHvRqw_sRKi6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666857952777?e=2147483647&v=beta&t=0YTfJ3ot6qzsNJ5pJQ-Wym8iDzf1soyRBWmLjBc2Qls',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bharti',
        lastName: 'Garg',
        emails: ['bgarg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHt2gKsVMSHuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648619270460?e=2147483647&v=beta&t=fFMFVbZynqcpi5oTjFHic14MvkWgGCPsiweC2_BoCyM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Monika',
        lastName: 'Aggarwal',
        emails: ['maggarwal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGIyFDsJIIJDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583147427808?e=2147483647&v=beta&t=-OM0m4H0ReR5o_ZP-S37tuHimSpV8C8cWOfZ6fqXI2Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sai Sushant',
        lastName: 'Kasturi',
        emails: ['skasturi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFvll1kLzgiIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720444385017?e=2147483647&v=beta&t=UuKKPl_PXTTKAuSKRbqW2ZYOlYmgvAiU2zEBdINJk0w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shruthi',
        lastName: 'Kola',
        emails: ['skola@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGNjqjeOfm1vA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680013842715?e=2147483647&v=beta&t=3p4rhTQVgWQWg1y68vFd4QJTbHcK4zwVMSaM1mDsQXQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  numspot: {
    name: 'NumSpot',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jocelyne',
        lastName: 'Kauffmann',
        emails: ['jkauffmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFfEnLviMg1lA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714323859330?e=2147483647&v=beta&t=l3RRUH7nuGSsx-4kIjeanKZjOEtnF9WxEK-89VfsDQ4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Arthur',
        lastName: 'Vallet',
        emails: ['avallet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGGpC8-elGuvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729495798384?e=2147483647&v=beta&t=yik4S4vpqSmgMiOdH8knAOrs2c5YccDLqaW7TS1Hk7Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jean',
        lastName: 'Atmé',
        emails: ['jatmé@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yohan',
        lastName: 'PARENT',
        emails: ['yparent@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQE9SM-19eC7bg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516925969153?e=2147483647&v=beta&t=8vcgFoaSpY4_jyJlfIxuZYSfRal72q3-AVzdN_3n8PU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eric',
        lastName: 'Fredj',
        emails: ['efredj@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gameloft: {
    name: 'Gameloft Barcelona',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Raúl',
        lastName: 'Orrego',
        emails: ['rorrego@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFn7Rgc0qBvSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679657192737?e=2147483647&v=beta&t=aYMuYBDlFX0bST-6o-AWaQoqyAji7i4Ry9JQe32F2zQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Albert',
        lastName: 'Puértolas',
        emails: ['apuértolas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG4lyeiCvJpcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517680804056?e=2147483647&v=beta&t=l9Pv5P7QQYmkatUXgAvcbPC5MbFQdOSOLJfcLP7L-vk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nora',
        lastName: 'Petrigalová',
        emails: ['npetrigalová@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGuUTB0itTo2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701009758520?e=2147483647&v=beta&t=NbMbjpf19O167h556Uf7L06ZCcYljAvMvXm2j_BZkts',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mihai',
        lastName: 'Sebea',
        emails: ['msebea@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHKG2FSCY7y9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724698187058?e=2147483647&v=beta&t=27Az1miJNOARQ6UzlmqcHRLlygSh0uOlZiDot45B03M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Fiona Jenkins',
        lastName: 'Pons',
        emails: ['fpons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEbhhaMi_gRBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666013653902?e=2147483647&v=beta&t=EVCQqNxQcbDvputlRYXGgO-gQsPfC5fhKew_29v8SdM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  luminoslabs: {
    name: 'Luminos Labs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Radu',
        lastName: 'Munteanu',
        emails: ['rmunteanu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jacobi',
        lastName: 'Zakrzewski',
        emails: ['jzakrzewski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEY3J1zxbKTdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564407637068?e=2147483647&v=beta&t=Q7tPB49zGoztq-Pr4TbATcoliDtXswSIZvql1RBqNz4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ken',
        lastName: 'Schroeder',
        emails: ['kschroeder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE0QTYnB4rvBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721504287410?e=2147483647&v=beta&t=wGaxcgwzdVvj8CoqG4WeCyJ_Scg5WqtUGWsAjIbTmWs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Raed',
        lastName: 'Aljawad',
        emails: ['raljawad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHaXYFuJmWzAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727493641908?e=2147483647&v=beta&t=oPyvzFWhK56v1wFaaMZmQmfkgq505TWxWW-k8QA1Ei0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aaron R.',
        lastName: 'Lett',
        emails: ['alett@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nyusoft: {
    name: 'Nyusoft Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lakshmi',
        lastName: 'Shah',
        emails: ['lshah@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dhaval',
        lastName: 'Shah',
        emails: ['dshah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG8yMi3WcgAqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678607411794?e=2147483647&v=beta&t=Fmv6nuUb-GQJe5HSHQ6OvgJlQVu3v_-Vtzef6XTntbg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jinal',
        lastName: 'Shah',
        emails: ['jshah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFS7Em1fhzyCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685851280092?e=2147483647&v=beta&t=JiVBsUroO4ejiGE2671mfGZnksanFB3TcX7gSo3V5N0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marmik',
        lastName: 'Patel',
        emails: ['mpatel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEGxPHO70BxRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714335521568?e=2147483647&v=beta&t=RjiXm3_13sjgdp1rIz4EiOkv29Ollq1MuoiKR1t1EeA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ronak',
        lastName: 'Pandya',
        emails: ['rpandya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG_pL4Lhtl-uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715311436473?e=2147483647&v=beta&t=Vg7U4jloO02UYbErdjRKrEJ7NlPuav2KEAKm2Aidi3I',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  arintra: {
    name: 'Arintra',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nitesh',
        lastName: 'Shroff',
        emails: ['nshroff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGCp0Rvb33ZgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655694884394?e=2147483647&v=beta&t=3OnN2OnWgxAdW48kddsjD-pPNsX9zADAk2ZVPKPGxJc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Preeti Bhargava,',
        lastName: 'PhD',
        emails: ['pphd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Satya Kishore',
        lastName: 'Gontina',
        emails: ['sgontina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFq8zAWFCKY9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626950485939?e=2147483647&v=beta&t=JmQvVbdGQPU0oKWXAaU4yNpZu85uj5uRfyekVGNulbM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Justin',
        lastName: 'Hunsinger',
        emails: ['jhunsinger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH6FN0FJQw17Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567225167861?e=2147483647&v=beta&t=JLbjX4KGmY4DZzl9e1mKJv2w2Med7X5U5eeP4IS9dKE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shashank',
        lastName: 'Jatav',
        emails: ['sjatav@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEZF4-gsIUbsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557649371649?e=2147483647&v=beta&t=5tzEjki7sMMYLIih9d35QIE03zbKQ_wKOa2XtsfPlU8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  driivz: {
    name: 'Driivz',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Blake',
        lastName: 'Jessen',
        emails: ['bjessen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Oren',
        lastName: 'Halevi',
        emails: ['ohalevi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGXAzwaH6tU3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718210925836?e=2147483647&v=beta&t=qoSYy5GSKDPvIcWjJLHhjMTsaii5Wtu6PauYs2hn9R4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yann',
        lastName: 'Kulp',
        emails: ['ykulp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shachar',
        lastName: 'Inbar',
        emails: ['sinbar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aidan',
        lastName: 'Daniell',
        emails: ['adaniell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF_2fh58WveCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681490890703?e=2147483647&v=beta&t=wGwd6NGvncoKU3RqUAp7iF2FGCz2fsqssvCsmUZGPSQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  liquidtechnology: {
    name: 'Liquid Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jason',
        lastName: 'Mota',
        emails: ['jmota@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFrL5uDXdAWzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517520533547?e=2147483647&v=beta&t=PWEL3HhDTDUvg6XDYyv07Wxzsk4LTq53RicgZhY48Fs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hadi R',
        lastName: 'Tabani',
        emails: ['htabani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHRrW6uqrumsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730209327963?e=2147483647&v=beta&t=z6M9_dcsesWKSp6oWiROPsZcVX6GiacCHekQ7W9CpZY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephanie',
        lastName: 'Talian',
        emails: ['stalian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEYTtWoHjGFFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1577802480895?e=2147483647&v=beta&t=fpI9BDjnAPRhIOzc5GDGb5ozzcCb4ekcnznPXhROYSE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rob',
        lastName: 'Ruehle',
        emails: ['rruehle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFz1tbTJDNBFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543526403541?e=2147483647&v=beta&t=flmvmapQHF7xKSQQNdwpYWeMyUnuuwdGEnu0wMTANao',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stephen',
        lastName: 'Sidwell',
        emails: ['ssidwell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ironedgegroup: {
    name: 'IronEdge Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ryan',
        lastName: 'Lakin',
        emails: ['rlakin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEX2aQKQpqaag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723782502299?e=2147483647&v=beta&t=uZLp5F5KG1AppmHawcr0c64_d4rzL5-IW6n5Pr6YLRc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Foit',
        emails: ['rfoit@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGW__7TTgtByQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557182258075?e=2147483647&v=beta&t=_ZZhDTMQLekMgJLaK4IEHeVuMhF2Fhi4dWAIUj77jN0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tom Albano,',
        lastName: 'CPA',
        emails: ['tcpa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Wayne',
        lastName: 'Shipman',
        emails: ['wshipman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHHRYTIcwWmJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586886160668?e=2147483647&v=beta&t=sgBnF3m4kzRYgpUs1ieH_2CoBlbjjySG6NrmgdQZFHw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cristian Gaspar de',
        lastName: 'Alba',
        emails: ['calba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGAWYOLEdqo0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678838250449?e=2147483647&v=beta&t=eCOWSlPT4J3JBl0NjpyssVrmyOEforRSMPWYaklwBc4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  milleni: {
    name: 'Millenicom',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dennis',
        lastName: 'Castle',
        emails: ['dcastle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEtgB53KysKqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609882060289?e=2147483647&v=beta&t=qxSFJfuPsY6NeubfQyJxB6y5DFg37okQ4D_eIqcoE9Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Igor',
        lastName: 'Marinkovic',
        emails: ['imarinkovic@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yaşar',
        lastName: 'Öztürk',
        emails: ['yöztürk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEnebLOgTBcXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581575295914?e=2147483647&v=beta&t=Jg2YBRdV5SPiHF8ZT-LEL7nNR5dKTYjSjWtAZG0sbow',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maral',
        lastName: 'Camcıoğlu',
        emails: ['mcamcıoğlu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFTpQzT7PxGVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516550455064?e=2147483647&v=beta&t=gTWESO2syQj1xXi4wLR-SlhlsbwJOPOTCSp_8jSW1DU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Deniz',
        lastName: 'B.',
        emails: ['db.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE3DGowJVbsfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612784366502?e=2147483647&v=beta&t=dyQ8WIjC-VuvwUab_WwrUD3zxvXFQ3lVopOC8FKddvk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cbc: {
    name: 'Champagne Beverage Co Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Vellinga',
        emails: ['jvellinga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEB2DYXJcr0tg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643825951899?e=2147483647&v=beta&t=mnnM2GjvWfyfp8A9e28eLWMsaZ-M0vdUcvhwvXZFC1Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Casey Kiekebelt',
        lastName: '(Wing)',
        emails: ['c(wing)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE2C4t8ocQllw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644506628367?e=2147483647&v=beta&t=Fqn3beKU1uh0mzdXKow-s_cqDz5RQuaSmhmcFo2hA9U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'marina',
        lastName: 'zirkova',
        emails: ['mzirkova@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Itka Zirkova – Doctor – Faculty Hospital |',
        lastName: 'LinkedIn',
        emails: ['ilinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Jenkins',
        emails: ['cjenkins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHn75YJZwbVfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549642761878?e=2147483647&v=beta&t=EJL81gDgs5WLp95Q8eBc442w9aLICca_0zLCgu7Dh0Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '1cgs': {
    name: '1C Game Studios',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nastya',
        lastName: 'Baratova',
        emails: ['nbaratova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFhBagTyQ0PcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673722156874?e=2147483647&v=beta&t=s50jEi21o1PGh3w-GCXms2WQvHYMy5K5wRBAC4jK14k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrey Shumakov – 1C Game Studios |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEeOY0xORK5WQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693501183489?e=2147483647&v=beta&t=SLt0GnDi229C4MyCtbJLHMqm5P7gOAzj6w9M98IzVb0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alexandra Mamaeva – Special Projects Manager – 1C Game',
        lastName: 'Studios',
        emails: ['astudios@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFJxRX4-iaTsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691575138250?e=2147483647&v=beta&t=MZ5Py2Kdpb_uAys8TzUC_h2liLcZDmOtcqo11o_qbys',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrei',
        lastName: 'Korovkin',
        emails: ['akorovkin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Isaac',
        lastName: 'Chavira',
        emails: ['ichavira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH34FqoRhaOmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615617217687?e=2147483647&v=beta&t=tDVuwV8ExILo1-BWTHN3I6PudhTW9uZFpDp1Fsv5YsA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  peopleinsight: {
    name: 'People Insight',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jane',
        lastName: 'Tidswell',
        emails: ['jtidswell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGCmhEEniJo_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541879881988?e=2147483647&v=beta&t=pdxvavPp6c65xfHf-lJw7zVtTwl51bnPDWlJ06lnC3c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Simon',
        lastName: 'Townsend',
        emails: ['stownsend@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFrU7bAWw-Brw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1576485711172?e=2147483647&v=beta&t=41juFJKaTd8tRubKHl5hNlBHTrtc-p4l3pqiKeaKLdk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carolyn',
        lastName: 'Nevitte',
        emails: ['cnevitte@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHeAfa3quPhVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693819151675?e=2147483647&v=beta&t=8JKu42XjeaEU7SJIvE84M2T8MfaWjBTvsyB_rCXZGqw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kate',
        lastName: 'Pritchard',
        emails: ['kpritchard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH7V-i7Hkz0FA/profile-displayphoto-shrink_200_200-alternative/profile-displayphoto-shrink_200_200-alternative/0/1529424273746?e=2147483647&v=beta&t=LgahxsxGG-yoknytqp6Wuu5vV4OmUEPtvvroa10cMnI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Josh',
        lastName: 'Klein',
        emails: ['jklein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGcNM1_lIuWiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729504618576?e=2147483647&v=beta&t=UURGG0yTO0_ynVDN0ON1R0z924XuZLzJsIRTzodxcj8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  witekio: {
    name: 'Witekio',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Samir',
        lastName: 'Bounab',
        emails: ['sbounab@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFeqNXcdjuj-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725927816815?e=2147483647&v=beta&t=hcZj4w9_pukZXHThYGa3bFNJOjnlQbzd-ITAZAZej1U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Georgina',
        lastName: 'Ryan',
        emails: ['gryan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF7XDg4JvObLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718276448197?e=2147483647&v=beta&t=3wHjWQjcWEXZf7bEHXRf9l8FS_9hGIYCD0mcWzXkYOs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dustin',
        lastName: 'Haring',
        emails: ['dharing@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEpkZouPg--aQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550983591883?e=2147483647&v=beta&t=U9CgANLmrsrRZ6kf6KOJScvLp2t9zjsI1lcrLlmPEuY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nicolas',
        lastName: 'Duvernay',
        emails: ['nduvernay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHw5Lx9zQ_C1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605781797761?e=2147483647&v=beta&t=3vkg2TCnTlhHPUiVpFkoXq9ix_1BlT5ISxE7AQok7yY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adrien',
        lastName: 'Leravat',
        emails: ['aleravat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGMH6LTeTjcfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670302278169?e=2147483647&v=beta&t=7Ob7o65_cY0iwWHJUJWZfv0eg1D-ZeVSftfwt6v3gIM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  artec3d: {
    name: 'Artec 3D',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alexander',
        lastName: 'Sharapov',
        emails: ['asharapov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF2UqDE7kvhaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651062020392?e=2147483647&v=beta&t=ZWwjAE3zPZexcEt7-zUfqB_TwXIDAHISFScmJCGMo-U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anna',
        lastName: 'Galdina',
        emails: ['agaldina@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anastasia',
        lastName: 'Khaytul',
        emails: ['akhaytul@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFIBOlvEvOhTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660688680909?e=2147483647&v=beta&t=IZDxmRDmLLnEThIH_qBEERn4EViogcYi_SgI3KfaWCE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrei',
        lastName: 'Vakulenko',
        emails: ['avakulenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF71C2zy8t-4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516228630885?e=2147483647&v=beta&t=Rpa27FSdpQFXFZclshVNuriMKYmplGoKutGif3vm9hQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sergey',
        lastName: 'Sukhovey',
        emails: ['ssukhovey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG4Tzn2KYIMNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517681443666?e=2147483647&v=beta&t=Zno3o1Vc9t6RtUmisru9KBgwRlXuOig_hVpq2hBAeow',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  getnexar: {
    name: 'Nexar Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bruno',
        lastName: 'Fernandez',
        emails: ['bfernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFG-MwjwZ-uSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651692635860?e=2147483647&v=beta&t=VYRulPbHzE3nz6hqquswDM8-9u_XflbrAbcnbcqqteg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ludovico',
        lastName: 'Fassati',
        emails: ['lfassati@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFOGbjFnoB2gA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724857068455?e=2147483647&v=beta&t=00P7AEWBCVWwHoBWcktMnyHdnsslHwuBh8-oTFVZk38',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Luc',
        lastName: 'Vincent',
        emails: ['lvincent@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH8VBIIuBLUdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728328037482?e=2147483647&v=beta&t=dfTAk_CrDHvEKWww0LimEg1Goqlw8SRwrVAb8ci-Ky0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jon',
        lastName: 'Miller',
        emails: ['jmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEVFc1boePxVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655379792502?e=2147483647&v=beta&t=eMs-m676Tyb9renrY0rIEhWJNK5CegQAAPpO2rs5NSE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Silvia Benquis',
        lastName: 'Hes',
        emails: ['shes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFx_VDph-tcNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623263236534?e=2147483647&v=beta&t=VqM-rR9PqlecXUcNp-1GMxfExTXklPD41QwsKjUSz4k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  roicommunications: {
    name: 'Amplix',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tara',
        lastName: 'Smith',
        emails: ['tsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE1gQzmPWGnVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519079256445?e=2147483647&v=beta&t=CbInCv82p7HJ6b3bckjLVcFLA6C6CkCevp2e6IxkbS4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joe',
        lastName: 'DeStefano',
        emails: ['jdestefano@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEqnRzul3SJYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670935896155?e=2147483647&v=beta&t=Z_rOY-leQSdvqnQjeU-O2XF1Pcp3pB3tnNy8hGK9Q5Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dan',
        lastName: 'Frasco',
        emails: ['dfrasco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGGOLGRGr5Pqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516262776711?e=2147483647&v=beta&t=RllFPjsuYuTn6Vj8LcEeNVEDE6UsuvY5S0-trYYx2z4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dan',
        lastName: 'Gill',
        emails: ['dgill@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Pyenson',
        emails: ['mpyenson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEvB2WMQiOlQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517743650546?e=2147483647&v=beta&t=VfgejFDpVRiVS3E_DvRCDMlUsQt0cBGKP-nl2GFWHBg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  goharness: {
    name: 'Harness',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Yogesh',
        lastName: 'Patil',
        emails: ['ypatil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHIvX8kNL-pWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684945226784?e=2147483647&v=beta&t=UC676ujgzvYlgUbKy2_9o6NIvr1PVLVpJ-d5yxqs6wg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Victoria',
        lastName: 'Harness',
        emails: ['vharness@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Masha',
        lastName: 'Harness',
        emails: ['mharness@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abirami',
        lastName: 'Krishnan',
        emails: ['akrishnan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFi4_EVpG8snw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661664265439?e=2147483647&v=beta&t=_qjrAaEtnUd2x7Z6Y2LTmjztf5teTS6eIA4EEl4icvg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Benjamin',
        lastName: 'Harness',
        emails: ['bharness@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHRsTYH7D-rtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636147084441?e=2147483647&v=beta&t=IobEF2ObatNKRwwNSuXhGH14MIK4ONoBxIBRN-W39tw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dgicommunications: {
    name: 'DGI Communications',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael Walsh,',
        lastName: 'CTS',
        emails: ['mcts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE3jHIBMC3Y5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687880262672?e=2147483647&v=beta&t=iXxeRWd80w_SOrPY7IJVazuHsBy-VwwnjDcKXhSAdgs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jim',
        lastName: 'Burke',
        emails: ['jburke@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alvaro Yurrita.',
        lastName: 'CTS',
        emails: ['acts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHW7e_CuIaAug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699465100729?e=2147483647&v=beta&t=QlJipCdX6jgOTQM9Uto2la2mbIOb_Nb_kX2GZdz6L40',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'Rue',
        emails: ['mrue@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHCHs_UseDgaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700069390068?e=2147483647&v=beta&t=Y8rt1jFqd9swqeYkeNt45vj3NiGL0RoupHwt7Ms84zg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Trisha',
        lastName: 'Aubin',
        emails: ['taubin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG0oYKGPj2lfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701913740343?e=2147483647&v=beta&t=VaMQmkG-ONHIs9MrDJjFzO4o5fnE7talmtI0S-dzr1A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dcidatacenters: {
    name: 'DCI Data Centers',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Heffernan',
        emails: ['cheffernan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGa0MwX08G2SQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647558958743?e=2147483647&v=beta&t=i-lJfOg7I0okXvztHmDIdzvB-gVgKgdZuE7awcZMHiU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Swati',
        lastName: 'Goyal',
        emails: ['sgoyal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGWyodG9YpTrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645069755250?e=2147483647&v=beta&t=la7RVwaQ-PDCsDEOQlHxtOquh8QJTxu-PeQ7I4QhABI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shane',
        lastName: 'Ross',
        emails: ['sross@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH9TgAeXD7iGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724913070522?e=2147483647&v=beta&t=DIozuHuH41kbSQ_jmBt5EC2L2_mJ7pTbhDspxaoKd2g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Karina',
        lastName: 'Tang',
        emails: ['ktang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEsREh10Y9Wtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692618077920?e=2147483647&v=beta&t=V7q4JvISGxUQjWWIa-slqGdctM8CzZR5hKryyrUAuBA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Navid',
        lastName: 'Sumar',
        emails: ['nsumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHfg36mvZnfAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1524547020176?e=2147483647&v=beta&t=NH1mt4aLXNI4PNH6yqDGJccfXWqzIxeUrPFmUeLsDw8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  alta: {
    name: 'Alta Media',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ben',
        lastName: 'Trevizo',
        emails: ['btrevizo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHx-b7jq3gebQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675907209824?e=2147483647&v=beta&t=K8nI_7UyxO4bHFRBkTVVTJXdqGfvRuuEt1bJxdY6Bq4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Peter',
        lastName: 'Iliopoulos',
        emails: ['piliopoulos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFfaKHdMebbMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690838433848?e=2147483647&v=beta&t=o5F8nBvXMR9NISMKclMO7bJ-qVVK1aAHobMt92wTBfE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sofia',
        lastName: 'Longo',
        emails: ['slongo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHGNMB-dWJzpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691562866874?e=2147483647&v=beta&t=tcDFmqiRUEKWqk2Pksc3i-BsVCINYaHJWbxZ11j3yJg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Concetta',
        lastName: 'Cougras',
        emails: ['ccougras@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHCrduNHB890w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697145080888?e=2147483647&v=beta&t=sEQiE5PaiqN5idnCZEJ55cTjAgMy3JBxZFYRca3QdSw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kate',
        lastName: 'Wirta',
        emails: ['kwirta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFkpYAZakKQpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687748774222?e=2147483647&v=beta&t=yFDf1cwIXkGCaRBEYtfVuhlz1Ew33rWntkv7yfjUcKo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  softcom: {
    name: 'Softcom',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ravi',
        lastName: '(RK)',
        emails: ['r(rk)@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Srinivas',
        lastName: 'Gujja',
        emails: ['sgujja@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFBLpCNuPFrQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710955452927?e=2147483647&v=beta&t=9ex8YEFbBWtCBbDYv_HPA9v5TPzHiWkwl3JQYdr9Fwk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sridhar',
        lastName: 'Goud',
        emails: ['sgoud@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shashidhar',
        lastName: 'Goli',
        emails: ['sgoli@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mohammad',
        lastName: 'Azharuddin',
        emails: ['mazharuddin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFew0oNKaVHqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713990152930?e=2147483647&v=beta&t=VGcCtq-JtdY_K8RY94-AKXq6FK1zMwPqcZXAOcTPpsw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  beacontechinc: {
    name: 'Beacon Technologies Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Keegan',
        lastName: "O'Brien",
        emails: ["ko'brien@example.com"],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rebecca',
        lastName: 'Rosenthal',
        emails: ['rrosenthal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH0pNVF6C8tQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517581922523?e=2147483647&v=beta&t=suCyaRa7nssyTja-hwg5sxlHAtFG4BjBIjximtkWODA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Craig',
        lastName: 'Wagner',
        emails: ['cwagner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGEumEQT44RBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729097084148?e=2147483647&v=beta&t=uCuap6JykYeIEF1qcHZZrdlawRY7iY-eegS_jUCXOHk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ernie',
        lastName: 'Crawford',
        emails: ['ecrawford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHm4A4LsHWM0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517597825739?e=2147483647&v=beta&t=A1KdTzlD0X1RdjwOXyN9Lr_u_c4B0XM0Poi_T5Gxw_g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Waite',
        emails: ['dwaite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHnJLp6CtVLGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517408191819?e=2147483647&v=beta&t=Sbn-f09xX_MiRk9yyICSnqjMiWNM-NvSkG6mJo1sdks',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  brandRex: {
    name: 'Leviton Network Solutions Europe',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rebecca',
        lastName: 'Smith',
        emails: ['rsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFAHRufimEhwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711972088772?e=2147483647&v=beta&t=yLMpOuss9EtTi4d-xnq3YVvCLjKnojcDRMxQz4qs7Ok',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kennedy',
        lastName: 'Miller',
        emails: ['kmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEQdGwLXARz9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721234565659?e=2147483647&v=beta&t=epqzfgxVmmLC4IoKKG5gWZL6puKrOiRDiBuiyOC6iwA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eddie',
        lastName: 'Mcginley',
        emails: ['emcginley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGrvl1FRZlF2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516682918919?e=2147483647&v=beta&t=JkRnJ0wvkMxPhb_kHzBvjcXn0nAaOpWDvQSndbGmmRA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Lawrence',
        emails: ['mlawrence@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHO_ys8D3b5ew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617493563538?e=2147483647&v=beta&t=MqO7lVzIG44lzn1j49NtYtOWZSfLnhDzwQewBxKsphg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shibu',
        lastName: 'Pathrose',
        emails: ['spathrose@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFYBQh_WOcamw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665257999892?e=2147483647&v=beta&t=AHznzNUhWggfDrVpzpRSP5k-BPbAKtkQLMiCJLkFdjU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cyberhull: {
    name: 'CyberHULL',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Roberts',
        emails: ['kroberts@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kevin J',
        lastName: 'Roberts',
        emails: ['kroberts@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alexander',
        lastName: 'Rakov',
        emails: ['arakov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQENkasfr43P4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601036654320?e=2147483647&v=beta&t=R0Moo2Y0gKjlSJsJMCn_AHqmVOKIeUHIcnoQVn1opYQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexander',
        lastName: 'Kaigorodov',
        emails: ['akaigorodov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHYBPpbybS74w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666886702587?e=2147483647&v=beta&t=uNAPOXm8LzV2UEjWtIpGemLqassCPios_M6aA5xS6eU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Konstantin',
        lastName: 'Gerasimov',
        emails: ['kgerasimov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFuYUrv-42xKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516891967183?e=2147483647&v=beta&t=Sqzp_m_qefHHY2RZX_JMI3lo1A01Psv2ZDsuTPNHf7g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  athenaGs: {
    name: 'ESET France',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Julien',
        lastName: 'JEAN',
        emails: ['jjean@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEmumqMlcZHgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638355579079?e=2147483647&v=beta&t=sqQ70NTWqZ2-RHiYm3SO4hiDayM7c6LBHYX-VXRE2F0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Athena Global Systems |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Laëtitia',
        lastName: 'BONNOT',
        emails: ['lbonnot@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHSF_jEf-C2IA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608034010349?e=2147483647&v=beta&t=_0PNoWD90Zm9aMoSaErzI-N5y4EuwuIMO3XLzeabciA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Houda',
        lastName: 'BenHmida',
        emails: ['hbenhmida@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Robert',
        lastName: 'Shambora',
        emails: ['rshambora@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEZHdXTmT2VaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517449976673?e=2147483647&v=beta&t=juO8pBYwUYiNSqDWQNqrBNCWlANpwmqAv0QdKEYIhAY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  casimbagaming: {
    name: 'Casimba Gaming',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dersim',
        lastName: 'Sylwan',
        emails: ['dsylwan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sara',
        lastName: 'Lohjelm',
        emails: ['slohjelm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEgVG1ostJo6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730573999568?e=2147483647&v=beta&t=mQw-8SkwaRymkKJ4zNRsov-5aNSV3MUkYUW3lOU9NOc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Venkata Srikanth',
        lastName: 'Manuri',
        emails: ['vmanuri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEFMtA_hMlJjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516625175883?e=2147483647&v=beta&t=z77c7ytdWrYmSpzOOYyrK6GhihqcHQfKcpYzztA2wDY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eve',
        lastName: 'Bezzina',
        emails: ['ebezzina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHFpHkxCQTarw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516515085325?e=2147483647&v=beta&t=JMnIwlp6_UyDgyv76jw8ruAF8RBhMuDt_lFgoMks8vo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Megan',
        lastName: 'Sponneck',
        emails: ['msponneck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFBSaSAbA7VAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623519396080?e=2147483647&v=beta&t=FmTy5aju5ICBdqiWLn2Wc7eEXW0usMybjLC0arZsr3g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  verticalag: {
    name: 'Infinit-I Workforce Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jay',
        lastName: 'Wommack',
        emails: ['jwommack@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Randy',
        lastName: 'Mitchell',
        emails: ['rmitchell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFgVN5lAbqCWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1545716930022?e=2147483647&v=beta&t=eMUCjqDxRW2HhRjbxIV9Qr8AxBRzzUN2JnApM60dwSI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Judd',
        lastName: 'Wommack',
        emails: ['jwommack@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHAWln_abhafA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678376170498?e=2147483647&v=beta&t=VVNCliaSYE3gomp3qbew_KV6ShwUBCA8h7bkZOZblS8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike Gambrell,',
        lastName: 'MA',
        emails: ['mma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEdJCV7dGVXhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563476431352?e=2147483647&v=beta&t=5zUFy93ABIpp7eW9UrZBNn3zoSbalauctHL9935U9W4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lisa',
        lastName: 'Rourk',
        emails: ['lrourk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHcYfAJ-rxsyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728678789644?e=2147483647&v=beta&t=Xj0ABo-hUvS0OLm3ILy9uDMUyEmmU939d7El1CpvMQ0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  accordtecinc: {
    name: 'Accord Technologies Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Minton',
        emails: ['cminton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHQOa8kHK_sWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619098821187?e=2147483647&v=beta&t=cO3PrhI5px0XRLebZoLBD1QI05tbXWS6drTTq4RMMPY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rick',
        lastName: 'Daniel',
        emails: ['rdaniel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lori',
        lastName: 'Leyendecker',
        emails: ['lleyendecker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHcuekvl5n0gw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517241724103?e=2147483647&v=beta&t=G9300w53Gt95dvG5kDN8nR3gdeUk76Su4M2NKEqvQHU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vinoothna',
        lastName: 'Aleti',
        emails: ['valeti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH6ywZnT3cBwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722884225589?e=2147483647&v=beta&t=icggYnzgvwJr-Ue_RBVlDF2KkQ3hBxXkYhw2RT14uXU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jyothi',
        lastName: 'Kandukuri',
        emails: ['jkandukuri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGbQGMXIEHs8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711656809345?e=2147483647&v=beta&t=EU0tztSfMXQNG4TiqGcuit-xLR7aOU-q88HlVuHKFv4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  optimizerx: {
    name: 'OptimizeRx',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stephen',
        lastName: 'Silvestro',
        emails: ['ssilvestro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFJCM1IUug66g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1520528615905?e=2147483647&v=beta&t=4YUUarUHdR7RW06KSU4pAcnR0-xyNg5R95_vllzD6bg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: "Lynn O'Connor",
        lastName: 'Vos',
        emails: ['lvos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEdtMw_Nu21_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607521191340?e=2147483647&v=beta&t=Q4NuOewlEQ3UBUT9eOfQ3P7z5RZSnFbj5hlonAiKhQE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'Connors',
        emails: ['mconnors@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Todd',
        lastName: 'Inman',
        emails: ['tinman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Marino',
        emails: ['dmarino@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  itential: {
    name: 'Itential',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ian',
        lastName: 'Bresnahan',
        emails: ['ibresnahan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Wade',
        emails: ['cwade@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Peter',
        lastName: 'Sprygada',
        emails: ['psprygada@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFV8l5r0w36WA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568995989030?e=2147483647&v=beta&t=sJdbHn-u7T-JPkF-44hRx3K6MftKr1uOr0j3mRarXIU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sam',
        lastName: 'Wysocki',
        emails: ['swysocki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGb8etDgBg-TQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697729626232?e=2147483647&v=beta&t=wBhX_72tBHDx7UxiPh4f-lXgG52X3QOnbWBCM-MW-ow',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kristen H.',
        lastName: 'Rachels',
        emails: ['krachels@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHtvJI9z4QLdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621307133916?e=2147483647&v=beta&t=5hox_5WehQXw9rzdjUyALNzYzcQkgih-1LH_7W6WKyg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  prtable: {
    name: 'PR Table Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Allison',
        lastName: 'Stokes',
        emails: ['astokes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQERfptAnfsFyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517686144762?e=2147483647&v=beta&t=G065X7KoydZYdoVDvqqcQYzWwx79Af0gleDFGLuzFuc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ted',
        lastName: 'Weismann',
        emails: ['tweismann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF2Sh2RXUoYyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574685827132?e=2147483647&v=beta&t=-4pslJF7W7Trw1CQqYo3AjLLiKxtHuODsK6Zg3EJ5Do',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tony',
        lastName: 'Loftis',
        emails: ['tloftis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEbHHRLd_UkRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579875007913?e=2147483647&v=beta&t=0UK5WxI_kJb389aqs1rmu3bBP-4wYL5sf8PUm7lOICg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexandra',
        lastName: 'Wolf',
        emails: ['awolf@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFVDNxrNwJnDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629204120531?e=2147483647&v=beta&t=V8M9VhCN04HlO51H_bgOnuOKYP-Smn4gVd_DXYj-7V8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dan',
        lastName: 'Gaffney',
        emails: ['dgaffney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vizocom: {
    name: 'Vizocom',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dennis',
        lastName: 'Marco',
        emails: ['dmarco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHzwjIn7PrkaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633474796527?e=2147483647&v=beta&t=X1UzA_1nIhdqjnqIQh1jxrkHgh_kE-GZG0HmSUpHhys',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mary',
        lastName: 'Harrison',
        emails: ['mharrison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH2kUNnNkBQYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517496836930?e=2147483647&v=beta&t=KWms4d5DUAoNpC-wJ__pobrte8pxB-MRKESz15qdNXI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kevin',
        lastName: 'Avila',
        emails: ['kavila@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFWpHees10VEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516623187349?e=2147483647&v=beta&t=ZJcAhUYuSl1ZyVYCtvPjdyVteDUVUypLEgb_LURvyQw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joseph',
        lastName: 'Deepak',
        emails: ['jdeepak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEFjo_OkML9Cw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517503637176?e=2147483647&v=beta&t=pOuZmEs3Yye3clq22v05AWCGS2x63EUleZ93WTF-5Ko',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Raymond',
        lastName: 'Ramsey',
        emails: ['rramsey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  itSpac: {
    name: 'SPAC Information Technology Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ikala: {
    name: 'iKala',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aspiresoftserv: {
    name: 'Aspire SoftServ',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Purav',
        lastName: 'Gandhi',
        emails: ['pgandhi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hitesh',
        lastName: 'Vagh',
        emails: ['hvagh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pratik',
        lastName: 'Patel',
        emails: ['ppatel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nishita',
        lastName: 'Beriwala',
        emails: ['nberiwala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHFGErBXkMN7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711039761720?e=2147483647&v=beta&t=w5liVVM85qfkOHoW2yzjN7ZpvHkTP8SPtI79FPEnBfM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lokendra',
        lastName: 'Yadav',
        emails: ['lyadav@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGOAE6cF5o3kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679391751760?e=2147483647&v=beta&t=GbgJWAoX_mKtabmVtG6R0_h2zjImhDiqXFt_dJb1r1Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  junifeup: {
    name: 'JuniFEUP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rita',
        lastName: 'Pereira',
        emails: ['rpereira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGzr8eMNHOahg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729455858895?e=2147483647&v=beta&t=VGL0ATxIaPYUAN9NcKe8tPgl5FYpIkl87QemnFou1Yo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maria Avides',
        lastName: 'Moreira',
        emails: ['mmoreira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHZE1EHJaC7VQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698236016667?e=2147483647&v=beta&t=lSRS_MBkInmN3ohoXUjNWUEk5Hg6KbFZblIfo0xUV6c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Inês',
        lastName: 'Gutierres',
        emails: ['igutierres@example.com'],
        image:
          'https://media.licdn.com/dms/image/D4D03AQE9FY91REVs2Q/profile-displayphoto-shrink_200_200/0/1677260278887?e=2147483647&v=beta&t=RTqOPIByqhfujeIgbFvnPpurJA917uTEUrFY6FbFObo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Francisco Pimentel',
        lastName: 'Serra',
        emails: ['fserra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEyHSEcdgWspw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697494396328?e=2147483647&v=beta&t=ceQTnmxmzq_P_f_LKpGZPDQ_C2KnwJUt7084KmDKIss',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daniel',
        lastName: 'Rebelo',
        emails: ['drebelo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH6b51TJPkl5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719016508565?e=2147483647&v=beta&t=3xv1woxLfM_gC_-mjneRtKwlmeToJu67eUD2Kb0DNGw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pcstalent: {
    name: 'Pacific Consultancy Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'L.',
        emails: ['jl.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Prasad',
        lastName: 'Tataverty',
        emails: ['ptataverty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG8W_JrvSid4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706367503362?e=2147483647&v=beta&t=7NsR4zzmxFU3GXOC8YncA9txFzdIwiV6FEh3mLfTm7Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sravan Kumar',
        lastName: 'Mankala',
        emails: ['smankala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFbc2Di0ln-Dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718226470658?e=2147483647&v=beta&t=lWs6N8Bnm8P1vBn_P9R0n-bWXQjsIMB6xVREHfx3UMw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'SRAVAN',
        lastName: 'KUMAR',
        emails: ['skumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHpe0e_cDHDpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726472785859?e=2147483647&v=beta&t=YY8NJGi98uZBL68jZc_GPCLJLEWbLIG3GumYrv5YsxU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elena Daniela',
        lastName: 'Candescu',
        emails: ['ecandescu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE_iW-VTYcMPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517531568033?e=2147483647&v=beta&t=aM5S_DyOYZev9SiwUHlxvBy4ElPSarJ31o9MzEY4CHA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sequentur: {
    name: 'Sequentur',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Holly',
        lastName: 'Haggerty',
        emails: ['hhaggerty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFCYdlvJi4oIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516556731188?e=2147483647&v=beta&t=L1fkIbhuFE2Pl2pz3f-dyLPDF02mj6E5jJxd4SRN-J4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Christopher',
        lastName: 'Capps',
        emails: ['ccapps@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brian',
        lastName: 'Marcucio',
        emails: ['bmarcucio@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Deb',
        lastName: 'Wiker',
        emails: ['dwiker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGDrPcK4mZTHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557228284353?e=2147483647&v=beta&t=iEEmt8oaUtq_zj3Vyrqbb-NYY59XJwOUFsBtM1SjjqE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Laura',
        lastName: 'Gruzdev',
        emails: ['lgruzdev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFsPzpKX-mudQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633127438841?e=2147483647&v=beta&t=pMmRWUN9B83jSDnZ-oVlaPFBly-473CVp-blnS-psyM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  triagePartners: {
    name: 'Triage Partners, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stephen Bokas | Director of Field',
        lastName: 'Services',
        emails: ['sservices@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brenda',
        lastName: 'Stewart',
        emails: ['bstewart@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'James',
        lastName: 'Han',
        emails: ['jhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGT_1TU42TyRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727017295644?e=2147483647&v=beta&t=7IytYMiCjevciR9puHSU1Ozwofx_RvMJj2ahtiBUDrQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joe',
        lastName: 'Cochran',
        emails: ['jcochran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF-Yc3iV_LGyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670356217671?e=2147483647&v=beta&t=JtvIPGBcg-0MsvnHggbuR8bLSUtLS5We4j5IBkEa3zM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dennis',
        lastName: 'Ayo',
        emails: ['dayo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pangeaIt: {
    name: 'Pangea',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rafi',
        lastName: 'Kaminer',
        emails: ['rkaminer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH-KjoTyptQjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682055363957?e=2147483647&v=beta&t=nva4duLXEpyrju0m37HncJHVI3kK0pQfutKB9uCB1xc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alex',
        lastName: 'Brodsky',
        emails: ['abrodsky@example.com'],
        image:
          'https://media.licdn.com/dms/image/C4E03AQHx-wVCOgWk2g/profile-displayphoto-shrink_200_200/0/1516159722191?e=2147483647&v=beta&t=JEOlRDGgKO3B46JEaWnsiT2zd09RUbPny3D3LinWaBg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Uzy',
        lastName: 'Rozenthal',
        emails: ['urozenthal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEDj4fOQO1otA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682229767863?e=2147483647&v=beta&t=tBsVDFrKX1girYYoP41LAmKgvJUw4ZqZdXRgVsiTxOc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shai',
        lastName: 'Yacobi',
        emails: ['syacobi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE8iAfBwiqsOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517592405785?e=2147483647&v=beta&t=lUrpqKmVOPdzf2mFIMF5peu8EJZK_eEbYDJoWCKz6cI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Reichfeld,',
        lastName: 'Ronen,',
        emails: ['rronen,@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGM04q2VkDfXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726623090689?e=2147483647&v=beta&t=cDf5e27jo7cvXzblromsIk4RS8gBw-rE8NzgYjKA36E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  alterset: {
    name: 'Alterset',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Milan',
        lastName: 'Maksimovic',
        emails: ['mmaksimovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEkRh5loEUtYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516607196309?e=2147483647&v=beta&t=SKvEyajdb678SQjOfIkFHwPO6vFYLX15BkNKaRqj3hA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Igor',
        lastName: 'Kovacevic',
        emails: ['ikovacevic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGMyHpgrbAr8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517703030536?e=2147483647&v=beta&t=5fK3AJssH8K5AXHnvtC7sWntCJVDZfHvvRWYZK0Tt9M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Milos',
        lastName: 'Mladenovic',
        emails: ['mmladenovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGfNqL6f5ctOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674084489835?e=2147483647&v=beta&t=CdAnlnRXC43kh6mvPZfo1gUNXHgEsMu_p5raRg6orqg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tamara',
        lastName: 'Mirković',
        emails: ['tmirković@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Frank',
        lastName: 'Dijkstra',
        emails: ['fdijkstra@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  agiledirective: {
    name: 'Agile Directive',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Travis',
        lastName: 'Reep',
        emails: ['treep@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHqThtNlWEyHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550517404941?e=2147483647&v=beta&t=l3gsKauGp9cB8g2QcHil_vQiFx_WEE89GMGsSZ0dxcw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jennifer',
        lastName: 'Snure',
        emails: ['jsnure@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGUqRucrll8Pg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517433819775?e=2147483647&v=beta&t=E1zmLt3Mp-yudyF03pXNN1m7d4sW_JkxHqIZiVJAfA4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ludmilla',
        lastName: 'Shain',
        emails: ['lshain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGwZpeE8HdzJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678744963055?e=2147483647&v=beta&t=hO8x_fjq_IqcabzFuYwMnrF3oIRWzFiHsDVkIPZqDD4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chad',
        lastName: 'Johnson',
        emails: ['cjohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Niranjan',
        lastName: 'V',
        emails: ['nv@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEe0IQ1aeyPmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607268423388?e=2147483647&v=beta&t=ZWDdXYb35XrXJFnQU-KbskxcswG4p2Xp_VHku8o1QUk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  softwarepoint: {
    name: 'Software Point - LabVantage Nordics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wayne',
        lastName: 'Harris',
        emails: ['wharris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFqCC_FuETrxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594661525814?e=2147483647&v=beta&t=WLKy4lMOCsb1YCqOKhlWeVtMxeHtiZumai7m3QeWJHY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rupal Shah',
        lastName: 'Hollenbeck',
        emails: ['rhollenbeck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEgtrgGn8semw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706796171881?e=2147483647&v=beta&t=Hj4zfzLNNosmPDDc-1wrhN2hej78076vOxG_bbLoRog',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Liz',
        lastName: 'W.',
        emails: ['lw.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHbBTS2tliVFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547154193087?e=2147483647&v=beta&t=nNEPkvrVp1Evfz-MG4xJQDPc0kn3l96U09UQlqaRD04',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jason',
        lastName: 'Prost',
        emails: ['jprost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEN_NP9vOTbZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721322685522?e=2147483647&v=beta&t=Z3_fYngdYXxBL4yn-m7Id2ZNKToAdIwAvLp53YoVZNU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pete',
        lastName: 'Nicoletti',
        emails: ['pnicoletti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFrQKNHf6NiZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628540371919?e=2147483647&v=beta&t=SrMQDsVEppbpz1lXMt7HjsY9eXtFdP3Ko1HYTykl_ec',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  performit: {
    name: 'Perform IT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stanislav',
        lastName: 'Ivanov',
        emails: ['sivanov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF-HKxuirwC7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582371843089?e=2147483647&v=beta&t=KpGtOVMRPZ_J0yAPz9dcDkuvcNhHkNb8gpPGyd-xkho',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David Mano',
        lastName: 'Loth',
        emails: ['dloth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF2SsP4SFuaIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598266505843?e=2147483647&v=beta&t=vCGXJLsAdThNgVtUgPJqlMeosI6YT0F7pN9EGZPbJe8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Emil',
        lastName: 'Širić',
        emails: ['eširić@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE-9EZjJsoahw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1538919258616?e=2147483647&v=beta&t=ldb4ierdc2mBJWS443SB7sYqKl3lnYEl5yGqB55g5U8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Emily Elizabeth',
        lastName: 'Jones',
        emails: ['ejones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHLTi0evwNw-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623022315695?e=2147483647&v=beta&t=ottLUE40f_sThltWK7AcbXAMqG2q33zHJ8Xcepvfrt8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Karl',
        lastName: 'Brenner',
        emails: ['kbrenner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFOR9BygzWaZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567576776151?e=2147483647&v=beta&t=Z0j8pJZbQV2E2f0p4PPnHnTkTwdRQR23n52rD8jhd0E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ecotrak: {
    name: 'Ecotrak Facility Management Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'Singer',
        emails: ['msinger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHQsm0SVJJl_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635799689132?e=2147483647&v=beta&t=Da-n_oZsgs5aUW4MsxmYRkb7mzBlI4Cvans__Jn3aDo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeannie',
        lastName: 'Malone',
        emails: ['jmalone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFfYQs69J2tzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516611843045?e=2147483647&v=beta&t=eC6ra2wydJPSC1l366Xd83d12sZF9Mn-SI6KMxUS9YQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Danielle',
        lastName: 'Nolan',
        emails: ['dnolan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGoauGbQ7DaNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607367388409?e=2147483647&v=beta&t=YldFxtZ8P28XLg82_StF3GQSfyliaIU-EDKqom5YOLc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dillon',
        lastName: 'Gibson',
        emails: ['dgibson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFGEYf1IXXyNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582653220389?e=2147483647&v=beta&t=UqXB86Tur9XAvwqsLwlI9ZVyi8Dw5SCDJg4-hrhRdU4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shawna',
        lastName: 'Moore',
        emails: ['smoore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEA14pK-Lfwog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652205376388?e=2147483647&v=beta&t=G9o4rwsy9XEpeKtstTjNgwzdfRrLFufm-1qvh2SoyzQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lightelligence: {
    name: 'Lightelligence',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Yichen',
        lastName: 'Shen',
        emails: ['yshen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGKv0akvKwkbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1534389394917?e=2147483647&v=beta&t=BJHQPLSejXImuGKNb6XNK8RELoV-1WkkDb2NIhBt7PI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Huaiyu',
        lastName: 'Meng',
        emails: ['hmeng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG8oOC3MaJZyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516497623643?e=2147483647&v=beta&t=8oJ2x-KmVtHw27W5_pqjjOEKCw4LPcDfJ2QD5d5ZN9E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Myers',
        emails: ['smyers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH80hSeu42nMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721140034752?e=2147483647&v=beta&t=mjx85ChzpyWDLgU1XDzPBK3i1RLfvcU3qnSHUCSO1io',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephen',
        lastName: 'Thompson',
        emails: ['sthompson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGZt6tEZYd1NA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601153989583?e=2147483647&v=beta&t=x2IzQM97l51ipVFNPxluBxDxQcPD9HdZzbzbyTL9EIc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ron',
        lastName: 'Swartzentruber',
        emails: ['rswartzentruber@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  survalent: {
    name: 'Survalent',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Young',
        lastName: 'Ngo',
        emails: ['yngo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mahesh',
        lastName: 'V.',
        emails: ['mv.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Roehl',
        lastName: 'Deduro',
        emails: ['rdeduro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQElKZZ4eHucDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516457930682?e=2147483647&v=beta&t=wqq2w-tGDe9NeNyj37fSLxwyCw_lyEl3oOFTB6zkjFg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ian MacCuaig, PMP,',
        lastName: 'CISSP',
        emails: ['icissp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yefim',
        lastName: 'Vinnikov',
        emails: ['yvinnikov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  localhost: {
    name: 'localhost.company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Roman',
        lastName: 'Denysov',
        emails: ['rdenysov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHPLLfz42a3kQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698157401773?e=2147483647&v=beta&t=cq_BHO4ES5rTxJ-fn9ecIrKlWArrksLeq9BcuUPJ_Fg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Peter',
        lastName: 'Vanyo',
        emails: ['pvanyo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG7Sm8SPT0ijg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638263499433?e=2147483647&v=beta&t=3Zl5MR1grTYyzBqnkpLB8GrF6z4Hm9cCotYbMYekdUQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jay',
        lastName: 'Shankarpure',
        emails: ['jshankarpure@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFIqI8VKOvaEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723800964460?e=2147483647&v=beta&t=vo2J2BEUzLytKwvG3RBqyBL9Iq2uUvqrBZoefiM6A2A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Miroslav',
        lastName: 'Ivanicko',
        emails: ['mivanicko@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Martin',
        lastName: 'Heinz',
        emails: ['mheinz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHAEFDG8r2C9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1525346875367?e=2147483647&v=beta&t=-fMoaUeWmFQE8TM-4fSJX_hMOVgY9uzDc2lA9Id1roQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  indigiconsulting: {
    name: 'Indigi Consulting and Solutions - Technology Leader of NorthEast India',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'lakhya',
        lastName: 'baruah',
        emails: ['lbaruah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHjjPpZiypJmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669378319646?e=2147483647&v=beta&t=87zNErF3M9GoKQ6DWrftcTR48vqSawHA63k1z2ZvMK0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aliviya',
        lastName: 'Talukdar',
        emails: ['atalukdar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGFelJBl6qF2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580122403691?e=2147483647&v=beta&t=CFV6z2y5Lw2B__6cEilEJIechfSqt3_-hO-qw2C0cZk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Madhurjya',
        lastName: 'Borgohain',
        emails: ['mborgohain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEWyX7-DhDsJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663330760027?e=2147483647&v=beta&t=fkN1giswwasjyiGxJAxqvg3VB6Ikm4Gql4BOm8iCkyA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pranjal',
        lastName: 'Baishya',
        emails: ['pbaishya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF_oBC49G8QdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720352327680?e=2147483647&v=beta&t=Tx5ynVa3ecGtUjPgPxlCGu_Pi_Von02JvFBTOap6E1I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Swarnav',
        lastName: 'Dutta',
        emails: ['sdutta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHsdDuSihcZWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516443936762?e=2147483647&v=beta&t=Kp0pjA1N3pIRj9mBcXJPK1zQyolNOi7AQiUejEE9EiE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  securedata: {
    name: 'SecureData',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric',
        lastName: 'Dvorin',
        emails: ['edvorin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHC1hWtyvksRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517060207333?e=2147483647&v=beta&t=L8zy_3AIuXuPt1dYz0qxM12XmxDq_RX21bP_uLZV0l0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Julia Keating, PMI,',
        lastName: 'ECA',
        emails: ['jeca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHaywcNL9k3MQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629660229740?e=2147483647&v=beta&t=i4088XVefpl6dzUL2K-mV7bkBQVX0Xv8GamhX2A0oOY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeff',
        lastName: 'Young',
        emails: ['jyoung@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aaron',
        lastName: 'Broyles',
        emails: ['abroyles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEQCN9EhUxuag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516271087320?e=2147483647&v=beta&t=3jJgr2SzVro2H7D7wCbkf5ADIE1yWt0WDucq4RIEu5Y',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dewald',
        lastName: 'Mulder',
        emails: ['dmulder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFRxk8FO_NUyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556286082701?e=2147483647&v=beta&t=k_eVekmVmlelQtMAdhLd5GcUSruIcPPPQpCQfQIGDUs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  keyivr: {
    name: 'Key IVR',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Darren',
        lastName: 'Wooding',
        emails: ['dwooding@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHPYLxjgjO9fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631000641673?e=2147483647&v=beta&t=pCP_pZKadbn1gVjknMSF2hjPynxuueAf1GaiJeOzGZo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Kelly',
        emails: ['mkelly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFyl2aoeONQSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720184001776?e=2147483647&v=beta&t=WT3-v7KiQute2ZJW2OI6M1yCsX9aMATNSDzXGE3IpX4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anna',
        lastName: 'Skirka',
        emails: ['askirka@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH7kmYyM83NmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575021997901?e=2147483647&v=beta&t=vMSgIgxXRd9oViYJPRMSmvntmKt1usP8DfqKq1FrLUU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cristina',
        lastName: 'Gupca',
        emails: ['cgupca@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Martin',
        lastName: 'Lines',
        emails: ['mlines@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG-MR7RRuroSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690403395466?e=2147483647&v=beta&t=cYBZ409gOk2cA6USdSOqmH-hbis72oCQuMVBNJ97R6o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  quonext: {
    name: 'CEI Europe',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sebastia Vidal',
        lastName: 'Nicolau',
        emails: ['snicolau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHd3sW6Kwwiig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684440830146?e=2147483647&v=beta&t=WIv7uo_foO51kIwHgygUrMfqbAwqxoPuBaGGTwoAACE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Salvador Mas',
        lastName: 'Portales',
        emails: ['sportales@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEbSqFFTi3Brw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706115478059?e=2147483647&v=beta&t=A60qJ_OV8ss6OeNpI2wyhjWhZtyjRzBmbWDqIK9415c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alberto Lamas',
        lastName: 'Ferrer',
        emails: ['aferrer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'PEDRO GABRIEL CORTES',
        lastName: 'ALVAREZ',
        emails: ['palvarez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHu-YPUrGvI3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600732065458?e=2147483647&v=beta&t=Rs0X4Fuzsgpgkz0DtXytbyeQtT2WgL5BY4_tIzFEpAY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daniel Contreras',
        lastName: 'Cruzado',
        emails: ['dcruzado@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH5-HO7SmsNuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516944876909?e=2147483647&v=beta&t=0n_L-WU5klhilt3jpK94Qubkn8-3siklsYMybAeqacc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  beansbit: {
    name: 'BeansBit Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Neha',
        lastName: 'Singh',
        emails: ['nsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHmoUca1Q2sYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583314541027?e=2147483647&v=beta&t=oDbN2nCH4iZ__3HYJ4X7adgdjjfigsBT0w3v4z-YKcc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ganga',
        lastName: 'devi',
        emails: ['gdevi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHNcjf9Z5RoVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651115527532?e=2147483647&v=beta&t=H88KS7hziQPHYG-898OS6rBhDMF37QoTj9Usevjh_tw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sneha',
        lastName: 'Shinde',
        emails: ['sshinde@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chaitali',
        lastName: 'Prasad',
        emails: ['cprasad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHpOfkZ_0x4DA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686319853476?e=2147483647&v=beta&t=7hLdIyT6ksD3Qmv40CHUyGIdd34UTSnf8JrL_e1XmpI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Deepali',
        lastName: 'Kaushik',
        emails: ['dkaushik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEizxfMG6C9KQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721294669266?e=2147483647&v=beta&t=H3xwy3k5Jlcz8VurtMoWN_MovXC0uCJbRV28im_SCkg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  calculusbusiness: {
    name: 'Calculus',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'AKINKUNMI',
        lastName: 'OLAWOYIN',
        emails: ['aolawoyin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName:
          'Nikos Efthymiadis – PHD Candidate – Visual Recognition Group',
        lastName: '...',
        emails: ['n...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFI_A0SbdsdPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701473391277?e=2147483647&v=beta&t=VaG5CO9b-r3sR01Kk0MupI2qyTy8gcXn9EM3CTxlVQg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pamela',
        lastName: 'Hernandez',
        emails: ['phernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGV1YhIkt-u9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724088217962?e=2147483647&v=beta&t=kwc-JDUsdvPJc-j-dSdULqUTlsqOh19Oa4OFmmxMy3g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vasiliki',
        lastName: 'Kelaidi',
        emails: ['vkelaidi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEwnllvEXbmBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657537959981?e=2147483647&v=beta&t=_IbbxblS3SZpubJNFz9NmgI_x3su4qiJoxQk5sX3CGg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Syed Shabbar Raza',
        lastName: 'Zaidi',
        emails: ['szaidi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG-kAQ60pg9XQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671896274272?e=2147483647&v=beta&t=QlhjDCAQj9cgRzm2A9M4AqtPRmypyw9VrBGwzW_IsxA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  newzoo: {
    name: 'Newzoo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mason',
        lastName: 'New',
        emails: ['mnew@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGoKydm_Iv5eA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630433081237?e=2147483647&v=beta&t=EWVcayJj4As1zTtjiwVxqx9J0mpEa2x7WAciQ1fFog8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emma',
        lastName: 'Goldberg',
        emails: ['egoldberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFvoi9wVSwPTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574697775878?e=2147483647&v=beta&t=oCskRvMnV8YVESv7YUp3Q2lxtPb1eSBXMFrfG_tBquM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erik',
        lastName: 'Vance',
        emails: ['evance@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH2ome8nT4BjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667436878721?e=2147483647&v=beta&t=r8JL79JYrrfHU_2udiI-i5LQ04qIzRZv_1fv7Jxg5pU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eric',
        lastName: 'Kim',
        emails: ['ekim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEsLu5VRCn_hg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693456618359?e=2147483647&v=beta&t=jzexFEYxiESVo-PqUzswhxNR9PgPreqzfpXwEhBZLiw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Katie',
        lastName: 'Rogers',
        emails: ['krogers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEs6chG48g7tg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699710733686?e=2147483647&v=beta&t=XUde5v-L2WjY9MbZxgQJE-5gosNly-Ih5qEVLHlLwus',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  elevi: {
    name: 'ELEVI Associates',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Phyllis',
        lastName: 'Puccio',
        emails: ['ppuccio@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jim',
        lastName: 'Reiley',
        emails: ['jreiley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFuxo52pXfp4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516941853207?e=2147483647&v=beta&t=ZsK65J0bs6P5JKZY1-GRTG5xjXGIEhOiq5TqvL1Jjdc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Navaneeth Muthu',
        lastName: '(Nav)',
        emails: ['n(nav)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHYPf0mWZ3QXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595098228126?e=2147483647&v=beta&t=rxi87yo22THKoD0Qq4U9c27eO58YdfNYr89DuD4qJiM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dan',
        lastName: 'Reiley',
        emails: ['dreiley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGfinQXarQeqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516825950409?e=2147483647&v=beta&t=3DUk6KMc_0FVvzMy4xQu79skmJEFqRjUWHGPTcbY8UU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jared',
        lastName: 'Graham',
        emails: ['jgraham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEK45o7QLEHXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602703969155?e=2147483647&v=beta&t=zlC3zonlvxXB4oTMm-H-UBJLO965UZna4l1HElpxJf0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hqsoftwarelab: {
    name: 'HQSoftware - Software Development Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'Sutherland',
        emails: ['msutherland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHSsMA6f44yBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723151107386?e=2147483647&v=beta&t=gSgXFTVXIcIK1qTo-FM0R5DWcqspry0hdlslfTpT7UQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steven',
        lastName: 'Boston',
        emails: ['sboston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGJqVCJXEJmFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619199566993?e=2147483647&v=beta&t=q4srwGUIE6KOWXBDb1HYBTQ2oA8SwqePEZE-y6oSNRc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Indi',
        lastName: 'Rinearson',
        emails: ['irinearson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGaZtV7wMhTdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676416840136?e=2147483647&v=beta&t=9f3f8DDW-rZ8GGY8OJPOOJ6eWOnpE3h9T-uY0BsI0Fk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tim',
        lastName: 'Calderone',
        emails: ['tcalderone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHvMiY3Ru2DgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573132716932?e=2147483647&v=beta&t=FSV_As-aZBII3kEKIq3oV6-4opBfG1YKTjYBmTRIWuQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adam',
        lastName: 'Cutting',
        emails: ['acutting@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFr4VA_A7UFCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691662676382?e=2147483647&v=beta&t=6eyQaP0eTu6P5rhZMsknv2ElcE-SaOjYnNhTKqwLclc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  adroitts: {
    name: 'Adroitts',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gokul',
        lastName: 'Kannan',
        emails: ['gkannan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGjPA0aWn0xiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517547109711?e=2147483647&v=beta&t=M20hyfK8lrCtAXNOVfjibacAHz1DtmWMoOqovAskE2Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sona',
        lastName: 'Prakash',
        emails: ['sprakash@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHBUzzEpLLfPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706558604430?e=2147483647&v=beta&t=O2m-_skYVLqL6gbMeoxLVMSAEiYhJ0ysS49OJrUA47U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anand',
        lastName: 'Lingaraj',
        emails: ['alingaraj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH6-hDmojS-mA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697755565921?e=2147483647&v=beta&t=2wnXKdve5UEbVvMGZ19VuG58TmUsYhVyY8S-W070lzU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Santhosh Kumar',
        lastName: 'Ravindran',
        emails: ['sravindran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEI7sJ26w5qKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705668518013?e=2147483647&v=beta&t=Mb9u-ZIBLUWNo3w1uvgg7mq9c4t76g29Q2VfDQbtkZc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nishita',
        lastName: 'Chundawat',
        emails: ['nchundawat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHGHrbjBhCrIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723498218151?e=2147483647&v=beta&t=iGjN3YQdCTFWawt0Q9Gumz6CXHJDYMkGOUPBGMAPtZI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  programstore: {
    name: 'PROGRAMMING STORE',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Fino',
        lastName: 'Geeks',
        emails: ['fgeeks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF7ZRnuWiA3Cg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1730080386124?e=2147483647&v=beta&t=j2U5j3HVhpan3Oq3AdiA5l8eg27McxOKF6X1---vHL8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jenny',
        lastName: 'Yin',
        emails: ['jyin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGoq-Os4pbXzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684311274907?e=2147483647&v=beta&t=VCRO6Mjpo7MnnHPmAkTNg3VilwNAz1Njz310Uq-reuw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eric',
        lastName: 'Luo',
        emails: ['eluo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGKkhzkpQbA3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710302547759?e=2147483647&v=beta&t=mJ-s8FDw8O_LiE6fD8BN4Amt0ZyfdE67bLk6pFTaRFk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jason',
        lastName: 'Lin',
        emails: ['jlin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEZa8ku4E22vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516177600535?e=2147483647&v=beta&t=TjIf5OFJ7s-9SMFXAwe3soREDvZSdHXs1cIk2ynYhdE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Perry',
        lastName: 'Hou',
        emails: ['phou@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFIAZRZBMw1WQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632985464407?e=2147483647&v=beta&t=yjebLSES2D5plJ55lySca4wSPtQF2q5z-zJ-iO3SLZw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  simeoncloud: {
    name: 'CoreView',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chip',
        lastName: 'Councill',
        emails: ['ccouncill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGYF4oNoDGUJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596195872970?e=2147483647&v=beta&t=_CxGfzybhivd4TT9V1-jxxpSUeKfT3v7vkPxsR2JRjU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Salvador',
        lastName: 'Gallardo',
        emails: ['sgallardo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH69Mto0-xjfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548784618153?e=2147483647&v=beta&t=BobH1mdWPs2YNr2NRVSs5_0IjNhlyZXGM_VaXP0Vq-g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marco Pietro',
        lastName: 'Benaglio',
        emails: ['mbenaglio@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dennis',
        lastName: 'Paul',
        emails: ['dpaul@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tariq',
        lastName: 'Iqbal',
        emails: ['tiqbal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHYWdQJVRaA_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569080538259?e=2147483647&v=beta&t=xLqfiEMg0Zpl7zGnvMjfjwQZbXjtQhjh4gHsquzAm8Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  psmpartners: {
    name: 'PSM Partners',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Mete',
        emails: ['mmete@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGyWpqcH0YUVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614695697031?e=2147483647&v=beta&t=4u9fFkOGOADEUdz1vLWDj3ypsAymT86H6C0DGzn3nU0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Stolarek',
        emails: ['dstolarek@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brian',
        lastName: 'Spencer',
        emails: ['bspencer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGH_B8yZoghPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517424413205?e=2147483647&v=beta&t=8Pk4NPeRZtX-Or3_JctOSJ0Shr0Baj9DNv4RBifWlqg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Germaine',
        lastName: 'Perez',
        emails: ['gperez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nicole',
        lastName: 'Mete',
        emails: ['nmete@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEcmL9BcwFmkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516244432342?e=2147483647&v=beta&t=jVoxR-vN_0nUtDym-ep4J9e97OSLBxs3rlMM17j2K8w',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  uk: {
    name: 'Thales Trusted Cyber Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Thales',
        lastName: 'Teixeira',
        emails: ['tteixeira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEcx37jaVShvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547834547812?e=2147483647&v=beta&t=LNgBMaDHPuQIC9SNrOxO6m8BaM7vDKX1EYgmeDJMJos',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hamilton',
        lastName: 'Mann',
        emails: ['hmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHrDQWCpiRZrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547317240785?e=2147483647&v=beta&t=sEihSWRY8FepTo8Kma21G4SUamEc-V8pjFOrBnprMMo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Todd',
        lastName: 'Moore',
        emails: ['tmoore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHQqzWIZrcvBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631542314035?e=2147483647&v=beta&t=uDRhQe7OD0qoUaRNbqhAXHWze6BrlwWJxbi4yg5Jl_g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sebastien',
        lastName: 'Cano',
        emails: ['scano@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Thales',
        lastName: 'Mello',
        emails: ['tmello@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  docmagic: {
    name: 'DocMagic',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pat',
        lastName: 'Theodora',
        emails: ['ptheodora@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Morford',
        emails: ['mmorford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGgK3E5Byoc8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626281891942?e=2147483647&v=beta&t=GMNT4601wP9oqIYyTAfWS64meerCHjOSyOCXESe-Gzk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeremy',
        lastName: 'Boyd',
        emails: ['jboyd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHg2djdrCISwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516276101823?e=2147483647&v=beta&t=2ZI72fU3Jjd6gWj7nIkDsAI7CLdqkUhrPhBs4JYIOMM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Colin',
        lastName: 'Fitchew',
        emails: ['cfitchew@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEmk_PbS8yO_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517513679555?e=2147483647&v=beta&t=QlgRyVhtWLtnb0dHoXCf8lRBt4DlyjjW8UGqz_sTnHI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christopher',
        lastName: 'Lewis',
        emails: ['clewis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  etecture: {
    name: 'ETECTURE GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sascha',
        lastName: 'Appel',
        emails: ['sappel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGcUX_FoLtriA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616584486736?e=2147483647&v=beta&t=FAY8IENPAmoyUA66wTaeqpyxcZYdCajJROc2eAPrj0M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sascha',
        lastName: 'Appel',
        emails: ['sappel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGcUX_FoLtriA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616584486736?e=2147483647&v=beta&t=FAY8IENPAmoyUA66wTaeqpyxcZYdCajJROc2eAPrj0M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alex',
        lastName: 'Ofoedu',
        emails: ['aofoedu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFyuGmugXMFGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516580705432?e=2147483647&v=beta&t=XXjMAWfOGH9Han01jNoIKMKnxyna9j_V2hJzLdkm73s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael Quenzer – ETECTURE GmbH |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH3Pky7aOOOwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517429170291?e=2147483647&v=beta&t=-fiOAJ4BgHapXRpzQCqDPJplTbkKdSQXngWqCUKTh40',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Manuel',
        lastName: 'Zamora',
        emails: ['mzamora@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEGSlBmSntSHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561021282202?e=2147483647&v=beta&t=nVrTX8ahcbfMsMWBFguScM7Dxgg6ze_dEQzMouVwG7Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bowdark: {
    name: 'Bowdark Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'James',
        lastName: 'Wood',
        emails: ['jwood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGJWgM4SfI71w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701018488880?e=2147483647&v=beta&t=k4ZX4LjsVfOWG94OVEz322CvifA4iYo3IQmIH1SQIMQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrea',
        lastName: 'Wood',
        emails: ['awood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHEvK7loy_9CA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614099192046?e=2147483647&v=beta&t=2Xqb7ptYe_kGR3IcOCS9s3ga3DolHKziqAg57oHhgjY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ian',
        lastName: 'Rockers',
        emails: ['irockers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHPe_hCH7xWZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635524634449?e=2147483647&v=beta&t=5Tqbh3xqaHeDqwD69wqFNwm0AvnFYP-ZnPmhRjyY1W4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amber',
        lastName: 'Kiefat',
        emails: ['akiefat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFmdMT4d3DSwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649814905964?e=2147483647&v=beta&t=sK5tD0FnpGRlL1iA5VbzMLsfKXw2mFhiLb-hfB00VQY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Colby',
        lastName: 'Hemond',
        emails: ['chemond@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHb5IGGDPAFzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663334694927?e=2147483647&v=beta&t=8gp1z57TIFkzdXkdVWu5T-wRg6kVWmeUOVC_exUGY3o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  weavix: {
    name: 'weavix',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Turpin',
        emails: ['kturpin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG6ZzDGQTdzEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631015358778?e=2147483647&v=beta&t=PdMExSEur7S2WK0WUPYtlaUPwY-IIcVd9wWb1nytJ8k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Justin',
        lastName: 'Nickel',
        emails: ['jnickel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFg6dVJCVd7_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620655560615?e=2147483647&v=beta&t=0UMJg1LToq7bZAIZoeR2-2dEOKvHTJ-3lt_P-CQ5s5E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Blake',
        lastName: 'Carlson',
        emails: ['bcarlson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG42413miUyWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596755967602?e=2147483647&v=beta&t=CMrBeYRRCuPW6BPZxnBuXdnFc7o8s0mn-GXcs7I5zh4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeremy',
        lastName: 'Williams',
        emails: ['jwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEvKDmVN1J94g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712765720380?e=2147483647&v=beta&t=AQwxmIh6S-KRWAceq5Mbxx5q_zOlcP6LHFPBqlAHaI8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Kapic',
        emails: ['dkapic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFsrX87Gaa9gQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719953369866?e=2147483647&v=beta&t=NG8nTOq9oG6YjHZBo6nIPnkB8TMaZnIca4C_MX4_62I',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fazla: {
    name: 'Fazla',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mehmet Emre',
        lastName: 'Ayar',
        emails: ['mayar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEHqrkbwToN_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627212045834?e=2147483647&v=beta&t=VYL9PHlTI5fNPNaN0blae2w2EbMsTEbj_v1q4bisaFI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ebru',
        lastName: 'Erdaş',
        emails: ['eerdaş@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Halil Tunbak – Technische Universität Dortmund – Bochum',
        lastName: '...',
        emails: ['h...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGEo5onovMPpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659519045536?e=2147483647&v=beta&t=onbV1NxmHGNS-A4iRdAn4d-sHP3N4C5HbR-SG2V3tuw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'İremnur',
        lastName: 'Bayramoğlu',
        emails: ['ibayramoğlu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHQkN6jqaF7_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600205264926?e=2147483647&v=beta&t=AVzjwM6tqAoCrzfER_CxVBONiZvsDDy-eO4cRoz89h0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mustafa',
        lastName: 'ÇİFTÇİ',
        emails: ['mçi̇ftçi̇@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGpgUyNY_oQEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677579692722?e=2147483647&v=beta&t=wO-X7DswuOf9rTaI_Wx68sx62Ooh-yViwaTgjoo9ko4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  onilab: {
    name: 'Onilab LLC.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alex',
        lastName: 'Husar',
        emails: ['ahusar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEetiO_ME1zrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558951581303?e=2147483647&v=beta&t=_4c6a4e57LvdSY714D6iFeiItFYVU2r96Uh1W7gDVGM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Svetlana',
        lastName: 'Kirienko',
        emails: ['skirienko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQELtmiLzKU5wQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558689771693?e=2147483647&v=beta&t=lhA-KiYgt5qAXZe_xDEkhaCmkfnbkKMELydEGFQ9MQI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alina',
        lastName: 'Kisenko',
        emails: ['akisenko@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Art Malkovich –',
        lastName: 'Co',
        emails: ['aco@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kate Parish – Onilab LLC. |',
        lastName: 'LinkedIn',
        emails: ['klinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHrBL708ULPcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613459891888?e=2147483647&v=beta&t=Aw6Xg2R4d9Gp8dtwWmgOCrDOptPrj4wvDSVVdVAhP70',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  spykegames: {
    name: 'Spyke Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  glopal: {
    name: 'Glopal',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Benjamin',
        lastName: 'COHEN',
        emails: ['bcohen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Patrick',
        lastName: 'Smarzynski',
        emails: ['psmarzynski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHa02lTcmjTXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715958463239?e=2147483647&v=beta&t=Rts-4xw0CuoC_Jix0jTBabXmrqeJWumKEyonXYhnBhI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Herbert',
        lastName: 'Knibiehly',
        emails: ['hknibiehly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEHadA-vdR0vQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614521539475?e=2147483647&v=beta&t=oCvAWZcMEUcgaG9mzPd1nMD28VpHBnkHXMU9UJrMWeM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Olga',
        lastName: 'Temnik',
        emails: ['otemnik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEvaa_NYZD99w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571740352312?e=2147483647&v=beta&t=NskR5_AxRmFEnTs2nQbkskJR7YNs9g1nwWU3BO7L0CY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Natashia',
        lastName: 'Redfern',
        emails: ['nredfern@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGot2gqtmfLFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709293037563?e=2147483647&v=beta&t=xLJIGPQ8YaAEHaRDJECSky3jCl9XMousFCAEnGSs7gQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  prophet: {
    name: 'Prophet SA, UK & US',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'Peachey',
        emails: ['mpeachey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stuart',
        lastName: 'Leitch',
        emails: ['sleitch@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tony',
        lastName: 'Hargreaves',
        emails: ['thargreaves@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Philip',
        lastName: 'Dawes',
        emails: ['pdawes@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Seekins',
        emails: ['pseekins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHKU0j_wcTPsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618926044703?e=2147483647&v=beta&t=474C9pyh7l_vG7XpTLKkjfrJX2VcrYa1lFvN4_2QBlw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  santodigital: {
    name: 'SantoDigital',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christian',
        lastName: 'Santo',
        emails: ['csanto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH3tlvK3FvfWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572310279800?e=2147483647&v=beta&t=yOTYqM1wblLPGzVMzd1x1YwzEeUQZU0YxE14Rp7Bvds',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Isaac',
        lastName: 'Barros',
        emails: ['ibarros@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG2g_QmbJfJKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727120043524?e=2147483647&v=beta&t=Lt1DxjYt-WBf0NxXHVtzXFaSxQs7vePi2CDOYAddjUE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lucas',
        lastName: 'Henrique',
        emails: ['lhenrique@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'William Miranda de',
        lastName: 'Jesus',
        emails: ['wjesus@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEI0UQyXkDvTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702772681884?e=2147483647&v=beta&t=S_ab4jQeLHiimK4qb5W9G3EN0-iZh_1Zj2iTQU4kuSE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Claudio',
        lastName: 'Santos',
        emails: ['csantos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGa1G07gxabMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517744183085?e=2147483647&v=beta&t=vtWhN-WZ85YlQxbxA3b9Bjb5x3wzzYE7vEmTb2zOKuI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  addon: {
    name: 'confidential',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dilip',
        lastName: 'Sadh',
        emails: ['dsadh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGOB2whkISJEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516286730379?e=2147483647&v=beta&t=STqvj0ieglQw8c2jT9EZE1dmcecfsEAmKrOem3aIEhc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nupur',
        lastName: 'Srivastav',
        emails: ['nsrivastav@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG_zgBawrJV6Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673089112370?e=2147483647&v=beta&t=aSQea0ciq34_x6PRqwXMFjUciZ0_qIFy8Kqh8aRcdeI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ravi',
        lastName: 'Badama',
        emails: ['rbadama@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rajendra',
        lastName: 'S',
        emails: ['rs@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abhay',
        lastName: 'Rana',
        emails: ['arana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFuURGjusJIDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685812052118?e=2147483647&v=beta&t=8fC4xnTKEBvZnq_rqkuytiSQFHO5MVGiWD7ChaGGXOU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  alysian: {
    name: 'Alysian',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Banks',
        emails: ['jbanks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE9JidcN0gsAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621195454332?e=2147483647&v=beta&t=8uXk8dBX9mUwS_YgqNdc-kXjtli9v0829Xe81db3_hQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jerone',
        lastName: 'Walters',
        emails: ['jwalters@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFcluh076j0vA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712777380650?e=2147483647&v=beta&t=24UyzHFiwrB5279eQae4XezbqCNBMW-A1cNtkc4FQ00',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Hart',
        emails: ['chart@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephen',
        lastName: 'Craig',
        emails: ['scraig@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF7AMlH-brLCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729457560585?e=2147483647&v=beta&t=sobt3Car-L7WbdbqtsArjLks6kbRrVBUbXTqitg6DTQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName:
          'Michał Dudziński – Warszawa, Woj. Mazowieckie, Polska | Profil',
        lastName: '...',
        emails: ['m...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  baystateit: {
    name: 'Bay State IT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sean Sanker',
        lastName: 'Sr.',
        emails: ['ssr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFpw69DexFz4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516284507357?e=2147483647&v=beta&t=i4F5tKMZZcUmGotP2tZm8dp2qw_7g1BqSzLa1GyJgYo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hadley',
        lastName: 'Garrett',
        emails: ['hgarrett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFOoh7_9vhVvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677600235926?e=2147483647&v=beta&t=8PGL6Nu-267yVwWZnFUpivCddPAeWkJavCyVBN61GDg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jennifer FitzHarris,',
        lastName: 'MBA',
        emails: ['jmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF41mNRra5qKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702475115295?e=2147483647&v=beta&t=KB1o0kpITK61_L0me_B7B1FZUqekZM5X6J-CWU_-_YQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Samson',
        lastName: 'Lee',
        emails: ['slee@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Truland',
        emails: ['ptruland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE189CmCg28bQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517691014884?e=2147483647&v=beta&t=SmdqERRvsayJW6SsA2bOj058wK1m-HM0YOwaME1DFyA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vitruvisoftware: {
    name: 'Vitruvi™ Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Walters',
        emails: ['dwalters@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFcSyAJzMLg-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708634031038?e=2147483647&v=beta&t=Ih9yOBkaz19dQE7YVZ1tpJE2gnokW-X005CF2PwJN3o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bryan',
        lastName: 'McIver',
        emails: ['bmciver@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Warner',
        emails: ['jwarner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF2ZtZlgIxVcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517707476277?e=2147483647&v=beta&t=5IP-Ct2nQncP71eS33u3BnnVi6xC-oz6SeTFZ0CkNpw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Justin',
        lastName: 'Reid',
        emails: ['jreid@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGOm03mtn6Aeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547671247959?e=2147483647&v=beta&t=aAwE7yTxYpVrLl5IyoevHPeT4EtB60zTwvnyi33yjzo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Neil',
        lastName: 'Hart',
        emails: ['nhart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH4JwyT8CWiiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560283563822?e=2147483647&v=beta&t=FmspfIDvtLc04EovXLfKFf9INfkSUysjT1b3IeNXq70',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  blancFischer: {
    name: 'BLANC & FISCHER Corporate Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  scadaInternational: {
    name: 'SCADA International',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matthew',
        lastName: 'Burt',
        emails: ['mburt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH18xZfrXf5AQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707839722935?e=2147483647&v=beta&t=VwRjX-kr9XDkSo_CiWZ870yOTwHtEXe1OE-EX20X3-k',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Morten',
        lastName: 'Bülow',
        emails: ['mbülow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEXR83ZdCJskg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730307124005?e=2147483647&v=beta&t=sl2nFTtBVNb_UZ1g9SXi7fH-IrWHlIIjUdLB8rdiKRg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joe',
        lastName: 'Perrone',
        emails: ['jperrone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG5XOnWd-HvZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578964348956?e=2147483647&v=beta&t=kd85-FFO-i9YdBvarA7_3aVCcJzfNj0J0mZpxMEtxCU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ruth',
        lastName: 'Gibson',
        emails: ['rgibson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGfKHophbTxMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593609158216?e=2147483647&v=beta&t=e24f_YoJsPDNeNW3qX45FTWqkMQSoUYPgjz_VI8-S9Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nicholas',
        lastName: 'Hess',
        emails: ['nhess@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHgIWJGL8Hpgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726760828560?e=2147483647&v=beta&t=OIZI4AfdBSFUkgPg4Hr1eZriAdiG8JY4GKFZuCANnmU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  obsidiansecurity: {
    name: 'Obsidian Security',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Glenn',
        lastName: 'Chisholm',
        emails: ['gchisholm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFIK7T7-qidtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517674881882?e=2147483647&v=beta&t=q68RoVCk3wBp8n7aD_AS3LRoeiAYbahKjuvBho6iBaw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hasan',
        lastName: 'Imam',
        emails: ['himam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE3NjIyk_Qh8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516266397100?e=2147483647&v=beta&t=3C9chrKdvlQHE0MXICFwoLWN9CDRhRpoVTtOkKxC4Rg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chithra Rajagopalan,',
        lastName: 'MBA',
        emails: ['cmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ben',
        lastName: 'Johnson',
        emails: ['bjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGOZ7ZQPo9cog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602212951933?e=2147483647&v=beta&t=YLHVbO2CY5JsCotsjoxVSGoJNL2DK0A8C3kPtU1q-YA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jason',
        lastName: 'Gradel',
        emails: ['jgradel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHxx0GSUxnjXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710338864929?e=2147483647&v=beta&t=28Nny5EdeNq_GZvDAlsr_k4eAB_Kw221etc5fEphvuQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zafran: {
    name: 'Zafran Security',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sanaz',
        lastName: 'Yashar',
        emails: ['syashar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sean',
        lastName: 'Robinson',
        emails: ['srobinson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Melanie Brancaleone, MS,',
        lastName: 'MBA',
        emails: ['mmba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nick',
        lastName: 'Fisher',
        emails: ['nfisher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH3CV6Ye-QKFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639519676211?e=2147483647&v=beta&t=PSHejEo9XoQ-xHqIm4gQcBJBOh2wYrMhInQf_IUJYsU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Heather',
        lastName: "O'Neill",
        emails: ["ho'neill@example.com"],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tritechsoft: {
    name: 'TriTech Software & Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rob',
        lastName: 'Hopkins',
        emails: ['rhopkins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEj3NlVfBC5TQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706137287071?e=2147483647&v=beta&t=kDPKKtFr7QsKhzoICO3ukkIlYukK2aPaYFqOgTjThsM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Massey',
        emails: ['dmassey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFV98CBWA0rTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584472910203?e=2147483647&v=beta&t=9j3Vg78JjZrUAdlmZN5_m-LgkaFjxsQxbqQixaI3Op8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alexis Jackson MBA,',
        lastName: 'SHRM',
        emails: ['ashrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG0k69AKhrONw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676776222784?e=2147483647&v=beta&t=2SFoTRU4nKS78S9Kab0FSkUFA-UsQvlvl26dsOJsn6M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robby',
        lastName: 'McCollom',
        emails: ['rmccollom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHhU6XBz4KWsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674679743003?e=2147483647&v=beta&t=AJ3LXezKB7Gh_8LPoA5BO8wRgs7gO-YYWTLFveAE5Zo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Linda',
        lastName: 'Ambrose',
        emails: ['lambrose@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  abscomm: {
    name: 'ABS Communications',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Erich',
        lastName: 'Metzger',
        emails: ['emetzger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFAt2hKARtB3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591901570763?e=2147483647&v=beta&t=1Kmq-sNLGakv95YxxvyvkA9uECVJ_ZSXgmbzOFedz-8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Workentine',
        emails: ['rworkentine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGvo6uWtWK6Tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593806232737?e=2147483647&v=beta&t=ROCKQFBVhvOqzUydZRfZKxntofjEQeERVEznMZ_MRc4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tommy',
        lastName: 'Dreger',
        emails: ['tdreger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFEhblMK136rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544843856812?e=2147483647&v=beta&t=h_wVw-AbRLdt2ej9kRskzykNQn6zONGQf7KIUshaSy4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Schmeding',
        emails: ['mschmeding@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF-F82VYB539w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621445804436?e=2147483647&v=beta&t=VPRURfw91P8nu9_P_vKkx0Qzu0AKH1kT0Lcxm8y1Im0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Becky',
        lastName: 'Evans',
        emails: ['bevans@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFmK3xLwHD7Yw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724157634957?e=2147483647&v=beta&t=cpMDh4LGV_ycoSbv8_cqOMub0EvL-PsPeuk5ZT_7dqY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ockers: {
    name: 'Ockers Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jon',
        lastName: 'Houser',
        emails: ['jhouser@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEc3j_QV14daA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706820817331?e=2147483647&v=beta&t=zIjwC8ERynUbEElPbvrsALdWrcLdW_FdK2PYI_KYwF0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ryan',
        lastName: 'Booth',
        emails: ['rbooth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFMCGiEUbx3KQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651160248288?e=2147483647&v=beta&t=RA33ZGJy3RI-GesyAVa7LATlexGcT7B8Soj30GIDzTc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jennifer',
        lastName: 'Moore',
        emails: ['jmoore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQELtTGtCrPKqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725659647620?e=2147483647&v=beta&t=a8eBkz0GDQj_LcOW8QFSPecNlGYYzmePs8mviBRnhTs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Quinten P.',
        lastName: 'Ockers',
        emails: ['qockers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEB6O101S6ZfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676214691397?e=2147483647&v=beta&t=4dpDZldlBF8RHjegEFDq6-NYmIL6ZoacMph7zUOpLTM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jerrel',
        lastName: 'Lowe',
        emails: ['jlowe@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rolique: {
    name: 'Rolique',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Volodymyr Morozenko – CEO & Founder – ROLIQUE |',
        lastName: 'LinkedIn',
        emails: ['vlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGD6ZtrL_uq3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729596856332?e=2147483647&v=beta&t=XTL_j653z22JdP-Tmaq-xVSdAqy_KhpR6a6RB4TPd9U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rolique',
        lastName: 'OPONGA',
        emails: ['roponga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEdVoTosa5dEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1599056829896?e=2147483647&v=beta&t=UydHE1y64uVCDvRuyDSdtxp5A7CKYFGd54zgeK3FTnM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Khrystyna Mnykh – Talent Sourcer – Rolique |',
        lastName: 'LinkedIn',
        emails: ['klinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEv6cvrV4Fg2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636305410397?e=2147483647&v=beta&t=6PYhb1a7AQMFYzCxnPwmG4znqLM90Y5qz-MSl4fbS8w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rolique',
        lastName: 'ADANGNIHOUDE',
        emails: ['radangnihoude@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHMfAjfioWoPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664317455569?e=2147483647&v=beta&t=CyuTtyyERW7NHwjoYFAMdYP9oZT1_1xd-VGMchFntfY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Oksana Ts. – Recruiter – Rolique |',
        lastName: 'LinkedIn',
        emails: ['olinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGbnQK4VM6ZZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627649408132?e=2147483647&v=beta&t=cCcY6dfePTVZE8fzkItZ_BSa5cXnNZWfKd3JEj0a4Fg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  h1systems: {
    name: 'H1 Systems LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mihály',
        lastName: 'Németh',
        emails: ['mnémeth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH6Z3C6LAownw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1538316359899?e=2147483647&v=beta&t=JIxLGjUeNXKt9XLzP5_uDGUejKIjM2U0iel3bLLAjgk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'Badar',
        emails: ['abadar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHCBgQnrC8IrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516947718654?e=2147483647&v=beta&t=XDW6_7H6mondf2YLKkrfWVQmTNbQvkRzW9q56Pzl7P0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tamas',
        lastName: 'Peter',
        emails: ['tpeter@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zoltán',
        lastName: 'Béres',
        emails: ['zbéres@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHorlB2KInyhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580199083599?e=2147483647&v=beta&t=8OaDgu5S8YZIQORQ2QHkxc19oghqpS6Gi6VZCTZsGtQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gergely',
        lastName: 'Straub',
        emails: ['gstraub@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dvlnet: {
    name: 'DVL Group, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anthony',
        lastName: 'Furey',
        emails: ['afurey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHU5w-Gj1Uyjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517222568175?e=2147483647&v=beta&t=revGKGChgYTRVkH-Ga0rzzJ55gljx0vAN53eO9g0Udg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nick',
        lastName: 'Babiak',
        emails: ['nbabiak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEkiP08uxuBuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703631397355?e=2147483647&v=beta&t=5oLe1kvcG2xqwoFZK2pOZvH5xW62RcTca4tBQGPrqJM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sean',
        lastName: 'Delaney',
        emails: ['sdelaney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Erica',
        lastName: 'Muzzy',
        emails: ['emuzzy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEcVPXuRy6Tkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517056657284?e=2147483647&v=beta&t=QcvujBkzc_TTiVMfuSvHULAGXoeJcTfvrs2zC5cX4Hw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Clayton',
        lastName: 'Mitstifer',
        emails: ['cmitstifer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGdOMhuyDkZXQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516490170156?e=2147483647&v=beta&t=USxzwqVCm9Ik5hlwlxibBk6sCApeM4PFhoccc5uMamI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gohealth: {
    name: 'GoHealth Slovakia',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jana',
        lastName: 'Kušnírová',
        emails: ['jkušnírová@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGh8M036CXJPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627937234138?e=2147483647&v=beta&t=iZnUiB9kBiftqoaCHm83L1vjiB5W6Tf49qRqw9DhHzE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Milan',
        lastName: 'Kohut',
        emails: ['mkohut@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFxmF3jec0fcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674214479301?e=2147483647&v=beta&t=dO7EgfBGAbkfZU2xgx4gvt3XLv2YJFbUj3muF0P7lv0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Martin',
        lastName: 'Knapik',
        emails: ['mknapik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFKFEoiqJojUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1545600815952?e=2147483647&v=beta&t=9wN0v8aKZmE0ki4wAkb0DWZT8vX602h6fGN09MHJQdI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ondrej',
        lastName: 'Tarasovič',
        emails: ['otarasovič@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFRmE7wTXUoCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600097160704?e=2147483647&v=beta&t=msVo-clUGn9OB0jPjHWxAwLe9fizj7lmpUdHaIHxN8I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Breno',
        lastName: 'R.',
        emails: ['br.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  leo: {
    name: 'Leo RegTech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wayne',
        lastName: 'Wang',
        emails: ['wwang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jerome',
        lastName: 'Lussan',
        emails: ['jlussan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHp_XxKKTkp1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688145060894?e=2147483647&v=beta&t=cZK_DYDSFn97UpnIsTFr_Df0HYNFqf6OfBO1U3oE0gQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Luke',
        lastName: 'Shepherd',
        emails: ['lshepherd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH-KaOlq-A87A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688395014767?e=2147483647&v=beta&t=izvhB4a_jY0f0OeY427KuArx35xMU9dSCHH0kYKUZ0Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sofia Schiller',
        lastName: 'Solti',
        emails: ['ssolti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEQKZrU-OsoxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645719370405?e=2147483647&v=beta&t=W6Ko-hBnzgK_-qqKqcgdGvNfMkxnyK_qKwtd1zd6y1o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hannah',
        lastName: 'Fyfe',
        emails: ['hfyfe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGsHz5rHGwFLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1538483980264?e=2147483647&v=beta&t=IFIlEk1tZ3_DBhgM8p7CPW3hcUbvcdBY0YsHKwcpwQg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bongarde: {
    name: 'Bongarde',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lori',
        lastName: 'Campbell',
        emails: ['lcampbell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFRicLB_cUrmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517531998790?e=2147483647&v=beta&t=nhUXaOIF8KGy8z4dZlb6koU0KDm-yQWELY5q8vF2z6c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Haley',
        lastName: "O'Halloran",
        emails: ["ho'halloran@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGQGPoP0p2Lsg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718805568381?e=2147483647&v=beta&t=TgeHF_Vju6u0yrwA6m2KTunUjQzurg8llccXhtYbdPU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Cerrone',
        emails: ['mcerrone@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vicky',
        lastName: 'Pickford',
        emails: ['vpickford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGIZpsmo_d_Og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1527184477875?e=2147483647&v=beta&t=lb9yj-LtHN_Qr-vWHqhjgsa6Q3nwmy8i_yDP5anm9Qs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jared',
        lastName: 'Edis',
        emails: ['jedis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  reprise: {
    name: 'Reprise',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeffrey Hall',
        lastName: '(he/him)',
        emails: ['j(he/him)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHXWzzgaIeqfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718927095898?e=2147483647&v=beta&t=xDB_8VujS80Izp0KfawYxQHT0f1aDDkPAA4m9ncbh5E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stephen',
        lastName: 'Stangl',
        emails: ['sstangl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFUz9sH4auKXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517578698605?e=2147483647&v=beta&t=BTF7hGO9Cm9G73uVRu5aMVt7i1fVP9RWR4KTRyg81e4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Evan',
        lastName: 'Powell',
        emails: ['epowell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGIwrGQ1Y9bng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605221867482?e=2147483647&v=beta&t=wMDGESAJyk12xUemWQEhn4MP4tod3eFbzCEi2BhgvMw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jorge',
        lastName: 'Soto',
        emails: ['jsoto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEWihaGEIqmFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646063042363?e=2147483647&v=beta&t=-8dN9Z0Kl6g4zlgN-Cd5wWxb8V2P92Jjt3ESg3Szh9w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Samuel',
        lastName: 'Clemens',
        emails: ['sclemens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEwWQbpgr9Hqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516170287657?e=2147483647&v=beta&t=RdlKBp_uIcgjkDOwLdABuMz67OfwCkjB2FBDfLi4hSY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  envisionsuccess: {
    name: 'Envision Technology Advisors',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mathiew',
        lastName: 'Medeiros',
        emails: ['mmedeiros@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHlfQgIV3yaXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687986658604?e=2147483647&v=beta&t=DfaHkvfglSsZUnZZzGie_m91oO6yMIbpPeH6Y9c9lAc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Todd',
        lastName: 'Knapp',
        emails: ['tknapp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQExYBIbn0YOTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517699871594?e=2147483647&v=beta&t=41IwJ3_UMo2AoSSWnjS-RXKzugmiYpyD83nZhgqtfSg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Karen',
        lastName: 'Penticost',
        emails: ['kpenticost@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jason',
        lastName: 'Albuquerque',
        emails: ['jalbuquerque@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGDbNudNApF1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1533255737435?e=2147483647&v=beta&t=7IUve75Mxd6kIHyBQpnKRWNPfRmdD_HcBMICGOy5Wtw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brent',
        lastName: 'Ouellette',
        emails: ['bouellette@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFSVMUwolgS8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516282087335?e=2147483647&v=beta&t=6gdJhEGRDrDHUQWm86o36TGonzb6JF4C_1RLZDjkPkk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aware: {
    name: 'Aware, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Eckel',
        emails: ['reckel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Conrad',
        lastName: 'Ruiz',
        emails: ['cruiz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEZFJmFqYQ--A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681921843474?e=2147483647&v=beta&t=HoaUeDNSee-yOq5FvuSyDQFkVr--4ux93W1n7_0BlZU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Barcelo',
        emails: ['dbarcelo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Traverse',
        emails: ['dtraverse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFp5ZVFUy4Kwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517661400953?e=2147483647&v=beta&t=DCDSpxPeqGAFWq_gYL3yQojaPBEU8DiZXFQE5hJdmwA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Heidi',
        lastName: 'Hunter',
        emails: ['hhunter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFbpkaxhallog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715707372069?e=2147483647&v=beta&t=4imJPJs_ryrXi-E71gyJOkNoRmhAFvW_iOjkAej9bfI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  opfocus: {
    name: 'OpFocus',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Astrid',
        lastName: 'Domenico',
        emails: ['adomenico@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGgbqmLfc82mQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728310003962?e=2147483647&v=beta&t=3oDbRZsBKzc4lvI7jI2JocPeEU1JZErT8wFLGJi-sf0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brian',
        lastName: 'Waterson',
        emails: ['bwaterson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Carnes',
        emails: ['dcarnes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGPJVHxm_gHZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652098194228?e=2147483647&v=beta&t=fowrYYkHUpLfRRvX3he2Zzef2p6LNhDnXtTUiOgV5f0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'MJ',
        lastName: 'Kahn',
        emails: ['mkahn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Natalia',
        lastName: 'Kochem',
        emails: ['nkochem@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG89G8xC4CdyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1566533363704?e=2147483647&v=beta&t=EgoAzHqVl-8OLL2KgY5T3xF5r5KgkDHTQHhep80UqPM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  devineco: {
    name: 'DeVine Consulting, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jennifer',
        lastName: 'Devine',
        emails: ['jdevine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLwyPWCTV-Yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521079361439?e=2147483647&v=beta&t=m_NFrHB7K0jKm_2jo9tl1kcfXnxKWajNhcsuvi8rnTg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeffrey',
        lastName: 'DeVine',
        emails: ['jdevine@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paul',
        lastName: 'Beach',
        emails: ['pbeach@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGzTXyJpQXgcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548831576366?e=2147483647&v=beta&t=Zu1BjDgXaOwkf-ynSGge5XzvC_vj9sf7NpYrfpx8wq4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kyle',
        lastName: 'Devine',
        emails: ['kdevine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEruS4lKhm6Kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648752939833?e=2147483647&v=beta&t=e3m_vaJxnrDZhWv9p4R2vi6bZiN3vO_lywcpoeKxmdg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rosemary',
        lastName: 'M.',
        emails: ['rm.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGX0fSw_MJkNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517602610053?e=2147483647&v=beta&t=Ok7MkaW5Sx18mFEiWYe0S-z2IxcpW1GjorRcX6tcPWk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  djaxtech: {
    name: 'DJAX Technologies Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ram Kumar',
        lastName: 'R',
        emails: ['rr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMWqWrSH4kuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674327872634?e=2147483647&v=beta&t=irdmmK-zTmrfxY2ankY0gmI5_lBP2mYNuI304XJvEVM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Raja Mohammed',
        lastName: 'Sheriff',
        emails: ['rsheriff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGlLRApFDauEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697461224582?e=2147483647&v=beta&t=PLrkT3Ba1_Ev6aDkq1X2HNv8aIQtJtr--3fs7RZdYpY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Manivannan',
        lastName: 'S',
        emails: ['ms@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGnpszvfd6_pg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548048578654?e=2147483647&v=beta&t=EJhIXvB9EkiciRJ0LStfd0qsJvJ-ITZVqD3xpjke0IQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Deepika',
        lastName: 'Rajan',
        emails: ['drajan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gokula',
        lastName: 'Krishnan',
        emails: ['gkrishnan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEdkSeMLIlQwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596774411634?e=2147483647&v=beta&t=uOQxqNTV0wHOtPHvPFiyf3chZdtpqyaKRQzTDxzRmxg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '9ostech': {
    name: 'Oak Street Technologies Pakistan',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Saad',
        lastName: 'Nadir',
        emails: ['snadir@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Usman',
        lastName: 'Tariq',
        emails: ['utariq@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGyEwz4QztTyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709557668211?e=2147483647&v=beta&t=4HU_C-2GAAR1H0FcT1BHQ06uHPEzt6nbMEpMTHxhkk0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Usama',
        lastName: 'Iqbal',
        emails: ['uiqbal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEeWPyjamVPzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714655699202?e=2147483647&v=beta&t=4SRhg0uW5w-bG1uBdfdqJRF5rfglAfB7UhlDrb1Rd1M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Habib Ur',
        lastName: 'Rehman',
        emails: ['hrehman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGm_Yc2TjDOUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693492634467?e=2147483647&v=beta&t=-a7-SLc1-fBN4rpTkFisp5izXEZfkoAvbBl2nmgmfo4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Muhammad Usama',
        lastName: 'Munir',
        emails: ['mmunir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFFi-_vnEMGHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673290082760?e=2147483647&v=beta&t=be2Rb7rKqk44mB2sMwNptUWhLtGIUwy2aUuTRPG4ZEI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  arrayasolutions: {
    name: 'Arraya Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Thomas',
        lastName: 'York',
        emails: ['tyork@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG5REgdiavQQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517466801934?e=2147483647&v=beta&t=r5Q4XFLl9tds-zCEev3URw7DbgffFpH7dpusSUfT4Fo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ed',
        lastName: 'Greene',
        emails: ['egreene@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGg29UpbsSrog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729346683023?e=2147483647&v=beta&t=XOYx_h1gHCXC92xWOFyLqeFjpGvcfrXhDnd157vPtn8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Bakker',
        emails: ['dbakker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQERDSUfbPnWVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516276261365?e=2147483647&v=beta&t=wK6H6Iw-wZrvwmksCcrAsWLFNSSYIwChgbir4fUPkBU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sally',
        lastName: 'Bonner',
        emails: ['sbonner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFq5rPWpjtndA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517737290938?e=2147483647&v=beta&t=orT_bxIzs_XmoncdcNkr_5-dLGndULahjUsfSgFUju8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cassandra',
        lastName: 'Levermore',
        emails: ['clevermore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEeczr7P5TJAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517580370074?e=2147483647&v=beta&t=Dj1YaxPz5uxV9LqA9-eANPs9DJrGLPtRN8Tk_TKopqA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  blockgraph: {
    name: 'Blockgraph',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aleck',
        lastName: 'Schleider',
        emails: ['aschleider@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF60ByV5qtJjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559965685809?e=2147483647&v=beta&t=9yvWWDMXkEMAYQKpDbxDze-Kkx-onpxOoYIy7t0b0_4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Manningham',
        emails: ['jmanningham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHWXSROLf26Eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729868256758?e=2147483647&v=beta&t=PQgX_gx80c7hf3aiN5XkPp7vrZbF69yhE1gGzkFCoOA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'August',
        lastName: 'Sartor',
        emails: ['asartor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG6EG8X5zjgOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666749075380?e=2147483647&v=beta&t=ZAOqqB__2scmnijpDyjxOY3tWVKPnaP0ROzRhqbltec',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Krysty Dimas,',
        lastName: 'SPHR',
        emails: ['ksphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF_U_ubks1vlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666279429603?e=2147483647&v=beta&t=61ab0wqZwy_RbursBr0Sg1MBzqT9oypb46VLqKCX2l0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Natalie',
        lastName: 'Kacik',
        emails: ['nkacik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF4iUm0JWgUuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697038674825?e=2147483647&v=beta&t=8PoEhgFk5895bgsTc-OR9c41vGxY1Gi3DszkV8s0-QA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  inensia: {
    name: 'inensia',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrew',
        lastName: 'Culbertson',
        emails: ['aculbertson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE76mn47BRYAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600837161549?e=2147483647&v=beta&t=rh5bKOxzw2LWFfvRGfczOcMjfFyvVit_1ZnfHn_aErU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Uzay',
        lastName: 'Kral',
        emails: ['ukral@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGMNRtx4lP0iQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699196719013?e=2147483647&v=beta&t=nmj4P5wKHdfMEps6umOLRXNk59FUULDoWAH-rHRUuac',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Milko',
        lastName: 'Dinev',
        emails: ['mdinev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEJoL-Xdfknew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653965712669?e=2147483647&v=beta&t=bSb8saSJ4lxY8LkSCAhpFbNrWcxyXuuLEwue8VI9drA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Milena',
        lastName: 'Hristova',
        emails: ['mhristova@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aidan',
        lastName: 'Dolan',
        emails: ['adolan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE6sBVkrZFOWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712248565677?e=2147483647&v=beta&t=_JhNn7fxnobQs0_Z5WDar2chCES-vJjxE0WFJ2bqZXg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '21tech': {
    name: '21Tech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dilraj',
        lastName: 'Kahai',
        emails: ['dkahai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHki1WX364g8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548199960315?e=2147483647&v=beta&t=6e7eXopte1nprE1sop6aYe5pz6xzTj08VPz9cCJDgGQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kellie',
        lastName: 'Thompson',
        emails: ['kthompson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGaQLDZpGPsEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691157647549?e=2147483647&v=beta&t=hvumDVpFVBjE0WTZsJN_vF2wa6xiN4yhld4rOC8zXoI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brad',
        lastName: 'Baker',
        emails: ['bbaker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE3M54G2O6hFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517724897913?e=2147483647&v=beta&t=mx9dCYFFzYG2ttlSGUyBG_D29P-87H-sr5mUpCPhqik',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bill',
        lastName: 'Carrick',
        emails: ['bcarrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHUJqmFbj_WJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516346705295?e=2147483647&v=beta&t=XRSRsmPK_or5nvU2H_IPKPalYry5Vd5azNPMvVZ4iGc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Taylor',
        lastName: 'Dawson',
        emails: ['tdawson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGQ7HejV8xhRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721863547869?e=2147483647&v=beta&t=L53ijToyLUKfmL9BrbXE7NewCobnondC0wSkcxJxUBo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  yempoSolutions: {
    name: 'Yempo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  labgroup: {
    name: 'Labgroup',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nick',
        lastName: 'Boudrie',
        emails: ['nboudrie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGunVBD5klTmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560638859468?e=2147483647&v=beta&t=D9sOCUA0o_0kmnjiOQj9zQaP2xNS-aX6NCxkU0qKmxw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kelli',
        lastName: 'Brooks',
        emails: ['kbrooks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHIlLnWaO66PQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1577488817739?e=2147483647&v=beta&t=1m_N12HpSGserApsqBoSF6aWUhIGYyAXq7xam7Tzuws',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eli',
        lastName: 'Silva',
        emails: ['esilva@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gil',
        lastName: 'Guminski',
        emails: ['gguminski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGx_H85MfcWVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518876288556?e=2147483647&v=beta&t=m0ILtRuV4Y4TwQ_B3tnKUKVsRbsLPLcF33SwSp7y7m8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Stange',
        emails: ['mstange@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGk8DXF8hDQ1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707456345204?e=2147483647&v=beta&t=TZ2JN7JkN-Icx9Lh_lfcUCSTUU4HuDmgUIgsPUKYA2g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  crystalblockchain: {
    name: 'Crystal Intelligence',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Louise C. D.',
        lastName: 'Hubert',
        emails: ['lhubert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGfIwv49j-5_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721118980979?e=2147483647&v=beta&t=XO-jiQpCCcoE_NT0umMEgVHdS58NcmS35Cb5iWirK1Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tiburcio',
        lastName: 'Sanz',
        emails: ['tsanz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH8MPlX8aEPhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683056629541?e=2147483647&v=beta&t=AOC3l4gbnZMf1alHr9VzmjiByLKs0v_OBS50fRUxqas',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nicolette',
        lastName: 'Brown',
        emails: ['nbrown@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'François',
        lastName: '',
        emails: ['f@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEzpdzsbm_0vA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623164762435?e=2147483647&v=beta&t=rbAx0uEU06q6gTj1grR18yrgUURoggWiItOjUeU7--0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Crystal Volinchak,',
        lastName: 'MBA',
        emails: ['cmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHLXe-WQMfJyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696251053231?e=2147483647&v=beta&t=fuXGbbkOOvOk5oPF1gm1Pp5pa9Bo4AQry_d7kVjB1Vw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '1ststepcommunications': {
    name: 'FirstStep Communications',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Umair',
        lastName: 'Dar',
        emails: ['udar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHnkhW9SGYWTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641333704923?e=2147483647&v=beta&t=lTH1wogDXml-yEts9PtDA8JcP_G5LntXeJd6Nj_KoQg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Waqar',
        lastName: 'Amin',
        emails: ['wamin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHcEwFFjZI1xQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517588329382?e=2147483647&v=beta&t=9a6GVsHCI9kRfKxwHQ6Dy8j2cpkMp6S8fKdk-tXIH1c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Wasim',
        lastName: 'Akram',
        emails: ['wakram@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF9QSkE7GEj0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677757214147?e=2147483647&v=beta&t=5VTn2-fuZwdmdyFVseJ3A3MGChlwHf2lQ9QajJlrQ44',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'ADNAN',
        lastName: 'AHMED',
        emails: ['aahmed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEQGc-8Y4pdlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725560544992?e=2147483647&v=beta&t=dohQX2ImdNAlc5A5OKlqfiktn--fI8dgK3Fepfkx74o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'KAROL',
        lastName: 'ATISH',
        emails: ['katish@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHlDc6p12p0Tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670580384684?e=2147483647&v=beta&t=wgAurwEP_vAq-cMjwQ6CBfF2uCMj9Tfh-gGrXumdVX4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  voltainc: {
    name: 'Volta, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Miles',
        lastName: 'Cox',
        emails: ['mcox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGz8WbXF7tS-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1534787515613?e=2147483647&v=beta&t=BtM5u_9bXM_JR01R79z7v8sQu0wPZh3SuVR88sQn7w0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rick',
        lastName: 'Clements',
        emails: ['rclements@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mathew',
        lastName: 'Duvall',
        emails: ['mduvall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGlKd8Uyy9XcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644254045702?e=2147483647&v=beta&t=dF-xXpfUdYD12r4YOEAUa6dVY9oUw6BuuZ44HRd4TQY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shana Watson, PHR,',
        lastName: 'SHRM',
        emails: ['sshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHKU6Sh-8iq2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578874355820?e=2147483647&v=beta&t=vpOYwSu99d-CZh7ceew2HdqZhpd5mar6m4lDCVk548o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marshall',
        lastName: 'Butler',
        emails: ['mbutler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kikiktagruk: {
    name: 'KIKIKTAGRUK INUPIAT CORPORATION',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Yoonsie',
        lastName: 'Kim',
        emails: ['ykim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Thomas',
        lastName: 'Kennedy',
        emails: ['tkennedy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEk2vtU4bEWxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516611718850?e=2147483647&v=beta&t=pbvQtygsRxLWCOK28Vv7_cUMivS2QVLiJji_pDkJKQk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Neil',
        lastName: 'Miller',
        emails: ['nmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFdz39QvYJbiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516975238873?e=2147483647&v=beta&t=vXdWx1YfBBRVyo8BLQoVOkeOgiFFq2jsAWTP0Y02prY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kirsten Cooley',
        lastName: 'MBA',
        emails: ['kmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHXZ1g-iHDQTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641964558985?e=2147483647&v=beta&t=KRPbNnsspehbrup1c5xMiiijyOdzUM1UVtY-sS3JPAA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amanda',
        lastName: 'Dunlap',
        emails: ['adunlap@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGhkYNO2R77ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517367913525?e=2147483647&v=beta&t=DqmrRTHt5T_5l3amgMopiyHEQGHAhDFdI-9Scfg0kNA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  yourenrichedlife: {
    name: 'Enriching Lives',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nidhi Gupta',
        lastName: '(PhD)',
        emails: ['n(phd)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEwlycAnMriCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703596343303?e=2147483647&v=beta&t=HB4RuTmNU5pOKgS38i4fRiwekK4p8w3d2Ga1SsrGUFY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Preeti',
        lastName: 'Gupta',
        emails: ['pgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFiul6Oo6mKPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687518734296?e=2147483647&v=beta&t=9H2Rg6qrcACoxbbUEj2ZIcAarwUV_D6ZI8lJmHy8jkM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nupur',
        lastName: 'Joshi',
        emails: ['njoshi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGXFNKtZWuTBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516313876865?e=2147483647&v=beta&t=wVRha98-djnPDzOTEI7gw0rzpRe9MBasFLTSeRrJn58',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aditya Birla Centre for Enriching',
        lastName: 'Lives',
        emails: ['alives@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGtmb_Hjp7yyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698847393765?e=2147483647&v=beta&t=Cv-0DxOjsxBdeT0DpD-PV0IhCyWn25YvSVDEPzCT2Jg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Padma',
        lastName: 'Rajeswari',
        emails: ['prajeswari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFn6U4ZnaSobA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713335169683?e=2147483647&v=beta&t=SGAf0kZFfla_fA5ZMpxXaCz-l4hcKOut6mpPFvE8RjA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  abntechcorp: {
    name: 'ABN TECH CORP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bhaskar',
        lastName: 'Reddy',
        emails: ['breddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF74qu9t7q_FA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723497741969?e=2147483647&v=beta&t=k70pNdqLQibaJga22J36RyMj2BUD6Y5SBCMYwijqOEI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sanjana',
        lastName: 'Kapoor',
        emails: ['skapoor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGe2-gIDgK2EQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725650907799?e=2147483647&v=beta&t=qrrguMx7cgq9JBBCvTqsVlsgkHaRgSOyfPxRuBmiaqM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mamatha',
        lastName: 'Karumanchi',
        emails: ['mkarumanchi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH5VVxpaRKEQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719592387555?e=2147483647&v=beta&t=aoaz8Ufa6rMt0RHCG6ZxuDUCcf7GUDJcZMu1PJXkUW0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Narasimha Prasad',
        lastName: 'Nandyala',
        emails: ['nnandyala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEqcBWEDqIfEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516582234369?e=2147483647&v=beta&t=DVGxVZWq5OGQkFWDwzV72FgSnXzFW4T42yCBZ8PPVy4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vedant',
        lastName: 'Khade',
        emails: ['vkhade@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEsRWCNtz0Q6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684515771352?e=2147483647&v=beta&t=OQFR0f7NhgQu85ig024pCWzIAlHX9GOuyCyfghWZqeM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  genieNetworks: {
    name: 'Genie Networks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aristotle: {
    name: 'Aristotle',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aristotle',
        lastName: 'Georgeson',
        emails: ['ageorgeson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHoQKh0ZnzW1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516575659738?e=2147483647&v=beta&t=v_sbLbzdxfuTsorxQPhAn1XcDXYG-Vpa3NwOs-xtoRg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hillary',
        lastName: 'Aristotle',
        emails: ['haristotle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFpb_05BjHPCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701460879116?e=2147483647&v=beta&t=AzGSKKe2l7MyW3-MBt5EsM3jzUL8mGx7vh1Gd73txv0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aristotle',
        lastName: 'Vainikos',
        emails: ['avainikos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHY_BpptvnRRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1640917246672?e=2147483647&v=beta&t=5j4IEnUtS0Z2dbRB-KLjLSUDrB9oSoxEw7Lecawzu4o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aristotle Garcia Byrne,',
        lastName: 'MBA',
        emails: ['amba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE17xwBG9g2ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715118712258?e=2147483647&v=beta&t=vkwrrEh7b_AyVFepvmqTyPOo7D31r_vCA2aFF8x2hS0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aristotle',
        lastName: 'Smith',
        emails: ['asmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEOR08VbYLPvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617657585514?e=2147483647&v=beta&t=meZK_726xeJnyBs75VgBXZn-iEhcbMAy9O_ZCPjIIps',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  besmartee: {
    name: 'BeSmartee',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Geoffrey',
        lastName: 'McCaleb',
        emails: ['gmccaleb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEmNsmR4BILOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699989906489?e=2147483647&v=beta&t=99D0LmH5BVB9fRsEma7LwjkQ6W-I9kUBFxLqK1KmwKg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'King',
        emails: ['dking@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGjn3P4Kyq_dw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516337806651?e=2147483647&v=beta&t=rrzQv7u14TtRqBWQc3EqHGqqnyI6Ld36G1lIe1q6FhI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sabrina',
        lastName: 'Park',
        emails: ['spark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFjhyaANoNq-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722453038678?e=2147483647&v=beta&t=UpvA1CnrPDtTmFQRlRLeRqkwrb91dvzTeNZoxDjV9UQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Veronica',
        lastName: 'N.',
        emails: ['vn.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFKW8TOutU5ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517440203065?e=2147483647&v=beta&t=CrC9ORyXYZfbN999dbl9vW4snlLf0xZ2YmNE0DsGREQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mina',
        lastName: 'Samaan',
        emails: ['msamaan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHzJLiGh3UDvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646704100152?e=2147483647&v=beta&t=ydrazdy0-cEr8p2nvtwNSgsyenZZi-9ncqQTvddPzqk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cloudambassadors: {
    name: 'Cloud Ambassadors',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Richard',
        lastName: 'Eliab',
        emails: ['reliab@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGUkMQNiKPXMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1731073968780?e=2147483647&v=beta&t=pR-yxoo1IGet9OOnA1IY9d7mRb6D2kdyMniwupThvA0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John Solomon',
        lastName: 'R',
        emails: ['jr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFEDcRTrHUroA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728879332834?e=2147483647&v=beta&t=LfiPiB4GbqDhjbpx_Ozl9SbDdhvM6ISq93Etl4m5eho',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nandini',
        lastName: 'Agrawal',
        emails: ['nagrawal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG-QD4OZBIWYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660978170933?e=2147483647&v=beta&t=ACBOcUHJjAyfc0YlJzOAV1XfIvBhqoHTbgw01CZ7_pA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kondasamy',
        lastName: 'Jayaraman',
        emails: ['kjayaraman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mohini',
        lastName: 'Mehrotra',
        emails: ['mmehrotra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFoHlFOzenRPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718279608172?e=2147483647&v=beta&t=JRdj_Zti3guGkT6Ag1dHidfxgtahyrcro8yL_o_Vhfs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ansibleGs: {
    name: 'Ansible Government Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Wheatley',
        emails: ['dwheatley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEhLVBIMJjr8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628638599744?e=2147483647&v=beta&t=dgWRzkQP0GxO6Woz5LDi-LIDoX8wW1vJJExJEcFnxRw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aimee',
        lastName: 'Nguyen',
        emails: ['anguyen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHyF1mgIE1EsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693269667827?e=2147483647&v=beta&t=WSE9rIq90t417DXhY_kJJ_DNb71YDTrSqC7DJWo0WxE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jacqueline',
        lastName: 'Scott',
        emails: ['jscott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGizWSVNnyWSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517357342335?e=2147483647&v=beta&t=8bJpM-CdN0u1USl__dQeP-ckhdKGn96I49dYkTG4yt0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carol',
        lastName: 'Johnson',
        emails: ['cjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGXURrvkVqPdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556295238834?e=2147483647&v=beta&t=WRtwD8qI5JIBEzxRcnYGkqWddIemSGaC3hlQtdjgBAY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Leeann Bond MBA,',
        lastName: 'BSRT(R)VI',
        emails: ['lbsrt(r)vi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEQ21gxLrwmXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608306761757?e=2147483647&v=beta&t=zftbBR7TkQJjXCwkDWJwXzXgkDQq570jC_nK1VRE85Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tlmpartners: {
    name: 'Endava Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'James',
        emails: ['mjames@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHXW0avHGfUvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727377008080?e=2147483647&v=beta&t=POY_W7Vykv5O5tu1G7v3c3ivwEN0yyqAsfThQbqxPnA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Benjamin',
        lastName: 'Shutt',
        emails: ['bshutt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'CASSIA',
        lastName: 'DOMINGUEZ',
        emails: ['cdominguez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Todd',
        lastName: 'Shallbetter',
        emails: ['tshallbetter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEtgZHFV--USQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604932505817?e=2147483647&v=beta&t=71i7673dYUZ6DwADxbYQotf-VzMaumb_NYYLRIXj2XU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jake',
        lastName: 'Proffitt',
        emails: ['jproffitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF3sfcRqGPmlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697820129971?e=2147483647&v=beta&t=fv-hHke5c-mJGcYHhrVJ96PNig45kDpaSugA49MKNYI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  qenta: {
    name: 'QENTA PAYMENTS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Blankinship',
        emails: ['dblankinship@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH9mhayw0NVwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674935511073?e=2147483647&v=beta&t=lQd-eE7nfrZc0AIs_Bv4bCxIJfoQPxsQmQV-tBFWgX4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniela',
        lastName: 'Combe',
        emails: ['dcombe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQETLm2wus7tdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727365155733?e=2147483647&v=beta&t=ic6fkLZpyPHOCo4CAuOXt10e8iJx2KidU61_e4ghK7A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Spencer',
        lastName: 'Burnham',
        emails: ['sburnham@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yehuda',
        lastName: 'Jacobs',
        emails: ['yjacobs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEV-i7Sa7ZnKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712578718994?e=2147483647&v=beta&t=O3U4s11vFEtnmUcdOSP7OiM3cU5rp4KgO7pk0gH-51s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ali',
        lastName: 'Crawford',
        emails: ['acrawford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHHsjNrpkSWXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714183652799?e=2147483647&v=beta&t=tyMt9kavHIkxQvQyxFSYDnB_Loj1ONrug5rjJ_G2PEo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  henryschein: {
    name: 'Henry Schein Services GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Seema Bhansali,',
        lastName: 'Esq.',
        emails: ['sesq.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGC6oy__UPBEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516621286940?e=2147483647&v=beta&t=uUtFdz7Sp5Dz4EwUr3pgVauQxXQjkmtv4i5Hmcwl6PY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kristyn (Valentine) Behrens,',
        lastName: 'SHRM',
        emails: ['kshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGXtpFO51xUqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714016317180?e=2147483647&v=beta&t=WrE8QxEhCZKlhH-g_3s0hhVk0r6nGzDunl09SEIRpJM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jorja',
        lastName: 'Marshall',
        emails: ['jmarshall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFcTcl3kJPDVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613659664257?e=2147483647&v=beta&t=D39VA4mTG7borKbJNYCeB0r6FJA-NZneT6fk3M7Sszs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Derek',
        lastName: 'Brite',
        emails: ['dbrite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHt78-adtW4IQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517726166041?e=2147483647&v=beta&t=KQ0BUS30NtWfPOoC_vdiQDUvW00xnrE0PEtH-hczJTI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'J.R. “Javi” Lozano,',
        lastName: 'MBA',
        emails: ['jmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFlOOoahd0Z6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713234802888?e=2147483647&v=beta&t=FQDF8y-uN4B19dM54S4Z4AMAQu5M54nQRffIpRfVvPo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  compuDynamics: {
    name: 'Compu Dynamics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Youngblood',
        emails: ['dyoungblood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF4wTA5Pxzbmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672891755891?e=2147483647&v=beta&t=2ls0qv6ons0dgzsb1YqbTvcwkLHt-D5t6cph6pOqbqA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Viggy',
        lastName: 'Hegde',
        emails: ['vhegde@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE_djXs7fPZXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722993777000?e=2147483647&v=beta&t=mVdtWWk78oWw-klbgRIHukWLAoJ4j4narf9ZS6ePiaY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mike',
        lastName: 'Durniak',
        emails: ['mdurniak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG3moy5r-_bpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542219262180?e=2147483647&v=beta&t=MFtmo_GdID1so9MEoNf3G6jd9GB-mjdXnhuaodzcu0w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brent',
        lastName: 'Cato',
        emails: ['bcato@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFRh_kDdTLagw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689858107081?e=2147483647&v=beta&t=3C-BdB-mw1ie-sViLFiQaaVCuoFgMSM0KbbOwybYrYI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark A. Miller, PE,',
        lastName: 'CGC',
        emails: ['mcgc@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  engineeredintel: {
    name: 'Engineered Intelligence Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Hyland',
        emails: ['chyland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH5qr4y2e0kTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585578063297?e=2147483647&v=beta&t=8mLt35N21Skr-YskgRb2KpBywqjA0kABMru-RP-Le5o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ted',
        lastName: 'Zalucki',
        emails: ['tzalucki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF27F3TIXkUAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668741155318?e=2147483647&v=beta&t=WFbbZCzPZTH6Yt-QMtTXhN6rsOBk6idTg0iTiIoNshU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Adam',
        lastName: 'Brown',
        emails: ['abrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGyySfcaNdKvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730051448474?e=2147483647&v=beta&t=k5DH9C5jOnRsnKJhx7DrW684klnmeSKrRCGNlyHT6vE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Claire',
        lastName: '',
        emails: ['c@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHWWRA_Pfq-Bg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634687729245?e=2147483647&v=beta&t=ROc3N_pDnTIsCTwjIb9HWGcS5agoIV7LioOXv5m-0nM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alex',
        lastName: 'Kachar',
        emails: ['akachar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cloudsynapps: {
    name: 'Cloud SynApps Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Utkarsh',
        lastName: 'Bhatnagar',
        emails: ['ubhatnagar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGr28oLtrITFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642634391955?e=2147483647&v=beta&t=q2Ci8IrzbaK5JGSLXYDrkEuCLhJQSliwVoEwFY_nEwc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alex',
        lastName: 'Carroll',
        emails: ['acarroll@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE1qHoSmVHcpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693940244596?e=2147483647&v=beta&t=IU9t3CMaTQQzMBD0r6JGN926a5hAnUucy2C3MpVgoC8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dale',
        lastName: 'Wilkinson',
        emails: ['dwilkinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEgRTITJwdDew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569429199747?e=2147483647&v=beta&t=LLML1RI46cbOoVgL9Hv-1q3TcFGlv0MHUEWazjv76E4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ajay',
        lastName: 'Sharma',
        emails: ['asharma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aditi',
        lastName: 'Bhatnagar',
        emails: ['abhatnagar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFMuqlGmW0hOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1533935340586?e=2147483647&v=beta&t=9cJGAZu31_RU61GmI4kmY0C59CA8oeyEhxv6KBLwwhU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  interapt: {
    name: 'Interapt',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ankur',
        lastName: 'Gopal',
        emails: ['agopal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG_1KnUcfrKeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629136769778?e=2147483647&v=beta&t=xTHoPhyXYj4RqBvG3-AtEZrd3t4iDvM68iJv5pJIBAg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kelsey',
        lastName: 'Halley',
        emails: ['khalley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGQX946DNYfWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684185630462?e=2147483647&v=beta&t=yXZYfOraw8015Ppegju7J6wR_a5xr4YjvvUlVAm0aWs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dianna',
        lastName: 'Coker',
        emails: ['dcoker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFkS5fHeHHJfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685972742312?e=2147483647&v=beta&t=68kDqdZ1S2gdeE4svWCZKcYw36Xetiau2-2oIm-JFVQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jack',
        lastName: 'Curtin',
        emails: ['jcurtin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFgKsybpEc4Mg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637774916103?e=2147483647&v=beta&t=rODovq03YG0IPlnrCLYG3ZDkc0atuURnqIvr5E3-MEM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Whitney',
        lastName: 'Woodruff',
        emails: ['wwoodruff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGfk-4nbXvl1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672321960788?e=2147483647&v=beta&t=Lf9o2ATCV7_BDTEoX1jSWSNclUF4T5_onGiSQfftYSg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  egroupUs: {
    name: 'eGroup Enabling Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mike',
        lastName: 'Carter',
        emails: ['mcarter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE04kiZCGdL8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700255160808?e=2147483647&v=beta&t=q2Quwo_-vgUYjmbSDBOupyh-qkJrkCnFKIp4Xg4dpyg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Colleen',
        lastName: 'McDowell',
        emails: ['cmcdowell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daniel',
        lastName: 'Navarro',
        emails: ['dnavarro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGRK6ZfdMPsOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704193227082?e=2147483647&v=beta&t=q-K-S_hDeKthdcFPJtDe7SKnmqNnO7KHJY8m90JOvZc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christa',
        lastName: 'Anderson',
        emails: ['canderson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Estenson',
        emails: ['pestenson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHbchw-8Kdnow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516286916241?e=2147483647&v=beta&t=qIScwVQrIyo1CZyCNAuyePI5n0s1IB4RXWSKjTCg_Rw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  digitalhands: {
    name: 'Digital Hands',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jordan',
        lastName: 'McCann',
        emails: ['jmccann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEniAxllu81TA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556903949322?e=2147483647&v=beta&t=NQJk-TAaFplegW6Fj9inclxs3rOvEdUFZpLyQePUlwQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Phil',
        lastName: 'Hillhouse',
        emails: ['phillhouse@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Laura',
        lastName: 'Cavanaugh',
        emails: ['lcavanaugh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFZIwe3F-eQvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692713522292?e=2147483647&v=beta&t=dXPfO_3WgEh5d3pVfaUM19R1AOBEU4CeKd6DObN3_qc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shueib',
        lastName: 'S.',
        emails: ['ss.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHWcFhY8P927g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697821831488?e=2147483647&v=beta&t=b3kRK8ABBF-btxRBNtrX6QwTy09SG4IDtcec90V8208',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matthew',
        lastName: 'Kolnicki',
        emails: ['mkolnicki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFHtkyZ5_ZF8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713113496407?e=2147483647&v=beta&t=-Aj874OaHSnwCmqdqD5QdJavBUB8epsxKvbpFQkiTh0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  baycountyfl: {
    name: 'Bay County Board of County Commisioners',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Shark',
        emails: ['kshark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEPhcMLqJ09VQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664660127629?e=2147483647&v=beta&t=NLgiSHHC5lsnrlQPeS6HdpYuAeG8ogNZreAKmNpT7hc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Donna',
        lastName: 'Billow',
        emails: ['dbillow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEAVqZh6TUMIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658447516613?e=2147483647&v=beta&t=9eDUHJUg8iLl_VleTPzHK9bXc8daGneJB6wFKXo-Pfk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Victoria',
        lastName: 'Routh',
        emails: ['vrouth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFSDSeZz6KQ4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606230784720?e=2147483647&v=beta&t=LBEZ7hK4L_ScGKnOsfzPLjqGbYB4T9426b5--AjodTI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Samuel',
        lastName: 'Fitzpatrick',
        emails: ['sfitzpatrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEX6KmUnmo8ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728442131355?e=2147483647&v=beta&t=CV0wP9SFky3QkcpOIK7H2TZe4xbpRE5xQbcSZ5RuHjw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Benjamin',
        lastName: 'Moorman',
        emails: ['bmoorman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF88hSy9tVWwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620087468775?e=2147483647&v=beta&t=18Kt_WRKYakKt6wfwDgXBISwV5zaPYVzK3PHNt52sEw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ergoserv: {
    name: 'ErgoServ',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dmitry Babenko – ErgoServ |',
        lastName: 'LinkedIn',
        emails: ['dlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alona Koruma – Project Manager – ErgoServ |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE_wIfs_gQPlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698408738053?e=2147483647&v=beta&t=aQL5LImnv5AiX2gm9T3T73kMDfMsobeDZq4tdhc7Cr8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yurii Stetsyk – Ruby on Rails Developer – ErgoServ |',
        lastName: 'LinkedIn',
        emails: ['ylinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Raphael Caetano de',
        lastName: 'Oliveira',
        emails: ['roliveira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHw_8COsIMNLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549327875877?e=2147483647&v=beta&t=j_y3x6hzubDh_atPdpXNiwJk-0xXsx23aNyB43sPEZ0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Wesley',
        lastName: 'Oleynik',
        emails: ['woleynik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHl3e7pMhjDig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715960407570?e=2147483647&v=beta&t=BEIouv6CQ52zUiDiZ2ro51UudxQUN_20niVFe--9U7U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bewith: {
    name: 'with',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rachel B.',
        lastName: 'Lee',
        emails: ['rlee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHnFifHMs8taQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629481273174?e=2147483647&v=beta&t=Nm6A7i0cG1L29X1VEyTCRMy2BTQrciDAD975KocG36M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'B',
        lastName: 'Cavello',
        emails: ['bcavello@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'George B.',
        lastName: 'Thomas',
        emails: ['gthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGYjB97FN1a6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694095489055?e=2147483647&v=beta&t=cLggqkVYxKCFWSgKEdUlsr19aeRsXO-Eb63wIeZbAFo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alex B',
        lastName: 'Sheridan',
        emails: ['asheridan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQESvmEdUZn2cw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719365049912?e=2147483647&v=beta&t=0O4FzneTVfzF_7C_sBF0YperzF4QPWl5tk-TeUsFwNE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Timea',
        lastName: 'B.',
        emails: ['tb.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFbqzGE9IZ4hw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702584029195?e=2147483647&v=beta&t=SDmlBTEhMvXAt-NtaQTR7LJGyx46xf8_6JKDjLJsA1Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  optomi: {
    name: 'Optomi Professional Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ashley',
        lastName: 'Crawford',
        emails: ['acrawford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF3W4sAbNnsYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727122303438?e=2147483647&v=beta&t=yWqxlxUVG1ths_FVRqqzNGbjGM7gfY8_ef1ksSzT3no',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kenda',
        lastName: 'Eubanks',
        emails: ['keubanks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE8LhqCSKK5YA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580154316427?e=2147483647&v=beta&t=cOH1cpgDmRqXMjslCuWhMgIpmi1mDKeZI0E2VnsA_lE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Charles',
        lastName: 'Ruggiero',
        emails: ['cruggiero@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHQiFxp3Xyuvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517152893714?e=2147483647&v=beta&t=jdLLj_OaINQmSX4InAPMRCvdBPhA_klxLPzdXhpo1Q8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Winwood',
        emails: ['mwinwood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGPts51MCia5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543283244725?e=2147483647&v=beta&t=Jk1MSnpHtk2OSdsK-W-olZLoMgVYBQoEjJp3kfy6phQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tony',
        lastName: 'Pritchett',
        emails: ['tpritchett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQExTxXjasTdzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685925037881?e=2147483647&v=beta&t=6cSl8Qb72pEcjzbocSUxnncl_e84MPNxVUV4aBm1xYU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  silverlinesolutions: {
    name: 'Silverline Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Trey',
        lastName: 'Nichols',
        emails: ['tnichols@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Jackson',
        emails: ['mjackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGOVZ2f2PDi7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699540366860?e=2147483647&v=beta&t=clNFBuT2DbXoa2iW3w0Umy5CYJ4_BVePdD0-TTDBykU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sean',
        lastName: 'Burkett',
        emails: ['sburkett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF5j35dy_Lisw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651817017872?e=2147483647&v=beta&t=sjthG2xK87joSiYUmvyPFjaqioszq-RXt1m1WQesau8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Josh',
        lastName: 'Walston',
        emails: ['jwalston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHkQZnqMF4xew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686536961617?e=2147483647&v=beta&t=wPNFcK-oaxJGZGJWB6X5iAnThOUNzgZYYdq5WiN2uRY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Daniel',
        lastName: 'Grotjan',
        emails: ['dgrotjan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  growbydata: {
    name: 'GrowByData',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Prasanna',
        lastName: 'Dhungel',
        emails: ['pdhungel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEikpZN8M55Eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649994443333?e=2147483647&v=beta&t=wfIN-5fk0-p7bQyRlDpMpP_H0P5ctG-Nv6pnbYcsd1M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Subin',
        lastName: 'Shrestha',
        emails: ['sshrestha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE5ojmfABIjrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724161225145?e=2147483647&v=beta&t=GwZTk4093tnBmixil5MkG_ERHi3SU_2_yExsQ0wsxRM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andy',
        lastName: 'Komack',
        emails: ['akomack@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEX2cnJ0Q_53Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710170265031?e=2147483647&v=beta&t=atFB-LEdWb4dmT0wvM0KE0GXY9am2cBQUgPG9YQLFdE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sonal',
        lastName: 'Joshi',
        emails: ['sjoshi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ambika',
        lastName: 'Adhikari',
        emails: ['aadhikari@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wochit: {
    name: 'Wochit',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jonathan',
        lastName: 'Lange',
        emails: ['jlange@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGC4i0PPIyoaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517827538923?e=2147483647&v=beta&t=cDYMyogta5elnkQAwYsnvv6tcf4OP8Lh_iY4ZwW5Hv0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jason',
        lastName: 'Harris',
        emails: ['jharris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHA_6P1PxxUXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724902594093?e=2147483647&v=beta&t=UrTm_VZvQuwwK_Q09iURYyVXepRxj2-528t6YaBr7as',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patrick',
        lastName: 'Tinnelly',
        emails: ['ptinnelly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGl1hvIocys4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561132348125?e=2147483647&v=beta&t=HeThHgavjeWDlk7Q-erUGpUZ5HYmMEHHvH82VsAMBaY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Liel',
        lastName: 'Bari',
        emails: ['lbari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFPfzLf4AXVFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1545662553734?e=2147483647&v=beta&t=fi3__yVEFWhbegEqdEawNYeX3CfqE5ykij3hTirPtqU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dror',
        lastName: 'Ginzberg',
        emails: ['dginzberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHU0BM092bzNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685622905640?e=2147483647&v=beta&t=YOc-umIVf2KkqXQYqHvt1pK_WEAfsvbRj2yOE9KSnaQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cupix: {
    name: 'Cupix',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Merlin',
        lastName: 'Love',
        emails: ['mlove@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGBFopAV6z-5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705439560452?e=2147483647&v=beta&t=5aoZlBFjlOHUJ96fPJI7TS9stQdmTq02rLpC6zbbDZs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jennifer',
        lastName: 'Alatorre',
        emails: ['jalatorre@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFjE0UNi_4fIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669661395114?e=2147483647&v=beta&t=xUy66ySNsd8-djgF4e1BRCEiIhxEDusGMJsqY7XwcMU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Simon (Seockhoon)',
        lastName: 'Bae',
        emails: ['sbae@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFtTBHO9R4ZHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668730070774?e=2147483647&v=beta&t=aPeb2uySeHmn-fv_j1NV0UYdFz6f7eR-9EDETpGmmlY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joshua',
        lastName: 'Bibb',
        emails: ['jbibb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHVCg4Beb7TQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570224737349?e=2147483647&v=beta&t=V3Jnt_22fRGX4BtxMeSeEdsTLD4AL_6e08ns3JvZTLg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Scott',
        lastName: 'Anderson',
        emails: ['sanderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHK2sTInDR_zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517753179424?e=2147483647&v=beta&t=sfYxPReLlTPVmGlqnqx1FYPe0r0oicTCSjacROyl1Mw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  flywheel: {
    name: 'FLYWHEEL Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tomoyuki',
        lastName: 'M.',
        emails: ['tm.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGBj55TSVV9Uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707710312315?e=2147483647&v=beta&t=WLv8N-AWOkdCeZ3nCipEpeKMmFwcYMay1yjJk3qiGhI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Toshiyuki',
        lastName: 'Yamaguchi',
        emails: ['tyamaguchi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Keisuke',
        lastName: 'Tsuchida',
        emails: ['ktsuchida@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hirotoshi',
        lastName: 'Matoba',
        emails: ['hmatoba@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jack',
        lastName: 'Cho',
        emails: ['jcho@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHASv4vUlM58w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580452784593?e=2147483647&v=beta&t=ADFOJoyp3E_tocDp-pQBOoioTRMS8-qsphwh-AZINac',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  interfolio: {
    name: 'Interfolio from Elsevier',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Bishop',
        emails: ['mbishop@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFkv9hundrEyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558405508857?e=2147483647&v=beta&t=XdddsPfEdKcfwKnZ0Jyfy7vtbMO4j_aSS0zDbCHhKyg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steve',
        lastName: 'Goldenberg',
        emails: ['sgoldenberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEkBrEvQvOUPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650486590794?e=2147483647&v=beta&t=iAM99fmTagWTP2Z7ptOEifK7Hmx63S-Omf5wLKhjV3U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rohan',
        lastName: 'Thakkar',
        emails: ['rthakkar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH11BLeTBbLYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727877267258?e=2147483647&v=beta&t=-AgjIIc3aDABkTW8Zl9zDAtk9ZJoVIGqTkQFHyS3nug',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Scott',
        lastName: 'Wymer',
        emails: ['swymer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zach',
        lastName: 'Ulm',
        emails: ['zulm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  atorusresearch: {
    name: 'Atorus',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eli',
        lastName: 'Miller',
        emails: ['emiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFu0D5OXBssXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595597357877?e=2147483647&v=beta&t=SxGig2_GHUIdl6Ql4zZ6YbFdz_TYlorfk2LCllypXsk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Penniston',
        emails: ['mpenniston@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: 'Bates',
        emails: ['abates@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGBMqNLRvS-pw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517997518134?e=2147483647&v=beta&t=7cxW3SvBcvWbW0iSJ-VlW7KofXlMwMiCv0M_TrXDMRk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jon',
        lastName: 'Harmon',
        emails: ['jharmon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEn8-G1VdS3LQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667660653501?e=2147483647&v=beta&t=el_U-JZjbbVmVzfsf-uD4O7Mw6RHPbUI5eth9d-Xjq8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Casey',
        lastName: 'Higgins',
        emails: ['chiggins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  oakwolfgroup: {
    name: 'Oakwolf Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kyle',
        lastName: 'Parker',
        emails: ['kparker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFBWUSoO1itgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677102025503?e=2147483647&v=beta&t=jePHHlgGuQdlfjMzANBkavYy762LiTePtp_w6sCIWcI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Duncan',
        lastName: 'S.',
        emails: ['ds.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dan',
        lastName: 'Walsh',
        emails: ['dwalsh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGI9xN6gjlENA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1540261838322?e=2147483647&v=beta&t=m-D5vn5C7Ox2WRX9C3ATh0o5JNJmRn2YYKonVAHLcbI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Grant Gonya,',
        lastName: 'MBA',
        emails: ['gmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHzOL4z53cnxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718319566366?e=2147483647&v=beta&t=KDTyAOotmPnfpf3s8kO0t9kViBHdUZcm93LPA9jNn7c',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nick',
        lastName: 'Burton',
        emails: ['nburton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH4Eq-p3jh-QQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579120258061?e=2147483647&v=beta&t=elr5fxm21BUcb8_qM8UpsAMi4-sdSleXLHlzl1fErjU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  geetoo: {
    name: 'Geetoo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matthew',
        lastName: 'Gover',
        emails: ['mgover@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEH_LaAXCNPCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694540409274?e=2147483647&v=beta&t=okH_RvHhCnuYon0Xe31PbvdrvsgI9-rqKpzi817pMwY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rocío',
        lastName: 'Coccio',
        emails: ['rcoccio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH0LVZ9t63R0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675105729688?e=2147483647&v=beta&t=BqeOdQByX1DsMIhNz_x6HR5yRqWFHeUi0upkPVEhGJM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Giuseppe',
        lastName: 'F.',
        emails: ['gf.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFEb23-hlRdNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702749012778?e=2147483647&v=beta&t=cmNju3bCW4GKaSQxw7M12By_sscN2cnw_PpP_Z5Hu7U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Katarina',
        lastName: 'Stojanović',
        emails: ['kstojanović@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF05EXOuXKf5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718710938790?e=2147483647&v=beta&t=ZLBs3E1ywQtT-kOyox2I2cRtgYbAr6HXBCvL42KJTgk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Maja',
        lastName: 'Jovančević',
        emails: ['mjovančević@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGNbRUG9cMx1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718295000924?e=2147483647&v=beta&t=rKnwyHM05xG3eE98uyulxViMDqdViOOoAQrclUAe1ao',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  adesso: {
    name: 'adesso Spain',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Horatiu',
        lastName: 'Moldovan',
        emails: ['hmoldovan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHji-NJAm-VrA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680105006657?e=2147483647&v=beta&t=8ujpudD9z5K957U4OY3dNrhKKL1MmCXx0fhlHMZQjh8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marc',
        lastName: 'Carreras',
        emails: ['mcarreras@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEK9iehMOVYng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579535040984?e=2147483647&v=beta&t=12OLkiiqPpu5-8KW7s2ZmsIX9w-ak_q2ZnRg8ZQkdr0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Manuel Ruiz',
        lastName: 'Fernandez',
        emails: ['mfernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFjG14TkFSfqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669663485031?e=2147483647&v=beta&t=AQxPlDZHzcvjm_DpSowLzKXElmoht1Y698Xh07q3VSQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Juan José Vivas',
        lastName: 'Pérez',
        emails: ['jpérez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vicente',
        lastName: 'Lage',
        emails: ['vlage@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFwVD1NoUP8EQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713782545996?e=2147483647&v=beta&t=yAya1uLnsXqZC-_l2qrLVz-J6W9lGVAanfahUeOJfAA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  utopiamusic: {
    name: 'Proper Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tom',
        lastName: 'Ruczynski',
        emails: ['truczynski@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Rusch',
        emails: ['mrusch@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Katie Suttles,',
        lastName: 'SHRM',
        emails: ['kshrm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andre',
        lastName: 'Schultz',
        emails: ['aschultz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGeKuwZJunojQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517453181285?e=2147483647&v=beta&t=NlfnjsgvS2KGqGD5OrmkZFgd_FFc4nqXo8MhW6d0JcY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Hahn',
        emails: ['dhahn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  paradisosolutions: {
    name: 'Paradiso Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sach',
        lastName: 'Chaudhari',
        emails: ['schaudhari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEg3iJa1j9dsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706535275613?e=2147483647&v=beta&t=7Q9BszVqb5rckiNiyA0Jyl5_FqV80Zz17d9N_zxgWgk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Herman',
        lastName: 'Miller',
        emails: ['hmiller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE1eImadY-4qQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517376181773?e=2147483647&v=beta&t=_oL4HlveaobRi9tTadZiLwIhgyPu-edNaB6XITPungI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jacob',
        lastName: 'Romano',
        emails: ['jromano@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Melinda',
        lastName: 'Paradiso',
        emails: ['mparadiso@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGW1HW0Gas0eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655780207887?e=2147483647&v=beta&t=3aRWRB4x-aY1ZPZrpafad6OH_MFkzABRAeSR8co2kvs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mary “Catie” Paradiso, MA, NCC, CASAC, LMHC,',
        lastName: 'LPCC',
        emails: ['mlpcc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG-fNJicXPmTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517465114577?e=2147483647&v=beta&t=fBtDSYzkeIVPogsGM1rvqggCj47RhveEl_YOm6iZXpg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  predii: {
    name: 'Predii',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tilak',
        lastName: 'Kasturi',
        emails: ['tkasturi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGsA_eBBRAjJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646515196334?e=2147483647&v=beta&t=fAdDt0czke-HeBOP7haBv7LxiLeS5UWUkbmbQQvQMso',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gary M.',
        lastName: 'Stephens',
        emails: ['gstephens@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'Seng',
        emails: ['mseng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHWjuJk4MbwZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606161725643?e=2147483647&v=beta&t=8QDZw-0JB9Be0xx19MPUSfQWu3GQuPPaAGiWD-FcsPA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tim',
        lastName: 'Eisenmann',
        emails: ['teisenmann@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Priyanka',
        lastName: 'Nichkaode',
        emails: ['pnichkaode@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGzv5cnYZm8Kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1565775332606?e=2147483647&v=beta&t=s-_iS6HisMxaUHx5NeD3Ym9DSMWYWSqW0DbyhLlbI0M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  constellationwest: {
    name: 'Constellation West',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Kelley',
        emails: ['jkelley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQENlU3zm_Iv6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1530927540706?e=2147483647&v=beta&t=1jhEfzCM1T3hsi4aoXSEfwvDl-0gqa_7ht36MpZ4KtU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joel',
        lastName: 'Merriman',
        emails: ['jmerriman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGDIuLcRA92Vw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695757059363?e=2147483647&v=beta&t=LVlYavOXikfnSjRrTkWhKFZn_1YF8ONucw14sWf-Gx0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kimrey Davis',
        lastName: 'Tefft',
        emails: ['ktefft@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHPzSIzNHIEXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614691578668?e=2147483647&v=beta&t=br1fFhiRZpt6FU80tX45f3QQN-VREA4Rlaw7nhrtEQQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Mazzulla',
        emails: ['dmazzulla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lisa',
        lastName: 'Wolford',
        emails: ['lwolford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFTaoLKe6-ZmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610734257892?e=2147483647&v=beta&t=AJ7N4eUEm1Rv5ZyrocreoTtBvnLbAhGe-km8eBHDCW4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  egogohub: {
    name: 'EGoGo Hub Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dynamic: {
    name: 'Dynamic Technosoft',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Deepak',
        lastName: 'Sah',
        emails: ['dsah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHx9TwXcL8VMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1576941733633?e=2147483647&v=beta&t=6pCFHt8kSbzrFVOmF4Ar41V-4K5UnErsQdR41bPBPAs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sandeep',
        lastName: 'KC',
        emails: ['skc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF_6bMUZjn5_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727242114756?e=2147483647&v=beta&t=NleM5gvxCfCKgYQjCyBK6t3rdHVJCRMUz4iECG1qADE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Arjun',
        lastName: 'Sah',
        emails: ['asah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQESJdLUe-DEwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681594835961?e=2147483647&v=beta&t=J3bun8bM1p6Xa1TJ5rYjAftUwfezRnW71dym1agUjNo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sagar',
        lastName: 'Poudel',
        emails: ['spoudel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHn4CLvFLbsUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714561088790?e=2147483647&v=beta&t=4gwXIK6TcLd3k1L0f9bmckl-GI-7_amuM-o39ZoeGgo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Suresh',
        lastName: 'Paudel',
        emails: ['spaudel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHT44ZrMzU91w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729136441898?e=2147483647&v=beta&t=Jcyp--DmdhDTCsyEQUobT5JYO2nnVC71Ad68vUd4NJc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  teckinfo: {
    name: 'Teckinfo Solutions Pvt Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nalin',
        lastName: 'Mital',
        emails: ['nmital@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jitender',
        lastName: 'Tiwari',
        emails: ['jtiwari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHubrs2ymlX8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658840858667?e=2147483647&v=beta&t=b9pTfC7iDpmf5XQzdCY5jooagRDnhqMPckGgEM14hx0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Abhishek',
        lastName: 'Bhatt',
        emails: ['abhatt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFzahsVseQbpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666852461563?e=2147483647&v=beta&t=Xu8Gq-cM_6wHKd4Lxbpwv7KtTXiOdowaIEjRujK-RnY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Uday',
        lastName: 'Vaishampayan',
        emails: ['uvaishampayan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHVagNWQXCbnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516304745760?e=2147483647&v=beta&t=T67zivAFGpqaX9MSVuqq2oxSN-In2J2TGIAP7ozxDD4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'K Tarun',
        lastName: 'Reddy',
        emails: ['kreddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHo3FyvCoNC7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672734376579?e=2147483647&v=beta&t=BH7_dqtmXopJrz1OGjegbxri6S4ZOesBaa5NApgmohY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  javna: {
    name: 'Javna',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jesse',
        lastName: 'Javna',
        emails: ['jjavna@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gideon Javna,',
        lastName: 'LCSW',
        emails: ['glcsw@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE9nzQCbXa9Ng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593020501698?e=2147483647&v=beta&t=dAdDsG8-fcMYEP9Rhuq6fLCMNBtBe5DD8kQMLXF6ByA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sam',
        lastName: 'Javna',
        emails: ['sjavna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG3O2siYwvsWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631747306074?e=2147483647&v=beta&t=OW-8fdQVGrXqDUBdq1kuX8jPKIIhsAg44fSeHaRvbw4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carol',
        lastName: 'Javna',
        emails: ['cjavna@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amer',
        lastName: 'Momani',
        emails: ['amomani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEuYQYFKYjvYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517353090857?e=2147483647&v=beta&t=I_XZ_gAJJedPxEbRnJvmni1kQENXx1cd9LOdAv2Qcm4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vantagebp: {
    name: 'VantageBP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michelle',
        lastName: 'Bonadonna',
        emails: ['mbonadonna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGAm4OOBHDHNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685576235952?e=2147483647&v=beta&t=cI7PRXvbsKDS7lJO2Cq2nxL9BFHuni-WUbr1ma52Ccc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alex',
        lastName: 'Lazarescu',
        emails: ['alazarescu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dimitri',
        lastName: 'Romanyukha',
        emails: ['dromanyukha@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anabel',
        lastName: 'Perez',
        emails: ['aperez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFGPDxxTyZMUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634066904611?e=2147483647&v=beta&t=neEFAJJ_FM_8RW8MMQlXQuaKSrCaaTDDN__TMIj9Oko',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alexis',
        lastName: 'Dabney',
        emails: ['adabney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHpGLHCJQw-9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676019255691?e=2147483647&v=beta&t=5Y175bwT2RZLNJGvV2hoKKqPMzCssVFjpLvr4RHTJnY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ss: {
    name: 'Zain South Sudan',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Roynina',
        lastName: 'Magaya',
        emails: ['rmagaya@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Jada',
        emails: ['djada@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGaz_aPuoxHKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516849304513?e=2147483647&v=beta&t=rxduVL0jXL_HWkWiZyFO0OPVy6u_D53XFkMC02-RFtQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yuot Cikom',
        lastName: 'Ayiei',
        emails: ['yayiei@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF9-AXCULZrNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689529149306?e=2147483647&v=beta&t=LOfqCWQIrSuJy-P1vbjWYs-ofwhLNCwpSSQc7WAXGF8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alberto',
        lastName: 'George',
        emails: ['ageorge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFD_Go3gxj-jw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517569628792?e=2147483647&v=beta&t=iM2Q_5ay7iphUQc92vvbO271oVlKoL6UCtCsK3aClHU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ahmed',
        lastName: 'Hussein',
        emails: ['ahussein@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ntrepidcorp: {
    name: 'Ntrepid LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric',
        lastName: 'Reichert',
        emails: ['ereichert@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Douglas',
        lastName: 'Adams',
        emails: ['dadams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHNQ0SoBfMuFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579995368419?e=2147483647&v=beta&t=Z072QPNOpPpDnx53Fa906bz6hPdbtRhOjhA53-Cyb7s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erin',
        lastName: 'Monahan',
        emails: ['emonahan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHwmJI5vxvROw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719595311763?e=2147483647&v=beta&t=uzoKAdaHouDfDHNmLc1N1IsM_GHHwRqogMPfnHmAN8s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brian',
        lastName: 'Baker',
        emails: ['bbaker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH2GoITOfElag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607794706626?e=2147483647&v=beta&t=96waE6BDbuHq6sk7nIKG4rzwUpoff-eZryHus7Z4WBs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joshua',
        lastName: 'Westmoreland',
        emails: ['jwestmoreland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEgXbt1wcz_cQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666181720192?e=2147483647&v=beta&t=W6qSLf5mP7Fe535N0gNBVRVc82mJGAPKJjI8rwJpHkc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  registria: {
    name: 'Registria',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mel',
        lastName: 'Künstler',
        emails: ['mkünstler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGNbHS3Js44iA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646933449425?e=2147483647&v=beta&t=6T14bvRrCxUAdfy8qHdaEH47gdQDk-75uiaSHLpoytw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vinay',
        lastName: 'Anne',
        emails: ['vanne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHFFTimPwqo_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563905218003?e=2147483647&v=beta&t=GzV_PbVoU_l23fjMW6hPFyvC3kYnomo2APxEpqo4IWY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vipin',
        lastName: 'Hegde',
        emails: ['vhegde@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Collin',
        lastName: 'Cohen',
        emails: ['ccohen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHJmaOy7QrhOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516260435659?e=2147483647&v=beta&t=PfTgrGAGTrDh6GWMzsB-IA4wlBVELjrPq5EJtexZITY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Watson',
        lastName: 'Mulkey',
        emails: ['wmulkey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH3D9mWg1LqnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516477157019?e=2147483647&v=beta&t=HUZX0Q8zefiRvJ6UzRnAasgWH3nuAOkqq4obFL3asqI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  relayr: {
    name: 'relayr',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jessica Poliner, QRD',
        lastName: '®',
        emails: ['j®@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGNtoYli2Xdvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661686208035?e=2147483647&v=beta&t=JYTL5eHDauw5qSkGpEjVF_sJ_5_DqoDD3tkKZE6DT2U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kiran kumar – relayr |',
        lastName: 'LinkedIn',
        emails: ['klinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Krishna',
        lastName: 'Yarramasu',
        emails: ['kyarramasu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFzneTdU6q2Vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517432614147?e=2147483647&v=beta&t=FLOUsQm1z0r0gnN7rkcSSWoutASlhg9ImBDcb7gS064',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Phil von Heydebreck – Head of Global Strategy and Business',
        lastName: '...',
        emails: ['p...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF0voRXk5x0oQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552641983116?e=2147483647&v=beta&t=MaWuAsHyqj9mh0YAb5UxXlMHU7UElZINZByng6L0QIM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Deborah',
        lastName: 'McVey',
        emails: ['dmcvey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFRArQpq9uDQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588265990245?e=2147483647&v=beta&t=DM77sDSA_Opm3Wjt2Z7gwRNhMcp5dxnVAensKmppxB0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wicketsoft: {
    name: 'Wicket',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeffrey',
        lastName: 'Josephson',
        emails: ['jjosephson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFsGv_rT4cADA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516543306137?e=2147483647&v=beta&t=It9t6TRlFzbq1AyDEOTz_20UuDuidelq_Y8OihvqI04',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Murat',
        lastName: 'Dogruol',
        emails: ['mdogruol@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF9GM927a9-qQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548708369411?e=2147483647&v=beta&t=DNbHQETQHikI12fILQAJ5PCja_Mv2buctvZMN1-qGxM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Roxana',
        lastName: 'Fariborz',
        emails: ['rfariborz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEtuxIZBYRIdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516978120419?e=2147483647&v=beta&t=vIPyjlRHeOZHKShqSzO01ozAdtpzEhPvRpojVMou4UI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Greg',
        lastName: 'Halom',
        emails: ['ghalom@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tom',
        lastName: 'Smith',
        emails: ['tsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHiu7XVcNIOnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516625624097?e=2147483647&v=beta&t=G3Y2Bx5ccQEsPgh9_mp3UfAb95Ekz6gWEoFpFEcz1zI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  servos: {
    name: 'Servos',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Doug',
        lastName: 'Powell',
        emails: ['dpowell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEBJEK9ivUcQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556546713379?e=2147483647&v=beta&t=CvbEQIq_8Yhvp33y_5dPw-4vvB-XHCW8Ke9g0Q2lK88',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ashley',
        lastName: 'Haglin',
        emails: ['ahaglin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF1dMbYOGJO6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711129627362?e=2147483647&v=beta&t=iz40jeD6A-PYnSKLUTrJDQOXW0BL-dH9ZCPoRAQihw8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Veer',
        lastName: 'Nahar',
        emails: ['vnahar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGPYNUCyhtjWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662773151283?e=2147483647&v=beta&t=TjTVp0auz4FDjVl8-TRlDk-vd2tuHjnuj13Gxx10Qew',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aaron',
        lastName: 'Wilson',
        emails: ['awilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFWjpNIBcfn4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664379237444?e=2147483647&v=beta&t=E7vJdmDU3e4IvsfiQFmmzhKvS9GWRqHpKGGy-bQJRiQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Madeleine',
        lastName: 'Pagni',
        emails: ['mpagni@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  foodsby: {
    name: 'Foodsby',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ben',
        lastName: 'Cattoor',
        emails: ['bcattoor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFx4Itv9VWuqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638373216668?e=2147483647&v=beta&t=0HFu2Aw4klXK4qx43mbgL2bJ9lldtEt2qcjB-GARNY0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'King',
        emails: ['mking@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFMxS3vybxu3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517594219831?e=2147483647&v=beta&t=gSKtWCHUhE__5YjuBJBo4QLvUo8lB5AcyMZmz9mKJRw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kevin',
        lastName: 'Cattoor',
        emails: ['kcattoor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE8ZRf6GOr73A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624983206279?e=2147483647&v=beta&t=2aHJUkTyxS1_cLEzt6eLocQ7KgP9Iv2WrwAU071Mp0I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jessica',
        lastName: 'Cattoor',
        emails: ['jcattoor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFyVLzFeDwdlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642707719103?e=2147483647&v=beta&t=5PbTISYMV-UwmtDybLWQ4zUwYJkzs5UAmCUkBuISHsI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Peter',
        lastName: 'Schuette',
        emails: ['pschuette@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH_vIG3ExPcWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668111520913?e=2147483647&v=beta&t=lHZsF4komVy9sWA3_icorjvw5NSl3zELMNYqIvqdCcs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  enoca: {
    name: 'enoca',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Fisher',
        emails: ['dfisher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHkz1xWeXCJHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676060779105?e=2147483647&v=beta&t=dUTF3xUM8HruFGn_CP67Awpg4LlR1B30iMSX0GQk2qY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kirk Freund',
        lastName: 'Chartier',
        emails: ['kchartier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGL5qrs_4ilgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516265784790?e=2147483647&v=beta&t=xXWdv3-U3frCFs3s_H6hPURflSXaQZ-_ueOzLvHkcPM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jasmine',
        lastName: 'Kent',
        emails: ['jkent@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHGHxfQ9uuesw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718212502601?e=2147483647&v=beta&t=Y_pwZ2_IL_XE9jqiIFzWfdA3es1gyq9ooQe972W07-U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Wolford',
        emails: ['mwolford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGL7vQc8fpeGA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517753113414?e=2147483647&v=beta&t=Y9Psgf4k9veUZja4SVf9y988ZKNuY5TXLmo6fcAR-Ig',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adam Behrens,',
        lastName: 'CPTD',
        emails: ['acptd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHlTDDr1nHxRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669919738247?e=2147483647&v=beta&t=tzdgyD204If76WHqiYeB4lpSt4hfnY62WaofovpcHlI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dragonfruit: {
    name: 'Dragonfruit AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amit',
        lastName: 'Kumar',
        emails: ['akumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEU2utEhy_VHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718324709356?e=2147483647&v=beta&t=l4tna9gXLmloL4OAFS12t7dk4FkGEF9iUBtCgPOSOQ8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bryan',
        lastName: 'Innes',
        emails: ['binnes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHoTxuA60_k1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587021701065?e=2147483647&v=beta&t=eQJejH20LAy6aImWcbPt_GljIicSfmxTvu8BESkAle4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kate',
        lastName: 'Morse',
        emails: ['kmorse@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alex',
        lastName: 'Kochanik',
        emails: ['akochanik@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Karissa',
        lastName: 'Price',
        emails: ['kprice@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGKtS6Oig9eaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691101978504?e=2147483647&v=beta&t=cgiSda6BDVVuAT9unoN96bsroFpFFseZzfqRibUbhD4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gosharp: {
    name: 'GO SHARP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matthew',
        lastName: 'Sharp',
        emails: ['msharp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG0xHc4HF327w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633806253930?e=2147483647&v=beta&t=MmKxUvvk4aYF6pWLK9hDcI86WajRTLJKlu0z6i0cReo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cahlan',
        lastName: 'Sharp',
        emails: ['csharp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHFBr-fASYpug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611769256200?e=2147483647&v=beta&t=o9AshneA8-L-6ksYDZRPOclBp-41hgBJdT7S87Ya49M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ian',
        lastName: 'Sharp',
        emails: ['isharp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHqOEzBFqDGRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647597419882?e=2147483647&v=beta&t=vPAWAZsy57F_U40JvwIAoiclX_9sz1_cF8fv0_wQ2FY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jon',
        lastName: 'Wilfong',
        emails: ['jwilfong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFczJjxIzrOkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1565192270663?e=2147483647&v=beta&t=0JGLjrisjerRB-9C6st0IlS66trZ7JDR7sQGgB4gJHg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Noah',
        lastName: 'Sharp',
        emails: ['nsharp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGXno8R9X6VqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695064354658?e=2147483647&v=beta&t=ZYvxtDhZIMhYXVZ-dSxdJfZDOTF-W0-RMDp4vRvqevI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  goswift: {
    name: 'Swiftly, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Annie L.',
        lastName: 'Lin',
        emails: ['alin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Arvin',
        emails: ['carvin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHXvf8FLzE6kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704833253237?e=2147483647&v=beta&t=HkodbH5dzutJDNha92we2l7cqrI5mRD0yKLtPIhvCg0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jonathan',
        lastName: 'Simkin',
        emails: ['jsimkin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE_tWUdcjOGQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590127709227?e=2147483647&v=beta&t=gXjQCIutmE9B-c2VIUKjCTLqYD9Ureu-8pBiMLftt44',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michelle',
        lastName: 'Willcox',
        emails: ['mwillcox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF1k1fBcfOrbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571262088518?e=2147483647&v=beta&t=IjYhNtH3oun0PVVA-0ppyfyfWyfWm9B30KVjC0EEwb8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Haomin',
        lastName: 'Xu',
        emails: ['hxu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGVEBJ9OlcoIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558718085538?e=2147483647&v=beta&t=6rpAnnP3fdVeeqZ2Unlt5TC7xgsotHXvMLhN7ILbwEs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ivix: {
    name: 'IVIX',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matan',
        lastName: 'Fattal',
        emails: ['mfattal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFkbI9FqWOKcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517409874964?e=2147483647&v=beta&t=0B_v0iuURJpEnODJX6gX39oW76nQY9fry_rpjsB7yjM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Noam',
        lastName: 'Chamovitz',
        emails: ['nchamovitz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHAu2xBAeok7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621153814974?e=2147483647&v=beta&t=e3Q1wV1tvyFGbwEY_ETieu21O4TcSOYtrYrDwkcoIuE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Melissa',
        lastName: 'Anderson',
        emails: ['manderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF462uKuqWRgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683725816954?e=2147483647&v=beta&t=3jOuNw77A79-R5vrFpPvVWNJQ5VqWvGyIc0aBESva3w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dana',
        lastName: 'Cass',
        emails: ['dcass@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Monte',
        lastName: '.',
        emails: ['m.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGMdAw4z_BcPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713010062964?e=2147483647&v=beta&t=Q8PVBEbK3-8Wx7Z3RxMOYhFdsCYAqvs4UJMkIPGNCIM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  osinearshore: {
    name: 'Outsourcing Services International | OSI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  itsaerospace: {
    name: 'ITS, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hassan',
        lastName: 'Campbell',
        emails: ['hcampbell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ahmad Swan, MBA,',
        lastName: 'CISSP',
        emails: ['acissp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFBsuyk4XVAVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643952948640?e=2147483647&v=beta&t=zg3iuF3JsclfueVgzeF4822nPgu3OpFeyz-I_iyq0Wg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Skylar',
        lastName: 'Strickland',
        emails: ['sstrickland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFY60I7Kg-irg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711375697167?e=2147483647&v=beta&t=AzX_YzPnKDu-Fq5CaeNlTcSVd-QBQqSmKiIj0Yz1rpw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michelle',
        lastName: 'Ingraham',
        emails: ['mingraham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGBLt6Tif9qMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642958092188?e=2147483647&v=beta&t=0S5_F2LQyR042cqfmHBbBOv5_-9Jlk982y4N_6Lmb_A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Manson',
        lastName: 'DeGraw',
        emails: ['mdegraw@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHLiy3oQhNnMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601618954355?e=2147483647&v=beta&t=F4WVZSm5D55XmFDO8L_Q9RZieBBj4HEc7lhDOpKmvQg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  banasolutions: {
    name: 'Bana Solutions, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ricardo',
        lastName: 'Berrios',
        emails: ['rberrios@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bob',
        lastName: 'Furtado',
        emails: ['bfurtado@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jacqueline Kazil,',
        lastName: 'PhD',
        emails: ['jphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFjvpbYlcvoQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597115778546?e=2147483647&v=beta&t=86N2DOyvMRW8W3ROoBWx5--4ez9M8t2M0nXTYyJfoNg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kendra',
        lastName: 'Mayweather',
        emails: ['kmayweather@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHaTqYCJflE-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611163670855?e=2147483647&v=beta&t=QccIRQnc0HoTqPxmSwvXC7V8lJ3vsaWCBS48txL6whk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rohan',
        lastName: 'Chandra',
        emails: ['rchandra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFtWl0RH7M8VA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644874270457?e=2147483647&v=beta&t=Wj2vuaAsnV_vp9dDb5_fS-a_G7qxBE4noja0Ln8NtWI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tbri: {
    name: 'Technology Business Research, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Foster',
        emails: ['cfoster@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Patrick',
        lastName: 'Heffernan',
        emails: ['pheffernan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFonnyxuI6Tog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522417371779?e=2147483647&v=beta&t=9029kxbT9G_gZLSbKiih8Tf8cux7DVzvzMNOv2vKDgc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christopher',
        lastName: 'Antlitz',
        emails: ['cantlitz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF25-m8CA_2mA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517788504820?e=2147483647&v=beta&t=9xQHflX48h-MY8azkIKvtGKZSz6KNoW6tZagfErYdSM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Steve',
        lastName: 'Vachon',
        emails: ['svachon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHIG9Tjz1yyIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708444992579?e=2147483647&v=beta&t=sL8e_XeZgoiepnJDov49ooJIIq7hfx8bX0QzWRpWXQU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Boz',
        lastName: 'Hristov',
        emails: ['bhristov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  entremt: {
    name: 'Entre Technology Services, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brandon',
        lastName: 'Eggart',
        emails: ['beggart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFJGB6jR_Ccow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560779116407?e=2147483647&v=beta&t=31nztMVaJHyKXQRp6laPVbyVupTP1FZa5LIiRQ3_-GA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Christison',
        emails: ['cchristison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHlYE7ssuu4fA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581632908649?e=2147483647&v=beta&t=xVpdS37XmAzOFquvOy3ndbld8CdrvLB9Xl1-E4qGhL4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Debra',
        lastName: 'Gleim',
        emails: ['dgleim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGUqppcNcrAZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718270427254?e=2147483647&v=beta&t=rw2PpQyOOyKxG7EpxdYLMY36DRp98WF7a-7PIMbNwXU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michele',
        lastName: 'Flanagan',
        emails: ['mflanagan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE3nff9CLy_fg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596651690216?e=2147483647&v=beta&t=mG-RUlRPjYxBt2AWG2hhWoap0gjvLgnZppGQ_kTySZk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sam',
        lastName: 'Pascua',
        emails: ['spascua@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF8BAcCXSfkcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706651577384?e=2147483647&v=beta&t=1lrb4Hbb48oE_tbLoCbed2gCItsYCxmZyHMmkCDi5sk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wirtek: {
    name: 'Wirtek A/S',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gorm',
        lastName: 'Gorell',
        emails: ['ggorell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cristian',
        lastName: 'Olariu',
        emails: ['colariu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEzlhbUuZVEoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694065280052?e=2147483647&v=beta&t=wsneMTna9O5R4kztD-xGXoovjEJqxMgJFzZSpKdJBBo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kamil',
        lastName: 'Wirtek',
        emails: ['kwirtek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGHIAkHl5HbIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581452977450?e=2147483647&v=beta&t=P8i9RAkjqzWI4gKbw_8A-BUrvf-vODBmHb3nusAtMp8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jesper',
        lastName: 'Frederiksen',
        emails: ['jfrederiksen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Trine Visted',
        lastName: 'Mikkelsen',
        emails: ['tmikkelsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFwtOCRyMsLaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548234623035?e=2147483647&v=beta&t=S_8VKtRn81uRPaqfdGgRuC2YxgTHZ9Xs_MxUMLsFFs0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  electrifex: {
    name: 'Electrifex',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anuja',
        lastName: 'Subhash',
        emails: ['asubhash@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEzhPX9TejVeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709628202940?e=2147483647&v=beta&t=k2c_M6vc43loGL37adhE9qHr59TMZICi_LiNWszTkNs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anooj',
        lastName: 'Gopi',
        emails: ['agopi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bennet',
        lastName: 'Benny',
        emails: ['bbenny@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHUTbp4INmdIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725823751220?e=2147483647&v=beta&t=5uJ-E6-mVt4F-y31nv9X_DctJrCBWL0iH_3F3mbbfZA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kiran',
        lastName: 'Raju',
        emails: ['kraju@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEWBEhYn2t0cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710120741147?e=2147483647&v=beta&t=hY3XINyVskxAJjzxPi1tFjcF9c1riZXkkfJnMb3NvT4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Logesh',
        lastName: 'Udhayakumar',
        emails: ['ludhayakumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHBujGUVBAuNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698140304492?e=2147483647&v=beta&t=UlVnJBDD5sj0cL9qvf8vm2-dothqMWh8ghMNqBU8QRI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  reflectiz: {
    name: 'Reflectiz',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Benjamin',
        lastName: 'Libby',
        emails: ['blibby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEd1UjaFLHfpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603232705201?e=2147483647&v=beta&t=VMtKXQqGZXKXcIZ_FdGZiClAmEe2atOc3x8zzD3qLtI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Patrick',
        lastName: 'Koch',
        emails: ['pkoch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGPiZYqQ6ZC2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608938602906?e=2147483647&v=beta&t=54g5oLH-wAVT8IO2JLYafGTUyYuYcf796oIQ1aDwX6k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Simon',
        lastName: 'Arazi',
        emails: ['sarazi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEsrpvpxDdesw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722079223833?e=2147483647&v=beta&t=WlmAb-Wi-VPlf9uLTgnLe1NvbIg1VZrf3TEd4IVV7g4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Idan',
        lastName: 'Cohen',
        emails: ['icohen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Leor',
        lastName: 'Eliashiv',
        emails: ['leliashiv@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE078D8LjCBCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724849723698?e=2147483647&v=beta&t=ip2SG_pIJegOGCUeSQdW1-XHMmCj_SQMGO28iN6Hl7o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wheelhouseit: {
    name: 'WheelHouse IT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gani',
        lastName: 'Zebersky',
        emails: ['gzebersky@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHmHe8FmxzdNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517721102800?e=2147483647&v=beta&t=MwROrRnb3fyXzUTXgg8Xz-26dr6sZ2I8nczTqdcpCIk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rory',
        lastName: 'Cooksey',
        emails: ['rcooksey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFyhANs55t13A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703019652101?e=2147483647&v=beta&t=sNjeedNSvS7xFBErl7cJtSF2vai2HQ4PDqHJJLN-gKE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Darin',
        lastName: 'McGrew',
        emails: ['dmcgrew@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGXNnS5d72bbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1577738316580?e=2147483647&v=beta&t=Sb2GXl2frT_WTR611P9BCtDnvYhtkKEcBih4B724NPk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nick',
        lastName: 'Barto',
        emails: ['nbarto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGnVk0f-m9WIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673290029557?e=2147483647&v=beta&t=yqyoQU22_pmwz7FPhUyIf8-SnvZl7gWIfSbFGok67jk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Terry',
        lastName: 'Inman',
        emails: ['tinman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGUQUcLhoZgjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578077958715?e=2147483647&v=beta&t=fmvO5bUBs0Un_sUrf9NcgDjEfu-7d5_3RWW7wNwCaeA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sferaspa: {
    name: 'Sferanet',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robertino',
        lastName: 'Morviducci',
        emails: ['rmorviducci@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Luciana',
        lastName: 'Distaso',
        emails: ['ldistaso@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alessandro',
        lastName: 'Landi',
        emails: ['alandi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGgRwobiW8fMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542311870654?e=2147483647&v=beta&t=0j2Yndb2JSNY0TSmpx1UROtMtGCrfh6aXQWiXzg-69w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'luca',
        lastName: 'torrigiani',
        emails: ['ltorrigiani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHhFfvlAvuCNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536557710192?e=2147483647&v=beta&t=1-u9szUeRLoI2G70bmuqgLfi-Km3EFBChzadoIfTmvM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Luciano Di',
        lastName: 'Benedetto',
        emails: ['lbenedetto@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ftsgps: {
    name: 'Forward Thinking Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Isler',
        emails: ['disler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHwswrrDzQioQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331466109?e=2147483647&v=beta&t=0jBiXT5mKK3Kqd1gz1jT59xoSEKPN4VxhyPB858BlWQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joseph',
        lastName: 'Laks',
        emails: ['jlaks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEL3BeD7yXgMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707333966876?e=2147483647&v=beta&t=yiAf2csgh8gRVkqtYq4bxIcYgiB_uns04KNo6bIOrsY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alfred',
        lastName: 'Alvarez',
        emails: ['aalvarez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeremy',
        lastName: 'Haft',
        emails: ['jhaft@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGPEvJpupdmiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657305375339?e=2147483647&v=beta&t=nkVKUA8k4QtgqV1SzulHg9KNphlaymJcfaCJTCXZCIY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Avraham Aram',
        lastName: 'Nadell',
        emails: ['anadell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHEASxo3MXGgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698236310928?e=2147483647&v=beta&t=i0wpnO4Je7Pyr-WVV7PjTYckuhtekjTBdVOMyQ7CdOA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  iptsb: {
    name: 'IPT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ken',
        lastName: 'Krutenat',
        emails: ['kkrutenat@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Monica',
        lastName: 'Semperlotti',
        emails: ['msemperlotti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFEfCan-gIaEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636702214854?e=2147483647&v=beta&t=PC7Z31DC8fpSwgJZOXLgJgqXmcFdcnqW0IVEOcdn5tQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carlos',
        lastName: 'Diaz',
        emails: ['cdiaz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Etty',
        lastName: 'Yenni',
        emails: ['eyenni@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Derek Pacheco,',
        lastName: 'MBA',
        emails: ['dmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE9gNh5J7fWWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653256914662?e=2147483647&v=beta&t=oEw1Qr9vwfYoNtAR5zTp1Y-e-wWO1Tx_o0KSdzMohlQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  technevity: {
    name: 'Technevity Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bushra',
        lastName: 'Sohail',
        emails: ['bsohail@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGtADqSRX_EZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683618611659?e=2147483647&v=beta&t=tKF8d62xFaT5WOaRc1DviLmBp1HpN1PgJyjXLDcgl54',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Asad',
        lastName: 'Rabani',
        emails: ['arabani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFlDrdeBqTVDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668165541707?e=2147483647&v=beta&t=b-WNrkOvyzZhT7oHggLUgWXN76S5xoCtg21zNK59uqw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Muhammad',
        lastName: 'Nauman',
        emails: ['mnauman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEcnjEnDf7LdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516532140855?e=2147483647&v=beta&t=OP6MBYUldzTwgiExlZ8BE5QrQz7ztdCc2VaM7Wy_ehs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Laraib',
        lastName: 'Shams',
        emails: ['lshams@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Muhammad Shehryar',
        lastName: 'Habib',
        emails: ['mhabib@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGlN_2EllIwgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631453649201?e=2147483647&v=beta&t=IG2BIniBz3aa8jFep9l9GU4ub0hTTp3_1PPURIG0WMg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hirequotient: {
    name: 'HireQuotient',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mary Shea,',
        lastName: 'PhD',
        emails: ['mphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGgOpWB2gAPow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700498702808?e=2147483647&v=beta&t=xZx-C3kGSES8rIvZC6FJggJcGKQjtpGQFx3GIe_IovY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pratik',
        lastName: 'Kedia',
        emails: ['pkedia@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Raushan',
        lastName: 'Pramod',
        emails: ['rpramod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHlDCst5pJCCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689791436717?e=2147483647&v=beta&t=N9iHg-BbR-9GjdTRZjtG6EJx4uPq6niG7Xmn0KrOsq4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aviral',
        lastName: 'Mishra',
        emails: ['amishra@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amartya',
        lastName: 'Bhargava',
        emails: ['abhargava@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGV8lmmw-Cf9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726471139189?e=2147483647&v=beta&t=3ZIOR9quZgMDt70FyXrvBqquXastd-YNzdsOJkaU1PI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  capefoxss: {
    name: 'Cape Fox Shared Services ( A Cape Fox Company )',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rachel',
        lastName: 'Carrington',
        emails: ['rcarrington@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Amy',
        lastName: 'Adsit',
        emails: ['aadsit@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFzlxDLnjf1AQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681408716362?e=2147483647&v=beta&t=bjuQExEkEk_llqSs2SvojyETodTOLZi2NObgnVM2c9o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'DeAndrae',
        lastName: 'Jackson',
        emails: ['djackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHFzY_k6NBz_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559748036146?e=2147483647&v=beta&t=9D6AJeKLUSyud0SR8AQcANaNd112GoygLXNs7GCZ4vg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Bartlett',
        emails: ['mbartlett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGZOaYg07HzkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1523492862460?e=2147483647&v=beta&t=8jUaZubdH6xYRY8ebtXTSuKkYuIZ30Bn9zWPISLFpDs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Todd',
        lastName: 'Fell',
        emails: ['tfell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHcf7Ws9cDJ9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703256461231?e=2147483647&v=beta&t=A3mB3KRvQw7ybhlaaxoZwpanCi7Qhczohj-bwIWfxog',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  isys: {
    name: 'iSYS Software GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Jobi Moldehnke – Head of IT Infrastructure – iSYS Software GmbH',
        lastName: '...',
        emails: ['j...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGQi7FOywNorA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593165537064?e=2147483647&v=beta&t=tobR8ovMPPINlB_2rzDGa7dHNrHKdvevX1GKU1wbJaw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cecilia Balmaceda – iSYS Software GmbH |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mischa Lieser – DevOps Engineer – iSYS Software GmbH |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQESZbzjH3RA3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628496912159?e=2147483647&v=beta&t=VNYZBhRHpridz8bSnZYIcSm1pp2mnfzUyiC6D9HpkBY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lukas Sokcevic – iSYS Software GmbH |',
        lastName: 'LinkedIn',
        emails: ['llinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFofbM1F4_3Kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658069830115?e=2147483647&v=beta&t=ZyFfqFi9dU3FNz_gaZa_MQ8mhTUJM8s52xh2QL-U474',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kurt Fastner – Softwareingenieur – iSYS Software GmbH |',
        lastName: 'LinkedIn',
        emails: ['klinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  datasales: {
    name: 'Data Sales Co.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Heather Seurer,',
        lastName: 'SHRM',
        emails: ['hshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHmWLCjEYMKqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646233491067?e=2147483647&v=beta&t=F4y91WtKO5OfpQF7feZucKin08nQD7TJHbHXtlOJAYI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dan',
        lastName: 'Gannon',
        emails: ['dgannon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephen',
        lastName: 'Nelson',
        emails: ['snelson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHDkMSUbhgybA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619066937734?e=2147483647&v=beta&t=EEiItfIyNEOzJqvuiYHP90ObIgGJAP3WiNEZLHrF76E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Patrick',
        lastName: 'Ponzio',
        emails: ['pponzio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF8ipQK32gI9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516252602080?e=2147483647&v=beta&t=W0s8h6Jj1uO6Kq6tDoAy10JP-NNnUd5gbluyTR-ctxY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jack',
        lastName: 'Breckner',
        emails: ['jbreckner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHRBPqqmhRHug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618844494091?e=2147483647&v=beta&t=0KHg7iJlPDVbYtKxktPQEyVSH-L9ZrqT1kenmxoQS-0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  extratik: {
    name: 'Extratik',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zclap: {
    name: 'ZCLAP Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aja',
        lastName: 'Delaney',
        emails: ['adelaney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Laura',
        lastName: 'Hinson',
        emails: ['lhinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFruenDustVGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729619439230?e=2147483647&v=beta&t=ZgHTUKdlkvB-kWYuCIcr8G89SxZvz5z_KnTmLOR3qyo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lynn',
        lastName: 'Weishaupt',
        emails: ['lweishaupt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG-2baoYJ5bmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517680663871?e=2147483647&v=beta&t=ZTq29Z7iTD-9_Vl99hgjp4_Xz-9EcyO4KE-onarebp4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abinash',
        lastName: 'Mahapatra',
        emails: ['amahapatra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFSwzyTbGiGjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725305917564?e=2147483647&v=beta&t=2YhYFSCTdw3Xkh0RccTSCNmCLOZ6Fab6bvJfZzZMzkI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Oliver',
        emails: ['joliver@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  brainstarstech: {
    name: 'Brain Stars Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gloria',
        lastName: 'Stancheva',
        emails: ['gstancheva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE_gpXRQ4Zq1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664743507828?e=2147483647&v=beta&t=qO6UzTkzzFmxYK8EXKTicpUGTW79BcYyp5O-SA77E_w',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kalin',
        lastName: 'Atanasov',
        emails: ['katanasov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFu7iqOwHqA_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588177260834?e=2147483647&v=beta&t=jDwAq4KEi0J-kBtjlqslTeCB0gf3_yZKVBBsei9JBoA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: '',
        emails: ['m@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGO3daecVOlpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608645856270?e=2147483647&v=beta&t=qXAozCSmLRfVlFj-xVF0EaRYvS5az6_8gJMvacPeD20',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Simeon',
        lastName: 'Nikolov',
        emails: ['snikolov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFnjuoukOOKvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677180603421?e=2147483647&v=beta&t=99HvfnTzM_r4cRtxKiEsFpESbOV1sn28DJU3EYsvsiE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sumit',
        lastName: 'K.',
        emails: ['sk.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF80GVbWXlVSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699017660873?e=2147483647&v=beta&t=hJtbITkho4SPBAMLn0ewqGXtHQk13HaR_vNYaSWFZ18',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  newforma: {
    name: 'Newforma',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tammy',
        lastName: 'Fuller',
        emails: ['tfuller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH9YKyqgrfmLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697146551019?e=2147483647&v=beta&t=wU0NHxM5VsTKaO97gqb7AmJolFzu-8DaEOsO9CBQ_k8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brock',
        lastName: 'Philp',
        emails: ['bphilp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEYSLKgWQ0jUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725891352540?e=2147483647&v=beta&t=ebnzLCaTSzZgrHAVHB82mca2V0VLn7_DLw7bz5vVhlo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Lidbury',
        emails: ['dlidbury@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEJXvOSpw-XFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517753512770?e=2147483647&v=beta&t=41-rxgQ3r_AoWXZnhgYzsImcOVcpWeE297fH3QBZQ3c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eric',
        lastName: 'Marrapodi',
        emails: ['emarrapodi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHEbTxyFtcLKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568746454969?e=2147483647&v=beta&t=9ukNicB8z1Jst9227bQ4vs5StUxgqA-Ins2IHpYuHrU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Henry',
        lastName: 'Auger',
        emails: ['hauger@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  centeva: {
    name: 'Centeva',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joe',
        lastName: 'Nay',
        emails: ['jnay@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Asa',
        lastName: 'Beckstead',
        emails: ['abeckstead@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFYXlV3byPCgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603218493903?e=2147483647&v=beta&t=YDyEVBYIqeIY33xnO7zU1EpiuVngH_fCqGQzhQ7HTbM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christopher Letcher',
        lastName: 'CSM',
        emails: ['ccsm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHW7aP3pjKv8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674570493156?e=2147483647&v=beta&t=Wy4lLPgbXeuGJPGW71Jlol7HBMI1x5_qzp-sPDCuxeY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Walter',
        lastName: 'Fitz',
        emails: ['wfitz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH5_ONe3Erf6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1640016956910?e=2147483647&v=beta&t=H4tfEfge0GfgJoK1Rqd3orr2dGlFqELqdxuotwA_REU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jan',
        lastName: 'Quan',
        emails: ['jquan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGTvATXJFhg5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635972999657?e=2147483647&v=beta&t=xAfg2dd7zZ6CsH1ygGQXH4LPMFfUQvoVDczvt2X5LRM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  schoenhofer: {
    name: 'Schönhofer Sales and Engineering GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dc: {
    name: 'dc AG',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jennifer',
        lastName: 'Jones',
        emails: ['jjones@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John W.',
        lastName: 'Lui',
        emails: ['jlui@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFnEJz82EugTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700082143424?e=2147483647&v=beta&t=DN7PIC5c1IOW6r91raI2jQOYv9MqhkuiguJ1fWABZCk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tabitha',
        lastName: 'Bartholomew',
        emails: ['tbartholomew@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Calvin',
        lastName: 'Pan',
        emails: ['cpan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEqPrezn8rcBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685111811013?e=2147483647&v=beta&t=hZDASzkXIfUh0ms-RqU4hDZ28w4vk3-IR9mRhcJQmWc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kathryn',
        lastName: 'Gilliland',
        emails: ['kgilliland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEkaT1bQ9QPMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718232654839?e=2147483647&v=beta&t=VYR0RF4TXRitMms7GGITU0YceaQRCjHl59BjWYVfsyA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  evenbetgaming: {
    name: 'EvenBet Gaming',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ekaterina',
        lastName: 'Giganova',
        emails: ['egiganova@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eugene',
        lastName: 'Rusakov',
        emails: ['erusakov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG7ViaY7UeGRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712238758443?e=2147483647&v=beta&t=-gx2DnyrnBqZL5O0A3iCYuUZoMJiSOoATSODs0QJQbM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Julia',
        lastName: 'Panina',
        emails: ['jpanina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE5tVkwUC1NSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696391906281?e=2147483647&v=beta&t=XzX_N2DksCrjMMT5KkdjJCRj4PnOgmGXIvhK5S-1FXY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nika',
        lastName: 'Belova',
        emails: ['nbelova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGZ6I93orzdeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724689646791?e=2147483647&v=beta&t=sttUV893By77QAve0PS3hzYjdO2hDgM0rDDEEjA-Sac',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'EvenBet',
        lastName: 'Gaming',
        emails: ['egaming@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGMNmuS1QCeiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643610045651?e=2147483647&v=beta&t=CUJmoVNwVbS_BH2Luf5guhEpwj3gHg7F1YWYco3WXok',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  studielgroup: {
    name: 'GROUPE STUDIEL',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amaury',
        lastName: 'Dormoy',
        emails: ['adormoy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGVWXCgltfkyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604568092358?e=2147483647&v=beta&t=T__-J3PvcpaCf95OvLt1BTN4rvH8lOKJWfSa3fNeBe8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sophie',
        lastName: 'CAMPAGNO',
        emails: ['scampagno@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Fanny',
        lastName: 'Castelbou',
        emails: ['fcastelbou@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH_qa6IynOP0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516823117337?e=2147483647&v=beta&t=U0BFR8JvdLv_km43RMzCybGSF4yCaPMPGJVHls_QFzo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mélissa',
        lastName: 'L.',
        emails: ['ml.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFqJQPZBQsU0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727425472806?e=2147483647&v=beta&t=w2UJZ54YVI-egUZc1oVTrLW9NhcTMdMN7kpl1jfsVeU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Susanna',
        lastName: 'Pich',
        emails: ['spich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG4D68XeOg28A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547545539903?e=2147483647&v=beta&t=4CtqXpJBUt6bauXzco6LLSXTKhQvd22oXrYRjuG8JaI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kindly: {
    name: 'Kindly',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nicolas',
        lastName: 'Bonduel',
        emails: ['nbonduel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHmlnWZRx4azg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612475296410?e=2147483647&v=beta&t=vH_SPyS2McG0GKYno1K5LjAqrZdhAdvP8ZgbU9SQxSg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jan Andreas',
        lastName: 'Vindenes',
        emails: ['jvindenes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGWkcQ7Z1tz7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547817268098?e=2147483647&v=beta&t=uzf3umjbU9wWSmSJzAoxPtdL8GynSgiT2_e8YBUndus',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joel Hansson – Regional Sales Manager – Kindly |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gjermund',
        lastName: 'Norderhaug',
        emails: ['gnorderhaug@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH7jbSeux2J9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656588340871?e=2147483647&v=beta&t=gJ4Q7Rv_bb8GhLqeSkIfvZFH8FwerVIKhQ2Fh3bNQvE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kevin Dannefalk – Kindly |',
        lastName: 'LinkedIn',
        emails: ['klinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  minds: {
    name: 'minds.ai',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Itzik',
        lastName: 'Gilboa',
        emails: ['igilboa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQECNnnGCVUiFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578076928374?e=2147483647&v=beta&t=aAqjLlAhz0_WnIHmg0oVhlhj8Z5HwQZEcJ-QdFF1e18',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ana Saroj',
        lastName: 'Harris',
        emails: ['aharris@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tijmen',
        lastName: 'Tieleman',
        emails: ['ttieleman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFomDgAVhGt-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679518431883?e=2147483647&v=beta&t=IBVWb5HyW_BbmYSAHbEKfh7P_M4KVBtqyk7G727eCpA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Constanza Gomez',
        lastName: 'Mont',
        emails: ['cmont@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH0DXyHWVD_ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729064496356?e=2147483647&v=beta&t=nn0KNZgQWR_2oxrYrRkvKzC0DCdx0Tmzsd2tKSdRK18',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Douglas',
        lastName: 'Butner',
        emails: ['dbutner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sipwise: {
    name: 'Sipwise - an ALE Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  machadoconsulting: {
    name: 'Machado',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Helder Machado,',
        lastName: 'CISSP',
        emails: ['hcissp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Erick',
        lastName: 'Machado',
        emails: ['emachado@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGgqOlwoqiS7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713402538770?e=2147483647&v=beta&t=4jD72c490ELcUV7VwdGwOFFpMUi_QGVwv-sOo0uKMQw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Thomas',
        lastName: 'Faulhaber',
        emails: ['tfaulhaber@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Machado',
        emails: ['jmachado@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGcmQ9_2taerg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695919695355?e=2147483647&v=beta&t=aL5vStJeU2C86E-uqZVcLEglp-ZwhKJTVXB7qCF8rZc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Edward',
        lastName: 'Machado',
        emails: ['emachado@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFaNDzeICp-qg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516463449328?e=2147483647&v=beta&t=lynzYiz2deyY0SDy9tQ97g-zAlzpDAEppKJHPlKe27Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  promosConsult: {
    name: 'PROMOS consult',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Oliver Merkert – Head of Project Development – PROMOS consult',
        lastName: '...',
        emails: ['o...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cosima Christian – PROMOS consult Projektmanagement',
        lastName: '...',
        emails: ['c...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFhcPTdlWmbtA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668574693839?e=2147483647&v=beta&t=o4RucQySKkeP3JUt6gEe2m4a-r0fGUf5JHKt4x-l3T4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anna Sonntag – Executive Assistant to the Executive Board',
        lastName: '...',
        emails: ['a...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFUePeta4fHaQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1549010260327?e=2147483647&v=beta&t=olErQsrBK0WjygQJy0dUhT0RjlSO7bwW6qfXojRlkFU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cengiz Zeyrek – PROMOS consult Projektmanagement',
        lastName: '...',
        emails: ['c...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFDhzIreM0tng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722200505908?e=2147483647&v=beta&t=zVv6NQhvvx-EObhEr9jm2WHUlbLhUGtpRqO4C6vCuc0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Julia Hoffmann –',
        lastName: 'HR',
        emails: ['jhr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '1kosmos': {
    name: '1Kosmos',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Engle',
        emails: ['mengle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFWTmcBZkFVlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582815742094?e=2147483647&v=beta&t=AkDrc3CRDTpmNGhc-CvzuqEU5shVetfApi5rkPJpSP0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hemen',
        lastName: 'Vimadalal',
        emails: ['hvimadalal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Huzefa',
        lastName: 'Olia',
        emails: ['holia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEHrShEHeM4hQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517688332547?e=2147483647&v=beta&t=OOdvfJrCiZxHHCU9y_y6hi8tKRpTmX8APIYXY7Lhi0c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Siddharth',
        lastName: 'Gandhi',
        emails: ['sgandhi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHm6AFJ5kIr2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727617523913?e=2147483647&v=beta&t=dnWs_mriPzTy2JfM4RTqzlP9-tQqUsCH4lzW-Ar0rm8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'MANIK',
        lastName: 'KAKAR',
        emails: ['mkakar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG5934awOCo7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586143777298?e=2147483647&v=beta&t=MeRaJFpqS6XsqLj_mQltOJ3Md96RzMAaUs6ETl4Ny8k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  siticom: {
    name: 'siticom',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Christoph Falkenstern – Director Managed Services – siticom',
        lastName: '...',
        emails: ['c...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHy9Y6ZcwaM_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643361587768?e=2147483647&v=beta&t=kn7Zus6-ssPR-bYjO8xn2qgzQMvlXxA0ZUVqEAaj1SM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shalini gupta – siticom |',
        lastName: 'LinkedIn',
        emails: ['slinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jakob',
        lastName: 'Miksch',
        emails: ['jmiksch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGGqz6tZEYGjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588662196776?e=2147483647&v=beta&t=kY6GhGgXNAOfoNWSejHPP8N4mxaXb3BteSn38sprL_Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Otgonbayar',
        lastName: 'Dandar',
        emails: ['odandar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christoph Mahnke – Senior Technical Consultant – siticom |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sequoiainc: {
    name: 'Sequoia',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stephen',
        lastName: 'Mouring',
        emails: ['smouring@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFKG9didA6rjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517246450570?e=2147483647&v=beta&t=GmYtAdBAzQxij_PF8ciclnk856Xv3-NjtvWtVOYWxC8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'Rand',
        emails: ['arand@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tina',
        lastName: 'Stroupe',
        emails: ['tstroupe@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lucia',
        lastName: 'Simmons',
        emails: ['lsimmons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFMF38HJ_o10A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516985040359?e=2147483647&v=beta&t=e3_aSHQiEcXz3rTD00wPj9_tTtHznuSJBPPXcu7lGfI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jody',
        lastName: 'Hagger',
        emails: ['jhagger@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dowelltech: {
    name: 'Dowell Technologies Pvt. Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Venkateswaran',
        lastName: 'Vagheeswaran',
        emails: ['vvagheeswaran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFv5c2pgba-vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547193138579?e=2147483647&v=beta&t=lcTNfa64XYmAr3U7cKhnFZRHN655hzAnuauzeKXSD1M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mythili',
        lastName: 'Sridhar',
        emails: ['msridhar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'guru',
        lastName: 'devi',
        emails: ['gdevi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sree',
        lastName: 'Ranjini',
        emails: ['sranjini@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sakthi Kumar',
        lastName: 'Pandian',
        emails: ['spandian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFYFMsTRoxo9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701666027614?e=2147483647&v=beta&t=Anc-kbldzpBSfePlEQsJCPIAzwKkde5bvW5RpHe-S7E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  talentsarena: {
    name: 'Talents Arena',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aya',
        lastName: 'E.',
        emails: ['ae.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGCxvAwqh0khw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695707991107?e=2147483647&v=beta&t=Y7Hx9g415tDG1nhOQxuCTI4icQFXC9kVFgOb8L385bI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abdelrahman',
        lastName: 'Shawki',
        emails: ['ashawki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE5bSUXmXRy1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697400006803?e=2147483647&v=beta&t=lFbQ0ao08xS5CTb1QfMSI2vQouGaSaliwl3s0WoN8QY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Magda',
        lastName: 'Mahmoud',
        emails: ['mmahmoud@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Habiba',
        lastName: 'Elharery',
        emails: ['helharery@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE6-lRZFM2ztQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721072589600?e=2147483647&v=beta&t=KyA_YGgbnuy6uXtD-o0ZDmNWZOuwERIR2v7iyd-3oYY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'KIDS TALENTS',
        lastName: 'ARENA',
        emails: ['karena@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGZoJrKjJm6AQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1626289211240?e=2147483647&v=beta&t=wm_z_IjbOx2oVTqbu0hJUVWggvcE1eehLuazo8hKfLs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  outdooractive: {
    name: 'Outdooractive',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  activeviam: {
    name: 'ActiveViam',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Wade',
        lastName: 'Callison',
        emails: ['wcallison@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shelley',
        lastName: 'Magee',
        emails: ['smagee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHh1YoGwyirtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708117075998?e=2147483647&v=beta&t=q5NvXgI3FaVHSNJE2iU3MJictdW4CxzAZpV9pC1QkEE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erica',
        lastName: 'Fidel',
        emails: ['efidel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEhEoOQdBAh3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721751703465?e=2147483647&v=beta&t=QuEf8nWyFahNcrvD8XBOowshhryhs4bFfsrxgrAaroI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pal',
        lastName: 'Shah',
        emails: ['pshah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFBiRWJjhQhZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709678465891?e=2147483647&v=beta&t=pTmhEEmB_ZZAZwbqie9TFhY8PC2iVtImHyNr5MwISP4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Victoria',
        lastName: 'Chan',
        emails: ['vchan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH97aVcLF3OwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516791917303?e=2147483647&v=beta&t=Z4KFKh7H3axBsbBIODSHAwUjeW1AByruF0Gsw0bPrQI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  infobate: {
    name: 'InfoBate',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul',
        lastName: 'George',
        emails: ['pgeorge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE94FPx7xUaog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1534517129665?e=2147483647&v=beta&t=GBRu6J79F_Mlb4f7wfqQjuNx7n5AdIkJAyUx6N-ra5I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Louis',
        lastName: 'Reichenbach',
        emails: ['lreichenbach@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nando',
        lastName: 'Pandolfo',
        emails: ['npandolfo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFX8VJUSoYUBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696365380168?e=2147483647&v=beta&t=XoEqrJR-T_mlSiOAmzfVtI5qvOu6llXqJAt0HVYyYFs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ammie',
        lastName: '',
        emails: ['a@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEgaJgpWcsdAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659490165547?e=2147483647&v=beta&t=JlqBi3BYInrevSfdQKncprwsUKZGaR4wMM8xRCDr3kc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Suzanne',
        lastName: 'George',
        emails: ['sgeorge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFIY5oj_4bCRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517583263343?e=2147483647&v=beta&t=tITwUS9ddIBFAYev-dEmRnA06msUMC4Kh64ccKyEquE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pacificdataintegrators: {
    name: 'Pacific Data Integrators',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pancham Banerjee,',
        lastName: 'PhD',
        emails: ['pphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFRfo-0DUL4Qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713777143177?e=2147483647&v=beta&t=zRIgrSdQVLtb54lwP2PlVQhobvoyy9Ppb5RqYLoEvpo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Lyle',
        emails: ['dlyle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQH5s-43n19R0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516261911340?e=2147483647&v=beta&t=c3wleEilQMR3vBE0J8hcVS6_2yXw7d89bP7_yVN5bvw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paul',
        lastName: 'Yoo',
        emails: ['pyoo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGb9Qhe8pq0FA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555706902625?e=2147483647&v=beta&t=X1KKuTuUhomD6GA5vNupjS6J7a_rIuhM0YDQbp1v0oM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Martin',
        lastName: 'Strazdins',
        emails: ['mstrazdins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFAt15J8uZTwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553216637445?e=2147483647&v=beta&t=G6RCnXJwMbU2VVXvut9W4YRhEidh2L-k8kIf-_uY9nE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'HAMAD',
        lastName: 'T.',
        emails: ['ht.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  seamgen: {
    name: 'Seamgen',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marc',
        lastName: 'Alringer',
        emails: ['malringer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQER6xmHprK2Jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516245981101?e=2147483647&v=beta&t=odARnoMdP6mLUk2tkLcaDUm6tUNVuvS067WzztMhXlQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Peter',
        lastName: 'Nystrom',
        emails: ['pnystrom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQElAiG7M6HxGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571679604783?e=2147483647&v=beta&t=tgehSet1td4RUpSyF9hRR7EYBQRuFHPfYfEMz8ItL-k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tina',
        lastName: 'Alringer',
        emails: ['talringer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHF8smdHTJlEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699554944330?e=2147483647&v=beta&t=JqKwKMpuMeB1xmCa8BKr4p0abyPCUz889riOda53ZFw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Oleg',
        lastName: 'Gorbatchev',
        emails: ['ogorbatchev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEaZ52uoQbzJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700951628845?e=2147483647&v=beta&t=0I5ruIos0Of1ih1kW2FaV-i2MmTWOXGQzosyobkfrNc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kenna Branch',
        lastName: '(Perez)',
        emails: ['k(perez)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGdl99yngH-7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710207462719?e=2147483647&v=beta&t=bBtZwcHb-42V8eh0CPxGFkA0xPedp6R7Z3bitxYfQlY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  shearwater: {
    name: 'Shearwater Research',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Josh',
        lastName: 'Horoshok',
        emails: ['jhoroshok@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Troy Strachan,',
        lastName: 'CISSP',
        emails: ['tcissp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jason',
        lastName: 'Leggatt',
        emails: ['jleggatt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Simon',
        lastName: 'Morris',
        emails: ['smorris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE9oVX5vU0jhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517754067520?e=2147483647&v=beta&t=m1fIHRZoDbprOcpL4a4QH-Qa59ECQ1ToP_mDdobFgGM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Trisha',
        lastName: 'Stovel',
        emails: ['tstovel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQERbnQaKJ03eA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597360327342?e=2147483647&v=beta&t=k4kovthuy63yoMbiyNlDyx1yZQnEWdWlu1ndy2_aMlk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mbisolutions: {
    name: 'MBI Digital Corp',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'Blumberg',
        emails: ['mblumberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH4znPDQwg5Kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689721096267?e=2147483647&v=beta&t=mP06HN0zlu9oRQ0AtWYBIA7ji18tyAxQakCCdIyPii8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Deanna',
        lastName: 'Young',
        emails: ['dyoung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEHiKw4Ef4_Lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517615299685?e=2147483647&v=beta&t=Iz4KGqD4HMaPeojIkGESMSa8erbLpEhzmViO4ThL7Ts',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Castleberry',
        emails: ['scastleberry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH7fvcBfHGvgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709174003097?e=2147483647&v=beta&t=jnGhedGFQcV2DnIdXU2znOdmHQxsbjz66j1laLmwQyo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aryan',
        lastName: 'Singh',
        emails: ['asingh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aurelia',
        lastName: 'Moneta',
        emails: ['amoneta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  visionistinc: {
    name: 'Visionist, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Berry',
        emails: ['cberry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGdSRm0j7ghBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516540038552?e=2147483647&v=beta&t=_qUrgTGJTgYuy0azjFPYQnTc9gNogcc2JCKy2kCV7jw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brian',
        lastName: 'Lehman',
        emails: ['blehman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEucUwiYwCo9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552153270281?e=2147483647&v=beta&t=6ksCHYQdjFlTIbKrIf1UPyPwPve4QELyBAn6xaNyxk8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Josh',
        lastName: 'Passwaters',
        emails: ['jpasswaters@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFXq9Sdg-Q81A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558532991276?e=2147483647&v=beta&t=7glp4YISpiHlL2hS3-aW1ezELfrYsppXQbuG6SD3VhU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sophia',
        lastName: 'Graham',
        emails: ['sgraham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFJ9K8mww-cvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662342075755?e=2147483647&v=beta&t=4L_7L6Yxt8JOEs_PAXebZEa9b3oDzrsh2MTNWgSiL6I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adrianne',
        lastName: 'Paulshock',
        emails: ['apaulshock@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFmnJZ0BHMrLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667836244297?e=2147483647&v=beta&t=saZwvBA5wMUMmMBLYXS0iSGX241EN8NG-pJQhztbI2E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  schellgames: {
    name: 'Schell Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tim',
        lastName: 'Sweeney',
        emails: ['tsweeney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFE1sJ00Azecg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516239115314?e=2147483647&v=beta&t=Dtp5gf_PS9CbDhgpusUHBmEYozZiBwOidzyD6Xy4tiA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ed',
        lastName: 'Saxton',
        emails: ['esaxton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHBB-ubNwjz5w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662490564550?e=2147483647&v=beta&t=6uYkZNO5BUxaDDB-RLPcw3ZuU-xT9F5UMu5CnRzbs1M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joey',
        lastName: 'Ziolkowski',
        emails: ['jziolkowski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFRXJbyqQGS3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632944312193?e=2147483647&v=beta&t=7RQItQwe6mOONtkvWDl_VS0dJAJRNLGr5z-6ez5fbDA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cam',
        lastName: 'Perry',
        emails: ['cperry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEin9kG67B6Mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586192777871?e=2147483647&v=beta&t=bJJSApeVO-1bjiQ_7sfIyhEWRDaYpT3P1r7udpnYz5k',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dwayne Waite',
        lastName: 'Jr',
        emails: ['djr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGPUmrk5_FPxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689592223934?e=2147483647&v=beta&t=-nVfSj0UGIBtzgW8y4VyyhO--R4nGTDZqjmkMSVWQQc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  c2aSec: {
    name: 'C2A Security',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Roy',
        lastName: 'Fridman',
        emails: ['rfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHMlv0ciZiZIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720793033038?e=2147483647&v=beta&t=nOoigFy45Ey7s0hTvv8DNT5dGmPIjQJByL8wYB70zvw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Dick',
        emails: ['mdick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFK7Z6u6E6Bhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569486333629?e=2147483647&v=beta&t=WiLt6MvqWeOR9XAOoBJdd6gEykY7SrfpmCS3fU0_a6s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephane',
        lastName: 'Khelifi',
        emails: ['skhelifi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGWWsVWq5N7tA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667758379612?e=2147483647&v=beta&t=Ap2fCuXo220rQZdbfofh8aF9NMt0nx7z-KYXhpvoF_U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gilad',
        lastName: 'Bandel',
        emails: ['gbandel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH7jJ6I1smQWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706164412096?e=2147483647&v=beta&t=CDLd8xwHymghKpbCUZ9TChi_eCr-bdVxZjqhs5liVsI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David Mor',
        lastName: 'Ofek',
        emails: ['dofek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG156lTNwoESA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630829022487?e=2147483647&v=beta&t=ucuqm2J1YnRiRN8uy-kwp_aV4-cNrPa3n7AkNk_cRqE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  scicominfra: {
    name: 'Scicom Infrastructure Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mary Sandry,',
        lastName: 'CHRS',
        emails: ['mchrs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFi5r5p5iwzoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685205694144?e=2147483647&v=beta&t=zS0mCw_FC5vSgotewkN8-XQRBdglTsNcMI1mM-937MU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Samit',
        lastName: 'Roy',
        emails: ['sroy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGfvUTwvjB3Yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592839831913?e=2147483647&v=beta&t=5u0Y9TafAorUUz6wFyYadNb7mIof2IOahhTgmPkAO8k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Todd',
        lastName: 'Radel',
        emails: ['tradel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHXWh0fxIw8IA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575041319570?e=2147483647&v=beta&t=-V9QD0dUVwag2_9Xb5k71aysW7dakpwFqFptWIj_gXU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brandon',
        lastName: 'Bernero',
        emails: ['bbernero@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGZTQgRxDMYlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550932300384?e=2147483647&v=beta&t=AxBH_5761wyfouHJqcM-O_2kzMjd55r75SFGgqZcY7Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Peter',
        lastName: 'Cherrick',
        emails: ['pcherrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEobKvPrmlbvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558441415449?e=2147483647&v=beta&t=_xMCm5ubZChkpxo7cHe3LmU19GbiDfI-qXISH1Nr9dU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bluenetinc: {
    name: 'Blue Net Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adam',
        lastName: 'Wittke',
        emails: ['awittke@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stephen',
        lastName: 'Schooley',
        emails: ['sschooley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFKQco0ZgC3YA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665103068385?e=2147483647&v=beta&t=l8K38ObLxiY2_PbNPMTrJPQVvhzFoSIT9daRWCgIDmE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Angie',
        lastName: 'Wittke',
        emails: ['awittke@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abby',
        lastName: 'Wirth',
        emails: ['awirth@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zach',
        lastName: 'Welding',
        emails: ['zwelding@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEl29nwofB7aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637612096281?e=2147483647&v=beta&t=l7spwe42Ri5kgheV054MlhFzc7NMWLk18SrXzGqEKHM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  edifytech: {
    name: 'Edify Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Latha',
        lastName: 'Pai',
        emails: ['lpai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFUcYHih5Voww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517686381236?e=2147483647&v=beta&t=vcTyKoKi8u6_gkIUPMa6r8XVFuR7UgTDoGbHwQKViSs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'samiksha sinai',
        lastName: 'talauliker',
        emails: ['stalauliker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Asha',
        lastName: 'Konakanchi',
        emails: ['akonakanchi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHoj9Xm0nlYDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602540862313?e=2147483647&v=beta&t=OhIYHpyIyoPAn9V5AhqaHbv_jhQgg7QkLpAH9xMEpUw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shankar',
        lastName: 'Subbiah',
        emails: ['ssubbiah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHqIWkkaM-1Iw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670950817784?e=2147483647&v=beta&t=f5DQuSnQM2bVLMeGl-FAbZZ5Z-YSwjvtGdfiwrsmBfs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sanjay',
        lastName: 'Babu',
        emails: ['sbabu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGnFEug4ceQbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724166451586?e=2147483647&v=beta&t=PPBbOpo0oTi2FGQUr1vD0bIls_Kcq-SEvIVBVeFOA98',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zpesystems: {
    name: 'ZPE Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Arnaldo',
        lastName: 'Zimmermann',
        emails: ['azimmermann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFO6UBXENmb7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639956066159?e=2147483647&v=beta&t=7zcRaWLIVlD5K7j7orGDXFdvbHoO85TmehZdmmiYRGI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'James',
        lastName: 'Cabe',
        emails: ['jcabe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFMYXVstfaU1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646938307843?e=2147483647&v=beta&t=_0APeuL1O3CXqd4GHImsjnmEyWVF1a1vYxKXFV1kbFM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tarun',
        lastName: 'Rajput',
        emails: ['trajput@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEqhWY3sErc0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675657432721?e=2147483647&v=beta&t=QCftFrBuqJJ80q7I3V1vEc40Jf1-P-zuyYfEOkUQrq0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Sale',
        emails: ['msale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEWPRaeSGJ0Ng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650573002318?e=2147483647&v=beta&t=SImYJx-wBtcTRsjyE9H7nT3lHnadGialQZCVKORlcLk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Luis',
        lastName: 'Ortiz',
        emails: ['lortiz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFsxUIKd8ZyeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676926911739?e=2147483647&v=beta&t=jpJd5Z6NxGgR0KQVx3yMtZDjiGu1T6Sy4heLyuAJZeo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cruInc: {
    name: 'CDSG (CRU Data Security Group)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'William Kevin',
        lastName: 'Lee',
        emails: ['wlee@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGek2R7sI98cQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619716002277?e=2147483647&v=beta&t=GQ3MumQh34Dbo4srEY7kbomT0Sq9aSDvpzDAgwxPE_s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kent',
        lastName: 'Hamar',
        emails: ['khamar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFd2x4MzGE8TQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597955104545?e=2147483647&v=beta&t=U7DQ8RxYVjnyyRrhC78sGHG8PrMCqoyCxle7qQ_9zvU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Murray Ellis',
        lastName: 'II',
        emails: ['mii@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHHSAchYuJQcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725667492058?e=2147483647&v=beta&t=VAeB4PotH-FHH1rCU8d2o3IIUlV_0ljRxNAdHtyRm3Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Randal',
        lastName: 'Barber',
        emails: ['rbarber@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Justin',
        lastName: 'Lauzet',
        emails: ['jlauzet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGFWrePceSv3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712067473192?e=2147483647&v=beta&t=qMyp10efnwmLTVYePi5Mr43-lWTgAFQZe1g2Wv8HLi8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  citridot: {
    name: 'Citridot Solutions Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ravikiran',
        lastName: 'Talluri',
        emails: ['rtalluri@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ankit',
        lastName: 'Pandey',
        emails: ['apandey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGvBfxg9UwSyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722269761105?e=2147483647&v=beta&t=3l2FC8TCCOz9rsKWrgpMjE66a24RsRzpjZZuGoB9m0E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'N M Chaitanya',
        lastName: 'Chinnam',
        emails: ['nchinnam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFoivoPRnhTRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516477278364?e=2147483647&v=beta&t=2cdyS_KnJMJpq8ZN_2LfjaQdouWOGi86WqWsXcRSRhg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Feroz',
        lastName: 'Pasha',
        emails: ['fpasha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHr-UDRjjFFXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517684859690?e=2147483647&v=beta&t=6PSkYhioKy9qn_SxthgusvlV9UZkzMWGoKy1-j3KA-Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Surya',
        lastName: 'Kanuri',
        emails: ['skanuri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFDfj7Cc5btXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608318147388?e=2147483647&v=beta&t=FvgNjyg7RGGw0QSbFcD2iu6K-3UlyqLwfa9KJrYwzhA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cos: {
    name: 'Center for Open Science',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Nosek',
        emails: ['bnosek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEFGg6UCw3q2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517402153356?e=2147483647&v=beta&t=HtMO3D1Tx1HDlTMIVUGTvvCDp23DJSULny9Im08qhB0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Mellor',
        emails: ['dmellor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFi4Ia7stXYnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701118878493?e=2147483647&v=beta&t=ImPcHo-W0LBW4tjdEC3QKZRNyiqS-Eb2lvuKNleYN_4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tim',
        lastName: 'Errington',
        emails: ['terrington@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEQcwH-Ip-7sw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648824628653?e=2147483647&v=beta&t=5GqJdSda5Ioe_KrgX4PSBAV4gcHt-gegnN3UNr4t4Sw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Crystal',
        lastName: 'Steltenpohl',
        emails: ['csteltenpohl@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lisa Cuevas Shaw,',
        lastName: 'PhD',
        emails: ['lphd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  agmtechsolutions: {
    name: 'AGM Tech Solutions - A Woman and Latina-owned IT Staffing Firm-an Inc. 5000 company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mirtes',
        lastName: 'Lobaito',
        emails: ['mlobaito@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFLK6ugfLlLpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727720083555?e=2147483647&v=beta&t=lAye3NZ7cWEYsVsNNoyo4Fo6Lmt90E1lfF7T6guSh5M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Corrie (Sudol)',
        lastName: 'Hagens',
        emails: ['chagens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF_v2TzW22iZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682081456478?e=2147483647&v=beta&t=53o4KaWqk_vDvks0NuR6woE6hXhRgRJzohQSEQiWloE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mohammed',
        lastName: 'Aqueel',
        emails: ['maqueel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ren',
        lastName: 'Hoilman',
        emails: ['rhoilman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEEs57obJBj1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724703371137?e=2147483647&v=beta&t=GCNYtrCzmgAnIijf6FF9Y7-g7QOELmd6JIYHFPMquX4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lindsey',
        lastName: 'Krist',
        emails: ['lkrist@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE19lNNVmGq3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662058317555?e=2147483647&v=beta&t=6sNeIhYeCdNl1WIR0Y-PI2Sd3BMRnjTYltqkFzMxK9g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tw: {
    name: 'ATP Electronics, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kelsey',
        lastName: 'Drake',
        emails: ['kdrake@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGEZ0CDDkMRnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622571986331?e=2147483647&v=beta&t=OLR_oBWdx2Q6uLzzJX2Jb0dbZMeKB7NaYvAEptMHDnw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Winnie',
        lastName: 'Chang',
        emails: ['wchang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mike',
        lastName: 'McClimans',
        emails: ['mmcclimans@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEJCKwvK6FeHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516277625457?e=2147483647&v=beta&t=WbSq-BECltTdw96Ts0lMLQWalSHq8ZQgJO_N5Nt2wso',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Moorman',
        emails: ['mmoorman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEQa2qgkji2AQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516310329418?e=2147483647&v=beta&t=Sc2YlyADoS9wcYhx6L3aDjqeEDRW1TSwXDda8mp4vA0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bill',
        lastName: 'Lane',
        emails: ['blane@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  geisermaclang: {
    name: 'GeiserMaclang Marketing Communications Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  smartiks: {
    name: 'Smartiks Technology Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  timengo: {
    name: 'timengo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Thomas',
        lastName: 'Nielsen',
        emails: ['tnielsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHUUj9A2UNUOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568560564560?e=2147483647&v=beta&t=nOnmqTGEjw9G-B52xYT9Cyfj4kJdEUb0mtPJlueaxaA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sune',
        lastName: 'L.',
        emails: ['sl.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGqp6xyXW14KQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710758706001?e=2147483647&v=beta&t=McHur3ZFgTAsGE-hbSZHELc7Cn0XSg0irEmulPgdd7o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gregers',
        lastName: 'Blach',
        emails: ['gblach@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEBBfOxzX0rmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705943439585?e=2147483647&v=beta&t=13FbdpGhwtI1sf_yoC0ioYwMjIxNEL4Ok5Z9WhjsWHY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Silje',
        lastName: 'Berg',
        emails: ['sberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ulrich',
        lastName: 'Kroll',
        emails: ['ukroll@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mbcGroup: {
    name: 'MBC Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric',
        lastName: 'Holloway',
        emails: ['eholloway@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHi7rvF5Lv7Ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516884635792?e=2147483647&v=beta&t=zgPVG7CU8fjdlGUFt6C78uCELYSMvWodkyG9CSLR7K0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eric',
        lastName: 'Holloway',
        emails: ['eholloway@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGNfP_NO3ffXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517452879136?e=2147483647&v=beta&t=icMslyJUqDRZBTEB01wJCHZJ5sLjOWiQUpWdhI9ta1s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Astolfi',
        emails: ['mastolfi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEEPYq5HKywTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723480209930?e=2147483647&v=beta&t=-peXCvEA6xno5pnUQzgaJYJXrx9hGJlkwYqJA8SbTKk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Jackson',
        emails: ['mjackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHyvhQo_ffbyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626050200487?e=2147483647&v=beta&t=mv9fCGCOVaXGk_hEZQGg4AULpEE4roK7Mp_RDMXplec',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abigail',
        lastName: 'Franks',
        emails: ['afranks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGIjrycYRzz7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614715814892?e=2147483647&v=beta&t=uoV2b0flhgw6Siqfac7OCubJ86SXvtEB9DPIKBQ2KXU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  newity: {
    name: 'Newity',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Cody',
        emails: ['dcody@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFjdBk2cI52ig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629980233451?e=2147483647&v=beta&t=emJ9KkAWQbSCj3yK_OdviXiXh5mCg0lYLyZxqaoz57A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eric',
        lastName: 'Colandrea',
        emails: ['ecolandrea@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHMv7lOAAbuQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638914643809?e=2147483647&v=beta&t=QclKw6wexzHz8apS5cPvLL00jjCQcDl8NTaV0A0hIgE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kathy',
        lastName: 'Salgado',
        emails: ['ksalgado@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE1cHe97PTR_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620099294695?e=2147483647&v=beta&t=OM0vlCH5lRPblDWYvc9O99J-Dzd2xAmEEolKXa9fDKo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Surya',
        lastName: 'Govindhakannan',
        emails: ['sgovindhakannan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGhrwp1YRYeAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722639654458?e=2147483647&v=beta&t=s2JK6TgBh6Li447IibPh3YPTSkDbHe9mBTNuyGoTnTE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Melinda',
        lastName: 'Bell',
        emails: ['mbell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  catertrax: {
    name: 'CaterTrax',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'Luchetti',
        emails: ['jluchetti@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ashley J.',
        lastName: 'Ditch',
        emails: ['aditch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFUgxdx_lrTpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516049967829?e=2147483647&v=beta&t=Ou95uJZdar710zbLc58ptyA0vaAkEnxMTB7KHO-ZKg4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lindsay',
        lastName: 'Pothos',
        emails: ['lpothos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHvyYsAS4VknQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714510997505?e=2147483647&v=beta&t=uIOv0JvaEVZ74fG2My_uQH2OFluN8g6de56V3V-Nhic',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kevin Jesse',
        lastName: 'Sr',
        emails: ['ksr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGfxETDaIdIuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516825043219?e=2147483647&v=beta&t=saYptAZZKV2D-LgBUf7OSCRqV1BVoxxQuhHI6-jX_JY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nick',
        lastName: 'Ramos',
        emails: ['nramos@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  qintel: {
    name: 'Qintel',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'William',
        lastName: 'Schambura',
        emails: ['wschambura@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHY9lrGJ3dTfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715698367176?e=2147483647&v=beta&t=bL0hZNj3-qMcRa_2hczZhciuIWmZc2BnM54ol_HuSOY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joe',
        lastName: 'Zanini',
        emails: ['jzanini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHIDsRfaBEHog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690651003696?e=2147483647&v=beta&t=drqChwyB_zBhyN5ny61_4ZJEwZb2x9VRCfY8UgdXw0w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tom',
        lastName: 'Grasso',
        emails: ['tgrasso@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christian',
        lastName: 'Roylo',
        emails: ['croylo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH2D7P569uPmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517763008182?e=2147483647&v=beta&t=Evs7cUjYOxnii-WCdTZYqNJk4BjIJHGi-9KLx0efdi4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chandler',
        lastName: 'Cain',
        emails: ['ccain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEMzp5H3ZIZXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696279284561?e=2147483647&v=beta&t=qn49eMk8d7GvenT_n66x08STsZI4KrcruL-IekvhanY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  itcosolutions: {
    name: 'ITCO Solutions, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Joubert',
        emails: ['cjoubert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHGdCCvlSG9nA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631754619558?e=2147483647&v=beta&t=B5vWNVknGLx1iQ6gfgLq_Ai85H9_uGhQhziN2-2Co6Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mona (Harpreet)',
        lastName: 'Sandhu',
        emails: ['msandhu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEZTSTvFu6lgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619125209767?e=2147483647&v=beta&t=wFEPM8oIpz_KIF864VP_7iNOImq8A2XUEGeH2oqTCGQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dalton',
        lastName: 'Jassman',
        emails: ['djassman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGUdOdJL3eacw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724352506285?e=2147483647&v=beta&t=mKoCiWvQsIhPmYKsoZORHyra-87cWQfbAsT4CjbEhQ0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ethel',
        lastName: 'Evans',
        emails: ['eevans@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shilpi',
        lastName: 'Ghosh',
        emails: ['sghosh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEEbKgDV9E_aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693006100739?e=2147483647&v=beta&t=0LeMY1RUFCv1N-lLpc6OXWUbSq7nxEuTwlBBO4hQVFI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  renditiondigital: {
    name: 'RenditionDigital International Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Arcee',
        lastName: 'Cabrera',
        emails: ['acabrera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG-R1_oJaIFMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517486790031?e=2147483647&v=beta&t=QVONGSW0Y9e2Tbo_wydj2dzYlfqsyNR-cgcq7dzceJQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexandra Nichole',
        lastName: 'Austria',
        emails: ['aaustria@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGyZUAbC_Qing/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1537468254674?e=2147483647&v=beta&t=q0hg40nrmI_fpwPPRtTn0YRkLZ1uaKp7QD99Ek5ftqM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andel Vincent',
        lastName: 'Aranas',
        emails: ['aaranas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF2ZiSM-a0ekw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693829801873?e=2147483647&v=beta&t=ve9MfEIsuTxCHZ0mzl_lUwsCY2l6BDNVWU_On8-3x-g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joy',
        lastName: 'Dacaymat',
        emails: ['jdacaymat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEIbsd0iNHHiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648442769443?e=2147483647&v=beta&t=83Dih3vrOivG-sSp29SEhdPE05ITJaMDpqPzL2R0yJI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David Geoffrey Fabella,',
        lastName: 'MBA',
        emails: ['dmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE-ojwhC2uuHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691111902415?e=2147483647&v=beta&t=HxRgwwiBXCUKPOVXrP3VjJnWw8XN_8P2K0-L8KNKXDY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  datasys: {
    name: 'Datasys Network',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Plugaru',
        lastName: 'Andrei',
        emails: ['pandrei@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHXoVmuDCGE2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516535084873?e=2147483647&v=beta&t=WEyKH29Cad6g1SehLJOlDq4y3BPtt_49YVKrDJBjlyM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Giorgio',
        lastName: 'Bardelli',
        emails: ['gbardelli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHPUDpsV_7LFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519831260184?e=2147483647&v=beta&t=k494oveeFu0XlL9sGyqeibB7WFQx4QUzdHl5PUmSecA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daniela',
        lastName: 'Zanet',
        emails: ['dzanet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGrGOTd40rcJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516459788192?e=2147483647&v=beta&t=sAb8rAs56mMg-96KS5LlTiaCUGrmjsWNOrq8uOGQ2II',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'TIZIANO',
        lastName: 'INGARSIA',
        emails: ['tingarsia@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Raffaella',
        lastName: 'Trighiera',
        emails: ['rtrighiera@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  evolutiops: {
    name: 'Evolutio',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aaron',
        lastName: 'Abodeely',
        emails: ['aabodeely@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGbiRNFDYVqIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724909347512?e=2147483647&v=beta&t=T37NDW-EL_OH5lIb5Rbou0faf-mjIXU33Cnjwccnx5g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'Ties',
        emails: ['aties@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: 'Cuskelly',
        emails: ['acuskelly@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'Seifert',
        emails: ['mseifert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQExrd2HeWb9eQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721304908508?e=2147483647&v=beta&t=YULYHiI4BC0eDMt-JqLm7GFb3NgJNo1frYa-nEoZFQc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'Lindquist',
        emails: ['alindquist@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHEDKKKd_moAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516878942188?e=2147483647&v=beta&t=1NPniotBQU1K0O-bDSsS1WkQsHrkvUBobAZJ8K8b_kY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  transloop: {
    name: 'TransLoop',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Julia',
        lastName: 'Robinson',
        emails: ['jrobinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF6L1XGJjkp5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683502109727?e=2147483647&v=beta&t=QpL36_Z-vUGdp0JI35SpoIP9KxNcZsoxcT5JqpZR8h8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'William',
        lastName: 'Kirk',
        emails: ['wkirk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFjbZG98e4gnQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628176015416?e=2147483647&v=beta&t=tLVJ_7EpXJFtqcckVNEOzkq6t-RoR8frPXhWg6kKcvI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Drew',
        lastName: 'Sikes',
        emails: ['dsikes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGAlFd06c14_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666707536569?e=2147483647&v=beta&t=kYK0P6nbyU0Uhd67jfFxTp_UrydWQqE-MnNsEDJkF88',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zach',
        lastName: 'Preuss',
        emails: ['zpreuss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEWJT1-sisiqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718280516871?e=2147483647&v=beta&t=s7dGVQ9pBKzpizSMVEmC04KVJxlpT6nRmiv9eb4b4V8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Taylor Walthers,',
        lastName: 'SHRM',
        emails: ['tshrm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aienterprise: {
    name: 'AIEnterprise Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'ASHWIN',
        lastName: 'SURESH',
        emails: ['asuresh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFqANbx4jAHIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708749312179?e=2147483647&v=beta&t=F1dX_BajRfJ0mcpc56qDbZ-h85nXXzez6lJwb0zlZA4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pragati',
        lastName: 'Kumar',
        emails: ['pkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFg6JwNHjqLtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721222610088?e=2147483647&v=beta&t=omuksKPr3euPkRLAG4JXuspQFKpYYXCa-XrS14Tg7sQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Upendra',
        lastName: 'Chivukula',
        emails: ['uchivukula@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Boudreau',
        emails: ['jboudreau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGHQ2bTo6j2qA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695913363222?e=2147483647&v=beta&t=ngX84UdfN8FvogmsyTD0Hwgq-QdGpdjFNlv67pWipu4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Neeraj',
        lastName: 'Aneja',
        emails: ['naneja@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEMpasdv18Oyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713502206122?e=2147483647&v=beta&t=jYSxEToE78e8TJ9fM1DjEs1PPvLA3v0bR3kYtnuFAJ4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  prestariang: {
    name: 'Awantec',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mohamad Hisyam',
        lastName: 'Majid',
        emails: ['mmajid@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG98FzqDAwsgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720654513190?e=2147483647&v=beta&t=NYBpRg5xOF4t81awIOtBqDDwckSkXyg-k-6BGGwaeJQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Justin',
        lastName: 'Francis',
        emails: ['jfrancis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sharifah Nor Hanim Syed Aslom',
        lastName: 'Shah',
        emails: ['sshah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFRYDyiSD_iZA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1594109488241?e=2147483647&v=beta&t=ivdSU1N9txidZqGcsfo8Q_hp7T5KSiJr-95sHhvhe4U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'KK',
        lastName: 'Chong',
        emails: ['kchong@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Wai Yip',
        lastName: 'Liew',
        emails: ['wliew@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  integratedrental: {
    name: 'Integrated Rental',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Greg',
        lastName: 'Feenstra',
        emails: ['gfeenstra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHPJXae7i-Vtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643652554783?e=2147483647&v=beta&t=-aO00IWlVqp_r8fDxzJCwkz3Lsx_X2cnaxJ3qKAdHho',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alise',
        lastName: 'Moncure',
        emails: ['amoncure@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kristin',
        lastName: 'Funston',
        emails: ['kfunston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGetcondg5YLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693188807412?e=2147483647&v=beta&t=1JRuW3PEwGOD5hyGNQChQB6S_22KK_El1Yvc-7gArpg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dana',
        lastName: 'Stump',
        emails: ['dstump@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFYdZEx6IHYMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517544877242?e=2147483647&v=beta&t=VefhteUK2r2d1pwbeyqD-rytKyLqp9qolvWCpCUZZ2s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matthew',
        lastName: 'Townsen',
        emails: ['mtownsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHSugK-_84Jng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516945007154?e=2147483647&v=beta&t=rJWDIuWI_kQyKf0ss6ngwISFfEnGiDwF7ggwymL2xEU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  astutetm: {
    name: 'Astute Technology Management',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric',
        lastName: 'Madden',
        emails: ['emadden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEwcuwdRB734w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720841341881?e=2147483647&v=beta&t=HrwG43REE2Z_5WwZMueGyPXMRaB_OoYHr0JqfYuZdvw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anna',
        lastName: 'Madden',
        emails: ['amadden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG1y4ANuPBPjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658760573644?e=2147483647&v=beta&t=ht_nRcQDVV-GV-JAz31U8LrHXWzTZyZA1TP34wb9m-g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sean',
        lastName: 'Malone',
        emails: ['smalone@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH-nRFdkBPWng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670585906557?e=2147483647&v=beta&t=kng9UcHyhLWx8R7fC4ldMhlDHtgj5YWE4DvPm4x4bco',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kane',
        lastName: 'Tabor',
        emails: ['ktabor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEqasoc6VmOEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718715252504?e=2147483647&v=beta&t=2bcPDXUeb_BlLdVVaYEliCr-q5OCe0w1zK70F5se3Kg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'TJ',
        lastName: 'Russell',
        emails: ['trussell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGfT68L7A1bxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696798012629?e=2147483647&v=beta&t=XHLL9_HIxi79Q_pYHyIo8j-JWZw3mbZvAoHYgZygcUE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  i2a: {
    name: 'i2a Technologies Pvt. Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kamal',
        lastName: 'Xavier',
        emails: ['kxavier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHG0cC6cIDQUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609141581904?e=2147483647&v=beta&t=tN5ql7B_9Yv7yswwru2QcV0wa9mK-68CV8P0VmrfZmc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pooja',
        lastName: 'Thapa',
        emails: ['pthapa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFRO5lRaaDQDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697106988664?e=2147483647&v=beta&t=4J3SfEXFjMC7_OiRLlkdEOlLBfRsiArh53Y95xRgGgA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amit',
        lastName: 'C.',
        emails: ['ac.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHkOWVP7JQv-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662379840585?e=2147483647&v=beta&t=JYRVW1w_cRcjnNVCAA_NNY-z2YMhUc6BZNv8Ezgw8W0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sumit',
        lastName: 'kalkandha',
        emails: ['skalkandha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEI98Uq1Qm2mA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693998493459?e=2147483647&v=beta&t=tzvvCPbfzfNlDu-oDwjr4MQIYRzVX-T9e_stmQ_L9BI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aasif',
        lastName: 'Bhat',
        emails: ['abhat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGXqoh5OG-o3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632415194951?e=2147483647&v=beta&t=KpTRggacjGWn7cACZD0E84nUo32ga1ZzaE9USr-z-Ew',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  phases: {
    name: 'Phases',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ivan',
        lastName: 'Liachko',
        emails: ['iliachko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGXQ-EDR3elSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726241128811?e=2147483647&v=beta&t=MS9DyOhHW6ZomtpVvLvzSy5mxZEYrYCBT9gWyrQDCQk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mike',
        lastName: 'Mazur',
        emails: ['mmazur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGTMpq-VZ6uRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517698555860?e=2147483647&v=beta&t=9rCOw_x0kZ_alE6_S8eJF0M1z0nklFasQQSvUnx6vuU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jon',
        lastName: 'Glass',
        emails: ['jglass@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEFGtBXycwXTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647611814602?e=2147483647&v=beta&t=tf4GBeNlCy749yhTSigDKV5xXko4R0HaflBtkrtEZoc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeena',
        lastName: 'Vaheed',
        emails: ['jvaheed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFjNwkmoxReMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516433903136?e=2147483647&v=beta&t=eUXVymOnQotfS7nFhkDrOzXG2c9w2HiQ9EmhHVznUUE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Myra',
        lastName: 'Schwartz',
        emails: ['mschwartz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  northerncomputer: {
    name: 'Northern Computer Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Wilson',
        emails: ['cwilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHztgRHCXgahQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646759152942?e=2147483647&v=beta&t=OQHaVpLVaUtDked2CO-0eqM5w305P4DWxho2j1ZN-q4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matthew',
        lastName: 'McLaren',
        emails: ['mmclaren@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dirk van',
        lastName: 'Dam',
        emails: ['ddam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGhbku_y8Ymlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516964886274?e=2147483647&v=beta&t=doxElWIug_xM9Uextxacswhq17mYaDRy65tlsJMejkU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brenden',
        lastName: 'Salter',
        emails: ['bsalter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFSkbl3VvXW2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517626727180?e=2147483647&v=beta&t=0Fl0TJhy1w5ySBhZQEZ-_DRICCr0h9B-5wyCcQ2hl6s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeff',
        lastName: 'Olson',
        emails: ['jolson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG-ulgpMk6TSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1535747531418?e=2147483647&v=beta&t=cbAXSBjzoiThycAvF9QzFAuwUUMCnYYaDtr6fuvvBRo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  genesisdigital: {
    name: 'Genesis Digital, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kelley',
        lastName: 'Schultz',
        emails: ['kschultz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hector',
        lastName: 'Yague',
        emails: ['hyague@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQElsJOKpp47ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630626256472?e=2147483647&v=beta&t=cpBQ3pQt_g2nNpOOebZFvhUhpmfcctfQbXgnacRcGZY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Darren',
        lastName: 'Hakeman',
        emails: ['dhakeman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vikram',
        lastName: 'Verma',
        emails: ['vverma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGf2I1ouzxsqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667843382208?e=2147483647&v=beta&t=U_DGVI6EUbSVm1Px-IbXKb-VNbKNCN1N3dCyfoI6JSg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'AJ',
        lastName: 'Roberts',
        emails: ['aroberts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHk20M-uqbc0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649874201351?e=2147483647&v=beta&t=A0hdIu2AJImfDGG-pApmELQzSffXdbd69t5j-VE45sE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  searchblox: {
    name: 'SearchBlox Software, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Timo',
        lastName: 'Selvaraj',
        emails: ['tselvaraj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOZdFXxE6gZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676513935744?e=2147483647&v=beta&t=LuhTY_FI2zNDLVGQPG0cPODqIX3EEOjx8YtcwKQjB68',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Selvaraj',
        emails: ['rselvaraj@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sushmitha',
        lastName: 'Inapanuri',
        emails: ['sinapanuri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFofHrwfVye7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517427808817?e=2147483647&v=beta&t=2wF86luQfkN-kJJyuwoEb-AvcQfBlvql9zcKCeko6Nc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sheik Syed',
        lastName: 'Ali',
        emails: ['sali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGuoDx0Wlj4-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709628462243?e=2147483647&v=beta&t=S-Mo9-yoFrfiZzIB5Q6rkhyHPIX-Pulp7D8CrUeV9Rc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vishal Vaibhav',
        lastName: 'N',
        emails: ['vn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEUOu7vhnu2rQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588573424738?e=2147483647&v=beta&t=GtG7yDLTGmqhJUXHL9Rebj4ZKJMDxDIxkvGYZ0IF9wM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  drivenTechnologies: {
    name: 'Driven Tech, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Morrison',
        emails: ['mmorrison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGfJpqPi4diZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708014048608?e=2147483647&v=beta&t=rMiDP2gwIaaW6JmhKbHSg6IroEjy_CHDQ5i8SxS_28Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rudy',
        lastName: 'Casasola',
        emails: ['rcasasola@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHFd7Y23orPAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708174312283?e=2147483647&v=beta&t=dhAoTYq2UYl86sHYZy9Su3duInmVqtHXcLlw61mAlZ0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paul',
        lastName: 'Powell',
        emails: ['ppowell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Erik',
        lastName: 'Bush',
        emails: ['ebush@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGwQ0lgZVaY_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659015348652?e=2147483647&v=beta&t=JbZcN9N4ko3bvmNd4mp3oPQcmLtHRPu7-dTvoYdrPNM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eve',
        lastName: 'McDavid',
        emails: ['emcdavid@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQELsDe5a53MEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635383634980?e=2147483647&v=beta&t=xHo33-fpm24xPMwFpMQAgwaZrmsSFm1xnGEib6aUXck',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  atto: {
    name: 'ATTO Technology, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tim',
        lastName: 'Klein',
        emails: ['tklein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEPU_SrYEqOZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516258855347?e=2147483647&v=beta&t=2YssRUm27R_hrsPT3cse8GlxvKGjxHua6IoO98NL6-M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Thomas',
        lastName: 'Johnson',
        emails: ['tjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFifVLm0LGI2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671136840605?e=2147483647&v=beta&t=IraTPmAVLy1ODBIdSArFb0zqAUo79afKIbmzHld34WY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amy',
        lastName: 'Merritt',
        emails: ['amerritt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tom',
        lastName: 'Kolniak',
        emails: ['tkolniak@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeff',
        lastName: 'Marks',
        emails: ['jmarks@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eniac: {
    name: 'ENIAC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gautam',
        lastName: 'Pujari',
        emails: ['gpujari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGKNSpgUSvbsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715128679718?e=2147483647&v=beta&t=9wIM3gG_RPLWMDvES9_304mDoMmWC3yc528C6O-l6cw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Milton',
        lastName: 'Pires',
        emails: ['mpires@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEraWS3NBm-pA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591135306096?e=2147483647&v=beta&t=aMA0YRb-PycHsEWITakENu_RAC7bCKwowHw9_ah8fq8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marcos Alex',
        lastName: 'Rodrigues',
        emails: ['mrodrigues@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGhvvzPnp6rfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586994142377?e=2147483647&v=beta&t=QGML6hGJPvWnegptItlXZUwIvaQcsF52SkANkJg7jfM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bruno Vilela da',
        lastName: 'Cunha',
        emails: ['bcunha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFOuTvkZ451cQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549637217163?e=2147483647&v=beta&t=tNjOqpI_bKKwkFX2bDkCYFf82LDa9JyFeYxrTle5aw0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rodney Rodrigues',
        lastName: 'Silveira',
        emails: ['rsilveira@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFI6UJi0VNvwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726268838448?e=2147483647&v=beta&t=TaPNgRK6U7DOAJjsK1sDWZ0zzeakrG825UhetUOBhBQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  airbornStudios: {
    name: 'Airborn Studios GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jordan',
        lastName: 'Cain',
        emails: ['jcain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEaERnrVMtLww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581998372092?e=2147483647&v=beta&t=Cg0BMkUbhFqz6DS0at5stlNg-BVT3UJLjQOE8uyoUB0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Julian Dasgupta – Airborn Studios |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anna Leonhart – 3D Artist – Airborn Studios GmbH |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFSvVDUKz-tpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695804617316?e=2147483647&v=beta&t=kmO-8ERvYWqAsb9kz_k-RH9QXIVcyrPUhKyaOW8tgDU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Elisa',
        lastName: 'Ferracini',
        emails: ['eferracini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHUs_KtWTNmKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709038655297?e=2147483647&v=beta&t=Cgyfuj3qpq0Q5i7Ylvm8dbPsHhOWg2EcoC5jhOWTWi4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Valeria',
        lastName: 'Traverso',
        emails: ['vtraverso@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF-vCiP851Aaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728309697218?e=2147483647&v=beta&t=EFUv-XydyQANeyGleGMzb6q3JzmhYNS9gAP6el4warI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cgnglobal: {
    name: 'TADA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ronald Mareachen,',
        lastName: 'CPA',
        emails: ['rcpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH6uZ97deCgkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699484155629?e=2147483647&v=beta&t=5ZsA7CIEmE2Hs6uSG8dux6BtXLGhv9AUigH1stsJZ5M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Scott',
        lastName: 'Broeker',
        emails: ['sbroeker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Denny',
        lastName: 'Tu',
        emails: ['dtu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHkWzUYhkKfHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1566870017273?e=2147483647&v=beta&t=zSTUrd-rB0K-YY7QmlfiywbWkjXxxXG8xoliJjCu1YE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nancy',
        lastName: 'Draper',
        emails: ['ndraper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHe6FxGfjUOhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517285479515?e=2147483647&v=beta&t=oBNHARwrvZ3zZnn437nAHzllxtWaM9B0S0NAjgmfe6A',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shruti',
        lastName: 'Sharma',
        emails: ['ssharma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  securenetmd: {
    name: 'ThinkSecureNet',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Larry Poli,',
        lastName: 'RCDD',
        emails: ['lrcdd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kristen',
        lastName: 'Jackson',
        emails: ['kjackson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Drew Laroche,',
        lastName: 'CISSP',
        emails: ['dcissp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHbJI56BZYWRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516262103384?e=2147483647&v=beta&t=YcNNHJVCw2kWO7cfT6AjDYvHaRVa4mpSLBPNCtnANE8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jack Berberian,',
        lastName: 'JD',
        emails: ['jjd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGF6nNpB8rFXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516971011349?e=2147483647&v=beta&t=n_wNQIZxtOo8LRlaUdihmM5mL1ABNYMEODYb8dKQok0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tony',
        lastName: 'Ledbetter',
        emails: ['tledbetter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHWej69lp7EjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710910195890?e=2147483647&v=beta&t=AHdwed0plelDyyWQ2RKN6edXrH4AQ4UDwqq0xE8ERo0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sphereinc: {
    name: 'Sphere',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul',
        lastName: 'Dominguez',
        emails: ['pdominguez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGFqJ8waDKl7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516313120075?e=2147483647&v=beta&t=mg-R0D-KbFo3fkRfETWBNTG0BQKhI66PM52ILOCIXfg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gina',
        lastName: 'Hayden',
        emails: ['ghayden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFu8uR9G4zIBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605635612180?e=2147483647&v=beta&t=5kzDd2eeA1qhL1XV1s7KF-qBJBp7m_bsHZzHn6PEx1M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Leon',
        lastName: 'Ginsburg',
        emails: ['lginsburg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEFO98-o0wn7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575140864292?e=2147483647&v=beta&t=0OYK6gD2un4Y5E6iAqlK6JyajhGSMski1P53_B9nmTg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sean',
        lastName: 'Haney',
        emails: ['shaney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhCHuJ_CTxKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589156470010?e=2147483647&v=beta&t=Nm9x7AY6VbZI-fY77Clcv4N39C5orACh0VajEeOvMlg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Natoff',
        emails: ['dnatoff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHs2G4FhQtdOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516236112776?e=2147483647&v=beta&t=--yALxJn4sRQhF5iagdnx2PSVf3gdYtV_gjFxIzQuZU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  parachutetechs: {
    name: 'Parachute Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ashley',
        lastName: 'Lukehart',
        emails: ['alukehart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFh6gn3_HXVbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722385892986?e=2147483647&v=beta&t=WiYuTDUqOArKuDcFP38ooM15CXA7CIrp_SpPh0rgVW0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Lukehart',
        emails: ['mlukehart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF8emrbGfZKVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516323542923?e=2147483647&v=beta&t=9pzhyL_BC_VhwHwgh8GLHupR3Eo31FhEuBsX_TF5Fqw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eric',
        lastName: 'Woudstra',
        emails: ['ewoudstra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHwfz00nv6Asg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517628156542?e=2147483647&v=beta&t=goBM9GsImdbnj7D6_10LrfeWoWB3cY0WDaL9IjaJEZo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Patrick',
        lastName: 'Sullivan',
        emails: ['psullivan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEfFKnUXShl8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570207491483?e=2147483647&v=beta&t=-BDXMQFfD0lr9P6biMa7lFF0nFyUfMKvl0DleE5_PNY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rebecca',
        lastName: 'Lamanna',
        emails: ['rlamanna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH_lZWP8PhChA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705962661533?e=2147483647&v=beta&t=0oOtsesrtMVTCQry3KJe9hQkZDtgtERO-Xa4Gpl5RqY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  inabia: {
    name: 'Inabia Solutions and Consulting, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tahira',
        lastName: 'Ashraf',
        emails: ['tashraf@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGmgIfbS8AvFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673653722889?e=2147483647&v=beta&t=8cPvGm8eoJy58w1XV9vI1vBMUd2bbnTE0GuXrrOnfdw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mingche (Steven)',
        lastName: 'Kuo',
        emails: ['mkuo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHqTCt3Sx0p3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589446826960?e=2147483647&v=beta&t=sk5_uWGAKzd3ayfNM5sbchRbfdiVGEICWQNE1uWljfU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aftab',
        lastName: 'Farooqi',
        emails: ['afarooqi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Raul',
        lastName: 'Ramos',
        emails: ['rramos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEIe5n51p70dQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713847966773?e=2147483647&v=beta&t=E_iHJZas5rsuJliqOT-x2fa3BvpgY_yj4pSJAKeU5DU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Serafima',
        lastName: 'Avdeyev',
        emails: ['savdeyev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEdNpgHHJflGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728522172852?e=2147483647&v=beta&t=GtaTbhvydKrwPdnHt8gXOROoo1qPuAvydNdQRxAZaRw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sability: {
    name: 'Sability',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kathy',
        lastName: 'Kruse',
        emails: ['kkruse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEOZeFnYYHwLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700645222517?e=2147483647&v=beta&t=P5J_eaHPbwHe5JuGJyilvvvow1KJ3I49WzKYUdDnTbY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adrian',
        lastName: 'Gibbs',
        emails: ['agibbs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHot03h7FTr2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721678741092?e=2147483647&v=beta&t=7oFk5IUKj9Rj2fxztTdynEjL2qevLWIi8OY9wVn0tbQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Mearman',
        emails: ['mmearman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE_luZtnms5OA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681769414778?e=2147483647&v=beta&t=mkXlyhMCy0b3kJ7gTSpcKx8gXyJ7cF7Ccd4OpMsNGNg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rob',
        lastName: 'Leonard',
        emails: ['rleonard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE92l0MeGLfMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518708432421?e=2147483647&v=beta&t=_ejTomSaqY70iQovneLZOu30uvY1beyY1ljqu19XBZE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Samantha Sak,',
        lastName: 'CHRS',
        emails: ['schrs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHE1otIZg77EA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711024915300?e=2147483647&v=beta&t=tFhpCvGFQ2PxsjzgA_eKX6z4j43KPbjN3p4CLV8_6Ko',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ipnsinc: {
    name: 'IP Network Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chip',
        lastName: 'Leazer',
        emails: ['cleazer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shantia',
        lastName: 'Hassanshahi',
        emails: ['shassanshahi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jason',
        lastName: 'Cottey',
        emails: ['jcottey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFboL9yG3LNdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517229871422?e=2147483647&v=beta&t=TWjM2kOhWslgud_ZoqG7g6tVPWzD4uexbTWbmFxoTWE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kaiwei',
        lastName: 'Chang',
        emails: ['kchang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFQA8H-KRB8lQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517447870216?e=2147483647&v=beta&t=aE2gG-XY_LXY-ytNHPBWy2OAU-_PUMoK2t6N5xjFbJo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tinuade Oguntuyi ,mMBA,',
        lastName: 'PMP®,fNSIG',
        emails: ['tpmp®,fnsig@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFwlFkBFZ0Xbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663114430783?e=2147483647&v=beta&t=VV4-J1Pg9xH6fI3oe9o-zf_RD5IXqKyXq9tMeLiEC9g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  salucro: {
    name: 'Salucro Healthcare Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matthew',
        lastName: 'Krol',
        emails: ['mkrol@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFj0EWPnXolZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656423169307?e=2147483647&v=beta&t=m4MJawCPQpwojOxPFaLigDrnrZD13qgnqRPUeuc5Ptk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Schulz',
        emails: ['dschulz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHFbJeJUAuAiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686240635272?e=2147483647&v=beta&t=pOkqpj9YGLirh9WOFZfhz1Fz7PixLQVAwnTUcEuLk3c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mandy',
        lastName: 'Ghetti',
        emails: ['mghetti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFmIyN8rO6ZoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685651439381?e=2147483647&v=beta&t=L_2nUNen9JXl8iOQJFUPdUn3HVfF9sKpEuEJElDpje8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Randall',
        lastName: 'Reynolds',
        emails: ['rreynolds@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFxEIz_TiT0kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685122390144?e=2147483647&v=beta&t=9RdRI3hCXGLeDvSWCbd3y5X82UxVpFACCRY5ZmD31zA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andy',
        lastName: 'McDonald',
        emails: ['amcdonald@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhPjFXKZEuyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1599055844569?e=2147483647&v=beta&t=ozKZ36_TmLsOJs0A5wAAwC47tMgUQ4mG8Rov-k-uvqo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  proteconsol: {
    name: 'Protecons Solutions Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Varun',
        lastName: 'Manjappa',
        emails: ['vmanjappa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tanu',
        lastName: 'Mishra',
        emails: ['tmishra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH4RBJeE290Gg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710503292911?e=2147483647&v=beta&t=W-p4u1MYLlMCuks5gpsRsMxIqsgEuIIJ-0wp9jqfkso',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Craciun',
        lastName: 'Cosmin',
        emails: ['ccosmin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vishal',
        lastName: 'Giri',
        emails: ['vgiri@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Siddharth',
        lastName: 'Pathak',
        emails: ['spathak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHHm_UOZ4iwXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722570928542?e=2147483647&v=beta&t=Uqwla2sXZfQKdoTJznh1B_ZTyEC2CpfeiBzYG-lMsM4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  softsource: {
    name: 'Softsource vBridge',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kimberley',
        lastName: 'McLean',
        emails: ['kmclean@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF1aG5o8dMcxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648511076444?e=2147483647&v=beta&t=8TBqfwz6USqxJshRC5nb64lYBIiKCUwpLhOZoIzD-fA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Scott',
        lastName: 'Parkin',
        emails: ['sparkin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFsI3FFDqsR1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671781307079?e=2147483647&v=beta&t=reEcIn5hRUJ3W7nCHjkBuooHxlTKTqG0b9w8o8CRZcs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'David',
        lastName: 'Small',
        emails: ['dsmall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH3KX-lE_hKTQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516494714286?e=2147483647&v=beta&t=ZqPrNqPgv6getMPKi5t18lXQOQBWoHDlNAWptpAqHQw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Antony',
        lastName: 'Watts',
        emails: ['awatts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFymA5Kv7CJUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718877169526?e=2147483647&v=beta&t=p75PS-o-65GYd63k8Pkizka2l5siyBKSPb9CPIMoFjU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mohammad',
        lastName: 'Malik',
        emails: ['mmalik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH0zXuNNSSujw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516260223550?e=2147483647&v=beta&t=dHBtDooiQLseYc8lRcA_4p1-o6kXUDFb4MoK9FKf0a4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tipl: {
    name: 'Transcend Infosystems Pvt Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'TIPL',
        lastName: 'Saini',
        emails: ['tsaini@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Zunaid',
        lastName: 'Alam',
        emails: ['zalam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHJTuYQDe_Jpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718302002289?e=2147483647&v=beta&t=Pma6p1NiKk06aAVI0l3GMxjWvewzCyB_GfbNvDb-LGc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rachin',
        lastName: 'Sharma',
        emails: ['rsharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF6cyOBMvklog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718260449472?e=2147483647&v=beta&t=tZVcVpbbhycgqdlcWsqm862hN8V0zu62iX4tVFGYMLk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Imtiaz',
        lastName: 'Ansari',
        emails: ['iansari@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Prerna',
        lastName: 'Rawat',
        emails: ['prawat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGSaWj29lfS2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636340344375?e=2147483647&v=beta&t=KhYI8PflRkP060eA3me2-w7mrRHMsVfFkaT3OaeAAAg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  co: {
    name: 'Sebastian County Government',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amanda',
        lastName: 'Cravey',
        emails: ['acravey@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kevin',
        lastName: 'Smith',
        emails: ['ksmith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Susan',
        lastName: 'Beam',
        emails: ['sbeam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHNkrCD0bUiqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669232220850?e=2147483647&v=beta&t=5rsqCYQyRvjnosVu1T_anf-0GW_kyqvLEYzymvbcYpc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Blevins',
        emails: ['mblevins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Philip',
        lastName: 'Pevehouse',
        emails: ['ppevehouse@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dmdsystems: {
    name: 'DMD Systems Recovery, LLC.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aaron',
        lastName: 'Zeper',
        emails: ['azeper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGRoPaH81hadQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603425617535?e=2147483647&v=beta&t=fPpef9Wniizc3AVfLZ_PqauCM1iBjrKjjgYQgyrGGM8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alison',
        lastName: 'Leslie',
        emails: ['aleslie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHTySCpa70p4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594681609529?e=2147483647&v=beta&t=So2bdGbLPbya37EFAOM5m3Pf-tR7xrQX_dEOR4sejnk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Craig',
        lastName: 'Kober',
        emails: ['ckober@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFXLh5FcKkKww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725998870286?e=2147483647&v=beta&t=gh1ABh0QKTZ_Tfk-7vOcykJJm9W7qOkVL7xXqqi52T4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David Binkley,',
        lastName: 'CHAMP',
        emails: ['dchamp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG25TTISblb-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723062220447?e=2147483647&v=beta&t=iGRfmsV5Y4AnaqfXW0SW5zFYlal10ba246IHflKGnZs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steven',
        lastName: 'Rodriguez',
        emails: ['srodriguez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQF3cTCKS_kDjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516278601010?e=2147483647&v=beta&t=v5YunQZzNz1x8Yk3xxeWKKWjXOifCqX8mg6iGM3_DKc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hypori: {
    name: 'Hypori',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jared',
        lastName: 'Shepard',
        emails: ['jshepard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFOIFQyJ_IIvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699551271336?e=2147483647&v=beta&t=HZ5yLXoYv_kCdeCI2hx88gWkGKVjmQNKzg4-wMOoIck',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Holly',
        lastName: 'Bone',
        emails: ['hbone@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Wayne',
        lastName: 'Lewandowski',
        emails: ['wlewandowski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF85wTm9fOj5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681847748477?e=2147483647&v=beta&t=DE3ROFUA6KBSi9h-fsUXWXgtgeEscEErxZsFu9Cb7E0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rob',
        lastName: 'Malnati',
        emails: ['rmalnati@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Laura Schwab',
        lastName: '(Asendio)',
        emails: ['l(asendio)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF240_btr4mtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725482408920?e=2147483647&v=beta&t=RySa_dviwVJ-_P7wRHS5E4Ffsbi2hLzam2f7Bv9fNZ4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  teamdev: {
    name: 'TeamDev',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anton',
        lastName: 'Bludov',
        emails: ['abludov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQENju7OjZ8mgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686565857166?e=2147483647&v=beta&t=sAct8OiW5lHnrBrVG6p_exBIoqUXsgkTMSKueQPBuGc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Oleksii',
        lastName: 'Kyslenko',
        emails: ['okyslenko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHWN_erZMpXtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517357898505?e=2147483647&v=beta&t=o8SyCz5oRYpa18K0kpbFTTnUvtGVbtV3odl4vQRk32o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pavel',
        lastName: 'Kaplin',
        emails: ['pkaplin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGmMriDFeYIqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546842096938?e=2147483647&v=beta&t=l1ipYWZ7aZYIsQMTRcoN2I_ZGiLYp5cc4azVfqupvS8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Stanislav Khotinok – Executive Solutions Architect – metafinanz',
        lastName: '...',
        emails: ['s...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEn7Ln8bt5nlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606827900572?e=2147483647&v=beta&t=eC91qoFxOvEcPbH4stB0j3LwxkZUu2Z0UV8CxKA7u2I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nikola',
        lastName: 'Radojević',
        emails: ['nradojević@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHfKcR6kBLJpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639996499283?e=2147483647&v=beta&t=kYPhkDDH8QjqmBNeX1Bgrz_h39Vz0yKfrwFouUvKZIs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sebAdmin: {
    name: 'SEB Administrative Services Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Keshav',
        lastName: 'Yadav',
        emails: ['kyadav@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEySUQaxgfiWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725300641023?e=2147483647&v=beta&t=31XglglGPdIOaVt-12rRX10i-oaKdbeKPX1Y7-oUflo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sean',
        lastName: 'McVeety',
        emails: ['smcveety@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dhawal',
        lastName: 'Goswami',
        emails: ['dgoswami@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGFMsgXvuQS1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626272577270?e=2147483647&v=beta&t=NI-xP6mRrumQUiMAizK3dDxGZRkIi0uROtiTp_2H3y4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'shivang',
        lastName: 'kumar',
        emails: ['skumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH_JWB7DQCbNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711786796427?e=2147483647&v=beta&t=iO5bWedUFmuOwjYrpoc-uJaFIIu1Ko9sLZQMmNK0c_w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tony',
        lastName: 'Pascuzzi',
        emails: ['tpascuzzi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFd7o55Sgl5og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659616192789?e=2147483647&v=beta&t=2Vuwl_Fa9zbpkPlvEHsaDZZ7pKkZv8VTHiyOsfcdock',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  epromis: {
    name: 'ePROMIS Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mathews',
        lastName: 'Mathew',
        emails: ['mmathew@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF_lgTeUwEnyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701755700788?e=2147483647&v=beta&t=ppa9cV_2avoDjm3nEhvI6RudU1zz48cxG5E8z6GSQUo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jibin',
        lastName: 'Mathews',
        emails: ['jmathews@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQESVK6cQvbFTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698157009822?e=2147483647&v=beta&t=0DEt3W6dAkGdRnxSOqpH24xe7k8lJBECQfeUFwUZxoQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mohammed Awad',
        lastName: 'Sati',
        emails: ['msati@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGo9CE_1IoiqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630748895388?e=2147483647&v=beta&t=QIyD4kX5vOUygSNaxoksPsg-_xX0vQhGWylJWJJPyzE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shijilesh',
        lastName: 'Cherrot',
        emails: ['scherrot@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFqb2RvCzkN1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698508226677?e=2147483647&v=beta&t=yHVCgVbe8CUrTgQAW6zURWNHCWLMy4WmwI3JWy4MpuI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jincy',
        lastName: 'J',
        emails: ['jj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFbVEHgFYAlqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710779989450?e=2147483647&v=beta&t=gAy_zrBvUsxUuXMIoSOSPRCX556Fsr3aGYFHgVQigUg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  flclerks: {
    name: 'Civitek Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris Hart',
        lastName: 'IV',
        emails: ['civ@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCaDiJna1dw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517679140303?e=2147483647&v=beta&t=YdUMXwGIqOn5ZFmvdYZXlfOdAu_rs_-cKWdSsitq3nY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Todd',
        lastName: 'Newberry',
        emails: ['tnewberry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEFhKhjO3srhA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517495383580?e=2147483647&v=beta&t=IioHwTNcUUWtq2H8XBoOGCJsTanVlLBYqVebHoeuZUY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jennifer Tedder, CMM,',
        lastName: 'CMP',
        emails: ['jcmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGvQTuROlxQkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517579002999?e=2147483647&v=beta&t=m2b-sNFfATREO4l70aPCZzanYuH-QoVfUbRLb3WI_H0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sara Sanders',
        lastName: 'Bremer',
        emails: ['sbremer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGyF1Svuvg_Ig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630096411313?e=2147483647&v=beta&t=hcC8eXPwRaJ-9eoP5-3DQKIF3RHpMP-II6Mordzk8CE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: 'Manderfield',
        emails: ['pmanderfield@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFlrur_sKiKLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607352447139?e=2147483647&v=beta&t=q9Ag_pYGbEuE8YVHMtA9Qp3-V85JGCDnimVbL60xPVM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  onezero: {
    name: 'oneZero Financial Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christopher',
        lastName: 'Kline',
        emails: ['ckline@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Fraser',
        lastName: 'McHardy',
        emails: ['fmchardy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFe0iYHs9Qniw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675448079496?e=2147483647&v=beta&t=kXpfH70FYbZ-XPXHsvRY07ETRgfnNTEt5Gv-VGeF0m0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alex',
        lastName: 'Sullivan',
        emails: ['asullivan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matthew',
        lastName: 'Kathman',
        emails: ['mkathman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGMLncd-qJBtQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516996167195?e=2147483647&v=beta&t=SmxI7PAkOm1iiQNZtXvmQvCCnIS-JvMTP8qWaAgDdes',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Leydon',
        emails: ['mleydon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHc1azAzr_ziA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550266967967?e=2147483647&v=beta&t=7Eh0Zx_svuLdtECJUhpElKnynFlNer8hztFYJxBm3o0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  koolspan: {
    name: 'KoolSpan, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jenkins (Jenks)',
        lastName: 'Ravenel',
        emails: ['jravenel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHCRhp3THr6Kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517662356667?e=2147483647&v=beta&t=p-IxubY3cBDS92tHo3_TiWphP6jLDIqJQB2UaIvI8sg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Henrik',
        lastName: 'K.',
        emails: ['hk.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG1qciT07lCUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665056734847?e=2147483647&v=beta&t=CSagzeirOVufgAD7nBfqzJgowHXBDpWXE73K5De5bzA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jason',
        lastName: 'Powell',
        emails: ['jpowell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEoCAMj_husLA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516766990195?e=2147483647&v=beta&t=AFDdHMztKYSGcCZ1U27Le6qHXDgtwBOBNB0PuXEHLC4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alessandro',
        lastName: 'Ossoli',
        emails: ['aossoli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHNMtWq_kp_Fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658406031618?e=2147483647&v=beta&t=YlnKHtNp11dhLj1y6t2mkDHf9eg_dOnBiiy4c1ifMKI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rebekah',
        lastName: 'Harris',
        emails: ['rharris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGjW5g1TWok7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516786785361?e=2147483647&v=beta&t=cNS2BMUbLbt0oFmD5gWc9ef5nQk1jv_UtjBDoqxhOoE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ceeSolutions: {
    name: 'CEE Solutions (Pvt) Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hiba',
        lastName: 'Masood',
        emails: ['hmasood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGbCEOzJLgl6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689673486369?e=2147483647&v=beta&t=Bq-DDkbtYQFrtsxTHI7njJqx-oKNIqMbzfYITb_gIc4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Huishan',
        lastName: 'Hsu',
        emails: ['hhsu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGvTkGqsO4B3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715927133799?e=2147483647&v=beta&t=HcbDsU4uyZClbOVoXBsGchSk1yGpaUrKyFsqFdhaCJY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Obaid',
        lastName: '',
        emails: ['o@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGw8BpVpMmQqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517562646702?e=2147483647&v=beta&t=60bl7akdb0SMOmGKUrc88xh2vyOjFMntrU3QBE8il7Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chander',
        lastName: 'Parkash',
        emails: ['cparkash@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFRH_Q_1ewrLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718511590209?e=2147483647&v=beta&t=0wrwlOW0GXzrQfnAwpswU9_ci4t2GctCzWnOuTnT1UU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christian',
        lastName: 'Frydenlund',
        emails: ['cfrydenlund@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHT2s9pqPAXWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517458904697?e=2147483647&v=beta&t=J0nfs9uSy1QOImbhcodkPNO_FPYYsg-YgwNh7dWS6XA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  technicaltoolboxes: {
    name: 'Technical Toolboxes',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jim',
        lastName: 'Schuchart',
        emails: ['jschuchart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFHWt3Ameci4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647371943574?e=2147483647&v=beta&t=ecienoO_SE_pRF3LBfFFoQM6tGJaCZ9iMI_5phTYuG4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nick',
        lastName: 'Rendall',
        emails: ['nrendall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF74YporqlqHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649187654713?e=2147483647&v=beta&t=3r81y9Lib_-cZkYWQMnz5CvH27HGg6ejIRrEo95pLxs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joseph (Joe)',
        lastName: 'Pikas',
        emails: ['jpikas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG38VzN8SeTSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516800740895?e=2147483647&v=beta&t=Vr3BxJuxeuGjHv8fUVy-bR13qiigZki0d-fo705txP4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christina',
        lastName: 'Miranda',
        emails: ['cmiranda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHUkdURhuVfmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661913329219?e=2147483647&v=beta&t=f-Ni2v6zYulo0FChWeyW7afeqgeGwSOoTbMPEk88NOQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marcin',
        lastName: 'Cierniewski',
        emails: ['mcierniewski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGHcBQ-lAbUCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673276656408?e=2147483647&v=beta&t=v6yRrHLMLEt5DpHhEYKV21jhAlQdqFenmr8EgfPnets',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xplain: {
    name: 'Xplain',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stefanos Karagos – XPLAIN |',
        lastName: 'LinkedIn',
        emails: ['slinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHVe6n_sXos5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615194979012?e=2147483647&v=beta&t=sQRZcYmBRno3Y5VCTIXmCdLZmG4u9xk4YXxoRMj4Gfs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gerry',
        lastName: 'Steinhauer',
        emails: ['gsteinhauer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHbr-TTkp6uIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605804051755?e=2147483647&v=beta&t=wq4SxxuYAAFYy7nNlpyMks9VqRYli1HQJIrCALQZCuQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Berend de',
        lastName: 'Boer',
        emails: ['bboer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGaGAfTG5MPOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670918165584?e=2147483647&v=beta&t=o7Fp2_3Ua3NnM5rDCEvPpwn1nbGXqLgfanx9DKAwW3k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Charlotte HU – real remote EU EngineerIn – Xplain Data GmbH',
        lastName: '...',
        emails: ['c...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Neil',
        lastName: 'Ticktin',
        emails: ['nticktin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG71XUKWkvLoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516163455301?e=2147483647&v=beta&t=R5L7dELPFI0IfIBsbEgaouUnu54lpYMb4Lk78pWupq8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '2wtech': {
    name: '2W Technologies, INC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'Jamieson',
        emails: ['mjamieson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFF3LDegu1kuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653958688366?e=2147483647&v=beta&t=G42v0cB7Rf2rN2V2-pDyA7YxP-OqqIP5PLxXTxDJPOs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jon',
        lastName: 'Dickey',
        emails: ['jdickey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHC9cVkFQQ_RA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516494369350?e=2147483647&v=beta&t=BodN40eSeSwUkl7n94MO4vOHuXj54Itolo-E28taqoU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tom',
        lastName: 'Geairn',
        emails: ['tgeairn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHgi5erYvio0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617914394177?e=2147483647&v=beta&t=wsF1ru7uyp-nE81XtfFILsGJYKvuSXRxg0g1MCzesFk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Bartlett',
        emails: ['dbartlett@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lisa',
        lastName: 'Dutcher',
        emails: ['ldutcher@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG_V4GKbVxxXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709652707766?e=2147483647&v=beta&t=A6z-hK0pBwOzZ5NRrEF06TJS5In9SNPxCLnsUDhIfic',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  passwerk: {
    name: 'Passwerk',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nico De',
        lastName: 'Cleen',
        emails: ['ncleen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Seppe',
        lastName: 'Druyts',
        emails: ['sdruyts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHbJbBhg-V_pA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522845445129?e=2147483647&v=beta&t=0SPLSfGEpK3ryxLDNBpfqa7E1tSOtmCRvASlPAHo_yA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Laurijn Van',
        lastName: 'Gorp',
        emails: ['lgorp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHbLP1_2mZ1Tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661326611285?e=2147483647&v=beta&t=TemmGKN9tzUZ8U_Hm3Sgxh5d4H44sEOPgcDzXg2ey54',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Roxane',
        lastName: 'Résimont',
        emails: ['rrésimont@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFnntKPpBLAwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661263692885?e=2147483647&v=beta&t=UvTB4luKx4H15qhTDx3LACw9N5UoosVOoim_Ar5JOS0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kevin',
        lastName: 'Stegen',
        emails: ['kstegen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHQokNipRk_Zw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677154208497?e=2147483647&v=beta&t=2xS2AhflcJackiewEFSnU3GUbzf1_v3EHEzeI6V1OPQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  criGroup: {
    name: 'VASS BENELUX',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Panna',
        lastName: 'Vass',
        emails: ['pvass@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFVzDRyU_95bw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516993991685?e=2147483647&v=beta&t=nnENV9S5Mu6SQ8I2TOxi1vLnn6AUfF-rPrTG_5NqQwQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Laszlo Adam',
        lastName: 'Vass',
        emails: ['lvass@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE6JV4tshmi9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571257508511?e=2147483647&v=beta&t=Ll7N8IPq5ZflQZINEBdG_XeGYMBj_nt6fuvqh2bMJj4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Francesca',
        lastName: 'Martelli',
        emails: ['fmartelli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFb44FGmESE1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628251023034?e=2147483647&v=beta&t=TPTw7_e1yn7Dh6qB5SVkCJ1lu_gwake94aiOdupA93Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maria Vass, MD, PhD, DipPharmMed,',
        lastName: 'MFPM',
        emails: ['mmfpm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'George T.',
        lastName: 'Barton',
        emails: ['gbarton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEaWn0EcSC1TQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581093405325?e=2147483647&v=beta&t=kRAhEopxmFgz5jXnZCgTaQTtpwEVAArCPTZ8UWKsnIo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  precisionSolutions: {
    name: 'Precision Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'King',
        emails: ['kking@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGID-fwdNlASA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1576261696806?e=2147483647&v=beta&t=0pb33QM37xnVcWpOo0s6J2x_LNM81bJusc41yMKPTSE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matt',
        lastName: 'Scudder',
        emails: ['mscudder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGFfWoeZVnAfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516883228396?e=2147483647&v=beta&t=hat0L9ZPvwkDzPnNa3xLriRVWC2Zs_aYFpNozcHA4OA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Linda',
        lastName: 'Gotzon',
        emails: ['lgotzon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHDqKQnrfA_6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727650047754?e=2147483647&v=beta&t=s-3Rkt6ngV7Hy2BKE5Cja0z1Mv2YV0tYjNmC37EYzdo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alex',
        lastName: 'Roberts',
        emails: ['aroberts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGdIKdI2i9Wyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645138406539?e=2147483647&v=beta&t=gmghf2XjyIiJW1xgfSSl8rDtOxz7mfEj6jzwHSBuv5g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Logan',
        lastName: 'Lewis',
        emails: ['llewis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOO91odlLB7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638459817112?e=2147483647&v=beta&t=pxiAcpoUF8UQNpaBXvnAaL5mdrlvm21lX_0AsvmHqRE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  verosoftdesign: {
    name: 'Verosoft Design (VSD)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Martin',
        lastName: 'Turgeon',
        emails: ['mturgeon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQET9Trh_q5hvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536611357690?e=2147483647&v=beta&t=7JtLDUH-KooVxfRrUHAd1MeIH0zpcF4NthhwjxccO5o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shaun',
        lastName: 'Portelli',
        emails: ['sportelli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGmicXnbKl5ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657638571258?e=2147483647&v=beta&t=PIQ84yUAf3v05h4GoA6baK7Z1Co_6G6G3qZZQ63BRd4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Doyle',
        lastName: 'Dettro',
        emails: ['ddettro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGxFUsNZZtLzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517686684279?e=2147483647&v=beta&t=E6uy_Wn7iV3P8m4c_jFPqKBuIq7Gjmc7yc7eHMfaPYQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alexandre Parent,',
        lastName: 'BSc',
        emails: ['absc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFvBXXF6PaIkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664685017549?e=2147483647&v=beta&t=w0apDISIEb9dqoxNbRMCKxhcodMI1LnwB8fzR1Y_XmE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Samuel',
        lastName: 'Arsenault',
        emails: ['sarsenault@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH7JIaGIc_V7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643570206037?e=2147483647&v=beta&t=JlOliLV-hLfNYVVJ4Me1XKFYhZmhMjUtjdRgMWdSr1k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  powernetco: {
    name: 'Powernet Co.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tiffany',
        lastName: 'Boggs',
        emails: ['tboggs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGBM1kvuNxnrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516984378591?e=2147483647&v=beta&t=WdIhjod2XvCr1ngnbEraCt5tSe-4JL0OJSmyCm8n5uY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Greg',
        lastName: 'Kelley',
        emails: ['gkelley@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Namwanga',
        lastName: 'Noeline',
        emails: ['nnoeline@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alli',
        lastName: 'Stevens',
        emails: ['astevens@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jainiti',
        lastName: 'Prasad',
        emails: ['jprasad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGr0WjCXSzX4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630947010415?e=2147483647&v=beta&t=d3meeeT7q5rKN0bkKdopKBfDmrXKfQ6B-geddK4iB6o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sunoida: {
    name: 'Sunoida Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Suleman',
        lastName: 'Dossani',
        emails: ['sdossani@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kanakarajan',
        lastName: 'Ganesan',
        emails: ['kganesan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Duncan',
        lastName: 'Gemmel',
        emails: ['dgemmel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGtnB6dGKwTFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698230013340?e=2147483647&v=beta&t=odci9g7SQc3dtDNmAUyQOE1USpGxcrvtHwZABjuY7WE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dan',
        lastName: 'McNamara',
        emails: ['dmcnamara@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Praful',
        lastName: 'Morar',
        emails: ['pmorar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHfI43-26yLeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517252757523?e=2147483647&v=beta&t=F5NDbxK8fYz9jXTD4PMmhUVykG3BE3ENnhcwmTaxdI0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  geocgi: {
    name: 'Geospatial Consulting Group International, LLC (geocgi)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Julie',
        lastName: 'Christie',
        emails: ['jchristie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEfUMz3gkRosQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714429226687?e=2147483647&v=beta&t=j3XtSTYTsq3ZWwX35tI79yop83b7hBl3A3RM0ZSof00',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ally McDonald PMP,',
        lastName: 'CSM',
        emails: ['acsm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Duncan',
        lastName: 'McKay',
        emails: ['dmckay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGBOg6JUTiQgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696522097756?e=2147483647&v=beta&t=utApDxeZJ-seAYsNjJpPf8eHQmys0pqUwicMXBz7dBM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eric',
        lastName: 'Davis',
        emails: ['edavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQESVYM19XUMhQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517497039410?e=2147483647&v=beta&t=XIa0ADT7lk9PnU3-HsjiFlTxOC2HWsuAymFR2hUbq8M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andy',
        lastName: 'Hanes',
        emails: ['ahanes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH2O-ofPhlEoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678322151535?e=2147483647&v=beta&t=xOdJeguyGix-eqnd9WQYeqwnZr5hb0XD-6x6mfMIsdM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  solabs: {
    name: 'SOLABS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Philippe',
        lastName: 'Gaudreau',
        emails: ['pgaudreau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHb5HGU0aSwIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725141809398?e=2147483647&v=beta&t=e-dTAIR2sFPPfErPEdvBv_wnWbP2UXO0nBqUfPiL6V4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ericka',
        lastName: 'Moore',
        emails: ['emoore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEOtTGN5wdccw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729091914644?e=2147483647&v=beta&t=BQxkdlP3GoNjjkPVfVKAGn01luNhW0Dioh5WHc2ngEA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kevin',
        lastName: 'Byrne',
        emails: ['kbyrne@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFOjwaZ2OiDpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584444595844?e=2147483647&v=beta&t=OqDzY2a4hHWG7atlE58Y560I49Hrvrwm-WOVESRSwj0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ivano Cavallin',
        lastName: 'CPA',
        emails: ['icpa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Francis',
        lastName: 'Lau',
        emails: ['flau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGXbfK2WQgcXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598932272251?e=2147483647&v=beta&t=x8xG18aCj943zAiz5RpXYfbm_HB1SXstZfKcM90Sy4Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tech5Sa: {
    name: 'TECH5',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rahul',
        lastName: 'Parthe',
        emails: ['rparthe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQES40C6p5cAOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645225528543?e=2147483647&v=beta&t=SPiRoBgCn9tl_1BfVc9NxkJl7zNY0gmzRTL403Lfdnw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Machiel van der Harst – Tech5.ai |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFLd84hYRfM0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516240807156?e=2147483647&v=beta&t=ytn7Y2-9iH-eGRsI6A2xaZmPdwAICelS-uHvggGee4U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vishesh',
        lastName: 'Mistry',
        emails: ['vmistry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEHKVVNRXxptQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580880050339?e=2147483647&v=beta&t=H7jdk9bOh666sAng5uRYGkORSZSnpwiqAxy86NDEJdw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ameya',
        lastName: 'Bhagwat',
        emails: ['abhagwat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFfQzAB7FsTjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645492383696?e=2147483647&v=beta&t=Xng5fynNUsQ7iqmcqpn7us375CFhqPQbEp5gOMNR3oo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amruta',
        lastName: 'Chaudhari',
        emails: ['achaudhari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF5dVNWnKETOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625588037503?e=2147483647&v=beta&t=4CozAi8dKyURpOfAkCiJgymFbAovyVKJ2MjlSSRgUWk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sfsmars: {
    name: 'Phoenix American SalesFocus Solutions India Pvt. Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Prabhu',
        lastName: 'Golla',
        emails: ['pgolla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Atluri Joshuva',
        lastName: 'Ramanjullu',
        emails: ['aramanjullu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEIvmvszjC8aA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653319469268?e=2147483647&v=beta&t=JRw_p_3BDgJOt_H6-qNBLxY8Au3DFINpkt0JWf6vHOc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Radha',
        lastName: 'Renukuntla',
        emails: ['rrenukuntla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'kambham',
        lastName: 'karthik',
        emails: ['kkarthik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHhwEBY7wWSDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667292180329?e=2147483647&v=beta&t=Kelban63o7Dlol5bq5bY-AYvD_Ogns-O6tt4iTFibg4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ramya',
        lastName: 'Gadhe',
        emails: ['rgadhe@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  yocale: {
    name: 'Yocale',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Arash D.',
        lastName: 'Asli',
        emails: ['aasli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGbsr68PEVIHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516242207272?e=2147483647&v=beta&t=pnOBW9DeLiwMLkFqobocQrSte6h0zwtE8BdpBljQkDE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Faith',
        lastName: 'Morante',
        emails: ['fmorante@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHdKtPB8Th2Ng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662979447295?e=2147483647&v=beta&t=V3A9OS_nhM6fA5npXEqvW1U9VyBVx5bSHvhM_Z6rFBU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Maryam',
        lastName: 'Amirazodi',
        emails: ['mamirazodi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHSxSltfibI4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618805747750?e=2147483647&v=beta&t=-fGJ1GzG5wpj8ZdHIkQrveGxyqY12XAxX49vLdeDzyo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pegah',
        lastName: 'Mirrahim',
        emails: ['pmirrahim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQELIO55srxIbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727931682799?e=2147483647&v=beta&t=xhTLiJOQCY0DsJ7dSdEZniecG1D8OdX9s46nUIqc9T8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aylin Aminifar,',
        lastName: 'MBA',
        emails: ['amba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFadiR9wKTUlA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668475408948?e=2147483647&v=beta&t=x2K_xi0Bbn1Ifs_1SUTffZ7ZlstVLmoO37uC9J9M78M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tlam: {
    name: 'tlam',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shikha',
        lastName: 'Kadian',
        emails: ['skadian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHo8H3CeEzGEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549665024831?e=2147483647&v=beta&t=FTHb-MmCeX_0zpE9yVZDCT-WzTvhgKZoZusb5kGrHxg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tina',
        lastName: 'Surman',
        emails: ['tsurman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amy',
        lastName: 'Tlam',
        emails: ['atlam@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Charlotte',
        lastName: 'Szeptycki',
        emails: ['cszeptycki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGGQeyhxqOxpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653416397783?e=2147483647&v=beta&t=zmDVXvFNQV3xzHuYRR5Gz9u3kr7LijLJeAFdJlZA04E',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Philippa',
        lastName: 'Onslow',
        emails: ['ponslow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE76S6egriHEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517258753906?e=2147483647&v=beta&t=JtaVMrBYDg7jnuL2UPm6Gf-jup0DpmVzRDZXAQZ811A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ppok: {
    name: 'PPOk',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ricky',
        lastName: 'Garver',
        emails: ['rgarver@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGjBpw6HOsCwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598310148270?e=2147483647&v=beta&t=C3qQsJvmmHi2ue65yzYQ80_M2UAxfDB3GTsOBaR41mc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stefan',
        lastName: 'Mullins',
        emails: ['smullins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE-uiPNKeMsCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672961791363?e=2147483647&v=beta&t=fHANTF2gqpl_5I-Nq0jVrYE_Lobp7tVupkEgtc7DmRc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kim',
        lastName: 'Dietz',
        emails: ['kdietz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGePYXXcyW6nA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627924835469?e=2147483647&v=beta&t=w0CTMqV5ESO2LGhpncPcDgrfCtuJzZDavDjxctRVwdk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Josh',
        lastName: 'Cline',
        emails: ['jcline@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFvW81Ermgwvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718373011290?e=2147483647&v=beta&t=Kt1Q1g8jPLlaa452hBF1qItsYx7zOpGUJ7INJt-IVfo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brandon',
        lastName: 'Pratt',
        emails: ['bpratt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEGayE8nAFEeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517535871239?e=2147483647&v=beta&t=5mTvVfw7qcB3kLFfITmfgvXy9V09_Awm1PFagltP2D8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dotcomweavers: {
    name: 'DotcomWeavers',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mayank',
        lastName: 'Agarwal',
        emails: ['magarwal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHEnA_uvQ2NNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707639469319?e=2147483647&v=beta&t=k9G1GRPD0RagndRG8_uaNbjwX9VnikyyaYIBP4TCyuQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Amit',
        lastName: 'Bhaiya',
        emails: ['abhaiya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGtdpBpaMVH3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536109181672?e=2147483647&v=beta&t=-io3v7uO-0uGHl0R4G-zJ4e7jwj4lv2ki8xeKYMqLmo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Malay Patel',
        lastName: '(PMP)',
        emails: ['m(pmp)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHl9fCEMcNemA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711727852484?e=2147483647&v=beta&t=vjddBBnuYv41h4zDyEwKcb8b9iF6SgXJPk2yP6AUuFg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Augustine',
        emails: ['daugustine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFrSzWn7vdP0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609387310121?e=2147483647&v=beta&t=uU-6bX4YVZE405A1Nsih1IDGd-0OW4Zmb04MzWJ8Z3w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Neeraja',
        lastName: 'Pammi',
        emails: ['npammi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGCyktyGtpNPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519211464889?e=2147483647&v=beta&t=sOt-0osTMOTTSarE1_YZCHXvwnD4r8flXm6SqhBHUXk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kmmrce: {
    name: 'KMMRCE',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'McKinlay',
        emails: ['mmckinlay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGKSclu5bqjzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517469255812?e=2147483647&v=beta&t=09cVmsJCQw4YPI7PhmcSWGTPxI9vgPKfyUe0TDhMZ-s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Justin',
        lastName: 'Henry',
        emails: ['jhenry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGHnSoJHUTORw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610450995814?e=2147483647&v=beta&t=NQ460RcMywIGgsrXiVFlgKsu8efxmCOFxdQ4gX0W8Tk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Darren',
        lastName: 'Hodgkin',
        emails: ['dhodgkin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG51Ogvou6smQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516519975319?e=2147483647&v=beta&t=JGgv3LCI5UR0saWvtcWAcJda1Qh416XnXNdIceOW24U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Adil',
        lastName: 'Norat',
        emails: ['anorat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHzxz8K0GfkMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661773709757?e=2147483647&v=beta&t=6CLgUfvx7az4kJHjBnMZ72VdC4X_zfCp0OJ439tTZIU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bernard',
        lastName: 'Ghartey',
        emails: ['bghartey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEym01Le_FdZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648017493080?e=2147483647&v=beta&t=t_IyKWzERXzrMghmwvTMv8di2CmpygZ6G5sV9uw2TzI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  winprotech: {
    name: 'Winprotech IT Solutions India Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Winprotech',
        lastName: 'Bangalore',
        emails: ['wbangalore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQF3FYzjG9zUeA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1517395290032?e=2147483647&v=beta&t=YB8m9unBGQGihZiCA1ZoTR_OXctM_ZEG_85D_B-tgwg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Amith',
        lastName: 'Kariappa',
        emails: ['akariappa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHNzSHQ5Xuqbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516371801200?e=2147483647&v=beta&t=PaqbSbhfE2EhvSML-deD64Beh6LMBfG1n3pWuPMkjgE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Winprotech',
        lastName: 'Training',
        emails: ['wtraining@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH85iingvERmA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631833929395?e=2147483647&v=beta&t=tf92HXV3tqURuMBBtSy2zl5dPeWV79pNtyhu4c9z6LM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shiva',
        lastName: 'Ranjini',
        emails: ['sranjini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGFzruMP4wrow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684558118982?e=2147483647&v=beta&t=BmQ_tJV3Y2jls0jttr7R-cMKBWHijYlQ5tCYRtpPTQ8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Praveena',
        lastName: 'Achar',
        emails: ['pachar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFhJuGSQWQf6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689871893481?e=2147483647&v=beta&t=XddfjifaYAEg6iLIVaAy8P4hw2RGGWh4HWHwnPhKqnE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bluematrixit: {
    name: 'Bluematrix Consultancy Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nikhil',
        lastName: 'Shah',
        emails: ['nshah@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rajindran',
        lastName: 'Annath',
        emails: ['rannath@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHZeV1PL2AV3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517879527298?e=2147483647&v=beta&t=KAEXP8-2pWvQEje3pxiztoe6214IXjSMoDeVaxX1X_g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sefene',
        lastName: 'Tsega',
        emails: ['stsega@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ravindra',
        lastName: 'Gawde',
        emails: ['rgawde@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jitesh',
        lastName: 'Kamble',
        emails: ['jkamble@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nikijones: {
    name: 'Niki Jones Agency, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Niki',
        lastName: 'Jones',
        emails: ['njones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEEe-IqsLWYUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696519421958?e=2147483647&v=beta&t=FfWHA6rjlpb0yA_jtNVpCAdXadrRyOWIEiWlHQGc3AI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Niki',
        lastName: 'Jones',
        emails: ['njones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE1glOy72q7BQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687469041640?e=2147483647&v=beta&t=m96Y-a_VOf_D5XeI0R7s7-by98U7rEKD0UvFc3bocV8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nikki',
        lastName: 'Jones',
        emails: ['njones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGYd8jCdFUh2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660747524744?e=2147483647&v=beta&t=s8L8ugjLUZ8xj2e_BItGE3IanOer9B9kwvJy_KRRL-c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephanie Brynes,',
        lastName: 'Ph.D.',
        emails: ['sph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHdDBnvBUdbuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692537505071?e=2147483647&v=beta&t=_NhpxzE6-RBqjwoNfXfMSzK0XhYEAF0uK_fmKbpC3dY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Melissa',
        lastName: 'Camacho',
        emails: ['mcamacho@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGHjnrP2Zk1GQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658874137113?e=2147483647&v=beta&t=3xXWMDee_rT2E1iaEV4IA4TYbUMxrGtv4BrzHzHR8j4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sitetechservices: {
    name: 'Site Tech Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'DiGregorio',
        emails: ['mdigregorio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGIBOPSpqWYAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718221875712?e=2147483647&v=beta&t=-vFJTdXKL3tLfIxJ3CLMklx99D5gnIHVcqU5mOIvssI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tyler',
        lastName: 'Pell',
        emails: ['tpell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Devin',
        lastName: 'Galliera',
        emails: ['dgalliera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHy9jXGLPpauA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652411508599?e=2147483647&v=beta&t=Yudx1cr500Xu1Zpfbe-soPS76nCq59xVaSrwm6B2Jis',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Austin',
        lastName: 'Eggleston',
        emails: ['aeggleston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF6SWCaeCCAJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660651600049?e=2147483647&v=beta&t=oEuQh3xW4vBqE6MEh3RJjfwgOVTyIeiDPnVGaryvpRc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ricardo Burrage',
        lastName: 'Jr.',
        emails: ['rjr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGB6G3u5Ynk4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704666958473?e=2147483647&v=beta&t=U9uPUv-den29TXVWN1g1n1lL2YLA1s4zMrMrksgnqPU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  digitalglobalconnectors: {
    name: 'Digital Global Connectors, LLC (DGC)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Yousif',
        lastName: 'Mostafa',
        emails: ['ymostafa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kathy',
        lastName: 'Mostafa',
        emails: ['kmostafa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH2qRBFrwB-gA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516976010574?e=2147483647&v=beta&t=lmGUG3aWoVVAQBJX-Hpr6EbiD5zazHqDaNEE4nKV8yI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Laura',
        lastName: 'Crouch',
        emails: ['lcrouch@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF_YKp0Kiv6_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691318362388?e=2147483647&v=beta&t=gkWR7mx68E1M6klISNI_DoatDmU8Kk_yata5K7lk0_s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nima',
        lastName: 'Noori',
        emails: ['nnoori@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGB8ogUUPBrvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686100625062?e=2147483647&v=beta&t=GPUN0NR88B3sdpHXXcIz_lzHA7HBlzczbPfym9ijgII',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'W. Brently Cheek',
        lastName: 'III',
        emails: ['wiii@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGIXHDKf3FRxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517596993844?e=2147483647&v=beta&t=eFDkHpKQ-awee49yvHjVUqwquNAGjSt_dkkZJDD88pI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vynyl: {
    name: 'VYNYL',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'Doubleday',
        emails: ['mdoubleday@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFeNFz3bHodYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637700882103?e=2147483647&v=beta&t=DpZDzhjFpTVeFMU6Loz99Ey-1WRxwzHpmsG2v5RdGnk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ian',
        lastName: 'Harris',
        emails: ['iharris@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFVDfiJp5FhSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601936061166?e=2147483647&v=beta&t=eBeEh5O0kdHsRft0Y89Tn7lMx8Tg4Bzo8UoCKM-HKk8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Hoyd',
        emails: ['choyd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH4IcZzyqDegQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612190941109?e=2147483647&v=beta&t=ifQbbcf333VvfpoicAYC_qo6T7bVgmWjMDJC6KdkEXA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nathan',
        lastName: 'Chandler',
        emails: ['nchandler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEBe_840pKhpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560363761218?e=2147483647&v=beta&t=DMA_JtWO5Ula1DvJXYlo1IDb-X4OofE3-yHQK_UortQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nicole S. Harris',
        lastName: 'Roberts',
        emails: ['nroberts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFJf5nm8WmRMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558402068640?e=2147483647&v=beta&t=wgC41Ag9vCYbmKnen5rg_fOcVA2KdFx0Dz7yY_c7Gl4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lexicontech: {
    name: 'Lexicon Tech Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mitch',
        lastName: 'Rogers',
        emails: ['mrogers@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joshua',
        lastName: 'King',
        emails: ['jking@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEWm60WzJcxiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654182058108?e=2147483647&v=beta&t=Gs6fUgqvVDI0wbmkaeScvx8_U5k23x-BONdi47St5Tg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shannon',
        lastName: 'Lightsey',
        emails: ['slightsey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEvx5l8Qvntvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626442627598?e=2147483647&v=beta&t=JcFIQgBeCZhvdyt8nHcjFK2DWPyNxCNO2Cc_sy3GEOo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Steven',
        lastName: 'Schubert',
        emails: ['sschubert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG9K-yhHmk1tA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722362376673?e=2147483647&v=beta&t=mndSbC33-IWY-kiLW93IYynK8x_nF317LU2YXrKWhxM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ben',
        lastName: 'Reeves',
        emails: ['breeves@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dainox: {
    name: 'dainox GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Astrid Mayer – Director Finance & HR – dainox GmbH |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGYVTd2kjklFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516967439987?e=2147483647&v=beta&t=1oqaG8ew0568d7hsk-et45CDVHDiZ5Pc9ROIp5PhAKQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bernd',
        lastName: 'Kohler',
        emails: ['bkohler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ann',
        lastName: '',
        emails: ['a@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Patrick Thiesen – DAINOX GmbH |',
        lastName: 'LinkedIn',
        emails: ['plinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHnV8llCQ-bUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516982737658?e=2147483647&v=beta&t=iJ4K1Jj-aMosjeDunT3LdOi6akE9EC6hM3uarBJLWFY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jan',
        lastName: '',
        emails: ['j@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGFx6DWUKrPPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718274955594?e=2147483647&v=beta&t=Qz7ZEWFgtu_jNudtdZ03H73btbLzqdrR57C9thJ6OUY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  metricell: {
    name: 'Metricell',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Frank',
        lastName: 'Rowsell',
        emails: ['frowsell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFCb0FwXTFCgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516244937027?e=2147483647&v=beta&t=zSVpoc0dy-tmxlE3TfJYj5xUf6mrLbyQENvgwCcQN9s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andy',
        lastName: 'Watson',
        emails: ['awatson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH0Hy8-eqzmiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726675134518?e=2147483647&v=beta&t=dBP7CGih_Kf4GpfAb46Zdslm1pSFz7rl-zXZybItVeg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tracy',
        lastName: 'Hines',
        emails: ['thines@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFkblblIXlnNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600185366601?e=2147483647&v=beta&t=cskKtzH0fK5RSDi0mIM6-FUwyxGjzwW8jugjE1RBaiU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tom',
        lastName: 'Smith',
        emails: ['tsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG0r0vtZRY3_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714561385626?e=2147483647&v=beta&t=JSZSkjXj10r1uAdAwPTuA0DgVu6W7wggYCmNbAHsYjE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Antony',
        lastName: 'Masterson',
        emails: ['amasterson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEP61FRd5omfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517370995594?e=2147483647&v=beta&t=_gzNLE6vdG_AWA8cCqT7rJYm4xeD1mysZIYdfuG94pc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  psgglobal: {
    name: 'Prometheus Security Group Global (PSG)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rick',
        lastName: 'Gross',
        emails: ['rgross@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeremy',
        lastName: 'Freeze',
        emails: ['jfreeze@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFQqFSt8hcNGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635635464635?e=2147483647&v=beta&t=ZXZhz6dzlIP9D0DQglWOXTtxWkWHd_Y9tlFLSw-3ZHE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'Kauaihilo',
        emails: ['mkauaihilo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jon',
        lastName: 'Bruno',
        emails: ['jbruno@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGXex9rJyDFUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712948093223?e=2147483647&v=beta&t=wRlf2KpFO77d5nNDi4sBOQiL8wLadGzQJlR9RCFpyPQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kevin',
        lastName: 'Bray',
        emails: ['kbray@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHY0GWock44jQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681335960426?e=2147483647&v=beta&t=J2ULMxvxYmyMPzORyM0EEO042KN1sEg15kjuSW6uzuM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  advantel: {
    name: 'Advantel Networks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steve',
        lastName: 'Tracy',
        emails: ['stracy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHrs9HlpIJNZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614894780992?e=2147483647&v=beta&t=f-TpMapKFywn96JYoohCsOuZWQmlVJRJjnt2VUB6vjE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nelson A.',
        lastName: 'Martinez',
        emails: ['nmartinez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHoQMyeDzuD8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725064375795?e=2147483647&v=beta&t=WC72WF8fygziid0z5x6ubRmv86kdfpBxNaGOtq3P6nI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andres',
        lastName: 'Cano',
        emails: ['acano@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bill',
        lastName: 'Bivins',
        emails: ['bbivins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH4ndV1wRr8Dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516324380784?e=2147483647&v=beta&t=kFZlmosPGRYaO8DVjd4mSV1_7llCnY6K9xrMfVkMYQM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rudy',
        lastName: 'Doria',
        emails: ['rdoria@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGqC_i-6ycWzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516452082341?e=2147483647&v=beta&t=ktc7cR4RfH7J-6WP5OZSEKtB2ZOOnaZa5EPY6g4QL98',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sintesys: {
    name: 'Sintesys North America - ServiceNow and NearShore Specialists',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ivan',
        lastName: 'Galvez',
        emails: ['igalvez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHqsW2l13NDgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715292987296?e=2147483647&v=beta&t=MWy1XJ83KrK5cDHFIsB6WHrnKxMWPeP6cxmvZr0v_w4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Monica',
        lastName: 'Gomez',
        emails: ['mgomez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Uriel',
        lastName: 'Loza',
        emails: ['uloza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHmIxW2si7cvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585942071742?e=2147483647&v=beta&t=JmJZWJ8Bgf-bqNHmi4c-RrlOCRt9_2S0gfbDkQCZxjo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Juan Carlos Gracia Rebelles – Leipzig, Sachsen, Deutschland',
        lastName: '...',
        emails: ['j...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGj-kajRY1rSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1534329401898?e=2147483647&v=beta&t=3j8uTqa6bU7cE8Smw7o97AB4H9tQTrXCG5ehketrio8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'María Naredo',
        lastName: 'Núñez',
        emails: ['mnúñez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  swastisolutions: {
    name: 'Swasti Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Swasti',
        lastName: 'Solutions',
        emails: ['ssolutions@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nitin',
        lastName: 'Shastri',
        emails: ['nshastri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFsr-sQKmJQsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688447515352?e=2147483647&v=beta&t=EtjrgrfxfKx0SIXZblB-9b08nM6Kz6w3l87h9V0iAFI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gaurav',
        lastName: 'Shastri',
        emails: ['gshastri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGksIQalKMlcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570338486334?e=2147483647&v=beta&t=X9SEiH5Mnc_wDWVy-plwY1G2iH90FdfV7XZdKkh4VF8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dev',
        lastName: 'Vyas',
        emails: ['dvyas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFhbYWNWnADUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706862745644?e=2147483647&v=beta&t=XBSRYcHCz3_K9qBscOLzpqBFVZ5mfpI1UhAUpNp5DYY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Akash',
        lastName: 'Shrivastav',
        emails: ['ashrivastav@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFsat-swY72Mg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725298039252?e=2147483647&v=beta&t=ZGM5B-NeU0OqiHYse5mhMyrHbOmzXaiX_Ij3xGl6drk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  autobahn: {
    name: 'AUTOBAHN',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ssgLlc: {
    name: 'Strategic Solutions Group (SSG)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ted',
        lastName: 'Hill',
        emails: ['thill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFt1HFJZruJ_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667229968341?e=2147483647&v=beta&t=UJlWk58IHeFlPkZWcT-LHz_b4ZbHOWqdLf5MguVyUGE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Neal',
        lastName: 'Brenner',
        emails: ['nbrenner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFgbemw8_qCaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668713584045?e=2147483647&v=beta&t=2wBUe4nXSA7db5gIcsxnxDyG6ECXPOfFjChpkCKXT_o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Frank Reilly, MBA,',
        lastName: 'PMP',
        emails: ['fpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMxBEgK8O_0w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728406928053?e=2147483647&v=beta&t=-ezBZ21r_4X49pSCmZ0NrskjE6dbSgeOwNL8gNokO78',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bryan',
        lastName: 'Heuertz',
        emails: ['bheuertz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGAKJyT3cNokg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710411075444?e=2147483647&v=beta&t=UhUmCwrHlKuZFU00tt7vvzDPGvMJxQo7yc7_TswzHd0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gayla',
        lastName: 'Wood',
        emails: ['gwood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGyy2zNPnx9kA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516455023540?e=2147483647&v=beta&t=oJ0VidgjizoAUtS5BuP7lYx9uDDl7DEUi4e5fOmac-8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '360cloudsolutions': {
    name: '360 Cloud Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Judy',
        lastName: 'Lohmueller',
        emails: ['jlohmueller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEiKlyggy18hw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654722526512?e=2147483647&v=beta&t=wYUSQDSuaKwzNmVQmZKmoa_zZJN5Cm3KhjL4mYAyRqo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Hernandez',
        emails: ['dhernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFn2ftoq5xr6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643135608099?e=2147483647&v=beta&t=a6DQxRHkq9Lj6qBlOXo5oTb1OQo8Jp8B9qgjmS91rMo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brian',
        lastName: 'Wheeler',
        emails: ['bwheeler@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shirley Barna,',
        lastName: 'SPHR',
        emails: ['ssphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGGpgH505h5YQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656538147825?e=2147483647&v=beta&t=olMyp7HTzHZb60_dgNeAUNpxqq8tTVO6Znw9JN0lvOg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chase',
        lastName: 'Pendley',
        emails: ['cpendley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGsOysuS2nSEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543475197099?e=2147483647&v=beta&t=OOwlASDl-3LByOgXX3yNeMcF_FZz8uceD7iYG1HscLA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cleanpower: {
    name: 'Clean Power Research',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marc Perez,',
        lastName: 'Ph.D.',
        emails: ['mph.d.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeff',
        lastName: 'Ressler',
        emails: ['jressler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFvbVfgZFskVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666736560679?e=2147483647&v=beta&t=0ZoyCMwfpdzFxBL8usCS3yVNmllDBk7LjbOLlco-xUw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joseph',
        lastName: 'Beer',
        emails: ['jbeer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nina',
        lastName: 'Edwards',
        emails: ['nedwards@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEmKGKRAYKZDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632523389874?e=2147483647&v=beta&t=yVoxm3fJFLJdzn-7fAwg886D6CyEbkvmJRPj_geM2n4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Seth',
        lastName: 'Lifland',
        emails: ['slifland@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  alphaii: {
    name: 'Aptarro',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gracey',
        lastName: 'Cantalupo',
        emails: ['gcantalupo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHELMMRbRNZAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730147480166?e=2147483647&v=beta&t=noqUKWtQDoChbkbIIweEhiGb-8AFO2gbvO5LurcWWu0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ashley',
        lastName: 'Womack',
        emails: ['awomack@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joseph',
        lastName: 'MacMichael',
        emails: ['jmacmichael@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHp01MFt_rjNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689187230458?e=2147483647&v=beta&t=YgyArPpcESRj65dgPkhmYS15Zf5ndIj0GRAl5h7uG04',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Steven',
        lastName: 'Williams',
        emails: ['swilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH-g9QZkXGfKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656074119730?e=2147483647&v=beta&t=0-0WPCvPjXAF838-yjj4Hp0MMM4gYL1hn6EcK5yFiDM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Heather Riley',
        lastName: 'Swanson',
        emails: ['hswanson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  razerzone: {
    name: 'Razer Europe GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bob',
        lastName: 'Ohlweiler',
        emails: ['bohlweiler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGwn-js4b-7Jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713337412627?e=2147483647&v=beta&t=ZhLT5uSXYS7I5f5K-HwDIUlAu8WVbNs5gApEJ2AQcOM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brendan',
        lastName: 'Gellman',
        emails: ['bgellman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFzg3f1OpDRsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548878402440?e=2147483647&v=beta&t=pcKPSLwwyCB1e_IlIJDXJOxGxycNpbvzJ5CiBcylteU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nick',
        lastName: 'Haywood',
        emails: ['nhaywood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFrbo-_4FXCVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549376009530?e=2147483647&v=beta&t=qWZDXh5nHSPLklhg-cnvsEG7XG01rEW5V-11aLyIPQ4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Min',
        lastName: '',
        emails: ['m@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGgVkf16yje8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711981177055?e=2147483647&v=beta&t=11_gq09jdiZg5_8lgHe4m89a3BhUSJsjw1HVB28xnh4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Seeson',
        lastName: 'Mahathavorn',
        emails: ['smahathavorn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH5M0T5NzUe_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705860244616?e=2147483647&v=beta&t=YXa93w4j-IT5cDGwmRsjnmXlRy3RGjGc4RN3T1EhwjI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cmcflex: {
    name: 'FLEX Credit Union Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Preston',
        lastName: 'Packer',
        emails: ['ppacker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE8Xh5ZsHDmNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669919258614?e=2147483647&v=beta&t=-_lLtWw11Nn6_ABfzoAZvS7SJhAGjKG3W8utAaeL-Mw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brandon',
        lastName: 'Layton',
        emails: ['blayton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEl89cA5lw8gQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714262395283?e=2147483647&v=beta&t=bfrbYIt8SluDanqdrSJ3hMyohI3sXODRxJlUrU7AT60',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Matthew',
        lastName: 'Jones',
        emails: ['mjones@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lori',
        lastName: 'Tuyo',
        emails: ['ltuyo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hayden',
        lastName: 'Monson',
        emails: ['hmonson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFK7-XSOVCv4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698673532084?e=2147483647&v=beta&t=GeVR881JRakrqH7GhM20vvGYElxwYwv3H02Hhg9Os2M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  radiuscargo: {
    name: 'Radius International',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Deschamps',
        emails: ['jdeschamps@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGtTIV71jUrqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517044650227?e=2147483647&v=beta&t=9W24BPlDNDlbagUNDS3o6tvvrcEFTPuacxn0NkT2jsc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ian',
        lastName: 'Blanchard',
        emails: ['iblanchard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFygS_QZP1VFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684442271747?e=2147483647&v=beta&t=Q0sXzJvHHmbYgZoxfxlyjN_ImWN4FCi6oqtMZKhwCds',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Leslie',
        lastName: "D'Avella",
        emails: ["ld'avella@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGNCSUc3QdiYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702388569211?e=2147483647&v=beta&t=GIlookh8mNm-iKeA2MJVPuwAlrFEIiL9LadRH7Qxhag',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kirk',
        lastName: 'Koylion',
        emails: ['kkoylion@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Murphy',
        emails: ['mmurphy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFhroznjuT3aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668609536084?e=2147483647&v=beta&t=gjkK6YwISnmT6AqE0IR5SGtM6YUG4E9V6AGcea2HF1k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  softpvt: {
    name: 'SOFT Pvt Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shrayas',
        lastName: 'Rajagopal',
        emails: ['srajagopal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHQPrJPLgkk3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630822187599?e=2147483647&v=beta&t=RvPPbu2e4kTQzVcrukxx__te9n6FXR8MKm0Hh21xdFM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'KRISHNA.',
        lastName: 'J',
        emails: ['kj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEjmM1m9CYugA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628163189469?e=2147483647&v=beta&t=L6N23liwGpzvqrf65o4LC1i3rdSbTHFW-bT-woAD2pQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Codiastic',
        lastName: 'Soft',
        emails: ['csoft@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGSXow2NctyxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706609161206?e=2147483647&v=beta&t=R429XX_wlCXej70vIh69d6-5uoEg-SG25NCPL_LPA78',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Manish',
        lastName: 'Bhalla',
        emails: ['mbhalla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG6G-R67jjTwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608882133133?e=2147483647&v=beta&t=3Vh8FquszgaUxSqwk0LUG4Mz1Nf6NHm4xuzKEfvcCC4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anandhi',
        lastName: 'Srinivasan',
        emails: ['asrinivasan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  brandingbrand: {
    name: 'Branding Brand',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Mason',
        emails: ['cmason@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHubFpazlTu_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617221227763?e=2147483647&v=beta&t=xnRKNy4n1lk3xH5fYdDJR05iYQE6Zg1XpVAydGEMiPQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brian',
        lastName: 'G.',
        emails: ['bg.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEuJJBs7_2YYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656272002178?e=2147483647&v=beta&t=kUwQ-HEFTLmgvbrmF9oN6RbuZWgjD1-0PFrNVotmiSY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kendra',
        lastName: 'Davis',
        emails: ['kdavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF8BfABf3XOdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1613233035466?e=2147483647&v=beta&t=zhiwYKfb1sCaUcMmliuazer4XmONVWytzJBLjQg9-ng',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kevin',
        lastName: 'Smith',
        emails: ['ksmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFuLE218WQcpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516773669364?e=2147483647&v=beta&t=2_YYyoKEBGYROdeZxs1I4kksCO3yLtGGKiWBN0MUIVs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Mason',
        emails: ['pmason@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQELpgdh7G_8eQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671060857612?e=2147483647&v=beta&t=3C87sG8AZp8IFuu9N3LpycJyQDMtRZq3FmuIsrhA1Hs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bluemargin: {
    name: 'Blue Margin, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jon',
        lastName: 'Thompson',
        emails: ['jthompson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFwHTf8KFS27A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516512266516?e=2147483647&v=beta&t=p-jic7rQjJ_oqnGzz1H5nb96A8iY7whZXrnkqjDBdoY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Greg',
        lastName: 'Brown',
        emails: ['gbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEYElGJS3ZJug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713194323700?e=2147483647&v=beta&t=75ipH_q_T1-d5dwMIzGtjE3HKE_YRmiWHp-FfvGE-Tc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tim',
        lastName: 'Kelley',
        emails: ['tkelley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFLGXDCmC4iyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694223314291?e=2147483647&v=beta&t=uNiAg6lNS0uvFvAiYD8JSxQOwYSlUHfGEfdR1d2Hjwo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nathan',
        lastName: 'Mildeberger',
        emails: ['nmildeberger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGPWDRxQVVFrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604549137174?e=2147483647&v=beta&t=T9GDADB1w0xQvyN4sY85VNGcUfCFzgdkSsgDcJSAFDg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Angela',
        lastName: 'Tourney',
        emails: ['atourney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGH0o1KMXLGpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516853489533?e=2147483647&v=beta&t=5eYE-FNtmMKrDWheXwrYeJgjDysBpCT4PHteMsr7OM8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  frs: {
    name: 'FRS Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marc Diederichsen – FRS Systems |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHWsYToaEW6rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686225092641?e=2147483647&v=beta&t=7yA-RaFmIWMuCepGuOhdltT_9rA_4-w5Ro7OFWV2z2E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marc',
        lastName: 'Diederichsen',
        emails: ['mdiederichsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHWsYToaEW6rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686225092641?e=2147483647&v=beta&t=7yA-RaFmIWMuCepGuOhdltT_9rA_4-w5Ro7OFWV2z2E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marcos',
        lastName: 'Pacheco',
        emails: ['mpacheco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFsFFQg_GjPiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670847997349?e=2147483647&v=beta&t=TBBm3X9PcvelcnGgCA9pn1-yaymlpO6Ur38cdggG_3s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mohamed',
        lastName: 'Masmoudi',
        emails: ['mmasmoudi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFQXn5My2E_UA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714412401786?e=2147483647&v=beta&t=WGXoRbgC6E2T470XywHlhJqsGMb1KPq1zVSmjCuLaMY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jane',
        lastName: 'Marks',
        emails: ['jmarks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGDkC5Wxa1swg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1599561950075?e=2147483647&v=beta&t=LEGLtTC5veec6UmlVnegJZWMmYd9e44ne_KPRiNoluA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  facettech: {
    name: 'Facet Technologies, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Elizabeth',
        lastName: 'Shaw',
        emails: ['eshaw@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brian',
        lastName: 'Ford',
        emails: ['bford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFLEJEI3B7RBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646499816847?e=2147483647&v=beta&t=XyFPZuaAhW-bJU4HMWw4Vhv7kvxwplsX8hpIVK9nsiA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Katie',
        lastName: 'Simmons',
        emails: ['ksimmons@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE4nkSmpWfs2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663181033980?e=2147483647&v=beta&t=2EIFbp-i9JzH_kDHjyCuzlVTHnZd0MObI1zlEm7GDYE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jason',
        lastName: 'Hahn',
        emails: ['jhahn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brett',
        lastName: 'Rogers',
        emails: ['brogers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGWDRwvQRQC0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628304744832?e=2147483647&v=beta&t=Ncr6eiA8XQdhev-nP2RilDd9QeIzpZpkYpEaJIWo8RQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  westmancom: {
    name: 'Westman Communications Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jamie',
        lastName: 'Harrison',
        emails: ['jharrison@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEAA-diBDN4RA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516930235746?e=2147483647&v=beta&t=lJAR0e3gIbu2UFPFVv4E3afU5VMhX2GWkT-FMjENsP0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Laurie',
        lastName: 'Brugger',
        emails: ['lbrugger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGscPt1pghEfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583789015320?e=2147483647&v=beta&t=lI6D5rHXpYn3XXqiSPWdaT3mi8GYY9VB7Oml0Ar0bNI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ken',
        lastName: 'Sitko',
        emails: ['ksitko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFFyS2EUi2hfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516927067117?e=2147483647&v=beta&t=kIkh1T83eEzSF2XiqFIasHTW0Bj-fB3btEhtA6ysNLQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jessika',
        lastName: 'Guse',
        emails: ['jguse@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sonia',
        lastName: 'Cole',
        emails: ['scole@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGGjxNJqXknMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629494977243?e=2147483647&v=beta&t=GadPOeXg4ANml2Mcw4ZpiPHMygC5p5biGt0pJVj7VQI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  shengqugames: {
    name: 'Shanda Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Helen',
        lastName: 'Tin',
        emails: ['htin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGoW_M77hvQag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708678820265?e=2147483647&v=beta&t=L-sohN8JY5d33If14TTJOAKAp3a7snDEqKMPFqHPs-o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Qianhui',
        lastName: 'Sun',
        emails: ['qsun@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFdsjrwRrbnpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723432338600?e=2147483647&v=beta&t=2gOEyicYHC-hgFNvpKrg8mOCRyANWosxaHwkuyGNQn8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jialou',
        lastName: 'Wang',
        emails: ['jwang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHVC4qCCSUJ9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662777096847?e=2147483647&v=beta&t=CKWFBXqp4F09cOgMRmHLGZkuuoJCewIOQSylGmDJN6Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Yifan',
        lastName: 'Shan',
        emails: ['yshan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE01r2JpdDd1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718322205144?e=2147483647&v=beta&t=I9Dpgls4rsad8p3aOciI8_fzoI9I8eUAw6yei7QD8aE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hansheng',
        lastName: 'Liu',
        emails: ['hliu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE-GxenYgqrrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654331050072?e=2147483647&v=beta&t=pAK1SrJTlork-RcjgzRdWS4RGvBgTByzBHSozMZBLQU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  piftech: {
    name: 'PiF Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brent',
        lastName: 'Wesler',
        emails: ['bwesler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE0nWMI3Cj28g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724250041634?e=2147483647&v=beta&t=5P2V6B6uspMvRIADOc03Rn5udahcQyO19jGEStFk52U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Deb',
        lastName: 'Sylvester',
        emails: ['dsylvester@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gary',
        lastName: 'Saklad',
        emails: ['gsaklad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFRKxG1xS73_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715093162349?e=2147483647&v=beta&t=_Ik0l8STXIDOJbBYN1YENALaqTMQHEtyFESGOg9JCzs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marissa',
        lastName: 'F.',
        emails: ['mf.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEGIBIM2E4MuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729706990266?e=2147483647&v=beta&t=92l7BNZw3xvi9-KxI7xng2rIMPtCSd8H5fNRGAACc9o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hannah',
        lastName: 'Barrett',
        emails: ['hbarrett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE_RHadfBBj3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723078704522?e=2147483647&v=beta&t=zer0mrRRqaPlNsD5XwFdjWtFF-w4ARRS5TUc-vj8878',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  optoma: {
    name: 'Optoma Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christopher',
        lastName: 'Graefe',
        emails: ['cgraefe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGmLuPjK2MVHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575576000931?e=2147483647&v=beta&t=U5c1EAO6tGCxKBxdlfI2Z_5SScocZJoGDKTLNVxetwA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Romel',
        lastName: 'Tomasian',
        emails: ['rtomasian@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGtrWN1qAuJtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685810044862?e=2147483647&v=beta&t=bUTIcCML15Xag9coCbepnCxWGx5NOEoda5d4rRNk8Vs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeffery',
        lastName: 'Perry',
        emails: ['jperry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGm8p56lOVtxQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694458588564?e=2147483647&v=beta&t=wNsbnO5BlqzJvm6ui_lsRZJtmCwy3ByKHR_TwdNxtz8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brian',
        lastName: 'Soto',
        emails: ['bsoto@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Scott',
        lastName: 'Willett',
        emails: ['swillett@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  microram: {
    name: 'MicroRam Electronics, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jim',
        lastName: 'McGauley',
        emails: ['jmcgauley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEkQAKNw-5aYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701780982696?e=2147483647&v=beta&t=dQ7dJAE6IQk5K_KqyIZTBycutSKz2qyUuwV-u4-CKxw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brian',
        lastName: 'Wilson',
        emails: ['bwilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEiqmeoNzJT7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517749150070?e=2147483647&v=beta&t=taMWkeZdss8W776jkA7wHmWkYFNVfEFTrb8GuWIUz7s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christine',
        lastName: 'DeFerrari',
        emails: ['cdeferrari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGjZ8hmUOr5HA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517245411174?e=2147483647&v=beta&t=6KLxBCD2vTZsxRQIRni_LFv-k4j5WQQFxxgI9pWOQcE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'McHugh',
        emails: ['mmchugh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHERWIEaRBE-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570734975751?e=2147483647&v=beta&t=7dmtZFIM_pHVOl9eK7FnCt0tOecxurR-bFgY7iHJdxU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Beth',
        lastName: 'Markle',
        emails: ['bmarkle@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  marathonts: {
    name: 'Marathon TS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brett',
        lastName: 'Herberger',
        emails: ['bherberger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF7ltC_a4Y7kA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1520009021177?e=2147483647&v=beta&t=oZ3hxGcegzcd-tTqhSj6FxD5lm5Y0h8JE3xtNHIBSgQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pamela',
        lastName: 'Siek',
        emails: ['psiek@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jalen',
        lastName: 'Howard',
        emails: ['jhoward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHXcvwsvmVNIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645802513297?e=2147483647&v=beta&t=2azj0q1P2lpBv1XGqukyMa-xGjGcZs-QXkO3VM8RHY4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mary Hepler,',
        lastName: 'CPP',
        emails: ['mcpp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFT1nQ7_4lGjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574712164370?e=2147483647&v=beta&t=Ec1wOQSxM9cc2cavR0eg0JIekPWBi5tDuesdsxl3_48',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sam',
        lastName: 'Lorenzo',
        emails: ['slorenzo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGxVeSbnKI8UQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660235615627?e=2147483647&v=beta&t=AtHSzJfZp_VEWVMV9jk-k5jm_NRPal2eIwsCbcCl1Ks',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  phoenixdatacorporation: {
    name: 'Phoenix Data Corporation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gerardo',
        lastName: 'Lozano',
        emails: ['glozano@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFiXQKu4nfVng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638508853278?e=2147483647&v=beta&t=KLizgthhqFM2etXacGPysv4E0r8TdKCZ7QGxebyccRY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brian',
        lastName: 'M.',
        emails: ['bm.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bryan',
        lastName: 'Larkin',
        emails: ['blarkin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEqd5iM4ZS0aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517516334469?e=2147483647&v=beta&t=uve8f17KwYPVaye2F2cs0JOa_AA6ilxoxdV-_0H1I6s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Wendy',
        lastName: 'Carr',
        emails: ['wcarr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alexander',
        lastName: 'May',
        emails: ['amay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHUrIERly4cug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1571776598849?e=2147483647&v=beta&t=T7ax4srldLqBuAQy4be9EqVMrQTE7Vg9V2YDE1XYgk0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  musicreports: {
    name: 'Music Reports, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bill',
        lastName: 'Colitre',
        emails: ['bcolitre@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFb5k7gZoB4Tg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656952604690?e=2147483647&v=beta&t=qJO7Da4Wtn7pwSffLpnASD-HjZJaoI9pKjYYu2DN1cQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tom',
        lastName: 'Faulkner',
        emails: ['tfaulkner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFTUPvYQAb_qQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693518871445?e=2147483647&v=beta&t=DYhUyLEmdjFxzIVVwgW_c256C34-Lkw6FTOuaeUKohA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Shanley',
        emails: ['mshanley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGnpXbP_uyPUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715217985245?e=2147483647&v=beta&t=4jq_nm5Ar7Id3f4btPAv0Erq4nBox065IeXtwYvh5e8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeffrey',
        lastName: 'Ensign',
        emails: ['jensign@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG5ND91t61TAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632780945268?e=2147483647&v=beta&t=emEVnGwGX_Xa-EocZ7dIVnKbzBYoGSRA3SMTs4J4_bA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eric',
        lastName: 'Deluca',
        emails: ['edeluca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFXFicmZAc-7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583686644949?e=2147483647&v=beta&t=oNX-gUe0Vc5t-KgrcCPAUxuQK7VeLnyhTbKdB11IS1M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  avanitechsolutions: {
    name: 'AVANI Technology Solutions Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Prashant',
        lastName: 'Vanga',
        emails: ['pvanga@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEN3mJQyjuJqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627312468851?e=2147483647&v=beta&t=uoiGReuBUaXtyPBtRwawWcvDEP-eRmoAEQEdNFHZ3Ho',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vidyadhar',
        lastName: 'Penikalapati',
        emails: ['vpenikalapati@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGSt_yGD4ZqNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517432396380?e=2147483647&v=beta&t=wkYyQobtz0WLo8Ulsx3X5Zvo5bcz12Bpxl74ubPW8wY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mitch',
        lastName: 'Meller',
        emails: ['mmeller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHggOJ-_1Fcog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517449439710?e=2147483647&v=beta&t=tYiM7RLkKeDJTQhwtqt3lW0fwfJP2lAbQfIsElAm3pU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Srinivasa Rao',
        lastName: 'Balina',
        emails: ['sbalina@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mallikarjuna',
        lastName: 'Dommalapati',
        emails: ['mdommalapati@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHdfNhrHdQfYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517764128146?e=2147483647&v=beta&t=b-m9bHcjVtuQ0xneFtw3L9AJosW2TRmzESm1uniurmY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  totalsol: {
    name: 'Total Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Drew',
        lastName: 'Fitzpatrick',
        emails: ['dfitzpatrick@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHAmzFM7wMp9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516529896292?e=2147483647&v=beta&t=Ub4Aq6w2f5XrlSQJhwkXsstncvVYnZkc4uSNQFyzPCQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Morrill',
        emails: ['mmorrill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGdruRsbMM_hg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601743806105?e=2147483647&v=beta&t=wJ09XCHDvIBYjAyb5kZ0Y0vbrj_sM__vtcIfckTWpqY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joe',
        lastName: 'Urbanski',
        emails: ['jurbanski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH_-DyF6e0mCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714523557276?e=2147483647&v=beta&t=re0GtY5DlS22iuAU31_8cVmo4RfIX-U-5gp-d68ea0c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kelley',
        lastName: 'Lynch',
        emails: ['klynch@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David S',
        lastName: 'Hargrett',
        emails: ['dhargrett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGKMI1fyv_4Pw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636309926576?e=2147483647&v=beta&t=8b-uXU_Whch3hxntfD2q2NvfJMlvwh6yo5XDhdKHfnA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thinknsolutions: {
    name: 'Think n Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Praveen',
        lastName: 'M',
        emails: ['pm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG8JnnqbAVzqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517471090247?e=2147483647&v=beta&t=9xqaNBT37rpjJ6s7E9emEmD8Jc-GoUN2nfOH9uA0va0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Suganya',
        lastName: 'Vijayakumar',
        emails: ['svijayakumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHsxjy03bsymg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655748650516?e=2147483647&v=beta&t=sttVs7HgImKVFK-B4mH7BzlZagY2iSDAgtC9FaRrrRY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Yamene',
        lastName: 'Ramesh',
        emails: ['yramesh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jothimurugan',
        lastName: 'G',
        emails: ['jg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFyVI4Lkgyiog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567601503965?e=2147483647&v=beta&t=6TMwJ7VVLj_h-yXfvdgLtBwaggWWBmWfDtUaYH-BCkg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'swathy',
        lastName: 'Ramamurthy',
        emails: ['sramamurthy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHVYgnxckSq4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517513188463?e=2147483647&v=beta&t=lSdK5gyzH1f6rirmNcHb3t6gM4zf9Qziw0FR7i-SW9Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ksourceinc: {
    name: 'K-Source Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vidhya',
        lastName: 'Sagar',
        emails: ['vsagar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG5zFCiqg0yQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517371944348?e=2147483647&v=beta&t=rjXPJ4Jr7BHPcYY4OQphCDj__2u_iI5uGmDgJ7XIU3Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sanjeev',
        lastName: 'Coduru',
        emails: ['scoduru@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marco',
        lastName: 'Yamaguchi',
        emails: ['myamaguchi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJsJWgY3-xEw/profile-displayphoto-shrink_200_200-alternative/profile-displayphoto-shrink_200_200-alternative/0/1528984197623?e=2147483647&v=beta&t=a-cFhstR5J11Kfa7HJANDdAF5T8BJyR8Tm6XHINCOlk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ranga Nallaparaju',
        lastName: 'PMP,CPIM',
        emails: ['rpmp,cpim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFUxxIb3-Ij_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676317336241?e=2147483647&v=beta&t=YmVcjRUAZIFlrAgaUaxq2bFXBjn2Zyjqabj0lnINwWQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Deepak Varma',
        lastName: 'Kanumuri',
        emails: ['dkanumuri@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  warrenlamb: {
    name: 'Warren Lamb',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tim',
        lastName: 'McLeod',
        emails: ['tmcleod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHL3YsmWiH9sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669924876998?e=2147483647&v=beta&t=DzLBBlsKyh6pOuXf7OjT5gYH5wKfrlFT8yFodW7cGzg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Warren',
        lastName: 'Lamb',
        emails: ['wlamb@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Warren K.P.',
        lastName: 'Lamb',
        emails: ['wlamb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFC8EKKUZG2sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569359749634?e=2147483647&v=beta&t=34UsvOs5jEhVZh40LGu-gRurmQcIic5MNOwguZ9dlc0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Warren',
        lastName: 'Lamb',
        emails: ['wlamb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF8_MdTcibq8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516772373037?e=2147483647&v=beta&t=4eV67DKtNWL4mvEl2bzEpdQ-5-4koIs3rDuYS_zhK0g',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Martia',
        lastName: 'Warren',
        emails: ['mwarren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGzSCk07QQ11A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637163182954?e=2147483647&v=beta&t=xrxlS4QY90CjbXwP1Wgi5SLC7eFtJP6lGeSQab7jF3I',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  strasz: {
    name: 'Strasz Assessment Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'McGregor',
        emails: ['jmcgregor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG34FpdWYSkaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631116110765?e=2147483647&v=beta&t=I5F-TpuR-RIh2keMmWyEShyHSFf0iYgtDqodAmOYGak',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Frank',
        lastName: 'Strasz',
        emails: ['fstrasz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFYVkB07n-CWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516267235003?e=2147483647&v=beta&t=fxmV8vavE2XzyWWxH5lNP3rvp4x8kqiaMVUEwdp4rwM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lisa',
        lastName: 'Everts',
        emails: ['leverts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE5u2cqBrBm_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536599678595?e=2147483647&v=beta&t=7UfKhAvQKpGJY9O3g3QarfLd2wLeLyDvsb97-CqaZ_Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jay',
        lastName: 'Myers',
        emails: ['jmyers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHJjnLL1b698Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516550525556?e=2147483647&v=beta&t=KzHIqsk5e4PcGU6j5BevDAGM3NJ9rgX-1Loqdim72Wk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emily',
        lastName: 'Antico',
        emails: ['eantico@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thechange: {
    name: 'The Change Organisation Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Edward',
        lastName: 'Johnston',
        emails: ['ejohnston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEYIITomVkgZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517271898688?e=2147483647&v=beta&t=Y5m-gGN4zvUKVj9aMa1xYWAaZnx4-67rtAwO4eQRFh0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matthew',
        lastName: 'Prince',
        emails: ['mprince@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHWnUnZ4m96NQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722240979310?e=2147483647&v=beta&t=Qsy1f8FYf7WVzNo03A0XAVeYteap-MC-MShcgyxiCXY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jai',
        lastName: 'Hogben',
        emails: ['jhogben@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFvKpiV7dWMVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696001987150?e=2147483647&v=beta&t=EXo69LnwpG7Hyh73fMX-ouvYitZEh6z34EBZcZuHs1I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Charles',
        lastName: 'Burns',
        emails: ['cburns@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Danielle',
        lastName: 'Rogers',
        emails: ['drogers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH4XWwOA09SeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578318310135?e=2147483647&v=beta&t=qCgd2_Dt8Hjmd5CZ1vGrUpHr4naW1S07Dg8s2ZVmT00',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  deGmbh: {
    name: 'DE software & control GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Onur Mubariz – Unternehmensinhaber – DE software & control',
        lastName: 'GmbH',
        emails: ['ogmbh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG697BYnnyGOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698224120047?e=2147483647&v=beta&t=bJvAyc0OqH_HavtjCQbysvDTKCU_tV-4xOE7HGwNoag',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nadiia',
        lastName: 'Sevastianova',
        emails: ['nsevastianova@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Nick Greulich – Software Developer – DE software & control GmbH',
        lastName: '...',
        emails: ['n...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Nadiia Sevastianova – Marketing Director – DE software & control',
        lastName: '...',
        emails: ['n...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'Kiermeier',
        emails: ['mkiermeier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHt9meVtFjiGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718253174178?e=2147483647&v=beta&t=1I9xqtCKEh_Rvm8xcT2o1iATr8q5ZdQrL3NVBUMTb7c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hc3: {
    name: 'HC3',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jennifer',
        lastName: 'Deaver',
        emails: ['jdeaver@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeffrey',
        lastName: 'Reeves',
        emails: ['jreeves@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGyFTm13eI8LQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673631522578?e=2147483647&v=beta&t=w3oKJUr4GM1q0GDIpv3zPnUpex4jhcA3Qtm-x-6qt4I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gerald',
        lastName: 'Pettway',
        emails: ['gpettway@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGyhI5DVcHMLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1537147083357?e=2147483647&v=beta&t=yEBwBDTWG-BQd2PyWaS9fC_3LaKxSkc5KIPbW4l13yY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Clark',
        emails: ['jclark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHWN_4Lkxw4Ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660251244500?e=2147483647&v=beta&t=o2mYMHkIMDdWQ9uOTOjbEWvSruDv1d3AYjTd1y6MG6w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Meghan Phillipp, MBA',
        lastName: '(she/her)',
        emails: ['m(she/her)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH1CCoNxUxg8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673040533861?e=2147483647&v=beta&t=IdAGcgbIEl9WasfL6KEowb2NMLJh8MbJpsFOeCvi2aY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  carvajalpharmacy: {
    name: 'Carvajal Pharmacy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adrian',
        lastName: 'Aguirre',
        emails: ['aaguirre@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFHUxmf-BbHvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1640551242205?e=2147483647&v=beta&t=rmLGAxZ9espVD6DEWxptGSUcQ5vRnMuROTrgsrsMBcs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brianna',
        lastName: 'Gonzalez',
        emails: ['bgonzalez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF5xbqr0K4rpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695089500083?e=2147483647&v=beta&t=Z0FDfZeI7yyOt4zGEQVVdMyAA31Oh_suPDpyYnrhEVI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lauren',
        lastName: 'Corrales',
        emails: ['lcorrales@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anna',
        lastName: 'Montero',
        emails: ['amontero@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGTylmpLwMqIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585683017130?e=2147483647&v=beta&t=GE6fx9drnbrzNBYKlNQCPR0Ev4TKe0CczrdfVa4z8xs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yvonne',
        lastName: 'Navarro',
        emails: ['ynavarro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG8p3g-Y_hQkw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516686107391?e=2147483647&v=beta&t=cRrPOtHGBeynBJlgwXn9vZpiMznyvs5jTIp1ElkdNw8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  datacaliper: {
    name: 'DataCaliper',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sara',
        lastName: 'Kapoor',
        emails: ['skapoor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG4Wwd84Blyuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517251016104?e=2147483647&v=beta&t=JVtmmjsbY-OQgC1on7oDxeozAoqBOs_Hb6Wgj6hkjlo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Waleed',
        lastName: 'Jeha',
        emails: ['wjeha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGYoPqTJejy8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632862642117?e=2147483647&v=beta&t=lRaM6uiS673VULSsajJLNyqnGlUfgOGjJDq0DXXViN0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shamsudeen Mohamed',
        lastName: 'Bakar',
        emails: ['sbakar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG2mveQZWbaDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536703324259?e=2147483647&v=beta&t=s0_deF22AA9XL2w1-vOWgWZULmZhoHAERRpKbOxsKjE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Justin',
        lastName: 'Sahayaraj',
        emails: ['jsahayaraj@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hari',
        lastName: 'Prasath',
        emails: ['hprasath@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEnfGorBXg2hA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718270867714?e=2147483647&v=beta&t=TEfvmwrqjdvMVac8lmv9zhY_2AjaLlaHoink01Id2jE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  edgematics: {
    name: 'Edgematics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ayesha',
        lastName: 'Khan',
        emails: ['akhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEzprR-gI4-6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561795676716?e=2147483647&v=beta&t=sv_3EQAxYy5qyOhKg8zs2RI3uGSYtrPlENttduGgK4I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rushikesh',
        lastName: 'Kulkarni',
        emails: ['rkulkarni@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEQhKH9bOGBog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683789297161?e=2147483647&v=beta&t=EvKv8ijegHjEsHFg9yHCoJ2j_6cJpE01eUd0ZOEiOVQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bharat',
        lastName: 'Phadke',
        emails: ['bphadke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEpsvir3Pxb8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683709586178?e=2147483647&v=beta&t=enr24oI5Skia43hTIhqcC9FX9N7A8azP17PThe63TnQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nitin',
        lastName: 'Pandita',
        emails: ['npandita@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHUxq3AvUkiXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699076919627?e=2147483647&v=beta&t=nXu-mbhz3Q_zhHW78Flz9U-okgtCq3j3GIQ_wTS1IYw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sharmila',
        lastName: 'Begum',
        emails: ['sbegum@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFQb87NKjasYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654668588538?e=2147483647&v=beta&t=wdVI708ndlE6r9DxH3PnR1G-1y4EtkkGifgteOmf1P4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mechanist: {
    name: 'Mechanist Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'L.',
        emails: ['dl.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHG7tsjZl8Xpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1627959625065?e=2147483647&v=beta&t=IdC0QL0yyYhIawE4Yk052raZ6NvMWQadIzEk_hneTT8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'Frankfort',
        emails: ['afrankfort@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGDGSpbNyWvLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628944795835?e=2147483647&v=beta&t=OxLazdbhvokw13pONHY8VT5Zz8fwhh2DdeP2mr0hMWU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Viktoria',
        lastName: 'S.',
        emails: ['vs.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHondojVYHBMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651645016530?e=2147483647&v=beta&t=G5Jy12AxrAr_JYYAjOMpvJsXDOOTYfbyWfqjNyZM744',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hakan',
        lastName: 'A.',
        emails: ['ha.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGduDbMTbx5PA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633527269583?e=2147483647&v=beta&t=KPHEBi-1vw5yrMzl4keDQD42z4afTEZC6yBbMdyae_0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ethan',
        lastName: 'Ben',
        emails: ['eben@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGbNqssSUKxpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517357919892?e=2147483647&v=beta&t=1-yk85oR4sboc9aeeE2JbaOIy-WDylsHZTgnvqrl7LY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  senecahq: {
    name: 'Seneca Resources',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Justin',
        lastName: 'Loweth',
        emails: ['jloweth@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Melissa Manley,',
        lastName: 'MBA',
        emails: ['mmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGh0MvffklHvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664797121912?e=2147483647&v=beta&t=s7bZWKD_vzgxL4eLo4U5MkuDzpRWxpxPgyBPuHN3IAI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Todd',
        lastName: 'Abbott',
        emails: ['tabbott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHlVYTkBUN1ng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564096641932?e=2147483647&v=beta&t=VJeKVFn_ULJlYOzPl7pc7cqsoBitfQbUVSUBzYN8Y4E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paul',
        lastName: 'Gamble',
        emails: ['pgamble@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGKs3GA4V_kNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1534277227564?e=2147483647&v=beta&t=9M_C68W4BeniSPM8AXrEDlfp6EUH2Eo5S1ZDy7nUjXs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Trey',
        lastName: 'Porter',
        emails: ['tporter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGcWe5m94yLlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564504934531?e=2147483647&v=beta&t=G6Jq-wVbDqgEGLf16pcRwvG5zDdd0DtxTjayNvknjfc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mobizcorp: {
    name: 'Mobizcorp',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Christoph Heiders – Director Business Development – Mobizcorp',
        lastName: '...',
        emails: ['c...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH0HeDq2tSbrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718358867879?e=2147483647&v=beta&t=lrxz2fP5Eulltfp3ida-HkubtYL879XjH2BLvF25uuM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rainer Sudhölter – Director Operations – Mobizcorp GmbH |',
        lastName: 'LinkedIn',
        emails: ['rlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tobias Helferich –',
        lastName: 'Director',
        emails: ['tdirector@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEoa-7W6oKagw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516970322146?e=2147483647&v=beta&t=ePVgocPsk499JuvEnry176IAzRwpM8lnv2e7eWlybmo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Philipp Fabian Scharhag – Mobizcorp |',
        lastName: 'LinkedIn',
        emails: ['plinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJAtCJgvx7og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556642422508?e=2147483647&v=beta&t=ShVo0cwpoQm24gaPjYQphG1G3zyDqxfDux0A7mCe7mA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bryan',
        lastName: 'Sanders',
        emails: ['bsanders@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  supportPartners: {
    name: 'Support Partners',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hamilton',
        lastName: 'Powell',
        emails: ['hpowell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGVq8eQNp3deA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605642397336?e=2147483647&v=beta&t=Os8EU9w4pCed15KwgHsP309f2lctSxdcLVwm4a7ePkU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Dworkis',
        emails: ['mdworkis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Peter',
        lastName: 'Pin',
        emails: ['ppin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHD-ecesF5jeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724964790518?e=2147483647&v=beta&t=tOoZYjFljHf8RZDL2-BtxG5QP_yhKbUtWow05ecLCXM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Peter',
        lastName: 'Willis',
        emails: ['pwillis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFEHKuK25blDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1545136452797?e=2147483647&v=beta&t=pqtvrBP09TAJ0q8NN9rRjEvL2KxM5M-4AceveTmhHEk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jake',
        lastName: 'Bittner',
        emails: ['jbittner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG-Gt_u1U-29w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675436099900?e=2147483647&v=beta&t=3Y8zp1dh9L95n4v-IhOCIMEN-2mTNyMgPy5yPlNXzzs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nexaq: {
    name: 'NexaQ.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Srikanth',
        lastName: 'Kasula',
        emails: ['skasula@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGcocgmzW1iqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517677373032?e=2147483647&v=beta&t=olHO30AHblY3wnH8I0eCSyc7TRk9-BSc_o0UUdfqwhA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Garima',
        lastName: 'Agarwal',
        emails: ['gagarwal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sindhu',
        lastName: 'Hullur',
        emails: ['shullur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHEXfQsdMW9Cw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707719912205?e=2147483647&v=beta&t=TqcuegyOgokz2t3ll4XKtiaI7ql8cIfkKKiotvODeVQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tarun Rohit',
        lastName: 'Sarana',
        emails: ['tsarana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGFzASb0t1-EQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718250882291?e=2147483647&v=beta&t=8ZAOqzNS-iOQDY0psgdTqReUIU67lh6cbjYraCAOyZg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Stephen',
        lastName: 'Raj',
        emails: ['sraj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHyOX9jNzHdnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650618959939?e=2147483647&v=beta&t=0Q8rVA8ozQowpeM4Ip8YvQVde6BHjW1ytZOOJRl-4bo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  polyelectronics: {
    name: 'Poly Electronics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'Kain',
        emails: ['mkain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGohfYCMxoEXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670450721868?e=2147483647&v=beta&t=JbWEkNdGmJr2u5By5-kifcH4UFGqrnrHSoJ4vQVtEn4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paul',
        lastName: 'Eastwood',
        emails: ['peastwood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEDIN3uPkD2WQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691072266279?e=2147483647&v=beta&t=bZg1yDEXQ0_f17RvgNm0h5hzmTyqXuxhnkPEQsPOelE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephen',
        lastName: 'Gress',
        emails: ['sgress@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFic66Tumos6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714672414929?e=2147483647&v=beta&t=asxanHC4Z55nUyJkhaR6zGpDJ6kDO8c3NxGpI8nj1Mw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rik',
        lastName: 'Kain',
        emails: ['rkain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGmJlZHlXOcXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656007781306?e=2147483647&v=beta&t=IzMlpBENXMfTnaM1sxh0skyHTRAFnve3CByKPsnS5Dw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paula',
        lastName: 'Kauffman',
        emails: ['pkauffman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFHP4GeQMGkVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531243909387?e=2147483647&v=beta&t=0zHg4bf917uUx8HdBy30b6RBxXQZUV82yHG_O-iPM3U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bromcom: {
    name: 'Bromcom',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ali',
        lastName: 'Guryel',
        emails: ['aguryel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE27ttu9AZQuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516571767356?e=2147483647&v=beta&t=qF-O8uKzYkekDw6iCOFS-40NoZKDhy3c_boSgmOmpqw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vijay',
        lastName: 'Koganti',
        emails: ['vkoganti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE-pNMq9pxHxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572821387240?e=2147483647&v=beta&t=JrBMcIuSwtft_TX7dByywRcTeB9O8UejkIELV2SeZeY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Biggs',
        emails: ['sbiggs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFTg3TghXk5Yw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664807830641?e=2147483647&v=beta&t=19IdegWRkPQtlD9dSG4L8EV3U2Z8nYj9alHhDA7RcAo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vicky',
        lastName: 'F',
        emails: ['vf@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEu_De8WT6YpQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730219642721?e=2147483647&v=beta&t=Kqc3Ux4Eas-b6bdq6XGhVHdeJM3-0RVf_YqrbhB2rNY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Loxton',
        emails: ['mloxton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGmyDK5WFCXYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637687483938?e=2147483647&v=beta&t=s2f_5TwSGuwiC4qRRjAooLYDGfcYKsGBw42_PlGkxoo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mapolbs: {
    name: 'Mapol Business Solutions Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Balakannan',
        lastName: 'R',
        emails: ['br@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGYOPXN_LJD9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517639294600?e=2147483647&v=beta&t=CEBRIJzg3QJt3RaGFt1HXbTz4pfgMbo1xmEzcFervk0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rajkumar',
        lastName: 'Gunasekaran',
        emails: ['rgunasekaran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFgiBefNF2D3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568981751006?e=2147483647&v=beta&t=dZKJNWumeH_qpfjwzVcjQx1L55vMC4_nI_YVSDm8OM8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'V',
        lastName: 'JAYARAMAN',
        emails: ['vjayaraman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEK-YQ5QhI5XA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701490510470?e=2147483647&v=beta&t=SsuSTELcSA_BPU7ucnxYEWYNtQn1tQbCTQEyaNr4SG0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bala',
        lastName: 'Kannan',
        emails: ['bkannan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Balakannan',
        lastName: 'Rajagopal',
        emails: ['brajagopal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFn7R053IWjAQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517485141027?e=2147483647&v=beta&t=eOg0bZW8eyUV8-VrCKmT_H28JjUgmZFmiAE6hrEU-nk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kalaniConsulting: {
    name: 'Kalani Consulting, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brenda',
        lastName: 'Quinlisk',
        emails: ['bquinlisk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGQL2xNqybkGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660168242889?e=2147483647&v=beta&t=zbxYuoENZvzK-sJFV1oiHJas8UT-3YsU0rwJ3QjgYnc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Madhu',
        lastName: '',
        emails: ['m@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFy9gWmJZvqPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575923608453?e=2147483647&v=beta&t=gstyvHmwjnsC4BaXDyVyzbv59xK5iohhKo9-4Kw9OmA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kayla',
        lastName: 'McVicker',
        emails: ['kmcvicker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHaJbQTbKCWxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643735372002?e=2147483647&v=beta&t=aFvEzeH3FdxSN5Ada3L02h9yubudJm_KskGetz80A1E',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alicia',
        lastName: 'Bermea',
        emails: ['abermea@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEmqZBAG8mYMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1612199484634?e=2147483647&v=beta&t=KSf1b52XldQfgsIk0v5Viqb_xgh8TPnZlWF0xl3Eajg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Phyl Khalil, MPA,',
        lastName: 'PMP',
        emails: ['ppmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFTYXVh0mvwLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542230203473?e=2147483647&v=beta&t=v92SHRz6-JtD7rWSJG3QegCQ-WT7deWlSMyldBQ928A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ilionx: {
    name: 'QNH Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  canvys: {
    name: 'Canvys – Visual Technology Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steve',
        lastName: 'Brothers',
        emails: ['sbrothers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHEa2SpaW_gfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517747402080?e=2147483647&v=beta&t=EjxfH2hyIe7nrRCoxqbmrGZUOVKqadwoGVxJ-Tx1v84',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mike',
        lastName: 'Klingberg',
        emails: ['mklingberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQERLa4HwjPIUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721396533993?e=2147483647&v=beta&t=U92Iyi33qIN4srEScBSZYCbJz1_hKY5QzjLrD3uD3Bk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pete',
        lastName: 'Nadeau',
        emails: ['pnadeau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEIN5s3gy80vQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697066596422?e=2147483647&v=beta&t=-yObvtJyzTTc8sensxPcFYxnYeHmf2A6ZaEjh6e2c-w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Karen',
        lastName: 'Wheet',
        emails: ['kwheet@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'STEVEN',
        lastName: 'STAMPER',
        emails: ['sstamper@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pipkins: {
    name: 'Pipkins, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Martha',
        lastName: 'Pipkins',
        emails: ['mpipkins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ronald Pipkins,',
        lastName: 'ITIL',
        emails: ['ritil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEKTA6U6dONlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516959007014?e=2147483647&v=beta&t=K4fm3lkEG1AlGQZoX_pudw7TkmQZz-zPDaIhw8DJlQ0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Annette',
        lastName: 'Pipkins',
        emails: ['apipkins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFm7vIX2IrsaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726349304857?e=2147483647&v=beta&t=2Z-z7d56XdRo9i5oYQpwXR4XDsiJDy7-Yjp_vjglk0k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brandy',
        lastName: 'Brown',
        emails: ['bbrown@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFKYLRyZdmIQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689542768529?e=2147483647&v=beta&t=TBT9Nc1J5xMFvfqQyilt64EAZcTVak3X31SeSdHByRU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Keith',
        lastName: 'Pipkins',
        emails: ['kpipkins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEEqHzxisR-mQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573342760992?e=2147483647&v=beta&t=61gjmiBDOVE3aXRHhUErJvgmG3j4hwcCvVLGAn3LB6g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  waident: {
    name: 'Waident Technology Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Ahlberg',
        emails: ['jahlberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHEEDVIMqqJyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708707523356?e=2147483647&v=beta&t=b3CF5A_QXxXH4DcrkczsTsDgfZ07JmkA_UltQplQfpo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Natalia',
        lastName: 'Kapustina',
        emails: ['nkapustina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGRrWeQFb9Zyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711643990416?e=2147483647&v=beta&t=n5k2ipGGPRpZrPlMgyoiCOwsrMg0NWDpjmpCwQArcNE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brenda',
        lastName: 'Quinn',
        emails: ['bquinn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG-EyOVbOBzYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516946702736?e=2147483647&v=beta&t=w8Mgr66teuJYmIrr3NjNuDBvu1FL37Y9onqcrVAaM8A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Jez',
        emails: ['djez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEr6Cak-INT7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686779296788?e=2147483647&v=beta&t=rMpRxccr4KCiv6AdOnGnyUYumuX2lqLLSAgnz5zkdUg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dan',
        lastName: 'Szuba',
        emails: ['dszuba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF3lVckYFHAxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712164513729?e=2147483647&v=beta&t=0aW5Myh_2h8wRanVGQ3g6f-QKs2MSs9e8o78FC2JEJU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  netrio: {
    name: 'NETRIO',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Hewett',
        emails: ['chewett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGVXRm8mo06mA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704737438342?e=2147483647&v=beta&t=q9qNv055foOez05JL8Nz7fTMckVO17Uk7i2QfE4ykio',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Cromwell',
        emails: ['mcromwell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGoy-hFilxKpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714579748819?e=2147483647&v=beta&t=_o5Qsj8bOdj3Ywd1u1IaL06tKZ6z2mYCeUUwlcu7V0o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mallori',
        lastName: 'Hanes',
        emails: ['mhanes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF6T1tSYtKJ8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610490306986?e=2147483647&v=beta&t=jhlUp6EPxuIlCH4pG9zXrhtUuapLPhuyvqKdlMIJ5Xc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Noah',
        lastName: 'Stromberg',
        emails: ['nstromberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHfXZR8mb6qqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708304670397?e=2147483647&v=beta&t=psR2meAYjkM5qZvGj78IMm5eUTMOsG3WFG3qwJqcp8c',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chad',
        lastName: 'Frazier',
        emails: ['cfrazier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEZDODs4_uttw/profile-displayphoto-shrink_200_200-alternative/profile-displayphoto-shrink_200_200-alternative/0/1529423743992?e=2147483647&v=beta&t=HGEvqf9xQB1AIEsKuLdpLxavOQK-SltODq2e4L0RMUE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  comresource: {
    name: 'ComResource',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anthony',
        lastName: 'Dials',
        emails: ['adials@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFPOhGIqU9CTA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516282912354?e=2147483647&v=beta&t=NT83aDX1CR0o379H5MyzMukckzh6hPGWI6GS07ezHWY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emmalee',
        lastName: 'Stauffer',
        emails: ['estauffer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHn5j_2W_OMUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517726096528?e=2147483647&v=beta&t=lPVQQ8Q4CZC8E8cdC28fEiQtg6qRrErIIA5Gpmj-EcY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bruce',
        lastName: 'Murray',
        emails: ['bmurray@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGsDFk-Oz6_Dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695658730780?e=2147483647&v=beta&t=KWImdwyjST1V9ZLMI_3GMy5fV6Lv0UhjLBTWtjTtl74',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Meg',
        lastName: 'Kunk',
        emails: ['mkunk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG3EClW_LshLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516434853002?e=2147483647&v=beta&t=5ldEwZFfjzPyjinq0GY9V42wQlGPUG92XQpIe1TtRFs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kellen',
        lastName: 'Creagh',
        emails: ['kcreagh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGNFJDweLHVaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605898521344?e=2147483647&v=beta&t=_Y8fDetP89c7s4mRdE1tYSXOC27lS2pkjhpv2LZRKsQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nowges: {
    name: 'Global Enterprise Services, LLC (GES)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dominic',
        lastName: "D'Ambrosio",
        emails: ["dd'ambrosio@example.com"],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGdaPXN9FbnfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1631122319843?e=2147483647&v=beta&t=1lXGqXFgpvOtGjWnx3_rtXvo3vZ9TJtSrm7Pjdgmpo8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cathy Rogers,',
        lastName: 'CMP',
        emails: ['ccmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFDL2-gom_aPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638224529614?e=2147483647&v=beta&t=cmouyBUoy6Pr7QhzQxeNk9rz18-sJetnlMnLxO6ljbI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Cheryl',
        lastName: 'Viglione',
        emails: ['cviglione@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG4IGTM-mg8ZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702016159408?e=2147483647&v=beta&t=Lg_9SVDH4beWQnkbL7j8h9OMLs1Zes8tynU46jbzSKs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Scott',
        lastName: 'Augustine',
        emails: ['saugustine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG-X0iaMS26SA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711467122061?e=2147483647&v=beta&t=mGj_S2XVXnuGwP8kXV3EXRU2oFfrvUDi6NxsZL1_Zl4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jomel',
        lastName: 'Avila',
        emails: ['javila@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHpED45mZ31qA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618178636635?e=2147483647&v=beta&t=xjqRtjs5Rm3HfykTicqJitrL5_Se9blJaEAFpdERxY8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tdktech: {
    name: 'TDK Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrew',
        lastName: 'Mansfield',
        emails: ['amansfield@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQE8OAYWyqhhyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684251643133?e=2147483647&v=beta&t=D87_D-naQq5LM_MNXS-zNfp1VPh7ioQyFq7y3UTn1f4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kayla',
        lastName: 'Jadwin',
        emails: ['kjadwin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE6FFJyQnKnkw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700513510912?e=2147483647&v=beta&t=wUfd919xEtx0CxnBvOlA47i7-sbnJxr30E6fp0L3jB4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mark',
        lastName: 'Henman',
        emails: ['mhenman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHgv7xWuI4A_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516480820348?e=2147483647&v=beta&t=LoNeJxpb-SkehRrwUANTpuSi3RpxkeGsr3VrvAp8ZRU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephen',
        lastName: 'Hurst',
        emails: ['shurst@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHB1YuYfI2Oag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689867456494?e=2147483647&v=beta&t=2cxTXpJyRU45JkCGC2Dp1YkDm3H-7PwsU5uj23wq8D0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Kocs',
        emails: ['dkocs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG_ujqklgCnUA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707500935836?e=2147483647&v=beta&t=uRm755RUa0GUMp7MSiNYQYAlDxzBHZco42Szj3vqfJM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  csitech: {
    name: 'CSI Technology Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Richard',
        lastName: 'Norcross',
        emails: ['rnorcross@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'William',
        lastName: 'Yeh',
        emails: ['wyeh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEIptY0HQv2YQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517065316743?e=2147483647&v=beta&t=KiFaJLmSF2pZRL62xW-aU7EW10TnOEAWIH7gvqvqz_A',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Maggie',
        lastName: 'Lin',
        emails: ['mlin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'tom',
        lastName: 'kenny',
        emails: ['tkenny@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHw-kmOPlM3sA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629208795652?e=2147483647&v=beta&t=quYAhILexy25FFrFDnoDAFy3NEO-WA0YVwN8WXYdr0w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'Womer',
        emails: ['bwomer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQELJSjoqzlNWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661193515859?e=2147483647&v=beta&t=rs8JdZai8T-EKSCyscdWP2WxUtP8daBoDgaxxL-Edgo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aliengena: {
    name: 'Aliengena Captive Pvt. Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Devendra',
        lastName: 'Suwase',
        emails: ['dsuwase@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHP9_llikZ4Ig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591702339132?e=2147483647&v=beta&t=r4DSRpXvDrzFriWQYyXlfxHjCudT97TPbG8LVX_FTX4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rajshree',
        lastName: 'Patel',
        emails: ['rpatel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGOoJIoNcuYdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684667784587?e=2147483647&v=beta&t=nC6wjkNb2K6OPnj7A00Svh274gSTY2E41Fquh_67MoI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nidhi Mariam',
        lastName: 'Mathew',
        emails: ['nmathew@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHpad077C-Xwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714997534554?e=2147483647&v=beta&t=HsLUWelzb_Qqi5dfS0RBgVaJg0G7bdSTKGqLdoNUpDY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sakshi',
        lastName: 'Ingale',
        emails: ['singale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEwxBnMWzczmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711191643883?e=2147483647&v=beta&t=vRV5stOwewxr6f8PrFS1wpZVNzO2uLIzOvtzWKYBbZc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Suraj',
        lastName: 'Gidwani',
        emails: ['sgidwani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGHQPmBpD695Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722977161195?e=2147483647&v=beta&t=_6OA2ZHbelDmd8_WQpu6elb06Wj8UyQ67YudHJYnu2Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  chelseaTech: {
    name: 'Chelsea Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Meyer',
        lastName: 'Ben',
        emails: ['mben@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'abraham',
        lastName: 'rodriguez',
        emails: ['arodriguez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFXaAJ0fn7wHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641485975025?e=2147483647&v=beta&t=f1mwptufN7vgv2zcInaFcepugGl4Hwjxww18gRO_Fnk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ellen',
        lastName: 'Keegan',
        emails: ['ekeegan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHZRyMh0hadiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567504608433?e=2147483647&v=beta&t=B-bDSL_rPrere8tpDUpS5kA83Q6Qyx4fQ69VIE1nWts',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nelson',
        lastName: 'Abreu',
        emails: ['nabreu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEq5us7IRho2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682636250721?e=2147483647&v=beta&t=rgyah1fQW91S8-97A76u3xqY7HPIi_590ldZDN3X7PA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Victor',
        lastName: 'Pozo',
        emails: ['vpozo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lextech: {
    name: 'Lextech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alex Bratton',
        lastName: 'ᯅ',
        emails: ['aᯅ@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG1auB7H1D4kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610817449822?e=2147483647&v=beta&t=6CHzbOvH0gZdGfq5pXwa7kUImwOo58oOGXnZen6Snd0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gary',
        lastName: 'Heusner',
        emails: ['gheusner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tom',
        lastName: 'Stoklosa',
        emails: ['tstoklosa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nelson Taruc',
        lastName: 'ᯅ',
        emails: ['nᯅ@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFYyQXEG2R__w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714776340763?e=2147483647&v=beta&t=MjFY0ScqQRnwl8SXqRgjuNXa34z3aUjUwZVH9Z5OanY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aric',
        lastName: 'Roush',
        emails: ['aroush@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE-UKPb-KbY5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516887065011?e=2147483647&v=beta&t=vAc33TmKT6I_mnXlVDhGMyKmh-ZsFq9PObVgVhq0yoU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  guidebook: {
    name: 'Guidebook Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Casey',
        emails: ['ccasey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHv5b6gYxTYCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517692820957?e=2147483647&v=beta&t=ig_zxnz-8Ks4Z9I-pMg2NbjlpPL0FK9uwZFSTaTwXTI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jack',
        lastName: 'Tattersall',
        emails: ['jtattersall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH4inH2UDPT4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726657694723?e=2147483647&v=beta&t=nI_WYu8K17ZGQZAqlo4ketufLadK6ptqx-OAYJEIBjs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marc',
        lastName: 'Mac',
        emails: ['mmac@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Lewis',
        emails: ['jlewis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEnTlZyVwpIWQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698895900263?e=2147483647&v=beta&t=H2L3Z1f3IbTbg-DWO4A3s9vekGjhjIclzjn598ExH6I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kiffany',
        lastName: 'Gardner',
        emails: ['kgardner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGwzx5hZUSPKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516527560616?e=2147483647&v=beta&t=MrS8moWsmX6EzF4xnIGIsZxKkDDMLIMnYCtEwAJDGM8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cashedge: {
    name: 'CashEdge',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bastin',
        lastName: 'Gerald',
        emails: ['bgerald@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHgzsYhlvzv2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516263290668?e=2147483647&v=beta&t=LLtRJpUXBeYKgyUjp360f18TidIKwvj0osaHNiRma24',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sanjeev',
        lastName: 'Dheer',
        emails: ['sdheer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Madhusudhan',
        lastName: 'C',
        emails: ['mc@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hemant',
        lastName: 'Jalagekar',
        emails: ['hjalagekar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE0itAZQj0X0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553745540659?e=2147483647&v=beta&t=ac-z3D5VnZMXR9G4ELBZG8ioS-JLu7CFFVTHi4YK7aQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Loga',
        lastName: 'Karthi',
        emails: ['lkarthi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rmantras: {
    name: 'RMantra Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kusuma',
        lastName: 'Rao',
        emails: ['krao@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHsC2-fYZJG8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610306492533?e=2147483647&v=beta&t=ldzUcalIxkH6iYydhR3p4i7tnl8nCFZZmG3Z4u-nrzA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kimberly',
        lastName: 'Mount',
        emails: ['kmount@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHoQWHoKJunjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726170500669?e=2147483647&v=beta&t=dLpj0Xcus06S7a2Jg50zJ192tg4dFf6n75CbQsHfOd8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christina',
        lastName: 'Lazar',
        emails: ['clazar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQELxtUa09vBwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621524931386?e=2147483647&v=beta&t=DAA2BvTNmF7O9fMe7Q-aCY21n2VYnomAf7cIfu3jDoo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kyle',
        lastName: 'Grove',
        emails: ['kgrove@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Breona',
        lastName: 'Butts',
        emails: ['bbutts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHYsNy98WD8sg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703346144923?e=2147483647&v=beta&t=TMWeBqO8iM5i0MHiEH-uahEJyjz9scNOFAm3SEBft3Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  iitdgroup: {
    name: 'IITD GROUP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jentis: {
    name: 'JENTIS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  blackinkit: {
    name: 'Blackink IT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Caleb',
        lastName: 'Johnson',
        emails: ['cjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGgyQzE4hcYPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713615293506?e=2147483647&v=beta&t=VXmHBUA64Wt-sQXZyVMXfaGENK0iLuhQWInG030fD6Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Doug',
        lastName: 'Allgood',
        emails: ['dallgood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHW12UFqRh9Zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516271507943?e=2147483647&v=beta&t=plLIP6RhZYu-fRbwqL95JS7hWBFmEE6yAFDjNRhwvnQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Nelson',
        emails: ['cnelson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFArTk_uGT4BQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632942495710?e=2147483647&v=beta&t=362qzkjsdIbu25XOW-0Sj_o8Bud8kRgwmf7VtJDvQvc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Deandra',
        lastName: 'Rodricks',
        emails: ['drodricks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGd4HsFmp0P0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669436745113?e=2147483647&v=beta&t=uM29eR2TPRJH4SiOnVcLnrZg7yIYWCAZ8xuaRn6YgA4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Maya',
        lastName: 'Caldwell',
        emails: ['mcaldwell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEywRPYTc0yhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657545311783?e=2147483647&v=beta&t=r2Z1vNKnrwEqb1hZidPtpBpYC7u7JDJ13m07GmlwMpQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tesTec: {
    name: 'TES 宸展科技',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gili',
        lastName: 'Omri',
        emails: ['gomri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGRBN08Hug3yw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516254807587?e=2147483647&v=beta&t=_0mDP2iX_MaQ2N0nexn34Vj57zQOEJvWVJGWo79ZvUo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tes',
        lastName: 'Tec',
        emails: ['ttec@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'kirsty',
        lastName: 'Hopkinson',
        emails: ['khopkinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHD-XeHsy_mKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682678693382?e=2147483647&v=beta&t=cD5yjn7x_JiUv62MeF0CCjgej8M2S7Wvhr8GrcFSE5g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Rhodes',
        emails: ['jrhodes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHVl8hRuTOjDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720471569198?e=2147483647&v=beta&t=QFqeGLyF-ETTs5OWE5jNcJmeAn-8EVowcpBuctPA6jY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tec',
        lastName: 'Tete',
        emails: ['ttete@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  peerlessnetwork: {
    name: 'Peerless Network, an Infobip Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jim',
        lastName: 'Brewer',
        emails: ['jbrewer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lisa',
        lastName: 'Neimark',
        emails: ['lneimark@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHY6Mf4JAWVcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723650430364?e=2147483647&v=beta&t=Q6TkncPO4ilXFN-jsAiUVDuJS04EgVLbZkehQh-0efc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Barnicle',
        emails: ['jbarnicle@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Susanne',
        lastName: 'Hite',
        emails: ['shite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFBGWXytuSKGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589472139120?e=2147483647&v=beta&t=pgF9rhA9H3dsyaeIHvcOoqfnZaGW-cr41bzznHuT3g8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Deon',
        lastName: 'Johnson',
        emails: ['djohnson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nterra: {
    name: 'nterra integration GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Thorsten Bumb – Senior Consultant – nterra integration GmbH',
        lastName: '...',
        emails: ['t...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hamed Samadzai – nterra integration GmbH |',
        lastName: 'LinkedIn',
        emails: ['hlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHxzBmjeFmb2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517076296203?e=2147483647&v=beta&t=z-JIJsxbGNAybAWG8FDTYUIiWRQ21Koa5zqpVyUUP5k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Heiko Stach – Director – nterra integration GmbH |',
        lastName: 'LinkedIn',
        emails: ['hlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEsEa1XAfGM4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622120990934?e=2147483647&v=beta&t=rBXtIJvRT3npxxcH4cRZTKP3YcNTR1XwEZVMpFf3JhM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Sebastian Lapke – Senior Director Marketing – nterra integration',
        lastName: '...',
        emails: ['s...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHmM7qblUoXhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1685567348239?e=2147483647&v=beta&t=OHkyyNK43ahg2y75GLcwgrVXplg8WL48yhdZVb-u2t0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alexander Harms – nterra integration GmbH |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHJuPD8D7UTJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680772928214?e=2147483647&v=beta&t=OExI2Ut6thGVTkuFIxzZAKAnxLubYyPcgsTm9t1za3U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  squaredatalabs: {
    name: 'Snippet Commerce 360°',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Reju',
        lastName: 'Koshy',
        emails: ['rkoshy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHNcc-jzByxeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722872810719?e=2147483647&v=beta&t=syayfqyhR653jeHSppmtC49r2Kz3atmlaYS0WeeTiZk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abhinand M',
        lastName: 'C',
        emails: ['ac@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG2IIBecf5pNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704454934339?e=2147483647&v=beta&t=5vgn5jG0xxc3Kwvt6de_yWPTwNG073q4JrCD5fc90Ko',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jimilit',
        lastName: 'Sebastian',
        emails: ['jsebastian@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Arya',
        lastName: 'Girish',
        emails: ['agirish@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rachitha',
        lastName: 'Ravindran',
        emails: ['rravindran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH7zRKVLqOCAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719013565310?e=2147483647&v=beta&t=BOnntEOBJ0y75MBlfEwNJgssdZyhJOj6ZwsPR3415Pw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  canopyone: {
    name: 'Canopy One Solutions Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ravikumar',
        lastName: 'Danda',
        emails: ['rdanda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGYTSccIRTE1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723494788151?e=2147483647&v=beta&t=CzrrKChOQpEwmg-fVLRs47PfkGvkTSelCc0vzrKbCe8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mohd',
        lastName: 'Ansar',
        emails: ['mansar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFrR6YXUFPLIA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701969914428?e=2147483647&v=beta&t=eMQAP3dJ4e_w6DyWU5z28YpIWzl42ZokilwfT4UmJ0Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Karthick',
        lastName: 'Raja',
        emails: ['kraja@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG6oiM3GmQPFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643012934902?e=2147483647&v=beta&t=XrDmCBKUJNgnYzahYM6lkSuV5y8fyqfuARBO_zYzruQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ifthekharuddin',
        lastName: 'Mohammed',
        emails: ['imohammed@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ramya',
        lastName: 'Sri',
        emails: ['rsri@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  unitedcallcenters: {
    name: 'United Call Centers',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Balázs',
        lastName: 'Kopcsó',
        emails: ['bkopcsó@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFHmPbc8Jz8tQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679567071258?e=2147483647&v=beta&t=p9s-Li9kzNOfdxpQjg_GKJDUBTP6RXYzmTB90Ym1UMg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Orsel',
        lastName: 'SANI',
        emails: ['osani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFA32JkrBuz8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701728089248?e=2147483647&v=beta&t=vbSt_30Mr0AAWHUib1ZLWuw-TtPw_dASZQ8O-Ohspo0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nikolett',
        lastName: 'Finta',
        emails: ['nfinta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGBWdafloTIsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727035340607?e=2147483647&v=beta&t=H5a0WGgzmITi1gGpMgVz856W8eKQ2meEV55MYk6HcP0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daniele',
        lastName: 'Cona',
        emails: ['dcona@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH9wPaawQ2VLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633979466342?e=2147483647&v=beta&t=G7sxZ5ZdHaAv7GDJNgzO5U3qdelk5O7-C2An_nL7Bms',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Manuela Vidinique',
        lastName: 'Tafock',
        emails: ['mtafock@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHhi_gt6iNbBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689096761056?e=2147483647&v=beta&t=qG2ygNSWIcd0M72ZOEr38BPaODS7ARnL8J16CNEmXF8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  flyhighStaffing: {
    name: 'Fly High Staffing',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Fly High Staffing',
        lastName: 'LLC',
        emails: ['fllc@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Udayasree',
        lastName: 'A',
        emails: ['ua@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGpqjBjynah8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659588808406?e=2147483647&v=beta&t=YzWLr69B_fqtyGAX5LHP4xIadcG40f6qD3yfMATyuNA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Saideep',
        lastName: 'Reddy',
        emails: ['sreddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHqXjN8sHd_wg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696967335129?e=2147483647&v=beta&t=vPZoVrXb_oBCT1lZlUCaOxHcKQGqlLbcHouLne-yAiM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sai Teja',
        lastName: 'Reddy',
        emails: ['sreddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFTgg5GE44skQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700239515959?e=2147483647&v=beta&t=XyinoFtB5EaYZ8oU_tZwETLfPlAbvjJD0YLcK2qeJr4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ramitha',
        lastName: 'S',
        emails: ['rs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEe4Pd4eO5Rsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644252954177?e=2147483647&v=beta&t=4MCZOWnot73sBVRhgxX_sHaQt8ikw4tFkscDs69WOFE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  together: {
    name: 'Together AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'ROMULO',
        lastName: 'OTERO',
        emails: ['rotero@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kevin',
        lastName: 'Iverson',
        emails: ['kiverson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEbM2kFYC0Y4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516248655721?e=2147483647&v=beta&t=5nm3VLS55tOgA2ykLcPiiIrQKILytG5dIzvZvlRr6vs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rebecca',
        lastName: 'Krauthamer',
        emails: ['rkrauthamer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFo12-8FIhsSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597949935868?e=2147483647&v=beta&t=TXFxFB0EENfRHAsOGBYaMms5TNytahlmul7OcKeshAI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sharon Zhou,',
        lastName: 'PhD',
        emails: ['sphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFJPYiPl9U4Ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652407227345?e=2147483647&v=beta&t=A5iQIVJU9ePpPOApEXB8I94z-OOL9YQesRgMoLbJvsE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Delmy',
        lastName: 'Reyes',
        emails: ['dreyes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEHWCtC0zlVAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516777823367?e=2147483647&v=beta&t=nLi1CHszCIhUp0jTauJWiKxAD6cd312kVJhIZspL2gY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  towerhamlets: {
    name: 'Tower Hamlets PCT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Panizzo',
        lastName: 'Enrico',
        emails: ['penrico@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam Stern MBBS MRCGP',
        lastName: 'DipIM',
        emails: ['adipim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Funmi',
        lastName: 'Popoola',
        emails: ['fpopoola@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'iyabo',
        lastName: 'giwa',
        emails: ['igiwa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'olushola',
        lastName: 'Ajao',
        emails: ['oajao@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEwvv377-nCJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517589552833?e=2147483647&v=beta&t=piSi8r6HeTrukEvWDfkEbPKy4v3xkU8OeBrtav3CWqE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mdland: {
    name: 'MDLand',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rumpa',
        lastName: 'Giri',
        emails: ['rgiri@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel Kraft,',
        lastName: 'MD',
        emails: ['dmd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEZNgucwxdQ0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1539483937648?e=2147483647&v=beta&t=29_AoMr9a2G_tlEFRDF0dUcmZCbh3h6jGy8qTSkpbnI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ami Bhatt,',
        lastName: 'MD',
        emails: ['amd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shaalan Beg MD MBA',
        lastName: 'FASCO',
        emails: ['sfasco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFYXrZ529GVkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708227941972?e=2147483647&v=beta&t=b39qH4GgR4u4GU77A-bJzeBuZtX7aXDimMfjE7q7I64',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matthew Lungren MD',
        lastName: 'MPH',
        emails: ['mmph@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHs0YGUV3_hOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688845962440?e=2147483647&v=beta&t=ic1cXizG5mLw87FQD6KBOTMky-QRqQo9PRzTzXJ3DgM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  livefibre: {
    name: 'LIVEFIBRE CONNECT PVT LTD',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rachit',
        lastName: 'Gupta',
        emails: ['rgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG-URRbgrEQ-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679817166643?e=2147483647&v=beta&t=6NldhLGyrawZTE8DJGfzY9SgdHRPtjH7AefKrsUJv3M',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tamanna',
        lastName: 'Dutt',
        emails: ['tdutt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Hemkant',
        lastName: 'Patil',
        emails: ['hpatil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHr-fE1aF3qFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711984266158?e=2147483647&v=beta&t=2baURcRv9WbG-BEcQOD-g9BH54HbBRaYoC7SA7rTWq4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abraham',
        lastName: 'TM',
        emails: ['atm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gajendra Singh',
        lastName: 'Shekhawat',
        emails: ['gshekhawat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG6OmYbAYFN2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684926599431?e=2147483647&v=beta&t=wKpIQJGIxXhFK9WcullOuO6YJ8OAbRiwcm5kZWeKw8k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  egerie: {
    name: 'EGERIE',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Grégory',
        lastName: 'Meunier',
        emails: ['gmeunier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGBNTRO11dayQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588349556768?e=2147483647&v=beta&t=92boGRbnR6aVGvhxruzoFJ8gVcGhx9-DlAbhAAcPJYU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pierre',
        lastName: 'OGER',
        emails: ['poger@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Adrien',
        lastName: 'M.',
        emails: ['am.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Victor',
        lastName: 'Richert',
        emails: ['vrichert@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGoLtH3XGBcog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572954650260?e=2147483647&v=beta&t=REfTrcL3hC2tNxFOjYl-8ba8WlsbEmooUOIQfu6pfrs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christina',
        lastName: 'Lacombe',
        emails: ['clacombe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFGqKtOJMsUwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656703026672?e=2147483647&v=beta&t=CUMCClbu2XOmog2xlR5L1_Pbqo9j3jnNAovMemwV1vY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  usmcMccs: {
    name: 'MCCS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anthony',
        lastName: 'Cisneros',
        emails: ['acisneros@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Segarra',
        emails: ['dsegarra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE8i-wPmh6R6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723770597893?e=2147483647&v=beta&t=g4wsYjhqha1FnQ9JV7XSKg2jSfwCr2QbJ4ROtDPK21Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nihar Chhaya, MBA,',
        lastName: 'MCC',
        emails: ['nmcc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGYqSmsCfbsdw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1593482114669?e=2147483647&v=beta&t=BEAq0Ekt7PBntfoKp-JupqgzjufVocWV13o-rfKpF-s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Edward',
        lastName: 'Cutshall',
        emails: ['ecutshall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGm9kpCbU4F-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730575578430?e=2147483647&v=beta&t=Czbq1jR87FRFYN7rlenlGKpCsDoW4SK7gVhSb3-nyo8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kimberly',
        lastName: 'Johnston',
        emails: ['kjohnston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEaI2t8iwqYlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517573847205?e=2147483647&v=beta&t=qUer3LBR4TJHRxbogtIvxM56pzsPR6jiLuUSXdOfHsY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  siyanainfo: {
    name: 'Siyana Info Solutions Pvt. Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shashank',
        lastName: 'sharma',
        emails: ['ssharma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGgFNrFh8GVgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713183669600?e=2147483647&v=beta&t=80_zhupeD-63YSHtCYxuZoqFRT5cS1k_QdUnSI1ZA3Q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sanjay',
        lastName: 'Ameta',
        emails: ['sameta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHrFl4EiYBf6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516961194896?e=2147483647&v=beta&t=LyjysXgKO_x5w5f4i_kDk0CPOfUwHfpCypKZMHYB3EI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'varsha',
        lastName: 'eevansh',
        emails: ['veevansh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sujata',
        lastName: 'Nayak',
        emails: ['snayak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGou02x6_vMEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660157816219?e=2147483647&v=beta&t=suObGe50_oRm88_Nue-Pw9mpKCRozwecYt9lAVi2uPM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dasharath',
        lastName: 'J',
        emails: ['dj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE973BX3NEdvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713562571110?e=2147483647&v=beta&t=R9FH63JF8cc-HzBfmS4TqYHjhS44xuglAjR3NgJXZKc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  diamanti: {
    name: 'Diamanti',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dafni',
        lastName: 'Diamanti',
        emails: ['ddiamanti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHFgHsYbIP4Rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669040391452?e=2147483647&v=beta&t=_l7erauvFZgysqq2DR-myCFjIjYxVajrzPofZk7BXbo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ron',
        lastName: 'Gill',
        emails: ['rgill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEEVEsp7xYtLw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682183780168?e=2147483647&v=beta&t=-DujL8d-t9K4vjxRO7q4FCTrM_PkkIvEg-kdpfTxXhY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Arnaldo',
        lastName: 'Perez',
        emails: ['aperez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eleni',
        lastName: 'Diamanti',
        emails: ['ediamanti@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Violet',
        lastName: 'Diamanti',
        emails: ['vdiamanti@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  incountry: {
    name: 'InCountry',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Peter',
        lastName: 'Yared',
        emails: ['pyared@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGa3uqUwr9sMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608802990866?e=2147483647&v=beta&t=itcPqS0F1a-KclsADIuUH4xvgEqJ4-AxqgAIhG5znfM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: "Renne'",
        lastName: 'Devasia',
        emails: ['rdevasia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF-Vj-7Aa6A6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592886766795?e=2147483647&v=beta&t=OaddWFKBvIYgRThsV8GCbXB3mUBN4d84BCLivVhNpQM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vic',
        lastName: 'Campbell',
        emails: ['vcampbell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGDYW9EhIBQpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516280467148?e=2147483647&v=beta&t=shIEBX0KRO7Hz7GzfKzzFjyn_XSZ5QXdSYRnH_fz3Es',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vlad',
        lastName: 'Rykov',
        emails: ['vrykov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Karim',
        lastName: 'Hopper',
        emails: ['khopper@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFLwX23-qzy_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718190252255?e=2147483647&v=beta&t=yq2Kb9Js76bxHYSD8k_1C2CGbwpK3-RHwCRie6dhUFc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  thinkperfect: {
    name: 'Think Perfect',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sumit',
        lastName: 'Saha',
        emails: ['ssaha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE7Rzeb83fjAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1534479561181?e=2147483647&v=beta&t=sOvzbrMINH9FYnkLfhbHucSgSl1VV4BAxgDlO__kVhI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Wendy',
        lastName: 'Abbott',
        emails: ['wabbott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFloKQyO7lHwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516765678643?e=2147483647&v=beta&t=bFv0wzVp_Byc9HJ0GFrG-VzOJP6gqOKIU2qH4u9INn4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Arunodoy',
        lastName: 'Ghosal',
        emails: ['aghosal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFyKO_lRcWSeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668485971132?e=2147483647&v=beta&t=CSci6AGXGwzr8EzV30yfoo-OAXVMMTwx_Qe-ThysI2c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alok',
        lastName: 'Tamhankar',
        emails: ['atamhankar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEVvXqaK5xW6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629975967512?e=2147483647&v=beta&t=XGfJO289-8ygVbVq8i0b2J2AOkSW-ukJ5eKXnyedIKY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris',
        lastName: 'Mendes',
        emails: ['cmendes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEyDx7beK3dWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675702400660?e=2147483647&v=beta&t=mHzq9EhfVIcdQGebQwZVQ2G2X8DNwsN-Ya047NHi-_Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  osivision: {
    name: 'Osi Vision, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dave',
        lastName: 'Henderson',
        emails: ['dhenderson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Benjamin',
        lastName: 'Truwe',
        emails: ['btruwe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHhipKNLvjh8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516787315870?e=2147483647&v=beta&t=oiLKjojTRsR_11qsy9tJWY4NDA9HbprAlJClQHMs1Ug',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Linda',
        lastName: 'Guinn',
        emails: ['lguinn@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brian',
        lastName: 'Krauss',
        emails: ['bkrauss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEdymulz8i9Hg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517661459949?e=2147483647&v=beta&t=yHW7KX-Qe9XW6Df2R9OSRq94nGXay1I_uly5vONH3Ac',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tiffany',
        lastName: 'Combs',
        emails: ['tcombs@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE4uVSu4DxM5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710885029274?e=2147483647&v=beta&t=qtNxiuWIALXO67qeeZeEYTD-q0Po1kMP2X3UaGR1Fak',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '2isolutions': {
    name: '2iSolutions Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Praveen Kumar',
        lastName: '(Tyagi)',
        emails: ['p(tyagi)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE7-R7IgK9yyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1587943978974?e=2147483647&v=beta&t=LN3bqoICeEeiJYILP-TSaaku2PkFfHQwkv35m7ebWrs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rajesh',
        lastName: 'Chaubey',
        emails: ['rchaubey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHfOdmddpDMEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516503214854?e=2147483647&v=beta&t=5XmYv-okS8ySkUsJ0KnpBrSzY5FrwAs31GrcNhdqmkU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mike',
        lastName: 'MacDonald',
        emails: ['mmacdonald@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHwnpHZRC5nUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561049343048?e=2147483647&v=beta&t=XhkHlgMKYp0teBO8PVpQf4Zwgn1Ad7KYE--eAncBQHA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sandeep',
        lastName: 'Tyagi',
        emails: ['styagi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rakshit',
        lastName: 'Shetty',
        emails: ['rshetty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEyh-aOVcj3zw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692170323815?e=2147483647&v=beta&t=JZgYQf7IMlRMl9gKqGwtVgks3tHd6mDJE9IHQkQ0cnk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aviznetworks: {
    name: 'Aviz Networks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Naresh',
        lastName: 'Kumar',
        emails: ['nkumar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Khurram',
        lastName: 'Khani',
        emails: ['kkhani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFXCIEIHwNM2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675660481504?e=2147483647&v=beta&t=uHHUh1b4V6P5zQ0v7r5HHgmtFiZVMG4QzwCyXb9opsI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Amit',
        lastName: 'Tomer',
        emails: ['atomer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEeHEpIC2acqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1540245218498?e=2147483647&v=beta&t=wQhAdNNZSraQT8GreFY0PvVgHXmXdiMW1TmqufJOzno',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vishal',
        lastName: 'Shukla',
        emails: ['vshukla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH3D1p3mwQ69A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664739180280?e=2147483647&v=beta&t=sRxky2agHO9r1nIa4ldHOjWi-_1hYdVTp-FOEvExTJ0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chidambaram',
        lastName: 'Bhagavathiperumal',
        emails: ['cbhagavathiperumal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  integriti: {
    name: 'Integriti',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Scott',
        lastName: 'Burnett',
        emails: ['sburnett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH4jP2nOOqmEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549655481495?e=2147483647&v=beta&t=8Euc6B_YEoUkgAr1kLceYt0UfqjId9dXAq6kONn1KNY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Steven',
        lastName: 'Libman',
        emails: ['slibman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH-L3juIfaGSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567624696177?e=2147483647&v=beta&t=XoXORYGmg7vZTHEx-OSEtdeerPD87nGLmcNPOAatcHQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrea',
        lastName: 'Crabb',
        emails: ['acrabb@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHxHKyTNyxpoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714668857397?e=2147483647&v=beta&t=4EgI6x4d6m-FGL6WzjQPvxyuj0GS7jdECLxoP3_tTls',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Samuel',
        lastName: 'Ruiz',
        emails: ['sruiz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matthew',
        lastName: 'Williams',
        emails: ['mwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEuo84iFIs-kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611725180827?e=2147483647&v=beta&t=aeYX4zZShTtlM8oALYGjtCuaTRny75vIaECCKljAKEE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pecan: {
    name: 'Pecan AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jamie',
        lastName: 'Bomsztyk',
        emails: ['jbomsztyk@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Barker',
        emails: ['dbarker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFrsdvHh7Ssxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725438717258?e=2147483647&v=beta&t=FM3H5g1DSI-IHO_kEu2Jq3RCwC6_yJAw7eERUKCvu80',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tomer',
        lastName: 'Meron',
        emails: ['tmeron@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGQb20c6je6mQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1562439747260?e=2147483647&v=beta&t=emYGsuoKQvqXCm0kIEecgkO1loS8HpcJmEWDUEMAKUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Zohar',
        lastName: 'Bronfman',
        emails: ['zbronfman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFXlQSGPlKW2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643708099833?e=2147483647&v=beta&t=0dlTUH5S8d_zk9oqOnTnkj-kXDkw6bqIfur0zrgSKjU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rotem',
        lastName: 'Yifat',
        emails: ['ryifat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH3enn5zUkXkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558343939672?e=2147483647&v=beta&t=V0jOiHKTPH-sqJM53XEqgmqPfTdAN3u9-e0MeB-QfX8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  system73: {
    name: 'System73® - Powering Profitable Streaming',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  restSolution: {
    name: 'REST Solution',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gryphon: {
    name: 'Gryphon.ai',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michelle',
        lastName: 'Tilton',
        emails: ['mtilton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeffrey',
        lastName: 'Fotta',
        emails: ['jfotta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Neal',
        lastName: 'Keene',
        emails: ['nkeene@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQESFYDOW0mXVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682533878169?e=2147483647&v=beta&t=J5uUkuypiGi96rhH_dPnb5y3gsiFI3T5Tote9JJkpTs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'McKinney',
        emails: ['mmckinney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nicky',
        lastName: 'Roberts',
        emails: ['nroberts@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG1qLO0fptmNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715614505842?e=2147483647&v=beta&t=AUvX-W6tNOBf5i0O76iBo_z1TcCjmzUD7XNZAaynlcI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cloudsquare: {
    name: 'Cloudsquare',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sudha',
        lastName: 'Kuchibhotla',
        emails: ['skuchibhotla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kellen V. H.',
        lastName: 'Silveira',
        emails: ['ksilveira@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Swati',
        lastName: 'Prasad',
        emails: ['sprasad@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Girija',
        lastName: 'Bhunia',
        emails: ['gbhunia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFtawrMos2a2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516328726785?e=2147483647&v=beta&t=sB5a8IG3CnuhM2TwdEU_Dn6tiYuppok06hrAOUKauE0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Praveen',
        lastName: 'K.',
        emails: ['pk.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFnHrxAE8af5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714614741033?e=2147483647&v=beta&t=KdQpSnaJfNsTck3eoviiWOJJa0b8LcAs-KINIq-BE24',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mindstormstudios: {
    name: 'Mindstorm Studios',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Babar',
        lastName: 'Ahmed',
        emails: ['bahmed@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sameer',
        lastName: 'Rizvi',
        emails: ['srizvi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFYs9HwAqJPWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584242664999?e=2147483647&v=beta&t=U0w3Cd1rsUAQeYNU3auLfpxShhZ_T_4_Nl7mFkUnAfo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Muzammil',
        lastName: 'Bashir',
        emails: ['mbashir@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Muhammad',
        lastName: 'Mughal',
        emails: ['mmughal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFoTFsE31SGzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718447630829?e=2147483647&v=beta&t=syUSEQEo7iK6oTkT5PXnl1LGvwScDvd0EuewlDeMAIE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sabeen',
        lastName: 'Arslan',
        emails: ['sarslan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rbmsoft: {
    name: 'RBM Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chad',
        lastName: 'Towers',
        emails: ['ctowers@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'RBM Technologies (P)',
        lastName: 'Ltd',
        emails: ['rltd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEMzTirAvz-TQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516961881867?e=2147483647&v=beta&t=Azj7i9MrnMpod1o_IoPCkE12d_AF0QXMHSeB9ilh-9k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Neha',
        lastName: 'Singh',
        emails: ['nsingh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mayuri',
        lastName: 'Mali',
        emails: ['mmali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQETp0rF4BUDvA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723358320878?e=2147483647&v=beta&t=0nJPVA89ZfGtoaWBuMkRi9dMa9GGNfqMNnDBs6V1-WE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rakesh',
        lastName: 'Gujrathi',
        emails: ['rgujrathi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGmt044Cbv9xw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708018829728?e=2147483647&v=beta&t=9BGdxJ_mLF1YAhQJNVJt5TAzZlOMTx4ij-PblsjVMCs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  neocol: {
    name: 'Neocol',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Drew',
        lastName: 'Adams',
        emails: ['dadams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHVHOpiv-j8Xg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606833474566?e=2147483647&v=beta&t=-yJpMPKKs5hhEvzRJSEjGmF2LERxR9YuTShiWPDS9EA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ryan',
        lastName: 'Lott',
        emails: ['rlott@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tom',
        lastName: 'Knauss',
        emails: ['tknauss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGZUFa235Tofg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1537214723707?e=2147483647&v=beta&t=S-kic4uxbQ-qdOsy5-paDKOR0IEg-KaTRXAiLJjD3UU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jenn',
        lastName: 'Meehan',
        emails: ['jmeehan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lizzie Starck Williams,',
        lastName: 'PHR®',
        emails: ['lphr®@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFHU2Sqd2w5SA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705355099210?e=2147483647&v=beta&t=Zf-iRwzfixINbqNskiJYKh4_NkB1ScA1alg434fP3bs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  scadea: {
    name: 'Scadea Software Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rishabh',
        lastName: 'Behal',
        emails: ['rbehal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGYlLfcJ6RB3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678775788677?e=2147483647&v=beta&t=Z0yhYL3gn9gbvO-t0nkqAQyaDzw4uIRfGucaOxtwoHY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Balaji',
        lastName: 'E',
        emails: ['be@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sharaq',
        lastName: 'M',
        emails: ['sm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGIGA9UB6lx3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1659042822589?e=2147483647&v=beta&t=wf9jlMVr-Z4Gi1e3lfx1-Um4JjPdlqqFC9jLzNe4CO8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eli',
        lastName: 'Kane',
        emails: ['ekane@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Harish',
        lastName: 'Rachuri',
        emails: ['hrachuri@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cathedralcorporation: {
    name: 'Cathedral Corporation',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'John',
        lastName: 'Slaney',
        emails: ['jslaney@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Fred Van',
        lastName: 'Alstyne',
        emails: ['falstyne@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Laurel',
        lastName: 'Mielcarski',
        emails: ['lmielcarski@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'George Mierek,',
        lastName: 'Jr.',
        emails: ['gjr.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGw1S2nfZiKow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517471906771?e=2147483647&v=beta&t=SD-qDYX9XcezJsiHXXFBdpt8lEKY3b8zF0d5dU47_Ew',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marianne',
        lastName: 'Gaige',
        emails: ['mgaige@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEB37BA6GFYBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561860195977?e=2147483647&v=beta&t=Z_9Vv8N0WE4RWjwFW0BrcW9QAPISJhk2-leWYNixHG4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  daSystems: {
    name: 'DA Systems Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Upton',
        emails: ['dupton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFQDtNqgOuXEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718239426347?e=2147483647&v=beta&t=rjGXa9HUFdIh1H4G79es2-Yrgk9NMY4UHHnaJECOiHI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ian',
        lastName: 'Oliver',
        emails: ['ioliver@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGWNF_JNE-LRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517715978693?e=2147483647&v=beta&t=Z27gmivGkXHJQvyMR19yXNMoQmU8f-yyFSzG1b6PPVc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Edward',
        lastName: 'Robinson',
        emails: ['erobinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEkkt3neMaWBA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551823519485?e=2147483647&v=beta&t=Bpwf-il-Q_oeiMZtldtAIaPlsh_he_ZpGPEUWDlcwSQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Travis',
        lastName: 'Wyllie',
        emails: ['twyllie@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carl',
        lastName: 'Fielder',
        emails: ['cfielder@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGLNNdCm9XCiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517550244526?e=2147483647&v=beta&t=4egv19V9l6DrzbgknvAUdYaQcEzCwtLG10imdZrz-6M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gridcap: {
    name: 'Grid Capital Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alexey',
        lastName: 'Sokolov',
        emails: ['asokolov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEmA9Uu1BqhJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704397060330?e=2147483647&v=beta&t=Ht4cdEEDfKkHhu18THYlXxCah0_ULGj_UuwK0LJnOb8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Arina',
        lastName: 'Makarova',
        emails: ['amakarova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFlZhnZ6dpwNw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695402137720?e=2147483647&v=beta&t=0-U_JCtMAfK-vBBzn2cJ4z65lJZb9oeYGCKQRgEJ_tM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alexander',
        lastName: 'Arjevanidze',
        emails: ['aarjevanidze@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHJgIA-QP1XOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699623037818?e=2147483647&v=beta&t=anc9vnX6TgsJnIaVwvotGrmp15wC_9vNjoalRO9ilLo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Damian',
        lastName: 'Miodek',
        emails: ['dmiodek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFAMFodgEHgzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702600589377?e=2147483647&v=beta&t=pogAwH-5PDmYi-bYOqbYU4JtnvG2M1gjG17IfiVBFnQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Peter',
        lastName: 'Christman',
        emails: ['pchristman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  combuilder: {
    name: 'Combuilder Pte Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Belle',
        lastName: 'Phang',
        emails: ['bphang@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chiang Jun',
        lastName: 'Ming',
        emails: ['cming@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFd755oQzFhxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517012770726?e=2147483647&v=beta&t=be2KNXq7rEPVFT-jHotskUEiGc2WYmBbJw6_vO-Wgko',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: 'Loo',
        emails: ['aloo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHGK0ryoYXeQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517554515464?e=2147483647&v=beta&t=SzUWLYmCrTcUGsetAOWfdIlSrWPCM6QzNfERt1g_mQQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kin',
        lastName: '',
        emails: ['k@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carole',
        lastName: 'Chia',
        emails: ['cchia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFFbgGf1wPSQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730126173347?e=2147483647&v=beta&t=ZtFWeBDXj_Xds3-VKO5O1jmbuTNhkcOsnnCvCAskPiY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  syncro: {
    name: 'Syncro Medical',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Patrick',
        lastName: 'Carr',
        emails: ['pcarr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dale Van',
        lastName: 'Aken',
        emails: ['daken@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gary',
        lastName: 'Berkheiser',
        emails: ['gberkheiser@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Russell',
        lastName: 'Hill',
        emails: ['rhill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFeGHxaDFzXPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516191352307?e=2147483647&v=beta&t=fvWvfZlkJsM7c_FBbIY-inWTgoM8cX4YrMCpM0Hrtfs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michael',
        lastName: 'George',
        emails: ['mgeorge@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEzO1gE4Pxmsw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516172180236?e=2147483647&v=beta&t=eMEkd2ituhHOz8cd6HW-F8s64QIwXrLKyr9lM9YAS8U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  weko: {
    name: 'WEKO',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  flexential: {
    name: 'ViaWest',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steven',
        lastName: 'Schwarz',
        emails: ['sschwarz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE21bY6uRSJ_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603734185639?e=2147483647&v=beta&t=0vM_nMqgtgrNmu6ALKOPoATo_mWyYkmQDWi1z84y0Z8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexander',
        lastName: 'Hondros',
        emails: ['ahondros@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFmvK8LHATD0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706811467049?e=2147483647&v=beta&t=FaHFSrO6eaDcjYPwETLx2OHE1qys0F9VWiIgH5GgW0Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alex',
        lastName: 'Boles',
        emails: ['aboles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHX7TpXgwCvXg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616703530809?e=2147483647&v=beta&t=J6z5qYLYtq0Et7WcIaL7DfsQwSb2Ej5PWcsjJqyT32U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Todd',
        lastName: 'Weiss',
        emails: ['tweiss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFHRqv_MaR0Gg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568591662003?e=2147483647&v=beta&t=Iys_hjkprRTC4_VdlTOnslPrMNjUVvUYhgFJlcfY7ok',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rodney C.',
        lastName: 'Boden',
        emails: ['rboden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFwCHKC4OjrHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665785075841?e=2147483647&v=beta&t=g4Dc_-6btqdhqTjDKrwkwFnk684lDAjL1gp23QWZZqc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hdsoftware: {
    name: 'Housing and Development Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cristina',
        lastName: 'Miranda',
        emails: ['cmiranda@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Andrew',
        lastName: 'Moffitt',
        emails: ['amoffitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG3IemRD8PZKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516830299217?e=2147483647&v=beta&t=pXqAwtqHjPC2fEbV5FSnQWaNG0ORY1IsmWGOL8AkW_c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Diosmar',
        lastName: 'Perez',
        emails: ['dperez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maria Elena',
        lastName: 'Miranda',
        emails: ['mmiranda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG04s1mjxWgKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621268818544?e=2147483647&v=beta&t=2p7kDkEz4IfGLidHuVqBoBItXEVqTVAYw4NVKnmoJZM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yenisel',
        lastName: 'Gonzalez',
        emails: ['ygonzalez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGNTh5iXgsJxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568907800660?e=2147483647&v=beta&t=omkZcSi7iss3hmNnslyrO3c5y7BIx_CCGUoYUcDWJH8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  enreap: {
    name: 'enreap',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Surinderpal S',
        lastName: 'Kumar',
        emails: ['skumar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Archana',
        lastName: 'Ghatpande',
        emails: ['aghatpande@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFfzab42At70g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1528716314451?e=2147483647&v=beta&t=O5LyVwJzTlESW5oN-Y5TlOb8gmh8qK7QrZbfHb9iMhw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kamal',
        lastName: 'Kaur',
        emails: ['kkaur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHipKGhXy9pEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551178455396?e=2147483647&v=beta&t=ZXpnXvHzMk6Isr5QSQOadyM2IJ-naiitXL2RgjVzseY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jaikishan',
        lastName: 'Daryanani',
        emails: ['jdaryanani@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Swapnil',
        lastName: 'Kosare',
        emails: ['skosare@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  exordiumnetworks: {
    name: 'Exordium Networks Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mohammed',
        lastName: 'Khurrum',
        emails: ['mkhurrum@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEuOuYnVd4oYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555950415575?e=2147483647&v=beta&t=97nMECdHKdxO1yW_lBVZa5iyRv2IwXnXLHTXni0ns3I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Murtaza N.',
        lastName: 'Jafferji',
        emails: ['mjafferji@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFTj6TCfvpSZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598714997834?e=2147483647&v=beta&t=4qO_53Db9EDAvorhDCp6OeD-47wBeftrHID4MiKg_ds',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andreas',
        lastName: 'Karakas',
        emails: ['akarakas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGoplPO1Og75g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603399386309?e=2147483647&v=beta&t=QYWsfuLLvPJqT21nOYc3Wj7rxlm6y7PAYX9xTDdUdw0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aliasgar',
        lastName: 'Hamid',
        emails: ['ahamid@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHFokWA8e2QOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622046557540?e=2147483647&v=beta&t=F0QB-jdiyoRb_ijcJ-yN7fPdfcoRIHocC1xVXLW9ZEw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dr Munira',
        lastName: 'Zahera',
        emails: ['dzahera@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rexav: {
    name: 'Rexav LLP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Xavier',
        lastName: 'Joseph',
        emails: ['xjoseph@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHZ8CBrHHjB7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611751481357?e=2147483647&v=beta&t=Oa65gZxgIJz0hXf5UG4G0IgkpjPin9GDa7Rs_ZhZIsQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jithin',
        lastName: 'Xavier',
        emails: ['jxavier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE_IDui6F7tzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726640026523?e=2147483647&v=beta&t=h_I0J-sYhL-cUGZPnkE-svXmyjaBzzv_I9yGhcHwj94',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rajath',
        lastName: 'Ramachandran',
        emails: ['rramachandran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFrmeq4LVyXhw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718230634005?e=2147483647&v=beta&t=X3clJWGzIhT4j8AhcUxcX2BcAgaviKfWmT1P7IK8Bx4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alex',
        lastName: 'Thampi',
        emails: ['athampi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEySvd1GQmJHA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665398697513?e=2147483647&v=beta&t=YJ4A6f91RrPsYoKG5GCNvBGxEHyKhgzgsTLFg-fiztg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abhijith',
        lastName: 'P',
        emails: ['ap@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEfIooydTLaJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704100011486?e=2147483647&v=beta&t=BdGoXjE125UL3hS3Oh_owcG7tLYjGRzoDxg5O1Y414o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vtccorp: {
    name: 'VISIONARY TECHNOLOGY CONSULTANTS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jay',
        lastName: 'Marshall',
        emails: ['jmarshall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFiABdP7kV9jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688669239635?e=2147483647&v=beta&t=bCOXA12koRmba5o0J1VlRbXpgrZTdLJiKKc67W0FlW0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robin',
        lastName: 'Popkin',
        emails: ['rpopkin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEcdULcrD0aDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668290382692?e=2147483647&v=beta&t=U_9WMCkll5ST6ZcYYUwYa--M_M2yEC9m2QaqNTgZMPc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tony Troy,',
        lastName: 'SPHR',
        emails: ['tsphr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHh0_F7_d11eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517742651820?e=2147483647&v=beta&t=-hjxWxUh3ruv8ITKqtY2gsCb3DLckPcKTSwBAYN2lgE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jay',
        lastName: 'Kerr',
        emails: ['jkerr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEq2nefGoFUyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516257584586?e=2147483647&v=beta&t=6VaMyiC0QGaNPXDdYKLzGhuEWBeGHdP7MTjfvtMHoDg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cameron',
        lastName: 'Blair',
        emails: ['cblair@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF8JJAibCLBXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683747953200?e=2147483647&v=beta&t=yS6aGHnjJpJdBp9V0dJWtLoLyo5o9UMD3u-YhcwtSTg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aboutthesolution: {
    name: 'About The Solution',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lisa Owens',
        lastName: 'Viani',
        emails: ['lviani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQE_KX638UeBQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516943829511?e=2147483647&v=beta&t=zoJ1v3gZ_i8kfs0M6qZoF1V671Ie9GsxmlXxSz_qQNQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dr. Angela',
        lastName: 'Holliday',
        emails: ['dholliday@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGX0Tpvyl9JEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690203801924?e=2147483647&v=beta&t=_Evj0F9frbJDlxPKQaER4tB42YfFX1iZINwmxkcu-X4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Linda',
        lastName: 'Metcalf',
        emails: ['lmetcalf@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGQYJOyImpjpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516575579920?e=2147483647&v=beta&t=EK2iN-L06qtgi5wkpbujNd_ftbWiUd_zw9JDpIrsaTM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gloria',
        lastName: 'Walton',
        emails: ['gwalton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGQbnTjmfvl4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617390544339?e=2147483647&v=beta&t=L2fYNHJqqm-u2097JvkhgDPGyHQhUxhVU7VLg2YycD8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Eddie',
        lastName: 'Satterly',
        emails: ['esatterly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE-xVe4rfT2YQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553032165116?e=2147483647&v=beta&t=eeN9t_xq2WCFF58dNRB3vl7caahn3cw5NvVVFuCFXJM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nero: {
    name: 'Nero AG',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Antonija Sakoman – Feelgood Manager – Nero AG |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHzYg093xBvXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663698511453?e=2147483647&v=beta&t=I0Cs4d_RXU_T5ufSDHHgQ9j1fm__eahdqShOeM6qTkw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David Tilleczek –',
        lastName: 'E',
        emails: ['de@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHidFEBUN2nsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595082687261?e=2147483647&v=beta&t=Aa3-N9ejbihPJP6RmjHceMJXsT45bLdcG9lT7YZmVTw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jürgen',
        lastName: 'Kurz',
        emails: ['jkurz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aaron Wenz – Software Entwickler:in – Nero AG |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Flavia',
        lastName: 'Fu',
        emails: ['ffu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFIdFNVazgT4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694675525704?e=2147483647&v=beta&t=0hXGRV9YYLtGxcVYLzSCv4Er0-TF9Tt0gXQRKOL-sGw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  datamaticstechnologies: {
    name: 'Datamatics Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Waqar',
        lastName: 'Baig',
        emails: ['wbaig@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGfxETurSEBkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609945750787?e=2147483647&v=beta&t=QGHFyMY2h2KlP5ZYtvGxCNV1DmHeNBMpUH1Jr25nYl0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cliff',
        lastName: 'Bugdalski',
        emails: ['cbugdalski@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Azfar',
        lastName: 'Rashid',
        emails: ['arashid@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG5ZRY0CAoJoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728200220716?e=2147483647&v=beta&t=q2h0Ts0MyftpkcBln8SkS7G-KCmiuvs0CarWBkSFijU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mitul',
        lastName: 'Mehta',
        emails: ['mmehta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Uzair Rasheed',
        lastName: 'Khawaja',
        emails: ['ukhawaja@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  elyxor: {
    name: 'Elyxor, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bob',
        lastName: 'Berg',
        emails: ['bberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGFY0WCTT-uQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522967596039?e=2147483647&v=beta&t=8_RAIe10ktfVDuwS1VCqxMfMcFhItPRI4n8W-2zhBgg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Carleo',
        emails: ['mcarleo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHiziiuLYDNqg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516361048602?e=2147483647&v=beta&t=Jj7hjQ5xFL-hC5zoVVd9rk8fLdPr11A-WqDhJPb0_pw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marc',
        lastName: 'Arbesman',
        emails: ['marbesman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF856Nq_Mwpyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718195303999?e=2147483647&v=beta&t=hG9ZOCEeznqZjHBoWnJNZkz635Jx21PozIVhSNXr7i4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brian',
        lastName: 'Earp',
        emails: ['bearp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEjQiV_YLyWQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555504046895?e=2147483647&v=beta&t=BI6NSTBVDg85aBhXycquqDy-BQ7PIB-95b8jYmN9iS4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christina',
        lastName: 'Schwam',
        emails: ['cschwam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHihldoQ1t4_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516781109784?e=2147483647&v=beta&t=LLyOSAQCz2TudYfF0jJ02tooyXn1SwfirkrsyQNeQLk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  firetide: {
    name: 'Firetide, Inc. - A Division of UNICOM Global',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Thejas',
        lastName: 'Ramashekar',
        emails: ['tramashekar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH--q_ZCJpugQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617563574869?e=2147483647&v=beta&t=F1OY5wfFWbDbzuBXXpM8C0su0uoNFk1vhlzKL4hcCE0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'James B',
        lastName: 'McCarthy',
        emails: ['jmccarthy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Arthur',
        lastName: 'Feldman',
        emails: ['afeldman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Craig',
        lastName: 'Bourdo',
        emails: ['cbourdo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Juli',
        lastName: 'Mallett',
        emails: ['jmallett@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  constellationhb: {
    name: 'Constellation HomeBuilder Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Milo',
        lastName: 'Anderson',
        emails: ['manderson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF9TxCAAZDLCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719008847317?e=2147483647&v=beta&t=oPadKhmZlvLQtw5z_ifOn2YYWRRP1RGzpyoLaXNkeQI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jono',
        lastName: 'Allen',
        emails: ['jallen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGvLVEhjMLTXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711396193094?e=2147483647&v=beta&t=gaFfHHBsON9nKqcr4Vv86ZZvXJy7ixhX_pjmeTnU308',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bob',
        lastName: 'Swainhart',
        emails: ['bswainhart@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sean',
        lastName: 'Wilhelm',
        emails: ['swilhelm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHtQdFNSirvGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603288081431?e=2147483647&v=beta&t=NTqtIV7HXn5aWL2x8jTiytoUXXY0sgE03h9abQSPGeY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Clint',
        lastName: 'Scherbarth',
        emails: ['cscherbarth@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jdc: {
    name: 'JDC Tech and People',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alex',
        lastName: 'Weisler',
        emails: ['aweisler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEewaHLSMcJTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710168064877?e=2147483647&v=beta&t=g5M4s4IDs8Pxh3iUS1jJRx1g4xXyRbWB7MtL2tidcT8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rachel',
        lastName: 'Abrams',
        emails: ['rabrams@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joshua',
        lastName: 'Mikutis',
        emails: ['jmikutis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFD6R1H5jxryA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516895182327?e=2147483647&v=beta&t=Dagmq9kl5zs9dhvfsmz5zYqJw6EWsXPI6nrsxdOvwZ0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Coen',
        emails: ['jcoen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHI3kyeoJYldw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517727103428?e=2147483647&v=beta&t=2sHSgIYXTKQpH_hKLFilVRXZ7ig87j_QoxYERBYhERU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Katarina Rolf,',
        lastName: 'M.Ed',
        emails: ['km.ed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGpK1DEAVypGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1601500328130?e=2147483647&v=beta&t=sMAtn590C-Oyv4FdMcPJ2kdsX43QTMOa1gELOTKUJG0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jobing: {
    name: 'Recruiting Ventures',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stuart',
        lastName: 'Sharpe',
        emails: ['ssharpe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHgEK4OywS77A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517628755389?e=2147483647&v=beta&t=KLNMss48kr6DyHGigmXFg9s28SxTZI0uufsYNitHobU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Caitlin',
        lastName: 'Sharpe',
        emails: ['csharpe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEhtwBO2bdwDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516860909809?e=2147483647&v=beta&t=VFbygzx60mBGlfvDTN5OICfw-ekwmTyk6Bg5if2k0go',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Janel',
        lastName: 'Benson',
        emails: ['jbenson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEofyoXZHth2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1536174287665?e=2147483647&v=beta&t=6RovnLezykF1CxCCBSt6qPjs52eTNbzbZlbfgD71gVg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tatiana',
        lastName: 'Becker',
        emails: ['tbecker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFR6h2PO6Nh6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726490397518?e=2147483647&v=beta&t=QrvpUGuaqPAXsQgRqeoKYJffBfhfxaLoc4s25HtH6GY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: 'Williams',
        emails: ['pwilliams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGdJZZR8-4KCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634158118915?e=2147483647&v=beta&t=2eHd9261R78xEH8_7j81NSpfwhn5A81jOM5FuZ0dE8g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pcsforpeople: {
    name: 'PCs for People',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Casey',
        lastName: 'Sorensen',
        emails: ['csorensen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG9qXa0LIw0mA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516273850216?e=2147483647&v=beta&t=QeFZ_6TqFrIQHYsdhppQ92d9FrdspGQVgzwcy20Nd-4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jackie',
        lastName: 'Fehrenbach',
        emails: ['jfehrenbach@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Samilee',
        lastName: 'Moody',
        emails: ['smoody@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHZynP9P1037w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704479498742?e=2147483647&v=beta&t=VCvW6XxEalUyh1shwF5U6B11h3OnDgKf3M3FOq1xXEk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joseph',
        lastName: 'Benson',
        emails: ['jbenson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFOTr4W163TkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684406823903?e=2147483647&v=beta&t=gNLubPGfdh_HLkOU1ezrnQs9w55wrt0miDFffl0Nq6s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Bloedorn',
        emails: ['pbloedorn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFteJp-CDgpqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705953053356?e=2147483647&v=beta&t=cEFyHTVFjc_E4ve9kB-TFn2ON3wnPdSw2au09phg32E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sx3Solutions: {
    name: 'SX3 Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Joseph',
        lastName: 'Smith',
        emails: ['jsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGK5jxCAb38kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643656774213?e=2147483647&v=beta&t=Qk9wICUmb6vz3k6s94aP7MP8T0tZTDUWwskY_EKrSAk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kimone',
        lastName: 'Johnson',
        emails: ['kjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEH6w_oJnuZFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658158864799?e=2147483647&v=beta&t=X2oUDoICMDM6vYDOT0g91hg5IWTdExb10FE1NLQdfwM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nyasha',
        lastName: 'G',
        emails: ['ng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFJjRTbFhr_GA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1709951189021?e=2147483647&v=beta&t=6SVExBVLpEEJ1aUSah7wcytaWdJe1FGhS91fEixQMqs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michell',
        lastName: 'Dixon',
        emails: ['mdixon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Franklyn L.',
        lastName: 'Ramirez',
        emails: ['framirez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE1wIO5svWOGQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664325579253?e=2147483647&v=beta&t=LOGTYDe9hrYdsMJMO9voxddg2YWaQ9B8IzdViJJJDtE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  globality: {
    name: 'Globality, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Seth',
        lastName: 'Catalli',
        emails: ['scatalli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGjqpgEkqSttA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516292386343?e=2147483647&v=beta&t=FarPo5K4oliYqI6KPB0AxyAEBMOvZhg-S7-kyqPSpl4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Keith',
        lastName: 'McFarlane',
        emails: ['kmcfarlane@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHnHvmn7MIlNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592355345334?e=2147483647&v=beta&t=_c41iKM6noTRpX0A7ITibaP9hArFZxNGs2UmFaGnsFM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joel',
        lastName: 'Hyatt',
        emails: ['jhyatt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFUdtZL5nR4IA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517002472339?e=2147483647&v=beta&t=H5blC3QIiwEJcyB__FC_9TFHFi0EGozEjkUtCRG8tGg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Vier',
        emails: ['jvier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFG0gbx-E8wyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586200513821?e=2147483647&v=beta&t=RQX1zDo3X0qwqX7TDwhlgmb7MW5yzkGqcujKdkwRDbQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sonia',
        lastName: 'Mathai',
        emails: ['smathai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHR9y-dTgBjjw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1626828117985?e=2147483647&v=beta&t=I6hJOViM7P_mfcBFWMm99NPLmHtsiZSn9z04T3Y3vME',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  itsf: {
    name: 'IT Solution Factor',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Patrycja',
        lastName: 'Garlicka',
        emails: ['pgarlicka@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFKFrfaxFL3qQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712307645313?e=2147483647&v=beta&t=scWfZRo3lxxTu7PIBwg9mo_7RJe6UCvAIWRdV81xWOw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mikolaj Wisniak – Vice President – IT Solution Factor |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stuart',
        lastName: 'Freides',
        emails: ['sfreides@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHHdGo_t8MH3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517749758885?e=2147483647&v=beta&t=Mpj0FwNETkRjObPmthm8qqsTF0FOIY-W_VzI2DJnR2A',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Dominik Urbanek – Junior Solutions Architect – IT Solution Factor',
        lastName: '...',
        emails: ['d...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ewelina Chorab – IT Solution Factor |',
        lastName: 'LinkedIn',
        emails: ['elinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHvG2ohKzXSDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597425946440?e=2147483647&v=beta&t=t8ZrQSCJq4FPMoQFuP_wN8u4ysKu9pvgOHbyBuJYj_k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  digitalinsightgames: {
    name: 'Digital Insight Games (DIG)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brett',
        lastName: 'Dishongh',
        emails: ['bdishongh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFeE8BCLq-IjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691010515951?e=2147483647&v=beta&t=RgUPfd6T9fLAMuE0lXGrfeLrO9p7n23ItLb_TwBgisI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jon Van',
        lastName: 'Caneghem',
        emails: ['jcaneghem@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHmDzsnBH3EYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553534432414?e=2147483647&v=beta&t=YfhGuu2TWo8T6H8cQRemU-LswXDZQFa1oQ1dibyD5kM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Daniel',
        lastName: 'Runyan',
        emails: ['drunyan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHKqRtoq4HvPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645751057021?e=2147483647&v=beta&t=woHafAqj-mb8csF9gyljqUcMGR72u8cM7EU7FSqzIT0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Won S.',
        lastName: 'Chung',
        emails: ['wchung@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'McDonald',
        emails: ['amcdonald@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF0ZkOnFehzgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718396207146?e=2147483647&v=beta&t=WuXYE3ByuGTJs9zOtkNq6QdkbastyKyNlvmmVxgUMoU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  boundaryless: {
    name: 'Boundaryless Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Erik Gillet – Boundaryless Group |',
        lastName: 'LinkedIn',
        emails: ['elinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mukesh',
        lastName: 'Kala',
        emails: ['mkala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHjvTxbSvifVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719891103096?e=2147483647&v=beta&t=v__9gGEqd2_RVYkYJbD1qP1O824gcK-BU2AFyLuXU7M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Deepak',
        lastName: 'Rai',
        emails: ['drai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF53VlnsPdjHQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700546723601?e=2147483647&v=beta&t=SicmHny2QbvO9xkjGWSokejiTTa5UewL1IhvaG9OwqU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lahiru',
        lastName: 'Fernando',
        emails: ['lfernando@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQETi8VIWt0yxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681889999626?e=2147483647&v=beta&t=1pmeX82mSCpts6ap6qU5hAm0UlgRUKsMrXMxthxvQg0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Devyani',
        lastName: 'Sharma',
        emails: ['dsharma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nubesopus: {
    name: 'Nubes Opus',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nubes',
        lastName: 'Opus',
        emails: ['nopus@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Naresh Reddy',
        lastName: 'Gurijala',
        emails: ['ngurijala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEXnHLGXoJrlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671285432193?e=2147483647&v=beta&t=XasAB7bwIaMynDa-fkBpH2oDQGVQz5U-ABonO16uHC8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Raja Rao',
        lastName: 'Dharanipragada',
        emails: ['rdharanipragada@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGw3Ppe6mJENw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715866048355?e=2147483647&v=beta&t=24JUEgCnPINZy-qR0Ubb8_Po66FLrNk1R9sWWIS6re8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'RAHUL BHARADWAJ',
        lastName: 'MACHIRAJU',
        emails: ['rmachiraju@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEmlOasEpBCmA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1589201586373?e=2147483647&v=beta&t=7X5o5Nf9QIuFVA1ejkW5Z61pY2OVRTJm5kRAgkOm78M',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Varsha',
        lastName: 'Boinapally',
        emails: ['vboinapally@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEOsPq1b-S2YA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679849033275?e=2147483647&v=beta&t=lZbpTin04A2ieVNx1DhZQtRcJGoiyc2HU87VKZDmgws',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ricohEurope: {
    name: 'Ricoh Rus',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nathaniel',
        lastName: 'Thomas',
        emails: ['nthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGZsgUX6xK6LQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671499351396?e=2147483647&v=beta&t=YBgjb1Larw-5TNnA5k2nnqWXS_cCKhBl3wrPwEaauWQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nicola',
        lastName: 'Downing',
        emails: ['ndowning@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFF8-kh9X5QaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607431651756?e=2147483647&v=beta&t=qa3gAYBeFOJH1C3j36DvYfUYoplNjhEASqdRGL3VwdY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrea',
        lastName: 'Gombac',
        emails: ['agombac@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFVEaN0hU1nuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1529828023683?e=2147483647&v=beta&t=VS3i7EWfhTM6eVcAk-leKfK9aqcAX8IAHlFBLXG1_hY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Caroline',
        lastName: 'Bright',
        emails: ['cbright@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEo2EXBNEiHlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602708832899?e=2147483647&v=beta&t=gOBcMyGeTaBwGNyuOaHDpxFo2Ok5VagrHDV4eLHCM7I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Mills',
        emails: ['dmills@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  edora: {
    name: 'Edora A/S',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Raquel Cruz',
        lastName: 'Edora',
        emails: ['redora@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGeX80xwhjKKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1558541268414?e=2147483647&v=beta&t=fBFaQHBgXhThRwD76Rf19JVra_2veJEgfUDXdRdPsUc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Edora Butler, MBA, CRVPM',
        lastName: 'III',
        emails: ['eiii@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEKQw36-fifLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666111598557?e=2147483647&v=beta&t=CGn9EzqRaKsA4y9mA9g7O7gBqgRKRTleMrbll1Xfoik',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Edsel',
        lastName: 'Edora',
        emails: ['eedora@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEtubC67Thukg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629817866713?e=2147483647&v=beta&t=nO9sxtsTc54UGJrOiwZdzspkXbKizwrVmyMm6mJfCyo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Edora',
        lastName: 'Roeil',
        emails: ['eroeil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE9yiRfNMRgag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724900821604?e=2147483647&v=beta&t=SFZ4MTw4IXrmgRuW0MJIEHOmYwIJBpLClvOpk3SZiu4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marlene',
        lastName: 'Edora',
        emails: ['medora@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF9_vlnZHzGvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621652743748?e=2147483647&v=beta&t=Qjk8oPu59MvH_1fme1XYnnC7ezT_JjZRnkxxqcqw8V4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  inforte: {
    name: 'Inforte Bilişim',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Yilmaz',
        lastName: 'Gundogdu',
        emails: ['ygundogdu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEdBGlkTvGBCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729022286804?e=2147483647&v=beta&t=JWuwDDQRK9foqqLp1_iQBXZobEw0g6clrFa_EVdI6a0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Orkun',
        lastName: 'Senturk',
        emails: ['osenturk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFEIftZPpN3lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713418874553?e=2147483647&v=beta&t=pN1fCAQ7TOKbZHTAou4VgtjZA4XtGKUAXccg62KsjoI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Elif Nur',
        lastName: 'Yıldız',
        emails: ['eyıldız@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHeFumkvsh0gQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703834004462?e=2147483647&v=beta&t=-nETrzbdTxxt0-61g8YomhclbfIwu9SR0VEkqQh_uRo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kazim',
        lastName: 'Akyazı',
        emails: ['kakyazı@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFx-k8vSoecAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715341801933?e=2147483647&v=beta&t=XBy15yV-NRLXaDIuInkcodXRCRdqwZQ47LBfIyRuQNU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cevdet',
        lastName: 'Isiklioglu',
        emails: ['cisiklioglu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG6xqAaSYD1_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516949966130?e=2147483647&v=beta&t=zKLL9zuRIUK4CDTFgTwiVCfzUcCv3pPXzW157VzjjqU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  omaticsoftware: {
    name: 'Omatic',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ken',
        lastName: 'Haigh',
        emails: ['khaigh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE2WGPXqXkoDA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550346364172?e=2147483647&v=beta&t=YNfGeA311VVKDaxElSL273agyyrwchh-Ez8eCZjOt0o',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Emily',
        lastName: 'Dalton',
        emails: ['edalton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG1Jf-0-kQ-yA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563932758408?e=2147483647&v=beta&t=IrrUOcNhDQApP2Z_JJ9jS9CXluIB7pFYssU8OYrACoA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stu',
        lastName: 'Manewith',
        emails: ['smanewith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brian',
        lastName: 'Hendel',
        emails: ['bhendel@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: 'Deming',
        emails: ['pdeming@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEdriMY9cu0HA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581085020243?e=2147483647&v=beta&t=CsI_7R6-5f4xzlZUKt9Mw1NDQKSydhMwzIBmLCuN2o4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  veganetworks: {
    name: 'Vega Networks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tayyar GUZEL,',
        lastName: 'PhD',
        emails: ['tphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEJC2lz9D_zVQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678520041409?e=2147483647&v=beta&t=zwbFv9KT-9srNPfwQzbFmPWI0Ih7PBy4wQWSwOctNHs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Zach Vega,',
        lastName: 'AICP',
        emails: ['zaicp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH6EgcJreNc_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709353738362?e=2147483647&v=beta&t=M3GkTeMpcUXBwXeaZDJRfb2VzZ9xyO2nxi2M9A22wSU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Guillermo',
        lastName: 'Vega',
        emails: ['gvega@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF6nPn82-4shA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544823483523?e=2147483647&v=beta&t=QiAM_pqZ15ll__P8HPGYVcIX1-GA3XNhiAJ9QBITx3c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Diana',
        lastName: 'Sanchez',
        emails: ['dsanchez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEbrbCROPq7JA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706296360608?e=2147483647&v=beta&t=cCd5GI4oCZ7aTr_hEZ5uvyTRP9F29aWKh_t5BefDnpM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Arturo',
        lastName: 'Vega',
        emails: ['avega@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHMhZgI1XgDcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1528145671945?e=2147483647&v=beta&t=j6lBkWL99WBN_VgsPSQVN5U8Vq4fNMIcL7O82PCPrbU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  entech: {
    name: 'Entech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Walden',
        lastName: 'Chu',
        emails: ['wchu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG_4L5xXxlICQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516300732499?e=2147483647&v=beta&t=SrpZhkR31sm7FUD7b7bQuV7JneJfel18siSD9NqmmxE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Titus',
        lastName: 'Cheong',
        emails: ['tcheong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFiyazKeohqGA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633183228974?e=2147483647&v=beta&t=uvrRQWt-_5kJZoVieopPSzjQFmJt1choAqVK8kaOPMU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'NOEMI',
        lastName: 'CASERTA',
        emails: ['ncaserta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGBcQbJb5kLUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516814465183?e=2147483647&v=beta&t=CGTOkxRAYhyuwVDtVd0L4zYsP04N3R0ZiRQAalfCLAE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kartik',
        lastName: 'Kapadia',
        emails: ['kkapadia@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Whitehouse',
        emails: ['dwhitehouse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEO41I11ptH1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517356978658?e=2147483647&v=beta&t=KHiumtHSGNOOUyprLlTtBqZkGqamy8LRkaH5Wb6hQDg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  inetframe: {
    name: 'iNetFrame Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sandhya',
        lastName: 'Rao',
        emails: ['srao@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGSvI22tJSA3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687327088009?e=2147483647&v=beta&t=-Phwo7AHMfjZN83F7GrHxTRDwJy8JrM9Lntl5Hruii4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vaidya',
        lastName: 'Shankar',
        emails: ['vshankar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF8W3u07U9vfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517759450811?e=2147483647&v=beta&t=eRvtsuXcsrgScFgmfvwg-yOy6Ha6vSlHp19Gj1QzWII',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Raju',
        lastName: 'Iyer',
        emails: ['riyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGOTaR4DWH6pQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517730054857?e=2147483647&v=beta&t=3ddKbnwjg2PFHnjoEKCyYShnWzh9thT1ZmLECaOSnw0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sravan',
        lastName: 'Narra',
        emails: ['snarra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGQ8A7Sbvy_Xg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580986760118?e=2147483647&v=beta&t=D1RsaZuUH5GQw_GB-qD1OXlo_rrPoH5VEFO5SqlHdUs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Niveditha.',
        lastName: 'R',
        emails: ['nr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGO93aGiUxlvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639238813384?e=2147483647&v=beta&t=gCpjJnym7mrfvyVEHk4tzxQRCrIO1P4MgYsLYRurG2A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  praegus: {
    name: 'Praegus',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tom',
        lastName: 'Heintzberger',
        emails: ['theintzberger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHS8AS_UwbZmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680690458624?e=2147483647&v=beta&t=y4hAEZjmkbVR56BU-MbTGN-wgrVXZcNhryF3N3e97GI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nicolaï',
        lastName: 'Roos',
        emails: ['nroos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGe9-dEGz01Rg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701340993208?e=2147483647&v=beta&t=5k0emp31o6Qcn2kQ2qlD9VyGhfcEW9Nocq5lbMb1Dx0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'V.',
        emails: ['mv.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'fin',
        lastName: 'kingma',
        emails: ['fkingma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHd1asROemhuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1520584333091?e=2147483647&v=beta&t=w6FC5HrguT7uQvt1XIt8-0nL3VQJzRW_tgWx_iX-b_0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Reinier van',
        lastName: 'Hall',
        emails: ['rhall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGDUliPYw7Iqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684492909646?e=2147483647&v=beta&t=F4OrXGpsj_U_XCTF_vicqjsB4E3bbHOpHM21JZrCD6k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  privafy: {
    name: 'Privafy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Guru',
        lastName: 'Pai',
        emails: ['gpai@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'David',
        lastName: 'Hanley',
        emails: ['dhanley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHY0YW_fug8Ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516248835341?e=2147483647&v=beta&t=D098yyRjTTQ3-ZMx05fJNec1VAzyc1Vurk2su28lWnY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rajesh',
        lastName: 'Vaishampayan',
        emails: ['rvaishampayan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEzMRwTQqg7cw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557832714304?e=2147483647&v=beta&t=wmh770gCKu-rG5CLwb0a_6bcRLad-7LGMkZmvGJu6sw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shagufta',
        lastName: 'M',
        emails: ['sm@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Narayana Rao',
        lastName: 'Pawar',
        emails: ['npawar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGmM61bnOH1-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517679536525?e=2147483647&v=beta&t=yEXlDmQoAoRcFshdL0JiiarDiOXr0G7mD8Qpafqbs3U',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  belocal: {
    name: 'Colleen AI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Colleen',
        lastName: 'McKenzie',
        emails: ['cmckenzie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFZKJRY4yzf-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1584998069117?e=2147483647&v=beta&t=4ZLy60m7MNQ35W4VB_BsvsAQjSNh3auJt4jyW3PcZL0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Itamar',
        lastName: 'Roth',
        emails: ['iroth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF_yjyfrP1gww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678380591499?e=2147483647&v=beta&t=fbg5icG06bHxFiC7dtOdwdiTIVp9Nvw145_Sdsv56mo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shaked',
        lastName: 'Liebermann',
        emails: ['sliebermann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEfx6UHaMgYRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551212352165?e=2147483647&v=beta&t=Cw3LEDLCJOSNHxLS5WZmj_Im-rw6NX6Yo9WWuHJs-dE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Colleen',
        lastName: 'Bordeaux',
        emails: ['cbordeaux@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH4dbFgVCE8Fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712200187729?e=2147483647&v=beta&t=tDWBG3-tg7YAgglJdvzrHrl36syOPKgXoa02umWXBBg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alex',
        lastName: 'Lanin',
        emails: ['alanin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGjitqqBMXH5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722444893041?e=2147483647&v=beta&t=iCcHb1SO3rQtlcjLvTSXHNEc4n2D-zzKUwJkcDOfNeI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gamaKsa: {
    name: 'Global Arabian for Modern Applications Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Maram',
        lastName: 'Algarni',
        emails: ['malgarni@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHvieWHv0oxoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699480408496?e=2147483647&v=beta&t=aHLFC_RgEBbwVRddQc9VHmmMSW53vFhWmQbxZ3dWPCM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Asim',
        lastName: 'Alhazmi',
        emails: ['aalhazmi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH62AYxH_JtXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714056357744?e=2147483647&v=beta&t=zLmicUiRMaDNU_sTYFxaru4R-egAvY-x5TJSfkY_8xo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Abkar',
        lastName: 'Ousman',
        emails: ['aousman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Omar El',
        lastName: 'Zayan',
        emails: ['ozayan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEHcuzSB2e0HA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615818385543?e=2147483647&v=beta&t=t7c2fe5bhUfEpi9ZG6IdyDAqmA9DjDpg7DQnaO2C3js',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Imran Ali',
        lastName: 'Khan',
        emails: ['ikhan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  insightful: {
    name: 'Insightful',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ivan',
        lastName: 'Petrovic',
        emails: ['ipetrovic@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Megan',
        lastName: 'Gatlin',
        emails: ['mgatlin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF4JzfXeFGjrQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719962375545?e=2147483647&v=beta&t=TsTidq0ksl5XMpQRtZBfJqhXXhygwy3XFo0hw7ALYC4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dijana',
        lastName: 'Milunovic',
        emails: ['dmilunovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHNVQvyLHNrSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573461472018?e=2147483647&v=beta&t=g09C9YruGRLBkuViOg-PFe6EeiAQfXA0IR1iAiZktyE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mila',
        lastName: 'Pavlovic',
        emails: ['mpavlovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGOIXV5m9dLgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569520619026?e=2147483647&v=beta&t=tJolrExA1Kq16-bcgiVC4sCKTlCApfpc21kH08qF9Bc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vanja',
        lastName: 'Novakovic',
        emails: ['vnovakovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGuIiZ48L_rjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676833633900?e=2147483647&v=beta&t=tBlSXKge3BIC0GVYEjEGFS2cNEWIKWtAX2fQnZTAqZA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  osmo: {
    name: 'Osmo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alex',
        lastName: 'Wiltschko',
        emails: ['awiltschko@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Greg',
        lastName: 'Warren',
        emails: ['gwarren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGtJl3zVyw1Ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703101650709?e=2147483647&v=beta&t=2sRS9kcQIo0Z7EXHtEArzuFZrG3ujK50lo645evAcQg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bradley',
        lastName: 'Gilbert',
        emails: ['bgilbert@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Murphy',
        emails: ['mmurphy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFaxWCtsz6iIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654125224082?e=2147483647&v=beta&t=AVdTg7ch0tFbWEXDex4agfKjZiBrXlcfzgl9ckBzYu0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dimei',
        lastName: 'Wu',
        emails: ['dwu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sourceRight: {
    name: 'Source-Right',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sydney',
        lastName: 'Sherrod',
        emails: ['ssherrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGZx-RQ3Dlr9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700176995893?e=2147483647&v=beta&t=jj4-gMzwwGQbZEsKLzk7rlTR93UMvfe1RhsU0HBSOdE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Travis',
        lastName: 'Wedgle',
        emails: ['twedgle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH03rNM8927TQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673023995324?e=2147483647&v=beta&t=iWnO7x_lHgFWM2tV_NVzYM2_E69CFCByi8Q07D5smEA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rita VanderWaal',
        lastName: 'Dawson',
        emails: ['rdawson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Destiny',
        lastName: 'Charles',
        emails: ['dcharles@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Athira',
        lastName: 'Sagar',
        emails: ['asagar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHJMwxDOikD6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711989617949?e=2147483647&v=beta&t=dB2LH0eH-FHsYlBP5cubg1OSp33VCYplyw9PodHlxJw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  deas: {
    name: 'DEAS S.P.A. -  DIFESA E ANALISI SISTEMI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Manuel Cangemi',
        lastName: '(crash)',
        emails: ['m(crash)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHXEOQuscoRgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699564089569?e=2147483647&v=beta&t=D-nbNRLudV9R1w-YBhVsLY9GQSfrfuOXNk3yPp-_h6A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Giovanni',
        lastName: 'Vitale',
        emails: ['gvitale@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGAKBv6xkp7hA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718888782510?e=2147483647&v=beta&t=HezYqJW2qVmhgvUAv62wut82NqIf1zCv-REz8VvzrlI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marta',
        lastName: 'Randazzo',
        emails: ['mrandazzo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGefBu1l0wRUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710932426710?e=2147483647&v=beta&t=a3Ekgl_Vu-yD7WG3aHSqfUOTJbMw8CZdeyFvTzoBeMs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Charlotte',
        lastName: 'Torres',
        emails: ['ctorres@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHGy3NtvqvqjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638269817677?e=2147483647&v=beta&t=FeOlKGzqRFCsSNooVgDDaVlJ0Z5TmcyY8r2Ab8z6h0U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carlo',
        lastName: 'Coccoli',
        emails: ['ccoccoli@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  smartchoiceus: {
    name: 'SmartChoice',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jarrett',
        lastName: 'Wolfe',
        emails: ['jwolfe@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Edward',
        lastName: 'Guerrero',
        emails: ['eguerrero@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH7Sa7HVVQvFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678935629684?e=2147483647&v=beta&t=u2kru13S5OvocrTxKCFNRDmg4hJ3lm8OV64MpfRIlT8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Elisa',
        lastName: 'Caro',
        emails: ['ecaro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF-EXp0_-IKwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714489854051?e=2147483647&v=beta&t=JqesXL2t2dvbT0gdzYTBbkV9BgbV2ciRkGT_PymoSOA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chester',
        lastName: 'Chong',
        emails: ['cchong@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Richard',
        lastName: 'Thomas',
        emails: ['rthomas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHlwbM8joxFyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516992077892?e=2147483647&v=beta&t=E50S3BQpPJNSPXMaMHwtmeUXeXofRj38O_0xvHKTC1g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wingu: {
    name: 'wingu.africa group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anthony',
        lastName: 'Voscarides',
        emails: ['avoscarides@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGh64cfTdx6zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666437681655?e=2147483647&v=beta&t=xLiMgiOcYkhKoU6Xr0y5qvyOhnY5LCKvsJlVeo7vlz4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Demos',
        lastName: 'Kyriacou',
        emails: ['dkyriacou@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Issaq Abdi',
        lastName: 'Houssein',
        emails: ['ihoussein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEDuW5l3JMijA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724138177117?e=2147483647&v=beta&t=62cFNSBgJxgSu9Lwtlpsv5fKXpGtBMsVNNY1dsNtl9Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Freddy',
        lastName: 'Mrema',
        emails: ['fmrema@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG3sIxZTtzVPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666072352173?e=2147483647&v=beta&t=nu_l37FIn1zhLRmZDNgUoMuFiADU-C-nTencEhFAbn4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nicholas',
        lastName: 'Lodge',
        emails: ['nlodge@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  inspiragrupa: {
    name: 'Inspira grupa',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stefan',
        lastName: 'Salom',
        emails: ['ssalom@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFAQoiud8gesg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675438239769?e=2147483647&v=beta&t=5xShj-MSLzSEnfRXE5WqMQkaHgbSDLD3l6W5sFd5Zwc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anamarija',
        lastName: 'Petrovic',
        emails: ['apetrovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGzBKasEHwWfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695885009666?e=2147483647&v=beta&t=86CuNmJQZxV1p84iKL5uGeQtdP0kvwwh4pVJatEUzxQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Damir',
        lastName: 'Bubic',
        emails: ['dbubic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGJIJqXGzH-aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550705265642?e=2147483647&v=beta&t=-lS-O0_UY2sDG2MJ_c3meZzU33nkzXASgCloNvf68lY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Branimir',
        lastName: 'Gajic',
        emails: ['bgajic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHZCa_a8rWvUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517920111565?e=2147483647&v=beta&t=A2Fc9wEenA7AYUFtXTbyVO_wc1rlcRHPosfXFLfeS70',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dajana',
        lastName: 'Tolj',
        emails: ['dtolj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH0QsqHfEJ3xA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670608034100?e=2147483647&v=beta&t=W7EX879iqDOocnDnbWdhKyWnKZOGrTykjxyqHK4z6MI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  qoo10: {
    name: 'Qoo10 Singapore',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Julian',
        lastName: 'Tan',
        emails: ['jtan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kex',
        lastName: 'Ang',
        emails: ['kang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEEM6lyoqi1Gg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688954809949?e=2147483647&v=beta&t=ugdURqeDdfMIUzFOpJfoPcEG0GOkTftSwMzdoeN86rA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kelvin',
        lastName: 'Chew',
        emails: ['kchew@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGIqceVvwepTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726222796849?e=2147483647&v=beta&t=qKs5fCbTd9L0zdBj3cDCPg9j27sexeUkPfclfWUy3hY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Elaine',
        lastName: 'Ng',
        emails: ['eng@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Johnson',
        lastName: 'Lim',
        emails: ['jlim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE_FagVH81htw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720880639670?e=2147483647&v=beta&t=mkC-tlhfOKYssih18D9aY5ffb812IKZHDpRzVR2SVOA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  velospear: {
    name: 'Velospear Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sudhaharan',
        lastName: 'J',
        emails: ['sj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFBISX1s7557g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517756286177?e=2147483647&v=beta&t=Cfck-EACxdaLIxf0OaCfI8W2o_op6ay0U6vQW7PfM0I',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Balapradeep',
        lastName: 'S.',
        emails: ['bs.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Arshad',
        lastName: 'Khan',
        emails: ['akhan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Patrick',
        lastName: 'Manne',
        emails: ['pmanne@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Panneer',
        lastName: 'Ramalingam',
        emails: ['pramalingam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFCrc5AlwLH6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1621780208298?e=2147483647&v=beta&t=EmrqXFDICoR-H7a2BeJpms89ZGG-CzVuAprftAE7OGU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  processgenius: {
    name: 'Process Genius',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ward De',
        lastName: 'Smedt',
        emails: ['wsmedt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF7e1l6Pzgg1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677573425902?e=2147483647&v=beta&t=Jl0XL33HtioZgVgG2LMwxg-ee7TQFRLX3xwSMEUTT9E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Melissa',
        lastName: 'Go',
        emails: ['mgo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFzPiTVZ3bG1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726067411637?e=2147483647&v=beta&t=jbwu5elIBX1DpWLdbsq94DugUBkV0sT6rWXcrtL_8zg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Katie McLennan CSM, CSPO, Working Genius',
        lastName: 'Facilitator',
        emails: ['kfacilitator@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEtvtabbveiWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728580378773?e=2147483647&v=beta&t=0xoU8m_IPpbgfOpHxJGScnW6OTQMtH3faS5radp1JXA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Heikki Hyttinen – Process Genius GmbH |',
        lastName: 'LinkedIn',
        emails: ['hlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGicyJcCmKF3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522185454581?e=2147483647&v=beta&t=X16I3HW0AMtLKtF8pmy2BSL0kk2EOkkUtZdpiLVTITA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anastasia',
        lastName: 'Varavva',
        emails: ['avaravva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEaFcmMyQF7Jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675766263629?e=2147483647&v=beta&t=s7vF2lztOpY7LE3Ltdq9jfaCiVmod0usC-oQ3mZPrS0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  expediteinc: {
    name: 'Expedite Technology Solutions LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Nishi',
        lastName: 'Raina',
        emails: ['nraina@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Doug',
        lastName: 'Parker',
        emails: ['dparker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFEZ6a8Pfld3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552269625486?e=2147483647&v=beta&t=0Mkbt8aJRbgPvyI-PW2IYrGlYXS-4OnZvozK55idMjs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Arun',
        lastName: 'Singh',
        emails: ['asingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHust3_6oW1nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578348799066?e=2147483647&v=beta&t=hxGyiVnwbuTq8JwYUCkHeFrE1HRELpPFzdGPNRGykPY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rahul',
        lastName: 'G',
        emails: ['rg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'sajal',
        lastName: 'agarwal',
        emails: ['sagarwal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vizru: {
    name: 'Vizru',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ramesh',
        lastName: 'Mahalingam',
        emails: ['rmahalingam@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Charlie',
        lastName: 'Chu',
        emails: ['cchu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFjoKsCHa-vdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518295036523?e=2147483647&v=beta&t=H6Cj52FVk6LK1OheQRXp8cwo1NeE-UmvJM1u0IVDtV4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shankar',
        lastName: 'Devarajan',
        emails: ['sdevarajan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGXGRpIzJw0Dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729283161640?e=2147483647&v=beta&t=L5P4AtTHTQ__kfDBAzBfEphi5yhxKTnGkLU_j7t0s90',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Victor',
        lastName: 'Nelson',
        emails: ['vnelson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Pratheesh K',
        lastName: 'P',
        emails: ['pp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEIzAlD0XMA0g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516942642925?e=2147483647&v=beta&t=0sZsnIQsVawTL7NERHPBV_neKGoJLC0fKmNBOMINsTo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pointcross: {
    name: 'PointCross Life Sciences',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Suresh',
        lastName: 'Madhavan',
        emails: ['smadhavan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rahul',
        lastName: 'Madhavan',
        emails: ['rmadhavan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF1xUAIIz9D-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609770574655?e=2147483647&v=beta&t=cuVbfppVQGddXd-HLhff2Dhp8TD2kd79lnhNkOZJLwM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vijay Shankar Raj',
        lastName: 'M.N',
        emails: ['vm.n@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHZIDrR6xuD7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634206446100?e=2147483647&v=beta&t=j5tIWpeCReT98INAu4UJtNJIQILwQHDVGjYXVVCGm7g',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vijay Kumar C',
        lastName: 'U',
        emails: ['vu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Haris',
        lastName: 'Khan',
        emails: ['hkhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGbnxGuhZV55A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516858303045?e=2147483647&v=beta&t=OaYwZxtGMBD8Z7G_nUPEy6oXPfo8cfKD9NR3VyXbNuU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  singularitysoftwaretech: {
    name: 'Singularity Software Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kattechsystems: {
    name: 'Kattech Systems Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lalith',
        lastName: 'Chokkapu',
        emails: ['lchokkapu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ajay',
        lastName: 'Golla',
        emails: ['agolla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH0CwgRLhuOMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708751289844?e=2147483647&v=beta&t=ZIvRONLNsWp8ftZp1Qhf1SEFX5YPhqoHI2BASR8Xbno',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ajay',
        lastName: 'Golla',
        emails: ['agolla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGCGyMBNd8VaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624694641299?e=2147483647&v=beta&t=fAd135rFkLX6Qc6U16wL3LeZBCnS_UzSzBCfYDXRB9s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ajay',
        lastName: 'Golla',
        emails: ['agolla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEpHuv9tRTSuw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517629645224?e=2147483647&v=beta&t=YCkgZlfq2AeA5AOpLXoYpV5ELzJkgjJZTUaPTfgEitY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jagan',
        lastName: 'R',
        emails: ['jr@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  acuative: {
    name: 'Acuative Middle East',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mohamad EL',
        lastName: 'Chami',
        emails: ['mchami@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEhoLng5rUNcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673238100891?e=2147483647&v=beta&t=Ul0ii9yNi2S8hp837f-VQRs4Oy2EcDHYjvxTyilJDRY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Verane',
        lastName: 'Djeudjam',
        emails: ['vdjeudjam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHOa2a78tvbSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517479173323?e=2147483647&v=beta&t=HNTFUL8P56W08qwgVK7a2cbHuLUlAJeytkiIGejOqI0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mansour Alessa,',
        lastName: 'VCAP',
        emails: ['mvcap@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHRdXLJim2K2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632661465998?e=2147483647&v=beta&t=qHTWe2y0v9jfvuOMNsbRLw1SB6RoXYy2gStd0_hxrTg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shaghig',
        lastName: 'Jinbashian',
        emails: ['sjinbashian@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abul Rehan N',
        lastName: 'Mohammed',
        emails: ['amohammed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFADR9rYsub-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517691894975?e=2147483647&v=beta&t=ED-f4SNggKUmXsuW8vjson_OjMgKJbQMfBP_fpat9Kg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  reprintsdesk: {
    name: 'Research Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lydia',
        lastName: 'Lindenberg',
        emails: ['llindenberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGgtfD77oOgag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1520286870595?e=2147483647&v=beta&t=-Vhqu8ny8Jomw3YCztLv5Ab9ADM7DMnuLzevXMXYgVc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Julia',
        lastName: 'Heesen',
        emails: ['jheesen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEKdkp6ZYqmxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727468313651?e=2147483647&v=beta&t=shl3dlOmGD04yVDRYBfV7mOmkkz7di2Vwrq83NGP6Sw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rick',
        lastName: 'Salloum',
        emails: ['rsalloum@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE3mRLbdoM9QA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678386987252?e=2147483647&v=beta&t=XW4ldvOTe76Ct0uuoFi3LFzuorcmrvekw8l5pPnyHIg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Wendy',
        lastName: 'Zellner',
        emails: ['wzellner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGeVbbW4zqDng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517484640562?e=2147483647&v=beta&t=P6RZYj-10RbEu7aTT2l8G9fjoQPrcr5sEOL58iHvSvI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Roy',
        lastName: 'Olivier',
        emails: ['rolivier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEHw7sCHrB9uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516308850189?e=2147483647&v=beta&t=fR7z5TpP3E635-hwQoU22h9HVrob3ulPGLLirLsTWs8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  innroad: {
    name: 'innRoad',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Frederik',
        lastName: 'Rasmussen',
        emails: ['frasmussen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG5xkDfjkGd_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687876189667?e=2147483647&v=beta&t=4mOfND3jJyFnu7SbvBHgXA9sPz-o1FDfCqymW6Qfz3U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Budreau',
        emails: ['mbudreau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE12LNwW_8rFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694029402583?e=2147483647&v=beta&t=wcnAFbrvul36l3LqctmarNxTQya-ZYsbwtZEdnaJ0PQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Raju',
        lastName: 'Dunna',
        emails: ['rdunna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG3AL1DwUsRQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606807130138?e=2147483647&v=beta&t=ibBY9l6zPbcw7pL_qnR0XVpkwPNnehTeb47gdTuOJKc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Thomas',
        lastName: 'Keane',
        emails: ['tkeane@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Peris',
        lastName: 'Chrysanthos',
        emails: ['pchrysanthos@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  filixconsulting: {
    name: 'Filix Consulting Pvt. Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gaurav Mathur (Filix) جوراف ماثور',
        lastName: '(فيليكس)',
        emails: ['g(فيليكس)@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ajay Kumar',
        lastName: 'Kedia',
        emails: ['akedia@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vaishali Gaur (Filix',
        lastName: 'Consulting)',
        emails: ['vconsulting)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH2sX0MVtnRxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639239810208?e=2147483647&v=beta&t=gfjnMVvxAxF3bmdeSwwxo2Fy-AtIm-2yL5mzMSu41RY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tarun',
        lastName: 'Sikka',
        emails: ['tsikka@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Social',
        lastName: 'Media',
        emails: ['smedia@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHVeus0udQzIg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516575939340?e=2147483647&v=beta&t=8fkPY7JkxGbxzLZPtln5RSScw5wMCbts6IOGSrse5HU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  itspartner: {
    name: 'ITS Partner',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Evgenia Ivanova – Deputy Director (Development and HR) – ITS',
        lastName: '...',
        emails: ['e...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFwZygagvWP6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1556739930139?e=2147483647&v=beta&t=VijgsmNhCgxTqgXzmWlfHn4IkvSSgoI5sJIAveh2GU0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mike',
        lastName: 'Mackey',
        emails: ['mmackey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFcNhXEb3NCJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516308803119?e=2147483647&v=beta&t=aQTkSnBA-7GNrqKQsWrAr1s3QXKVaVJtIqttAVRtA3g',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chris',
        lastName: 'Haughey',
        emails: ['chaughey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHJZUCmQ1Sv8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555672905282?e=2147483647&v=beta&t=JRVwrMIypray4IfRwyhAwsVgfGBK91q8Gu-lNjbdTms',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Valeria',
        lastName: 'Galkevich',
        emails: ['vgalkevich@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Wright',
        emails: ['jwright@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE0ePiKDGCklg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517674968203?e=2147483647&v=beta&t=8M-8kwiE6u5SbtxvktuUZzOZ9I0IdRZ7iANDMtAizsY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  primer: {
    name: 'Primer.ai',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sean',
        lastName: 'Moriarty',
        emails: ['smoriarty@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Leonard',
        lastName: 'Law',
        emails: ['llaw@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Curtis',
        lastName: 'Bell',
        emails: ['cbell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFFPdPdzXn-Ew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1562845725580?e=2147483647&v=beta&t=wXAmImi1iMfcDwJGv9NV_SnD6zKqek5KOJcBlLmqd5I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Joe',
        lastName: 'Chang',
        emails: ['jchang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFdPS9Ws5GKMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670302453916?e=2147483647&v=beta&t=U28p5X-uLzcJJ130xGpWyJCcv-p-kB3vLeLGnApmyYo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Adam',
        lastName: 'W.',
        emails: ['aw.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHoPvL_Bxo2FA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645066002290?e=2147483647&v=beta&t=NrPSObpJHhjNJyZraqNeaU88WoCTykdFr-2Z8v6cZrA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  unbrokenstudios: {
    name: 'Unbroken Studios',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Peeler',
        emails: ['rpeeler@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEQVi2taHJLSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544738755481?e=2147483647&v=beta&t=KFw9qu_vPRUFi3adeNGegxa7o8Zo03q6aIRVibXwjSQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Leon',
        lastName: 'Brazil',
        emails: ['lbrazil@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Corey',
        lastName: 'Burnett',
        emails: ['cburnett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEXNA0hS8EqfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582777029391?e=2147483647&v=beta&t=Z7HH83b2sN75mALv_ghGDCN7NtQIBzV4klXLIvbbzUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Torin',
        lastName: 'Kampa',
        emails: ['tkampa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'Hayford',
        emails: ['ahayford@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFEq4ZjFZALig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516860572299?e=2147483647&v=beta&t=kOYpashri0n6r6sHT5Io7MMz_LD4ud4jMPojb1JyyDE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  itgsoftware: {
    name: 'ITG Software, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Amer',
        lastName: 'Abushamma',
        emails: ['aabushamma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEDrxTqivliFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686566508724?e=2147483647&v=beta&t=v-cqqqI1T8lqbfhjq1a5uSWez24G0dU4IukZaS4YOa4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Joe',
        lastName: 'Barkawi',
        emails: ['jbarkawi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHLI2nbmCotFA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606063744123?e=2147483647&v=beta&t=oFsO8LlUCLj1za6Iz0SxQxv6hyCh_KLT2Pwioegi1P4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sora Abu',
        lastName: 'Nasser',
        emails: ['snasser@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGYYMliTiu5Kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715784834792?e=2147483647&v=beta&t=Iu3qiOGaNgyoOiz4s6VtiHgqkpwuDqmryRX5iSi5Vdg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Omar',
        lastName: 'Barkawi',
        emails: ['obarkawi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEahNu0Tn5OPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595193562077?e=2147483647&v=beta&t=D0ROnj0PUfpwi7BJkBhKzQ2P7UfiPqDqMDn218a6CHA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Karma',
        lastName: 'Khayyat',
        emails: ['kkhayyat@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  flexiele: {
    name: 'FlexiEle',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lakshmi',
        lastName: 'Trivedy',
        emails: ['ltrivedy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFFJgt14334aA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719082654196?e=2147483647&v=beta&t=RGRCwS1JtskwKGr8DBpD3UDEloJ3Af7azsFxA-Fa-OE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Keshav',
        lastName: 'Arora',
        emails: ['karora@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGIBWc6xLmVZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723742022510?e=2147483647&v=beta&t=e03HofsL50nQ_kmJvgo7sG58HCh5N5r-w9ySivMwEKw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'rozy',
        lastName: 'jaret',
        emails: ['rjaret@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'K SAI',
        lastName: 'SARAN',
        emails: ['ksaran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEnCIhSOh7x3w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320201669?e=2147483647&v=beta&t=zRkmyve3gb7YHrqcb79FNZfeMpD7T5Y5hESQsb6gjoo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amitabh',
        lastName: 'Trivedy',
        emails: ['atrivedy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hitachivantarafederal: {
    name: 'Hitachi Vantara Federal',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rahel',
        lastName: 'Hake',
        emails: ['rhake@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEKmRjRuhtNHg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689010437607?e=2147483647&v=beta&t=5aBArb-Fg9Huyc48d1Zicpgr3TlakqWwDtTuE-rDbr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gary',
        lastName: 'Hix',
        emails: ['ghix@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEqi9eb9aGJjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686312928185?e=2147483647&v=beta&t=NRn8J8wzBRldwbuLXyGP92fDVsx2Xvtc1WHSuDkbK-s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Phil',
        lastName: 'Fuster',
        emails: ['pfuster@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF4F5eK8G7tdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729024843660?e=2147483647&v=beta&t=sacHP5cq6ONgHdvZgJZrn2Jf2CUXTeCjb7DT69pqvEw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pragyansmita',
        lastName: 'Nayak',
        emails: ['pnayak@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGK3IU0KA0Bjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729182313373?e=2147483647&v=beta&t=fKyx8NUBRXS5Hn1hvZ2okaIRNdsRQE1xSlzM8OO3Jkg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Melissa',
        lastName: 'Hardt',
        emails: ['mhardt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFQaDddz0xBZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718218249205?e=2147483647&v=beta&t=YX2bZ--XBJ4TqBPQ_amZm_Li2xpjFWzo9R6cxMr8XKY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hsitp: {
    name: 'HSITP Hong Kong-Shenzhen Innovation and Technology Park',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Henry',
        lastName: 'Au',
        emails: ['hau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHKxSpF3JnBYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563417811929?e=2147483647&v=beta&t=M40CMwSZmA7uijnZQ_cDaIQldI_Qr8OKtc91dPkUPRw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alex',
        lastName: 'Kwong',
        emails: ['akwong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGzftA-_9FAGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517740753786?e=2147483647&v=beta&t=VzhqwSoK-UkrX7H19yyal0QteGDYA2vYGw4iBB9dDEU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jacky',
        lastName: 'Yip',
        emails: ['jyip@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Lam',
        emails: ['mlam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFAaQd9dSEF9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682604803771?e=2147483647&v=beta&t=15oDv_RdN5jCCAVm09aIo2a6Ajy1-K0fVBVIhfqzINM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Herman Haiwen Lei,',
        lastName: 'CISSP',
        emails: ['hcissp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGYo9j0vHw-7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598017368629?e=2147483647&v=beta&t=RDyGYYcE6CsVTRENSuTeZ6f1CpsLhxsbA3JZ0DSErBg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  abOvo: {
    name: 'Ab Ovo | Going Beyond Complexity',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Barbara',
        lastName: 'Roos',
        emails: ['broos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEkTANAC0oJjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729280397330?e=2147483647&v=beta&t=vbCBCu5WXsSgVA43YZJ1X5zpADLJdCe88Ve3Oqqe2XE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Praveen',
        lastName: 'Lal',
        emails: ['plal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Deepa',
        lastName: 'Chandramohan',
        emails: ['dchandramohan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shalaka',
        lastName: 'Chaphekar',
        emails: ['schaphekar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH4rOPk5CxETw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516944529769?e=2147483647&v=beta&t=p10xZ2oNTcz4Sn_yv7xNJ-J2bprqOMYvXtcyJF37orM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sudha S',
        lastName: 'Atre',
        emails: ['satre@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGVpUPo9AjE3g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643502023262?e=2147483647&v=beta&t=68KyX4nuvNG7gz84bNv-cSFAb6vrODWVCxge6ZKDGXU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bigvikinggames: {
    name: 'Big Viking Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'Nutt',
        emails: ['mnutt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFWx94vavrLew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697081108498?e=2147483647&v=beta&t=wZ7gi7oF6GJjeaLv7-KFT4SXgSgRvxa3dEtr6rmRYrs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Masterton',
        emails: ['cmasterton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFXHx6bi-2vcQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713655797342?e=2147483647&v=beta&t=k4kNOvKImgfwHD1c_WlMbQ75mx4A23cUauBQPe-QNl0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sean',
        lastName: 'Worsham',
        emails: ['sworsham@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFJRQmaf2lcaw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516157512708?e=2147483647&v=beta&t=ZI1Os0aiQ-4Cw205krojQCKgauu6-ltEmZy9_F9yado',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nicki',
        lastName: 'Ogaki',
        emails: ['nogaki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF6yo4yL7bFjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549393891965?e=2147483647&v=beta&t=hl1o7yoromFYnz9t6iU-nSLFKwepfVsLaVsVCO5_Rfo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Wilson',
        lastName: 'Chan',
        emails: ['wchan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHXI6a61wwzRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614525813947?e=2147483647&v=beta&t=cxONv4lUTTZhBJggYR3KGqtzR8o1KlyI7rk0Vlc5SNE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  spearmc: {
    name: 'SpearMC Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marcus',
        lastName: 'Bode',
        emails: ['mbode@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGZwVpxAuBioQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516255868066?e=2147483647&v=beta&t=azcBQyO25zU6kt7VabBbl89lbWne2ZlFI2-ftzTP6gY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cameron',
        lastName: 'McClurg',
        emails: ['cmcclurg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFUx9uotaGwdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522429208024?e=2147483647&v=beta&t=69wuVESGSHbwwX3KiDS_eTt4tktDenJhT_qmDlifGM8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Thomas',
        lastName: 'Tretter',
        emails: ['ttretter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEnFHvJX9BZQQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516243503426?e=2147483647&v=beta&t=hpUpO_964wVt4mMjRrb1SgQkz-EdQGzE5qvTnLFpufQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Justin Willis,',
        lastName: 'PMP',
        emails: ['jpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHmFzHnKFSYDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704490654244?e=2147483647&v=beta&t=PjOjXEgx2y6hVFNL7y32no6dweFJxMf-ypRKlMOBCIk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joe',
        lastName: 'Finlinson',
        emails: ['jfinlinson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGZkXKvBycOeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720621114070?e=2147483647&v=beta&t=-X6lrAyoMrOoGdv1QxCx8nthmt05m-4_arBG3UbxxRM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  alliedmedia: {
    name: 'BAAR Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eiric',
        lastName: 'Baardsen',
        emails: ['ebaardsen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Aslaug',
        lastName: 'Herrem',
        emails: ['aherrem@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGSV-Aj4EW_6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1548582881298?e=2147483647&v=beta&t=qgkE8Fzkdu94badiruMEPx-2QeHcF5ufV4ks7i_rkCk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Liane Heidtmann',
        lastName: 'Negri',
        emails: ['lnegri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGn-20FcybGzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715791961196?e=2147483647&v=beta&t=HRxHkFZaUs8y3zuV1Sar6muQV6SpVIIq1qBwNUhAPsI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bikash',
        lastName: 'Gupta',
        emails: ['bgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFlwfyQ5nmtwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516561629608?e=2147483647&v=beta&t=LmhAyw8QTkbFFqp5uX25iu52cOJTLoUvdjxSO-WaoBw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Surabhi',
        lastName: 'S.',
        emails: ['ss.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEd5Ka_Q_Tf9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544632812217?e=2147483647&v=beta&t=NTxfz0L0cwRU-A8p2_Bsxj9vhXZiM2RkOAgPkWKpP9E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  abett: {
    name: 'Abett',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bridget',
        lastName: 'Keaton',
        emails: ['bkeaton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alexander',
        lastName: 'Yan',
        emails: ['ayan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHJ485HJHIF4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517674320251?e=2147483647&v=beta&t=TDYh7BrNc2-1YoR74sRpampJLkOrUxBE8i4zB8ZrffQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ryan',
        lastName: 'Haughey',
        emails: ['rhaughey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOBH8zreuHeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718256221435?e=2147483647&v=beta&t=BLOtipaetfk5biXZeue4AOc_RT_hl7TNbWNsCiVkkMk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Katherine',
        lastName: 'Lofgren',
        emails: ['klofgren@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abett Reddy',
        lastName: 'Cheruku',
        emails: ['acheruku@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEN9JTXQc4ZCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643662105047?e=2147483647&v=beta&t=0a8As41FwOqPQLL9nPiv8v-gy3MDh35QcPHTzoYwAEE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  contegix: {
    name: 'Contegix',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brad',
        lastName: 'Hokamp',
        emails: ['bhokamp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Solomon',
        lastName: 'Gifford',
        emails: ['sgifford@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'B.',
        emails: ['mb.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQERjBVl8Q3yCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693268654863?e=2147483647&v=beta&t=VtJb5404Z7YqPeVclS43aLGJhKaajtzhnnARJnXRF_M',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kim',
        lastName: 'Brooks',
        emails: ['kbrooks@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shaun',
        lastName: 'Jones',
        emails: ['sjones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEYywgTni6-Fw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517042549100?e=2147483647&v=beta&t=Arm5GNo6GqAppfMcohi59ICWXlYiWVQGm86Xf8i76tc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ejamerica: {
    name: 'eJAmerica',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Arturo',
        lastName: 'Pacheco',
        emails: ['apacheco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHO8BxBMGX5HA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516455293055?e=2147483647&v=beta&t=9qBToRo21UH2npxegdBN12CDljXIzV0u7I1ytj1hHkA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Zulaika Natekal',
        lastName: 'Malek',
        emails: ['zmalek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEnPl6MofE0rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517053224464?e=2147483647&v=beta&t=WZfiQHlojS7IzOkMk9eavaiAzgAzpxnahAiIPxVnl1s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sara Gabriela Carranco',
        lastName: 'Orduño',
        emails: ['sorduño@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHHhpFTOom3lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728961158355?e=2147483647&v=beta&t=SWM7pQM-q3QK6n3rEoFh_s0HmOi8gcQPPXDF8ctHFvM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nathan',
        lastName: 'Ward',
        emails: ['nward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGyS82neI5aAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664906902631?e=2147483647&v=beta&t=rbPRXCgmI0F6RrOyrRywfvVEK7gnhAU4bdrWqdqMowQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aditi',
        lastName: 'Narayan',
        emails: ['anarayan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHaCKhbN-awKg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692098720645?e=2147483647&v=beta&t=s7LKlBteJQRR2PDBrHh6mLQ5qtdEiSyrWUMunUQNSG0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  skyslope: {
    name: 'SkySlope',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rashael',
        lastName: 'Parker',
        emails: ['rparker@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGHbIrY8fyBMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517708109153?e=2147483647&v=beta&t=C9XKWr9R9AGKvfMGxe0-kr_H-M0jOf3luVKMiFNx-is',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kien',
        lastName: 'Lam',
        emails: ['klam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEX2SzPHN--GA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619285044747?e=2147483647&v=beta&t=NQIiWdTmqnSXhPjLsjCtCI8RUAyNjTRYubZNM-NZVSc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sky',
        lastName: 'Hartman',
        emails: ['shartman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFGx9B-MkHfpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721062292588?e=2147483647&v=beta&t=SaQqyDm-Sr888J4C8sg0iN0GDtiPmXCYezMgrKYaQ-k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Tyler',
        lastName: 'Smith',
        emails: ['tsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFPznI21Qjj4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710655289175?e=2147483647&v=beta&t=b1AKmwZigK4uel8sYYrMikElDkbuhybOLm2H8bObILQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Koji Fujimoto,',
        lastName: 'PHRca',
        emails: ['kphrca@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEv46MxiZw_8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628107742892?e=2147483647&v=beta&t=nIkcDCz7ktBB1IFDLa5TkYZrEDVNs0DxOQ2qTuCOAEU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  simmsAssociates: {
    name: 'Simms & Associates',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dennis',
        lastName: 'Mitchem',
        emails: ['dmitchem@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFu_2DpIYXq4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516533561846?e=2147483647&v=beta&t=mdHShjtS2TE-mHGfMryT_-loX5av9YdZW4RCuEZXwD8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lazarus',
        lastName: 'Sims',
        emails: ['lsims@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHRXyIfqFocJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544580131332?e=2147483647&v=beta&t=Z-tl0pVesOtEI2jZ2PYPEnmYihgCEPodBcMbasbOU4s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sylvia',
        lastName: 'Colacios',
        emails: ['scolacios@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGa0Yee7tiA6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703076462625?e=2147483647&v=beta&t=thh182B2QLBCCAon-EFuL-ggj2QoCBmErsVsldpit44',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Aimee',
        lastName: 'Ledall',
        emails: ['aledall@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFzDT7seJuwKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1629829936077?e=2147483647&v=beta&t=wBxTSz2zztTcGXau9ehu9ooZkALmCtlWPoA-tLm21wc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Simms A. Adu,',
        lastName: 'PhD',
        emails: ['sphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFcTfFJ-CR4Jw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651793233700?e=2147483647&v=beta&t=QUeGDEq2nQgItE0w_FsaVydv7HjKNlXnkJFlAHBLpoY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sailotech: {
    name: 'Sailotech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Suresh',
        lastName: 'Babu',
        emails: ['sbabu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFidHHBM86duQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1599653294355?e=2147483647&v=beta&t=0_wmtq3rNh9N0cvRyjvRZf1LImmDB2PS0quXrNm-FUc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Krishna',
        lastName: 'Chekuri',
        emails: ['kchekuri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFy8e6EXivP1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1551278304753?e=2147483647&v=beta&t=JvBatSVhfNz422U-LjpBie7ri7d-KEJRNE87NTae21Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Satish Pelluru ,',
        lastName: 'PMP',
        emails: ['spmp@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'SANDESH',
        lastName: 'SHRINGARPURE',
        emails: ['sshringarpure@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGopVxuGODaUw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1569426681432?e=2147483647&v=beta&t=ZtfVxtlLR-4IAkK3QOmX9FICMzXuxUygJoc-yMtJmbU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sheshma',
        lastName: 'Kunchala',
        emails: ['skunchala@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  alignable: {
    name: 'Alignable',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric',
        lastName: 'Groves',
        emails: ['egroves@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFW70eunZGSvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516173766669?e=2147483647&v=beta&t=7-_-YP7x_csuTpy9ACX32Hkjj0qUr6b-XUPKCYWUN9A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chuck',
        lastName: 'Casto',
        emails: ['ccasto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGB8H1xqdN8ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516283479055?e=2147483647&v=beta&t=6cZd1dTVDoK70mA3Ww9v0Nbz60Ox71bulIbKBkDS7Tg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sean',
        lastName: 'Tarzy',
        emails: ['starzy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Da',
        lastName: '',
        emails: ['d@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGLFFm0HgINfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1537245767963?e=2147483647&v=beta&t=bFBl2NOp5YFCxmG2XXT5ljHvXvqFPv1I6EwwowaOcWc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Avery',
        lastName: 'Huang',
        emails: ['ahuang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFKvsypd0W2Dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638370941678?e=2147483647&v=beta&t=_GxmGYr7T3MxpECr3Bxz3RD3GW9XysrrBwsSjsPR_8s',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  imri: {
    name: 'IMRI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Imri',
        lastName: 'Eisner',
        emails: ['ieisner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Imri',
        lastName: 'Sofer',
        emails: ['isofer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Imri',
        lastName: 'Frenkel',
        emails: ['ifrenkel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHmSOIi_qfhoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680073493979?e=2147483647&v=beta&t=l8_B3gp1DsMOHmRrkeCcpgFjcbj7x37ojU8gNTIPWbU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Imri',
        lastName: 'Adams',
        emails: ['iadams@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHzZ4lKZJALog/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698419461882?e=2147483647&v=beta&t=IMhT9VY1yOUEKyKXlHvTjLC_BuCJ6WfFgDKKr6cLC3s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Imri',
        lastName: 'Ganiel',
        emails: ['iganiel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHuWjh-eHNVOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517502047075?e=2147483647&v=beta&t=fhLvRCCY0fXcLROwwZ050hadGwANd0zIRMXZWOzxkqQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  servicepower: {
    name: 'ServicePower',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Frank',
        lastName: 'Gelbart',
        emails: ['fgelbart@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sam',
        lastName: 'Sippl',
        emails: ['ssippl@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHOUmnWrukJsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678211467956?e=2147483647&v=beta&t=7iEffP468g2RtmuODqXrQHKV3-8ltPjc7sQSvB6bAug',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brad',
        lastName: 'Hawkins',
        emails: ['bhawkins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marc',
        lastName: 'Sprecher',
        emails: ['msprecher@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Heather',
        lastName: 'Mills',
        emails: ['hmills@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHICmYbIt6vsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694458618891?e=2147483647&v=beta&t=FSVsHRcslFNDpqGNsh4cglT5zHUlFj5HutMTr5Lpw1k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  leap: {
    name: 'LEAP Legal Software US',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ramsey',
        lastName: 'Cabbani',
        emails: ['rcabbani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFID-IJ0YjcVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516995307612?e=2147483647&v=beta&t=Rx6hfoBuI4rL6SXsCTlKn60jjPT-_VQ-dZVnM6g_w7c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Stephanie',
        lastName: 'Leach',
        emails: ['sleach@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHbRQCumJUG9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727905266753?e=2147483647&v=beta&t=NcFdI8szFMzRT7VeXfJGw1eX2971ADfdVSFtH3xhQ90',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joe',
        lastName: 'D.',
        emails: ['jd.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Poppy Bale',
        lastName: 'Dyer',
        emails: ['pdyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFSzKVzIpI7cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667934541426?e=2147483647&v=beta&t=MaYb5gqDwaoV5FlUDdx2Q2TkFe7bUknG4-Vf_MQ6eaA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Laurie (Azaria)',
        lastName: 'Klausner',
        emails: ['lklausner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH5R3wObqgMaA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723646870337?e=2147483647&v=beta&t=XsCB008GoTrbYFQPXtb5Pnku1Z5w4JlDXehqtYY7H_8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xduce: {
    name: 'XDuce',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jay',
        lastName: 'Dave',
        emails: ['jdave@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kaliprasad',
        lastName: 'Rambhatla',
        emails: ['krambhatla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'RAJ',
        lastName: 'RANE',
        emails: ['rrane@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gautam',
        lastName: 'Sethiya',
        emails: ['gsethiya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHB_tHLgzB2sw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1537201711954?e=2147483647&v=beta&t=RuzjWz97rx_AhGQHKfV3agBuzuyIh1PgWsouUfDSQfo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Raj',
        lastName: 'R.',
        emails: ['rr.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bugendaitech: {
    name: 'BugendaiTech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Roushan',
        lastName: 'K.',
        emails: ['rk.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGG99H_XFRopQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522011318555?e=2147483647&v=beta&t=bTTgsCSBwnuBnb0rAIm1J2B1I7vQvYy07qj_3nlTcxM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rajnish',
        lastName: 'Kumar☁️',
        emails: ['rkumar☁️@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEBebQRFHUR6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696259631513?e=2147483647&v=beta&t=0J8qt-iQSyNujDLuWehH5v4zwfrkb8YgS1yVx9FzEEU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Wendy',
        lastName: 'Brunner',
        emails: ['wbrunner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHZtz6hiFJd3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575653841900?e=2147483647&v=beta&t=qjJNoxatB9yErMDRFIiybjTZW7yFOL4nvhcEBp83az0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kat',
        lastName: 'G.',
        emails: ['kg.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHl1Qq9lkGuKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649938774913?e=2147483647&v=beta&t=x_57lRUOTLs-3SSq_jsFXA8YKjYPZMgvZ-We85bYMEA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Aman',
        lastName: 'Sahu',
        emails: ['asahu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFU-0nS2fcy6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713032831197?e=2147483647&v=beta&t=T_r53D2LM6lFzsHXyru8SIIrZTeYv4_rdQ_ppupi5lY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  conceras: {
    name: 'Conceras',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tom',
        lastName: 'Woodward',
        emails: ['twoodward@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG7jaql_t7wBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721753331446?e=2147483647&v=beta&t=WgO9JEF9ivAkAmnIqDoFmIwTfVZ4KX5SVh6BguozFHw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eve',
        lastName: 'Mercer',
        emails: ['emercer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFdFX6UF7PlsQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710731749766?e=2147483647&v=beta&t=541hcdbo6U5ddDKSpRQowF8bNVhykqIhUgysZem2cNY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Melinda',
        lastName: 'Nguyen',
        emails: ['mnguyen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG_HgglnxFbeg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678930250082?e=2147483647&v=beta&t=Gs5FUjBQ2Kb6yBSdxbQpC_vCOqs_4oRWjjjTaoO3sN4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Harvey',
        lastName: 'Nguyen',
        emails: ['hnguyen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHi4IZzmPm5Mg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516311932947?e=2147483647&v=beta&t=ebem6L3rXb7Cin8FBnpMwSmEqHb0u8Ga6vHq4EfNYBk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kelley Dionne,',
        lastName: 'PMP',
        emails: ['kpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGsTCPNslUYtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694204436424?e=2147483647&v=beta&t=5tVT06yJBoUi3VWHQaRBDisThYpESB1I418E0r7N3uw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  scantrust: {
    name: 'Scantrust',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tobias',
        lastName: 'Kars',
        emails: ['tkars@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Justin Picard – ScanTrust |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEBj2tkjCHKeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588198168263?e=2147483647&v=beta&t=Is6ZAazq__PEknR7fxG7vVdn-7N37hpNFgjAKmtaRK4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrea Beck – Head of Customer Success – Scantrust |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEvl38-OHrVyw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517667672517?e=2147483647&v=beta&t=2Pr_3-ju4bfqqZVIKjZ6bflgAlOsiluj17Iqi7pMHj0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nathan J.',
        lastName: 'Anderson',
        emails: ['nanderson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName:
          'Michael Bolay – Research And Development Engineer – ScanTrust',
        lastName: '...',
        emails: ['m...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  technikinc: {
    name: 'Imagry | Autonomous Driving',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eran',
        lastName: 'Ofir',
        emails: ['eofir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE0qn1sSkx_Sw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598235546384?e=2147483647&v=beta&t=zTroemGldM6sWbPaVcgsa4FFP6CeNppNl5yn9Y0vhA8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Suhail',
        lastName: 'Habib',
        emails: ['shabib@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHvi08eNzHNjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670352679926?e=2147483647&v=beta&t=37ERynAiXI-oL_q6h9k8fXxJaAc3uMyDIDGkP2-9zyg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ilan',
        lastName: 'Shaviv',
        emails: ['ishaviv@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Adham',
        lastName: 'Ghazali',
        emails: ['aghazali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGtZTTPHuUBfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654523370544?e=2147483647&v=beta&t=1wqeDW61WlIrnwBYxIoVkW7aFAuAsXibiLHZ8PFuwwY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jonathan',
        lastName: 'Kesner',
        emails: ['jkesner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGHIj-53L7MYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1546934792046?e=2147483647&v=beta&t=VW0lgmxUsyvhTj6qaZ6cOW8Swbwn_RZlCN4W2Jl9T1o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  worldgatellc: {
    name: 'Technik, Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David',
        lastName: 'Schultz',
        emails: ['dschultz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQETBbQ-4ZD8yA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696754949590?e=2147483647&v=beta&t=yX60--gc-0fYsKCrgs8e92t47D2VRs1vYDCqPFYfqJY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark A',
        lastName: 'Gomulka',
        emails: ['mgomulka@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Patrick',
        lastName: 'Burke',
        emails: ['pburke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF_Quzr9F8q2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516452927127?e=2147483647&v=beta&t=dYeh_zdlAIv4ylYfvOK6DIQCJFY1suHyAxF1HLIll_8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'MacKenzie',
        emails: ['jmackenzie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEB1s9fxkaNqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729714496662?e=2147483647&v=beta&t=A_5fQTTuOuPU96ERRq_DpsaAiPs6vXR6zBQGM-lq8jo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kevin',
        lastName: 'White',
        emails: ['kwhite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE8BiOfsMcFyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684935517154?e=2147483647&v=beta&t=mKFFir-HIpUy7c-cbRMVjmJlPI09D85BcFvST6MemPQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  incontextsolutions: {
    name: 'Metra Commuter Rail',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rebecca',
        lastName: 'Casto',
        emails: ['rcasto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHbdPjWX2Lgyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664772119702?e=2147483647&v=beta&t=CIUlkTWzAyJ4R0DFmHJkdtjKuSEvX1_RP1V6vpeSUyQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Metra',
        lastName: 'Mehran',
        emails: ['mmehran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFMJ5ez5MeLvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518023667699?e=2147483647&v=beta&t=ZoDulj2v1GZ9Kcc7Yz0YNp_8k0VXhdPsz0HcbKht674',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Radzinski',
        emails: ['jradzinski@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGHl7mDfDkvbw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730242640925?e=2147483647&v=beta&t=asC9mXnBwqmyWWD_bBXG__pb18JoleJ4p1sKs0-OaCA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kevin',
        lastName: 'McCann',
        emails: ['kmccann@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Steven',
        lastName: 'Radecki',
        emails: ['sradecki@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sungardas: {
    name: 'InContext Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Fred',
        lastName: 'Nagy',
        emails: ['fnagy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Beat Meyer – Blauen Solutions',
        lastName: 'GmbH',
        emails: ['bgmbh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mike',
        lastName: 'Deck',
        emails: ['mdeck@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFlH9zAx2CHNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1521093014846?e=2147483647&v=beta&t=ElWIaOl-f_R7NUrF7QqFf2doCIKbRgKUydIegH7hrEs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mike',
        lastName: 'Perez',
        emails: ['mperez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHj3Kj_g6BZNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729999559809?e=2147483647&v=beta&t=2AXA90ylQBhs2bkI8RXNrTPlr1FbmkIxytibS0oxgpk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Carrie Graham,',
        lastName: 'PhD',
        emails: ['cphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGcCjm_FgHo_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730300049712?e=2147483647&v=beta&t=fTMpdIyY6APB4VHTN3YwDVeLPArKFK2bRez3LJmDDHw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  zesty: {
    name: 'FLUX',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Griffin',
        lastName: 'Thill',
        emails: ['gthill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGizfIkJT-IqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1576541547507?e=2147483647&v=beta&t=I-ZW5ee5m3jw5Gs5iO_rj9Y4plvochMLf-lSzDWt8aU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Deb',
        lastName: 'Galiel',
        emails: ['dgaliel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFRPiu6jseG1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517019737306?e=2147483647&v=beta&t=53HONZdBHV2S-apuuVOrgWOT_mz5kCezHzQkMOOz0Ik',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eric',
        lastName: 'Shepherd',
        emails: ['eshepherd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHXSS7yjaGZPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687969917066?e=2147483647&v=beta&t=gCX-83_yJX7nkEtZ3Mu9oydNZfRSPuY8beRIfqQPllU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sean',
        lastName: 'Egusa（江草）',
        emails: ['segusa（江草）@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Misty Brown,',
        lastName: 'PE',
        emails: ['mpe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFoOBxVraoePg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1641312612028?e=2147483647&v=beta&t=HwuBj0oH6-IQn4lmB3fQzpK2zYK2DF5-otSwN8znU40',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ordina: {
    name: 'Zesty',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Maxim',
        lastName: 'Melamedov',
        emails: ['mmelamedov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Herrick',
        lastName: 'Griffin',
        emails: ['hgriffin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Darin',
        lastName: 'Ritz',
        emails: ['dritz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEuQRV6HfwK5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712943997851?e=2147483647&v=beta&t=-6G5E4Q_IZnp18TRKZGWqnhm-XF2iSWH1LH9SnhI4EA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maor',
        lastName: 'Ezer',
        emails: ['mezer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lloyd',
        lastName: 'Price',
        emails: ['lprice@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFGvisPWUjDCA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718252251848?e=2147483647&v=beta&t=S1ihahkWpVLUe8p1IbEdpodzi8b_Ok-huUWvu1_kaKc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cirata: {
    name: 'Kerry Express (Hong Kong)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Wong',
        emails: ['bwong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH_OBG9yZWD2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701792648674?e=2147483647&v=beta&t=Pe8dajWcC-3ujEiG4nco_SesVQIO82Kz53XVjyJ_GLw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Samuel',
        lastName: 'lau',
        emails: ['slau@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kerry',
        lastName: 'Logistics',
        emails: ['klogistics@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF-0W8oU29brA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603330132866?e=2147483647&v=beta&t=S5I1ttRAKJ4pClbOAuBkYNV62iMhnuveRRr3Z-FsrV8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ann',
        lastName: 'Yeung',
        emails: ['ayeung@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE_K2NjHBnoxw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668580595800?e=2147483647&v=beta&t=ko50TgeEgfb5j6g9tUNXkzYpXnEgBDmCqbabKQ8p_4o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kevin',
        lastName: 'Lam',
        emails: ['klam@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  technoval: {
    name: 'Ordina Luxembourg',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Benoit',
        lastName: 'Richard',
        emails: ['brichard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGC6BkEm_ltjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680018635058?e=2147483647&v=beta&t=IrhVoe_GPDbCcZEUDPEBPUEuyxSP9W_rQ-cyQdPZHMg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'HAJAR',
        lastName: 'ELMISSAOUI',
        emails: ['helmissaoui@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bryan',
        lastName: 'Au',
        emails: ['bau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG7_y3owJE4Kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1662303789824?e=2147483647&v=beta&t=1f-AYiSzco8LMJMWqbFiIl3FFFZNwnz-Xd6y6CNBg0Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Raphael',
        lastName: 'Maka',
        emails: ['rmaka@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Valerio',
        lastName: 'M.',
        emails: ['vm.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEa9jRQ9UQllw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564397548944?e=2147483647&v=beta&t=xNTcIHjHOgtDQLaJTWtN8NDpCaiBJAKSB70FuyMCCpQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  simpletire: {
    name: 'Cirata',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Stephen',
        lastName: 'Kelly',
        emails: ['skelly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEThTF2mX3EKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630497914687?e=2147483647&v=beta&t=IYBarYWMR8Lnjm9TXbcST4rw4o3ZD_imE7RLqpqSl7U',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Cochran',
        emails: ['ccochran@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Justin',
        lastName: 'Holtzinger',
        emails: ['jholtzinger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQExO15zVDLcDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516233392112?e=2147483647&v=beta&t=U70GO81032OxP1IBUQ4Nlu4pmLSLox8-Vlg_tBmJN5Y',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Paul',
        lastName: 'Scott',
        emails: ['pscott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH00je6A9Lgvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704754363195?e=2147483647&v=beta&t=-neRlEFA9CbSfGTw9oDjucyGh6z9698Qcs0b7b9vhKE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Darren',
        lastName: 'Mullen',
        emails: ['dmullen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHHua15U2B17w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728038139782?e=2147483647&v=beta&t=rVG2LKHl7Aa2fnNGI2klC1NFQMlQUORpYsCS2R2UeBk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  jibe: {
    name: 'SimpleTire',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Karthik',
        lastName: 'Iyer',
        emails: ['kiyer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kenny',
        lastName: 'Pratt',
        emails: ['kpratt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG-Y_0JVWFWkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1596499526257?e=2147483647&v=beta&t=t6XJdmR8gTpKbue1Z7iw6o9SisL718e_hyaMYFYev2E',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mike',
        lastName: 'Stefano',
        emails: ['mstefano@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH--mNcvzEy5g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517357595737?e=2147483647&v=beta&t=_FHwADBeYELb2yfDqj5tX1B-pJAGPB8fgq3i8WsyPbI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Umair',
        lastName: 'Khan',
        emails: ['ukhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEVEGQ2wsQhUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686853082872?e=2147483647&v=beta&t=Rov2jSQPdwNoNjGkUGEE2E1k-F03Uh05KBiB9h83NaQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emily',
        lastName: 'Anthony',
        emails: ['eanthony@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  brycetech: {
    name: 'Transcend',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Debora',
        lastName: 'Nielson',
        emails: ['dnielson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFg9YUyQaZ74g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560348343977?e=2147483647&v=beta&t=ZZQC-BX3lF0ufUdQSPhN2dRK2zrY6qshaTmVf43qjck',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Georgia Space Grant',
        lastName: 'Consortium',
        emails: ['gconsortium@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFZ8Jv32j0Awg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664290837749?e=2147483647&v=beta&t=ELJe1ZrIFFuWpJCY0v7YTZ_6SjyAennQthS7_otZ7_s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Paulo Oemig,',
        lastName: 'Ph.D.',
        emails: ['pph.d.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jordan',
        lastName: 'Bopp',
        emails: ['jbopp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGaYWDKz3va2A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691593887203?e=2147483647&v=beta&t=Rw4uQfNxf0jP4n4crp_he2fICV1FPqgtf9KYTMHf-XE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alabama Space Grant Consortium',
        lastName: '(ASGC)',
        emails: ['a(asgc)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFXlXWe960RcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544219845975?e=2147483647&v=beta&t=aXGvTrpFZclxYe69Zg1jOTjUIs9ZM4WrAANJWCtz1Cg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cadmiumcd: {
    name: 'JiBe ERP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Yariv',
        lastName: 'Zghoul',
        emails: ['yzghoul@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vesta',
        lastName: 'Mathew',
        emails: ['vmathew@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Oded',
        lastName: 'Sittsamer',
        emails: ['osittsamer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Maria',
        lastName: 'Tzitzali',
        emails: ['mtzitzali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHvS1P8gu7pHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516808990585?e=2147483647&v=beta&t=M882POTdAlUWlSgALQG3UHldUmSbJc1ve_w4tU49wz4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Tate',
        emails: ['dtate@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFatdKnluyGuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658150766409?e=2147483647&v=beta&t=P5fQUkBAag7wPo0dHtu_x9vuUCtJR-1AZGx315FhlxM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  benefithub: {
    name: 'BryceTech',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Carissa Bryce',
        lastName: 'Christensen',
        emails: ['cchristensen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chris',
        lastName: 'Wills',
        emails: ['cwills@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH6CTYwCaxREA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573497117148?e=2147483647&v=beta&t=G83JIft0XQ2TUPdnSMqc5vOt07HQzHtInQuKSECU-Dk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Madeline',
        lastName: 'Schmidt',
        emails: ['mschmidt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jason',
        lastName: 'Hay',
        emails: ['jhay@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nathan',
        lastName: 'Sanders',
        emails: ['nsanders@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFZ3qMKx3n0dg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1560525862091?e=2147483647&v=beta&t=zCFA86ZQL1nSnqT3es0jlV07t70C1tTjta34g2B3rog',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  a2G: {
    name: 'Cadmium',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tracy',
        lastName: 'Evans',
        emails: ['tevans@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marianne',
        lastName: 'Bryant',
        emails: ['mbryant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFe7Ad-OG5MpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656624467971?e=2147483647&v=beta&t=ee-YUW_0d9O-5vVuI5k1nRgQkfuAfMPYeKnJsL8kWxI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'Volentine',
        emails: ['jvolentine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFKUX64v8eqBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1636108897165?e=2147483647&v=beta&t=Dwx2yBUTg4VNFldluHunp5HvIGrL8KlcLYLJ0djLnxA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amy Seward,',
        lastName: 'MBA',
        emails: ['amba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGdQda9ka4HHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516661735176?e=2147483647&v=beta&t=biZ4-nsS1Rb1lWGkcicYqAkn7QhoQrSyrwQq7tJvHsM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kevin',
        lastName: 'Smith',
        emails: ['ksmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHC7jlF86h2lQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581535135502?e=2147483647&v=beta&t=qh9TU4BOh6hSHzKATdvgYbZuHfiVAo8x7elJ4rLHsoY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  slabs: {
    name: 'BenefitHub',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'Litvack',
        emails: ['jlitvack@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEU-8egO9dsBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516193094958?e=2147483647&v=beta&t=UMfXaK3-EVHd0JlBG7iVfRBDJX7NgqgRYh9y1JtuZJI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Seif',
        lastName: 'Saghri',
        emails: ['ssaghri@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John C. Ferrara, CFO, Board',
        lastName: 'Member',
        emails: ['jmember@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHC9Bxf4knfBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516347028963?e=2147483647&v=beta&t=obnUgLb8WKxF-SXZIXc9XQJ4pXYnWKF4qOiElX0iy1s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael',
        lastName: 'Stockwell',
        emails: ['mstockwell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHxOo0tfVtHOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1554664536476?e=2147483647&v=beta&t=D_lbnqC3iGzraIuJ4iV-HBrttTkWmxCzi2EpGCUPaQI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joseph',
        lastName: 'Kelly',
        emails: ['jkelly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHwfU1pg-V1XQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516762635376?e=2147483647&v=beta&t=F-NiZXL_bgLlx4VJmNTfYaFTTK8EEBJ9UJ70DJgGvLs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  msad51: {
    name: 'A Square Group (ASG)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mandeep Singh PMP,',
        lastName: 'CISM',
        emails: ['mcism@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ankesh',
        lastName: 'Arora',
        emails: ['aarora@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEqn0iXPIOV6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681028059693?e=2147483647&v=beta&t=Jk5SRLjCwqAsK7Vz8uL4M-Yh2YKUZ7olGh4Sd6xyTCo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sara Singh M.D.,',
        lastName: 'DFAPA',
        emails: ['sdfapa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFM_14X5vBBfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642260227808?e=2147483647&v=beta&t=xnNKHDJw0KegVX35GfCgVRluoSsrgXg-D-hatRejOXo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Turner',
        emails: ['jturner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Larry',
        lastName: 'Janeshek',
        emails: ['ljaneshek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHhtoPYEKn4ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665674904828?e=2147483647&v=beta&t=AMARpzDvXE9IUWT6FzgDGI7U_sq5fs4TlTd_Ffp_WHs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  armorizetech: {
    name: 'S-Labs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Keerthi',
        lastName: 'Chavva',
        emails: ['kchavva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEausBNxQzq2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602086825649?e=2147483647&v=beta&t=mPOlh-qG6D2oSXWR6BjTl2pgOXQQQxRcfTmhpzYYtqg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: "Peter D'",
        lastName: 'Souza',
        emails: ['psouza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEZ6GnJfyAhOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673437661397?e=2147483647&v=beta&t=M7JdhZ-feun4TniDXSx9FvPuASVj2bsCTYpsBoI5dNg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ashok',
        lastName: 'Gundlapalli',
        emails: ['agundlapalli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGP16yYnmffQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676356740448?e=2147483647&v=beta&t=58kVwdSgaCgyJOeelF3crcgBD8mXb5Ez5M9SSsLSLpA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Balababu',
        lastName: 'T',
        emails: ['bt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH9mIg_g7sB9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701091914392?e=2147483647&v=beta&t=JuixTkT1thVSXppMsuQMLu-AxrFWOrcnfLLPyIWr3RY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sridhar G',
        lastName: 'Nelapatla',
        emails: ['snelapatla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHt63oSJmvfgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695471871325?e=2147483647&v=beta&t=-4HbXRWDBAzpcrAnz00g50DRkgTNNDrZO-xvLj8unTw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ankix: {
    name: 'MSAD 51',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeffrey J.',
        lastName: 'Porter',
        emails: ['jporter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFPxImIsuIHkQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647005812300?e=2147483647&v=beta&t=a7rEoUHyxXUu6CjgVAWDrj0zF2Ierbuljo2WVOInLGo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rachel',
        lastName: 'Rivera',
        emails: ['rrivera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGe8VW8AeRUyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634092743343?e=2147483647&v=beta&t=zHisAss034n__NQSlK_7XLyqeIDXf3V1v9nbKGccs2Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brady',
        lastName: 'Grass',
        emails: ['bgrass@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH4ECEs8BdOpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671121436107?e=2147483647&v=beta&t=MHgwiFn5Hpv5WPJGxIMbrb8g-qnYDfxgZbKTzr11Flk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Teresa',
        lastName: 'Folley',
        emails: ['tfolley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQH1hmA4WcQZPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517495049656?e=2147483647&v=beta&t=3gM7QKrsJwz6gOQIb-ew0ar-6SL0QZO2qqh90M1TYUs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kelsey',
        lastName: 'Frost',
        emails: ['kfrost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG50u_4NZgDOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673574451955?e=2147483647&v=beta&t=Oj4sqhRWG5iHj8Qj12th5l4Q4043BikTbSKl7BIE7vA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  contourds: {
    name: 'Armorize Technology Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Keerthi',
        lastName: 'Chavva',
        emails: ['kchavva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEausBNxQzq2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602086825649?e=2147483647&v=beta&t=mPOlh-qG6D2oSXWR6BjTl2pgOXQQQxRcfTmhpzYYtqg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: "Peter D'",
        lastName: 'Souza',
        emails: ['psouza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEZ6GnJfyAhOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673437661397?e=2147483647&v=beta&t=M7JdhZ-feun4TniDXSx9FvPuASVj2bsCTYpsBoI5dNg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ashok',
        lastName: 'Gundlapalli',
        emails: ['agundlapalli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGP16yYnmffQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676356740448?e=2147483647&v=beta&t=58kVwdSgaCgyJOeelF3crcgBD8mXb5Ez5M9SSsLSLpA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Balababu',
        lastName: 'T',
        emails: ['bt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH9mIg_g7sB9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701091914392?e=2147483647&v=beta&t=JuixTkT1thVSXppMsuQMLu-AxrFWOrcnfLLPyIWr3RY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sridhar G',
        lastName: 'Nelapatla',
        emails: ['snelapatla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHt63oSJmvfgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695471871325?e=2147483647&v=beta&t=-4HbXRWDBAzpcrAnz00g50DRkgTNNDrZO-xvLj8unTw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  prtcom: {
    name: 'Contour Data Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Briana',
        lastName: 'Fechter',
        emails: ['bfechter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGlAbsdS6L3MQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670289910833?e=2147483647&v=beta&t=SF2HbDmY-pwB3Y6fupl3k9JH-y8j6gK8S0AhkcpCeAk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Robert',
        lastName: 'Dabundo',
        emails: ['rdabundo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF5R_Lv18uS_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1591630163359?e=2147483647&v=beta&t=I7Utj2RK8-xkj-7sq_ujHsE2vyhpel6ygX4hbXpEh3k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Brandon',
        lastName: 'McKenna',
        emails: ['bmckenna@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFZ9_FXdNksGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701194585431?e=2147483647&v=beta&t=grnZCTJB_2DNhj86Gp05jMTaoNO8aOYkgYt48kg4fSo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kacey',
        lastName: 'McDevitt',
        emails: ['kmcdevitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH_p0K5im0IDQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665153412413?e=2147483647&v=beta&t=QaUKSiYlBAUNmFkdu5yDfluVHFcCTdfD6JwGw6-L2yw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cheryl',
        lastName: 'Collins',
        emails: ['ccollins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  analyticalSoftware: {
    name: 'Galenika a.d.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Marija',
        lastName: 'Jovanovic',
        emails: ['mjovanovic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGgbxr2YBqLiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517884990811?e=2147483647&v=beta&t=Xt-CY4egn9KM9qfkri4VDfSbosfKzLf1XfwmE1ZrOis',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sanja',
        lastName: 'Karanović',
        emails: ['skaranović@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Carlos H. del',
        lastName: 'Rio',
        emails: ['crio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEelJn7_FgsXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516486222424?e=2147483647&v=beta&t=kNN4Z7iH78pPPARsWuWsiIY6faErjeeLPQ_hw8Ledpk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Carolina del',
        lastName: 'Rio',
        emails: ['crio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFqjWw2Yx5yzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547685576292?e=2147483647&v=beta&t=ZgKPDz8xsdOnvBjztCErWS3WcEAEolVddSR7T5I-Swo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Javier Del Rio',
        lastName: 'Mejias',
        emails: ['jmejias@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHqNpcB0t6L5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723764708085?e=2147483647&v=beta&t=LSEt_4g9_LQ8wQwAgMMMoTIzUVI2oChI4IelI3H8T-E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  chameleoncg: {
    name: 'PRTC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Keith',
        lastName: 'Gabbard',
        emails: ['kgabbard@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dan',
        lastName: 'Fruchey',
        emails: ['dfruchey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFdcFKZXasvRA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714076912175?e=2147483647&v=beta&t=75Wz5Kzpq1tfdr1nNg6ATqxHn9IBEgvf1bQsVG4ZQCA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jako van',
        lastName: 'Blerk',
        emails: ['jblerk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFUncXqTLhd8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517735937288?e=2147483647&v=beta&t=Drp1_A2HEvcStLfuR7HF5R57298s2uKRJiqAxbeoLKQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hillary',
        lastName: 'Mintz',
        emails: ['hmintz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGTen2IpcUGnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691261854889?e=2147483647&v=beta&t=DsHpSzsg3e_OXQR2-1TR8mewA-xRljAIY7GyknsNFB8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vinn',
        lastName: 'White',
        emails: ['vwhite@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  alphanetworks: {
    name: 'A Square Group (ASG)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mandeep Singh PMP,',
        lastName: 'CISM',
        emails: ['mcism@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ankesh',
        lastName: 'Arora',
        emails: ['aarora@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEqn0iXPIOV6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681028059693?e=2147483647&v=beta&t=Jk5SRLjCwqAsK7Vz8uL4M-Yh2YKUZ7olGh4Sd6xyTCo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sara Singh M.D.,',
        lastName: 'DFAPA',
        emails: ['sdfapa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFM_14X5vBBfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1642260227808?e=2147483647&v=beta&t=xnNKHDJw0KegVX35GfCgVRluoSsrgXg-D-hatRejOXo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jeff',
        lastName: 'Turner',
        emails: ['jturner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Larry',
        lastName: 'Janeshek',
        emails: ['ljaneshek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHhtoPYEKn4ow/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665674904828?e=2147483647&v=beta&t=AMARpzDvXE9IUWT6FzgDGI7U_sq5fs4TlTd_Ffp_WHs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  custodiatechnology: {
    name: 'HMS Analytical Software | Consulting & End-to-End Solutions for Data Science & Analytics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  coherentLabs: {
    name: 'Alpha Networks',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rui',
        lastName: 'Ma',
        emails: ['rma@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFst6DYCuHTcw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600995864605?e=2147483647&v=beta&t=6UkkcxgxPqffjr7UAxjJU8fPGb-2Wtm_v3xu07jH0nI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Varun',
        lastName: 'V.',
        emails: ['vv.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Emily',
        lastName: 'Ma',
        emails: ['ema@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEP-ZvB-TB27Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729665970418?e=2147483647&v=beta&t=1T6vvTnKkW9ub_pBDbc80zBlB0AUDrc0PNXuj8ZPrO0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Winston',
        lastName: 'Tang',
        emails: ['wtang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFDT9TbYcpi-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1563345537049?e=2147483647&v=beta&t=Da2DnhyNjS6AO1dwtbK-pP8TtQi-EbCP5uLvTBHxMG8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'Gahsman',
        emails: ['bgahsman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGB26OtxfE5-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531795420300?e=2147483647&v=beta&t=0nJ0UFL6kFbDTnuuKIGsRBUOthIaFAi5hr-tfxwCL_4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  datanimbus: {
    name: 'K-Source Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vidhya',
        lastName: 'Sagar',
        emails: ['vsagar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG5zFCiqg0yQg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517371944348?e=2147483647&v=beta&t=rjXPJ4Jr7BHPcYY4OQphCDj__2u_iI5uGmDgJ7XIU3Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sanjeev',
        lastName: 'Coduru',
        emails: ['scoduru@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Marco',
        lastName: 'Yamaguchi',
        emails: ['myamaguchi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJsJWgY3-xEw/profile-displayphoto-shrink_200_200-alternative/profile-displayphoto-shrink_200_200-alternative/0/1528984197623?e=2147483647&v=beta&t=a-cFhstR5J11Kfa7HJANDdAF5T8BJyR8Tm6XHINCOlk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ranga Nallaparaju',
        lastName: 'PMP,CPIM',
        emails: ['rpmp,cpim@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFUxxIb3-Ij_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676317336241?e=2147483647&v=beta&t=YmVcjRUAZIFlrAgaUaxq2bFXBjn2Zyjqabj0lnINwWQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Deepak Varma',
        lastName: 'Kanumuri',
        emails: ['dkanumuri@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rockset: {
    name: 'Coherent Labs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'George',
        lastName: 'P.',
        emails: ['gp.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFzqIRIJ3IKrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516241734419?e=2147483647&v=beta&t=Gyw5vb_NrTKZqRDkXbqyEFuMxiP3u6lHRFoo23RCG1s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dimitar',
        lastName: 'Trendafilov',
        emails: ['dtrendafilov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQE6XgA62QNycw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516585012019?e=2147483647&v=beta&t=poEs4veDVF1526tJzIOfDH5Qa2GqFKkq8c0DlgAJv1U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ivan',
        lastName: 'Gradev',
        emails: ['igradev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEg9yFtSj1Z8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712732971404?e=2147483647&v=beta&t=BXoOngbfuVDjQi6bp97I2CisL8ugrcjvZzmaWgAJRog',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nikola',
        lastName: 'Vasilev',
        emails: ['nvasilev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHweF090KviYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1535133267243?e=2147483647&v=beta&t=LNPx2q8H_esCJiSe1HyLPmieUbwbSlcvvjjead8vKwY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bozhidar',
        lastName: 'Lyubenov',
        emails: ['blyubenov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGJgs-QtZDZRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623437518666?e=2147483647&v=beta&t=z3oc36kFyaTTL3U_fLyFjsitf_3o7rtFO145yLdmH9E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dimater: {
    name: 'BICP',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lehanna Sanders,',
        lastName: 'PhD',
        emails: ['lphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFH8g86FgvsJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595994800546?e=2147483647&v=beta&t=mP-R7_K1olgvonWadhg_i4ras3RibbKA3KyFEbtwNiY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'James',
        lastName: 'Hoying',
        emails: ['jhoying@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGqDFp9sv7kRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728052606121?e=2147483647&v=beta&t=B1-Kn_8TsMdItscsTbjwLbUXc86KH9F52vnaJkP0xeU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Terri',
        lastName: 'Waller',
        emails: ['twaller@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFcRF-zKH-9kA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707315797598?e=2147483647&v=beta&t=1DVLhciCworMgu2VXxMtIYIQZctZiuU-KqHwXjpnCfw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brandon',
        lastName: 'Hanke',
        emails: ['bhanke@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFEs1NzNr3OXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517754638915?e=2147483647&v=beta&t=yGpTtxAQcgtDMSZxp0_FFKRTEQiwGsXCYiu-GCacHao',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Maya',
        lastName: 'Yaakov',
        emails: ['myaakov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHxmr-wUOC8Zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715092409525?e=2147483647&v=beta&t=5v8jRitWM_8QJSgGG_lblKXidiFgCxG8kYn21ybBd1E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  softekpr: {
    name: 'Rockset (acquired by OpenAI)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Will',
        lastName: 'Jewitt',
        emails: ['wjewitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHKH8MM0ZqU4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675444589985?e=2147483647&v=beta&t=B8eyNde6U6R-otRAmZ4P5Zg5JWcprONvCHHyNPJoOXQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Donovan',
        lastName: 'An',
        emails: ['dan@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aniket',
        lastName: 'Awasthi',
        emails: ['aawasthi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF24NwYJ19BCg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677722102821?e=2147483647&v=beta&t=e_kCbcdwUW_Eg9xCONMd7UnLNJfAVpZXswjsjfwcBMk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Spandana',
        lastName: 'R.',
        emails: ['sr.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mohamed',
        lastName: 'KHEIR',
        emails: ['mkheir@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGcOZgpt4k9zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645555368583?e=2147483647&v=beta&t=JvqNiQsvbJSHhB7aqo93oppUVpSP6eZNXacmEQSWbvc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vantagepointInc: {
    name: 'dimater GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Samuel',
        lastName: 'PAGE',
        emails: ['spage@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michel',
        lastName: 'Tissut',
        emails: ['mtissut@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHBYluNdnfq8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690439984191?e=2147483647&v=beta&t=oRhO10ir7WgpoSb5fRt_70n5muL0SGd1Sf_YQO5Kxzw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ranjit',
        lastName: 'Multani',
        emails: ['rmultani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF73A5HHfiz4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575969216851?e=2147483647&v=beta&t=88-uS3zjbmoLORU8K0v5GEd1TcBa55fWMJ-Lky8hP1s',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Akash',
        lastName: 'Bhargav',
        emails: ['abhargav@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFfZBlhJaNKwA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555581556005?e=2147483647&v=beta&t=btDTeFtHbMM9diBx-4dtc8Mg2cKHhMjS6Vx-yQfqFiw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Martin',
        lastName: 'Roth',
        emails: ['mroth@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHXtIFFSEB8VQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707814541409?e=2147483647&v=beta&t=W9y26QSE3_EC11fIn5O2kNfjg5SFIsN83hts6nX2xUE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  '500apps': {
    name: 'PNT Data Corp.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lisa',
        lastName: 'Mattos',
        emails: ['lmattos@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Victoria Mangado,',
        lastName: 'MSA',
        emails: ['vmsa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHNDgyrbr97pg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1530459529711?e=2147483647&v=beta&t=irYYjZu4abv3L1l5I3IIIRK-6PObmyuDiGVcnQw7FWA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pam',
        lastName: 'Russek',
        emails: ['prussek@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Stephanie Sher',
        lastName: 'Imberman',
        emails: ['simberman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Melby',
        emails: ['jmelby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEtTGCFsCl5mw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516936953608?e=2147483647&v=beta&t=l8MckaP3k8uFAgfoG5jzHnSXtPVRuKDKMZ_abR-A1xs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  signetgroup: {
    name: 'Softek, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bfa: {
    name: 'Vantage Point Consulting, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeffrey',
        lastName: 'Carpenter',
        emails: ['jcarpenter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEUIoinKJICNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516271045978?e=2147483647&v=beta&t=vnXTddYPTZz-DuQgkazm70TS3sSHQ0BQOaKJ_WYVgrs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Contreras',
        emails: ['jcontreras@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lauren',
        lastName: 'Collier',
        emails: ['lcollier@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGDXTHpICsclA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699460479831?e=2147483647&v=beta&t=qOQqTTaadpjtXrosJr8gUWSSQyz6T2mmJe43zITS8wM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Harold',
        lastName: 'Tran',
        emails: ['htran@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Paul',
        lastName: 'Jackson',
        emails: ['pjackson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFzVnrLntQYTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541604186993?e=2147483647&v=beta&t=Uf1pRL90cyFGJNBwuhSQG4bpqr8RAsVmUuvWZd-mVq4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mcsgtech: {
    name: '500apps by Mantra',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lavanya',
        lastName: 'kurakula',
        emails: ['lkurakula@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHg4mP3ldjmFw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1559543132996?e=2147483647&v=beta&t=f_gNMnU4Nzp6_68CBX865ByTtWMC-O3j9gCnHiD8XVA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nishant',
        lastName: 'Sutar',
        emails: ['nsutar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHk2jCFBWsPzA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653464131188?e=2147483647&v=beta&t=7qVlTgTd_vjzBEx5gnDlEhm0TK5wCjkHPVr9TmMN9g4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Chakradhar',
        lastName: 'Kalapureddy',
        emails: ['ckalapureddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGh3bFRcmXF9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709265807606?e=2147483647&v=beta&t=by_wqZGCthOdp7JUXmaAq2BzezX0jeThJxJ370-YDV0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Manikanta',
        lastName: 'Gorle',
        emails: ['mgorle@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFZDp1ns44KRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1706257784956?e=2147483647&v=beta&t=tXrOhDlrajTeAn6URCfavbhGUzu1u2YSLnB4pehZuhg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Laxmi Ankit',
        lastName: 'Manchella',
        emails: ['lmanchella@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFVyyWsWRHrPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658900895712?e=2147483647&v=beta&t=PBZlvZhgSHVsomihvAJEjksBiEGQCZbdDt-lWC_rh4Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aerieconsulting: {
    name: 'SIGNET, A Pavion Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Greg',
        lastName: 'Sparrow',
        emails: ['gsparrow@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHpFC0V_za-WA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684433872011?e=2147483647&v=beta&t=7gJAKQXGYmVqBQOKWR78mBFplpNUUmFYKFBlmIAhGYs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Bradford',
        lastName: 'Caron',
        emails: ['bcaron@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHv-oKEKQSdbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516921244758?e=2147483647&v=beta&t=wdq-ggqsdxMNmZBlcbsdLGa11fhJO0BSb1NLzk0r6eg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joe',
        lastName: 'Blaney',
        emails: ['jblaney@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFpyqOKwEyymA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635867387418?e=2147483647&v=beta&t=c7kFgRALF3bwlxFNTF5bC0gw_dQCITsTfBnBZ0_v8rg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'John',
        lastName: 'Skayne',
        emails: ['jskayne@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Zachary',
        lastName: 'Callender',
        emails: ['zcallender@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFaq2qu0eaWig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517883024930?e=2147483647&v=beta&t=TSoCJMFrfBKsZ7NdIv1bKbybYNyrqvJ4h7sspPZgvRA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  upshelf: {
    name: 'BDO Advisory, Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Adriena',
        lastName: 'Faam',
        emails: ['afaam@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ryan',
        lastName: 'Geluk',
        emails: ['rgeluk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEvd2PSMwu7VQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638892170037?e=2147483647&v=beta&t=VzeCdzyZUH890VbDSbdBGg6iLP-KaYG4FnKFzAOAAvQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shayne Aguilon',
        lastName: 'Briones',
        emails: ['sbriones@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGypIXVwGwHQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516551766094?e=2147483647&v=beta&t=wrhiapiU2tcOFLY8L0TCl2seWyogjgeH3TNbUr_3hAk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Michael Newton,',
        lastName: 'FCPA',
        emails: ['mfcpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHJfielDPANKw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711385770017?e=2147483647&v=beta&t=RIPCum3i6hDhNhihgtW918RoMINEWRjgJcUM4xX0Tu8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Justin',
        lastName: 'Tu',
        emails: ['jtu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  arcadianinfra: {
    name: 'Aerie',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Brian',
        lastName: 'Polk',
        emails: ['bpolk@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lauren',
        lastName: 'Reilly',
        emails: ['lreilly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGchuv2oasebw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726673245983?e=2147483647&v=beta&t=QlfavSR_iU3wQPUAFOOE3fJTOomrBYi040YVI2Vsq9U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kandice',
        lastName: 'Kollada',
        emails: ['kkollada@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFuVCFm_jB89w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704730655836?e=2147483647&v=beta&t=4WIrJvWXkH_hUxYH409rFSUFk9-INKQq-_6XdcaPDuM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Christine',
        lastName: 'Soderlund',
        emails: ['csoderlund@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHb_UTw_DE24g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1668122311457?e=2147483647&v=beta&t=sUub4Iw6lY4U8VsEchzl7_TbDrjTRB5NyvOd7seW5JI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jill',
        lastName: 'Longo',
        emails: ['jlongo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHq2Mw9r8PEXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711464530076?e=2147483647&v=beta&t=immT-C_1ZxE1jFj9v16Z3lT2q4zn40swzXi9inCLFUg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  arclightconsulting: {
    name: 'Upshelf',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  medispend: {
    name: 'Vulnerability Research Labs, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Luna',
        lastName: 'Chiu',
        emails: ['lchiu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEeae2ELRX4HA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646768258135?e=2147483647&v=beta&t=bpze2hGG4UaspScL1KcgLN9Z5XlHKIEjVHGWkidgFoc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Beth',
        lastName: 'Mottar',
        emails: ['bmottar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEmCapO3eZ_YA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725360724390?e=2147483647&v=beta&t=SgP5G9SpkZQcIdYdUDgvfAMV3HTzvB4zscguwfof52U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Eole',
        lastName: 'Lake',
        emails: ['elake@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHS99xm4MUlyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689336055967?e=2147483647&v=beta&t=2j1Nm6qUOK6gws5XlPIkFeakHPSXywLYkhhnKBUIpXE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robyn',
        lastName: 'McGovern',
        emails: ['rmcgovern@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sean',
        lastName: 'Weeks',
        emails: ['sweeks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG7SLkqW6KLzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690858406717?e=2147483647&v=beta&t=DwrLQiXeBw5saq_GcmGYdhSYj8sYp9JZfYUWT4NRUQw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  golighthouse: {
    name: 'Arcadian Infracom',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Dan',
        lastName: 'Davis',
        emails: ['ddavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHU1IhgsCaL6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516323802499?e=2147483647&v=beta&t=GDFpYDCsC1LAvI8vw8UZduYXRO5iAKHxnCwFvpU05FM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Asim Raza, JD,',
        lastName: 'MBA',
        emails: ['amba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHBCvfaa_tABg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1540875329140?e=2147483647&v=beta&t=KauW-bcdTt1vW6EV1sf5R3uRLO9yUOQNi1vTvqDsivU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeff',
        lastName: 'Chapman',
        emails: ['jchapman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEYSotkTtpgyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651183575244?e=2147483647&v=beta&t=2JBnm8qNONj75oF_UJKQN7n6PJKGvf4RQz6z-4fdc6k',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brock Killen,',
        lastName: 'MBA',
        emails: ['bmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHiD9yDUiYeXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637069621496?e=2147483647&v=beta&t=z7bQxdQgQOZafBEJNBCJGAdbIvZiRTlcB43tGzxOafs',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'Firnschild',
        emails: ['jfirnschild@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  abelsoft: {
    name: 'ArcLight Consulting',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lauren',
        lastName: 'Koeppe',
        emails: ['lkoeppe@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGzY2yVT3M5kQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652367269990?e=2147483647&v=beta&t=LwHzpT2ZEzPUgO4s62tviadJ0kjjB_dkA63PbZh7XeY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jenny Bundgaard,',
        lastName: 'PMP',
        emails: ['jpmp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGr9V8uNZUibA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672892584860?e=2147483647&v=beta&t=nJstW6eaI1me0DTqDuC8kPe2oAgjbxehtQcfzWZYSjw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nicole',
        lastName: 'Norton',
        emails: ['nnorton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kelli',
        lastName: 'Rightmyer',
        emails: ['krightmyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHtyCKlMXkM4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619031821623?e=2147483647&v=beta&t=aVsUKvtWG34SSGOrpqhT4iYYG3q5qcRRWoB_t0nOhyY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jack',
        lastName: 'Boyles',
        emails: ['jboyles@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  freebalance: {
    name: 'MediSpend',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Craig',
        lastName: 'Hauben',
        emails: ['chauben@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Seth',
        lastName: 'Houston',
        emails: ['shouston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFRjzNHpqG2uw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1573789786164?e=2147483647&v=beta&t=UdwwqEO_IB5uDpR69VAiNPa29L5LLa9LtPkg_qmjBYU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Suj',
        lastName: 'Patel',
        emails: ['spatel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGHmnMe0P-6YA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579538465958?e=2147483647&v=beta&t=Qm8hGbkmknd6AE1pIQq3cfxeIv0aindEayNq32HE9lE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marc',
        lastName: 'Bodner',
        emails: ['mbodner@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian Flaherty,',
        lastName: 'MBA',
        emails: ['bmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHaj1klscNbdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1615896466693?e=2147483647&v=beta&t=jA-EvfuwYU26SfPRkEXn1Vved8LrdHKueou2Vc-r8d8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tdk: {
    name: 'LWS (Lighthouse Worldwide Solutions)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'Jameson',
        emails: ['mjameson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGAHMVUInt3og/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1703617885882?e=2147483647&v=beta&t=PLzVrLFdXZF4Kg6ixMXtAKazdMqBNgUft9QViubuh7Y',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paul',
        lastName: 'Newman',
        emails: ['pnewman@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jason',
        lastName: 'K.',
        emails: ['jk.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Peter',
        lastName: 'Maguire',
        emails: ['pmaguire@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFhOl8eUmxQrw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696093593380?e=2147483647&v=beta&t=hJF8JqKRDnq2ki1gQJwFRQ9CEqHT5vBbzFcOUXbl_-c',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rebecca',
        lastName: 'Perry',
        emails: ['rperry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFUa0Ak-5YyoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651340852140?e=2147483647&v=beta&t=l34WCstDPIHLmJEYN2oFW5hX8tWD-TqljjTL3_emKdo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  labelmaster: {
    name: 'ABELSoft Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Lukas',
        lastName: 'Mills',
        emails: ['lmills@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGnZ6MKVuO83A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1555768119140?e=2147483647&v=beta&t=1iSKlJCRCrztJpepdMy_sPOWlHzT3e-jSPblGDlEndQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Angela',
        lastName: 'Spinks',
        emails: ['aspinks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEREb2ri7nrgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516850268302?e=2147483647&v=beta&t=Yvf2BT_-nckRviv3ZSkmtWi3neBR0BE1kUUs1ncpVw8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Arun',
        lastName: 'Rele',
        emails: ['arele@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGDOQJzEBNHYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516726693057?e=2147483647&v=beta&t=O8Khi9nMdI_4zvT0t1ikvemqlRv0JFl0Pp0MNm3Z3RY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Man',
        lastName: 'Vu',
        emails: ['mvu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGa11y-IJB0Lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727711297283?e=2147483647&v=beta&t=Mo_umS_Ze7kPgf8D3S7aYr1UEsIQB9HT6w5pT-boFbM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kel',
        lastName: 'McKay',
        emails: ['kmckay@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQED2LxqHK7VNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516574762375?e=2147483647&v=beta&t=bVWntSAX0TloUK_D58M9FjhOknEgfjsMqkudVLBFlNE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  prowritingaid: {
    name: 'FreeBalance',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Free',
        lastName: 'Wortley',
        emails: ['fwortley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQERd_UfXXQcGg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687719773151?e=2147483647&v=beta&t=o4F5Vl7IXcd3Wv7W3ZS9Q3WG3JCsazukUQZ6dz9Pn84',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Manuel Schiappa',
        lastName: 'Pietra',
        emails: ['mpietra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF3yD1Fr6p_iA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516265020967?e=2147483647&v=beta&t=UPClD2akRB6rVWVAybOV0ouxO09QWVE0NKxdSH7zaS8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'AJ',
        lastName: 'Balance',
        emails: ['abalance@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHMilW5hJEPCw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639167534820?e=2147483647&v=beta&t=8f2NYZOxfdnWk6SkaqTXKzuc7cseuKOQw-NN-csSo98',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sherveen',
        lastName: 'Mashayekhi',
        emails: ['smashayekhi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGK2-fZeoDaVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579756045633?e=2147483647&v=beta&t=ZCHqaRoWWcvQgVOquE8FoR-o7kmJY33ddJviyODHg3I',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vicky Free',
        lastName: 'Sistrunk',
        emails: ['vsistrunk@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHLMco1Op5jBg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678886578746?e=2147483647&v=beta&t=gU0hUl-_kv9DKwN8UPg2KW2RPaHa_X7YfPYMXIYzhKQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  syss: {
    name: 'TDK-Lambda Israel',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Noa',
        lastName: 'Benyamini',
        emails: ['nbenyamini@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGKUcGyL4G8Vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598527290196?e=2147483647&v=beta&t=d9Ior_nkxuI0bVkE9GirAjU-X_m9bjPkmhhM1weM3lE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eyal',
        lastName: 'Federlein',
        emails: ['efederlein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHj3DPicQ3BBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704026159114?e=2147483647&v=beta&t=gi15umO8iWIPcjRl0vj_6NZRIBViXCZB4LkovUUk908',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Shafik',
        lastName: 'Toubi',
        emails: ['stoubi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHnWg2KM7limw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678530522101?e=2147483647&v=beta&t=JNdxpUfpcbl7rmufo5Zw6X1ZnclZaw7RuO-QHI9r1pI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Erez',
        lastName: 'Bar',
        emails: ['ebar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFvZ95yh6j9yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1597575663196?e=2147483647&v=beta&t=76W75h3iTJ8Z-nw0716YsqVo-No2puHfsaVGEziRXJM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yariv Eyni',
        lastName: '🎗️',
        emails: ['y🎗️@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ziaconsulting: {
    name: 'Labelmaster',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Alissa',
        lastName: 'Green',
        emails: ['agreen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEsdRUQviHV6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661290402185?e=2147483647&v=beta&t=u7m3RkROBE24z74nzyFrVdJ9fkRbu99ZSgV40udMZqE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brandon',
        lastName: 'Yost',
        emails: ['byost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFX8Q1CBzFYtw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719014876610?e=2147483647&v=beta&t=lNIrbtfhy7Rtr31ytEcVeUwdtMc3d8eURgUPmi62jto',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Manuel Fuentes,',
        lastName: 'CPP',
        emails: ['mcpp@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFPpBT4XZTtXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570138318245?e=2147483647&v=beta&t=t9MpxW9WeKGELwpKUciNEO8UAmA731Bc8ei8b-8RqJY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Alan',
        lastName: 'Schoen',
        emails: ['aschoen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Robert',
        lastName: 'Finn',
        emails: ['rfinn@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF4stcnL2g8kg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682804773352?e=2147483647&v=beta&t=ZPItpFod4Rc5yveYjY18B7SVobQ_0-uOFnhJ8vfoqHc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  routesmart: {
    name: 'ProWritingAid',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rachel',
        lastName: 'Nurse',
        emails: ['rnurse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGOd7ZdANgZqA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686673221236?e=2147483647&v=beta&t=_YnWklEF48FgbbPj1n55p1qJH01OIcdCK1AcszXErlM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Freddie',
        lastName: 'Beesley',
        emails: ['fbeesley@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFMRKHHC1YLLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582541428378?e=2147483647&v=beta&t=xBsdCTUa10KHrXqrglolqLYUq9xYtdcR_NrWBDkPwpE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Regan',
        lastName: 'Pro',
        emails: ['rpro@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEVqra-OLVOWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1565131705051?e=2147483647&v=beta&t=UOqxNu6gbaZlKWAsfR513nC-DOMIiipUrcXy7Olnb0I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Dinsmore',
        emails: ['cdinsmore@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Donna',
        lastName: 'Knutson',
        emails: ['dknutson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGXvNpLYyHyKQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1557860341434?e=2147483647&v=beta&t=0MPE1685XHswrlmZbqA-FTO8GWrPqqWaTZQAYBOO49k',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  intellyk: {
    name: 'SySS GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Yvonne Obrecht – Geschäftsleitung – Publix AG Werbeagentur',
        lastName: '...',
        emails: ['y...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGnP1ACypGf7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517058042351?e=2147483647&v=beta&t=VNEYePnVGxFNWhwWut0TWMUr9obrLxFJEOgZMMKpgl8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName:
          'Fredy Obrecht – Eidg. dipl Werbeleiter, Unternehmensinhaber',
        lastName: '...',
        emails: ['f...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFihOktuH6jZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517540234418?e=2147483647&v=beta&t=dPUYDhlp3tBlbTZyCD-gRxErPIc9ZrlNoTsC9Fkwhvc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Silvia Burkhard – Kommunikationsleiterin – thunerSeespiele AG',
        lastName: '...',
        emails: ['s...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQE_Rlj0DJz0ag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516502161002?e=2147483647&v=beta&t=WpnE4Q-niu-xrxSPbK4SVtHk9mz3Q7r0rHBJIhfGHSk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sabine Deinhofer – Senior Professional Quality & Knowledge',
        lastName: '...',
        emails: ['s...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Philipp Kucher – Geschäftsführer – PUBLIX WerbegmbH |',
        lastName: 'LinkedIn',
        emails: ['plinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHyXA3MrqZ_eg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620123257491?e=2147483647&v=beta&t=gSyHAjqf1yUvKFAWloUWZYKxrkehwr0UId23L00nUdM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  awcape: {
    name: 'Zia Consulting, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeremy',
        lastName: 'White',
        emails: ['jwhite@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFuIN0Mi7B-tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690831869087?e=2147483647&v=beta&t=h5e7V8X_zI3gsQJ9P1Rhv1g9btukpnMphlXO2_ILsZM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shelly D. Mahon,',
        lastName: 'Ph.D.',
        emails: ['sph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHfb7WzA9fM8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718212417253?e=2147483647&v=beta&t=kvgpA3ttyJM3_ALdl9wznMPDEAd1ElAcvFwbPgZayIQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gi',
        lastName: 'Lee',
        emails: ['glee@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ernie',
        lastName: 'Bahr',
        emails: ['ebahr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH95bcwWsp_tg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1583264259497?e=2147483647&v=beta&t=iJQvSNAhWInbZC2qDtpc0zXYs16f01Hz6U1T5WWPkPc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeff',
        lastName: 'Barry',
        emails: ['jbarry@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  amrasia: {
    name: 'RouteSmart Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aaron',
        lastName: 'Nuffer',
        emails: ['anuffer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGws4D16gkTgA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516585358605?e=2147483647&v=beta&t=8ul2KanFgdH3vPBjGhf9zKrzOTHVeHbU08Fn8w0ix8g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Larry',
        lastName: 'Levy',
        emails: ['llevy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jessica',
        lastName: 'Cearfoss',
        emails: ['jcearfoss@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQERWYWHVzmNYA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516782685594?e=2147483647&v=beta&t=8e_rqqNXHaWuT-qePb-HOPAjInhtzEOKbt8cRwDBmU4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Damon',
        lastName: 'Gulczynski',
        emails: ['dgulczynski@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jon',
        lastName: 'Knazur',
        emails: ['jknazur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEGXwnhVEKcnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579132451153?e=2147483647&v=beta&t=iFhjsN1i2Ksa2ZCRuIqcUa2Qfzfi4R6mKxx7wJuIl9Y',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  inovium: {
    name: 'Intellyk Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vineet',
        lastName: 'Kumar',
        emails: ['vkumar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pragati',
        lastName: 'Jain',
        emails: ['pjain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFXoUwwjsNCjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708973185992?e=2147483647&v=beta&t=4Ae6CRE8mtfdChk6rOg0OQGOTtd27Bj3t1QelfV4zz0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Manoj',
        lastName: 'Tiwari',
        emails: ['mtiwari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHdMGb5kPY0aw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710431129620?e=2147483647&v=beta&t=ka97JBkxuHQ9YuiApA8bmY3_lDUMQLqfMYrx0xmKx94',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mary',
        lastName: 'Palaparthy',
        emails: ['mpalaparthy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHNPIuDgOVcPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517270211358?e=2147483647&v=beta&t=KdIh89Hf7LnzQ56CCHm64alo-LhjAy0UBZRnGYOCV4Q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gayatri',
        lastName: 'Choudhary',
        emails: ['gchoudhary@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFAA4NG-S793w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728353323727?e=2147483647&v=beta&t=KyvzzMHXceeTSbnntWviYrP2EEpVH8vuoW96DtYkKxg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  services4It: {
    name: 'AWCape',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jeff',
        lastName: 'Ryan',
        emails: ['jryan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFG0xA8NcHwRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660652251893?e=2147483647&v=beta&t=PneAs6w2Go8tMl05iP0inCR8n_rsNoxh9jzB_lPrqos',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adriana',
        lastName: 'Prins',
        emails: ['aprins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFUrYpwRMCi7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660815494674?e=2147483647&v=beta&t=qjy306s-3uQatVMA_BXJvnpG6w9CJwL1CvqVxus6SH8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Del',
        lastName: '',
        emails: ['d@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQETUeU0IsH8ig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679915860573?e=2147483647&v=beta&t=c6nfp_cnq95Pxw4bLA7jAGPvHJFAdGh9LdPGt4WnMSk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matthew (Matt)',
        lastName: 'Lescault',
        emails: ['mlescault@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHlZ8CmDg-0vg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658164695826?e=2147483647&v=beta&t=7zUcTtyxW4b3quE645hBUFhljW376pMHpD0iJBAHsxE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: '',
        emails: ['j@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rapidratings: {
    name: 'AMR Asia',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  millims: {
    name: 'Services4-IT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jaydeep',
        lastName: 'Dafda',
        emails: ['jdafda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF-qN5m_v34rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1650355261464?e=2147483647&v=beta&t=nmbtYwPSaQYZ9as_GuEcXQ6vztKOrhJlpD8WW8-xZEY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'SARAVanan',
        lastName: 'Sundaramurthy',
        emails: ['ssundaramurthy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG0Xb6ThsJt9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1533596345036?e=2147483647&v=beta&t=v7kqeKzt8-cTpQp4DsFu0a34FJ5ZHGZ_hhCIPJZX0TM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gaurav',
        lastName: 'Vasava',
        emails: ['gvasava@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF4MwvZ8o9PBw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1679134620547?e=2147483647&v=beta&t=3e5qcnmbDiyj27Q8lMy9uqgPz2daiNMWMmcdfcLx5bA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Teréz',
        lastName: 'Pápa',
        emails: ['tpápa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGPpBbTX0OfAg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517287572356?e=2147483647&v=beta&t=R5eXlFPukScDNEiFb0qY7HmFX8yZDSreisRv5jdFTgw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Don',
        lastName: 'Lawrence',
        emails: ['dlawrence@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sincera: {
    name: 'RapidRatings',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric',
        lastName: 'Evans',
        emails: ['eevans@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGlTRoB88zEyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516273535538?e=2147483647&v=beta&t=D7qFGQ-dE1RNDS0kKj6jWxjpHrJFl0ZMji3x5MXn-KI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Douglas',
        lastName: 'Cameron',
        emails: ['dcameron@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQETMi-fhMm1QQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519682033146?e=2147483647&v=beta&t=9PIocm3sxR9PTFLMy3vZpz5U_pR0EUfZXbHkJKevV6Q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tim',
        lastName: 'Wilks',
        emails: ['twilks@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEcgfJ6VNvhIw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516580562067?e=2147483647&v=beta&t=ddPVe79n6-eyFNbNI6Yv3KYD7hP7eY0UNKRoCDsjOlg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ed',
        lastName: 'Chambliss',
        emails: ['echambliss@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Patrick',
        lastName: 'Caragata',
        emails: ['pcaragata@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  webmotech: {
    name: 'NetSentries Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anishka',
        lastName: 'Shukla',
        emails: ['ashukla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHBC3NJZNSRrg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675342598256?e=2147483647&v=beta&t=T54sTYuA9n5IZMBO2j4uy9BWUKqGmYIX6cKR8ejuJJ8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Purab',
        lastName: 'Parihar',
        emails: ['pparihar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF7xei0u_jdTw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708945200754?e=2147483647&v=beta&t=zvr0VyVAPk2AmoI34EkqHnyKh_hhNjcxvQkO0MWXmUk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Arun',
        lastName: 'T.',
        emails: ['at.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGYl62RbnN17w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721491600455?e=2147483647&v=beta&t=EwuVojDJTDuhgYlYGp0bjUpEhfK0iO2ZoqbESgJofFM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anu',
        lastName: 'Mathew',
        emails: ['amathew@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ravindra',
        lastName: 'Lakhara',
        emails: ['rlakhara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG_xYWjNrpCNg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728787019601?e=2147483647&v=beta&t=nG8toyHtOi_3wYQfa9QedLQN5O2PGEGY1jGDaWeDsmo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  inventi: {
    name: 'Millennium IMS Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christina Millim – GvW Graf von Westphalen |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHidOKTde5y4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549840841939?e=2147483647&v=beta&t=Q8nixIPN9ANS-02ZH5v9Ssdn87BGhfcHZlaB-i18Xuo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sylvester',
        lastName: 'Stephen',
        emails: ['sstephen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrea',
        lastName: 'Wehgartner',
        emails: ['awehgartner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH9N8ncaCCX8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516349270818?e=2147483647&v=beta&t=vS0FijgElbvJdQP4Mav7aQsLHWwDCqlL1sg6BecRGac',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Runaly Chris',
        lastName: 'Misquith',
        emails: ['rmisquith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emily',
        lastName: 'Mullins',
        emails: ['emullins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFEKBQN7ivtMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647802854505?e=2147483647&v=beta&t=wm1IAE6iNY9Rws_Z7h4wK6TnYZBzUVk-cU2qLS-lp7M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  idex: {
    name: 'Webmotech Pvt Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kasun',
        lastName: 'Gamage',
        emails: ['kgamage@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG2slC3BDb11g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683958517748?e=2147483647&v=beta&t=mzZtd-0rEu4CmerFLjYCjObEeb-IOLrNFgrSjabUmdk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kavindu',
        lastName: 'Nimesh',
        emails: ['knimesh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEV9dz3rbfl_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724182291425?e=2147483647&v=beta&t=ObTaayRzVl8TAFcQe2PRQEwN-IEAKeq399j5SPlOxIc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ujitha',
        lastName: 'Migara',
        emails: ['umigara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFVcRZOFgl5lQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718792962655?e=2147483647&v=beta&t=deCUOZm2l-AvM2nRTwYrhTT0mHJNRQq48ejC9gSHVjo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Hansika',
        lastName: 'Perera',
        emails: ['hperera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHlCXhq9L-Bzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709115604352?e=2147483647&v=beta&t=oy6i3A__7N9VEHki37LIY--Uhc49lJO_3PTFfmWX47U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Yasiru',
        lastName: 'Jayashan',
        emails: ['yjayashan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQG631Wgep3MQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683437768860?e=2147483647&v=beta&t=r9eFUjkzns4dBgauOv4dQlD1MRG4801LPe-EeBKJAvI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  paradyme: {
    name: 'INVENTI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  strategic: {
    name: 'IDEX SERVICES COMPANY',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Catharina',
        lastName: 'Eklof',
        emails: ['ceklof@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Fred',
        lastName: 'Benkley',
        emails: ['fbenkley@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Anette Trulsson',
        lastName: 'Corda',
        emails: ['acorda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFh6LLbBnnIWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643456464371?e=2147483647&v=beta&t=7kgGUmxXnWRMzUQetmadCkLQZnJAt9zXZx_aCxTlgWE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Petter',
        lastName: 'Andersson',
        emails: ['pandersson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anthony',
        lastName: 'Eaton',
        emails: ['aeaton@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  coromatic: {
    name: 'Paradyme, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bryan',
        lastName: 'Na',
        emails: ['bna@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kyle',
        lastName: 'Hassold',
        emails: ['khassold@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEdVjjaYH9u8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600663718865?e=2147483647&v=beta&t=Ddxx5WoZ2c06NcAeMJinFD737L6249Q6AJkHrOeIJ5M',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ryan J.',
        lastName: 'Garland',
        emails: ['rgarland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEKgbIXqtqqdQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1605556046746?e=2147483647&v=beta&t=8WJa7oZDy-ZkUVGGPuaYUq6pHd1e8G1FKPhvxjufxp0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Eli',
        lastName: 'Hernandez',
        emails: ['ehernandez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF7A1PWmUUG0A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655229060101?e=2147483647&v=beta&t=Z1gTqu2jXZr6QpMXH89U1EuROsw7faK44j7c-so_L2s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sang',
        lastName: 'Na',
        emails: ['sna@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  engieimpact: {
    name: 'YantrAdhigam',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Krishna',
        lastName: 'J.',
        emails: ['kj.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abhimanyu',
        lastName: 'Acharya',
        emails: ['aacharya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGLPp4f7aLZAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724835019563?e=2147483647&v=beta&t=Um4HkNprmmgTX34Z8yjBMHxgI6KTxkAASTbRu2b7its',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Hiten',
        lastName: 'Movaliya',
        emails: ['hmovaliya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF_fXwilnq1yg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715084315527?e=2147483647&v=beta&t=_HyJY22M0m-h9UnRGrTCQ4GiJ2lJyf9HYkS-zdoE8yk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Pareshkumar',
        lastName: 'Joshi',
        emails: ['pjoshi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFnydj0xDAXZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724499849497?e=2147483647&v=beta&t=2GczrJG84IhkUYhSuKwT0ohlMUpAAfaaNo9ZGfJyub8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nikita',
        lastName: 'Chhatbar',
        emails: ['nchhatbar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  schoenbergerGroup: {
    name: 'Strategic Information Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael A.',
        lastName: 'Kelly',
        emails: ['mkelly@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEMx6VmyEkBtg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1651545275281?e=2147483647&v=beta&t=tgAA3utLIwOQhyy2_HZhXgBMsS-wOZKdoiC1rbsqjT0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Doris',
        lastName: 'Cheng',
        emails: ['dcheng@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQECNxzKBrwHVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1632625655369?e=2147483647&v=beta&t=Xm_-fOaKE7jAoTPGQbkMK6VKhKd1LFsqmP-86Xnh7YU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Melinda',
        lastName: 'Quilici',
        emails: ['mquilici@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHw8HsYtitbwg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1618127013510?e=2147483647&v=beta&t=BZhnzgqhQ2WnujHD8IaDaisQtYwGw9nyNPKIVcnQCs8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrew',
        lastName: 'McDevitt',
        emails: ['amcdevitt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGoVVhWNR1rKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517677370455?e=2147483647&v=beta&t=8eJ594JmA2h_ZHIALIQas3tb7jg2ByeY2J-fwGHolic',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ashcon',
        lastName: 'Minoiefar',
        emails: ['aminoiefar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHsXlNDcKNPwQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682356672293?e=2147483647&v=beta&t=dp0Imgg0vAWeIbDPcDQQD_tb6d1bA4qQdfVj5WhD8E4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ulula: {
    name: 'ENGIE Insight',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Frédéric Juillard – CEO & Founder – TreaTech |',
        lastName: 'LinkedIn',
        emails: ['flinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Raquel Zambrano Varela – COO and',
        lastName: 'co',
        emails: ['rco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH8JTm9N2id2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666690459448?e=2147483647&v=beta&t=XImedWyP41Izyuw3Zy76jyUpX82HMWWx2r1pVRHJLuI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gaël Peng – CTO &',
        lastName: 'co',
        emails: ['gco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH94qgOCFgxZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1534759203614?e=2147483647&v=beta&t=-Ql66ULHxAnWh2veo2-olkr-NMyU_3KvaGo-Le_GBHU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Juliette',
        lastName: 'Mizon',
        emails: ['jmizon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Virginie Vinel Kolovos',
        lastName: 'MCIM',
        emails: ['vmcim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  soracom: {
    name: 'Schoenberger Germany Enterprises GmbH & Co. KG',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Schoenberger',
        emails: ['rschoenberger@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brandon',
        lastName: 'Schoenberger',
        emails: ['bschoenberger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEKP-f4LXlXiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712966785547?e=2147483647&v=beta&t=0RN6zkqxaaXxjIi1p9km-Md44iIRJQ_njYUNXhorrxw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Scott',
        lastName: 'Schoeneberger',
        emails: ['sschoeneberger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHyBPBmmq8SQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723378182803?e=2147483647&v=beta&t=_x6FXSSqn1BfIcnCJotx9t_TFVPidD_yQDs3A0sZIF8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Erich',
        lastName: 'Schoeneberger',
        emails: ['eschoeneberger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEevLanf6Ny9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672808101139?e=2147483647&v=beta&t=eQJztrqfGTNCpn9681TcYfNeKcmfMrz-ZcbGaSwx4jU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Todd M.',
        lastName: 'Schoenberger',
        emails: ['tschoenberger@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFZePmg81NL4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716328435322?e=2147483647&v=beta&t=DFszLfemT4O9Bfexy5EyWP9htyJor64xHPdYe9TVL5c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gripcorp: {
    name: 'Fiserv',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Prasanna',
        lastName: 'Dhore',
        emails: ['pdhore@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEYx4_0NKFlRQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658698856284?e=2147483647&v=beta&t=oGAUOeD9ntxzuZc-IB5w1K7X-GXo9PVxUtmgU60S1Kg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Michael',
        lastName: 'Wynston',
        emails: ['mwynston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHK_aTG-O4Pfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715292235547?e=2147483647&v=beta&t=g7jhSdIKydf5DWxT0EaiDJ6CoJI8lgw4l9ut5_p7_yk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jennifer',
        lastName: 'Manchester',
        emails: ['jmanchester@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGZMurNlhEtJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690113752258?e=2147483647&v=beta&t=m-sKWxZzVN8cesSa7GOFii58BRuPZxUGyQVFtZ9kWCE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Rasco',
        emails: ['crasco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEC3vIN1re7lg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1705617251139?e=2147483647&v=beta&t=y9K1gAlwzk0f4LIvRdcUvn0TzT-nHOku_RLseqry0QA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Guy',
        lastName: 'Chiarello',
        emails: ['gchiarello@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nobelglobe: {
    name: 'Armorize Technology Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Keerthi',
        lastName: 'Chavva',
        emails: ['kchavva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEausBNxQzq2g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1602086825649?e=2147483647&v=beta&t=mPOlh-qG6D2oSXWR6BjTl2pgOXQQQxRcfTmhpzYYtqg',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: "Peter D'",
        lastName: 'Souza',
        emails: ['psouza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEZ6GnJfyAhOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673437661397?e=2147483647&v=beta&t=M7JdhZ-feun4TniDXSx9FvPuASVj2bsCTYpsBoI5dNg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ashok',
        lastName: 'Gundlapalli',
        emails: ['agundlapalli@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGP16yYnmffQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676356740448?e=2147483647&v=beta&t=58kVwdSgaCgyJOeelF3crcgBD8mXb5Ez5M9SSsLSLpA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Balababu',
        lastName: 'T',
        emails: ['bt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH9mIg_g7sB9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701091914392?e=2147483647&v=beta&t=JuixTkT1thVSXppMsuQMLu-AxrFWOrcnfLLPyIWr3RY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sridhar G',
        lastName: 'Nelapatla',
        emails: ['snelapatla@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHt63oSJmvfgw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695471871325?e=2147483647&v=beta&t=-4HbXRWDBAzpcrAnz00g50DRkgTNNDrZO-xvLj8unTw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  foretellix: {
    name: 'Ulula - An EcoVadis Company',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aparna Moitra,',
        lastName: 'Ph.D.',
        emails: ['aph.d.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Antoine',
        lastName: 'Heuty',
        emails: ['aheuty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGYc49S4UTTlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574719861100?e=2147483647&v=beta&t=xN6hoW934vyGlx9k01tOuFnJ69p1VB28NhTJlkgv8fA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andres Mauricio',
        lastName: 'Fernandez',
        emails: ['afernandez@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Haider',
        lastName: 'Ali',
        emails: ['hali@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFSUE8YXc8mfA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1649411977378?e=2147483647&v=beta&t=WWQEZrmtrd1pwQ_-kQXtgbf9TG6kJtOV3l2zzQ9LDL8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lisa',
        lastName: 'Fedorova',
        emails: ['lfedorova@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  simplydeliver: {
    name: 'SORACOM',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kenta',
        lastName: 'Yasukawa',
        emails: ['kyasukawa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Brenna',
        lastName: 'Belletti',
        emails: ['bbelletti@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHIk5nQIuReRg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603471146425?e=2147483647&v=beta&t=Lnk-WmGVBXO2uB9JNVpTME1sYVK0jpy4UTrjujrgpcQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Taku (Tack)',
        lastName: 'Tamagawa',
        emails: ['ttamagawa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Colin',
        lastName: 'Shave',
        emails: ['cshave@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFNqs4LNe7Qzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594144868447?e=2147483647&v=beta&t=ziU2HQv5OUoj1AMeXLp3GOKjvYw3tqc2LtH9tgEeaOQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Brian',
        lastName: 'Johnson',
        emails: ['bjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEyrpEGxU2ffQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516564495160?e=2147483647&v=beta&t=783lg6c3wIjkR5htkKk4GDdJTNYkjjLYY4feuINPxAU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gearinc: {
    name: 'Millennium IMS Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christina Millim – GvW Graf von Westphalen |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHidOKTde5y4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1549840841939?e=2147483647&v=beta&t=Q8nixIPN9ANS-02ZH5v9Ssdn87BGhfcHZlaB-i18Xuo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sylvester',
        lastName: 'Stephen',
        emails: ['sstephen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrea',
        lastName: 'Wehgartner',
        emails: ['awehgartner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH9N8ncaCCX8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516349270818?e=2147483647&v=beta&t=vS0FijgElbvJdQP4Mav7aQsLHWwDCqlL1sg6BecRGac',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Runaly Chris',
        lastName: 'Misquith',
        emails: ['rmisquith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Emily',
        lastName: 'Mullins',
        emails: ['emullins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFEKBQN7ivtMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647802854505?e=2147483647&v=beta&t=wm1IAE6iNY9Rws_Z7h4wK6TnYZBzUVk-cU2qLS-lp7M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dantatechnologies: {
    name: '그립컴퍼니(Grip)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Eric/Pam',
        lastName: 'Marquez',
        emails: ['emarquez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH4tOoYjKIKMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516480862611?e=2147483647&v=beta&t=Ml4Hjfwf9Oih__R35HFpDFjkmbZQ13pDvEikmgxSZKI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Shawn',
        lastName: 'Procter',
        emails: ['sprocter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHxOAfaAoeZEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721788807475?e=2147483647&v=beta&t=O6HtTBpMoiddQQJsefzp8PxlePUyHkAK4zUg_aIlvM8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nick',
        lastName: 'Moers',
        emails: ['nmoers@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kris',
        lastName: 'Griffin',
        emails: ['kgriffin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG6uMFE5EQ89A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516953265968?e=2147483647&v=beta&t=6-IQtl9gUtnYvuelCx8rxYruJXiTSefBtd8hswtnl0E',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Trahern',
        lastName: 'Wells',
        emails: ['twells@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQENd4sPSWHgHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1690839768927?e=2147483647&v=beta&t=Qmy_lpVMZXbIwr0wZP25DKCIw_j70gw8I_sXSIRAQwk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  stiorg: {
    name: 'Nobel, Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shayonnita',
        lastName: 'Mallik',
        emails: ['smallik@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGeYNv3_saxfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709749453658?e=2147483647&v=beta&t=TnAfTWgiRWkymad_e1Hp-ekO4gj65tKuXYyGKM2V_aE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mario',
        lastName: 'Bushaj',
        emails: ['mbushaj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGIFdJ9kPfllA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729171918650?e=2147483647&v=beta&t=nVc2opu17wfvnw7nlsTve3O7Xzs90rmyKQewodUMfoU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Milica',
        lastName: 'Kostic',
        emails: ['mkostic@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Das',
        lastName: 'Nobel',
        emails: ['dnobel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG7Sf-bYdHT7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639050701875?e=2147483647&v=beta&t=KeverV4rbg19Wo8og0WfK9TdnUZtd42RtDyZM7KGmTo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gary',
        lastName: 'Eckman',
        emails: ['geckman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEmHd8-0bwinA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671990302876?e=2147483647&v=beta&t=VbcImA5KPDUODqEbkgpcAHv4d81k2-hefBSNV8Q0K40',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bigr: {
    name: 'Foretellix',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  abakusglobal: {
    name: 'Gear Games',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Al',
        lastName: 'Dimery',
        emails: ['adimery@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEqOHLEWvM-8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712701470669?e=2147483647&v=beta&t=RX3qEY4XzpGWc9KZchb3Lq_W6maFg9mQdugJPXTy-U4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kamil',
        lastName: 'Orman',
        emails: ['korman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGKcAX-5_5GSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691061964917?e=2147483647&v=beta&t=gi0mMUsOlWApJH0zDpXzKtVJ6G84jFFFgBLWfCBCUQE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ken',
        lastName: 'Tan',
        emails: ['ktan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHa5ywwwKUDYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635715634950?e=2147483647&v=beta&t=fVMVjQ6LuJGVlPFVI4O1m1KP-SH717ihu2XtV4h7mTA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Blake',
        lastName: 'Dwan',
        emails: ['bdwan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGgWcZQ_ffsNQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517791880313?e=2147483647&v=beta&t=NBBwEivv7JDuE0yj_6aRm5LehEojQmJkGGtd9S8HYZU',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Matthew',
        lastName: 'Bland',
        emails: ['mbland@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF1IxglEBKr6w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1674943826657?e=2147483647&v=beta&t=mcQkJSGx-c5wxfR0fuKGPMM-su6EvVMPCUrdHgh7rTg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  playboomsports: {
    name: 'Paragon Technology Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bruce',
        lastName: 'Card',
        emails: ['bcard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHNQN-OuqsoAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588002308246?e=2147483647&v=beta&t=fyZ9cBE8VuNeNFEfxfBuN4jGhdy1Kbga7ySKtIyNjUs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vivek',
        lastName: 'Gupta',
        emails: ['vgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHNUHzHKYOI4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517668913120?e=2147483647&v=beta&t=CaMpjcuy-cj1HPLHcIwfBwtfToPJcCGtvBWm7xIwkbE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jay',
        lastName: 'McAllister',
        emails: ['jmcallister@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'Nikola',
        emails: ['mnikola@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bill',
        lastName: 'Daub',
        emails: ['bdaub@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  serino: {
    name: 'Danta Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Danta Technologies',
        lastName: 'INC',
        emails: ['dinc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEyIoOhsgD2VA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517618817291?e=2147483647&v=beta&t=l0JKI44TGigOEhOvt5-wn0SVUn1xGpsc-1nMliyw7Xk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sudeep Kumar',
        lastName: '(Steve)',
        emails: ['s(steve)@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Phalgun',
        lastName: 'Satya',
        emails: ['psatya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF1tgAmQg-8tQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729883325060?e=2147483647&v=beta&t=C9IfsKPRMA6_CkdD5HdhwNlGd3moyo1LsChWtK_GxL8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kiran',
        lastName: 'Konda',
        emails: ['kkonda@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kalyan',
        lastName: 'K',
        emails: ['kk@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  xcellinkgroup: {
    name: 'BigRio',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rohit',
        lastName: 'M.',
        emails: ['rm.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFjgzo9EBVrZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516248791652?e=2147483647&v=beta&t=VDhPwn2K5bRMse0xs5wZM8AyqS7MtQcVyC6nxV0jWYs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jim',
        lastName: 'Deely',
        emails: ['jdeely@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG0WRkwX_3vXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726152555638?e=2147483647&v=beta&t=bRMdtFZyOooff2B9ObPDwhg28clEsaQwrqIVvilbYvw',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Corey E',
        lastName: 'Thompson',
        emails: ['cthompson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGbQvFFdRSR6g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1589494829402?e=2147483647&v=beta&t=-elAKD5d8Z3tozhQ-rPmxM-GMmiR_4QgIoNoA948bQI',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'William Foland,',
        lastName: 'PhD',
        emails: ['wphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFdkR71jxfpoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712589815606?e=2147483647&v=beta&t=RAfXwdT6L0M9_W2q6Skd7UmabYPoNBZv5u0KXfdh9oQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Priyanka',
        lastName: 'Talapatra',
        emails: ['ptalapatra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG26gcNWhq_Kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713996246718?e=2147483647&v=beta&t=b2-t83ykGmfEtVHY1dK6ZJ9NM0fpBrvcONr9a65g5IQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  technogenics: {
    name: 'Abakus Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Francky',
        lastName: 'Gob',
        emails: ['fgob@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abakus',
        lastName: '',
        emails: ['a@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHrASkBhd2M4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544780820919?e=2147483647&v=beta&t=5EhJ2F6sJXNbmdJiMHLX7WR8RNeohqELhGo4PByqlTo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ken',
        lastName: 'Warmington',
        emails: ['kwarmington@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGl9qPRWjn5SA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1535929516151?e=2147483647&v=beta&t=mYsYNa7ix7w5463XTDBK9O16zozuZHCOkhRXQTAy6fo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ayten',
        lastName: 'Aliyeva',
        emails: ['aaliyeva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEnhWhbv1nSiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704784173550?e=2147483647&v=beta&t=C8Rs0xFj8P2p5IY0gnBo0Av6mK9euDkQB2ACgwzrJM4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anny (Wei)',
        lastName: 'Luo',
        emails: ['aluo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  caseware: {
    name: 'Client Server Technology Solutions LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jim',
        lastName: 'Long',
        emails: ['jlong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGLl5PwYGPJdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516326623949?e=2147483647&v=beta&t=n948VbjtXxRovo4MPhc8PtUSRiQucvzDtbN1uObIXd8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Niaz',
        lastName: 'Ally',
        emails: ['nally@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Tricia Borerwe',
        lastName: 'CA(SA)',
        emails: ['tca(sa)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFU19KN9dSHJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718369826918?e=2147483647&v=beta&t=puG1jCfDcEpgOasOCyEFaAjxbsVh7xh8aQmLiLw6w5o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Robert',
        lastName: 'Ertel',
        emails: ['rertel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQH5tA7_iJVJ1g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516339782046?e=2147483647&v=beta&t=L_Wd9-6BmF1ivUV33IzoEc79iKh9GGskgTUeN8GgH5o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Milot',
        lastName: 'R.',
        emails: ['mr.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  dataservtech: {
    name: 'Xcellink Pte Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Isabel',
        lastName: 'Lim',
        emails: ['ilim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Clement',
        lastName: 'Teo',
        emails: ['cteo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQED80opN8f__g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637655671221?e=2147483647&v=beta&t=3VbgOOS5YpiuDr3mezH9BQR9bkGckncRI6mvapTsFsY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Farah',
        lastName: 'Shakina',
        emails: ['fshakina@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFo1zDZl8krOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663033792188?e=2147483647&v=beta&t=5hxkoVqdRptm3T03td_gB1zXH7KfW1XaSughogiSyiE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nurul Huda Ab',
        lastName: 'H.',
        emails: ['nh.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFVUiU_FlmXSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619416735351?e=2147483647&v=beta&t=hWwep1mIJOo28sRhBM8iiWmlaOOAAvfwFS23U7i2nsY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mohamed',
        lastName: 'Raihan',
        emails: ['mraihan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE6kAF63VNxOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517410150484?e=2147483647&v=beta&t=qICMQNYb22OaStRechTmB4--DmbP5kUNXydDxXm9f4I',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  compettia: {
    name: 'Jobkey',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'apdalla',
        lastName: 'moha',
        emails: ['amoha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF6aBxJJql9jA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1590431365566?e=2147483647&v=beta&t=vTeh2-I7WfRb9R48iq2czDrcU6F8qIp2pBdJASmQhdo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ismail muse',
        lastName: 'Abdi',
        emails: ['iabdi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Ismail Mohamed',
        lastName: 'Ahmed',
        emails: ['iahmed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFQ6K6X9y0ucA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1719030549166?e=2147483647&v=beta&t=3XhvNdYdLuRbIiiuRYOC3H5mlhuNNQHMBQdNdR4YvO4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Philipp Eckert – HeyJobs |',
        lastName: 'LinkedIn',
        emails: ['plinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abhishek',
        lastName: 'Mate',
        emails: ['amate@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHW7ltdmOJupw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517432955701?e=2147483647&v=beta&t=z4x3szvRNIPOqSTYjZ8Hg2fU9hvffelmfTy4Nxwk1Lc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  secureco: {
    name: 'TechnoGenics SMC PVT LTD',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Abdul',
        lastName: 'Majeed',
        emails: ['amajeed@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Qasid',
        lastName: 'Ali',
        emails: ['qali@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Hira',
        lastName: 'Ahmad',
        emails: ['hahmad@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Esha',
        lastName: 'Sajid',
        emails: ['esajid@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQET1MYbnpfDdA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720808018276?e=2147483647&v=beta&t=eZRG_Jeb1AHzrFEEA69Xe2fiH1xzCTq8TIpLcq_Hnp8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abdul Jalil',
        lastName: 'Memon',
        emails: ['amemon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGdoiYigjLbdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677568635256?e=2147483647&v=beta&t=GXt_OanALusV1BIoqVS-puetz0veJ__nsaupmsMe9pU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nxtbookmedia: {
    name: 'BLANC & FISCHER Corporate Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  siia: {
    name: 'HeroDevs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aaron',
        lastName: 'Frost',
        emails: ['afrost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGVBDkPbHtVXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726597007128?e=2147483647&v=beta&t=-pj44op61ZM1QF3ZHP6nNRwfwYOFwUshM4AQBZ3DwOE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Travis',
        lastName: 'Cox',
        emails: ['tcox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHpOIPRwF9gOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559663673743?e=2147483647&v=beta&t=ujj6q--M0iu9fRSmSmDbmM2nIlk6U2RGcG8ceJmHs9k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aaron',
        lastName: 'Mitchell',
        emails: ['amitchell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQElYPwJWlP_4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689098086132?e=2147483647&v=beta&t=ATtXT9zHRhsnhDxeJ9Jw6ubDE6HZUNGo_Bndv_6GDCg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jared W',
        lastName: 'Rhodes',
        emails: ['jrhodes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEeE20mXvvDEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611601748753?e=2147483647&v=beta&t=MQjVDlFLTFPq9FVbE3PEJ2bx1ElYHeqklUsvB9dhXmQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joe',
        lastName: 'Eames',
        emails: ['jeames@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHFPfzatU3sMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547194343742?e=2147483647&v=beta&t=ok7DedgG_5YMteR1mFAi2eKKD2counqI9cjFAg69lao',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  callcorp: {
    name: 'HeroDevs',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Aaron',
        lastName: 'Frost',
        emails: ['afrost@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGVBDkPbHtVXw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726597007128?e=2147483647&v=beta&t=-pj44op61ZM1QF3ZHP6nNRwfwYOFwUshM4AQBZ3DwOE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Travis',
        lastName: 'Cox',
        emails: ['tcox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHpOIPRwF9gOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559663673743?e=2147483647&v=beta&t=ujj6q--M0iu9fRSmSmDbmM2nIlk6U2RGcG8ceJmHs9k',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Aaron',
        lastName: 'Mitchell',
        emails: ['amitchell@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQElYPwJWlP_4w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1689098086132?e=2147483647&v=beta&t=ATtXT9zHRhsnhDxeJ9Jw6ubDE6HZUNGo_Bndv_6GDCg',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jared W',
        lastName: 'Rhodes',
        emails: ['jrhodes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEeE20mXvvDEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1611601748753?e=2147483647&v=beta&t=MQjVDlFLTFPq9FVbE3PEJ2bx1ElYHeqklUsvB9dhXmQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joe',
        lastName: 'Eames',
        emails: ['jeames@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHFPfzatU3sMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547194343742?e=2147483647&v=beta&t=ok7DedgG_5YMteR1mFAi2eKKD2counqI9cjFAg69lao',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  uniconIntl: {
    name: 'Caseware Germany',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Klaus van der',
        lastName: 'Meulen',
        emails: ['kmeulen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGm8ANGzhoS7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673848279010?e=2147483647&v=beta&t=WtluYeQbgGAK2KPuLuddJ4Lvl79gZnAns45qbIUeOdA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Zoran Jotanovic – Caseware Germany |',
        lastName: 'LinkedIn',
        emails: ['zlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFLqKHCCsgCAQ/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1541489916856?e=2147483647&v=beta&t=1Ny6WBoB6Uk8a9l0WP4jb1Fx7fYj2GOZa5JGplj30oA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Felix Hinrichs – Caseware Germany |',
        lastName: 'LinkedIn',
        emails: ['flinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGqK2nqqFYvEA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646927304198?e=2147483647&v=beta&t=svmgfIsn9to1SX_DL_VXk3Kk2C61wfv2ZX_c7MaaCdU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Klaus van der Meulen – Caseware Germany |',
        lastName: 'LinkedIn',
        emails: ['klinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGm8ANGzhoS7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1673848279010?e=2147483647&v=beta&t=WtluYeQbgGAK2KPuLuddJ4Lvl79gZnAns45qbIUeOdA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andreas Arntz – Leiter',
        lastName: 'IT',
        emails: ['ait@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ingeus: {
    name: 'DataServ',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paula',
        lastName: 'Tarrant',
        emails: ['ptarrant@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEyCBOOvM87ug/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1542407170562?e=2147483647&v=beta&t=Z-2x2dJEco9OOwsnXKb1uypNS2UeJi0XTUVL-vgnnUk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Benjamin',
        lastName: 'Rhew',
        emails: ['brhew@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQH0KJjhMTE4kA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610493340822?e=2147483647&v=beta&t=iEiuOQ4YItDU0jACWnEscw0FjTCEk8SpTSftxmcWbmo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kristin Beatty,',
        lastName: 'APPSC',
        emails: ['kappsc@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFi_WRQlyh3qw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1575327880026?e=2147483647&v=beta&t=KoLJPj-uF6nsByoGkRhXn_EbbQoRnp6oBhHxozWDyWo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jared',
        lastName: 'Gleason',
        emails: ['jgleason@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHfEn5K5FCDxA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517751632605?e=2147483647&v=beta&t=ia5YN5RlqR7QuXZJf3stat0M8QyqCkX3ShTUCZIIOHc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Antonio',
        lastName: 'Mattera',
        emails: ['amattera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQH1Er0DbjiJeA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1715271700476?e=2147483647&v=beta&t=XRZFh4pSawkFrbPOEU1tVvAv14g1lQ2g3HpfARV2nSU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  grapecity: {
    name: 'Compettia - Atrivity',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jaume',
        lastName: 'Juan',
        emails: ['jjuan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG5q-QDhHhIiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517681584143?e=2147483647&v=beta&t=qcdMHF1q9ljBdqnQr7XTx0oNAhSGZU5FkUR2JpR2lTA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jorge',
        lastName: 'Villota',
        emails: ['jvillota@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF71iX3fVOCjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709126168071?e=2147483647&v=beta&t=rlUE5lwAB1v0jwn1tgZ7onIuavd2GGfY4aDDGiTlF2c',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Santiago',
        lastName: 'Ubeda',
        emails: ['subeda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEZvLqoAEaH8A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517747387830?e=2147483647&v=beta&t=1jLBy66-PmStg-gjZfTZAr0lmExdveOZHxDi2TpdTCY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lucía',
        lastName: 'Hernández',
        emails: ['lhernández@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'Labanda',
        emails: ['dlabanda@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHCLXJ--HKJZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1552645739624?e=2147483647&v=beta&t=hNft1A5lxO_Y9BozFfKl0XZyM5ShXhJGQzc4pDxO_sY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  arcurve: {
    name: 'SecureCo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul',
        lastName: 'Bird',
        emails: ['pbird@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH3MY5QSNVQTg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655443601285?e=2147483647&v=beta&t=Kjt463eqlNOLXi0WudfAUYeMCHZLqUS9zWfHyTQqlGk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Glenn',
        lastName: 'Ives',
        emails: ['gives@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE8RinzyDJvQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516521223974?e=2147483647&v=beta&t=6YNiOHdRcCCesEBVE9K329EU1pTwlMZe4bzFVbCHixM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lakshmi',
        lastName: 'Murugeshan',
        emails: ['lmurugeshan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE0cWKjYw1LfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1522347053275?e=2147483647&v=beta&t=wbNCpfw73OXggg8xG_foKr83HYE9_TH3i6fS1FPDrEM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Guy',
        lastName: 'Coles',
        emails: ['gcoles@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEDyuRF_aVgYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516244309437?e=2147483647&v=beta&t=Rf8Z3UfvN5T9V89uvfNTpbTUG7c4PPGuyr_cGMOArE4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rick',
        lastName: 'Sauer',
        emails: ['rsauer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHlEFpkTNJ8hQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516876924605?e=2147483647&v=beta&t=DYxCyLLuPQRyDYy5SWcD2FqdjMnl5D5SEozDr1CsVJ4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  simsol: {
    name: 'Nxtbook Media',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Biggerstaff',
        emails: ['mbiggerstaff@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGihqC_JDFEHw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586267467755?e=2147483647&v=beta&t=E9Q_yALXMstVW4lqu1jxNs35GcEhH_lCK0OevnpgHV8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Matt',
        lastName: 'Guest',
        emails: ['mguest@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGSCD0g5U913w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517749918786?e=2147483647&v=beta&t=QhB6Xy-XLG3BLpCR9U8niJG9pif4TBt2TXae0gF-5LU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Todd',
        lastName: 'Reinhart',
        emails: ['treinhart@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHW-Wb8FTskyA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1695042120219?e=2147483647&v=beta&t=Sbf0V07fRVDzPV9EIsyl0_InFrs1iMb0WthLlvV0Dus',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brent',
        lastName: 'Hughes',
        emails: ['bhughes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG_IFdmVlyZOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516343913670?e=2147483647&v=beta&t=QhXlDxr6FUm6pklMsRKiGq_Y7B-HnwBCS69Z3sFuXSI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Philippe',
        lastName: 'Maurer',
        emails: ['pmaurer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGvhZSJ2LSSZg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578319529615?e=2147483647&v=beta&t=5GU2tqon2WJcOVOWybH7qFDlmbYByNcvZb98Sawd2sU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  drishinfo: {
    name: 'SIIA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sia',
        lastName: 'Nowrojee',
        emails: ['snowrojee@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sia',
        lastName: 'Nyorkor',
        emails: ['snyorkor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQECHMeXEnhJeQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1655767848005?e=2147483647&v=beta&t=lJN1B5ZLLHUYOQXWepNWSMAw0a8y27JXNTdjXIvKd6U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sia',
        lastName: 'Ramchandran',
        emails: ['sramchandran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFYcx6b1flFEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718845601102?e=2147483647&v=beta&t=QN5SiVfpRDkYsQTw6tpgAV5v2hUtqqfWBSrG9p6iRTc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Arniel Sia,',
        lastName: 'CPA',
        emails: ['acpa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGV5XJKNaMGqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729702510790?e=2147483647&v=beta&t=NZExvZGHd7KfCrqEr3KHMDU_eoM4CWuj_leGe17uyqM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hicham',
        lastName: 'Khodri',
        emails: ['hkhodri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEzres58_zAMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656744437947?e=2147483647&v=beta&t=QDNfj8sf94UjrTzFu57nmt5CDJgTbhcJeTXBRAgI7GM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  wiscenterprises: {
    name: 'CallCorp',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Kevin',
        lastName: 'Childs',
        emails: ['kchilds@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH6R8nbnGlqyg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614188914195?e=2147483647&v=beta&t=9ELTs_jLM1LyO6npxcQLQAow2nqTDotTNmxF2Ip1P9s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Mark',
        lastName: 'Bowland',
        emails: ['mbowland@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Greta',
        lastName: 'McMahon',
        emails: ['gmcmahon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHqzDTP9f7dEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669733470166?e=2147483647&v=beta&t=5ZDjFhkcCBlhExX7T_x1NF0yCTSvC2WHhMVKUbeYT70',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Randy',
        lastName: 'Call',
        emails: ['rcall@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mitchell',
        lastName: 'Goodwin',
        emails: ['mgoodwin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEIRYFYQD0cVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707192810906?e=2147483647&v=beta&t=p46IiVzdkVhltxlx85pmnVxhbREPdy0ZP4JcG-iJWNk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  parascript: {
    name: 'UNICON International',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anannya',
        lastName: 'Phaltankar,CPSR',
        emails: ['aphaltankar,cpsr@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEkv3CVeRGjVw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666718050545?e=2147483647&v=beta&t=4BeEiPtmkfRiyeMVXeSAqxi0GFRk19-VB-PkGdCqDEc',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jane',
        lastName: 'Lee',
        emails: ['jlee@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'McAlear',
        emails: ['mmcalear@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEPaEDWmdOKqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1559156236414?e=2147483647&v=beta&t=S_wj9DBhOZuaHFnyzdOjExh4BW6KjUBQma3jcitKHa4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jennifer McCann, MLHR,',
        lastName: 'SHRM',
        emails: ['jshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFIRc8mgBWknw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656098419247?e=2147483647&v=beta&t=8QXfU7CFqL8l95cwf0_q73Prn2Buf-xiHpcTtQj8TiE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mark',
        lastName: 'Morrill',
        emails: ['mmorrill@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHfZGzGQ1Gzfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517270999642?e=2147483647&v=beta&t=GygKP-a7GFe9Eo6sFUb_MjltVIyjlbrCifjzzzSEbuM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hkconsulting: {
    name: 'Ingeus',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anthony',
        lastName: 'Lengden',
        emails: ['alengden@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Claire',
        lastName: '',
        emails: ['c@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEgywlWr_UERA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1684865991845?e=2147483647&v=beta&t=ePnxScD-nxJDk5Jm8vs6_1bjLyPI7v2j-JiryKa99ko',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Janet',
        lastName: 'Muchinjiko',
        emails: ['jmuchinjiko@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jon',
        lastName: 'Hughes',
        emails: ['jhughes@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Heather',
        lastName: 'Wright',
        emails: ['hwright@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFCbE3XNUkbuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1545658767254?e=2147483647&v=beta&t=xo-6AEH1SeX2u7RBguU3I904Dnn4mWFcQV7Ese2-jxw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  processfusion: {
    name: 'GrapeCity Mongolia LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Munkhtsetseg',
        lastName: 'Baasandorj',
        emails: ['mbaasandorj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE5iYGa0nkGcg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516961238987?e=2147483647&v=beta&t=6HkXGCrxExme5NsgfUt4BOeJO-WStUo39Erp3oH5N5s',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Batkhuyag',
        lastName: 'Dashnyam',
        emails: ['bdashnyam@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erdene',
        lastName: '',
        emails: ['e@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGWmAveuNpUhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638835551639?e=2147483647&v=beta&t=rFwuQIPNNMN6QJucy4KRIGZtBgKhXCmHUZJNQ-IMCmo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Munkhtsetseg',
        lastName: 'Byambadorj',
        emails: ['mbyambadorj@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFvny6Hxgi2Ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517025274343?e=2147483647&v=beta&t=dT-dSW4OnbFv_i_iRvrivIz1moM69a31SpA9bVM3QjA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tumenjargal',
        lastName: 'Lkhagvasuren',
        emails: ['tlkhagvasuren@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHzoH0OHOFeig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541928586741?e=2147483647&v=beta&t=1FbqY86HaXKbJMGyMqVRrdJHtwAGoMFGOd3pC-YycsY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  americancyber: {
    name: 'Drish Infotech Ltd.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vikas',
        lastName: 'Aggarwal',
        emails: ['vaggarwal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Harshvir',
        lastName: 'Singh',
        emails: ['hsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHEBm6DqNUWDg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516261626124?e=2147483647&v=beta&t=L6fPoP6aGEQNJqLwAeGYRHuTc7YKnB21PsMpLSYFvHo',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjeev',
        lastName: 'Kumar',
        emails: ['skumar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Drish Infotech Limited |',
        lastName: 'LinkedIn',
        emails: ['dlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFELhPLHHHu3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1562845664345?e=2147483647&v=beta&t=GlhIP2AXFaNt1WYTzNsxStMN-f_6in5FI_7ilhGmuVM',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Karan',
        lastName: 'Checker',
        emails: ['kchecker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  futureip: {
    name: 'WiSC Enterprises, LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bill',
        lastName: 'Craig',
        emails: ['bcraig@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGvU9qPY_7SYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516526190066?e=2147483647&v=beta&t=46GDxuol0S2rL9by4VwfVo_z2qr44aXr-1W3nuw7OdU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Patrick J.',
        lastName: 'Doran',
        emails: ['pdoran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEVR3l-QBnxpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667937506099?e=2147483647&v=beta&t=kZf2wTCfdOknYeUcRcBr9s5BFbQdalIDtdIYSuqjE_U',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jim',
        lastName: 'Antonisse',
        emails: ['jantonisse@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE2JxLea4L8Iw/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1715705862785?e=2147483647&v=beta&t=4mhhW-ajgG4cQgeNqFkWMMrp7C0bzynJTZ2LhDv8A-U',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Kyle',
        lastName: 'Thomsen',
        emails: ['kthomsen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG4ydDzjpPNZw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592101990920?e=2147483647&v=beta&t=T9m2bVz4j2GGLhvIm-45J_jhrxTgslOxcoTHMndit40',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Glenn',
        lastName: 'Bergeron',
        emails: ['gbergeron@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFl_-6TuYTY1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730432324587?e=2147483647&v=beta&t=tF2Qfw7SO9yr7uM6Pku8peXZ6XGFZBWbSHGZWDq3etA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  msiuk: {
    name: 'Parascript',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Emiliano',
        lastName: 'Giacchetti',
        emails: ['egiacchetti@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lindsey',
        lastName: 'Wohlman',
        emails: ['lwohlman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGgh7QmUJ3fkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1687278315677?e=2147483647&v=beta&t=S7j1Evt8lIfZJg_TaGn_sxrnsNwgck6wkNo5INJEt-0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alexander',
        lastName: 'Filatov',
        emails: ['afilatov@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ted V.',
        lastName: 'Niemann',
        emails: ['tniemann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG6JF8GD69qgQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516975234651?e=2147483647&v=beta&t=Ek6FzdtUDTD4rGyZeTWQ5pM9_V5SJRK6LtSN0m3uoZQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sergey',
        lastName: 'Derevyanko',
        emails: ['sderevyanko@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG13DxN2IAr_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516501765814?e=2147483647&v=beta&t=uGekkAbkuX64tHFF4mp-jMlKEyZxUOaPrg-uwplLcQ0',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  icubecsi: {
    name: 'HK Consulting Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Anel',
        lastName: 'Canto',
        emails: ['acanto@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHPoY2PeZG4_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713369602811?e=2147483647&v=beta&t=LLsF7UksIW39sNMjuZCwILn2wiTDDLpmok2SRyoj3z0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'James',
        lastName: 'Hofman',
        emails: ['jhofman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHatu6dB9mFvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517716739841?e=2147483647&v=beta&t=d5qIgtPgW0W1FQI0FI9_J8Hj4IModwo0e9ux52_6psc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Harsh Kumar Satyarthy',
        lastName: '(HK)',
        emails: ['h(hk)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQG67AJnh1dqOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581977846727?e=2147483647&v=beta&t=mQTzN8p3rmC8ea3nvQR8skYNdxRvwSKdX9ybA72pgqE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Harry',
        lastName: 'Krajcer',
        emails: ['hkrajcer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Scott',
        lastName: 'Hilty',
        emails: ['shilty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG-55fhU1WPww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517008266650?e=2147483647&v=beta&t=9tHdhbIFB9rx9g-jdNKPoCpohfnuMumGNncBwWU0A54',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hyperspectral: {
    name: 'Process Fusion',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matthew',
        lastName: 'Godmere',
        emails: ['mgodmere@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anthony',
        lastName: 'Quiroz',
        emails: ['aquiroz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEMWEaekxEP_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688477277147?e=2147483647&v=beta&t=O7UJOLdDyNgficHMhcDTlD2BIelM0DWEq0j-gB4PnJg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Erik',
        lastName: 'Reed',
        emails: ['ereed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHaVkp3OHeO3Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1564960661542?e=2147483647&v=beta&t=IfDURLQmLJMD7vjj4WAO0ij5zD_qlahF6W3DpfFvV7c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chris',
        lastName: 'Shaub',
        emails: ['cshaub@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bradford Oliver,',
        lastName: 'MBA',
        emails: ['bmba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGxEOjPnZaaOA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516352094041?e=2147483647&v=beta&t=nJhHb5RusS-8NcNIVWQaYy47rAIilcWWa9Rt09GRqSM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kmccorp: {
    name: 'Paragon Technology Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Bruce',
        lastName: 'Card',
        emails: ['bcard@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHNQN-OuqsoAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1588002308246?e=2147483647&v=beta&t=fyZ9cBE8VuNeNFEfxfBuN4jGhdy1Kbga7ySKtIyNjUs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Vivek',
        lastName: 'Gupta',
        emails: ['vgupta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHNUHzHKYOI4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517668913120?e=2147483647&v=beta&t=CaMpjcuy-cj1HPLHcIwfBwtfToPJcCGtvBWm7xIwkbE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jay',
        lastName: 'McAllister',
        emails: ['jmcallister@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'Nikola',
        emails: ['mnikola@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Bill',
        lastName: 'Daub',
        emails: ['bdaub@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  marcotechnology: {
    name: 'NGN Technologies Private Limited',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Khemlal',
        lastName: 'Chhetri',
        emails: ['kchhetri@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHwTpc9zcdgsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675396200098?e=2147483647&v=beta&t=epHialXwGVzlE3r7pERhEhzLEdjBOyThlYbsnLhbVD4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Pema',
        lastName: 'Gyalpo',
        emails: ['pgyalpo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFMpbA2pSA2pg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678519977089?e=2147483647&v=beta&t=5-OFj3hgZQkG0SB7oPtDlDnnNjaqXhbkapPSJDBnEkA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Krishna',
        lastName: 'Powdyel',
        emails: ['kpowdyel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFhLXva1Pfo5Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696230165163?e=2147483647&v=beta&t=ajLnli7e2mP0pHC9o2prNBD7A1_S2BmOue3h4YZEIk0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sabitri',
        lastName: 'Gurung',
        emails: ['sgurung@example.com'],
        image:
          'https://media.licdn.com/dms/image/D5603AQEDAtqHl8WFyw/profile-displayphoto-shrink_200_200/0/1721372736764?e=2147483647&v=beta&t=LUo9Sy9NQtmdyH6JoxXYHKoDUv_H0BfRUB8otkrbxM8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ajay',
        lastName: 'Rai',
        emails: ['arai@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lacomputerworld: {
    name: 'Mobile Systems International (MSIUK)',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Walid S.',
        lastName: 'K.',
        emails: ['wk.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Baraa',
        lastName: 'Owaied',
        emails: ['bowaied@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH2KhO5mBxwZA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517041277288?e=2147483647&v=beta&t=hQamR2HZpGXMoHtxExnjoWh_TmFa6tX5AAd-R7cK3iA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: "O'Dolan",
        emails: ["ao'dolan@example.com"],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Akaki',
        lastName: 'Zurebiani',
        emails: ['azurebiani@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG9Pfs31A5kxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1547633644136?e=2147483647&v=beta&t=S44wJXGsvjxE5F9ga9vaEYp7UfxDkWwQSLCZ3xrn6Dg',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Khurram',
        lastName: 'Maqsood',
        emails: ['kmaqsood@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGkQFk0jXXc6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516944992738?e=2147483647&v=beta&t=RpPsfzEuaSKkbuZcCo9ejzNGFBUmHCH7e335FNcbW1g',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nuhtech: {
    name: 'iCubeCSI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sridhar Palle, PhD,',
        lastName: 'MBA',
        emails: ['smba@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFKX8SiRDxHfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1585919201342?e=2147483647&v=beta&t=jRPvRf7r9elUrWIR68w70qZGnsPFJzihjRDPbu7gSIs',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rohit',
        lastName: 'Reddy',
        emails: ['rreddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGFXQl2wefzxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516514416473?e=2147483647&v=beta&t=BoNU_kNTksHE86TOwI0LpTbb6D_W3J02cfrlv_-WbOU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Manideep',
        lastName: 'Hanumanthavarjula',
        emails: ['mhanumanthavarjula@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEm6nRzN69f8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672757244133?e=2147483647&v=beta&t=owHGKJuJw2hqTh3ZCH0R5dmDv0k1tjQZ2Kh1zR2kZDc',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bhami',
        lastName: 'Reddy',
        emails: ['breddy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFcieU5KOen7g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1656078569622?e=2147483647&v=beta&t=clMoXQzEVJpkiXTvY_9xjEmh9yGwLvDWI2B4ga9ZDus',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kiran',
        lastName: 'Kala',
        emails: ['kkala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF4JtDDQLQ7tw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516245166867?e=2147483647&v=beta&t=tVlQQ_-8uWX2SDOxK9Dqf5Gq5UwUIEMCRrbetx3OCuY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  newInnov: {
    name: 'HyperSpectral',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Daniel',
        lastName: 'Baeza',
        emails: ['dbaeza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGJQAFo2xhQkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516331884230?e=2147483647&v=beta&t=naiz9NKathX1Gwspvqc2B08FIKWHHqxd4A4mQXenRm4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lex',
        lastName: 'Fridman',
        emails: ['lfridman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLCrHhGZ0xMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1519486751908?e=2147483647&v=beta&t=00cMuWdaJK57kI8wi04k86AEw-eeS8L-9N5jFnej3gg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  resoluteai: {
    name: 'KMCCorp',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mohammed',
        lastName: 'Rafeek',
        emails: ['mrafeek@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFceZxY5Xw2Zw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728561757464?e=2147483647&v=beta&t=urvf4KRh8lhGGkcKkPOKe4HevO5cgtRuOj9ZJfiSIJ0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sankarasubramanian',
        lastName: 'Mohan',
        emails: ['smohan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGHggVMSQLT9Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725366104832?e=2147483647&v=beta&t=UFLgfMbKWsZ8JnnaAy4LSSLxxvm7fqp9Y2VMKlUzLoY',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bhavana',
        lastName: 'KM',
        emails: ['bkm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGRWcMpKxFlpw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1625560560826?e=2147483647&v=beta&t=Ka1YltKB0D6G2Xf_n6y5xR5w8jq-VvVGNTv5svCe-6c',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anusha',
        lastName: 'Naraharisetty',
        emails: ['anaraharisetty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEq9t-epBVNpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666949248926?e=2147483647&v=beta&t=h6LfcG-WOMa6XYno8XqXVtu2qAdPDKH0_cb83zDEqXw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Vasanthakumar',
        lastName: 'Krishnasingh',
        emails: ['vkrishnasingh@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  digades: {
    name: 'Marco Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Paul',
        lastName: 'Kolbe',
        emails: ['pkolbe@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paolo Dello',
        lastName: 'Vicario',
        emails: ['pvicario@example.com'],
        image:
          'https://media.licdn.com/dms/image/D4D03AQEUaPcMLgSUqw/profile-displayphoto-shrink_200_200/0/1685267281485?e=2147483647&v=beta&t=gds91K3jspzQhmkWwUQr3QjvdqxEv6LJST5OvTgh-20',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dylan',
        lastName: 'Ciaccio',
        emails: ['dciaccio@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH149a-LyNpPw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720040480733?e=2147483647&v=beta&t=U70XxM4okjY1sFS87yfdVm7H2xX3ccJ8RhT7auT--jM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Brent Reams',
        lastName: 'Burke',
        emails: ['bburke@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chelsea',
        lastName: 'Johnson',
        emails: ['cjohnson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGl9YGyuJaDUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1711674341988?e=2147483647&v=beta&t=EcxxJtVNM_rEWev39kHkzRLheYM6he1tS_I0cC_q0dM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  benchachinda: {
    name: 'TechR2',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sepp',
        lastName: 'Rajaie',
        emails: ['srajaie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGKiYtlN5LFvw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643751443960?e=2147483647&v=beta&t=g82VspUEAqO286q6U-Rc0YyaQRamVNOtuj6-nN4dH60',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Marc',
        lastName: 'Swearengen',
        emails: ['mswearengen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lisa',
        lastName: 'Rajaie',
        emails: ['lrajaie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEb97wfZm-eqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1638401297836?e=2147483647&v=beta&t=BvpLHmFWwTRUNzIQVlBqbagH9YFC7jh4jKFLH3xXtOA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Charles',
        lastName: 'Robbins',
        emails: ['crobbins@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGXbT_4jSgRlw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1554673347825?e=2147483647&v=beta&t=yVgWIohlpkIWH7dlDA2ju3qUrZmnTLp-HMIQwNSki0o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nathan',
        lastName: 'Ringwalt',
        emails: ['nringwalt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF9e1Vv8Ov8kw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721136995047?e=2147483647&v=beta&t=a3AB-GntjOMs1bUHsuYnbUuOhRD1sh5vvMA6mhSrQQ4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  vanillasoft: {
    name: 'ResoluteAI Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sanjay',
        lastName: 'Jha',
        emails: ['sjha@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHk3jgXoDiVPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696669812933?e=2147483647&v=beta&t=8RruJ6KL1fnqpTyZ4VRdRZu3p-S-mBRjNKOqF0VgkH0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rishika',
        lastName: 'Agarwala',
        emails: ['ragarwala@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHszSaHkCV2ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1614620247024?e=2147483647&v=beta&t=8dTlLMQ262wQVWFXphpMNic0cfxS3UnGPaEqO_OcB_I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pawan',
        lastName: 'Kumar',
        emails: ['pkumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEi5ijjSMvkPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1608631551723?e=2147483647&v=beta&t=Zx64ElclNaYJcKzUkQG4rldDNve5ut1xsj3HyDpNxsU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Matt',
        lastName: 'Doherty',
        emails: ['mdoherty@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHko6nK_pJbCQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616171089620?e=2147483647&v=beta&t=ZPcdoTtPlxrFTxmRIpP1l_cRuthmq446c6GnL5gu_cQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jeenal',
        lastName: 'Rajgor',
        emails: ['jrajgor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHmM9G-0CEDMw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1672804510857?e=2147483647&v=beta&t=sMRAA71zyR6vuBML6HAiNHzO3MFO0JLZuu8BgQNdcZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  callrevu: {
    name: 'digades GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sascha Berger – CEO and',
        lastName: 'Co',
        emails: ['sco@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHTMXlm6-wBYg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1666702612644?e=2147483647&v=beta&t=XXqstl8qWPYLaYIIogoEzp4KTFG2fMFs0RhR2MH54Po',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nicole Ramelow – Einkaufsleiter – digades GmbH |',
        lastName: 'LinkedIn',
        emails: ['nlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Ankush Nagaraju – Embedded Software Engineer – digades GmbH',
        lastName: '...',
        emails: ['a...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFHicYIjv2V9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1567349901961?e=2147483647&v=beta&t=SuEzHG7BGZpIW7yKfpd7AZ0sZeVaBECy01W6Vkrm1_8',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Anja Schulz – digades GmbH |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHVSL50MQzS_A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1663917902043?e=2147483647&v=beta&t=Sl2X7YGTFICrSVpHxhc4sFxANdPXGGL_IdYDMM2WHHE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Anton Bayer – Gruppenleiter PMO – digades GmbH |',
        lastName: 'LinkedIn',
        emails: ['alinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFE1SayPe1E4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1566196080494?e=2147483647&v=beta&t=TyEHSs8EsCHerig38r2omcBtrqfwb5XViWJJoSX0JrU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  scaleops: {
    name: 'Axioned',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Libby',
        lastName: 'S.',
        emails: ['ls.@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Anurag',
        lastName: 'Banerjee',
        emails: ['abanerjee@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sangeeta',
        lastName: 'Poojari',
        emails: ['spoojari@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHBLVXJgholSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1617127117314?e=2147483647&v=beta&t=wGyvdyCDvth2BNA1LZegoV1agzVqjC_VYzVPgr8FlYk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vipin',
        lastName: 'Yadav',
        emails: ['vyadav@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHU7E1G16bfdg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1729089316590?e=2147483647&v=beta&t=9OeHSMAKY1BAXVwBYDrHZOa4Da1a6wmgC1BH6QHAUuY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Deepa',
        lastName: 'Patil',
        emails: ['dpatil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFI7AtW8GF9iw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696495241122?e=2147483647&v=beta&t=8is7K0KrisoMi8XklqGfzfmZIQww46BbIhBJAQ9HuMk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  incentivate: {
    name: 'Benchachinda Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Natawudh',
        lastName: 'Pintongkam',
        emails: ['npintongkam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEvYg1ScW4AUg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1647888890251?e=2147483647&v=beta&t=S4lV-qCxImcawfwaFMAMbL0O0wsZ6ygrBVPiYf03b98',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Supaporn',
        lastName: 'Suntornvipat',
        emails: ['ssuntornvipat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHM1_BuDTKxZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720801881505?e=2147483647&v=beta&t=cPzOvVdeF6JDkT1dTwMSF8ypLgITT29QA_6DsOiiJvE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jatupon',
        lastName: 'Jitpong',
        emails: ['jjitpong@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHW1VAEEdOelQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704363916709?e=2147483647&v=beta&t=jAyJhrv3GoAJOUHwJkGIG0eX1iHz2yqpqYfVSqrBjcE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Narapat',
        lastName: 'Kanokvilairat',
        emails: ['nkanokvilairat@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF3VPXqoM6I4g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683687117418?e=2147483647&v=beta&t=HEFYoULkoAc_GVgqb3901FobhoTixslSZ2GV5awcfpQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Oraya',
        lastName: 'Thaloengpol',
        emails: ['othaloengpol@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  arrcus: {
    name: 'FieldPulse',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Gabriel',
        lastName: 'Pinchev',
        emails: ['gpinchev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFFtNx8CG5D7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697225405379?e=2147483647&v=beta&t=FS0g57b95URAwLCOkupkrvH01RS7wcCn5jvA3Sr90GM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Myers',
        emails: ['dmyers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHegQlW5A-UWw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1677598207215?e=2147483647&v=beta&t=FZYGz2u_Hif54kHCMY-tkSmo2vmvPl8A-Tuz8n1FuP0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Blaine',
        lastName: 'Dawson',
        emails: ['bdawson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE-aYpEhTiQJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1518577858056?e=2147483647&v=beta&t=jE0lmVsS6yF_CmgTC0VWDI0r7dYT5QecLsDkkQy3Ck4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Lauren',
        lastName: 'Martin',
        emails: ['lmartin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Samuel',
        lastName: 'Denman',
        emails: ['sdenman@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGc0ThAuJDejQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727292490869?e=2147483647&v=beta&t=lC-85FZG0aSY3wx_3WT9JkRld18UuEGpSxEQEVh8vsk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  teintechnology: {
    name: 'ScaleOps - Cloud-Native Optimization',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Guy',
        lastName: 'Baron',
        emails: ['gbaron@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEXf1i59nVSiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683535055113?e=2147483647&v=beta&t=SsY0FGWWpUH_jCeosMSpP_XMRee5VO7zLLzEK0fzj88',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Amit',
        lastName: 'Kanfer',
        emails: ['akanfer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHyPV1ycSYdbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628164036152?e=2147483647&v=beta&t=LHG7jC0N7ZrecN8oOEiBfSDbGYq8UqaDIj-lppQmlTM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Timea',
        lastName: 'B.',
        emails: ['tb.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFbqzGE9IZ4hw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702584029195?e=2147483647&v=beta&t=SDmlBTEhMvXAt-NtaQTR7LJGyx46xf8_6JKDjLJsA1Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dov',
        lastName: 'Shtauber',
        emails: ['dshtauber@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGZZcKzK0b-IA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676765503058?e=2147483647&v=beta&t=A6e4uVK5O0J5dp_XJV-Nzo2NIbnkVqjSq22IfFsi7x4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rodolfo',
        lastName: 'Marin',
        emails: ['rmarin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQG6SSGIRkgp2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700092225447?e=2147483647&v=beta&t=riQ4jxZwM0HfYJUuOGnN-haOAAsZ1zrUNtgLP666DAw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  kivra: {
    name: 'Incentivate Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sumeet',
        lastName: 'Shah',
        emails: ['sshah@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG_SdlF4diZBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724240071968?e=2147483647&v=beta&t=LhVxzEuXLUSXxVL3tqnVH1vCh38oXD3nXEhNOQQo6H8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Amit',
        lastName: 'Jain',
        emails: ['ajain@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGwfVNLOYcm1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1574233776409?e=2147483647&v=beta&t=oPU4CfnwnavjNR1hKkDZK-z_4W3OQhHCu3cBfdALBDA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Rajendra',
        lastName: 'Bhirud',
        emails: ['rbhirud@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sujeet',
        lastName: 'Pillai',
        emails: ['spillai@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGKie8aHSLEnA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1693541342815?e=2147483647&v=beta&t=FoD7RX2Du5AFfqS2kx70BphebhuA41SzpcmmD-D7clI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Mohit',
        lastName: 'Dubey',
        emails: ['mdubey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEX3yo5e4h1_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692812659308?e=2147483647&v=beta&t=CWf44O17JdCvltw6p6WrmefbveRajLwmjlVD6pc7kgo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  copainstechnologies: {
    name: 'RPI Consultants',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chris',
        lastName: 'Arey',
        emails: ['carey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHAnP-beDhGIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1701657234548?e=2147483647&v=beta&t=QUTZIvGcI1XlGXw1q7bhsMnGlR5dNQ2aaz5oCFxyjiw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Keith',
        lastName: 'Wayland',
        emails: ['kwayland@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Melissa',
        lastName: 'Olson',
        emails: ['molson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE0U_X5H5ETPg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1692817931663?e=2147483647&v=beta&t=ZWByDp2sCBEzIHmpJzTiZNHVQ684XewECFNOXc5ppNM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Nate',
        lastName: 'Dean',
        emails: ['ndean@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEouGe5skIk2w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680806636130?e=2147483647&v=beta&t=mCXY1acH4Jo_KF1_Y_kdUK28ELApODT6tXNElWEvOVk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sarah',
        lastName: 'Concannon',
        emails: ['sconcannon@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  hiqoSolutions: {
    name: 'Clarity Software Solutions, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Steve',
        lastName: 'Mongelli',
        emails: ['smongelli@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Rich',
        lastName: 'West',
        emails: ['rwest@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHD-265Ofcjbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607731520879?e=2147483647&v=beta&t=_iUyBs_aNIG5YI0Cp4BnwBVdhZAUCKInlMop5irgFNI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Robert',
        lastName: 'Stepeck',
        emails: ['rstepeck@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sean',
        lastName: 'Rotermund',
        emails: ['srotermund@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Dan',
        lastName: 'Overholt',
        emails: ['doverholt@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ditoweb: {
    name: 'Arrcus, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shekar',
        lastName: 'Ayyar',
        emails: ['sayyar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sanjay',
        lastName: 'Kumar',
        emails: ['skumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEg4ejgjaCaSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1592861377800?e=2147483647&v=beta&t=uUn4a3xel-j5bV0nB9ZFdodhymKJolv7jtiBSfzNy6Y',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sean P.',
        lastName: 'Griffin',
        emails: ['sgriffin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGvOpPDA5FBOg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652978680914?e=2147483647&v=beta&t=IR4czLed7-91CcZyfWHxFHYjaBL5YXp8kG1Sn1D8buQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jennifer',
        lastName: 'Smith',
        emails: ['jsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQHY0ZswNK2GKA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516311806458?e=2147483647&v=beta&t=yAz0CHd8hr9SZeAengByklNGp_vhguJ1HCtqI03tndc',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Abhay',
        lastName: 'Roy',
        emails: ['aroy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  autozone: {
    name: 'Microgenesis',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Girish',
        lastName: 'Shenoy',
        emails: ['gshenoy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFefztARYJE7A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682665299842?e=2147483647&v=beta&t=SKDisKZlJQBX-XSX07YiOU6-GODixe1VCRFR8xsVx5E',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sukanya',
        lastName: 'Dutta',
        emails: ['sdutta@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF99uNgoci9ag/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1686736631502?e=2147483647&v=beta&t=iaqtIK7xO59AtdGLS3ysdmJUzMQiU2-VNqTuP1msUTc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Dhananjaya',
        lastName: 'K',
        emails: ['dk@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Santy',
        lastName: 'Nelson',
        emails: ['snelson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Murthy',
        lastName: 'Manjunath',
        emails: ['mmanjunath@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQELm3xfYjxC1Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1581499962442?e=2147483647&v=beta&t=RidwtnLBhoBFXpj7dLhvQWOhdPKha9RCCHiiDkwzrBk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  lightbeamhealth: {
    name: 'Tein Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Smith',
        emails: ['rsmith@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'William',
        lastName: 'Maw',
        emails: ['wmaw@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sharon Smith (nee',
        lastName: 'Potter)',
        emails: ['spotter)@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mary',
        lastName: 'Willhoeft',
        emails: ['mwillhoeft@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFEcQcWUhg0iw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568724158016?e=2147483647&v=beta&t=59ho2KH9ClYgsvgZLZjYrby6Fnht0NYgnFWw24XgCgI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Distie',
        lastName: 'Profit',
        emails: ['dprofit@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHSOIXf7s-MjQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1543161602537?e=2147483647&v=beta&t=DOdbOue2E1zD1oXQsokQZ5JQmS8QDoAfvRZMOHQaJKA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  oriontekinc: {
    name: 'Kivra Oy',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jenna',
        lastName: 'Tulonen',
        emails: ['jtulonen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHx7KGCF8oCkg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708021772283?e=2147483647&v=beta&t=FUlFcHOifwhtn-jpK6ehU20SpUVkhiqa0S_dgOagF6c',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ilari',
        lastName: 'Abdeen',
        emails: ['iabdeen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQG131UZEuGicg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712906533779?e=2147483647&v=beta&t=Dkv8REbUxXCggp0gauVg_wXIKqLIjkorIoSBzVowWyc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Elina',
        lastName: 'Pietilä',
        emails: ['epietilä@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEip-wSWkdikQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652288975788?e=2147483647&v=beta&t=QQmBer1oTBeAW6ST1B5slW1LK4TzZldLEfSRDFi7Lws',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Huy',
        lastName: 'Bui',
        emails: ['hbui@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHDxLZ0DxGxLQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716018907975?e=2147483647&v=beta&t=VxJKtpAZd0n5_xRo-MxmvO7J1nD7mAgz-7sNdI_6iko',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jari',
        lastName: 'Takalo',
        emails: ['jtakalo@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFZozsB6Fc-_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1609779177176?e=2147483647&v=beta&t=dxpOEi4mVp1kJ2zN5oAK9R_hX6v_cGRkkSp6Rrjca7o',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  secureit: {
    name: 'COPAINS',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Abdulaziz Altuwayjiri عبدالعزيز',
        lastName: 'التويجري',
        emails: ['aالتويجري@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5QsR2bqpMQA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665050729792?e=2147483647&v=beta&t=_egzxFLIzjvHMfMbCU_nsaNupDINVWlq8dN6f55B38w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Chris Petrovic – FunPlus |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEfUG3zWQCnMQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670402891790?e=2147483647&v=beta&t=fXPfBKL_vcFI-yVX6YSLXXpZ-fVNlOfZLmx77X0GFZY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  laaketietokeskus: {
    name: 'Zucchetti Spain, Soluciones TI',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Borja',
        lastName: 'Milla',
        emails: ['bmilla@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Luis Barturen',
        lastName: 'Múgica',
        emails: ['lmúgica@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQF2_SI4Us-gbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683837274552?e=2147483647&v=beta&t=vdLVTnJ7bwHeD7qqbnCKJ72tkWBMW5nj_lBJZPbyzvk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Joana Jiménez',
        lastName: 'López',
        emails: ['jlópez@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHl_YYmVi2FSw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1598278954547?e=2147483647&v=beta&t=Yc9wZmmdQg3QkAXM6g0f52ylz98DOSO69BOrIXZ6I3I',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Domingo Anguita',
        lastName: 'Godino',
        emails: ['dgodino@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jon',
        lastName: 'Uriarte',
        emails: ['juriarte@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFFpepCQxNObA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1645176632233?e=2147483647&v=beta&t=QVGoLsZqc8CrHppHCHv1AvoZCnzKEMEN3wZt8IwxJ-Q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  itorizon: {
    name: 'HiQo Solutions, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mathew',
        lastName: 'Garver',
        emails: ['mgarver@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQH6kgFLLaVUYw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1707867366208?e=2147483647&v=beta&t=wZAe8bL89ZfY-bsW_dhifjRfFktUS69GwPHSFiJJ9JY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Martin',
        lastName: 'Mauersberg',
        emails: ['mmauersberg@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jeremy',
        lastName: 'Udovich',
        emails: ['judovich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGhj4vEj5BrpA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1619544449094?e=2147483647&v=beta&t=DpqaOpFdLNi_O4A9REG3cvKlDG5tXZFV8R7DgAMj7dA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Marcus',
        lastName: 'Krause',
        emails: ['mkrause@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQETS72nixRu8w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517726657281?e=2147483647&v=beta&t=jETU7fhd4ICsETNeGt8unreIctajouESvWJQ3r8bGJ8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nastassia',
        lastName: 'Klishevich',
        emails: ['nklishevich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQH9DAHaw1sTvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1639664072163?e=2147483647&v=beta&t=jvT1IG2Vy2N35YLMc7KN29J6lJ7pwvO9KuN1vszY45M',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  pirel: {
    name: 'Dito',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Erwin',
        lastName: 'Dito',
        emails: ['edito@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Kevin A.',
        lastName: 'McGrail',
        emails: ['kmcgrail@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGxmkp1ahzb6A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516174542402?e=2147483647&v=beta&t=HFShEN1Up0dQcsJW6oqa402YoaosqBMTak8NzC4HTP0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Stephanie',
        lastName: 'Dito',
        emails: ['sdito@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEL9HSkZetxlQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637575879480?e=2147483647&v=beta&t=He_xCGqz-cH69qmxrANIFRLwoW95dyRkmp5lR6TJkkQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Missy',
        lastName: 'Dito',
        emails: ['mdito@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQENuaANl-gV4Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727642876436?e=2147483647&v=beta&t=lk5HC_Rdpr9QSkq5oqEpP2qM3NwEsA-rPOgBPWp6gk8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jenelle',
        lastName: 'Dito',
        emails: ['jdito@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEn36WjTMuKpg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550273367587?e=2147483647&v=beta&t=Y8pZcu4-CrynpCQ-4lOiDF6xTMxTjmuBL0I6HYIA2E4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  iotsquared: {
    name: 'Explizit AB',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Johan Fahlgren – Explizit AB |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEoLwpBRl1muA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622017131883?e=2147483647&v=beta&t=HHchS-7Ep3Nf6NAACBZvKajCsxwjVb-vov0esgTpLew',
      },
      {
        id: 'U077TRRJQ3G',
        firstName:
          'Fredrik Bergqvist – CEO at Explizit Solutions AB / CTO SAYP Group',
        lastName: '...',
        emails: ['f...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Miljan',
        lastName: 'Minic',
        emails: ['mminic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGFRb0GthATuA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1526971114043?e=2147483647&v=beta&t=3UB-cxXwAQLTkjH9kXhJJ5-O2PWyL0s_TcA2AZ4swgA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName:
          'Inge Sjölin – Verksamhetsutvecklare – Explizit Public Service AB',
        lastName: '...',
        emails: ['i...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQElbp0v-2NeAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671909555271?e=2147483647&v=beta&t=4wYaudUsKV5xRCXjlNqOjXQ59ZJIqFnlCoXq7_je9D0',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jakob Bergström – Software Developer – Explizit AB |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEKWjfI7xEaOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1708878195046?e=2147483647&v=beta&t=tbIpwCb7YkI06f4Lk2k0VTgYrj5VpEq8t7_vyBE8_CQ',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  casanet: {
    name: 'AutoZone',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Matt',
        lastName: 'Harmon',
        emails: ['mharmon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFRSqqo9F074Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516336646706?e=2147483647&v=beta&t=7r_SGeUi7tWl5kLJjaa43lZx3_gyho4EJPlT061LcmQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jamey',
        lastName: 'Maki',
        emails: ['jmaki@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE6imBg4ADtsA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665958534759?e=2147483647&v=beta&t=XjlMNIeNVppfPlolwsUmEm10OgO8d6oyF9x49EIti8o',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christopher',
        lastName: 'Netterwald',
        emails: ['cnetterwald@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFfVit0CpIJVg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1676461834353?e=2147483647&v=beta&t=IFAX-e4SGuNcBCjNpHTH-mIHKwoe-L8VWvdAmYLP3-o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'David',
        lastName: 'Cohen',
        emails: ['dcohen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF0rmCEVlUdig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1607093321351?e=2147483647&v=beta&t=Hx7qadud888PdzlMb7CTMfsdghENNDoI8I-YmmcYq7o',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Julia',
        lastName: 'Levy',
        emails: ['jlevy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQG_mTr1KtOpnw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1664221280297?e=2147483647&v=beta&t=QWtG1zLfpkBE0Hvm--_wdmf0yiilRnQScL-NOmhs0xM',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  maximaconsulting: {
    name: 'Lightbeam Health Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Patrick',
        lastName: 'Burton',
        emails: ['pburton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEN0Yjp0qCS-w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595421615755?e=2147483647&v=beta&t=V8NfAWQGgzCpavhQo8WrIn_msi32omUQ5V8L4JdK-ek',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Patrick',
        lastName: 'Cline',
        emails: ['pcline@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGEsPBnrDzS9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710193543669?e=2147483647&v=beta&t=gLjTxILUl-OasiM-DDqMtUz5-xouwlEr26bW2QUuCnc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Michael',
        lastName: 'Hoxter',
        emails: ['mhoxter@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGVahZ9p2mXSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516879755967?e=2147483647&v=beta&t=l2KJLRhEqVNZ5DxmG3SxzPYFRcdVhT41K9G4CLzEomA',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Russell',
        lastName: 'Smith',
        emails: ['rsmith@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQErB2_-Fag0QA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670967093821?e=2147483647&v=beta&t=MDp6tgX0d9cxTP6e9ywFiOUHjxYjBBxCJh2uxugmAq8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jerry',
        lastName: 'Shultz',
        emails: ['jshultz@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFoETJDcIT_Dw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516688796349?e=2147483647&v=beta&t=Ro_3jV1qgDLYB_GbBmG5AcMlOI_cVOFwy98_I_MhCHk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  soundpayments: {
    name: 'OrionTek Inc',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Saranya',
        lastName: 'Devarapalli',
        emails: ['sdevarapalli@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lokesh',
        lastName: 'Akula',
        emails: ['lakula@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Pradeep',
        lastName: 'Menon',
        emails: ['pmenon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQErYzJurFMpYQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517713155944?e=2147483647&v=beta&t=xqBAmSTa4A3tuZQtemTRi8NCPTl4UH08gc822XKfuwE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Subhakar',
        lastName: 'Koneru',
        emails: ['skoneru@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sowjanya',
        lastName: 'Kaza',
        emails: ['skaza@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  relic: {
    name: 'SecureIT',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ed',
        lastName: 'Trevis',
        emails: ['etrevis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGa7sd6F8dtSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718676630536?e=2147483647&v=beta&t=QPD-Ise--WOfUr64FW5YF1w5Yz88HBx0EUuuITrQkX4',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Martin',
        lastName: 'Rudloff',
        emails: ['mrudloff@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Liz',
        lastName: 'Trevizan',
        emails: ['ltrevizan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF7Y4NLZtm67A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517346491479?e=2147483647&v=beta&t=Ni8CZB9N6kKURFteks-CPvkZ4wzmc5ADeTwD2CjESvw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Denise',
        lastName: 'Manchester',
        emails: ['dmanchester@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elcio Toledo dos',
        lastName: 'Santos',
        emails: ['esantos@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHLe2UY-Miyhg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1646424988859?e=2147483647&v=beta&t=o4mKvOxRLtp6JWg1NH8QjE6Zczgp4KiyJoxGAj5g3Wk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  eset: {
    name: 'Pharmaca Health Intelligence',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Hans de',
        lastName: 'Raad',
        emails: ['hraad@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQH6hVcaGPXQLg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516624375748?e=2147483647&v=beta&t=eT-T-AUsU8BovRS9R975zk6P3_pK0sg1BEXfdWMIH74',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Egor',
        lastName: 'Shevtsov',
        emails: ['eshevtsov@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEnfbBIazKZ3A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723049400240?e=2147483647&v=beta&t=MK3YWyEsEk9z1tr5EnVb5rWHWACA1YMAqFgwTvbdfnU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Frank',
        lastName: 'Anemaet',
        emails: ['fanemaet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHwvXpAPxm8Jg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722691898513?e=2147483647&v=beta&t=qTHUFoHswHzOpvXIbfjaYIoFrcB-15vq-hkAN3G7tH4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Luka',
        lastName: 'Čupić',
        emails: ['lčupić@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Valerija',
        lastName: 'Olic',
        emails: ['volic@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEbC5A-sw-LJQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1681735460922?e=2147483647&v=beta&t=_tqDGjHWlMfkg7xgUVbnSzw0d8Nbzy_-ehOAs7rY4Qg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  techniumnetworking: {
    name: 'ITOrizon Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shan',
        lastName: 'Muthuvelu',
        emails: ['smuthuvelu@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFJD3xJ98TsNA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727293121102?e=2147483647&v=beta&t=PPefw1baHC8p2lbmT70hykK8IBbpLfCEX-_Jwtcwa_0',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jay',
        lastName: 'Ganapathy',
        emails: ['jganapathy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sreevidhya',
        lastName: 'Shashi',
        emails: ['sshashi@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dennis',
        lastName: 'Aronson',
        emails: ['daronson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGohj_SlqnuoQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1582172182661?e=2147483647&v=beta&t=sl9GUTmDXDs86aerpKX35uOPAa0w9ABXmFRgtBZ5BqA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shashi',
        lastName: 'Shivashankar',
        emails: ['sshivashankar@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sumogroupltd: {
    name: 'PIREL Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Denis',
        lastName: 'Pigeon',
        emails: ['dpigeon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHkLMVQxBx2pA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1716301632295?e=2147483647&v=beta&t=FC5iSNtx0YXi6HZTmE6GKWFZt7aIVbE3r3Wab2RheOw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Eric',
        lastName: 'Riendeau',
        emails: ['eriendeau@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHKdk1xpJZrPA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718229007144?e=2147483647&v=beta&t=35Me979vI5M1rjbuFt2TjlA-hRAfCNwKXsQuspiF7mk',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sebastian',
        lastName: 'Cardenas',
        emails: ['scardenas@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHrrWEhlhDLiw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1539824027798?e=2147483647&v=beta&t=GY4h9qezQGw3OypiQ0ltp9MvkHwCuEjw1Pwdw1_Ewbo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Elio',
        lastName: 'Costantine',
        emails: ['ecostantine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGb4CVIOv01IA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1623951627817?e=2147483647&v=beta&t=DXz3Ghuy0CICK4okJv4k7dP-2ctU03zCWHj58K7hUnE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michele',
        lastName: 'Chapdelaine',
        emails: ['mchapdelaine@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQF6hGaFVwfxxg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517568004574?e=2147483647&v=beta&t=7CwMtrLSYWDJKkLw1JEwLaPhsVphNYO5aY6dngN7HrA',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  celsiortech: {
    name: 'iot squared',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Muhannad',
        lastName: 'Alhuthail',
        emails: ['malhuthail@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEKUObuCqBy9w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1616921560071?e=2147483647&v=beta&t=l-a8azBS_ws1dMx_lWuxbNDOQhJz5qKswlFY2-r_izk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Saja',
        lastName: 'Alhussein',
        emails: ['salhussein@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQExNDV5eruE_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654775539208?e=2147483647&v=beta&t=jF-ucI2ctO8MK8Q_Iz7fOK4-hUKiuHPKXs3-BLVMSmg',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Mohammed Fadhel ` محمد فاضل CPM,',
        lastName: 'CA',
        emails: ['mca@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mohammed',
        lastName: 'Yousef',
        emails: ['myousef@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH_joS9yZWfkA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1721887678929?e=2147483647&v=beta&t=V-TnaSWcyqFKZqXuI8nPtWZ7MpuTuAdSnFRZmEfZmlw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Ahmad',
        lastName: 'AlRawaf',
        emails: ['aalrawaf@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHqcNt-au68nQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1727607671955?e=2147483647&v=beta&t=RZqzUCiHXyt1RDYK_YyMduUVSyNi2QdZfO2Jtws_uL4',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  tein: {
    name: 'Casanet SA, Filiale de Maroc Telecom',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Drew',
        lastName: 'Eccleston',
        emails: ['deccleston@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEyU7lD4xMe7Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1578893394178?e=2147483647&v=beta&t=I2k-HrIjzoLSbHMG42whZydnzZ8UhlP9yfPHYUx6CGY',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Hiba',
        lastName: 'BEKKALI',
        emails: ['hbekkali@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'kamal',
        lastName: 'rougui',
        emails: ['krougui@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEjanorpeNgrA/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1678797019352?e=2147483647&v=beta&t=C1tbCjwMX8mJqfM08r9XyzryPB2cezJIqyftATtYvzs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Younes',
        lastName: 'MACHHOUR',
        emails: ['ymachhour@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE-sUHlBxRbAw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1561565341883?e=2147483647&v=beta&t=_MDouK0zhK-gjuTIe1iCvc1AUJCVG7LRbJY6iY7ByoA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Rita',
        lastName: 'LAICH',
        emails: ['rlaich@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEy7JPQaxDKmg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709931275027?e=2147483647&v=beta&t=z4TA9sRa73hSeNcJfOUEtm8GDlCl2nfBc9gEXvtxKRk',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  baanyan: {
    name: 'Sound Payments',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Scott',
        lastName: 'McArthur',
        emails: ['smcarthur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQGn4r4iCerU9A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516349710359?e=2147483647&v=beta&t=NCe6gR4cU86qpUXwOhaKVhwQjIyWCgBDHI6licDN3vo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Melissa',
        lastName: 'Wood',
        emails: ['mwood@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Andrew',
        lastName: 'Russell',
        emails: ['arussell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Andrew',
        lastName: 'Russell',
        emails: ['arussell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Michele',
        lastName: 'Woods',
        emails: ['mwoods@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  trigital: {
    name: 'Technium, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Michael',
        lastName: 'Joseph',
        emails: ['mjoseph@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Ben',
        lastName: 'Davis',
        emails: ['bdavis@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG30Gb_cglA_w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1533685683270?e=2147483647&v=beta&t=uNfUmtt6Vs6wgFTlXmF6wiA5g8w5jOqkq689bjMzSK0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Raela Ripaldi',
        lastName: '(she/her)',
        emails: ['r(she/her)@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHz0HCIkXcXmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516834432291?e=2147483647&v=beta&t=qhIxO2PeIn4cJbp83ycB2lJnLds_tzFhZWQ6Dhzcf-w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Katie',
        lastName: 'Perry',
        emails: ['kperry@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEfTyjBpWhqjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1524674259382?e=2147483647&v=beta&t=E6jLErhOFT9_JUUs2dRHUb6DnMf3zibpT5vyffvmT0U',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Marius',
        lastName: 'Janulis',
        emails: ['mjanulis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  documentolog: {
    name: 'Sumo Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Scott',
        lastName: 'Kirkland',
        emails: ['skirkland@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel',
        lastName: 'Secker',
        emails: ['dsecker@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Benjamin',
        lastName: 'Godwin',
        emails: ['bgodwin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHm-19e5IjnvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1709061671371?e=2147483647&v=beta&t=C6QKTL7b25de2VwA4UTrx7Qm8CNIN1yWz0Q46ExwIPk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Daniel',
        lastName: 'Stahl',
        emails: ['dstahl@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sophie',
        lastName: 'Benefer',
        emails: ['sbenefer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  allnet: {
    name: 'Celsior',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Vasu',
        lastName: 'Puvvada',
        emails: ['vpuvvada@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEMANY5JJK9Rw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1653474559275?e=2147483647&v=beta&t=b9vIg7DW9pitZrEgROa4tTcHPzf52y-1whZ4HFasrGk',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Chingangbam',
        lastName: 'Irabanta',
        emails: ['cirabanta@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Nitin',
        lastName: 'Sharma',
        emails: ['nsharma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sagir',
        lastName: 'Ahamed',
        emails: ['sahamed@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'David',
        lastName: 'DaCasto',
        emails: ['ddacasto@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  deaflead: {
    name: 'Tein Technology',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Françoise',
        lastName: 'Jockin',
        emails: ['fjockin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQF0Gvli0FQ3ig/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1579172081566?e=2147483647&v=beta&t=CKI32KL8HIF92SmX4SoZhw6Bt51U8jwUrBfPnhodL9A',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Gert De',
        lastName: 'Meyer',
        emails: ['gmeyer@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFeVi-cVG1JvQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1628578035419?e=2147483647&v=beta&t=9TMOo1dryPDFpaKTytw-thW5ja79flLtJq70dCAtvwI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Bram',
        lastName: 'Knudde',
        emails: ['bknudde@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFf-abO8vx7XA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517002630924?e=2147483647&v=beta&t=DpSf9xxxLi7JcXLu2OOhMAOqweqr1Gr3wJvw1SA5IUQ',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'FREDERIC',
        lastName: 'LONCOUR',
        emails: ['floncour@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEyjrSmTsPO1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553767615228?e=2147483647&v=beta&t=RCEwMeZPrQ4lf7_xZFhMDOwfFUWYWcXX4l7Qkb0ilgY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Laurens',
        lastName: 'Plumet',
        emails: ['lplumet@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFVuqVIX6dZ1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517284421582?e=2147483647&v=beta&t=y8qfKmMIgZgihAWh73i1Jh1Q7u-iAK_d-hBcQwW7G9E',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  metocean: {
    name: 'Baanyan Software Services, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Pundarika',
        lastName: 'Bibireddy',
        emails: ['pbibireddy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Dhruva',
        lastName: 'D',
        emails: ['dd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGOt9650so1bA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1720187478415?e=2147483647&v=beta&t=LK4Y0i2nHB0JjyamxSQ60oA0LTVMb7jQfaW-HKVGaA0',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Parikshit',
        lastName: 'Dey',
        emails: ['pdey@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGxziG6ic09FQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516581911333?e=2147483647&v=beta&t=53liw0HlkXHH7fSDu2c9zOWvJuwaoPSlqvGbfYnqvF4',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Siddharth',
        lastName: 'Kushal',
        emails: ['skushal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEpKlA2BA-uBQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1697813889288?e=2147483647&v=beta&t=l5D6MTsAVJ_babDGW6NQXXglkJ_uOU8WGDTj4UecKp8',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Kaleru',
        lastName: 'Vasu',
        emails: ['kvasu@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  consultsda: {
    name: 'Digitus Tec Pty Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Chamitha',
        lastName: 'Ranneththi',
        emails: ['cranneththi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEr9NO5tSUpSg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1698404867111?e=2147483647&v=beta&t=mmJ7lYclqX1sIozxhGY09srOJ56ZYW8-a9nhnpiy100',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Akila Sajith',
        lastName: 'Kumar',
        emails: ['akumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHHG_9CxQOLjA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1702403234647?e=2147483647&v=beta&t=e2KwBnnm97LtGrHF7-q2C6SinVqmdXAgfLLhNVg2tAs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanath Madhawa',
        lastName: 'Premachandra',
        emails: ['spremachandra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFiV7igZrItbQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516306033616?e=2147483647&v=beta&t=9ZYe82D31VIAvcPEYI7Geq4SkGegiBYTWvtb6MxY9-0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Bishal',
        lastName: 'Samaranayake',
        emails: ['bsamaranayake@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFgTkjJtBb-7w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730198656035?e=2147483647&v=beta&t=ZDysj-cJ5dj-PH19k3fN6dHSlwRIR8rnWYIDN3_h_Xw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Hashan',
        lastName: 'Perera',
        emails: ['hperera@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQF-AAFK_13soA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1700116118874?e=2147483647&v=beta&t=h25VeiXn5V5yF9sncQHoUrVjraQtLxeUVSZ-RDItc70',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  finmatics: {
    name: 'SeKON Enterprise, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Cameron',
        lastName: 'Kirby',
        emails: ['ckirby@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Sherrell',
        lastName: 'Nicholson',
        emails: ['snicholson@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Darren',
        lastName: 'Collins',
        emails: ['dcollins@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'RP',
        lastName: 'Sekon',
        emails: ['rsekon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGkC23cra1I9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517756995815?e=2147483647&v=beta&t=JI8YyvN6T4FLQg9J5U32OH4U1bzhjeH-XJ7Glx0ipsY',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Sekon',
        lastName: 'Stephens',
        emails: ['sstephens@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFJBspl0xRTFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1712236207252?e=2147483647&v=beta&t=2CLVQz-yDn8EINulyE_pOgE3vMsjOAFA1B3etgp_u6A',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  cxomni: {
    name: 'Trigital Technologies Pvt Ltd',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Venkat',
        lastName: 'Akula',
        emails: ['vakula@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nidhi',
        lastName: 'Kandwal',
        emails: ['nkandwal@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQH2lTd367r1SA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1572421717727?e=2147483647&v=beta&t=JWw49hQcFQLMEygvJlqrsl5Gk1y751-G3oLgsg6tBL8',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Vishal',
        lastName: 'Soni',
        emails: ['vsoni@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQE0e1pqFjZxyQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1704448646041?e=2147483647&v=beta&t=xLWOIfR8yxSRtXl4aeFBe796TjUYDreaiMUzwLfi-h0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rajat',
        lastName: 'Chopdekar',
        emails: ['rchopdekar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFSEnTbZONFzw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1730697951226?e=2147483647&v=beta&t=DR-3jJhKmN_zxKF9dZGlcCrOLyGRYV5hoVtEyTUf9rE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Shailesh',
        lastName: 'Nigam',
        emails: ['snigam@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQHFSC3gr1kFqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516267535858?e=2147483647&v=beta&t=z5rG78tU5l6mjDOgJnO3884AEuuVE281Tu4rfBdySWw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  qaware: {
    name: 'Documentolog',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Madina',
        lastName: 'Dusmagambetova',
        emails: ['mdusmagambetova@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE7YWiXgQVDZQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1682919539832?e=2147483647&v=beta&t=enYCTccOC4siYfYDCOJkgpv5KAg79d_YCLdAYq-UcIw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Baizhan',
        lastName: 'Kanafin',
        emails: ['bkanafin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Azamat',
        lastName: 'Samiyev',
        emails: ['asamiyev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHqViuiNoS2BQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1544086097242?e=2147483647&v=beta&t=_qNcxQXXr3wZQdq8nXlNqiQt7Lnwow3UXTFTvlyipkk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Vitaliy',
        lastName: 'Lebedev',
        emails: ['vlebedev@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHUrjkmbzlcSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1570425506423?e=2147483647&v=beta&t=UN0phqPcUqQsPRfFUrDFxcZqfr6qdMbsNY6JD0hwFco',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Gulzat',
        lastName: 'Temirbayeva',
        emails: ['gtemirbayeva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFYJNcZrEHrFw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1665570335103?e=2147483647&v=beta&t=FJTr6LJHBzhw9uSF4CSwf95G_22bAZNhDXLov90nKLU',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  legion: {
    name: 'ALLNET GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Martin Buerki – Unternehmensinhaber – Buerki Innomed AG |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Frederic Buerki – Team Head IT Operations – UPGREAT AG |',
        lastName: 'LinkedIn',
        emails: ['flinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQETc87PxjHUjg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678991593488?e=2147483647&v=beta&t=-HteMawpcfb6D2h5U8O2hxccUpmB90LK_5qMZJMsnKM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Regula Moser – Finance Accounting Specialist – Comet Group',
        lastName: '...',
        emails: ['r...@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Timothy Wagner – Inhaber / Geschäftsleiter – Bürki Moser |',
        lastName: 'LinkedIn',
        emails: ['tlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHelE5h4h9bDw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1678267798015?e=2147483647&v=beta&t=byms0pIdjOE06jUUm6JdDHIaQSIjEafOR82fqTiUUqI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elena Moser – Office Manager – Renera AG |',
        lastName: 'LinkedIn',
        emails: ['elinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEKhqxfao2-GQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1648121359834?e=2147483647&v=beta&t=S-zemMO51pzjaECMHVa2p7TVZ1bCid0Zj42aZX-s1u8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  aiinformatics: {
    name: 'DeafLEAD',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Tiffanie Ware,',
        lastName: 'M.S.',
        emails: ['tm.s.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFeBuffhq9Qqw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691175930123?e=2147483647&v=beta&t=tUJ2m_Wv8n7JKx8bCsF08pyZQSWxnEVOsaMB4XM9XTE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Tyler',
        lastName: 'Hannsz',
        emails: ['thannsz@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Angela',
        lastName: 'Rogers',
        emails: ['arogers@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGF5_D1y6wlmw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728524056915?e=2147483647&v=beta&t=ikr5D0i1XxxlYJzuqmbcGttNmW-e0gV40eA3IuNQ5l0',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Mark',
        lastName: 'Mavel',
        emails: ['mmavel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE0jqQvPazHbg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595786809868?e=2147483647&v=beta&t=zDC9y8f4CAKKClXDW1OHfqFtoyqDpWxB5_O3Lq44R44',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jennifer',
        lastName: 'Sanders',
        emails: ['jsanders@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFWY6aRbi0vzQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680253779524?e=2147483647&v=beta&t=NKlShespd60jcZYWf1-T2QJtDZ1nmOd_tDXaTYTGCo8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  mavinsys: {
    name: 'Data Center Group',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Harika',
        lastName: 'Vaka',
        emails: ['hvaka@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Y',
        lastName: 'CHANIKYA',
        emails: ['ychanikya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHjSf3mK9GZ_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718280425133?e=2147483647&v=beta&t=Y8R9sCqZxn6f0ludo8LJbxOb0O3MotXndjN2MnhZFL4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sathish',
        lastName: 'S',
        emails: ['ss@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chandragiri Harsha',
        lastName: 'Vardhan',
        emails: ['cvardhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEegbr_WZD7Gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723588534112?e=2147483647&v=beta&t=MgEXEFvYsoqpvU-_tEpBbz3MI8D0oHw8527SGIOEeSQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Manish',
        lastName: 'Yadav',
        emails: ['myadav@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rdalabs: {
    name: 'MetOcean Telematics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Rahul',
        lastName: 'Ravindran',
        emails: ['rravindran@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQE_Z36oT-1SFg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610027050983?e=2147483647&v=beta&t=We8MGcIJevzSEIUa8QYuVP1h6bxh8Gw7n2Zpx9RNSDI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Geoffrey',
        lastName: 'Creighton',
        emails: ['gcreighton@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQELwlr7JMakfg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1675969266986?e=2147483647&v=beta&t=0eMRnA1uftHgGZkzt-0EBfGEtUPy2ZHAc5APjp1ijBU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Lee',
        lastName: 'Foster',
        emails: ['lfoster@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEkTTzBqqnc-Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1634810559341?e=2147483647&v=beta&t=gO4cALx1JvDyHtxTPk1xP-z_qXIwE1h-QQzDBT60Zpw',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Harinder',
        lastName: 'Singh',
        emails: ['hsingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFYwmqU_sI2Zw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516887803592?e=2147483647&v=beta&t=IVHocyGUEcyjIp3UcCcLMFpNDEgJ7YmkdSM2uQg5-uA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Cathy MacDonald,',
        lastName: 'CPA',
        emails: ['ccpa@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  northernrecruiters: {
    name: 'SDA',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Derek',
        lastName: 'Tournear',
        emails: ['dtournear@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGiuGb5QoA-Qg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1606316852539?e=2147483647&v=beta&t=kLR32fBcMM3cLJDVQS-hedkGItg4bLQR3on87rMtxQI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Paula',
        lastName: 'Trimble',
        emails: ['ptrimble@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Frank',
        lastName: 'Turner',
        emails: ['fturner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQG2LAtqaFvEWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1635795430431?e=2147483647&v=beta&t=WmMAgzZAbs0jPMysR-f2c0979OrXFwnjWzuXf6A6xpk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Heather',
        lastName: 'Campbell',
        emails: ['hcampbell@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Alexander',
        lastName: 'Rasmussen',
        emails: ['arasmussen@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEZmFYlhShz-g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1683910119993?e=2147483647&v=beta&t=xFaeO-toVBQa0VG9P2PRYTaoo0B3kJ8GLn-AFhlx41I',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  grf: {
    name: 'Finmatics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Christoph Prieler – Finmatics GmbH |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFfF2LH-oeFoA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1603376637817?e=2147483647&v=beta&t=hi5KgZ8syr6QBdKKAH7Wkz39EbZLznsgEcOruLELSjU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName:
          'Natalie Weiss – Sales Development Representative – Finmatics',
        lastName: '...',
        emails: ['n...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHWqmeeD4ZpiQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1661260015707?e=2147483647&v=beta&t=R4dhZorourXoFpjcInVssnxlKlUT41JETKhmnEHD34I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Severin Kliegl – Information Security Officer – Finmatics |',
        lastName: 'LinkedIn',
        emails: ['slinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFgHDYJ2edjJw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669299209725?e=2147483647&v=beta&t=9tezIJ1WIVRvKHsnZJ23YACp3MkBs3LOXBt3QRvzSso',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Sarah Ebner – Finmatics |',
        lastName: 'LinkedIn',
        emails: ['slinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQH5XcBzKhVwSQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726915081802?e=2147483647&v=beta&t=p6c86ABaXaJTrmiqmJdyCgrexXKIX8aC9JZgZmpOejI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Markus Gremmel – Finmatics |',
        lastName: 'LinkedIn',
        emails: ['mlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  smartnetsol: {
    name: 'cxomni',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Robert Horndasch – Chief Executive Officer – cxomni CXM cloud',
        lastName: '...',
        emails: ['r...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEZov7dLRNE8g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1710774775124?e=2147483647&v=beta&t=EO7cD2cwW0cyLM7yDUDEP8E2LByE38dhZshUwCHImXw',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Johannes',
        lastName: 'Wicht',
        emails: ['jwicht@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEi4zy2t3RqfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724267481744?e=2147483647&v=beta&t=cEpKpHV09DK4Er0UTZVYfZHhqGWdkO7GkEqR8rAMRbA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Christian Peksen – Sales Manager – cxomni CXM cloud |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQF5p4_hx9MIaQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1696595392938?e=2147483647&v=beta&t=ZqXZL79b-qoo8DnErmshfXmBaEGLIAMHeOpiCNfuDxM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Johannes Wicht – cxomni |',
        lastName: 'LinkedIn',
        emails: ['jlinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEi4zy2t3RqfQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1724267481744?e=2147483647&v=beta&t=cEpKpHV09DK4Er0UTZVYfZHhqGWdkO7GkEqR8rAMRbA',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Elnaz Mazaheri – cxomni CXM cloud |',
        lastName: 'LinkedIn',
        emails: ['elinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQENa0eofMexUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660724765993?e=2147483647&v=beta&t=tN7BtUo8shaAfHzdjNhSll6oc2QW3WBx8KMRNZleyz8',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  fingerprints: {
    name: 'QAware GmbH',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mario',
        lastName: '',
        emails: ['m@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHBNN_XMt3sng/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1654959296878?e=2147483647&v=beta&t=oNxDIM676pCyDpKvP8171TAnS7fR5G5mtcjAs84gCN8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Felix',
        lastName: 'Ketelaar',
        emails: ['fketelaar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFfYTUWWCRsPQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1667558445629?e=2147483647&v=beta&t=QXZxHDhW8xuyjQKyHAhEkOEGaAq9-OsROluZh94yPWI',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Charlotte Kuhn – QAware GmbH |',
        lastName: 'LinkedIn',
        emails: ['clinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFavSs0vbtpEQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1671143561438?e=2147483647&v=beta&t=5Y77xnJ8HhO4IgP3BhT5p3nOxhl34OikK10IgBg4iXs',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jens Wetterich – Senior Software Engineer – QAware GmbH',
        lastName: '...',
        emails: ['j...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGc2fyBGaxm4A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660855234720?e=2147483647&v=beta&t=aMtelhb_MX-2rlREI0pDjXyINvv-tOAXrTbdqqzBEuw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Joonas',
        lastName: 'Palm',
        emails: ['jpalm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHZUR-OCo5enw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1568994513870?e=2147483647&v=beta&t=xQU2Z7u_r_y-aKoK36hyjBwy2aWZf9AQaBkJ97XkzOI',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  rolloutsys: {
    name: 'Legion Technologies',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Robert',
        lastName: 'Means',
        emails: ['rmeans@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE3EDQDUxrhAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1657576339898?e=2147483647&v=beta&t=5fSvC5c0G4WLT0uKPGm2vK2QfZDM66BbqLCggt3OWmU',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Alina',
        lastName: 'Kozyreva',
        emails: ['akozyreva@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEfMhGVgiMy_Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1594057745601?e=2147483647&v=beta&t=wOpicbsHbHT7m2hbWOTDFl-q2LvQj3jNS2esNN21HhQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Jillian',
        lastName: 'Clancy',
        emails: ['jclancy@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGzyByHchmEgg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516845566830?e=2147483647&v=beta&t=AYX_YRoWliRal9EOqjGlkPtQHTwg-_AyC6mSF774694',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gopal',
        lastName: 'Sundaram',
        emails: ['gsundaram@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHEb-572e63pQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517748444311?e=2147483647&v=beta&t=uByTegN-leX10bA3oQmM5khZm9ZBzDcK7rWmBfC242c',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Susan',
        lastName: 'McGovern',
        emails: ['smcgovern@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEZmxBzfg6SJg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1553106756503?e=2147483647&v=beta&t=Pdk0946hiW7tt2llK2Hf3yq37c6DSOa3aT1guyZDnmw',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  ksthawaii: {
    name: 'ai informatics',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Sharon Zhou,',
        lastName: 'PhD',
        emails: ['sphd@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFJPYiPl9U4Ww/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1652407227345?e=2147483647&v=beta&t=A5iQIVJU9ePpPOApEXB8I94z-OOL9YQesRgMoLbJvsE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Cui Tao, PhD',
        lastName: 'FACMI',
        emails: ['cfacmi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQE0h55mv_D0gA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1524022717333?e=2147483647&v=beta&t=DwqSZ7yUfu_LYdo1s4vDvR8nKdVPi1YmkRoxOCkgO1I',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Gillian',
        lastName: 'Hayes',
        emails: ['ghayes@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFiF2aYrhKs2Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1637096017469?e=2147483647&v=beta&t=CoND7qZUH5tPwsTXRTQ_udjz1q_TwUwi9_nFiwD_fCY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Graham Walker,',
        lastName: 'MD',
        emails: ['gmd@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Andrew',
        lastName: 'Taylor',
        emails: ['ataylor@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQHDFDmlLWLExQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1726345370772?e=2147483647&v=beta&t=SlFJQKALXEFmY0Hd-PXDL3rjCqZE4yXBRXs65Gs1cMY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  innovagency: {
    name: 'Mavinsys',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Harika',
        lastName: 'Vaka',
        emails: ['hvaka@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Y',
        lastName: 'CHANIKYA',
        emails: ['ychanikya@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQHjSf3mK9GZ_g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718280425133?e=2147483647&v=beta&t=Y8R9sCqZxn6f0ludo8LJbxOb0O3MotXndjN2MnhZFL4',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sathish',
        lastName: 'S',
        emails: ['ss@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Chandragiri Harsha',
        lastName: 'Vardhan',
        emails: ['cvardhan@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQEegbr_WZD7Gw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1723588534112?e=2147483647&v=beta&t=MgEXEFvYsoqpvU-_tEpBbz3MI8D0oHw8527SGIOEeSQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Manish',
        lastName: 'Yadav',
        emails: ['myadav@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  nexuminc: {
    name: 'RDAlabs LLC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Reji',
        lastName: 'Baby',
        emails: ['rbaby@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGo-E-1lDuoXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1580912708131?e=2147483647&v=beta&t=H1h2aqxkxBXDnFHGsiclXLcPTzY6HOYEN4pQPAcJuBM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Akhilesh Pratap',
        lastName: 'Singh',
        emails: ['asingh@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQGSbpQMSzI2kA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550407643589?e=2147483647&v=beta&t=Gb3Hpy2VV1_W0wb11FIWk22hRAvTW6wBwI4_Q2lFr2s',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Sheba Mary – Regensburg, Bayern, Deutschland | Berufliches Profil',
        lastName: '...',
        emails: ['s...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEcV_QXZaF_mA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517453892092?e=2147483647&v=beta&t=TJEANmUetTn3Fu_XExNZlMQlCe7pex37Nrme_ZkDWRU',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Ankur',
        lastName: 'Jasoria',
        emails: ['ajasoria@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGLHhyzsCsJqQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658165490446?e=2147483647&v=beta&t=DDCea44PVR0-vm6OfHn8L8Ie9ydvG1dknF-yRNLtoy4',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Byju',
        lastName: 'Valappil',
        emails: ['bvalappil@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGNDPV5Ji7xuQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516609540957?e=2147483647&v=beta&t=mbioechg6FPX3is847G9NHgTNgd1gp4_Y5tZr8BKQsc',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  shaw: {
    name: 'NT&MC',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Mark',
        lastName: 'Wilson',
        emails: ['mwilson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHdXYaTzUR9_g/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1516791196050?e=2147483647&v=beta&t=rEpbJCxUxxU0XWxWh8vC3rRW2VoRlGI39cSnWcv79NI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Maria',
        lastName: 'Diana',
        emails: ['mdiana@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQG8VNR6NLWyWg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1644071789195?e=2147483647&v=beta&t=dag4z5iQhTR0OU1-xFbU604Awa4OLpL-105EsMgPwWE',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Alvina',
        lastName: 'Kar',
        emails: ['akar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGRyMculd3tSA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1633170830114?e=2147483647&v=beta&t=7yD7HCcO19otZUUP4pWNTrD8NdSSmdq-tBEcMROxnbE',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Julie',
        lastName: 'Willis',
        emails: ['jwillis@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Asma',
        lastName: 'Shafeeq',
        emails: ['ashafeeq@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFMXq1Mr2JHMA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1680097606816?e=2147483647&v=beta&t=kNuWW2itYMwTy1mKvYmd7iDP_K5y39dTZDCiux45PzY',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  daplitservices: {
    name: 'SmartNet IT Solutions',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Karuna',
        lastName: 'Loyapally',
        emails: ['kloyapally@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Nag',
        lastName: 'Kanuganti',
        emails: ['nkanuganti@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sasa',
        lastName: 'Tancev',
        emails: ['stancev@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Jesper',
        lastName: 'Bwalya',
        emails: ['jbwalya@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Jordan',
        lastName: 'Chifwalo',
        emails: ['jchifwalo@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  onpointcorp: {
    name: 'Fingerprint Cards',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName:
          'Pontus Jägemalm – Senior Vice President, Chief Technology Officer',
        lastName: '...',
        emails: ['p...@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQEKTe0GR9LrXQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699454882373?e=2147483647&v=beta&t=4ibGsZ185mPY3Dd8e1O_ByiM6qwp9cR7DQ2GIJveiWQ',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Adam',
        lastName: 'Philpott',
        emails: ['aphilpott@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQGfkAkxHfPSvg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1713255812501?e=2147483647&v=beta&t=wYV0PCpjzXYQqe9t8LPBhtFk89KhlFywkYBxGZKwkyQ',
      },
      {
        id: 'U077TRRJQ3H',
        firstName:
          'Fredrik Ramberg – Chief Product Officer – Fingerprint Cards |',
        lastName: 'LinkedIn',
        emails: ['flinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Dimitrij Titov – Board Member – Fingerprint Cards |',
        lastName: 'LinkedIn',
        emails: ['dlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Amit',
        lastName: 'Kumar',
        emails: ['akumar@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQHAuDtIrNxpmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699613062920?e=2147483647&v=beta&t=LgqPGxRWXUdnVzOXERU5SJQBfGP5F05hxOupZKNJfZo',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  avaamo: {
    name: 'Rollout Systems',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Shari Arends,',
        lastName: 'SHRM',
        emails: ['sshrm@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFVoQOB3QGh1A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1728701562129?e=2147483647&v=beta&t=o_oYM7DP92Q6F6Xqk_4jgo9Dr90rBpEdIsZ6oxRlxyo',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jeff',
        lastName: 'Cox',
        emails: ['jcox@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHCGx60Ra0UXA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517730282131?e=2147483647&v=beta&t=N0Z6OLwEqaCKEqKEKvHdP8OKmBzwhEQhLKE_zZAebkc',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'MICHELLE',
        lastName: 'CHAPPIE',
        emails: ['mchappie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFi516M66VqMg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517739645033?e=2147483647&v=beta&t=vQws1AduiUOwUeJ6fuEvm0DbZN4Rhy_hltBn6IB5ibk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Greg',
        lastName: 'Hefner',
        emails: ['ghefner@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFLE1XzUhbx9g/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1541079450852?e=2147483647&v=beta&t=oPfMgFbUnCqpoX921D5HdW5o_4MHW8yQ9PfUrIOJLcI',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tony',
        lastName: 'Kamenicky',
        emails: ['tkamenicky@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  webidSolutions: {
    name: "Ka'ala Systems Technology, Corporation",
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Andrew',
        lastName: 'Yunson',
        emails: ['ayunson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEaTwP2qiY_Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1600981407642?e=2147483647&v=beta&t=lMWMkGuEhTYFLq0iaPXS7ggFqh0JXxibPKwIx2uXrYE',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'William',
        lastName: 'Burnett',
        emails: ['wburnett@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQFOcD-Y1YGNfw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688153509773?e=2147483647&v=beta&t=0BdznBQh7EB-bdp844MMKJYAwBi_hMbVj5vI3Ot0IHU',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'KN',
        lastName: 'Nethon',
        emails: ['knethon@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHDYEZ_rl5gVA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1630298315605?e=2147483647&v=beta&t=4pGPNjyrJiYPy9RW88nsscM3sy2F1xHEgBMsVDQnUXM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Shane T.',
        lastName: 'Neal',
        emails: ['sneal@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lloyd',
        lastName: 'Richardson',
        emails: ['lrichardson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFLQftdVrtGmQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1624656961543?e=2147483647&v=beta&t=IgQWVTG8TWec9IgQD7PD6X9A-xgCI42ZQwrbGy8Evgg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  digitalspine: {
    name: 'Innovagency',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Jolein',
        lastName: 'Baidenmann',
        emails: ['jbaidenmann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEh1xdP0BU-0Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1595972352896?e=2147483647&v=beta&t=TW1H8mlkZr48JxwXV4eFTgGnadm3XWK6jSnVlau_Cr8',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'McNamara',
        emails: ['jmcnamara@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFA_oQhi6-2Cg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1550396093058?e=2147483647&v=beta&t=UjCGUYURQjeWRqqY4aVr2zRfGp2hfW3gtti4Hmk8pPs',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sanjay Kathuria,',
        lastName: 'CFA',
        emails: ['scfa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGOnHGzYj8uJA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718320382191?e=2147483647&v=beta&t=kcD1UvTH8LunF7y2j5RRihHE-lvdhL12T4wkbdaIbUo',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Cheryl Lovell,',
        lastName: 'Ph.D.',
        emails: ['cph.d.@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEhuOC9Fv-6Nw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517577954906?e=2147483647&v=beta&t=_EBTDrm9dPLJI7vcx_FLjKKajpaIfAzxPGCZ6espKEQ',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Tim',
        lastName: 'Ormrod',
        emails: ['tormrod@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQEMakqPXtiQzg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1699879107355?e=2147483647&v=beta&t=i5pl6pA980bA7ey4ZtngTWrUbaiFnjUWOkYlMp4K3lg',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bigdatacorp: {
    name: 'Nexum, Inc.',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'David J.',
        lastName: 'Lesser',
        emails: ['dlesser@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Darrell',
        lastName: 'Potie',
        emails: ['dpotie@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQEACRGfCrcWOw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1604334758656?e=2147483647&v=beta&t=fiTinvtpm8xPUyPBhHTIg_YNx_opQkBAoHg9ZzrA9RM',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'J.D.',
        lastName: 'Butt',
        emails: ['jbutt@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQGIANJJi9r8vA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517704866496?e=2147483647&v=beta&t=R6OKbxivUsFHkKHXIDaJIRz8-dmrBkt2J-lSxJE3n0w',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Allison',
        lastName: 'Souza',
        emails: ['asouza@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQEvNGA_LRIHew/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1517282906804?e=2147483647&v=beta&t=PXB9QVBa57lyJwq0Vi6PCMgLf0GDtc920thQSPAkl1s',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Megan',
        lastName: 'Harris',
        emails: ['mharris@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  deltadatasoft: {
    name: 'Independent coach and consultant',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Zachary',
        lastName: 'Perin',
        emails: ['zperin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF-S7v_RTrySQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1620059027187?e=2147483647&v=beta&t=4tpw4b954Q2oKIAdBDf0ZR7ibjTw9N_E2RKVWdXwkBM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'John',
        lastName: 'Nesheim',
        emails: ['jnesheim@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Linda E.',
        lastName: 'Laddin',
        emails: ['lladdin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQFrV7xSe1JpGw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1643812903031?e=2147483647&v=beta&t=JLSHt16GhnwRphAAav6ppbDymEIHwRb9r1TYhte587Q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gill',
        lastName: 'Caleary',
        emails: ['gcaleary@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQHxm1T0KHk0-A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1658745800720?e=2147483647&v=beta&t=J8zlUlqfmgyDF_cVk2JMfzlp4D7c8NHUKhk22cf2Zgk',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Lisa',
        lastName: 'Frusztajer',
        emails: ['lfrusztajer@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  gigsky: {
    name: 'BCS HR Software',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ludmila van den',
        lastName: 'Enden',
        emails: ['lenden@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQFE92NuiK9AOg/profile-displayphoto-shrink_100_100/profile-displayphoto-shrink_100_100/0/1710253987484?e=2147483647&v=beta&t=RQR089ovlALw7zk-3cmVGVC-5r3LwXVdzVPMZxBlzVI',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Lonneke van',
        lastName: 'Eekelen',
        emails: ['leekelen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Louis',
        lastName: 'Lambooy',
        emails: ['llambooy@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Gert Van',
        lastName: 'Meerbergen',
        emails: ['gmeerbergen@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Nick',
        lastName: 'Rietberg',
        emails: ['nrietberg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4D03AQGRY9nM72bWUQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1670963304204?e=2147483647&v=beta&t=B685bM_63HJB4kp-3xaKE3hZAdrCHDkE6fbO0KNw_bE',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  net56: {
    name: 'DAPL IT Services',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Guneet',
        lastName: 'Kaur',
        emails: ['gkaur@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQFYWF4bmDjKlg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1531295284548?e=2147483647&v=beta&t=22AwihbcTwbgRXhfVQSjFF2r1sAconwFOARNnPm1uwM',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'CA Kuldeep',
        lastName: 'Joshi',
        emails: ['cjoshi@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGYDRZI30L3Vw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1669877701306?e=2147483647&v=beta&t=ksefY5zay0q8OEnQ_qoKfIxtf7BOwPUqoxqlAiFqyoA',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Saahil',
        lastName: 'Samel',
        emails: ['ssamel@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4E03AQFICQVKhs3qIQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1590810064673?e=2147483647&v=beta&t=6GweupPKyCr8O78nb4dexGw_EHf6LcU0iG1qtXxyLVM',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Amita',
        lastName: 'Sharma',
        emails: ['asharma@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Edgardo',
        lastName: 'Gonzales',
        emails: ['egonzales@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  unifiedTeam: {
    name: 'OnPoint',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Donna',
        lastName: 'Levin',
        emails: ['dlevin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFHRFOM_JReEg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516763204242?e=2147483647&v=beta&t=nMaIAdJVbbbkRWYjbeFFX4l7aj2AQZLmmEZZ_FGAGAA',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Josh',
        lastName: 'Lang',
        emails: ['jlang@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQE4blvLdbLsiA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1714504640558?e=2147483647&v=beta&t=A8Mg1aFaEmiXdExTasaFa8u39z8-GMVotPjEP-1RLes',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Kirsty',
        lastName: 'McGrath',
        emails: ['kmcgrath@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQF56KqpgPdW1w/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1622422837689?e=2147483647&v=beta&t=jt6lxw9pWjyjdXl9J6S1I79s7pZl1mmRZ7Q26Wgd-AY',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Rico',
        lastName: 'A',
        emails: ['ra@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQE11XNvxRbtAA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1610450273916?e=2147483647&v=beta&t=mtKueEJp_Z9ATiweEuRT4bryiWwz-vRfB1yk84kJ3tw',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Houck',
        lastName: 'Reed',
        emails: ['hreed@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQGfcgskflOofA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516262559736?e=2147483647&v=beta&t=EnNgfSeRF3lRWS4aHQqB3s8BfiyC1GN6NWl-NG_bX3c',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  sequelServices: {
    name: 'Avaamo',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Ranjit',
        lastName: 'Anand',
        emails: ['ranand@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5103AQEApVxOOBU4zg/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1586537600838?e=2147483647&v=beta&t=cxuPNG5Mm5xhh_UZRQoVOEIFrgOmLFQ3-SlDA4A-L7g',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Jayanth',
        lastName: 'G',
        emails: ['jg@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D5603AQGR_5r3o1defQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1688487918686?e=2147483647&v=beta&t=7vd72YGBZckbfpIn7VMzsgF-hCYS0WA7_LQUloCMt0w',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sal',
        lastName: 'LaRosa',
        emails: ['slarosa@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQEznz4V4XeJRw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516247340340?e=2147483647&v=beta&t=qT85Wb-owvuSZnqgTN5tNtyI8LvItdG5LUKL9TDja-o',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Madhav',
        lastName: 'Vodnala',
        emails: ['mvodnala@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Christian',
        lastName: 'Nall',
        emails: ['cnall@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
  bitwave: {
    name: 'WebID',
    users: [
      {
        id: 'U077TRRJQ3F',
        firstName: 'Frank S. Jorga – WebID |',
        lastName: 'LinkedIn',
        emails: ['flinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3G',
        firstName: 'Daniel Kreis – WebID |',
        lastName: 'LinkedIn',
        emails: ['dlinkedin@example.com'],
        image:
          'https://static.licdn.com/aero-v1/sc/h/1c5u578iilxfi4m4dvc4q810q',
      },
      {
        id: 'U077TRRJQ3H',
        firstName: 'Sam',
        lastName: 'Nelson',
        emails: ['snelson@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C5603AQHjApZ6HUbu5A/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516314470033?e=2147483647&v=beta&t=FnQhmleNE66vCZ6HW9Oh0972OtK6eiuZcP1_-b3iaZk',
      },
      {
        id: 'U077TRRJQ3I',
        firstName: 'Irena Anna',
        lastName: 'Neumann',
        emails: ['ineumann@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/D4E03AQFV8mWcQwUuQw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1725984252881?e=2147483647&v=beta&t=wG0hlJQomUCo0bS8O-EdNUEbDsv0hXvk-L-XJZnO3OE',
      },
      {
        id: 'U077TRRJQ3J',
        firstName: 'Fritz Tupy – WebID Austria GmbH |',
        lastName: 'LinkedIn',
        emails: ['flinkedin@example.com'],
        image:
          'https://media.licdn.com/dms/image/v2/C4D03AQFV3ippMv_xFQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1660129393338?e=2147483647&v=beta&t=ABuerw-yez65qr_-EivjhDz4GEbH9gDqqesvZb1rvac',
      },
    ],
    values: [
      { label: 'Customer obsession', id: '1' },
      { label: 'Be a hustler', id: '2' },
      { label: 'Ownership', id: '3' },
      { label: 'Focus on quick wins', id: '4' },
      { label: 'Resourcefulness', id: '5' },
    ],
  },
}
