import { useClerk } from '@clerk/clerk-react'
import { useEffect, useMemo, useState } from 'react'
import { useAuth, useUser, useOrganizationList } from '@clerk/clerk-react'
import { Outlet, useNavigate } from 'react-router-dom'
import posthog from 'posthog-js'

import { Loading } from '@/components/ui/loading'
import { MOCK_SERVER_ENABLED } from '@/services/config/env'

export const ProtectedPage = () => {
  const [createOrganizationCalled, setCreateOrganizationCalled] =
    useState(false)
  const clerk = useClerk()
  const { isLoaded: isAuthLoaded, isSignedIn } = useAuth()
  const { user } = useUser()
  const { userMemberships } = useOrganizationList({
    userMemberships: true,
  })
  const navigate = useNavigate()

  // Determine if we're still loading anything
  const isLoading = useMemo(() => {
    if (MOCK_SERVER_ENABLED) return false
    return !isAuthLoaded || userMemberships?.isLoading !== false
  }, [isAuthLoaded, userMemberships])

  // Handle authentication and organization routing
  useEffect(() => {
    if (MOCK_SERVER_ENABLED) return
    if (!isAuthLoaded) return // Wait for auth to load

    if (!isSignedIn) {
      navigate('/sign-in')
      return
    }
    if (userMemberships?.isLoading !== false) return // Wait for org data to load

    if (!userMemberships.count && !createOrganizationCalled) {
      clerk.openCreateOrganization({
        hideSlug: true,
        afterCreateOrganizationUrl: '/licensing',
      })
      setCreateOrganizationCalled(true)
    }
  }, [
    isSignedIn,
    isAuthLoaded,
    userMemberships,
    navigate,
    clerk,
    setCreateOrganizationCalled,
    createOrganizationCalled,
  ])

  // Handle PostHog user identification
  useEffect(() => {
    if (user) {
      posthog.identify(user.id, {
        email: user.primaryEmailAddress?.emailAddress,
        firstName: user.firstName,
        lastName: user.lastName,
        image: user.imageUrl,
      })
    } else {
      posthog.identify(undefined)
    }
  }, [user])

  if (isLoading) {
    return (
      <Loading className="absolute top-1/2 left-1/2 size-12 text-green-600" />
    )
  }

  if (MOCK_SERVER_ENABLED || isSignedIn) {
    return <Outlet />
  }

  return null
}
