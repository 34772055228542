import { useState, useEffect } from 'react'
import { useOrganization, useUser } from '@clerk/clerk-react'
import { Textarea } from '@/components/ui/textarea'
import { Plus, X } from 'lucide-react'
import { cn } from '@/lib/utils'

import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Button } from '@/components/ui/button'
import { LayoutTile } from '@/components/layout'
import { H3, P } from '@/components/ui/typography'
import { TooltipWithIcon } from '@/components/common/tooltip'
import { Card } from '@/components/ui/card'

import { MOCK_SERVER_ENABLED } from '@/services/config/env'
import { useUserRole } from '@/hooks/useUserRole'
import { useFetchIntegrations } from '@/services/api/organization.api'
import { useGoogleOAuth } from '@/hooks/useGoogleOAuth'

import SlackLogo from '@/assets/slack.svg'
import GoogleLogo from '@/assets/google.svg'
import { SLACK_OAUTH_URL } from '@/services/config/env'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

interface Integration {
  id: string
  name: string
  logo: string
  description: string
  isConnected: boolean
  isDisabled?: boolean
  disabledReason?: string
  onConnect: () => void
}

export function SettingsPage() {
  const { organization } = useOrganization()
  const { user } = useUser()
  const { isAdmin } = useUserRole()
  const { data: integrations = [], isLoading: isLoadingIntegrations } =
    useFetchIntegrations()
  const [slackConnected, setSlackConnected] = useState(false)
  const [googleConnected, setGoogleConnected] = useState(false)
  const { connectGoogle } = useGoogleOAuth({
    redirectUrl: `${window.location.origin}/settings`,
  })
  const [orgValues, setOrgValues] = useState([{ name: '', description: '' }])
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)

  useEffect(() => {
    if (!isLoadingIntegrations) {
      setSlackConnected(
        Array.isArray(integrations) &&
          integrations.some(
            (integration) =>
              integration.type === 'SLACK' && integration.enabled,
          ),
      )
    }
  }, [integrations, isLoadingIntegrations])

  useEffect(() => {
    if (user) {
      const googleAccount = user.externalAccounts.find(
        (account) =>
          account.provider === 'google' &&
          account.verification?.status === 'verified',
      )
      setGoogleConnected(!!googleAccount)
    }
  }, [user])

  const organizationIntegrations: Integration[] = [
    {
      id: 'slack',
      name: 'Slack',
      logo: SlackLogo,
      description: 'Enables the Fiddy Slack bot to collect feedback.',
      isConnected: slackConnected,
      isDisabled: MOCK_SERVER_ENABLED,
      disabledReason: 'Slack integration is disabled in demo mode',
      onConnect: () => {
        if (MOCK_SERVER_ENABLED || slackConnected) return
        window.location.href = SLACK_OAUTH_URL(organization?.id || '')
      },
    },
  ]

  const userIntegrations: Integration[] = [
    {
      id: 'google',
      name: 'Google',
      logo: GoogleLogo,
      description:
        'Connect your Google account to enable calendar sync and meeting scheduling.',
      isConnected: googleConnected,
      isDisabled: MOCK_SERVER_ENABLED,
      disabledReason: 'Google integration is disabled in demo mode',
      onConnect: async () => {
        if (MOCK_SERVER_ENABLED || googleConnected) return
        await connectGoogle()
      },
    },
  ]

  const removeValue = (index: number) => {
    if (orgValues.length <= 1) return
    setOrgValues(orgValues.filter((_, i) => i !== index))
  }

  const updateValue = (
    index: number,
    field: 'name' | 'description',
    value: string,
  ) => {
    const newValues = [...orgValues]
    newValues[index] = { ...newValues[index], [field]: value }
    setOrgValues(newValues)
    setHasChanges(true)
  }

  const handleSaveValues = () => {
    // TODO: Implement the actual save logic here
    setHasChanges(false)
    setIsConfirmDialogOpen(false)
  }

  return (
    <LayoutTile>
      <div className="space-y-2">
        <H3 className="font-semibold">Settings</H3>
        <P className="text-sm text-muted-foreground">
          {isAdmin
            ? 'Configure your user and organization preferences.'
            : 'Configure your user preferences.'}
        </P>
      </div>
      <div className="space-y-6">
        {isAdmin && (
          <Card className="p-6">
            <div className="flex flex-col space-y-1.5 mb-6">
              <h3 className="font-semibold leading-none tracking-tight">
                Organization settings
              </h3>
              <P className="text-sm text-muted-foreground">
                This section is only available to organization Admins.
              </P>
            </div>
            <div className="space-y-6">
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Label htmlFor="org-performance-cycle">
                    Performance Cycle
                  </Label>
                  <TooltipWithIcon
                    content="This feature will be available in a future update."
                    type="info"
                  />
                </div>
                <Input
                  id="org-performance-cycle"
                  placeholder="Quarterly"
                  disabled
                />
              </div>
              <div className="space-y-2">
                <div className="flex items-center gap-2">
                  <Label htmlFor="org-preferred-language">
                    Preferred Language
                  </Label>
                  <TooltipWithIcon
                    content="This feature will be available in a future update."
                    type="info"
                  />
                </div>
                <Input
                  id="org-preferred-language"
                  placeholder="Auto-detect"
                  disabled
                />
              </div>
              <div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Label>Values</Label>
                    <TooltipWithIcon
                      content="Changing existing values will trigger a recalculation of the sentiment analysis for the current cycle across the organization."
                      type="warning"
                    />
                  </div>
                  <div className="flex gap-2">
                    <TooltipWithIcon
                      content="This feature will be available in a future update."
                      type="info"
                    >
                      <div>
                        <Dialog
                          open={isConfirmDialogOpen}
                          onOpenChange={setIsConfirmDialogOpen}
                        >
                          <DialogTrigger asChild>
                            <Button
                              variant="default"
                              size="sm"
                              disabled={!hasChanges || orgValues.length < 3}
                            >
                              Save Values
                            </Button>
                          </DialogTrigger>
                          <DialogContent>
                            <DialogHeader>
                              <DialogTitle>Confirm Values update</DialogTitle>
                              <DialogDescription>
                                Updating organization values will trigger a
                                recalculation of the sentiment analysis for all
                                feedback in the current cycle. This process
                                cannot be undone. Are you sure you want to
                                proceed?
                              </DialogDescription>
                            </DialogHeader>
                            <DialogFooter className="mt-4">
                              <Button
                                variant="outline"
                                onClick={() => setIsConfirmDialogOpen(false)}
                              >
                                Cancel
                              </Button>
                              <Button onClick={handleSaveValues}>
                                Confirm
                              </Button>
                            </DialogFooter>
                          </DialogContent>
                        </Dialog>
                      </div>
                    </TooltipWithIcon>
                    <TooltipWithIcon
                      content="This feature will be available in a future update."
                      type="info"
                    >
                      <div>
                        <Button variant="outline" size="sm" disabled>
                          <Plus className="w-4 h-4 mr-2" />
                          Add Value
                        </Button>
                      </div>
                    </TooltipWithIcon>
                  </div>
                </div>
                <P className="text-xs text-muted-foreground mb-2">
                  Add between 3 and 10 values to your organization.
                </P>
                <div className="space-y-4">
                  {orgValues.map((value, index) => (
                    <div key={index} className="flex gap-4 items-start">
                      <div className="flex-1 space-y-2">
                        <Input
                          placeholder="Value name"
                          value={value.name}
                          onChange={(e) =>
                            updateValue(index, 'name', e.target.value)
                          }
                          maxLength={30}
                        />
                        <Textarea
                          placeholder="Value description"
                          value={value.description}
                          onChange={(e) =>
                            updateValue(index, 'description', e.target.value)
                          }
                          maxLength={100}
                          className="resize-none"
                          rows={2}
                        />
                      </div>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => removeValue(index)}
                        disabled={orgValues.length <= 1}
                        className={cn(
                          'h-10 w-10',
                          orgValues.length <= 1 && 'invisible',
                        )}
                      >
                        <X className="w-4 h-4" />
                      </Button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="space-y-2">
                <Label>Integrations</Label>
                <div className="flex flex-col gap-4">
                  {organizationIntegrations.map((integration) => (
                    <div
                      key={integration.id}
                      className="flex items-center justify-between"
                    >
                      <div className="flex flex-col flex-1">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-3">
                            <img
                              src={integration.logo}
                              alt={integration.name}
                              className="w-4 h-4"
                            />
                            <span className="text-sm">{integration.name}</span>
                          </div>
                          {integration.isDisabled ? (
                            <TooltipWithIcon
                              content={integration.disabledReason || ''}
                              type="warning"
                            >
                              <Button
                                onClick={integration.onConnect}
                                variant="outline"
                                disabled
                              >
                                Connect
                              </Button>
                            </TooltipWithIcon>
                          ) : (
                            <Button
                              onClick={integration.onConnect}
                              variant={
                                integration.isConnected ? 'outline' : 'default'
                              }
                              disabled={integration.isConnected}
                            >
                              {integration.isConnected
                                ? 'Connected'
                                : 'Connect'}
                            </Button>
                          )}
                        </div>
                        <P className="text-xs text-muted-foreground">
                          {integration.description}
                        </P>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Card>
        )}

        <Card className="p-6">
          <div className="flex flex-col space-y-1.5 mb-6">
            <h3 className="font-semibold leading-none tracking-tight">
              User settings
            </h3>
          </div>
          <div className="space-y-6">
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <Label htmlFor="user-preferred-language">
                  Preferred Language
                </Label>
                <TooltipWithIcon
                  content="This feature will be available in a future update"
                  type="info"
                />
              </div>
              <Input
                id="user-preferred-language"
                placeholder="Auto-detect"
                disabled
              />
            </div>
            <div className="space-y-2">
              <Label>Integrations</Label>
              <div className="flex flex-col gap-4">
                {userIntegrations.map((integration) => (
                  <div
                    key={integration.id}
                    className="flex items-center justify-between"
                  >
                    <div className="flex flex-col flex-1">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-3">
                          <img
                            src={integration.logo}
                            alt={integration.name}
                            className="w-4 h-4"
                          />
                          <span className="text-sm">{integration.name}</span>
                        </div>
                        {integration.isDisabled ? (
                          <TooltipWithIcon
                            content={integration.disabledReason || ''}
                            type="warning"
                          >
                            <Button
                              onClick={integration.onConnect}
                              variant="outline"
                              disabled
                            >
                              Connect
                            </Button>
                          </TooltipWithIcon>
                        ) : (
                          <Button
                            onClick={integration.onConnect}
                            variant={
                              integration.isConnected ? 'outline' : 'default'
                            }
                            disabled={integration.isConnected}
                          >
                            {integration.isConnected ? 'Connected' : 'Connect'}
                          </Button>
                        )}
                      </div>
                      <P className="text-xs text-muted-foreground">
                        {integration.description}
                      </P>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </LayoutTile>
  )
}
