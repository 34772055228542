import React, { useMemo } from 'react'
import moment from 'moment'
import lodash from 'lodash'
import colors from 'tailwindcss/colors'

import { Tile } from '@/components/common/tile'
import { Muted, H4 } from '@/components/ui/typography'
import { TooltipWithIcon } from '@/components/common/tooltip'
import BarChart from '@/components/common/bar-chart'
import LineChart, { LINE_CHART_COLORS } from '@/components/common/line-chart'
import { PlayCircle } from 'lucide-react'

import { useScreenDetector } from '@/hooks/useScreenDetector'
import pastWeekIntervals from '@/services/utils/pastWeekIntervals'
import { formatDecimalToPercentage } from '@/services/utils/formatters'
import { average } from '@/services/utils/math'

import { FeedbackStats } from '@/types/FeedbackStats'
import { Progress } from '@/components/ui/progress'

interface MetricTileProps {
  title: string
  value: string | number
  isLoading: boolean
  valueColor?: string
  tooltipContent: string
  tooltipType?: 'info' | 'warning' | 'error'
}

export const MetricTile: React.FC<MetricTileProps> = ({
  title,
  value,
  isLoading,
  valueColor = 'text-primary',
  tooltipContent,
  tooltipType = 'info',
}) => (
  <Tile className="min-h-20" isLoading={isLoading}>
    <div className="flex items-center gap-2">
      <Muted>{title}</Muted>
      <TooltipWithIcon content={tooltipContent} type={tooltipType} />
    </div>
    <H4 className={valueColor}>{value}</H4>
  </Tile>
)

interface DashboardContentProps {
  feedbackStats?: FeedbackStats
  isLoadingStats: boolean
}

export const DashboardContent: React.FC<DashboardContentProps> = ({
  feedbackStats,
  isLoadingStats,
}) => {
  const { isMobile } = useScreenDetector()
  const pastWeekIntervalsToShow = useMemo(
    () => (isMobile ? pastWeekIntervals.slice(3) : pastWeekIntervals),
    [isMobile],
  )

  const checklistComplianceValue = useMemo(() => {
    return feedbackStats?.checklistCompliance ?? 0
  }, [feedbackStats])

  const companyValues = useMemo(() => {
    if (!feedbackStats?.companyValuesAverage) return []
    return Object.entries(feedbackStats.companyValuesAverage).map(
      ([name, score]) => ({
        name,
        score,
      }),
    )
  }, [feedbackStats?.companyValuesAverage])

  const sortedCompanyValues = useMemo(() => {
    return [...companyValues].sort((a, b) => b.score - a.score)
  }, [companyValues])

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <MetricTile
          title="Feedback Points"
          value={
            (feedbackStats?.done?.meets_checklist ?? 0) +
            (feedbackStats?.done?.does_not_meet_checklist ?? 0)
          }
          isLoading={isLoadingStats}
          tooltipContent="Number of Feedback Points with a clear target"
        />
        <MetricTile
          title="Missed Feedback"
          value={formatDecimalToPercentage(
            feedbackStats?.missedProactiveFeedback ?? 0,
            0,
          )}
          isLoading={isLoadingStats}
          valueColor={
            (feedbackStats?.missedProactiveFeedback ?? 0) > 0.3
              ? 'text-rose-400'
              : 'text-green-600'
          }
          tooltipContent="Percentage of nudges from Fiddy you've missed"
          tooltipType="info"
        />
        <MetricTile
          title="Quality score"
          value={formatDecimalToPercentage(checklistComplianceValue, 0)}
          isLoading={isLoadingStats}
          valueColor={
            checklistComplianceValue < 0.7 ? 'text-rose-400' : 'text-green-600'
          }
          tooltipContent="Percentage of quality checklist criteria that are met"
          tooltipType="info"
        />
        <Tile
          className="min-h-20 flex flex-col justify-between"
          onClick={() =>
            window.open(
              'https://www.youtube.com/watch?v=1GzBSuNJdHA&list=PLc5u-ik0Rm5ekzrCpESsanA4zD9kAaV55&index=1',
              '_blank',
            )
          }
        >
          <div className="flex items-center gap-2">
            <Muted>Questions on how to use?</Muted>
          </div>
          <div className="flex items-center gap-2">
            <PlayCircle className="w-5 h-5 text-primary" />
            <span className="font-medium">Check our tutorials</span>
          </div>
        </Tile>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
        <Tile className="lg:col-span-3 h-[300px]" isLoading={isLoadingStats}>
          <div>
            <Muted>Feedback Points over time</Muted>
          </div>
          <BarChart
            data={pastWeekIntervalsToShow.map((week) => {
              const weekDetails = feedbackStats?.weekDetails.find(
                (elem) => elem.week === moment(week).week(),
              )
              const meetsChecklist = weekDetails?.done.meets_checklist ?? 0
              const doesNotMeetChecklist =
                weekDetails?.done.does_not_meet_checklist ?? 0
              return {
                label: `W${moment(week).week()}`,
                meetsChecklist,
                doesNotMeetChecklist,
              }
            })}
            bars={['meetsChecklist', 'doesNotMeetChecklist']}
            config={{
              meetsChecklist: {
                label: 'Meets quality threshold',
                color: colors.green['600'],
              },
              doesNotMeetChecklist: {
                label: "Doesn't meet quality threshold",
                color: colors.red['200'],
              },
            }}
            activeIndex={pastWeekIntervalsToShow.length}
            stacked={true}
          />
        </Tile>
        <Tile className="lg:col-span-2 h-[300px]" isLoading={isLoadingStats}>
          <Muted>Company values attributed to others</Muted>
          <div className="flex flex-1 flex-col gap-4 justify-center">
            {sortedCompanyValues.map((value) => (
              <div
                key={value.name}
                className="flex items-center justify-between w-full"
              >
                <span
                  className="w-32 text-sm text-muted-foreground mr-2 truncate font-medium"
                  title={value.name}
                >
                  {value.name}
                </span>
                <div className="flex-1 mx-2">
                  <Progress value={value.score * 20} className="h-4" />
                </div>
                <span className="text-sm font-medium">
                  {value.score.toFixed(1)}
                </span>
              </div>
            ))}
          </div>
        </Tile>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <Tile className="h-[300px]" isLoading={isLoadingStats}>
          <Muted>Values attributed to others over time</Muted>
          <LineChart
            data={pastWeekIntervalsToShow.map((week) => ({
              label: `W${moment(week).week()}`,
              ...lodash.fromPairs(
                (feedbackStats?.companyValuesKeys ?? []).map((key) => [
                  key,
                  average(
                    feedbackStats?.weekDetails
                      .find((elem) => elem.week === moment(week).week())
                      ?.companyValuesScore?.map(
                        (sentiment) => sentiment[key],
                      ) ?? [],
                  ),
                ]),
              ),
            }))}
            lines={feedbackStats?.companyValuesKeys ?? []}
            config={lodash.fromPairs(
              (feedbackStats?.companyValuesKeys ?? []).map((value, idx) => [
                value,
                { label: value, color: LINE_CHART_COLORS[idx] },
              ]),
            )}
            domain={[0, 5]}
            legend
            type="monotone"
            initialHiddenLines={feedbackStats?.companyValuesKeys.slice(1) ?? []}
            storageKey="dashboard.company-values-chart.hidden-lines"
          />
        </Tile>
        <Tile className="h-[300px]" isLoading={isLoadingStats}>
          <Muted>Quality score over time</Muted>
          <LineChart
            data={pastWeekIntervalsToShow.map((week) => {
              const weekDetails = feedbackStats?.weekDetails.find(
                (elem) => elem.week === moment(week).week(),
              )

              // Calculate total scores for the week, only for complete scores
              const totalScores = weekDetails?.checklistScores.reduce(
                (acc, score) => {
                  // Only count scores that have all three values
                  if (
                    typeof score.yes === 'number' &&
                    typeof score.no === 'number' &&
                    typeof score.partial === 'number'
                  ) {
                    return {
                      yes: acc.yes + score.yes,
                      no: acc.no + score.no,
                      partial: acc.partial + score.partial,
                    }
                  }
                  return acc
                },
                { yes: 0, no: 0, partial: 0 },
              ) ?? { yes: 0, no: 0, partial: 0 }

              // Calculate compliance score only if we have valid scores
              const total =
                totalScores.yes + totalScores.no + totalScores.partial
              const complianceScore =
                total > 0
                  ? Number(
                      (
                        (totalScores.yes + totalScores.partial * 0.5) /
                        total
                      ).toFixed(2),
                    )
                  : null

              return {
                label: `W${moment(week).week()}`,
                averageCompliance: complianceScore,
              }
            })}
            lines={['averageCompliance']}
            config={{
              averageCompliance: {
                label: 'Average Quality Score',
                color: colors.blue['600'],
              },
            }}
            domain={[0, 1]}
            type="monotone"
            referenceLine={{
              y: 0.7,
              stroke: colors.gray['400'],
              strokeDasharray: '3 3',
              label: { value: 'Target: 0.7', position: 'insideTopRight' },
            }}
            storageKey="dashboard.quality-score-chart.hidden-lines"
          />
        </Tile>
      </div>
    </>
  )
}
