import { Link } from 'react-router-dom'
import { Logo } from '@/components/common/logo'
import { Icon } from '@/components/ui/icon'
import SlackSvg from '@/assets/slack.svg'
import { H5 } from '@/components/ui/typography'
import { buttonVariants } from '@/components/ui/button'
import { cn } from '@/lib/utils'

export const SlackSuccessPage = () => {
  return (
    <div className="min-h-screen flex-1 flex justify-center items-center">
      <div className="bg-white w-full max-w-[386px] rounded drop-shadow p-8 flex flex-col items-center gap-10">
        <div className="flex flex-col gap-4 w-full items-center">
          <div className="flex gap-2 items-center">
            <Logo />
            <Icon name="Plus" className="h-4 w-4 text-muted" />
            <img src={SlackSvg} alt="slack-logo" className="w-10 h-10" />
          </div>
          <H5>Fiddy is now in your Slack!</H5>
        </div>
        <Link
          className={cn(buttonVariants({ variant: 'default' }), 'w-full')}
          to="/"
        >
          Sign in now
        </Link>
      </div>
    </div>
  )
}
