import { companyProfiles } from '@/services/mocks/data/companyProfiles'

let currentCompanyProfile = 'default'

export const setCompanyProfile = (profile: string) => {
  if (companyProfiles[profile]) {
    currentCompanyProfile = profile
  } else {
    currentCompanyProfile = 'default'
  }
}

export const getCurrentCompanyProfile = () => {
  return currentCompanyProfile
}
