import axios, { AxiosError } from 'axios'

import { MOCK_SERVER_ENABLED } from '@/services/config/env'

type ApiResponse<T> = T & {
  error?: {
    id: string
    message: string
  }
}

const BASE_API_URL = !MOCK_SERVER_ENABLED
  ? import.meta.env.VITE_API_URL
  : import.meta.env.VITE_MOCK_API_URL

export const client = axios.create({
  baseURL: BASE_API_URL,
})

client.interceptors.response.use(
  function (response) {
    return response
  },
  function (error: AxiosError) {
    const response = error.response?.data as ApiResponse<unknown>

    throw new Error(
      response?.error?.message ??
        'Unknown error occurred. Can you please try again?',
    )
  },
)
