import { useAuth, useOrganization } from '@clerk/clerk-react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { addQueryParams, fetchResolve, patchResolve } from './helpers'

import { OrganizationMembership, UserProfile } from '@/types/UserProfile'
import { useToast } from '@/hooks/use-toast'
import { createServerErrorToast } from '@/lib/toast'

type TeamScope = 'all' | 'directReports' | 'underUser'

export const useFetchTeamMembers = (options?: {
  teamScope?: TeamScope
  enabled?: boolean
}) => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<UserProfile[]>({
    queryKey: ['useFetchTeamMembers', options?.teamScope, organization?.id],
    queryFn: () =>
      fetchResolve(
        addQueryParams(`/organization/${organization?.id}/user`, {
          teamScope: options?.teamScope || 'all',
        }),
        getToken,
      ),
    enabled: (options?.enabled ?? true) && !!organization?.id,
    staleTime: 30000,
  })
}

export const useFetchOrganizationMemberships = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<OrganizationMembership[]>({
    queryKey: ['useFetchOrganizationMemberships', organization?.id],
    queryFn: () =>
      fetchResolve(`/organization/${organization?.id}/membership`, getToken),
  })
}

export const useFetchIntegrations = () => {
  const { getToken } = useAuth()
  const { organization } = useOrganization()

  return useQuery<{ type: string; enabled: boolean }[]>({
    queryKey: ['useFetchIntegrations', organization?.id],
    queryFn: () =>
      fetchResolve(`/organization/${organization?.id}/integration`, getToken),
  })
}

export const usePatchOrganizationMembership = () => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (props: {
      membershipId: string
      body: { managerId?: string | null; isLicensed?: boolean } // TODO: Add zod schema here
    }) =>
      await patchResolve(
        `/organization/${organization?.id}/membership/${props.membershipId}`,
        props.body,
        getToken,
      ),
    onSuccess: (newNode: OrganizationMembership, variables) => {
      queryClient.setQueryData(
        ['useFetchOrganizationMemberships', organization?.id],
        (old: OrganizationMembership[]) => {
          return old.map((node) =>
            node.id === variables.membershipId ? newNode : node,
          )
        },
      )
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: ['useFetchOrganizationMemberships', organization?.id],
      })
      const previousQuery = queryClient.getQueryData([
        'useFetchOrganizationMemberships',
        organization?.id,
      ])

      // Optimistically update to the new value
      queryClient.setQueryData(
        ['useFetchOrganizationMemberships', organization?.id],
        (old: OrganizationMembership[]) =>
          old?.map((membership) =>
            membership.id === variables.membershipId
              ? {
                  ...membership,
                  ...variables.body,
                }
              : membership,
          ) ?? [],
      )

      // Return a context object with the snapshotted value
      return { previousQuery }
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(
        ['useFetchOrganizationMemberships', organization?.id],
        context?.previousQuery ?? [],
      )
      toast(createServerErrorToast(error.message))
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['useFetchLicenseInfo'] })
      queryClient.invalidateQueries({
        queryKey: ['useFetchOrganizationMemberships'],
      })
      queryClient.invalidateQueries({
        queryKey: ['useFetchTeamMembers'],
      })
    },
  })
}
