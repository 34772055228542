import { SignUp, useAuth } from '@clerk/clerk-react'
import { useNavigate } from 'react-router-dom'

export function SignUpPage() {
  const { isSignedIn } = useAuth()
  const navigate = useNavigate()

  if (isSignedIn) {
    navigate('/')
    return
  }

  return (
    <div className="w-full flex flex-col justify-center items-center">
      <SignUp path="/sign-up" signInUrl="/sign-in" />
    </div>
  )
}
