import { useOrganization } from '@clerk/clerk-react'

import { P } from '@/components/ui/typography'
import { Icon } from '@/components/ui/icon'
import { Button } from '@/components/ui/button'
import {
  Card,
  CardFooter,
  CardHeader,
  CardTitle,
  CardContent,
} from '@/components/ui/card'

import { useGetPortalUrl } from '@/services/api/billing.api'
import { MOCK_SERVER_ENABLED } from '@/services/config/env'

interface ManageLicensesCardProps {
  licensedUsersCount: number
  purchasedLicensesCount: number
}

function ManageLicensesCard({
  licensedUsersCount,
  purchasedLicensesCount,
}: ManageLicensesCardProps) {
  const { organization } = useOrganization()
  const getPortalUrl = useGetPortalUrl()

  async function onManageSubscriptionClicked() {
    const url = await getPortalUrl.mutateAsync()
    window.location.href = url
  }

  if (MOCK_SERVER_ENABLED) {
    return (
      <Card className="w-full max-w-[92vw] sm:max-w-none">
        <CardHeader>
          <CardTitle>You are currently using a demo account</CardTitle>
        </CardHeader>
        <CardContent>
          <P className="text-xs sm:text-sm">
            Visit our pricing page to create your own account and get access to
            all features.
          </P>
        </CardContent>
        <CardFooter>
          <Button
            className="w-full sm:w-auto"
            onClick={() =>
              window.open('https://fidforward.com/pricing/', '_blank')
            }
          >
            View pricing plans
          </Button>
        </CardFooter>
      </Card>
    )
  }

  return (
    <Card className="w-full max-w-[92vw] sm:max-w-none">
      <CardHeader>
        <CardTitle>
          {organization?.name} is using {licensedUsersCount} of{' '}
          {purchasedLicensesCount} available seats.
        </CardTitle>
      </CardHeader>
      <CardFooter className="flex justify-center sm:justify-start">
        <Button
          className="w-full sm:w-auto"
          onClick={onManageSubscriptionClicked}
        >
          {getPortalUrl.isPending ? (
            <Icon name="Loader" className="animate-spin" />
          ) : (
            'Manage your seats'
          )}
        </Button>
      </CardFooter>
    </Card>
  )
}

export default ManageLicensesCard
