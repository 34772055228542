import { useState } from 'react'
import moment from 'moment'

import { MultiSelect } from '@/components/common/select'
import { Muted, H2 } from '@/components/ui/typography'
import { LayoutTile } from '@/components/layout'
import { DatePicker } from '@/components/common/date-picker'
import { DashboardContent } from '@/components/dashboard-content'

import { useFetchFeedbackStats } from '@/services/api/feedbackPoints.api'
import { useFetchTeamMembers } from '@/services/api/organization.api'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { useUserRole } from '@/hooks/useUserRole'

import {
  StoredDateState,
  convertDateRangeToStoredRange,
} from '@/types/DateState'

export const ManagementDashboard = () => {
  const { isAdmin } = useUserRole()
  const [selectedMemberIds, setSelectedMemberIds] = useState<string[]>(['all'])
  const [storedDateState, setStoredDateState] =
    useLocalStorage<StoredDateState>('management-dashboard.date-state', {
      dateRange: {
        from: moment('2024-01-01').startOf('day').toDate(),
        to: new Date(),
      },
      preset: 'all-time',
    })

  const { data: feedbackStats, isLoading: isLoadingStats } =
    useFetchFeedbackStats(
      selectedMemberIds.includes('all') ? 'all' : selectedMemberIds,
      storedDateState.dateRange,
    )
  const { data: teamMembers, isLoading: isLoadingTeamMembers } =
    useFetchTeamMembers({ teamScope: isAdmin ? 'all' : 'underUser' })

  return (
    <LayoutTile className="bg-transparent p-0 sm:p-0">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-2">
        <div>
          <H2>Management Dashboard</H2>
          <Muted className="mt-1">
            You can find here the consolidated data for the team under you.
          </Muted>
        </div>
        <div className="management-dashboard-filters flex flex-col sm:flex-row items-stretch sm:items-center gap-4 mt-4 sm:mt-0">
          <div className="flex-shrink-0">
            <DatePicker
              value={storedDateState.dateRange}
              selectedPreset={storedDateState.preset}
              onChange={(dateRange, preset) => {
                setStoredDateState({
                  dateRange: convertDateRangeToStoredRange(dateRange),
                  preset,
                })
              }}
              includeAllTime
            />
          </div>
          <MultiSelect
            value={selectedMemberIds}
            onValueChange={(value) => {
              if (value.includes('all')) {
                setSelectedMemberIds(['all'])
              } else {
                setSelectedMemberIds(value)
              }
            }}
            options={[
              { value: 'all', label: 'All Team Members' },
              ...(teamMembers?.map((member) => ({
                value: member.id,
                label: `${member.firstName} ${member.lastName}`,
              })) || []),
            ]}
            className="w-full sm:w-[300px] bg-background"
            isLoading={isLoadingTeamMembers || isLoadingStats}
          />
        </div>
      </div>

      <DashboardContent
        feedbackStats={feedbackStats}
        isLoadingStats={isLoadingStats}
      />
    </LayoutTile>
  )
}
