import { useState } from 'react'
import { Label } from '@radix-ui/react-label'

import { Button } from '@/components/ui/button'
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Loader2 } from 'lucide-react'

import { useGetCheckoutUrl } from '@/services/api/billing.api'

function PurchaseLicensesCard() {
  const [count, setCount] = useState(1)
  const getCheckoutUrl = useGetCheckoutUrl()

  const calculatePrice = (userCount: number) => {
    if (userCount < 10) {
      return userCount * 8
    }
    // If 10 or more users, all users are charged at $5
    return userCount * 5
  }

  const pricePerUser = count >= 10 ? 5 : 8
  const totalPrice = calculatePrice(count || 0)

  async function onPurchaseClicked() {
    const promotekitReferral = (
      window as unknown as { promotekit_referral: string }
    ).promotekit_referral

    const url = await getCheckoutUrl.mutateAsync({
      quantity: count,
      promotekitReferral: promotekitReferral,
    })
    window.location.href = url
  }

  return (
    <Card className="w-full max-w-[92vw] sm:max-w-none">
      <CardHeader>
        <CardTitle>Purchase FidForward licenses</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-4">
        <div className="flex flex-col sm:flex-row items-center sm:items-baseline gap-4">
          <div className="flex flex-col sm:flex-row gap-2 items-center sm:items-baseline">
            <Input
              min={1}
              className="w-[120px]"
              type="number"
              onChange={(e) => setCount(e.target.valueAsNumber)}
              value={count}
            />
            <span className="text-sm text-muted-foreground text-center sm:text-left">
              users @ ${pricePerUser}/user/mo
            </span>
          </div>
          <div className="items-center sm:items-baseline flex gap-2">
            <span className="text-sm text-muted-foreground">Total:</span>
            <Label className="font-bold">${totalPrice}.00/month</Label>
          </div>
        </div>
        {count < 10 && (
          <p className="text-sm text-muted-foreground text-center sm:text-left">
            Purchase 10 or more licenses to upgrade to the Startup plan
            ($5/user/mo)
          </p>
        )}
      </CardContent>
      <CardFooter className="flex justify-center sm:justify-start">
        <Button
          className="w-full sm:w-auto"
          disabled={getCheckoutUrl.isPending}
          onClick={onPurchaseClicked}
        >
          {getCheckoutUrl.isPending ? (
            <Loader2 className="animate-spin" />
          ) : (
            'Purchase licenses'
          )}
        </Button>
      </CardFooter>
    </Card>
  )
}

export default PurchaseLicensesCard
