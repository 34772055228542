import { useQuery } from '@tanstack/react-query'
import { useOrganization, useAuth } from '@clerk/clerk-react'

import { fetchResolve } from './helpers'
import type { CompanyValue } from '@/types/CompanyValue'

export const useFetchCompanyValues = () => {
  const { organization } = useOrganization()
  const { getToken } = useAuth()

  return useQuery<CompanyValue[]>({
    queryKey: ['useFetchCompanyValues', organization?.id],
    queryFn: () =>
      fetchResolve(`/organization/${organization?.id}/values`, getToken),
  })
}
