import { z } from 'zod'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useAuth, useOrganization } from '@clerk/clerk-react'
import { useToast } from '@/hooks/use-toast'
import { createServerErrorToast } from '@/lib/toast'

import {
  addQueryParams,
  fetchResolve,
  patchResolve,
  postResolve,
} from './helpers'
import { Quarter } from '@/services/utils/dates'
import {
  PerformanceReviewCreateSchema,
  PerformanceReviewPatchSchema,
} from '@/lib/schema/performance-review.schema'

import {
  PerformanceReview,
  TeamMemberPerformanceReview,
} from '@/types/PerformanceReview'

export const useFetchTeamMemberPerformanceReviews = (
  quarter: Quarter,
  employeeScope: 'directReports' | 'allUnderMe' | 'all',
) => {
  const { organization } = useOrganization()
  const { getToken } = useAuth()

  return useQuery<TeamMemberPerformanceReview[]>({
    queryKey: [
      'useFetchTeamMemberPerformanceReviews',
      quarter,
      employeeScope,
      organization?.id,
    ],
    queryFn: () =>
      fetchResolve(
        addQueryParams(
          `/organization/${organization?.id}/performance-review/team`,
          {
            year: quarter.year,
            quarter: quarter.quarter,
            scope: employeeScope,
          },
        ),
        getToken,
      ),
  })
}

export const useFetchMyPerformanceReviews = () => {
  const { organization } = useOrganization()
  const { getToken } = useAuth()

  return useQuery<PerformanceReview[]>({
    queryKey: ['useFetchMyPerformanceReviews', organization?.id],
    queryFn: () =>
      fetchResolve(
        `/organization/${organization?.id}/performance-review`,
        getToken,
      ),
  })
}

export const useUpdateTeamMemberPerformanceReview = (
  selectedQuarter: Quarter,
) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (props: {
      id: string
      body: z.infer<typeof PerformanceReviewPatchSchema>
    }) =>
      await patchResolve(
        `/organization/${organization?.id}/performance-review/${props.id}`,
        props.body,
        getToken,
      ),
    onMutate: async (variables) => {
      let truePreviousQuery = undefined
      let falsePreviousQuery = undefined

      for (const includeAll of [true, false]) {
        await queryClient.cancelQueries({
          queryKey: [
            'useFetchTeamMemberPerformanceReviews',
            selectedQuarter,
            includeAll,
          ],
        })

        if (includeAll) {
          truePreviousQuery = queryClient.getQueryData([
            'useFetchTeamMemberPerformanceReviews',
            selectedQuarter,
            true,
            organization?.id,
          ])
        } else {
          falsePreviousQuery = queryClient.getQueryData([
            'useFetchTeamMemberPerformanceReviews',
            selectedQuarter,
            false,
            organization?.id,
          ])
        }

        // Optimistically update to the new value
        queryClient.setQueryData<TeamMemberPerformanceReview[]>(
          [
            'useFetchTeamMemberPerformanceReviews',
            selectedQuarter,
            includeAll,
            organization?.id,
          ],
          (old) =>
            (old ?? []).map((teamReview) =>
              teamReview.review?.id === variables.id
                ? {
                    ...teamReview,
                    review: { ...teamReview.review, ...variables.body },
                  }
                : teamReview,
            ),
        )
      }

      return { truePreviousQuery, falsePreviousQuery }
    },
    onError: (error, _variables, context) => {
      queryClient.setQueryData(
        [
          'useFetchTeamMemberPerformanceReviews',
          selectedQuarter,
          true,
          organization?.id,
        ],
        context?.truePreviousQuery ?? [],
      )
      queryClient.setQueryData(
        [
          'useFetchTeamMemberPerformanceReviews',
          selectedQuarter,
          false,
          organization?.id,
        ],
        context?.falsePreviousQuery ?? [],
      )
      toast(createServerErrorToast(error.message))
    },
  })
}

export const useCreateTeamMemberPerformanceReview = (
  selectedQuarter: Quarter,
) => {
  const queryClient = useQueryClient()
  const { getToken } = useAuth()
  const { organization } = useOrganization()
  const { toast } = useToast()

  return useMutation({
    mutationFn: async (body: z.infer<typeof PerformanceReviewCreateSchema>) =>
      await postResolve(
        `/organization/${organization?.id}/performance-review`,
        body,
        getToken,
      ),
    onSuccess: (updatedReview: PerformanceReview) => {
      for (const includeAll of [true, false]) {
        queryClient.setQueryData<TeamMemberPerformanceReview[]>(
          [
            'useFetchTeamMemberPerformanceReviews',
            selectedQuarter,
            includeAll,
            organization?.id,
          ],
          (oldData) =>
            (oldData ?? []).map((teamReview) =>
              teamReview.user.id === updatedReview.user.id
                ? { ...teamReview, review: updatedReview }
                : teamReview,
            ),
        )
      }
    },
    onError: (error) => {
      toast(createServerErrorToast(error.message))
    },
  })
}
