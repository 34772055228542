import { DateRange } from 'react-day-picker'

export interface StoredDateState {
  dateRange: DateRange
  preset: string
}

export const convertDateRangeToStoredRange = (range: DateRange): DateRange => ({
  from: range.from ?? undefined,
  to: range.to ?? new Date(),
})

export const isAllTime = (dateRange: DateRange) => {
  return !dateRange.from && dateRange.to
}
