import { useState, useEffect, useCallback } from 'react'
import { TawkAPI, TawkAttributes } from '@/types/Tawk'

const MAX_RETRIES = 3
const RETRY_DELAY = 1000 // 1 second

export function useTawk() {
  const [tawkApi, setTawkApi] = useState<TawkAPI | null>(null)

  useEffect(() => {
    const checkTawkReady = () => {
      if (
        window.Tawk_API &&
        typeof window.Tawk_API.setAttributes === 'function'
      ) {
        setTawkApi(window.Tawk_API)
        console.log('Tawk API is ready')
      } else {
        setTimeout(checkTawkReady, 100)
      }
    }

    checkTawkReady()

    return () => {
      setTawkApi(null)
    }
  }, [])

  const setAttributes = useCallback(
    async (attributes: TawkAttributes, retryCount = 0) => {
      if (!tawkApi) {
        if (retryCount < MAX_RETRIES) {
          console.log(
            `Tawk API not ready. Retrying (attempt ${retryCount + 1})`,
          )
          setTimeout(
            () => setAttributes(attributes, retryCount + 1),
            RETRY_DELAY,
          )
          return
        }
        console.error('Max retries reached. Failed to set Tawk attributes.')
        return
      }

      return new Promise<void>((resolve, reject) => {
        try {
          console.log('Setting Tawk attributes:', attributes)
          tawkApi.setAttributes(attributes, (error) => {
            if (error) {
              console.error('Error setting Tawk attributes:', error)
              if (retryCount < MAX_RETRIES) {
                console.log(
                  `Retrying setAttributes (attempt ${retryCount + 1})`,
                )
                setTimeout(
                  () => setAttributes(attributes, retryCount + 1),
                  RETRY_DELAY,
                )
                reject(error)
              } else {
                console.error(
                  'Max retries reached. Failed to set Tawk attributes.',
                )
                reject(error)
              }
            } else {
              console.log('Tawk attributes set successfully')
              resolve()
            }
          })
        } catch (error) {
          console.error('Error calling Tawk setAttributes:', error)
          reject(error)
        }
      })
    },
    [tawkApi],
  )

  return { tawkApi, setAttributes }
}
